import { getCreateMeetingPath, getInitialMeetingPricingPath } from 'utils/path-helpers/api'
import axios from 'axios'
import * as CONSTANTS from 'store/constants/meetingScheduler'
import { getRestrictionError } from 'utils/getters'
import { navigateToClientDashboard, navigateToSearchProAccount } from '../navigation'
import { openSnackbar, openErrorModal } from '../common'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'
import * as moment from 'moment'

export const createMeeting = data => (dispatch, getState) => {
  dispatch(createMeetingLoadingStart())
  return axios
    .post(getCreateMeetingPath(), data)
    .then(response => {
      dispatch(createMeetingLoadingEnd())
      dispatch(toggleMeetingModal(false))
      dispatch(navigateToClientDashboard())
      dispatch(openSnackbar('success', 'The meeting has been successfully scheduled'))
      Analytics.track(EVENTS.CONSULTATION_SCHEDULE, {
        pro_id: response.data.pro,
        practice_area: response.data.practice_area_name,
        hours_before_event: moment.unix(response.data.start_time).diff(moment()) / 1000 / 60 / 60,
        consultation_type: response.data.is_initial ? 'initial' : 'followup',
        state: getState().search.countryStateValue,
      })
      return {
        data: response.data,
        error: '',
      }
    })
    .catch(e => {
      dispatch(createMeetingLoadingEnd())

      Analytics.track(EVENTS.CONSULTATION_SCHEDULE, {
        pro_id: data.pro,
        practice_area: null,
        hours_before_event: moment.unix(data.start_time).diff(moment()) / 1000 / 60 / 60,
        consultation_type: data.is_initial ? 'initial' : 'followup',
        error: e.response && e.response.data && e.response.data.detail,
      })

      if (e.response && e.response.data && e.response.data.is_stripe_error) {
        return {
          data: {},
          error:
            e.response.data.description && e.response.data.instructions
              ? `${e.response.data.description} ${e.response.data.instructions}`
              : 'Error was occurred',
          isStripeError: e.response.data.is_stripe_error,
        }
      }

      dispatch(navigateToSearchProAccount(data.pro))
      if (getRestrictionError(e)) {
        dispatch(openErrorModal(e.response.data.title[0], e.response.data.detail[0]))
        return Promise.reject(e)
      }
      const errorMessage = (e.response && e.response.data && e.response.data.detail) || 'Error was occurred'
      dispatch(openSnackbar('error', errorMessage))
      return {
        data: {},
        error: errorMessage,
        isStripeError: false,
      }
    })
}

export const getInitialMeetingPricing = (proId, practiceArea) => dispatch => {
  return axios
    .post(getInitialMeetingPricingPath(), {
      practice_area_uuid: practiceArea,
      pro_uuid: proId,
    })
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(e => {
      const errorMessage =
        (e.response && e.response.data && e.response.data.detail) || 'Error was occurred while getting pricing'
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject()
    })
}

const createMeetingLoadingStart = () => ({
  type: CONSTANTS.CREATE_MEETING_LOADING_START,
})

const createMeetingLoadingEnd = () => ({
  type: CONSTANTS.CREATE_MEETING_LOADING_END,
})

export const setMeetingTime = payload => ({
  type: CONSTANTS.SET_MEETING_TIME,
  payload,
})

export const toggleMeetingModal = payload => ({
  type: CONSTANTS.TOGGLE_MEETING_MODAL,
  payload,
})
