export const formatNegativeNumber = (number, adornment = '') => {
  if (number === null || number === undefined) {
    return ''
  }
  if (number < 0) {
    return `(${formatNumber(Math.abs(number), adornment)})`
  } else {
    return formatNumber(number, adornment)
  }
}

export const formatNumber = (number, adornment = '') => {
  return `${adornment}${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number)}`
}
