import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIcon, Typography } from 'components'
import { ReactComponent as Logo } from 'static/Logo_Color_Primary.svg'
import BackgroundImage from 'static/mainPage/landing.photo1.jpg'
import { colors } from 'constants/index'
import { useQuery } from 'utils/hooks'
import { InviteRegistrationSteps } from 'containers'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    height: 40,
    width: 139,
    fill: 'none',
    marginBottom: 16,
  },
  photoContainer: {
    width: '30%',
    minHeight: '100vh',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
  },
  content: {
    padding: '24px 100px',
  },
  topText: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 2.78,
    color: colors.BASIC.COLOR[80],
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.25,
    color: colors.BASIC.COLOR[80],
    marginBottom: 24,
  },
  '@media(max-width:767px)': {
    content: {
      padding: '16px 16px',
    },
  },
  '@media(max-width:500px)': {
    photoContainer: {
      display: 'none',
    },
    content: {
      flexGrow: 1,
    },
  },
})

const InviteClientPage = () => {
  const classes = useStyles()
  const query = useQuery()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <SvgIcon viewBox="0 0 139 40" className={classes.icon}>
          <Logo />
        </SvgIcon>
        <Typography className={classes.topText}>find &bull; book &bull; meet</Typography>
        <Typography className={classes.title}>law made easy</Typography>
        <InviteRegistrationSteps
          hash={query.get('hash')}
          userEmail={query.get('username')}
          firstName={query.get('first_name')}
          lastName={query.get('last_name')}
        />
      </div>
      <div className={classes.photoContainer} />
    </div>
  )
}

export default InviteClientPage
