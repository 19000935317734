import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { Link, Checkbox, TextField, Hint, Icon } from 'components'
import { CONSTANTS, MESSAGES, REGEXP } from 'constants/index'
import { useStyles } from './rowStyles'
import { ReactComponent as MainFolderIcon } from 'static/vault/casesList/folder_row.svg'
import { ReactComponent as DocIcon } from 'static/vault/folder/doc.svg'
import { ReactComponent as OtherFileIcon } from 'static/vault/folder/file.svg'
import { ReactComponent as PDFIcon } from 'static/vault/folder/pdf.svg'
import { ReactComponent as XLSIcon } from 'static/vault/folder/xls.svg'
import { ReactComponent as VideoIcon } from 'static/vault/folder/video.svg'
import { ReactComponent as PPTIcon } from 'static/vault/folder/ppt.svg'
import { ReactComponent as SVGIcon } from 'static/vault/folder/svg.svg'
import { formatBytes } from 'utils/vault/vault-helpers'
import { setVaultSelectedFolders, renameVaultItem, setVaultPreviewItem } from 'store/actions/vault'

const FileIcon = ({ type, className }) => {
  switch (type) {
    case 'folder':
      return <MainFolderIcon className={className} />
    case 'doc':
    case 'docx':
      return <DocIcon className={className} />
    case 'pdf':
      return <PDFIcon className={className} />
    case 'ppt':
      return <PPTIcon className={className} />
    case 'svg':
      return <SVGIcon className={className} />
    case 'xls':
    case 'xlsx':
      return <XLSIcon className={className} />
    case 'mp4':
    case 'mov':
      return <VideoIcon className={className} />
    case 'zip':
      return <OtherFileIcon className={className} />
    default:
      return <OtherFileIcon className={className} />
  }
}

const getTypeLabel = type => {
  switch (type) {
    case 'folder':
      return 'Folder'
    case 'doc':
    case 'docx':
      return 'DOC document'
    case 'pdf':
      return 'PDF document'
    case 'ppt':
      return 'PPT document'
    case 'svg':
      return 'SVG document'
    case 'png':
      return 'PNG image'
    case 'jpg':
      return 'JPG image'
    case 'xls':
    case 'xlsx':
      return 'XLS document'
    case 'mov':
      return 'MOV video'
    case 'mp4':
      return 'MP4 video'
    case 'zip':
      return 'ZIP archive'
    default:
      return 'File'
  }
}

const getFileNameInputValue = data => {
  if (data.isFolder) return data.name
  else {
    const dotPos = data.name.lastIndexOf('.')
    return dotPos !== -1 ? data.name.slice(0, dotPos) : data.name
  }
}

const VaultFolderTableRow = props => {
  const {
    data,
    timezone,
    selectedRows,
    selectedFolders,
    renameItem,
    setRenameItem,
    nameCheck,
    disableSelection,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [nameInput, setNameInput] = useState(getFileNameInputValue(data))
  const [errorMessage, setErrorMessage] = useState(null)
  const isItemSelected = !!selectedRows.find(row => row.uuid === data.uuid)

  const navigateToCaseFolderDetails = e => {
    e.stopPropagation()
    if (data.isFolder) {
      dispatch(setVaultSelectedFolders([...selectedFolders, data]))
    } else dispatch(setVaultPreviewItem(data))
  }

  const handleNameFocus = e => {
    e.target.select()
  }

  const handleNameBlur = e => {
    setRenameItem(null)
  }

  const handleNameChange = e => {
    if (errorMessage) setErrorMessage(null)
    const value = e.target.value
    if (value && (value.length > 50 || REGEXP.folderNameRestrictedChars.test(value))) return
    setNameInput(value)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (nameInput === getFileNameInputValue(data) || !nameInput) {
        setRenameItem(null)
        return
      }
      if (!nameCheck(nameInput)) {
        setErrorMessage(MESSAGES.M0086)
        return
      }
      setRenameItem(null)
      const name = data.isFolder ? nameInput : `${nameInput}.${data.fileType}`
      dispatch(renameVaultItem(name, data))
    }
  }

  return (
    <>
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} disabled={disableSelection} />
      </TableCell>
      <TableCell className={classNames({ [classes.selectedRow]: isItemSelected })}>
        <div className={classes.cell} onClick={navigateToCaseFolderDetails}>
          {data.thumbnail ? (
            <img src={data.thumbnail} alt={data.name} className={classes.thumbnail} />
          ) : (
            <FileIcon type={data.fileType || data.type} className={classes.mainFolderIcon} />
          )}
          {renameItem && renameItem.name === data.name ? (
            <div>
              <TextField
                autoFocus
                onFocus={handleNameFocus}
                onBlur={handleNameBlur}
                variant="outlined"
                value={nameInput}
                onKeyPress={handleKeyPress}
                onChange={handleNameChange}
                onClick={e => e.stopPropagation()}
                InputProps={{
                  classes: { root: classes.input },
                }}
              />
              {errorMessage && (
                <Hint classes={{ subtitle2: classes.error }}>
                  <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                  {errorMessage}
                </Hint>
              )}
            </div>
          ) : (
            <Link className={classes.link}>{data.name}</Link>
          )}
        </div>
      </TableCell>
      <TableCell className={classNames({ [classes.selectedRow]: isItemSelected })}>
        {getTypeLabel(data.fileType || data.type)}
      </TableCell>
      <TableCell className={classNames({ [classes.selectedRow]: isItemSelected })}>{formatBytes(data.size)}</TableCell>
      <TableCell className={classNames({ [classes.selectedRow]: isItemSelected })}>
        {data.lastUploaded
          ? moment(data.lastUploaded)
              .tz(timezone)
              .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)
          : '-'}
      </TableCell>
    </>
  )
}
export default VaultFolderTableRow
