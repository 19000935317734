import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Modal, VaultModalHeader, VaultModalBody, Transition } from 'components'
import {
  getVaultFilesList,
  closeVaultModal,
  connectToVaultService,
  disconnectFromVaultService,
} from 'store/actions/vault'
import { navigateTo } from 'store/actions/navigation'
import { getVaultPreviewItem } from 'store/actions/vault'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'

const useStyles = makeStyles({
  vaultContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  paper: {
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0,
    height: '100%',
    width: '90%',
  },
})

const VaultModal = ({ open, previewItem, isClientVault, previewedFileId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const [vaultTab, setVaultTab] = useState(0)

  useEffect(() => {
    if (open) {
      dispatch(getVaultFilesList()).then(res => {
        dispatch(connectToVaultService())
        if (previewedFileId && res.length) {
          dispatch(getVaultPreviewItem(previewedFileId, res))
        }
      })
      setVaultTab(0)
    } else {
      dispatch(disconnectFromVaultService())
    }
  }, [open, previewedFileId])

  const handleClose = async () => {
    await dispatch(closeVaultModal())
    dispatch(navigateTo(location.pathname))
  }

  const handleVaultTabChange = tab => {
    setVaultTab(tab)
    if (tab === 2) {
      Analytics.track(EVENTS.PRO_OPENED_TRASH)
    } else if (tab === 1) {
      Analytics.track(isClientVault ? EVENTS.CLIENT_OPENED_LOGS : EVENTS.PRO_OPENED_LOGS)
    }
  }

  return (
    <Modal
      open={open}
      TransitionComponent={Transition}
      maxWidth="xl"
      handleClose={handleClose}
      dialogClasses={{ paper: classes.paper }}
    >
      <div className={classes.vaultContainer} id={'vault'}>
        <VaultModalHeader
          vaultTab={vaultTab}
          previewItem={previewItem}
          isClientVault={isClientVault}
          handleTabChange={handleVaultTabChange}
        />
        <VaultModalBody
          vaultTab={vaultTab}
          handleTabChange={handleVaultTabChange}
          previewItem={previewItem}
          isClientVault={isClientVault}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  open: state.vault.isVaultModalOpen,
  previewItem: state.vault.previewItem,
  isClientVault: state.vault.isClientVault,
  previewedFileId: state.vault.previewedFileId,
})

export default connect(mapStateToProps)(VaultModal)
