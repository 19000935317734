import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ProInvoicesContainer, ProViewInvoicesContainer, ProWalletContainer } from 'containers'

const useStyles = makeStyles({
  financeContainer: {
    height: '100%',
  },
})
const ProFinancesPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.financeContainer}>
      <Switch>
        <Route path="/pro/account/finances/invoices/view/:invoiceId" component={ProViewInvoicesContainer} />
        <Route path="/pro/account/finances/invoices" component={ProInvoicesContainer} />
        <Route path="/pro/account/finances/my-wallet" component={ProWalletContainer} />
      </Switch>
    </div>
  )
}

export default withRouter(ProFinancesPage)
