import React from 'react'
import { REGEXP, MESSAGES } from 'constants/index'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ActivityFormBody, Typography } from 'components'
import { useStyles } from './styles'

const validationSchema = Yup.object({
  clientId: Yup.string()
    .trim()
    .nullable()
    .required(MESSAGES.M0001),
  caseId: Yup.string()
    .trim()
    .nullable()
    .required(MESSAGES.M0001),
  billable: Yup.boolean(),
  activityType: Yup.number(),
  creationDate: Yup.string()
    .trim()
    .nullable()
    .required(MESSAGES.M0001),
  invoiceDate: Yup.string().when('billable', {
    is: true,
    then: Yup.string()
      .nullable()
      .required(MESSAGES.M0001),
    otherwise: Yup.string().nullable(),
  }),
  description: Yup.string()
    .trim()
    .nullable()
    .max(150),
  hours: Yup.number().when('activityType', {
    is: 0,
    then: Yup.number().when('minutes', {
      is: minutes => !minutes,
      then: Yup.number()
        .typeError('Hours should be a number')
        .required(MESSAGES.M0001)
        .max(99)
        .min(0),
      otherwise: Yup.number()
        .typeError('Hours should be a number')
        .max(99)
        .min(0),
    }),
    otherwise: Yup.number().nullable(),
  }),
  minutes: Yup.number().when('activityType', {
    is: 0,
    then: Yup.number().when('hours', {
      is: hours => !hours,
      then: Yup.number()
        .typeError('Minutes should be a number')
        .required(MESSAGES.M0001)
        .max(59)
        .min(0),
      otherwise: Yup.number()
        .typeError('Minutes should be a number')
        .max(59)
        .min(0),
    }),
    otherwise: Yup.number().nullable(),
  }),
  expense: Yup.string().when('activityType', {
    is: 1,
    then: Yup.string()
      .required(MESSAGES.M0001)
      .test('regex', 'please enter a valid expense', val => REGEXP.decimal.test(val)),
    otherwise: Yup.string().nullable(),
  }),
  rate: Yup.string().when('activityType', {
    is: 0,
    then: Yup.string()
      .required(MESSAGES.M0001)
      .test('regex', 'Please enter a maximum of 5 digits and no decimals', val => REGEXP.fiveDecimals.test(val)),
    otherwise: Yup.string().nullable(),
  }),
})

const ActivitiesForm = ({
  setActivityInfo = () => {},
  activity,
  onSubmit,
  onReset,
  footer,
  type,
  mode = 'create',
  header,
}) => {
  const classes = useStyles()
  const initialValues = {
    case: activity.case || {},
    caseId: activity.caseId || null,
    billable: activity.billable || false,
    activityType: activity.activityType,
    charge: activity.charge,
    rate: activity.rate,
    expense: activity.expense || null,
    time: activity.time || null,
    hours: activity.hours || '',
    minutes: activity.minutes || '',
    description: activity.description,
    creationDate: activity.creationDate || new Date(),
    invoiceDate: activity.invoiceDate || null,
    clientCharge: activity.clientCharge || ' ',
    clientId: activity.clientId || null,
    editMode: mode === 'create' ? true : activity.editMode,
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onReset}
      validateOnChange={false}
      enableReinitialize
    >
      {props => {
        const { clientCharge, editMode } = props.values
        const clientChargeLabel = clientCharge === null ? 'N/A' : clientCharge !== 0 ? `$${clientCharge}` : 'No charge'
        return (
          <Form className={classes.form}>
            {header}
            <div className={classes.formContainer}>
              <ActivityFormBody type={type} mode={mode} {...props} />
            </div>
            <div className={classes.footerContainer}>
              <div className={classes.chargeColumn}>
                <Typography className={classes.chargeTitle}>Client charge: </Typography>
                <Typography className={classes.chargeText}>{clientChargeLabel}</Typography>
              </div>
              {editMode && (
                <div className={classes.footer}>{React.cloneElement(footer, { disabled: !props.dirty })}</div>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ActivitiesForm
