import { getStripeConnectUrl } from '../../path-helpers/api'

export const getWalletFilters = ({ filters, tableConfiguration }) => ({
  search: filters.search || null,
  action: filters.action === '' || filters.action === undefined || filters.action === null ? null : filters.action,
  date_before: filters.date.to || null,
  date_after: filters.date.from || null,
  limit: tableConfiguration.limit,
  offset: tableConfiguration.offset,
})

export const getWalletFiltersExport = ({ filters }) => ({
  search: filters.search || null,
  action: filters.action === '' || filters.action === undefined || filters.action === null ? null : filters.action,
  date_before: filters.date.to || null,
  date_after: filters.date.from || null,
})

export const getStripeConnectParams = ({ profileCreation, auth }) => {
  let path = `${getStripeConnectUrl()}?redirect_uri=${window.location.origin}/pro/account/settings/payment&client_id=${
    process.env.REACT_APP_STRIPE_CONNECT_KEY
  }`
  const userInfo = auth.currentUser
  const contactInfo = profileCreation.profileData.contactInfo

  if (contactInfo.phone) {
    path = `${path}&stripe_user[phone_number]=${contactInfo.phone}`
  }
  if (contactInfo.country) {
    const country = profileCreation.countries.find(e => e.value === contactInfo.country)
    path = `${path}&stripe_user[country]=${country ? country.label : ''}`
  }
  if (userInfo.username) {
    const email = userInfo.username.replace(/\+/gi, '%2B')
    path = `${path}&stripe_user[email]=${email}`
  }
  if (userInfo.first_name) {
    path = `${path}&stripe_user[first_name]=${userInfo.first_name}`
  }
  if (userInfo.last_name) {
    path = `${path}&stripe_user[last_name]=${userInfo.last_name}`
  }

  return path
}
