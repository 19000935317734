import React from "react"
import { ReactComponent as UploadIcon } from 'static/profile/file-upload.svg'
import { ReactComponent as UploadSecondaryIcon } from 'static/vault/upload_orange.svg'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { colors } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  fileInputLabel: {
    border: `1px solid ${colors.PRIMARY.COLOR["50"]}`,
    display: 'flex',
    padding: '4px 12px',
    cursor: 'pointer',
    borderRadius: 4,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.3,
    width: 145,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  primary:{
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY.COLOR["50"],
  },
  secondary:{
    color: colors.PRIMARY.COLOR["50"],
    backgroundColor: colors.PRIMARY.COLOR["0"],
  },
  fileInputLabel__icon: {
    marginRight: 4,
  },
})

const FileUploadButton = ({htmlFor, label, className, variant = 'primary'}) => {
  const classes = useStyles()
  return <label htmlFor={htmlFor}
                className={classNames(classes.fileInputLabel, classes[variant], className)}>
    {variant === 'primary'
      ? <UploadIcon className={classes.fileInputLabel__icon} />
      : <UploadSecondaryIcon className={classes.fileInputLabel__icon} />
    } {label}
  </label>
}

export default FileUploadButton