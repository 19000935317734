import * as CONSTANTS from 'store/constants/common'
import axios from 'axios'
import { navigateToNewWindow, navigateToWindow } from 'store/actions/navigation'
import {
  getLanguagesPath,
  getTimezonesPath,
  getAdvancedSchedulesPath,
  getAdvancedEventBufferPath,
  getClientInviteValidationPath,
  getClientInviteSubmissionPath,
  getPortalStatePath,
  getZendeskSsoPath,
  getZendeskTicketsPath,
  getZendeskFieldPath,
} from '../../../utils/path-helpers/api'

export const openErrorModal = (title, detail) => ({
  type: CONSTANTS.OPEN_ERROR_MODAL,
  title,
  detail,
})

export const closeErrorModal = () => ({
  type: CONSTANTS.CLOSE_ERROR_MODAL,
})

export const openSnackbar = (variant, message) => ({
  type: CONSTANTS.OPEN_SNACKBAR,
  variant,
  message,
})

export const closeSnackbar = () => ({
  type: CONSTANTS.CLOSE_SNACKBAR,
})

const setAllLanguages = payload => ({
  type: CONSTANTS.SET_ALL_LANGUAGES,
  payload,
})

export const setSessionID = sessionID => ({
  type: CONSTANTS.SET_SESSION_ID,
  payload: sessionID,
})

export const getAllLanguages = () => dispatch => {
  axios
    .get(getLanguagesPath())
    .then(response => dispatch(setAllLanguages(response.data)))
    .catch(e => Promise.reject(e))
}

const setAdvancedCalendarValues = payload => ({
  type: CONSTANTS.SET_ADVANCED_CALENDAR_VALUES,
  payload,
})

export const getAdvancedCalendarValues = () => dispatch => {
  const getAdvancedSchedules = axios.get(getAdvancedSchedulesPath())
  const getAdvancedEventBuffer = axios.get(getAdvancedEventBufferPath())

  return Promise.all([getAdvancedSchedules, getAdvancedEventBuffer])
    .then(([advancedSchedules, advancedEventBuffer]) => {
      dispatch(
        setAdvancedCalendarValues({
          advancedSchedulesList: advancedSchedules.data,
          advancedEventBufferList: advancedEventBuffer.data,
        })
      )
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while getting advanced schedulers'))
      return Promise.reject(e)
    })
}

export const getTimezonesList = () => dispatch => {
  axios
    .get(getTimezonesPath())
    .then(res =>
      dispatch(
        setAdvancedCalendarValues({
          timezonesList: res.data,
        })
      )
    )
    .catch(e => {
      if (e.response && e.response.status === 401) {
        dispatch(getTimezonesList())
      } else {
        dispatch(openSnackbar('error', 'Error while getting timezones'))
        return Promise.reject(e)
      }
    })
}

export const showNotFound = () => ({
  type: CONSTANTS.SHOW_NOT_FOUND,
})

export const hideNotFound = () => ({
  type: CONSTANTS.HIDE_NOT_FOUND,
})

export const toggleMobileHint = payload => ({
  type: CONSTANTS.TOGGLE_MOBILE_HINT,
  payload,
})

export const toggleLogin = payload => ({
  type: CONSTANTS.TOGGLE_LOGIN,
  payload,
})

export const toggleSignUp = payload => ({
  type: CONSTANTS.TOGGLE_SIGNUP,
  payload,
})

export const toggleRestorePass = payload => ({
  type: CONSTANTS.TOGGLE_RESTORE,
  payload,
})

export const toggleForgotPassEmail = payload => ({
  type: CONSTANTS.TOGGLE_FORGOT_PASS_EMAIL,
  payload,
})

export const setRedirectPath = payload => ({
  type: CONSTANTS.SET_REDIRECT,
  payload,
})

export const exportFileStart = () => ({
  type: CONSTANTS.EXPORT_FILE_START,
})

export const exportFileEnd = () => ({
  type: CONSTANTS.EXPORT_FILE_END,
})

export const exportFile = (data, filename, mime, bom) => dispatch => {
  dispatch(exportFileStart())
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data]
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()

    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(blobURL)
    }, 0)
  }
  dispatch(exportFileEnd())
  return Promise.resolve()
}

export const validateClientInvite = hash => dispatch => {
  return axios
    .post(getClientInviteValidationPath(), {
      hash: hash,
    })
    .then(response => {
      return response.data.user_exists
    })
    .catch(error => {
      const errorMessage =
        (error.response && error.response.data && error.response.data.detail) || 'You have invalid registration link'
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject(error)
    })
}

export const submitInvitationFlow = hash => dispatch => {
  return axios
    .post(getClientInviteSubmissionPath(), {
      hash: hash,
    })
    .then(response => response.data)
    .catch(error => {
      const errorMessage =
        (error.response && error.response.data && error.response.data.detail) ||
        'There was some server error during creating connection'
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject(error)
    })
}

export const showFilter = payload => ({
  type: CONSTANTS.SHOW_FILTER,
  payload,
})

export const showSort = payload => ({
  type: CONSTANTS.SHOW_SORT,
  payload,
})

export const setCalendarTab = payload => ({
  type: CONSTANTS.SET_CALENDAR_TAB,
  payload,
})

export const saveCalendarTab = calendarTab => dispatch => {
  dispatch(setCalendarTab(calendarTab))
  return axios
    .post(getPortalStatePath('calendarTab'), {
      key: 'calendarTab',
      state: calendarTab,
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error)
    })
}

export const getCalendarTab = () => dispatch => {
  return axios
    .get(getPortalStatePath('calendarTab'))
    .then(response => dispatch(setCalendarTab(response.data.state)))
    .catch(error => {
      return Promise.reject(error)
    })
}

export const openMobileMenu = () => ({
  type: CONSTANTS.SET_MOBILE_MENU_OPEN,
})

export const closeMobileMenu = () => ({
  type: CONSTANTS.SET_MOBILE_MENU_CLOSE,
})

export const goToZendesk = (mail, redirect) => dispatch => {
  let params = {}
  if (redirect) {
    params = {
      redirect: 'hc/en-us/requests/new',
    }
  }

  return axios
    .get(getZendeskSsoPath(), {
      params: params,
    })
    .then(response => {
      if (response && response.data) {
        if (mail) {
          dispatch(navigateToWindow(response.data.url))
        } else {
          dispatch(navigateToNewWindow(response.data.url))
        }
      }
    })
}

export const ticketUploadStart = () => ({
  type: CONSTANTS.TICKET_UPLOAD_START,
})

export const ticketUploadEnd = () => ({
  type: CONSTANTS.TICKET_UPLOAD_END,
})

export const submitTicket = data => dispatch => {
  dispatch(ticketUploadStart())

  const payload = new FormData()
  payload.append('first_name', data.first_name)
  payload.append('email', data.email)
  payload.append('last_name', data.last_name)
  payload.append('subject', data.subject)
  payload.append('description', data.description)

  return axios
    .post(getZendeskTicketsPath(), payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      dispatch(ticketUploadEnd())
      dispatch(openSnackbar('success', 'Ticket successfully submitted'))
      return response.data
    })
    .catch(e => {
      const message =
        (e.response && e.response.data && e.response.data.detail) || 'Error was occurred while creating Zendesk ticket'
      dispatch(ticketUploadEnd())
      dispatch(openSnackbar('error', message))
      return Promise.reject(e)
    })
}

export const setUsernameForResendPassword = payload => ({
  type: CONSTANTS.SET_USERNAME_FOR_RESEND_PASSWORD,
  payload,
})

export const showAdminMessageModal = payload => ({
  type: CONSTANTS.OPEN_ADMIN_MESSAGE_MODAL,
  payload,
})

export const closeAdminMessageModal = () => ({
  type: CONSTANTS.CLOSE_ADMIN_MESSAGE_MODAL,
})
