import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { ColorCircularProgress } from 'components'
import { isEmpty } from 'lodash'

const locationHelper = locationHelperBuilder({})

const proIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.auth.currentUser && state.auth.currentUser.role === 'pro',
  authenticatingSelector: state => state.auth.isUserLogginingIn,
  wrapperDisplayName: 'ProIsAuthenticated',
}

const clientIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.auth.currentUser && state.auth.currentUser.role === 'client',
  authenticatingSelector: state => state.auth.isUserLogginingIn,
  wrapperDisplayName: 'ClientIsAuthenticated',
}

const profileIsCreatedDefaults = {
  authenticatedSelector: state => state.profileCreation.profileData.currentStep !== 0,
  authenticatingSelector: state => state.auth.isProfileGetting,
  wrapperDisplayName: 'ProfileIsCreated',
}

const profileIsApprovedDefaults = {
  authenticatedSelector: state => state.accountProfile.isProfileApproved,
  authenticatingSelector: state => state.accountProfile.profileIsLoading,
  wrapperDisplayName: 'ProfileIsApproved',
}

const searchIsAvailableDefaults = {
  authenticatedSelector: state => isEmpty(state.auth.currentUser) || state.auth.currentUser.role === 'client',
  authenticatingSelector: state => state.auth.isUserLogginingIn,
  wrapperDisplayName: 'SearchIsAvailable',
}

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state =>
    state.auth.currentUser && (state.auth.currentUser.role === 'client' || state.auth.currentUser.role === 'pro'),
  authenticatingSelector: state => state.auth.isUserLogginingIn,
  wrapperDisplayName: 'UserIsAuthenticated',
}

export const proIsAuthenticatedRedir = connectedRouterRedirect({
  ...proIsAuthenticatedDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/login',
})

export const clientIsAuthenticatedRedir = connectedRouterRedirect({
  ...clientIsAuthenticatedDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/login',
})

export const profileIsCreatedRedir = connectedRouterRedirect({
  ...profileIsCreatedDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/pro/account/events',
})
export const profileIsApprovedActivityRedir = connectedRouterRedirect({
  ...profileIsApprovedDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/pro/account/activities',
})

export const profileIsApprovedCasesRedir = connectedRouterRedirect({
  ...profileIsApprovedDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/pro/account/cases',
})

export const searchIsAvailableRedir = connectedRouterRedirect({
  ...searchIsAvailableDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/pro/account/events',
})

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: ColorCircularProgress,
  redirectPath: '/login',
})

const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: state => isEmpty(state.auth.currentUser) && state.auth.isUserLogginingIn === false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
}

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || ownProps.location.pathname,
  allowRedirectBack: false,
})
