import * as CONSTANTS from 'store/constants/proReview/proReview'
import axios from 'axios'
import {
  getClientReviewsPath,
  getCreateProReplyPath,
  getProReviewsPath,
  getUpdateProReplyPath,
} from 'utils/path-helpers/api/proReviews/proReviews'
import { openSnackbar } from '../common'

export const proReviewModalOpen = () => ({
  type: CONSTANTS.SET_PRO_REVIEW_MODAL_OPEN,
})

export const proReviewModalClose = () => ({
  type: CONSTANTS.SET_PRO_REVIEW_MODAL_CLOSE,
})

export const proProCommentReviewed = () => ({
  type: CONSTANTS.SET_PRO_COMMENT_REVIEWED,
})

const getProReviewsStart = () => ({
  type: CONSTANTS.GET_PRO_REVIEW_START,
})

export const getProReviews = proID => async (dispatch, getState) => {
  dispatch(getProReviewsStart())
  const { proReviewCommentsSort, proReviewCommentsFilter } = getState().proReview
  const path = proID ? getClientReviewsPath(proID) : getProReviewsPath()
  try {
    const { data } = await axios.get(path, {
      params: {
        ordering: proReviewCommentsSort.property
          ? `${proReviewCommentsSort.isAsc ? '' : '-'}${proReviewCommentsSort.property}`
          : null,
        rating: proReviewCommentsFilter,
      },
    })
    dispatch(getProReviewsEnd(data.results, data.statistic, data.total_count))
  } catch (e) {
    dispatch(getProReviewsEnd([], null, 0))
    dispatch(openSnackbar('error', 'Error while getting review comments'))
  }
}

const getProReviewsEnd = (comments, stats, count) => ({
  type: CONSTANTS.GET_PRO_REVIEW_END,
  proReviewComments: comments,
  proReviewStats: stats,
  proReviewCommentsCount: count,
})

export const setReviewCommentsSorting = (property, isAsc) => ({
  type: CONSTANTS.SET_PRO_REVIEW_SORTING,
  property: property,
  isAsc: isAsc,
})

export const setReviewCommentsFilter = filterValue => ({
  type: CONSTANTS.SET_PRO_REVIEW_FILTER,
  payload: filterValue,
})

export const createProReply = (commentID, replyContent) => async dispatch => {
  try {
    await axios.post(getCreateProReplyPath(), {
      user_questionnaire_comment: commentID,
      content: replyContent,
    })
    dispatch(getProReviews())
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while creating reply'))
  }
}

export const editProReply = (replyID, replyContent) => async dispatch => {
  try {
    await axios.put(getUpdateProReplyPath(replyID), {
      content: replyContent,
    })
    dispatch(getProReviews())
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while editing reply'))
  }
}

export const deleteProReply = replyID => async dispatch => {
  try {
    await axios.delete(getUpdateProReplyPath(replyID))
    dispatch(getProReviews())
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while deleting reply'))
  }
}
