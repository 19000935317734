import React from 'react'
import { Typography, CalendarList, Button, ColorCircularProgress, XIRACalendarSettings } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  stepperBodyContainer: {
    padding: 24,
  },
  bodySubtitle: {
    fontSize: 15,
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: 25,
    marginRight: 25,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const CalendarStepperBody = ({
  activeStep,
  availableForConnectionCalendars,
  setSelectedCalendar,
  setActiveStep,
  selectedCalendarList,
  closeModal,
  isCalendarLoading,
  removeSelectedCalendar,
  onNextClick,
  localXiraCalendar,
}) => {
  const classes = useStyles()
  const isRefreshingConnectedCalendars = useSelector(state => state.calendar.isRefreshingConnectedCalendars)
  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <div>
            <Typography variant="h4">select calendar</Typography>
            <Typography variant="subtitle1" className={classes.bodySubtitle}>
              Connect your calendar to let XIRA know when you're available and update your calendar as events are
              scheduled.
            </Typography>
            {availableForConnectionCalendars && (
              <CalendarList
                mode="connect"
                isCalendarLoading={isCalendarLoading}
                calendarsList={availableForConnectionCalendars}
                selectedCalendarList={selectedCalendarList}
                setCalendar={setSelectedCalendar}
                removeSelectedCalendar={removeSelectedCalendar}
                changeStep={() => setActiveStep(1)}
              />
            )}
            <div className={classes.btnContainer}>
              <Button className={classes.button} variant="text" onClick={closeModal}>
                cancel
              </Button>
              <Button
                className={classes.button}
                variant="primary"
                disabled={!selectedCalendarList.length}
                onClick={() => onNextClick(0)}
              >
                continue
              </Button>
            </div>
          </div>
        )
      case 1:
        return (
          <>
            {isRefreshingConnectedCalendars ? (
              <ColorCircularProgress />
            ) : (
              <div>
                <XIRACalendarSettings />
                <div className={classes.btnContainer}>
                  <Button className={classes.button} variant="text" onClick={closeModal}>
                    cancel
                  </Button>
                  <Button
                    className={classes.button}
                    variant="primary"
                    disabled={!localXiraCalendar.id}
                    onClick={() => onNextClick(1)}
                  >
                    complete
                  </Button>
                </div>
              </div>
            )}
          </>
        )
      default:
        return <div />
    }
  }

  return <div className={classes.stepperBodyContainer}>{getStepContent(activeStep)}</div>
}

export default CalendarStepperBody
