import React from 'react'
import { Route } from 'react-router-dom'
import {
  ClientCasesContainer,
  ClientDashboardContainer,
  ClientEventRescheduleContainer,
  ClientInvoicesContainer,
  ClientViewInvoicesContainer,
  ClientConnectionsContainer,
  ClientProfileContainer,
} from 'containers'
import { CancelEventModal } from 'components'
import { navigateToClientDashboard } from 'store/actions/navigation'
import { useDispatch } from 'react-redux'
import { getClientEvents, resetEventsUploadOffset } from 'store/actions/events'

const ClientAccountPageContent = ({ executeScroll }) => {
  const dispatch = useDispatch()

  const onModalConfirm = () => {
    dispatch(navigateToClientDashboard())
    dispatch(resetEventsUploadOffset())
    dispatch(getClientEvents())
  }

  const onModalClose = () => {
    dispatch(navigateToClientDashboard())
  }

  return (
    <>
      <Route
        exact
        path={['/client/account/events', '/client/account/events/:uuid/cancel']}
        component={ClientDashboardContainer}
      />
      <Route
        path="/client/account/events/:uuid/cancel"
        component={props => (
          <CancelEventModal
            onModalClose={onModalClose}
            onModalConfirm={onModalConfirm}
            eventId={props.match.params.uuid}
          />
        )}
      />
      <Route
        path={[
          '/client/account/profile',
          '/client/account/settings',
          '/client/account/notifications',
          '/client/account/payments',
        ]}
        render={() => <ClientProfileContainer executeScroll={executeScroll} />}
      />
      <Route path="/client/account/events/:uuid/reschedule" component={ClientEventRescheduleContainer} />
      <Route path="/client/account/invoices" exact component={ClientInvoicesContainer} />
      <Route path="/client/account/invoices/view/:invoiceId" component={ClientViewInvoicesContainer} />
      <Route path="/client/account/connections" component={ClientConnectionsContainer} />
      <Route path="/client/account/cases" component={ClientCasesContainer} />
    </>
  )
}

export default ClientAccountPageContent
