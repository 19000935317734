import * as CONSTANTS from 'store/constants/payments'

const initialState = {
  clientSecretIsLoading: false,
  clientPaymentSecretKey: '',
  cardsAreLoading: false,
  userCards: [],
  cardsAreSaving: false,
}

const payments = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_CLIENT_PAYMENT_SECRET_START:
      return {
        ...state,
        clientSecretIsLoading: true,
      }
    case CONSTANTS.GET_CLIENT_PAYMENT_SECRET_END:
      return {
        ...state,
        clientSecretIsLoading: false,
        clientPaymentSecretKey: action.payload,
      }
    case CONSTANTS.GET_USER_CARDS_START:
      return {
        ...state,
        cardsAreLoading: true,
      }
    case CONSTANTS.GET_USER_CARDS_END:
      return {
        ...state,
        cardsAreLoading: false,
        userCards: action.payload,
      }
    case CONSTANTS.SAVE_USER_CARD_START:
      return {
        ...state,
        cardsAreSaving: true,
      }
    case CONSTANTS.SAVE_USER_CARD_END:
      return {
        ...state,
        cardsAreSaving: false,
      }
    case CONSTANTS.UPDATE_USER_CARD_START:
      return {
        ...state,
        cardsAreSaving: false,
      }
    case CONSTANTS.UPDATE_USER_CARD_END:
      return {
        ...state,
        cardsAreSaving: true,
      }
    case CONSTANTS.DELETE_USER_CARD_START:
      return {
        ...state,
        cardsAreSaving: false,
      }
    case CONSTANTS.DELETE_USER_CARD_END:
      return {
        ...state,
        cardsAreSaving: true,
      }
    case CONSTANTS.GET_USER_CARD_START:
      return {
        ...state,
        cardsAreSaving: false,
      }
    case CONSTANTS.GET_USER_CARD_END:
      return {
        ...state,
        cardsAreSaving: true,
      }
    default:
      return state
  }
}

export default payments
