import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, FormikInput, Modal, Typography, FormTextArea } from 'components'
import { ButtonGroup } from '@material-ui/core'
import { recordTransaction } from 'store/actions/invoices'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { REGEXP } from 'constants/index'
import { formatDecimal } from 'utils/getters/common'

const useStyles = makeStyles({
  createCreditAdjustmentContainer: {
    width: 440,
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 40px',
  },
  createCreditAdjustmentTitle: {
    fontSize: 25,
    fontWeight: 500,
    marginBottom: 21,
  },
  transactionFirstRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  invoiceGroup: {
    width: '45%',
  },
  invoiceFieldLabel: {
    fontWeight: 'normal',
    marginBottom: 3,
    color: colors.BASIC.COLOR[50],
  },
  transactionLabel: {},
  invoiceFieldValue: {
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 16,
  },
  title: {
    fontWeight: 500,
  },
  buttonGroup: {
    height: 32,
    marginBottom: 16,
    marginTop: 5,
  },
  btnRecordType: {
    width: 120,
    backgroundColor: '#EBEEF5',
    color: colors.BASIC.COLOR[50],
    borderColor: `${colors.WHITE}!important`,
    fontSize: 13,
    fontWeight: 'normal',
    textTransform: 'none',
  },
  btnSelectedType: {
    backgroundColor: colors.ADDITIONAL.COLOR[50],
    color: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.ADDITIONAL.COLOR[50],
    },
  },
  textArea: {
    width: '100%',
    marginBottom: 16,
  },
  paymentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  btn: {
    height: 40,
    minHeight: 40,
    marginLeft: 15,
  },
})

const RecordTransactionModal = ({ open, onClose, invoice, viewType, payment }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { status } = invoice
  const getDefaultType = () => {
    if (payment) {
      return payment.type
    } else {
      return status === 2 ? 'C' : 'P'
    }
  }
  const defaultType = getDefaultType()
  const [selectedType, setSelectedType] = useState(defaultType)
  const getTransactionType = type => {
    switch (type) {
      case 'P':
        return 'Payment'
      case 'C':
        return 'Credit'
      case 'A':
        return 'Debit'
      default:
        return 'Payment'
    }
  }
  const getDescription = type => {
    if (payment) {
      return payment.description
    } else {
      return getTransactionType(type)
    }
  }
  const getAmount = () => {
    if (payment) {
      return payment.amount
    } else {
      return selectedType === 'P' ? invoice.amountDue : ''
    }
  }
  const getAmountValidation = () => {
    if (payment) {
      return Yup.number()
        .typeError('Credit amount should be a valid number')
        .min(0, 'Credit amount should be more or equal zero')
        .required('please fill out this field')
        .test('regex', 'please enter a valid credit amount', val => REGEXP.decimal.test(val))
    } else {
      return Yup.number()
        .typeError('Credit amount should be a valid number')
        .positive('Credit amount should be more than zero')
        .required('please fill out this field')
        .test('regex', 'please enter a valid credit amount', val => REGEXP.decimal.test(val))
    }
  }
  const initialValues = {
    amount: getAmount(),
    viewType: viewType,
    invoiceId: invoice && invoice.uuid,
    description: getDescription(selectedType),
    paymentId: payment && payment.uuid,
  }
  const validationSchema = Yup.object({
    amount: getAmountValidation(),
    invoiceId: Yup.string(),
    description: Yup.string().max(150),
  })

  const transactionBtns = [
    {
      label: 'payment',
      type: 'P',
      disabled: status === 2,
    },
    {
      label: 'credit',
      type: 'C',
    },
    {
      label: 'debit',
      type: 'A',
      disabled: status === 2,
    },
  ]

  const onChangeType = (btn, props) => {
    props.setFieldValue('description', getDescription(btn.type))
    setSelectedType(btn.type)
  }

  const onSubmit = values => {
    dispatch(recordTransaction({ ...values, type: selectedType }))
    onClose()
  }

  const getTransactionModalTitle = () => {
    return payment ? 'Edit transaction' : 'Record transaction'
  }

  return (
    <>
      <Modal open={open} handleClose={onClose} scroll="body">
        <div className={classes.createCreditAdjustmentContainer}>
          <Typography className={classes.createCreditAdjustmentTitle}>{getTransactionModalTitle()}</Typography>
          <div className={classes.transactionFirstRow}>
            <div className={classes.invoiceGroup}>
              <Typography className={classes.invoiceFieldLabel}>Invoice number</Typography>
              <Typography className={classes.invoiceFieldValue}>{invoice && invoice.invoiceNumber}</Typography>
            </div>
            <div className={classes.invoiceGroup}>
              <Typography className={classes.invoiceFieldLabel}>Client</Typography>
              <Typography className={classes.invoiceFieldValue}>{invoice && invoice.clientFullName}</Typography>
            </div>
          </div>
          <Typography className={classes.invoiceFieldLabel}>Case</Typography>
          <Typography className={classes.invoiceFieldValue}>{invoice && invoice.caseName}</Typography>
          {payment ? (
            <>
              <Typography className={classes.invoiceFieldLabel}>Transaction type</Typography>
              <Typography className={classes.invoiceFieldValue}>{getTransactionType(payment.type)}</Typography>
            </>
          ) : (
            <Typography className={classes.title}>Transaction type</Typography>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {props => (
              <>
                {!payment && (
                  <ButtonGroup className={classes.buttonGroup}>
                    {transactionBtns.map(btn => (
                      <Button
                        disabled={btn.disabled}
                        className={classNames({
                          [classes.btnRecordType]: true,
                          [classes.btnSelectedType]: selectedType === btn.type,
                        })}
                        onClick={() => onChangeType(btn, props)}
                        variant="secondary"
                      >
                        {btn.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                )}
                <Form>
                  <FormikInput
                    variant="outlined"
                    name="amount"
                    label="Amount"
                    inputProps={{ style: { textAlign: 'right' } }}
                    handleBlur={e => props.setFieldValue('amount', formatDecimal(e.target.value))}
                  />
                  <Typography className={classes.title}>Description</Typography>
                  <FormTextArea
                    onChange={props.setFieldValue}
                    value={props.values.description}
                    name="description"
                    areaWrapperClass={classes.textArea}
                    rowsMin={2}
                    characterLimit={150}
                  />
                  <div className={classes.paymentRow}>
                    <Button variant="text" onClick={onClose} className={classes.btn}>
                      cancel
                    </Button>
                    <Button variant="primary" type="submit" className={classes.btn}>
                      done
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default RecordTransactionModal
