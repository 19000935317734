import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  typingIndicator: {
    backgroundColor: colors.ADDITIONAL.COLOR['0'],
    width: 46,
    borderRadius: '30%',
    borderTopLeftRadius: 0,
    padding: 8,
    animation: '2s $pulse infinite ease-out',
    marginBottom: 2,
    display: 'flex',
    justifyContent: 'space-around',
    '& > span': {
      height: 6,
      width: 6,
      backgroundColor: colors.ADDITIONAL.COLOR['30'],
      display: 'block',
      borderRadius: '50%',
      opacity: 0.4,
      '&:nth-child(1)': {
        animation: '1s $blink infinite .3333s',
      },
      '&:nth-child(2)': {
        animation: '1s $blink infinite .6666s',
      },
      '&:nth-child(3)': {
        animation: '1s $blink infinite .9999s',
      },
    },
  },
  name: {
    fontSize: 9,
    color: colors.BASIC.COLOR['50'],
  },
  '@keyframes blink': {
    '50%': {
      opacity: 1,
    },
  },
  '@keyframes pulse': {
    '50%': {
      opacity: 'scale(1.05)',
    },
  },
})

const TypingIndicator = props => {
  const classes = useStyles()
  const { channel, selectedChat } = props
  const [typingIndicator, setTypingIndicator] = useState({ member: '', typing: false })

  useEffect(() => {
    if (channel) {
      channel.on('typingStarted', member => {
        setTypingIndicator({ member: member, typing: true })
      })
      channel.on('typingEnded', member => {
        setTypingIndicator({ member: member, typing: false })
      })
    }
  }, [channel])

  return (
    typingIndicator.typing && (
      <div className={classes.container}>
        <div className={classes.typingIndicator}>
          <span />
          <span />
          <span />
        </div>
        <p className={classes.name}>{selectedChat.chat_member_full_name} is typing...</p>
      </div>
    )
  )
}

export default TypingIndicator
