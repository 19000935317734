import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserPassword } from 'store/actions/auth'
import { ChangePasswordForm } from 'containers'
import DeleteClientAccount from './DeleteClientAccount'
import {
  sendDeleteProfileRequest,
  getDeletionRequestInfoEnd,
  cancelAccountDeletion,
} from 'store/actions/accountProfile'
import { openSnackbar } from 'store/actions/common'

const useStyles = makeStyles({
  profileContentHeader: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  changePassword: {
    maxWidth: 840,
  },
})

const ClientSettings = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isPasswordChanging = useSelector(state => state.auth.isChangingPassword)
  const deletionRequestUuid = useSelector(state => state.accountProfile.deletionRequestUuid)
  const deleteRequestIsloading = useSelector(state => state.accountProfile.deleteRequestIsloading)

  const onChangePassword = passwords => {
    if (isPasswordChanging) {
      return
    }
    dispatch(updateUserPassword(passwords))
  }

  const requestDelete = () => {
    return dispatch(sendDeleteProfileRequest())
  }

  const setDeleteRequestUuid = uuid => {
    dispatch(getDeletionRequestInfoEnd(uuid))
  }

  const showSnackbar = (type, text) => {
    dispatch(openSnackbar(type, text))
  }

  const onCancelDeletion = () => {
    dispatch(cancelAccountDeletion())
  }

  return (
    <div className={classes.changePassword}>
      <div className={classes.profileContentHeader}>Change Password</div>
      <ChangePasswordForm changePassword={onChangePassword} isPasswordChanging={isPasswordChanging} isClient />
      {!deleteRequestIsloading && (
        <DeleteClientAccount
          requestDelete={requestDelete}
          setRequestUuid={setDeleteRequestUuid}
          showSnackbar={showSnackbar}
          deletionRequestUuid={deletionRequestUuid}
          onCancelDeletion={onCancelDeletion}
        />
      )}
    </div>
  )
}

export default ClientSettings
