import React from 'react'
import { colors, CONSTANTS } from '../../constants'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { Tooltip, Typography } from '../index'

const useStyles = makeStyles({
  statusText: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'upperCase',
  },
  issuedStatus: {
    color: colors.PRIMARY.COLOR[40],
  },
  paidStatus: {
    color: colors.SUCCESS,
  },
  failedStatus: {
    color: colors.ERROR,
  },
  writeOffStatus: {
    color: colors.BASIC.COLOR[60],
  },
  statusTooltip: {
    fontSize: 13,
    fontWeight: 'normal',
    color: colors.WHITE,
    backgroundColor: colors.BASIC.COLOR['80'],
  },
})

const InvoiceStatus = ({ status, title, clientStatus }) => {
  const classes = useStyles()
  const isIssued =
    status === CONSTANTS.INVOICE_TYPE_BY_LABEl.ISSUED || status === CONSTANTS.INVOICE_TYPE_BY_LABEl.PARTIAL
  const isPaid = status === CONSTANTS.INVOICE_TYPE_BY_LABEl.PAID || status === CONSTANTS.INVOICE_TYPE_BY_LABEl.EPAID
  return (
    <Tooltip title={title || CONSTANTS.TOOLTIP_TEXT_BY_INVOICE[status]} tooltipClass={classes.statusTooltip}>
      <Typography
        variant="body2"
        classes={{
          root: classNames(
            classes.statusText,
            {
              [classes.issuedStatus]: isIssued,
            },
            {
              [classes.paidStatus]: isPaid,
            },
            { [classes.failedStatus]: status === CONSTANTS.INVOICE_TYPE_BY_LABEl.FAILED },
            { [classes.writeOffStatus]: status === CONSTANTS.INVOICE_TYPE_BY_LABEl.WRITE_OFF }
          ),
        }}
      >
        {clientStatus
          ? CONSTANTS.CLIENT_INVOICE_TYPE_BY_CODE[status].label
          : CONSTANTS.INVOICE_TYPE_BY_CODE[status].statusLabel || CONSTANTS.INVOICE_TYPE_BY_CODE[status].label}
      </Typography>
    </Tooltip>
  )
}

export default InvoiceStatus
