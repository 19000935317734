import React from 'react'
import { Autocomplete, SvgIcon } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { setAdvancedCalendar } from 'store/actions/calendar'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const TIMEZONE = 'timezone'
const COUNTRY = 'country'

const useStyles = makeStyles({
  selectIcon: {
    color: colors.BASIC.COLOR[20],
  },
})

const TimezoneSetup = ({ handleLoading }) => {
  const classes = useStyles()
  const timezonesList = useSelector(state => state.common.timezonesList)
  const userTimezone = useSelector(state => state.calendar.advanced_calendar[TIMEZONE])
  const dispatch = useDispatch()

  const onSelectChange = (e, value) => {
    if (!value) return
    handleLoading(true)
    dispatch(setAdvancedCalendar({ [TIMEZONE]: value.value})).then(() => handleLoading(false))
  }

  return (
    <Autocomplete
      fullWidth
      variant="outlined"
      options={timezonesList.map(timezone => ({value: timezone.timezone, label: timezone.label}))}
      groupBy={option => option[COUNTRY]}
      value={userTimezone}
      onChange={onSelectChange}
      popupIcon={<SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.selectIcon} />}
    />
  )
}

export default TimezoneSetup
