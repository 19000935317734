import React, { useState, useEffect } from 'react'
import {
  Breadcrumbs,
  Button,
  Typography,
  ColorCircularProgress,
  InvoiceDocument,
  InvoiceDetails,
  Link,
  InvoiceStatus,
  Menu,
  MenuItem,
  ActionRow,
  ConfirmationModal,
} from 'components'
import InvoiceSummary from 'components/invoices/pdf/summaryPDF'
import classNames from 'classnames'
import { colors, CONSTANTS, routes, tooltip } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { getProInvoice, setInvoiceStatus } from 'store/actions/invoices'
import { makeStyles } from '@material-ui/core/styles'
import * as moment from 'moment'
import RecordTransactionModal from './RecordTransactionModal'
import { ReactComponent as ActionsIcon } from 'static/vault/header/chevron-down.svg'
import { pdf } from '@react-pdf/renderer'
import PDFMerger from 'pdf-merger-js/browser'
import { exportFile } from 'store/actions/common'
import InvoicePaymentsCredits from '../../components/invoices/pdf/paymentsCredits'

const useStyles = makeStyles({
  viewInvoiceContainer: {
    padding: '24px 30px 24px 0',
    height: '100%',
  },
  link: {
    color: colors.BASIC.COLOR[20],
    textDecoration: 'none',
  },
  breadcrumbs: {
    fontWeight: 500,
    fontSize: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginRight: 24,
  },
  invoiceTitle: {
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 8,
    display: 'inline',
  },
  viewInvoiceHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnContainer: {
    display: 'flex',
    padding: '0 24px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 16,
  },
  btn: {
    padding: '10px 24px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  viewInvoiceBody: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  status: {
    color: colors.PRIMARY.COLOR[50],
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  anchorLink: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  actionItem: {
    backgroundColor: colors.PRIMARY.COLOR['0'],
    padding: '6px 12px',
    height: 32,
  },
})

const ProViewInvoicesContainer = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const invoice = useSelector(state => state.invoices.invoice)
  const invoiceSummary = useSelector(state => state.invoices.invoiceSummary)
  const invoiceIsLoading = useSelector(state => state.invoices.invoiceIsLoading)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const invoiceId = match.params.invoiceId
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const [showWriteOffModal, setShowWriteOffModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const recordTransactionAvailable = invoice.isPaymentRecordAvailable
  const writeOffAvailable = invoice.isWriteOffAvailable

  const fileName = `Invoice ${invoice.invoiceNumber} ${moment().format(CONSTANTS.DATE_FORMAT.PDF_DATE)}.pdf`

  useEffect(() => {
    dispatch(getProInvoice(invoiceId))
  }, [invoiceId])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getMenuAction = () => {
    const recordTransaction = recordTransactionAvailable
      ? [
          {
            key: 'transaction',
            label: 'record transaction',
            action: () => {
              setShowTransactionModal(true)
              handleClose()
            },
          },
        ]
      : []
    const writeOffAction = writeOffAvailable
      ? [
          {
            key: 'write',
            label: 'write-off',
            tooltipText: tooltip.writeOffTooltipText,
            action: () => {
              setShowWriteOffModal(true)
            },
          },
        ]
      : []
    const common = [
      {
        label: 'export as PDF',
        key: 'export',
        action: async () => {
          if (!invoice.file_link) {
            const blob = await pdf(
              <InvoiceDocument timezone={timezone} download invoice={invoice} summary={invoiceSummary} />
            ).toBlob()
            dispatch(exportFile(blob, fileName, 'application/pdf'))
          } else {
            try {
              const summaryBlob = await pdf(
                <InvoiceSummary timezone={timezone} invoice={invoice} summary={invoiceSummary} />
              ).toBlob()
              const paymentsBlob = await pdf(
                <InvoicePaymentsCredits timezone={timezone} invoice={invoice} summary={invoiceSummary} />
              ).toBlob()

              const pdfFile = await fetch(invoice.file_link).then(res => res.blob())
              const pdfBlob = new Blob([pdfFile], { type: 'application/pdf' })

              let merger = new PDFMerger()
              await Promise.all([summaryBlob, pdfBlob, paymentsBlob].map(async file => await merger.add(file, null)))

              const mergedPdf = await merger.saveAsBlob()
              dispatch(exportFile(mergedPdf, fileName, 'application/pdf'))
              merger = null
            } catch (err) {
              console.log(err)
            }
          }
        },
      },
    ]
    return [...recordTransaction, ...writeOffAction, ...common]
  }

  const sendWriteOff = () => {
    dispatch(setInvoiceStatus(invoice.uuid, CONSTANTS.INVOICE_TYPE_BY_LABEl.WRITE_OFF))
    dispatch(getProInvoice(invoice.uuid))
    setShowWriteOffModal(false)
  }

  const getWriteOffMessage = () =>
    'Continue if you have performed legal services for a client forwhich you have not been/will not be compensated for despite all attempts to resolve with the client. Marking an invoice as “write-off” is not visible to your client. If the client does pay the invoice, you will need to manually change the status to “paid”.'

  return (
    <>
      {invoiceIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <div className={classes.viewInvoiceContainer}>
          <Breadcrumbs className={classes.standardMargin}>
            <Link
              className={classNames(classes.link, classes.breadcrumbs)}
              component={RouterLink}
              to={routes.getProAccountFinancesGeneralPath()}
            >
              invoices
            </Link>
            <Typography className={classes.breadcrumbs} variant="body1">
              invoice {invoice.invoiceNumber} details
            </Typography>
          </Breadcrumbs>
          <div className={classes.viewInvoiceHeader}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title} variant="h2">
                Invoice details {invoice.invoiceNumber}
              </Typography>
              <InvoiceStatus status={invoice.status || 0} />
            </div>
            <Button variant={'secondary'} className={classes.actionItem} onClick={handleClick}>
              actions <ActionsIcon className={classes.actionIcon} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              {getMenuAction().map(action => (
                <MenuItem key={action.key} onClick={action.action}>
                  <ActionRow title={action.label} tooltip={action.tooltipText} />
                </MenuItem>
              ))}
            </Menu>
          </div>
          <InvoiceDetails invoice={invoice} invoiceSummary={invoiceSummary} />
          {showTransactionModal && (
            <RecordTransactionModal
              open={showTransactionModal}
              viewType="view"
              invoice={invoice}
              onClose={() => setShowTransactionModal(false)}
            />
          )}
          <ConfirmationModal
            title="Change the status of the invoice to “write-off”?"
            message={getWriteOffMessage()}
            isOpen={showWriteOffModal}
            onClose={() => setShowWriteOffModal(false)}
            onConfirm={sendWriteOff}
          />
        </div>
      )}
    </>
  )
}

export default ProViewInvoicesContainer
