import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from 'components'
import { ReactComponent as PlayCircle } from 'static/play-circle.svg'
import { colors } from 'constants/index'
import { ReactComponent as PlayButton } from 'static/icon_play-button.svg'

const useStyles = makeStyles({
  shroud: {
    display: 'none',
    position: 'absolute',
    top: 0,
    bottom: 4,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(38, 38, 37, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tile: {
    width: 320,
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: colors.WHITE,
      boxShadow: `0 2px 12px 0 ${colors.BASIC.COLOR[20]}`,
      '& $shroud': {
        display: 'flex',
      },
    },
  },
  videoThumbnail: {
    width: '100%',
  },
  content: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    display: 'block',
    marginBottom: 8,
  },
  description: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.2,
    display: 'block',
    marginBottom: 24,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    height: 'auto',
    padding: '7px 16px',
    fontSize: 11,
    lineHeight: 1,
  },
  thumbnailContainer: {
    position: 'relative',
  },
  duration: {
    position: 'absolute',
    right: 8,
    bottom: 12,
    padding: '2px 8px',
    backgroundColor: colors.BASIC.COLOR[100],
    color: colors.WHITE,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 4,
    fontFamily: 'Roboto',
  },
  '@media (min-width: 1124px) and (max-width: 1325px)': {
    tile: {
      width: 'calc(100%/3 - 25px)',
    },
  },
  '@media (min-width: 768px) and (max-width: 1123px)': {
    tile: {
      width: 'calc(100%/2 - 25px)',
    },
  },
  '@media (max-width: 767px)': {
    tile: {
      width: 'calc(100% / 2 - 25px)',
      marginRight: 12,
    },
  },
  '@media (max-width: 691px)': {
    tile: {
      width: 'calc(100% - 20px)',
      marginRight: 12,
    },
  },
  '@media (max-width: 360px)': {
    tile: {
      width: 'calc(100% - 15px)',
    },
  },
})

const TutorialVideoTile = props => {
  const classes = useStyles()
  const { video, containerClass, onClick } = props

  const onTileClick = () => {
    onClick(video.id)
  }

  return (
    <div className={classNames({ [containerClass]: containerClass, [classes.tile]: true })} onClick={onTileClick}>
      <div className={classes.thumbnailContainer}>
        <img
          src={`https://img.youtube.com/vi/${video.id}/0.jpg`}
          alt={video.title}
          className={classes.videoThumbnail}
        />
        <div className={classes.duration}>{video.duration}</div>
        <div className={classes.shroud}>
          <PlayButton />
        </div>
      </div>

      <div className={classes.content}>
        <div>
          <Typography variant="h6" className={classes.title}>
            {video.title}
          </Typography>
          <Typography variant="h6" className={classes.description}>
            {video.description}
          </Typography>
        </div>

        <div className={classes.btnGroup}>
          <Button variant="secondary" startIcon={<PlayCircle />} className={classes.button} onClick={onTileClick}>
            Watch
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TutorialVideoTile
