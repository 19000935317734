import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {
  AppBar,
  Avatar,
  Button,
  Icon,
  Link,
  Menu,
  MenuItem,
  NavButton,
  SvgIcon,
  Typography,
  NotificationButton,
} from 'components'
import Logo from 'static/xira-L-W-A-360.gif'
import { ReactComponent as XiraLogoSvg } from 'static/Logo_Color_Primary.svg'
import { ReactComponent as LogoutIcon } from 'static/icon_24_ log-out.svg'
import { ReactComponent as ProfileIcon } from 'static/icon_24_ person.svg'
import { ReactComponent as PaymentIcon } from 'static/icon_24_ credit-card.svg'
import { ReactComponent as SettingsIcon } from 'static/icon_24_settings-2.svg'
import { ReactComponent as SearchIcon } from 'static/ic_search_32.svg'
import { ReactComponent as CloseIcon } from 'static/ic_close_32.svg'
import { ReactComponent as CloseIconMenu } from 'static/icon_24_close_black.svg'
import { ReactComponent as BurgerMenu } from 'static/ic_burger_menu.svg'
import { ReactComponent as SupportIcon } from 'static/header/icon _ 24 _ cover _ question-mark-circle.svg'
import { ReactComponent as SupportIconFilled } from 'static/header/icon  _24 _ fill _ question-mark-circle.svg'
import { colors } from 'constants/index'
import {
  navigateToAccountDashboard,
  navigateToClientConnections,
  navigateToClientDashboard,
  navigateToClientInvoices,
  navigateToClientPayments,
  navigateToClientProfile,
  navigateToClientSettings,
  navigateToWindow,
  navigateTo,
} from 'store/actions/navigation'
import { logOutUser } from 'store/actions/auth'
import { goToZendesk, toggleLogin, toggleSignUp } from 'store/actions/common'
import { withRouter } from 'react-router-dom'
import { routes } from '../../constants'
import Search from '../search/Search'

const useStyles = makeStyles({
  menuItem: {
    color: colors.BASIC.COLOR[80],
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
      fill: colors.PRIMARY.COLOR[50],
      backgroundColor: 'transparent',
    },
  },
  toolbarContainer: {
    paddingRight: '30px',
    display: 'flex',
  },
  button: {
    height: 32,
    marginTop: 9,
    marginLeft: 40,
    width: 109,
  },
  img: {
    width: '100%',
    height: '100%',
  },
  logoSvg: {
    fill: colors.WHITE,
  },

  clientAvatar: {
    color: colors.WHITE,
    backgroundColor: colors.BASIC.COLOR[80],
  },
  profileAvatar: {
    height: '48px',
    width: '48px',
    fontSize: '17px',
  },
  headerAvatar: {
    height: '32px',
    width: '32px',
    fontSize: '14px',
  },
  userName: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[80],
    marginRight: '10px',
  },
  userDetails: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
  },
  loginIcon: {
    fontSize: 20,
    marginLeft: 8,
  },
  popoverClasses: {
    // to make header interactive, popover backdrop needs to be lower
    zIndex: '1200 !important',
  },
  navButton: {
    padding: 4,
    marginLeft: 19,
    textTransform: 'lowercase',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  clientNavigation: {
    marginRight: 63,
  },
  menuItemContainer: {
    display: 'flex',
    padding: 5,
    width: '100%',
  },
  borderBottom: {
    paddingBottom: 20,
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  smallProfileContainer: {
    display: 'flex',
    width: '100%',
  },
  smallProfileInfoContainer: {
    marginLeft: 13,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  smallProfileInfoName: {
    marginBottom: 4,
    whiteSpace: 'normal',
  },
  menuItemIcon: {
    width: 18,
    height: 18,
    marginRight: 8,
    '&:hover': {
      fill: colors.PRIMARY.COLOR[50],
    },
  },
  menuItemTop: {
    marginTop: 10,
  },
  menu: {
    // left: 1680,
    boxShadow: '0 2px 16px 0 rgba(87, 87, 87, 0.2)',
    height: 'auto',
    width: 305,
    padding: '10px 20px',
  },
  label: {
    fontWeight: 500,
  },
  searchWrapperIcon: {
    display: 'none',
  },
  responsiveBurger: {
    display: 'none',
  },
  menuItemNavigation: {
    display: 'none',
  },
  menuCloseIcon: {
    display: 'none',
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 30,
  },
  reconnectBtn: {
    fontSize: 11,
    height: 22,
    minHeight: 22,
    fontWeight: 500,
    backgroundColor: colors.ERROR,
    padding: '4px 8px',
    marginRight: 27,
    textTransform: 'none',
  },
  supportIcon: {
    display: 'block',
  },
  supportIconFilled: {
    display: 'none',
  },
  growButton: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 14,
    textTransform: 'none',
    '@media(max-width:375px)': {
      lineHeight: 1.25,
    },
    '@media(max-width:280px)': {
      fontSize: 10,
    },
  },
  supportIconWrap: {
    width: 24,
    height: 24,
    marginRight: 24,
    cursor: 'pointer',
    '@media(max-width:999px)': {
      display: 'none',
    },
    '&:hover': {
      '& > $supportIcon': {
        display: 'none',
      },
      '& > $supportIconFilled': {
        display: 'block',
      },
    },
  },

  '@media(max-width:1279px)': {
    button: {
      marginLeft: 24,
    },
    toolbarContainer: {
      paddingRight: 19,
    },
  },
  wrapperIcon: {
    height: 20,
  },
  '@media(max-width:999px)': {
    hiddenClientAvatar: {
      display: 'none',
    },
    hiddenClientNavigation: {
      display: 'none',
    },
    responsiveBurger: {
      display: 'block',
      height: 44,
      width: 44,
    },
    hiddenMenuWrapper: {
      backgroundColor: 'rgba(38, 38, 37, 0.5)',
    },
    menu: {
      height: '100%',
      maxHeight: '100%',
      top: '0 !important',
      left: 'unset !important',
      right: 0,
      borderRadius: '4px 0 0 4px',
    },
    menuItemNavigation: {
      display: 'block',
    },
    menuCloseIcon: {
      display: 'block',
      position: 'absolute',
      padding: 0,
      top: -6,
      right: -16,
    },
  },
  '@media(max-width:767px)': {
    button: {
      marginLeft: 15,
    },
    navButton: {
      marginLeft: 0,
    },
    toolbarContainer: {
      paddingRight: 10,
    },
    responsiveIcon: {
      display: 'none',
    },
    searchMainWrapper: {
      flexGrow: 1,
      textAlign: 'right',
    },
    searchWrapperIcon: {
      display: 'inline-block',
    },
    searchButtonIcon: {
      minWidth: 40,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    mountSearchWrapper: {
      display: 'none',
    },
    openSearchWrapper: {
      display: 'block',
      position: 'absolute',
      top: 54,
      left: 0,
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
      padding: '20px 15px',
    },
    menu: {
      width: 255,
    },
  },
})

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const USER_TYPE = {
  PRO: 'pro',
  CLIENT: 'client',
}

const OPERATION_TYPE = {
  SIGN_UP: 'signUp',
  LOG_IN: 'logIn',
}

const dontShowSearchRoutesArray = [routes.LOGIN, routes.SIGN_UP, routes.VERIFY_EMAIL, routes.PASSWORD, routes.AI_CHAT]

const Header = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [operation, setOperation] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  const currentUser = useSelector(state => state.auth.currentUser)
  const isUserLogginingIn = useSelector(state => state.auth.isUserLogginingIn)
  const calendarsConnectionError = useSelector(state => state.calendar.calendarsConnectionError)
  const currentStep = useSelector(state => state.profileCreation.profileData.currentStep)

  const [openRespSearch, setOpenRespSearch] = React.useState(false)

  const closeMenu = () => {
    setOperation('')
    setAnchorEl(null)
  }

  const openMenu = (item, _anchorEl) => {
    setOperation(item)
    setAnchorEl(_anchorEl)
  }

  const onLogoutClick = () => {
    closeMenu()
    const link = window.location.origin.includes('law.xira') ? 'https://xira.com/' : ''
    dispatch(logOutUser(link))
  }

  const onLogoClick = () => {
    if (!isUserLoggedIn) {
      // dispatch(navigateToRoot())
      window.location.href = 'https://xira.com/'
    } else if (currentUser.role === USER_TYPE.PRO) {
      if (currentStep === 0) dispatch(navigateToAccountDashboard())
    } else {
      dispatch(navigateToClientDashboard())
    }
  }
  const onSupportIconClick = () => {
    dispatch(goToZendesk())
  }

  const onLogIn = () => {
    dispatch(toggleLogin(true))
  }

  const onSignUp = () => {
    dispatch(toggleSignUp(true))
  }

  const onReconnectClick = () => {
    dispatch(navigateTo('/pro/account/settings/connections'))
  }

  const returnToolbar = () => {
    if (isUserLoggedIn) {
      return (
        <div className={classes.toolBar}>
          {calendarsConnectionError && (
            <Button className={classes.reconnectBtn} onClick={onReconnectClick}>
              Your calendar is not connected and clients won’t be able to book time with you. Click to reconnect.
            </Button>
          )}
          <NotificationButton />
          <div className={classes.supportIconWrap} onClick={() => onSupportIconClick()}>
            <SvgIcon component={SupportIcon} viewBox="0 0 24 24" className={classes.supportIcon} />
            <SvgIcon component={SupportIconFilled} viewBox="0 0 24 24" className={classes.supportIconFilled} />
          </div>
          <div className={classes.userDetails} onClick={e => openMenu('userSettings', e.currentTarget)}>
            <SvgIcon viewBox="0 0 44 44" className={classes.responsiveBurger}>
              <BurgerMenu />
            </SvgIcon>
            <div className={classes.hiddenClientAvatar}>
              {currentUser.role === USER_TYPE.CLIENT ? (
                <>
                  <Avatar className={classNames(classes.clientAvatar, classes.headerAvatar)}>
                    {currentUser.first_name[0]}
                  </Avatar>
                </>
              ) : (
                <Avatar src={currentUser.photo} className={classes.headerAvatar}>
                  {currentUser.first_name && currentUser.last_name
                    ? `${currentUser.first_name[0]}${currentUser.last_name[0]}`
                    : ''}
                </Avatar>
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        {props.location.pathname === routes.START_PAGE && (
          <NavButton
            className={classes.growButton}
            onClick={() => dispatch(navigateToWindow('https://xira.com/p/lawyers/'))}
          >
            grow your practice
          </NavButton>
        )}
        {/* <NavButton className={classes.navButton} onClick={onSignUp} active={operation === OPERATION_TYPE.SIGN_UP}> */}
        {/* Join us */}
        {/* </NavButton> */}
        <NavButton className={classes.navButton} onClick={onLogIn} active={operation === OPERATION_TYPE.LOG_IN}>
          Log In
          <div className={classNames(classes.wrapperIcon, { [classes.responsiveIcon]: staticPages() })}>
            <Icon iconClass="lar la-user" style={classes.loginIcon} />
          </div>
        </NavButton>
      </>
    )
  }

  const returnMenuItems = () => {
    const menuItems = [
      {
        name: 'close_icon',
        component: (
          <SvgIcon viewBox="0 0 24 24">
            <CloseIconMenu />
          </SvgIcon>
        ),
      },
      {
        name: 'profile_info',
        component: (
          <div className={classes.menuItemContainer}>
            {currentUser.role === USER_TYPE.CLIENT ? (
              <Avatar className={classNames(classes.clientAvatar, classes.profileAvatar)}>
                {currentUser.first_name[0]}
              </Avatar>
            ) : (
              <Avatar src={currentUser.photo} className={classes.profileAvatar}>
                {currentUser.first_name && currentUser.last_name
                  ? `${currentUser.first_name[0]}${currentUser.last_name[0]}`
                  : ''}
              </Avatar>
            )}
            <div className={classes.smallProfileInfoContainer}>
              <Typography className={classes.smallProfileInfoName}>{currentUser.first_name}</Typography>
              <Typography className={classes.userName}>{currentUser.username}</Typography>
            </div>
          </div>
        ),
      },
      {
        name: 'profile_dashboard',
        action: () => dispatch(navigateToClientDashboard()),
        role: 'client',
        uncompletedProfile: true,
        component: (
          <div className={classNames(classes.menuItemContainer, classes.menuItemTop)}>
            <Typography className={classes.label} variant="body2">
              events
            </Typography>
          </div>
        ),
      },
      {
        name: 'profile_invoices',
        action: () => dispatch(navigateToClientInvoices()),
        role: 'client',
        uncompletedProfile: true,
        component: (
          <div className={classNames(classes.menuItemContainer)}>
            <Typography className={classes.label} variant="body2">
              invoices
            </Typography>
          </div>
        ),
      },
      {
        name: 'profile_connections',
        action: () => dispatch(navigateToClientConnections()),
        role: 'client',
        uncompletedProfile: true,
        component: (
          <div className={classes.menuItemContainer}>
            <Typography className={classes.label} variant="body2">
              connections
            </Typography>
          </div>
        ),
      },
      {
        name: 'profile',
        action: () => dispatch(navigateToClientProfile()),
        role: 'client',
        uncompletedProfile: true,
        component: (
          <div className={classNames(classes.menuItemContainer, classes.menuItemTop)}>
            <SvgIcon viewBox="0 0 18 18" className={classes.menuItemIcon}>
              <ProfileIcon />
            </SvgIcon>
            <Typography className={classes.label} variant="body2">
              profile
            </Typography>
          </div>
        ),
      },
      {
        name: 'payments',
        action: () => dispatch(navigateToClientPayments()),
        role: 'client',
        uncompletedProfile: true,
        component: (
          <div className={classes.menuItemContainer}>
            <SvgIcon viewBox="0 0 18 18" className={classes.menuItemIcon}>
              <PaymentIcon />
            </SvgIcon>
            <Typography className={classes.label} variant="body2">
              payments
            </Typography>
          </div>
        ),
      },
      {
        name: 'settings',
        action: () => dispatch(navigateToClientSettings()),
        role: 'client',
        uncompletedProfile: true,
        component: (
          <div className={classes.menuItemContainer}>
            <SvgIcon viewBox="0 0 18 18" className={classes.menuItemIcon}>
              <SettingsIcon />
            </SvgIcon>
            <Typography className={classes.label} variant="body2">
              settings
            </Typography>
          </div>
        ),
      },
      {
        name: 'support',
        action: onSupportIconClick,
        component: (
          <div className={classNames(classes.menuItemContainer, classes.menuItemTop)}>
            <SvgIcon viewBox="0 0 24 24" className={classes.menuItemIcon}>
              <SupportIcon />
            </SvgIcon>
            <Typography className={classes.label} variant="body2">
              support
            </Typography>
          </div>
        ),
      },
      {
        name: 'log_out',
        action: onLogoutClick,
        component: (
          <div className={classNames(classes.menuItemContainer)}>
            <SvgIcon viewBox="0 0 18 18" className={classes.menuItemIcon}>
              <LogoutIcon />
            </SvgIcon>
            <Typography className={classes.label} variant="body2">
              log out
            </Typography>
          </div>
        ),
      },
    ]

    const getMenuItems = () => {
      return currentUser.role === USER_TYPE.PRO ? menuItems.filter(item => item.role !== 'client') : menuItems
    }
    // material-ui doesnt like fragments for some reason
    if (isUserLoggedIn) {
      return getMenuItems().map((menuItem, index) => {
        return (
          <MenuItem
            disableGutters
            key={menuItem.name}
            classes={{
              root: classNames(classes.menuItem, {
                [classes.borderBottom]:
                  menuItem.name === 'profile_info' ||
                  menuItem.name === 'settings' ||
                  menuItem.name === 'profile_connections',
                [classes.menuItemNavigation]:
                  menuItem.name === 'profile_dashboard' ||
                  menuItem.name === 'profile_connections' ||
                  menuItem.name === 'profile_invoices',
                [classes.menuCloseIcon]: menuItem.name === 'close_icon',
              }),
            }}
            onClick={menuItem.action}
          >
            {menuItem.component}
          </MenuItem>
        )
      })
    }
  }

  const mountSearchButton = () => {
    return (
      <div className={classes.searchWrapperIcon}>
        <Button
          type="button"
          variant="text"
          className={classes.searchButtonIcon}
          onClick={() => setOpenRespSearch(!openRespSearch)}
        >
          <SvgIcon viewBox="0 0 32 32">{openRespSearch ? <CloseIcon /> : <SearchIcon />}</SvgIcon>
        </Button>
      </div>
    )
  }

  const mountSearch = () => {
    if (currentUser.role !== USER_TYPE.PRO) {
      if (props.location.pathname !== routes.START_PAGE) {
        if (!dontShowSearchRoutesArray.some(el => props.location.pathname.includes(el))) {
          return (
            <div className={classes.searchMainWrapper}>
              <div className={classNames(classes.mountSearchWrapper, { [classes.openSearchWrapper]: openRespSearch })}>
                <Search type="header" />
              </div>
              {mountSearchButton()}
            </div>
          )
        }
      }
    }
  }

  const staticPages = () => {
    return [routes.PRIVACY_POLICY, routes.TERMS_AND_CONDITIONS].indexOf(props.location.pathname) > -1
  }

  const mountClientNavigation = () => {
    return (
      <>
        <NavButton
          className={classes.navButton}
          active={props.location.pathname.indexOf('events') !== -1}
          onClick={() => dispatch(navigateToClientDashboard())}
        >
          events
        </NavButton>
        <NavButton
          className={classes.navButton}
          active={props.location.pathname.indexOf('connections') !== -1}
          onClick={() => dispatch(navigateToClientConnections())}
        >
          connections
        </NavButton>
        <NavButton
          className={classes.navButton}
          active={props.location.pathname.indexOf('invoices') !== -1}
          onClick={() => dispatch(navigateToClientInvoices())}
        >
          invoices
        </NavButton>
      </>
    )
  }
  return (
    <AppBar withMenuExpandSpace={staticPages()} showBanner={props.banner.show} bannerText={props.banner.text}>
      <Link component="button" onClick={onLogoClick} className={classes.button}>
        {props.history.location.pathname === '/' ? (
          <img src={Logo} className={classes.img} alt="logo" />
        ) : (
          <SvgIcon component={XiraLogoSvg} viewBox="0 0 139 40" className={classNames(classes.img, classes.logoSvg)} />
        )}
      </Link>
      {!isUserLogginingIn && mountSearch()}
      <div className={classes.toolbarContainer}>
        <div className={classes.hiddenClientNavigation}>
          {currentUser.role === USER_TYPE.CLIENT && mountClientNavigation()}
        </div>
        {!isUserLogginingIn && returnToolbar()}
      </div>
      <Menu
        className={classes.hiddenMenuWrapper}
        classes={{ paper: classNames({ [classes.menu]: isUserLoggedIn }) }}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={closeMenu}
        MenuListProps={{
          onClick: closeMenu,
          disablePadding: true,
        }}
      >
        {returnMenuItems()}
      </Menu>
    </AppBar>
  )
}

export default withRouter(Header)
