import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Hint, Icon, LicensingRow, Typography, ColorCircularProgress } from 'components'
import { colors } from 'constants/colors'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'

const useStyles = makeStyles({
  subtitle: {
    marginBottom: 24,
  },
  step: {
    padding: '0 32px',
    width: 800,
  },
  stepButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  licenseRow: {
    marginBottom: 12,
  },
  addButton: {
    marginBottom: 40,
  },
  hint: {
    color: colors.ERROR,
    margin: '10px 0 20px 0',
  },
})

const LicensingStep = props => {
  const {
    states,
    goToPreviousStep,
    goToNextStep,
    licensing,
    addLicensing,
    handleValidationStatus,
    saveProfileLicensing,
    getProfileLicenses,
    licensingAreLoading,
    showSnackbar,
    isValidation,
    resetValidation,
  } = props

  const [licensingList, setLicensingList] = useState(licensing)
  const classes = useStyles()

  useEffect(() => {
    getProfileLicenses()
  }, [getProfileLicenses])

  useEffect(() => {
    setLicensingList(licensing)
  }, [licensing])

  useEffect(() => {
    if (isValidation) handleLicencesSubmit()
  }, [isValidation])

  const handleLicencesSubmit = () => {
    resetValidation()
    if (!validateLicenses()) {
      return
    }

    addLicensing(licensingList)
    saveProfileLicensing()
      .then(() => {
        goToNextStep()
        handleValidationStatus('licensingStep', true)
        Analytics.track(EVENTS.PRO_LICENSING_COMPLETED)
      })
      .catch(e => {
        const message = (e && e['non_field_errors'] && e['non_field_errors'][0]) || 'Server Error'
        showSnackbar('error', message)
      })
  }

  const validateLicenses = () => {
    if (!licensingList.length) {
      return false
    }

    let isValid = true
    const states = []
    const licences = licensingList.map(licence => {
      let errors = {}
      if (!states.includes(licence.state)) {
        states.push(licence.state)
      } else {
        isValid = false
        errors = {
          ...errors,
          state: 'Please select unique States',
        }
      }

      if (!licence.barId) {
        isValid = false
        errors = {
          ...errors,
          barId: 'Please fill out the field',
        }
      }
      if (!licence.state) {
        isValid = false
        errors = {
          ...errors,
          state: 'Please fill out the field',
        }
      }
      return {
        ...licence,
        errors: errors,
      }
    })
    setLicensingList(licences)
    return isValid
  }

  const onAddLicensingClick = () => {
    const emptyLicence = {
      id: Math.random(),
      barId: '',
      state: '',
      errors: {
        barId: '',
        state: '',
      },
    }
    setLicensingList([...licensingList, emptyLicence])
  }

  const handleLicenseChange = (id, name, value) => {
    const licences = licensingList.map(licence => {
      if (licence.id === id) {
        if (!value) {
          return {
            ...licence,
            [name]: value,
            errors: {
              [name]: 'Please fill out the field',
            },
          }
        }
        return {
          ...licence,
          [name]: value,
          errors: {
            ...licence.errors,
            [name]: '',
          },
        }
      }
      return licence
    })
    setLicensingList(licences)
  }

  const handleLicenseDelete = id => {
    const licences = licensingList.filter(item => item.id !== id)
    setLicensingList(licences)
  }

  return licensingAreLoading ? (
    <ColorCircularProgress />
  ) : (
    <div className={classes.step}>
      <Typography variant="h4">Licensing</Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        XIRA requests your Bar/License Number in order to validate your credentials
      </Typography>

      {!licensingList.length && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <Icon iconClass="las las la-exclamation-triangle" />
          Please add at least one license
        </Hint>
      )}

      {licensingList &&
        licensingList.map(license => {
          return (
            <LicensingRow
              key={license.id}
              id={license.id}
              barId={license.barId}
              state={license.state}
              errors={license.errors}
              onLicenseChange={handleLicenseChange}
              onLicenseDelete={handleLicenseDelete}
              states={states}
              rowClass={classes.licenseRow}
            />
          )
        })}
      {licensingList && licensingList.length < 20 && (
        <Button
          classes={{
            root: classes.addButton,
          }}
          variant="text"
          onClick={onAddLicensingClick}
          startIcon={<Icon iconClass="las la-plus-circle" />}
        >
          Add Another
        </Button>
      )}
    </div>
  )
}

export default LicensingStep
