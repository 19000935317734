import React from 'react'
import { MeetingContainer } from 'containers'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIcon } from 'components'
import { ReactComponent as Logo } from 'static/Logo_Color_Primary.svg'
import BackgroundImage from 'static/meeting_room_bg.jpg'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    zIndex: 1,
  },
  icon: {
    height: 40,
    width: 139,
    fill: 'none',
    marginBottom: 16,
  },
  imageContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  '@media(max-width:939px)': {
    root: {
      padding: '32px 16px',
    },
  },
})

const MeetingPage = () => {
  const classes = useStyles()
  const currentUser = useSelector(state => state.auth.currentUser)
  const { role } = currentUser
  const redirectPathOnClickLogo = `/${role}/account/events`
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logoContainer}>
          <Link to={redirectPathOnClickLogo}>
            <SvgIcon viewBox="0 0 139 40" className={classes.icon}>
              <Logo />
            </SvgIcon>
          </Link>
        </div>
        <MeetingContainer />
      </div>
      <div className={classes.imageContainer} />
    </div>
  )
}

export default MeetingPage
