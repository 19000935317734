import { CardElement } from '@stripe/react-stripe-js'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { Hint, Icon } from 'components'

const { ERROR } = colors

const useStyles = makeStyles({
  container: {
    marginBottom: 16,
  },
  cardElement: {
    border: `1px solid ${colors.BASIC.COLOR[20]}`,
    borderRadius: 4,
    padding: 5,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:active': {
      borderRadius: 0,
    },
  },
  hint: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: 8,
  },
})

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '15px',
      '::placeholder': {
        color: colors.BASIC.COLOR[20],
      },
    },
    invalid: {
      color: ERROR,
      iconColor: ERROR,
    },
  },
  hidePostalCode: true,
}

const PaymentCardElement = props => {
  const { stripeError } = props
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CardElement options={CARD_ELEMENT_OPTIONS} className={classes.cardElement} />
      {stripeError && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {stripeError}
        </Hint>
      )}
    </div>
  )
}

export default PaymentCardElement
