import React from 'react'
import { Hint, Icon, InputLabel, TextArea } from 'components'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  error: {
    marginTop: 5,
    color: colors.ERROR,
  },
  hint: {
    marginTop: 5,
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
})

const FormTextArea = props => {
  const classes = useStyles()
  const {
    onChange,
    label,
    disabled,
    value = '',
    fullWidth,
    containerClass,
    name,
    error,
    characterLimit,
    areaClass,
    areaWrapperClass,
    hint,
    rowsMax,
    rowsMin,
  } = props

  const handleChange = e => {
    onChange(name, e.target.value)
  }

  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <TextArea
        disabled={disabled}
        value={value}
        variant="outlined"
        fullWidth={fullWidth}
        wrapperClass={areaWrapperClass}
        areaClass={areaClass}
        handleChange={handleChange}
        name={name}
        characterLimit={characterLimit}
        rowsMax={rowsMax}
        rowsMin={rowsMin}
      />
      {hint && <Hint classes={{ root: classes.hint }}>{hint}</Hint>}
      {error && (
        <Hint classes={{ root: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {error}
        </Hint>
      )}
    </div>
  )
}

export default FormTextArea
