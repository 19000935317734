import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { colors } from 'constants/colors'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    color: colors.PRIMARY.COLOR[50],
    position: 'absolute',
  },
  position: {
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
})

const ColorCircularProgress = ({ positionClass, size }) => {
  const classes = useStyles()
  return (
    <CircularProgress
      size={size}
      className={classNames(classes.root, { [classes.position]: !positionClass }, { [positionClass]: positionClass })}
    />
  )
}

export default ColorCircularProgress
