import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS, routes } from 'constants/index'
import { Button, Icon, Link, MessagesButton, SvgIcon, Tooltip, Typography, VaultButton, SplitButton } from 'components'
import moment from 'moment-timezone'
import { ReactComponent as MeetingIcon } from 'static/eventsCalendar/meetingIcon.svg'
import { ReactComponent as ListIcon } from 'static/icon_24_list.svg'
import { ReactComponent as LocationIcon } from 'static/icon_24_pin.svg'
import { ReactComponent as DollarIcon } from 'static/icon _ 24_ dollar-symbol.svg'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProMeeting } from 'store/actions/events'
import { Link as RouterLink } from 'react-router-dom'
import { goToZendesk } from 'store/actions/common'
import { navigateToCancelEvent, navigateToRescheduleEvent } from 'store/actions/navigation'

const useClasses = makeStyles({
  root: {
    width: 354,
    minHeight: 170,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    marginBottom: 24,
    alignItems: 'center',
  },
  lastRow: {
    display: 'flex',
    marginBottom: 15,
  },
  iconColumn: {
    marginRight: 16,
  },
  icons: {
    height: 24,
    width: 24,
  },
  avatar: {
    borderRadius: '50%',
    backgroundColor: colors.BASIC.COLOR[10],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 32,
    width: 32,
  },
  price: {
    fontWeight: 600,
  },
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
    fontSize: 13,
  },
  infoColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: 15,
    color: colors.BASIC.COLOR[100],
  },
  sectionSubTitle: {
    fontWeight: 'normal',
    fontSize: 13,
    color: colors.BASIC.COLOR[100],
  },
  tooltip: {
    padding: 12,
    backgroundColor: colors.BASIC.COLOR[80],
    color: colors.WHITE,
    fontWeight: 'normal',
    fontSize: 13,
  },
  tooltipLabel: {
    color: colors.PRIMARY.COLOR[50],
    textDecoration: 'underline dotted ',
    fontSize: 13,
    fontWeight: 'normal',
    cursor: 'pointer',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  btnGroup: {
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '12px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reasonSection: {
    fontWeight: 'normal',
    fontSize: 13,
    color: colors.BASIC.COLOR[70],
    fontStyle: 'italic',
  },
  canceledSubTitle: {
    fontWeight: 'bold',
    fontSize: 13,
    color: colors.ERROR,
  },
  rescheduledSubTitle: {
    fontWeight: 500,
    fontSize: 13,
    color: colors.PRIMARY.COLOR[50],
  },
  canceledTime: {
    textDecoration: 'line-through',
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  eventBody: {
    padding: '19px 24px 0 24px',
  },
  btnContainer: {
    display: 'flex',
  },
  messageBtn: {
    marginLeft: 8,
  },
  btn: {
    padding: '10px 16px',
    fontSize: 11,
    height: 32,
    minHeight: 32,
  },
  removeBtn: {
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  removeBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  goToMeetingBtn: {
    minHeight: 'auto',
    padding: '10px 16px',
    lineHeight: 1,
    fontSize: 11,
  },
  eventActionsControl: {
    display: 'flex',
  },
  manageButton: {
    height: 32,
    minHeight: 32,
    fontSize: 11,
    marginRight: 8,
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  manageInnerButtons: {
    height: 32,
    minHeight: 32,
    fontSize: 11,
    padding: '5px 10px',
  },
  manageIconButton: {
    minWidth: 'auto',
    padding: '5px 10px 5px 0',
    borderLeft: 'none',
    '&:hover': {
      borderLeft: 'none',
    },
  },
})

const MANAGE_OPTIONS = ['reschedule', 'cancel']

const getMeetingDay = (start, timezone) => {
  return moment(start)
    .tz(timezone)
    .format(CONSTANTS.DATE_FORMAT.FORMAT_TWO)
}

const getMeetingRange = (start, end, timezone) => {
  return `${moment(start)
    .tz(timezone)
    .format('h:mm')} - ${moment(end)
    .tz(timezone)
    .format('h:mm a')}`
}

const NoDataContent = () => {
  const classes = useClasses()
  return (
    <div className={classes.noDataContainer}>
      <Typography variant="h4">No data available</Typography>
    </div>
  )
}

const trashIcon = <Icon iconClass="lar la-trash-alt" />

const TooltipText = ({ goToZendesk }) => (
  <span>
    You can not send messages until your license is approved. Please contact <Link onClick={goToZendesk}>XIRA</Link>{' '}
    admin for details.
  </span>
)

const EventsFullView = props => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const isProfileApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const { meetingInfo, timezone, handleClose, calendar } = props
  const isFree = Number(meetingInfo.price) === 0
  const isRescheduled =
    meetingInfo.status === CONSTANTS.EVENT_STATUS.RESCHEDULED_BY_PRO ||
    meetingInfo.status === CONSTANTS.EVENT_STATUS.RESCHEDULED_BY_CLIENT
  const isCanceled =
    meetingInfo.status === CONSTANTS.EVENT_STATUS.CANCELED_BY_PRO ||
    meetingInfo.status === CONSTANTS.EVENT_STATUS.CANCELED_BY_CLIENT ||
    meetingInfo.status === CONSTANTS.EVENT_STATUS.DELETED_CANCELED_MEETING_BY_CLIENT
  const isEmpty = !meetingInfo.clientInfo || !meetingInfo.startTime || !meetingInfo.endTime
  const isInThePast = moment(meetingInfo.endTime)
    .tz(timezone)
    .isBefore(moment().tz(timezone))

  const isInFuture = moment(meetingInfo.endTime)
    .tz(timezone)
    .isAfter(moment.tz(timezone))

  const isOngoing = meetingInfo.startTime.getTime() < new Date().getTime()

  const onDeleteMeeting = async () => {
    try {
      handleClose()
      await dispatch(deleteProMeeting(meetingInfo.uuid))
      if (calendar) {
        calendar.current.calendar.refetchEvents()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  const onManage = action => {
    switch (action) {
      case 'cancel':
        dispatch(navigateToCancelEvent(meetingInfo.uuid, 'pro'))
        handleClose()
        break
      case 'reschedule':
        dispatch(navigateToRescheduleEvent(meetingInfo.uuid, 'pro'))
        handleClose()
        break
      default:
        break
    }
  }

  const btnGroup = isCanceled ? (
    <div className={classes.removeBtnContainer}>
      <Button
        variant="secondary"
        onClick={onDeleteMeeting}
        startIcon={trashIcon}
        className={classNames(classes.btn, classes.removeBtn)}
      >
        remove from the list
      </Button>
    </div>
  ) : (
    <>
      <div className={classes.btnContainer}>
        <VaultButton
          disabled={!meetingInfo.hasClientFolder}
          options={{
            userID: meetingInfo.client,
          }}
          vaultPlace="events"
        />
        <MessagesButton
          className={classes.messageBtn}
          chatId={meetingInfo.twilio_chat_sid}
          disabledText={
            !isProfileApproved ? <TooltipText goToZendesk={zendeskSso} /> : CONSTANTS.DISABLED_MESSAGES_TOOLTIP
          }
          disabled={!isProfileApproved || !meetingInfo.readyForMessages}
          place="events"
          handleClose={handleClose}
        />
      </div>
      <div className={classes.eventActionsControl}>
        {!isOngoing && (
          <SplitButton
            color="secondary"
            variant="secondary"
            options={MANAGE_OPTIONS}
            classes={{
              buttonGroup: classes.manageButton,
              buttons: classes.manageInnerButtons,
              iconButton: classes.manageIconButton,
            }}
            onSelect={onManage}
          >
            manage
          </SplitButton>
        )}
        {isInFuture && (
          <Button
            className={classes.goToMeetingBtn}
            variant="primary"
            target="_blank"
            component={RouterLink}
            to={`${routes.MEETING_LINK}/${meetingInfo.uuid}`}
          >
            Go to meeting
          </Button>
        )}
      </div>
    </>
  )

  return (
    <div className={classes.root}>
      {isEmpty ? (
        <NoDataContent />
      ) : (
        <>
          <div className={classes.eventBody}>
            <div className={classes.row}>
              <div className={classes.iconColumn}>
                <MeetingIcon />
              </div>
              <div>
                {isRescheduled && (
                  <Typography variant="subtitle2" className={classes.rescheduledSubTitle}>
                    rescheduled to
                  </Typography>
                )}
                {isCanceled && (
                  <Typography variant="subtitle2" className={classes.canceledSubTitle}>
                    canceled
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  className={classNames({
                    [classes.sectionTitle]: true,
                    [classes.canceledTime]: isCanceled,
                  })}
                >
                  {getMeetingDay(meetingInfo.startTime, timezone)} &bull;{' '}
                  {getMeetingRange(meetingInfo.startTime, meetingInfo.endTime, timezone)}
                </Typography>
                {meetingInfo.updateReason && (
                  <Typography variant="subtitle2" className={classes.reasonSection}>
                    {`"${meetingInfo.updateReason}"`}
                  </Typography>
                )}
              </div>
            </div>
            {meetingInfo.isInitial && (
              <>
                <div className={classes.row}>
                  <div className={classes.iconColumn}>
                    <SvgIcon viewBox="0 0 24 24" className={classes.icons}>
                      <ListIcon />
                    </SvgIcon>
                  </div>
                  <div>
                    <Typography variant="body1" className={classes.sectionTitle}>
                      initial consultation
                    </Typography>
                    <Typography variant="subtitle2" className={classes.sectionSubTitle}>
                      {meetingInfo.practiceAreaName}
                    </Typography>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.iconColumn}>
                    <SvgIcon viewBox="0 0 24 24" className={classes.icons}>
                      <DollarIcon />
                    </SvgIcon>
                  </div>
                  <div>
                    <Typography variant="body1" className={classes.price}>
                      {isFree ? 'no charge' : `$${meetingInfo.price}`}
                    </Typography>
                  </div>
                </div>
              </>
            )}

            {meetingInfo.isInitialMeetingSuccessfull && (
              <div className={classes.lastRow}>
                <div className={classes.iconColumn}>
                  <SvgIcon viewBox="0 0 24 24" className={classes.icons}>
                    <LocationIcon />
                  </SvgIcon>
                </div>
                <div className={classes.infoColumn}>
                  <Typography variant="body1" className={classes.sectionTitle}>
                    {`${meetingInfo.clientInfo.contactInfo.city},
                      ${
                        meetingInfo.clientInfo.contactInfo.state
                          ? meetingInfo.clientInfo.contactInfo.state.replace('US-', '')
                          : ''
                      }
                      ${meetingInfo.clientInfo.contactInfo.zip}`}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.sectionSubTitle}>
                    {`${meetingInfo.clientInfo.contactInfo.suite} ${meetingInfo.clientInfo.contactInfo.street}`}
                  </Typography>
                  <Link href={`mailto:${meetingInfo.clientInfo.username}`} className={classes.link}>
                    {meetingInfo.clientInfo.username}
                  </Link>
                  <Typography variant="subtitle2" className={classes.sectionSubTitle}>
                    {meetingInfo.clientInfo.contactInfo.phone}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className={classes.btnGroup}>{btnGroup}</div>
        </>
      )}
    </div>
  )
}

export default EventsFullView
