import React, { useEffect, useState } from 'react'
import {
  Button,
  ColorCircularProgress,
  Hint,
  Icon,
  InputLabel,
  Modal,
  TextField,
  Typography,
  ErrorModal,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getMeetingDay, getMeetingDuration, getMeetingRange } from 'utils/getters/events'
import { closeErrorModal, goToZendesk } from 'store/actions/common'
import RestrictionMsg from 'components/events/RestrictionMsg'

const useStyles = makeStyles({
  root: {
    width: 568,
    minHeight: 364,
    position: 'relative',
    paddingBottom: 24,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  meetingTimeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    width: '100%',
    padding: '0 40px',
  },
  meetingNewTimeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    width: '100%',
    padding: '0 40px',
    color: colors.SUCCESS,
  },
  meetingTimeLabel: {
    fontSize: 16,
    fontWeight: 500,
  },
  meetingTime: {
    textDecoration: 'line-through',
  },
  title: {
    marginTop: 24,
    marginBottom: 27,
  },
  reasonSection: {
    width: '100%',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    padding: '24px 40px',
    marginBottom: 16,
  },
  tooltipTextContainer: {
    width: '100%',
    padding: '0 40px',
    marginBottom: 24,
  },
  buttonGroup: {
    width: '100%',
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    height: 40,
    padding: '5px 24px',
    marginLeft: 10,
    minHeight: 40,
  },
  hint: {
    color: colors.ERROR,
    marginTop: 4,
  },
})

const RescheduleEventModal = props => {
  const classes = useStyles()
  const { open, currentEvent, newEvent, onModalClose, onRescheduleConfirm, reason, setReason } = props
  const dispatch = useDispatch()
  const [rescheduleReason, setRescheduleReason] = useState(reason)
  const [error, setError] = useState(false)
  const eventInfoIsLoading = useSelector(store => store.events.meetingInfoIsLoading)
  const { title: titleErrorMOdal, detail: detailErrorModal, isOpen: isOpenErrorModal } = useSelector(
    state => state.common.errorModal
  )

  useEffect(() => {
    setError(false)
    setRescheduleReason('')
  }, [open])

  const onConfirm = async () => {
    if (!rescheduleReason) {
      setError(true)
      return
    }
    setReason(rescheduleReason)
    setError(false)
    onRescheduleConfirm(rescheduleReason)
  }

  const onErrorModalClose = () => {
    dispatch(closeErrorModal())
    onModalClose()
  }

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  return (
    <>
      <Modal open={open && !isOpenErrorModal} maxWidth={false} handleClose={onModalClose}>
        <div className={classes.root}>
          {eventInfoIsLoading ? (
            <ColorCircularProgress />
          ) : (
            <div className={classes.content}>
              <Typography variant="h4" className={classes.title}>
                confirm rescheduling a meeting
              </Typography>
              <div className={classes.meetingTimeSection}>
                <Typography variant="body1" className={classes.meetingTimeLabel}>
                  scheduled date & time
                </Typography>
                <Typography variant="body2" className={classes.meetingTime}>
                  {getMeetingDay(currentEvent.startTime)}{' '}
                  {getMeetingRange(currentEvent.startTime, currentEvent.endTime)} &bull;{' '}
                  {getMeetingDuration(currentEvent.startTime, currentEvent.endTime)}
                </Typography>
              </div>
              <div className={classes.meetingNewTimeSection}>
                <Typography variant="body1" className={classes.meetingTimeLabel}>
                  updated date & time
                </Typography>
                <Typography variant="body2">
                  {getMeetingDay(newEvent.startTime)} {getMeetingRange(newEvent.startTime, newEvent.endTime)} &bull;{' '}
                  {getMeetingDuration(newEvent.startTime, newEvent.endTime)}
                </Typography>
              </div>
              <div className={classes.reasonSection}>
                <InputLabel variant="body1">reason for rescheduling*</InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={rescheduleReason}
                  onChange={e => setRescheduleReason(e.target.value)}
                  inputProps={{
                    maxLength: 150,
                  }}
                />
                {error && (
                  <Hint classes={{ subtitle2: classes.hint }}>
                    <Icon iconClass="las las la-exclamation-triangle" />
                    Please provide reason for rescheduling
                  </Hint>
                )}
              </div>
              <div className={classes.buttonGroup}>
                <Button variant="text" onClick={onModalClose}>
                  cancel
                </Button>
                <Button variant="primary" onClick={onConfirm} className={classes.confirmButton}>
                  confirm
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onErrorModalClose}
        title={titleErrorMOdal}
        message={<RestrictionMsg goToZendesk={zendeskSso} message={detailErrorModal} />}
        cancelationButtonText="ok"
      />
    </>
  )
}

export default RescheduleEventModal
