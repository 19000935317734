import * as CONSTANTS from 'store/constants/chat/chat'

const initialState = {
  messages: [],
  chats: [],
  count: 0,
  chatsAreLoading: false,
  selectedChat: {
    chat_member_full_name: '',
    chat_member_twilio_sid: '',
    chat_member_uuid: '',
    last_message_at: '',
    twilio_chat_sid: '',
    chat_member_photo_thumbnail: '',
    unread_messages_count: 0,
    chat_member_is_online: false,
  },
  showChatWindow: false,
  userHasNewMessages: false,
  chatListConfiguration: {
    limit: 20,
    offset: 0,
  },
  searchValue: '',
  chatClient: null,
}

const chat = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.SET_USER_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      }
    case CONSTANTS.RESET_USER_MESSAGES:
      return {
        ...state,
        messages: [],
      }
    case CONSTANTS.SET_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      }
    case CONSTANTS.SET_CHAT_INFO:
      return {
        ...state,
        chats: action.payload,
      }
    case CONSTANTS.RESET_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: {
          chat_member_full_name: '',
          chat_member_twilio_sid: '',
          chat_member_uuid: '',
          last_message_at: '',
          twilio_chat_sid: '',
          chat_member_photo_thumbnail: '',
          unread_messages_count: 0,
        },
      }
    case CONSTANTS.GET_USER_CHATS_START:
      return {
        ...state,
        chatsAreLoading: true,
      }
    case CONSTANTS.GET_USER_CHATS_END:
      return {
        ...state,
        chats: action.payload,
        chatsAreLoading: false,
        count: action.count,
      }
    case CONSTANTS.SET_CHAT_WINDOW_VISIBILITY:
      return {
        ...state,
        showChatWindow: action.payload,
      }
    case CONSTANTS.CHECK_IF_USER_HAS_UNREAD_MESSAGES_END:
      return {
        ...state,
        userHasNewMessages: action.payload,
      }
    case CONSTANTS.INCREASE_CHAT_LIST_OFFSET:
      return {
        ...state,
        chatListConfiguration: {
          ...state.chatListConfiguration,
          offset: state.chatListConfiguration.offset + state.chatListConfiguration.limit,
        },
      }
    case CONSTANTS.RESET_CHAT_LIST_OFFSET:
      return {
        ...state,
        chatListConfiguration: {
          ...state.chatListConfiguration,
          offset: 0,
        },
      }
    case CONSTANTS.SET_CHAT_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case CONSTANTS.SET_SELECTED_CHAT_ONLINE:
      return {
        ...state,
        selectedChat: {
          ...state.selectedChat,
          chat_member_is_online: action.payload,
        },
      }
    case CONSTANTS.SET_CHAT_CLIENT:
      return {
        ...state,
        chatClient: action.payload,
      }
    default:
      return state
  }
}

export default chat
