import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormikInput, Link, SvgIcon, Text, Typography, AccountTypeSwitch } from 'components'
import {
  setSignUpEmail,
  logInWithSocial,
  setAuthRole,
  endAuthByEmail,
  startAuthByEmail,
  requestVerificationLink,
  checkEmail,
} from 'store/actions/auth'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useGoogleLogin } from '@react-oauth/google'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { withRouter } from 'react-router-dom'
import { isEmailInvalid } from 'utils/auth'
// import { ReactComponent as IconFacebook } from 'static/ic_facebook.svg'
import { ReactComponent as IconGoogle } from 'static/ic_google.svg'
import { ReactComponent as AlertTriangle } from 'static/alert-triangle.svg'
import { colors } from 'constants/index'
import SocialSignUp from './socialSignUp'
import EmailSignUp from './emailSignUp'
import Analytics from '../../../utils/analytics/AnalyticsService'

const useStyles = makeStyles({
  signUp: {
    textAlign: 'center',
    width: 568,
    height: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: colors.BASIC.COLOR[80],
      },
    },
  },
  signUpHeader: {
    marginTop: 56,
  },
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: 368,
  },
  buttonContainer: {
    marginTop: 10,
  },
  signUpFooter: {
    textAlign: 'center',
    marginTop: 34,
    marginBottom: 64,
  },
  socialButtons: {
    width: 368,
    height: 46,
    margin: '4px 0',
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 16,
    padding: 0,
  },
  outlinedSecondaryGoogle: {
    color: colors.BASIC.COLOR[100],
    borderColor: colors.BASIC.COLOR[10],
    backgroundColor: colors.WHITE,
    '&:disabled': {
      backgroundColor: colors.BASIC.COLOR[10],
    },
    '&:hover': {
      backgroundColor: colors.WHITE,
      borderColor: colors.BASIC.COLOR[10],
      color: colors.BASIC.COLOR[100],
    },
  },
  iconGoogle: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  outlinedSecondaryFacebook: {
    color: colors.WHITE,
    borderColor: colors.FACEBOOK_BLUE,
    backgroundColor: colors.FACEBOOK_BLUE,
    '&:hover': {
      backgroundColor: colors.FACEBOOK_BLUE,
      borderColor: colors.FACEBOOK_BLUE,
      color: colors.WHITE,
    },
  },
  iconFacebook: {
    height: 16,
    width: 16,
    marginRight: 8,
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',
    marginBottom: '15px',
  },
  text: {
    margin: '32px',
  },
  link: {
    lineHeight: 1.17,
    marginLeft: 4,
  },
  footerText: {
    fontSize: 15,
    lineHeight: 1.47,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    marginBottom: 40,
  },
  errorMsg: {
    display: 'flex',
    margin: '0 auto',
    fontSize: '14px',
    marginBottom: 16,
    width: 368,
    border: `1px solid ${colors.PRIMARY.COLOR[40]}`,
    backgroundColor: colors.PRIMARY.COLOR[0],
    padding: 12,
    borderRadius: 4,
  },
  errorDesc: {
    textAlign: 'left',
    color: colors.BASIC.COLOR[100],
  },
  alertLogo: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 1,
    color: colors.ERROR,
  },
  verifyLink: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: colors.PRIMARY.COLOR[50],
    fontWeight: 500,
    textDecoration: 'none',
  },
  errorCont: {
    marginTop: 6,
  },
  '@media(max-width:767px)': {
    signUp: {
      width: '100%',
      padding: '0 15px',
    },
    signUpForm: {
      width: '100%',
    },
    signUpContent: {
      width: '100%',
      maxWidth: 368,
      margin: '0 auto',
    },
    btn: {
      width: '100%',
    },
    errorMsg: {
      width: '100%',
      maxWidth: 368,
    },
    socialButtons: {
      width: '100%',
    },
  },
  '@media(max-width:400px)': {
    buttonText: {
      display: 'none',
    },
    socialButtons: {
      width: 46,
      minWidth: 46,
      borderRadius: '50%',
      margin: '4px 7px',
    },
    iconFacebook: {
      margin: 0,
    },
    iconGoogle: {
      margin: 0,
    },
  },
  bolderText: {
    fontWeight: 500,
  },
})

const SignUp = props => {
  const isAuthByEmail = useSelector(state => state.auth.isAuthByEmail)
  const redirectPath = useSelector(state => state.common.redirectPath)
  const [signUpType, setSignUpType] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [access_token, setToken] = useState('')
  const [provider, setProvider] = useState('')
  const [showAccountTypeError, setAccountTypeError] = useState(false)
  const [errorVisibility, setErrorVisibility] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [disabledPro, setDisabledPro] = useState(false)
  const [isSocialLogin, setIsSocialLogin] = useState(false)
  const [verificationState, setVerificationState] = useState(false)
  const authRole = useSelector(state => state.auth.authRole)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (redirectPath.indexOf('client') > -1 || props.location.search.indexOf('client') > -1) {
      setDisabledPro(true)
      dispatch(setAuthRole('client'))
    } else if (props.location.search.indexOf('pro') > -1) {
      dispatch(setAuthRole('pro'))
    }
  }, [dispatch, props.location.search, redirectPath])

  const onSocialSubmit = values => {
    const { firstName, lastName, acceptTerms } = values
    socialAuth({
      access_token,
      provider,
      first_name: firstName,
      last_name: lastName,
      agreement_accepted_at: acceptTerms,
    })
  }

  const formBySignUpType = {
    email: <EmailSignUp userType={authRole} />,
    social: (
      <SocialSignUp
        firstName={firstName}
        lastName={lastName}
        onFormSubmit={onSocialSubmit}
        // disabledContinue={isSocialLogin}
      />
    ),
  }
  const onFormSubmit = async ({ email }, { setErrors }) => {
    if (!authRole) {
      return
    }
    try {
      setVerificationState(false)
      setErrorVisibility(false)
      props.setSignUpEmail(email)
      dispatch(startAuthByEmail())
      await isEmailInvalid(email)
      dispatch(endAuthByEmail())
    } catch (e) {
      dispatch(endAuthByEmail())
      setErrorVisibility(false)
      if (e.response.data.username) {
        setVerificationState(false)
        setErrors({ email: e.response.data.username })
      } else if (e.response && e.response.data && e.response.data.detail) {
        if (e.response.data.title && e.response.data.title === 'Inactive') {
          setVerificationState(true)
        }
        setErrorMessage(e.response.data.detail)
        setErrorVisibility(true)
      } else {
        setErrorVisibility(false)
        setSignUpType('email')
      }
    }
  }

  const socialErrorHandler = () => {
    setIsSocialLogin(false)
  }

  const onFBSignUp = async response => {
    const { accessToken, email, name, status } = response
    if (status === 'unknown') {
      socialErrorHandler()
      Analytics.register({
        $email: email,
        user_type: authRole === 'pro' ? 'lawyer' : 'client',
        auth_type: 'fb',
        error: status,
      })
      return
    }
    const emailCheck = await props.checkEmail(email)
    if (!emailCheck) {
      socialErrorHandler()
      Analytics.register({
        $email: email,
        user_type: authRole === 'pro' ? 'lawyer' : 'client',
        auth_type: 'fb',
        error: 'Failed email check',
      })
    } else if (emailCheck.data.email_exists) {
      socialAuth({ access_token: accessToken, provider: 'facebook', isEmailExists: true })
    } else {
      setIsSocialLogin(false)
      if (name) {
        setFirstName(name.substr(0, name.indexOf(' ')))
        setLastName(name.substr(name.indexOf(' ') + 1))
      }
      setToken(accessToken)
      setProvider('facebook')
      setSignUpType('social')
    }
  }

  const onGoogleSignUp = async ({ access_token }) => {
    const { data: profileObj } = await axios('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: { Authorization: `Bearer ${access_token}` },
    })
    const { email, given_name, family_name } = profileObj
    const emailCheck = await props.checkEmail(email)
    if (!emailCheck) {
      socialErrorHandler()
      Analytics.register({
        $email: email,
        user_type: authRole === 'pro' ? 'lawyer' : 'client',
        auth_type: 'google',
        error: 'Failed email check',
      })
    } else if (emailCheck.data.email_exists) {
      socialAuth({ access_token, provider: 'google-oauth2', isEmailExists: true })
    } else {
      setIsSocialLogin(false)
      if (given_name) {
        setFirstName(given_name)
      }
      if (family_name) {
        setLastName(family_name)
      }
      setToken(access_token)
      setProvider('google-oauth2')
      setSignUpType('social')
    }
  }

  const socialAuth = ({ access_token, provider, first_name, last_name, agreement_accepted_at, isEmailExists }) => {
    setIsSocialLogin(true)
    props
      .logInWithSocial({
        access_token,
        role: authRole,
        provider,
        first_name,
        last_name,
        agreement_accepted_at,
        isEmailExists,
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.detail) {
          setErrorMessage(e.response.data.detail)
        }
        setErrorVisibility(true)
      })
      .finally(() => setIsSocialLogin(false))
  }

  const changeAuthRole = role => {
    props.setAuthRole(role)
    setAccountTypeError(false)
  }

  const onContinueClick = () => {
    if (!authRole) {
      setAccountTypeError(true)
    }
  }

  const socialSignUp = onClick => {
    setErrorVisibility(false)
    setVerificationState(false)
    if (!authRole) {
      setAccountTypeError(true)
    } else {
      setIsSocialLogin(true)
      onClick()
    }
  }

  const requestVerification = async event => {
    event.preventDefault()
    dispatch(requestVerificationLink())
    setErrorVisibility(false)
  }

  const login = useGoogleLogin({ onSuccess: onGoogleSignUp, onError: socialErrorHandler })

  return (
    <div className={classes.signUp}>
      <div className={classes.signUpHeader}>
        <Typography classes={{ h2: classes.title }} variant="h2">
          let&apos;s get you signed up
        </Typography>
      </div>
      {errorVisibility && (
        <div className={classes.errorMsg}>
          <SvgIcon component={AlertTriangle} viewBox="0 0 24 24" className={classes.alertLogo} />
          <div className={classes.errorDesc}>
            {verificationState ? (
              <>
                The email you entered during registration{' '}
                <span className={classes.bolderText}>hasn&apos;t been verified yet</span>
                . Please select the VERIFY MY EMAIL button in the email that was sent to you
                <br />
                <div className={classes.errorCont}>
                  or,{' '}
                  <Link
                    href="#"
                    onClick={requestVerification}
                    classes={{
                      root: classes.verifyLink,
                    }}
                  >
                    send new verification email
                  </Link>
                </div>
              </>
            ) : (
              errorMessage
            )}
          </div>
        </div>
      )}
      <div className={classes.signUpContent}>
        {signUpType ? (
          formBySignUpType[signUpType]
        ) : (
          <>
            <AccountTypeSwitch
              changeAuthRole={changeAuthRole}
              accountType={authRole}
              showError={showAccountTypeError}
              disabledPro={disabledPro || isAuthByEmail}
              disabledClient={isAuthByEmail}
            />
            <Formik
              initialValues={{ email: '' }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('Looks like this email is incomplete')
                  .trim()
                  .required('Please enter your email '),
              })}
              onSubmit={onFormSubmit}
              validateOnChange={false}
              validateOnBlur
            >
              {props => {
                return (
                  <Form className={classes.signUpForm} onSubmit={props.handleSubmit}>
                    <FormikInput
                      variant="outlined"
                      name="email"
                      label="Email"
                      type="text"
                      size="responsive"
                      externalError={errorVisibility}
                    />
                    <Button
                      className={classes.btn}
                      variant="primary"
                      type="submit"
                      size="large"
                      onClick={onContinueClick}
                      isButtonBlocked={isAuthByEmail}
                      disabled={isAuthByEmail}
                    >
                      Sign up
                    </Button>
                  </Form>
                )
              }}
            </Formik>

            <Text className={classes.footerText}>or log in using</Text>
            <div className={classes.buttonContainer}>
              <Button
                classes={{ root: classes.socialButtons, outlinedSecondary: classes.outlinedSecondaryGoogle }}
                variant="secondary"
                onClick={() => socialSignUp(login)}
                disabled={isAuthByEmail}
              >
                <SvgIcon component={IconGoogle} viewBox="0 0 22 22" className={classes.iconGoogle} />
                <span className={classes.buttonText}>Google</span>
              </Button>
              {/*<FacebookLogin*/}
              {/*  appId={process.env.REACT_APP_FACEBOOK_APP}*/}
              {/*  autoLoad={false}*/}
              {/*  callback={onFBSignUp}*/}
              {/*  responseType="code"*/}
              {/*  fields="name,email"*/}
              {/*  render={renderProps => (*/}
              {/*    <Button*/}
              {/*      classes={{ root: classes.socialButtons, outlinedSecondary: classes.outlinedSecondaryFacebook }}*/}
              {/*      variant="secondary"*/}
              {/*      disabled={isAuthByEmail || isSocialLogin}*/}
              {/*      onClick={() => {*/}
              {/*        socialSignUp(renderProps.onClick)*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <SvgIcon component={IconFacebook} viewBox="0 0 8 15" className={classes.iconFacebook} />*/}
              {/*      <span className={classes.buttonText}>Facebook</span>*/}
              {/*    </Button>*/}
              {/*  )}*/}
              {/*/>*/}
            </div>
          </>
        )}
      </div>

      <div className={classes.signUpFooter}>
        <Text className={classes.footerText}>
          {"I'm already a member "}
          <Button variant="text" className={classes.link} onClick={() => props.goToLogin(props.location.search)}>
            LogIn
          </Button>
        </Text>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setSignUpEmail: email => dispatch(setSignUpEmail(email)),
  logInWithSocial: payload => dispatch(logInWithSocial(payload)),
  setAuthRole: payload => dispatch(setAuthRole(payload)),
  checkEmail: payload => dispatch(checkEmail(payload)),
})
export default connect(null, mapDispatchToProps)(withRouter(SignUp))
