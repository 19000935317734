import * as firebase from 'firebase/app'
import 'firebase/analytics'
import { EVENTS } from './Events'
import { PROPERTIES } from './Properties'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export class FirebaseService {
  constructor() {
    firebase.initializeApp(firebaseConfig)
    this.analytics = firebase.analytics()
    this.isIdentified = false
  }

  track(event, payload) {
    this.setUserProperties(payload)
    this.analytics.logEvent(event, payload)
  }

  register(profile) {
    if (this.isIdentified) {
      this.logout()
    }

    this.analytics.setUserId(profile.uuid)
    this.setUserProperties(profile)
    const user = {
      email: profile.$email,
      user_type: profile.user_type,
      auth_type: profile.auth_type,
      userID: profile.uuid,
      error: profile.error,
    }
    this.analytics.logEvent(EVENTS.REGISTRATION, user)
    this.isIdentified = true
  }

  login(profile) {
    this.identify(profile)
    this.setUserProperties(profile)
    const user = {
      email: profile.$email,
      user_type: profile.user_type,
      auth_type: profile.auth_type,
      userID: profile.uuid,
      error: profile.error,
    }
    this.analytics.logEvent(EVENTS.LOGIN, user)
  }

  identify(profile) {
    if (!profile) {
      return false
    }

    this.analytics.setUserId(profile.uuid)
    this.isIdentified = true
  }

  logout() {
    this.analytics.setUserId(null)
    this.isIdentified = false
  }

  setUserProperties(payload) {
    if (!payload) {
      return
    }
    const superToSend = {}
    PROPERTIES.FIREBASE_PROFILE_PROPERTIES.forEach(v => {
      if (payload.hasOwnProperty(v)) {
        superToSend[v] = payload[v]
      }
    })

    Object.keys(superToSend).forEach(key => {
      this.analytics.setUserProperties(key, payload[key])
    })
  }
}
