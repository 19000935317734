import { SummaryTable, InvoiceDocumentFooter, InvoiceDocumentHeader } from './index'
import { View, StyleSheet, Text, Image } from '@react-pdf/renderer'
import React from 'react'
import CompanyLogo from 'static/invoices/invoice_logo.png'

const styles = StyleSheet.create({
  mainSection: {
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
  },
  creditAdjustmentContainer: {
    height: '100%',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 150,
    padding: '30px 30px 0 30px',
  },
  logo: {
    height: 20,
    width: 65,
  },
})

const SummaryTableEntry = ({ summary, invoice, timezone }) => {
  return (
    <View style={styles.creditAdjustmentContainer} break>
      <View fixed style={styles.section}>
        <Image src={CompanyLogo} style={styles.logo} />
        <View />
      </View>
      <View style={styles.mainSection}>
        <SummaryTable summary={summary} timezone={timezone} invoice={invoice} />
      </View>
      <View />
      <InvoiceDocumentFooter invoice={invoice} isSummary />
    </View>
  )
}

export default SummaryTableEntry
