import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Typography, ContactInfoFormBody, LanguageSection } from 'components'
import { CONSTANTS, colors } from '../../constants/index'
const useStyles = makeStyles({
  stepButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 24,
  },
  languageSection: {
    marginTop: 40,
  },
})

const ContactInfoForm = props => {
  const {
    contactInfo,
    countries,
    states,
    onSubmit,
    onReset,
    footer,
    saveCurrentUserLanguages,
    updateLocalUserLanguages,
    languages,
    languagesDB,
    className,
    updateContactInfo = () => {},
  } = props
  const classes = useStyles()
  const initialValues = {
    street: contactInfo.street,
    suite: contactInfo.suite,
    city: contactInfo.city,
    state: contactInfo.state,
    zip: contactInfo.zip,
    phone: contactInfo.phone,
    optionalPhone: contactInfo.optionalPhone,
    country: contactInfo.country,
  }

  return (
    <div>
      <Typography classes={{ h4: classes.title }} variant="h4">
        Contact Info
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Only your city and state will be displayed publicly
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          street: Yup.string()
            .trim()
            .max(100, 'Please ensure this field has no more than 100 characters')
            .required('Please fill in the field'),
          suite: Yup.string()
            .trim()
            .max(50, 'Please ensure this field has no more than 50 characters'),
          city: Yup.string()
            .trim()
            .max(50, 'Please ensure this field has no more than 50 characters')
            .required('Please fill in the field'),
          state: Yup.string()
            .trim()
            .required('Please fill in the field'),
          zip: Yup.string()
            .typeError('Please enter valid ZIP code (5 digits)')
            .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
            .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
            .required('Please fill in the field'),
          phone: Yup.string()
            .test('len', 'Please enter 10 digits', val =>
              val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
            )
            .required('Please fill in the field'),
          optionalPhone: Yup.string().test('len', 'Please enter 10 digits', val =>
            val === '+1' || !val ? true : val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
          ),
          country: Yup.string()
            .trim()
            .required('Please fill in the field'),
        })}
        onSubmit={onSubmit}
        onReset={onReset}
        enableReinitialize
      >
        {props => (
          <Form>
            <ContactInfoFormBody countries={countries} states={states} updateContactInfo={updateContactInfo} />
            {languagesDB && (
              <LanguageSection
                languages={languages}
                containerClass={classes.languageSection}
                languagesDB={languagesDB}
                updateLocalUserLanguages={updateLocalUserLanguages}
              />
            )}
            <div className={classes.stepButtons}>{React.cloneElement(footer, { disabled: !props.dirty })}</div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactInfoForm
