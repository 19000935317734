import React from 'react'
import moment from 'moment-timezone'
import TableCell from '@material-ui/core/TableCell'
import { CONSTANTS } from 'constants/index'
import { useStyles } from './rowStyles'

const VaultLogTableRow = ({ data, timezone, isPrivate }) => {
  const classes = useStyles()
  const actionArray = data.action.split(' ')
  const action = actionArray[0]
  const startPositionDescription = data.action.indexOf(actionArray[1])
  const descriprion = data.action.slice(startPositionDescription)

  return (
    <>
      <TableCell classes={{ body: classes.tableCellLog }}>
        {moment(data.date)
          .tz(timezone)
          .format(CONSTANTS.DATE_FORMAT.FORMAT_THREE)}
      </TableCell>
      <TableCell classes={{ body: classes.tableCellLog }}>{data.case_folder}</TableCell>
      {!isPrivate && <TableCell classes={{ body: classes.tableCellLog }}>{data.user}</TableCell>}
      <TableCell classes={{ body: classes.tableCellLog }}>
        <span className={classes.action}>{action}</span>
        {` ${descriprion}`}
      </TableCell>
    </>
  )
}

export default VaultLogTableRow
