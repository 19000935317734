import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactMarkdown from 'react-markdown'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Loader, TextField, Typography } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors, REGEXP, routes } from 'constants/index'
import { getAnswer, setClientQuestion, setCountryStateValue } from '../../store/actions/search'
import { navigateTo } from '../../store/actions/navigation'
import StatesAutocomplete from '../../components/search/StatesAutocomplete'
import { useQuery } from '../../utils/hooks'

const useStyles = makeStyles({
  wrapper: {
    paddingBottom: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    marginTop: 25,
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
      padding: '0 15px 50px',
    },
  },
  startPageBtn: {
    cursor: 'pointer',
    height: 57,
    background: colors.PRIMARY.COLOR[50],
    borderRadius: '0 5px 5px 0',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.PRIMARY.COLOR[0],
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.WHITE,
    },
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
      marginTop: 15,
      borderRadius: 5,
    },
  },
  linkButton: {
    cursor: 'pointer',
    height: 55,
    background: colors.PRIMARY.COLOR[50],
    borderRadius: '5px',
    fontSize: 16,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 40px',
    color: colors.PRIMARY.COLOR[0],
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.WHITE,
    },
  },
  notched: {
    border: 0,
  },
  buttonTypeSearch: {
    top: 5,
    left: 276,
    padding: '6px 24px',
    fontSize: '10px',
    height: 20,
    minHeight: 20,
    width: 70,
    '@media(max-width:1279px)': {
      left: 174,
    },
    '@media(max-width:939px)': {
      left: 130,
    },
    '@media(max-width:768px)': {
      left: 'unset',
      right: 12,
      top: 17,
    },
  },
  clientQuestion: {
    marginTop: 20,
    padding: 24,
    background: colors.PRIMARY.COLOR[0],
    borderRadius: '16px 16px 0 16px',
    border: `${1}px solid ${colors.PRIMARY.COLOR[40]}`,
    width: '80vw',
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
    },
  },
  authorTitle: {
    color: colors.PRIMARY.COLOR[50],
    fontWeight: 700,
    fontSize: 15,
    marginBottom: 10,
  },
  authorTitleBlack: {
    color: colors.BLACK,
    fontWeight: 700,
    fontSize: 15,
    marginBottom: 10,
  },
  aiAnswer: {
    marginTop: 20,
    padding: 24,
    background: colors.BASIC.COLOR[0],
    borderRadius: '16px 16px 16px 0',
    border: `${1}px solid ${colors.BASIC.COLOR[10]}`,
    width: '80vw',
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    width: '80vw',
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
    },
  },
})

const AIChatPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const countryState = useSelector(state => state.search.countryStateValue)
  const question = useSelector(state => state.search.clientQuestion)
  const practiceArea = useSelector(state => state.search.practiceAreaValue)
  const answer = useSelector(state => state.search.aiAnswer)
  const answerIsLoading = useSelector(state => state.search.answerIsLoading)
  const isDisclaimerChecked = window.sessionStorage.getItem('isDisclaimerChecked')
  const [currentSearchState, setCurrentSearchState] = useState(countryState)
  const [aiAnswer, setAiAnswer] = useState(answer)
  const [href, setHref] = useState(window.location.origin + routes.SEARCH_PAGE)

  useEffect(() => {
    if (isDisclaimerChecked === 'false' || !question.trim().length) {
      dispatch(navigateTo(routes.START_PAGE))
    }
  }, [dispatch, isDisclaimerChecked, question])

  useEffect(() => {
    const params = {
      practice_area: practiceArea,
      state: currentSearchState,
    }
    let queryString = ''
    for (const key in params) {
      if (params[key]) {
        queryString += queryString ? '&' : '?'
        queryString += `${key}=${params[key]}`
      }
    }
    setHref(`${window.location.origin + routes.SEARCH_PAGE}${queryString}`)
  }, [practiceArea, currentSearchState])

  useEffect(() => {
    if (!!question && !!countryState) {
      dispatch(getAnswer())
    }
  }, [countryState, dispatch, question])

  useEffect(() => {
    setAiAnswer(answer)
  }, [answer])

  const onLinkClick = () => {
    window.top.location.href = href
  }

  return (
    <div className={classes.wrapper}>
      <div>
        {answer ? (
          <>
            <div className={classes.clientQuestion}>
              <Typography variant="subtitle1" className={classes.authorTitle}>
                Your question
              </Typography>
              <Typography variant="subtitle1">{question}</Typography>
            </div>
            <div className={classes.aiAnswer}>
              <Typography variant="subtitle1">
                <span className={classes.authorTitleBlack}>Answer by </span>
                <span className={classes.authorTitle}>AI</span>
                <span className={classes.authorTitleBlack}>RA</span>
              </Typography>
              <ReactMarkdown children={aiAnswer} />
              {practiceArea && (
                <>
                  <br />
                  <span>As with all legal matters, its best to consult with an attorney.</span>{' '}
                  <a href={href} style={{ color: colors.PRIMARY.COLOR[50], textDecoration: 'underline' }}>
                    CLICK HERE
                  </a>{' '}
                  <span>to see a list of attorneys that specialize in the area of law related to your question.</span>
                </>
              )}
            </div>
            <div className={classes.buttonWrapper}>
              <Button variant="primary" onClick={onLinkClick} className={classes.linkButton}>
                View list of lawyers that can help
              </Button>
            </div>
          </>
        ) : null}
        {answerIsLoading && <Loader />}
      </div>
    </div>
  )
}

export default AIChatPage
