import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link, MessagesButton, Tooltip, Typography, VaultButton } from 'components'
import TableCell from '@material-ui/core/TableCell'
import classNames from 'classnames'
import { colors, CONSTANTS } from 'constants/index'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { navigateToCaseDetails, navigateToEditCase, navigateToProConnectionsInNewTab } from 'store/actions/navigation'
import { goToZendesk } from 'store/actions/common'

const useStyles = makeStyles({
  bulletCell: {
    width: 10,
  },
  circle: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    backgroundColor: colors.BASIC.COLOR[20],
  },
  activeBullet: {
    backgroundColor: colors.SUCCESS,
  },
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR[80],
    color: colors.WHITE,
    fontSize: 13,
    fontWeight: 'normal',
    textTransform: 'lowercase',
  },
  flatFee: {
    color: colors.PRIMARY.COLOR['50'],
  },
  inactiveCase: {
    color: colors.BASIC.COLOR[20],
  },
  feeType: {
    cursor: 'pointer',
    fontWeight: 500,
  },
  btnContainer: {
    display: 'flex',
  },
  messageBtn: {
    marginLeft: 8,
  },
})

const TooltipText = ({ goToZendesk }) => (
  <span>
    You can not send messages until your license is approved. Please contact <Link onClick={goToZendesk}>XIRA</Link>{' '}
    admin for details.
  </span>
)

const ProCaseTableRow = ({ data, timezone, profileIsApproved }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partiallyFlat = !data.is_flat_fee_case_fully_paid && data.fee_type === 1
  const feeTypeValue = (CONSTANTS.FEE_TYPES[data.fee_type] && CONSTANTS.FEE_TYPES[data.fee_type].value) || ''
  const feeTypeLabel = partiallyFlat
    ? 'Flat fee partially scheduled'
    : (CONSTANTS.FEE_TYPES[data.fee_type] && CONSTANTS.FEE_TYPES[data.fee_type].label) || ''

  const navigateToDetails = () => {
    if (!data.is_active || data.fee_type === CONSTANTS.FEE_TYPES['0'].key) {
      dispatch(navigateToCaseDetails(data.uuid))
    } else {
      dispatch(navigateToEditCase(data.uuid))
    }
  }

  const navigateToConnections = () => {
    dispatch(
      navigateToProConnectionsInNewTab({
        clientName: data.client_name,
      })
    )
  }

  const clientName = data.is_client_deleted ? (
    <Typography variant="body2">{data.client_name}</Typography>
  ) : (
    <Link className={classes.link} onClick={navigateToConnections}>
      {data.client_name}
    </Link>
  )

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  const getDisabledMessageTooltip = () => {
    if (!profileIsApproved) {
      return <TooltipText goToZendesk={zendeskSso} />
    } else if (!data.is_ready_for_confidential) {
      return CONSTANTS.DISABLED_MESSAGES_TOOLTIP
    } else if (!data.is_vault_or_chat_available) {
      return CONSTANTS.DISABLED_MESSAGES_INVITED_TOOLTIP
    }
  }

  return (
    <>
      <TableCell className={classes.bulletCell}>
        <div
          className={classNames({
            [classes.circle]: true,
            [classes.activeBullet]: data.is_active,
          })}
        />
      </TableCell>
      <TableCell>
        <Link className={classes.link} onClick={navigateToDetails}>
          {data.name}
        </Link>
      </TableCell>
      <TableCell>{clientName}</TableCell>
      <TableCell>
        <Tooltip
          tooltipClass={classes.tooltip}
          childrenClass={classNames({ [classes.flatFee]: partiallyFlat })}
          title={feeTypeLabel}
          placement="bottom-start"
        >
          {feeTypeValue}
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="body2" className={classNames({ [classes.inactiveCase]: !data.is_active })}>
          {data.practice_area_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" className={classNames({ [classes.inactiveCase]: !data.is_active })}>
          {moment(data.updated_at)
            .tz(timezone)
            .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
        </Typography>
      </TableCell>
      <TableCell>
        <div className={classes.btnContainer}>
          <VaultButton
            isInitialCase={data.fee_type === CONSTANTS.FEE_TYPES['0'].key}
            options={{
              userID: data.client,
              caseID: data.uuid,
            }}
            vaultPlace="cases"
          />
          <MessagesButton
            className={classes.messageBtn}
            chatId={data ? data.twilio_chat_sid : ''}
            disabledText={getDisabledMessageTooltip()}
            disabled={!profileIsApproved || !data.is_ready_for_confidential || !data.is_vault_or_chat_available}
            place="cases"
          />
        </div>
      </TableCell>
    </>
  )
}

export default ProCaseTableRow
