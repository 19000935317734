import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, FormikCheckbox, FormikInput, Link } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import TermsOfServiceLink from './termsOfServiceLink'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: 368,
  },
  labelClassesControl: {
    margin: '0 0 0 -2px',
  },
  checkBox: {
    color: colors.BASIC.COLOR[50],
    padding: 0,
    marginRight: 8,
  },
  checkBoxLabelText: {
    cursor: 'pointer',
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
    lineHeight: 1.6,
    marginTop: 5,
  },
})

const SocialSignUp = ({ firstName, lastName, onFormSubmit, disabledContinue }) => {
  const classes = useStyles()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const labelClassesControl = {
    root: classes.labelClassesControl,
  }
  const labelTextClasses = {
    root: classes.checkBoxLabelText,
  }
  const checkBoxClasses = {
    root: classes.checkBox,
  }
  const onSubmit = values => {
    onFormSubmit(values)
  }
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        acceptTerms: false,
      }}
      validateOnBlur
      validateOnChange={false}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .required('please type your first name')
          .trim()
          .max(50, 'First Name should be maximum 50 characters'),
        lastName: Yup.string()
          .required('please type your last name')
          .trim()
          .max(50, 'Last Name should be maximum 50 characters'),
        acceptTerms: Yup.boolean()
          .required('Required')
          .oneOf([true], 'You must accept the terms and conditions.'),
      })}
    >
      <Form className={classes.signUpForm}>
        <FormikInput variant="outlined" size="large" name="firstName" label="First Name" type="text" />
        <FormikInput variant="outlined" size="large" name="lastName" label="Last Name" type="text" />
        <FormikCheckbox
          name="acceptTerms"
          checked={acceptTerms || false}
          label="I accept XIRA"
          classes={checkBoxClasses}
          labelClasses={labelTextClasses}
          labelClassesControl={labelClassesControl}
          link={<TermsOfServiceLink />}
          onClick={e => setAcceptTerms(e.target.checked)}
        />
        <Button
          size="large"
          variant="primary"
          type="submit"
          disabled={disabledContinue}
          isButtonBlocked={disabledContinue}
        >
          Continue
        </Button>
      </Form>
    </Formik>
  )
}

export default SocialSignUp
