import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, DownloadPDF } from 'components'
import { colors } from 'constants/colors'
import file from '../../static/Booking_policy.pdf'

const useStyles = makeStyles({
  container: {
    width: '792px',
    margin: '0 auto 70px',
  },
  title: {
    margin: '45px 0 40px 0',
    fontSize: 36,
  },
  policyList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  policyItem: {
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 32,
    },
  },
  subtitle: {
    marginBottom: 8,
    fontSize: 20,
  },
  titleDesc: {
    marginBottom: 10,
    fontWeight: 400,
    fontSize: 15,
  },
  desc: {
    fontWeight: 400,
    fontSize: 15,
  },
  update: {
    color: colors.BASIC.COLOR[70],
    fontWeight: 500,
  },
})

const BOOKING_POLICY_TITLE = 'XIRA Booking Policy'
const FIRST_POLICY_TITTLE = '1. Initial consultation charges'
const FIRST_POLICY_DESC = `For certain lawyers or practice areas, there may be a charge for an initial consultation.  This is always disclosed on the lawyer’s profile.  When there is a charge for an initial consultation, a temporary hold will be applied to your account that is equal to the initial consultation fee listed in the lawyer’s profile.  Once the initial consultation is concluded, you will be charged the initial consultation fee listed in the lawyer’s profile.`
const SECOND_POLICY_TITLE = '2. Initial consultation late cancellation and no show policy'
const SECOND_POLICY_DESC_PART_1 =
  'There is no charge for any initial consultation canceled more than 24 hours in advance.'
const SECOND_POLICY_DESC_PART_2 = `If the initial consultation is chargeable and you cancel your initial consultation with less than 24 hours’ notice, you will incur an automatic charge equal to the initial consultation fee listed in the lawyer’s profile.  If you rescheduled with the same lawyer (only one reschedule per initial consultation is allowed) or if the lawyer reschedules the initial consultation with you at any time, a fee will not be charged.`
const SECOND_POLICY_DESC_PART_3 = `If the initial consultation is chargeable and you do not attend (no show) the initial video/audio conference or do not join within 15 minutes of the start of the meeting, you will incur a no show charge equal to the initial consultation fee listed in the lawyer’s profile.  Note: You should wait at least 15 minutes after the start of the meeting for your lawyer to join before leaving to avoid charges.`
const THIRD_POLICY_TITLE = '3. Other meetings'
const THIRD_POLICY_DESC = `XIRA does not charge a fee for cancellation of any meeting after your initial consultation with a lawyer. However, your lawyer may charge cancellation fees and his/her discretion.`
const LAST_UPDATE = 'Last Updated: October 8, 2020'

const BookingPolicyPage = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          {BOOKING_POLICY_TITLE}
        </Typography>
        <ul className={classes.policyList}>
          <li className={classes.policyItem}>
            <Typography variant="h4" className={classes.subtitle}>
              {FIRST_POLICY_TITTLE}
            </Typography>
            <Typography variant="body1" className={classes.desc}>
              {FIRST_POLICY_DESC}
            </Typography>
          </li>
          <li className={classes.policyItem}>
            <Typography variant="h4" className={classes.subtitle}>
              {SECOND_POLICY_TITLE}
            </Typography>
            <Typography variant="body1" className={classes.titleDesc}>
              {SECOND_POLICY_DESC_PART_1}
            </Typography>
            <Typography variant="body1" className={classes.titleDesc}>
              {SECOND_POLICY_DESC_PART_2}
            </Typography>
            <Typography variant="body1" className={classes.desc}>
              {SECOND_POLICY_DESC_PART_3}
            </Typography>
          </li>
          <li className={classes.policyItem}>
            <Typography variant="h4" className={classes.subtitle}>
              {THIRD_POLICY_TITLE}
            </Typography>
            <Typography variant="body1" className={classes.desc}>
              {THIRD_POLICY_DESC}
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" className={classes.update}>
          {LAST_UPDATE}
        </Typography>
      </div>
      <DownloadPDF file={file} name="XIRA Booking Policy" />
    </>
  )
}

export default BookingPolicyPage
