import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Icon } from 'components'
import { colors } from 'constants/index'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  root: {
    borderBottom: 'none',
  },
  buttonGroup: {
    marginLeft: 12,
  },
  button: {
    padding: '10px 10px',
    minWidth: 32,
    backgroundColor: colors.BASIC.COLOR[0],
  },
})

function TablePaginationActions(props) {
  const classes = useStyles()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Button onClick={handleBackButtonClick} disabled={page === 0} className={classes.button}>
        <Icon iconClass="las la-angle-left" />
      </Button>
      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        className={classes.button}
      >
        <Icon iconClass="las la-angle-right" />
      </Button>
    </ButtonGroup>
  )
}

const XTablePagination = props => {
  const classes = useStyles()
  const {
    count,
    rowsPerPage,
    onRowsPerPageChange,
    onPageChange,
    colSpan,
    page,
    rowsPerPageOptions = [10, 20, 50],
  } = props

  const handleChangePage = (event, newPage) => {
    onPageChange(newPage)
  }

  const handleChangeRowsPerPage = event => {
    onRowsPerPageChange(parseInt(event.target.value, 10))
    onPageChange(0)
  }

  return (
    <TableRow>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={colSpan}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
        }}
        labelDisplayedRows={({ from, to, count }) => `${from} – ${to} of ${count} items`}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        classes={{
          root: classes.root,
        }}
      />
    </TableRow>
  )
}

export default XTablePagination
