import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { VaultMenu, VaultCases, VaultLog, VaultTrash } from 'components'
import { colors } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  vaultBodyContainer: {
    paddingTop: 56,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  vaultTabs: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 64,
    backgroundColor: colors.BASIC.COLOR['0'],
    borderRight: `1px solid ${colors.BASIC.COLOR['10']}`,
  },
  vaultBody: {
    width: '100%',
    display: 'flex',
  },
})

const VaultModalBody = ({ vaultTab, handleTabChange, previewItem, isClientVault }) => {
  const classes = useStyles()

  return (
    <div className={classes.vaultBodyContainer}>
      <div className={classes.vaultTabs}>
        {!previewItem && (
          <VaultMenu vaultTab={vaultTab} handleTabChange={handleTabChange} isClientVault={isClientVault} />
        )}
      </div>
      <div className={classNames(classes.vaultBody)}>
        {
          {
            0: <VaultCases previewItem={previewItem} />,
            1: <VaultLog />,
            2: <VaultTrash />,
          }[vaultTab]
        }
      </div>
    </div>
  )
}

export default VaultModalBody
