import React from 'react'
import { Button, ColorCircularProgress, Icon, PracticeAreas, Typography, DataLossWarning } from 'components'
import { connect } from 'react-redux'
import { addPracticeAreas, savePracticeAreas } from 'store/actions/profileCreation'
import withStyles from '@material-ui/core/styles/withStyles'
import { colors, REGEXP } from 'constants/index'
import { isEqual } from 'lodash'
import { openSnackbar } from 'store/actions/common'
import classNames from 'classnames'

const styles = {
  checkboxLabel: {
    marginBottom: 0,
  },
  panelValid: {
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  panel: {
    boxShadow: 'none',
    '&::before': {
      background: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    width: 900,
  },
  panelError: {
    border: `solid 1px ${colors.ERROR}`,
    backgroundColor: colors.WHITE,
  },
  validationMessage: {
    color: colors.ERROR,
    margin: '10px 0 20px 0',
  },
  practiceAreasContainer: {
    paddingBottom: 50,
  },
  practiceAreasLabel: {
    marginBottom: 24,
  },
  practiceAreasBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  practiceAreasSteps: {
    width: 810,
  },
  practiceAreasButtonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginTop: 24,
  },
  practiceAreasCancelBtn: {
    marginRight: 40,
  },
  practiceAreasSaveBtn: {
    width: 84,
    minWidth: 84,
  },
}

class PracticeAreasContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null,
      invalidAreas: [],
    }
  }

  componentWillUnmount() {
    this.props.addProfilePracticeAreas(this.props.practiceAreasDB)
  }

  onCancelClick = () => {
    this.props.executeScroll()
    this.props.addProfilePracticeAreas(this.props.practiceAreasDB)
    this.setState({ errorMessage: null, invalidAreas: [] })
  }

  onSaveClick = () => {
    const invalidPracticeAreas = []
    let valid = true
    if (this.props.practiceAreas.some(area => area.checked)) {
      this.props.practiceAreas
        .filter(area => area.checked)
        .forEach(area => {
          const hourlyRateError =
            !area.hourlyRate ||
            area.hourlyRate <= 0 ||
            REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(area.hourlyRate) ||
            !REGEXP.fiveDecimals.test(area.hourlyRate)
          const initialConsultationError =
            area.consultationCost &&
            (REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(area.consultationCost) ||
              !REGEXP.fiveDecimals.test(area.consultationCost))

          if (!area.subSpecialities.some(sub => sub.checked) || hourlyRateError || initialConsultationError) {
            valid = false
            invalidPracticeAreas.push(area.uuid)
          }
        })
      if (valid) {
        this.setState({ errorMessage: null, invalidAreas: [] })
        this.props
          .saveProfilePracticeAreas()
          .then(response => {
            this.props.showSnackbar('success', 'Practice Areas were updated')
          })
          .catch(e => {
            this.props.showSnackbar('error', 'There was some error while changing practice areas')
          })
      } else {
        this.props.executeScroll()
        this.setState({
          errorMessage: `Please specify practice area details`,
          invalidAreas: invalidPracticeAreas,
        })
      }
    } else {
      this.setState({ errorMessage: 'please select at least one practice area' })
    }
    this.props.executeScroll()
  }

  render() {
    const { classes, addProfilePracticeAreas, practiceAreas, practiceAreasDB } = this.props
    return this.props.arePracticeAreasLoading ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.practiceAreasContainer}>
        <DataLossWarning whenCondition={!isEqual(practiceAreas, practiceAreasDB)} path="/pro/account/profile/areas" />
        <Typography variant="h4" className={classes.practiceAreasLabel}>
          Practice Areas
        </Typography>
        {this.state.errorMessage && (
          <Typography variant="subtitle2" className={classes.validationMessage}>
            <Icon iconClass="las las la-exclamation-triangle" />
            {this.state.errorMessage}
          </Typography>
        )}
        <div className={classes.practiceAreasBody}>
          <div className={classes.practiceAreasSteps}>
            <PracticeAreas
              addProfilePracticeAreas={addProfilePracticeAreas}
              labelClasses={{
                root: classes.checkboxLabel,
              }}
              panelClasses={{
                root: classNames(classes.panel, classes.panelValid),
              }}
              practiceAreas={practiceAreas}
              errorPanelClasses={{
                root: classNames(classes.panel, classes.panelError),
              }}
              setInvalidAreas={areas => this.setState({ invalidAreas: areas })}
              setErrorMessage={message => this.setState({ errorMessage: message })}
              invalidAreas={this.state.invalidAreas}
              key={this.state.invalidAreas.length}
            />
          </div>
          {this.props.practiceAreas && this.props.practiceAreas.length ? (
            <div className={classes.practiceAreasButtonContainer}>
              <Button
                className={classes.practiceAreasCancelBtn}
                type="reset"
                variant="text"
                disabled={isEqual(practiceAreas, practiceAreasDB)}
                onClick={this.onCancelClick}
              >
                Cancel
              </Button>
              <Button
                className={classes.practiceAreasSaveBtn}
                type="submit"
                variant="primary"
                disabled={isEqual(practiceAreas, practiceAreasDB)}
                onClick={this.onSaveClick}
              >
                Save
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ profileCreation }) => ({
  practiceAreas: profileCreation.practiceAreas,
  practiceAreasDB: profileCreation.practiceAreasDB,
  arePracticeAreasLoading: profileCreation.arePracticeAreasLoading,
})

const mapDispatchToProps = dispatch => ({
  addProfilePracticeAreas: practiceAreas => dispatch(addPracticeAreas(practiceAreas)),
  saveProfilePracticeAreas: () => dispatch(savePracticeAreas()),
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PracticeAreasContainer))
