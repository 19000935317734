import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, FormikInput, Modal, Typography } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import { useSelector } from 'react-redux'
import { UserAddressFormFragment } from './UserAdressFormFragment'

const useStyles = makeStyles({
  invitedClientInfo: {
    width: 832,
    padding: '32px 40px 21px 40px',
  },
  inviteModalTitle: {
    color: colors.BASIC.COLOR[80],
  },
  inviteModalContainer: {
    width: 550,
    padding: 32,
    textAlign: 'center',
  },
  contactTitle: {
    marginTop: 24,
    marginBottom: 16,
  },
  addressTitle: {
    marginTop: 31,
    marginBottom: 16,
  },
  inviteSubFormTitle: {
    fontSize: 13,
    lineHeight: 1.08,
    letterSpacing: 0.6,
    color: colors.BASIC.COLOR[50],
  },
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  twoColumnInput: {
    width: 360,
  },
  phoneInput: {
    height: '40px !important',
  },
  saveButton: {
    width: 80,
    marginLeft: 24,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inviteModalText: {
    margin: '16px 0px 24px 32px',
    fontSize: 15,
    textAlign: 'left',
  },
  boldModalText: {
    width: 305,
    margin: '20px auto 24px',
  },
  buttonsFooter: {
    textAlign: 'right',
  },
  dialogTitle: {
    textAlign: 'left',
    display: 'flex',
    marginBottom: '16px',
  },
  dialogText: {
    margin: '0 0 0 8px',
  },
  inviteOneMoreButton: {
    marginRight: 16,
  },
  dialogLogo: {
    color: '#F55838',
  },
})

const InviteClientPopup = ({ open, onClose, onSubmit, inviteInfo, isCreateMode, resendInvite }) => {
  const classes = useStyles()
  const isSendInviteLink = useSelector(state => state.connections.isSendInviteLink)
  const states = useSelector(state => state.profileCreation.states)

  const validationSchema = Yup.object().shape(
    {
      first_name: Yup.string()
        .trim()
        .max(50, 'First name should be maximum 50 characters')
        .required('Please fill out this field'),
      last_name: Yup.string()
        .trim()
        .max(50, 'Last name should be maximum 50 characters')
        .required('Please fill out this field'),
      username: Yup.string()
        .email('Looks like this email is incomplete')
        .trim()
        .required('Please enter email'),
      street: Yup.string().when(['suite', 'city', 'state', 'zip', 'phone'], {
        is: (suite, city, state, zip, phone) =>
          (suite && suite.length > 0) ||
          (city && city.length > 0) ||
          (state && state.length > 0) ||
          (zip && zip.length > 0) ||
          (phone && phone.length > 0 && phone !== '+1'),
        then: Yup.string()
          .trim()
          .max(100, 'Please ensure this field has no more than 100 characters')
          .required('Please fill in the field'),
        otherwise: Yup.string(),
      }),
      suite: Yup.string()
        .trim()
        .max(50, 'Please ensure this field has no more than 50 characters'),
      city: Yup.string().when(['street', 'suite', 'state', 'zip', 'phone'], {
        is: (street, suite, state, zip, phone) =>
          (street && street.length > 0) ||
          (suite && suite.length > 0) ||
          (state && state.length > 0) ||
          (zip && zip.length > 0) ||
          (phone && phone.length > 0 && phone !== '+1'),
        then: Yup.string()
          .trim()
          .max(50, 'Please ensure this field has no more than 50 characters')
          .required('Please fill in the field'),
        otherwise: Yup.string(),
      }),
      state: Yup.string().when(['street', 'suite', 'city', 'zip', 'phone'], {
        is: (street, suite, city, zip, phone) =>
          (street && street.length > 0) ||
          (suite && suite.length > 0) ||
          (city && city.length > 0) ||
          (zip && zip.length > 0) ||
          (phone && phone.length > 0 && phone !== '+1'),
        then: Yup.string()
          .trim()
          .required('Please fill in the field'),
        otherwise: Yup.string(),
      }),
      zip: Yup.string().when(['street', 'suite', 'city', 'state', 'phone'], {
        is: (street, suite, city, state, phone) =>
          (street && street.length > 0) ||
          (suite && suite.length > 0) ||
          (city && city.length > 0) ||
          (state && state.length > 0) ||
          (phone && phone.length > 0 && phone !== '+1'),
        then: Yup.string()
          .typeError('Please enter valid ZIP code (5 digits)')
          .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
          .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
          .required('Please fill in the field'),
        otherwise: Yup.string(),
      }),
      phone: Yup.string().when(['street', 'suite', 'city', 'state', 'zip'], {
        is: (street, suite, city, state, zip) =>
          (street && street.length > 0) ||
          (suite && suite.length > 0) ||
          (city && city.length > 0) ||
          (state && state.length > 0) ||
          (zip && zip.length > 0),
        then: Yup.string()
          .test('len', 'Please enter 10 digits', val =>
            val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
          )
          .required('Please fill in the field'),
        otherwise: Yup.string(),
      }),
    },
    [
      ['street', 'city'],
      ['street', 'state'],
      ['street', 'zip'],
      ['street', 'phone'],
      ['city', 'state'],
      ['city', 'zip'],
      ['city', 'phone'],
      ['state', 'zip'],
      ['state', 'phone'],
      ['zip', 'phone'],
    ]
  )

  const onSubmitForm = payload => {
    const { first_name, last_name, username, street } = payload
    let inviteData = {
      invited_user: {
        first_name,
        last_name,
        username,
      },
    }
    if (street) {
      const { suite, city, country, state, zip, phone } = payload
      inviteData = {
        ...inviteData,
        contact_info: {
          street,
          suite,
          city,
          country,
          state,
          zip,
          phone,
        },
      }
    }
    onSubmit(inviteData)
  }

  return (
    <Modal open={open} handleClose={onClose} maxWidth={false}>
      <div className={classes.invitedClientInfo}>
        <Typography className={classes.inviteModalTitle} variant="h3">
          {isCreateMode ? 'Add/Invite client' : 'Edit invited client info'}
        </Typography>
        <div className={classes.contactTitle}>
          <Typography className={classes.inviteSubFormTitle} variant="h5">
            CONTACT
          </Typography>
        </div>
        <Formik
          initialValues={inviteInfo}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, actions) => {
            onSubmitForm(values)
          }}
        >
          <Form>
            <div className={classes.twoColumn}>
              <FormikInput
                variant="outlined"
                name="first_name"
                label="First name"
                fullWidth
                type="text"
                containerClasses={classes.twoColumnInput}
              />
              <FormikInput
                variant="outlined"
                name="last_name"
                label="Last name"
                fullWidth
                type="text"
                containerClasses={classes.twoColumnInput}
              />
            </div>
            <FormikInput variant="outlined" name="username" label="Email address" type="text" fullWidth />
            <div className={classes.addressTitle}>
              <Typography className={classes.inviteSubFormTitle} variant="h5">
                PHONE NUMBER AND ADDRESS (optional)
              </Typography>
            </div>
            <UserAddressFormFragment states={states} />
            <div className={classes.buttons}>
              <div>
                {!isCreateMode && (
                  <Button variant="text" disabled={isSendInviteLink} onClick={resendInvite}>
                    resend invitation link
                  </Button>
                )}
              </div>
              <div>
                <Button variant="text" disabled={isSendInviteLink} onClick={onClose}>
                  cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className={classes.saveButton}
                  isButtonBlocked={isSendInviteLink}
                  disabled={isSendInviteLink}
                  circularSize={22}
                >
                  {isCreateMode ? 'Submit' : 'Save'}
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}

export default InviteClientPopup
