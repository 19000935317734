import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import {
  ViewCalendarCardBody,
  ConnectCalendarCardBody,
  ColorCircularProgress,
  ConnectedCalendarCardBody,
  XIRACalendarCardBody,
} from 'components'
import { colors, CONSTANTS } from 'constants/index'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { refreshCalendar } from 'store/actions/calendar'

const useStyles = makeStyles({
  card: {
    width: '33%',
    position: 'relative',
    backgroundColor: colors.BASIC.COLOR[0],
    marginRight: 24,
    cursor: 'pointer',
    boxShadow: 'none',
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    '&:hover': {
      boxShadow: `0 2px 16px 0 rgba(87, 87, 87, 0.2)`,
    },
  },
  cardContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  viewCalendarContainer: {
    minHeight: 350,
  },
  connectMode: {
    height: 'fit-content',
  },
})

const CalendarCard = ({
  calendarSettings,
  setCalendarModalOptions,
  setReconnectModalOptions,
  selectedCalendarList,
  isCalendarLoading,
  xiraCalendar,
  mode,
  ...props
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [hover, setHover] = useState(false)
  const isCalendarSubListLoading = useSelector(state => state.calendar.isCalendarSubListLoading)
  const calendarsConnectionError = useSelector(state => state.calendar.calendarsConnectionError)
  const calendar = calendarSettings.provider ? calendarSettings : CONSTANTS.CALENDAR_SETTINGS[calendarSettings]
  const onCardClick = async () => {
    if (setCalendarModalOptions) {
      try {
        const updatedCalendar = await dispatch(refreshCalendar(calendar.provider))
        setCalendarModalOptions({ open: true, calendar: updatedCalendar })
      } catch (e) {
        setCalendarModalOptions({ open: true, calendar })
      } finally {
        setHover(false)
      }
    }
  }
  const getCardContent = () => {
    if (xiraCalendar) {
      return (
        <XIRACalendarCardBody
          XIRACalendar={xiraCalendar}
          onEditClick={props.onEditClick}
          calendarsConnectionError={calendarsConnectionError}
        />
      )
    } else if (setCalendarModalOptions) {
      return isCalendarSubListLoading.loading && isCalendarSubListLoading.provider === calendar.provider ? (
        <ColorCircularProgress />
      ) : (
        <ViewCalendarCardBody
          calendar={calendar}
          onCardClick={onCardClick}
          calendarsConnectionError={calendarsConnectionError}
          onReconnectClick={setReconnectModalOptions}
        />
      )
    } else {
      const selected = selectedCalendarList.find(selected => selected.provider === calendar.provider)
      if (selected) {
        return (
          <ConnectedCalendarCardBody
            calendar={selected}
            setCalendar={props.setCalendar}
            removeSelectedCalendar={props.removeSelectedCalendar}
          />
        )
      } else return <ConnectCalendarCardBody calendar={calendar} hover={hover} {...props} />
    }
  }
  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classNames(
        classes.card,
        { [classes.viewCalendarContainer]: calendarSettings.provider && !xiraCalendar },
        { [classes.connectMode]: mode === 'connect' }
      )}
    >
      <CardContent
        classes={{
          root: classNames(classes.cardContent, {
            [classes.viewCalendarContainer]: calendarSettings.provider && !xiraCalendar,
          }),
        }}
      >
        {getCardContent()}
      </CardContent>
    </Card>
  )
}

export default CalendarCard
