import React, { useMemo } from 'react'
import { Button, ProInfoClientCard, VaultButton, MessagesButton } from 'components'
import { navigateToSearchProAccount } from 'store/actions/navigation'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { routes, CONSTANTS } from 'constants/index'

const useStyles = makeStyles({
  connectionsCard: {
    width: 588,
    minHeight: 240,
  },
  buttonGroup: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttons: {
    marginLeft: 12,
    padding: '5px 12px',
    height: 32,
    minHeight: 32,
    fontSize: 11,
  },
  buttonPrimary: {
    marginLeft: 12,
  },
  messageBtn: {
    marginLeft: 8,
  },
  '@media(max-width:1280px)': {
    connectionsCard: {
      margin: '0 12px 24px !important',
      width: 'calc(50% - 24px)',
    },
  },
  '@media(max-width:939px)': {
    connectionsCard: {
      width: 'calc(100% - 24px)',
    },
  },
  '@media(max-width:767px)': {
    connectionsCard: {
      margin: '0 0 16px !important',
      width: '100%',
    },
  },
})

const ClientConnectionsCard = ({ connection, isArchived, onArchive }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const proName = `${connection.proInfo.firstName} ${connection.proInfo.lastName}`

  const navigateToProPage = () => {
    dispatch(navigateToSearchProAccount(connection.pro))
  }

  const getButtons = useMemo(
    () => (
      <div className={classes.buttonGroup}>
        <VaultButton
          options={{
            proID: connection.pro,
            proName: proName,
            isClientVault: true,
            proTwilioChatID: connection ? connection.twilio_chat_sid : '',
          }}
          disabled={!(connection.vaultPlanAccepted && connection.hasClientFolder)}
          vaultPlace="connections"
        />
        <MessagesButton
          className={classes.messageBtn}
          chatId={connection ? connection.twilio_chat_sid : ''}
          place="connections"
          disabled={!connection.readyForMessages}
          disabledText={CONSTANTS.DISABLED_MESSAGES_TOOLTIP}
        />
        <Button
          variant="primary"
          className={classes.buttons}
          component={RouterLink}
          to={`${routes.SEARCH_PAGE}/${connection.pro}`}
        >
          book a meeting
        </Button>
      </div>
    ),
    []
  )

  return (
    <ProInfoClientCard
      proInfo={connection.proInfo}
      navigateToProPage={navigateToProPage}
      cardStyle={classes.connectionsCard}
      buttons={getButtons}
      checkmark={connection.isRecommended}
      isInitialMeetingSuccessfull={connection.isInitialMeetingSuccessfull}
      onArchive={onArchive}
      isArchived={isArchived}
    />
  )
}

export default ClientConnectionsCard
