export const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

//snackbar placeholders
export const VERIFICATION_EMAIL_RESEND_SUCCESS = 'Verification email has been resent successfully!'
export const VERIFICATION_EMAIL_REQUEST_SUCCESS = 'The verification link has been sent to your email'
export const SOMETHING_WENT_WRONG = 'Something went wrong'

//statuses
export const SUCCESS_SNACKBAR = 'success'
export const ERROR_SNACKBAR = 'error'
export const WARNING_SNACKBAR = 'warning'

//show header
export const SHOW_SEARCH_IN_START_PAGE = 'SHOW_SEARCH_IN_START_PAGE'
export const SHOW_SEARCH_IN_HEADER = 'SHOW_SEARCH_IN_HEADER'

//languages
export const SET_ALL_LANGUAGES = 'SET_ALL_LANGUAGES'

//calendars
export const SET_ADVANCED_CALENDAR_VALUES = 'SET_ADVANCED_CALENDAR_VALUES'

//not found
export const SHOW_NOT_FOUND = 'SHOW_NOT_FOUND'
export const HIDE_NOT_FOUND = 'HIDE_NOT_FOUND'

//login, signup
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN'
export const TOGGLE_SIGNUP = 'TOGGLE_SIGNUP'
export const TOGGLE_RESTORE = 'TOGGLE_RESTORE'
export const TOGGLE_FORGOT_PASS_EMAIL = 'TOGGLE_FORGOT_PASS_EMAIL'
export const SET_REDIRECT = 'SET_REDIRECT'

//export
export const EXPORT_FILE_START = 'EXPORT_FILE_START'
export const EXPORT_FILE_END = 'EXPORT_FILE_END'

//error modal
export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL'
export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL'

//filter
export const SHOW_FILTER = 'SHOW_FILTER'

//sort
export const SHOW_SORT = 'SHOW_SORT'

export const SET_CALENDAR_TAB = 'SET_CALENDAR_TAB'

//show mobile menu
export const SET_MOBILE_MENU_OPEN = 'SET_MOBILE_MENU_OPEN'
export const SET_MOBILE_MENU_CLOSE = 'SET_MOBILE_MENU_CLOSE'

export const TICKET_UPLOAD_START = 'TICKET_UPLOAD_START'
export const TICKET_UPLOAD_END = 'TICKET_UPLOAD_END'

//resend password username
export const SET_USERNAME_FOR_RESEND_PASSWORD = 'SET_USERNAME_FOR_RESEND_PASSWORD'

//sessionID
export const SET_SESSION_ID = 'SET_SESSION_ID'

export const OPEN_ADMIN_MESSAGE_MODAL = 'OPEN_ADMIN_MESSAGE_MODAL'
export const CLOSE_ADMIN_MESSAGE_MODAL = 'CLOSE_ADMIN_MESSAGE_MODAL'

export const TOGGLE_MOBILE_HINT = 'TOGGLE_MOBILE_HINT'
