import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import {
  ContactInfoSection,
  LicensingSection,
  UserPersonalInfo,
  ProRatingModal,
  ColorCircularProgress,
  DataLossWarning,
} from 'components'
import { changeProfileContactInfo, updateProfileContactInfo, saveContactInfo } from 'store/actions/profileCreation'
import { saveUserLanguages, updateLocalLanguages } from 'store/actions/accountProfile'
import { ValidationAlert } from '../../profileCreation/modals'
import { openSnackbar, goToZendesk } from 'store/actions/common'
import { isEqual } from 'lodash'
import { withRouter } from 'react-router-dom'
import {
  getProReviews,
  proReviewModalClose,
  proReviewModalOpen,
  setReviewCommentsFilter,
  setReviewCommentsSorting,
} from 'store/actions/proReview/proReview'
import { navigateTo } from 'store/actions/navigation'

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  personalInfo: {
    width: 380,
    paddingRight: 20,
  },
  profileInfo: {
    width: '100%',
    paddingLeft: 20,
  },
  section: {
    marginBottom: 48,
  },
  lastSection: {
    marginBottom: 32,
  },
})

class AccountProfileGeneralInformationContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsgs: [],
      isValidationModalOpen: false,
      validationDialogTitle: '',
      commentID: new URLSearchParams(this.props.location.search).get('comment_uuid'),
    }
  }

  onValidationAlertClose() {
    this.setState({
      isValidationModalOpen: false,
    })
  }

  setErrorMessage(msgs, title) {
    this.setState({
      errorMsgs: msgs,
      isValidationModalOpen: true,
      validationDialogTitle: title,
    })
  }

  handleRatingModalClose = () => {
    this.props.proReviewModalClose()
    this.props.navigateTo(this.props.location.pathname)
  }

  handleRatingModalOpen = filterValue => {
    this.props.proReviewModalOpen()
    this.props.setReviewCommentsFilter(filterValue || null)
    this.props.setReviewCommentsSorting('created_at', false)
    this.props.getProReviews()
  }

  componentDidMount() {
    if (this.state.commentID) {
      this.handleRatingModalOpen()
    }
  }

  componentWillUnmount() {
    this.props.updateLocalContactInfo({
      ...this.props.contactInfo,
    })
    this.props.updateLocalUserLanguages(this.props.languages)
  }

  render() {
    const {
      classes,
      states,
      licensing,
      contactInfo,
      localContactInfo,
      changeContactInfo,
      updateLocalContactInfo,
      saveProfileContactInfo,
      languages,
      saveCurrentUserLanguages,
      adminEmail,
      showSnackbar,
      statesAreLoading,
      licensingAreLoading,
      contactInfoIsLoading,
      languagesAreLoading,
      profileIsLoading,
      deleteRequestIsloading,
      executeScroll,
      updateLocalUserLanguages,
      languagesDB,
      goToZendesk,
      isProReviewLoading,
      reviewModalOpen,
      rating,
      reviews,
      proReviewComments,
      proReviewStats,
      proReviewCommentsCount,
      userInfo,
    } = this.props
    const { isValidationModalOpen, errorMsgs, validationDialogTitle } = this.state

    const { is_phone_confirmed: _c, optional_phone, ...contactInfoCompare } = contactInfo
    const { is_phone_confirmed: _lc, ...localContactInfoCompare } = localContactInfo

    return statesAreLoading ||
      licensingAreLoading ||
      contactInfoIsLoading ||
      languagesAreLoading ||
      deleteRequestIsloading ||
      (isProReviewLoading && !reviewModalOpen) ||
      profileIsLoading ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.root}>
        <DataLossWarning
          whenCondition={!isEqual(contactInfoCompare, localContactInfoCompare) || !isEqual(languages, languagesDB)}
          path="/pro/account/profile/general"
        />
        <UserPersonalInfo
          handleRatingModalOpen={this.handleRatingModalOpen}
          proReviewCommentsCount={proReviewCommentsCount}
          className={classes.personalInfo}
          setErrorMessage={(msgs, title) => this.setErrorMessage(msgs, title)}
        />

        <div className={classes.profileInfo}>
          <LicensingSection
            states={states}
            licensing={licensing}
            containerClass={classes.section}
            adminEmail={adminEmail}
            goToZendesk={goToZendesk}
          />
          <ContactInfoSection
            updateLocalContactInfo={updateLocalContactInfo}
            containerClass={classes.section}
            contactInfo={contactInfo}
            changeContactInfo={changeContactInfo}
            states={states}
            saveProfileContactInfo={saveProfileContactInfo}
            executeScroll={executeScroll}
            showSnackbar={showSnackbar}
            languages={languages}
            languagesDB={languagesDB}
            saveCurrentUserLanguages={saveCurrentUserLanguages}
            updateLocalUserLanguages={updateLocalUserLanguages}
          />
        </div>
        <ValidationAlert
          title={validationDialogTitle}
          messages={errorMsgs}
          isOpen={isValidationModalOpen}
          onClose={() => this.onValidationAlertClose()}
        />
        <ProRatingModal
          open={reviewModalOpen}
          handleClose={this.handleRatingModalClose}
          reviews={reviews}
          rating={rating}
          comments={proReviewComments}
          stats={proReviewStats}
          proReviewCommentsCount={proReviewCommentsCount}
          proName={`${userInfo.first_name || ''} ${userInfo.last_name || ''}`}
          selectedComment={this.state.commentID}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ profileCreation, accountProfile, auth, proReview }) => ({
  licensing: profileCreation.profileData.licensing,
  states: profileCreation.states,
  contactInfo: profileCreation.profileData.contactInfo,
  localContactInfo: profileCreation.profileData.localContactInfo,
  languages: accountProfile.languages,
  languagesDB: accountProfile.languagesDB,
  rating: accountProfile.rating,
  reviews: accountProfile.reviews,
  adminEmail: auth.currentUser.admin_email,
  statesAreLoading: profileCreation.statesAreLoading,
  licensingAreLoading: profileCreation.profileData.licensingAreLoading,
  contactInfoIsLoading: profileCreation.profileData.contactInfoIsLoading,
  languagesAreLoading: accountProfile.languagesAreLoading,
  profileIsLoading: accountProfile.profileIsLoading,
  deleteRequestIsloading: accountProfile.deleteRequestIsloading,
  isProReviewLoading: proReview.isProReviewLoading,
  reviewModalOpen: proReview.reviewModalOpen,
  proReviewComments: proReview.proReviewComments,
  proReviewStats: proReview.proReviewStats,
  proReviewCommentsCount: proReview.proReviewCommentsCount,
  userInfo: auth.currentUser,
})

const mapDispatchToProps = dispatch => ({
  changeContactInfo: data => dispatch(changeProfileContactInfo(data)),
  updateLocalContactInfo: data => dispatch(updateProfileContactInfo(data)),
  saveProfileContactInfo: data => dispatch(saveContactInfo(data)),
  saveCurrentUserLanguages: languages => dispatch(saveUserLanguages(languages)),
  updateLocalUserLanguages: data => dispatch(updateLocalLanguages(data)),
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
  goToZendesk: (mail, redirect) => dispatch(goToZendesk(mail, redirect)),
  setReviewCommentsFilter: value => dispatch(setReviewCommentsFilter(value)),
  getProReviews: () => dispatch(getProReviews()),
  proReviewModalClose: () => dispatch(proReviewModalClose()),
  proReviewModalOpen: () => dispatch(proReviewModalOpen()),
  navigateTo: path => dispatch(navigateTo(path)),
  setReviewCommentsSorting: (property, isAsc) => dispatch(setReviewCommentsSorting(property, isAsc)),
})

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(AccountProfileGeneralInformationContainer)
)
