import React from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames/bind'
import { colors } from '../../../constants'
import XInputLabel from '../text/inputLabel'
import Hint from '../text/hint'
import XSelect from '../select/select'
import XIcon from '../Icon'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
  },
  hint: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: '8px',
  },
  input: {
    marginBottom: 4,
  },
})

const XFormikSelect = ({ label, containerClasses, ...props }) => {
  const [field, meta] = useField(props)
  const classes = useStyles()
  return (
    <div
      className={classNames({
        [classes.div]: true,
        [containerClasses]: true,
      })}
    >
      <XInputLabel>{label}</XInputLabel>
      <div className={classes.input}>
        <XSelect
          {...field}
          {...props}
          // error={!!meta.error}
        />
      </div>
      {meta.touched && meta.error && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <XIcon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {meta.error}
        </Hint>
      )}
    </div>
  )
}

export default XFormikSelect
