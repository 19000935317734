import axios from 'axios'
import { toArray, size } from 'lodash'
import * as CONSTANTS from 'store/constants/vault'
import { CONSTANTS as PROJECT_CONSTANTS } from 'constants/index'
import {
  getVaultCasesPath,
  getVaultCreateFolderPath,
  getVaultRenameItemPath,
  getVaultMoveItemsPath,
  getVaultUploadFileConfirmPath,
  getVaultUploadFileLinkPath,
  getVaultPlansPath,
  getVaultEventsPath,
  getClientVaultCasesPath,
  getVaultCopyItemsPath,
  getVaultPlansRatePath,
  getVaultUsagePath,
  getVaultDeleteItemsPath,
  getVaultTrashDeletePath,
  getVaultTrashPath,
  getVaultTrashRestorePath,
  getVaultZipChannelPath,
  getVaultZipSocketChannelPath,
  getVaultLoggingEventPath,
  getClientProVaultSettingsPath,
  getProVaultUploadRegisterPath,
  getEventsRealUpdateSocketPath,
  getVaultCasesMainLevelPath,
} from 'utils/path-helpers/api/vault/vault'
import { getVaultEventsParameters } from 'utils/getters/vault'
import {
  mapVaultDataToFront,
  mapVaultPlansRateToFront,
  mapVaultSettingsToFront,
  mapVaultUsageToFront,
  mapVaultTrashDataToFront,
  mapVaultEventsToFront,
} from 'utils/mappers/backend/vault/vault-mappers'
import { v4 as uuidv4 } from 'uuid'
import {
  getSubFolder,
  sortVaultFiles,
  extractKeys,
  getFileSizeGB,
  getFileLinkById,
  getSubFolderByPath,
} from 'utils/vault/vault-helpers'
import { exportFile, openSnackbar, openErrorModal } from '../common'
import { getJWTLocally } from 'utils/auth'
import { WebsocketService } from 'utils/service/WebsocketService'
import { EVENTS } from '../../../utils/analytics/Events'
import Analytics from '../../../utils/analytics/AnalyticsService'

let sourceVaultList

export const setVaultSorting = (property, isAsc) => (dispatch, getState) => {
  const { currentFolderItemsList } = getState().vault
  dispatch({
    type: CONSTANTS.SET_VAULT_SORTING,
    property,
    isAsc,
    currentFolderItemsList: sortVaultFiles(currentFolderItemsList, property, isAsc),
  })
}
export const setSecureVaultSorting = (property, isAsc) => (dispatch, getState) => {
  const { itemsList } = getState().vault
  dispatch({
    type: CONSTANTS.SET_SECURE_VAULT_SORTING,
    property,
    isAsc,
    itemsList: sortVaultFiles(itemsList, property, isAsc),
  })
}

export const setVaultTrashSorting = (property, isAsc) => (dispatch, getState) => {
  const { trashList } = getState().vault
  dispatch({
    type: CONSTANTS.SET_VAULT_TRASH_SORTING,
    property,
    isAsc,
    trashList: sortVaultFiles(trashList, property, isAsc),
  })
}

export const setVaultPreviewItem = previewItem => (dispatch, getState) => {
  Analytics.track(getState().auth.currentUser.role === 'pro' ? EVENTS.PRO_PREVIEWED_FILE : EVENTS.CLIENT_PREVIEWED_FILE)
  dispatch(setVaultSelectedItems([]))
  dispatch({
    type: CONSTANTS.SET_VAULT_PREVIEW_ITEM,
    payload: previewItem,
  })
}

export const getVaultPreviewItemStart = () => dispatch => {
  dispatch({
    type: CONSTANTS.GET_VAULT_PREVIEW_ITEM_START,
  })
}

export const getVaultPreviewItemEnd = () => dispatch => {
  dispatch({
    type: CONSTANTS.GET_VAULT_PREVIEW_ITEM_END,
  })
}

export const getVaultPreviewItem = (previewItemId, itemsList) => async dispatch => {
  dispatch(getVaultPreviewItemStart())
  const previewURLItem = getFileLinkById(previewItemId, itemsList)
  if (previewURLItem) {
    await dispatch(setVaultPreviewItem(previewURLItem))
    const selectedFolders = getSubFolderByPath(previewURLItem, itemsList)
    dispatch(setVaultSelectedFolders(selectedFolders))
    if (!selectedFolders.find(folder => folder.isPrivateFolder) && selectedFolders[1])
      dispatch(setVaultCase(selectedFolders[1].relatedID))
  } else {
    dispatch(closeVaultModal())
    dispatch(openSnackbar('error', 'Sorry, file not found'))
  }
  dispatch(getVaultPreviewItemEnd())
}

export const setVaultSelectedItems = selectedItems => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_SELECTED_ITEMS,
    payload: selectedItems,
  })
}
export const setVaultItemsAction = action => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_ITEMS_ACTION,
    payload: action,
  })
}

export const setVaultSingleActionItem = selectedItem => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_SINGLE_ACTION_ITEM,
    payload: selectedItem,
  })
}

export const setVaultUploadItemsModalOpen = action => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_UPLOAD_ITEMS_MODAL_OPEN,
    payload: action,
  })
}
export const setIsLoadingDeleteItemStart = () => dispatch => {
  dispatch({ type: CONSTANTS.SET_LOADING_DELETE_ITEM_START })
}

export const setIsLoadingDeleteItemEnd = () => dispatch => {
  dispatch({ type: CONSTANTS.SET_LOADING_DELETE_ITEM_END })
}

export const deleteVaultItem = deleteItems => async (dispatch, getState) => {
  const { sessionID } = getState().common
  const requestBody = {
    vault_objects: deleteItems.map(item => item.uuid),
    session_id: sessionID,
  }
  try {
    dispatch(setIsLoadingDeleteItemStart())
    await axios.post(getVaultDeleteItemsPath(), requestBody)
    dispatch(getVaultFilesList())
    dispatch(setIsLoadingDeleteItemEnd())
    dispatch(setVaultPreviewItem(null))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while deleting items'
    dispatch(openSnackbar('error', message))
    dispatch(setIsLoadingDeleteItemEnd())
    return Promise.reject(e)
  }
}

export const deleteTrashVaultItem = deleteItems => async (dispatch, getState) => {
  const requestBody = {
    vault_objects: deleteItems.map(item => item.uuid),
  }
  try {
    dispatch(setIsLoadingDeleteItemStart())
    await axios.post(getVaultTrashDeletePath(), requestBody)
    dispatch(getVaultFilesList())
    dispatch(getVaultTrashList())
    dispatch(setIsLoadingDeleteItemEnd())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while deleting items from trash'
    dispatch(openSnackbar('error', message))
    dispatch(setIsLoadingDeleteItemEnd())
    return Promise.reject(e)
  }
}

export const restoreTrashVaultItem = restoreItems => async (dispatch, getState) => {
  const { sessionID } = getState().common
  dispatch(setRestoring(true))
  const requestBody = {
    vault_objects: restoreItems.map(item => item.uuid),
    session_id: sessionID,
  }
  try {
    await axios.post(getVaultTrashRestorePath(), requestBody)
    dispatch(getVaultFilesList())
    dispatch(getVaultTrashList())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while restoring items from trash'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  } finally {
    dispatch(setRestoring(false))
  }
}

export const openVaultModal = options => (dispatch, getState) => {
  if (window.location.pathname !== '/pro/account/vault') {
    Analytics.track(getState().auth.currentUser.role === 'pro' ? EVENTS.PRO_OPENED_VAULT : EVENTS.CLIENT_OPENED_VAULT, {
      vault_place: getState().vault.vaultOpenPlace,
    })
  }

  dispatch({
    type: CONSTANTS.OPEN_VAULT_MODAL,
    payload: { ...options, isVaultModalOpen: true },
  })
}

export const closeVaultModal = () => (dispatch, getState) => {
  const { secureSort } = getState().vault
  const { property, isAsc } = secureSort
  sourceVaultList && sourceVaultList.cancel()
  dispatch({
    type: CONSTANTS.CLOSE_VAULT_MODAL,
  })
  dispatch(setSecureVaultSorting(property, isAsc))
}

export const setVaultUser = user => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_USER,
    payload: user,
  })
}

export const setVaultCase = caseID => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_CASE,
    payload: caseID,
  })
}

export const setVaultSelectedFolders = folders => dispatch => {
  if (folders.length === 1) {
    dispatch(setVaultCase(null))
  }
  dispatch({
    type: CONSTANTS.SET_VAULT_SELECTED_FOLDERS,
    payload: folders,
  })
}

const getVaultFilesListStart = () => dispatch => {
  dispatch({
    type: CONSTANTS.GET_VAULT_FILES_LIST_START,
  })
}

export const setTableConfiguration = payload => ({
  type: CONSTANTS.SET_TABLE_CONFIGURATION,
  payload,
})

export const getVaultSubFolderItem = () => (dispatch, getState) => {
  const { selectedFolders, sort, itemsList } = getState().vault
  const subFolderItems = getSubFolder(selectedFolders, itemsList)

  dispatch({
    type: CONSTANTS.GET_VAULT_SUBFOLDER_ITEMS,
    payload: sortVaultFiles(subFolderItems, sort.property, sort.isAsc),
  })
}

export const getVaultFilesList = (isSocketConnection, topLevel) => async (dispatch, getState) => {
  sourceVaultList = axios.CancelToken.source()
  if (!isSocketConnection) dispatch(getVaultFilesListStart())
  const {
    userID,
    caseID,
    selectedFolders,
    sort,
    proID,
    isPrivateFolder,
    itemsList,
    currentFolderItemsList,
  } = getState().vault
  try {
    const requestURL = topLevel
      ? getVaultCasesMainLevelPath()
      : proID
      ? getClientVaultCasesPath(proID)
      : getVaultCasesPath()
    const { data } = await axios.get(requestURL, {
      cancelToken: sourceVaultList.token,
    })
    const mappedData = mapVaultDataToFront(data)
    const newSelectedFolders = []
    if (!selectedFolders.length) {
      if (userID || caseID) {
        const userFolder = mappedData.find(data => data.relatedID === userID)
        let caseFolder = null
        if (userFolder) newSelectedFolders.push(userFolder)
        if (caseID) caseFolder = userFolder && userFolder.children.find(data => data.relatedID === caseID)
        else if (userFolder.children && userFolder.children.length === 1) {
          caseFolder = userFolder.children[0]
          dispatch(setVaultCase(caseFolder.relatedID))
        }
        if (caseFolder) newSelectedFolders.push(caseFolder)
      }
      if (proID) {
        const userFolder = mappedData[0]
        newSelectedFolders.push(userFolder)
        if (userFolder.children && userFolder.children.length === 1) {
          newSelectedFolders.push(userFolder.children[0])
          dispatch(setVaultCase(userFolder.children[0].relatedID))
        }
      }
      if (isPrivateFolder) newSelectedFolders.push(mappedData.find(folder => folder.isPrivateFolder))
    }
    const folders = newSelectedFolders.length ? newSelectedFolders : selectedFolders
    const subFolderItems = topLevel ? mappedData : getSubFolder(folders, mappedData)
    const itemsList = topLevel ? sortVaultFiles(mappedData, sort.property, sort.isAsc) : mappedData
    await dispatch(getVaultFilesListEnd(itemsList, sortVaultFiles(subFolderItems, sort.property, sort.isAsc)))
    await dispatch(setVaultSelectedFolders(folders))
    return Promise.resolve(mappedData)
  } catch (e) {
    if (axios.isCancel(e)) {
      dispatch(getVaultFilesListEnd(itemsList, currentFolderItemsList))
      return []
    }
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while downloading user vault'
    dispatch(openSnackbar('error', message))
    dispatch(getVaultFilesListEnd([], []))
    dispatch(closeVaultModal())
    return Promise.reject(e)
  }
}

const getVaultFilesListEnd = (itemsList, currentFolderItemsList) => dispatch => {
  dispatch({
    type: CONSTANTS.GET_VAULT_FILES_LIST_END,
    itemsList,
    currentFolderItemsList,
  })
}

export const setVaultFolderList = list => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_FOLDER_LIST,
    payload: list,
  })
}

const setFolderCreationInProgress = isCreation => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_FOLDER_CREATION_PROGRESS,
    payload: isCreation,
  })
}

export const createVaultFolder = (title, parentFolder) => async (dispatch, getState) => {
  dispatch(setFolderCreationInProgress(true))
  const { userID, selectedFolders } = getState().vault
  const { sessionID } = getState().common
  const requestBody = {
    title,
    parent: (parentFolder && parentFolder.uuid) || selectedFolders[selectedFolders.length - 1].uuid,
    session_id: sessionID,
  }
  try {
    await axios.post(getVaultCreateFolderPath(userID), requestBody)
    dispatch(getVaultFilesList())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while creating folder'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  } finally {
    dispatch(setFolderCreationInProgress(false))
  }
}

export const renameVaultItem = (title, item) => async (dispatch, getState) => {
  const { sessionID } = getState().common
  const requestBody = {
    title,
    session_id: sessionID,
  }
  try {
    await axios.put(getVaultRenameItemPath(item.uuid), requestBody)
    dispatch(getVaultFilesList())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while renaming item'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  }
}

const setIsMoving = isMoving => dispatch => {
  dispatch({
    type: CONSTANTS.SET_IS_MOVING,
    payload: isMoving,
  })
}

const setCoping = isCoping => dispatch => {
  dispatch({
    type: CONSTANTS.SET_COPING,
    payload: isCoping,
  })
}

const setRestoring = isRestoring => dispatch => {
  dispatch({
    type: CONSTANTS.SET_RESTORING,
    payload: isRestoring,
  })
}

export const moveVaultItems = (moveItems, destinationFolder) => async (dispatch, getState) => {
  const { itemsList } = getState().vault
  const { sessionID } = getState().common
  dispatch(setIsMoving(true))
  const requestBody = {
    vault_objects: moveItems.map(item => item.uuid),
    parent: destinationFolder.uuid,
    session_id: sessionID,
  }
  try {
    await axios.post(getVaultMoveItemsPath(), requestBody)
    await dispatch(getVaultFilesList())
    dispatch(setVaultPreviewItem(null))
    const folders = getSubFolderByPath(destinationFolder, itemsList)
    dispatch(setVaultSelectedFolders(folders))
    if (folders[0]) dispatch(setVaultUser(folders[0].relatedID))
    if (folders[1]) dispatch(setVaultCase(folders[1].relatedID))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while moving items'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  } finally {
    dispatch(setIsMoving(false))
  }
}

export const copyVaultItems = (copyItems, destinationFolder) => async (dispatch, getState) => {
  const { itemsList, proID } = getState().vault
  const { currentUser } = getState().auth
  const { sessionID } = getState().common
  dispatch(setCoping(true))
  const requestBody = {
    vault_objects: copyItems.map(item => item.uuid),
    parent: destinationFolder.uuid,
    session_id: sessionID,
  }
  const settingsRequestURL = currentUser.role === 'pro' ? getVaultPlansPath() : getClientProVaultSettingsPath(proID)
  const { data: settingsData } = await axios.get(settingsRequestURL)
  if (settingsData.vault_plan === 'free') {
    const copyingFilesSize = getFileSizeGB(copyItems.reduce((sum, item) => sum + item.size, 0))
    if (settingsData.unusable_space < copyingFilesSize) {
      dispatch(setVaultCopySizeExceeded(true, settingsData.unusable_space * 1000))
      dispatch(setCoping(false))
      return
    }
  }
  try {
    await axios.post(getVaultCopyItemsPath(), requestBody)
    await dispatch(getVaultFilesList())
    dispatch(setVaultPreviewItem(null))
    const folders = getSubFolderByPath(destinationFolder, itemsList)
    dispatch(setVaultSelectedFolders(folders))
    if (folders[0]) dispatch(setVaultUser(folders[0].relatedID))
    if (folders[1]) dispatch(setVaultCase(folders[1].relatedID))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while copy items'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  } finally {
    dispatch(setCoping(false))
  }
}

export const downloadVaultFile = file => async (dispatch, getState) => {
  dispatch(setVaultItemsAction('download'))
  Analytics.track(
    getState().auth.currentUser.role === 'pro' ? EVENTS.PRO_DOWNLOADED_FILE : EVENTS.CLIENT_DOWNLOADED_FILE
  )
  try {
    const { data } = await axios.get(file.link, { responseType: 'blob' })
    dispatch(exportFile(data, file.name))
    axios.post(getVaultLoggingEventPath(file.uuid, PROJECT_CONSTANTS.VAULT_EVENTS.DOWNLOAD))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while downloading item'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  } finally {
    dispatch(setVaultItemsAction(null))
  }
}

export const setUploadFile = data => async (dispatch, getState) => {
  const { proID } = getState().vault
  const { currentUser } = getState().auth
  if (data.some(file => file.size > PROJECT_CONSTANTS.FILE_SIZE_LIMIT)) {
    dispatch(openSnackbar('error', 'File size too large (max 2 GB)'))
    return
  }
  const settingsRequestURL = currentUser.role === 'pro' ? getVaultPlansPath() : getClientProVaultSettingsPath(proID)
  const { data: settingsData } = await axios.get(settingsRequestURL)
  if (settingsData.vault_plan === 'free') {
    const uploadingFilesSize = getFileSizeGB(data.reduce((sum, item) => sum + item.size, 0))
    if (settingsData.unusable_space < uploadingFilesSize) {
      dispatch(setVaultSizeExceeded(true))
      if (currentUser.role === 'pro') await dispatch(setPausedUploadFiles(data))
      return
    }
  }
  await dispatch({
    type: CONSTANTS.SET_VAULT_UPLOAD_FILE,
    payload: data,
  })
  dispatch(setPausedUploadFiles([]))
  dispatch(setVaultItemsAction('upload'))
  dispatch(setVaultUploadItemsModalOpen(true))
  dispatch(uploadItems())
}

export const setUploadProgress = (id, progress) => ({
  type: CONSTANTS.SET_VAULT_UPLOAD_FILE_PROGRESS,
  payload: {
    id,
    progress,
  },
})

export const setPausedUploadFiles = data => ({
  type: CONSTANTS.SET_VAULT_PAUSED_UPLOAD_FILES,
  payload: data,
})

export const setUploadFileUuid = (id, file_uuid, source) => ({
  type: CONSTANTS.SET_VAULT_UPLOAD_FILE_UUID,
  payload: {
    id,
    file_uuid,
    source,
  },
})

export const setVaultPlanAccepted = isAccepted => ({
  type: CONSTANTS.SET_VAULT_PLAN_ACCEPTED,
  payload: isAccepted,
})

export const setVaultSizeExceeded = isExceeded => ({
  type: CONSTANTS.SET_VAULT_SIZE_EXCEEDED,
  payload: isExceeded,
})

export const setVaultCopySizeExceeded = (isCopyExceeded, maxCopySize) => ({
  type: CONSTANTS.SET_VAULT_COPY_SIZE_EXCEEDED,
  payload: {
    isCopyExceeded,
    maxCopySize,
  },
})

const successUploadFile = id => ({
  type: CONSTANTS.SET_VAULT_UPLOAD_FILE_SUCCESS,
  payload: id,
})

const failureUploadFile = id => ({
  type: CONSTANTS.SET_VAULT_UPLOAD_FILE_FAILURE,
  payload: id,
})

export const cancelUploadFile = file => async dispatch => {
  try {
    await axios.delete(getVaultUploadFileConfirmPath(file.file_uuid))
    file.source.cancel('Upload canceled by the user')
    dispatch({
      type: CONSTANTS.SET_VAULT_UPLOAD_FILE_CANCELLED,
      payload: file.id,
    })
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while canceling file upload'
    dispatch(openSnackbar('error', message))
  }
}

const setUploadFilesEnd = uploadingItems => (dispatch, getState) => {
  const { fileProgress } = getState().vault
  const restFileProgress = extractKeys(
    uploadingItems.map(item => item.id),
    fileProgress
  )
  dispatch({
    type: CONSTANTS.SET_VAULT_UPLOAD_FILE_END,
    fileProgress: restFileProgress,
    filesAreUploading: !!size(restFileProgress),
  })
}

const setUploadFilesStart = () => ({
  type: CONSTANTS.SET_VAULT_UPLOAD_FILE_START,
})

export const uploadItems = () => async (dispatch, getState) => {
  dispatch(setUploadFilesStart())
  const { selectedFolders, fileProgress } = getState().vault
  const { sessionID } = getState().common
  const uploadingItems = toArray(fileProgress).filter(file => !file.progress)
  const parent = selectedFolders[selectedFolders.length - 1]
  const registerFolder = selectedFolders[0]
  const fileUploadCalls = uploadingItems
    .map(async item => {
      const requestBody = {
        title: item.file.name,
        size: item.file.size,
        parent: parent.uuid,
      }
      try {
        const { data } = await axios.post(getVaultUploadFileLinkPath(), requestBody)
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        const { url, fields, file_uuid } = data
        dispatch(setUploadFileUuid(item.id, file_uuid, source))
        const formData = new FormData()
        Object.keys(fields).forEach(key => {
          formData.append(key, fields[key])
        })
        formData.append('file', item.file)
        const fileUploadConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progress => {
            const { loaded, total } = progress
            const percentageProgress = Math.floor((loaded / total) * 100)
            dispatch(setUploadProgress(item.id, percentageProgress))
          },
          cancelToken: source.token,
        }
        await axios.post(url, formData, fileUploadConfig)
        await axios.post(getVaultUploadFileConfirmPath(file_uuid))
        await dispatch(successUploadFile(item.id))
      } catch (e) {
        if (axios.isCancel(e)) {
          return []
        }
        dispatch(failureUploadFile(item.id))
        const message =
          (e.response && e.response.data && e.response.data.detail && e.response.data.detail[0]) ||
          'Error while uploading file'
        dispatch(openSnackbar('error', message))
      }
    })
    .map(p => p.catch(e => e))
  try {
    await Promise.all(fileUploadCalls)
    await axios.post(getProVaultUploadRegisterPath(registerFolder && registerFolder.uuid), {
      session_id: sessionID,
    })
    dispatch(getVaultFilesList())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while uploading files'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  } finally {
    dispatch(setUploadFilesEnd(uploadingItems))
  }
}

export const setVaultSettings = settings => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_SETTINGS,
    payload: settings,
  })
}

export const vaultSettingsIsLoadingStart = () => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_SETTINGS_LOADING_START,
  })
}

export const vaultSettingsIsLoadingEnd = () => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_SETTINGS_LOADING_END,
  })
}

export const getVaultPlans = () => async dispatch => {
  try {
    dispatch(vaultSettingsIsLoadingStart())
    const { data } = await axios.get(getVaultPlansPath())
    const mappedData = mapVaultSettingsToFront(data)
    dispatch(setVaultSettings(mappedData))
    dispatch(vaultSettingsIsLoadingEnd())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while get vault plans'
    if (e.response && e.response.status === 404) {
      dispatch(vaultSettingsIsLoadingEnd())
      return Promise.reject(e)
    }
    dispatch(openSnackbar('error', message))
    dispatch(vaultSettingsIsLoadingEnd())
    return Promise.reject(e)
  }
}

export const setVaultPlans = payload => async dispatch => {
  try {
    const response = await axios.post(getVaultPlansPath(), payload)
    dispatch(setVaultPlanAccepted(true))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while set vault plans'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  }
}

export const setVaultEvents = (events, count) => ({
  type: CONSTANTS.SET_VAULT_EVENTS,
  payload: {
    events,
    count,
  },
})

export const vaultEventsIsLoadingStart = () => ({
  type: CONSTANTS.SET_VAULT_EVENTS_LOADING_START,
})

export const vaultEventsIsLoadingEnd = () => ({
  type: CONSTANTS.SET_VAULT_EVENTS_LOADING_END,
})

export const getVaultEvents = folderId => async (dispatch, getState) => {
  try {
    dispatch(vaultEventsIsLoadingStart())
    const { data } = await axios.get(getVaultEventsPath(folderId), {
      params: getVaultEventsParameters(getState().vault),
    })
    const mappedData = mapVaultEventsToFront(data.results)
    dispatch(setVaultEvents(mappedData, data.count))
    dispatch(vaultEventsIsLoadingEnd())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while get vault events'
    dispatch(openSnackbar('error', message))
    dispatch(vaultEventsIsLoadingEnd())
    return Promise.reject(e)
  }
}
export const updateVaultPlans = payload => async dispatch => {
  try {
    const response = await axios.patch(getVaultPlansPath(), payload)
    dispatch(setVaultPlanAccepted(true))
  } catch (e) {
    if (e.response && e.response.data && e.response.data.title && e.response.data.title === 'Cannot downgraded') {
      dispatch(openErrorModal(e.response.data.title, e.response.data.detail))
      return Promise.reject(e)
    }
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while set vault plans'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  }
}

export const setVaultPlansRate = plansRate => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_PLANS_RATE,
    payload: plansRate,
  })
}

export const getVaultPlansRate = () => async dispatch => {
  try {
    const { data } = await axios.get(getVaultPlansRatePath())
    const mappedData = mapVaultPlansRateToFront(data)
    dispatch(setVaultPlansRate(mappedData))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while get vault plans rate'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  }
}

const getVaultTrashListStart = () => dispatch => {
  dispatch({
    type: CONSTANTS.GET_VAULT_TRASH_LIST_START,
  })
}

export const getVaultTrashList = () => async (dispatch, getState) => {
  dispatch(getVaultTrashListStart())
  const { userID, trashSort } = getState().vault
  try {
    const { data } = await axios.get(getVaultTrashPath(userID || 'private-folder'))
    const mappedTrashInfo = data.map(trash => mapVaultTrashDataToFront(trash))
    dispatch(getVaultTrashListEnd(sortVaultFiles(mappedTrashInfo, trashSort.property, trashSort.isAsc)))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while downloading user vault trash'
    dispatch(openSnackbar('error', message))
    dispatch(getVaultTrashListEnd([]))
    return Promise.reject(e)
  }
}

export const setVaultUsage = usage => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_USAGE,
    payload: usage,
  })
}

export const vaultUsageIsLoadingStart = () => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_USAGE_LOADING_START,
  })
}

export const vaultUsageIsLoadingEnd = () => dispatch => {
  dispatch({
    type: CONSTANTS.SET_VAULT_USAGE_LOADING_END,
  })
}

export const getVaultUsage = () => async dispatch => {
  try {
    dispatch(vaultUsageIsLoadingStart())
    const { data } = await axios.get(getVaultUsagePath())
    const mappedData = mapVaultUsageToFront(data)
    dispatch(setVaultUsage(mappedData))
    dispatch(vaultUsageIsLoadingEnd())
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while get vault usage'
    dispatch(openSnackbar('error', message))
    dispatch(vaultUsageIsLoadingEnd())
    return Promise.reject(e)
  }
}
const getVaultTrashListEnd = trashList => dispatch => {
  dispatch({
    type: CONSTANTS.GET_VAULT_TRASH_LIST_END,
    payload: trashList,
  })
}

export const downloadToZipFile = files => async (dispatch, getState) => {
  dispatch(setVaultItemsAction('download'))
  const token = getJWTLocally()
  const wsChannelUuid = uuidv4()
  const vaultObjects = files.map(file => file.uuid)
  const payload = {
    vault_objects: vaultObjects,
    ws_channel_uuid: wsChannelUuid,
  }

  Analytics.track(
    getState().auth.currentUser.role === 'pro' ? EVENTS.PRO_DOWNLOADED_FILE : EVENTS.CLIENT_DOWNLOADED_FILE
  )

  const subject = new WebsocketService(getVaultZipSocketChannelPath(wsChannelUuid, token))
  subject.subscribe(
    msg => {
      if (msg.status === 'finished') {
        axios
          .get(msg.url, { responseType: 'blob' })
          .then(data => {
            return dispatch(exportFile(data.data, 'XIRA.zip'))
          })
          .then(() => {
            dispatch(setVaultItemsAction(null))
            return subject.unsubscribe()
          })
      }
    },
    () => {
      dispatch(setVaultItemsAction(null))
      dispatch(openSnackbar('error', 'Error while download'))
      return subject.unsubscribe()
    }
  )

  try {
    await axios.post(getVaultZipChannelPath(), payload)
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail
        ? e.response.data.detail
        : 'Error while downloading zip archive'
    dispatch(openSnackbar('error', message))
    dispatch(setVaultItemsAction(null))
    return Promise.reject(e)
  }
}
export const setVaultLoggingEventPrint = fileId => dispatch => {
  try {
    axios.post(getVaultLoggingEventPath(fileId, PROJECT_CONSTANTS.VAULT_EVENTS.PRINT))
  } catch (e) {
    const message =
      e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error logging event print'
    dispatch(openSnackbar('error', message))
    return Promise.reject(e)
  }
}

let vaultSubject

export const connectToVaultService = () => (dispatch, getState) => {
  const { selectedFolders } = getState().vault
  const { sessionID } = getState().common
  const isSocketConnection = true
  const token = getJWTLocally()
  const currentRootFolderId = selectedFolders && selectedFolders[0] && selectedFolders[0].uuid
  vaultSubject = new WebsocketService(getEventsRealUpdateSocketPath(currentRootFolderId, token))
  vaultSubject.subscribe(msg => {
    if (sessionID !== msg.session_id) dispatch(getVaultFilesList(isSocketConnection))
  })
}

export const disconnectFromVaultService = () => dispatch => {
  if (vaultSubject) vaultSubject.unsubscribe()
}

export const setVaultOpenPlace = payload => ({
  type: CONSTANTS.SET_VAULT_OPEN_PLACE,
  payload,
})
