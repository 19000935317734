import React, { useEffect, useRef, useState } from 'react'
import { NotificationsPopper, SvgIcon, Typography } from 'components'
import { ReactComponent as NotificationBell } from 'static/header/notification_bell.svg'
import { ReactComponent as NotificationBellFilled } from 'static/header/notification_bell_filled.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { colors } from 'constants/index'
import { useQuery } from 'utils/hooks'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  notificationIcon: {
    display: 'block',
  },
  notificationIconFilled: {
    display: 'none',
  },
  notificationButtonWrapper: {
    width: 24,
    height: 24,
    marginRight: 24,
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& > $notificationIcon': {
        display: 'none',
      },
      '& > $notificationIconFilled': {
        display: 'block',
      },
    },
  },
  unreadIndicator: {
    backgroundColor: colors.ERROR,
    position: 'absolute',
    top: -6,
    right: -6,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 18,
    width: 18,
  },
  notificationsNumber: {
    color: colors.WHITE,
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 0.91,
    letterSpacing: -1,
  },
})

const NotificationButton = () => {
  const classes = useStyles()
  const notificationButton = useRef()
  const query = useQuery()
  const showNotificationList = query.get('showNotificationList')
  const [notificationsAreOpen, setNotificationsAreOpen] = useState(false)
  const unreadNotificationsCounter = useSelector(state => state.notifications.unreadNotificationsCounter)
  const role = useSelector(state => state.auth.currentUser.role)

  useEffect(() => {
    if (showNotificationList === 'true' && role) {
      setNotificationsAreOpen(true)
      Analytics.track(role === 'pro' ? EVENTS.PRO_OPENED_NOTIFICATIONS : EVENTS.CLIENT_OPENED_NOTIFICATIONS)
    }
  }, [showNotificationList, role])

  const onOpenNotificationPanel = () => {
    setNotificationsAreOpen(true)
    Analytics.track(role === 'pro' ? EVENTS.PRO_OPENED_NOTIFICATIONS : EVENTS.CLIENT_OPENED_NOTIFICATIONS)
  }

  return (
    <div className={classes.container} ref={notificationButton}>
      <div className={classes.notificationButtonWrapper} onClick={() => onOpenNotificationPanel()}>
        <SvgIcon component={NotificationBell} viewBox="0 0 24 24" className={classes.notificationIcon} />
        <SvgIcon component={NotificationBellFilled} viewBox="0 0 24 24" className={classes.notificationIconFilled} />
        {!!unreadNotificationsCounter && (
          <div className={classes.unreadIndicator}>
            <Typography className={classes.notificationsNumber}>
              {unreadNotificationsCounter > 99 ? '99+' : unreadNotificationsCounter}
            </Typography>
          </div>
        )}
      </div>
      <NotificationsPopper
        isOpen={notificationsAreOpen}
        close={() => setNotificationsAreOpen(false)}
        anchorEl={notificationButton.current}
      />
    </div>
  )
}

export default NotificationButton
