import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as XiraIcon } from 'static/Favicon_32.svg'
import { Icon, Typography, IconButton, SvgIcon, Button } from 'components'
import { toggleMobileHint } from 'store/actions/common'
import { colors } from 'constants/index'
import { useDispatch } from 'react-redux'
import { navigateToWindow } from 'store/actions/navigation'

const useStyles = makeStyles({
  mobileApp: {
    paddingRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 48,
    backgroundColor: colors.BASIC.COLOR[0],
    zIndex: 1201,
  },
  closeMobileHintIconButton: {
    backgroundColor: colors.BASIC.COLOR[0],
    fontSize: 16,
  },
  xiraIcon: {
    height: 32,
    width: 32,
  },
  mobileHintText: {
    marginLeft: 8,
    fontSize: 11,
    fontWeight: 'normal',
    width: 100,
  },
  mobileHint: {
    display: 'flex',
    alignItems: 'center',
  },
  getAppButton: {
    backgroundColor: colors.ADDITIONAL.COLOR[50],
    width: 78,
    height: 24,
    minHeight: 24,
    fontSize: 11,
    textTransform: 'lowercase',
    padding: '0px 17px',
  },
})

const MobileAppHint = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const goToApp = () => {
    let storeLink
    if (navigator.userAgent.match(/Android/i)) {
      storeLink = 'https://play.google.com/store/apps/details?id=com.xira.lawyers&hl=en_US'
    } else if (navigator.userAgent.match(/iPhone/i)) {
      storeLink = 'https://apps.apple.com/us/app/xira/id1516671652'
    }
    dispatch(navigateToWindow(storeLink))
  }
  return (
    <div className={classes.mobileApp}>
      <div className={classes.mobileHint}>
        <IconButton
          onClick={() => {
            dispatch(toggleMobileHint(false))
          }}
          className={classes.closeMobileHintIconButton}
        >
          <Icon iconClass="las la-times" style={classes.closeIcon} />
        </IconButton>
        <SvgIcon viewBox="0 0 32 32" className={classes.xiraIcon}>
          <XiraIcon />
        </SvgIcon>
        <Typography className={classes.mobileHintText}>for best experience download xira app</Typography>
      </div>
      <Button className={classes.getAppButton} onClick={goToApp}>
        get app
      </Button>
    </div>
  )
}

export default MobileAppHint
