import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { SvgIcon } from 'components'
import { ReactComponent as DoneIcon } from 'static/chat/done.svg'
import moment from 'moment'

const useStyles = makeStyles({
  message: {
    maxWidth: '80%',
    backgroundColor: colors.PRIMARY.COLOR['20'],
    borderRadius: 5,
    borderTopRightRadius: 0,
    marginLeft: 10,
    marginRight: 10,
    padding: 12,
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  opponent: {
    backgroundColor: colors.ADDITIONAL.COLOR['20'],
    borderRadius: 5,
    borderTopLeftRadius: 0,
    alignItems: 'flex-start',
  },
  text: {
    wordBreak: 'break-word',
    width: '100%',
    fontSize: 14,
    whiteSpace: 'pre-line',
  },
  footer: {
    display: 'flex',
    marginTop: 4,
    alignItems: 'center',
  },
  icon: {
    height: 16,
    width: 16,
    marginLeft: 4,
  },
  time: {
    color: colors.BASIC.COLOR['70'],
    fontSize: 12,
  },
  newMessageIndicator: {
    color: colors.PRIMARY.COLOR['50'],
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 500,
  },
  line: {
    width: '10%',
    height: 0,
    borderTop: `1px solid ${colors.PRIMARY.COLOR['50']}`,
    marginRight: 12,
    marginLeft: 12,
  },
  editButton: {
    padding: 3,
    minHeight: 20,
    height: 20,
    fontSize: 12,
    textTransform: 'none',
    color: colors.BASIC.COLOR['70'],
    minWidth: 20,
    marginRight: 4,
    marginLeft: 4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  editIcon: {
    height: 12,
    width: 12,
    marginRight: 4,
  },
})

const MessageBox = props => {
  const { message, isMyMessage, lastConsumedMessage, newMessagesIndex } = props
  const classes = useStyles()

  return (
    <>
      {newMessagesIndex !== null && newMessagesIndex !== undefined && newMessagesIndex + 1 === message.index && (
        <div className={classes.newMessageIndicator}>
          <div className={classes.line} />
          new messages
          <div className={classes.line} />
        </div>
      )}
      <div className={classNames({ [classes.message]: true, [classes.opponent]: !isMyMessage })}>
        <div className={classes.text}>{message.body}</div>
        <div className={classes.footer}>
          <div className={classes.time}>{moment(message.timestamp).format('h:mm A')}</div>
          {lastConsumedMessage !== null &&
            lastConsumedMessage !== undefined &&
            isMyMessage &&
            lastConsumedMessage >= message.index && (
              <SvgIcon viewBox="0 0 16 16" className={classes.icon}>
                <DoneIcon />
              </SvgIcon>
            )}
        </div>
      </div>
    </>
  )
}

export default MessageBox
