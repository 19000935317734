import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { openSnackbar } from 'store/actions/common'
import { ERROR_SNACKBAR } from 'store/constants/common'
import { colors, routes, REGEXP } from 'constants/index'
import { navigateTo } from 'store/actions/navigation'
import {
  getProfileList,
  setPracticeAreaValue,
  setCountryStateValue,
  setPracticeAreaSearchObject,
  setDefaultFilter,
  setSearchFilter,
  setPractiseSearchType,
  setPractiseSearchName,
  setPractiseArea,
} from 'store/actions/search'
import { ReactComponent as IconSearch } from 'static/ic_search.svg'
import { ReactComponent as AlertTriangle } from 'static/alert-triangle.svg'
import { ReactComponent as ActionsIcon } from 'static/vault/header/chevron-down.svg'
import { Button, IconButton, Menu, MenuItem, SvgIcon, TextField, Typography } from 'components'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'utils/hooks'
import MainSearch from './MainSearch'
import PracticeAreaAutocomplete from './PracticeAreaAutocomplete'
import StatesAutocomplete from './StatesAutocomplete'

const useStyles = makeStyles({
  autosuggestAreasInputStartPage: {
    paddingLeft: 10,
    width: 240,
    height: 52,
    fontSize: 16,
    background: colors.WHITE,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
  },
  startPageSearchAreasWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerBtn: {
    cursor: 'pointer',
    height: 32,
    width: 36,
    background: colors.PRIMARY.COLOR[50],
    borderRadius: '0 5px 5px 0',
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.PRIMARY.COLOR[50],
    padding: 0,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.PRIMARY.COLOR[60],
    },
  },
  startPageBtn: {
    cursor: 'pointer',
    height: 55,
    width: 60,
    background: colors.PRIMARY.COLOR[50],
    borderRadius: '0 5px 5px 0',
    fontSize: 19,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.PRIMARY.COLOR[50],
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.PRIMARY.COLOR[60],
    },
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
      borderRadius: 5,
    },
  },
  autocompleteWrapp: {
    background: colors.WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  startPageLoader: {
    color: colors.WHITE,
  },
  headerLoader: {
    color: colors.BASIC.COLOR[50],
  },
  headerSearch: {
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  headerIcon: {
    width: 20,
  },
  startPageIcon: {
    width: 24,
    '@media(max-width:767px)': {
      display: 'none',
    },
  },
  startPageText: {
    display: 'none',
    color: colors.WHITE,
    '@media(max-width:767px)': {
      display: 'block',
    },
  },
  searchButtonText: {
    display: 'none',
  },
  '@media(max-width:767px)': {
    headerBtn: {
      width: '100%',
      height: 46,
      borderRadius: 4,
      fontSize: 15,
    },
    headerIcon: {
      display: 'none',
    },
    searchButtonText: {
      display: 'inline',
      color: '#fff',
      textTransform: 'uppercase',
    },
  },
  alertIcon: {
    color: colors.ERROR,
    height: 13,
    width: 13,
    marginRight: 5,
  },
  error: {
    color: colors.ERROR,
    fontSize: 13,
    fontWeight: 'normal',
  },
  errorContainer: {
    padding: '5px 8px',
    backgroundColor: colors.PRIMARY.COLOR['0'],
    borderRadius: 4,
    display: 'flex',
    marginTop: 5,
    width: 'fit-content',
    alignItems: 'center',
  },
  notched: {
    border: 0,
  },
  searchRoot: {
    height: 55,
    width: 420,
    padding: 9,
    paddingRight: 90,
    borderRadius: '4px 0 0 4px',
    '@media(max-width:1023px) and (min-width:768px)': {
      width: 300,
    },
    '@media(max-width:768px)': {
      width: '100%',
      marginBottom: 12,
      borderRadius: 4,
    },
  },
  startPageInput: {
    borderRight: '1px solid #2B4157',
    '@media(max-width:768px)': {
      border: 0,
    },
  },
  headerPageInput: {
    borderRadius: '5px 0 0 5px',
    height: 32,
    border: '1px solid #DDE2EB',
    '&:hover': {
      borderColor: '#C1CBD6',
    },
    '@media(max-width: 768px)': {
      height: 54,
      marginBottom: 12,
      borderRadius: 5,
    },
  },
  headerPageInputRoot: {
    '@media(max-width: 768px)': {
      height: '100%',
    },
  },
  headerInputRoot: {
    height: 32,
    width: 350,
    padding: '9.5px 12px',
  },
  headerInputRootSearch: {
    width: 257,
    padding: '0 80px 0 12px',
    '@media(max-width:1279px)': {
      width: 157,
    },
    '@media(max-width:939px)': {
      width: 112,
    },
    '@media(max-width: 768px)': {
      width: '100%',
    },
  },
  buttonType: {
    width: 72,
    height: 29,
    minHeight: 29,
    borderRadius: 4,
    backgroundColor: '#FAE9E1',
    color: colors.PRIMARY.COLOR['50'],
    position: 'absolute',
    left: 335,
    top: 13,
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#FAE9E1',
      color: colors.PRIMARY.COLOR['50'],
      boxShadow: 'none',
    },
    '@media(max-width:1023px)': {
      left: 215,
    },
    '@media(max-width:768px)': {
      left: 'unset',
      right: 12,
    },
  },
  buttonTypeSearch: {
    top: 5,
    left: 276,
    padding: '6px 24px',
    fontSize: '10px',
    height: 20,
    minHeight: 20,
    width: 70,
    '@media(max-width:1279px)': {
      left: 174,
    },
    '@media(max-width:939px)': {
      left: 130,
    },
    '@media(max-width:768px)': {
      left: 'unset',
      right: 12,
      top: 17,
    },
  },
  typesList: {
    marginTop: 4,
    transform: 'translateX(-100%) !important',
    '@media(max-width: 768px)': {
      marginLeft: 213,
    },
  },
  typesListExtra: {
    '@media(max-width: 768px)': {
      marginLeft: 209,
    },
  },
  activeItem: {
    fontWeight: 700,
  },
})

const Search = props => {
  const { type, location } = props
  const btnClassname = `${type}Btn`
  const iconClassname = `${type}Icon`
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const countryStateValue = useSelector(state => state.search.countryStateValue)
  const { states } = useSelector(state => state.profileCreation)
  const practiceAreaValue = useSelector(state => state.search.practiceAreaValue)
  const practiceSearchType = useSelector(state => state.search.practiceSearchType)
  const practiceSearchName = useSelector(state => state.search.practiceSearchName)
  const practiceAreaSearchObject = useSelector(state => state.search.practiceAreaSearchObject)
  const practiceArea = query.get('practice_area')
  const state = query.get('state')
  const [currentSearchPracticeArea, setCurrentSearchPracticeArea] = useState({ name: practiceAreaValue })
  const [areaObject, setAreaObject] = useState({})
  const [searchType, setSearchType] = useState(practiceSearchType)
  const [searchName, setSearchName] = useState(practiceSearchName)
  const [currentSearchState, setCurrentSearchState] = useState(countryStateValue)
  const [error, setError] = useState(false)

  useEffect(() => {
    setAreaObject(practiceAreaSearchObject)
  }, [practiceAreaSearchObject])

  useEffect(() => {
    setCurrentSearchPracticeArea({ name: practiceAreaValue })
  }, [practiceAreaValue])

  useEffect(() => {
    if (REGEXP.matchToSearchRoute.test(location.pathname)) {
      if (practiceArea) {
        dispatch(setPracticeAreaValue(practiceArea))
      }
    }
  }, [location.pathname, practiceArea])

  useEffect(() => {
    setCurrentSearchState(countryStateValue)
    if (REGEXP.matchToSearchRoute.test(location.pathname)) {
      if (state) {
        dispatch(setCountryStateValue(state))
      }
    }
  }, [location.pathname, countryStateValue, state])

  const onStartPageSearchClick = () => {
    const searchNameTrim = searchName.trim()
    if (searchName.length !== searchNameTrim.length) onChangeSearch(searchNameTrim)
    if ((currentSearchPracticeArea.name && !searchType) || (searchType && searchNameTrim.length >= 3)) {
      dispatch(setPractiseSearchType(searchType))
      dispatch(setPractiseSearchName(searchNameTrim))
      dispatch(setPracticeAreaValue(currentSearchPracticeArea.name))
      dispatch(setCountryStateValue(currentSearchState))
      dispatch(setPracticeAreaSearchObject(areaObject))
      dispatch(setDefaultFilter({ state_search: !areaObject.is_federal }))
      dispatch(setSearchFilter('state_search', !areaObject.is_federal))
    }
    if (!searchType && !currentSearchPracticeArea.name) {
      if (type === 'startPage') {
        setError(true)
        return
      }
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Please choose valid practice area'))
      return
    }
    if (searchType && searchNameTrim.length < 3) {
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Please provide more then 3 symbols'))
      return
    }
    if (!states.some(el => el.label === currentSearchState)) {
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Please choose valid state'))
      return
    }
    if (window.location !== window.parent.location) {
      const params = {
        practice_area: currentSearchPracticeArea.name,
        attorney_name: searchNameTrim,
        state: currentSearchState,
      }
      let query = ''
      for (const key in params) {
        if (params[key]) {
          query += query ? '&' : '?'
          query += `${key}=${params[key]}`
        }
      }
      window.top.location.href = `${routes.SEARCH_PAGE}${query}`
    } else {
      dispatch(navigateTo(routes.SEARCH_PAGE))
    }
    dispatch(getProfileList(true, true))
  }

  const mountSearchButton = () => {
    return (
      <IconButton classes={{ root: classes[btnClassname] }} onClick={onStartPageSearchClick}>
        <SvgIcon component={IconSearch} viewBox="0 0 24 24" classes={{ root: classes[iconClassname] }} />
        {type === 'startPage' && <div className={classes.startPageText}>SEARCH</div>}
        {type === 'header' && <span className={classes.searchButtonText}>search</span>}
      </IconButton>
    )
  }

  const onChangeValue = value => {
    window.sessionStorage.setItem('searchName', '')
    window.sessionStorage.setItem('searchType', value)
    dispatch(setPractiseSearchType(value))
    dispatch(setPracticeAreaSearchObject({}))
    dispatch(setPracticeAreaValue(''))
    dispatch(setPractiseArea(''))
    setSearchName('')
    setSearchType(value)
  }
  const onChangeSearch = value => {
    window.sessionStorage.setItem('searchName', value)
    dispatch(setPractiseSearchName(searchName))
    setSearchName(value)
  }

  return (
    <>
      <MainSearch type={type}>
        {!searchType && (
          <PracticeAreaAutocomplete
            type={type}
            currentSearchPracticeArea={currentSearchPracticeArea}
            setError={setError}
            setAreaObject={setAreaObject}
          />
        )}
        {!!searchType && (
          <TextField
            variant="outlined"
            fullWidth
            placeholder="legal topic or attorney name"
            onChange={e => onChangeSearch(e.target.value)}
            value={searchName}
            className={cn({
              [classes.startPageInput]: type === 'startPage',
              [classes.headerPageInput]: type === 'header',
            })}
            inputProps={{
              maxLength: 50,
              className: type === 'startPage' ? classes.headerInputRoot : classes.headerInputRootSearch,
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notched,
                root: type === 'startPage' ? classes.searchRoot : classes.headerPageInputRoot,
              },
            }}
          />
        )}
        <SearchTypeSelect classes={classes} value={searchType} setValue={onChangeValue} type={type} />

        <StatesAutocomplete
          type={type}
          setCurrentSearchState={setCurrentSearchState}
          currentSearchState={currentSearchState}
        />
        {mountSearchButton()}
      </MainSearch>
      {type === 'startPage' && error && (
        <div className={classes.errorContainer}>
          <SvgIcon component={AlertTriangle} viewBox="0 0 24 24" className={classes.alertIcon} />
          <Typography className={classes.error}>please enter a legal topic</Typography>
        </div>
      )}
    </>
  )
}

const SearchTypeSelect = ({ classes, value, setValue, type }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const onItemClick = v => {
    setAnchorEl(null)
    setValue(v)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<ActionsIcon />}
        className={cn({ [classes.buttonType]: true, [classes.buttonTypeSearch]: type === 'header' })}
      >
        {!value ? 'TOPIC' : 'NAME'}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: cn(classes.typesList, { [classes.typesListExtra]: value }) }}
      >
        <MenuItem classes={{ root: cn({ [classes.activeItem]: !value }) }} onClick={() => onItemClick(0)}>
          Practice area or legal topic
        </MenuItem>
        <MenuItem classes={{ root: cn({ [classes.activeItem]: value }) }} onClick={() => onItemClick(1)}>
          Name
        </MenuItem>
      </Menu>
    </>
  )
}

export default withRouter(Search)
