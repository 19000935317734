import React, { useState } from 'react'
import { ActionButton, Tooltip, VaultPlansModal } from 'components'
import { ReactComponent as VaultLogo } from 'static/vault/vault.svg'
import { ReactComponent as VaultDisabledLogo } from 'static/vault/vault_disabled.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { openVaultModal, setVaultOpenPlace } from 'store/actions/vault'

const useStyles = makeStyles({
  tooltip: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: 16,
  },
})

const disabledNoCasesVaultText = 'You will be able to add files only for the client once you create the first case.'
const disabledClientNoCasesVaultText = 'You will be able to add files once your lawyer creates the first case with you.'
const disabledInitialCasesVaultText = 'You can not upload files for initial cases.'

const VaultButton = ({ disabled, className, options, isInitialCase, vaultPlace }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isPlanModalOpen, setPlanModalOpen] = useState(false)
  const isVaultPlanAccepted = useSelector(state => state.vault.isVaultPlanAccepted)
  const setVaultModalOpen = async () => {
    dispatch(setVaultOpenPlace(vaultPlace))
    await dispatch(openVaultModal(options))
    setPlanModalOpen(false)
  }

  const getTooltipTitle = () => {
    if (isInitialCase) {
      return disabledInitialCasesVaultText
    } else if (options.isClientVault) {
      return disabledClientNoCasesVaultText
    } else {
      return disabledNoCasesVaultText
    }
  }

  const handleClick = async () => {
    if (!isVaultPlanAccepted && !options.proID) {
      setPlanModalOpen(true)
      return
    }
    await setVaultModalOpen()
  }
  return (
    <div className={className}>
      {disabled || isInitialCase ? (
        <Tooltip tooltipClass={classes.tooltip} title={getTooltipTitle()}>
          <ActionButton disabled>
            <VaultDisabledLogo />
          </ActionButton>
        </Tooltip>
      ) : (
        <ActionButton onClick={handleClick}>
          <VaultLogo />
        </ActionButton>
      )}
      <VaultPlansModal
        isOpen={isPlanModalOpen}
        openVaultModal={setVaultModalOpen}
        onClose={() => setPlanModalOpen(false)}
      />
    </div>
  )
}

export default VaultButton
