import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Tooltip } from 'components'
import {
  ExpenseCreationDisabledTooltipText,
  ProfileIsUnderApprovalTooltipText,
  TimeCreationDisabledTooltipText,
} from './ActivityTooltips'
import { makeStyles } from '@material-ui/core'
import { goToZendesk } from 'store/actions/common'

const useStyles = makeStyles({
  addButtonContainer: {
    display: 'flex',
  },
  addButton: {
    padding: '5px 15px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  tooltipText: {
    fontSize: 13,
  },
  tooltip: {
    padding: 16,
  },
})

const TooltipButton = ({ button, profileIsApproved, disableExpense, disableTime }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  if (profileIsApproved && !disableExpense && !disableTime) return button
  else if (!profileIsApproved)
    return (
      <Tooltip
        title={
          <ProfileIsUnderApprovalTooltipText
            tooltipText={classes.tooltipText}
            goToZendesk={() => dispatch(goToZendesk())}
          />
        }
        tooltipClass={classes.tooltip}
        interactive
      >
        {button}
      </Tooltip>
    )
  else if (disableExpense)
    return (
      <Tooltip
        title={<ExpenseCreationDisabledTooltipText tooltipText={classes.tooltipText} />}
        tooltipClass={classes.tooltip}
        interactive
      >
        {button}
      </Tooltip>
    )
  else if (disableTime)
    return (
      <Tooltip
        title={<TimeCreationDisabledTooltipText tooltipText={classes.tooltipText} />}
        tooltipClass={classes.tooltip}
        interactive
      >
        {button}
      </Tooltip>
    )
}

const ActivitiesAddEntriesButtons = ({
  profileIsApproved,
  disableTime = false,
  disableExpense = false,
  handleActivityModal,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.addButtonContainer}>
      <TooltipButton
        profileIsApproved={profileIsApproved}
        disableExpense={disableExpense}
        button={
          <Button
            variant="primary"
            className={classes.addButton}
            disabled={!profileIsApproved || disableExpense}
            onClick={() =>
              handleActivityModal(config => ({ ...config, open: true, action: 'create', type: 'expense' }))
            }
          >
            new expense entry
          </Button>
        }
      />
      <TooltipButton
        profileIsApproved={profileIsApproved}
        disableTime={disableTime}
        button={
          <Button
            variant="primary"
            className={classes.addButton}
            disabled={!profileIsApproved || disableTime}
            onClick={() => handleActivityModal(config => ({ ...config, open: true, action: 'create', type: 'time' }))}
          >
            new time entry
          </Button>
        }
      />
    </div>
  )
}

export default ActivitiesAddEntriesButtons
