import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, ConfirmationModal, Tooltip } from 'components'
import { ReactComponent as CircleLogo } from 'static/icon_24_alert-circle.svg'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  wrapper: {
    height: 153,
    padding: '12px 8px',
    backgroundColor: colors.PRIMARY.COLOR[0],
    borderRadius: 4,
    border: 'solid 1px #fed9b4',
  },
  textInfo: {
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 1.46,
    letterSpacing: 'normal',
  },
  beforeText: {
    fontSize: 13,
    lineHeight: 1.46,
    fontWeight: 500,
    marginBottom: 4,
  },
  listItem: {
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 1.46,
    marginTop: 8,
  },
  list: {
    marginLeft: 18,
    listStyleType: 'circle',
    color: colors.PRIMARY.COLOR[50],
  },
})

const BeforeDeleteTooltipContent = () => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.beforeText}>Before we can delete your account, please ensure</Typography>
      <ul className={classes.list}>
        <li>
          <Typography className={classes.listItem}>
            You have entered all work performed for your clients in the activity table – please do this immediately so
            XIRA can invoice all remaining activities
          </Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>All invoices have been sent to your clients and are paid</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>You have no scheduled meetings</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>All your cases are made inactive</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>
            Once all steps above are completed, request final payment from XIRA or pay XIRA if there is an open balance.
            Your wallet balance must be $0.00.
          </Typography>
        </li>
      </ul>
    </>
  )
}

const BlockedStatus = ({ onCancelDeletion }) => {
  const classes = useStyles()
  const [isDeleteConfirmOpen, openDeleteConfirm] = useState(false)
  const cancelAccountDeletion = () => {
    openDeleteConfirm(true)
  }
  const onDeleteConfirmClose = () => {
    openDeleteConfirm(false)
  }
  const onConfirmCancelDeletion = () => {
    openDeleteConfirm(false)
    onCancelDeletion()
  }
  return (
    <>
      <div className={classes.wrapper}>
        <Tooltip title={<BeforeDeleteTooltipContent />} tooltipClass={classes.tooltip} interactive>
          <CircleLogo />
        </Tooltip>
        <Typography className={classes.textInfo}>
          Your request for account deletion was sent to XIRA Admin which may require several days to process.
        </Typography>
        <Button variant="text" onClick={cancelAccountDeletion}>
          Cancel account deletion
        </Button>
      </div>
      <ConfirmationModal
        title="Remove account deletion request"
        message="If you cancel your request, your account won't be deleted. Continue?"
        confirmationButtonText="yes, remove request"
        isOpen={isDeleteConfirmOpen}
        onClose={onDeleteConfirmClose}
        onConfirm={onConfirmCancelDeletion}
      />
    </>
  )
}

export default BlockedStatus
