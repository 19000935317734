import React, { useMemo } from 'react'
import { Typography, Button } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { colors } from '../../constants'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import LegalTopicSelector from './LegalTopicSelector'
import { useQuery } from '../../utils/hooks'

const useStyles = makeStyles({
  title: {
    fontSize: 16,
    marginBottom: 17,
  },
  btnContainer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 30,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
})

const schema = Yup.object({
  topic: Yup.string()
    .trim()
    .required('Please fill in the field'),
})

const MeetingSchedulerLegalTopic = ({ onSubmit, onContinue, cancelButton, onTopicChange }) => {
  const classes = useStyles()

  const query = useQuery()
  const practiceArea = query.get('area')
  const proAccount = useSelector(state => state.search.proAccount)
  const options = useMemo(
    () =>
      proAccount.practiceAreas
        .map(area =>
          area.selected_sub_specialities.map(e => ({
            value: e.uuid,
            name: e.name,
            label: e.name,
            practiceArea: area.name,
            practiceAreaUuid: area.selected_practice_area.uuid,
          }))
        )
        .flat(),
    [proAccount.practiceAreas]
  )

  const initialValues = { topic: options.find(el => el.name === practiceArea)?.value || '' }

  return (
    <div>
      <Typography classes={{ h4: classes.title }} variant="h4">
        Select area of law or legal topic
      </Typography>

      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onContinue} enableReinitialize>
        {props => (
          <Form>
            <LegalTopicSelector options={options} onChange={onTopicChange} />

            <div className={classes.btnContainer}>
              {cancelButton}
              <Button type="submit" variant="primary" className={classes.button}>
                {onSubmit ? 'complete' : 'continue'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MeetingSchedulerLegalTopic
