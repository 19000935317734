import React from 'react'
import { ButtonGroup } from '@material-ui/core'
import { Button, Tooltip, Typography } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import classNames from 'classnames'
import makeStyles from '@material-ui/core/styles/makeStyles'

const ACTIVITIES_TYPE = {
  TIME: 0,
  EXPENSE: 1,
}

const buttons = [
  {
    type: 0,
    text: CONSTANTS.ACTIVITY_TYPE[ACTIVITIES_TYPE.TIME].icon,
    hint: CONSTANTS.ACTIVITY_TYPE[ACTIVITIES_TYPE.TIME].hint,
  },
  {
    type: 1,
    text: CONSTANTS.ACTIVITY_TYPE[ACTIVITIES_TYPE.EXPENSE].icon,
    hint: CONSTANTS.ACTIVITY_TYPE[ACTIVITIES_TYPE.EXPENSE].hint,
  },
]

const useStyles = makeStyles({
  buttonRoleOutlined: {
    width: 55,
    height: 40,
    padding: 0,
    textTransform: 'none',
    fill: colors.BASIC.COLOR[70],
  },
  buttonRolePrimary: {
    color: colors.WHITE,
    fill: colors.WHITE,
    backgroundColor: colors.PRIMARY.COLOR[50],
    border: 'none',
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.WHITE,
    },
  },
  btnGroup: {
    height: 42,
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR[80],
  },
  tooltipText: {
    fontSize: 13,
    color: colors.WHITE,
  },
  btnWithIcon: {
    '& .MuiTypography-body1': {
      width: '100%',
      paddingTop: 7,
    },
  },
})
const ActivitiesFilterButtons = ({ changeActivityTypeFilter, activityTypeFilter }) => {
  const classes = useStyles()
  return (
    <ButtonGroup size="small" classes={{ root: classes.btnGroup }}>
      <Button
        key={null}
        onClick={() => changeActivityTypeFilter(null)}
        className={classNames({
          [classes.buttonRolePrimary]: activityTypeFilter === null,
          [classes.buttonRoleOutlined]: true,
        })}
        variant="secondary"
      >
        All
      </Button>
      {buttons.map(({ type, text, hint }) => (
        <Button
          key={type}
          onClick={() => changeActivityTypeFilter(type)}
          className={classNames({
            [classes.buttonRolePrimary]: activityTypeFilter === type,
            [classes.buttonRoleOutlined]: true,
          })}
          variant="secondary"
          classes={{ root: classes.btnWithIcon }}
        >
          <Tooltip
            title={
              <Typography className={classes.tooltipText} variant="subtitle1">
                {hint}
              </Typography>
            }
            tooltipClass={classes.tooltip}
          >
            {text}
          </Tooltip>
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default ActivitiesFilterButtons
