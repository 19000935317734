export const getCasesParameters = caseState => {
  return {
    search: caseState.filters.search || null,
    is_active: caseState.filters.status,
    client: caseState.filters.clientId || null,
    limit: caseState.tableConfiguration.limit,
    offset: caseState.tableConfiguration.offset,
    ordering: caseState.sort.property ? `${caseState.sort.isAsc ? '' : '-'}${caseState.sort.property}` : null,
  }
}
