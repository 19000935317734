import React from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames/bind'
import { PhoneInput } from 'components'
import InputAdornment from '@material-ui/core/InputAdornment'
import XTextField from '../textField'
import { colors, CONSTANTS } from '../../../constants'
import XInputLabel from '../text/inputLabel'
import Hint from '../text/hint'
import XIcon from '../Icon'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
  },
  input: {
    marginBottom: '4px',
  },
  focused: {
    borderColor: colors.BASIC.COLOR[80],
    borderWidth: '1px',
  },
  notchedOutline: {
    borderColor: colors.BASIC.COLOR[50],
    '&$focused': {
      borderColor: colors.BASIC.COLOR[80],
    },
  },
  hint: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: '8px',
  },
  label: {
    textAlign: 'left',
    display: 'flex',
  },
  optional: {
    color: colors.BASIC.COLOR[50],
    margin: '0 0 0 5px',
  },
  valid: {
    color: colors.SUCCESS,
  },
  error: {
    borderColor: colors.ERROR,
  },
  large: {
    width: '368px',
    marginBottom: 0,
  },
  inputWrapper: {
    width: '100%',
  },
  responsive: {
    width: '100%',
    marginBottom: 0,
  },
  labelWithTooltip: {
    marginBottom: 6,
  },
})

const getInput = type => {
  switch (type) {
    case CONSTANTS.INPUT_TYPE.PHONE:
      return PhoneInput
    case CONSTANTS.INPUT_TYPE.TEXT:
    default:
      return XTextField
  }
}

const XFormikInput = ({
  label,
  type,
  containerClasses,
  inputClasses,
  hintClasses,
  size,
  responsive,
  required = true,
  needEndAddornment = true,
  tooltip,
  externalError,
  InputProps,
  inputProps,
  InputLabelProps,
  innerLabel,
  handleChange = () => {},
  handleBlur = () => {},
  ...props
}) => {
  const [field, meta] = useField(props)
  const classes = useStyles()
  const Input = getInput(type)
  const optional = required ? '' : <XInputLabel classes={{ root: classes.optional }}> (optional)</XInputLabel>
  const endAdornment =
    meta.touched && !meta.error && needEndAddornment ? (
      <InputAdornment position="end">
        <XIcon style={classes.valid} iconClass="las la-check" />
      </InputAdornment>
    ) : null

  const handleInputChange = e => {
    let event = e
    if (type === CONSTANTS.INPUT_TYPE.PHONE) {
      event = {
        target: {
          value: e,
          name: props.name,
        },
      }
    }
    field.onChange(event)
    handleChange(event)
  }

  const handleInputBlur = e => {
    field.onBlur(e)
    handleBlur(e)
  }

  return (
    <div
      className={classNames({
        [classes.div]: true,
        [containerClasses]: true,
        [classes.inputWrapper]: size === 'responsive',
      })}
    >
      {!innerLabel &&
        <XInputLabel classes={{ root: classNames(classes.label, { [classes.labelWithTooltip]: tooltip }) }}>
          {label}
          {optional}
          {tooltip}
        </XInputLabel>}
      <Input
        {...field}
        {...props}
        // metaError={meta.error}
        InputLabelProps={InputLabelProps}
        externalError={externalError}
        type={type}
        label={innerLabel && label}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        inputProps={inputProps}
        InputProps={{
          endAdornment: endAdornment,
          classes: {
            root: classNames(classes.input, inputClasses, {
              [classes.large]: size === 'large',
              [classes.responsive]: size === 'responsive',
              [inputClasses]: true,
            }),
            responsive: responsive === 'responsive' && classes.responsive,
            focused: classes.focused,
            notchedOutline: meta.touched && meta.error ? classes.error : classes.notchedOutline,
          },
          ...InputProps,
        }}
      />
      {meta.touched && meta.error && (
        <Hint classes={{ subtitle2: classNames(classes.hint, hintClasses) }}>
          <XIcon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {meta.error}
        </Hint>
      )}
    </div>
  )
}

export default XFormikInput
