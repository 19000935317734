import React from 'react'
import { Button, Icon, Modal, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { setRequestFundsErrorModalOpen } from 'store/actions/wallet/wallet'
import { navigateToStripeConnect } from 'store/actions/navigation'

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    margin: '0 0 24px 0',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    maxWidth: '568px',
  },
  boxBtn: {
    textAlign: 'right',
    marginTop: 14,
  },
  icon: {
    color: 'red',
    marginRight: 8,
    fontSize: 36,
  },
  '@media(max-width:767px)': {
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: colors.WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
  },
})

const RequestFundsErrorModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isPendingFundsErrorModalOpen = useSelector(state => state.wallet.isPendingFundsErrorModalOpen)

  const onClose = () => {
    dispatch(setRequestFundsErrorModalOpen(false))
  }

  const onNavigate = () => {
    dispatch(navigateToStripeConnect())
  }

  return (
    <Modal
      open={isPendingFundsErrorModalOpen}
      handleClose={onClose}
      maxWidth={false}
      dialogClasses={{ paper: classes.paper }}
    >
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          <Icon iconClass="las la-exclamation-triangle" style={classes.icon} />
          Please create your payment account to request funds
        </Typography>
        <Typography variant="subtitle1">Please create your payment account to request funds</Typography>

        <div className={classes.boxBtn}>
          <Button variant="primary" onClick={onNavigate} className={classes.doneBtn}>
            create payment account
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RequestFundsErrorModal
