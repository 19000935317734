import React from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { colors, CONSTANTS } from 'constants/index'
import { Typography } from 'components'

const useStyles = makeStyles({
  drawerPaper: {
    width: 236,
    paddingTop: 50,
    borderRight: 0,
    backgroundColor: colors.BASIC.COLOR[0],
  },
  list: {
    padding: 0,
    paddingTop: 24,
  },
  listItem: {
    padding: '17px 0 17px 40px',
    color: colors.BASIC.COLOR[70],
    fontSize: 15,
    height: 34,
    fill: colors.BASIC.COLOR[70],
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.BASIC.COLOR[100],
      fill: colors.BASIC.COLOR[100],
    },
  },
  listItemIcon: {
    minWidth: 16.5,
    marginRight: 12,
    margin: 0,
    color: 'inherit',
  },
  listItemTextPrimary: {
    fontWeight: 500,
    color: 'inherit',
    textTransform: 'lowercase',
  },
  isActive: {
    '& $listItemTextPrimary': {
      color: colors.PRIMARY.COLOR[50],
    },
    '& $listItem': {
      fill: colors.PRIMARY.COLOR[50],
    },
  },
  titleNav: {
    margin: '32px 0 0 40px',
    fontWeight: 500,
    lineHeight: '1.31',
  },
  navLink: {
    textDecoration: 'none',
  },
})

const ClientNavDrawer = () => {
  const classes = useStyles()

  const getItem = item => {
    const { route, icon, text } = item
    return (
      <NavLink
        key={route}
        to={`/client/account/${route}`}
        activeClassName={classes.isActive}
        className={classes.navLink}
      >
        <ListItem
          button
          classes={{
            root: classes.listItem,
          }}
        >
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.listItemTextPrimary,
            }}
            primary={text}
          />
        </ListItem>
      </NavLink>
    )
  }

  const getListItems = () => {
    return CONSTANTS.CLIENT_DRAWER_MENU_ITEMS.map((item, i) => {
      return getItem(item, i)
    })
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        anchor="left"
      >
        <Typography className={classes.titleNav} variant="h3">
          My account
        </Typography>
        <List
          classes={{
            root: classes.list,
          }}
        >
          {getListItems()}
        </List>
      </Drawer>
    </>
  )
}

export default ClientNavDrawer
