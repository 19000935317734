import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, SvgIcon } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { setAdvancedCalendar } from 'store/actions/calendar'
import { colors } from 'constants/colors'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'
import { parseTimeOptions } from 'utils/calendar/advanced'

const useStyles = makeStyles({
  selectIcon: {
    fontSize: '1.5rem',
    position: 'absolute',
    right: 10,
    top: 8,
    zIndex: 0,
    color: colors.BASIC.COLOR[20],
  },
  select: {
    zIndex: 1,
  },
})

const BUFFER = 'buffer'

const EventBuffer = ({ handleLoading }) => {
  const classes = useStyles()
  const userBuffer = useSelector(state => state.calendar.advanced_calendar[BUFFER])
  const bufferOptions = useSelector(state => state.common.advancedEventBufferList)
  const dispatch = useDispatch()

  return (
    <Select
      options={parseTimeOptions(bufferOptions)}
      variant="outlined"
      className={classes.selectWrapper}
      fullWidth
      disablePlaceholder
      value={`${userBuffer}`}
      onChange={e => {
        handleLoading(true)
        return dispatch(setAdvancedCalendar({ [BUFFER]: e.target.value })).then(() => handleLoading(false))
      }}
      selectClasses={{ root: classes.select }}
      IconComponent={() => <SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.selectIcon} />}
    />
  )
}

export default EventBuffer
