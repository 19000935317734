import React from 'react'
import { ProCasesContainer, ProCreateCaseContainer, ProEditCaseContainer, ProViewCaseContainer } from 'containers'
import { Route, Switch } from 'react-router-dom'
import { profileIsApprovedCasesRedir } from '../../../auth'

const ProCasesPage = () => {
  return (
    <Switch>
      <Route path="/pro/account/cases/create" exact component={profileIsApprovedCasesRedir(ProCreateCaseContainer)} />
      <Route path="/pro/account/cases/:caseId" exact component={ProViewCaseContainer} />
      <Route
        path="/pro/account/cases/:caseId/edit"
        exact
        component={profileIsApprovedCasesRedir(ProEditCaseContainer)}
      />
      <Route path="/pro/account/cases" component={ProCasesContainer} />
    </Switch>
  )
}

export default ProCasesPage
