import React, { useState, useEffect } from 'react'
import {
  InvoicesTable,
  Typography,
  InvoicesFilters,
  NoDataPlaceholder,
  InvoicesAdditionalFilters,
  ColorCircularProgress,
  TableEmptyState,
  ConfirmationModal,
  Button,
  Tooltip,
  Link,
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterProCases } from 'store/actions/cases'
import {
  getProInvoices,
  resetInvoicesFilter,
  resetInvoicesSorting,
  setTableConfiguration,
  setInvoiceStatus,
} from 'store/actions/invoices'
import { getUserConnectionsForFilter } from 'store/actions/connections'
import { CONSTANTS } from 'constants/index'
import { isEqual } from 'lodash'
import { makeStyles } from '@material-ui/core'
import RecordTransactionModal from './RecordTransactionModal'
import moment from 'moment-timezone'
import NoInvoices from 'static/invoices/no_invoices.png'
import UploadInvoiceModal from '../../components/invoices/UploadInvoiceModal'
import classNames from 'classnames'
import { ReactComponent as IconUpload } from '../../static/icon_24_upload .svg'
import { goToZendesk } from '../../store/actions/common'
import { getCurrentProfileStatus } from '../../store/actions/accountProfile'

const useStyles = makeStyles({
  invoicesContainer: {
    padding: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 23,
  },
  addButton: {
    padding: '10px 24px',
    marginLeft: 24,
    height: 40,
    minHeight: 40,
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: 4,
      transform: 'rotate(180deg)',
      '& path': {
        fill: 'white',
      },
    },
  },
})

const currentTimezone = moment.tz.guess()
const ProInvoicesContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [additionalFilter, setAdditionalFilter] = useState(false)
  const [recordTransactionInvoice, setRecordTransactionInvoice] = useState(null)
  const [writeOffInvoice, setWriteOffInvoice] = useState(null)
  const invoices = useSelector(state => state.invoices.invoices)
  const invoicesAreLoading = useSelector(state => state.invoices.invoicesAreLoading)
  const filters = useSelector(state => state.invoices.filters)
  const initialFilters = useSelector(state => state.invoices.initialFilters)
  const count = useSelector(state => state.invoices.invoicesLength)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone) || currentTimezone
  const isInitialFilters = isEqual(initialFilters, filters)
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)

  const [openUploadInvoice, setOpenUploadInvoice] = useState(false)
  const changeOpenUploadInvoice = () => setOpenUploadInvoice(o => !o)

  useEffect(() => {
    dispatch(getProInvoices())
    dispatch(getFilterProCases())
    dispatch(getCurrentProfileStatus())
    dispatch(getUserConnectionsForFilter())
    return () => {
      dispatch(resetInvoicesFilter())
      dispatch(resetInvoicesSorting())
      dispatch(
        setTableConfiguration({
          tablePage: 0,
          offset: 0,
        })
      )
    }
  }, [])

  const getWriteOffMessage = () =>
    'Continue if you have performed legal services for a client forwhich you have not been/will not be compensated for despite all attempts to resolve with the client. Marking an invoice as “write-off” is not visible to your client. If the client does pay the invoice, you will need to manually change the status to “paid”.'

  const sendWriteOff = () => {
    dispatch(setInvoiceStatus(writeOffInvoice.uuid, CONSTANTS.INVOICE_TYPE_BY_LABEl.WRITE_OFF))
    setWriteOffInvoice(null)
  }

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  return (
    <div className={classes.invoicesContainer}>
      <div className={classes.header}>
        <Typography variant="h2">Invoices</Typography>
        {(count || !isInitialFilters) && (
          <InvoicesFilters
            invoices={invoices}
            invoicesAreLoading={invoicesAreLoading}
            filters={filters}
            setShowAdditionalFilter={setAdditionalFilter}
            additionalFilter={additionalFilter}
          />
        )}
        {!count &&
          isInitialFilters &&
          (profileIsApproved ? (
            <Button
              variant="primary"
              className={classNames(classes.addButton, classes.upload)}
              onClick={changeOpenUploadInvoice}
            >
              <IconUpload />
              {' upload invoice'}
            </Button>
          ) : (
            <Tooltip
              title={
                <span>
                  {'You can not upload invoices until your license is  approved. Please contact '}
                  <Link onClick={zendeskSso}>XIRA</Link>
                  {' admin for details.'}
                </span>
              }
              tooltipClass={classes.tooltip}
              interactive
            >
              <Button variant="primary" className={classNames(classes.addButton, classes.upload)} disabled>
                <IconUpload />
                {' upload invoice'}
              </Button>
            </Tooltip>
          ))}
      </div>
      {additionalFilter && (
        <InvoicesAdditionalFilters filters={filters} results={count} isInitialFilters={isInitialFilters} />
      )}
      {invoicesAreLoading ? (
        <ColorCircularProgress />
      ) : !count && isInitialFilters ? (
        <NoDataPlaceholder
          label="A sortable list of your client invoices and their status will appears on this page"
          hideButton
          imageSrc={NoInvoices}
        />
      ) : !count && !isInitialFilters ? (
        <TableEmptyState />
      ) : (
        <InvoicesTable
          invoices={invoices}
          setRecordTransactionInvoice={setRecordTransactionInvoice}
          setWriteOffInvoice={setWriteOffInvoice}
          timezone={timezone}
        />
      )}
      {!!recordTransactionInvoice && (
        <RecordTransactionModal
          open={!!recordTransactionInvoice}
          viewType="list"
          invoice={recordTransactionInvoice}
          onClose={() => setRecordTransactionInvoice(null)}
        />
      )}
      <ConfirmationModal
        title="Change the status of the invoice to “write-off”?"
        message={getWriteOffMessage()}
        isOpen={!!writeOffInvoice}
        onClose={() => setWriteOffInvoice(null)}
        onConfirm={sendWriteOff}
      />
      <UploadInvoiceModal open={openUploadInvoice} onClose={changeOpenUploadInvoice} />
    </div>
  )
}

export default ProInvoicesContainer
