import React, { useEffect, useRef, useState } from 'react'
import { Icon, Link, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  textContainer: {
    marginTop: 16,
    fontFamily: 'san-serif, "Roboto"',
    fontSize: 16,
    lineHeight: 1.69,
  },
  textContainerHidden: {
    fontFamily: 'san-serif, "Roboto"',
    fontSize: 16,
    overflow: 'hidden',
    position: 'relative',
    lineHeight: 1.69,
    maxHeight: 135,
    textAlign: 'justify',
    marginRight: '-1em',
    paddingRight: '1em',
    '&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      width: '1em',
      height: '1em',
      marginTop: '0.2em',
      background: 'white',
    },
  },
  link: {
    marginTop: 8,
    color: colors.BASIC.COLOR[80],
    cursor: 'pointer',
  },
  '@media(max-width:767px)': {
    textContainer: {
      marginTop: 0,
    },
  },
})

const BioProAccount = ({ bio, firstName, lastName }) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isReadMoreShown, setIsReadMoreShown] = useState(false)
  const textContainer = useRef(null)
  const classes = useStyles()
  useEffect(() => {
    if (textContainer && textContainer.current && textContainer.current.getBoundingClientRect().height > 136) {
      setIsHidden(true)
      setIsReadMoreShown(true)
    }
  }, [])
  return (
    <div>
      <Typography variant="h4">
        about {firstName} {lastName}
      </Typography>
      <div
        ref={textContainer}
        className={classNames({ [classes.textContainerHidden]: isHidden }, classes.textContainer)}
      >
        <p>{bio}</p>
      </div>
      {isReadMoreShown && (
        <Link classes={{ root: classes.link }} underline="always" onClick={() => setIsHidden(prevState => !prevState)}>
          {isHidden ? 'read more' : 'read less'}
        </Link>
      )}
    </div>
  )
}

export default BioProAccount
