import React, { useState, useEffect } from 'react'
import { ColorCircularProgress, AdvancedSection } from 'components'
import { useDispatch } from 'react-redux'
import { getAdvancedCalendarValues, openSnackbar } from 'store/actions/common'
import { getUserAdvancedCalendarValues } from 'store/actions/calendar'
import AdvancedSchedule from './AdvancedSchedule'
import TimezoneSetup from './TimezoneSetup'
import EventBuffer from './EventBuffer'

const ADVANCED_SCHEDULE_TITLE = 'Advance notice'
const ADVANCED_SCHEDULE_SUBTITLE = 'Choose the minimum scheduling time you require to prevent last minute bookings'
const EVENT_BUFFER_TITLE = 'Event buffer'
const EVENT_BUFFER_SUBTITLE = 'Set the time gap you need between meetings'
const TIMEZONE_SETUP_TITLE = 'My time zone'

const CalendarAdvanced = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    Promise.all([dispatch(getAdvancedCalendarValues()), dispatch(getUserAdvancedCalendarValues())])
      .then(() => setIsLoading(false))
      .catch(() => {
        dispatch(openSnackbar('error', 'Error while advanced info loading'))
        setIsLoading(false)
      })
  }, [dispatch])

  return isLoading ? (
    <ColorCircularProgress />
  ) : (
    <div>
      <AdvancedSection title={ADVANCED_SCHEDULE_TITLE} subtitle={ADVANCED_SCHEDULE_SUBTITLE}>
        <AdvancedSchedule
          handleLoading={loading => {
            setIsLoading(loading)
          }}
        />
      </AdvancedSection>
      <AdvancedSection title={EVENT_BUFFER_TITLE} subtitle={EVENT_BUFFER_SUBTITLE}>
        <EventBuffer
          handleLoading={loading => {
            setIsLoading(loading)
          }}
        />
      </AdvancedSection>

      <AdvancedSection title={TIMEZONE_SETUP_TITLE}>
        <TimezoneSetup handleLoading={loading => setIsLoading(loading)} />
      </AdvancedSection>
    </div>
  )
}

export default CalendarAdvanced
