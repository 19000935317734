import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { colors, CONSTANTS, INVITE_CLIENT_CONFIRMATION, routes } from 'constants/index'
import {
  Breadcrumbs,
  Button,
  CaseConfigurationForm,
  ColorCircularProgress,
  ConfirmationModal,
  InviteClientPopup,
  Typography,
} from 'components'
import { isEmpty } from 'lodash'
import { navigateTo, navigateToCaseList } from 'store/actions/navigation'
import { getProSpecificPracticeAreas, saveProCase } from 'store/actions/cases'
import { validateCaseCreateForm } from 'utils/validators'
import {
  getProConnections,
  getUserConnectionsForFilter,
  openInvitePopup,
  sendInviteLink,
} from 'store/actions/connections'
import { useQuery } from 'utils/hooks'
import { Link as RouterLink } from 'react-router-dom'
import { getDatePickerValueWithProTZ, getDateWithTZ } from '../../utils/getters/common'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'
import { ReactComponent as CheckIcon } from 'static/Check.svg'
import history from '../../history'
import { NotForFeeModal } from '../../components/cases/NotForFeeModal'

const useStyles = makeStyles({
  root: {
    padding: '24px 36px',
  },
  link: {
    color: colors.BASIC.COLOR[20],
    textDecoration: 'none',
  },
  standardMargin: {
    marginBottom: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: 13,
    padding: '5px 8px',
  },
  saveButton: {
    padding: '5px 24px',
    minHeight: 40,
    height: 40,
  },
  icon: {
    marginRight: 16,
  },
})

const getInitialCaseData = timezone => ({
  initial_case: '',
  system_case_id: '',
  invoicing_sections: [
    {
      invoice_amount: '',
      invoice_date: null,
    },
  ],
  name: '',
  hourly_rate: '',
  description: '',
  fee_type: 3,
  invoice_frequency: null,
  updated_at: '',
  is_active: true,
  invoicing: null,
  expense_invoicing: false,
  amount: '',
  client: '',
  practice_area: '',
  sub_speciality: '',
  date_of_creation: getDatePickerValueWithProTZ(getDateWithTZ(null, timezone), timezone),
})

const popupInitialInfo = () => ({
  inviteInfo: {
    first_name: '',
    last_name: '',
    username: '',
    street: '',
    suite: '',
    city: '',
    country: 840,
    state: '',
    zip: '',
    phone: '',
  },
  isCreateMode: true,
  clientID: '',
})

const ProCreateCaseContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const clientId = query.get('connection')
  const popupOpened = useSelector(state => state.connections.openInvitePopup)
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const caseFullInfoIsLoading = useSelector(state => state.cases.caseFullInfoIsLoading)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const isCasesAble = useSelector(state => state.auth.activatedFeature.hasCases)
  const activatedFeatureLoaded = useSelector(state => state.auth.activatedFeatureLoaded)
  const [caseData, setCaseData] = useState(getInitialCaseData(timezone))
  const [errors, setErrors] = useState({})
  const [popupInfo, setPopupInfo] = useState(popupInitialInfo())
  const [confirmationModalShow, setConfirmationModalShow] = useState(false)
  const [invitationStatusText, setInvitationStatusText] = useState({})

  useEffect(() => {
    setCaseData({
      ...caseData,
      client: clientId,
    })
  }, [clientId])

  useEffect(() => {
    dispatch(getUserConnectionsForFilter({ is_case_creation_available: 'true' }))
    dispatch(getProSpecificPracticeAreas())
  }, [])

  const onChange = value => {
    setCaseData({
      ...caseData,
      ...value,
    })
  }

  const changeErrors = newErrors => {
    setErrors({
      ...errors,
      ...newErrors,
    })
  }

  const onClosePopup = () => {
    dispatch(navigateTo('/pro/account/cases'))
  }

  const validateForm = () => {
    const newErrors = validateCaseCreateForm(caseData)
    setErrors(newErrors)
    return isEmpty(newErrors)
  }

  const onSavePress = async () => {
    if (!validateForm()) {
      return
    }

    try {
      await dispatch(saveProCase(caseData))
      dispatch(navigateToCaseList())
    } catch (e) {
      console.error(e)
    }
  }

  const onAddClientClick = () => {
    setPopupInfo(popupInitialInfo())
    dispatch(openInvitePopup(true))
  }

  const onSubmitInviteForm = async payload => {
    try {
      const response = await dispatch(sendInviteLink(payload))
      dispatch(openInvitePopup(false))
      dispatch(getUserConnectionsForFilter({ is_case_creation_available: 'true' }))
      setCaseData({
        ...caseData,
        client: response.client_uuid,
      })
      handleSuccessInvite(response, payload.invited_user.username)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSuccessInvite = (response, username) => {
    const texts = INVITE_CLIENT_CONFIRMATION(username)
    setInvitationStatusText(texts[response.invitation_status])
    setConfirmationModalShow(true)

    let client_status

    if (!response) return
    if (response.is_existing_user_connected) {
      client_status = 'registered'
    } else if (response.is_existing_user_connected === false) {
      client_status = 'not registered'
    } else {
      setPopupInfo({ clientID: response.client_uuid })
    }

    Analytics.track(EVENTS.PRO_INVITED_CLIENT, {
      client_status,
      client_id: response.client_uuid,
    })
  }

  const onPopupClose = () => {
    dispatch(openInvitePopup(false))
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs className={classes.standardMargin}>
        <RouterLink className={classes.link} to={routes.getProAccountCasesPath()}>
          cases
        </RouterLink>
        <Typography variant="body1">create case</Typography>
      </Breadcrumbs>
      {caseFullInfoIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <Typography variant="h3" className={classes.standardMargin}>
            Create case
          </Typography>
          <CaseConfigurationForm
            caseData={caseData}
            mode={CONSTANTS.MODE.ADD}
            onChange={onChange}
            errors={errors}
            setErrors={changeErrors}
            addClient={onAddClientClick}
          />
          <InviteClientPopup
            onClose={onPopupClose}
            onSubmit={onSubmitInviteForm}
            inviteInfo={popupInfo.inviteInfo}
            isCreateMode={popupInfo.isCreateMode}
            open={popupOpened}
          />
          <ConfirmationModal
            isOpen={confirmationModalShow}
            message={invitationStatusText.content}
            onClose={() => setConfirmationModalShow(false)}
            title={invitationStatusText.title}
            onConfirm={() => setConfirmationModalShow(false)}
            confirmationButtonText="continue"
            showCancel={false}
            icon={<CheckIcon className={classes.icon} />}
          />
          <div className={classes.buttonGroup}>
            <Button
              variant="text"
              className={classes.cancelButton}
              component={RouterLink}
              to={routes.getProAccountCasesPath()}
            >
              cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSavePress}
              className={classes.saveButton}
              disabled={!profileIsApproved}
            >
              create
            </Button>
          </div>
        </>
      )}
      <NotForFeeModal open={!isCasesAble && activatedFeatureLoaded} onClose={onClosePopup} />
    </div>
  )
}

export default ProCreateCaseContainer
