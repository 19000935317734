import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { ReactComponent as IconUpload } from 'static/icon_24_upload .svg'


const useStyles = makeStyles({
  fixedBottom: {
    position: 'fixed',
    bottom: 0,
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 56,
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  iconUpload: {
    transform: 'rotate(180deg)',
    marginRight: 4,
  },
  dowloadLink: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 12,
    padding: '0 16px 0',
    textTransform: 'uppercase',
    display: 'flex',
    fontWeight: 500,
    cursor: 'pointer',
    alignItems: 'center',
    height: '100%',
    width: 175,
  },
})

const downloadFile = (fileToDownload, name) => {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.setAttribute('download', name)
  link.href = fileToDownload
  link.click()
  document.body.removeChild(link)
}

const DownloadPDF = props => {
  const classes = useStyles()
  const showMobileMenu = useSelector(state => state.common.showMobileMenu)

  const [width, setWidth] = useState('100%')

  const setSize = () => {
    if (document.querySelector('.main-content')) setWidth(document.querySelector('.main-content').clientWidth)
  }

  useEffect(() => {
    setSize()
  })

  useEffect(() => {
    window.addEventListener('resize', setSize)

    return () => {
      window.removeEventListener('resize', setSize)
    }
  })

  return (
    <>
      {!showMobileMenu && (
        <div className={classes.fixedBottom} style={{ width }}>
          <div
            className={classes.dowloadLink}
            onClick={() => {
              downloadFile(props.file, props.name)
            }}
          >
            <SvgIcon component={IconUpload} viewBox="0 0 16 16" className={classes.iconUpload} />
            download as pdf
          </div>
        </div>
      )}
    </>
  )
}

export default DownloadPDF
