import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'

const XTooltip = ({
  children,
  tooltipClass,
  popperTooltipClass,
  arrowClass,
  title,
  interactive,
  arrow,
  childrenClass,
  placement,
  open,
  showChildren = false,
}) => (
  <Tooltip
    arrow={arrow}
    open={open}
    placement={placement}
    classes={{ tooltip: tooltipClass, arrow: arrowClass, popper: popperTooltipClass }}
    title={title}
    interactive={interactive}
  >
    {showChildren ? children : <Typography className={childrenClass}>{children}</Typography>}
  </Tooltip>
)

export default XTooltip
