import React from 'react'
import XTypography from '../typography'

const Hint = ({ children, classes }) => (
  <XTypography classes={classes} variant="subtitle2">
    {children}
  </XTypography>
)

export default Hint
