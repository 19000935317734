import React from 'react'
import XTypography from '../typography'

const Text = ({ children, classes, className }) => (
  <XTypography classes={classes} variant="subtitle1" className={className}>
    {children}
  </XTypography>
)

export default Text
