import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { CalendarView, Typography, MeetingTimeRange, Skeleton } from 'components'
import { setMeetingTime } from 'store/actions/meetingScheduler'
import MeetingModal from './MeetingModal'
import classNames from 'classnames'
import { navigateToMeetingSchedule } from 'store/actions/navigation'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLogin, setRedirectPath } from 'store/actions/common'
import { toggleMeetingModal } from 'store/actions/meetingScheduler'
import { colors } from 'constants/colors'
import { getProAccountAvailableTime } from 'store/actions/search'
import { isEmpty } from 'lodash'
import { ReactComponent as NoEvents } from 'static/calendar/no_time.svg'
import Analytics from '../../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../../utils/analytics/Events'

const useStyles = makeStyles({
  proCalendarContainer: {
    marginBottom: 24,
    position: 'relative',
  },
  title: {
    marginBottom: 24,
  },
  loader: {
    marginTop: 90,
    marginBottom: 120,
  },
  separator: {
    width: '100%',
    borderTop: `solid 1px ${colors.BASIC.COLOR[5]}`,
  },
  '@media(max-width:1279px)': {
    title: {
      textAlign: 'center',
      paddingTop: 25,
      marginBottom: 15,
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: colors.WHITE,
    opacity: 0.7,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  calendarContainer: {
    position: 'relative',
  },
  noEventsText: {
    fontSize: 15,
    fontWeight: 500,
  },
  overlayImgContainer: {
    marginTop: 90,
  },
})

const ProCalendar = ({
  firstName,
  lastName,
  photo,
  proId,
  practiceAreas,
  isInitial,
  connectionWithProExists,
  hasCalendar,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const showMeetingModal = useSelector(state => state.meetingScheduler.showMeetingModal)
  const [isMeetingModalOpen, openMeetingModal] = useState(showMeetingModal)
  const [selectedDate, handleDateChange] = useState(moment())
  const [minDate, setMinDate] = useState(moment())
  const [showCalendar, setShowCalendar] = useState(false)
  const proAccountAvailableTimeIsLoading = useSelector(state => state.search.proAccountAvailableTimeIsLoading)
  const availableTime = useSelector(state => state.search.proAccountAvailableTime)
  const practiceAreaParam = useSelector(state => state.search.practiceAreaParam)
  const currentUser = useSelector(state => state.auth.currentUser)
  const contactInfo = useSelector(state => state.profileCreation.profileData.contactInfo)
  const ref = useRef(null)
  const formattedDate = selectedDate.format('YYYY-MM-DD')
  const [openSelectModal, setOpenSelectModal] = useState(false)
  const meetingType = connectionWithProExists ? 'a meeting' : 'an initial consultation'

  useEffect(() => {
    const firstAvailableDay = moment(Object.keys(availableTime).sort()[0])
    setShowCalendar(!!Object.values(availableTime).length)
    setMinDate(firstAvailableDay)
    handleDateChange(firstAvailableDay)
  }, [availableTime])

  useEffect(() => {
    openMeetingModal(showMeetingModal)
  }, [showMeetingModal])

  const setCurrentDate = (date, newAvailableTime) => {
    if (newAvailableTime[date.format('YYYY-MM-DD')]) {
      handleDateChange(date)
      setOpenSelectModal(false)
    } else setCurrentDate(moment(date).add(1, 'd'), newAvailableTime)
  }

  const goToMeetingModal = time => {
    dispatch(setMeetingTime(time))
    dispatch(toggleMeetingModal(true))
  }

  const onTimeSlotClick = time => {
    const timeToStart = moment.unix(time.start).diff(moment()) / 1000 / 60 / 60
    Analytics.track(EVENTS.SELECT_TIME, {
      pro_id: proId,
      hours_before_event: timeToStart,
    })

    if (connectionWithProExists) {
      goToMeetingModal(time)
      return
    }
    if (isEmpty(currentUser)) {
      dispatch(
        setRedirectPath(`/client/schedule/${proId}?start=${time.start}&end=${time.end}&area=${practiceAreaParam}`)
      )
      dispatch(toggleLogin(true))
    } else {
      if (contactInfo.street && contactInfo.city && contactInfo.state && contactInfo.zip && contactInfo.phone) {
        goToMeetingModal(time)
      } else {
        dispatch(navigateToMeetingSchedule(proId, time.start, time.end, practiceAreaParam))
      }
    }
  }

  const onMonthChange = date => {
    if (date.diff(moment()) > 0) {
      setCurrentDate(date, availableTime)
    } else {
      setCurrentDate(moment(), availableTime)
    }
  }
  const closeMeetingModal = () => {
    dispatch(toggleMeetingModal(false))
  }

  const onDurationChange = () => {
    ref.current.scrollIntoView()
    dispatch(getProAccountAvailableTime(proId))
  }

  return (
    <div ref={ref} className={classNames(classes.proCalendarContainer)}>
      {proAccountAvailableTimeIsLoading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="rect" height={200} />
        </>
      ) : (
        <>
          <Typography variant="h4" className={classes.title}>
            book {meetingType} with {firstName}
          </Typography>
          <div className={classes.calendarContainer}>
            <CalendarView
              selectedDate={selectedDate}
              minDate={minDate}
              onMonthChange={onMonthChange}
              handleDateChange={(e, isFinish) => {
                if (isFinish) setOpenSelectModal(true)
                handleDateChange(e)
              }}
              disableDay={!showCalendar}
              availableTime={availableTime}
            />
            {showCalendar ? (
              <>
                <div className={classes.separator} />
                <MeetingTimeRange
                  selectedDate={moment(selectedDate).format('dddd, MMMM D YYYY')}
                  openSelectModal={openSelectModal}
                  setOpenSelectModal={setOpenSelectModal}
                  isInitial={isInitial}
                  onDurationChange={onDurationChange}
                  onClick={onTimeSlotClick}
                  availableTimeRange={availableTime[formattedDate]}
                />
                <MeetingModal
                  isOpen={isMeetingModalOpen}
                  onClose={closeMeetingModal}
                  firstName={firstName}
                  lastName={lastName}
                  meetingType={meetingType}
                  photo={photo}
                  proId={proId}
                  isInitialMeeting={!connectionWithProExists}
                  practiceAreas={practiceAreas}
                  contactInfo={contactInfo}
                />
                <div className={classes.separator} />
              </>
            ) : (
              <div className={classes.overlay}>
                <div className={classes.overlayImgContainer}>
                  <NoEvents />
                </div>
                <Typography className={classes.noEventsText}>
                  {hasCalendar ? 'no time slots are available' : 'No online calendar available'}
                </Typography>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ProCalendar
