import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import { ReactComponent as FolderIcon } from 'static/vault/moveModal/folder.svg'
import { ReactComponent as FolderCloseIcon } from 'static/vault/moveModal/folder_close.svg'
import { ReactComponent as FolderOpenIcon } from 'static/vault/moveModal/folder_open.svg'
import { FoldersList } from 'components'

const useStyles = makeStyles({
  listItemText: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  folderIcon: {
    marginRight: 8,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  listItemWrapper: {
    marginLeft: 8,
  },
  subFolder: {
    marginLeft: 25,
  },
  row: {
    display: 'flex',
  },
  iconContainer: {
    width: 27,
    paddingTop: 4,
  },
})

const FolderListItem = props => {
  const classes = useStyles()
  const {
    selectedFolder,
    setSelectedFolder,
    addFolder,
    cancelCreateFolder,
    defaultSubFolderName,
    nameCheck,
    onCreateFolderConfirm,
    folder,
    openFolders,
    openSubFolders,
    hideSubFolders,
  } = props
  const isOpen = openFolders.includes(folder.uuid)
  const folderChildren = folder.children.filter(child => child.isFolder)
  const folderHasChildren = !!folderChildren.length

  return (
    <div key={folder.uuid}>
      <ListItem
        button
        classes={{ root: classes.listItem }}
        selected={selectedFolder && selectedFolder.uuid === folder.uuid}
        onClick={() => setSelectedFolder(folder)}
      >
        <div className={classes.row}>
          <div className={classes.iconContainer}>
            {folderHasChildren &&
              !folder.isPrivateFolder &&
              !folder.relatedID &&
              (isOpen ? (
                <FolderOpenIcon className={classes.folderIcon} onClick={() => hideSubFolders(folder)} />
              ) : (
                <FolderCloseIcon className={classes.folderIcon} onClick={() => openSubFolders(folder)} />
              ))}
          </div>
          <FolderIcon className={classes.folderIcon} />
          <ListItemText primary={folder.name} classes={{ primary: classes.listItemText }} />
        </div>
      </ListItem>
      {isOpen && (
        <div className={classes.subFolder}>
          {folderHasChildren && (
            <FoldersList
              children={folderChildren}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              addFolder={addFolder}
              cancelCreateFolder={cancelCreateFolder}
              nameCheck={nameCheck}
              defaultSubFolderName={defaultSubFolderName}
              onCreateFolderConfirm={onCreateFolderConfirm}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default FolderListItem
