import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Alert,
  Button,
  Link,
  Modal,
  NoDataPlaceholder,
  ProfileShortView,
  SvgIcon,
  Typography,
  Loader,
  ColorCircularProgress,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconClock } from 'static/icon_24_clock.svg'
import NoDataImage, { ReactComponent as ScheduleImage } from 'static/schedule_and_time_icon.svg'
import SearchEmptyState from 'static/search/searc_empty_state.png'
import { useSelector } from 'react-redux'
import { colors } from 'constants/index'
import ProfileSearchSorting from './ProfileSearchSorting'
import { getNonLaunchedStateModalVisibility, setNonLaunchedStateModalVisibility } from 'utils/getters/common'

const useStyles = makeStyles({
  profileCount: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 35,
  },
  scroll: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    marginBottom: 8,
  },
  container: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapperMainContent: {
    padding: '30px 0 0 30px',
  },
  none: {
    display: 'none',
  },
  alert: {
    margin: '0 35px 16px 0',
    backgroundColor: colors.ADDITIONAL.COLOR[0],
    border: `1px solid ${colors.ADDITIONAL.COLOR[20]}`,
    borderRadius: 4,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[80],
  },
  modal: {
    maxWidth: 432,
    padding: '30px 25px 32px 25px',
    opacity: 0.9,
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  scheduleImage: {
    width: 308,
    height: 200,
    fill: 'none',
    marginBottom: 2,
  },
  backDrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
  },
  dialogPaper: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  titleModal: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 16,
    color: colors.BASIC.COLOR[100],
  },
  detailsModal: {
    maxWidth: 381,
    marginBottom: 24,
    fontSize: 15,
    fontWeight: 400,
    color: colors.BASIC.COLOR[100],
  },
  closeBtn: {
    minHeight: 'auto',
    padding: '10px 16px',
    lineHeight: 1,
    fontSize: 11,
  },
  alertTitle: {
    fontWeight: 500,
  },
  noDataContainer: {
    marginRight: 30,
  },
  noDataImage: {
    width: 300,
    height: 225,
  },
  noDataTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 12,
  },
  link: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.ADDITIONAL.COLOR[50],
    lineHeight: 1.4,
  },
  closeBtnModal: {
    display: 'none',
  },
  '@media (max-width: 767px)': {
    title: {
      display: 'none',
    },
    hide: {
      display: 'none',
    },
    profileCount: {
      justifyContent: 'flex-end',
    },
    wrapperMainContent: {
      paddingLeft: 16,
    },
    noDataContainer: {
      marginRight: 16,
    },
  },
})

const LAWYERS_NOT_FOUND_MSG = `No lawyers were found meeting your criteria. 
                              Please modify some of the search parameters and start a new search`

const LaunchedModal = ({ title, details, isOpen, onClose }) => {
  const classes = useStyles()
  return (
    <Modal
      open={isOpen}
      maxWidth={false}
      BackdropProps={{ classes: { root: classes.backDrop } }}
      dialogClasses={{ paper: classes.dialogPaper }}
      handleClose={onClose}
      closeButtonClass={classes.closeBtnModal}
    >
      <div className={classes.modal}>
        <SvgIcon component={ScheduleImage} viewBox="0 0 308 200" className={classes.scheduleImage} />
        <Typography variant="h4" className={classes.titleModal}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.detailsModal}>
          {details}
        </Typography>
        <div className={classes.boxBtn}>
          <Button variant="primary" className={classes.closeBtn} onClick={onClose}>
            got it, thanks!
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const ProList = props => {
  const { profiles = [], profilesLength, loadProfiles, increaseOffset, setSorter, sorter, searchListTitle } = props
  const classes = useStyles()
  const scrollContainer = useRef(null)
  const profilesEmptyMsg = useSelector(state => state.search.profilesEmptyMsg)
  const countryStateValue = useSelector(state => state.search.countryStateValue)
  const profileListIsLoading = useSelector(state => state.search.profileListIsLoading)
  const profileListIsLazyLoading = useSelector(state => state.search.profileListIsLazyLoading)
  const lawyers = profilesLength === 1 ? 'lawyer' : 'lawyers'
  const [openModal, setOpenModal] = useState(false)
  const launchedMsgIsExist = profilesEmptyMsg && profilesEmptyMsg.title && profilesEmptyMsg.details
  const xiraSupportEmail = (profilesEmptyMsg && profilesEmptyMsg.email) || 'notify@xira.com'
  const showLaunchedModal = launchedMsgIsExist && openModal && profilesLength !== 0
  const showLaunchedAlert = launchedMsgIsExist && !openModal && profilesLength !== 0
  const launchedEmail = (
    <Link href={`mailto:${xiraSupportEmail}`} className={classes.link}>
      {xiraSupportEmail}
    </Link>
  )

  useEffect(() => {
    const noLaunchedStateModalWasShown = getNonLaunchedStateModalVisibility(countryStateValue)
    if (launchedMsgIsExist && !noLaunchedStateModalWasShown) {
      setOpenModal(true)
    }
  }, [countryStateValue, profilesEmptyMsg])

  const onLoadMore = () => {
    if (profileListIsLoading) return
    increaseOffset()
    loadProfiles(false)
  }

  const onCloseModal = () => {
    setOpenModal(false)
    setNonLaunchedStateModalVisibility(countryStateValue, true)
  }

  return (
    <div ref={scrollContainer} className={classes.container}>
      <div className={classes.wrapperMainContent}>
        <div className={classes.title}>
          <Typography variant="h3">{searchListTitle || 'Search'}</Typography>
        </div>
        {showLaunchedAlert && (
          <Alert classes={{ root: classes.alert }} variant="outlined" icon={<IconClock />}>
            <span className={classes.alertTitle}>{launchedMsgIsExist && profilesEmptyMsg.title}</span>
            {'. '}
            <span className={classes.alertDetails}>{launchedMsgIsExist && profilesEmptyMsg.details}</span>
          </Alert>
        )}
        {profilesLength !== 0 && (
          <div className={classes.profileCount}>
            <Typography variant="subtitle2" className={classes.hide}>
              {profilesLength} {lawyers} found
            </Typography>
            <ProfileSearchSorting value={sorter} setSorter={setSorter} loadProfiles={loadProfiles} />
          </div>
        )}
        <InfiniteScroll
          scrollableTarget="profile_search_id"
          dataLength={profiles.length}
          hasMore={profilesLength > profiles.length}
          next={onLoadMore}
        >
          <div className={classes.scroll}>
            {!profiles.length && !profileListIsLoading && (
              <>
                {launchedMsgIsExist ? (
                  <NoDataPlaceholder
                    imageSrc={NoDataImage}
                    hideButton
                    imageClass={classes.noDataImage}
                    containerClass={classes.noDataContainer}
                    titleClass={classes.noDataTitle}
                    label={launchedMsgIsExist && profilesEmptyMsg.title}
                    details={launchedMsgIsExist && profilesEmptyMsg.details}
                    email={launchedEmail}
                  />
                ) : (
                  <NoDataPlaceholder
                    imageSrc={SearchEmptyState}
                    hideButton
                    imageClass={classes.noDataImage}
                    containerClass={classes.noDataContainer}
                    titleClass={classes.noDataTitle}
                    details={LAWYERS_NOT_FOUND_MSG}
                  />
                )}
              </>
            )}
            <>
              {!!profiles.length &&
                !profileListIsLoading &&
                profiles.map(pro => <ProfileShortView userData={pro} key={pro.uuid} />)}
              {profileListIsLoading && <ColorCircularProgress />}
              {profileListIsLazyLoading && <Loader />}
            </>
          </div>
        </InfiniteScroll>
      </div>
      <LaunchedModal
        isOpen={showLaunchedModal}
        onClose={onCloseModal}
        title={launchedMsgIsExist && profilesEmptyMsg.title}
        details={launchedMsgIsExist && profilesEmptyMsg.details}
      />
    </div>
  )
}

export default ProList
