import React from 'react'
import moment from 'moment-timezone'
import TableCell from '@material-ui/core/TableCell'
import classNames from 'classnames'
import { Typography, Link, VaultCasesBulkDownloadButton } from 'components'
import { CONSTANTS } from 'constants/index'
import { useStyles } from './rowStyles'
import { ReactComponent as SecureIcon } from 'static/vault/secure_folder.svg'
import { ReactComponent as MainFolderIcon } from 'static/vault/casesList/folder_row.svg'
import { ReactComponent as FolderActiveIcon } from 'static/vault/casesList/folder_active.svg'
import { ReactComponent as FolderInactiveIcon } from 'static/vault/casesList/folder_inactive.svg'
import { ReactComponent as FileActiveIcon } from 'static/vault/casesList/file_active.svg'
import { ReactComponent as FileInactiveIcon } from 'static/vault/casesList/file_inactive.svg'
import { formatBytes } from 'utils/vault/vault-helpers'

const FolderInfo = ({ folderAmount, classes }) => {
  return (
    <>
      {folderAmount ? (
        <FolderActiveIcon className={classes.secondaryIcon} />
      ) : (
        <FolderInactiveIcon className={classes.secondaryIcon} />
      )}
      <Typography className={classNames(classes.numbers, { [classes.inactiveNumber]: !folderAmount })}>
        {folderAmount}
      </Typography>
    </>
  )
}

const FileInfo = ({ fileAmount, classes }) => {
  return (
    <div className={classes.fileInfoContainer}>
      {fileAmount ? (
        <FileActiveIcon className={classes.secondaryIcon} />
      ) : (
        <FileInactiveIcon className={classes.secondaryIcon} />
      )}
      <Typography className={classNames(classes.numbers, { [classes.inactiveNumber]: !fileAmount })}>
        {fileAmount}
      </Typography>
    </div>
  )
}

const VaultCasesTableRow = ({ data, timezone }) => {
  const classes = useStyles()
  return (
    <>
      <TableCell classes={{ body: classes.tableCell }}>
        <div className={classes.cell}>
          {data.isGeneralFolder ? (
            <SecureIcon className={classes.mainFolderIcon} />
          ) : (
            <MainFolderIcon className={classes.mainFolderIcon} />
          )}
          <Link className={classes.link}>{data.name}</Link>
        </div>
      </TableCell>
      <TableCell classes={{ body: classes.tableCell }}>{`${formatBytes(data.size)}`}</TableCell>
      <TableCell classes={{ body: classes.tableCell }}>
        <div className={classes.cell}>
          <FolderInfo folderAmount={data.folderCount} classes={classes} />
          <FileInfo fileAmount={data.fileCount} classes={classes} />
        </div>
      </TableCell>
      <TableCell classes={{ body: classes.tableCell }}>
        {data.lastUploaded
          ? moment(data.lastUploaded)
              .tz(timezone)
              .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)
          : '-'}
      </TableCell>
      <TableCell classes={{ body: classes.tableCell }}>
        <VaultCasesBulkDownloadButton disabled={!data.folderCount && !data.fileCount} data={data} />
      </TableCell>
    </>
  )
}

export default VaultCasesTableRow
