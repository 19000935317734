import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import 'react-phone-input-2/dist/style.css'
import './phoneInput.scss'

const { ERROR } = colors

const useStyles = makeStyles({
  input: {
    borderColor: `${colors.BASIC.COLOR[50]} !important`,
  },
  dropDownBtn: {
    borderColor: `${colors.BASIC.COLOR[50]} !important`,
  },
  errorInput: {
    borderColor: `${ERROR} !important`,
  },
  errorDropDownBtn: {
    borderLeftColor: `${ERROR} !important`,
    borderTopColor: `${ERROR} !important`,
    borderBottomColor: `${ERROR} !important`,
  },
  fullWidth: {
    width: '100% !important',
  },
})

const XPhoneInput = ({ value, onChange, inputClass, metaError, fullWidth }) => {
  const classes = useStyles()
  const errorInputStyle = metaError ? classes.errorInput : null
  const errorDropDownBtnStyle = metaError ? classes.errorDropDownBtn : null
  const fullWidthStyle = fullWidth ? classes.fullWidth : null

  const onEdit = value => {
    let formattedValue = value

    if (value[0] !== '+') {
      formattedValue = `+${value}`
    }

    if (formattedValue[1] !== '1') {
      const a = formattedValue.split('')
      a[1] = '1'
      onChange(a.join())
      return
    }

    onChange(formattedValue)
  }

  return (
    <PhoneInput
      defaultCountry="us"
      disableAreaCodes
      value={value}
      onChange={onEdit}
      inputClass={`${classes.input} ${inputClass} ${errorInputStyle} ${fullWidthStyle}`}
      buttonClass={`${classes.dropDownBtn} ${errorDropDownBtnStyle}`}
      countryCodeEditable={false}
      onlyCountries={['us']}
      masks={{ us: '+. ...-...-....' }}
    />
  )
}

export default XPhoneInput
