import React from 'react'
import { FormikInput, Typography } from 'components'

const ClientBookingInfo = ({ formClasses, updateBookingInfo }) => {
  return (
    <>
      <Typography classes={{ h4: formClasses.title }} variant="h4">
        For When You Book
      </Typography>
      <Typography variant="subtitle1" classes={{ subtitle1: formClasses.subtitle }}>
        This information is only used to autofill your details and make it quicker for you to book.
      </Typography>
      <div className={formClasses.contacts}>
        <div className={formClasses.twoColumn}>
          <FormikInput
            variant="outlined"
            size="small"
            name="first_name"
            label="First Name"
            type="text"
            containerClasses={formClasses.inputContainer}
            handleChange={updateBookingInfo}
          />
          <FormikInput
            variant="outlined"
            size="small"
            name="last_name"
            label="Last Name"
            type="text"
            containerClasses={formClasses.inputContainer}
            handleChange={updateBookingInfo}
          />
        </div>
        <FormikInput variant="outlined" size="small" name="email" label="Email" type="text" disabled fullWidth />
      </div>
    </>
  )
}

export default ClientBookingInfo
