import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { Typography } from 'components'
import classNames from 'classnames'

const useStyles = makeStyles({
  indicator: {
    width: 6,
    height: 6,
    backgroundColor: colors.SUCCESS,
    borderRadius: '50%',
  },
  text: {
    color: colors.SUCCESS,
    marginLeft: 6,
    fontSize: 'inherit',
    fontWeight: 'normal',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
})

const OnlineIndicator = ({ className, showText = false }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.wrapper, className)}>
      <div className={classes.indicator} />
      {showText && <Typography className={classes.text}>online</Typography>}
    </div>
  )
}

export default OnlineIndicator
