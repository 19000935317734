import React from 'react'
import { Button, SvgIcon, Modal, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as AlertTriangle } from 'static/alert-triangle.svg'
import { colors } from '../../constants'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 550,
    position: 'relative',
    padding: '32px 32px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 25,
    fontSize: 15,
    lineHeight: 1.47,
  },
  boxBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: 20,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkbox: {
    width: '50%',
  },
  header: {
    display: 'flex',
    marginBottom: 16,
  },
  alertIcon: {
    marginRight: 8,
    color: '#F55838',
  },
  desc: {
    marginLeft: 33,
    marginRight: 33,
  },
})

const HideProfileConfirmation = ({ isOpen, onClose, onAccept }) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <div className={classes.header}>
          <SvgIcon component={AlertTriangle} viewBox="0 0 24 24" className={classes.alertIcon} />
          <Typography variant="h4" className={classes.title}>
            Deactivate your profile?
          </Typography>
        </div>
        <div className={classes.desc}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            <div className={classes.subtitleParagraph}>
              If you decide to temporarily deactivate your profile, you will not show up in any searches and NOT BE
              BOOKABLE FOR NEW CLIENTS.
            </div>
            <br />
            All your existing appointments will remain in your XIRA calendar. You should continue to attend them or
            inform your clients if you need to reschedule. Current clients will still be able to book new appointments
            with you. If you do not want your current clients to book new appointments, please mark yourself as
            unavailable in your XIRA calendar or deselect your working days in your XIRA profile. Access your “settings”
            in order to reactivate your profile.
          </Typography>
        </div>
        <div className={classes.boxBtn}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" className={classes.saveButton} onClick={onAccept}>
            Yes, Deactivate
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default HideProfileConfirmation
