import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLogin, toggleRestorePass, setRedirectPath } from 'store/actions/common'
import RestorePassword from './RestorePassword'

const RestoreDialog = props => {
  const dispatch = useDispatch()
  const showRestore = useSelector(state => state.common.showRestore)
  const [open, setOpen] = useState(showRestore)
  useEffect(() => {
    setOpen(showRestore)
  }, [showRestore])
  const goToLogIn = () => {
    dispatch(toggleRestorePass(false))
    dispatch(toggleLogin(true))
  }
  const closeModal = () => {
    dispatch(toggleRestorePass(false))
    dispatch(setRedirectPath(''))
  }
  return <RestorePassword open={open} goToLogIn={goToLogIn} handleClose={closeModal} />
}

export default RestoreDialog
