import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import XButton from './button'
import { colors } from '../../constants'

const useStyles = makeStyles({
  default: {
    height: '100%',
    borderColor: 'transparent',
    borderRadius: 0,
    backgroundColor: colors.WHITE,
    color: colors.BASIC.COLOR[80],
    boxShadow: '0 0 0 0',
    borderBottomWidth: '2px',
    '&:hover': {
      backgroundColor: colors.WHITE,
      borderBottomColor: colors.PRIMARY.COLOR[50],
      color: colors.PRIMARY.COLOR[50],
      boxShadow: '0 0 0 0',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: colors.WHITE,
    },
  },
  active: {
    height: '100%',
    borderColor: 'transparent',
    boxShadow: '0 0 0 0',
    borderRadius: 0,
    backgroundColor: colors.WHITE,
    borderBottomColor: colors.PRIMARY.COLOR[50],
    color: colors.PRIMARY.COLOR[50],
    borderBottomWidth: '2px',
  },
})
const NavButton = ({ children, onClick, active, endIcon, ...restProps }) => {
  const classes = useStyles()
  return (
    <XButton
      variant="nav"
      onClick={onClick}
      classes={{ outlined: active ? classes.active : classes.default, root: classes.root }}
      endIcon={endIcon}
      {...restProps}
    >
      {children}
    </XButton>
  )
}

export default NavButton
