import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ProAccountChatContainer } from 'containers'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserChats,
  resetSelectedChat,
  resetUserMessages,
  setChatInfo,
  setSelectedChat,
} from 'store/actions/chat/chat'
import { NoMessagesState } from 'components'
import { useQuery } from '../../utils/hooks'
import { getUserChat } from '../../store/actions/chat/chat'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
})

const ChatPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const chatId = query.get('chatId')
  const isProfileApproved = useSelector(state => state.accountProfile.isProfileApproved)

  useEffect(() => {
    dispatch(getUserChats())
    Analytics.track(EVENTS.PRO_OPENED_MESSAGES, {
      message_place: 'main_menu',
    })
    return () => {
      dispatch(resetSelectedChat())
      dispatch(resetUserMessages())
    }
  }, [])

  useEffect(() => {
    setupUserChatList()
  }, [chatId])

  const setupUserChatList = async () => {
    if (chatId) {
      try {
        const selectedChat = await dispatch(getUserChat(chatId))
        if (selectedChat) {
          dispatch(setSelectedChat(selectedChat))
          dispatch(setChatInfo(selectedChat.twilio_chat_sid, { unread_messages_count: 0 }))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <div className={classes.root}>
      {isProfileApproved ? (
        <ProAccountChatContainer />
      ) : (
        <NoMessagesState text="please wait for the approval to start messaging" />
      )}
    </div>
  )
}

export default ChatPage
