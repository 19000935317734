import React from 'react'
import { Chip, Icon, LanguageModal, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  chip: {
    marginRight: 10,
    marginBottom: 10,
    backgroundColor: colors.WHITE,
    border: `solid 1px ${colors.BASIC.COLOR[80]}`,
    color: colors.BASIC.COLOR[80],
  },
  chipContainer: {
    marginTop: 30,
  },
  title: {
    marginBottom: 8,
  },
  content: {
    marginTop: 24,
  },
  languages: {
    marginTop: 12,
  },
})

const LanguagesProAccount = ({ languages }) => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <Typography variant="h4">spoken languages</Typography>
      <div className={classes.languages}>
        {languages.map(language => (
          <Chip
            key={language.uuid}
            label={<Typography variant="subtitle1">{language.name}</Typography>}
            className={classes.chip}
          />
        ))}
      </div>
    </div>
  )
}

export default LanguagesProAccount
