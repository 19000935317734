import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../constants'
import { Avatar, Button, Typography } from '../index'
import NoPhoto from 'static/search/pro_no photo.png'
import { navigateToSearchProAccount } from '../../store/actions/navigation'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  cardContainer: {
    width: 588,
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    marginBottom: 26,
    marginRight: 24,
    padding: 16,
    '&:hover': {
      boxShadow: '1px 3px 16px 0 rgba(0, 0, 0, 0.1)',
    },
  },
  avatar: {
    height: 80,
    width: 80,
    cursor: 'pointer',
  },
  info: {
    flexGrow: 1,
    padding: 16,
  },
  button: {
    minHeight: 32,
    marginRight: 16,
    height: 32,
  },
  placeholder: {
    fontSize: 13,
    color: colors.BASIC.COLOR['50'],
  },
  name: {
    cursor: 'pointer',
    fontSize: 20,
    color: colors.BASIC.COLOR['100'],
    fontWeight: 500,

    '&:hover': {
      color: colors.PRIMARY.COLOR['50'],
    },
  },
})

const ClientInviteCard = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data, cardStyle, onHide, onAccept } = props

  const onNavigateToProPage = () => {
    dispatch(navigateToSearchProAccount(data.pro_uuid))
  }

  return (
    <div className={classNames(classes.cardContainer, cardStyle)}>
      <Avatar className={classes.avatar} src={data.pro_photo_thumbnail || NoPhoto} onClick={onNavigateToProPage} />
      <div className={classes.info}>
        <Typography variant="body" className={classes.placeholder}>
          Invitation
        </Typography>
        <Typography className={classes.name} onClick={onNavigateToProPage}>
          {data.pro_full_name}
        </Typography>
      </div>
      <div>
        {!data.is_hidden && (
          <Button variant="text" className={classes.button} onClick={() => onHide(data.uuid)}>
            Hide
          </Button>
        )}
        <Button variant="primary" className={classes.button} onClick={() => onAccept(data.uuid, data.pro_uuid)}>
          Accept
        </Button>
      </div>
    </div>
  )
}

export default ClientInviteCard
