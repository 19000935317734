import React, { useState } from 'react'
import { Icon, Button, SvgIcon, Typography, Menu, MenuItem } from 'components'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { ReactComponent as AMLogo } from 'static/AMEX.svg'
import { ReactComponent as MCLogo } from 'static/MC.svg'
import { ReactComponent as VisaLogo } from 'static/Visa.svg'
import { ReactComponent as JCBLogo } from 'static/ic_logo_JCB.svg'
import { ReactComponent as DCLogo } from 'static/ic_logo_dinner_club.svg'
import { ReactComponent as DiscoverLogo } from 'static/ic_logo_discover.svg'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.BASIC.COLOR[5]}`,
    marginBottom: 24,
    paddingBottom: 20,
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  containerForMeeting: {
    borderBottom: 'none',
    marginBottom: 0,
  },
  cardIcon: {
    height: 42,
    width: 64,
    marginRight: 16,
  },
  cardInfo: {
    display: 'flex',
  },
  defaultMark: {
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    color: colors.BASIC.COLOR[70],
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '6.5px 12px',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
  },
  button: {
    marginLeft: 8,
    border: `1px solid ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    padding: 3,
    minWidth: 0,
    color: colors.BASIC.COLOR[70],
  },
  subtitle: {
    color: colors.BASIC.COLOR[20],
  },
  title: {
    marginBottom: 4,
  },
  menu: {
    width: 195,
    padding: '0 10px',
  },
  '@media(max-width:767px)': {
    defaultMark: {
      padding: '6.5px 8px',
    },
  },
})

const DefaultMark = () => {
  const classes = useStyles()
  return (
    <div className={classes.defaultMark}>
      <Typography variant="body2">default</Typography>
    </div>
  )
}

const getCardIcon = (cardType = '') => {
  switch (cardType.toLowerCase()) {
    case 'visa':
      return <VisaLogo />
    case 'mastercard':
      return <MCLogo />
    case 'amex':
      return <AMLogo />
    case 'jcb':
      return <JCBLogo />
    case 'discover':
      return <DiscoverLogo />
    case 'diners':
      return <DCLogo />
    default:
      return <div />
  }
}

const CardRow = props => {
  const classes = useStyles()
  const { cardData, onEditCard, onSetDefault, onDeleteCard, showConfigurationButton = false, variant } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onDeletePress = () => {
    onDeleteCard(cardData.uuid)
    handleClose()
  }

  const onSetDefaultPress = () => {
    onSetDefault(cardData.uuid)
    handleClose()
  }

  const onEditCardPress = () => {
    onEditCard(cardData.uuid)
    handleClose()
  }

  return (
    <div className={classNames(classes.container, { [classes.containerForMeeting]: variant === 'setupMeeting' })}>
      <div className={classes.cardInfo}>
        <SvgIcon viewBox="0 0 64 42" className={classes.cardIcon}>
          {getCardIcon(cardData.brand)}
        </SvgIcon>

        <div>
          <Typography variant="body1" className={classes.title}>
            {cardData.label}
            {'    '}
            &middot;&middot;&middot;&middot;
            {'    '}
            {cardData.lastDigits}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.subtitle}
          >{`Expiration: ${cardData.expirationMonth}/${cardData.expirationYear}`}</Typography>
        </div>
      </div>
      <div className={classes.buttonGroup}>
        {cardData.isDefault && <DefaultMark />}
        {showConfigurationButton && (
          <>
            <Button variant="text" className={classes.button} onClick={handleClick}>
              <Icon iconClass="las la-cog" style={classes.icon} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={{ paper: classes.menu }}
            >
              {!cardData.isDefault && (
                <MenuItem onClick={onSetDefaultPress} classes={{ root: classes.menuItem }}>
                  make default
                </MenuItem>
              )}
              <MenuItem onClick={onEditCardPress} classes={{ root: classes.menuItem }}>
                edit
              </MenuItem>
              <MenuItem onClick={onDeletePress}>remove</MenuItem>
            </Menu>
          </>
        )}
      </div>
    </div>
  )
}

export default CardRow
