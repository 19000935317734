import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Typography, Button, Menu, MenuItem, ActionRow, ErrorModal } from 'components'
import { ReactComponent as GoBackIcon } from 'static/vault/header/chevron-left.svg'
import { ReactComponent as ActionsIcon } from 'static/vault/header/chevron-down.svg'
import { ReactComponent as DownloadLogo } from 'static/vault/folder/actions/download_action.svg'
import { ReactComponent as DeleteLogo } from 'static/vault/folder/actions/delete_action.svg'
import { ReactComponent as MoveLogo } from 'static/vault/folder/actions/move_action.svg'
import { ReactComponent as CopyLogo } from 'static/vault/folder/actions/copy_action.svg'
import { ReactComponent as PrintLogo } from 'static/vault/folder/actions/print_action.svg'
import { colors } from 'constants/index'
import {
  setVaultPreviewItem,
  downloadVaultFile,
  setVaultItemsAction,
  setVaultLoggingEventPrint,
  setVaultSingleActionItem,
} from 'store/actions/vault'
import { handleFilePrint, isPrintAvailable } from 'utils/vault/vault-helpers'
import { navigateTo } from 'store/actions/navigation'

const useStyles = makeStyles({
  viewItemHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewItemName: {
    fontSize: 15,
    fontWeight: 500,
  },
  actionIcon: {
    marginLeft: 4,
  },
  logo: {
    marginRight: 8,
  },
  buttonClass: {
    padding: '6px 16px',
    height: 32,
    marginRight: 12,
    fontSize: 11,
  },
  buttonColor: {
    backgroundColor: colors.PRIMARY.COLOR['0'],
  },
  iconClass: {
    marginRight: 4,
  },
})

const VaultFilePreviewHeader = ({ previewItem, isClientVault }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isError, setError] = React.useState(false)

  const getMenuAction = () => {
    const print = isPrintAvailable(previewItem)
      ? [
          {
            type: 'print',
            logo: <PrintLogo className={classes.logo} />,
          },
        ]
      : []
    const common = [
      {
        type: 'download',
        logo: <DownloadLogo className={classes.logo} />,
      },
      ...print,
    ]
    const pro = [
      {
        type: 'move',
        logo: <MoveLogo className={classes.logo} />,
      },
      {
        type: 'delete',
        logo: <DeleteLogo className={classes.logo} />,
      },
      {
        type: 'copy',
        logo: <CopyLogo className={classes.logo} />,
      },
    ]
    return isClientVault ? common : [...pro, ...common]
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuAction = async action => {
    if (action === 'download') dispatch(downloadVaultFile(previewItem))
    if (action === 'print') {
      try {
        await handleFilePrint(previewItem)
        dispatch(setVaultLoggingEventPrint(previewItem.uuid))
      } catch (e) {
        setError(true)
      }
    } else {
      dispatch(setVaultSingleActionItem([previewItem]))
      dispatch(setVaultItemsAction(action))
    }
    handleClose()
  }

  const handleGoBack = () => {
    dispatch(navigateTo(location.pathname))
    dispatch(setVaultPreviewItem(null))
  }

  return (
    <div className={classes.viewItemHeader}>
      <Button variant={'text'} className={classes.buttonClass} onClick={handleGoBack}>
        <GoBackIcon className={classes.iconClass} />
      </Button>
      <Typography className={classes.viewItemName}>{previewItem && previewItem.name}</Typography>
      <Button
        variant={'secondary'}
        className={classNames(classes.buttonClass, classes.buttonColor)}
        onClick={handleClick}
      >
        actions <ActionsIcon className={classes.actionIcon} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {getMenuAction().map(action => (
          <MenuItem key={action.type} onClick={() => handleMenuAction(action.type)}>
            <ActionRow title={action.type} logo={action.logo} disabled={action.disabled} />
          </MenuItem>
        ))}
      </Menu>
      <ErrorModal
        isOpen={isError}
        onClose={setError}
        title={"Can't perform print action on this file"}
        message={'Please download file to print or try in another browser'}
        cancelationButtonText="ok"
      />
    </div>
  )
}

export default VaultFilePreviewHeader
