import * as CONSTANTS from 'store/constants/notifications/notifications'

const initialState = {
  notificationsAreLoading: false,
  notifications: [],
  notificationsLength: 0,
  notificationListConfiguration: {
    limit: 15,
    offset: 0,
  },
  unreadNotificationsCounter: 0,
  adminUnreadCounter: 0,
  notAdminUnreadCounter: 0,
  notificationSnackbar: {
    isOpen: false,
    type: '',
    data: {},
    uuid: '',
  },
  adminNotifications: [],
  adminNotificationsLength: 0,
}

const notifications = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.SET_USER_NOTIFICATIONS_LIST:
      return {
        ...state,
        notifications: action.payload,
        notificationsLength: action.count,
      }
    case CONSTANTS.SET_USER_ADMIN_NOTIFICATIONS_LIST:
      return {
        ...state,
        adminNotifications: action.payload,
        adminNotificationsLength: action.count,
      }
    case CONSTANTS.SET_NOTIFICATIONS_ARE_LOADING:
      return {
        ...state,
        notificationsAreLoading: action.payload,
      }
    case CONSTANTS.SET_NOTIFICATION_LIST_CONFIGURATION:
      return {
        ...state,
        notificationListConfiguration: {
          ...state.notificationListConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_UNREAD_NOTIFICATIONS_COUNTER:
      return {
        ...state,
        unreadNotificationsCounter: action.payload.unread_count,
        adminUnreadCounter: action.payload.admin_messages_unread_count,
        notAdminUnreadCounter: action.payload.not_admin_messages_unread_count,
      }
    case CONSTANTS.SHOW_NOTIFICATION_SNACKBAR:
      return {
        ...state,
        notificationSnackbar: {
          isOpen: true,
          ...action.payload,
        },
      }
    case CONSTANTS.HIDE_NOTIFICATION_SNACKBAR:
      return {
        ...state,
        notificationSnackbar: {
          ...state.notificationSnackbar,
          isOpen: false,
        },
      }
    default:
      return state
  }
}

export default notifications
