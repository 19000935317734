import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { Typography, SubCalendarList, Button } from 'components'
import { useDispatch } from 'react-redux'
import { disconnectCalendar } from '../../store/actions/calendar'

const useStyles = makeStyles({
  title: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
  },
  company: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[80],
    fontSize: 13,
  },
  bodyText: {
    fontSize: 13,
    color: colors.BASIC.COLOR[50],
  },
  titleContainer: {
    display: 'flex',
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
    justifyContent: 'space-between',
    padding: 11,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  list: {
    padding: 16,
    margin: 0,
  },
  bodyContainer: {
    padding: 16,
    flexGrow: 1,
    backgroundColor: colors.WHITE,
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  icon: {
    fontSize: 20,
  },
  emptyTitle: {
    fontSize: 15,
  },
  listItem: {
    marginBottom: 7,
  },
  subContainer: {
    display: 'flex',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: colors.WHITE,
    paddingLeft: 16,
  },
})

const ConnectedCalendarCardBody = ({ calendar, setCalendar, removeSelectedCalendar }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const calendarSettings = CONSTANTS.CALENDAR_SETTINGS[calendar.provider]
  const onSubCalendarListChange = (id, value) => {
    const calendars = calendar.calendars.map(subCalendar => {
      return subCalendar.id === id ? { ...subCalendar, selected: value } : subCalendar
    })
    setCalendar({ ...calendar, calendars })
  }
  const onDisconnectClick = () => {
    removeSelectedCalendar(calendar)
    dispatch(disconnectCalendar(calendar.provider, true))
  }
  return (
    <>
      <div className={classes.titleContainer}>
        <div className={classes.subContainer}>
          <div>{calendarSettings.calendarLogo}</div>
          <div className={classes.columnContainer}>
            <Typography variant="body1" className={classes.company}>
              {calendarSettings.title}
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              {calendar.username}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <Typography variant="subtitle2">
          Set the calendar(s) to check for conflicts to prevent double bookings
        </Typography>
        <SubCalendarList
          calendars={calendar.calendars}
          onSubCalendarListChange={onSubCalendarListChange}
          border={false}
        />
      </div>
      <div className={classes.btnContainer}>
        <Button variant="text" onClick={onDisconnectClick}>
          disconnect
        </Button>
      </div>
    </>
  )
}

export default ConnectedCalendarCardBody
