import React from 'react'
import { navigateToRoot, navigateToLogin, navigateTo } from 'store/actions/navigation'
import { useDispatch } from 'react-redux'
import RestorePassword from './RestorePassword'
import { routes } from '../../constants'

const RestoreByRoute = props => {
  const dispatch = useDispatch()
  const disableClose = props.location.pathname === routes.getUserPasswordResetUnauthorizedPath()
  const goToLogIn = () => {
    if (disableClose) dispatch(navigateTo('/sign-in'))
    else dispatch(navigateToLogin())
  }

  const closeModal = () => {
    if (disableClose) return
    dispatch(navigateToRoot())
  }

  return <RestorePassword open goToLogIn={goToLogIn} handleClose={closeModal} disableClose />
}

export default RestoreByRoute
