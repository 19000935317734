import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  ContactInfoFormRef,
  ContactInfoForm,
  Icon,
  SvgIcon,
  ColorCircularProgress,
  Typography,
} from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { CONSTANTS } from '../../constants'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { MobileVerificationModal } from '../../components/profileCreation/MobileVerificationModal'

const useStyles = makeStyles({
  step: {
    padding: '0 32px',
    width: 800,
    marginBottom: 16,
  },
})

const ContactInfoStep = props => {
  const {
    contactInfo,
    goToNextStep,
    countries,
    changeContactInfo,
    states,
    setValidated,
    saveProfileContactInfo,
    getCurrentProfileContactInfo,
    contactInfoIsLoading,
    onReset,
    isValidation,
    resetValidation,
  } = props

  const initialValues = {
    street: contactInfo.street,
    suite: contactInfo.suite,
    city: contactInfo.city,
    state: contactInfo.state,
    zip: contactInfo.zip,
    phone: contactInfo.phone,
    optionalPhone: contactInfo.optionalPhone,
    country: contactInfo.country,
  }
  const classes = useStyles()
  const mockCountries = [
    {
      label: 'USA',
      value: 840,
      icon: (
        <SvgIcon classes={{ root: classes.selectIcon }}>
          <path fill="#F0F0F0" d="M16 2.667H0v10.666h16V2.667z" />
          <path
            fill="#D80027"
            d="M16 4H0v1.333h16V4zM16 6.667H0V8h16V6.667zM16 9.333H0v1.333h16V9.333zM16 12H0v1.333h16V12z"
          />
          <path fill="#2E52B2" d="M8 2.667H0V8.41h8V2.667z" />
          <path
            fill="#F0F0F0"
            d="M3.12 5.02l-.129.396h-.417l.337.245-.128.396.337-.245.337.245-.129-.396.337-.245h-.417L3.12 5.02zM3.248 6.846L3.12 6.45l-.129.396h-.417l.337.245-.128.396.337-.244.337.244-.129-.396.337-.245h-.417zM1.487 6.846l-.129-.396-.129.396H.812l.338.245-.13.396.338-.244.337.244-.129-.396.337-.245h-.416zM1.358 5.02l-.129.396H.812l.338.245-.13.396.338-.245.337.245-.129-.396.337-.245h-.416l-.129-.396zM3.12 3.59l-.129.395h-.417l.337.245-.128.397.337-.245.337.245-.129-.397.337-.245h-.417L3.12 3.59zM1.358 3.59l-.129.395H.812l.338.245-.13.397.338-.245.337.245-.129-.397.337-.245h-.416l-.129-.396zM4.881 5.02l-.128.396h-.417l.337.245-.129.396.338-.245.337.245-.13-.396.338-.245H5.01l-.128-.396zM5.01 6.846l-.128-.396-.13.396h-.416l.337.245-.129.396.338-.244.337.244-.13-.396.338-.245H5.01zM6.77 6.846L6.64 6.45l-.129.396h-.416l.337.245-.129.396.337-.244.337.244-.129-.396.338-.245H6.77zM6.641 5.02l-.129.396h-.416l.337.245-.129.396.337-.245.337.245-.129-.396.338-.245H6.77L6.64 5.02zM4.881 3.59l-.128.395h-.417l.337.245-.129.397.338-.245.337.245-.13-.397.338-.245H5.01l-.128-.396zM6.641 3.59l-.129.395h-.416l.337.245-.129.397.337-.245.337.245-.129-.397.338-.245H6.77L6.64 3.59z"
          />
        </SvgIcon>
      ),
    },
  ]
  const formRef = useRef()
  const [verifyNumber, setVerifyNumber] = useState('')

  useEffect(() => {
    getCurrentProfileContactInfo()
  }, [getCurrentProfileContactInfo])

  useEffect(() => {
    if (isValidation) handleContactInfo()
  }, [isValidation])

  const onOk = () => {
    setVerifyNumber('')
    goToNextStep()
    setValidated()
    Analytics.track(EVENTS.PRO_CONTACT_INFO_COMPLETED)
  }

  const onSubmit = values => {
    saveProfileContactInfo(values).then(() => {
      changeContactInfo(values)
      if (contactInfo.is_phone_confirmed && contactInfo.phone === values.phone) {
        onOk()
      } else {
        setVerifyNumber(values.phone)
      }
    })
  }

  const onClose = () => {
    setVerifyNumber('')
  }

  const handleContactInfo = () => {
    resetValidation()
    if (formRef && formRef.current) {
      formRef.current.submitForm()
    }
  }

  return contactInfoIsLoading ? (
    <ColorCircularProgress />
  ) : (
    <div>
      <div className={classes.step}>
        <Typography classes={{ h4: classes.title }} variant="h4">
          Contact Info
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Only your city and state will be displayed publicly
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          street: Yup.string()
            .trim()
            .max(100, 'Please ensure this field has no more than 100 characters')
            .required('Please fill in the field'),
          suite: Yup.string()
            .trim()
            .max(50, 'Please ensure this field has no more than 50 characters'),
          city: Yup.string()
            .trim()
            .max(50, 'Please ensure this field has no more than 50 characters')
            .required('Please fill in the field'),
          state: Yup.string()
            .trim()
            .required('Please fill in the field'),
          zip: Yup.string()
            .typeError('Please enter valid ZIP code (5 digits)')
            .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
            .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
            .required('Please fill in the field'),
          phone: Yup.string()
            .test('len', 'Please enter 10 digits', val =>
              val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
            )
            .required('Please fill in the field'),
          optionalPhone: Yup.string().test('len', 'Please enter 10 digits', val =>
            val === '+1' || !val ? true : val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
          ),
          country: Yup.string()
            .trim()
            .required('Please fill in the field'),
        })}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {props => (
          <ContactInfoFormRef
            ref={formRef}
            countries={mockCountries}
            className={classes.step}
            onSubmit={onSubmit}
            states={states}
            {...props}
          />
        )}
      </Formik>
      <MobileVerificationModal open={!!verifyNumber} number={verifyNumber} onOk={onOk} onClose={onClose} />
    </div>
  )
}

export default ContactInfoStep
