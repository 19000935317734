import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { VaultTable, SecureVaultTableRow, ActionRow } from 'components'
import { colors } from 'constants/index'
import { ReactComponent as DownloadLogo } from 'static/vault/folder/actions/download_action.svg'
import { ReactComponent as OpenLogo } from 'static/vault/folder/actions/open_action.svg'
import { openVaultModal, downloadToZipFile, setVaultOpenPlace } from 'store/actions/vault'

export const useStyles = makeStyles({
  tableRow: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: colors.ADDITIONAL.TRANSPARENT['8'],
      color: colors.ADDITIONAL.COLOR['50'],
      '& a': {
        color: colors.ADDITIONAL.COLOR['50'],
      },
    },
  },
  tableCellClass: {
    paddingLeft: 0,
  },
  logo: {
    marginRight: 8,
  },
})

const columns = [
  {
    value: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    value: 'size',
    label: 'Size',
    sortable: false,
  },
  {
    value: 'last_uploaded',
    label: 'Last Uploaded',
    sortable: true,
  },
]

const SecureVaultTable = ({ filesList }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  const navigateToCaseFolderDetails = (e, data) => {
    dispatch(setVaultOpenPlace('main_menu'))
    dispatch(openVaultModal({ userID: data.relatedID, isPrivateFolder: data.isPrivateFolder }))
  }

  const getRowActions = rowData => {
    const download = rowData.size
      ? [
          {
            key: 'download',
            label: <ActionRow classes={classes} title={'download'} logo={<DownloadLogo className={classes.logo} />} />,
            action: data => dispatch(downloadToZipFile([data])),
          },
        ]
      : []
    return [
      {
        key: 'open_folder',
        label: <ActionRow classes={classes} title={'open'} logo={<OpenLogo className={classes.logo} />} />,
        action: data => navigateToCaseFolderDetails(null, data),
      },
      ...download,
    ]
  }

  return (
    <VaultTable
      secure
      onRowClick={navigateToCaseFolderDetails}
      columns={columns}
      data={filesList}
      getRowActions={getRowActions}
      tableRowClasses={classes.tableRow}
      tableRow={<SecureVaultTableRow timezone={timezone} />}
      tableHeaderCellClass={{ root: classes.tableCellClass }}
      activeRows
    />
  )
}

export default SecureVaultTable
