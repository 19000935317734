import * as CONSTANTS from 'store/constants/activities/'
import axios, { CancelToken } from 'axios'
import { openSnackbar } from 'store/actions/common'
import { getActivitiesPath, getActivityPath, getActivitiesFilePath } from 'utils/path-helpers/api'
import { getActivitiesParameters, getActivitiesFileParameters } from 'utils/getters/activities'
import { mapActivityDataToBack, mapActivityDataToFront } from 'utils/mappers/backend'
import { navigateToActivitiesList } from '../navigation'

export const setTableConfiguration = payload => ({
  type: CONSTANTS.SET_TABLE_CONFIGURATION,
  payload,
})

export const setActivitiesOffset = payload => ({
  type: CONSTANTS.SET_OFFSET,
  payload,
})

export const setActivitiesLimit = payload => ({
  type: CONSTANTS.SET_LIMIT,
  payload,
})

export const setActivitiesFilter = (name, value) => ({
  type: CONSTANTS.SET_FILTERS,
  name,
  value,
})

export const resetActivitiesFilter = () => ({
  type: CONSTANTS.RESET_FILTERS,
})

export const resetActivitiesSorting = () => ({
  type: CONSTANTS.RESET_SORTING,
})

export const setActivitiesSorting = (property, isAsc) => {
  return {
    type: CONSTANTS.SET_SORTING,
    property,
    isAsc,
  }
}
export const resetActivity = () => {
  return {
    type: CONSTANTS.RESET_ACTIVITY,
  }
}

export const setPredefinedActivity = (property, value) => {
  return {
    type: CONSTANTS.SET_PREDEFINED_ACTIVITY,
    property,
    value,
  }
}

const getActivitiesStart = () => ({
  type: CONSTANTS.GET_ACTIVITIES_START,
})

const getActivitiesEnd = (payload, length) => ({
  type: CONSTANTS.GET_ACTIVITIES_END,
  payload,
  length,
})

const getActivitiesFileStart = () => ({
  type: CONSTANTS.GET_ACTIVITIES_FILE_START,
})

const getActivitiesFileEnd = () => ({
  type: CONSTANTS.GET_ACTIVITIES_FILE_END,
})

let cancelProActivitiesRequest

export const getProActivities = () => (dispatch, getState) => {
  cancelProActivitiesRequest && cancelProActivitiesRequest()
  dispatch(getActivitiesStart())
  return axios
    .get(getActivitiesPath(), {
      params: getActivitiesParameters(getState().activities),
      cancelToken: new CancelToken(c => {
        cancelProActivitiesRequest = c
      }),
    })
    .then(response => {
      const results = response.data.results.map(result => mapActivityDataToFront(result, getState().calendar))
      dispatch(getActivitiesEnd(results, response.data.count))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(getActivitiesEnd([]))
      dispatch(openSnackbar('error', 'Error while getting activities'))
      return Promise.reject(e)
    })
}

export const getProActivitiesFile = () => (dispatch, getState) => {
  dispatch(getActivitiesFileStart())
  return axios
    .get(getActivitiesFilePath(), { params: getActivitiesFileParameters(getState().activities), responseType: 'blob' })
    .then(response => {
      return response
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while exporting activities'))
      return Promise.reject(e)
    })
    .finally(() => {
      dispatch(getActivitiesFileEnd())
    })
}

export const getProActivity = uuid => (dispatch, getState) => {
  dispatch(getActivityStart())
  return axios
    .get(getActivityPath(uuid))
    .then(response => {
      dispatch(getActivityEnd(mapActivityDataToFront(response.data)))
    })
    .catch(e => {
      const errorMessage =
        (e.response && e.response.data && e.response.data.detail) || 'Error was occurred while activity creation'
      dispatch(openSnackbar('error', errorMessage))
      dispatch(navigateToActivitiesList())
      return Promise.reject(e)
    })
}

export const updateProActivity = (uuid, activity) => (dispatch, getState) => {
  dispatch(getActivityStart())
  return axios
    .put(getActivityPath(uuid), mapActivityDataToBack(activity, getState().calendar))
    .then(response => {
      dispatch(openSnackbar('success', 'Your activity was successfully updated'))
      dispatch(getActivityEnd({}))
    })
    .catch(e => {
      const errorMessage =
        (e.response && e.response.data && e.response.data.detail) || 'Error was occurred while activity update'
      dispatch(openSnackbar('error', errorMessage))
      dispatch(getActivityEnd(activity))
      return Promise.reject(e)
    })
}

export const deleteProActivity = (uuid, activity) => (dispatch, getState) => {
  dispatch(getActivityStart())
  return axios
    .delete(getActivityPath(uuid))
    .then(response => {
      dispatch(openSnackbar('success', 'Your activity was successfully deleted'))
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while deleting activity'))
      return Promise.reject(e)
    })
}

const getActivityStart = () => ({
  type: CONSTANTS.GET_ACTIVITY_START,
})

const getActivityEnd = payload => ({
  type: CONSTANTS.GET_ACTIVITY_END,
  payload,
})

export const createProActivity = activity => (dispatch, getState) => {
  return axios
    .post(getActivitiesPath(), mapActivityDataToBack(activity, getState().calendar))
    .then(response => {
      dispatch(openSnackbar('success', 'Activity was created successfully'))
    })
    .catch(e => {
      const errorMessage =
        (e.response && e.response.data && e.response.data.detail) || 'Error was occurred while activity creation'
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject(e)
    })
}
