import React, { useState } from 'react'
import { TextField, IconButton, Icon } from 'components'
import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  searchIcon: {
    color: colors.BASIC.COLOR[80],
    fontSize: 22,
  },
  inputRoot: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
})

const CommonSearch = ({
  search,
  setSearch,
  setFilter,
  setTableConfiguration,
  getSearchResults,
  searchClass,
  placeholder,
}) => {
  const classes = useStyles()

  const onSearch = () => {
    setFilter(search)
    setTableConfiguration()
    getSearchResults()
  }

  const onKey = event => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const onSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <TextField
      variant="outlined"
      className={searchClass}
      value={search}
      placeholder={placeholder}
      fullWidth
      InputProps={{
        startAdornment: (
          <IconButton onClick={onSearch} className={classes.searchButton}>
            <Icon iconClass="las la-search" style={classes.searchIcon} />
          </IconButton>
        ),
        classes: { root: classes.inputRoot },
      }}
      onChange={onSearchChange}
      onKeyPress={onKey}
    />
  )
}

export default CommonSearch
