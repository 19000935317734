import moment from 'moment-timezone'

export const convetAmPmTo24FormatTime = time => moment(time, ['h:mm A']).format('HH:mm')

export const replaceTime = (data, timezone) => {
  return data.map(event => {
    const eventStartTime = moment(event.start).tz(timezone)
    if (eventStartTime.hour() === 23 && eventStartTime.minute() > 30) {
      const startTime = moment(eventStartTime)
        .hour(23)
        .minutes(30)
        .toDate()
      return { ...event, start: startTime, originalStartTime: event.start }
    } else {
      return event
    }
  })
}
