import React from 'react'
import Table from '@material-ui/core/Table'
import { Icon, Link, ThreeDotsMenu, Checkbox, DraggableTableRow, CustomDragLayer } from 'components'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { TableFooter } from '@material-ui/core'
import { colors } from 'constants/index'
import classNames from 'classnames'

const matchValueLabel = (match, value) => {
  const matched = match.find(m => m.value === value)
  return matched ? matched.label : value
}

const useStyles = makeStyles({
  actionsColumn: {
    width: 50,
    padding: 0,
  },
  root: {
    color: colors.BASIC.COLOR[80],
    fontWeight: 'bold',
    width: 'max-content',
    '&.MuiTableSortLabel-active': {
      color: colors.PRIMARY.COLOR[50],
      fontWeight: 'bold',
      '&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
        color: colors.PRIMARY.COLOR[50],
        fontWeight: 'bold',
      },
    },
  },
  rowRoot: {
    '&.MuiTableRow-hover:hover': {
      cursor: 'pointer',
    },
  },
  headerCheckbox: {
    color: colors.PRIMARY.COLOR['50'],
  },
  selectedRow: {
    backgroundColor: colors.ADDITIONAL.TRANSPARENT['8'],
  },
})

const Row = ({ draggable, rowClasses, data, selectedRows, handleDrop, ...props }) => {
  return draggable ? (
    <DraggableTableRow
      rowClasses={rowClasses}
      data={data}
      selectedRows={selectedRows}
      handleDrop={handleDrop}
      {...props}
    />
  ) : (
    <TableRow {...props} classes={{ root: rowClasses }} />
  )
}

const XTable = ({
  className,
  ariaLabel,
  tableHeaderCellClass,
  tableColumns,
  tableData,
  getRowActions,
  tableBodyCellClass,
  tableCellTextClass,
  onSort,
  tableDataRowRenderer,
  footer,
  activeRows,
  sorting,
  getRowTitle,
  customColumnWidth,
  selectable,
  selectedRows,
  onSelectAllClick = () => {},
  onRowClick = () => {},
  tableRowClasses,
  draggable,
  dragPreviewComponent,
  handleDrop,
  disableSelection,
}) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState(sorting ? (sorting.isAsc ? 'asc' : 'desc') : 'asc')
  const [orderBy, setOrderBy] = React.useState(sorting && sorting.property ? sorting.property : null)
  const rowCount = tableData ? tableData.length : 0
  const numSelected = selectedRows ? selectedRows.length : 0
  const isIndeterminate = numSelected > 0 && numSelected < rowCount
  const isSelectedItems = selectedRows && selectedRows.length > 0
  const getSelected = data => (selectable && selectedRows ? !!selectedRows.find(row => row.uuid === data.uuid) : false)

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    if (onSort) {
      onSort(property, !isAsc)
    }
  }

  return (
    <TableContainer className={className}>
      <Table aria-label={ariaLabel}>
        {customColumnWidth && (
          <colgroup>
            {tableColumns.map((column, i) => (
              <col key={`colWidth_${i}`} width={column.width} />
            ))}
          </colgroup>
        )}
        <TableHead>
          <TableRow>
            {selectable && (
              <TableCell padding="checkbox" classes={tableHeaderCellClass}>
                <Checkbox
                  classes={{ root: classNames({ [classes.headerCheckbox]: isIndeterminate }) }}
                  indeterminate={isIndeterminate}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={e => onSelectAllClick(e, tableData)}
                  disabled={disableSelection}
                />
              </TableCell>
            )}
            {tableColumns.map(column => (
              <TableCell
                classes={tableHeaderCellClass}
                key={column.value}
                align={column.numeric ? 'right' : 'left'}
                sortDirection={orderBy === column.value ? order : false}
              >
                {column.sortable ? (
                  <TableSortLabel
                    classes={{ root: classes.root }}
                    active={orderBy === column.value}
                    direction={orderBy === column.value ? order : 'asc'}
                    onClick={() => handleRequestSort(column.value)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
            {getRowActions && <TableCell classes={tableHeaderCellClass} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(data => (
            <Row
              draggable={draggable}
              key={Math.random()}
              rowClasses={classNames(classes.rowRoot, tableRowClasses)}
              onClick={e => onRowClick(e, data)}
              hover={activeRows}
              title={getRowTitle ? getRowTitle(data) : ''}
              selectedRows={selectedRows}
              data={data}
              handleDrop={handleDrop}
            >
              {tableDataRowRenderer
                ? tableDataRowRenderer(tableColumns, data)
                : tableColumns.map(col => (
                    <TableCell title={data[col.value]} classes={tableBodyCellClass} key={col.value}>
                      <>
                        <p className={tableCellTextClass}>
                          {col.match ? matchValueLabel(col.match, data[col.value]) : data[col.value]}
                        </p>
                        {col.link && data[col.link] && (
                          <Link className={col.linkStyle} onClick={() => window.open(data[col.link])}>
                            <Icon style={col.iconStyle} iconClass={col.icon} />
                          </Link>
                        )}
                      </>
                    </TableCell>
                  ))}
              {getRowActions ? (
                <TableCell
                  key="actions"
                  className={classNames(classes.actionsColumn, { [classes.selectedRow]: getSelected(data) })}
                >
                  {getRowActions(data) && !!getRowActions(data).length && !isSelectedItems && (
                    <ThreeDotsMenu data={data} actions={getRowActions(data)} />
                  )}
                </TableCell>
              ) : null}
            </Row>
          ))}
          {draggable && <CustomDragLayer component={dragPreviewComponent} />}
        </TableBody>
        {footer && <TableFooter>{footer}</TableFooter>}
      </Table>
    </TableContainer>
  )
}

export default XTable
