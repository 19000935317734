export const GET_USER_EVENTS_START = 'GET_USER_EVENTS_START'
export const GET_USER_EVENTS_END = 'GET_USER_EVENTS_END'
export const GET_USER_EVENT_START = 'GET_USER_EVENT_START'
export const GET_USER_EVENT_END = 'GET_USER_EVENT_END'
export const RESET_EVENTS_UPLOAD_OFFSET = 'RESET_EVENTS_UPLOAD_OFFSET'
export const INCREASE_EVENTS_UPLOAD_OFFSET = 'INCREASE_EVENTS_UPLOAD_OFFSET'
export const SET_EVENTS_CURRENT_TIMESTAMP = 'SET_EVENTS_CURRENT_TIMESTAMP'
export const GET_CLIENT_EVENTS_START = 'GET_CLIENT_EVENTS_START'
export const GET_CLIENT_EVENTS_END = 'GET_CLIENT_EVENTS_END'
export const CANCEL_USER_MEETING_START = 'CANCEL_USER_MEETING_START'
export const CANCEL_USER_MEETING_END = 'CANCEL_USER_MEETING_END'
export const RESCHEDULE_USER_MEETING_START = 'RESCHEDULE_USER_MEETING_START'
export const RESCHEDULE_USER_MEETING_END = 'RESCHEDULE_USER_MEETING_END'
export const RESET_EVENTS_LIST = 'RESET_EVENTS_LIST'
