import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Autocomplete, Checkbox, Hint, Icon, Link } from 'components'
import { CONSTANTS } from 'constants/constants'
import classNames from 'classnames/bind'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginBottom: 30,
  },
  checkboxContainer: {
    width: 200,
  },
  checkbox: {
    color: colors.BASIC.COLOR[50],
  },
  deleteIcon: {
    position: 'absolute',
    right: '-30px',
    top: 8,
    fontSize: 23,
    cursor: 'pointer',
    color: '#575757',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  row: {
    marginBottom: 12,
    width: 360,
  },
  timeRow: {
    position: 'relative',
    marginBottom: 4,
    display: 'flex',
  },
  select: {
    width: 180,
  },
  startTimeSelect: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  endTimeSelect: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  link: {
    cursor: 'pointer',
    color: colors.BASIC.COLOR[100],
    textDecoration: 'underline',
  },
  hint: {
    color: colors.ERROR,
  },
  error: {
    borderColor: colors.ERROR,
  },
})

const getTimeIntervals = () => {
  const [firstOption, ...restTimes] = CONSTANTS.TIME_SELECTIONS
  return {
    timeFrom: CONSTANTS.TIME_SELECTIONS,
    timeTo: [...restTimes, firstOption],
  }
}

const WorkingHoursRow = props => {
  const classes = useStyles()
  const { dayData, onDayChange } = props
  const isDeleteEnabled = dayData.workingHours.length > 1 && dayData.isAvailable
  const { timeFrom, timeTo } = getTimeIntervals()

  const onActiveDayChange = (e, v) => {
    const newData = {
      ...dayData,
      isAvailable: v,
      workingHours: [
        {
          fromHour: '09:00 AM',
          toHour: '05:00 PM',
          error: '',
        },
      ],
    }
    onDayChange(newData)
  }

  const onTimeChanged = (index, property, value) => {
    const newWorkingHours = dayData.workingHours.map((item, i) =>
      i === index
        ? {
            ...item,
            [property]: value,
          }
        : item
    )
    const newData = {
      ...dayData,
      workingHours: newWorkingHours,
    }
    onDayChange(newData)
  }

  const onAddInterval = () => {
    const newData = {
      ...dayData,
      workingHours: [
        ...dayData.workingHours,
        {
          fromHour: '09:00 AM',
          toHour: '05:00 PM',
          error: '',
        },
      ],
    }
    onDayChange(newData)
  }

  const onDeleteHours = index => {
    const newWorkingHours = dayData.workingHours.filter((item, i) => i !== index)
    const newData = {
      ...dayData,
      workingHours: newWorkingHours,
    }
    onDayChange(newData)
  }

  return (
    <div className={classes.root}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          checked={dayData.isAvailable}
          onChange={onActiveDayChange}
          name="isAvailable"
          label={CONSTANTS.DAYS[dayData.weekday]}
          classes={{ root: classes.checkbox }}
        />
      </div>
      <div className={classes.timeColumn}>
        <div>
          {dayData.workingHours.map((hours, i) => (
            <div key={i} className={classes.row}>
              <div className={classes.timeRow}>
                <Autocomplete
                  variant="outlined"
                  options={timeFrom}
                  value={hours.fromHour}
                  disabled={!dayData.isAvailable}
                  onChange={(e, v) => onTimeChanged(i, 'fromHour', v ? v.value : null)}
                  InputProps={{
                    classes: {
                      root: classNames(classes.select, classes.startTimeSelect),
                      notchedOutline: hours.error ? classes.error : null,
                    },
                  }}
                />
                <Autocomplete
                  variant="outlined"
                  options={timeTo}
                  value={hours.toHour}
                  disabled={!dayData.isAvailable}
                  onChange={(e, v) => onTimeChanged(i, 'toHour', v ? v.value : null)}
                  InputProps={{
                    classes: {
                      root: classNames(classes.select, classes.endTimeSelect),
                      notchedOutline: hours.error ? classes.error : null,
                    },
                  }}
                />
                {isDeleteEnabled && (
                  <Icon style={classes.deleteIcon} iconClass="las la-trash-alt" onClick={() => onDeleteHours(i)} />
                )}
              </div>
              {hours.error && (
                <Hint classes={{ subtitle2: classes.hint }}>
                  <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                  {hours.error}
                </Hint>
              )}
            </div>
          ))}
        </div>
        {dayData.isAvailable && dayData.workingHours.length < 5 && (
          <Link className={classes.link} onClick={() => onAddInterval()}>
            + add interval
          </Link>
        )}
      </div>
    </div>
  )
}

export default WorkingHoursRow
