import React from 'react'
import { Button } from '@material-ui/core'
import { ButtonPrimaryProgress } from 'components'

export const BUTTON_TYPES = {
  primary: {
    variant: 'contained',
    color: 'primary',
  },
  secondary: {
    variant: 'outlined',
    color: 'secondary',
  },
  text: {
    variant: 'text',
    color: 'default',
  },
  nav: {
    variant: 'outlined',
    color: 'default',
  },
  submit: {
    variant: 'outlined',
    color: 'secondary',
  },
  label: {
    variant: 'label',
    color: 'default',
  },
}

const DEFAULT_SIZE = 'medium'
const DEFAULT_TYPE = 'primary'

const XButton = ({
  variant = DEFAULT_TYPE,
  size = DEFAULT_SIZE,
  type = 'button',
  id,
  onClick,
  classes,
  children,
  endIcon,
  startIcon,
  disabled,
  isButtonBlocked,
  circularSize,
  circularColor,
  ...props
}) => {
  return (
    <Button
      id={id}
      variant={BUTTON_TYPES[variant].variant}
      color={BUTTON_TYPES[variant].color}
      size={size}
      onClick={onClick}
      classes={classes}
      endIcon={endIcon}
      startIcon={startIcon}
      type={type}
      disabled={disabled}
      {...props}
    >
      {(isButtonBlocked && <ButtonPrimaryProgress circularSize={circularSize} circularColor={circularColor} />) ||
        children}
    </Button>
  )
}

export default XButton
