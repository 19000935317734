import React from 'react'
import { FormikInput } from 'components'
import { useStyles } from '../styles'

const TimeFormBody = ({ values }) => {
  const classes = useStyles()
  const { editMode, case: caseItem } = values

  // will be used in future
  // const getSwitcher = () => {
  //   return (
  //     <>
  //       <div className={classNames(classes.switchRow, { [classes.switchBillable]: values.case.fee_type !== 3 })}>
  //         <Switch
  //           color="default"
  //           className={classes.switch}
  //           formControlClasses={{ root: values.case.fee_type !== 3 && classes.formControlFlatClasses }}
  //           labelClasses={{ root: classes.checkboxLabel }}
  //           disabled={values.case.fee_type !== 3 || !values.editMode}
  //           label="Billable"
  //           checked={values.billable}
  //           onChange={e => setFieldValue('billable', e.target.checked)}
  //         />
  //         <Tooltip
  //           title={<BillableTooltipContent billable={values.billable} tooltipText={classes.tooltipText} />}
  //           tooltipClass={classes.tooltip}
  //           interactive
  //         >
  //           <Icon style={classes.tooltipIcon} iconClass="las la-question-circle" />
  //         </Tooltip>
  //       </div>
  //       {values.billable && values.case.fee_type === 3 && (
  //         <Switch
  //           color="default"
  //           labelClasses={{ root: classes.checkboxLabel }}
  //           label="Charge"
  //           checked={values.charge}
  //           disabled={!values.editMode}
  //           onChange={e => setFieldValue('charge', e.target.checked)}
  //         />
  //       )}
  //     </>
  //   )
  // }
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.timeContainer}>
          <FormikInput
            variant="outlined"
            size="small"
            name="hours"
            label="Hours"
            placeholder="00"
            type="text"
            fullWidth
            disabled={!editMode}
            containerClasses={classes.timeInputContainer}
            inputClasses={classes.hoursInputContainer}
            hintClasses={classes.hintClasses}
          />
          <FormikInput
            variant="outlined"
            size="small"
            name="minutes"
            label="Minutes"
            placeholder="00"
            type="text"
            fullWidth
            disabled={!editMode}
            containerClasses={classes.timeInputContainer}
            inputClasses={classes.minutesInputContainer}
            hintClasses={classes.hintClasses}
          />
        </div>
        <FormikInput
          variant="outlined"
          size="small"
          name="rate"
          label="Rate, $"
          placeholder="0"
          type="text"
          fullWidth
          disabled={!editMode}
          containerClasses={caseItem.fee_type !== 3 ? classes.flatInputContainer : classes.inputContainer}
        />
        {/*will be used in future*/}
        {/*{values.case.fee_type !== 3 && getSwitcher()}*/}
      </div>
      {/*will be used in future*/}
      {/*{values.case.fee_type === 3 && <div className={classes.switchRow}>{getSwitcher()}</div>}*/}
    </div>
  )
}

export default TimeFormBody
