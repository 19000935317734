import { TITLES } from './constants'

export const notificationMapper = data => {
  const notifications = {}
  data.forEach(el => {
    notifications[el.event] = {
      title: TITLES[el.event],
      items: notifications[el.event]?.items ? [...notifications[el.event].items, el] : [el],
    }
  })
  return Object.values(notifications)
}
