import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { VideoPlayer } from 'components'
import { colors } from 'constants/index'
import classNames from 'classnames'


const useStyles = makeStyles({
  videoContainer: {
    marginTop: 40,
  },
  '@media(max-width:767px)': {
    videoContainer: {
      marginTop: 16,
      paddingBottom: 23,
    },
    border: {
      borderBottom: `solid 1px ${colors.BASIC.COLOR[5]}`,
    },
  },
})
const VideoProAccount = ({ video, border = true }) => {
  const classes = useStyles()
  return (
    <div className={classNames({ [classes.videoContainer]: true, [classes.border]: border })}>
      <VideoPlayer
        url={video}
        controls
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
      />
    </div>
  )
}

export default VideoProAccount
