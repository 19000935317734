export const getInvoicesParameters = invoiceState => {
  return {
    search: invoiceState.filters.search || null,
    case__client: invoiceState.filters.client || null,
    case__pro: invoiceState.filters.pro || null,
    date_after: invoiceState.filters.issuedRange.from,
    date_before: invoiceState.filters.issuedRange.to,
    status: invoiceState.filters.invoiceStatus,
    case: invoiceState.filters.case || null,
    limit: invoiceState.tableConfiguration.limit,
    offset: invoiceState.tableConfiguration.offset,
    ordering: invoiceState.sort.property ? `${invoiceState.sort.isAsc ? '' : '-'}${invoiceState.sort.property}` : null,
  }
}

export const getInvoicesFileParameters = invoiceState => {
  return {
    search: invoiceState.filters.search || null,
    case__client: invoiceState.filters.client || null,
    case__pro: invoiceState.filters.pro || null,
    date_after: invoiceState.filters.issuedRange.from,
    date_before: invoiceState.filters.issuedRange.to,
    status: invoiceState.filters.invoiceStatus,
    case: invoiceState.filters.case || null,
    ordering: invoiceState.sort.property ? `${invoiceState.sort.isAsc ? '' : '-'}${invoiceState.sort.property}` : null,
  }
}

export const getInvoiceActivityDescription = description => {
  if (!description) return '-'
  if (description.length < 55) return description
  const cutOfString = description.slice(0, 55) + '...'
  return cutOfString.includes(' ') ? description : cutOfString
}
