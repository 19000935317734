import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  videoText: {
    color: colors.WHITE,
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 'bold',
  },
  videoTextSubtitle: {
    fontSize: 18,
    margin: '12px 0 82px',
    fontWeight: 'normal',
    color: colors.WHITE,
  },
  videoTextButton: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY.COLOR[50],
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100%',
    zIndex: '-100',
    transform: 'translateX(-50%) translateY(-50%)',
    backgroundSize: 'cover',
    transition: '1s opacity',
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderLeft: `36px solid ${colors.WHITE}`,
    marginLeft: 10,
  },
  container: {
    height: 550,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    padding: '90px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(238,95,16,0.5)',
  },
})

const BlockWithVideoBackground = props => {
  const classes = useStyles()
  const { videoId, videoSource, title, subtitle, containerClass, onPressPlay } = props

  return (
    <section className={classNames({ [classes.container]: true, [containerClass]: containerClass })}>
      <h1 className={classes.videoText}>{title}</h1>
      <div className={classes.videoTextSubtitle}>{subtitle}</div>
      <div className={classes.videoTextButton} onClick={() => onPressPlay(videoId)}>
        <div className={classes.triangle} />
      </div>
      <video className={classes.video} loop autoPlay muted playsInline>
        <source src={videoSource} />
        Your browser does not support the video tag.
      </video>
    </section>
  )
}

export default BlockWithVideoBackground
