import React from 'react'
import Chip from '@material-ui/core/Chip'

const XChip = ({ label, icon, onClick, variant, className, onDelete, onMouseDown, deleteIcon, classes }) => {
  return (
    <Chip
      onMouseDown={onMouseDown}
      deleteIcon={deleteIcon}
      icon={icon}
      label={label}
      onClick={onClick}
      variant={variant}
      className={className}
      classes={classes}
      onDelete={onDelete}
    />
  )
}

export default XChip
