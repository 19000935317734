import React, { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS, routes } from 'constants/index'
import { Button, MainProAccount, PracticeAreasShortView } from 'components'
import {
  navigateToSearchProAccount,
  navigateToMeetingSchedule,
  navigateToSearchProUploadedAccount,
  navigateTo,
} from 'store/actions/navigation'
import { setMeetingTime, toggleMeetingModal } from 'store/actions/meetingScheduler'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLogin, setRedirectPath } from 'store/actions/common'
import { ReactComponent as Communication } from 'static/profile/communication.svg'

const useStyles = makeStyles({
  listItem: {
    position: 'relative',
    backgroundColor: colors.BASIC.COLOR[0],
    width: 282,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 24px 24px 0',
    justifyContent: 'space-between',
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    '&:hover': {
      boxShadow: `0 2px 12px 0 ${colors.BASIC.COLOR[20]}`,
      backgroundColor: colors.WHITE,
      '& .checkmark rect': {
        fill: colors.WHITE,
      },
    },
  },
  link: {
    padding: '16px 24px',
    textDecoration: 'none',
    textAlign: 'right',
  },
  btnsContainer: {
    position: 'absolute',
    bottom: 58,
    left: 24,
    right: 24,
  },
  ratingContainerClasses: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.17,
  },
  meetButton: {
    width: '100%',
    margin: '12px 0',
    padding: 10,
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  meetButtonLink: {
    textDecoration: 'none',
  },
  btnMore: {
    display: 'none',
    padding: 10,
  },
  '@media (min-width: 1124px) and (max-width: 1280px)': {
    listItem: {
      width: 'calc(100%/3 - 25px)',
    },
  },
  '@media (min-width: 940px) and (max-width: 1123px)': {
    listItem: {
      width: 'calc(100%/2 - 25px)',
    },
  },
  '@media (min-width: 768px) and (max-width: 939px)': {
    listItem: {
      width: 'calc(100% / 2 - 25px)',
      height: 207,
      padding: '16px 13px 16px 12px',
    },
    link: {
      padding: '0 0',
    },
    btnsContainer: {
      bottom: 0,
      left: 12,
      right: 12,
    },
    meetButton: {
      position: 'relative',
      width: '70%',
      marginTop: '12px',
    },
    btns: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    btn: {
      display: 'none',
    },
    btnMore: {
      display: 'flex',
      width: '20%',
      alignSelf: 'center',
    },
  },
  '@media (max-width: 767px)': {
    listItem: {
      width: 'calc(100% / 2 - 25px)',
      marginRight: 12,
    },
    btnsContainer: {
      bottom: 10,
    },
    meetButton: {
      position: 'relative',
      width: '73%',
      marginTop: '12px',
    },
    btns: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btn: {
      display: 'none',
    },
    btnMore: {
      display: 'flex',
      width: '8%',
      alignSelf: 'center',
    },
  },
  '@media (max-width: 691px)': {
    listItem: {
      width: 'calc(100% - 20px)',
      padding: '22px 16px',
      marginRight: 12,
    },
    meetButton: {
      position: 'relative',
      width: '75%',
      marginTop: '12px',
      fontSize: 14,
    },
    btns: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    btn: {
      display: 'none',
    },
    btnMore: {
      display: 'flex',
      width: '20%',
      alignSelf: 'center',
    },
  },
  '@media (max-width: 360px)': {
    listItem: {
      width: 'calc(100% - 15px)',
      padding: '18px 12px',
    },
    btnsContainer: {
      left: 12,
      right: 12,
    },
  },
})

const ProfileShortView = ({ userData }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  const practiceAreaValue = useSelector(state => state.search.practiceAreaValue)
  const practiceAreaParam = useSelector(state => state.search.practiceAreaParam)
  const countryStateValue = useSelector(state => state.search.countryStateValue)
  const currentUser = useSelector(state => state.auth.currentUser)
  const contactInfo = useSelector(state => state.profileCreation.profileData.contactInfo)
  const {
    practice_areas,
    rating,
    first_name,
    last_name,
    photo,
    reviews,
    free_time,
    uuid,
    is_ready_for_followup,
    is_recommended,
    is_uploaded,
    active_calendar,
    email,
  } = userData

  const onShortViewClick = () => {
    if (is_uploaded) dispatch(navigateToSearchProUploadedAccount(uuid))
    else dispatch(navigateToSearchProAccount(uuid))
  }

  const openMeetingModal = meetingEnd => {
    dispatch(setMeetingTime({ start: free_time, end: meetingEnd }))
    dispatch(toggleMeetingModal(true))
    dispatch(navigateToSearchProAccount(uuid))
  }

  const onShortViewMeetingClick = () => {
    const meetingEnd = moment
      .unix(free_time)
      .add(30, 'm')
      .unix()
    // if (!practiceAreaValue || !practiceAreaParam) {
    //   dispatch(openSnackbar('error', 'Please specify practice area value'))
    //   return
    // }
    if (is_ready_for_followup) {
      openMeetingModal(meetingEnd)
    } else {
      if (isEmpty(currentUser)) {
        dispatch(
          setRedirectPath(`/client/schedule/${uuid}?start=${free_time}&end=${meetingEnd}&area=${practiceAreaParam}`)
        )
        dispatch(toggleLogin(true))
      } else {
        if (
          contactInfo.street &&
          contactInfo.city &&
          contactInfo.state &&
          contactInfo.zip &&
          contactInfo.phone &&
          practice_areas &&
          practice_areas[0] &&
          practice_areas[0].consultation_cost === 0
        ) {
          openMeetingModal(meetingEnd)
        } else {
          dispatch(navigateToMeetingSchedule(uuid, free_time, meetingEnd, practiceAreaParam))
        }
      }
    }
  }

  const onContactPro = () => {
    if (isUserLoggedIn) {
      window.open(`mailto:${email}?subject=${encodeURIComponent('CONFIDENTIAL CLIENT INQUIRY FROM XIRA.COM')}`, '_self')
    } else {
      dispatch(navigateTo('/sign-up?role=client'))
    }
  }

  const disableButton = useMemo(() => is_uploaded || !free_time || !active_calendar, [
    is_uploaded,
    free_time,
    active_calendar,
  ])

  return (
    <div className={classes.listItem}>
      <RouterLink
        to={{
          pathname: `${routes.SEARCH_PAGE}/${uuid}${is_uploaded ? '/uploaded' : ''}`,
          search: `?practice_area=${practiceAreaValue}&state=${countryStateValue}`,
        }}
        className={classes.link}
      >
        <MainProAccount
          type="short_view"
          rating={rating}
          reviews={reviews}
          firstName={first_name}
          lastName={last_name}
          photo={photo}
          checkmark={is_recommended}
          ratingContainerClasses={classes.ratingContainerClasses}
          isUploaded={is_uploaded}
        />
        {practice_areas && practice_areas[0] && !is_uploaded && (
          <PracticeAreasShortView practiceAreas={practice_areas} />
        )}
        {is_uploaded && <PracticeAreasShortView practiceAreas={practice_areas} isUploaded={is_uploaded} />}
        <Button className={classes.btn} variant="text">
          see bio & more times
        </Button>
      </RouterLink>
      <div className={classes.btnsContainer}>
        <div className={classes.btns}>
          {email ? (
            <Button
              variant="secondary"
              className={classes.meetButton}
              startIcon={<Communication />}
              onClick={onContactPro}
            >
              CONTACT
            </Button>
          ) : (
            <Button
              variant="secondary"
              className={classes.meetButton}
              onClick={onShortViewMeetingClick}
              disabled={disableButton}
            >
              {!active_calendar
                ? 'No online calendar available'
                : disableButton
                ? 'No available time slots'
                : `Meet at ${moment.unix(free_time).format('h:mm a')} ${moment
                    .unix(free_time)
                    .format(CONSTANTS.DATE_FORMAT.FORMAT_SHORT)}`}
            </Button>
          )}
          <Button className={classes.btnMore} variant="secondary" onClick={onShortViewClick}>
            more
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfileShortView
