import axios from 'axios'
import { createNewUserPath } from 'utils/path-helpers/api'

export const isEmailInvalid = async email => {
  const body = { username: email }
  const response = await axios.post(createNewUserPath(), body)
  if (response.status === 400) return response.username

  return false
}

export const openSocialLoginWindow = (provider, handler, errorHandler) => {
  const windowFeatures = 'menubar=no,location=yes,resizable=no,scrollbars=no,status=no,width=580px,height=400px'

  const providers = {
    fbMedia: {
      loginUrl: `https://www.facebook.com/v5.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP}&redirect_uri=${window.location.origin}/social-login&state={st=state123abc,ds=123456789}&response_type=code&scope=user_link`,
      windowName: `facebook-media-social-login`,
    },
    outlook: {
      loginUrl: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=1d446853-22f2-4546-80af-f7f85c34af4a&response_type=code&prompt=consent&redirect_uri=${window.location.origin}/social-login&scope=User.Read Calendars.ReadWrite openid offline_access&response_mode=query`,
      windowName: `outlook-social-login`,
    },
  }

  const popupWindow = window.open(providers[provider].loginUrl, providers[provider].windowName, windowFeatures)
  const timer = setInterval(() => {
    if (popupWindow.closed) {
      clearInterval(timer)
      if (popupWindow.document) {
        const socialData = popupWindow.document.socialLoginData || { error: 'Login failed' }
        handler(socialData)
      } else {
        if (errorHandler) errorHandler()
      }
    }
  }, 300)
}

export const setRefreshTokenLocally = refreshToken => {
  if (!refreshToken) return
  window.localStorage.refreshToken = refreshToken
}

export const removeRefreshTokenLocally = () => {
  window.localStorage.removeItem('refreshToken')
}

export const getRefreshTokenLocally = () => (window.localStorage.refreshToken ? window.localStorage.refreshToken : null)

export const setJWTLocally = JWT => {
  if (!JWT) return
  window.localStorage.JWT = JWT
}

export const removeJWTLocally = () => {
  window.localStorage.removeItem('JWT')
}

export const getJWTLocally = () => (window.localStorage.JWT ? window.localStorage.JWT : null)
