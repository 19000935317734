import React from "react"
import { IconButton, Tooltip } from 'components'
import { colors } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  connectionRowBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconBtn: {
    width: 32,
    height: 32,
    borderRadius: 4,
    border: `solid 1px ${colors.PRIMARY.COLOR[10]}`,
    padding: 6,
    backgroundColor: colors.PRIMARY.COLOR[0],
    '&:disabled': {
      backgroundColor: colors.BASIC.COLOR[5],
      border: 0
    },
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[5],
    },
  },
})

const ActionButton = ({children, onClick, disabled}) => {
  const classes = useStyles()
  return <IconButton classes={{ root: classes.iconBtn }} onClick={onClick} disabled={disabled}>
          {children}
        </IconButton>


}

export default ActionButton