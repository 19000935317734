import React from 'react'
import { Table, TableEmptyState, TablePagination } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getWalletItems, setTableConfiguration } from 'store/actions/wallet/wallet'
import AvailableWalletTableRow from './AvailableWalletTableRow'

const useStyles = makeStyles({
  table: {},
  tableHeaderCell: {},
})

const columns = [
  {
    value: 'posted_date',
    label: 'Posted',
  },
  {
    value: 'title',
    label: 'Item',
  },
  {
    value: 'revenue',
    label: 'Revenue',
    numeric: true,
  },
  {
    value: 'xira_charge',
    label: 'XIRA charge',
    numeric: true,
  },
  {
    value: 'transaction_charge',
    label: 'Transaction charge',
    numeric: true,
  },
  {
    value: 'net_revenue',
    label: 'Net revenue',
    numeric: true,
  },
  {
    value: 'balance',
    label: 'Balance',
    numeric: true,
  },
]

const AvailableWalletTable = ({ setSelectedInvoiceId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const walletItems = useSelector(state => state.wallet.walletItems)
  const count = useSelector(state => state.wallet.walletItemsLength)
  const { limit, tablePage } = useSelector(state => state.wallet.tableConfiguration)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  const onRowsPerPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
        limit: value,
      })
    )
    dispatch(getWalletItems())
  }

  const onPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: value,
        offset: value * limit,
      })
    )
    dispatch(getWalletItems())
  }

  return !count ? (
    <TableEmptyState />
  ) : (
    <Table
      tableColumns={columns}
      className={classes.table}
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableData={walletItems}
      tableDataRowRenderer={(columns, data) => (
        <AvailableWalletTableRow
          columns={columns}
          data={data}
          setSelectedInvoiceId={setSelectedInvoiceId}
          timezone={timezone}
        />
      )}
      activeRows
      footer={
        <TablePagination
          page={tablePage}
          colSpan={columns.length}
          count={count}
          rowsPerPage={limit}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          rowsPerPageOptions={[50, 100, 200]}
        />
      }
    />
  )
}

export default AvailableWalletTable
