import React from 'react'
import { Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  AccountProfileGeneralInformationContainer,
  AccountProfileSocialContainer,
  CalendarAdvanced,
  CalendarConnections,
  CredentialsContainer,
  PracticeAreasContainer,
  ProPaymentContainer,
  WorkingHoursContainer,
  VaultSettingsContainer,
  NotificationSettings,
} from 'containers'
import Bio from './bio/bio'

const useStyles = makeStyles({
  profileContent: {
    position: 'relative',
    padding: '32px 70px',
    minHeight: '100%',
    backgroundColor: '#ffffff',
  },
})

const ProfileContent = ({ executeScroll }) => {
  const classes = useStyles()
  return (
    <div className={classes.profileContent}>
      <Route
        path="/pro/account/profile/general"
        component={() => <AccountProfileGeneralInformationContainer executeScroll={executeScroll} />}
      />
      <Route
        path="/pro/account/profile/areas"
        component={() => <PracticeAreasContainer executeScroll={executeScroll} />}
      />
      <Route path="/pro/account/profile/credentials" component={CredentialsContainer} />
      <Route path="/pro/account/profile/bio" component={Bio} />
      <Route path="/pro/account/profile/social" component={AccountProfileSocialContainer} />
      <Route path="/pro/account/settings/connections" component={CalendarConnections} />
      <Route path="/pro/account/settings/notifications" component={NotificationSettings} />
      <Route path="/pro/account/settings/vault-settings" component={VaultSettingsContainer} />
      <Route
        path="/pro/account/settings/working-hours"
        render={() => <WorkingHoursContainer executeScroll={executeScroll} />}
      />
      <Route path="/pro/account/settings/timezones" component={CalendarAdvanced} />
      <Route path="/pro/account/settings/payment" component={ProPaymentContainer} />
    </div>
  )
}

export default ProfileContent
