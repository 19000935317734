import { createSelector } from 'reselect'
import { CONSTANTS } from 'constants/index'
import { memoize } from 'lodash'

export const getFilterCases = state => state.cases.filterCases

export const selectCasesOptions = createSelector([getFilterCases], cases =>
  cases.map(caseItem => ({ ...caseItem, label: caseItem.name, value: caseItem.uuid }))
)

export const selectCasesOptionsWithEmptyState = createSelector([getFilterCases], cases => [
  {
    value: null,
    label: 'All',
  },
  ...cases.map(caseItem => ({ label: caseItem.name, value: caseItem.uuid })),
])

export const selectCasesForTimeCreation = createSelector(selectCasesOptions, cases =>
  cases.filter(caseItem => caseItem.fee_type === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.T_E && caseItem.is_active)
)

export const selectTimeCreationAbility = createSelector(selectCasesForTimeCreation, cases => !!cases.length)

export const selectTimeCasesByClient = createSelector(selectCasesForTimeCreation, cases =>
  memoize(clientId => cases.filter(caseItem => caseItem.client === clientId))
)

export const selectCasesForExpenseCreation = createSelector(selectCasesOptions, cases =>
  cases.filter(
    caseItem =>
      (caseItem.fee_type === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.T_E ||
        ((caseItem.fee_type === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.FLAT_FEE ||
          caseItem.fee_type === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.CONTINGENCY) &&
          caseItem.expense_invoicing)) &&
      caseItem.is_active
  )
)

export const selectExpenseCasesByClient = createSelector(selectCasesForExpenseCreation, cases =>
  memoize(clientId => cases.filter(caseItem => caseItem.client === clientId))
)

export const selectExpenseCreationAbility = createSelector(selectCasesForExpenseCreation, cases => {
  return !!cases.length
})
