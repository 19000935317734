import React from 'react'
import Timer from 'react-compound-timer'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'


const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  column: {
    textAlign: 'center',
    width: 40,
  },
  time: {
    fontSize: 28,
    color: colors.BASIC.COLOR[80],
    fontFamily: "'Roboto Mono', monospace",
  },
  subTime: {
    fontSize: 9,
  },
  '@media(max-width:939px)': {
    time: {
      fontSize: 22,
    },
    subTime: {
      fontSize: 7,
    },
  },
})

const XTimer = props => {
  const { countdownValue } = props
  const classes = useStyles()

  return (
    <Timer initialTime={countdownValue} direction="backward">
      {() => (
        <div className={classes.container}>
          <div className={classes.column}>
            <Typography variant="h3" className={classes.time}>
              <Timer.Days />
            </Typography>
            <Typography variant="body2" className={classes.subTime}>
              days
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={classes.time}>
              :
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography variant="h3" className={classes.time}>
              <Timer.Hours />
            </Typography>
            <Typography variant="body2" className={classes.subTime}>
              hours
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={classes.time}>
              :
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography variant="h3" className={classes.time}>
              <Timer.Minutes />
            </Typography>
            <Typography variant="body2" className={classes.subTime}>
              minutes
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={classes.time}>
              :
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography variant="h3" className={classes.time}>
              <Timer.Seconds />
            </Typography>
            <Typography variant="body2" className={classes.subTime}>
              seconds
            </Typography>
          </div>
        </div>
      )}
    </Timer>
  )
}

export default XTimer
