import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/index'
import { Table, TablePagination } from 'components'
import { setInvoicesSorting, setTableConfiguration, getProInvoices } from 'store/actions/invoices'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

const useStyles = makeStyles({
  invoiceTable: {
    marginTop: 24,
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableHeaderCell: {
    borderBottom: `2px solid ${colors.BASIC.COLOR[10]}`,
    padding: '8px 12px',
  },
})

const CommonInvoicesTable = ({ invoices, getRowActions, columns, tableDataRowRenderer, className }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const sorting = useSelector(state => state.invoices.sort)
  const { limit, tablePage } = useSelector(state => state.invoices.tableConfiguration)
  const count = useSelector(state => state.invoices.invoicesLength)

  const onSort = (name, isAcs) => {
    dispatch(setInvoicesSorting(name, isAcs))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProInvoices())
  }

  const onRowsPerPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
        limit: value,
      })
    )
    dispatch(getProInvoices())
  }

  const onPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: value,
        offset: value * limit,
      })
    )
    dispatch(getProInvoices())
  }

  return (
    <Table
      tableColumns={columns}
      className={classNames(classes.invoiceTable, className)}
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableData={invoices}
      getRowActions={getRowActions}
      sorting={sorting}
      onSort={onSort}
      tableDataRowRenderer={tableDataRowRenderer}
      footer={
        <TablePagination
          colSpan={columns.length}
          page={tablePage}
          count={count}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
        />
      }
      activeRows
    />
  )
}

export default CommonInvoicesTable
