import React from 'react'
import { InputLabel, TextField, Hint, Icon, Autocomplete } from 'components'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  error: {
    marginTop: 5,
    color: colors.ERROR,
  },
  hint: {
    marginTop: 5,
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
  errorInput: {
    borderColor: colors.ERROR,
  },
})

const FormAutocomplete = props => {
  const classes = useStyles()
  const {
    onChange,
    label,
    disabled,
    value,
    inputClass,
    fullWidth,
    containerClass,
    name,
    InputProps,
    error,
    options = [],
    labelAccessor = 'label',
    valueAccessor = 'value',
    placeholder,
    hint,
  } = props

  const values = options.map(item => ({
    value: item[valueAccessor],
    label: item[labelAccessor],
  }))

  const handleChange = (e, v) => {
    onChange(name, v ? v.value : null)
  }

  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        disabled={disabled}
        value={value}
        variant="outlined"
        fullWidth={fullWidth}
        className={inputClass}
        onChange={handleChange}
        name={name}
        options={values}
        placeholder={placeholder}
        InputProps={{
          classes: {
            notchedOutline: error ? classes.errorInput : null,
          },
          ...InputProps
        }}
      />
      {hint && <Hint classes={{ root: classes.hint }}>{hint}</Hint>}
      {error && (
        <Hint classes={{ root: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {error}
        </Hint>
      )}
    </div>
  )
}

export default FormAutocomplete
