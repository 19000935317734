import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import moment from 'moment-timezone'
import { ReactComponent as TrashFileIcon } from 'static/vault/trash/trash_file.svg'
import { ReactComponent as TrashFolderIcon } from 'static/vault/trash/trash_folder.svg'
import { Link, Checkbox } from 'components'
import { CONSTANTS } from 'constants/index'
import classNames from 'classnames'
import { useStyles } from '../rows/rowStyles'

const VaultTrashTableRow = props => {
  const { data, timezone, selectedRows, disableSelection } = props
  const classes = useStyles()
  const isItemSelected = !!selectedRows.find(row => row.uuid === data.uuid)

  return (
    <>
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} disabled={disableSelection} />
      </TableCell>
      <TableCell className={classNames({ [classes.selectedRow]: isItemSelected })}>
        <div className={classes.cell}>
          {data.isFolder ? (
            <TrashFolderIcon className={classes.mainFolderIcon} />
          ) : (
            <TrashFileIcon className={classes.mainFolderIcon} />
          )}
          <Link className={classes.link}>{data.name}</Link>
        </div>
      </TableCell>
      <TableCell className={classNames({ [classes.selectedRow]: isItemSelected })}>
        {data.deletedAt
          ? moment(data.deletedAt)
              .tz(timezone)
              .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)
          : '-'}
      </TableCell>
    </>
  )
}
export default VaultTrashTableRow
