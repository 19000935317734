import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link, Typography } from 'components'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS } from 'constants/index'
import moment from 'moment-timezone'
import { formatNegativeNumber } from 'utils/formatters'

const useStyles = makeStyles({
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  highlighted: {
    color: colors.PRIMARY.COLOR[40],
    fontWeight: 600,
  },
  boldText: {
    fontWeight: 600,
  },
})

const PendingFundsTableRow = ({ data, setSelectedInvoiceId, timezone }) => {
  const classes = useStyles()

  return (
    <>
      <TableCell>
        <Typography variant="body2" className={classes.text}>
          {moment
            .utc(data.paid_at)
            .tz(timezone)
            .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link className={classes.link} onClick={() => setSelectedInvoiceId(data.invoice_uuid)}>
          {data.title}
        </Link>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" className={classes.text}>
          {formatNegativeNumber(data.revenue, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" className={classes.text}>
          {formatNegativeNumber(data.xira_charge, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" className={classes.text}>
          {formatNegativeNumber(data.transaction_charge, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" className={classes.highlighted}>
          {formatNegativeNumber(data.pending_funds, '$')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" className={classes.boldText}>
          {moment
            .utc(data.available_date)
            .tz(timezone)
            .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
        </Typography>
      </TableCell>
    </>
  )
}

export default PendingFundsTableRow
