import React from 'react'
import { ButtonGroup } from '@material-ui/core'
import { Button } from 'components'
import { colors } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import XInputLabel from './text/inputLabel'
import Hint from './text/hint'
import XIcon from './Icon'

const { WHITE, ERROR } = colors

const useStyles = makeStyles({
  switchWrapper: {
    width: 368,
    margin: '0 auto 16px auto',
  },
  label: {
    textAlign: 'left',
  },
  buttonRoleOutlined: {
    width: 184,
    height: 40,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: '12px 24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    borderColor: '#d0cfcf',
    color: colors.BASIC.COLOR[70],
    backgroundColor: colors.BASIC.COLOR[0],
  },
  buttonRolePrimary: {
    color: WHITE,
    backgroundColor: colors.PRIMARY.COLOR[50],
    border: 'none',
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  hint: {
    color: ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: '8px',
  },
  '@media(max-width:767px)': {
    switchWrapper: {
      width: '100%',
    },
    buttonRoleOutlined: {
      width: '50%',
    },
    buttonGroupWpapper: {
      width: '100%',
    },
  },
})

const AccountTypeSwitch = ({ changeAuthRole, accountType, showError, disabledPro, disabledClient }) => {
  const classes = useStyles()
  const buttons = [
    { role: 'pro', text: "I'm a lawyer", disabled: disabledPro },
    { role: 'client', text: "I'm a client", disabled: disabledClient },
  ]

  const getButtons = () => {
    return buttons.map(({ role, text, disabled }) => (
      <Button
        key={role}
        disabled={disabled}
        onClick={() => changeAuthRole(role)}
        className={classNames({
          [classes.buttonRolePrimary]: accountType === role,
          [classes.buttonRoleOutlined]: true,
        })}
        variant="secondary"
      >
        {text}
      </Button>
    ))
  }

  return (
    <div className={classes.switchWrapper}>
      <XInputLabel classes={{ root: classes.label }}>Choose account type</XInputLabel>
      <ButtonGroup className={classes.buttonGroupWpapper}>{getButtons()}</ButtonGroup>
      {showError && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <XIcon style={classes.icon} iconClass="las la-exclamation-triangle" />
          please choose your account type
        </Hint>
      )}
    </div>
  )
}

export default AccountTypeSwitch
