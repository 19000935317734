import React from 'react'
import {
  ClientBookingInfo,
  ClientAddressInfo,
  SvgIcon,
  ColorCircularProgress,
  Button,
  DataLossWarning,
} from 'components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { openSnackbar } from 'store/actions/common'
import { updateCurrentUser, updateLocalCurrentUSer } from 'store/actions/auth'
import {
  changeProfileContactInfo,
  saveContactInfo,
  updateProfileContactInfo,
  isChangeProfileDataStart,
  isChangeProfileDataEnd,
} from 'store/actions/profileCreation'
import { withStyles } from '@material-ui/core/styles'
import { pick, keys, isEqual } from 'lodash'
import { CONSTANTS, colors } from 'constants/index'
import { MobileVerificationModal } from 'components/profileCreation/MobileVerificationModal'

const mockCountries = [
  {
    label: 'USA',
    value: 840,
    icon: (
      <SvgIcon>
        <path fill="#F0F0F0" d="M16 2.667H0v10.666h16V2.667z" />
        <path
          fill="#D80027"
          d="M16 4H0v1.333h16V4zM16 6.667H0V8h16V6.667zM16 9.333H0v1.333h16V9.333zM16 12H0v1.333h16V12z"
        />
        <path fill="#2E52B2" d="M8 2.667H0V8.41h8V2.667z" />
        <path
          fill="#F0F0F0"
          d="M3.12 5.02l-.129.396h-.417l.337.245-.128.396.337-.245.337.245-.129-.396.337-.245h-.417L3.12 5.02zM3.248 6.846L3.12 6.45l-.129.396h-.417l.337.245-.128.396.337-.244.337.244-.129-.396.337-.245h-.417zM1.487 6.846l-.129-.396-.129.396H.812l.338.245-.13.396.338-.244.337.244-.129-.396.337-.245h-.416zM1.358 5.02l-.129.396H.812l.338.245-.13.396.338-.245.337.245-.129-.396.337-.245h-.416l-.129-.396zM3.12 3.59l-.129.395h-.417l.337.245-.128.397.337-.245.337.245-.129-.397.337-.245h-.417L3.12 3.59zM1.358 3.59l-.129.395H.812l.338.245-.13.397.338-.245.337.245-.129-.397.337-.245h-.416l-.129-.396zM4.881 5.02l-.128.396h-.417l.337.245-.129.396.338-.245.337.245-.13-.396.338-.245H5.01l-.128-.396zM5.01 6.846l-.128-.396-.13.396h-.416l.337.245-.129.396.338-.244.337.244-.13-.396.338-.245H5.01zM6.77 6.846L6.64 6.45l-.129.396h-.416l.337.245-.129.396.337-.244.337.244-.129-.396.338-.245H6.77zM6.641 5.02l-.129.396h-.416l.337.245-.129.396.337-.245.337.245-.129-.396.338-.245H6.77L6.64 5.02zM4.881 3.59l-.128.395h-.417l.337.245-.129.397.338-.245.337.245-.13-.397.338-.245H5.01l-.128-.396zM6.641 3.59l-.129.395h-.416l.337.245-.129.397.337-.245.337.245-.129-.397.338-.245H6.77L6.64 3.59z"
        />
      </SvgIcon>
    ),
  },
]

const styles = {
  clientProfileForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  clientProfileBtnContainer: {
    display: 'flex',
    marginTop: '40px',
    marginBottom: '10px',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  clientProfileBtnSubmit: {
    width: '140px',
    marginLeft: '20px',
    boxShadow: '0 0 0 0',
  },
  subtitle: {
    marginBottom: 16,
  },
  contacts: {
    padding: 24,
    border: `1px solid ${colors.BASIC.COLOR[5]}`,
    borderRadius: 4,
    backgroundColor: colors.WHITE,
  },
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: colors.BASIC.COLOR[100],
    marginBottom: 8,
  },
  contactInfo: {
    marginTop: '32px',
  },
  inputContainer: {
    width: '47%',
  },
  clientProfileContainer: {
    width: 840,
  },
}

const validationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .required('Please fill in the filed'),
  last_name: Yup.string()
    .trim()
    .required('Please fill in the filed'),
  phone: Yup.string()
    .test('len', 'Please enter 10 digits', val => (val ? val.toString().replace(/([+ -])/g, '').length === 11 : false))
    .required('Please fill in the filed'),
  optionalPhone: Yup.string().test('len', 'Please enter 10 digits', val =>
    val === '+1' || !val ? true : val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
  ),
  email: Yup.string().required('Please fill in the filed'),
  street: Yup.string()
    .trim()
    .required('Please fill in the filed'),
  suite: Yup.string(),
  city: Yup.string()
    .trim()
    .required('Please fill in the filed'),
  state: Yup.string().required('Please fill in the filed'),
  zip: Yup.string()
    .typeError('Please enter valid ZIP code (5 digits)')
    .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
    .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
    .required('Please fill in the field'),
  country: Yup.string().required('Please fill in the filed'),
})

class ClientProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyNumber: '',
    }
  }
  componentWillUnmount() {
    this.resetLocalInfo()
  }

  onClose = () => {
    this.setState({ verifyNumber: '' })
  }

  onSubmit = values => {
    this.onClose()
    const {
      changeContactInfo,
      updateCurrentUser,
      saveProfileContactInfo,
      showSnackbar,
      contactInfo,
      currentUser,
      executeScroll,
      isChangeProfileDataStart,
      isChangeProfileDataEnd,
    } = this.props
    isChangeProfileDataStart()
    Promise.all([
      updateCurrentUser(this.preparePayload(values, currentUser)),
      saveProfileContactInfo(this.preparePayload(values, contactInfo)),
    ])
      .then(([_, resp]) => {
        showSnackbar('success', 'Contact info changed')
        changeContactInfo(this.preparePayload(values, contactInfo))
        if (!resp.is_phone_confirmed) this.setState({ verifyNumber: resp.phone })
      })
      .catch(e => showSnackbar('error', 'There was some error while changing contact info'))
      .finally(() => {
        isChangeProfileDataEnd()
        this.props.executeScroll()
      })
  }

  preparePayload(values, relatedObject) {
    return pick(values, keys(relatedObject))
  }

  resetLocalInfo = () => {
    this.props.updateLocalContactInfo({
      ...this.props.contactInfo,
    })
    this.props.updateNames({
      ...this.props.currentUser,
    })
  }

  onReset = () => {
    this.resetLocalInfo()
    this.props.executeScroll()
  }

  updateContactInfo = event => {
    this.props.updateLocalContactInfo({
      [event.target.name]: event.target.value,
    })
  }

  updateBookingInfo = event => {
    this.props.updateNames({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    const {
      classes,
      states,
      contactInfoIsLoading,
      currentUser,
      localCurrentUser,
      contactInfo,
      localContactInfo,
      isChangeProfileData,
    } = this.props
    const initialValues = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.username,
      phone: contactInfo.phone,
      optionalPhone: contactInfo.optionalPhone,
      street: contactInfo.street,
      suite: contactInfo.suite,
      city: contactInfo.city,
      state: contactInfo.state,
      zip: contactInfo.zip,
      country: contactInfo.country,
    }
    const { is_phone_confirmed: _c, optional_phone, ...contactInfoCompare } = contactInfo
    const { is_phone_confirmed: _lc, ...localContactInfoCompare } = localContactInfo
    return contactInfoIsLoading ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.clientProfileContainer}>
        <DataLossWarning
          whenCondition={
            !isEqual(contactInfoCompare, localContactInfoCompare) || !isEqual(currentUser, localCurrentUser)
          }
          path="/client/account/profile"
        />
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            this.onSubmit(values)
          }}
          onReset={this.onReset}
          enableReinitialize
        >
          {props => (
            <Form className={classes.clientProfileForm}>
              <div>
                <ClientBookingInfo
                  updateBookingInfo={this.updateBookingInfo}
                  formClasses={{
                    subtitle: classes.subtitle,
                    twoColumn: classes.twoColumn,
                    inputContainer: classes.inputContainer,
                    title: classes.title,
                    contacts: classes.contacts,
                  }}
                />
                <ClientAddressInfo
                  states={states}
                  countries={mockCountries}
                  className={classes.contactInfo}
                  updateContactInfo={this.updateContactInfo}
                  formClasses={{
                    subtitle: classes.subtitle,
                    twoColumn: classes.twoColumn,
                    inputContainer: classes.inputContainer,
                    title: classes.title,
                    phoneInput: classes.phoneInput,
                    phoneContainer: classes.phoneContainer,
                  }}
                />
              </div>
              <div className={classes.clientProfileBtnContainer}>
                <Button variant="text" type="reset" disabled={!props.dirty || isChangeProfileData}>
                  cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className={classes.clientProfileBtnSubmit}
                  isButtonBlocked={isChangeProfileData}
                  disabled={!props.dirty || isChangeProfileData}
                  circularSize={22}
                >
                  save changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <MobileVerificationModal
          open={!!this.state.verifyNumber}
          number={this.state.verifyNumber}
          onOk={this.onClose}
          onClose={this.onClose}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ auth, profileCreation }) => ({
  currentUser: auth.currentUser,
  localCurrentUser: auth.localCurrentUser,
  contactInfo: profileCreation.profileData.contactInfo,
  localContactInfo: profileCreation.profileData.localContactInfo,
  states: profileCreation.states,
  contactInfoIsLoading: profileCreation.profileData.contactInfoIsLoading,
  isChangeProfileData: profileCreation.profileData.isChangeProfileData,
})
const mapDispatchToProps = dispatch => ({
  updateCurrentUser: payload => dispatch(updateCurrentUser(payload)),
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
  saveProfileContactInfo: data => dispatch(saveContactInfo(data)),
  changeContactInfo: data => dispatch(changeProfileContactInfo(data)),
  updateLocalContactInfo: data => dispatch(updateProfileContactInfo(data)),
  updateNames: data => dispatch(updateLocalCurrentUSer(data)),
  isChangeProfileDataStart: () => dispatch(isChangeProfileDataStart()),
  isChangeProfileDataEnd: () => dispatch(isChangeProfileDataEnd()),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ClientProfile))
