export const GET_ACTIVITIES_START = 'GET_ACTIVITIES_START'
export const GET_ACTIVITIES_FILE_START = 'GET_ACTIVITIES_FILE_START'
export const GET_ACTIVITIES_END = 'GET_ACTIVITIES_END'
export const GET_ACTIVITIES_FILE_END = 'GET_ACTIVITIES_FILE_END'
export const GET_ACTIVITY_START = 'GET_ACTIVITY_START'
export const GET_ACTIVITY_END = 'GET_ACTIVITY_END'
export const RESET_ACTIVITY = 'RESET_ACTIVITY'
export const SET_OFFSET = 'SET_ACTIVITIES_OFFSET'
export const SET_LIMIT = 'SET_ACTIVITIES_LIMIT'
export const SET_FILTERS = 'SET_ACTIVITIES_FILTERS'
export const RESET_FILTERS = 'RESET_ACTIVITIES_FILTERS'
export const SET_SORTING = 'SET_ACTIVITIES_SORTING'
export const RESET_SORTING = 'RESET_ACTIVITIES_SORTING'
export const SET_PREDEFINED_ACTIVITY = 'SET_PREDEFINED_ACTIVITY'
export const SET_TABLE_CONFIGURATION = 'SET_ACTIVITIES__TABLE_CONFIGURATION'
