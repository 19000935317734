import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
})

const TermsOfServiceLink = () => {
  const classes = useStyles()
  return (
    <span>
      <Link to="/user-agreement" target="_blank" className={classes.link}>
        User agreement
      </Link>
      <span>{' and '}</span>
      <Link to="/privacy-policy" target="_blank" className={classes.link}>
        Privacy Policy
      </Link>
    </span>
  )
}

export default TermsOfServiceLink
