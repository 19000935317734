import React from 'react'
import { ExpenseInvoicingSelection } from 'components'

const ContingencySection = props => {
  const { caseData, mode, onChange, errors = {}, setErrors = error => {} } = props

  return (
    <>
      <ExpenseInvoicingSelection
        caseData={caseData}
        errors={errors}
        onChange={onChange}
        mode={mode}
        setErrors={setErrors}
      />
    </>
  )
}

export default ContingencySection
