import { CONSTANTS } from 'constants/index'

export const createNewUserPath = () => '/api/v1/users/'
export const getCurrentUserPath = () => '/api/v1/users/current/'
export const resendVerificationEmailPath = () => '/api/v1/users/resend-verification-link/'
export const loginUserPath = () => '/api/v1/auth/token/'
export const refreshTokenPath = () => '/api/v1/auth/token/refresh/'
export const socialSignUpPath = () => '/api/v1/auth/token/social/access/'
export const getCountriesPath = () => '/api/v1/countries/'
export const getPracticeAreasPath = () => '/api/v2/practice_areas/'
export const getExtendedPracticeAreasPath = () => '/api/v1/practice_areas/extended/'
export const getAnswersPath = () => '/api/v1/ai/practice_areas/'
export const addPracticeAreasPath = () => '/api/v1/users/current/practice_areas/'
export const getUserContactInfoPath = () => '/api/v2/users/current/contact-info/'
export const getStatesPath = (country = 840) => `/api/v1/countries/${country}/divisions`
export const getProfileLicensingPath = () => '/api/v1/users/current/licenses/'
export const getSaveProfilePicturePath = () => '/api/v1/users/current/photo/'
export const getSubmitProfilePath = () => '/api/v1/users/current/submit-profile/'
export const getCurrentProfilePath = () => '/api/v1/users/current/profile/'
export const getUserDegreesPath = () => '/api/v1/users/current/degrees/'
export const getAllDegreesPath = () => '/api/v1/degrees/'
export const getUpdatePasswordPath = () => '/api/v1/users/current/update-password/'
export const getPasswordResetEmailPath = () => '/api/v1/codes/password_restore/'
export const getPasswordResetPath = () => '/api/v1/codes/password_restore/validate/'
export const getLanguagesPath = () => '/api/v1/languages/'
export const getUserLanguagesPath = () => '/api/v1/users/current/languages/'
export const socialLinksPath = () => '/api/v1/users/current/social-links/'
export const getVideoPath = () => '/api/v1/users/current/profile/introduction-video/'
export const getProAccountDegreePath = pro_uuid => `/api/v1/users/${pro_uuid}/degrees/`
export const getProAccountLanguagesPath = pro_uuid => `/api/v1/users/${pro_uuid}/languages/`
export const getProAccountLicensesPath = pro_uuid => `/api/v1/users/${pro_uuid}/licenses/`
export const getProAccountPracticeAreasPath = pro_uuid => `/api/v1/users/${pro_uuid}/practice-areas/`
export const getProAccountAvailableTimePath = pro_uuid => `/api/v1/users/${pro_uuid}/calendar-slots/`
export const getProAccountSocialLinksPath = pro_uuid => `/api/v1/users/${pro_uuid}/social-links/`
export const getProAccountProfilePath = pro_uuid => `/api/v1/users/${pro_uuid}/profile/`
export const getProAccountPath = pro_uuid => `/api/v1/users/${pro_uuid}`
export const getPortalStatePath = key => `/api/v1/users/current/portal-state/${key}/`
export const getProfileListPath = () => '/api/v3/users/search/pro/'
export const getAveragePricePath = () => '/api/v2/practice_areas/average-price/'
export const getAdvancedCalendarValuesPath = () => '/api/v1/users/current/calendar-info/'
export const getTimezonesPath = () => '/api/v1/timezones/'
export const getAdvancedSchedulesPath = () => '/api/v1/calendars-config/advanced-schedules/'
export const getAdvancedEventBufferPath = () => '/api/v1/calendars-config/buffers/'
export const getWorkingDaysPath = () => '/api/v1/users/current/working-days/'
export const getWorkingDaysFlatPath = () => '/api/v1/users/current/working-days/flat/'
export const getConnectCalendarAccountPath = provider => `/api/v1/users/current/calendars/calendar-connect/${provider}/`
export const getConnectCalendarsPath = () => '/api/v1/users/current/calendars/calendar-connections/'
export const getSubCalendarListPath = provider => `/api/v1/users/current/calendars/${provider}/external/`
export const getcheckCalendarConnectionsPath = () =>
  '/api/v1/users/current/calendars/calendar-connections/health-check/'
export const getCalendarsHealthWebSocketPath = token =>
  `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/current/calendars_problems_events/?token=${token}`
export const getDisconnectCalendarsPath = () => '/api/v1/users/current/calendars/calendar-connections/delete/'
export const getMeetingsPath = meetingId => `/api/v1/zoom/${meetingId}/`
export const getSocketMeetingPath = (meetingId, token) =>
  `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/meeting/${meetingId}/?token=${token}`
export const getClientSecretPath = () => '/api/v1/users/current/payments/cards/setup-intent/'
export const getUserCardsPath = () => '/api/v1/users/current/payments/cards/'
export const getUserCardPath = uuid => `/api/v1/users/current/payments/cards/${uuid}/`
export const getCreateMeetingPath = () => '/api/v1/users/current/calendars/meetings/'
export const getUserCalendarEventsPath = () => '/api/v1/users/current/calendars/calendar-events/'
export const getUserMeetingsPath = () => '/api/v1/users/current/calendars/meetings/'
export const getUserMeetingPath = uuid => `/api/v1/users/current/calendars/meetings/${uuid}/`
export const getUserMeetingCancelPath = uuid => `/api/v1/users/current/calendars/meetings/${uuid}/cancel/`
export const getUserMeetingReschedulePath = uuid => `/api/v1/users/current/calendars/meetings/${uuid}/reschedule/`
export const getUserMeetingDeletePath = uuid => `/api/v1/users/current/calendars/meetings/${uuid}/delete/`
export const getUserConnectionsPath = () => '/api/v1/users/current/connections/'
export const getUserConnectionsSwitchPath = uuid => `/api/v1/users/current/connections/${uuid}/archive/switch/`
export const getCasesPath = () => '/api/v1/users/current/cases/'
export const getActivitiesPath = () => '/api/v1/users/current/activities/'
export const getActivitiesFilePath = () => '/api/v1/users/current/activities/export/excel/'
export const getActivityPath = uuid => `/api/v1/users/current/activities/${uuid}/`
export const getCasesDetailsPath = id => `/api/v1/users/current/cases/${id}/`
export const getEventsSocketsPath = token => `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/current/events/?token=${token}`
export const getCheckEmailPath = () => '/api/v1/users/check-email/'
export const getInvoicesPath = () => '/api/v1/users/current/invoices/'
export const getInvoicesFilePath = () => '/api/v1/users/current/invoices/export/excel/'
export const getInvoicePath = uuid => `/api/v1/users/current/invoices/${uuid}/`
export const getInvoicePaymentPath = uuid => `/api/v1/users/current/invoices/${uuid}/pay/`
export const getInvoiceSummaryPath = uuid => `/api/v1/users/current/invoices/${uuid}/payment-records/`
export const getUpdateInvoiceSummaryPath = (uuid, paymentId) =>
  `/api/v1/users/current/invoices/${uuid}/payment-records/${paymentId}/`
export const getInviteLinkPath = () => '/api/v1/users/current/invite-for-connect/'
export const getClientInviteValidationPath = () => '/api/v1/codes/invite_for_connect/validate/ '
export const getClientInviteSubmissionPath = () => '/api/v1/users/current/accept-connect-invitation/'
export const getDeletionRequestPath = () => '/api/v1/users/current/deletion-request/'
export const getCancelDeletionRequestPath = uuid => `/api/v1/users/current/deletion-request/${uuid}/`
export const getWalletItemsPath = () => '/api/v1/users/current/wallet/'
export const getWalletPendingFundsPath = () => '/api/v1/users/current/wallet/paid-invoices/'
export const getWalletSummaryPath = () => '/api/v1/users/current/wallet/summary/'
export const getWalletExportPath = () => '/api/v1/users/current/wallet/export/excel/'
export const getStripeConnectUrl = () => 'https://connect.stripe.com/express/oauth/authorize'
export const getPaymentAccountPath = () => '/api/v1/users/current/payments/account/'
export const getStripeDashboardPath = () => '/api/v1/users/current/payments/account/stripe/'
export const getRequestFundsPath = () => '/api/v1/users/current/wallet/funds-request/'
export const getRatingStatusPath = () => '/api/v1/users/current/rating-request/'
export const getVerificationLinkStatusPath = () => '/api/v1/codes/validate-verification-email/'
export const getInitialMeetingPricingPath = () => '/api/v1/users/current/calendars/meetings/initial-price/'
export const getZendeskSsoPath = () => '/api/v1/auth/sso/zendesk/'
export const getZendeskTicketsPath = () => '/api/v1/zendesk/tickets/'
export const getZendeskFieldPath = () => '/api/v1/zendesk/fields/'
export const getChatTokenPath = () => '/api/v1/twilio/token/'
export const getUserChatsPath = () => '/api/v1/users/current/chats/'
export const getUserChatByIdPath = uuid => `/api/v1/users/current/chats/${uuid}/`
export const getTwilioUnreadMessagesPath = () => '/api/v1/twilio/unread_messages/'
export const getTwilioSubscriptionPath = (sid, token) =>
  `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/twilio_users/${sid}/?token=${token}`
export const getUserPracticeAreasFlat = () => '/api/v2/practice_areas/autocomplete/'
export const getUserNotificationsPath = () => '/api/v1/notifications/'
export const getUserCurrentNotificationPath = uuid => `/api/v1/notifications/${uuid}/`
export const getUserUnreadNotificationsPath = () => '/api/v1/notifications/unread/count/'
export const getMarkAllNotificationsReadPath = () => '/api/v1/notifications/mark-all-as-read/'
export const getDeleteAllNotificationsPath = () => '/api/v1/notifications/clear/'
export const getMarkNotificationReadPath = uuid => `/api/v1/notifications/${uuid}/mark-read-or-unread/`
export const getNotificationsWebSocketPath = token =>
  `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/notifications/?token=${token}`
export const getReadChatNotificationsPath = () => '/api/v1/notifications/read-chat-notifications'
export const getInvitesForClientPath = () => '/api/v1/users/current/pro-invitations/'
export const getInviteForClientPath = uuid => `/api/v1/users/current/pro-invitations/${uuid}/`
export const getUserConnectionPath = uuid => `/api/v1/users/current/invited-users/${uuid}/`
export const getInvitedUserContactInfoPath = () => '/api/v1/users/current/invited-users-contact-info/'
export const getResendInviteLink = () => '/api/v1/users/resend-invitation-link/'
export const getPredefndeInvitedUserContactInfoPath = uuid =>
  `/api/v1/users/current/invited-users-contact-info/${uuid}/`
export const getUploadInvoicePath = () => '/api/v1/users/current/invoices/create/'
export const getUploadedProPath = uuid => `/api/v1/roles/pro/uploaded/${uuid}/`
export const getNotificationSettingsPath = () => '/api/v1/users/current/notification-settings/'
export const updateNotificationSettingsPath = uuid => `/api/v1/users/current/notification-settings/${uuid}/`
