import React from 'react'
import { Icon, ProfileAvatarEditor, Text, Typography } from 'components'
import withStyles from '@material-ui/core/styles/withStyles'
import { colors } from 'constants/colors'
import { ValidationAlert } from './modals'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'
import { ReactComponent as UploadIcon } from 'static/profile/file-upload.svg'

const styles = {
  step: {
    padding: '0 32px',
  },
  stepButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  subtitle: {
    marginBottom: 24,
  },
  validationMessage: {
    color: colors.ERROR,
    margin: '10px 0 20px 0',
  },
  photoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  avatar: {
    height: 168,
    width: 168,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  fileInputLabel: {
    border: `1px solid ${colors.PRIMARY.COLOR[50]}`,
    display: 'flex',
    padding: '4px 12px',
    cursor: 'pointer',
    borderRadius: 4,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0.6,
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY.COLOR[50],
    width: 145,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 25,
  },
  fileInputLabel__icon: {
    marginRight: 4,
  },
}

class ProfilePictureStep extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isEmptyPhotoTextShown: false,
      isValidationModalOpen: false,
      picture: null,
      errorMsgs: [],
    }
  }

  componentDidUpdate() {
    if (this.props.isValidation) {
      this.props.resetValidation()
      this.handleProfilePictureSubmit()
    }
  }

  async onSavePicture(file) {
    try {
      await this.props.saveProfilePicture(file)
      this.setState({
        picture: window.URL.createObjectURL(file),
        isEmptyPhotoTextShown: false,
      })
    } catch (e) {
      const message = e && e.detail ? e.detail : ['Please upload a photo that includes your face']
      this.setState({
        errorMsgs: message,
        isValidationModalOpen: true,
      })
    }
  }

  onValidationAlertClose() {
    this.setState({
      isValidationModalOpen: false,
    })
  }

  async handleProfilePictureSubmit() {
    // if (!this.props.pictureUrl) {
    //   this.setState({
    //     isEmptyPhotoTextShown: true,
    //   })
    //   return
    // }

    this.setState({
      isEmptyPhotoTextShown: false,
    })

    try {
      this.props.goToNextStep()
      Analytics.track(EVENTS.PRO_PROFILE_PICTURE_COMPLETED)
    } catch (e) {
      console.error(e)
    }
  }

  setPicture(picture) {
    this.setState({
      picture: window.URL.createObjectURL(picture),
    })
  }

  setErrorMessage(msg) {
    this.setState({
      errorMsgs: [msg],
      isValidationModalOpen: true,
    })
  }

  render() {
    const { classes, pictureUrl } = this.props
    const { isEmptyPhotoTextShown, picture, errorMsgs, isValidationModalOpen } = this.state

    return (
      <div className={classes.step}>
        <Typography variant="h4">Profile picture</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Let’s personalize your profile with a photo so clients can see who they are working with
        </Typography>
        {isEmptyPhotoTextShown && (
          <Typography variant="subtitle2" className={classes.validationMessage}>
            <Icon iconClass="las las la-exclamation-triangle" />
            please upload a photo
          </Typography>
        )}

        <div className={classes.photoContainer}>
          <ProfileAvatarEditor
            editable
            avatarStyles={classes.avatar}
            picture={picture}
            pictureUrl={pictureUrl}
            onSave={file => this.onSavePicture(file)}
            setPicture={file => this.setPicture(file)}
            setErrorMessage={msg => this.setErrorMessage(msg)}
          />
          <div>
            <ol>
              <li>
                <Text>Use .png, .jpg and .gif formats.</Text>
              </li>
              <li>
                <Text>Max size of the photo up to 5 MB</Text>
              </li>
            </ol>
            <label htmlFor={'file'} className={classes.fileInputLabel}>
              <UploadIcon className={classes.fileInputLabel__icon} /> UPLOAD PHOTO
            </label>
          </div>
        </div>
        <ValidationAlert
          title="We have detected some problems with your uploaded portrait image"
          messages={errorMsgs}
          isOpen={isValidationModalOpen}
          onClose={() => this.onValidationAlertClose()}
        />
      </div>
    )
  }
}

export default withStyles(styles)(ProfilePictureStep)
