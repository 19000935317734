export const SET_USER_BIO = 'SET_USER_BIO'
export const GET_USER_DEGREES_START = 'GET_USER_DEGREES_START'
export const GET_USER_DEGREES = 'GET_USER_DEGREES'
export const GET_USER_DEGREES_END = 'GET_USER_DEGREES_END'
export const UPDATE_USER_DEGREES = 'UPDATE_USER_DEGREES'
export const SET_USER_DEGREES = 'SET_USER_DEGREES'
export const GET_USER_LANGUAGES_START = 'GET_USER_LANGUAGES_START'
export const GET_USER_LANGUAGES_END = 'GET_USER_LANGUAGES_END'
export const SAVE_USER_LANGUAGES_START = 'SAVE_USER_LANGUAGES_START'
export const SAVE_USER_LANGUAGES_END = 'SAVE_USER_LANGUAGES_END'
export const UPDATE_LOCAL_LANGUAGES = 'UPDATE_LOCAL_LANGUAGES'
export const GET_CURRENT_PROFILE_STATUS_START = 'GET_CURRENT_PROFILE_STATUS_START'
export const GET_DELETION_REQUEST_STATUS_START = 'GET_DELETION_REQUEST_STATUS_START'
export const GET_DELETION_REQUEST_STATUS_END = 'GET_DELETION_REQUEST_STATUS_END'
export const GET_CURRENT_PROFILE_STATUS_END = 'GET_CURRENT_PROFILE_STATUS_END'
export const GET_CURRENT_PROFILE_STATUS_ERROR = 'GET_CURRENT_PROFILE_STATUS_ERROR'
export const CHANGE_PROFILE_VISIBILITY_START = 'CHANGE_PROFILE_VISIBILITY_START'
export const CHANGE_PROFILE_VISIBILITY_END = 'CHANGE_PROFILE_VISIBILITY_END'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const SET_VIDEO = 'SET_VIDEO'
export const GET_DEGREES_START = 'GET_DEGREES_START'
export const GET_DEGREES_END = 'GET_DEGREES_END'
export const SET_PROFILE_APPROVE_STATUS = 'SET_PROFILE_APPROVE_STATUS'
export const GET_RATING_STATUS_START = 'GET_RATING_STATUS_START'
export const GET_RATING_STATUS_END = 'GET_RATING_STATUS_END'
