import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch } from 'react-redux'
import { ReactComponent as DownloadIcon } from 'static/vault/folder/preview_download.svg'
import EmptyState from 'static/vault/folder/preview_empty.png'
import { colors } from 'constants/index'
import { Typography, Button, VideoPlayer, URLPDFViewer, ColorCircularProgress } from 'components'
import { downloadVaultFile } from 'store/actions/vault'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'
import { getFileBlob } from 'utils/vault/vault-helpers'

const useStyles = makeStyles({
  vaultFilePreviewContainer: {
    minHeight: '100%',
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    backgroundColor: colors.BASIC.COLOR['0'],
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  noPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noPreviewTitle: {
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR['80'],
    marginBottom: 16,
  },
  icon: {
    marginRight: 4,
  },
  btn: {
    minHeight: 32,
    height: 32,
  },
  videoContainer: {
    width: '60%',
  },
  pdfContainer: {
    height: '90%',
    width: '50%',
    padding: 0,
  },
  fileContainer: {
    padding: 20,
  },
  excelTable: {
    backgroundColor: colors.WHITE,
  },
  excelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  excelSheetButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  excelSheetButton: {
    height: 32,
    padding: '10px 12px',
    marginLeft: 12,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

const NoPreview = ({ item }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  return (
    <div className={classes.noPreviewContainer}>
      <img src={EmptyState} alt="we cannot show the contents of this file" />
      <Typography className={classes.noPreviewTitle}>download the file to view it</Typography>
      <Button variant={'primary'} className={classes.btn} onClick={() => dispatch(downloadVaultFile(item))}>
        <DownloadIcon className={classes.icon} /> download
      </Button>
    </div>
  )
}

const ImagePreview = ({ item }) => {
  const classes = useStyles()
  const [link, setLink] = useState(null)

  useEffect(() => {
    getFileBlob(item.link).then(res => {
      const link = window.URL.createObjectURL(res)
      setLink(link)
    })
  }, [])
  return link ? <img src={link} alt={item.name} className={classes.image} /> : <ColorCircularProgress />
}

const VideoPreview = ({ item }) => {
  const classes = useStyles()
  return (
    <div className={classes.videoContainer}>
      <VideoPlayer
        url={item.link}
        controls
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
      />
    </div>
  )
}

const PDFPreview = ({ item }) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
      <div style={{ height: '100vh', width: '100%' }}>
        <Viewer fileUrl={item.link} />
      </div>
    </Worker>
  )
}

const DOC_FILE_MAX_SIZE = '1e+7'
const EXCEL_FILE_MAX_SIZE = '5e+6'
const MSOfficePreview = ({ item }) => {
  const link = encodeURIComponent(item.link)
  const previewAvailable =
    (item.fileType === 'docx' && item.size < DOC_FILE_MAX_SIZE) ||
    (item.fileType === 'doc' && item.size < DOC_FILE_MAX_SIZE) ||
    (item.fileType === 'xlsx' && item.size < EXCEL_FILE_MAX_SIZE) ||
    (item.fileType === 'xls' && item.size < EXCEL_FILE_MAX_SIZE)
  return (
    <>
      {previewAvailable ? (
        <iframe
          width="1366"
          height="623"
          frameBorder="0"
          scrolling="no"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`}
        />
      ) : (
        <NoPreview item={item} />
      )}
    </>
  )
}

const getPreview = item => {
  switch (item.fileType) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <ImagePreview item={item} />
    case 'mov':
    case 'mp4':
      return <VideoPreview item={item} />
    case 'pdf':
      return <PDFPreview item={item} />
    case 'doc':
    case 'xls':
    case 'docx':
    case 'xlsx':
      return <MSOfficePreview item={item} />
    default:
      return <NoPreview item={item} />
  }
}

const VaultFilePreview = ({ previewItem }) => {
  const classes = useStyles()
  return React.useMemo(() => {
    return <div className={classes.vaultFilePreviewContainer}>{getPreview(previewItem)}</div>
  }, [previewItem.uuid])
}

export default VaultFilePreview
