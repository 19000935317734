import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactComponent as TrashIcon } from 'static/vault/trash/trash.svg'
import { colors } from 'constants/index'
import { ColorCircularProgress, Typography, VaultTrashTable, VaultDeleteItemsModal } from 'components'
import {
  getVaultTrashList,
  setVaultItemsAction,
  deleteTrashVaultItem,
  setVaultSelectedItems,
} from 'store/actions/vault'
import { CONSTANTS } from 'constants/index'

const useStyles = makeStyles({
  vaultTrashWrapper: {
    height: '100%',
    width: '100%',
    padding: '0 24px',
  },
  vaultTrashContainer: {
    height: '100%',
    width: '100%',
  },
  vaultTrashEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  vaultTrashEmptyTitle: {
    color: colors.BASIC.COLOR['80'],
    fontSize: 15,
    fontWeight: 'normal',
    marginTop: 15,
  },
})

const VaultTrash = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trashList, trashListIsLoading, selectedItems, itemsAction, singleActionItem } = props

  useEffect(() => {
    dispatch(getVaultTrashList())
    dispatch(setVaultSelectedItems([]))
  }, [])

  const onModalClose = () => {
    dispatch(setVaultItemsAction(null))
  }

  const onItemsDeleteTrashConfirm = async items => {
    await dispatch(deleteTrashVaultItem(items))
    onModalClose()
    dispatch(setVaultSelectedItems([]))
  }

  return (
    <div className={classes.vaultTrashWrapper}>
      {trashListIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <div className={classes.vaultTrashContainer}>
          {trashList.length ? (
            <VaultTrashTable data={trashList} selectedItems={selectedItems} />
          ) : (
            <div className={classes.vaultTrashEmptyContainer}>
              <TrashIcon />
              <Typography className={classes.vaultTrashEmptyTitle}>
                you don’t have any recently deleted files
              </Typography>
            </div>
          )}
        </div>
      )}
      <VaultDeleteItemsModal
        deleteItems={selectedItems}
        onConfirm={onItemsDeleteTrashConfirm}
        isOpen={itemsAction === CONSTANTS.VAULT_ACTIONS.MASS_DELETE_TRASH}
        onClose={onModalClose}
        trash={true}
      />
      <VaultDeleteItemsModal
        deleteItems={singleActionItem}
        onConfirm={onItemsDeleteTrashConfirm}
        isOpen={itemsAction === CONSTANTS.VAULT_ACTIONS.DELETE_TRASH}
        onClose={onModalClose}
        trash={true}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  trashList: state.vault.trashList,
  trashListIsLoading: state.vault.trashListIsLoading,
  selectedItems: state.vault.selectedItems,
  singleActionItem: state.vault.singleActionItem,
  itemsAction: state.vault.itemsAction,
})

export default connect(mapStateToProps)(VaultTrash)
