import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'

const XInputLabel = ({ id, classes, children }) => (
  <InputLabel id={id} classes={classes}>
    {children}
  </InputLabel>
)

export default XInputLabel
