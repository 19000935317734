import { getStripeConnectParams } from 'utils/getters/wallet/wallet'

export const navigateTo = path => (dispatch, getState, { history }) => {
  history.push(path)
}

export const navigateToNewWindow = path => (dispatch, getState, { history }) => {
  window.open(path, '_blank')
}

export const navigateToWindow = path => (dispatch, getState, { history }) => {
  window.open(path, '_self')
}

export const redirectTo = path => (dispatch, getState, { history }) => {
  history.replace(path)
}

export const navigateToRoot = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/'))
}

export const navigateToLogin = (redirect = '') => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/login${redirect}`))
}

export const navigateToSignIn = (redirect = '') => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/sign-in${redirect}`))
}

export const navigateToPasswordChange = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/password/update'))
}

export const navigateToSignUp = (redirect = '') => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/signup${redirect}`))
}

export const navigateToRestorePassword = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/password/reset'))
}

export const navigateToWelcome = (step = 1) => (dispatch, getState, { history }) => {
  dispatch(redirectTo('/pro/welcome'))
}
export const navigateToClientWelcome = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/welcome'))
}
export const navigateToClientDashboard = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/events'))
}
export const navigateToClientProfile = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/profile'))
}
export const navigateToClientSettings = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/settings'))
}
export const navigateToClientPayments = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/payments'))
}
export const navigateToClientCases = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/cases'))
}

export const navigateToClientConnections = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/connections'))
}
export const navigateToClientInvoices = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/client/account/invoices'))
}
export const navigateToClientInvoicePayment = id => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/client/pay-now/${id}`))
}
export const navigateToClientInvoiceDetails = id => dispatch => {
  dispatch(navigateTo(`/client/account/invoices/view/${id}`))
}

export const navigateToAccountTab = (tab = 'events') => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/pro/account/${tab}`))
}
export const navigateToClientAccountTab = (tab = 'events') => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/client/account/${tab}`))
}

export const navigateToAccountProfileTab = (tab = 'general') => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/pro/account/profile/${tab}`))
}

export const navigateToAccountDashboard = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/pro/account/events'))
}
export const navigateToAccountCalendar = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/pro/account/calendar'))
}
export const navigateToAccountCalendarConnectModal = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/pro/account/calendar/connect'))
}

export const navigateToSearch = () => (dispatch, getState, { history }) => {
  dispatch(navigateTo('/search'))
}
export const navigateToSearchProAccount = id => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/search/${id}`))
}
export const navigateToSearchProUploadedAccount = id => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/search/${id}/uploaded`))
}

export const navigateToProfileStep = (step = 1) => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/pro/profile/create/${step}`))
}

export const navigateToMeetingSchedule = (id, start, end, practiceArea) => (dispatch, getState, { history }) => {
  dispatch(navigateTo(`/client/schedule/${id}?start=${start}&end=${end}&area=${practiceArea}`))
}

export const navigateToMeetingPage = id => dispatch => {
  window.open(`/meeting/${id}`, '_blank')
}

export const navigateToCancelEvent = (id, role) => dispatch => {
  dispatch(navigateTo(`/${role}/account/events/${id}/cancel`))
}

export const navigateToRescheduleEvent = (id, role) => dispatch => {
  dispatch(navigateTo(`/${role}/account/events/${id}/reschedule`))
}

export const navigateToCaseList = () => dispatch => {
  dispatch(navigateTo('/pro/account/cases'))
}

export const navigateToCaseListInNewTab = params => dispatch => {
  window.open(`/pro/account/cases?connection=${params.clientId}`, '_blank')
}

export const navigateToCaseDetails = id => dispatch => {
  dispatch(navigateTo(`/pro/account/cases/${id}`))
}

export const redirectToCaseDetails = id => dispatch => {
  dispatch(redirectTo(`/pro/account/cases/${id}`))
}

export const navigateToCaseDetailsInNewTab = id => dispatch => {
  window.open(`/pro/account/cases/${id}`, '_blank')
}

export const navigateToCreateCase = () => dispatch => {
  dispatch(navigateTo(`/pro/account/cases/create`))
}

export const navigateToCreateCaseInNewTab = params => dispatch => {
  window.open(`/pro/account/cases/create?connection=${params.clientId}`, '_blank')
}

export const navigateToEditCase = id => dispatch => {
  dispatch(navigateTo(`/pro/account/cases/${id}/edit`))
}

export const navigateToEditCaseInNewTab = id => dispatch => {
  window.open(`/pro/account/cases/${id}/edit`, '_blank')
}

export const navigateToActivitiesList = () => dispatch => {
  dispatch(navigateTo('/pro/account/activities'))
}

export const navigateToActivitiesListInNewTab = params => dispatch => {
  if (params.caseId) {
    window.open(`/pro/account/activities?case=${params.caseId}`, '_blank')
    return
  }
  if (params.clientId) {
    window.open(`/pro/account/activities?connection=${params.clientId}`, '_blank')
  }
}

export const navigateToInvoicesListInNewTab = params => dispatch => {
  if (params.caseId) {
    window.open(`/pro/account/finances/invoices?case=${params.caseId}`, '_blank')
    return
  }
  if (params.clientId) {
    window.open(`/pro/account/finances/invoices?connection=${params.clientId}`, '_blank')
  }
}

export const navigateToProConnections = params => dispatch => {
  if (params.clientName) {
    dispatch(navigateTo(`/pro/account/connections?connection=${params.clientName}`))
    return
  }
  dispatch(navigateTo('/pro/account/connections'))
}

export const navigateToProConnectionsInNewTab = params => dispatch => {
  window.open(`/pro/account/connections?connection=${params.clientName}`, '_blank')
}

export const navigateToInvoicesList = () => dispatch => {
  dispatch(navigateTo('/pro/account/finances/invoices'))
}

export const navigateToInvoiceDetailsInNewTab = id => dispatch => {
  window.open(`/pro/account/finances/invoices/view/${id}`, '_blank')
}

export const navigateToInvoiceDetails = id => dispatch => {
  dispatch(navigateTo(`/pro/account/finances/invoices/view/${id}`))
}

export const navigateToVaultSettings = () => dispatch => {
  dispatch(navigateTo(`/pro/account/settings/vault-settings`))
}
export const navigateToSettingsGeneral = () => dispatch => {
  dispatch(navigateTo('/pro/account/profile/general'))
}

export const navigateToVault = () => dispatch => {
  dispatch(navigateTo('/pro/account/vault'))
}

export const navigateToStripeConnect = () => (dispatch, getState) => {
  window.location.href = getStripeConnectParams(getState())
}
