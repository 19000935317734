import { CONSTANTS } from 'constants/index'
import moment from 'moment'

export const getSearchParams = (state, states) => {
  const startTimeConstant = CONSTANTS.AVAILABLE_TIME_FILTER[state.filters.startFreeTimeValue]
  const endTimeConstant = CONSTANTS.AVAILABLE_TIME_FILTER[state.filters.endFreeTimeValue]
  const currentTimestamp = moment().unix()
  const startTime = currentTimestamp + startTimeConstant.timestamp
  const endTime = currentTimestamp + endTimeConstant.timestamp
  return {
    ...state.searchParams,
    ordering: state.sorter || null,
    search: state.practiceSearchType ? state.practiceSearchName : state.practiceAreaParam,
    search_type: state.practiceSearchType,
    state:
      (states.find(s => s.label === state.countryStateValue) &&
        states.find(s => s.label === state.countryStateValue).value) ||
      null,
    min_hourly_rate: state.filters.minHourlyRate || null,
    max_hourly_rate: state.filters.maxHourlyRate >= 1000 ? null : state.filters.maxHourlyRate,
    languages: state.filters.languages || null,
    consultation_cost: state.filters.consultationCost ? 0 : null,
    contingency: state.filters.contingency || null,
    fixed_price: state.filters.fixedPrice || null,
    rating: state.filters.averageRating || null,
    start_free_time: startTime || null,
    end_free_time: endTime || null,
    state_search: state.filters.state_search,
  }
}

export const getAnalyticsParams = state => {
  const startTimeConstant = CONSTANTS.AVAILABLE_TIME_FILTER[state.filters.startFreeTimeValue]
  const endTimeConstant = CONSTANTS.AVAILABLE_TIME_FILTER[state.filters.endFreeTimeValue]
  return {
    practice_area: state.practiceAreaValue,
    state: state.countryStateValue,
    available_from: startTimeConstant.valueLabel,
    available_to: endTimeConstant.valueLabel,
    hourly_rate_from: state.filters.minHourlyRate || 0,
    hourly_rate_to: state.filters.maxHourlyRate || 1000,
    no_charge_initial_event: !!state.filters.consultationCost,
    fee_type_contingency: state.filters.contingency || false,
    fee_type_flat: state.filters.fixedPrice || false,
    min_rating: state.filters.averageRating || 0,
    preferred_language: state.filters.languages,
  }
}
