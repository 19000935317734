import React from 'react'
import { ConfirmationModal, WorkingHoursRow } from 'components'

const CONFIRMATION_TEXT =
  'If you make all weekdays unavailable, you will not show up in any searches AND NOT BE BOOKABLE FOR ANY CLIENT. All your appointments will be maintained. Please take care to attend those meetings or informing your clients to re-schedule the discussions. Continue?'
const CONFIRMATION_TITLE = 'Hide your availability time?'
const CONFIRMATION_BUTTON_TEXT = 'Yes, hide'

const WorkingHoursList = props => {
  const { weekData, setWeekData, validationShown, setValidationShown, saveWorkingDaysData } = props

  const onDayChange = dayData => {
    const newDaysData = weekData.map(day => (day.weekday === dayData.weekday ? dayData : day))
    setWeekData(newDaysData)
  }

  const onModalConfirm = () => {
    setValidationShown(false)
    saveWorkingDaysData(weekData)
  }

  const onModalClose = () => {
    setValidationShown(false)
  }

  return (
    <>
      {weekData && !!weekData.length && (
        <>
          {weekData.map(data => (
            <WorkingHoursRow key={data.weekday} dayData={data} onDayChange={onDayChange} />
          ))}
        </>
      )}
      <ConfirmationModal
        title={CONFIRMATION_TITLE}
        message={CONFIRMATION_TEXT}
        confirmationButtonText={CONFIRMATION_BUTTON_TEXT}
        isOpen={validationShown}
        onClose={onModalClose}
        onConfirm={onModalConfirm}
      />
    </>
  )
}

export default WorkingHoursList
