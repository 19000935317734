import React from 'react'
import { ProAccountChatListContainer } from 'containers'
import { makeStyles } from '@material-ui/core/styles'
import { Chat, OnlineIndicator, Typography } from 'components'
import { colors } from 'constants/index'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
  },
  chat: {
    borderTop: `1px solid ${colors.BASIC.COLOR['10']}`,
    height: '100%',
    overflowY: 'auto',
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    height: '100%',
    borderLeft: `1px solid ${colors.BASIC.COLOR['10']}`,
    borderRight: `1px solid ${colors.BASIC.COLOR['10']}`,
  },
  title: {
    margin: '11px 5px 11px 24px',
  },
  titleContainer: {
    height: 59,
    display: 'flex',
    alignItems: 'center',
  },
  connectionList: {
    width: '30%',
  },
  indicator: {
    marginLeft: 6,
    fontSize: 14,
  },
  transparent: {
    opacity: 0,
  },
})

const ProAccountChatContainer = () => {
  const classes = useStyles()
  const selectedChat = useSelector(state => state.chat.selectedChat)

  return (
    <div className={classes.container}>
      <div className={classes.chatContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {selectedChat.chat_member_full_name}
          </Typography>
          <OnlineIndicator
            className={classNames({
              [classes.indicator]: true,
              [classes.transparent]: !selectedChat.chat_member_is_online,
            })}
            showText
          />
        </div>
        <div className={classes.chat}>
          <Chat selectedChat={selectedChat} />
        </div>
      </div>
      <div className={classes.connectionList}>
        <ProAccountChatListContainer selectedChatId={selectedChat.twilio_chat_sid} />
      </div>
    </div>
  )
}

export default ProAccountChatContainer
