import React, { useEffect, useState } from 'react'
import { Modal, Typography, Button, InputLabel, TextField, Hint, Icon, ColorCircularProgress, Select } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getUserEventDetails, cancelUserMeeting } from 'store/actions/events'
import { getMeetingDay, getMeetingDuration, getMeetingRange } from 'utils/getters/events'
import { navigateToAccountDashboard } from 'store/actions/navigation'

const useStyles = makeStyles({
  root: {
    width: 568,
    minHeight: 315,
    position: 'relative',
    paddingBottom: 24,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  meetingTimeSection: {
    width: '100%',
    marginBottom: 24,
    textAlign: 'center',
  },
  meetingTimeLabel: {
    fontSize: 16,
    fontWeight: 500,
  },
  meetingTime: {
    textDecoration: 'line-through',
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    width: '100%',
    padding: '0 40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  reasonSection: {
    width: '100%',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    padding: '24px 40px',
    marginBottom: 24,
  },
  tooltipTextContainer: {
    width: '100%',
    padding: '0 40px',
    marginBottom: 24,
  },
  buttonGroup: {
    width: '100%',
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    height: 40,
    padding: '5px 24px',
    marginLeft: 10,
    minHeight: 40,
  },
  hint: {
    color: colors.ERROR,
    marginTop: 4,
  },
  notFound: {
    position: 'absolute',
    top: 164,
    padding: '0 40px',
    textAlign: 'center',
  },
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  cancelButton: {
    height: 'auto',
    fontSize: 13,
    padding: '8px 24px',
  },
  reasonSelect: {
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: `2px solid ${colors.PRIMARY.COLOR[50]}`,
    },
    '& .MuiInputBase-input': {
      padding: '12px 15px 9px',
    },
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
})

const ProCancelEventModal = props => {
  const classes = useStyles()
  const { eventId } = props
  const dispatch = useDispatch()
  const eventInfoIsLoading = useSelector(store => store.events.meetingInfoIsLoading)
  const eventInfo = useSelector(store => store.events.meetingInfo)
  const [reason, setReason] = useState('')
  const [error, setError] = useState(false)
  const [opneModal, setOpenModal] = useState(true)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  useEffect(() => {
    if (eventInfo.isInitial) {
      setReason(CONSTANTS.REASON_FOR_CANCELATION_BY_PRO[0].value)
    }
    setError(false)
  }, [eventInfo])

  useEffect(() => {
    dispatch(getUserEventDetails(eventId))
  }, [eventId])

  const onConfirm = async () => {
    if (!reason) {
      setError(true)
      return
    }
    setError(false)

    try {
      await dispatch(cancelUserMeeting(eventInfo.uuid, reason))
      onModalClose()
    } catch (e) {
      onModalClose()
      console.error(e)
    }
  }
  const onModalClose = () => {
    setOpenModal(false)
    dispatch(navigateToAccountDashboard())
  }

  return (
    <>
      <Modal open={opneModal} maxWidth={false} handleClose={onModalClose}>
        <div className={classes.root}>
          {eventInfoIsLoading ? (
            <ColorCircularProgress />
          ) : (
            <>
              {!eventInfo.isUpdateButtonVisible ? (
                <Typography variant="h4" className={classes.notFound}>
                  Action is not available because the meeting was canceled or is over
                </Typography>
              ) : (
                eventInfo.clientInfo &&
                eventInfo.startTime &&
                eventInfo.endTime && (
                  <div className={classes.content}>
                    <Typography variant="h4" className={classes.title}>
                      cancel a meeting with
                      {` ${eventInfo.clientInfo.firstName} ${eventInfo.clientInfo.lastName}`}
                    </Typography>
                    <div className={classes.meetingTimeSection}>
                      <Typography variant="body2" className={classes.meetingTime}>
                        {getMeetingDay(eventInfo.startTime, timezone)} &bull;{' '}
                        {getMeetingRange(eventInfo.startTime, eventInfo.endTime, timezone)} &bull;{' '}
                        {getMeetingDuration(eventInfo.startTime, eventInfo.endTime)}
                      </Typography>
                    </div>
                    <div className={classes.reasonSection}>
                      <InputLabel variant="body1">reason for cancellation*</InputLabel>
                      {eventInfo.isInitial ? (
                        <Select
                          formControlClassName={classes.reasonSelect}
                          onChange={e => setReason(e.target.value)}
                          value={reason}
                          options={CONSTANTS.REASON_FOR_CANCELATION_BY_PRO}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={reason}
                          onChange={e => setReason(e.target.value)}
                          inputProps={{
                            maxLength: 150,
                          }}
                        />
                      )}
                      {error && (
                        <Hint classes={{ subtitle2: classes.hint }}>
                          <Icon iconClass="las las la-exclamation-triangle" />
                          Please provide reason for cancellation
                        </Hint>
                      )}
                    </div>
                    <div className={classes.buttonGroup}>
                      <Button variant="secondary" onClick={onModalClose} className={classes.cancelButton}>
                        cancel
                      </Button>
                      <Button variant="primary" onClick={onConfirm} className={classes.confirmButton}>
                        confirm
                      </Button>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ProCancelEventModal
