import moment from 'moment'
import { REGEXP } from '../../../constants'
import { getDatePickerValueWithProTZ, getDateWithTZ } from '../common'

export const getTimeFromDecimal = decimal => {
  if (!decimal) return null
  const hrs = parseInt(Number(decimal))
  const min = Math.round((Number(decimal) - hrs) * 60) || '00'
  return `${hrs}h ${min}m`
}

export const getHoursFromDecimal = decimal => {
  if (!decimal) return 0
  return parseInt(Number(decimal))
}

export const getMinutesFromDecimal = decimal => {
  if (!decimal) return 0
  const hrs = parseInt(Number(decimal))
  return Math.round((Number(decimal) - hrs) * 60) || '00'
}

export const getDecimalFromTime = time => {
  if (!time) return 0
  const str = time.replace(' ', '')
  const hours = (REGEXP.hour.exec(str) && REGEXP.hour.exec(str)[0]) || 0
  const minutes = (REGEXP.minute.exec(str) && REGEXP.minute.exec(str)[0]) || 0
  return +hours + parseFloat((minutes / 60).toFixed(2))
}
export const mathRounding = (number, point) => {
  return Math.round(number * Math.pow(10, point) + Number.EPSILON) / Math.pow(10, point)
}

export const getDecimalFromTimeAndMinutes = (hours = 0, minutes = 0) => {
  const decimal = +hours + parseFloat(mathRounding(minutes / 60, 5))
  return +decimal.toFixed(6)
}

export const getActivitiesParameters = activitiesState => {
  return {
    search: activitiesState.filters.search || null,
    case__client: activitiesState.filters.client || null,
    activity_type: activitiesState.filters.activityType,
    date_of_creation_after: activitiesState.filters.entryRange.from,
    date_of_creation_before: activitiesState.filters.entryRange.to,
    invoice_date_after: activitiesState.filters.invoicesRange.from,
    invoice_date_before: activitiesState.filters.invoicesRange.to,
    invoice_generated: activitiesState.filters.invoiceGenerationStatus,
    case: activitiesState.filters.case || null,
    limit: activitiesState.tableConfiguration.limit,
    offset: activitiesState.tableConfiguration.offset,
    ordering: activitiesState.sort.property
      ? `${activitiesState.sort.isAsc ? '' : '-'}${activitiesState.sort.property}`
      : null,
  }
}

export const getActivitiesFileParameters = activitiesState => {
  return {
    search: activitiesState.filters.search || null,
    case__client: activitiesState.filters.client || null,
    activity_type: activitiesState.filters.activityType,
    date_of_creation_after: activitiesState.filters.entryRange.from,
    date_of_creation_before: activitiesState.filters.entryRange.to,
    invoice_date_after: activitiesState.filters.invoicesRange.from,
    invoice_date_before: activitiesState.filters.invoicesRange.to,
    invoice_generation: activitiesState.filters.invoiceGenerationStatus || null,
    case: activitiesState.filters.case || null,
    ordering: activitiesState.sort.property
      ? `${activitiesState.sort.isAsc ? '' : '-'}${activitiesState.sort.property}`
      : null,
  }
}

const ACTIVITY_TYPE = {
  time: 0,
  expense: 1,
}

export const getActivityByType = (type, caseItem, timeZone) => {
  const creationDate = getDatePickerValueWithProTZ(getDateWithTZ(null, timeZone), timeZone)
  const invoiceDate =
    caseItem.invoicing === 1
      ? null
      : caseItem.invoice_frequency === 1
      ? moment()
          .endOf('isoWeek')
          .toDate()
      : moment()
          .endOf('month')
          .toDate()
  return {
    time: {
      1: {
        case: caseItem,
        caseId: caseItem.uuid,
        clientId: caseItem.client,
        activityType: ACTIVITY_TYPE[type],
        billable: false,
        charge: null,
        invoiceDate: null,
        creationDate: creationDate,
        time: '',
        description: '',
        expense: null,
        rate: caseItem.hourly_rate,
        clientCharge: null,
      },
      2: {
        case: caseItem,
        caseId: caseItem.uuid,
        clientId: caseItem.client,
        activityType: ACTIVITY_TYPE[type],
        billable: false,
        charge: null,
        invoiceDate: null,
        creationDate: creationDate,
        time: '',
        description: '',
        expense: null,
        rate: caseItem.hourly_rate,
        clientCharge: null,
      },
      3: {
        case: caseItem,
        caseId: caseItem.uuid,
        clientId: caseItem.client,
        activityType: ACTIVITY_TYPE[type],
        billable: true,
        charge: true,
        invoiceDate: invoiceDate,
        creationDate: creationDate,
        time: '',
        description: '',
        expense: null,
        rate: caseItem.hourly_rate,
        clientCharge: ' ',
      },
    },
    expense: {
      1: {
        case: caseItem,
        caseId: caseItem.uuid,
        clientId: caseItem.client,
        activityType: ACTIVITY_TYPE[type],
        creationDate: creationDate,
        expense: '',
        description: '',
        time: null,
        rate: null,
        charge: caseItem.expense_invoicing ? true : null,
        billable: !!caseItem.expense_invoicing,
        invoiceDate: caseItem.expense_invoicing ? invoiceDate : null,
        clientCharge: caseItem.expense_invoicing ? ' ' : null,
      },
      2: {
        case: caseItem,
        caseId: caseItem.uuid,
        clientId: caseItem.client,
        activityType: ACTIVITY_TYPE[type],
        creationDate: creationDate,
        expense: '',
        description: '',
        time: null,
        rate: null,
        charge: caseItem.expense_invoicing ? true : null,
        billable: !!caseItem.expense_invoicing,
        invoiceDate: caseItem.expense_invoicing ? invoiceDate : null,
        clientCharge: caseItem.expense_invoicing ? ' ' : null,
      },
      3: {
        case: caseItem,
        caseId: caseItem.uuid,
        clientId: caseItem.client,
        activityType: ACTIVITY_TYPE[type],
        creationDate: creationDate,
        expense: '',
        description: '',
        time: null,
        rate: null,
        charge: true,
        billable: true,
        invoiceDate: invoiceDate,
        clientCharge: ' ',
      },
    },
  }
}
