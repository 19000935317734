import React from 'react'
import { StyledStepper } from 'components'

const getSteps = () => ['area of law or legal topic', 'contact information', 'payment', 'confirmation']

const MeetingSchedulerStepper = ({ activeStep, setActiveStep, completedStep }) => {
  return (
    <StyledStepper
      steps={getSteps()}
      activeStep={activeStep}
      completedStep={completedStep}
      setActiveStep={setActiveStep}
    />
  )
}

export default MeetingSchedulerStepper
