import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import { colors } from 'constants/index'
import { NotificationList, SvgIcon, ThreeDotsMenu, Typography, Button } from 'components'
import { ReactComponent as CheckIcon } from 'static/notifications/checkmark.svg'
import { ReactComponent as SettingsIcon } from 'static/notifications/settings.svg'
import { ReactComponent as TrashIcon } from 'static/notifications/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteAllNotifications,
  getUserNotifications,
  markAllNotificationsAsRead,
} from 'store/actions/notifications/notifications'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const useStyles = makeStyles({
  paper: {
    width: 400,
    backgroundColor: colors.BASIC.COLOR['0'],
    display: 'flex',
    flexDirection: 'column',
  },
  menuIcon: {
    height: 20,
    width: 20,
    marginRight: 8,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${colors.BASIC.COLOR['5']}`,
    paddingRight: 16,
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
  },
  iconButton: {
    backgroundColor: colors.BASIC.COLOR['0'],
    height: 26,
    width: 26,
    padding: 0,
  },
  content: {
    flexGrow: 1,
  },
  menuWrapper: {
    display: 'block',
  },
  buttonsWrapper: {
    display: 'none',
  },
  '@media (max-width: 500px)': {
    paper: {
      width: '100vw',
      boxShadow: 'none',
    },
    menuWrapper: {
      display: 'none',
    },
    buttonsWrapper: {
      display: 'block',
    },
  },
  '@media (max-height: 480px)': {
    paper: {
      width: '100vw',
      boxShadow: 'none',
    },
  },
  '@media (max-width: 370px)': {
    menuWrapper: {
      display: 'block',
    },
    buttonsWrapper: {
      display: 'none',
    },
  },
  paperContent: {
    maxWidth: '100%',
  },
  actionButton: {
    textTransform: 'none',
    color: colors.BASIC.COLOR['100'],
    minHeight: 40,
    height: 40,
    padding: 5,
  },
  tabIndicator: {
    display: 'none',
  },
  tab: {
    borderRight: `1px solid ${colors.BASIC.COLOR['5']}`,
    backgroundColor: colors.BASIC.COLOR['5'],
    color: colors.BASIC.COLOR['50'],
    textTransform: 'none',
  },
  selectedTab: {
    backgroundColor: colors.BASIC.COLOR['0'],
    color: colors.BASIC.COLOR['100'],
  },
  counter: {
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.PRIMARY.COLOR[50],
    marginLeft: 8,
  },
})

const ActionMenuItem = ({ itemKey }) => {
  const classes = useStyles()

  switch (itemKey) {
    case 'markAllAsRead':
      return (
        <>
          <SvgIcon viewBox="0 0 20 20" className={classes.menuIcon}>
            <CheckIcon />
          </SvgIcon>
          mark all as read
        </>
      )

    case 'clearAll':
      return (
        <>
          <SvgIcon viewBox="0 0 20 20" className={classes.menuIcon}>
            <TrashIcon />
          </SvgIcon>
          clear all
        </>
      )

    case 'manage':
      return (
        <>
          <SvgIcon viewBox="0 0 20 20" className={classes.menuIcon}>
            <SettingsIcon />
          </SvgIcon>
          manage
        </>
      )

    default:
      return null
  }
}

const NotificationsPopper = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpen, close, anchorEl } = props
  const notificationsLength = useSelector(state => state.notifications.notificationsLength)
  const adminNotificationsLength = useSelector(state => state.notifications.adminNotificationsLength)
  const adminUnreadCounter = useSelector(state => state.notifications.adminUnreadCounter)
  const notAdminUnreadCounter = useSelector(state => state.notifications.notAdminUnreadCounter)
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (isOpen) {
      dispatch(getUserNotifications(true))
    }
  }, [isOpen])

  const actions = [
    {
      key: 'markAllAsRead',
      label: <ActionMenuItem itemKey="markAllAsRead" />,
      action: () => {
        dispatch(markAllNotificationsAsRead())
      },
    },
    {
      key: 'clearAll',
      label: <ActionMenuItem itemKey="clearAll" />,
      action: () => {
        dispatch(deleteAllNotifications())
      },
    },
  ]

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    dispatch(getUserNotifications(true, newValue === 1))
  }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      marginThreshold={0}
      classes={{ paper: classes.paperContent }}
    >
      <Paper classes={{ root: classes.paper }}>
        <div className={classes.header}>
          <Tabs classes={{ indicator: classes.tabIndicator }} value={selectedTab} onChange={handleTabChange}>
            <Tab
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              label={
                <span>
                  Notifications <span className={classes.counter}>{notAdminUnreadCounter}</span>
                </span>
              }
            />
            <Tab
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              label={
                <span>
                  XIRA messages <span className={classes.counter}>{adminUnreadCounter}</span>
                </span>
              }
            />
          </Tabs>
          {(!!notificationsLength || !!adminNotificationsLength) && (
            <div className={classes.menuWrapper}>
              <ThreeDotsMenu actions={actions} iconButtonClass={classes.iconButton} />
            </div>
          )}
          {(!!notificationsLength || !!adminNotificationsLength) && (
            <div className={classes.buttonsWrapper}>
              {actions.map(action => (
                <Button
                  key={action.key}
                  variant="text"
                  onClick={() => {
                    action.action()
                  }}
                  className={classes.actionButton}
                >
                  {action.label}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className={classes.content}>
          <NotificationList closePopover={close} isAdmin={selectedTab === 1} />
        </div>
      </Paper>
    </Popover>
  )
}

export default NotificationsPopper
