import React from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd'
import classNames from 'classnames'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  hoverText: {
    fontWeight: 500,
  },
})

const VaultDragDropArea = ({
  onAddFile,
  wrapperClasses,
  containerClasses,
  inputID,
  children,
  inputClasses,
  hoverWrapperClasses,
  hoverContainerClasses,
  hoverText,
  withFileInput,
}) => {
  const classes = useStyles()
  const handleFileDrop = (item, monitor) => {
    if (monitor) {
      const file = monitor.getItem().files
      onAddFile(file)
    }
  }

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    collect: monitor => {
      return {
        isOver: monitor.isOver({ shallow: true }),
      }
    },
    drop(item, monitor) {
      if (handleFileDrop) {
        handleFileDrop({}, monitor)
      }
    },
  })
  return (
    <div className={classNames(wrapperClasses, { [hoverWrapperClasses]: isOver })}>
      <div ref={drop} className={classNames(containerClasses, { [hoverContainerClasses]: isOver })}>
        {withFileInput && (
          <input
            multiple
            name={'vault_files[]'}
            onChange={e => onAddFile(e.currentTarget.files)}
            className={inputClasses}
            id={inputID}
            type="file"
            value={''}
          />
        )}
        {isOver && hoverText ? <Typography className={classes.hoverText}> {hoverText} </Typography> : children}
      </div>
    </div>
  )
}

export default VaultDragDropArea
