import React from 'react'
import { Button } from 'components'
import { pdf } from '@react-pdf/renderer'
import { exportFile } from '../../../store/actions/common'
import { useDispatch } from 'react-redux'

const DownloadPDFButton = ({ document, fileName, btnClasses, variant, onClick }) => {
  const dispatch = useDispatch()
  const onPDFLoadBtnClick = async () => {
    const blob = await pdf(document).toBlob()
    dispatch(exportFile(blob, fileName, 'application/pdf'))
  }
  return (
    <Button className={btnClasses} variant={variant} onClick={onClick || onPDFLoadBtnClick}>
      download pdf
    </Button>
  )
}

export default DownloadPDFButton
