export const SET_USER_MESSAGES = 'SET_USER_MESSAGES'
export const RESET_USER_MESSAGES = 'RESET_USER_MESSAGES'
export const GET_USER_CHATS_START = 'GET_USER_CHATS_START'
export const GET_USER_CHATS_END = 'GET_USER_CHATS_END'
export const SET_SELECTED_CHAT = 'SET_SELECTED_CHAT'
export const SET_CHAT_INFO = 'SET_CHAT_INFO'
export const RESET_SELECTED_CHAT = 'RESET_SELECTED_CHAT'
export const SET_CHAT_WINDOW_VISIBILITY = 'SET_CHAT_WINDOW_VISIBILITY'
export const CHECK_IF_USER_HAS_UNREAD_MESSAGES_END = 'CHECK_IF_USER_HAS_UNREAD_MESSAGES_END'
export const SET_CHAT_SEARCH_VALUE = 'SET_CHAT_SEARCH_VALUE'
export const INCREASE_CHAT_LIST_OFFSET = 'INCREASE_CHAT_LIST_OFFSET'
export const RESET_CHAT_LIST_OFFSET = 'RESET_CHAT_LIST_OFFSET'
export const SET_SELECTED_CHAT_ONLINE = 'SET_SELECTED_CHAT_ONLINE'
export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT'
