import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Autocomplete, Button, Icon, IconButton, TextField, Link, Tooltip } from 'components'
import { getProCases, setCasesFilter, setTableConfiguration } from 'store/actions/cases'
import { goToZendesk } from 'store/actions/common'
import { colors, routes } from 'constants/index'
import { useQuery } from 'utils/hooks'
import { Link as RouterLink } from 'react-router-dom'
import { NotForFeeModal } from './NotForFeeModal'

const useStyles = makeStyles({
  filters: {
    display: 'flex',
  },
  statusFilter: {
    marginLeft: 12,
    width: 150,
  },
  clientFilter: {
    marginLeft: 12,
    width: 200,
  },
  addButton: {
    width: 120,
    padding: '5px 15px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  search: {
    marginLeft: 12,
    width: 300,
  },
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
  searchButton: {
    padding: 6,
  },
  tooltip: {
    padding: 16,
    maxWidth: 330,
    color: colors.BASIC.COLOR[100],
    fontWeight: 'normal',
    fontSize: 13,
  },
})

const statusOptions = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Inactive',
  },
  {
    value: null,
    label: 'All',
  },
]

const TooltipText = ({ goToZendesk }) => (
  <span>
    You can not manage cases until your license is approved. Please contact <Link onClick={goToZendesk}>XIRA</Link>{' '}
    admin for details.
  </span>
)

const CasesTableFilters = ({ showFilters, isCasesAble }) => {
  const classes = useStyles()
  const query = useQuery()
  const clientId = query.get('connection')
  const dispatch = useDispatch()
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const filters = useSelector(state => state.cases.filters)
  const [search, setSearch] = useState(filters.search)
  const connections = useSelector(state => state.connections.filterConnections)
  const [openPopup, setOpenPopup] = useState(false)

  const onOpenPopup = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpenPopup(true)
  }
  const onClosePopup = () => {
    setOpenPopup(false)
  }

  useEffect(() => {
    setSearch(filters.search)
  }, [filters.search])

  useEffect(() => {
    dispatch(setCasesFilter('clientId', clientId))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProCases())
  }, [clientId])

  const onSearch = () => {
    dispatch(setCasesFilter('search', search))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProCases())
  }

  const onFilterChange = (name, value) => {
    dispatch(setCasesFilter(name, value))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProCases())
  }

  const onSearchChange = e => {
    setSearch(e.target.value)
  }

  const onKey = event => {
    if (event.key === 'Enter') {
      dispatch(setCasesFilter('search', search))
      dispatch(
        setTableConfiguration({
          tablePage: 0,
          offset: 0,
        })
      )
      dispatch(getProCases())
    }
  }

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  return (
    <div className={classes.filters}>
      {showFilters && (
        <>
          <TextField
            variant="outlined"
            className={classes.search}
            value={search}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <IconButton onClick={onSearch} className={classes.searchButton}>
                  <Icon iconClass="las la-search" style={classes.searchIcon} />
                </IconButton>
              ),
            }}
            onChange={onSearchChange}
            onKeyPress={onKey}
          />
          <Autocomplete
            fullWidth
            placeholder="Status"
            variant="outlined"
            value={filters.status}
            className={classes.statusFilter}
            onChange={(e, v) => onFilterChange('status', v ? v.value : null)}
            options={statusOptions}
          />
          <Autocomplete
            fullWidth
            placeholder="Client"
            variant="outlined"
            value={filters.clientId}
            className={classes.clientFilter}
            onChange={(e, v) => onFilterChange('clientId', v ? v.value : null)}
            options={connections}
          />
        </>
      )}
      {!profileIsApproved ? (
        <Tooltip title={<TooltipText goToZendesk={zendeskSso} />} tooltipClass={classes.tooltip} interactive>
          <Button
            type="primary"
            className={classes.addButton}
            disabled
            component={RouterLink}
            to={routes.getProAccountCasesCreateLinkPath()}
          >
            create case
          </Button>
        </Tooltip>
      ) : (
        <Button
          type="primary"
          className={classes.addButton}
          component={RouterLink}
          onClick={!isCasesAble && onOpenPopup}
          to={routes.getProAccountCasesCreateLinkPath()}
        >
          create case
        </Button>
      )}
      <NotForFeeModal open={openPopup} onClose={onClosePopup} />
    </div>
  )
}

export default CasesTableFilters
