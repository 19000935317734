import React from 'react'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { ReactComponent as InactiveFilterLogo } from 'static/invoices/inactive_filter.svg'
import { Button, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  filterBtn: {
    padding: '10px 24px',
    border: 'solid 1px #bbbab9',
    color: colors.BASIC.COLOR[80],
    fill: colors.BASIC.COLOR[80],
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 40,
    minHeight: 40,
    '&:hover': {
      fill: colors.PRIMARY.COLOR[50],
    },
  },
  btnIcon: {
    marginRight: 4,
    height: 16,
    width: 16,
  },
  activeFilterBtn: {
    backgroundColor: colors.PRIMARY.COLOR[50],
    color: colors.WHITE,
    fill: colors.WHITE,
  },
  buttonRange: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
})

const CommonFilterButton = ({ onClick, showFilters, range }) => {
  const classes = useStyles()
  return (
    <Button
      variant="secondary"
      className={classNames(
        classes.filterBtn,
        { [classes.activeFilterBtn]: showFilters },
        { [classes.buttonRange]: range }
      )}
      onClick={onClick}
    >
      <SvgIcon viewBox="0 0 16 16" className={classes.btnIcon}>
        <InactiveFilterLogo />
      </SvgIcon>
      filters
    </Button>
  )
}

export default CommonFilterButton
