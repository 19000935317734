import React, { useEffect, useState, useRef } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { TextField } from 'components'
import { InputAdornment } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { colors, CONSTANTS } from 'constants/index'
import { ReactComponent as CalendarLogo } from 'static/proMenuIcons/calendar/calendar.svg'
import { ReactComponent as DeleteIcon } from 'static/common/delete.svg'
import moment from 'moment-timezone'
import '../dataRangePicker/styles/index.scss'
import 'react-day-picker/lib/style.css'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { getDatePickerValueWithProTZ, getDateWithTZ } from '../../../utils/getters/common'

const useStyles = makeStyles({
  adornment: {
    cursor: 'pointer',
  },
  error: {
    borderColor: colors.ERROR,
  },
  inputFullWidth: {
    width: '100%',
  },
})

const fullWidthStyle = {
  width: '100%',
}

const DatePicker = ({
  containerClasses,
  numberOfMonths = 1,
  selectedDate,
  updateDate,
  disabled,
  disabledDays,
  error,
  fullWidth,
  onDayPickerHide,
  dateFormat = CONSTANTS.DATE_FORMAT.FORMAT_ONE,
  calendarOrientation = 'bottom',
  timezone = moment.tz.guess(),
}) => {
  const classes = useStyles()
  const DatePickerInput = React.forwardRef((props, ref) => {
    return (
      <TextField
        classes={{ root: fullWidth && classes.inputFullWidth }}
        variant="outlined"
        InputProps={{
          classes: {
            notchedOutline: error && classes.error,
          },
          startAdornment: (
            <InputAdornment disableTypography position="start">
              <CalendarLogo style={{ stroke: colors.BASIC.COLOR[80] }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment disableTypography position="end" classes={{ root: classes.adornment }} onClick={clearRange}>
              {!disabled && <DeleteIcon style={{ stroke: colors.BASIC.COLOR[70] }} />}
            </InputAdornment>
          ),
        }}
        inputProps={{
          readOnly: true,
        }}
        {...props}
      />
    )
  })
  const [value, setValue] = useState('')
  const inputRef = useRef(null)
  const overlayClass =
    calendarOrientation === 'top'
      ? 'DayPickerInput-Overlay DayPickerInput-Overlay__top DayPickerInput-Overlay__common'
      : 'DayPickerInput-Overlay DayPickerInput-Overlay__common'
  const modifiers = { today: getDateWithTZ(null, timezone) }

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    updateDate(modifiers.selected ? undefined : getDatePickerValueWithProTZ(day, timezone))
  }

  const clearRange = e => {
    e.stopPropagation()
    inputRef.current.hideDayPicker()
    updateDate(null)
  }

  const clickAwayHandler = () => {
    inputRef.current.hideDayPicker()
  }

  useEffect(() => {
    let value = ''
    if (selectedDate)
      value = `${moment(selectedDate)
        .tz(timezone)
        .format(dateFormat)}`
    else value = selectedDate === null && disabled ? 'N/A' : 'Choose date'
    setValue(value)
  }, [selectedDate, disabled])

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className={containerClasses}>
        <DayPickerInput
          ref={inputRef}
          classNames={{
            overlay: overlayClass,
            overlayWrapper: 'DayPickerInput-OverlayWrapper',
          }}
          onDayPickerHide={onDayPickerHide}
          style={fullWidth ? fullWidthStyle : {}}
          inputProps={{ disabled: disabled }}
          placeholder=""
          value={value}
          component={DatePickerInput}
          dayPickerProps={{
            className: 'Selectable',
            numberOfMonths: numberOfMonths,
            selectedDays: selectedDate && getDateWithTZ(selectedDate, timezone),
            disabledDays: disabledDays,
            modifiers: modifiers,
            onDayClick: handleDayClick,
          }}
        />
      </div>
    </ClickAwayListener>
  )
}

export default DatePicker
