import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Checkbox, Hint, Icon, InputLabel, TextField, Tooltip, Divider, Typography } from 'components'
import { colors, REGEXP } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  card: {
    width: '100%',
    boxShadow: 'none',
  },
  cardContent: {
    padding: 0,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 14,
    paddingLeft: 65,
  },
  column: {
    width: '49%',
  },
  checkboxLabel: {
    marginBottom: 0,
    fontSize: 15,
  },
  checkbox: {
    padding: 5,
  },
  subCheckboxLabel: {
    fontWeight: 500,
  },
  subSpecialityContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 30,
    paddingLeft: 65,
  },
  subSpeciality: {
    width: '50%',
  },
  subTitle: {
    marginTop: 20,
    fontWeight: 600,
  },
  hint: {
    color: colors.BASIC.COLOR[80],
    fontSize: 17,
    marginLeft: 5,
  },
  mandatory: {
    borderColor: '#575757',
    textAlign: 'right',
  },
  error: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: '8px',
  },
  iconButton: {
    padding: 0,
  },
  toolTip: {
    backgroundColor: colors.white,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    fontSize: 13,
  },
  input: {
    textAlign: 'right',
  },
  checkboxContainer: {
    display: 'flex',
    paddingLeft: 65,
    margin: '16px 0',
  },
  inputLabel: {
    height: 20,
    display: 'flex',
    flexWrap: 'noWrap',
    marginBottom: 0,
  },
  subSpecialityLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 0 0 65px',
  },
  errorInput: {
    borderColor: colors.ERROR,
  },
  divider: {
    width: '100%',
    backgroundColor: colors.BASIC.COLOR[10],
  },
  checkboxTitle: {
    marginLeft: '-3px',
    fontSize: 14,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
  },
  feeTypesContainer: {
    width: '50%',
    marginRight: 25,
  },
  federalLawContainer: {
    width: '49.5%',
  },
  feeTypesCheckbox: {
    display: 'flex',
    flexDirection: 'column',
  },
  averageRate: {
    paddingLeft: 65,
    marginBottom: 15,
    color: colors.BASIC.COLOR[80],
  },
  checked: {
    color: colors.ADDITIONAL.COLOR['50'],
  },
  hintText: {
    fontWeight: 'normal',
    color: colors.BASIC.COLOR['80'],
    padding: '6px 95px',
  },
  disabledText: {
    color: colors.BASIC.COLOR['50'],
  },
})

const toolTip = 'Most lawyers provide the initial consultation free'

const getHourlyRateErrorMessage = hourlyRate => {
  if (!hourlyRate) return 'Please fill out the field'
  else if (hourlyRate === '0') return 'Please enter value greater than 0'
  else if (REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(hourlyRate) || !REGEXP.fiveDecimals.test(hourlyRate))
    return 'Please enter a maximum of 5 digits and no decimals'
}

const getInitialCostErrorMessage = initialCost => {
  if (REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(initialCost) || !REGEXP.fiveDecimals.test(initialCost))
    return 'Please enter a maximum of 5 digits and no decimals'
}

const PracticeAreaDetails = props => {
  const {
    areaDetails,
    setPracticeAreaDetails,
    setPracticeAreaSubDetails,
    setPracticeAreaSubSpeciality,
    onBlurHR,
    showHRValidation,
    areaChecked,
  } = props
  const classes = useStyles()
  const onDetailsChange = e => {
    const value = e.target.value ? e.target.value : e.target.checked
    setPracticeAreaDetails(areaDetails, e.target.name, value)
  }

  const onSubDetailsChange = (e, subDetail, isFederal) => {
    setPracticeAreaSubDetails(areaDetails, subDetail, e.target.name, e.target.checked, isFederal)
  }

  const onSubSpecialityChange = (e, isFederal) => {
    setPracticeAreaSubSpeciality(areaDetails, e.target.checked, isFederal)
  }

  const onFocusConsultCost = e => {
    if (Number(e.target.value) === 0) {
      e.target.selectionEnd = 1
    }
  }

  const hourlyRateError =
    (showHRValidation &&
      (!areaDetails.hourlyRate ||
        areaDetails.hourlyRate === '0' ||
        REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(areaDetails.hourlyRate))) ||
    (!REGEXP.fiveDecimals.test(areaDetails.hourlyRate) && areaDetails.hourlyRate)

  const initialCostRateError =
    (showHRValidation && REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(areaDetails.consultationCost)) ||
    (!REGEXP.fiveDecimals.test(areaDetails.consultationCost) && areaDetails.consultationCost)

  return React.useMemo(() => {
    const checkedSub = areaDetails.subSpecialities.filter(sub => sub.checked)
    const checkedFederalSub = areaDetails.federalSubSpecialities.filter(sub => sub.checked)
    return (
      <Card className={classes.card}>
        <CardContent classes={{ root: classes.cardContent }}>
          <div>
            <div className={classes.row}>
              <div className={classes.column}>
                <InputLabel classes={{ root: classes.inputLabel }}>Standard Hourly Rate, $</InputLabel>
                <TextField
                  autoFocus={areaDetails.checked}
                  onBlur={e => onBlurHR(e, areaDetails)}
                  variant="outlined"
                  name="hourlyRate"
                  InputProps={{
                    classes: {
                      notchedOutline: classNames(
                        { [classes.mandatory]: areaDetails.checked && !areaDetails.hourlyRate },
                        { [classes.input]: !areaDetails.checked || !areaDetails.hourlyRate },
                        {
                          [classes.errorInput]: hourlyRateError,
                        }
                      ),
                      input: classes.input,
                    },
                  }}
                  value={areaDetails.hourlyRate || ''}
                  onChange={onDetailsChange}
                />
                {hourlyRateError && (
                  <Hint classes={{ subtitle2: classes.error }}>
                    <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                    {getHourlyRateErrorMessage(areaDetails.hourlyRate)}
                  </Hint>
                )}
              </div>
              <div className={classes.column}>
                <InputLabel classes={{ root: classes.inputLabel }}>
                  Initial Consultation (30 min), $
                  <Tooltip tooltipClass={classes.toolTip} title={toolTip}>
                    <Icon iconClass="las la-question-circle" style={classes.hint} />
                  </Tooltip>
                </InputLabel>

                <TextField
                  onFocus={onFocusConsultCost}
                  onClick={onFocusConsultCost}
                  className={classes.input}
                  variant="outlined"
                  name="consultationCost"
                  onBlur={e => onBlurHR(e, areaDetails)}
                  InputProps={{
                    classes: {
                      input: classes.input,
                      notchedOutline: classNames({
                        [classes.errorInput]: initialCostRateError,
                      }),
                    },
                  }}
                  value={areaDetails.consultationCost || 0}
                  onChange={onDetailsChange}
                />
                {showHRValidation && initialCostRateError && (
                  <Hint classes={{ subtitle2: classes.error }}>
                    <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                    {getInitialCostErrorMessage(areaDetails.consultationCost)}
                  </Hint>
                )}
              </div>
            </div>
            {areaChecked && areaDetails.averageHourlyRate && (
              <Typography
                variant="subtitle2"
                className={classes.averageRate}
              >{`Average hourly rate is $${areaDetails.averageHourlyRate}`}</Typography>
            )}
          </div>
          <Divider classes={{ root: classes.divider }} />
          <div className={classes.checkboxContainer}>
            <div className={classes.feeTypesContainer}>
              <Typography variant="subtitle2" className={classes.checkboxTitle}>
                Fee types
              </Typography>
              <div className={classes.feeTypesCheckbox}>
                <Checkbox
                  label="Would consider contingency (optional)"
                  name="contingency"
                  checked={!!areaDetails.contingency}
                  onChange={onDetailsChange}
                  classes={{ root: classes.checkbox }}
                  labelTextClasses={{ subtitle1: classes.checkboxLabel }}
                />
                <Checkbox
                  label="Would consider flat fee (optional)"
                  name="fixedPrice"
                  checked={!!areaDetails.fixedPrice}
                  onChange={onDetailsChange}
                  classes={{ root: classes.checkbox }}
                  labelTextClasses={{ subtitle1: classes.checkboxLabel }}
                />
              </div>
            </div>
          </div>
          <Divider classes={{ root: classes.divider }} />
          <div className={classes.subSpecialityLabelContainer}>
            <Checkbox
              indeterminate={!!checkedSub.length && checkedSub.length !== areaDetails.subSpecialities.length}
              checked={!!checkedSub.length}
              onChange={e => onSubSpecialityChange(e, false)}
              variant="subtitle1"
              label="Sub-specialties"
              classes={{ root: classes.checkbox }}
              className={classes.subTitle}
              labelTextClasses={{
                subtitle1: classNames({
                  [classes.checkboxLabel]: true,
                  [classes.subCheckboxLabel]: true,
                  [classes.disabledText]: !!checkedFederalSub.length,
                }),
              }}
              disabled={!!checkedFederalSub.length}
            />
            {showHRValidation && !checkedSub.length && (
              <div>
                <Hint classes={{ subtitle2: classes.error }}>
                  <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                  please select at least one sub-speciality
                </Hint>
              </div>
            )}
          </div>
          <div className={classes.subSpecialityContainer}>
            {areaDetails.subSpecialities.map(item => (
              <div key={item.uuid} className={classes.subSpeciality}>
                <Checkbox
                  checked={item.checked || false}
                  label={item.name}
                  name={item.uuid}
                  onChange={e => onSubDetailsChange(e, 'subSpecialities', false)}
                  classes={{ root: classes.checkbox }}
                  labelTextClasses={{
                    subtitle1: classNames({
                      [classes.checkboxLabel]: true,
                      [classes.disabledText]: item.disabled,
                    }),
                  }}
                  disabled={item.disabled}
                />
              </div>
            ))}
          </div>
          {!!areaDetails.federalSubSpecialities.length && (
            <>
              <Divider classes={{ root: classes.divider }} />
              <div className={classes.subSpecialityLabelContainer}>
                <Checkbox
                  indeterminate={
                    !!checkedFederalSub.length && checkedFederalSub.length !== areaDetails.federalSubSpecialities.length
                  }
                  checked={!!checkedFederalSub.length}
                  onChange={e => onSubSpecialityChange(e, true)}
                  variant="subtitle1"
                  label="List me nationally"
                  classes={{ root: classes.federalCheckbox, checked: classes.checked }}
                  className={classes.subTitle}
                  labelTextClasses={{
                    subtitle1: classNames({
                      [classes.checkboxLabel]: true,
                      [classes.subCheckboxLabel]: true,
                      [classes.disabledText]: !checkedSub.length,
                    }),
                  }}
                  color={'secondary'}
                  disabled={areaDetails.federalSubSpecialities.some(item => item.disabled) || !checkedSub.length}
                />
              </div>
              <Typography className={classes.hintText}>
                By clicking this box, I agree for clients from states in which I am not licensed, I will only consult
                with them on federal law issues on which I am legally authorized to render advice. I will not violate
                any state laws regarding unauthorized practice of law.
              </Typography>
              <div className={classes.subSpecialityContainer}>
                {areaDetails.federalSubSpecialities.map(item => (
                  <div key={item.uuid} className={classes.subSpeciality}>
                    <Checkbox
                      disabled={item.disabled || !checkedSub.length}
                      checked={item.checked || false}
                      label={item.name}
                      name={item.uuid}
                      onChange={e => onSubDetailsChange(e, 'federalSubSpecialities', true)}
                      classes={{ root: classes.federalCheckbox }}
                      labelTextClasses={{
                        subtitle1: classNames({
                          [classes.checkboxLabel]: true,
                          [classes.disabledText]: item.disabled || !checkedSub.length,
                        }),
                      }}
                      color={'secondary'}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </CardContent>
      </Card>
    )
  }, [areaDetails, showHRValidation])
}

export default PracticeAreaDetails
