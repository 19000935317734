import { webSocket } from 'rxjs/webSocket'

export class WebsocketService {
  reconnect
  websocketSubject
  path

  constructor(path) {
    this.reconnect = true
    this.path = path
    this.websocketSubject = webSocket(path)
  }

  subscribe(callback, errorCallback) {
    this.websocketSubject.subscribe(
      msg => callback(msg),
      error => {
        console.error(`Error`, error)
        if (errorCallback) {
          errorCallback()
          return
        }
        if (this.reconnect) {
          this.unsubscribe()
          this.subscribe(callback, errorCallback)
        }
      },
      () => {
        if (this.reconnect) {
          this.subscribe(callback, errorCallback)
        } else {
          console.log(`Connection completed to `, this.path)
        }
      }
    )
  }

  unsubscribe() {
    this.reconnect = false
    this.websocketSubject.unsubscribe()
  }
}
