import { Document as ViewDocument, Page as ViewPage, Outline } from 'react-pdf/dist/entry.webpack'
import React, { useState } from 'react'
import './style/index.scss'

const URLPDFViewer = ({ url, className }) => {
  const [numPages, setNumPage] = useState(null)
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPage(numPages)
  }
  return (
          <>
            <ViewDocument file={url} onLoadSuccess={onDocumentLoadSuccess} className={className}>
              {Array.from(new Array(numPages), (el, index) => (
                <ViewPage key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </ViewDocument>
          </>
  )
}

export default URLPDFViewer