import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as d3 from 'd3'
import { colors } from 'constants/index'
import { useResizeObserver } from 'utils/hooks'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { max } from 'lodash'
import { getValuesYAxisFreePlan, getValuesYAxiPremiumPlan, memoryRate } from 'utils/vault/vault-helpers'

const useStyles = makeStyles({
  svgChart: {
    width: '100%',
    height: 300,
    overflow: 'visible',
    display: 'block',
  },
  chartWrapper: {
    width: '100%',
    marginBottom: '2rem',
    marginLeft: 35,
  },
})

const minBarHeightInPx = 5

const MemoryChart = ({ ...props }) => {
  const { vaultPlan } = props

  const classes = useStyles()
  const svgChartRef = useRef()
  const wrapperRef = useRef()
  const dimensions = useResizeObserver(wrapperRef)
  const vaultUsage = useSelector(state => state.vault.vaultUsage)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  const currentDay = moment()
    .tz(timezone)
    .date()
  const maxUsedMemoryValue = max(vaultUsage)

  const STRATEGY_PLAN = {
    free: () => getValuesYAxisFreePlan(maxUsedMemoryValue),
    premium: () => getValuesYAxiPremiumPlan(maxUsedMemoryValue),
  }

  useEffect(() => STRATEGY_PLAN[vaultPlan](), [])

  useEffect(() => {
    const chart = d3.select(svgChartRef.current)

    if (!dimensions) return
    let koefShiftingTooltip
    if (dimensions.width > 1500) {
      koefShiftingTooltip = 1.65
    } else if (dimensions.width > 1400 && dimensions.width < 1500) {
      koefShiftingTooltip = 1.45
    } else {
      koefShiftingTooltip = dimensions.width / 1000 - 0.2
    }

    chart.selectAll('*').remove()

    chart.append('g').attr('class', 'x-axis')
    chart.append('g').attr('class', 'y-axis')
    const subAxis = chart.append('g').attr('class', 'sub-axis')
    const chartContent = chart.append('g').attr('class', 'chart-content')
    const tooltips = chart.append('g').attr('class', 'hint')
    const paddingTooltip = memoryRate[vaultPlan].domainValue / 20

    const xScale = d3
      .scaleBand()
      .domain(vaultUsage.map((value, index) => index))
      .range([0, dimensions.width])
      .padding(0.5)

    const yScale = d3
      .scaleLinear()
      .domain([0, memoryRate[vaultPlan].domainValue])
      .range([dimensions.height, 0])

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(vaultUsage.length - 1)
      .tickSize(0)
      .tickPadding(15)
      .tickFormat(tick => {
        if (tick < 9) {
          return `0${tick + 1}`
        } else {
          return tick + 1
        }
      })

    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(0)
      .tickPadding(10)
      .tickValues(memoryRate[vaultPlan].ticksValue)
      .tickFormat(d3.format(memoryRate[vaultPlan].formatValue))

    chart
      .select('.x-axis')
      .style('transform', `translateY(${dimensions.height}px)`)
      .call(xAxis)

    chart
      .selectAll('.x-axis > .tick > text')
      .attr('fill', colors.BASIC.COLOR[70])
      .attr('font-family', 'Roboto')
      .attr('font-size', '13px')
      .attr('font-weight', 'normal')

    chart.selectAll('.x-axis > .tick').each(function(d, i) {
      if (currentDay === i + 1) {
        d3.select(this)
          .select('text')
          .attr('fill', colors.PRIMARY.COLOR[50])
          .attr('font-weight', 'bold')

        d3.select(this)
          .insert('rect', ':first-child')
          .attr('width', 27)
          .attr('height', 27)
          .attr('fill', colors.PRIMARY.COLOR[5])
          .attr('rx', 4)
          .attr('transform', 'translate(-13, 6)')
      }
    })

    chart
      .select('.y-axis')
      .style('transform', 'translateX(0px)')
      .call(yAxis)

    chart.select('.y-axis > .domain').attr('stroke', colors.BASIC.COLOR[10])

    chart
      .selectAll('.y-axis > .tick > text')
      .attr('fill', colors.BASIC.COLOR[50])
      .attr('font-family', 'Roboto')
      .attr('font-size', '13px')
      .attr('font-weight', 'normal')

    const yAxisSub = d3
      .axisRight(yScale)
      .tickSize(dimensions.width)
      .tickValues(memoryRate[vaultPlan].ticksValue)

    const customXAxis = () => {
      g.call(yAxisSub)
      g.select('.domain').remove()
      g.selectAll('.tick:not(:first-of-type) line')
        .attr('stroke', colors.BASIC.COLOR[10])
        .attr('stroke-dasharray', '2,2')
      g.selectAll('.tick:nth-of-type(2) line')
        .attr('stroke', colors.BASIC.COLOR[10])
        .attr('stroke-dasharray', '0')
      g.selectAll('.tick text').remove()
    }

    const g = subAxis.append('g').attr('transform', 'translate(0,0)')
    g.append('g').call(customXAxis)

    chartContent
      .selectAll('.bar')
      .data(vaultUsage)
      .join('rect')
      .attr('class', 'bar')
      .attr('cursor', 'pointer')
      .style('transform', 'scale(1, -1)')
      .attr('x', (value, index) => xScale(index))
      .attr('y', -286)
      .attr('width', xScale.bandwidth())
      .on('mouseenter', (value, index) => {
        tooltips
          .selectAll('.tooltip-wrap')
          .data([value])
          .join(enter =>
            enter
              .append('rect')
              .attr('width', 57)
              .attr('height', 27)
              .attr('fill', colors.BASIC.COLOR[80])
              .attr('rx', 2)
              .attr('y', yScale(value) - 4)
          )
          .attr('class', 'tooltip-wrap')
          .attr('x', xScale(index) - xScale.bandwidth() / koefShiftingTooltip)
          .attr('y', () => {
            if (286 - yScale(value) <= 1) {
              return yScale(paddingTooltip) - 40
            } else {
              return yScale(value) - 35
            }
          })
          .attr('opacity', 1)

        tooltips
          .selectAll('.tooltip')
          .data([value])
          .join(enter =>
            enter
              .append('text')
              .attr('fill', colors.WHITE)
              .attr('font-family', 'Roboto')
              .attr('font-size', '13px')
              .attr('font-weight', 'normal')
              .attr('y', yScale(value) - 4)
          )
          .attr('class', 'tooltip')
          .text(`${value} GB`)
          .attr('x', xScale(index) + xScale.bandwidth() / 2)
          .attr('text-anchor', 'middle')
          .attr('y', () => {
            if (286 - yScale(value) <= 1) {
              return yScale(paddingTooltip) - 21
            } else {
              return yScale(value) - 16
            }
          })
          .attr('opacity', 1)
      })
      .on('mouseleave', () => {
        chart.select('.tooltip-wrap').remove()
        chart.select('.tooltip').remove()
      })
      .transition()
      .attr('fill', colors.ADDITIONAL.COLOR[50])
      .attr('height', value => {
        if (value === 0) {
          return 300 - yScale(value)
        } else if (286 - yScale(value) <= 1) {
          return minBarHeightInPx
        } else {
          return 286 - yScale(value)
        }
      })
      .attr('rx', 3)
  }, [currentDay, vaultUsage, dimensions, vaultPlan])

  return (
    <div ref={wrapperRef} className={classes.chartWrapper}>
      <svg ref={svgChartRef} className={classes.svgChart} />
    </div>
  )
}

export default MemoryChart
