import React from 'react'
import { XIRACalendarList, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  subtitle: {
    fontSize: 15,
    marginBottom: 25,
  },
})
const XIRACalendarSettings = () => {
  const classes = useStyles()
  return (
    <div>
      <Typography variant="h4">set XIRA calendar</Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Select the calendar where XIRA bookings should be saved
      </Typography>
      <XIRACalendarList />
    </div>
  )
}

export default XIRACalendarSettings
