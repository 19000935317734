import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { Typography, Button } from 'components'

const useStyles = makeStyles({
  title: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
  },
  company: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[80],
    fontSize: 13,
  },
  bodyText: {
    fontSize: 13,
    color: colors.BASIC.COLOR[50],
  },
  titleContainer: {
    display: 'flex',
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
    justifyContent: 'space-between',
    padding: 11,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  list: {
    padding: 16,
    margin: 0,
  },
  bodyContainer: {
    padding: 16,
    flexGrow: 1,
    backgroundColor: colors.WHITE,
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  icon: {
    fontSize: 20,
  },
  emptyTitle: {
    fontSize: 15,
  },
  listItem: {
    marginBottom: 7,
  },
  subContainer: {
    display: 'flex',
  },
  reconnectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    fontSize: 13,
    color: colors.ERROR,
  },
  reconnectBtn: {
    minHeight: 32,
    height: 32,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: colors.WHITE,
    paddingRight: 16,
  },
})

const ViewCalendarCardBody = ({ calendar, onCardClick, calendarsConnectionError, onReconnectClick }) => {
  const classes = useStyles()
  const calendarSettings = CONSTANTS.CALENDAR_SETTINGS[calendar.provider]
  const calendarList = calendar.calendars.filter(calendar => calendar.selected)
  return (
    <>
      <div className={classes.titleContainer}>
        <div className={classes.subContainer}>
          <div>{calendarSettings.calendarLogo}</div>
          <div className={classes.columnContainer}>
            <Typography variant="body1" className={classes.company}>
              {calendarSettings.title}
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              {calendar.username}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bodyContainer}>
        {calendarList.length ? (
          <>
            <Typography variant="subtitle2" className={classes.bodyText}>
              check for conflicts
            </Typography>
            <ul className={classes.list}>
              {calendarList.map(calendar => (
                <li className={classes.listItem} key={calendar.name}>
                  {calendar.name}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <Typography variant="subtitle1" className={classes.emptyTitle}>
            Do not check this calendar for conflicts
          </Typography>
        )}
      </div>
      {calendarsConnectionError ? (
        <div className={classes.reconnectContainer}>
          <div>You are no longer visible to clients due to calendar being disconnected</div>
          <Button variant="primary" onClick={() => onReconnectClick({ open: true })} className={classes.reconnectBtn}>
            reconnect
          </Button>
        </div>
      ) : (
        <div className={classes.btnContainer}>
          <Button variant="text" onClick={onCardClick}>
            edit
          </Button>
        </div>
      )}
    </>
  )
}

export default ViewCalendarCardBody
