import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link as RouterLink } from 'react-router-dom'
import { colors, CONSTANTS, routes } from 'constants/index'
import {
  Breadcrumbs,
  Button,
  CaseConfigurationForm,
  ColorCircularProgress,
  ConfirmationModal,
  ProActivityEntryModal,
  Typography,
  ErrorModal,
} from 'components'
import { isEmpty } from 'lodash'
import { navigateToCaseList, redirectToCaseDetails } from 'store/actions/navigation'
import { getProCaseFullInfo, getProSpecificPracticeAreas, makeCaseInactive, updateProCases } from 'store/actions/cases'
import { validateCaseEditForm } from 'utils/validators'
import { getUserConnectionsForFilter } from 'store/actions/connections'
import MakeInactiveForbiddenMsg from 'components/cases/MakeInactiveForbiddenMsg'
import { closeErrorModal } from 'store/actions/common'
import CaseInactivationErrorModal from './CaseInactivationErrorModal'

const useStyles = makeStyles({
  root: {
    padding: '24px 36px',
  },
  link: {
    color: colors.BASIC.COLOR[20],
    textDecoration: 'none',
  },
  standardMargin: {
    marginBottom: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    padding: '5px 24px',
    minHeight: 40,
    height: 40,
    marginLeft: 16,
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
})

const ProEditCaseContainer = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const caseFullInfoIsLoading = useSelector(state => state.cases.caseFullInfoIsLoading)
  const caseFullInfo = useSelector(state => state.cases.caseFullInfo)
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const [caseData, setCaseData] = useState(caseFullInfo)
  const [errors, setErrors] = useState({})
  const [confirmationModalShow, setConfirmationModalShow] = useState(false)
  const [showCancellationError, setShowCancellationError] = useState(false)
  const [showActivityModal, setActivityModal] = useState({
    open: false,
    action: null,
    type: null,
    activity: {},
  })
  const isInitial = caseData && caseData.fee_type === CONSTANTS.FEE_TYPES['0'].key
  const { title: titleErrorModal, isOpen: isOpenErrorModal } = useSelector(state => state.common.errorModal)

  useEffect(() => {
    dispatch(getUserConnectionsForFilter())
    dispatch(getProSpecificPracticeAreas())
  }, [])

  useEffect(() => {
    dispatch(getProCaseFullInfo(props.match.params.caseId))
  }, [props.match.params.caseId])

  useEffect(() => {
    setCaseData(caseFullInfo)
  }, [caseFullInfo])

  const onChange = value => {
    setCaseData({
      ...caseData,
      ...value,
    })
  }

  const changeErrors = newErrors => {
    setErrors({
      ...errors,
      ...newErrors,
    })
  }

  const validateForm = () => {
    const newErrors = validateCaseEditForm(caseData)
    setErrors(newErrors)
    return isEmpty(newErrors)
  }

  const onMakeCaseInactiveConfirm = async () => {
    try {
      await dispatch(makeCaseInactive(caseData.uuid, caseData.name))
      dispatch(redirectToCaseDetails(caseData.uuid))
    } catch (e) {
      console.error(e)
    }
  }

  const onMakeInactivePress = () => {
    if (caseData.can_inactivate && caseData.reason_for_disable_inactivate) {
      setShowCancellationError(true)
    } else if (!caseData.can_inactivate) {
      setShowCancellationError(true)
    } else {
      setConfirmationModalShow(true)
    }
  }

  const onSavePress = async () => {
    if (!validateForm()) {
      return
    }

    try {
      await dispatch(updateProCases(caseData.uuid, caseData))
      setShowCancellationError(false)
      dispatch(navigateToCaseList())
    } catch (e) {
      console.error(e)
    }
  }

  const onNavigateToActivity = type => {
    setActivityModal(config => ({
      ...config,
      open: true,
      action: 'create',
      type: type,
      activity: { caseId: caseData.uuid },
    }))
  }

  const onActivityModalClose = () => {
    setActivityModal({
      open: false,
      type: null,
      action: null,
      activity: {},
    })
  }

  const onErrorModalClose = () => {
    dispatch(closeErrorModal())
    setConfirmationModalShow(false)
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs className={classes.standardMargin}>
        <RouterLink className={classes.link} to={routes.getProAccountCasesPath()}>
          cases
        </RouterLink>
        <Typography variant="body1">{caseData.name}</Typography>
      </Breadcrumbs>
      {caseFullInfoIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <div className={classes.pageHeader}>
            <Typography variant="h3" className={classes.standardMargin}>
              View / Edit case
            </Typography>
            <div>
              {profileIsApproved && caseData.is_able_to_create_time_entry && (
                <Button variant="secondary" className={classes.button} onClick={() => onNavigateToActivity('time')}>
                  new time entry
                </Button>
              )}
              {profileIsApproved && caseData.is_able_to_create_time_expense_entry && (
                <Button variant="secondary" className={classes.button} onClick={() => onNavigateToActivity('expense')}>
                  new expense entry
                </Button>
              )}
            </div>
          </div>
          <CaseConfigurationForm
            caseData={caseData}
            mode={CONSTANTS.MODE.EDIT}
            onChange={onChange}
            errors={errors}
            setErrors={changeErrors}
          />
          <div className={classes.buttonGroup}>
            <Button variant="text" onClick={onMakeInactivePress} disabled={!profileIsApproved}>
              make inactive
            </Button>
            {!isInitial && (
              <div>
                <Button variant="text" component={RouterLink} to={routes.getProAccountCasesPath()}>
                  cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={onSavePress}
                  className={classes.button}
                  disabled={!profileIsApproved}
                >
                  save
                </Button>
              </div>
            )}
          </div>
          <ConfirmationModal
            isOpen={confirmationModalShow}
            message={`If you make the case ${caseData.name} inactive, you won't be able to create activities for it and no invoice will be generated. Continue?`}
            onClose={() => setConfirmationModalShow(false)}
            title="Make case inactive?"
            onConfirm={onMakeCaseInactiveConfirm}
            confirmationButtonText="yes, make inactive"
            cancelationButtonText="cancel"
          />
          <ProActivityEntryModal
            open={showActivityModal.open}
            type={showActivityModal.type}
            action={showActivityModal.action}
            activity={showActivityModal.activity}
            handleClose={onActivityModalClose}
          />
          <ErrorModal
            variant="primary"
            isOpen={isOpenErrorModal}
            onClose={onErrorModalClose}
            title={titleErrorModal}
            message={<MakeInactiveForbiddenMsg />}
            cancelationButtonText="ok"
          />
          <CaseInactivationErrorModal
            code={caseData.reason_for_disable_inactivate}
            isOpen={showCancellationError}
            onClose={() => setShowCancellationError(false)}
            title={caseData.can_inactivate ? 'Make case inactive?' : 'You can not make the case inactive'}
            confirmButtonText={'yes, make inactive'}
            cancellationButtonText={caseData.can_inactivate ? 'cancel' : 'close'}
            cancelButtonVariant={caseData.can_inactivate ? 'secondary' : 'primary'}
            onConfirm={caseData.can_inactivate ? onMakeCaseInactiveConfirm : null}
          />
        </>
      )}
    </div>
  )
}

export default ProEditCaseContainer
