import React, { useEffect, useState } from 'react'
import {
  Modal,
  Typography,
  Button,
  InputLabel,
  TextField,
  Hint,
  Icon,
  ColorCircularProgress,
  ErrorModal,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getUserEventDetails, cancelUserMeeting } from 'store/actions/events'
import { getMeetingDay, getMeetingDuration, getMeetingRange } from 'utils/getters/events'
import { Link } from 'react-router-dom'
import { closeErrorModal, goToZendesk } from 'store/actions/common'
import RestrictionMsg from 'components/events/RestrictionMsg'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'

const useStyles = makeStyles({
  root: {
    width: 568,
    minHeight: 364,
    position: 'relative',
    paddingBottom: 24,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  meetingTimeSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    width: '100%',
    padding: '0 40px',
  },
  meetingTimeLabel: {
    fontSize: 16,
    fontWeight: 500,
  },
  meetingTime: {
    textDecoration: 'line-through',
  },
  title: {
    marginTop: 24,
    marginBottom: 27,
    width: '100%',
    padding: '0 40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  reasonSection: {
    width: '100%',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    padding: '24px 40px',
    marginBottom: 16,
  },
  tooltipTextContainer: {
    width: '100%',
    padding: '0 40px',
    marginBottom: 24,
  },
  buttonGroup: {
    width: '100%',
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    height: 40,
    padding: '5px 24px',
    marginLeft: 10,
    minHeight: 40,
  },
  hint: {
    color: colors.ERROR,
    marginTop: 4,
  },
  notFound: {
    position: 'absolute',
    top: 164,
    padding: '0 40px',
    textAlign: 'center',
  },
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
})

const CancelEventModal = props => {
  const classes = useStyles()
  const { onModalClose, eventId, open = true, onModalConfirm } = props
  const dispatch = useDispatch()
  const eventInfoIsLoading = useSelector(store => store.events.meetingInfoIsLoading)
  const eventInfo = useSelector(store => store.events.meetingInfo)
  const { title: titleErrorMOdal, detail: detailErrorModal, isOpen: isOpenErrorModal } = useSelector(
    state => state.common.errorModal
  )
  const [reason, setReason] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    setReason('')
    setError(false)
  }, [open])

  useEffect(() => {
    dispatch(getUserEventDetails(eventId))
  }, [eventId])

  const onConfirm = async () => {
    if (!reason) {
      setError(true)
      return
    }
    setError(false)

    try {
      await dispatch(cancelUserMeeting(eventInfo.uuid, reason))
      onModalConfirm()
    } catch (e) {
      console.error(e)
    }
  }

  const onErrorModalClose = () => {
    dispatch(closeErrorModal())
    onModalClose()
  }

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  return (
    <>
      <Modal open={open && !isOpenErrorModal} maxWidth={false} handleClose={onModalClose}>
        <div className={classes.root}>
          {eventInfoIsLoading ? (
            <ColorCircularProgress />
          ) : (
            <>
              {!eventInfo.isUpdateButtonVisible ? (
                <Typography variant="h4" className={classes.notFound}>
                  Action is not available because the meeting was canceled or is over
                </Typography>
              ) : (
                eventInfo.proInfo &&
                eventInfo.startTime &&
                eventInfo.endTime && (
                  <div className={classes.content}>
                    <Typography variant="h4" className={classes.title}>
                      cancel a meeting with {`${eventInfo.proInfo.firstName} ${eventInfo.proInfo.lastName}`}
                    </Typography>
                    <div className={classes.meetingTimeSection}>
                      <Typography variant="body1" className={classes.meetingTimeLabel}>
                        scheduled date & time
                      </Typography>
                      <Typography variant="body2" className={classes.meetingTime}>
                        {getMeetingDay(eventInfo.startTime)} {getMeetingRange(eventInfo.startTime, eventInfo.endTime)}{' '}
                        &bull; {getMeetingDuration(eventInfo.startTime, eventInfo.endTime)}
                      </Typography>
                    </div>
                    <div className={classes.reasonSection}>
                      <InputLabel variant="body1">reason for cancellation*</InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                        inputProps={{
                          maxLength: 150,
                        }}
                      />
                      {error && (
                        <Hint classes={{ subtitle2: classes.hint }}>
                          <Icon iconClass="las las la-exclamation-triangle" />
                          Please provide reason for cancellation
                        </Hint>
                      )}
                    </div>
                    <div className={classes.tooltipTextContainer}>
                      <Typography className={classes.tooltipText} variant="subtitle1">
                        <Link to="/client/booking-policy" target="_blank" className={classes.link}>
                          XIRA&apos;s booking policy
                        </Link>
                      </Typography>
                    </div>

                    <div className={classes.buttonGroup}>
                      <Button variant="text" onClick={onModalClose}>
                        cancel
                      </Button>
                      <Button variant="primary" onClick={onConfirm} className={classes.confirmButton}>
                        confirm
                      </Button>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </Modal>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onErrorModalClose}
        title={titleErrorMOdal}
        message={<RestrictionMsg goToZendesk={zendeskSso} message={detailErrorModal} />}
        cancelationButtonText="ok"
      />
    </>
  )
}

export default CancelEventModal
