import React, { useState } from 'react'
import {
  MeetingSchedulerContactInfoForm,
  Button,
  MeetingSchedulerPayment,
  MeetingSchedulerConfirmation,
  ConfirmationModal,
} from 'components'
import { colors } from 'constants/colors'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { navigateTo } from '../../store/actions/navigation'
import MeetingSchedulerLegalTopic from './MeetingSchedulerLegalTopic'

const useStyles = makeStyles({
  meetingSchedulerBody: {
    padding: '24px 26px',
    width: '100%',
    maxWidth: 940,
  },
  resetBtn: {
    color: colors.PRIMARY.COLOR[50],
    alignSelf: 'flex-start',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '@media(max-width:767px)': {
    meetingSchedulerBody: {
      padding: '20px 15px 30px',
      margin: '0 auto',
      maxWidth: 400,
    },
  },
})

const MeetingSchedulerBodyBuilder = ({
  activeStep,
  countries,
  states,
  contactInfo,
  updateContactInfo,
  setActiveStep,
  onCancel,
  setCompletedStep,
  proAccount,
  practiceArea,
  start,
  end,
  userCards,
  paymentCard,
  setPaymentCard,
  onSubmit,
  isSubmitMeetingData,
  stripeError,
  cancellationFee,
  initialPrice,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clientPaymentSecretKey = useSelector(state => state.payments.clientPaymentSecretKey)
  const clientSecretIsLoading = useSelector(state => state.payments.clientSecretIsLoading)

  const [isConfirmationModalShown, setIsConfirmationModalShown] = useState(false)

  const CancelButton = ({ disabled }) => (
    <Button
      type="reset"
      className={classes.resetBtn}
      onClick={() => setIsConfirmationModalShown(true)}
      variant="text"
      disabled={disabled}
    >
      cancel
    </Button>
  )
  const onBack = step => {
    setActiveStep(step - 1)
  }
  const onContinue = step => {
    setActiveStep(step + 1)
    setCompletedStep(prevState => [...prevState, step])
  }
  const onConfirmationModalClose = () => {
    setIsConfirmationModalShown(false)
  }
  const onConfirmationModalConfirm = () => {
    onCancel()
    setIsConfirmationModalShown(false)
  }

  const onTopicChange = v => {
    if (!v) return
    dispatch(navigateTo(`/client/schedule/${proAccount.id}?start=${start}&end=${end}&area=${v.name}`))
  }

  const getBody = step => {
    switch (step) {
      case 0:
        return (
          <MeetingSchedulerLegalTopic
            onContinue={() => onContinue(0)}
            cancelButton={<CancelButton />}
            onTopicChange={onTopicChange}
          />
        )
      case 1:
        return (
          <MeetingSchedulerContactInfoForm
            onTopicChange={onTopicChange}
            countries={countries}
            states={states}
            updateContactInfo={updateContactInfo}
            contactInfo={contactInfo}
            onBack={() => onBack(1)}
            onContinue={() => onContinue(1)}
            cancelButton={<CancelButton />}
          />
        )
      case 2:
        return (
          <MeetingSchedulerPayment
            onBack={() => onBack(2)}
            onContinue={() => onContinue(2)}
            userCards={userCards}
            paymentCard={paymentCard}
            setPaymentCard={setPaymentCard}
            clientPaymentSecretKey={clientPaymentSecretKey}
            clientSecretIsLoading={clientSecretIsLoading}
            cancelButton={<CancelButton />}
          />
        )
      case 3:
        return (
          <MeetingSchedulerConfirmation
            proAccount={proAccount}
            start={start}
            practiceArea={practiceArea}
            userCards={userCards}
            paymentCard={paymentCard}
            setPaymentCard={setPaymentCard}
            onSubmit={() => onSubmit()}
            clientPaymentSecretKey={clientPaymentSecretKey}
            clientSecretIsLoading={clientSecretIsLoading}
            onBack={() => onBack(3)}
            cancelButton={<CancelButton disabled={isSubmitMeetingData} />}
            isSubmitMeetingData={isSubmitMeetingData}
            stripeError={stripeError}
            cancellationFee={cancellationFee}
            initialPrice={initialPrice}
          />
        )
      default:
        return <div />
    }
  }
  return (
    <>
      <div className={classes.meetingSchedulerBody}>
        {initialPrice ? (
          getBody(activeStep)
        ) : (
          <MeetingSchedulerContactInfoForm
            showTopicSelect
            onTopicChange={onTopicChange}
            countries={countries}
            states={states}
            updateContactInfo={updateContactInfo}
            contactInfo={contactInfo}
            onSubmit={onSubmit}
            cancelButton={<CancelButton />}
          />
        )}
      </div>
      <ConfirmationModal
        title="Cancel booking process"
        message="If you cancel the process consultation won't be booked."
        confirmationButtonText="Yes"
        cancelationButtonText="No"
        isOpen={isConfirmationModalShown}
        onClose={() => onConfirmationModalClose()}
        onConfirm={() => onConfirmationModalConfirm()}
      />
    </>
  )
}

export default MeetingSchedulerBodyBuilder
