import * as CONSTANTS from 'store/constants/meeting'
import axios from 'axios'
import { getMeetingsPath } from 'utils/path-helpers/api'
import { mapMeetingsToFront } from 'utils/mappers/backend'
import { showNotFound, hideNotFound } from 'store/actions/common'

export const showMeetingLoader = () => ({
  type: CONSTANTS.SHOW_MEETING_LOADER,
})

export const hideMeetingLoader = () => ({
  type: CONSTANTS.HIDE_MEETING_LOADER,
})

export const getMeetingInfoStart = () => ({
  type: CONSTANTS.GET_MEETING_INFO_START,
})

export const getMeetingInfoEnd = payload => ({
  type: CONSTANTS.GET_MEETING_INFO_END,
  payload,
})

export const getMeetingInfo = (meetingId, showLoader = true) => dispatch => {
  dispatch(getMeetingInfoStart())
  if (showLoader) {
    dispatch(showMeetingLoader())
  }
  return axios
    .get(getMeetingsPath(meetingId))
    .then(response => {
      const data = mapMeetingsToFront(response.data)
      dispatch(hideNotFound())
      dispatch(getMeetingInfoEnd(data))
    })
    .catch(e => {
      dispatch(getMeetingInfoEnd({}))
      if (e.response && e.response.status === 404) {
        dispatch(showNotFound())
      }
      return Promise.reject(e)
    })
    .finally(() => {
      dispatch(hideMeetingLoader())
    })
}
