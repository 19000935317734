import React, { useState } from 'react'
import { Icon, Button, DeleteModal, VideoPlayer } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { useDispatch } from 'react-redux'
import { deleteVideo } from 'store/actions/accountProfile'

const useStyles = makeStyles({
  iconBtn: {
    color: colors.PRIMARY.COLOR[50],
    borderRadius: 4,
    backgroundColor: 'transparent',
    marginBottom: 5,
  },
  iconStyle: {
    fontSize: 20,
  },
  previewVideo: {
    width: '100%',
    height: 'auto',
    minHeight: 176,
    margin: '10px 0',
  },
})

const DELETE_BUTTON_TEXT = 'DELETE VIDEO'
const VIDEO_TEXT = 'Video'

const VideoPreview = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { video, resetVideoUrl } = props
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleDeleteVideo = async () => {
    setIsButtonDisabled(true)
    await dispatch(deleteVideo())
    setOpenDeleteModal(false)
    setIsButtonDisabled(false)
    resetVideoUrl()
  }

  const mountDeleteDialog = () => (
    <DeleteModal
      isOpen={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      deleteTitle={VIDEO_TEXT}
      onDelete={handleDeleteVideo}
      deleteText={VIDEO_TEXT}
      buttonDisabled={isButtonDisabled}
    />
  )

  return (
    <div className={classes.previewVideoContainer}>
      <div className={classes.previewVideo}>
        <VideoPlayer url={video} controls width="100%" height="100%" />
      </div>
      <Button
        classes={{ root: classes.iconBtn }}
        variant="text"
        onClick={() => setOpenDeleteModal(true)}
        startIcon={<Icon style={classes.iconStyle} iconClass="las la-trash-alt" />}
      >
        {DELETE_BUTTON_TEXT}
      </Button>
      {mountDeleteDialog()}
    </div>
  )
}

export default VideoPreview
