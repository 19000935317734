import { withStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { LinearProgress } from '@material-ui/core'

const CustomLinearProgress = withStyles({
  root: {
    backgroundColor: colors.BASIC.COLOR[10],
  },
  bar: {
    backgroundColor: colors.PRIMARY.COLOR[50],
  },
})(LinearProgress)

export default CustomLinearProgress
