import React from 'react'
import { colors, CONSTANTS } from 'constants/index'
import { Link, Typography } from 'components'
import { navigateTo, navigateToNewWindow } from 'store/actions/navigation'
import { ReactComponent as MessageLogo } from 'static/notifications/message.svg'
import { ReactComponent as FolderLogo } from 'static/notifications/folder.svg'
import { ReactComponent as AdminLogo } from 'static/notifications/admin_notification.svg'
import { ReactComponent as ReviewLogo } from 'static/notifications/review_notification.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { markNotificationAsRead } from 'store/actions/notifications/notifications'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { showAdminMessageModal } from 'store/actions/common'

export const dispatchNotificationAction = (notification, closePopover) => async (dispatch, getState) => {
  const isVaultModalOpen = getState().vault.isVaultModalOpen
  let action

  if (notification.unread) {
    await dispatch(markNotificationAsRead(notification.uuid, true, notification.type))
  }

  if (closePopover) {
    closePopover()
  }

  if (isVaultModalOpen) {
    action = navigateToNewWindow
  } else {
    action = navigateTo
  }

  switch (notification.type) {
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_FILE:
      return dispatch(action(`/pro/account/vault?file=${notification.data.vault_asset_uuid}`))
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_MESSAGE:
      return dispatch(action(`/pro/account/messaging?chatId=${notification.data.chat_id}`))
    case CONSTANTS.NOTIFICATION_TYPES.PRO_UPGRADE_STORAGE:
      return dispatch(action('/pro/account/settings/vault-settings'))
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_MESSAGE:
      return dispatch(action(`/client/account/connections?chatId=${notification.data.chat_id}`))
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_FILE:
      return dispatch(
        action(
          `/client/account/connections?file=${notification.data.vault_asset_uuid}&proID=${notification.data.pro_uuid}&proName=${notification.data.full_name}`
        )
      )
    case CONSTANTS.NOTIFICATION_TYPES.ADMIN_MESSAGE:
      dispatch(showAdminMessageModal(notification.data))
      return () => {}
    case CONSTANTS.NOTIFICATION_TYPES.INITIAL_QUESTIONNAIRE:
      Analytics.track(EVENTS.CLIENT_CLICK_FEEDBACK, { id: notification.data.questionnaire_uuid })
      return dispatch(action(`/client/rating/${notification.data.questionnaire_uuid}`))
    case CONSTANTS.NOTIFICATION_TYPES.INVOICE_QUESTIONNAIRE:
      Analytics.track(EVENTS.CLIENT_CLICK_FEEDBACK, { id: notification.data.questionnaire_uuid })
      return dispatch(action(`/client/rating/${notification.data.questionnaire_uuid}`))
    case CONSTANTS.NOTIFICATION_TYPES.CASE_QUESTIONNAIRE:
      Analytics.track(EVENTS.CLIENT_CLICK_FEEDBACK, { id: notification.data.questionnaire_uuid })
      return dispatch(action(`/client/rating/${notification.data.questionnaire_uuid}`))
    case CONSTANTS.NOTIFICATION_TYPES.NEW_APPROVED_COMMENT:
      return dispatch(action(`/pro/account/profile/general?comment_uuid=${notification.data.comment_uuid}`))
    case CONSTANTS.NOTIFICATION_TYPES.NEW_COMMENT_REPLY:
      return dispatch(action(`/search/${notification.data.lawyer_uuid}?comment_uuid=${notification.data.comment_uuid}`))
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_FILES:
      if (notification.data.vault_asset_uuid) {
        return dispatch(action(`/pro/account/vault?file=${notification.data.vault_asset_uuid}`))
      }
      return null
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_FILES:
      if (notification.data.vault_asset_uuid) {
        return dispatch(
          action(
            `/client/account/connections?file=${notification.data.vault_asset_uuid}&proID=${notification.data.pro_uuid}&proName=${notification.data.full_name}`
          )
        )
      }
      return null
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_INVOICE_REMINDER:
      if (notification.data.invoice_number) {
        return dispatch(action(`/pro/account/finances/invoices/view/${notification.data.invoice_id}`))
      }
      return null
    default:
      return null
  }
}

export const getNotificationIcon = type => {
  switch (type) {
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_FILE:
    case CONSTANTS.NOTIFICATION_TYPES.PRO_UPGRADE_STORAGE:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_FILE:
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_FILES:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_FILES:
      return <FolderLogo />
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_MESSAGE:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_MESSAGE:
      return <MessageLogo />
    case CONSTANTS.NOTIFICATION_TYPES.INITIAL_QUESTIONNAIRE:
    case CONSTANTS.NOTIFICATION_TYPES.INVOICE_QUESTIONNAIRE:
    case CONSTANTS.NOTIFICATION_TYPES.CASE_QUESTIONNAIRE:
    case CONSTANTS.NOTIFICATION_TYPES.NEW_APPROVED_COMMENT:
    case CONSTANTS.NOTIFICATION_TYPES.NEW_COMMENT_REPLY:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_INVOICE_REMINDER:
      return <ReviewLogo />
    case CONSTANTS.NOTIFICATION_TYPES.ADMIN_MESSAGE:
      return <AdminLogo />
    default:
      return null
  }
}

const useStyles = makeStyles({
  mainText: {
    color: 'inherit',
    fontSize: 15,
    fontWeight: 'normal',
  },
  link: {
    color: 'inherit',
    fontSize: 15,
    fontWeight: 500,
    '&:visited': {
      color: 'inherit',
    },
    '&:hover': {
      color: colors.PRIMARY.COLOR['50'],
    },
  },
  secondaryText: {
    color: 'inherit',
    fontSize: 13,
    fontWeight: 'normal',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
  },
  fileString: {
    color: 'inherit',
    fontWeight: 'normal',
    fontSize: 13,
  },
  fileLink: {
    color: 'inherit',
    fontWeight: 500,
    fontSize: 13,
    wordBreak: 'break-all',
  },
  secondaryTextAdmin: {
    color: 'inherit',
    fontSize: 13,
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 250,
  },
})

export const BaseNotificationBody = props => {
  const { notification, closePopover } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClick = e => {
    e.stopPropagation()
    dispatch(dispatchNotificationAction(notification))
    if (closePopover) {
      closePopover()
    }
  }

  switch (notification.type) {
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_FILE:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_FILE:
      return <NewFile notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_FILES:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_FILES:
      return <NewFiles notification={notification} classes={classes} closePopover={closePopover} />
    case CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_MESSAGE:
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_MESSAGE:
      return <NewMessage notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.PRO_UPGRADE_STORAGE:
      return <ProUpgradePlan notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.ADMIN_MESSAGE:
      return <AdminMessage notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.INITIAL_QUESTIONNAIRE:
      return <InitialQuestionnaire notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.INVOICE_QUESTIONNAIRE:
      return <InvoiceQuestionnaire notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.CASE_QUESTIONNAIRE:
      return <CaseQuestionnaire notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.NEW_APPROVED_COMMENT:
      return <NewApprovedComment notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.NEW_COMMENT_REPLY:
      return <NewCommentReply notification={notification} classes={classes} onClick={onClick} />
    case CONSTANTS.NOTIFICATION_TYPES.CLIENT_INVOICE_REMINDER:
      return <ClientInvoiceReminder notification={notification} classes={classes} onClick={onClick} />
    default:
      return null
  }
}

const NewMessage = props => {
  const { notification, classes, onClick } = props

  return (
    <Typography className={classes.mainText}>
      {notification.data.full_name} sent a{' '}
      <Link onClick={onClick} className={classes.link}>
        message
      </Link>{' '}
      to you
    </Typography>
  )
}

const NewFile = props => {
  const { notification, classes, onClick } = props

  return (
    <Typography className={classes.mainText}>
      {notification.data.full_name} uploaded a new file <br />
      <Link onClick={onClick} className={classes.fileLink}>
        {notification.data.title}
      </Link>
    </Typography>
  )
}

const NewFiles = props => {
  const { notification, classes, closePopover } = props
  const dispatch = useDispatch()

  const onClick = (e, uuid) => {
    e.stopPropagation()
    dispatch(
      dispatchNotificationAction(
        { ...notification, data: { ...notification.data, vault_asset_uuid: uuid } },
        closePopover
      )
    )
  }

  return (
    <Typography className={classes.mainText}>
      {notification.data.full_name} uploaded the following files:
      {notification.data.vault_assets &&
        notification.data.vault_assets.map(fileInfo => (
          <>
            <br />
            <Typography className={classes.fileString}>
              <Link onClick={e => onClick(e, fileInfo.vault_asset_uuid)} className={classes.fileLink}>
                {fileInfo.title}
              </Link>{' '}
              to the case folder {fileInfo.case_folder_name}
            </Typography>
          </>
        ))}
    </Typography>
  )
}

const ProUpgradePlan = props => {
  const { notification, classes, onClick } = props

  return (
    <Typography className={classes.mainText}>
      You are at {notification.data.percent_left} % of your free storage space.{' '}
      <Link onClick={onClick} className={classes.link}>
        Upgrade
      </Link>{' '}
      your account today
    </Typography>
  )
}

const AdminMessage = props => {
  const { notification, classes, onClick } = props

  return (
    <div className={classes.bodyWrapper}>
      <Link className={classes.link} onClick={onClick}>
        {notification.data.summary}
      </Link>
      <Typography className={classes.secondaryTextAdmin}>{notification.data.body}</Typography>
    </div>
  )
}

const InitialQuestionnaire = props => {
  const { notification, classes, onClick } = props
  return (
    <Typography className={classes.mainText}>
      Your initial consultation is finished! Please{' '}
      <Link onClick={onClick} className={classes.link}>
        rate
      </Link>{' '}
      your lawyer {notification.data.lawyer_full_name}
    </Typography>
  )
}

const InvoiceQuestionnaire = props => {
  const { notification, classes, onClick } = props
  return (
    <Typography className={classes.mainText}>
      You’ve received your first invoice! Remember to{' '}
      <Link onClick={onClick} className={classes.link}>
        rate
      </Link>{' '}
      your lawyer {notification.data.lawyer_full_name}
    </Typography>
  )
}

const CaseQuestionnaire = props => {
  const { notification, classes, onClick } = props
  return (
    <Typography className={classes.mainText}>
      Your case is completed! It’s time to{' '}
      <Link onClick={onClick} className={classes.link}>
        rate
      </Link>{' '}
      your lawyer {notification.data.lawyer_full_name}
    </Typography>
  )
}

const NewApprovedComment = props => {
  const { notification, classes, onClick } = props
  return (
    <Typography className={classes.mainText}>
      {notification.data.client_full_name} posted a{' '}
      <Link onClick={onClick} className={classes.link}>
        review
      </Link>{' '}
      for you!
    </Typography>
  )
}

const NewCommentReply = props => {
  const { notification, classes, onClick } = props
  return (
    <Typography className={classes.mainText}>
      {notification.data.lawyer_full_name} replied to your{' '}
      <Link onClick={onClick} className={classes.link}>
        review
      </Link>
      !
    </Typography>
  )
}

const ClientInvoiceReminder = props => {
  const { notification, classes, onClick } = props
  return (
    <Typography className={classes.mainText}>
      A reminder was sent to {notification.data.client_full_name} to pay invoice{' '}
      <Link onClick={onClick} className={classes.link}>
        {notification.data.invoice_number}
      </Link>
    </Typography>
  )
}
