import React, { useRef, useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { Icon } from 'components'
import Tooltip from '@material-ui/core/Tooltip'

const useClasses = makeStyles({
  event: {
    flexGrow: 1,
    padding: '3px 6px',
    fontSize: 12,
    whiteSpace: 'nowrap',
    borderRadius: 4,
    overflow: 'hidden',
  },
  eventContainer: {
    border: `1px solid ${colors.PRIMARY.COLOR[50]}`,
    backgroundColor: colors.PRIMARY.COLOR[40],
  },
  eventContainerCanceled: {
    border: `1px solid ${colors.PRIMARY.COLOR[50]}`,
    textDecoration: 'line-through',
    color: colors.PRIMARY.COLOR[50],
  },
  eventSubtitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR[80],
    color: colors.WHITE,
    fontSize: 13,
    fontWeight: 'normal',
  },
  icon: {
    color: colors.WHITE,
    fontSize: 16,
  },
  externalEventContainer: {
    border: `1px solid ${colors.BASIC.COLOR[20]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    color: colors.BASIC.COLOR[20],
  },
  externalEventContainerCanceled: {
    textDecoration: 'line-through',
  },
  name: {
    fontWeight: 500,
  },
})

const ONE_HOUR = 60 * 60 * 1000

const EventIcon = ({ status }) => {
  const classes = useClasses()

  switch (status) {
    case CONSTANTS.EVENT_STATUS.RESCHEDULED_BY_CLIENT:
    case CONSTANTS.EVENT_STATUS.RESCHEDULED_BY_PRO:
      return (
        <Tooltip classes={{ tooltip: classes.tooltip }} title="rescheduled" placement="bottom-end">
          <div>
            <Icon iconClass="las la-exchange-alt" style={classes.icon} />
          </div>
        </Tooltip>
      )
    default:
      return null
  }
}

const Event = props => {
  const classes = useClasses()
  const { eventData, start, end, timezone, isEnd, isStart } = props
  const isLessThanHour = end - start < ONE_HOUR
  const startTime = moment(start).tz(timezone)
  const endTime = moment(end).tz(timezone)
  const startEventTime = eventData.originalStartTime ? moment(eventData.originalStartTime).tz(timezone) : startTime
  const isExternal = eventData.is_external_event
  const isEventCanceled =
    eventData.status === CONSTANTS.EVENT_STATUS.CANCELED_BY_PRO ||
    eventData.status === CONSTANTS.EVENT_STATUS.CANCELED_BY_CLIENT ||
    eventData.status === CONSTANTS.EVENT_STATUS.DELETED_CANCELED_MEETING_BY_CLIENT ||
    eventData.is_cancelled

  const title = isExternal
    ? `${eventData.calendar_name} `
    : `${eventData.clientInfo.firstName} ${eventData.clientInfo.lastName} `

  const meetingDuration = `${startEventTime.format('h:mm')} - ${endTime.format('h:mm a')}`

  const content = (
    <div
      className={classNames({
        [classes.event]: true,
        [classes.eventContainerCanceled]: isEventCanceled && !isExternal,
        [classes.eventContainer]: !isEventCanceled && !isExternal,
        [classes.externalEventContainerCanceled]: isEventCanceled && isExternal,
        [classes.externalEventContainer]: isExternal,
      })}
    >
      <div>
        <span className={classes.name}>{title}</span>
        {(isLessThanHour || (isStart && !isEnd)) && meetingDuration}
      </div>
      {!isLessThanHour && (!isStart || isEnd) && (
        <div className={classes.eventSubtitle}>
          <span>{`${startEventTime.format('h:mm')} - ${endTime.format('h:mm a')}`}</span>
          <EventIcon status={eventData.status} />
        </div>
      )}
    </div>
  )

  return isExternal ? (
    <Tooltip title="busy time" classes={{ tooltip: classes.tooltip }}>
      {content}
    </Tooltip>
  ) : (
    content
  )
}

export default Event
