import { ReactComponent as MicrosoftLogo } from 'static/microsoft_logo.svg'
import { ReactComponent as OutlookLogo } from 'static/ic_office 365.svg'
import { ReactComponent as GoogleLogo } from 'static/google_logo.svg'
import { ReactComponent as GoogleCalendarLogo } from 'static/google_calendar_logo.svg'
import { ReactComponent as AppleLogo } from 'static/ic_apple.svg'
import { ReactComponent as AppleCalendarLogo } from 'static/Apple_Calendar_logo.svg'
import { ReactComponent as DashboardLogo } from 'static/proMenuIcons/disabled/dashboard.svg'
import { ReactComponent as MessageLogo } from 'static/proMenuIcons/messages/message.svg'
import { ReactComponent as VaultLogo } from 'static/proMenuIcons/vault/vault.svg'
import { ReactComponent as CalendarLogo } from 'static/proMenuIcons/calendar/calendar.svg'
import { ReactComponent as ActivitiesLogo } from 'static/proMenuIcons/activities/activities.svg'
import { ReactComponent as ConnectionsLogo } from 'static/proMenuIcons/connections/connections.svg'
import { ReactComponent as CasesLogo } from 'static/proMenuIcons/cases/cases.svg'
import { ReactComponent as FinancesLogo } from 'static/proMenuIcons/finances/finances.svg'
import { ReactComponent as SettingsLogo } from 'static/proMenuIcons/settings/settings.svg'
import { ReactComponent as TimeLogo } from 'static/activities/time.svg'
import { ReactComponent as ExpenseLogo } from 'static/activities/expense.svg'
import { ReactComponent as ProfileIcon } from 'static/icon_24_ person.svg'
import { ReactComponent as PaymentIcon } from 'static/icon_24_ credit-card.svg'
import { ReactComponent as SettingsIcon } from 'static/icon_24_settings-2.svg'
import { ReactComponent as TutorialsIcon } from 'static/icon_24_video.svg'
import { ReactComponent as ProfileLogo } from 'static/proMenuIcons/profile/profile.svg'
import { ReactComponent as Notifications } from 'static/icon_18_bell.svg'
import React from 'react'

export const CONSTANTS = {
  WEBSOCKET_HOST: process.env.NODE_ENV === 'development' ? 'dev.xiralaw.com' : window.location.host,
  DISABLED_MENU_ITEMS: [
    {
      text: 'dashboard',
      icon: <DashboardLogo />,
    },
  ],
  APP_DRAWER_MENU_ITEMS: [
    {
      route: 'events',
      text: 'CALENDAR',
      icon: <CalendarLogo />,
    },
    {
      route: 'activities',
      text: 'ACTIVITIES',
      icon: <ActivitiesLogo />,
    },
    {
      route: 'cases',
      text: 'CASES',
      icon: <CasesLogo />,
    },
    {
      route: 'finances',
      text: 'FINANCES',
      icon: <FinancesLogo />,
      children: [
        {
          route: 'invoices',
          text: 'INVOICES',
        },
        {
          route: 'my-wallet',
          text: 'MY WALLET',
        },
      ],
    },
    {
      text: 'messaging',
      icon: <MessageLogo />,
      route: 'messaging',
    },
    {
      text: 'secure vault',
      icon: <VaultLogo />,
      route: 'vault',
    },
    {
      route: 'connections',
      text: 'CONNECTIONS',
      icon: <ConnectionsLogo />,
    },
    {
      route: 'profile',
      text: 'MY PROFILE',
      icon: <ProfileLogo />,
      children: [
        {
          route: 'general',
          text: 'GENERAL',
        },
        {
          route: 'areas',
          text: 'PRACTICE AREAS',
        },
        {
          route: 'credentials',
          text: 'CREDENTIALS',
        },
        {
          route: 'bio',
          text: 'BIO',
        },
        {
          route: 'social',
          text: 'SOCIAL CIRCLE',
        },
      ],
    },
    {
      route: 'settings',
      text: 'SETTINGS',
      icon: <SettingsLogo />,
      children: [
        {
          route: 'connections',
          text: 'calendar connections',
        },
        {
          route: 'working-hours',
          text: 'Working hours',
        },
        {
          route: 'timezones',
          text: 'advanced calendar settings',
        },
        {
          route: 'payment',
          text: 'payment',
        },
        {
          route: 'vault-settings',
          text: 'secure vault',
        },
        {
          route: 'notifications',
          text: 'notifications',
        },
      ],
    },
    {
      route: 'https://xira.com/p/tutorial/',
      text: 'tutorials',
      icon: <TutorialsIcon />,
      external: true,
    },
  ],
  CLIENT_DRAWER_MENU_ITEMS: [
    {
      route: 'profile',
      text: 'PROFILE',
      icon: <ProfileIcon />,
    },
    {
      route: 'payments',
      text: 'PAYMENTS',
      icon: <PaymentIcon />,
    },
    {
      route: 'notifications',
      text: 'manage notifications',
      icon: <Notifications />,
    },
    {
      route: 'settings',
      text: 'SETTINGS',
      icon: <SettingsIcon />,
    },
  ],
  EVENT_STATUS: {
    CREATED: 0,
    RESCHEDULED_BY_CLIENT: 1,
    RESCHEDULED_BY_PRO: 2,
    CANCELED_BY_CLIENT: 3,
    CANCELED_BY_PRO: 4,
    DELETED_CANCELED_MEETING_BY_CLIENT: 5,
    DELETED_CANCELED_MEETING_BY_PRO: 6,
  },
  MEETING_STATUS: {
    WAITING: 0,
    STARTED: 1,
    DELETED: 2,
  },
  MODE: {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
  },
  INPUT_TYPE: {
    PHONE: 'phone',
    TEXT: 'text',
  },
  AVAILABLE_TIME_FILTER: {
    0: {
      sliderValue: 0,
      valueLabel: 'now',
      timestamp: 0,
      amount: 0,
      unit: 'm',
    },
    1: {
      sliderValue: 1,
      valueLabel: '4 hours',
      timestamp: 14400,
      amount: 4,
      unit: 'h',
    },
    2: {
      sliderValue: 2,
      valueLabel: '8 hours',
      timestamp: 28800,
      amount: 8,
      unit: 'h',
    },
    3: {
      sliderValue: 3,
      valueLabel: '12 hours',
      timestamp: 43200,
      amount: 12,
      unit: 'h',
    },
    4: {
      sliderValue: 4,
      valueLabel: '16 hours',
      timestamp: 57600,
      amount: 16,
      unit: 'h',
    },
    5: {
      sliderValue: 5,
      valueLabel: '24 hours',
      timestamp: 86400,
      amount: 24,
      unit: 'h',
    },
    6: {
      sliderValue: 6,
      valueLabel: '2 days',
      timestamp: 172800,
      amount: 2,
      unit: 'd',
    },
    7: {
      sliderValue: 7,
      valueLabel: '3 days',
      timestamp: 259200,
      amount: 3,
      unit: 'd',
    },
    8: {
      sliderValue: 8,
      valueLabel: '4 days',
      timestamp: 345600,
      amount: 4,
      unit: 'd',
    },
    9: {
      sliderValue: 9,
      valueLabel: '5 days',
      timestamp: 432000,
      amount: 5,
      unit: 'd',
    },
    10: {
      sliderValue: 10,
      valueLabel: '6 days',
      timestamp: 518400,
      amount: 6,
      unit: 'd',
    },
    11: {
      sliderValue: 11,
      valueLabel: '7 days',
      timestamp: 604800,
      amount: 7,
      unit: 'd',
    },
    12: {
      sliderValue: 12,
      valueLabel: '14 days',
      timestamp: 1209600,
      amount: 14,
      unit: 'd',
    },
    13: {
      sliderValue: 13,
      valueLabel: '30 days',
      timestamp: 2592000,
      amount: 30,
      unit: 'd',
    },
  },
  ZIP_CODE_LENGTH: 5,
  CALENDAR_SETTINGS: {
    microsoft: {
      provider: 'microsoft',
      companyLogo: <MicrosoftLogo />,
      calendarLogo: <OutlookLogo />,
      company: 'Microsoft',
      title: 'Office 365 Calendar',
      description: 'Office 365, Outlook.com, live.com, or hotmail calendar',
    },
    google: {
      provider: 'google',
      companyLogo: <GoogleLogo />,
      calendarLogo: <GoogleCalendarLogo />,
      company: 'Google',
      title: 'Google Calendar',
      description: 'Gmail, G Suite',
    },
    apple: {
      provider: 'apple',
      companyLogo: <AppleLogo />,
      calendarLogo: <AppleCalendarLogo />,
      company: 'Apple',
      title: 'iCloud Calendar',
      description: 'Default calendar for all Apple products.',
    },
  },
  DAYS: {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  },
  TIME_SELECTIONS: [
    {
      value: '12:00 AM',
      label: '12:00 am',
    },
    {
      value: '12:30 AM',
      label: '12:30 am',
    },
    {
      value: '01:00 AM',
      label: '1:00 am',
    },
    {
      value: '01:30 AM',
      label: '1:30 am',
    },
    {
      value: '02:00 AM',
      label: '2:00 am',
    },
    {
      value: '02:30 AM',
      label: '2:30 am',
    },
    {
      value: '03:00 AM',
      label: '3:00 am',
    },
    {
      value: '03:30 AM',
      label: '3:30 am',
    },
    {
      value: '04:00 AM',
      label: '4:00 am',
    },
    {
      value: '04:30 AM',
      label: '4:30 am',
    },
    {
      value: '05:00 AM',
      label: '5:00 am',
    },
    {
      value: '05:30 AM',
      label: '5:30 am',
    },
    {
      value: '06:00 AM',
      label: '6:00 am',
    },
    {
      value: '06:30 AM',
      label: '6:30 am',
    },
    {
      value: '07:00 AM',
      label: '7:00 am',
    },
    {
      value: '07:30 AM',
      label: '7:30 am',
    },
    {
      value: '08:00 AM',
      label: '8:00 am',
    },
    {
      value: '08:30 AM',
      label: '8:30 am',
    },
    {
      value: '09:00 AM',
      label: '9:00 am',
    },
    {
      value: '09:30 AM',
      label: '9:30 am',
    },
    {
      value: '10:00 AM',
      label: '10:00 am',
    },
    {
      value: '10:30 AM',
      label: '10:30 am',
    },
    {
      value: '11:00 AM',
      label: '11:00 am',
    },
    {
      value: '11:30 AM',
      label: '11:30 am',
    },
    {
      value: '12:00 PM',
      label: '12:00 pm',
    },
    {
      value: '12:30 PM',
      label: '12:30 pm',
    },
    {
      value: '01:00 PM',
      label: '1:00 pm',
    },
    {
      value: '01:30 PM',
      label: '1:30 pm',
    },
    {
      value: '02:00 PM',
      label: '2:00 pm',
    },
    {
      value: '02:30 PM',
      label: '2:30 pm',
    },
    {
      value: '03:00 PM',
      label: '3:00 pm',
    },
    {
      value: '03:30 PM',
      label: '3:30 pm',
    },
    {
      value: '04:00 PM',
      label: '4:00 pm',
    },
    {
      value: '04:30 PM',
      label: '4:30 pm',
    },
    {
      value: '05:00 PM',
      label: '5:00 pm',
    },
    {
      value: '05:30 PM',
      label: '5:30 pm',
    },
    {
      value: '06:00 PM',
      label: '6:00 pm',
    },
    {
      value: '06:30 PM',
      label: '6:30 pm',
    },
    {
      value: '07:00 PM',
      label: '7:00 pm',
    },
    {
      value: '07:30 PM',
      label: '7:30 pm',
    },
    {
      value: '08:00 PM',
      label: '8:00 pm',
    },
    {
      value: '08:30 PM',
      label: '8:30 pm',
    },
    {
      value: '09:00 PM',
      label: '9:00 pm',
    },
    {
      value: '09:30 PM',
      label: '9:30 pm',
    },
    {
      value: '10:00 PM',
      label: '10:00 pm',
    },
    {
      value: '10:30 PM',
      label: '10:30 pm',
    },
    {
      value: '11:00 PM',
      label: '11:00 pm',
    },
    {
      value: '11:30 PM',
      label: '11:30 pm',
    },
  ],
  WORLDS_EXCLUDE_IN_SEARCH: ['and', 'a', 'the', 'or', 'of', 'with'],
  START_VIDEO_YOUTUBE_ID: '3DKO8kWpC1I',
  MOBILE_VIDEO_YOUTUBE_ID: 'zDUkff_OsCc',
  FEE_TYPES: {
    0: {
      value: 'I',
      label: 'initial case',
      activityLabel: 'Initial case',
      key: 0,
    },
    1: {
      value: 'F',
      label: 'Flat fee',
      activityLabel: 'Flat fee',
      key: 1,
    },
    2: {
      value: '%',
      label: 'Contingency',
      activityLabel: 'Contingency',
      key: 2,
    },
    3: {
      value: 'T&E',
      label: 'Time & Expense case',
      activityLabel: 'Time & Expense case',
      key: 3,
    },
  },
  CASE_FEE_TYPES_BY_LABEL: {
    INITIAL: 0,
    FLAT_FEE: 1,
    CONTINGENCY: 2,
    T_E: 3,
  },
  INVOICING: {
    1: {
      value: 1,
      label: 'Invoice by date',
      hint: 'An invoice will be generated according to the “Invoice date” parameter in each activity',
    },
    2: {
      value: 2,
      label: 'Periodically invoicing',
      hint: 'Invoices will be generated at the selected interval',
    },
  },
  INVOICE_FREQUENCY: {
    1: {
      value: 1,
      label: 'End of every week',
      hint: 'The time for the weekly invoice is the Sunday at 11:59 pm PT',
    },
    2: {
      value: 2,
      label: 'End of every month',
      hint: 'The time for monthly invoice will be the last day of the month at 11:59 pm PT',
    },
  },
  ACTIVITY_TYPE: {
    0: {
      type: 'time',
      icon: <TimeLogo />,
      hint: 'time entry',
    },
    1: {
      type: 'expense',
      icon: <ExpenseLogo />,
      hint: 'expense entry',
    },
  },
  INVOICE_TYPE_BY_CODE: {
    0: {
      value: 0,
      label: 'Issued',
    },
    1: {
      value: 1,
      label: 'Failed',
    },
    2: {
      value: 2,
      label: 'E-Paid',
    },
    3: {
      value: 3,
      label: 'Write-off',
      statusLabel: 'WO',
    },
    4: {
      value: 4,
      label: 'Paid',
    },
    5: {
      value: 5,
      label: 'Partial',
    },
  },
  TOOLTIP_TEXT_BY_INVOICE: {
    0: 'Waiting for payment',
    1: '',
    2: 'The payment completed successfully',
    3: '',
    4: '',
    5: 'Partial payment',
  },
  CLIENT_INVOICE_TYPE_BY_CODE: {
    0: {
      value: 0,
      label: 'Due',
    },
    1: {
      value: 1,
      label: 'Failed',
    },
    2: {
      value: 2,
      label: 'E-Paid',
    },
    4: {
      value: 4,
      label: 'Paid',
    },
    5: {
      value: 5,
      label: 'Partial',
    },
  },
  INVOICE_TYPE_BY_LABEl: {
    ISSUED: 0,
    FAILED: 1,
    EPAID: 2,
    WRITE_OFF: 3,
    PAID: 4,
    PARTIAL: 5,
  },
  DATE_FORMAT: {
    FORMAT_SHORT: 'MMM D',
    FORMAT_ONE: 'MMM D, YYYY',
    FORMAT_TWO: 'dddd, MMMM D',
    FORMAT_THREE: 'MMMM D, YYYY',
    PDF_DATE: 'MMM D YYYY hh_mm A',
  },
  WALLET_ITEMS: {
    0: {
      key: 0,
      label: 'Invoice',
      value: 0,
    },
    1: {
      key: 1,
      label: 'Initial consultation',
      value: 1,
    },
    2: {
      key: 2,
      label: 'Video meet',
      value: 2,
    },
    3: {
      key: 3,
      label: 'Bank Transfer to Account (Funds Requested)',
      value: 3,
    },
    4: {
      key: 4,
      label: 'Xira (credit)',
      value: 4,
    },
    5: {
      key: 5,
      label: 'Xira (charge)',
      value: 5,
    },
    6: {
      key: 6,
      label: 'Bank Transfer to Account (Declined)',
      value: 6,
    },
    7: {
      key: 7,
      label: 'secure vault',
      value: 7,
    },
  },
  PAY_NOW_BUTTON_STATE: {
    SHOWED: 0,
    SHOWED_AND_DISABLED: 1,
    NO_SHOWED: 2,
  },
  parallaxProperties: {
    speed: 0.6,
  },
  WALLET_ITEM_STATUSES: {
    0: {
      label: 'pending',
    },
    1: {
      label: 'approved',
    },
    2: {
      label: 'declined',
    },
  },
  TUTORIAL_VIDEOS: {
    CALENDAR: {
      title: 'XIRA Calendar Tutorial',
      description: 'Tutorial on how XIRA calendar works.',
      id: 'Rw7QR4EBLOw',
      duration: '2:56',
    },
    ACTIVITY: {
      title: 'Creating an activity in XIRA',
      description: 'Tutorial for how to create an activity in XIRA.',
      id: 'nLHDy0fYjgg',
      duration: '2:13',
    },
    T_AND_E_CASE: {
      title: 'Creating a time & expense case in XIRA',
      description: 'Tutorial for creating a time and expense case in XIRA.',
      id: 'faiMFw1Or_Q',
      duration: '1:39',
    },
    CONTINGENCY_CASE: {
      title: 'Creating a contingency case in XIRA',
      description: 'Tutorial for creating a contingency case in XIRA.',
      id: 'EGIY90wBIVw',
      duration: '1:13',
    },
    FLAT_FEE_CASE: {
      title: 'Creating a flat fee case in XIRA',
      description: 'Tutorial for creating a flat fee case in XIRA.',
      id: 'Ut1JbJnOHcM',
      duration: '2:00',
    },
    ICALENDAR: {
      title: 'XIRA iCloud Calendar integration',
      description: 'Tutorial on how to connect your iCalendar to XIRA - Law Made Easy.',
      id: 'V7G5j00tc9s',
      duration: '1:25',
    },
    INVOICES: {
      title: 'Invoices on XIRA',
      description: '',
      id: 't0I7amgbYOU',
      duration: '3:04',
    },
    WALLET: {
      title: 'My wallet on XIRA',
      description: '',
      id: 'DlsLIoVwU2c',
      duration: '3:16',
    },
    CONNECTION: {
      title: 'Connections on XIRA',
      description: '',
      id: 'EscTp9DrccI',
      duration: '2:11',
    },
    VAULT: {
      title: 'Secure Document Vault on XIRA',
      description: 'One secure document management vault for attorneys on XIRA',
      id: 'bQnHnqjacTM',
      duration: '3:16',
    },
  },
  PROFILE_CREATION_TUTORIAL_VIDEO: {
    1: {
      duration: '0:27',
      id: 'ikJAK4m66pc',
    },
    2: {
      duration: '0:28',
      id: 'ScpAr76Bmp8',
    },
    3: {
      duration: '1:00',
      id: 'u1gjBgrUm7E',
    },
    4: {
      duration: '0:35',
      id: 'UGPqHnjnnqE',
    },
    5: {
      duration: '1:10',
      id: 'LlfqPqY7gLo',
    },
    6: {
      duration: '0:40',
      id: 'm_PP8cj3sFw',
    },
  },
  DEFAULT_SUBFOLDER_NAME: 'New subfolder',
  FILE_UPLOAD_STATUS: {
    FAILED: 0,
    SUCCESS: 1,
    CANCELLED: 2,
    IN_PROGRESS: 3,
  },
  FILE_SIZE_LIMIT: 2147483648,
  VAULT_ACTIONS: {
    MOVE: 'move',
    COPY: 'copy',
    MASS_MOVE: 'mass_move',
    MASS_COPY: 'mass_copy',
    DELETE: 'delete',
    MASS_DELETE: 'mass_delete',
    UPLOAD: 'upload',
    DOWNLOAD: 'download',
    DELETE_TRASH: 'delete_trash',
    MASS_DELETE_TRASH: 'mass_delete_trash',
  },
  NOTIFICATION_TYPES: {
    PRO_NEW_FILE: 1,
    PRO_UPGRADE_STORAGE: 2,
    PRO_NEW_MESSAGE: 3,
    CLIENT_NEW_MESSAGE: 4,
    CLIENT_NEW_FILE: 0,
    ADMIN_MESSAGE: 5,
    INITIAL_QUESTIONNAIRE: 6,
    INVOICE_QUESTIONNAIRE: 7,
    CASE_QUESTIONNAIRE: 8,
    NEW_APPROVED_COMMENT: 9,
    NEW_COMMENT_REPLY: 10,
    CLIENT_NEW_FILES: 11,
    PRO_NEW_FILES: 12,
    CLIENT_INVOICE_REMINDER: 13,
  },
  VAULT_EVENTS: {
    PRINT: 6,
    DOWNLOAD: 5,
  },
  CONTACT_US_SUBJECTS: [
    {
      value: 'General',
      label: 'General',
    },
    {
      value: 'Registration',
      label: 'Registration',
    },
    {
      value: 'Investor relation',
      label: 'Investor relation',
    },
    {
      value: 'Media',
      label: 'Media',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Sales',
      label: 'Sales',
    },
    {
      value: 'Privacy / Security',
      label: 'Privacy / Security',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
  ],
  RATING_ANSWER: {
    0: '',
    1: 'very poor',
    2: 'poor',
    3: 'okay',
    4: 'good',
    5: 'wonderful',
  },
  REASON_FOR_CANCELATION_BY_PRO: [
    {
      value: 'Sorry, I can no longer make this scheduled time. Please pick another time on my calendar',
      label: 'Sorry, I can no longer make this scheduled time. Please pick another time on my calendar',
    },
    {
      value: 'Sorry, I will not be able to meet with you. Please select a different attorney on XIRA',
      label: 'Sorry, I will not be able to meet with you. Please select a different attorney on XIRA',
    },
  ],
  REASON_FOR_RESCHEDULE_BY_PRO: [
    {
      value: 'Sorry, I need to reschedule this meeting. Please select another time on my calendar',
      label: 'Sorry, I need to reschedule this meeting. Please select another time on my calendar',
    },
  ],
  DISABLED_MESSAGES_TOOLTIP: 'Messaging will be available once your initial consultation is concluded',
  DISABLED_MESSAGES_INVITED_TOOLTIP:
    'Messages will be available once your client registers and accepts your invitation',
}

export const INVITE_CLIENT_CONFIRMATION = userEmail => ({
  0: {
    title: `An invite to ${userEmail} was successfully sent`,
    content: `Now you may create cases for this client and issue invoices. You may edit the name and contacts until the client has signed up and taken control of the profile.`,
  },
  1: {
    title: `${userEmail} already exists in XIRA and added to your connections`,
    content: `Now you can create cases for this client and generate invoices.`,
  },
  2: {
    title: `${userEmail} already exists in your connections`,
    content: `Continue to create case for this client.`,
  },
})
