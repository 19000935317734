import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles, InputAdornment } from '@material-ui/core'
import {
  Icon,
  Link,
  Text,
  TextField,
  Typography,
  XiraRating,
  ColorCircularProgress,
  MemoryChart,
  Button,
} from 'components'
import { colors } from 'constants/index'
import {
  deleteSocialAccount,
  getSocialAccounts,
  postSocialAccount,
  sendRatingRequest,
} from 'store/actions/accountProfile'
import AccountsModal from './modals/accountsModal/AccountsModal'
import { openSocialLoginWindow } from 'utils/auth'
import { ReactComponent as InviteUserIcon } from '../../../static/person-add.svg'

const styles = () => ({
  title: {
    fontFamily: "'Roboto', san-serif",
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    marginBottom: '15px',
  },
  text: {
    margin: '32px',
  },
  link: {
    color: colors.BASIC.COLOR[80],
    paddingBottom: '1px',
    borderBottom: `1px solid ${colors.BASIC.COLOR[80]}`,
    cursor: 'pointer',
    height: '26px',
    lineHeight: '30px',
  },
  titleModal: {
    marginBottom: 8,
  },
  subtitle: {
    fontFamily: "'Roboto', san-serif",
    fontSize: '15px',
    fontWeight: '400',
    marginBottom: 25,
  },
  inputIcon: {
    fontSize: 18,
    color: colors.BASIC.COLOR[80],
    borderRight: '1px solid #E8E8E8',
    height: 39,
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textField: {
    marginTop: 26,
    marginBottom: 32,
  },
  input: {
    paddingLeft: 0,
  },
  deleteIcon: {
    color: colors.ERROR,
    fontSize: 32,
    verticalAlign: 'middle',
    marginRight: 8,
  },
  disconnectIcon: {
    fontSize: 15,
    marginTop: 10,
    marginLeft: 8,
  },
  socialMediaConnectLink: {
    display: 'flex',
    marginTop: 10,
  },
  socialMediaConnectIconWrapper: {
    width: 54,
  },
  socialMediaAppsIconsFb: {
    color: '#1976d2',
    fontSize: 32,
    lineHeight: '32px',
    height: 32,
  },
  socialMediaAppsIconsLi: {
    color: '#1976d2',
    fontSize: 43,
    lineHeight: '32px',
    height: 32,
  },
  socialMediaPageHeader: {
    fontSize: 20,
    lineHeight: 1.4,
  },
  socialMediaPageText: {
    fontSize: 15,
    lineHeight: 1.6,
    marginTop: 8,
  },
  socialAccountLink: {
    marginTop: 5,
  },
  recommended: {
    marginTop: 36,
  },
  recommendedLink: {
    textDecoration: 'none',
  },
  recommendedButton: {
    marginTop: 20,
  },
})

const OPEN_FACEBOOK = 'facebook'
const OPEN_LINKEDIN = 'linkedin'
const DELETE_LINKEDIN = 'DELETE_LINKEDIN'
const DELETE_FACEBOOK = 'DELETE_FACEBOOK'

class AccountProfileSocialContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonDisabled: false,
      openModalStatus: '',
      accountLink: '',
      error: false,
      helperText: '',
    }
  }

  changeDisablingButtonStatus(status) {
    this.setState({ buttonDisabled: status })
  }

  openModal(type) {
    this.setState({
      openModalStatus: type,
    })
  }

  closeModal() {
    this.setState({
      openModalStatus: '',
    })
  }

  async connectAccount(account) {
    const body = {
      social_account: account.social_account,
      link: this.state.accountLink,
    }

    this.props
      .postSocialAccount(body)
      .then(() => {
        this.props.getSocialAccounts()
        this.closeModal()
        this.setState({ error: false, helperText: '' })
      })
      .catch(e =>
        this.setState({
          error: true,
          helperText: `Please provide a link to your account on https://www.${account.social_account}.com domain`,
        })
      )
      .finally(() => {
        this.changeDisablingButtonStatus(false)
      })
  }

  async deleteAccount() {
    const { facebook, linkedin } = this.props
    const { openModalStatus } = this.state
    let uuid = ''
    if (openModalStatus === DELETE_FACEBOOK) uuid = facebook.uuid
    if (openModalStatus === DELETE_LINKEDIN) uuid = linkedin.uuid
    this.changeDisablingButtonStatus(true)
    this.props
      .deleteSocialAccount(uuid)
      .then(() => this.props.getSocialAccounts())
      .finally(() => {
        this.changeDisablingButtonStatus(false)
        this.closeModal()
      })
  }

  accountLinkChange(e) {
    this.setState({
      accountLink: e.currentTarget.value,
    })
  }

  onFBLoginSuccess = response => {
    if (!response.code) return
    this.props
      .postSocialAccount({
        social_account: 'facebook',
        code: response.code,
        redirect_uri: `${window.location.origin}/social-login`,
      })
      .then(() => this.props.getSocialAccounts())
  }

  onFacebookClick = provider => {
    if (!this.props.facebook.link) openSocialLoginWindow('fbMedia', this.onFBLoginSuccess)
    else this.onConnectedAccountClick(this.props.facebook.link)
  }

  onConnectedAccountClick(link) {
    window.open(link)
  }

  mountFacebookAccount(facebook) {
    const { classes } = this.props
    const onLinkClick = () => {
      if (facebook.link) this.onConnectedAccountClick(facebook.link)
      else {
        this.openModal(OPEN_FACEBOOK)
      }
    }
    return (
      <div className={classes.socialMediaConnectLink}>
        <div className={classes.socialMediaConnectIconWrapper}>
          <Icon iconClass="lab la-facebook-f" style={classes.socialMediaAppsIconsFb} />
        </div>
        <Link className={classes.socialAccountLink} onClick={() => onLinkClick(facebook.link)}>
          {facebook.link ? 'Connected' : 'Connect'}
        </Link>
        {facebook.link && (
          <Icon
            iconClass="las la-unlink"
            style={this.props.classes.disconnectIcon}
            onClick={() => this.openModal(DELETE_FACEBOOK)}
          />
        )}
      </div>
    )
  }

  mountLinkedinAccount(account) {
    const { classes } = this.props
    const onLinkClick = () => {
      if (account.link) this.onConnectedAccountClick(account.link)
      else {
        this.openModal(OPEN_LINKEDIN)
      }
    }
    return (
      <div className={classes.socialMediaConnectLink}>
        <div className={classes.socialMediaConnectIconWrapper}>
          <Icon iconClass="lab la-linkedin-in" style={classes.socialMediaAppsIconsLi} />
        </div>
        <Link className={classes.socialAccountLink} onClick={() => onLinkClick(account.link)}>
          {account.link ? 'Connected' : 'Connect'}
        </Link>
        {account.link && (
          <Icon
            iconClass="las la-unlink"
            style={classes.disconnectIcon}
            onClick={() => this.openModal(DELETE_LINKEDIN)}
          />
        )}
      </div>
    )
  }

  mountAccountConnectModal() {
    const { openModalStatus } = this.state
    const { classes, facebook, linkedin } = this.props
    let media = ''
    if (openModalStatus === OPEN_LINKEDIN) {
      media = 'Linkedin'
    }
    if (openModalStatus === OPEN_FACEBOOK) {
      media = 'Facebook'
    }
    const currentAccountData = this.state.openModalStatus === OPEN_FACEBOOK ? facebook : linkedin

    const closeModalWithClearingErrors = () => {
      this.setState({ error: false, helperText: '' })
      this.closeModal()
    }

    return (
      <AccountsModal
        isOpen={Boolean(media)}
        onClose={closeModalWithClearingErrors}
        onSave={() => this.connectAccount(currentAccountData)}
        buttonDisabled={this.state.buttonDisabled}
      >
        <Typography variant="h4" className={classes.titleModal}>
          {`Insert Link to Your ${media} Account`}
        </Typography>
        <TextField
          classes={{ root: classes.textField }}
          variant="outlined"
          size="small"
          name="yelp"
          required
          defaultValue={currentAccountData.link}
          onBlur={e => this.accountLinkChange(e)}
          type="text"
          error={this.state.error}
          helperText={this.state.helperText}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon iconClass="las la-link" style={classes.inputIcon} />
              </InputAdornment>
            ),
            classes: { root: classes.input },
            inputProps: {
              maxLength: 100,
            },
          }}
        />
      </AccountsModal>
    )
  }

  mountAccountDisconnectModal() {
    const { classes } = this.props
    const { openModalStatus } = this.state
    let openStatus = false
    let media = ''
    if (openModalStatus === DELETE_LINKEDIN) {
      media = 'Linkedin'
      openStatus = true
    }
    if (openModalStatus === DELETE_FACEBOOK) {
      media = 'Facebook'
      openStatus = true
    }
    return (
      <AccountsModal
        isOpen={openStatus}
        onClose={() => this.closeModal('')}
        saveButtonText="Yes, delete"
        onSave={() => this.deleteAccount()}
        buttonDisabled={this.state.buttonDisabled}
      >
        <Typography variant="h4" className={classes.title}>
          <Icon iconClass="las la-exclamation-triangle" style={classes.deleteIcon} />
          {`Delete ${media} Account?`}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {`${media} account will be permanently deleted from your profile.`}
        </Typography>
      </AccountsModal>
    )
  }

  render() {
    const {
      classes,
      adminEmail,
      ratingValue,
      profileIsLoading,
      reviews,
      ratingStatus,
      sendProRatingRequest,
      ratingRequestStatusLoading,
    } = this.props

    return (
      <>
        {profileIsLoading || ratingRequestStatusLoading ? (
          <ColorCircularProgress />
        ) : (
          <>
            <Typography className={classes.socialMediaPageHeader} variant="h4">
              Social Circle
            </Typography>
            <Text classes={{ subtitle1: classes.socialMediaPageText }}>
              Add if you want clients to see your social page or know if they have common connections with you.
            </Text>
            {this.mountFacebookAccount(this.props.facebook)}
            {this.mountLinkedinAccount(this.props.linkedin)}
            <XiraRating
              ratingValue={ratingValue}
              reviews={reviews}
              ratingStatus={ratingStatus}
              sendRatingRequest={sendProRatingRequest}
            />
            <div className={classes.recommended}>
              <Typography variant="h4">Be on top of search results for FREE by getting Peer Recommendations</Typography>
              <Text classes={{ subtitle1: classes.socialMediaPageText }}>Click below to start the simple process</Text>
              <a target="_blank" href="https://xira.com/p/peerrecommend/" className={classes.recommendedLink}>
                <Button className={classes.recommendedButton} variant="primary">
                  ELEVATE MY PROFILE
                </Button>
              </a>
            </div>
            {this.mountAccountConnectModal()}
            {this.mountAccountDisconnectModal()}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = ({ accountProfile, auth }) => {
  return {
    facebook: accountProfile.socialAccounts.facebook,
    linkedin: accountProfile.socialAccounts.linkedin,
    adminEmail: auth.currentUser.admin_email,
    ratingValue: accountProfile.rating,
    profileIsLoading: accountProfile.profileIsLoading,
    reviews: accountProfile.reviews,
    ratingStatus: accountProfile.ratingStatus,
    ratingRequestStatusLoading: accountProfile.ratingRequestStatusLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  postSocialAccount: (provider, code) => dispatch(postSocialAccount(provider, code)),
  getSocialAccounts: () => dispatch(getSocialAccounts()),
  sendProRatingRequest: message => dispatch(sendRatingRequest(message)),
  deleteSocialAccount: uuid => dispatch(deleteSocialAccount(uuid)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(AccountProfileSocialContainer)
