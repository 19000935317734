import React from 'react'
import { Autocomplete, DateRangePickerComponent, TableResetFilters } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { setWalletFilter, setTableConfiguration, resetWalletFilter } from 'store/actions/wallet/wallet'

const useStyles = makeStyles({
  additionalFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #f0efee',
    backgroundColor: colors.WHITE,
  },
  additionalFilter: {
    display: 'flex',
    marginBottom: 30,
  },
  mdFilter: {
    marginRight: 12,
    width: 240,
  },
  rangeInput: {
    width: 240,
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  result: {
    fontWeight: 'normal',
    fontSize: 15,
  },
})

const options = [{ value: null, label: 'All' }, ...Object.values(CONSTANTS.WALLET_ITEMS)]

const AdditionalWalletFilters = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const { filters, getWalletData, count, isInitialFilters, selectedTab } = props

  const onFilterChange = (name, value) => {
    dispatch(setWalletFilter(name, value))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    getWalletData()
  }

  const clearFilters = () => {
    dispatch(resetWalletFilter())
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    getWalletData()
  }
  return (
    <div className={classes.additionalFilterContainer}>
      <div className={classes.additionalFilter}>
        {selectedTab === 0 && (
          <Autocomplete
            fullWidth
            placeholder="Item"
            label="Item"
            variant="outlined"
            className={classes.mdFilter}
            value={filters.action}
            onChange={(e, v) => onFilterChange('action', v ? v.value : null)}
            options={options}
          />
        )}
        <DateRangePickerComponent
          containerClasses={classes.dateRangeFilter}
          inputClasses={classes.rangeInput}
          updateRange={value => onFilterChange('date', value)}
          label="Dates available between"
          range={filters.date}
          timezone={timezone}
        />
      </div>
      <TableResetFilters
        count={count}
        onClearFilters={clearFilters}
        showClearFilters={!isInitialFilters}
        containerClass={classes.resultContainer}
      />
    </div>
  )
}

export default AdditionalWalletFilters
