import * as CONSTANTS from 'store/constants/cases'

const initialState = {
  cases: [],
  filterCases: [],
  casesAreLoading: false,
  userCasesAreLoading: true,
  filterCasesAreLoading: false,
  casesLength: 0,
  caseFullInfo: {},
  caseFullInfoIsLoading: false,
  initialCases: [],
  filters: {
    search: '',
    status: true,
    clientId: null,
  },
  sort: {
    property: 'updated_at',
    iaAsc: false,
  },
  initialSort: {
    property: 'updated_at',
    iaAsc: false,
  },
  tableConfiguration: {
    tablePage: 0,
    limit: 20,
    offset: 0,
  },
  proCases: [],
  addButtonActive: false,
  userHasCases: false,
}

const cases = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_CASES_START:
      return {
        ...state,
        casesAreLoading: true,
      }
    case CONSTANTS.GET_CASES_END:
      return {
        ...state,
        casesAreLoading: false,
        cases: action.payload,
        casesLength: action.length,
      }
    case CONSTANTS.GET_CASES_FILTER_START:
      return {
        ...state,
        filterCasesAreLoading: true,
      }
    case CONSTANTS.GET_CASES_FILTER_END:
      return {
        ...state,
        filterCasesAreLoading: false,
        filterCases: action.payload,
      }
    case CONSTANTS.SET_TABLE_CONFIGURATION:
      return {
        ...state,
        tableConfiguration: {
          ...state.tableConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      }
    case CONSTANTS.SET_SORTING:
      return {
        ...state,
        sort: {
          property: action.property,
          isAsc: action.isAsc,
        },
      }
    case CONSTANTS.UPDATE_CASES_START:
      return {
        ...state,
        caseFullInfoIsLoading: true,
      }
    case CONSTANTS.UPDATE_CASES_END:
      return {
        ...state,
        caseFullInfoIsLoading: false,
      }
    case CONSTANTS.GET_CASE_FULL_INFO_START:
      return {
        ...state,
        caseFullInfoIsLoading: true,
      }
    case CONSTANTS.GET_CASE_FULL_INFO_END:
      return {
        ...state,
        caseFullInfo: action.payload,
        caseFullInfoIsLoading: false,
      }
    case CONSTANTS.GET_INITIAL_CASES_START:
      return {
        ...state,
      }
    case CONSTANTS.GET_INITIAL_CASES_END:
      return {
        ...state,
        initialCases: action.payload,
      }
    case CONSTANTS.MAKE_CASE_INACTIVE_START:
      return {
        ...state,
        caseFullInfoIsLoading: true,
      }
    case CONSTANTS.MAKE_CASE_INACTIVE_END:
      return {
        ...state,
        caseFullInfoIsLoading: false,
      }
    case CONSTANTS.SAVE_PRO_CASE_START:
      return {
        ...state,
        caseFullInfoIsLoading: true,
      }
    case CONSTANTS.SAVE_PRO_CASE_END:
      return {
        ...state,
        caseFullInfoIsLoading: false,
      }
    case CONSTANTS.RESET_FILTERS:
      return {
        ...state,
        filters: {
          search: '',
          status: true,
          clientId: null,
        },
      }
    case CONSTANTS.RESET_SORTING:
      return {
        ...state,
        sort: state.initialSort,
      }
    case CONSTANTS.GET_PRO_SPECIFIC_PRACTICE_AREAS_START:
      return {
        ...state,
      }
    case CONSTANTS.GET_PRO_SPECIFIC_PRACTICE_AREAS_END:
      return {
        ...state,
        proCases: action.payload,
      }
    case CONSTANTS.SET_ADD_CASES_BUTTON_ACTIVE:
      return {
        ...state,
        addButtonActive: action.payload,
      }
    case CONSTANTS.USER_HAS_CASES_START:
      return {
        ...state,
        userCasesAreLoading: true,
      }
    case CONSTANTS.SET_USER_HAS_CASES:
      return {
        ...state,
        userHasCases: action.payload,
        userCasesAreLoading: false,
      }
    default:
      return state
  }
}

export default cases
