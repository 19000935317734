import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { ChatModal, ClientConnectionsCard, Loader, NoDataPlaceholder, Typography, ClientInviteCard } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import {
  archiveUserConnections,
  getUserConnections,
  increaseConnectionsUploadOffset,
  resetConnectionsList,
  resetConnectionsUploadOffset,
} from 'store/actions/connections'
import InfiniteScroll from 'react-infinite-scroll-component'
import NoConnectionsImage from 'static/connections/no_client_connections.png'
import { useQuery } from 'utils/hooks'
import { openVaultModal, setVaultOpenPlace } from 'store/actions/vault'
import { openChatModal } from 'store/actions/chat/chat'
import TabList from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
  acceptConnectionInvite,
  getInvitationsForClient,
  hideConnectionInvite,
} from '../../../store/actions/connections'
import { getUserContactInfo, saveContactInfo, changeProfileContactInfo } from 'store/actions/profileCreation'
import { ContactInfoPopup } from '../../../components/connections/ContactInfoPopup'
import Analytics from 'utils/analytics/AnalyticsService'
import { openSnackbar } from '../../../store/actions/common'
import { EVENTS } from 'utils/analytics/Events'

const useStyles = makeStyles({
  profileContentHeader: {
    fontSize: 25,
    fontWeight: 700,
    lineHeight: '36px',
    fontFamily: 'Roboto',
  },
  connectionsHeader: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  connectionsList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  '@media(max-width:1279px)': {},
  '@media(max-width:767px)': {
    profileContentHeader: {
      marginBottom: 8,
    },
    connectionsHeader: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    connectionsCard: {
      margin: '0 0 16px !important',
      width: '100%',
    },
  },
  tabsContainer: {},
  tab: {
    minWidth: 'unset',
    padding: '14px 16px 12px',
    borderBottom: '1px solid #C1CBD6',
    color: '#6F8194',
  },
  selectedTab: {
    color: '#2B4157',
  },
  tabIndicator: {
    backgroundColor: '#2B4157',
  },
  '@media(max-width:1280px)': {
    connectionsCard: {
      margin: '0 12px 24px !important',
      width: 'calc(50% - 24px)',
    },
  },
  '@media(max-width:939px)': {
    connectionsCard: {
      width: 'calc(100% - 24px)',
    },
  },
  invitesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  loader: {
    position: 'relative',
    width: '100%',
    height: 40,
    marginBottom: 30,
  },
  emptyTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: '#011A33',
  },
})

const ClientConnectionsContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const connections = useSelector(state => state.connections.connections)
  const connectionsLength = useSelector(state => state.connections.connectionsLength)
  const connectionsAreLoading = useSelector(state => state.connections.connectionsAreLoading)
  const clientInvitesIsLoading = useSelector(state => state.connections.clientInvitesIsLoading)
  const clientInvites = useSelector(state => state.connections.clientInvites)
  const clientArchiveIsLoading = useSelector(state => state.connections.clientArchiveIsLoading)
  const [selectedTab, setSelectedTab] = useState('active')
  const [showContactInfoForm, setShowContactInfoForm] = useState(false)
  const [invitationId, setInvitationId] = useState('')
  const [inviteProId, setInviteProId] = useState('')
  const query = useQuery()
  const fileId = query.get('file')
  const proID = query.get('proID')
  const proName = query.get('proName')
  const chatId = query.get('chatId')

  useEffect(() => {
    dispatch(setVaultOpenPlace('connections'))
    if (fileId && proID && proName) {
      dispatch(openVaultModal({ proID: proID, proName: proName, isClientVault: true, previewedFileId: fileId }))
    } else if (proID && proName) {
      dispatch(openVaultModal({ proID: proID, proName: proName, isClientVault: true }))
    }
  }, [fileId, proID, proName])

  useEffect(() => {
    if (chatId) {
      getChatDetails()
    }
  }, [chatId])

  const getChatDetails = async () => {
    try {
      dispatch(openChatModal(chatId, 'connections'))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    dispatch(resetConnectionsUploadOffset())
    dispatch(getUserConnections(false, selectedTab === 'archive'))
    //dispatch(getInvitationsForClient())
    return () => dispatch(resetConnectionsList())
  }, [])

  const onLoadMore = () => {
    dispatch(increaseConnectionsUploadOffset())
    dispatch(getUserConnections(false, selectedTab === 'archive'))
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    dispatch(resetConnectionsList())
    dispatch(resetConnectionsUploadOffset())
    dispatch(getUserConnections(true, newValue === 'archive'))
  }

  const onHideInvitePress = async uuid => {
    try {
      await dispatch(hideConnectionInvite(uuid))
      dispatch(getInvitationsForClient())
    } catch (e) {
      console.error(e)
    }
  }

  const onAcceptInvitePress = async (uuid, proId) => {
    try {
      await dispatch(getUserContactInfo())
      await acceptInvite(uuid)
    } catch (e) {
      setInvitationId(uuid)
      setInviteProId(proId)
      setShowContactInfoForm(true)
    }
  }

  const acceptInvite = async uuid => {
    try {
      await dispatch(acceptConnectionInvite(uuid))
      Analytics.track(EVENTS.CLIENT_ACCEPTED_INVITE, {
        pro_id: uuid,
      })
      dispatch(getInvitationsForClient())
      dispatch(getUserConnections(true, selectedTab === 'archive'))
    } catch (e) {
      console.error(e)
    }
  }

  const onSaveContactInfo = async values => {
    try {
      await dispatch(saveContactInfo(values))
      dispatch(changeProfileContactInfo(values))
      await acceptInvite(invitationId)
      setShowContactInfoForm(false)
      setInvitationId('')
    } catch (e) {
      dispatch(openSnackbar('error', 'Error while saving contact info'))
    }
  }

  const onArchive = async uuid => {
    if (clientArchiveIsLoading) return
    try {
      await dispatch(archiveUserConnections(uuid))
      await dispatch(resetConnectionsUploadOffset())
      dispatch(getUserConnections(true, selectedTab === 'archive'))
    } catch (e) {
      dispatch(openSnackbar('error', 'Error while archiving connection'))
    }
  }

  return (
    <div>
      <div className={classes.connectionsHeader}>
        <Typography variant="h3" className={classes.profileContentHeader}>
          {selectedTab === 'active' ? 'Active connections' : 'Archived connections'}
        </Typography>
        <TabList
          classes={{ indicator: classes.tabIndicator, root: classes.tabsContainer }}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            value="active"
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label={<span>Active</span>}
          />
          <Tab
            value="archive"
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label={<span>Archive</span>}
          />
        </TabList>
      </div>
      <div className={classes.invitesContainer}>
        {!clientInvitesIsLoading &&
          clientInvites.map(invite => (
            <ClientInviteCard
              data={invite}
              key={invite.uuid}
              cardStyle={classes.connectionsCard}
              onHide={onHideInvitePress}
              onAccept={onAcceptInvitePress}
            />
          ))}
      </div>

      <InfiniteScroll
        scrollableTarget="profile_search_id"
        dataLength={connections.length}
        hasMore={connectionsLength > connections.length}
        next={onLoadMore}
      >
        <>
          {!connections.length && !connectionsAreLoading && !clientInvites.length && !clientInvitesIsLoading && (
            <NoDataPlaceholder
              label={`you have no ${selectedTab === 'archive' ? 'archived' : 'active'} connections`}
              imageSrc={NoConnectionsImage}
              hideButton
              titleClass={classes.emptyTitle}
            />
          )}
          {!!connections.length && (
            <div className={classes.connectionsList}>
              {connections.map(
                connection =>
                  connection &&
                  connection.proInfo && (
                    <ClientConnectionsCard
                      connection={connection}
                      key={connection.pro}
                      isArchived={selectedTab === 'archive'}
                      onArchive={() => onArchive(connection.pro)}
                    />
                  )
              )}
            </div>
          )}
          {connectionsAreLoading && <Loader />}
        </>
      </InfiniteScroll>
      <ChatModal />
      {showContactInfoForm && (
        <ContactInfoPopup
          isOpen={showContactInfoForm}
          proId={inviteProId}
          onSaveContactInfo={onSaveContactInfo}
          onClose={() => {
            setShowContactInfoForm(false)
          }}
        />
      )}
    </div>
  )
}

export default ClientConnectionsContainer
