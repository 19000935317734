import React from 'react'
import { Typography, Button } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { setVaultPlans, getVaultPlans } from 'store/actions/vault'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  containerVault: {
    textAlign: 'center',
  },
  vaultTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 34,
  },
  vaultSubTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
  },
  vaultDesc: {
    fontSize: 15,
    fontWeight: 400,
    marginBottom: 24,
  },
  rates: {
    marginBottom: 27,
  },
  btn: {
    fontSize: 13,
    fontWeight: 500,
    minHeight: 'auto',
    lineHeight: 1.1,
    letterSpacing: 0.6,
  },
  freePlanBtn: {
    marginRight: 24,
    padding: '13px 39px',
    backgroundColor: colors.ADDITIONAL.COLOR[50],
    '&:hover': {
      backgroundColor: colors.ADDITIONAL.COLOR[60],
    },
  },
  premiumPlanBtn: {
    padding: '13px 24px',
  },
})

const VaultPlans = ({ openVaultModal, choiceFromSettings }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handlePlanSelection = async plan => {
    await dispatch(setVaultPlans({ vault_plan: plan }))
    if (choiceFromSettings) dispatch(getVaultPlans())
    if (openVaultModal) openVaultModal()
  }

  return (
    <div className={classes.containerVault}>
      <Typography variant="h1" className={classes.vaultTitle}>
        secure vault plans
      </Typography>
      <Typography variant="h3" className={classes.vaultSubTitle}>
        How it works?
      </Typography>
      <Typography variant="subtitle1" className={classes.vaultDesc}>
        [Description]
      </Typography>
      <Typography variant="h3" className={classes.vaultSubTitle}>
        How you will be charged?
      </Typography>
      <Typography variant="subtitle1" className={classes.rates}>
        Rates
      </Typography>
      <div className={classes.controlBtns}>
        <Button
          variant="primary"
          className={classNames(classes.btn, classes.freePlanBtn)}
          onClick={() => handlePlanSelection('free')}
        >
          free plan
        </Button>
        <Button
          variant="primary"
          className={classNames(classes.btn, classes.premiumPlanBtn)}
          onClick={() => handlePlanSelection('premium')}
        >
          premium plan
        </Button>
      </div>
    </div>
  )
}

export default VaultPlans
