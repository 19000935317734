import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, CalendarCard } from 'components'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  xiraCalendarContainer: {
    marginTop: 48,
    marginBottom: 36,
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    marginBottom: 28,
  },
})

const XiraCalendar = ({ xiraCalendar, onEditClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.xiraCalendarContainer}>
      {xiraCalendar.id && (
        <>
          <Typography variant="h3">XIRA calendar</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            The calendar where bookings for XIRA clients are saved
          </Typography>
          <CalendarCard calendarSettings={xiraCalendar} xiraCalendar={xiraCalendar} onEditClick={onEditClick} />
        </>
      )}
    </div>
  )
}

export default XiraCalendar
