import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { EVENTS } from 'utils/analytics/Events'
import Analytics from 'utils/analytics/AnalyticsService'
import ResizeObserver from 'resize-observer-polyfill'

const useScrollToTop = () => {
  const reference = useRef()
  const { pathname } = useLocation()
  useEffect(() => {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style
    if (supportsNativeSmoothScroll && reference.current) reference.current.scrollTo(0, 0)
  }, [pathname])

  return reference
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const mapPageToAnalyticsEvent = {
  '/': EVENTS.VIEW_MAIN_PAGE,
  '/signup': EVENTS.VIEW_SIGN_UP,
  '/about-xira': EVENTS.VIEW_ABOUT_XIRA,
  '/xira-for-lawyers': EVENTS.VIEW_XIRA_FOR_LAWYERS,
  '/user-agreement': EVENTS.VIEW_USER_AGREEMENT,
  '/contact-us': EVENTS.VIEW_CONTACT_US,
  '/privacy-policy': EVENTS.VIEW_PRIVACY_POLICY,
}

const useAnalytics = () => {
  const { pathname } = useLocation()
  useEffect(() => Analytics.track(mapPageToAnalyticsEvent[pathname]), [])
}

const useResizeObserver = ref => {
  const [dimensions, setDimensions] = useState(null)
  useEffect(() => {
    const observeTarget = ref.current
    const resizeObserver = new ResizeObserver(entries => entries.forEach(entry => setDimensions(entry.contentRect)))
    resizeObserver.observe(observeTarget)
    return () => resizeObserver.unobserve(observeTarget)
  }, [ref])
  return dimensions
}

const useWhyDidYouUpdate = (name, props) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef()

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props })
      // Use this object to keep track of changed props
      const changesObj = {}
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          }
        }
      })

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj)
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props
  })
}

export { useScrollToTop, useQuery, useAnalytics, useResizeObserver, useWhyDidYouUpdate }
