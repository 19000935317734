import * as CONSTANTS from 'store/constants/wallet/wallet'

const initialState = {
  walletSummary: {
    ytd_revenue: '0',
    pending_charges_sum: '0',
    pending_funds_sum: '0',
    available_funds: '0',
  },
  walletSummaryIsLoading: false,
  walletItems: [],
  walletPendingFunds: [],
  walletPendingFundsLength: 0,
  walletItemsAreLoading: false,
  walletItemsLength: 0,
  filters: {
    search: '',
    action: null,
    date: {
      from: '',
      to: '',
    },
  },
  initialFilters: {
    search: '',
    action: null,
    date: {
      from: '',
      to: '',
    },
  },
  tableConfiguration: {
    tablePage: 0,
    limit: 100,
    offset: 0,
  },
  paymentAccountStatus: false,
  paymentAccountStatusIsLoading: false,
  requestingFunds: false,
  isPendingFundsErrorModalOpen: false,
  hasTaxForm: false,
}

const wallet = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.SET_TABLE_CONFIGURATION:
      return {
        ...state,
        tableConfiguration: {
          ...state.tableConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      }
    case CONSTANTS.RESET_FILTERS:
      return {
        ...state,
        filters: state.initialFilters,
      }
    case CONSTANTS.GET_WALLET_SUMMARY_START:
      return {
        ...state,
        walletSummaryIsLoading: true,
      }
    case CONSTANTS.GET_WALLET_SUMMARY_END:
      return {
        ...state,
        walletSummaryIsLoading: false,
        walletSummary: action.payload,
      }
    case CONSTANTS.GET_WALLET_ITEMS_START:
      return {
        ...state,
        walletItemsAreLoading: true,
      }
    case CONSTANTS.GET_WALLET_ITEMS_END:
      return {
        ...state,
        walletItemsAreLoading: false,
        walletItems: action.payload,
        walletItemsLength: action.count,
      }
    case CONSTANTS.GET_WALLET_PENDING_FUNDS_START:
      return {
        ...state,
        walletItemsAreLoading: true,
      }
    case CONSTANTS.GET_WALLET_PENDING_FUNDS_END:
      return {
        ...state,
        walletItemsAreLoading: false,
        walletPendingFunds: action.payload,
        walletPendingFundsLength: action.count,
      }
    case CONSTANTS.GET_PAYMENT_CONNECTION_STATUS_START:
      return {
        ...state,
        paymentAccountStatusIsLoading: true,
      }
    case CONSTANTS.GET_PAYMENT_CONNECTION_STATUS_END:
      return {
        ...state,
        paymentAccountStatusIsLoading: false,
        paymentAccountStatus: action.payload,
      }
    case CONSTANTS.DISCONNECT_PAYMENT_START:
      return {
        ...state,
        paymentAccountStatusIsLoading: true,
      }
    case CONSTANTS.DISCONNECT_PAYMENT_END:
      return {
        ...state,
        paymentAccountStatusIsLoading: false,
      }
    case CONSTANTS.SAVE_PAYMENT_METHOD_START:
      return {
        ...state,
        paymentAccountStatusIsLoading: true,
      }
    case CONSTANTS.SAVE_PAYMENT_METHOD_END:
      return {
        ...state,
        paymentAccountStatusIsLoading: false,
      }
    case CONSTANTS.REQUEST_FUNDS_START:
      return {
        ...state,
        requestingFunds: true,
      }
    case CONSTANTS.REQUEST_FUNDS_END:
      return {
        ...state,
        requestingFunds: false,
      }
    case CONSTANTS.SET_REQUEST_FUNDS_ERROR_MODAL_OPEN:
      return {
        ...state,
        isPendingFundsErrorModalOpen: action.isShown,
      }
    case CONSTANTS.SET_HAS_TAX_FORM:
      return {
        ...state,
        hasTaxForm: action.payload,
      }
    default:
      return state
  }
}

export default wallet
