import * as CONSTANTS from 'store/constants/auth'
const initialState = {
  currentUser: {},
  localCurrentUser: {},
  isUserLogginingIn: false,
  isAuthByEmail: false,
  isUserLoggedIn: false,
  signUpEmail: '',
  isRegistrationPending: false,
  isChangingPassword: false,
  isProfileGetting: false,
  openUserDeletedModal: false,
  authRole: '',
  headerBanner: {
    show: false,
    text: '',
  },
  activatedFeature: {
    hasBanner: false,
    hasCalendar: false,
    hasCases: false,
    hasVault: false,
  },
  activatedFeatureLoaded: false,
}

const auth = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.IS_AUTH_BY_EMAIL_START:
      return {
        ...state,
        isAuthByEmail: true,
      }

    case CONSTANTS.IS_AUTH_BY_EMAIL_END:
      return {
        ...state,
        isAuthByEmail: false,
      }

    case CONSTANTS.REGISTRATION_BEGIN:
      return {
        ...state,
        isRegistrationPending: true,
      }

    case CONSTANTS.REGISTRATION_END:
      return {
        ...state,
        isRegistrationPending: false,
      }

    case CONSTANTS.USER_LOGGING_IN:
      return {
        ...state,
        isUserLogginingIn: true,
      }

    case CONSTANTS.USER_LOGGED_IN:
      return {
        ...state,
        isUserLogginingIn: false,
      }

    case CONSTANTS.GETTING_PROFILE:
      return {
        ...state,
        isProfileGetting: true,
      }

    case CONSTANTS.GOT_PROFILE:
      return {
        ...state,
        isProfileGetting: false,
      }

    case CONSTANTS.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        localCurrentUser: action.payload.currentUser,
        isUserLoggedIn: action.payload.isUserLoggedIn,
        isUserLogginingIn: false,
      }

    case CONSTANTS.UPDATE_LOCAL_CURRENT_USER:
      return {
        ...state,
        localCurrentUser: {
          ...state.localCurrentUser,
          ...action.payload,
        },
      }

    case CONSTANTS.SET_SIGN_UP_EMAIL:
      return {
        ...state,
        signUpEmail: action.payload,
      }
    case CONSTANTS.SET_PROFILE_PHOTO:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          photo: action.payload,
        },
      }
    case CONSTANTS.IS_CHANGING_PASSWORD:
      return {
        ...state,
        isChangingPassword: true,
      }
    case CONSTANTS.END_CHANGING_PASSWORD:
      return {
        ...state,
        isChangingPassword: false,
      }
    case CONSTANTS.CHANGE_AUTH_ROLE:
      return {
        ...state,
        authRole: action.payload,
      }
    case CONSTANTS.TOGGLE_USER_DELETED_MODAL:
      return {
        ...state,
        openUserDeletedModal: action.payload,
      }
    case CONSTANTS.SET_SHOW_BANNER:
      return {
        ...state,
        headerBanner: action.payload,
      }
    case CONSTANTS.SET_HIDE_BANNER:
      return {
        ...state,
        headerBanner: {
          show: false,
          text: state.headerBanner.text,
        },
      }
    case CONSTANTS.SET_ACTIVATED_FEATURES:
      return {
        ...state,
        activatedFeature: action.payload,
      }
    case CONSTANTS.SET_ACTIVATED_FEATURES_LOADED:
      return {
        ...state,
        activatedFeatureLoaded: true,
      }
    default:
      return state
  }
}

export default auth
