import moment from 'moment-timezone'
import { CONSTANTS } from 'constants/index'
import { convetAmPmTo24FormatTime, replaceTime } from 'utils/calendar'
import { getDecimalFromTimeAndMinutes, getHoursFromDecimal, getMinutesFromDecimal } from '../../getters/activities'
import { isInFutureCaseInvoice } from '../../cases/casesGetters'

export const mapWorkingDaysToBack = workingDays => {
  return workingDays.map(day => {
    return {
      weekday: day.weekday,
      is_available: day.isAvailable,
      working_hours: day.workingHours.map(hours => ({
        from_hour: hours.fromHour,
        to_hour: hours.toHour,
      })),
    }
  })
}

export const mapWorkingDaysToFront = workingDays => {
  const mappedWorkingDays = workingDays.map(day => {
    return {
      weekday: day.weekday,
      isAvailable: day.is_available,
      workingHours: day.working_hours.map(hours => ({
        fromHour: hours.from_hour,
        toHour: hours.to_hour,
        error: '',
      })),
    }
  })
  return [mappedWorkingDays.pop(), ...mappedWorkingDays]
}

export const mapWorkingDaysFlatToFront = workingDays => {
  const sunday = 6
  return workingDays.map(day => {
    const endTime = convetAmPmTo24FormatTime(day.to_hour)
    const weekday = day.weekday === sunday ? 0 : day.weekday + 1
    return {
      daysOfWeek: [weekday],
      startTime: convetAmPmTo24FormatTime(day.from_hour),
      endTime: endTime === '00:00' ? '23:59' : endTime,
    }
  })
}

export const mapMeetingsToFront = meeting => {
  return {
    startTime: meeting.start_time,
    endTime: meeting.end_time,
    duration: meeting.duration,
    proName: meeting.pro_full_name,
    clientName: meeting.client_full_name,
    proPhoto: meeting.pro_photo,
    clientPhoto: meeting.client_photo,
    joinUrl: meeting.join_url,
    meetingStatus: meeting.status_meeting,
    meetingId: meeting.meeting_id,
  }
}

export const mapPaymentCardToFront = card => {
  return {
    uuid: card.payment_method_id,
    brand: card.brand,
    lastDigits: card.last_4_digits,
    expirationMonth: card.expiration_month,
    expirationYear: card.expiration_year,
    cardHolder: card.card_holder,
    label: card.label,
    isDefault: card.is_default,
    rememberCard: card.remember_card,
    paymentMethodId: card.payment_method_id,
  }
}

const mapContactInfoToFront = contactInfo => ({
  street: contactInfo.street,
  suite: contactInfo.suite,
  city: contactInfo.city,
  zip: contactInfo.zip,
  country: contactInfo.country,
  state: contactInfo.state,
  phone: contactInfo.phone,
  optionalPhone: contactInfo.optional_phone,
})

const mapContactInfoToBack = contactInfo => ({
  ...contactInfo,
  optional_phone: contactInfo.optionalPhone,
})

const mapUserInfoToFront = userInfo => ({
  firstName: userInfo.first_name,
  lastName: userInfo.last_name,
  username: userInfo.username,
  photo: userInfo.photo,
  isDeleted: userInfo.is_deleted,
  contactInfo: mapContactInfoToFront(userInfo.contact_info || {}),
})

const mapUserInfoToBack = userInfo => ({
  first_name: userInfo.firstName,
  last_name: userInfo.lastName,
  username: userInfo.username,
  photo: userInfo.photo,
  contact_info: mapContactInfoToBack(userInfo.contactInfo || {}),
})

export const mapMeetingInfoToFront = meeting => {
  return {
    startTime: meeting.start_time ? moment.utc(meeting.start_time * 1000).toDate() : null,
    endTime: meeting.end_time ? moment.utc(meeting.end_time * 1000).toDate() : null,
    ...getMeetingGeneralInfo(meeting),
  }
}

export const mapExternalMeetingInfoToFront = meeting => {
  return {
    is_external_event: meeting.is_external_event,
    is_cancelled: meeting.is_cancelled,
    description: meeting.description,
    name: meeting.title,
    start: meeting.start_time ? moment.utc(meeting.start_time * 1000).toDate() : null,
    end: meeting.end_time ? moment.utc(meeting.end_time * 1000).toDate() : null,
    allDay: meeting.is_all_day,
    calendar_name: meeting.calendar_name,
    hasClientFolder: meeting.has_client_folder,
  }
}

export const mapProMeetingInfoToFront = meeting => {
  return {
    start: meeting.start_time ? moment.utc(meeting.start_time * 1000).toDate() : null,
    end: meeting.end_time ? moment.utc(meeting.end_time * 1000).toDate() : null,
    ...getMeetingGeneralInfo(meeting),
  }
}

const getMeetingGeneralInfo = meeting => ({
  uuid: meeting.uuid,
  pro: meeting.pro,
  client: meeting.client_uuid,
  hasVaultCases: meeting.has_cases_except_initial,
  vaultPlanAccepted: meeting.is_accepted_pro_vault_plan,
  proInfo: mapUserInfoToFront(meeting.pro_info || {}),
  clientInfo: mapUserInfoToFront(meeting.client_info || {}),
  clientTimezone: meeting.client_timezone,
  practiceArea: meeting.practice_area,
  practiceAreaName: meeting.practice_area_name,
  subSpeciality: meeting.sub_speciality,
  subSpecialityName: meeting.sub_speciality_name,
  price: meeting.price,
  cancellationFee: meeting.cancellation_fee,
  isInitial: meeting.is_initial,
  meetingUrl: meeting.meeting_url,
  isUpdateButtonVisible: meeting.is_update_button_visible,
  updateReason: meeting.update_reason,
  status: meeting.status,
  paymentMethodId: meeting.payment_method_id,
  twilio_chat_sid: meeting.twilio_chat_sid,
  isInitialMeetingSuccessfull: meeting.is_ready_for_confidential,
  readyForMessages: meeting.is_ready_for_confidential,
  hasClientFolder: meeting.has_client_folder,
})

export const mapConnectionInfoToFront = connection => {
  return {
    pro: connection.uuid,
    hasClientFolder: connection.has_client_folder,
    hasVaultCases: connection.has_cases_except_initial,
    vaultPlanAccepted: connection.is_accepted_pro_vault_plan,
    proInfo: mapUserInfoToFront(connection),
    twilio_chat_sid: connection.twilio_chat_sid,
    isInitialMeetingSuccessfull: connection.is_ready_for_confidential,
    readyForMessages: connection.is_ready_for_confidential,
    isRecommended: connection.is_recommended,
  }
}

export const mapProConnectionInfoToFront = connection => {
  return {
    client: connection.uuid,
    clientInfo: mapUserInfoToFront(connection),
    hasClientFolder: connection.has_client_folder,
    hasCases: connection.has_cases,
    hasActiveCases: connection.has_active_cases,
    hasVaultCases: connection.has_cases_except_initial,
    enableTimeActivities: connection.has_time_and_expence_cases,
    enableExpenseActivities:
      connection.has_time_and_expence_cases || connection.has_contigency_cases || connection.has_flat_fee_cases,
    twilio_chat_sid: connection.twilio_chat_sid,
    isInitialMeetingSuccessfull: connection.is_ready_for_confidential,
    readyForMessages: connection.is_chat_available,
    isReadyForConfidential: connection.is_ready_for_confidential,
    invitedUserID: connection.invited_user_id,
    isInvited: connection.is_invited_client,
    isCaseCreationAvailable: connection.is_case_creation_available,
  }
}

export const mapConnectionFilterToFront = connection => {
  return {
    label: `${connection.first_name} ${connection.last_name}`,
    value: connection.uuid,
    enableTimeActivities: connection.has_time_and_expence_cases,
    enableExpenseActivities:
      connection.has_time_and_expence_cases || connection.has_contigency_cases || connection.has_flat_fee_cases,
  }
}

export const mapCaseDataToBack = (data, calendar) => {
  const payload = {
    client: data.client,
    initial_case: data.initial_case,
    name: data.name,
    practice_area: data.practice_area,
    sub_speciality: data.sub_speciality,
    description: data.description,
    date_of_creation: moment(data.date_of_creation).format(),
    fee_type: data.fee_type,
  }

  switch (data.fee_type) {
    case CONSTANTS.FEE_TYPES[1].key:
      payload.invoicing_sections = data.invoicing_sections.reduce((result, section) => {
        if (!section.invoice_amount && !section.invoice_date) {
          return result
        }

        const timezone = calendar.advanced_calendar.timezone
        const isInFuture = isInFutureCaseInvoice(section, timezone, false)

        const invoiceDate =
          section.uuid && !isInFuture
            ? section.invoice_date
            : moment(section.invoice_date)
                .tz(timezone)
                .endOf('d')
                .utc()

        const data = {
          invoice_amount: section.invoice_amount,
          invoice_date: invoiceDate,
        }

        if (section.uuid) {
          data.uuid = section.uuid
        }

        return [...result, data]
      }, [])
      payload.amount = data.amount
      payload.expense_invoicing = data.expense_invoicing
      if (data.expense_invoicing) {
        payload.invoicing = data.invoicing
      }
      if (data.expense_invoicing && data.invoicing === CONSTANTS.INVOICING[2].value) {
        payload.invoice_frequency = data.invoice_frequency
      }
      break
    case CONSTANTS.FEE_TYPES[2].key:
      payload.expense_invoicing = data.expense_invoicing
      if (data.expense_invoicing) {
        payload.invoicing = data.invoicing
      }
      if (data.expense_invoicing && data.invoicing === CONSTANTS.INVOICING[2].value) {
        payload.invoice_frequency = data.invoice_frequency
      }
      break
    case CONSTANTS.FEE_TYPES[3].key:
      payload.hourly_rate = data.hourly_rate
      payload.invoicing = data.invoicing
      if (data.invoicing === CONSTANTS.INVOICING[2].value) {
        payload.invoice_frequency = data.invoice_frequency
      }
      break
  }

  return payload
}

export const mapActivityDataToBack = (data, calendar) => {
  const invoiceDate =
    data.invoiceDate &&
    (moment(data.invoiceDate).isBefore(moment())
      ? moment()
          .tz(calendar.advanced_calendar.timezone)
          .endOf('d')
          .utc()
      : moment(data.invoiceDate)
          .tz(calendar.advanced_calendar.timezone)
          .endOf('d')
    ).utc()
  const creationDate = moment(data.creationDate).isAfter(moment())
    ? moment().toISOString()
    : moment(data.creationDate).toISOString()
  return {
    case: data.caseId,
    date_of_creation: creationDate,
    activity_type: data.activityType,
    non_billable: !data.billable,
    no_charge: data.charge === null ? null : !data.charge,
    time_duration: data.activityType ? null : getDecimalFromTimeAndMinutes(data.hours, data.minutes),
    hourly_rate: data.rate,
    description: data.description,
    invoice_date: invoiceDate,
    expense_amount: data.expense,
    client_charge: data.clientCharge === null ? null : data.clientCharge.toString().trim() || null,
  }
}

export const mapActivityDataToFront = (data, calendar) => {
  return {
    caseId: data.case.uuid,
    uuid: data.uuid,
    case: data.case,
    clientId: data.case.client,
    activityType: data.activity_type,
    billable: !data.non_billable,
    charge: data.no_charge === null ? null : !data.no_charge,
    hours: getHoursFromDecimal(data.time_duration),
    minutes: getMinutesFromDecimal(data.time_duration),
    rate: data.hourly_rate,
    creationDate: data.date_of_creation,
    description: data.description,
    invoiceDate: data.invoice_date,
    invoice: data.invoice,
    expense: data.expense_amount,
    clientCharge: data.client_charge,
    editMode: data.edit_mode,
    updatedAt: data.updated_at,
    isClientDeleted: data.case.is_client_deleted,
  }
}

export const getEventsForCalendarView = (data = [], view, timezone) => {
  if (view === 'timeGridWeek') {
    return replaceTime(data, timezone)
  } else if (view === 'listThirtyDaysView') {
    return data.filter(
      event =>
        event.status !== CONSTANTS.EVENT_STATUS.CANCELED_BY_PRO &&
        event.status !== CONSTANTS.EVENT_STATUS.CANCELED_BY_CLIENT &&
        event.status !== CONSTANTS.EVENT_STATUS.DELETED_CANCELED_MEETING_BY_CLIENT &&
        !event.is_external_event
    )
  }
  return []
}

export const mapInvoiceDataToFront = data => {
  return {
    uuid: data.uuid,
    invoiceNumber: data.generated_invoice_number,
    case: data.case,
    caseName: data.case_name,
    caseAmount: data.case_amount,
    caseFeeType: data.case_fee_type,
    caseIsActive: data.is_case_active,
    proInfo: mapUserInfoToFront(data.pro_info || {}),
    clientInfo: mapUserInfoToFront(data.client_info || {}),
    clientFullName: data.client_full_name,
    clientUsername: data.client_username,
    clientContactInfo: data.client_contact_info,
    proFullName: data.pro_full_name,
    timeSubtotal: data.time_subtotal,
    expenseSubtotal: data.expense_subtotal,
    caseAmountPaidToDateSubtotal: data.case_amount_paid_to_date,
    invoiceAmount: data.invoice_amount,
    invoiceDate: data.issued_at,
    invoiceAmountWithCredit: data.invoice_amount_with_credit,
    creditAmount: data.credit_amount,
    status: data.status,
    statusForDisplay: data.status_for_display,
    activities: data.activities,
    isPayButtonState: data.pay_button_state,
    isReadyForPayout: data.is_ready_for_payout,
    paymentMethodId: data.payment_method_id,
    failureReason: data.fail_reason,
    amountPaid: data.amount_paid,
    amountDue: data.amount_due,
    isPaymentRecordAvailable: data.can_create_payment_record,
    isAdjustmentRecordAvailable: data.can_create_adjustment,
    isWriteOffAvailable: data.is_write_off_option_available,
    isCreditAdjustmentAvailable: data.can_create_credit_adjustment,
    file_link: data.file_link,
  }
}

export const mapCreditAdjustmentToFront = credit => {
  return {
    amount: credit.amount,
    createdAt: credit.created_at,
    description: credit.description,
    number: credit.generated_credit_adjustment_number,
    currentInvoiceAmount: credit.current_invoice_amount,
    previousInvoiceAmount: credit.previous_invoice_amount,
  }
}

export const mapInvoiceDataToBack = data => {
  return {
    uuid: data.uuid,
    generated_invoice_number: data.invoiceNumber,
    case: data.case,
    case_name: data.caseName,
    case_amount: data.caseAmount,
    case_fee_type: data.caseFeeType,
    pro_info: mapUserInfoToBack(data.proInfo || {}),
    client_info: mapUserInfoToBack(data.clientInfo || {}),
    time_subtotal: data.timeSubtotal,
    expense_subtotal: data.expenseSubtotal,
    case_amount_paid_to_date: data.caseAmountPaidToDateSubtotal,
    invoice_amount: data.invoiceAmount,
    issued_at: data.invoiceDate,
    invoice_amount_with_credit: data.invoiceAmountWithCredit,
    credit_amount: data.creditAmount,
    credit_adjustments: data.creditAdjustments,
    status: data.status,
    status_for_display: data.statusForDisplay,
    activities: data.activities,
  }
}

export const mapUserMessages = messages => {
  return messages.reduce((resultMessages, message) => {
    return newMessageMapper(message, resultMessages)
  }, [])
}

export const newMessageMapper = (message, resultMessages) => {
  if (!resultMessages.length) {
    return [
      {
        date: message.timestamp,
        messages: [{ authorId: message.author, messages: [message] }],
      },
    ]
  }

  if (moment(message.timestamp).diff(resultMessages[resultMessages.length - 1].date, 'days')) {
    return [
      ...resultMessages,
      {
        date: message.timestamp,
        messages: [{ authorId: message.author, messages: [message] }],
      },
    ]
  } else {
    const temp = [...resultMessages]
    const lastDay = temp.pop()
    const numberOfMessagesInDay = lastDay.messages.length
    const lastMessage = lastDay.messages[numberOfMessagesInDay - 1]

    if (lastMessage.authorId !== message.author) {
      return [
        ...temp,
        {
          ...lastDay,
          messages: [...lastDay.messages, { authorId: message.author, messages: [message] }],
        },
      ]
    } else {
      lastDay.messages[numberOfMessagesInDay - 1].messages.push(message)
      return [...temp, lastDay]
    }
  }
}

export const mergeMessages = (oldMessages, newMessages) => {
  if (moment(oldMessages[oldMessages.length - 1].date).diff(newMessages[0], 'days')) {
    return [...oldMessages, ...newMessages]
  } else {
    const lastOldMessageDay = oldMessages.pop()
    let [firstNewMessageDay, ...restNewMessages] = newMessages

    if (
      lastOldMessageDay.messages[lastOldMessageDay.messages.length - 1].authorId !==
      firstNewMessageDay.messages[0].authorId
    ) {
      firstNewMessageDay = {
        ...firstNewMessageDay,
        messages: [...lastOldMessageDay.messages, ...firstNewMessageDay.messages],
      }
      return [...oldMessages, firstNewMessageDay, ...restNewMessages]
    } else {
      const lastOldMessageDayAuthorMessage = lastOldMessageDay.messages.pop()
      firstNewMessageDay.messages[0].messages = [
        ...lastOldMessageDayAuthorMessage.messages,
        ...firstNewMessageDay.messages[0].messages,
      ]
      firstNewMessageDay = {
        ...firstNewMessageDay,
        messages: [...lastOldMessageDay.messages, ...firstNewMessageDay.messages],
      }
      return [...oldMessages, firstNewMessageDay, ...restNewMessages]
    }
  }
}

export const updateMessageBody = (message, messages) => {
  return messages.map(messageDayObject => {
    if (moment(message.timestamp).diff(messageDayObject.date, 'days')) {
      return messageDayObject
    }
    return {
      ...messageDayObject,
      messages: messageDayObject.messages.map(authorMessage => {
        if (authorMessage.authorId !== message.author) {
          return authorMessage
        }
        return {
          ...authorMessage,
          messages: authorMessage.messages.map(messageObject => {
            if (messageObject.sid !== message.sid) {
              return messageObject
            }
            return message
          }),
        }
      }),
    }
  })
}

export const mapRatingAnalytics = data => {
  return {
    id: data.uuid,
    lawyer_email: data.pro_email,
    type: data.questionnaire.rating_type,
    content_XIRA_questions: !!(data.questionnaire.xira_questions && data.questionnaire.xira_questions.length),
    content_lawyer_questions: !!(data.questionnaire.questions && data.questionnaire.questions.length),
    number_of_questions:
      (data.questionnaire.xira_questions ? data.questionnaire.xira_questions.length : 0) +
      (data.questionnaire.questions ? data.questionnaire.questions.length : 0),
    contains_comment: data.questionnaire.xira_comment_enabled,
  }
}

export const mapRatingSubmitAnalytics = (state, payload) => {
  const numberOfQuestions =
    (state.questionnaire.xira_questions ? state.questionnaire.xira_questions.length : 0) +
    (state.questionnaire.questions ? state.questionnaire.questions.length : 0)
  const numberOfAnsweredQuestions =
    (payload.user_questions ? payload.user_questions.length : 0) +
    (payload.xira_questions ? payload.xira_questions.length : 0)
  return {
    id: state.uuid,
    lawyer_email: state.pro_email,
    type: state.questionnaire.rating_type,
    content_XIRA_questions: !!(state.questionnaire.xira_questions && state.questionnaire.xira_questions.length),
    content_lawyer_questions: !!(state.questionnaire.questions && state.questionnaire.questions.length),
    number_of_questions: numberOfQuestions,
    contains_comment: state.questionnaire.xira_comment_enabled,
    part_of_answered_questions:
      numberOfQuestions && numberOfAnsweredQuestions ? numberOfAnsweredQuestions / numberOfQuestions : 0,
    provided_comments: !!(payload.comment || payload.xira_comment),
  }
}
