import React, { useEffect } from 'react'
import { Typography, Button, PriceSummary, Divider, CancellationInfo, PaymentInfo } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { getClientPaymentSecret } from 'store/actions/payments'
import { useDispatch } from 'react-redux'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  title: {
    fontSize: 16,
    marginBottom: 17,
  },
  btnContainer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnSubmit: {
    width: 103,
    marginLeft: 30,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  icon: {
    marginRight: 7,
  },
})
const MeetingSchedulerConfirmation = ({
  cancelButton,
  onBack,
  proAccount,
  start,
  practiceArea,
  clientPaymentSecretKey,
  clientSecretIsLoading,
  userCards,
  paymentCard,
  setPaymentCard,
  onSubmit,
  isSubmitMeetingData,
  stripeError,
  cancellationFee,
  initialPrice,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getClientPaymentSecret())
  }, [])
  return (
    <div>
      <Typography classes={{ h4: classes.title }} variant="h4">
        Confirmation
      </Typography>
      <PriceSummary proAccount={proAccount} initialPrice={initialPrice} showTooltip />
      <Divider />
      <CancellationInfo
        proAccount={proAccount}
        start={start}
        practiceArea={practiceArea}
        cancellationFee={cancellationFee}
      />
      <Divider />
      <PaymentInfo
        clientPaymentSecretKey={clientPaymentSecretKey}
        clientSecretIsLoading={clientSecretIsLoading}
        userCards={userCards}
        paymentCard={paymentCard}
        setPaymentCard={setPaymentCard}
        stripeError={stripeError}
      />
      <div className={classes.btnContainer}>
        {cancelButton}
        <div>
          <Button variant="text" onClick={onBack} disabled={isSubmitMeetingData}>
            back
          </Button>
          <Button
            onClick={onSubmit}
            className={classes.btnSubmit}
            variant="primary"
            isButtonBlocked={isSubmitMeetingData}
            disabled={isSubmitMeetingData}
            circularSize={22}
          >
            confirm
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MeetingSchedulerConfirmation
