import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, SvgIcon, Typography } from 'components'
import { useDispatch } from 'react-redux'
import { resendVerificationLink } from 'store/actions/auth'
import { ReactComponent as VerifyEmail } from 'static/il_verify_email.svg'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 170px',
    marginTop: 40,
    alignItems: 'center',
    '@media(max-width:767px)': {
      margin: '24px 0',
      padding: '0 10px',
    },
  },
  subtitle: {
    marginBottom: 40,
    fontSize: 18,
    lineHeight: 1.44,
    color: colors.BASIC.COLOR[100],
    textAlign: 'center',
    '@media(max-width:767px)': {
      width: 243,
      marginBottom: 32,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: 1.19,
    color: colors.BASIC.COLOR[80],
    marginBottom: 32,
    '@media(max-width:767px)': {
      fontSize: 24,
      marginBottom: 12,
    },
  },
  advise: {
    fontSize: 13,
    lineHeight: 1.46,
    color: colors.BASIC.COLOR[80],
    '@media(max-width:767px)': {
      width: '100%',
    },
  },
  link: {
    fontSize: 13,
    lineHeight: 1.46,
    fontWeight: 500,
  },
  email: {
    fontWeight: 500,
  },
  image: {
    height: 200,
    width: 308,
    marginBottom: 60,
  },
  content: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '@media(max-width:767px)': {
      width: '100%',
    },
  },
})

const VerifyEmailPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const signUpEmail = window.sessionStorage.getItem('verifyEmail')
  const isInvited = window.sessionStorage.getItem('isInvited')

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          verify your email
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {isInvited === 'true' ? (
            <>
              you have been already invited by a lawyer to XIRA. Please check your email and click the verification
              button to complete your registration and be connected with your lawyer
            </>
          ) : (
            <>
              click the verification button provided in the email <span className={classes.email}>{signUpEmail}</span>{' '}
              we sent to complete your registration
            </>
          )}
        </Typography>
        <SvgIcon viewBox="0 0 308 200" className={classes.image}>
          <VerifyEmail />
        </SvgIcon>
        <Typography variant="subtitle1" className={classes.advise}>
          haven&apos;t received verification email yet?
        </Typography>
        <Link className={classes.link} onClick={() => dispatch(resendVerificationLink(isInvited))}>
          resend verification email
        </Link>
      </div>
    </div>
  )
}

export default VerifyEmailPage
