import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  Icon,
  CalendarList,
  XiraCalendar,
  ColorCircularProgress,
  ConfirmationModal,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import {
  disconnectCalendar,
  setXIRACalendar,
  updateCalendar,
  getConnectedCalendarList,
  disconnectAllCalendars,
} from 'store/actions/calendar'
import { openSnackbar } from 'store/actions/common'
import DisconnectCalendarModal from './DisconectCalendarModal'
import UpdateCalendarModal from './UpdateCalendarModal'
import ConnectCalendarModal from './ConnectCalendarModal'
import XIRACalendarModal from './XIRACalendarModal'
import { connect } from 'react-redux'
import { getAvailableForConnectionCalendars } from 'store/selectors/calendarSelector'
import { updatedConnectedCalendarList } from 'store/actions/calendar'

const useStyles = makeStyles({
  calendarContainer: {
    height: '100%',
    backgroundColor: colors.WHITE,
  },
  calendarContainerPadding: {
    padding: '24px 0',
  },
  addCalendarBtn: {
    height: 'auto',
    padding: '13px 24px',
    letterSpacing: 0.6,
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 1,
  },
  icon: {
    fontSize: 16,
    marginRight: 8,
    marginBottom: 1,
  },
  btnContainer: {
    display: 'flex',
    paddingTop: 24,
    alignItems: 'center',
  },
  hint: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 20,
    marginLeft: 8,
  },
})

const CalendarConnections = ({ disablePadding = false, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    availableCalendarList,
    connectedCalendars,
    xiraCalendar,
    localXiraCalendar,
    isCalendarListLoading,
    isCalendarConnecting,
    selectedCalendarList,
    availableForConnectionCalendars,
    calendarsConnectionError,
  } = props
  const [connectModalOptions, setConnectModalOptions] = useState({ open: false })
  const [xiraModalOptions, setXIRAModalOptions] = useState({ open: false })
  const [calendarModalOptions, setCalendarModalOptions] = useState({ calendar: null, open: false })
  const [reconnectModalOptons, setReconnectModalOptions] = useState({ open: false })
  const [disconnectModalOptions, setDisconnectModalOptions] = useState({ calendar: null, open: false })

  useEffect(() => {
    dispatch(getConnectedCalendarList())
  }, [])

  const onModalSave = () => {
    dispatch(updateCalendar(calendarModalOptions.calendar))
    setCalendarModalOptions({ calendar: null, open: false })
  }
  const onDisconnectBtnClick = calendar => {
    setCalendarModalOptions({ calendar: null, open: false })
    setDisconnectModalOptions({ calendar, open: true })
  }
  const onDisconnect = type => {
    setDisconnectModalOptions({ calendar: null, open: false })
    dispatch(disconnectCalendar(type))
  }

  const onSubCalendarListChange = (id, value) => {
    const calendars = calendarModalOptions.calendar.calendars.map(subCalendar =>
      subCalendar.id === id ? { ...subCalendar, selected: value } : subCalendar
    )
    setCalendarModalOptions({
      ...calendarModalOptions,
      calendar: { ...calendarModalOptions.calendar, calendars: calendars },
    })
  }
  const onXIRAModalSave = () => {
    dispatch(setXIRACalendar(localXiraCalendar))
    setXIRAModalOptions({ open: false })
  }

  const onXIRAModalOpen = () => {
    dispatch(updatedConnectedCalendarList())
    setXIRAModalOptions({ open: true })
  }

  const onReconnectConfirm = async () => {
    setReconnectModalOptions({ open: false })
    try {
      await dispatch(disconnectAllCalendars())
      setConnectModalOptions({ open: true })
    } catch (e) {
      dispatch(openSnackbar('error', 'Error while calendar reconnect'))
    }
  }

  const getReconnectMessage = () =>
    'Click Continue to reconnect your calendar. If you have more than one calendar, reconnect all calendars'

  return isCalendarListLoading && !connectModalOptions.open ? (
    <ColorCircularProgress />
  ) : (
    <div
      className={classNames({ [classes.calendarContainer]: true, [classes.calendarContainerPadding]: !disablePadding })}
    >
      <Typography variant="h3">Calendar connections</Typography>
      <Typography variant="subtitle1">
        Connect your calendar so clients can book meetings with you on your XIRA calendar
      </Typography>

      <CalendarList
        mode="view"
        calendarsList={connectedCalendars}
        setCalendarModalOptions={setCalendarModalOptions}
        setReconnectModalOptions={setReconnectModalOptions}
      />
      {availableCalendarList.length !== connectedCalendars.length &&
        (!calendarsConnectionError || connectedCalendars.length === 0) && (
          <div className={classes.btnContainer}>
            <Button
              className={classes.addCalendarBtn}
              onClick={() => setConnectModalOptions({ open: true })}
              variant={connectedCalendars.length ? 'secondary' : 'primary'}
            >
              {!connectedCalendars.length && <Icon style={classes.icon} iconClass="las la-plus-circle" />}
              {connectedCalendars.length ? 'add another calendar?' : 'add my calendar'}
            </Button>
          </div>
        )}
      {!!connectedCalendars.length && <XiraCalendar xiraCalendar={xiraCalendar} onEditClick={onXIRAModalOpen} />}
      <UpdateCalendarModal
        open={calendarModalOptions.open}
        calendar={calendarModalOptions.calendar}
        onSave={onModalSave}
        xiraCalendar={xiraCalendar}
        onDisconnect={onDisconnectBtnClick}
        onSubCalendarListChange={onSubCalendarListChange}
        isLastCalendar={connectedCalendars.length === 1}
        onClose={() => setCalendarModalOptions({ calendar: null, open: false })}
      />

      <DisconnectCalendarModal
        open={disconnectModalOptions.open}
        calendar={disconnectModalOptions.calendar}
        onDisconnect={onDisconnect}
        isLastCalendar={connectedCalendars.length === 1}
        onClose={() => setDisconnectModalOptions({ calendar: null, open: false })}
      />
      <ConnectCalendarModal
        localXiraCalendar={localXiraCalendar}
        selectedCalendarList={selectedCalendarList}
        availableForConnectionCalendars={availableForConnectionCalendars}
        isCalendarLoading={isCalendarConnecting}
        xiraCalendar={xiraCalendar}
        open={connectModalOptions.open}
        onClose={() => setConnectModalOptions({ open: false })}
      />
      <XIRACalendarModal
        open={xiraModalOptions.open}
        onSave={onXIRAModalSave}
        saveDisabled={!localXiraCalendar.id}
        onClose={() => setXIRAModalOptions({ open: false })}
      />
      <ConfirmationModal
        title="Reconnect calendars"
        message={getReconnectMessage()}
        isOpen={reconnectModalOptons.open}
        onClose={() => setReconnectModalOptions({ open: false })}
        onConfirm={onReconnectConfirm}
        confirmationButtonText="Continue"
      />
    </div>
  )
}

const mapStateToProps = state => ({
  availableCalendarList: state.calendar.availableCalendarList,
  connectedCalendars: state.calendar.connectedCalendars,
  xiraCalendar: state.calendar.xiraCalendar,
  localXiraCalendar: state.calendar.localXiraCalendar,
  isCalendarListLoading: state.calendar.isCalendarListLoading,
  isCalendarConnecting: state.calendar.isCalendarConnecting,
  selectedCalendarList: state.calendar.readyForConnectCalendars,
  calendarsConnectionError: state.calendar.calendarsConnectionError,
  availableForConnectionCalendars: getAvailableForConnectionCalendars(state),
})

export default connect(mapStateToProps)(CalendarConnections)
