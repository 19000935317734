import React from 'react'
import { Tab, Tabs as MUITabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index.js'

const useStyles = makeStyles({
  tabRoot: {
    height: 64,
    paddingLeft: 10,
    paddingRight: 10,
    color: colors.BASIC.COLOR[50],
  },
  tabSelected: {
    color: colors.BASIC.COLOR[80],
  },
  tabWrapper: {
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: 'inherit',
  },
  tabsInicator: {
    backgroundColor: colors.BASIC.COLOR[80],
  },
})

// Currently this component is not in use, but we leave it for future
const Tabs = ({ tabs, handleTabChange, selectedTab, className }) => {
  const classes = useStyles()

  const getTab = tab => {
    return (
      <Tab
        classes={{
          root: classes.tabRoot,
          wrapper: classes.tabWrapper,
          selected: classes.tabSelected,
        }}
        key={tab.key}
        label={tab.label}
      />
    )
  }

  const renderTabs = () => {
    return tabs.map(tab => getTab(tab))
  }

  return (
    <MUITabs
      className={className}
      classes={{
        indicator: classes.tabsInicator,
      }}
      value={selectedTab}
      onChange={handleTabChange}
    >
      {renderTabs()}
    </MUITabs>
  )
}

export default Tabs
