import React, { useState, useEffect } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { Select, Typography, Hint, Icon, ColorCircularProgress } from 'components'
import { CONSTANTS, colors } from 'constants/index'
import { setLocalXIRACalendar } from 'store/actions/calendar'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emailLabel: {
    marginLeft: 16,
    marginRight: 35,
  },
  radioContainer: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    width: 250,
  },
  icon: {
    marginRight: '8px',
  },
  error: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
})
const Label = ({ logo, username, containerClass, labelClass }) => (
  <div className={containerClass}>
    {logo}
    <Typography className={labelClass}>{username}</Typography>
  </div>
)

const XIRACalendarList = () => {
  const localXiraCalendar = useSelector(state => state.calendar.localXiraCalendar)
  const connectedCalendars = useSelector(state => state.calendar.connectedCalendars)
  const readyForConnectCalendars = useSelector(state => state.calendar.readyForConnectCalendars)
  const [calendarsList, setCalendarsList] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (!localXiraCalendar.provider && calendarsList[0])
      dispatch(setLocalXIRACalendar({ id: '', name: '', account: calendarsList[0].uuid, username: '' }))
  }, [calendarsList])

  useEffect(() => {
    setCalendarsList([...connectedCalendars, ...readyForConnectCalendars])
  }, [connectedCalendars, readyForConnectCalendars])

  const onProviderOnChange = account => {
    setErrorMessage(null)
    dispatch(setLocalXIRACalendar({ account, name: '', id: '', username: '' }))
  }
  const onSelectOnChange = (value, calendars, username) => {
    if (value) setErrorMessage(null)
    const connectedCalendar = calendars.find(calendar => calendar.id === value)
    const { name, id, can_edit } = connectedCalendar ? connectedCalendar : {}
    dispatch(setLocalXIRACalendar({ ...localXiraCalendar, id, name, username, can_edit }))
  }

  const getSelectOptions = calendar => {
    return calendar.calendars
      .filter(cal => cal.can_edit)
      .map(calendar => ({ label: calendar.name, value: calendar.id }))
  }
  return (
    <>
      {errorMessage && (
        <Hint classes={{ subtitle2: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {errorMessage}
        </Hint>
      )}
      <FormControl component="fieldset">
        <RadioGroup value={localXiraCalendar.account || ''} onChange={e => onProviderOnChange(e.target.value)}>
          {calendarsList &&
          calendarsList.map(calendar => {
              const calendarSettings = CONSTANTS.CALENDAR_SETTINGS[calendar.provider]
              return (
                <div key={calendar.provider} className={classes.radioContainer}>
                  <FormControlLabel
                    value={calendar.uuid}
                    control={<Radio color="primary" />}
                    label={
                      <Label
                        logo={calendarSettings.calendarLogo}
                        username={calendar.username}
                        labelClass={classes.emailLabel}
                        containerClass={classes.labelContainer}
                      />
                    }
                  />
                  {localXiraCalendar.account === calendar.uuid && (
                    <Select
                      className={classes.select}
                      error={errorMessage}
                      value={localXiraCalendar.id}
                      variant="outlined"
                      onChange={e => onSelectOnChange(e.target.value, calendar.calendars, calendar.username)}
                      options={getSelectOptions(calendar)}
                    />
                  )}
                </div>
              )
            })}
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default XIRACalendarList
