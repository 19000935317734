function checkBrowserVersion() {
  let ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    M[0] = 'MSIE'
    M[1] = tem[1] || ''
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) {
      tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
      M = tem
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  let old = true
  switch (M[0]) {
    case 'Chrome':
      // if (parseInt(M[1]) >= 30) old = false
      old = false // Android apps use chrome
      break
    case 'Firefox':
      if (parseInt(M[1]) >= 30) old = false
      break
    case 'Safari':
      if (parseInt(M[1]) >= 7) old = false
      break
    case 'Edge':
      old = true
      break
    case 'OPR':
      if (parseInt(M[1]) >= 15) old = false
      break
    case 'Netscape':
      old = false // IOS apps
      break
    default:
      old = true
  }
  return old
}

export { checkBrowserVersion }
