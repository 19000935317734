import { createSelector } from 'reselect'

const getAvailableCalendarList = state => state.calendar.availableCalendarList

const getConnectedCalendarList = state => state.calendar.connectedCalendars

export const getAvailableForConnectionCalendars = createSelector(
  [getAvailableCalendarList, getConnectedCalendarList],
  (availableCalendarList, connectedCalendars) =>
    availableCalendarList.filter(
      calendar => !connectedCalendars.find(connectedCalendar => connectedCalendar.provider === calendar)
    )
)
