import React from 'react'
import TextField from '@material-ui/core/TextField'

const XTextField = ({ InputProps, id, variant, placeholder, type, metaError, externalError, ...props }) => {
  return (
    <TextField
      InputProps={InputProps}
      id={id}
      variant={variant}
      type={type}
      placeholder={placeholder}
      error={externalError}
      {...props}
    />
  )
}

export default XTextField
