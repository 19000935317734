import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ColorCircularProgress, Typography } from 'components'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { formatNegativeNumber } from 'utils/formatters'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    position: 'relative',
    minHeight: 100,
  },
  card: {
    backgroundColor: colors.BASIC.COLOR[0],
    padding: 24,
    marginRight: 24,
    flexGrow: 1,
  },
  activeCard: {
    backgroundColor: 'rgba(76, 175, 80, 0.05)',
    padding: 24,
    flexGrow: 1,
  },
  label: {
    fontSize: 13,
    marginBottom: 8,
  },
  value: {
    fontSize: 24,
    fontWeight: 500,
  },
  activeText: {
    color: colors.SUCCESS,
  },
})

const WalletRevenue = () => {
  const classes = useStyles()
  const revenue = useSelector(state => state.wallet.walletSummary)
  const walletSummaryIsLoading = useSelector(state => state.wallet.walletSummaryIsLoading)

  return (
    <div className={classes.row}>
      {walletSummaryIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <div className={classes.card}>
            <Typography className={classes.label}>YTD revenue</Typography>
            <Typography className={classes.value}>{formatNegativeNumber(revenue.ytd_revenue, '$')}</Typography>
          </div>
          <div className={classes.card}>
            <Typography className={classes.label}>Funds pending</Typography>
            <Typography className={classes.value}>{formatNegativeNumber(revenue.pending_funds_sum, '$')}</Typography>
          </div>
          <div className={classes.card}>
            <Typography className={classes.label}>Charges pending</Typography>
            <Typography className={classes.value}>{formatNegativeNumber(revenue.pending_charges_sum, '$')}</Typography>
          </div>
          <div className={classes.activeCard}>
            <Typography className={classNames({ [classes.activeText]: true, [classes.label]: true })}>
              Funds available
            </Typography>
            <Typography className={classNames({ [classes.activeText]: true, [classes.value]: true })}>
              {formatNegativeNumber(revenue.available_funds, '$')}
            </Typography>
          </div>
        </>
      )}
    </div>
  )
}

export default WalletRevenue
