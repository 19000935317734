import React from 'react'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../constants'
import ConnectButton from './ConnectButton'

const useStyles = makeStyles({
  title: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
  },
  bodyText: {
    fontSize: 13,
    color: colors.BASIC.COLOR[50],
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
    padding: 11,
  },
  bodyContainer: {
    display: 'flex',
    padding: 16,
  },
  textContainer: {
    marginLeft: 16,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  bodyTitle: {
    fontFamily: '"Roboto", san-serif',
    fontWeight: 500,
  },
  btnContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 40,
    width: '100%',
    display: 'flex',
  },
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
  connectCalendarCard: {
    minHeight: 180,
  },
})

const ConnectCalendarCardBody = ({ hover, calendar, ...props }) => {
  const classes = useStyles()
  const { provider, company, companyLogo, calendarLogo, title, description } = calendar
  return (
    <div className={classes.connectCalendarCard}>
      <div className={classes.titleContainer}>
        {companyLogo}
        <Typography variant="subtitle2" className={classes.title}>
          {company}
        </Typography>
      </div>
      <>
        {hover ? (
          <div className={classes.btnContainer}>
            <ConnectButton
              changeStep={props.changeStep}
              setAppleLoginModal={props.setAppleLoginModal}
              setCalendar={props.setCalendar}
              provider={provider}
            />
          </div>
        ) : (
          <div className={classes.bodyContainer}>
            <div>{calendarLogo}</div>
            <div className={classes.textContainer}>
              <Typography className={classes.bodyTitle} variant="h4">
                {title}
              </Typography>
              <Typography variant="subtitle2" className={classes.bodyText}>
                {description}
              </Typography>
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default ConnectCalendarCardBody
