import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { setCountryStateValue, getProfileList } from 'store/actions/search'

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_GEOLOCATTION
const GOOGLE_GEOLOCATION_BASE_URL = `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_KEY}`
const GOOGLE_GEOCODE_BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='

const useStyles = makeStyles({
  startPage: {
    position: 'relative',
    display: 'flex',
    fontFamily: 'Roboto',
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
      width: 'calc(100vw - 30px)',
    },
  },
  header: {
    position: 'relative',
    marginTop: 10,
    display: 'flex',
    fontFamily: 'Roboto',
    border: '1px solid #f2f2f2',
    borderRadius: 5,
  },
  '@media(max-width:767px)': {
    header: {
      flexDirection: 'column',
      border: 'none',
      margin: 'unset',
    },
  },
})
const COUNTRY = 'country'
const ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1'

const MainSearch = ({ showSearch, ...props }) => {
  //geolocation
  const [googleCoord, setGoogleCoord] = useState([])
  const dispatch = useDispatch()
  const classes = useStyles()
  const { states, defaultCountryState } = useSelector(state => state.profileCreation)
  const countryStateValue = useSelector(state => state.search.countryStateValue)
  const practiceAreaValue = useSelector(state => state.search.practiceAreaValue)
  const coordinatesData = googleCoord && googleCoord[0]
  let foundState = {}

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const fetchGeoData = async () => {
      try {
        const coordinates = await axios.post(GOOGLE_GEOLOCATION_BASE_URL, { cancelToken: source.token })
        const getCurrentGeoDataUrl = `${GOOGLE_GEOCODE_BASE_URL}${coordinates.data.location.lat},${coordinates.data.location.lng}&key=${GOOGLE_KEY}`
        const result = await axios.get(getCurrentGeoDataUrl, { cancelToken: source.token })
        setGoogleCoord(result.data.results)
      } catch (error) {
        const state = window.sessionStorage.countryState
        dispatch(setCountryStateValue(state || defaultCountryState))
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
    }

    fetchGeoData()

    const state = window.sessionStorage.countryState
    if (state) dispatch(setCountryStateValue(state))

    return () => {
      source.cancel()
    }
  }, [])

  useEffect(() => {
    if (coordinatesData && !countryStateValue && states.length) {
      const locationObject = coordinatesData.address_components.reduce((result, { short_name, types }) => {
        const defaultType = types[0]
        if (defaultType === COUNTRY) return { ...result, country: short_name }
        if (defaultType === ADMINISTRATIVE_AREA_LEVEL_1) return { ...result, state: short_name }
        return result
      }, {})
      foundState = states.find(el => {
        return el.value === `${locationObject.country}-${locationObject.state}`
      })
      foundState
        ? dispatch(setCountryStateValue(foundState.label))
        : dispatch(setCountryStateValue(defaultCountryState))

      //trigger search requests after geolocation was determined or not and some state was set
      if (practiceAreaValue) {
        dispatch(getProfileList())
      }
    }
  }, [coordinatesData, states])

  return <div className={classNames(classes[props.type], props.className)}>{props.children}</div>
}

export default MainSearch
