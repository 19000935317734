import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { goToZendesk } from 'store/actions/common'
import { navigateToLogin } from 'store/actions/navigation'
import { ColorCircularProgress } from 'components'
import { getJWTLocally } from 'utils/auth'

const ZendeskLogin = () => {
  const token = getJWTLocally()
  const dispatch = useDispatch()
  useEffect(() => {
    if (token) {
      dispatch(goToZendesk(true))
    } else {
      dispatch(navigateToLogin())
    }
  }, [goToZendesk])

  return <ColorCircularProgress />
}

export default ZendeskLogin
