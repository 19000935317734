export const GET_CLIENT_PAYMENT_SECRET_START = 'GET_CLIENT_PAYMENT_SECRET_START'
export const GET_CLIENT_PAYMENT_SECRET_END = 'GET_CLIENT_PAYMENT_SECRET_END'
export const GET_USER_CARDS_START = 'GET_USER_CARDS_START'
export const GET_USER_CARDS_END = 'GET_USER_CARDS_END'
export const SAVE_USER_CARD_START = 'SAVE_USER_CARD_START'
export const SAVE_USER_CARD_END = 'SAVE_USER_CARD_END'
export const UPDATE_USER_CARD_START = 'UPDATE_USER_CARD_START'
export const UPDATE_USER_CARD_END = 'UPDATE_USER_CARD_END'
export const DELETE_USER_CARD_START = 'DELETE_USER_CARD_START'
export const DELETE_USER_CARD_END = 'DELETE_USER_CARD_END'
export const GET_USER_CARD_START = 'GET_USER_CARD_START'
export const GET_USER_CARD_END = 'GET_USER_CARD_END'
