import React from 'react'
import { Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../constants'

const { ERROR, SUCCESS } = colors
const useStyles = makeStyles({
  passwordHint: {
    alignSelf: 'flex-start',
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    color: colors.BASIC.COLOR[80],
    textAlign: 'left',
    marginBottom: 20,
  },
  fail: {
    color: ERROR,
  },
  success: {
    color: SUCCESS,
  },
})

const lowerCase = /[a-z]/
const upperCase = /[A-Z]/
const digit = /\d/
const symbol = /[!@#%&*\$\^]/

const PasswordChecker = ({ password }) => {
  const classes = useStyles()

  const successIcon = <Icon style={classes.success} iconClass="las la-check-circle" />
  const failIcon = <Icon style={classes.fail} iconClass="las la-exclamation-triangle" />

  return (
    <div className={classes.passwordHint}>
      Please include: <br />
      {password.length >= 8 ? successIcon : '-'} At least 8 symbols
      <br />
      {lowerCase.test(password) ? successIcon : '-'} At least one lowercase
      <br />
      {upperCase.test(password) ? successIcon : '-'} At least one uppercase
      <br />
      {digit.test(password) ? successIcon : '-'} At least one number
      <br />
      {symbol.test(password) ? successIcon : '-'} At least one special symbol (@#$%^&*!)
      <br />
    </div>
  )
}

export default PasswordChecker
