import React, { useState } from 'react'
import { CalendarCard } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import ICalendarSocialLogin from '../../containers/account-calendar/connections/ICalendarSocialLogin'

const useStyles = makeStyles({
  calendarList: {
    marginTop: 24,
    display: 'flex',
  },
})
const CalendarList = ({
  calendarsList,
  setCalendarModalOptions,
  setReconnectModalOptions,
  selectedCalendarList,
  isCalendarLoading,
  mode,
  ...props
}) => {
  const classes = useStyles()
  const [appleLoginModal, setAppleLoginModal] = useState({ open: false, onSubmit: null })
  return (
    <div className={classes.calendarList}>
      {calendarsList.map(calendar => (
        <CalendarCard
          key={calendar.provider || calendar}
          calendarSettings={calendar}
          mode={mode}
          selectedCalendarList={selectedCalendarList}
          setCalendarModalOptions={setCalendarModalOptions}
          setReconnectModalOptions={setReconnectModalOptions}
          setAppleLoginModal={setAppleLoginModal}
          isCalendarLoading={isCalendarLoading}
          {...props}
        />
      ))}
      <ICalendarSocialLogin
        open={appleLoginModal.open}
        onSubmit={appleLoginModal.onSubmit}
        closeModal={() => setAppleLoginModal({ open: false, onSubmit: null })}
      />
    </div>
  )
}

export default CalendarList
