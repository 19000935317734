import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ProfileCreationStepper } from 'containers'

const useStyles = makeStyles({
  creationPage: {
    display: 'flex',
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px 0',
  },
  pageTitle: {
    paddingLeft: 40,
  },
  title: {
    fontWeight: 500,
    fontSize: 25,
  },
})

const scrollToRef = ref => {
  ref.current.scrollTo(0, 0)
}

const ProfileCreationPage = props => {
  const classes = useStyles()
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  const currentStep = Number(props.match.params.step)

  return (
    <div ref={myRef} className={classes.creationPage}>
      <div className={classes.content}>
        <ProfileCreationStepper executeScroll={executeScroll} currentStep={currentStep} />
      </div>
    </div>
  )
}

export default ProfileCreationPage
