import React, { useState } from 'react'
import {
  Typography,
  ExpansionPanelProAccount,
  PracticeAreasExpansionPanelSummary,
  PracticeAreasExpansionPanelDetail,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  title: {
    marginBottom: '12px',
  },
  proPracticeAreaContainer: {
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  '@media(max-width:767px)': {
    title: {
      marginTop: 24,
    },
    proPracticeAreaContainer: {
      borderBottom: 'none',
    },
  },
})

const PracticeAreasProAccount = ({ practiceAreas, unspecified }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <Typography className={classes.title} variant="h4">
        practice areas and fees
      </Typography>
      <div className={classes.proPracticeAreaContainer}>
        {practiceAreas.map((area, i) => {
          return (
            <ExpansionPanelProAccount
              area={area}
              onChange={handleChange(i)}
              expanded={expanded === i}
              key={area.selected_practice_area.uuid}
              summary={<PracticeAreasExpansionPanelSummary area={area} unspecified={unspecified} />}
              details={<PracticeAreasExpansionPanelDetail area={area} />}
            />
          )
        })}
      </div>
    </>
  )
}

export default PracticeAreasProAccount
