import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import MessageBox from './MessageBox'
import { Avatar } from '../index'
import moment from 'moment'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  row: {
    width: '100%',
    display: 'flex',
    marginBottom: 12,
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
  date: {
    color: colors.BASIC.COLOR['80'],
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 12,
    marginTop: 12,
    textAlign: 'center',
  },
  messageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
})

const MessageGroupper = props => {
  const classes = useStyles()
  const { messages, chatInfo, currentUser, lastConsumedMessage, newMessagesIndex, showAvatar } = props

  return messages.map(message => {
    return (
      <div key={message.date}>
        <div className={classes.date}>{moment(message.date).format('MMM D')}</div>
        {message.messages.map((message, i) => {
          const isMyMessage = message.authorId !== chatInfo.chat_member_twilio_identity
          return (
            <div key={i} className={classNames({ [classes.row]: true, [classes.reverse]: isMyMessage })}>
              {showAvatar && (
                <Avatar src={isMyMessage ? currentUser.photo : chatInfo.chat_member_photo_thumbnail}>
                  {isMyMessage
                    ? `${currentUser.first_name ? currentUser.first_name[0] : ''}`
                    : `${chatInfo.chat_member_full_name ? chatInfo.chat_member_full_name[0] : ''}`}
                </Avatar>
              )}
              <div
                className={classNames({
                  [classes.messageContainer]: true,
                  [classes.alignRight]: isMyMessage,
                  [classes.alignLeft]: !isMyMessage,
                })}
              >
                {message.messages.map(message => (
                  <MessageBox
                    key={message.index}
                    message={message}
                    isMyMessage={isMyMessage}
                    lastConsumedMessage={lastConsumedMessage}
                    newMessagesIndex={newMessagesIndex}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  })
}

export default MessageGroupper
