import React from 'react'
import classNames from 'classnames'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { colors } from 'constants/index'
import { useSelector } from 'react-redux'
import { ReactComponent as CasesIcon } from 'static/vault/menu/cases.svg'
import { ReactComponent as CasesInactiveIcon } from 'static/vault/menu/cases_inactive.svg'
import { ReactComponent as LogIcon } from 'static/vault/menu/log.svg'
import { ReactComponent as LogInactiveIcon } from 'static/vault/menu/log_inactive.svg'
import { ReactComponent as TrashIcon } from 'static/vault/menu/trash.svg'
import { ReactComponent as TrashInactiveIcon } from 'static/vault/menu/trash_inactive.svg'
import { getIsPrivateFolderSelected } from 'store/selectors/vaultSelector'

const useStyles = makeStyles({
  tab: {
    maxWidth: 64,
    minWidth: 64,
    lineHeight: 1.23,
    fontSize: 13,
    opacity: 1,
    color: colors.BASIC.COLOR['50'],
    fontWeight: 'normal',
    textTransform: 'none',
    padding: 0,
  },
  selectedTab: {
    color: colors.BASIC.COLOR['80'],
  },
  tabWrapper: {
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginBottom: 0,
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})

const VaultMenu = ({ vaultTab, handleTabChange, isClientVault }) => {
  const classes = useStyles()
  const isPrivateFolder = useSelector(getIsPrivateFolderSelected)
  const folderLabel = isPrivateFolder ? 'my files' : 'folders'

  const commonTabs = [
    {
      label: folderLabel,
      activeIcon: <CasesIcon />,
      inactiveIcon: <CasesInactiveIcon />,
    },
    {
      label: 'log',
      activeIcon: <LogIcon />,
      inactiveIcon: <LogInactiveIcon />,
    },
  ]
  const tabs = isClientVault
    ? commonTabs
    : [
        ...commonTabs,
        {
          label: 'trash',
          activeIcon: <TrashIcon />,
          inactiveIcon: <TrashInactiveIcon />,
        },
      ]
  return (
    <Tabs
      orientation="vertical"
      value={vaultTab || 0}
      classes={{ indicator: classes.indicator }}
      onChange={(e, newValue) => handleTabChange(newValue)}
    >
      {tabs.map((tab, i) => (
        <Tab
          classes={{
            root: classNames(classes.tab, { [classes.selectedTab]: vaultTab === i }),
            wrapper: classes.tabWrapper,
          }}
          label={tab.label}
          key={tab.label}
          icon={vaultTab === i ? tab.activeIcon : tab.inactiveIcon}
        />
      ))}
    </Tabs>
  )
}

export default VaultMenu
