import * as CONSTANTS from '../../constants/profileCreation'

const initialState = {
  isPending: false,
  countries: [
    {
      label: 'US',
      value: 840,
    },
  ],
  defaultCountryState: 'California',
  states: [],
  statesAreLoading: false,
  practiceAreas: [],
  arePracticeAreasLoading: false,
  practiceAreasDB: [],
  profileData: {
    contactInfo: {
      street: '',
      city: '',
      zip: '',
      state: '',
      country: 840,
      phone: '',
      suite: '',
      optionalPhone: '',
    },
    localContactInfo: {
      street: '',
      city: '',
      zip: '',
      state: '',
      country: 840,
      phone: '',
      suite: '',
      optionalPhone: '',
    },
    licensing: [
      {
        id: Math.random(),
        barId: '',
        state: '',
        verificationLink: '',
        errors: {
          barId: '',
          state: '',
        },
      },
    ],
    contactInfoIsLoading: false,
    licensingAreLoading: false,
    profilePicture: null,
    currentStep: 0,
    isChangeProfileData: false,
  },
}

export const profileCreation = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_COUNTRIES_BEGIN:
      return {
        ...state,
        isPending: true,
      }
    case CONSTANTS.GET_COUNTRIES_END:
      return {
        ...state,
        isPending: false,
        countries: action.payload,
      }
    case CONSTANTS.CHANGE_PROFILE_CONTACT_INFO:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          contactInfo: {
            ...state.profileData.contactInfo,
            ...action.data,
          },
          localContactInfo: {
            ...state.profileData.localContactInfo,
            ...action.data,
          },
        },
      }
    case CONSTANTS.UPDATE_CONTACT_INFO:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          localContactInfo: {
            ...state.profileData.localContactInfo,
            ...action.data,
          },
        },
      }
    case CONSTANTS.ADD_LICENSING:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          licensing: action.payload,
        },
      }
    case CONSTANTS.ADD_PRACTICE_AREAS:
      return {
        ...state,
        practiceAreas: action.payload,
      }
    case CONSTANTS.GET_PRACTICE_AREAS_START:
      return {
        ...state,
        arePracticeAreasLoading: true,
      }
    case CONSTANTS.GET_PRACTICE_AREAS_END:
      return {
        ...state,
        arePracticeAreasLoading: false,
        practiceAreas: action.payload,
        practiceAreasDB: action.payload,
      }
    case CONSTANTS.SAVE_PRACTICE_AREAS_END:
      return {
        ...state,
        practiceAreasDB: action.payload,
      }
    case CONSTANTS.SAVE_PROFILE_CONTACT_INFO_START:
      return {
        ...state,
        isPending: false,
      }
    case CONSTANTS.SAVE_PROFILE_CONTACT_INFO_END:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          contactInfo: {
            ...state.profileData.contactInfo,
            ...action.payload,
          },
        },
        isPending: false,
      }
    case CONSTANTS.GET_STATES_START:
      return {
        ...state,
        statesAreLoading: true,
      }
    case CONSTANTS.GET_STATES_END:
      return {
        ...state,
        statesAreLoading: false,
        states: action.payload,
      }
    case CONSTANTS.SAVE_PROFILE_LICENSING_START:
      return {
        ...state,
        isPending: true,
      }
    case CONSTANTS.SAVE_PROFILE_LICENSING_END:
      return {
        ...state,
        isPending: false,
      }
    case CONSTANTS.GET_PROFILE_LICENSING_START:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          licensingAreLoading: true,
        },
      }
    case CONSTANTS.GET_PROFILE_LICENSING_END:
      return {
        ...state,
        isPending: false,
        profileData: {
          ...state.profileData,
          licensingAreLoading: false,
          licensing: action.payload,
        },
      }
    case CONSTANTS.SAVE_PROFILE_PHOTO_START:
      return {
        ...state,
        isPending: true,
      }
    case CONSTANTS.SAVE_PROFILE_PHOTO_END:
      return {
        ...state,
        isPending: false,
      }
    case CONSTANTS.SUBMIT_PROFILE_START:
      return {
        ...state,
        isPending: true,
      }
    case CONSTANTS.SUBMIT_PROFILE_END:
      return {
        ...state,
        isPending: false,
      }
    case CONSTANTS.GET_USER_CONTACT_INFO_START:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          contactInfoIsLoading: true,
        },
      }
    case CONSTANTS.GET_USER_CONTACT_INFO_END:
      return {
        ...state,
        isPending: false,
        profileData: {
          ...state.profileData,
          contactInfoIsLoading: false,
          contactInfo: action.payload,
          localContactInfo: action.payload,
        },
      }

    case CONSTANTS.RESET_PROFILE_CREATION_INFO:
      return {
        ...initialState,
        states: state.states,
        profileData: {
          ...initialState.profileData,
          currentStep: state.profileData.currentStep,
        },
      }

    case CONSTANTS.SET_CURRENT_STEP:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          currentStep: action.payload,
        },
      }
    case CONSTANTS.IS_CHANGE_PROFILE_DATA_START:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isChangeProfileData: true,
        },
      }

    case CONSTANTS.IS_CHANGE_PROFILE_DATA_END:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isChangeProfileData: false,
        },
      }

    default:
      return state
  }
}
