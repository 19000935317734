import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {useDispatch} from "react-redux"
import { toArray, size } from 'lodash'
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress"
import { ReactComponent as UploadIcon } from 'static/vault/cloud_upload_orange.svg'
import { ReactComponent as FileIcon } from 'static/vault/file_small.svg'
import { ReactComponent as UploadComplete } from 'static/vault/upload_complete.svg'
import { ReactComponent as UploadCancel } from 'static/vault/upload_cancel.svg'
import { ReactComponent as UploadFailed } from 'static/vault/upload_failed.svg'
import { Modal, Typography, Button, Tooltip } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import {formatBytes} from "utils/vault/vault-helpers"
import {cancelUploadFile} from "store/actions/vault"

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 534,
    position: 'relative',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    padding: 24,

  },
  progressRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16
  },
  progressFileContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadIcon:{
    marginRight: 8
  },
  fileIcon:{
    marginRight: 4
  },
  uploadProgressTitle:{
    fontSize: 20,
    fontWeight: 500
  },
  progressContainer:{
    marginTop: 25
  },
  percentage:{
    fontSize: 13,
    color: colors.BASIC.COLOR["80"],
    fontWeight: 'normal',
    marginRight: 8,
  },
  fileTitle:{
    fontSize: 15,
    fontWeight: 500,
    color: colors.BASIC.COLOR["100"]
  },
  sizeTitle:{
    marginLeft: 2,
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR["70"]
  },
  progressCircle:{
    color: colors.SUCCESS,
  },
  root: {
    position: 'relative',
  },
  bottom: {
    color: colors.BASIC.COLOR["10"],
  },
  top: {
    color: colors.SUCCESS,
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  cancelBtn:{
    width: 24,
    height: 22,
    padding: 0,
    minWidth: 24,
    '&:hover':{
      backgroundColor: colors.WHITE
    }
  },
  box:{
    marginRight: 4,
    marginTop: 4,
  },
  circularProgressContainer:{
    display: 'flex',
    height: 25,
    alignItems: 'center'
  },
  tooltip:{
    fontWeight: 'normal',
    fontSize: 13
  }
})

const CircularProgressWithLabel = (props)  => {
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center">
            <Box minWidth={24}>
              <Typography className={classes.percentage}>
                {`${Math.round(props.value,)}%`}
              </Typography>
            </Box>
            <Box mr={1} classes={{root: classes.box}}>
              <div className={classes.root}>
                <CircularProgress
                  variant="determinate"
                  className={classes.bottom}
                  size={20}
                  thickness={4}
                  {...props}
                  value={100}
                />
                <CircularProgress
                  variant="static"
                  className={classes.top}
                  classes={{
                    circle: classes.circle,
                  }}
                  size={20}
                  thickness={4}
                  {...props}
                />
              </div>
            </Box>
        </Box>
  );
}

const getFileName = name => {
  return name.length < 25 ? name : name.slice(0, 25) + '...'
}

const VaultFileUploadProgressModal = ({fileProgress, onClose, isOpen}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [hoveredFile, setHoveredFile] = useState(null)

  const fileInProgress = toArray(fileProgress).filter(file => file.status === CONSTANTS.FILE_UPLOAD_STATUS.IN_PROGRESS).length

  const handleUploadCancel = () => {
    dispatch(cancelUploadFile(hoveredFile))
    setHoveredFile(null)
  }

  const getFileUploadProgress = (file) => {
    if(file.status === CONSTANTS.FILE_UPLOAD_STATUS.SUCCESS) return <UploadComplete/>
    else if(file.status === CONSTANTS.FILE_UPLOAD_STATUS.FAILED || file.status === CONSTANTS.FILE_UPLOAD_STATUS.CANCELLED)
      return <>
        <Typography className={classes.percentage}>
          {file.status === CONSTANTS.FILE_UPLOAD_STATUS.FAILED ? 'upload failed' : 'upload cancelled'}
        </Typography>
        <UploadFailed/>
      </>
    else if(hoveredFile && hoveredFile.id === file.id && file.file_uuid)
      return <>
        <Typography className={classes.percentage}>
          {`${Math.round(file.progress)}%`}
        </Typography>
        <Button variant={'text'}
                className={classes.cancelBtn}
                onClick={handleUploadCancel}
        >
          <UploadCancel/>
        </Button>
    </>
   else return <CircularProgressWithLabel value={file.progress} />
  }

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <div className={classes.progressFileContainer}>
          <UploadIcon className={classes.uploadIcon} />
          <Typography className={classes.uploadProgressTitle}>
            Uploading {fileInProgress} of {size(fileProgress)} items
          </Typography>
        </div>
        <div className={classes.progressContainer}>
          {
            toArray(fileProgress).map(file =>
              <div key={file.id} className={classes.progressRow}>
                <div className={classes.progressFileContainer}>
                  <FileIcon className={classes.fileIcon} />
                  <Tooltip title={file.file && file.file.name}
                           tooltipClass={classes.tooltip}
                           childrenClass={classes.fileTitle}>
                      {file.file && getFileName(file.file.name)}
                  </Tooltip>
                  <Typography className={classes.sizeTitle}>
                    ({formatBytes(file.file && file.file.size)})
                  </Typography>
                </div>
                <div className={classes.circularProgressContainer}
                     onMouseEnter={() => setHoveredFile(file)}
                     onMouseLeave={() => setHoveredFile(null)}
                >
                  {getFileUploadProgress(file)}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default VaultFileUploadProgressModal