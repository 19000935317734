import React from 'react'
import { Modal, Typography, Button, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import MakeInactiveForbiddenMsg from '../../components/cases/MakeInactiveForbiddenMsg'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    margin: '0 0 24px 0',
    fontSize: '20px',
    display: 'flex',
  },
  subtitle: {
    maxWidth: '568px',
  },
  boxBtn: {
    textAlign: 'right',
    marginTop: 10,
  },
  icon: {
    color: 'red',
    marginRight: 8,
    fontSize: 36,
  },
  errors: {
    margin: 0,
    paddingLeft: 15,
    marginBottom: 32,
  },
  closeBtn: {
    marginRight: 24,
  },
})

const getMessage = code => {
  switch (code) {
    case 'M120':
      return 'Please make sure that all generated invoices are paid or written off'
    case 'M121':
      return 'The invoicing schedule for this case is missing, or has not been completed.  If you make this case inactive, you will be unable to issue any invoices'
    case 'M121a':
      return 'There are charges scheduled to be invoiced at a later date.  If you make this case inactive, you will be unable to issue invoices for the pending charges'
    case 'M127':
      return (
        <MakeInactiveForbiddenMsg
          newCases={[
            'activity for the initial meeting is created',
            ' invoice is generated for the created activity',
            'invoice is paid or written off',
          ]}
        />
      )
    case 'M0074':
    default:
      return <MakeInactiveForbiddenMsg />
  }
}

const CaseInactivationErrorModal = ({
  code,
  isOpen,
  onClose,
  title,
  confirmButtonText,
  cancellationButtonText,
  onConfirm,
  cancelButtonVariant,
}) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          <Icon iconClass="las la-exclamation-triangle" style={classes.icon} />
          {title}
        </Typography>
        <Typography variant="subtitle1">{getMessage(code)}</Typography>
        <div className={classes.boxBtn}>
          <Button variant={cancelButtonVariant} onClick={onClose} className={onConfirm ? classes.closeBtn : null}>
            {cancellationButtonText || 'cancel'}
          </Button>
          {onConfirm && (
            <Button variant={'primary'} onClick={onConfirm} className={classes.closeBtn}>
              {confirmButtonText || 'ok'}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CaseInactivationErrorModal
