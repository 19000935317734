import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const XSvgIcon = ({ classes, children, component, viewBox, fill, className, stroke }) => (
  <SvgIcon viewBox={viewBox} stroke={stroke} component={component} classes={classes} fill={fill} className={className}>
    {children}
  </SvgIcon>
)

export default XSvgIcon
