import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip, Icon } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import moment from 'moment'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  title: {
    marginBottom: 12,
    fontSize: 15,
  },
  bodySubtitle: {
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 5,
  },
  price: {
    fontSize: 15,
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  flexContainer: {
    display: 'flex',
  },
  infoContainer: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'column',
  },
  freeText: {
    color: colors.SUCCESS,
    whiteSpace: 'nowrap',
    fontSize: 15,
    fontWeight: 500,
  },
  tooltip: {
    padding: 15,
    maxWidth: 440,
  },
  tooltipIcon: {
    fontSize: 17,
    color: colors.PRIMARY.COLOR[50],
    marginLeft: 5,
  },
  tooltipText: {
    marginTop: 15,
    fontSize: 13,
    '&:first-child': {
      marginTop: 0,
    },
  },
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 13,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  '@media(max-width:767px)': {
    freeText: {
      fontSize: 12,
    },
  },
})

const TooltipContent = ({ tooltipText }) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={tooltipText} variant="subtitle1">
        Typically, there are no charges for any meetings canceled more than 24 hours in advance. However, after your
        initial consultation, your lawyer may choose (at their discretion) to charge fees for other meetings you cancel.
      </Typography>
      <Typography className={tooltipText} variant="subtitle1">
        Not attending (no show) your initial consultation or canceling your initial consultation with less than 24
        hours’ notice, will incur an automatic charge equal to the the initial consultation fee indicated in the
        lawyer’s profile.
      </Typography>
      <Typography className={tooltipText} variant="subtitle1">
        {'Refer to '}
        <Link to="/client/booking-policy" target="_blank" className={classes.link}>
          XIRA’s booking policy
        </Link>
      </Typography>
    </>
  )
}

const CancellationInfo = ({ start, cancellationFee }) => {
  const classes = useStyles()
  const cancelationFeeDate = moment.unix(start).subtract(24, 'h')
  const lessThan24H = moment().isSameOrAfter(cancelationFeeDate)
  const cancelationFreeDate = moment
    .unix(start)
    .subtract(24, 'h')
    .subtract(1, 'minute')
  return (
    <div className={classes.infoContainer}>
      <div className={classes.flexContainer}>
        <Typography className={classes.title}>cancellation or no show</Typography>
        <Tooltip
          title={<TooltipContent tooltipText={classes.tooltipText} />}
          tooltipClass={classes.tooltip}
          interactive
        >
          <Icon style={classes.tooltipIcon} iconClass="las la-question-circle" />
        </Tooltip>
      </div>
      {!lessThan24H && (
        <div className={classes.priceContainer}>
          <Typography className={classes.bodySubtitle}>
            up to {cancelationFreeDate.format('h:mm a')} on{' '}
            {cancelationFreeDate.format(CONSTANTS.DATE_FORMAT.FORMAT_TWO)}
          </Typography>
          <Typography className={classes.freeText}>no charge</Typography>
        </div>
      )}
      <div className={classes.priceContainer}>
        <Typography className={classes.bodySubtitle}>
          {!lessThan24H
            ? `after ${cancelationFeeDate.format('h:mm a')} on ${cancelationFeeDate.format(
                CONSTANTS.DATE_FORMAT.FORMAT_TWO
              )}`
            : `within 24 hours before the meeting`}
        </Typography>
        <Typography className={classes.price}>{`$${Number(cancellationFee).toFixed(2)}`}</Typography>
      </div>
    </div>
  )
}

export default CancellationInfo
