export const SET_TABLE_CONFIGURATION = 'SET_TABLE_CONFIGURATION'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SORTING = 'SET_SORTING'
export const RESET_FILTERS = 'RESET_FILTERS'
export const GET_WALLET_SUMMARY_END = 'GET_WALLET_SUMMARY_END'
export const GET_WALLET_SUMMARY_START = 'GET_WALLET_SUMMARY_START'
export const GET_WALLET_ITEMS_START = 'GET_WALLET_ITEMS_START'
export const GET_WALLET_ITEMS_END = 'GET_WALLET_ITEMS_END'
export const GET_WALLET_PENDING_FUNDS_START = 'GET_WALLET_PENDING_FUNDS_START'
export const GET_WALLET_PENDING_FUNDS_END = 'GET_WALLET_PENDING_FUNDS_END'
export const GET_PAYMENT_CONNECTION_STATUS_START = 'GET_PAYMENT_CONNECTION_STATUS_START'
export const GET_PAYMENT_CONNECTION_STATUS_END = 'GET_PAYMENT_CONNECTION_STATUS_END'
export const DISCONNECT_PAYMENT_END = 'DISCONNECT_PAYMENT_END'
export const DISCONNECT_PAYMENT_START = 'DISCONNECT_PAYMENT_START'
export const SAVE_PAYMENT_METHOD_START = 'SAVE_PAYMENT_METHOD_START'
export const SAVE_PAYMENT_METHOD_END = 'SAVE_PAYMENT_METHOD_END'
export const REQUEST_FUNDS_START = 'REQUEST_FUNDS_START'
export const REQUEST_FUNDS_END = 'REQUEST_FUNDS_END'
export const SET_REQUEST_FUNDS_ERROR_MODAL_OPEN = 'SET_REQUEST_FUNDS_ERROR_MODAL_OPEN'
export const SET_HAS_TAX_FORM = 'SET_HAS_TAX_FORM'
