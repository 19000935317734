import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { ChatModal, ClientEventCard, Loader, NoDataPlaceholder, Typography } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import {
  getClientEvents,
  increaseEventsUploadOffset,
  resetEventList,
  resetEventsUploadOffset,
  setEventsCurrentTimestamp,
} from 'store/actions/events'
import { navigateToSearch } from 'store/actions/navigation'
import { colors } from 'constants/index'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getTimezoneLabel } from 'utils/getters/timezone'
import { getJWTLocally } from 'utils/auth'
import { getEventsSocketsPath } from 'utils/path-helpers/api'
import NoEvents from 'static/events/no_events.png'
import { WebsocketService } from 'utils/service/WebsocketService'
import { useQuery } from 'utils/hooks'
import { getUserCurrentNotification, markNotificationAsRead } from 'store/actions/notifications/notifications'

const useStyles = makeStyles({
  profileContentHeader: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    alignItems: 'center',
  },
  buttonRoleOutlined: {
    width: 113,
    height: 40,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: '12px 24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    borderColor: colors.BASIC.COLOR[10],
    color: colors.BASIC.COLOR[70],
    backgroundColor: colors.BASIC.COLOR[0],
  },
  buttonRolePrimary: {
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY.COLOR[50],
    border: 'none',
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  scroll: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  '@media(max-width:1279px)': {
    header: {
      margin: '0 12px 16px',
    },
  },
  '@media(max-width:767px)': {
    header: {
      margin: '0 0 16px',
    },
    profileTimezoneHeader: {
      fontSize: 12,
    },
  },
})

const getCurrentTimestamp = () =>
  moment()
    .utc()
    .unix()

const ClientDashboardContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const events = useSelector(state => state.events.meetings)
  const eventsLength = useSelector(state => state.events.eventsLength)
  const eventsAreLoading = useSelector(state => state.events.meetingAreLoading)
  const timezonesList = useSelector(state => state.common.timezonesList)
  const [timezone, setTimezone] = useState(null)
  const query = useQuery()
  const adminMessageId = query.get('admin_message_id')

  const getUserEvents = () => {
    dispatch(resetEventsUploadOffset())
    dispatch(setEventsCurrentTimestamp(getCurrentTimestamp()))
    dispatch(getClientEvents())
  }

  useEffect(() => {
    getUserEvents()
    if (adminMessageId) {
      dispatch(getUserCurrentNotification(adminMessageId))
    }

    const token = getJWTLocally()
    const subject = new WebsocketService(getEventsSocketsPath(token))
    subject.subscribe(msg => {
      if (msg.type === 'user_events') {
        getUserEvents()
      }
    })

    return () => {
      subject.unsubscribe()
      dispatch(resetEventList())
    }
  }, [])

  useEffect(() => {
    setTimezone(getTimezoneLabel(timezonesList))
  }, [timezonesList])

  const onLoadMore = () => {
    dispatch(increaseEventsUploadOffset())
    dispatch(getClientEvents(false))
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h2" className={classes.profileContentHeader}>
          Events
        </Typography>
        <Typography variant="subtitle2" className={classes.profileTimezoneHeader}>
          {timezone}
        </Typography>
      </div>
      <InfiniteScroll
        scrollableTarget="profile_search_id"
        dataLength={events.length}
        hasMore={eventsLength > events.length}
        next={onLoadMore}
      >
        <div className={classes.scroll}>
          {!events.length && !eventsAreLoading && (
            <NoDataPlaceholder
              imageSrc={NoEvents}
              onButtonPress={() => dispatch(navigateToSearch())}
              buttonText="start exploring"
              label="you currently have no meetings scheduled"
            />
          )}
          {events.length ? events.map(event => <ClientEventCard event={event} key={event.uuid} />) : null}
          {eventsAreLoading && <Loader />}
        </div>
      </InfiniteScroll>
      <ChatModal />
    </>
  )
}

export default ClientDashboardContainer
