import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import * as moment from 'moment-timezone'
import { CONSTANTS, colors } from 'constants/index'
import { getItemName } from 'utils/invoice/invoice-helpers'
import TableCell from '@material-ui/core/TableCell'

const styles = StyleSheet.create({
  table: {
    marginTop: 8,
    display: 'table',
    width: '100%',
  },
  titleTable: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
  },
  tableRow: {
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#e6eaec',
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 1,
  },
  partialTableCol: {
    width: '15%',
    minWidth: 20,
  },
  partialDescriptionTableCol: {
    width: '40%',
  },
  tableHeaderCell: {
    marginTop: 8,
    fontSize: 9,
    height: 24,
  },
  amountTableCell: {
    textAlign: 'right',
  },
  tableCell: {
    marginTop: 8,
    fontSize: 10,
    minHeight: 24,
  },
  tableCellAmount: {
    marginTop: 8,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    minHeight: 24,
  },
  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  subtotalTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subtotalAmount: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
  },
  partialAmountContainer: {
    marginTop: 32,
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  info: {
    fontSize: 10,
  },
  smDivider: {
    height: 1,
    backgroundColor: '#e6eaec',
    marginBottom: 5,
  },
  caseTitle: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 10,
  },
  void: {
    fontSize: 40,
    fontWeight: 'bold',
    color: colors.BASIC.COLOR['80'],
  },
  voidContainer: {
    position: 'absolute',
    left: 200,
    top: 20,
  },
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  voidTitle: {
    color: colors.BASIC.COLOR['80'],
    fontWeight: 500,
    fontSize: 13,
    marginLeft: 20,
  },
  voidAmount: {
    textDecoration: 'line-through',
  },
  row: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  footer: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: colors.BASIC.COLOR['100'],
    fontFamily: 'Helvetica-Bold',
  },
})

const SummaryTable = ({ summary, timezone, invoice }) => {
  return (
    <>
      <Text style={styles.caseTitle}>Summary</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.partialDescriptionTableCol}>
            <Text style={styles.tableHeaderCell}>Item</Text>
          </View>
          <View style={styles.partialTableCol}>
            <Text style={styles.tableHeaderCell}>Date</Text>
          </View>
          <View style={styles.partialTableCol}>
            <Text style={[styles.tableHeaderCell, styles.amountTableCell]}>Amount</Text>
          </View>
          <View style={styles.partialTableCol}>
            <Text style={[styles.tableHeaderCell, styles.amountTableCell]}>Balance</Text>
          </View>
          <View style={styles.partialTableCol} />
        </View>
        {summary.map(summaryItem => (
          <View style={styles.tableRow} wrap={false} key={summaryItem.uuid}>
            <View style={styles.partialDescriptionTableCol}>
              <Text style={styles.tableCell}>
                {`${getItemName(summaryItem.type)} ${summaryItem.generated_payment_record_number}`}
              </Text>
            </View>
            <View style={styles.partialTableCol}>
              <Text style={styles.tableCell}>
                {moment(summaryItem.created_at)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
              </Text>
            </View>
            <View style={styles.partialTableCol}>
              <Text
                style={[styles.tableCellAmount, styles.amountTableCell, { [styles.voidAmount]: summaryItem.is_void }]}
              >{`${summaryItem.type === 'C' || summaryItem.type === 'P' ? '-' : ''} $${Math.abs(
                summaryItem.amount
              ).toFixed(2)}`}</Text>
            </View>
            <View style={styles.partialTableCol}>
              <Text
                style={[styles.tableCellAmount, styles.amountTableCell]}
              >{`$${summaryItem.current_invoice_amount}`}</Text>
            </View>
            <View style={styles.partialTableCol}>
              <Text style={[styles.tableCellAmount, styles.voidTitle]}>{summaryItem.is_void ? 'VOID' : ''}</Text>
            </View>
          </View>
        ))}
        <View style={styles.row}>
          <View style={styles.partialDescriptionTableCol}>
            <Text style={styles.footer}>Current Balance</Text>
          </View>
          <View style={styles.partialTableCol}>
            <Text style={styles.footer}>{`$${invoice.amountDue}`}</Text>
          </View>
        </View>
      </View>
    </>
  )
}

export default SummaryTable
