import * as CONSTANTS from 'store/constants/calendar'
import axios from 'axios'
import {
  getAdvancedCalendarValuesPath,
  getSubCalendarListPath,
  getWorkingDaysPath,
  getWorkingDaysFlatPath,
  getcheckCalendarConnectionsPath,
  getDisconnectCalendarsPath,
  getCalendarsHealthWebSocketPath,
} from 'utils/path-helpers/api'
import { getJWTLocally } from 'utils/auth'
import moment from 'moment-timezone'
import { openSnackbar } from 'store/actions/common'
import { mapWorkingDaysToBack, mapWorkingDaysToFront, mapWorkingDaysFlatToFront } from 'utils/mappers/backend'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { getConnectCalendarAccountPath, getConnectCalendarsPath } from 'utils/path-helpers/api'
import { WebsocketService } from 'utils/service/WebsocketService'

export const setXIRACalendar = () => async (dispatch, getState) => {
  const localXiraCalendar = getState().calendar.localXiraCalendar
  const xiraCalendar = getState().calendar.xiraCalendar
  if (xiraCalendar.id === localXiraCalendar.id) return
  try {
    const { data } = await axios.patch(getConnectCalendarsPath(), {
      primary_calendar: localXiraCalendar,
    })
    dispatch(setLocalCalendarEntities(data))
    dispatch(openSnackbar('success', 'XIRA Calendar was updated'))
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while calendar update'))
  }
}

export const setLocalXIRACalendar = calendar => async (dispatch, getState) => {
  dispatch({
    type: CONSTANTS.SET_LOCAL_CALENDAR,
    payload: calendar,
  })
}

export const setReadyForConnectCalendars = calendars => dispatch => {
  dispatch({
    type: CONSTANTS.SET_READY_FOR_CONNECT_CALENDARS,
    payload: calendars,
  })
}

const getCalendarListStart = () => ({
  type: CONSTANTS.GET_CALENDAR_LIST_START,
})

const getCalendarListEnd = () => ({
  type: CONSTANTS.GET_CALENDAR_LIST_END,
})

const getCalendarSubListStart = payload => ({
  type: CONSTANTS.GET_CALENDAR_SUBLIST_START,
  payload,
})

export const getCalendarSubListEnd = () => ({
  type: CONSTANTS.GET_CALENDAR_SUBLIST_END,
})
const getCalendarConnectionStart = () => ({
  type: CONSTANTS.GET_CALENDAR_CONNECTION_START,
})

const getCalendarConnectionEnd = () => ({
  type: CONSTANTS.GET_CALENDAR_CONNECTION_END,
})

const refreshCalendarConnectionsStart = () => ({
  type: CONSTANTS.REFRESH_CALENDAR_CONNECTIONS_START,
})

const refreshCalendarConnectionsEnd = () => ({
  type: CONSTANTS.REFRESH_CALENDAR_CONNECTIONS_END,
})

const getICloudCalendarConnectionStart = () => ({
  type: CONSTANTS.GET_ICLOUD_CALENDAR_CONNECTION_START,
})

export const getCalendarConnection = ({ code, provider, userData }) => async (dispatch, getState) => {
  if (userData) dispatch(getICloudCalendarConnectionStart())
  else dispatch(getCalendarConnectionStart())
  const payload = {
    code,
    redirect_uri: provider === 'microsoft' ? `${window.location.origin}/social-login` : window.location.origin,
  }
  if (userData) {
    payload.username = userData.username
    payload.password = userData.password
  }
  const readyForConnectCalendars = getState().calendar.readyForConnectCalendars

  try {
    const { data } = await axios.post(getConnectCalendarAccountPath(provider), payload)
    const account = {
      uuid: data.uuid,
      provider: data.provider,
      username: data.username,
      calendars: data.external_calendars
        ? data.external_calendars.map(calendar => ({ ...calendar, selected: true }))
        : [],
    }
    dispatch(setReadyForConnectCalendars([...readyForConnectCalendars, account]))
    return account
  } catch (e) {
    return Promise.reject(e)
  } finally {
    dispatch(getCalendarConnectionEnd())
  }
}
export const getSubcalendarList = provider => (dispatch, getState) => {
  return axios.get(getSubCalendarListPath(provider)).then(res => res.data)
}

export const updatedConnectedCalendarList = () => async (dispatch, getState) => {
  const connectedCalendars = getState().calendar.connectedCalendars
  dispatch(refreshCalendarConnectionsStart())

  const calendarCalls = connectedCalendars
    .map(async connectedCalendar => await axios.get(getConnectCalendarAccountPath(connectedCalendar.provider)))
    .map(p => p.catch(e => e))

  try {
    const response = await Promise.all(calendarCalls)
    const updatedCalendarsWithoutErrors = response.filter(res => res.data).map(res => res.data)
    const updatedConnectedCalendars = connectedCalendars.map(connectedCalendar => {
      const updated = updatedCalendarsWithoutErrors.find(cal => cal.uuid === connectedCalendar.uuid)
      return updated
        ? {
            ...connectedCalendar,
            calendars: updated.external_calendars.map(externalCalendar =>
              connectedCalendar.calendars.find(connectedCalendar => externalCalendar.id === connectedCalendar.id)
                ? { ...externalCalendar, selected: true }
                : { ...externalCalendar, selected: false }
            ),
          }
        : connectedCalendar
    })
    dispatch({
      type: CONSTANTS.SET_CONNECTED_CALENDAR_LIST,
      payload: updatedConnectedCalendars,
    })
  } catch (e) {
    openSnackbar('error', 'Error while getting calendars info')
  } finally {
    dispatch(refreshCalendarConnectionsEnd())
  }
}

const setLocalCalendarEntities = data => dispatch => {
  const connectedCalendarsList = data
    ? data.accounts.map(account => ({
        ...account,
        calendars: account.calendars.map(subCal => ({ ...subCal, selected: true })),
      }))
    : []
  const primaryCalendar = data.primary_calendar
    ? {
        account: data.primary_calendar.account.uuid,
        username: data.primary_calendar.account.username,
        provider: data.primary_calendar.account.provider,
        name: data.primary_calendar.name,
        id: data.primary_calendar.id,
      }
    : {}
  dispatch({
    type: CONSTANTS.SET_CONNECTED_CALENDAR_LIST,
    payload: connectedCalendarsList,
  })
  dispatch({
    type: CONSTANTS.SET_CALENDAR,
    payload: primaryCalendar,
  })
}

export const getConnectedCalendarList = () => async dispatch => {
  dispatch(getCalendarListStart())
  try {
    const { data } = await axios.get(getConnectCalendarsPath())
    dispatch(setLocalCalendarEntities(data))
  } catch (e) {
    const errorMessage = (e.response && e.response.data && e.response.data.detail) || 'Error while getting calendars'
    dispatch(openSnackbar('error', errorMessage))
  } finally {
    dispatch(getCalendarListEnd())
  }
}

export const refreshCalendar = provider => async dispatch => {
  dispatch(getCalendarSubListStart(provider))
  try {
    const { data } = await axios.get(getConnectCalendarAccountPath(provider))
    return {
      uuid: data.uuid,
      provider: data.provider,
      username: data.username,
      calendars: data.external_calendars.map(externalCalendar =>
        data.calendars.find(connectedCalendar => externalCalendar.id === connectedCalendar.id)
          ? { ...externalCalendar, selected: true }
          : { ...externalCalendar, selected: false }
      ),
    }
  } catch (e) {
    return Promise.reject()
  } finally {
    dispatch(getCalendarSubListEnd())
  }
}

export const setCalendarEntities = () => async (dispatch, getState) => {
  const readyForConnectCalendars = getState().calendar.readyForConnectCalendars
  const localXiraCalendar = getState().calendar.localXiraCalendar
  const xiraCalendar = getState().calendar.xiraCalendar
  const currentStep = getState().profileCreation.profileData.currentStep
  const isProfileApproved = getState().accountProfile.isProfileApproved

  const accounts = readyForConnectCalendars
    .map(cal => ({
      ...cal,
      calendars: cal.calendars.filter(subCalendar => subCalendar.selected),
    }))
    .map(calendar => ({
      account: calendar.uuid,
      calendars: calendar.calendars,
    }))

  const body =
    xiraCalendar.id === localXiraCalendar.id
      ? {
          accounts: accounts,
        }
      : {
          accounts: accounts,
          primary_calendar: localXiraCalendar,
        }

  try {
    const { data } = await axios.patch(getConnectCalendarsPath(), body)
    dispatch(setLocalCalendarEntities(data))
    if (currentStep === 0 && isProfileApproved) {
      dispatch(checkCalendarConnections())
    }
    dispatch(openSnackbar('success', 'Your calendars has been added'))
    readyForConnectCalendars.forEach(cal => {
      if (cal.provider === 'google') {
        Analytics.track(EVENTS.PRO_ADDED_GOOGLE_CALENDAR, {
          google_cal: true,
        })
      } else if (cal.provider === 'microsoft') {
        Analytics.track(EVENTS.PRO_ADDED_OUTLOOK_CALENDAR, {
          outlook_cal: true,
        })
      } else if (cal.provider === 'apple') {
        Analytics.track(EVENTS.PRO_ADDED_ICALENDAR, {
          iCal: true,
        })
      }
    })
  } catch (e) {
    const error = e.response && e.response.data && e.response.data.detail
    dispatch('error', error)
    readyForConnectCalendars.forEach(cal => {
      if (cal.provider === 'google') {
        Analytics.track(EVENTS.PRO_ADDED_GOOGLE_CALENDAR, {
          error: error,
          google_cal: false,
        })
      } else if (cal.provider === 'microsoft') {
        Analytics.track(EVENTS.PRO_ADDED_OUTLOOK_CALENDAR, {
          error: error,
          outlook_cal: false,
        })
      } else if (cal.provider === 'apple') {
        Analytics.track(EVENTS.PRO_ADDED_ICALENDAR, {
          error: error,
          iCal: false,
        })
      }
    })
    return Promise.reject(e)
  }
}

export const updateCalendar = calendar => async dispatch => {
  try {
    const { data } = await axios.patch(getConnectCalendarsPath(), {
      accounts: [
        {
          account: calendar.uuid,
          calendars: calendar.calendars.filter(calendar => calendar.selected),
        },
      ],
    })
    dispatch(openSnackbar('success', 'Your calendar was successfully updated'))
    dispatch(setLocalCalendarEntities(data))
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while calendar update'))
  }
}

const trackDisconnect = provider => {
  if (provider === 'google') {
    Analytics.track(EVENTS.PRO_DELETED_GOOGLE_CALENDAR, {
      google_cal: false,
    })
  } else if (provider === 'microsoft') {
    Analytics.track(EVENTS.PRO_DELETED_OUTLOOK_CALENDAR, {
      outlook_cal: false,
    })
  } else if (provider === 'apple') {
    Analytics.track(EVENTS.PRO_DELETED_ICALENDAR, {
      iCal: false,
    })
  }
}

export const disconnectCalendar = (provider, disconnectedFromModal = false) => async (dispatch, getState) => {
  const currentStep = getState().profileCreation.profileData.currentStep
  const isProfileApproved = getState().accountProfile.isProfileApproved
  try {
    await axios.delete(getConnectCalendarAccountPath(provider))
    dispatch(openSnackbar('success', 'Your calendar has been disconnected'))
    if (!disconnectedFromModal) dispatch(getConnectedCalendarList())
    if (currentStep === 0 && isProfileApproved) {
      dispatch(checkCalendarConnections())
    }
    trackDisconnect(provider)
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while calendar disconnection'))
  }
}

export const disconnectAllCalendars = () => async (dispatch, getState) => {
  const { connectedCalendars } = getState().calendar
  return axios
    .post(getDisconnectCalendarsPath())
    .then(() => {
      connectedCalendars.forEach(calendar => {
        trackDisconnect(calendar.provider)
      })
      dispatch(getConnectedCalendarList())
    })
    .catch(e => Promise.reject(e))
}

const setUserAdvancedCalendarValues = payload => ({
  type: CONSTANTS.SET_USER_ADVANCED_CALENDAR,
  payload,
})

export const createAdvancedCalendarData = (initialData = {}) => dispatch => {
  return axios
    .post(getAdvancedCalendarValuesPath(), initialData)
    .then(response => {
      dispatch(setUserAdvancedCalendarValues(response.data))
    })
    .catch(e => Promise.reject(e))
}

export const getUserAdvancedCalendarValues = () => dispatch => {
  return axios
    .get(getAdvancedCalendarValuesPath())
    .then(response => {
      if (!response.data.timezone) {
        dispatch(
          createAdvancedCalendarData({
            // if user hasn't selected timezone, we need to show his current timezone,
            timezone: moment.tz.guess(),
          })
        )
      } else {
        dispatch(setUserAdvancedCalendarValues(response.data))
      }
    })
    .catch(e => Promise.reject(e))
}

export const setAdvancedCalendar = data => dispatch => {
  return axios
    .patch(getAdvancedCalendarValuesPath(), data)
    .then(() => {
      dispatch(setUserAdvancedCalendarValues(data))
    })
    .then(() => dispatch(openSnackbar('success', 'Successfully updated!')))
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while advance time update'))
      Promise.reject(e)
    })
}

const getWorkingDaysStart = () => ({
  type: CONSTANTS.GET_WORKING_DAYS_START,
})

const getWorkingDaysEnd = payload => ({
  type: CONSTANTS.GET_WORKING_DAYS_END,
  payload,
})

export const getWorkingDays = () => dispatch => {
  dispatch(getWorkingDaysStart())
  axios
    .get(getWorkingDaysPath())
    .then(result => {
      dispatch(getWorkingDaysEnd(mapWorkingDaysToFront(result.data)))
    })
    .catch(error => {
      dispatch(getWorkingDaysEnd([]))
      dispatch(openSnackbar('error', 'Error while working hours loading'))
      return Promise.reject(error.data)
    })
}

const saveWorkingDaysStart = () => ({
  type: CONSTANTS.SAVE_WORKING_DAYS_START,
})

const saveWorkingDaysEnd = payload => ({
  type: CONSTANTS.SAVE_WORKING_DAYS_END,
  payload,
})

export const saveWorkingDays = data => dispatch => {
  dispatch(saveWorkingDaysStart())
  return axios
    .post(getWorkingDaysPath(), { working_days: mapWorkingDaysToBack(data) })
    .then(() => {
      dispatch(saveWorkingDaysEnd(data))
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const getUserCalendarTimezone = () => dispatch => {
  return axios
    .get(getAdvancedCalendarValuesPath())
    .then(response => {
      dispatch(setUserTimezone(response.data.timezone || moment.tz.guess()))
    })
    .catch(e => Promise.reject(e))
}

const setUserTimezone = payload => ({
  type: CONSTANTS.SET_USER_TIMEZONE,
  payload,
})

const setWorkingDaysFlat = payload => ({
  type: CONSTANTS.GET_WORKING_DAYS_FLAT_END,
  payload,
})

export const getWorkingDaysFlat = () => async dispatch => {
  try {
    const response = await axios.get(getWorkingDaysFlatPath())
    dispatch(setWorkingDaysFlat(mapWorkingDaysFlatToFront(response.data)))
    return null
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while loading working hours'))
    return Promise.reject(e)
  }
}

export const checkCalendarConnections = () => async dispatch => {
  return axios.get(getcheckCalendarConnectionsPath()).then(response => {
    dispatch(setHasCalendarProblems(response.data.has_calendar_problems))
  })
}

export const setHasCalendarProblems = payload => ({
  type: CONSTANTS.SET_HAS_CALENDAR_PROBLEMS,
  payload,
})

let calendarsHealthSubject
export const connectToCalendarHealthService = () => (dispatch, getState) => {
  const token = getJWTLocally()
  calendarsHealthSubject = new WebsocketService(getCalendarsHealthWebSocketPath(token))

  calendarsHealthSubject.subscribe(msg => {
    if (msg.type === 'user_calendar_problems') {
      dispatch(checkCalendarConnections())
    }
  })
}

export const disconnectFromCalendarHealthService = () => {
  if (calendarsHealthSubject) {
    calendarsHealthSubject.unsubscribe()
  }
}
