import React from 'react'
import { Button, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { getCalendarConnection } from 'store/actions/calendar'
import {openSnackbar} from "store/actions/common";

const useStyles = makeStyles({
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
})

const AppleConnectButton = ({ setCalendar, provider, setAppleLoginModal }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const onClick = () => {
    setAppleLoginModal({ open: true, onSubmit: onSubmit })
  }

  const onSubmit = async formData => {
    try {
      const calendar = await dispatch(getCalendarConnection({ code: 'apple', provider, userData: formData }))
      if(calendar) setCalendar(calendar)
    } catch (e) {
      dispatch(openSnackbar('error', 'Error while connecting calendar'))
      return Promise.reject(e)
    }
  }
  return (
    <>
      <Button variant="secondary" onClick={onClick}>
        <Icon style={classes.icon} iconClass="las la-plug" />
        connect
      </Button>
    </>
  )
}

export default AppleConnectButton
