import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, SvgIcon, ClientContactInfoFormBody } from 'components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { saveContactInfo } from 'store/actions/profileCreation'
import { CONSTANTS } from 'constants/index'
import { getInvitedUserContactInfo } from '../../store/actions/connections'

const useStyles = makeStyles({
  title: {
    fontSize: 25,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',

    marginBottom: 24,
  },
  clientProfileForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  clientProfileBtnContainer: {
    display: 'flex',
    marginTop: '40px',
    marginBottom: '10px',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  clientProfileBtnSubmit: {
    marginLeft: '20px',
    minWidth: '84px',
    boxShadow: '0 0 0 0',
  },
})

const mockCountries = [
  {
    label: 'USA',
    value: 840,
    icon: (
      <SvgIcon>
        <path fill="#F0F0F0" d="M16 2.667H0v10.666h16V2.667z" />
        <path
          fill="#D80027"
          d="M16 4H0v1.333h16V4zM16 6.667H0V8h16V6.667zM16 9.333H0v1.333h16V9.333zM16 12H0v1.333h16V12z"
        />
        <path fill="#2E52B2" d="M8 2.667H0V8.41h8V2.667z" />
        <path
          fill="#F0F0F0"
          d="M3.12 5.02l-.129.396h-.417l.337.245-.128.396.337-.245.337.245-.129-.396.337-.245h-.417L3.12 5.02zM3.248 6.846L3.12 6.45l-.129.396h-.417l.337.245-.128.396.337-.244.337.244-.129-.396.337-.245h-.417zM1.487 6.846l-.129-.396-.129.396H.812l.338.245-.13.396.338-.244.337.244-.129-.396.337-.245h-.416zM1.358 5.02l-.129.396H.812l.338.245-.13.396.338-.245.337.245-.129-.396.337-.245h-.416l-.129-.396zM3.12 3.59l-.129.395h-.417l.337.245-.128.397.337-.245.337.245-.129-.397.337-.245h-.417L3.12 3.59zM1.358 3.59l-.129.395H.812l.338.245-.13.397.338-.245.337.245-.129-.397.337-.245h-.416l-.129-.396zM4.881 5.02l-.128.396h-.417l.337.245-.129.396.338-.245.337.245-.13-.396.338-.245H5.01l-.128-.396zM5.01 6.846l-.128-.396-.13.396h-.416l.337.245-.129.396.338-.244.337.244-.13-.396.338-.245H5.01zM6.77 6.846L6.64 6.45l-.129.396h-.416l.337.245-.129.396.337-.244.337.244-.129-.396.338-.245H6.77zM6.641 5.02l-.129.396h-.416l.337.245-.129.396.337-.245.337.245-.129-.396.338-.245H6.77L6.64 5.02zM4.881 3.59l-.128.395h-.417l.337.245-.129.397.338-.245.337.245-.13-.397.338-.245H5.01l-.128-.396zM6.641 3.59l-.129.395h-.416l.337.245-.129.397.337-.245.337.245-.129-.397.338-.245H6.77L6.64 3.59z"
        />
      </SvgIcon>
    ),
  },
]

const validationSchema = Yup.object({
  phone: Yup.string()
    .test('len', 'Please enter 10 digits', val => (val ? val.toString().replace(/([+ -])/g, '').length === 11 : false))
    .required('Please fill in the filed'),
  street: Yup.string()
    .trim()
    .required('Please fill in the filed'),
  suite: Yup.string(),
  city: Yup.string()
    .trim()
    .required('Please fill in the filed'),
  state: Yup.string().required('Please fill in the filed'),
  zip: Yup.string()
    .typeError('Please enter valid ZIP code (5 digits)')
    .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
    .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
    .required('Please fill in the field'),
  country: Yup.string().required('Please fill in the filed'),
})

const InviteRegistrationContactInfo = props => {
  const { onAfterContactInfoSubmit } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const states = useSelector(state => state.profileCreation.states)
  const [initialValues, setInitialValues] = useState({
    phone: '',
    street: '',
    suite: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  })

  useEffect(() => {
    getContactInfo()
  }, [])

  const getContactInfo = async () => {
    try {
      const data = await dispatch(getInvitedUserContactInfo())
      setInitialValues(data)
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = async data => {
    try {
      await dispatch(saveContactInfo(data))
      if (onAfterContactInfoSubmit) {
        onAfterContactInfoSubmit()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values)
      }}
    >
      {() => (
        <Form className={classes.clientProfileForm}>
          <div>
            <ClientContactInfoFormBody countries={mockCountries} states={states} />
          </div>
          <div className={classes.clientProfileBtnContainer}>
            <Button type="submit" variant="primary" className={classes.clientProfileBtnSubmit}>
              Continue
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default InviteRegistrationContactInfo
