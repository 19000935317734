import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  NotFound,
  InfoLeftMenu,
  VaultModal,
  NotificationSnackbar,
  ProCancelEventModal,
  ProRescheduleEventModal,
  AdminMessageModal,
  MobileAppHint,
  PortalSearch,
  PortalSearchOnly,
} from 'components'
import {
  AppNavDrawer,
  Calendar,
  ClientAccountPage,
  ClientWelcome,
  CompletePage,
  MeetingPage,
  Profile,
  ProfileCreationPage,
  SocialLoginPage,
  StartPage,
  VerifyEmailPage,
  WelcomePage,
  ScheduleMeetingPage,
  ProActivitiesPage,
  EventsPage,
  ProCasesPage,
  BookingPolicyPage,
  ProConnectionsPage,
  ProFinancesPage,
  InviteClientPage,
  ClientInvoicePaymentPage,
  XiraForLawyersPage,
  TutorialsPage,
  ContactUsPage,
  ChatPage,
  ProVaultPage,
  ClientRatingPage,
    AIChatPage,
} from 'pages'
import {
  ChooseNewPassword,
  LogInByRoute,
  LogInDialog,
  ProfileSearch,
  ProfileSearchResult,
  ProfileSearchResultUploaded,
  RestoreByRoute,
  RestoreDialog,
  RestorePasswordInfoModal,
  SignUpContainer,
  TermsAndConditionsContainer,
  PrivacyPolicyContainer,
  SignUpDialog,
  OldBrowserWarning,
  AboutXira,
  Zendesk,
  ZendeskLogin,
} from 'containers'
import Header from 'components/header/header'
import { useDispatch, useSelector } from 'react-redux'
import { onApplicationStart } from 'store/actions/auth'
import { useScrollToTop } from 'utils/hooks'
import { checkBrowserVersion } from 'utils/old-browser-check'
import { isMobile } from 'utils/mobileCheck'
import { routes } from '../constants'
import {
  clientIsAuthenticatedRedir,
  profileIsCreatedRedir,
  proIsAuthenticatedRedir,
  searchIsAvailableRedir,
  userIsNotAuthenticatedRedir,
  userIsAuthenticatedRedir,
} from '../auth'
import PortalSearchByAi from '../components/search/PortalSearchByAi'

const UnauthAppComponent = () => (
  <>
    <LogInDialog />
    <SignUpDialog />
    <RestoreDialog />
    <RestorePasswordInfoModal />
    <Switch>
      <Route path={routes.getUserLoginPath()} component={LogInByRoute} />
      <Route path={routes.getUserSignupPath()} component={SignUpContainer} />
      <Route path={routes.getUserPasswordResetPath()} component={RestoreByRoute} />
      <Route path={routes.getUserPasswordResetUnauthorizedPath()} component={RestoreByRoute} />
      <Route path={routes.getUserPasswordUpdatePath()} component={ChooseNewPassword} />
      <Route path={routes.getSignInPath()} component={LogInByRoute} />
      <Route path={routes.getSignUpPath()} component={SignUpContainer} />
    </Switch>
  </>
)

const ProfileCreation = () => (
  <Switch>
    <Route path={routes.getProProfileCreateStepPath()} component={ProfileCreationPage} />
    <Redirect to={routes.getProProfileCreateStepPropsPath(1)} />
  </Switch>
)

const ProApp = () => (
  <>
    <Switch>
      <Route path={routes.getProProfileCreatePath()} component={ProfileCreationProtected} />
      <Route path={routes.getProWelcomePath()} exact component={WelcomePage} />
      <Route path={routes.getProCompletePath()} exact component={CompletePage} />
      <Route
        exact
        path={[
          '/pro/account/dashboard',
          '/pro/account/events',
          '/pro/account/activities',
          '/pro/account/activities/create/:type',
          '/pro/account/activities/edit/:activityId',
          '/pro/account/connections',
          '/pro/account/cases',
          '/pro/account/vault',
          '/pro/account/cases/create',
          '/pro/account/cases/:caseId',
          '/pro/account/cases/:caseId/edit',
          '/pro/account/finances',
          '/pro/account/finances/invoices',
          '/pro/account/finances/invoices/view/:invoiceId',
          '/pro/account/finances/my-wallet',
          '/pro/account/profile',
          '/pro/account/profile/general',
          '/pro/account/profile/areas',
          '/pro/account/profile/credentials',
          '/pro/account/profile/bio',
          '/pro/account/profile/social',
          '/pro/account/settings',
          '/pro/account/settings/connections',
          '/pro/account/settings/working-hours',
          '/pro/account/settings/timezones',
          '/pro/account/settings/payment',
          '/pro/account/settings/vault-settings',
          '/pro/account/settings/vault-settings/:update',
          '/pro/account/settings/notifications',
          '/pro/account/tutorials',
          '/pro/account/messaging',
          '/pro/account/events/:event_uuid/reschedule',
          '/pro/account/events/:event_uuid/cancel',
        ]}
        component={Account}
      />
      <Route path="*" component={NotFound} />
    </Switch>
    <NotificationSnackbar />
  </>
)

const ClientApp = () => (
  <>
    <Switch>
      <Route path={routes.getBookingPolicyPath()} component={BookingPolicyPage} />
      <Route path={routes.getClientAccountPath()} component={ClientAccount} />
      <Route path={routes.getClientWelcomePath()} component={ClientWelcome} />
      <Route path={routes.getClientMeetingSchedulePath()} component={ScheduleMeetingPage} />
      <Route path={routes.getClientInvoicePaymentPath()} component={ClientInvoicePaymentPage} />
      <Route path="*" component={NotFound} />
    </Switch>
    <NotificationSnackbar />
  </>
)

const Search = () => (
  <>
    <Route path={routes.getSearchPagePath()} exact component={ProfileSearch} />
    <Route path={routes.getSearchProProfilePath()} exact component={ProfileSearchResult} />
    <Route path={routes.getSearchProExcelProfilePath()} exact component={ProfileSearchResultUploaded} />
  </>
)

export const Account = () => (
  <div className="account">
    <Route path={routes.getProAccountTabPath()} component={AppNavDrawer} />
    <Route path={routes.getProAccountEventsPath()} component={EventsPage} />
    <Route path={routes.getProAccountActivitiesPath()} component={ProActivitiesPage} />
    <Route path={routes.getProAccountConnectionsPath()} component={ProConnectionsPage} />
    <Route path={routes.getProAccountCasesPath()} component={ProCasesPage} />
    <Route path={routes.getProAccountSettingsPath()} component={SettingsApp} />
    <Route path={routes.getProAccountProfilePath()} component={ProfileApp} />
    <Route path={routes.getProAccountFinancesPath()} component={FinancesApp} />
    <Route path={routes.getProAccountVaultPath()} component={ProVaultPage} />
    <Route path={routes.getProAccountTutorialsPath()} component={TutorialsPage} />
    <Route path={routes.getProAccountMessagingPath()} component={ChatPage} />
    <Route path={routes.getProAccountDashboardPath()} component={() => ''} />
    <Route
      path={routes.getProCancelEventModalPath()}
      component={props => <ProCancelEventModal eventId={props.match.params.event_uuid} />}
    />
    <Route
      path={routes.getProRescheduleEventModalPath()}
      component={props => <ProRescheduleEventModal eventId={props.match.params.event_uuid} />}
    />
    <VaultModal />
  </div>
)

export const Info = () => (
  <div className="info-wrapper">
    <Route path={routes.getFooterLeftMenuPath()} component={InfoLeftMenu} />
    <Route path={routes.getTermsAndConditionsPath()} component={TermsAndConditionsContainer} />
    <Route path={routes.getPrivacyPolicyPath()} component={PrivacyPolicyContainer} />
    <Route path={routes.getAboutXiraPath()} component={AboutXira} />
    <Route path={routes.getXiraForLawyersPath()} component={XiraForLawyersPage} />
    <Route path={routes.getContactUsPath()} component={ContactUsPage} />
  </div>
)

const FinancesApp = () => (
  <Switch>
    <Route path={routes.getProAccountFinancesTabPath()} component={ProFinancesPage} />
    <Redirect to={routes.getProAccountFinancesGeneralPath()} />
  </Switch>
)

const SettingsApp = () => (
  <Switch>
    <Route path={routes.getProAccountSettingsTabPath()} component={Profile} />
    <Redirect to={routes.getProAccountSettingsGeneralPath()} />
  </Switch>
)

const ProfileApp = () => (
  <Switch>
    <Route path={routes.getProAccountProfileTabPath()} component={Profile} />
    <Redirect to={routes.getProAccountProfileGeneralPath()} />
  </Switch>
)

const CalendarApp = () => (
  <Switch>
    <Route path={routes.getProAccountCalendarTabPath()} component={Calendar} />
    <Redirect to={routes.getProAccountCalendarConnectionsPath()} />
  </Switch>
)

const ClientAccount = () => (
  <Switch>
    <Route
      exact
      path={[
        routes.getClientAccountTabPath(),
        routes.getClientAccountEventActionPath(),
        routes.getClientAccountInvoiceActionPath(),
      ]}
      component={ClientAccountPage}
    />
    <Redirect to={routes.getClientAccountDashboardPath()} />
  </Switch>
)

const ProfileCreationProtected = profileIsCreatedRedir(ProfileCreation)
const UnauthApp = userIsNotAuthenticatedRedir(UnauthAppComponent)
const ProtectedPro = proIsAuthenticatedRedir(ProApp)
const ProtectedClient = clientIsAuthenticatedRedir(ClientApp)
const ProtectedProRating = clientIsAuthenticatedRedir(ClientRatingPage)
const ProtectedSearch = searchIsAvailableRedir(Search)
const ProtectedMeeting = userIsAuthenticatedRedir(MeetingPage)

const MainApp = () => {
  const reference = useScrollToTop()
  const isMobileBrowser = isMobile()
  const showMobileAppHint = useSelector(state => state.common.showMobileAppHint)
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  const role = useSelector(state => state.auth.currentUser.role)
  const headerBanner = useSelector(state => state.auth.headerBanner)

  return (
    <>
      <Header position="static" banner={headerBanner} />
      <div className="main-content" id="profile_search_id" ref={reference}>
        {isMobileBrowser && showMobileAppHint && <MobileAppHint />}
        <Route
          exact
          path={[
            routes.getUserLoginPath(),
            routes.getUserSignupPath(),
            routes.getUserAllPasswordPath(),
            routes.getSignInPath(),
            routes.getSignUpPath(),
          ]}
          component={StartPage}
        />
        <Route
          exact
          path={routes.getStartPagePath()}
          render={({ location }) => {
            if (isUserLoggedIn && window.location.origin.includes('law.xira')) {
              return <Redirect to={`/${role}/account/events`} />
            } else {
              return <StartPage location={location} />
            }
          }}
        />
        <Route path={routes.getVerifyEmailPath()} exact component={VerifyEmailPage} />
        <Route path={routes.getSocialLoginPath()} component={SocialLoginPage} />
        <Route path={routes.getSearchPagePath()} component={ProtectedSearch} />
        <Route path={routes.getAiChatPath()} component={AIChatPage} />
        <Route
          path={[
            routes.getStartPagePath(),
            routes.getUserLoginPath(),
            routes.getUserSignupPath(),
            routes.getUserAllPasswordPath(),
            routes.getSignInPath(),
            routes.getSignUpPath(),
          ]}
          component={UnauthApp}
        />
        <Route path={routes.getProPath()} component={ProtectedPro} />
        <Route path={routes.getClientPath()} component={ProtectedClient} />
        {/* <Route
          path={[
            routes.getTermsAndConditionsPath(),
            routes.getPrivacyPolicyPath(),
            routes.getAboutXiraPath(),
            routes.getXiraForLawyersPath(),
            routes.getContactUsPath(),
          ]}
          component={Info}
        /> */}

        <Route path={routes.getZendeskPath()} component={Zendesk} />
        <Route path={routes.getZendeskLoginPath()} component={ZendeskLogin} />
        <AdminMessageModal />
      </div>
    </>
  )
}

export const Routes = () => (
  <Switch>
    <Route path={routes.getPreviewSearchOnlyPath()} component={PortalSearchOnly} />
    <Route path={routes.getPreviewAiFormPath()} component={PortalSearchByAi} />
    <AppRoutes />
  </Switch>
)

export const AppRoutes = () => {
  const dispatch = useDispatch()
  const showOldBrowser = checkBrowserVersion()
  useEffect(() => {
    if (!showOldBrowser) dispatch(onApplicationStart())
  }, [dispatch, showOldBrowser])

  return (
    <Switch>
      {showOldBrowser && (
        <>
          <Route path={routes.getOldBrowserWarningPath()} component={OldBrowserWarning} />
          <Redirect to={routes.getOldBrowserWarningPath()} />
        </>
      )}
      <Route path={routes.getPreviewPath()} component={PortalSearch} />
      <Route path={routes.getMeetingPagePath()} component={ProtectedMeeting} />
      <Route path={routes.getClientInvitePath()} component={InviteClientPage} />
      <Route path={routes.getClientReviewPath()} component={ProtectedProRating} />
      <Route path={routes.getStartPagePath()} component={MainApp} />
    </Switch>
  )
}
