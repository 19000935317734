import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Icon, IconButton, StarRating } from 'components'
import classNames from 'classnames'
import { colors } from '../../constants/colors'

const STAR_FONT_SIZE = 20
const STAR_MARGIN = 8

const useStyles = makeStyles({
  rating: {
    margin: '20px 0',
  },
  logo: {
    color: colors.ERROR,
    fontSize: 42,
    marginLeft: -5,
  },
  icon: {
    fontSize: STAR_FONT_SIZE,
  },
  iconMargin: {
    '&:not(:last-child)': {
      marginRight: STAR_MARGIN,
    },
  },

  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingActions: {
    color: colors.BASIC.COLOR[80],
    borderRadius: 4,
  },
})

const SocialRating = ({ rating, onEdit, onDelete, socialIconClass }) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.flexWrapper, classes.rating)}>
      <Icon iconClass={socialIconClass} style={classes.logo} />
      <StarRating rating={rating} stars={5} />
      {rating ? (
        <div className={classNames(classes.flexWrapper)}>
          <IconButton classes={{ root: classes.ratingActions }} onClick={onEdit}>
            <Icon iconClass="las la-pen" style={classes.icon} />
          </IconButton>
          <IconButton classes={{ root: classes.ratingActions }} onClick={onDelete}>
            <Icon iconClass="lar la-trash-alt" style={classes.icon} />
          </IconButton>
        </div>
      ) : (
        <IconButton classes={{ root: classes.ratingActions }} onClick={onEdit}>
          <Icon iconClass="las la-plus-circle" style={classNames(classes.iconMargin, classes.icon)} />
          <Typography variant="subtitle2">ADD</Typography>
        </IconButton>
      )}
    </div>
  )
}

export default SocialRating
