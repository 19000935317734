import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as FolderIcon } from 'static/vault/casesList/folder_active.svg'
import { colors, MESSAGES, REGEXP } from 'constants/index'
import { Modal, Typography, Button, TextField, Hint, Icon } from 'components'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 534,
    position: 'relative',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  modalBody: {
    height: '80%',
    padding: 24,
  },
  title: {
    marginBottom: 24,
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  boxBtn: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: colors.BASIC.COLOR['0'],
    borderTop: `1px solid ${colors.BASIC.COLOR['10']}`,
    padding: '8px 24px',
  },
  icon: {
    marginRight: 8,
  },
  error: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  closeBtn: {
    marginRight: 24,
  },
  confirmBtn: {
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  btn: {
    minHeight: 32,
    height: 32,
    paddingTop: 7,
    paddingBottom: 7,
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '648px !important',
    },
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
    boxBtn: {
      marginTop: 16,
    },
  },
})

const VaultCreateFolderModal = props => {
  const classes = useStyles()
  const { isOpen, onClose, onConfirm, defaultSubFolderName, nameCheck = name => {} } = props
  const [nameInput, setNameInput] = useState(defaultSubFolderName)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    setNameInput(defaultSubFolderName)
  }, [defaultSubFolderName])

  const onConfirmClick = () => {
    if (!nameCheck(nameInput)) {
      setErrorMessage(MESSAGES.M0086)
      return
    }
    if (!nameInput) {
      setNameInput(defaultSubFolderName)
      onConfirm(defaultSubFolderName)
      return
    }
    onConfirm(nameInput)
  }

  const onNameInputFocus = e => {
    e.target.select()
    setErrorMessage(null)
  }

  const onNameInputChange = e => {
    const value = e.target.value
    if (value && (value.length > 50 || REGEXP.folderNameRestrictedChars.test(value))) return
    setNameInput(value)
  }
  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <div className={classes.modalBody}>
          <Typography variant="h4" className={classes.title}>
            <FolderIcon className={classes.icon} />
            Create folder
          </Typography>
          <TextField
            autoFocus
            fullWidth
            onFocus={onNameInputFocus}
            variant="outlined"
            value={nameInput}
            onChange={onNameInputChange}
            InputProps={{
              classes: { root: classes.input },
            }}
          />
          {errorMessage && (
            <Hint classes={{ subtitle2: classes.error }}>
              <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
              {errorMessage}
            </Hint>
          )}
        </div>
        <div className={classes.boxBtn}>
          <Button variant="text" onClick={onClose} className={classNames(classes.closeBtn, classes.btn)}>
            {'cancel'}
          </Button>
          <Button variant="primary" onClick={onConfirmClick} className={classNames(classes.confirmBtn, classes.btn)}>
            {'Create'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default VaultCreateFolderModal
