import { REGEXP } from 'constants/regexp'

export const mapVaultDataToFront = items => {
  const directories = items
    .filter(item => item.is_folder)
    .map(directory => ({
      uuid: directory.uuid,
      name: directory.title,
      path: directory.path,
      fileCount: directory.file_count,
      lastUploaded: directory.last_uploaded,
      folderCount: directory.folder_count,
      size: directory.size || 0,
      type: 'folder',
      isFolder: true,
      isPrivateFolder: directory.is_private_folder,
      isGeneralFolder: directory.is_general_folder,
      relatedID: directory.related_uuid,
      children: directory.children && mapVaultDataToFront(directory.children),
    }))

  const files = items
    .filter(item => !item.is_folder)
    .map(file => {
      const fileName = file.link.split('?')[0]
      const fileType = (fileName && fileName.match(REGEXP.fileType) && fileName.match(REGEXP.fileType)[0]) || ''
      return {
        uuid: file.uuid,
        name: file.title,
        path: file.path,
        lastUploaded: file.last_uploaded,
        size: file.size || 0,
        type: 'file',
        isFolder: false,
        fileType: fileType.substring(1).toLowerCase(),
        link: file.link,
        thumbnail: file.thumbnail_link,
      }
    })
  return [...directories, ...files]
}

export const mapVaultEventsToFront = events => {
  return events.map(event => {
    return {
      case_folder: event.case_folder_name,
      date: event.created_at,
      user: `${event.creator_first_name} ${event.creator_last_name}`,
      action: event.event_description,
      eventType: event.event_type,
    }
  })
}
export const mapVaultTrashDataToFront = item => ({
  name: item.title,
  uuid: item.uuid,
  deletedAt: item.deleted_at,
  isFolder: item.is_folder,
})

export const mapVaultPlansRateToFront = plansRate => {
  return plansRate.map(planRate => {
    return {
      fromValue: planRate.from_value,
      plan: planRate.plan,
      planPrice: planRate.plan_price,
      pricePerGb: planRate.price_per_gb,
      toValue: planRate.to_value,
    }
  })
}

export const mapVaultSettingsToFront = setting => {
  return {
    overallFreeSpace: setting.overall_free_space,
    unusableSpace: setting.unusable_space,
    usableSpace: setting.usable_space,
    vaultPlan: setting.vault_plan,
  }
}

export const mapVaultUsageToFront = usage => {
  return usage.map(memory => {
    return memory.file_size
  })
}
