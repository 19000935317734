import { CONSTANTS } from 'constants/index'
import { REGEXP } from 'constants/regexp'

export const validateCaseEditForm = caseData => {
  const errors = {}

  if (!caseData.name) {
    errors.name = 'Please fill out this field'
  }

  if (!caseData.sub_speciality && !caseData.practice_area) {
    errors.sub_speciality = 'Please fill out this field'
    errors.practice_area = 'Please fill out this field'
  }

  if (caseData.fee_type === CONSTANTS.FEE_TYPES['3'].key) {
    if (!caseData.hourly_rate) {
      errors.hourly_rate = 'Please fill out this field'
    } else if (!REGEXP.fiveDecimals.test(caseData.hourly_rate)) {
      errors.hourly_rate = 'please enter value with max 5 digits'
    }
  }

  if (caseData.fee_type === CONSTANTS.FEE_TYPES['1'].key) {
    const invoicingValidation = validateInvoiceSections(caseData.invoicing_sections)

    if (!invoicingValidation.isValid) {
      errors.invoicing_sections = invoicingValidation.errors
    } else if (!validateBalance(caseData.amount, caseData.invoicing_sections)) {
      errors.invoicing_sections = caseData.invoicing_sections.map(() => ({
        invoice_amount: 'please correct amount or invoice schedule amounts so the balance should be positive ',
      }))
    }
  }

  return errors
}

export const validateCaseCreateForm = caseData => {
  let errors = {}

  if (!caseData.sub_speciality && !caseData.practice_area) {
    errors.sub_speciality = 'Please fill out this field'
    errors.practice_area = 'Please fill out this field'
  }

  if (caseData.fee_type === CONSTANTS.FEE_TYPES['3'].key) {
    if (!caseData.hourly_rate) {
      errors.hourly_rate = 'Please fill out this field'
    } else if (caseData.hourly_rate === '0') {
      errors.hourly_rate = 'Please enter a value greater than 0'
    } else if (!REGEXP.fiveDecimals.test(caseData.hourly_rate)) {
      errors.hourly_rate = 'Please enter a maximum of 5 digits and no decimals'
    }
  }

  if (!caseData.name) {
    errors.name = 'Please fill out this field'
  }

  if (!caseData.client) {
    errors.client = 'Please fill out this field'
  }

  if (!caseData.date_of_creation) {
    errors.date_of_creation = 'Please fill out this field'
  }

  switch (caseData.fee_type) {
    case CONSTANTS.FEE_TYPES['1'].key:
      errors = { ...errors, ...validateFlatFee(caseData) }
      break
    case CONSTANTS.FEE_TYPES['2'].key:
      errors = { ...errors, ...validateContingency(caseData) }
      break
    case CONSTANTS.FEE_TYPES['3'].key:
      errors = { ...errors, ...validateTimeAndExpense(caseData) }
      break
  }

  return errors
}

const validateFlatFee = caseData => {
  const errors = {}

  if (!caseData.amount) {
    errors.amount = 'Please fill out this field'
  } else if (!REGEXP.fiveDecimalsDotTwoDecimals.test(caseData.amount)) {
    errors.amount = 'please enter value with max 5 digits and 2 decimals'
  }

  const invoicingValidation = validateInvoiceSections(caseData.invoicing_sections)

  if (!invoicingValidation.isValid) {
    errors.invoicing_sections = invoicingValidation.errors
  } else if (!validateBalance(caseData.amount, caseData.invoicing_sections)) {
    errors.invoicing_sections = caseData.invoicing_sections.map(() => ({
      invoice_amount: 'please correct amount or invoice schedule amounts so the balance should be positive ',
    }))
  }

  if (caseData.expense_invoicing && !caseData.invoicing) {
    errors.invoicing = 'Please fill out this field'
  }

  if (
    caseData.expense_invoicing &&
    caseData.invoicing === CONSTANTS.INVOICING[2].value &&
    !caseData.invoice_frequency
  ) {
    errors.invoice_frequency = 'Please fill out this field'
  }

  return errors
}

const validateContingency = caseData => {
  const errors = {}

  if (caseData.expense_invoicing && !caseData.invoicing) {
    errors.invoicing = 'Please fill out this field'
  }

  if (
    caseData.expense_invoicing &&
    caseData.invoicing === CONSTANTS.INVOICING[2].value &&
    !caseData.invoice_frequency
  ) {
    errors.invoice_frequency = 'Please fill out this field'
  }

  return errors
}

const validateTimeAndExpense = caseData => {
  const errors = {}

  if (!caseData.invoicing) {
    errors.invoicing = 'Please fill out this field'
  }

  if (caseData.invoicing === CONSTANTS.INVOICING[2].value && !caseData.invoice_frequency) {
    errors.invoice_frequency = 'Please fill out this field'
  }

  return errors
}

const validateInvoiceSections = invoicingSections => {
  let isValid = true
  const errors = invoicingSections.map(section => {
    const sectionErrors = {}
    if (section.invoice_amount || section.invoice_date) {
      const nonZeroValueRegExp = section.invoice_amount.match(/^[0]+[.]0+$/)
      if (!section.invoice_amount || (nonZeroValueRegExp && nonZeroValueRegExp[0])) {
        sectionErrors.invoice_amount = 'Amount should be greater than $0.00.'
        isValid = false
      } else if (!REGEXP.fiveDecimalsDotTwoDecimals.test(section.invoice_amount)) {
        sectionErrors.invoice_amount = 'Please enter value with max 5 digits and 2 decimals'
        isValid = false
      }

      if (!section.invoice_date) {
        sectionErrors.invoice_date = 'Please fill out this field'
        isValid = false
      }
    }
    return sectionErrors
  })
  return {
    errors: errors,
    isValid: isValid,
  }
}

const validateBalance = (amount, sections) => {
  if (sections.length) {
    const sectionAmount = sections.reduce((sum, item) => sum + Number(item.invoice_amount), 0)
    return Number(amount) - sectionAmount >= 0
  }
  return amount >= 0
}
