import React from 'react'
import { Modal, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import ChangePasswordForm from './changePasswordForm/ChangePasswordForm'

const useStyles = makeStyles({
  changePassword: {
    width: 648,
    height: 'auto',
    textAlign: 'center',
    padding: '50px 140px',
  },
})

const ChangePassword = ({ open, handleClose, changePassword, isPasswordChanging }) => {
  const classes = useStyles()
  return (
    <Modal open={open} handleClose={handleClose} maxWidth={false}>
      <div className={classes.changePassword}>
        <Typography variant="h4">Change Password</Typography>
        <ChangePasswordForm changePassword={changePassword} isPasswordChanging={isPasswordChanging} />
      </div>
    </Modal>
  )
}

export default ChangePassword
