import React from 'react'
import { useDispatch } from 'react-redux'
import { ActionButton } from 'components'
import { downloadToZipFile } from 'store/actions/vault'
import { ReactComponent as DownloadActiveIcon } from 'static/vault/casesList/bulk_download_active.svg'
import { ReactComponent as DownloadInactiveIcon } from 'static/vault/casesList/bulk_download_inactive.svg'

const VaultCasesBulkDownloadButton = ({ disabled, className, data }) => {
  const dispatch = useDispatch()

  const handleClick = e => {
    e.stopPropagation()
    dispatch(downloadToZipFile([data]))
  }

  return (
    <div className={className}>
      {disabled ? (
        <ActionButton disabled>
          <DownloadInactiveIcon />
        </ActionButton>
      ) : (
        <ActionButton onClick={handleClick}>
          <DownloadActiveIcon />
        </ActionButton>
      )}
    </div>
  )
}

export default VaultCasesBulkDownloadButton
