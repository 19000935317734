import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { colors } from '../../constants'

const useStyles = makeStyles({
  fileInput: {
    display: 'none',
  },
  fileInputLabel: {
    borderRadius: '4px',
    padding: '5px 15px',
    backgroundColor: colors.WHITE,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  text: {
    textTransform: 'uppercase',
  },
})

const FileInput = ({ id, labelClass, children, icon, onChange, disabled, textClasses, value = '', accept }) => {
  const classes = useStyles()
  const buttonIcon = icon && <span>{icon}</span>

  return (
    <>
      <input
        type="file"
        name="file"
        id={id}
        onChange={onChange}
        className={classes.fileInput}
        value={value}
        disabled={disabled}
        accept={accept}
      />
      <label
        htmlFor={id}
        className={classNames({
          [classes.fileInputLabel]: true,
          [labelClass]: true,
        })}
      >
        {buttonIcon}
        <span className={classNames({ [classes.text]: true, [textClasses]: true })}>{children}</span>
      </label>
    </>
  )
}
export default FileInput
