import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { SvgIcon, IconButton } from 'components'
import YouTube from 'react-youtube'
import { ReactComponent as IconClose } from 'static/icon_24_close_white.svg'

const useStyles = makeStyles({
  popupWrapper: {
    position: 'fixed',
    zIndex: 5000,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(61, 61, 61, 0.65)',
    width: '100%',
    height: '100%',
    padding: 70,
    textAlign: 'center',
    '@media(max-width:1023px)': {
      padding: '120px 30px',
    },
    '@media(max-width:767px)': {
      padding: '120px 15px',
    },
  },
  popup: {},
  closeButton: {
    position: 'absolute',
    top: 70,
    right: 70,
    width: 60,
    height: 60,
    background: colors.BASIC.COLOR[100],
    opacity: 0.5,
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      opacity: 0.7,
    },
    '@media(max-width:1023px)': {
      width: 40,
      height: 40,
      right: 30,
    },
    '@media(max-width:767px)': {
      right: 15,
    },
  },
  iconClose: {
    width: 60,
    height: 60,
    '@media(max-width:1023px)': {
      width: 40,
      height: 40,
    },
    '@media(max-width:767px)': {},
  },
})

const VideoPopup = ({ close, src }) => {
  const classes = useStyles()
  const opts = {
    height: window.innerWidth > 1023 ? window.innerHeight - 140 : 300,
    width:
      window.innerWidth > 1023
        ? window.innerWidth - 280
        : window.innerWidth > 767
        ? window.innerWidth - 60
        : window.innerWidth - 30,
    className: 'popup',
    playerVars: {},
  }

  return (
    <div className={classes.popupWrapper}>
      <div className={classes.popup}>
        <YouTube videoId={src} opts={opts} />
      </div>
      <div className={classes.closeButton} onClick={close}>
        <SvgIcon component={IconClose} viewBox="0 0 24 24" className={classes.iconClose} />
      </div>
    </div>
  )
}

export default VideoPopup
