import { ThemeProvider } from '@material-ui/core/styles'
import { Router } from 'react-router-dom'
import React from 'react'
import { Provider } from 'react-redux'
import { Routes } from 'routes'
import { Snackbar } from 'components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { theme } from '../constants'
import store from '../store'
import history from '../history'
import Helmet from 'react-helmet'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <DndProvider backend={Backend}>
            <Router history={history}>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <Routes />
              </GoogleOAuthProvider>
              <Helmet>
                <title>XIRA</title>
                <meta name="description" content="Xira" />
              </Helmet>
            </Router>
            <Snackbar />
          </DndProvider>
        </ThemeProvider>
      </Provider>
    </Elements>
  )
}

export default App
