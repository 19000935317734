import React from 'react'
import { useParams } from 'react-router-dom'
import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Rating, Typography, ProRating, ProRatingTitle, Tooltip, Button } from 'components'
import { colors } from 'constants/index'
import NoPhoto from 'static/search/pro_no photo.png'
import classNames from 'classnames'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Checkmark } from 'static/checkmark.svg'
import { ReactComponent as Communication } from 'static/profile/communication.svg'
import { navigateTo } from '../../../store/actions/navigation'

const useStyles = makeStyles({
  avatarContainerDetails: {
    '& .checkmark rect': {
      fill: '#FFFFFF',
    },
  },
  avatarContainer: {
    display: 'inherit',
    marginBottom: '32px',
  },
  avatarContainerShort: {
    display: 'flex',
    marginBottom: '13px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    height: 200,
    width: 200,
  },
  checkmark: {
    padding: 0,
    width: 52,
    height: 52,
    right: 0,
    bottom: 8,
    '& rect': {
      fill: '#FAFBFC',
    },
  },
  tooltip: {
    margin: '5px 0',
  },
  avatarShort: {
    height: 200,
    width: 200,
    cursor: 'pointer',
    marginBottom: 8,
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.BASIC.COLOR[100],
    fontSize: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    cursor: 'pointer',
    textDecoration: 'none!important',
    maxWidth: '100%',
    marginBottom: 4,
    transition: '0.2s linear',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  nameContainer: {
    width: '50%',
    display: 'inherit',
    marginLeft: '40px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nameContainerShort: {
    width: 'auto',
    marginLeft: 0,
    maxWidth: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
  },
  ratingClassesRoot: {
    color: `${colors.BASIC.COLOR[80]}!important`,
    fontSize: '18px !important',
  },
  meetButton: {
    marginTop: 26,
    width: '100%',
  },
  meetButtonLink: {
    textDecoration: 'none',
  },
  lineClamp: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  responsiveTitle: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '25px',
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 600,
    lineHeight: 1.44,
  },
  '@media(max-width:1279px)': {
    avatarContainerDetails: {
      display: 'flex',
      marginBottom: '13px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatarProDetails: {
      height: 150,
      width: 150,
      cursor: 'pointer',
      marginBottom: 8,
    },
    nameContainerDetails: {
      width: 'auto',
      marginLeft: 0,
      maxWidth: '100%',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
    responsiveTitle: {
      width: '100%',
    },
  },
  '@media(min-width: 768px) and (max-width: 939px)': {
    avatarContainerShort: {
      flexDirection: 'row',
      alignItems: 'initial',
    },
    checkmark: {
      width: 24,
      height: 24,
      '&.checkmarkProfile': {
        width: 44,
        height: 44,
      },
    },
    avatarShort: {
      height: 90,
      width: 90,
    },
    lineClamp: {
      width: '210px',
      '& + p': {
        display: 'none',
      },
    },
    linkUploaded: {
      marginTop: 16,
    },
    nameContainerShort: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginLeft: '12px',
    },
  },
  ratingTooltipTextClasses: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textUnderlinePosition: 'under',
  },
  '@media(max-width: 767px)': {
    checkmark: {
      width: 32,
      height: 32,
      '&.checkmarkProfile': {
        width: 44,
        height: 44,
      },
    },
    avatarShort: {
      height: 90,
      width: 90,
    },
  },
  mainProAccountRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullProfileRatingMargin: {
    marginTop: 19,
  },
  hideRating: {
    display: 'none',
  },
  ratingContainer: {
    margin: 0,
  },
  ratingTooltipContainer: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
  },
})

const TYPE = {
  SHORT_VIEW: 'short_view',
  PRO_DETAILS: 'pro_details_view',
}

const ClampName = ({ firstName, lastName }) => {
  const classes = useStyles()
  return (
    <>
      <span className={classes.lineClamp}>{firstName}</span>
      <span className={classes.lineClamp}>{lastName}</span>
    </>
  )
}

const ProReviewRating = ({ rating, showTooltip }) => {
  const classes = useStyles()
  return (
    <Rating
      value={rating}
      name="rating"
      readOnly
      ratingClasses={{ root: classes.ratingClassesRoot }}
      ratingContainerClasses={classNames(classes.ratingContainer, { [classes.ratingTooltipContainer]: showTooltip })}
      precision={0.1}
    />
  )
}
const MainProAccount = ({
  photo,
  firstName,
  lastName,
  type,
  rating,
  reviews,
  openRatingModal,
  stats,
  proReviewCommentsCount,
  proID,
  checkmark,
  checkmarkClassName = '',
  isUploaded,
  email,
}) => {
  const dispatch = useDispatch()
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  const proAccount = useSelector(state => state.search.proAccount)
  const states = useSelector(state => state.profileCreation.states)
  const licenseNumber = proAccount.licenses[0]?.bar_id ?? ''
  const licenseState = states.find(el => el.value === proAccount.licenses[0]?.state)?.label ?? ''
  const practiceAreas = proAccount.practiceAreas.map(el => el.selected_practice_area.name).join(',')
  const subSpecialties = proAccount.practiceAreas
    .map(el => el.selected_sub_specialities.map(e => e.name).join(','))
    .join(',')
  const params = useParams()
  const classes = useStyles()
  const fullName =
    firstName.length >= 16 || lastName.length >= 16 ? (
      <ClampName firstName={firstName} lastName={lastName} />
    ) : (
      `${firstName} ${lastName}`
    )

  const onContactPro = () => {
    if (isUserLoggedIn) {
      window.open(`mailto:${email}?subject=${encodeURIComponent('CONFIDENTIAL CLIENT INQUIRY FROM XIRA.COM')}`, '_self')
    } else {
      dispatch(navigateTo('/sign-up?role=client'))
    }
  }

  return (
    <div
      className={classNames(classes.avatarContainer, {
        [classes.avatarContainerShort]: type === TYPE.SHORT_VIEW,
        [classes.avatarContainerDetails]: type === TYPE.PRO_DETAILS,
      })}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          checkmark ? (
            <div>
              <Tooltip title="Peer recommended" tooltipClass={classes.tooltip}>
                <Checkmark className="checkmark" />
              </Tooltip>
            </div>
          ) : null
        }
        classes={{ badge: `${classes.checkmark} ${checkmarkClassName}` }}
      >
        <Avatar
          className={classNames(classes.avatar, {
            [classes.avatarShort]: type === TYPE.SHORT_VIEW,
            [classes.avatarProDetails]: type === TYPE.PRO_DETAILS,
          })}
          src={photo || NoPhoto}
        />
      </Badge>
      <div
        className={classNames(classes.nameContainer, {
          [classes.nameContainerShort]: type === TYPE.SHORT_VIEW,
          [classes.nameContainerDetails]: type === TYPE.PRO_DETAILS,
        })}
      >
        {params.pro_uuid && (
          <Helmet>
            <meta
              name="description"
              content={`find book meet lawyer, ${proAccount.bio}, ${practiceAreas}, ${subSpecialties}, ${licenseState}`}
            />
            <title>{fullName ? `Attorney ${fullName} / ${licenseNumber}` : 'XIRA'}</title>
          </Helmet>
        )}
        {type === TYPE.SHORT_VIEW ? (
          <Typography className={classNames(classes.link, { [classes.linkUploaded]: isUploaded })}>
            {fullName}
          </Typography>
        ) : (
          <Typography variant="h1" className={classes.responsiveTitle}>
            {fullName}
          </Typography>
        )}
        <div
          className={classNames(classes.mainProAccountRow, {
            [classes.fullProfileRatingMargin]: type === TYPE.PRO_DETAILS,
            [classes.hideRating]: isUploaded,
          })}
        >
          {type === TYPE.SHORT_VIEW || !proReviewCommentsCount ? (
            <>
              <ProReviewRating rating={rating} />
              <ProRatingTitle rating={rating} reviews={reviews} />
            </>
          ) : (
            <ProRating
              stats={stats}
              reviews={reviews}
              rating={rating}
              openRatingModal={openRatingModal}
              proID={proID}
              ratingComponent={<ProReviewRating rating={rating} showTooltip />}
            />
          )}
        </div>
        {!!email && (
          <Button
            variant="secondary"
            className={classes.meetButton}
            startIcon={<Communication />}
            onClick={onContactPro}
          >
            CONTACT
          </Button>
        )}
      </div>
    </div>
  )
}

export default MainProAccount
