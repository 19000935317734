import * as CONSTANTS from 'store/constants/connections'
import axios, { CancelToken } from 'axios'
import {
  getInvitedUserContactInfoPath,
  getInviteForClientPath,
  getInviteLinkPath,
  getInvitesForClientPath,
  getUserConnectionPath,
  getUserConnectionsPath,
  getResendInviteLink,
  getPredefndeInvitedUserContactInfoPath,
  getUserConnectionsSwitchPath,
} from 'utils/path-helpers/api'
import {
  mapConnectionFilterToFront,
  mapConnectionInfoToFront,
  mapProConnectionInfoToFront,
} from 'utils/mappers/backend'
import { getConnectionsParams, getProConnectionsParams } from 'utils/getters/connections'
import { openSnackbar } from '../common'
import { SET_ARCHIVE_USER_CONNECTIONS_START } from 'store/constants/connections'

const getUserConnectionsStart = () => ({
  type: CONSTANTS.GET_USER_CONNECTIONS_START,
})

const getUserConnectionsEnd = (connections, length) => ({
  type: CONSTANTS.GET_USER_CONNECTIONS_END,
  payload: connections,
  length: length,
})

export const getUserConnections = (isReloading = true, isArchived) => (dispatch, getState) => {
  dispatch(getUserConnectionsStart())
  axios
    .get(getUserConnectionsPath(), {
      params: {
        ...getConnectionsParams(getState()),
        is_archived: isArchived,
      },
    })
    .then(response => {
      const data = response.data.results.map(connection => mapConnectionInfoToFront(connection))
      const newData = isReloading ? data : [...getState().connections.connections, ...data]
      dispatch(getUserConnectionsEnd(newData, response.data.count))
    })
    .catch(e => {
      dispatch(getUserConnectionsEnd([]))
      dispatch(openSnackbar('error', 'Error while loading connections'))
      return Promise.reject(e)
    })
}

let cancelProConnectionsRequest

export const getProConnections = () => (dispatch, getState) => {
  cancelProConnectionsRequest && cancelProConnectionsRequest()
  dispatch(getUserConnectionsStart())
  return axios
    .get(getUserConnectionsPath(), {
      params: getProConnectionsParams(getState()),
      cancelToken: new CancelToken(c => {
        cancelProConnectionsRequest = c
      }),
    })
    .then(response => {
      const data = response.data.results.map(connection => mapProConnectionInfoToFront(connection))
      dispatch(getUserConnectionsEnd(data, response.data.count))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(getUserConnectionsEnd([]))
      dispatch(openSnackbar('error', 'Error while loading connections'))
      return Promise.reject(e)
    })
}

export const resetConnectionsUploadOffset = () => ({
  type: CONSTANTS.RESET_CONNECTIONS_UPLOAD_OFFSET,
})

export const increaseConnectionsUploadOffset = () => ({
  type: CONSTANTS.INCREASE_CONNECTIONS_UPLOAD_OFFSET,
})

export const setTableConfiguration = payload => ({
  type: CONSTANTS.SET_TABLE_CONFIGURATION,
  payload,
})

export const resetConnectionsList = () => ({
  type: CONSTANTS.RESET_CONNECTIONS_LIST,
})

const getUserConnectionsForFilterStart = () => ({
  type: CONSTANTS.GET_USER_CONNECTIONS_FOR_FILTER_START,
})

const getUserConnectionsForFilterEnd = connections => ({
  type: CONSTANTS.GET_USER_CONNECTIONS_FOR_FILTER_END,
  payload: connections,
})

const archiveUserConnectionsStart = () => ({
  type: CONSTANTS.SET_ARCHIVE_USER_CONNECTIONS_START,
})

const archiveUserConnectionsEnd = () => ({
  type: CONSTANTS.SET_ARCHIVE_USER_CONNECTIONS_END,
})

export const getUserConnectionsForFilter = (filter = {}) => dispatch => {
  dispatch(getUserConnectionsForFilterStart())
  return axios
    .get(getUserConnectionsPath(), {
      params: {
        ordering: 'full_name',
        ...filter,
      },
    })
    .then(response => {
      const data = response.data.map(connection => mapConnectionFilterToFront(connection))
      dispatch(getUserConnectionsForFilterEnd(data))
    })
    .catch(e => {
      dispatch(getUserConnectionsForFilterEnd([]))
      dispatch(openSnackbar('error', 'Error while loading connections'))
      return Promise.reject(e)
    })
}

export const archiveUserConnections = uuid => dispatch => {
  dispatch(archiveUserConnectionsStart())
  return axios
    .patch(getUserConnectionsSwitchPath(uuid))
    .then(() => {
      dispatch(archiveUserConnectionsEnd())
    })
    .catch(e => {
      dispatch(getUserConnectionsForFilterEnd([]))
      return Promise.reject(e)
    })
}

export const setConnectionFilter = (name, value) => ({
  type: CONSTANTS.SET_FILTERS,
  name,
  value,
})

export const setConnectionsSorting = (property, isAsc) => {
  return {
    type: CONSTANTS.SET_SORTING,
    property,
    isAsc,
  }
}

export const openInvitePopup = open => {
  return {
    type: CONSTANTS.OPEN_INVITE_POPUP,
    open,
  }
}
const isSendInviteLinkStart = () => ({
  type: CONSTANTS.IS_SEND_INVITE_LINK_START,
})

const isSendInviteLinkEnd = () => ({
  type: CONSTANTS.IS_SEND_INVITE_LINK_END,
})

export const sendInviteLink = payload => async dispatch => {
  try {
    dispatch(isSendInviteLinkStart())
    const response = await axios.post(getInviteLinkPath(), payload)
    dispatch(isSendInviteLinkEnd())
    return response.data
  } catch (e) {
    dispatch(isSendInviteLinkEnd())
    if (e.response.data.title == 'The invitation has already been sent') {
      return e.response.data
    } else {
      dispatch(openInvitePopup(false))
      dispatch(openSnackbar('error', e.response.data.detail || 'Error while sending invite link'))
    }
  }
}

export const editInviteInfo = (payload, uuid) => dispatch => {
  dispatch(isSendInviteLinkStart())
  axios
    .put(getUserConnectionPath(uuid), payload)
    .then(data => {
      dispatch(isSendInviteLinkEnd())
      dispatch(getProConnections())
      dispatch(openInvitePopup(false))
      dispatch(openSnackbar('success', 'Client information was updated'))
    })
    .catch(e => {
      dispatch(isSendInviteLinkEnd())
      dispatch(openInvitePopup(false))
      dispatch(openSnackbar('error', e.response.data.detail || 'Error while client information update'))
    })
}

const setClientInvites = payload => ({
  type: CONSTANTS.SET_CLIENT_INVITES,
  payload,
})

const setClientInvitesIsLoading = payload => ({
  type: CONSTANTS.SET_CLIENT_INVITES_LOADING,
  payload,
})

export const getInvitationsForClient = () => async dispatch => {
  try {
    dispatch(setClientInvitesIsLoading(true))
    const response = await axios.get(getInvitesForClientPath(), {
      params: {
        is_hidden: false,
      },
    })
    dispatch(setClientInvites(response.data))
  } catch (e) {
    dispatch(openSnackbar('error', e.response.data.detail || 'Error while loading invites'))
    dispatch(setClientInvites([]))
  } finally {
    dispatch(setClientInvitesIsLoading(false))
  }
}

export const hideConnectionInvite = uuid => async dispatch => {
  try {
    dispatch(setClientInvitesIsLoading(true))
    await axios.put(getInviteForClientPath(uuid), {
      is_hidden: true,
    })
  } catch (e) {
    dispatch(openSnackbar('error', e.response.data.detail || 'Error while hiding invite'))
    return Promise.reject(e)
  } finally {
    dispatch(setClientInvitesIsLoading(false))
  }
}

export const acceptConnectionInvite = uuid => async dispatch => {
  try {
    dispatch(setClientInvitesIsLoading(true))
    await axios.put(getInviteForClientPath(uuid), {
      is_accepted: true,
    })
  } catch (e) {
    dispatch(openSnackbar('error', e.response.data.detail || 'Error while accepting invite'))
    return Promise.reject(e)
  } finally {
    dispatch(setClientInvitesIsLoading(false))
  }
}

export const deleteInvite = uuid => async dispatch => {
  try {
    await axios.delete(getUserConnectionPath(uuid))
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getInvitedUserContactInfo = () => () => {
  return axios
    .get(getInvitedUserContactInfoPath())
    .then(response => {
      const info = response.data[0]
      if (info) {
        const { optional_phone, ...data } = info
        return {
          ...data,
          optionalPhone: optional_phone,
        }
      } else {
        return {
          street: '',
          city: '',
          zip: '',
          state: '',
          country: 840,
          phone: '',
          suite: '',
          optionalPhone: '',
        }
      }
    })
    .catch(() => {
      return {
        street: '',
        city: '',
        zip: '',
        state: '',
        country: 840,
        phone: '',
        suite: '',
        optionalPhone: '',
      }
    })
}

export const resendInviteLink = uuid => dispatch => {
  return axios
    .post(getResendInviteLink(), {
      uuid,
    })
    .then(() => {
      dispatch(openSnackbar('success', 'Invitation link successfully sent'))
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while resending invite link'))
    })
}

export const getPredefinedInvitedUserContactInfo = uuid => () => {
  return axios
    .get(getPredefndeInvitedUserContactInfoPath(uuid))
    .then(response => {
      const info = response.data[0]
      if (info) {
        return info
      }
    })
    .catch(e => {
      return {
        street: '',
        city: '',
        zip: '',
        state: '',
        country: 840,
        phone: '',
        suite: '',
      }
    })
}
