import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'

const useStyles = makeStyles({
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
})
const VideoPlayer = ({ ...restProps }) => {
  const classes = useStyles()
  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer className={classes.reactPlayer} {...restProps} />
    </div>
  )
}

export default VideoPlayer
