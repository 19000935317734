export const EVENTS = {
  VISIT_LOBBY: 'Visit_Lobby',
  REGISTRATION: 'Registration',
  RESEND_THE_LINK: 'Resend_the_link',
  PRO_DELETED_GOOGLE_CALENDAR: 'Pro_Deleted_Google_Calendar',
  PRO_DELETED_ICALENDAR: 'Pro_Deleted_iCalendar',
  PRO_DELETED_OUTLOOK_CALENDAR: 'Pro_Deleted_Outlook_Calendar',
  PRO_ADDED_GOOGLE_CALENDAR: 'Pro_Added_Google_Calendar',
  PRO_ADDED_ICALENDAR: 'Pro_Added_iCalendar',
  PRO_ADDED_OUTLOOK_CALENDAR: 'Pro_Added_Outlook_Calendar',
  PRO_CONTACT_INFO_COMPLETED: 'Pro_Contact_Info_Completed',
  PRO_LICENSING_COMPLETED: 'Pro_Licensing_Completed',
  PRO_PRACTICE_AREAS_COMPLETED: 'Pro_Practice_Areas_Completed',
  PRO_PROFILE_PICTURE_COMPLETED: 'Pro_Profile_Picture_Completed',
  PRO_ADVANCED_CAL_SETTINGS_COMPLETED: 'Pro_Advanced_Cal_Settings_Completed',
  PRO_REGISTERED: 'Pro_Registered',
  VIEW_PRO_DETAILS: 'View_Pro_Details',
  SEARCH: 'Search',
  SELECT_TIME: 'Select_time',
  LOGIN: 'Login',
  CONSULTATION_SCHEDULE: 'Consultation_Schedule',
  VERIFY_EMAIL: 'Verify_Email',
  PRO_ADDED_STRIPE_CONNECT: 'Pro_Added_Stripe_Connect',
  PRO_DELETED_STRIPE_CONNECT: 'Pro_Deleted_Stripe_Connect',
  CLIENT_ADDED_CARD: 'Client_Added_Card',
  CLIENT_DELETED_CARD: 'Client_Deleted_Card',

  VIEW_MAIN_PAGE: 'View_Main_Page',
  VIEW_ABOUT_XIRA: 'View_About_Xira',
  CLICK_FAQ: 'Click_FAQ',
  VIEW_XIRA_FOR_LAWYERS: 'View_XIRA_for_Lawyers',
  CLICK_SUPPORT: 'Click_Support',
  VIEW_USER_AGREEMENT: 'View_User_Agreement',
  VIEW_PRIVACY_POLICY: 'View_Privacy_Policy',
  VIEW_CONTACT_US: 'View_Contact_Us',
  VIEW_PRESS: 'View_Press',
  VIEW_SIGN_UP: 'View_Sign_Up',

  CLICK_FB: 'Click_FB',
  CLICK_TWITTER: 'Click_Twitter',
  CLICK_LINKEDIN: 'Click_Linkedin',
  CLICK_YOUTUBE: 'Click_Youtube',

  PRO_OPENED_MESSAGES: 'Pro_Opened_Messages',
  CLIENT_OPENED_MESSAGES: 'Client_Opened_Messages',
  CLIENT_SUBMITTED_FEEDBACK: 'Client_Submitted_Feedback',
  CLIENT_OPEN_FEEDBACK: 'Client_Open_Feedback',
  CLIENT_CLICK_FEEDBACK: 'Client_Click_Feedback',
  PRO_OPENED_VAULT: 'Pro_Opened_Vault',
  CLIENT_OPENED_VAULT: 'Client_Opened_Vault',
  PRO_SENT_MESSAGE: 'Pro_Sent_Message',
  CLIENT_SENT_MESSAGE: 'Client_Sent_Message',
  PRO_PREVIEWED_FILE: 'Pro_Previewed_File',
  CLIENT_PREVIEWED_FILE: 'Client_Previewed_File',
  PRO_DOWNLOADED_FILE: 'Pro_Downloaded_File',
  CLIENT_DOWNLOADED_FILE: 'Client_Downloaded_File',
  PRO_OPENED_NOTIFICATIONS: 'Pro_Opened_Notifications',
  CLIENT_OPENED_NOTIFICATIONS: 'Client_Opened_Notifications',
  PRO_OPENED_LOGS: 'Pro_Opened_Logs',
  CLIENT_OPENED_LOGS: 'Client_Opened_Logs',
  PRO_OPENED_TRASH: 'Pro_Opened_Trash',

  //New

  CLIENT_ACCEPTED_INVITE: 'Client_Accepted_Invite',
  PRO_INVITED_CLIENT: 'Pro_Invited_Client',
}
