import React, { useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Typography, ContactInfoFormBody } from 'components'
import { CONSTANTS } from '../../constants/index'

const useStyles = makeStyles({
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 24,
  },
})

const ContactInfoFormRef = React.forwardRef((props, ref) => {
  const { className, countries, states, updateContactInfo, submitForm } = props
  useImperativeHandle(
    ref,
    () => ({
      submitForm,
    }),
    []
  )

  return (
    <Form>
      <div className={className}>
        <ContactInfoFormBody countries={countries} states={states} updateContactInfo={updateContactInfo} />
      </div>
    </Form>
  )
})

export default ContactInfoFormRef
