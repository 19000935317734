import React from 'react'
import { CommonInvoicesTable, ProInvoicesTableRow, InvoiceDocument } from 'components'
import { getProInvoice, getProInvoiceSummary } from 'store/actions/invoices'
import { useDispatch } from 'react-redux'
import { CONSTANTS } from 'constants/index'
import { pdf } from '@react-pdf/renderer'
import { exportFile } from 'store/actions/common'
import * as moment from 'moment'
import { navigateToInvoiceDetails } from 'store/actions/navigation'
import { tooltip } from 'constants/index'
import InvoiceSummary from './pdf/summaryPDF'
import InvoicePaymentsCredits from './pdf/paymentsCredits'
import PDFMerger from 'pdf-merger-js/browser'

const columns = [
  {
    value: 'invoice_number',
    label: 'Invoice number',
    sortable: true,
  },
  {
    value: 'issued_at',
    label: 'Issued',
    sortable: true,
  },
  {
    value: 'client_full_name',
    label: 'Client',
    sortable: true,
  },
  {
    value: 'case__name',
    label: 'Case/Matter',
    sortable: true,
  },
  {
    value: 'amount_due',
    label: 'Amount due',
    sortable: true,
    numeric: true,
  },
  {
    value: 'amount_paid',
    label: 'Paid',
    sortable: true,
    numeric: true,
  },
  {
    value: 'status',
    label: 'Status',
    sortable: true,
  },
]

const InvoicesTable = ({ invoices, timezone, setRecordTransactionInvoice, setWriteOffInvoice }) => {
  const dispatch = useDispatch()

  const getRowActions = rowData => {
    const recordTransactionAction = rowData.isPaymentRecordAvailable
      ? [
          {
            key: 'transaction',
            label: 'record transaction',
            action: data => {
              setRecordTransactionInvoice(data)
            },
          },
        ]
      : []

    const writeOffAction = rowData.isWriteOffAvailable
      ? [
          {
            key: 'write',
            label: 'write-off',
            tooltipText: tooltip.writeOffTooltipText,
            action: data => {
              setWriteOffInvoice(data)
            },
          },
        ]
      : []
    return [
      {
        key: 'view',
        label: 'view details',
        action: data => {
          dispatch(navigateToInvoiceDetails(data.uuid))
        },
      },
      {
        key: 'download',
        label: 'download in PDF',
        action: async data => {
          const pdfInvoice = await dispatch(getProInvoice(data.uuid, true))
          const pdfInvoiceSummary = await dispatch(getProInvoiceSummary(data.uuid, true))
          const fileName = `Invoice ${data.invoiceNumber} ${moment().format(CONSTANTS.DATE_FORMAT.PDF_DATE)}.pdf`
          if (!pdfInvoice.file_link) {
            const blob = await pdf(
              <InvoiceDocument timezone={timezone} download invoice={pdfInvoice} summary={pdfInvoiceSummary} />
            ).toBlob()
            dispatch(exportFile(blob, fileName, 'application/pdf'))
          } else {
            try {
              const summaryBlob = await pdf(
                <InvoiceSummary timezone={timezone} invoice={pdfInvoice} summary={pdfInvoiceSummary} />
              ).toBlob()
              const paymentsBlob = await pdf(
                <InvoicePaymentsCredits timezone={timezone} invoice={pdfInvoice} summary={pdfInvoiceSummary} />
              ).toBlob()

              const pdfFile = await fetch(pdfInvoice.file_link).then(res => res.blob())
              const pdfBlob = new Blob([pdfFile], { type: 'application/pdf' })
              let merger = new PDFMerger()
              await Promise.all([summaryBlob, pdfBlob, paymentsBlob].map(async file => await merger.add(file, null)))

              const mergedPdf = await merger.saveAsBlob()
              dispatch(exportFile(mergedPdf, fileName, 'application/pdf'))
              merger = null
            } catch (err) {
              console.log(err)
            }
          }
        },
      },
      ...recordTransactionAction,
      ...writeOffAction,
    ]
  }

  return (
    <CommonInvoicesTable
      invoices={invoices}
      getRowActions={getRowActions}
      tableDataRowRenderer={(columns, data) => (
        <ProInvoicesTableRow timezone={timezone} columns={columns} data={data} />
      )}
      columns={columns}
    />
  )
}

export default InvoicesTable
