import React from 'react'
import { Modal } from 'components'
import { navigateTo, navigateToLogin, navigateToRoot } from 'store/actions/navigation'
import SignUp from 'components/auth/signUp/signUp'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { setAuthRole } from 'store/actions/auth'
import { routes } from 'constants/index'

const useStyles = makeStyles({
  closeButtonClass: {
    display: 'none',
  },
})
const SignUpContainer = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const disableClose = props.location.pathname === routes.SIGN_UP_ONLY

  const goToLogin = location => {
    if (disableClose) {
      dispatch(navigateTo('/sign-in'))
    } else {
      dispatch(navigateToLogin(location))
    }
  }

  const closeModal = () => {
    if (disableClose) return
    dispatch(setAuthRole(''))
    dispatch(navigateToRoot())
  }

  return (
    <Modal open scroll="body" handleClose={closeModal} closeButtonClass={disableClose && classes.closeButtonClass}>
      <SignUp goToLogin={goToLogin} />
    </Modal>
  )
}

export default SignUpContainer
