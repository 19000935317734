import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { FormikInput, Button, Icon, Modal, Typography, Avatar, Link, Tooltip } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ICloudXIRALogo } from 'static/xira_icloud.svg'
import { ReactComponent as WatchVideoIcon } from 'static/watch_video.svg'
import ICloudGeneratePassword from 'static/icloud.png'
import { colors } from 'constants/colors'
import { useSelector } from 'react-redux'

const initialFormikValues = {
  username: '',
  password: '',
}

const schema = Yup.object({
  username: Yup.string()
    .email('Enter a valid email address')
    .trim()
    .required('please type your email'),
  password: Yup.string()
    .trim()
    .required('please type your password'),
})

const useStyles = makeStyles({
  title: {
    marginTop: 24,
    marginBottom: 8,
    fontWeight: 500,
  },
  subtitle: {
    marginBottom: 24,
  },
  iCalendarLogin: {
    width: 666,
    height: 'auto',
    padding: '32px 96px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btn: {
    marginBottom: 50,
    width: 384,
  },
  input: {
    width: 384,
  },
  signUpForm: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
  },
  instruction: {
    fontSize: 15,
  },
  instructionContainer: {
    width: 384,
    textAlign: 'left',
  },
  redirectBtn: {
    marginTop: 29,
    alignSelf: 'flex-end',
    marginRight: 40,
  },
  icon: {
    marginLeft: 4,
    fontSize: 15,
  },
  row: {
    display: 'flex',
    marginBottom: 16,
  },
  rowStep2: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: colors.PRIMARY.COLOR[50],
    color: colors.WHITE,
    fontSize: 15,
    width: 32,
    height: 32,
    marginRight: 16,
  },
  tooltip: {
    padding: 15,
    maxWidth: 590,
  },
  tooltipIcon: {
    fontSize: 17,
    color: colors.PRIMARY.COLOR[50],
    marginLeft: 5,
  },
  errorWrapper: {
    marginBottom: 15,
  },
  error: {
    color: colors.ERROR,
  },
  errorIcon: {
    marginRight: 5,
  },
  errorLink: {
    fontSize: 15,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  notStandPass: {
    fontSize: 13,
    color: '#2b4157',
    lineHeight: 1.46,
    letterSpacing: 'normal',
    fontWeight: 'normal',
  },
})
const TooltipContent = () => <img src={ICloudGeneratePassword} alt="" />

const ICalendarSocialLogin = ({ open, closeModal, onSubmit }) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const isICloudCalendarConnecting = useSelector(state => state.calendar.isICloudCalendarConnecting)
  const onFormSubmit = formData => {
    onSubmit(formData)
      .then(() => {
        handleClose()
      })
      .catch(e => setError(true))
  }
  const handleClose = () => {
    setError(false)
    closeModal()
  }
  const showTutorialVideo = () => {
    window.open('https://www.youtube.com/watch?v=V7G5j00tc9s&feature=emb_logo')
  }
  const getWatchVideoButton = () => {
    return (
      <Button onClick={showTutorialVideo} variant="text" startIcon={<WatchVideoIcon />}>
        watch tutorial video
      </Button>
    )
  }
  return (
    <Modal maxWidth="lg" open={open} handleClose={handleClose} scroll="body">
      <div className={classes.iCalendarLogin}>
        <div>
          <ICloudXIRALogo />
        </div>
        <Typography className={classes.title} variant="h3">
          iCloud integration
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          XIRA needs to authenticate with iCloud to connect to your calendar
        </Typography>
        <div className={classes.instructionContainer}>
          <div className={classes.row}>
            <Avatar className={classes.badge}>1</Avatar>
            <div className={classes.column}>
              <Typography className={classes.instruction} variant="subtitle1">
                Sign into your{' '}
                <Link href="https://appleid.apple.com/#!&page=signin" target="_blank">
                  iCloud account
                </Link>
                .
              </Typography>
              {getWatchVideoButton()}
            </div>
          </div>
          <div className={classes.row}>
            <Avatar className={classes.badge}>2</Avatar>
            <div>
              <Typography className={classes.instruction} variant="subtitle1">
                In the <b>Security</b> section click on <b>Generate App</b>
              </Typography>
              <div className={classes.rowStep2}>
                <Typography className={classes.instruction} variant="subtitle1">
                  <b>Specific Password.</b>
                </Typography>
                <Tooltip title={<TooltipContent />} tooltipClass={classes.tooltip} interactive>
                  <Icon style={classes.tooltipIcon} iconClass="las la-info-circle" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <Avatar className={classes.badge}>3</Avatar>
            <Typography className={classes.instruction} variant="subtitle1">
              Copy password generated and paste below.
            </Typography>
          </div>
        </div>
        <Formik
          initialValues={initialFormikValues}
          validationSchema={schema}
          onSubmit={onFormSubmit}
          validateOnChange={false}
        >
          {formikProps => (
            <Form className={classes.signUpForm} onSubmit={formikProps.handleSubmit}>
              <FormikInput
                className={classes.input}
                variant="outlined"
                name="username"
                label="iCloud email address"
                error={error}
                type="text"
              />
              <FormikInput
                className={classes.input}
                variant="outlined"
                name="password"
                label={
                  <div>
                    <Typography>App-specific password</Typography>
                    <Typography className={classes.notStandPass}>This is not your standard iCloud password</Typography>
                  </div>
                }
                type="password"
                error={error}
              />
              {error && (
                <div className={classes.errorWrapper}>
                  <Typography className={classes.error} variant="subtitle2">
                    <Icon style={classes.errorIcon} iconClass="las la-exclamation-triangle" />
                    Make sure you are using the correct email address and app-specific password.
                  </Typography>
                  {getWatchVideoButton()}
                </div>
              )}
              <Button
                variant="primary"
                isButtonBlocked={isICloudCalendarConnecting}
                disabled={isICloudCalendarConnecting}
                type="submit"
                className={classes.btn}
              >
                Connect
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default ICalendarSocialLogin
