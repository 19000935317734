import React, { useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { TextField } from 'components'
import parse from 'autosuggest-highlight/parse'
import { colors, CONSTANTS } from '../../constants'
import { setPracticeAreaValue } from 'store/actions/search'

const useStyles = makeStyles({
  root: {
    paddingRight: '90px !important',
    width: 350,
    borderRadius: '4px 0 0 4px',
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
    },
  },
  rootStartPage: {
    height: 55,
    width: 420,
    '@media(max-width:1023px) and (min-width:768px)': {
      width: 300,
    },
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
      width: 'calc(100vw - 30px)',
      borderRadius: 4,
      marginBottom: 12,
    },
  },
  headerPage: {
    height: 32,
    padding: '0 !important',
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    borderRightWidth: 0,
    '&:hover': {
      border: `solid 1px ${colors.BASIC.COLOR[20]}`,
      borderRadius: '4px 0 0 4px',
    },
  },
  startPage: {
    borderWidth: 0,
  },
  header: {
    borderWidth: 0,
  },
  hover: {
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
        },
      },
    },
  },
  '@media(max-width:1279px)': {
    headerPage: {
      width: 250,
    },
  },
  '@media(max-width:939px)': {
    headerPage: {
      width: 205,
    },
  },
  '@media(max-width:767px)': {
    headerPage: {
      width: '100%',
      marginBottom: 12,
      borderRadius: 4,
      height: 54,
    },
    header: {
      borderRightWidth: 0,
    },
  },
  groupLabel: {
    color: colors.BASIC.COLOR['70'],
    fontWeight: 'bold',
    fontSize: 14,
  },
  input: {
    paddingLeft: '12px !important',
    paddingRight: '80px !important',
  },
  wrapper: {
    '@media(min-width:768px)': {
      borderRight: `1px solid ${colors.BASIC.COLOR['80']}`,
    },
  },
  group: {
    padding: '5px 15px',
    color: colors.BASIC.COLOR['70'],
    fontWeight: 500,
  },
  hideGroup: {
    padding: 0,
  },
  option: {
    color: colors.BLACK,
    fontWeight: 400,
  },
})

const AutocompleteOption = props => {
  const { name, value, children, type } = props
  const classes = useStyles()

  const optionSplit = /\W/.test(value)
    ? value.toLowerCase()
    : name
        .split(/\W/)
        .filter(world => world.toLowerCase().startsWith(value.toLowerCase()))
        .find(opt => !CONSTANTS.WORLDS_EXCLUDE_IN_SEARCH.includes(opt.toLowerCase()))
  const index = optionSplit ? name.toLowerCase().indexOf(optionSplit.toLowerCase()) : -1
  const matches = index === -1 ? [[0, 0]] : [[index, index + value.length]]
  const parts = parse(name, matches)

  return (
    <li
      className={
        type === 'group' ? classNames({ [classes.group]: true, [classes.hideGroup]: value !== '' }) : classes.option
      }
    >
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : type === 'group' ? 500 : 400 }}>
          {value !== '' && type === 'group' ? '' : part.text}
        </span>
      ))}
      {children}
    </li>
  )
}

const PracticeAreaAutocomplete = ({ type, currentSearchPracticeArea, setError, setAreaObject }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const autocompletePracticeArray = useSelector(state => state.search.autocompletePracticeArray)
  const [value, setValue] = useState('')

  const onOptionChange = value => {
    const changeValue = value ? value.name : ''
    dispatch(setPracticeAreaValue(changeValue))
    setAreaObject(value || {})
    setError(false)
  }

  const filterPracticeAreas = (options, { inputValue }) => {
    if (!inputValue && value && currentSearchPracticeArea.name === value) {
      //when practice area is selected explicitly the search should be filterd only by it
      return options.filter(option => option.name === value)
    }
    if (!inputValue) {
      return options.filter(option => !option.is_synonym)
    }
    return /\W/.test(inputValue)
      ? options.filter(option => option.name.toLowerCase().startsWith(inputValue.toLowerCase()))
      : options
          .map(option => {
            let index = 100
            option.name.split(/\W/).forEach((opt, i) => {
              if (
                opt.toLowerCase().startsWith(inputValue.toLowerCase()) &&
                !CONSTANTS.WORLDS_EXCLUDE_IN_SEARCH.includes(opt.toLowerCase()) &&
                index === 100
              ) {
                index = i
              }
            })
            return { ...option, wordIndex: index }
          })
          .filter(option => option.wordIndex < 100)
          .sort((optionA, optionB) => {
            if (optionA.wordIndex > optionB.wordIndex) {
              return 1
            }
            if (optionA.wordIndex < optionB.wordIndex) {
              return -1
            }
            return 0
          })
  }

  const renderPracticeAreaOptions = (option, { inputValue }) => {
    setValue(inputValue)
    return <AutocompleteOption name={option.name} value={inputValue} />
  }

  return (
    <div className={type === 'startPage' && classes.wrapper}>
      <Autocomplete
        id="autocomplete-practice-area"
        autoHighlight
        classes={{
          inputRoot: classNames(classes.root, {
            [classes.rootStartPage]: type === 'startPage',
            [classes.headerPage]: type === 'header',
          }),
          groupLabel: classes.groupLabel,
        }}
        filterOptions={filterPracticeAreas}
        onChange={(e, value) => onOptionChange(value)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={autocompletePracticeArray}
        value={currentSearchPracticeArea || { name: '' }}
        popupIcon={null}
        groupBy={option => option.practiceArea}
        renderGroup={option => (
          <AutocompleteOption name={option.group} value={value} type="group">
            {option.children}
          </AutocompleteOption>
        )}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            placeholder="legal topic or attorney name"
            className={classes.hover}
            InputProps={{
              ...params.InputProps,
              classes: { notchedOutline: classes[type], input: classes.input },
            }}
          />
        )}
        renderOption={renderPracticeAreaOptions}
        disableClearable
      />
    </div>
  )
}

export default PracticeAreaAutocomplete
