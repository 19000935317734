import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CONSTANTS } from 'constants/index'
import { Typography, TutorialVideoTile, VideoPopup } from 'components'

const useStyles = makeStyles({
  root: {
    padding: 26,
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 24,
  },
  videoTile: {
    marginRight: 24,
    marginBottom: 24,
  },
})

const videos = Object.values(CONSTANTS.TUTORIAL_VIDEOS)

const TutorialsPage = props => {
  const classes = useStyles()
  const { match } = props
  const videoId = match.params.videoId || null
  const [openedVideoId, setOpenedVideoId] = useState(null)

  useEffect(() => {
    setOpenedVideoId(videoId)
  }, [videoId])

  const onTileClick = id => {
    setOpenedVideoId(id)
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3">Tutorials</Typography>
      <div className={classes.videoContainer}>
        {videos.map(video => (
          <TutorialVideoTile video={video} containerClass={classes.videoTile} key={video.id} onClick={onTileClick} />
        ))}
      </div>
      {Boolean(openedVideoId) && (
        <VideoPopup
          src={openedVideoId}
          close={() => {
            setOpenedVideoId(null)
          }}
        />
      )}
    </div>
  )
}

export default TutorialsPage
