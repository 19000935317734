import axios from 'axios'
import { setProfilePhoto } from 'store/actions/auth'
import { createAdvancedCalendarData } from 'store/actions/calendar'
import { openSnackbar } from 'store/actions/common'
import moment from 'moment-timezone'
import * as CONSTANTS from '../../constants/profileCreation'
import {
  addPracticeAreasPath,
  getCountriesPath,
  getPracticeAreasPath,
  getProfileLicensingPath,
  getSaveProfilePicturePath,
  getStatesPath,
  getSubmitProfilePath,
  getUserContactInfoPath,
} from '../../../utils/path-helpers/api'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

const getCountriesBegin = () => ({
  type: CONSTANTS.GET_COUNTRIES_BEGIN,
})

export const setCurrentStep = payload => ({
  type: CONSTANTS.SET_CURRENT_STEP,
  payload,
})

const getCountriesEnd = payload => ({
  type: CONSTANTS.GET_COUNTRIES_END,
  payload,
})

export const addLicensing = payload => ({
  type: CONSTANTS.ADD_LICENSING,
  payload,
})
export const addPracticeAreas = payload => {
  return {
    type: CONSTANTS.ADD_PRACTICE_AREAS,
    payload,
  }
}

export const getCountries = () => (dispatch, getState) => {
  dispatch(getCountriesBegin())
  axios.get(getCountriesPath()).then(response => {
    const countries = response.data.map(item => ({
      value: item.name,
      label: item.code,
    }))
    dispatch(getCountriesEnd(countries))
  })
}

export const changeProfileContactInfo = data => ({
  type: CONSTANTS.CHANGE_PROFILE_CONTACT_INFO,
  data,
})

export const updateProfileContactInfo = data => ({
  type: CONSTANTS.UPDATE_CONTACT_INFO,
  data,
})

export const isChangeProfileDataStart = () => ({
  type: CONSTANTS.IS_CHANGE_PROFILE_DATA_START,
})

export const isChangeProfileDataEnd = () => ({
  type: CONSTANTS.IS_CHANGE_PROFILE_DATA_END,
})

const getPracticeAreasBegin = () => ({
  type: CONSTANTS.GET_PRACTICE_AREAS_START,
})

const getPracticeAreasEnd = payload => ({
  type: CONSTANTS.GET_PRACTICE_AREAS_END,
  payload,
})

export const getPracticeAreas = () => dispatch => {
  dispatch(getPracticeAreasBegin())
  dispatch(getUserPracticeArea()).then(userSelectedPracticeAreas => {
    axios.get(getPracticeAreasPath()).then(response => {
      const practiceAreas = response.data.map(practiceArea => {
        const userSelectedPracticeArea =
          userSelectedPracticeAreas &&
          userSelectedPracticeAreas.find(
            userSelectedArea => userSelectedArea.selected_practice_area.uuid === practiceArea.uuid
          )
        return userSelectedPracticeArea
          ? {
              uuid: practiceArea.uuid,
              name: practiceArea.name,
              averageHourlyRate: practiceArea.average_hourly_rate,
              hourlyRate: userSelectedPracticeArea.hourly_rate,
              consultationCost: userSelectedPracticeArea.consultation_cost,
              contingency: userSelectedPracticeArea.contingency,
              fixedPrice: userSelectedPracticeArea.fixed_price,
              subSpecialities: practiceArea.sub_specialities.map(subSpeciality => {
                const selectedSub = userSelectedPracticeArea.user_sub_specialities.find(
                  sub => sub.selected_sub_speciality.uuid === subSpeciality.uuid
                )
                return selectedSub
                  ? {
                      ...subSpeciality,
                      checked: selectedSub.state_law,
                      disabled: selectedSub.federal_law,
                    }
                  : { ...subSpeciality, checked: false, disabled: false }
              }),
              federalSubSpecialities: practiceArea.sub_specialities.reduce((selectedItems, subSpeciality) => {
                if (!subSpeciality.is_federal) {
                  return selectedItems
                }
                const selectedSub = userSelectedPracticeArea.user_sub_specialities.find(
                  sub => sub.selected_sub_speciality.uuid === subSpeciality.uuid
                )
                return [
                  ...selectedItems,
                  selectedSub
                    ? { ...subSpeciality, checked: selectedSub.federal_law, disabled: !selectedSub.state_law }
                    : { ...subSpeciality, checked: false, disabled: true },
                ]
              }, []),
              layerSides: [] /*item.lawyer_sides.map(item => {                const selectedSub = selectedArea.selected_lawyer_sides.find(sub => sub.uuid === item.uuid)
                return selectedSub ? { ...item, checked: true } : { ...item, checked: false }
              }),*/,
              checked: true,
            }
          : {
              uuid: practiceArea.uuid,
              name: practiceArea.name,
              averageHourlyRate: practiceArea.average_hourly_rate,
              hourlyRate: practiceArea.hourly_rate,
              consultationCost: practiceArea.consultation_cost,
              contingency: practiceArea.contingency,
              fixedPrice: practiceArea.fixed_price,
              subSpecialities: practiceArea.sub_specialities.map(subSpeciality => ({
                ...subSpeciality,
                checked: false,
                disabled: false,
              })),
              federalSubSpecialities: practiceArea.sub_specialities.reduce((selectedItems, subSpeciality) => {
                if (!subSpeciality.is_federal) {
                  return selectedItems
                }
                return [...selectedItems, { ...subSpeciality, checked: false, disabled: true }]
              }, []),
              layerSides: [], //item.lawyer_sides.map(item => ({ ...item, checked: false })),
              checked: false,
            }
      })
      dispatch(getPracticeAreasEnd(practiceAreas))
    })
  })
}

const saveContactInfoStart = () => ({
  type: CONSTANTS.SAVE_PROFILE_CONTACT_INFO_START,
})

const saveContactInfoEnd = payload => ({
  type: CONSTANTS.SAVE_PROFILE_CONTACT_INFO_END,
  payload,
})
const savePracticeAreaStart = () => ({
  type: CONSTANTS.SAVE_PRACTICE_AREAS_START,
})

const savePracticeAreaEnd = payload => ({
  type: CONSTANTS.SAVE_PRACTICE_AREAS_END,
  payload,
})

export const saveContactInfo = data => dispatch => {
  dispatch(saveContactInfoStart())
  const { optionalPhone, phone, ...userInfo } = data
  const payload = {
    ...userInfo,
    optional_phone: optionalPhone === '+1' ? '' : optionalPhone,
    phone: phone === '+1' ? '' : phone,
  }
  return axios
    .post(getUserContactInfoPath(), payload)
    .then(response => {
      dispatch(saveContactInfoEnd(response.data))
      return response.data
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const savePracticeAreas = () => (dispatch, getState) => {
  dispatch(savePracticeAreaStart())
  const practiceAreasState = getState().profileCreation.practiceAreas
  const checkedPracticeAreas = practiceAreasState.filter(area => area.checked)
  const practiceArea = checkedPracticeAreas.map(area => {
    return {
      practice_area: area.uuid,
      hourly_rate: area.hourlyRate,
      consultation_cost: area.consultationCost || 0,
      contingency: area.contingency || false,
      fixed_price: area.fixedPrice || false,
      user_sub_specialities: area.subSpecialities.reduce((resultSubSpeciality, subSpeciality) => {
        if (!subSpeciality.checked) {
          return resultSubSpeciality
        }
        return [
          ...resultSubSpeciality,
          {
            sub_speciality: subSpeciality.uuid,
            state_law: true,
            federal_law: !!area.federalSubSpecialities.find(sub => sub.uuid === subSpeciality.uuid && sub.checked),
          },
        ]
      }, []),
      //lawyer_sides: [], //area.layerSides.filter(area => area.checked).map(layer => layer.uuid),
    }
  })

  const analyticsPracticeAreas = checkedPracticeAreas.reduce((newAreas, area) => {
    if (area.name) {
      return [...newAreas, area.name]
    }
    return newAreas
  }, [])

  return axios
    .post(addPracticeAreasPath(), { practice_areas: practiceArea })
    .then(response => {
      dispatch(savePracticeAreaEnd(practiceAreasState))
      Analytics.track(EVENTS.PRO_PRACTICE_AREAS_COMPLETED, {
        practice_areas: analyticsPracticeAreas,
      })
    })
    .catch(error => {
      const message = []
      for (const [key, value] of Object.entries(error.response.data.practice_areas[0])) {
        message.push(`${key}: ${value}`)
      }
      throw new Error(`${error.response.statusText}: ${message.join(' ')}`)
    })
}

const getStatesStart = () => ({
  type: CONSTANTS.GET_STATES_START,
})

const getStatesEnd = payload => ({
  type: CONSTANTS.GET_STATES_END,
  payload,
})

export const getStates = () => dispatch => {
  dispatch(getStatesStart())
  return axios
    .get(getStatesPath())
    .then(response => {
      const states = response.data.map(item => ({
        value: item.code,
        label: item.name,
      }))
      dispatch(getStatesEnd(states))
      return response.data
    })
    .catch(e => {
      if (e.response && e.response.status === 401) {
        dispatch(getStates())
      } else {
        dispatch(openSnackbar('error', 'Error while getting states'))
        Promise.reject(e)
      }
    })
}

const saveLicensingStart = () => ({
  type: CONSTANTS.SAVE_PROFILE_LICENSING_START,
})

const saveLicensingEnd = payload => ({
  type: CONSTANTS.SAVE_PROFILE_LICENSING_END,
})

export const saveLicensing = () => (dispatch, getState) => {
  dispatch(saveLicensingStart())
  const licenses = {
    licenses: getState().profileCreation.profileData.licensing.map(item => ({
      bar_id: item.barId,
      state: item.state,
    })),
  }
  return axios
    .post(getProfileLicensingPath(), licenses)
    .then(response => {
      dispatch(saveLicensingEnd())
    })
    .catch(error => {
      const data = error.response && error.response.data
      return Promise.reject(data)
    })
}

const getLicensingStart = () => ({
  type: CONSTANTS.GET_PROFILE_LICENSING_START,
})

const getLicensingEnd = payload => ({
  type: CONSTANTS.GET_PROFILE_LICENSING_END,
  payload,
})

export const getLicensing = () => dispatch => {
  dispatch(getLicensingStart())
  return axios
    .get(getProfileLicensingPath())
    .then(response => {
      let licenses = []
      if (!response.data.length) {
        licenses = [
          {
            barId: '',
            id: Math.random(),
            state: '',
            verificationLink: '',
            errors: {
              barId: '',
              state: '',
            },
          },
        ]
      } else {
        licenses = response.data.map(license => ({
          barId: license.bar_id,
          id: Math.random(),
          state: license.state,
          verificationLink: license.verification_link,
          errors: {
            barId: '',
            state: '',
          },
        }))
      }
      dispatch(getLicensingEnd(licenses))
    })
    .catch(error => {
      const data = error.response && error.response.data
      return Promise.reject(data)
    })
}

const savePhotoStart = () => ({
  type: CONSTANTS.SAVE_PROFILE_PHOTO_START,
})

const savePhotoEnd = payload => ({
  type: CONSTANTS.SAVE_PROFILE_PHOTO_END,
})

export const savePhoto = photo => (dispatch, getState) => {
  dispatch(savePhotoStart())
  const payload = new FormData()
  payload.append('photo', photo)
  return axios
    .patch(getSaveProfilePicturePath(), payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      dispatch(setProfilePhoto(response.data.photo))
      dispatch(savePhotoEnd())
    })
    .catch(error => {
      const data = error.response && error.response.data && error.response.data
      return Promise.reject(data)
    })
}

const submitProfileStart = () => ({
  type: CONSTANTS.SUBMIT_PROFILE_START,
})

const submitProfileEnd = payload => ({
  type: CONSTANTS.SUBMIT_PROFILE_END,
})

export const submitProfile = () => (dispatch, getState) => {
  dispatch(submitProfileStart())
  return axios
    .post(getSubmitProfilePath())
    .then(() => {})
    .catch(e => Promise.reject(e))
    .finally(() => {
      dispatch(submitProfileEnd())
      window.location.href = '/pro/complete'
    })
}

const getUserContactInfoStart = () => ({
  type: CONSTANTS.GET_USER_CONTACT_INFO_START,
})

export const getUserContactInfo = () => (dispatch, getState) => {
  dispatch(getUserContactInfoStart())
  return axios
    .get(getUserContactInfoPath())
    .then(
      response => {
        const { optional_phone, ...data } = response.data
        const contactInfo = {
          ...data,
          optionalPhone: optional_phone,
        }
        dispatch(getUserContactInfoEnd(contactInfo))
      },
      error => Promise.reject(error)
    )
    .catch(error => {
      const emptyContactInfo = {
        street: '',
        city: '',
        zip: '',
        state: '',
        country: 840,
        phone: '',
        suite: '',
        optionalPhone: '',
      }
      dispatch(getUserContactInfoEnd(emptyContactInfo))
      return Promise.reject(error)
    })
}

const getUserContactInfoEnd = payload => ({
  type: CONSTANTS.GET_USER_CONTACT_INFO_END,
  payload,
})

export const getUserPracticeArea = () => (dispatch, getState) => {
  return axios
    .get(addPracticeAreasPath())
    .then(response => {
      return response.data
    })
    .catch(error => Promise.reject(error))
}

export const resetProfileCreationInfo = () => ({
  type: CONSTANTS.RESET_PROFILE_CREATION_INFO,
})
