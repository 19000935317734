import React, { useEffect, useMemo, useState } from 'react'
import { Autocomplete, Hint, Icon, InputLabel } from 'components'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'constants/index'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  error: {
    marginTop: 5,
    color: colors.ERROR,
  },
  hint: {
    marginTop: 5,
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
  errorInput: {
    borderColor: colors.ERROR,
  },
})

const selectOptions = createSelector(
  state => state.cases.proCases,
  practiceAreas =>
    practiceAreas.map(item => ({
      value: item.name,
      label: item.name,
      practice_area: item.practice_area_uuid,
      sub_speciality: item.sub_specialities_uuid,
      hourly_rate: item.hourly_rate,
    }))
)

const getValue = (value, options) => {
  if (value.sub_speciality) {
    const foundValue = options.find(item => item.sub_speciality === value.sub_speciality)
    return foundValue ? foundValue.value : ''
  } else if (value.practice_area) {
    const foundValue = options.find(item => item.practice_area === value.practice_area)
    return foundValue ? foundValue.value : ''
  } else {
    return ''
  }
}

const FormSubSpecialityAutocomplete = props => {
  const classes = useStyles()
  const {
    onChange,
    label,
    disabled,
    value = { sub_speciality: '', practice_area: '' },
    inputClass,
    fullWidth,
    containerClass,
    error,
    InputProps,
    placeholder,
    hint,
  } = props
  const options = useSelector(selectOptions)
  const values = useMemo(() => getValue(value, options), [value, options])
  const [internalValue, setInternalValue] = useState(values)

  useEffect(() => {
    setInternalValue(values)
  }, [value, options])

  const handleChange = (e, v) => {
    onChange({
      sub_speciality: v ? v.sub_speciality || null : null,
      practice_area: v ? v.practice_area || null : null,
      hourly_rate: v ? v.hourly_rate || null : null,
    })
  }

  const practiceAreas = useMemo(() => sortBy(options, practiceArea => practiceArea.label), [options])

  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        disabled={disabled}
        value={internalValue}
        variant="outlined"
        fullWidth={fullWidth}
        className={inputClass}
        onChange={handleChange}
        options={practiceAreas}
        placeholder={placeholder}
        InputProps={{
          classes: {
            notchedOutline: error ? classes.errorInput : null,
          },
          ...InputProps
        }}
      />
      {hint && <Hint classes={{ root: classes.hint }}>{hint}</Hint>}
      {error && (
        <Hint classes={{ root: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {error}
        </Hint>
      )}
    </div>
  )
}

export default FormSubSpecialityAutocomplete
