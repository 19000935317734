import React from 'react'
import { ReactComponent as BookLogo } from '../../static/mainPage/book.svg'
import { ReactComponent as MeetLogo } from '../../static/mainPage/meet.svg'
import { ReactComponent as SearchLogo } from '../../static/mainPage/search.svg'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { colors } from 'constants/colors'
import { Typography } from 'components'

const useStyles = makeStyles({
  mainStepperContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepper: {
    backgroundColor: 'transparent',
    width: 1200,
    alignItems: 'center',
    '@media(max-width:1023px)': {
      width: 720,
    },
    '@media(max-width:767px)': {
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  vertical: {
    marginLeft: -5,
    marginTop: -130,
    padding: 0,
    '@media(max-width:1023px)': {
      marginTop: -195,
    },
    '@media(max-width:767px)': {
      marginTop: -185,
      marginLeft: 30,
      display: 'none',
    },
  },
  lineVertical: {
    height: 175,
    borderLeftWidth: 2,
    '@media(max-width:767px)': {
      display: 'none',
    },
  },
  line: {
    borderColor: colors.PRIMARY.COLOR[50],
  },
  contentTitle: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  contentSubtitle: {
    fontSize: 18,
    lineHeight: 1.44,
    fontWeight: 'normal',
  },
  labelContainer: {
    width: 418,
    padding: 24,
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    '@media(max-width:1023px) and (min-width:768px)': {
      width: 283,
    },
    '@media(max-width:767px)': {
      width: 'calc(100% - 30px)',
    },
  },
  firstLabelContainer: {
    alignItems: 'center',
  },
  secondLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stepLabel: {
    alignItems: 'flex-start',
  },
  reverseDirection: {
    flexDirection: 'row-reverse',
    '@media(max-width:767px)': {
      flexDirection: 'row',
    },
  },
  stepEnd: {
    marginRight: 75,
    alignSelf: 'flex-end',
    '@media(max-width:1023px)': {
      marginRight: 0,
    },
    '@media(max-width:767px)': {
      width: '100%',
      marginBottom: 60,
    },
  },
  stepStart: {
    alignSelf: 'flex-start',
    marginLeft: 75,
    '@media(max-width:1023px)': {
      marginLeft: 0,
    },
    '@media(max-width:767px)': {
      alignSelf: 'flex-end',
      width: '100%',
      marginBottom: 60,
    },
  },
  labelContainerRight: {
    marginLeft: 40,
    position: 'relative',
    '@media(max-width:1023px)': {
      marginLeft: 20,
    },
    '@media(max-width:767px)': {
      position: 'relative',
    },
  },
  labelContainerLeft: {
    marginRight: 40,
    '@media(max-width:1023px)': {
      marginRight: 20,
    },
    '@media(max-width:767px)': {
      marginLeft: 20,
      marginRight: 0,
    },
  },
  lastContainer: {
    '&:after': {
      position: 'absolute',
      width: 2,
      height: '120%',
      left: -60,
      top: '-120%',
      content: "''",
      backgroundColor: colors.PRIMARY.COLOR[50],
      display: 'none',
      '@media(max-width:767px)': {
        display: 'block',
      },
    },
  },
  firstContainer: {
    '&:after': {
      position: 'absolute',
      width: 2,
      height: '120%',
      left: -60,
      top: 30,
      content: "''",
      backgroundColor: colors.PRIMARY.COLOR[50],
      display: 'none',
      '@media(max-width:767px)': {
        display: 'block',
      },
    },
  },
})

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: colors.WHITE,
    zIndex: 1,
    width: 80,
    height: 80,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '1px 2px 20px 0 rgba(0, 0, 0, 0.16)',
    '@media(max-width:1023px)': {
      width: 60,
      height: 60,
    },
  },
})
const ColorlibStepIcon = props => {
  const classes = useColorlibStepIconStyles()
  const icons = {
    1: <SearchLogo />,
    2: <BookLogo />,
    3: <MeetLogo />,
  }

  return <div className={classes.root}>{icons[String(props.icon)]}</div>
}

const MainPageStepper = () => {
  const classes = useStyles()
  const steps = [
    <div className={classNames(classes.labelContainer, classes.labelContainerRight, classes.firstContainer)}>
      <Typography className={classes.contentTitle}>find</Typography>
      <Typography className={classes.contentSubtitle}>
        search for lawyers that can help you.
        <br />
        filter the list based on price that is right
        <br />
        for you and the time that works for you
      </Typography>
    </div>,
    <div className={classNames(classes.labelContainer, classes.labelContainerLeft)}>
      <Typography className={classes.contentTitle}>book</Typography>
      <Typography className={classes.contentSubtitle}>
        you have access to the calendar of the lawyer that matches your need. go ahead and book the time that works best
        for you
      </Typography>
    </div>,
    <div className={classNames(classes.labelContainer, classes.labelContainerRight, classes.lastContainer)}>
      <Typography className={classes.contentTitle}>meet</Typography>
      <Typography className={classes.contentSubtitle}>
        you will receive a booking confirmation with a link that will take you to the virtual conference room where you
        will meet with your lawyer. all of this is done at your convenience and from the comfort of your space
      </Typography>
    </div>,
  ]
  return (
    <Stepper
      classes={{ root: classes.stepper }}
      connector={
        <StepConnector
          classes={{
            vertical: classes.vertical,
            lineVertical: classes.lineVertical,
            line: classes.line,
          }}
        />
      }
      orientation="vertical"
    >
      {steps.map((label, index) => (
        <Step classes={{ root: classNames(index === 1 ? classes.stepStart : classes.stepEnd) }} key={label}>
          <StepLabel
            classes={{ root: classNames(classes.stepLabel, { [classes.reverseDirection]: index === 1 }) }}
            StepIconComponent={ColorlibStepIcon}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default MainPageStepper
