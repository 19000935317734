import React from 'react'
import { ProConnectionsContainer } from 'containers'
import { Route, Switch } from 'react-router-dom'

const ProConnectionsPage = () => {
  return (
    <Switch>
      <Route path="/pro/account/connections" component={ProConnectionsContainer} />
    </Switch>
  )
}

export default ProConnectionsPage
