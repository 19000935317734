import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS } from 'constants/index'
import moment from 'moment-timezone'
import { getItemName } from 'utils/invoice/invoice-helpers'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { navigateTo } from '../../../store/actions/navigation'

const useStyles = makeStyles({
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  void: {
    color: colors.BASIC.COLOR['80'],
    fontWeight: 500,
    fontSize: 13,
  },
  voidAmount: {
    textDecoration: 'line-through',
  },
})

const InvoiceSummaryTableRow = ({ data, timezone, setInvoiceTab }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()

  const invoiceDate = moment(data.created_at)
    .tz(timezone)
    .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)

  const handleDocumentLinkClick = () => {
    if (data.type === 'I') setInvoiceTab(1)
    else dispatch(navigateTo(`${location.pathname}#${data.generated_payment_record_number}`))
  }

  return (
    <>
      <TableCell className={classes.link} onClick={handleDocumentLinkClick}>{`${getItemName()} ${
        data.generated_payment_record_number
      }`}</TableCell>
      <TableCell>{invoiceDate}</TableCell>
      <TableCell className={classNames({ [classes.voidAmount]: data.is_void })} align={'right'}>
        {data.type === 'C' || data.type === 'P' ? '-' : ''} ${Math.abs(data.amount).toFixed(2)}
      </TableCell>
      <TableCell align={'right'}>${data.current_invoice_amount_for_display}</TableCell>
      <TableCell className={classes.void}>{data.is_void ? 'VOID' : ''}</TableCell>
    </>
  )
}

export default InvoiceSummaryTableRow
