import React from 'react'
import { ReactComponent as Tick } from 'static/tick.svg'
import { Typography, StyledStepper } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  startText: {
    fontWeight: 500,
  },
  stepperHeaderContainer: {
    padding: '40px 0 24px 24px',
  },
  finalTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  finalTitle: {
    marginLeft: 16,
  },
  finalText: {
    marginTop: 16,
    fontWeight: 'normal',
    fontSize: 15,
    alignSelf: 'center',
  },
})

const getSteps = () => ['connect calendar service', 'set XIRA calendar']

const CalendarStepperHeader = ({ activeStep }) => {
  const classes = useStyles()
  const getStepHeader = step => {
    return step === getSteps().length ? (
      <>
        <div className={classes.finalTitleContainer}>
          <Tick />
          <Typography className={classes.finalTitle} variant="h3">
            well done!
          </Typography>
        </div>
        <Typography className={classes.finalText} variant="body1">
          Your calendar was successfully added.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="subtitle1" className={classes.startText}>
          add my calendar
        </Typography>
        <StyledStepper activeStep={activeStep} steps={getSteps()} />
      </>
    )
  }

  return <div className={classes.stepperHeaderContainer}>{getStepHeader(activeStep)}</div>
}

export default CalendarStepperHeader
