import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  ColorCircularProgress,
  ConfigureCardModal,
  ConfirmationModal,
  Icon,
  Typography,
  CardRow,
  Link,
  ErrorModal,
} from 'components'
import { CONSTANTS, colors } from 'constants/index'
import {
  deleteUserCard,
  getClientPaymentSecret,
  getUserCard,
  getUserCards,
  saveUserCard,
  updateUserCard,
} from 'store/actions/payments'
import { openSnackbar } from 'store/actions/common'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

const styles = {
  root: {
    width: 840,
    marginBottom: 20,
  },
  buttonIcon: {
    marginRight: 4,
    fontSize: 14,
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 26,
  },
  cards: {
    padding: 25,
    marginBottom: 24,
    border: `1px solid ${colors.BASIC.COLOR[5]}`,
    borderRadius: 4,
    backgroundColor: colors.WHITE,
  },
}

class ClientPaymentsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: CONSTANTS.MODE.ADD,
      isModalShown: false,
      isConfirmationModalShown: false,
      currentCardId: '',
      cardData: {},
      disableBtn: false,
      sortedCards: [],
    }
  }

  componentDidMount() {
    this.props.getCards()
  }

  componentDidUpdate(prevProps) {
    if (this.props.userCards !== prevProps.userCards) {
      this.sortingCardsByDefaultProperty();
    }
  }

  sortingCardsByDefaultProperty() {
    const { userCards } = this.props
    const sortedCards = userCards.sort((a, b) => b.isDefault - a.isDefault)
    this.setState({ sortedCards: sortedCards })
  }

  onAddNewCardPress() {
    this.props.getSecretKey()
    this.setState({
      isModalShown: true,
      mode: CONSTANTS.MODE.ADD,
      cardData: { label: '', isDefault: false, remember: true },
    })
  }

  async onEditCard(id) {
    try {
      const data = await this.props.getCardDetails(id)
      this.setState({ isModalShown: true, mode: CONSTANTS.MODE.EDIT, cardData: data })
    } catch (e) {
      this.props.showSnackbar('error', 'Card heve not found')
    }
  }

  onModalClose() {
    this.setState({ isModalShown: false })
  }

  async onModalSave(data) {
    try {
      if (this.state.mode === CONSTANTS.MODE.ADD) {
        await this.props.saveCard(data)
      } else {
        const { uuid, ...payload } = data
        await this.props.updateCard(uuid, payload)
      }
      this.props.getCards()
      this.setState({ isModalShown: false })
    } catch (e) {
      this.setState({ isModalShown: false })
    }
  }

  onDeleteCard(id) {
    this.openConfirmationModalPress(id)
  }

  onConfirmationModalClose() {
    this.setState({ isConfirmationModalShown: false, currentCardId: '' })
  }

  async onConfirmationModalConfirm() {
    this.setState({ disableBtn: true })

    try {
      await this.props.deleteCard(this.state.currentCardId)
      this.setState({ isConfirmationModalShown: false, currentCardId: '' })
      this.props.showSnackbar('success', 'Card deleted')
      const cards = await this.props.getCards()
      Analytics.track(EVENTS.CLIENT_DELETED_CARD, {
        card: !!cards.length,
      })
    } catch (e) {
      this.props.showSnackbar('error', 'Error while deleting card')
    }

    this.setState({ disableBtn: false })
  }

  async onSetDefault(id) {
    const data = {
      is_default: true,
    }
    try {
      await this.props.updateCard(id, data)
      this.props.getCards()
      this.props.showSnackbar('success', 'Card selected as default')
    } catch (e) {
      this.props.showSnackbar('error', 'Error while selecting card as default')
    }
  }

  openConfirmationModalPress(id) {
    this.setState({ isConfirmationModalShown: true, currentCardId: id })
  }

  getErrorModalBody = () => {
    return (
      <>
        You can not remove the last payment method. For details please contact{' '}
        <Link href={`mailto:${this.props.adminEmail || 'admin@xirasupport.com'}`}>XIRA Admin</Link>.
      </>
    )
  }
  render() {
    const { classes, clientSecretIsLoading, clientPaymentSecretKey, userCards, cardsAreLoading } = this.props
    const { isConfirmationModalShown, isModalShown, mode, cardData, sortedCards } = this.state

    return cardsAreLoading ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Payment methods
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Add and manage your payment methods using our secure payment system.
        </Typography>
        {!!(userCards && userCards.length) && (
          <div className={classes.cards}>
            {sortedCards.map(card => (
              <CardRow
                cardData={card}
                key={card.uuid}
                onEditCard={id => this.onEditCard(id)}
                onSetDefault={id => this.onSetDefault(id)}
                onDeleteCard={id => this.onDeleteCard(id)}
                showConfigurationButton
              />
            ))}
          </div>
        )}

        <Button variant="primary" onClick={() => this.onAddNewCardPress()}>
          <Icon iconClass="las la-plus-circle" style={classes.buttonIcon} /> add payment method
        </Button>
        <ConfirmationModal
          title="Remove payment method"
          message={'Are you sure you want to remove this payment method?'}
          confirmationButtonText="Yes, remove"
          isOpen={isConfirmationModalShown && userCards.length !== 1}
          onClose={() => this.onConfirmationModalClose()}
          onConfirm={() => this.onConfirmationModalConfirm()}
          disableButton={this.state.disableBtn}
        />
        <ErrorModal
          title="Remove the last payment method"
          message={this.getErrorModalBody()}
          cancelationButtonText="close"
          isOpen={isConfirmationModalShown && userCards.length === 1}
          onClose={() => this.onConfirmationModalClose()}
        />
        <ConfigureCardModal
          isOpen={isModalShown}
          onClose={() => this.onModalClose()}
          onSave={data => this.onModalSave(data)}
          mode={mode}
          clientPaymentSecretKey={clientPaymentSecretKey}
          clientSecretIsLoading={clientSecretIsLoading}
          cardData={cardData}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ payments }) => ({
  clientSecretIsLoading: payments.clientSecretIsLoading,
  clientPaymentSecretKey: payments.clientPaymentSecretKey,
  userCards: payments.userCards,
  cardsAreLoading: payments.cardsAreLoading,
})
const mapDispatchToProps = dispatch => ({
  getSecretKey: () => dispatch(getClientPaymentSecret()),
  getCards: () => dispatch(getUserCards()),
  saveCard: data => dispatch(saveUserCard(data)),
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
  updateCard: (uuid, data) => dispatch(updateUserCard(uuid, data)),
  deleteCard: uuid => dispatch(deleteUserCard(uuid)),
  getCardDetails: uuid => dispatch(getUserCard(uuid)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ClientPaymentsContainer))
