import React from 'react'
import { Typography, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  summaryRoot: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  summaryCol: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
  },
  icon: {
    fontSize: '24px',
    marginRight: '30px',
  },
  summaryRow: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    marginTop: '4px',
  },
  text: {
    fontFamily: 'san-serif, "Roboto"',
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '@media(max-width:1279px)': {
    summaryCol: {
      width: '100%',
    },
  },
})

const DegreeExpansionPanelSummary = ({ iconClass, degree }) => {
  const classes = useStyles()
  return (
    <div className={classes.summaryRoot}>
      <div className={classes.summaryRow}>
        <Icon style={classes.icon} iconClass={iconClass} />
        <div className={classes.summaryCol}>
          <p title={degree.school_name} className={classes.text}>
            <b>{degree.school_name}</b>, {degree.year_of_graduation}
          </p>
        </div>
      </div>
    </div>
  )
}

export default DegreeExpansionPanelSummary
