import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Hint, Icon, Modal, TextField, Typography } from 'components'
import { colors } from 'constants/colors'
import { openSnackbar } from 'store/actions/common'
import { getVerifyCodePath, getVerifyPhonePath } from 'utils/path-helpers/api/vault/vault'
import { getUserContactInfo } from '../../store/actions/profileCreation'

const useStyles = makeStyles({
  paper: {
    margin: 16,
  },
  verificationContainer: {
    width: 408,
    padding: '24px 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  verificationTitle: {
    marginBottom: 16,
    maxWidth: 296,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '26px',
  },
  verificationText: {
    marginBottom: 14,
    maxWidth: 296,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '18px',
    letterSpacing: 'normal',
  },
  verificationTextNumber: {
    whiteSpace: 'nowrap',
  },
  verificationCodeInput: {
    marginBottom: 4,
    width: 160,
    '& input': {
      textAlign: 'center',
    },
  },
  hint: {
    color: colors.ERROR,
  },
  sendAgain: {
    marginBottom: 32,
    padding: 10,
  },
  verificationButton: {
    width: '100%',
  },
})

export const MobileVerificationModal = ({ open, onClose, onOk, number }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const onChange = e => {
    const { value } = e.target
    setCode(value.replace(/\D/g, ''))
  }

  const onBlur = () => {
    if (!code.length) {
      setError('Please fill out this field')
    } else if (code.length < 6) {
      setError('Please enter a valid verification code')
    } else {
      setError('')
    }
  }

  const onSendAgain = async () => {
    try {
      await axios.post(getVerifyCodePath(), { new_phone_number: number })
      dispatch(openSnackbar('success', 'Code sent successfully.'))
      setShowPopup(true)
    } catch (e) {
      dispatch(
        openSnackbar('error', (e.response && e.response.data && e.response.data.detail) || 'Error while sending code.')
      )
      onClose()
    }
  }

  const onSubmit = async () => {
    try {
      await axios.post(getVerifyPhonePath(), { verification_code: code, new_phone_number: number })
      setCode('')
      setError('')
      onOk()
      dispatch(getUserContactInfo())
    } catch (e) {
      const errorMessage = (e.response && e.response.data && e.response.data.detail) || 'Phone verification error.'
      dispatch(openSnackbar('error', errorMessage))
    }
  }
  const onCloseModal = () => {
    setCode('')
    setError('')
    onClose()
    dispatch(getUserContactInfo())
  }

  useEffect(() => {
    if (open) onSendAgain()
    else setShowPopup(false)
  }, [open])

  return (
    <Modal dialogClasses={{ paper: classes.paper }} open={showPopup} maxWidth="md" handleClose={onCloseModal}>
      <div className={classes.verificationContainer}>
        <Typography className={classes.verificationTitle} variant="subtitle1" align="center">
          Verify mobile number
        </Typography>
        <Typography className={classes.verificationText} align="center">
          Code sent to <strong className={classes.verificationTextNumber}>{number.slice(2)}</strong>
        </Typography>
        <TextField
          className={classes.verificationCodeInput}
          variant="outlined"
          placeholder="000000"
          value={code}
          onChange={onChange}
          onBlur={onBlur}
          inputProps={{
            maxLength: 6,
          }}
        />
        {error && (
          <Hint classes={{ subtitle2: classes.hint }}>
            <Icon iconClass="las las la-exclamation-triangle" />
            {error}
          </Hint>
        )}
        <Button className={classes.sendAgain} variant="text" onClick={onSendAgain}>
          send code again
        </Button>
        <Button className={classes.verificationButton} onClick={onSubmit} disabled={error || code.length < 6}>
          verify
        </Button>
      </div>
    </Modal>
  )
}
