import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { colors } from '../../constants'

const useStyles = makeStyles({
  tab: {
    lineHeight: 1.23,
    fontSize: 12,
    opacity: 1,
    color: colors.BLUE_20,
    fontWeight: 500,
    padding: 0,
    borderRight: `solid 1px ${colors.WHITE}`,
  },
  selectedTab: {
    color: colors.BASIC.COLOR['100'],
    backgroundColor: colors.WHITE,
    borderBottom: `solid 1px ${colors.PRIMARY.COLOR['50']}`,
  },
  tabWrapper: {
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginBottom: 0,
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  flexContainer: {
    backgroundColor: colors.BASIC.COLOR['5'],
  },
})

const InvoiceTabs = ({ invoiceTab, handleTabChange }) => {
  const classes = useStyles()

  const commonTabs = [
    {
      label: 'summary',
    },
    {
      label: 'invoice',
    },
    {
      label: 'payments/credits',
    },
  ]

  return (
    <Tabs
      value={invoiceTab || 0}
      classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer }}
      onChange={(e, newValue) => handleTabChange(newValue)}
    >
      {commonTabs.map((tab, i) => (
        <Tab
          classes={{
            root: classNames(classes.tab, { [classes.selectedTab]: invoiceTab === i }),
            wrapper: classes.tabWrapper,
          }}
          label={tab.label}
          key={tab.label}
        />
      ))}
    </Tabs>
  )
}

export default InvoiceTabs
