export const colors = {
  PRIMARY: {
    COLOR: {
      0: '#FEF9F6',
      5: '#FAE9E1',
      10: '#FAD2BB',
      20: '#F5AF89',
      30: '#F28C54',
      40: '#F07735',
      50: '#EE5F10',
      60: '#E55107',
      70: '#DB4603',
    },
    TRANSPARENT: {
      8: 'rgba(238, 95, 16, 0.08)',
      16: 'rgba(238, 95, 16, 0.16)',
      24: 'rgba(238, 95, 16, 0.24)',
      32: 'rgba(238, 95, 16, 0.32)',
      40: 'rgba(238, 95, 16, 0.4)',
      48: 'rgba(238, 95, 16, 0.48)',
    },
  },
  ADDITIONAL: {
    COLOR: {
      0: '#EBF6FE',
      5: '#D6EFFC',
      10: '#C2E7FB',
      20: '#ADDDF9',
      30: '#85C7F6',
      40: '#5CB4F4',
      50: '#008AED',
      60: '#0080DB',
    },
    TRANSPARENT: {
      8: 'rgba(0, 138, 237, 0.08)',
      16: 'rgba(0, 138, 237, 0.16)',
      24: 'rgba(0, 138, 237, 0.24)',
      32: 'rgba(0, 138, 237, 0.32)',
      48: 'rgba(0, 138, 237, 0.48)',
      64: 'rgba(0, 138, 237, 0.64)',
    },
  },
  BASIC: {
    COLOR: {
      0: '#FAFBFC',
      5: '#EBEEF5',
      10: '#DDE2EB',
      20: '#C1CBD6',
      30: '#A5B3C2',
      40: '#8799A8',
      50: '#6F8194',
      60: '#596C80',
      70: '#42586E',
      80: '#2B4157',
      100: '#011A33',
    },
  },
  SUCCESS: '#4CAF50',
  ERROR: '#F71305',
  WHITE: '#FFFFFF',
  YELLOW_MAIN: '#ffc107',
  FACEBOOK_BLUE: '#34579d',
  BLUE_20: '#1a73e8',
  BLACK: '#000000',
}
