import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Button, Modal, Typography, FileInput, FormikInput, FormikAutocomplete, InviteClientPopup } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { colors, MESSAGES } from 'constants/index'
import { formatDecimal } from 'utils/getters/common'
import { ReactComponent as Upload } from 'static/invoices/files.svg'
import { ReactComponent as TrashIcon } from 'static/notifications/trash.svg'
import { ReactComponent as PDFIcon } from 'static/vault/folder/pdf.svg'
import { ReactComponent as InviteUserIcon } from 'static/person-add.svg'
import { getUserConnectionsForFilter, sendInviteLink } from 'store/actions/connections'
import { getProInvoices, uploadInvoice } from 'store/actions/invoices'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

const useStyles = makeStyles({
  modal: {
    width: 440,
    minHeight: 391,
    position: 'relative',
    padding: '68px 40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    position: 'absolute',
    top: 24,
  },
  form: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  select: {
    marginBottom: 8,
  },
  uploadInvoice: {
    padding: 16,
    height: 56,
    backgroundColor: '#EBEFF5',
    border: '2px dashed #C1CBD6',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000000',
    textTransform: 'none',
    fontSize: 15,
    lineHeight: '24px',
    '& strong': {
      color: '#2B4157',
    },
    '& span': {
      color: '#EE5F10',
    },
  },
  uploadInvoiceImage: {
    marginRight: 12,
  },
  uploadInvoiceSize: {
    marginTop: 8,
    color: '#8799A8',
    fontSize: 13,
    lineHeight: '18px',
  },
  uploadInvoiceSizeError: {
    color: colors.ERROR,
    fontWeight: 400,
    marginTop: 8,
  },
  fileUploadLabel: {
    padding: 0,
  },
  fileUploadText: {
    width: '100%',
  },
  footer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    minHeight: 40,
    height: 40,
    marginRight: 12,
    padding: '13px 24px',
  },
  okButton: {
    minHeight: 40,
    height: 40,
    padding: '13px 24px',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    backgroundColor: '#EBEFF5',
    borderRadius: 4,
    '& svg': {
      cursor: 'pointer',
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
  },
  fileNameTitle: {
    maxWidth: 245,
    margin: '0 24px 0 6px',
    color: '#011A33',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  addButton: {
    padding: '5px 15px',
    height: 40,
    minHeight: 40,
    marginBottom: 16,
  },
})

const validationSchema = Yup.object({
  client: Yup.string().required(MESSAGES.M0001),
  amount: Yup.number()
    .min(0.01)
    .max(99999.99)
    .required(MESSAGES.M0001),
})

const initialValues = {
  first_name: '',
  last_name: '',
  username: '',
  street: '',
  suite: '',
  city: '',
  country: 840,
  state: '',
  zip: '',
  phone: '',
}

const UploadInvoiceModal = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clients = useSelector(state => state.connections.filterConnections)
  const uploadingInProgress = useSelector(state => state.invoices.uploadingInvoice)
  const [invoice, setInvoice] = useState(null)
  const [invoiceUploadError, setInvoiceUploadError] = useState('')
  const [addClientModal, setAddClientModal] = useState(false)

  const onChange = file => {
    if (file.size / 1024 ** 2 > 2) {
      setInvoiceUploadError('File size too large (max 2 MB)')
      setInvoice(null)
    } else if (file.type !== 'application/pdf') {
      setInvoiceUploadError('')
      setInvoiceUploadError('Please upload PDF file')
      setInvoice(null)
    } else {
      setInvoiceUploadError('')
      setInvoice(file)
    }
  }

  const onInviteClientClick = () => setAddClientModal(o => !o)
  const onDeleteFile = () => setInvoice(null)
  const onSubmit = async ({ amount, client }) => {
    const formData = new FormData()
    formData.append('client', client)
    formData.append('invoice_amount', +amount)
    formData.append('file', invoice)
    await dispatch(uploadInvoice(formData))
    dispatch(getProInvoices())
    onClose()
  }

  const onCreateUser = async values => {
    try {
      await dispatch(sendInviteLink(values))
      await dispatch(getUserConnectionsForFilter({}))
      setAddClientModal(false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleFileDrop = (item, monitor) => {
    if (monitor) {
      const file = monitor.getItem().files[0]
      onChange(file)
    }
  }

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    collect: monitor => {
      return {
        isOver: monitor.isOver({ shallow: true }),
      }
    },
    drop(item, monitor) {
      if (handleFileDrop) {
        handleFileDrop({}, monitor)
      }
    },
  })

  useEffect(() => {
    setInvoice(null)
    setInvoiceUploadError('')
    setAddClientModal(false)
  }, [open])

  return (
    <>
      <Modal open={open} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
        <div className={classes.modal}>
          <Typography variant="h4" className={classes.title}>
            Upload invoice
          </Typography>
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onReset={() => {}}
            validateOnChange={false}
            enableReinitialize
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className={classes.form}>
                  <FormikAutocomplete
                    value={values.client}
                    variant="outlined"
                    size="small"
                    name="client"
                    label="Client"
                    type="text"
                    fullWidth
                    InputProps={{ placeholder: 'Please Select' }}
                    containerClasses={classes.select}
                    options={clients}
                  />
                  <Button
                    variant="primary"
                    className={classes.addButton}
                    onClick={onInviteClientClick}
                    startIcon={<InviteUserIcon />}
                  >
                    add/invite client
                  </Button>
                  <FormikInput
                    value={values.amount}
                    variant="outlined"
                    name="amount"
                    label="Amount"
                    type="number"
                    placeholder="$00.00"
                    handleBlur={e => setFieldValue('amount', formatDecimal(e.target.value))}
                  />
                  {!invoice ? (
                    <>
                      <div ref={drop}>
                        <FileInput
                          id="upload-invoice"
                          value={invoice}
                          labelClass={classes.fileUploadLabel}
                          textClasses={classes.fileUploadText}
                          onChange={e => onChange(e.target.files[0])}
                          accept=".pdf"
                        >
                          <div className={classes.uploadInvoice}>
                            <Upload className={classes.uploadInvoiceImage} />
                            <div>
                              {isOver ? (
                                'drop your files here'
                              ) : (
                                <>
                                  <strong>drag & drop</strong>
                                  {' your file or '}
                                  <span>browse</span>
                                </>
                              )}
                            </div>
                          </div>
                        </FileInput>
                      </div>
                      {invoiceUploadError ? (
                        <Typography className={classes.uploadInvoiceSizeError}>{invoiceUploadError}</Typography>
                      ) : (
                        <Typography className={classes.uploadInvoiceSize}>Format - PDF. Max size - 2 MB</Typography>
                      )}
                    </>
                  ) : (
                    <File name={invoice.name} classes={classes} onDeleteFile={onDeleteFile} />
                  )}
                  <div className={classes.footer}>
                    <Button size="small" variant="text" className={classes.cancelButton} onClick={onClose}>
                      cancel
                    </Button>
                    <Button
                      size="small"
                      variant="primary"
                      className={classes.okButton}
                      disabled={
                        !values.client ||
                        !invoice ||
                        !values.amount ||
                        +values.amount < 0.01 ||
                        +values.amount > 99999.99 ||
                        uploadingInProgress
                      }
                      type="submit"
                      isButtonBlocked={uploadingInProgress}
                      circularSize={22}
                    >
                      done
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
      <InviteClientPopup
        open={addClientModal}
        isCreateMode
        onClose={onInviteClientClick}
        onSubmit={onCreateUser}
        inviteInfo={initialValues}
      />
    </>
  )
}

const File = ({ name, classes, onDeleteFile }) => (
  <div className={classes.file}>
    <div className={classes.fileName}>
      <PDFIcon />
      <Typography className={classes.fileNameTitle} title={name}>
        {name}
      </Typography>
    </div>
    <TrashIcon onClick={onDeleteFile} />
  </div>
)

export default UploadInvoiceModal
