import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { getUserContactInfo } from 'store/actions/profileCreation'
import { getDeletionRequestInfo } from 'store/actions/accountProfile'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import ClientAccountPageContent from './clientAccountPageContent/ClientAccountPageContent'
import { VaultModal } from 'components'

const useStyles = makeStyles({
  content: {
    backgroundColor: colors.BASIC.COLOR[0],
    minHeight: '100%',
  },
  clientAccount: {
    padding: '32px  15px 32px 40px',
    backgroundColor: colors.BASIC.COLOR[0],
    minHeight: '100%',
  },
  '@media(max-width:1279px)': {
    clientAccount: {
      maxWidth: 1212,
      padding: '30px  12px',
      margin: '0 auto',
    },
  },
  '@media(max-width:939px)': {
    clientAccount: {
      maxWidth: 744,
    },
  },
  '@media(max-width:767px)': {
    clientAccount: {
      padding: '24px  15px',
    },
  },
})

const scrollToRef = ref => {
  ref.current.scrollTo(0, 0)
}

const ClientAccountPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    dispatch(getDeletionRequestInfo())
  }, [])

  return (
    <div className={classes.content}>
      <div ref={myRef} className={classes.clientAccount}>
        <ClientAccountPageContent executeScroll={executeScroll} />
      </div>
      <VaultModal />
    </div>
  )
}

export default withRouter(ClientAccountPage)
