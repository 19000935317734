import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'components'
import { InputAdornment } from '@material-ui/core'
import { colors } from '../../../constants/colors'

const useStyles = makeStyles({
  inputRoot: {
    width: 100,
    borderRadius: 4,
    backgroundColor: 'transparent !important',
    color: colors.BASIC.COLOR[50],
  },
  focusedInput: {
    borderColor: colors.BASIC.COLOR[100],
    color: colors.BASIC.COLOR[100],
  },
  label: {
    fontSize: 14,
    color: colors.BASIC.COLOR[50],
  },
  adornedStart: {
    fontSize: 16,
  },
  input: { textAlign: 'right' },
})

const Input = ({ fieldData, endIcon = '', inputValue, validationRange = {}, onChange }) => {
  const classes = useStyles()
  const [value, setValue] = useState(inputValue)
  const { min, max } = validationRange

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  const handleInputChange = event => {
    const input = Number(event.target.value.replace(/[^\d]/g, ''))

    setValue(input)
  }

  const handleFocus = event => {
    const target = event.target
    const valueLength = target.value.length
    target.setSelectionRange(0, valueLength)
  }

  const handleInputBlur = event => {
    if (value > max || value < min) {
      setValue(inputValue)
      return
    }

    onChange(event, value)
  }

  const getFieldValue = () => {
    if (endIcon) {
      return fieldData.value === value ? `${value}${endIcon}` : value
    }
    return value
  }

  const onKeyPressed = event => {
    if (event.key === 'Enter') {
      handleInputBlur(event)
    }
  }

  return (
    <TextField
      variant="outlined"
      value={getFieldValue()}
      name={fieldData.name}
      label={fieldData.label}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      onFocus={handleFocus}
      onKeyPress={onKeyPressed}
      InputProps={{
        classes: { root: classes.inputRoot, input: classes.input, focused: classes.focusedInput },
        startAdornment: (
          <InputAdornment disableTypography className={classes.adornedStart} position="start">
            $
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ classes: { root: classes.label }, shrink: true }}
    />
  )
}

export default Input
