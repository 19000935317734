import React, { useEffect, useState } from 'react'
import { openSnackbar } from 'store/actions/common'
import { getWorkingDays, saveWorkingDays } from 'store/actions/calendar'
import { Button, ColorCircularProgress, Typography, DataLossWarning, WorkingHoursList } from 'components'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { checkForDaySelect, validateHours } from 'utils/calendar/working-hours'

const useStyles = makeStyles({
  root: {
    paddingTop: 32,
    paddingBottom: 32,
  },
  title: {
    marginBottom: 10,
  },
  subtitle: {
    marginBottom: 24,
  },
  content: {
    width: 700,
  },
  buttonsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancel: {
    marginRight: 40,
  },
})

const WorkingHoursContainer = props => {
  const { workingHoursAreLoading, weekWorkingHours, getWorkingWeek, saveWorkingWeek, showSnackbar } = props
  const classes = useStyles()
  const [weekData, setWeekData] = useState(weekWorkingHours)
  const [validationShown, setValidationShown] = useState(false)
  const isDirty = !isEqual(weekData, weekWorkingHours)

  useEffect(() => {
    getWorkingWeek()
  }, [getWorkingWeek])

  useEffect(() => {
    setWeekData(weekWorkingHours)
  }, [weekWorkingHours])

  const onCancel = () => {
    props.executeScroll()
    setWeekData(weekWorkingHours)
  }

  const onSave = () => {
    let isValid = true

    props.executeScroll()
    const newWeekData = weekData.map(dayData => {
      const { valid, workingHours } = validateHours(dayData.workingHours)

      if (!valid) {
        isValid = valid
      }

      return {
        ...dayData,
        workingHours: workingHours,
      }
    })

    setWeekData(newWeekData)

    if (!isValid) {
      showSnackbar('error', 'Your working hours are incorrect. Please check them')
      return
    }

    if (!checkForDaySelect(newWeekData)) {
      setValidationShown(true)
      return
    }
    saveWorkingDaysData(newWeekData)
  }

  const saveWorkingDaysData = async data => {
    try {
      await saveWorkingWeek(data)
      showSnackbar('success', 'Working hours saved')
    } catch (error) {
      showSnackbar('error', 'There was some error while saving working hours')
    }
  }

  return workingHoursAreLoading ? (
    <ColorCircularProgress />
  ) : (
    <div className={classes.root}>
      <DataLossWarning whenCondition={isDirty} path="/pro/account/calendar/working-hours" />
      <Typography variant="h4" classes={{ h4: classes.title }}>
        Availability
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Select the daily time periods you will be available on your XIRA calendar so clients can book meetings with you
      </Typography>
      {weekData && !!weekData.length && (
        <div className={classes.content}>
          <WorkingHoursList
            weekData={weekData}
            setWeekData={setWeekData}
            validationShown={validationShown}
            setValidationShown={setValidationShown}
            saveWorkingDaysData={saveWorkingDaysData}
          />
          <div className={classes.buttonsGroup}>
            <Button onClick={onCancel} variant="text" className={classes.cancel} disabled={!isDirty}>
              cancel
            </Button>
            <Button onClick={onSave} variant="secondary" disabled={!isDirty}>
              save
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ calendar }) => ({
  weekWorkingHours: calendar.workingDays,
  workingHoursAreLoading: calendar.isWorkingDaysLoading,
})

const mapDispatchToProps = dispatch => ({
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
  getWorkingWeek: () => dispatch(getWorkingDays()),
  saveWorkingWeek: data => dispatch(saveWorkingDays(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkingHoursContainer)
