import React from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd'
import { Button, Icon, Typography, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { ReactComponent as DownloadIcon } from 'static/icon_24_upload .svg'

const useStyles = makeStyles({
  uploadContainer: {
    width: '100%',
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: `1px dashed ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    color: colors.BASIC.COLOR[20],
    marginBottom: 4,
  },
  input: {
    display: 'none',
  },
  iconBtn: {
    color: colors.PRIMARY.COLOR[50],
    borderRadius: 4,
    backgroundColor: 'transparent',
    marginBottom: 5,
  },
  iconStyle: {
    width: 16,
    height: 16,
  },
  errorText: {
    color: colors.ERROR,
    display: 'flex',
  },
  errorIcon: {
    fontSize: 16,
    marginRight: 8,
  },
  subText: {
    color: colors.BASIC.COLOR[20],
  },
})

const UPLOAD_BUTTON_TEXT = 'UPLOAD VIDEO'
const MAX_SIZE_INFO_TEXT = 'Max size - 66 MB'

const DndVideoBox = props => {
  const classes = useStyles()
  const { onDrop, onFileUpload, error, supportedVideoTypes } = props

  const [{}, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(props, monitor)
      }
    },
  })

  return (
    <>
      <div ref={drop} className={classes.uploadContainer}>
        <input
          onChange={e => onFileUpload(e.currentTarget.files[0])}
          accept={supportedVideoTypes}
          className={classes.input}
          id="upload-button-file"
          type="file"
        />
        <label htmlFor="upload-button-file">
          <Button
            classes={{ root: classes.iconBtn }}
            variant="text"
            component="span"
            startIcon={
              <SvgIcon viewBox="0 0 16 16" className={classes.iconStyle}>
                <DownloadIcon />
              </SvgIcon>
            }
          >
            {UPLOAD_BUTTON_TEXT}
          </Button>
        </label>
        <Typography variant="body2" className={classes.subText}>
          {MAX_SIZE_INFO_TEXT}
        </Typography>
      </div>
      {!!error && (
        <div className={classes.errorText}>
          <Icon style={classes.errorIcon} iconClass="las la-exclamation-triangle" />
          <Typography variant="body2">{error}</Typography>
        </div>
      )}
    </>
  )
}

export default DndVideoBox
