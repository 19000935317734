import { REGEXP } from 'constants/index'
import moment from 'moment-timezone'
import { getHoursFromDecimal, getMinutesFromDecimal } from '../activities'
import mixpanel from 'mixpanel-browser/src/loader-module'

export function getFilenameFromContentDisposition(response) {
  let filename = 'corrupted_file'
  const contentDisposition = response.headers['content-disposition']
  const matches = REGEXP.filename.exec(contentDisposition)
  if (matches != null && matches[3]) {
    filename = matches[3].replace(/['"]/g, '')
  }
  return decodeURI(filename)
}

export function getDefaultTZ() {
  return moment.tz.guess()
}

export function getDatePickerValueWithProTZ(date, tz) {
  if (!tz) tz = getDefaultTZ()
  const tzOffset =
    moment()
      .tz(tz)
      .utcOffset() / 60
  const offsetWithoutSign = tzOffset >= 0 ? tzOffset : tzOffset.toString().substring(1)
  const hoursOffset = getHoursFromDecimal(offsetWithoutSign)
  const minutesOffset = getMinutesFromDecimal(offsetWithoutSign)
  const formattedOffset = hoursOffset < 10 ? `0${hoursOffset}` : hoursOffset
  const sign = tzOffset > 0 ? '+' : '-'
  const momentDate = date ? moment(date) : moment()
  const dateString = `${momentDate.format('YYYY-MM-DDTHH:mm:ss')}${sign}${formattedOffset}:${minutesOffset || '00'}`
  return new Date(dateString)
}

export function getDateWithTZ(date, tz) {
  if (!tz) tz = getDefaultTZ()
  const localTZ = moment.tz.guess()
  const offSetLocal =
    moment()
      .tz(localTZ)
      .utcOffset() / 60
  const offSet =
    moment()
      .tz(tz)
      .utcOffset() / 60
  const dateObj = date
    ? moment(date)
        .utc()
        .toDate()
    : moment()
        .utc()
        .toDate()
  return new Date(dateObj.getTime() + (offSet - offSetLocal) * 3600000)
}

export function getUtcWithOffset(date, timezone, part) {
  if (!timezone) timezone = getDefaultTZ()
  const offSet =
    moment()
      .tz(timezone)
      .utcOffset() / 60
  if (part === 'start')
    return moment
      .utc(date)
      .startOf('day')
      .subtract(offSet, 'h')
  else if (part === 'end')
    return moment
      .utc(date)
      .endOf('day')
      .subtract(offSet, 'h')
  else return moment.utc(date).subtract(offSet, 'h')
}

export const formatDecimal = value => {
  return value && Number(value).toFixed(2)
}

export const getFormattedMessageDate = date => {
  const momentDate = moment(date)
  const now = moment()
  const timeDiff = momentDate.diff(now, 'days')

  if (timeDiff === 0) {
    return momentDate.format('h:mm a')
  } else if (timeDiff === 1) {
    return 'yesterday'
  } else if (momentDate.get('year') !== now.get('year')) {
    return momentDate.format('MMM DD, YYYY')
  } else {
    return momentDate.format('MMM DD')
  }
}

export const getUtmSource = () => {
  const { params = {} } = getCampaignParams()
  return params['utm_source [last touch]'] || ''
}

const getQueryParam = (url, param) => {
  // Expects a raw URL
  const newParam = param.replace(/[[]/, '[').replace(/[]]/, ']')
  const regexS = `[?&]${newParam}=([^&#]*)`
  const regex = new RegExp(regexS)
  const results = regex.exec(url)

  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return ''
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
  }
}

export const getCampaignParams = () => {
  const campaignKeywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
  let kw = ''
  const params = {}
  const firstParams = {}

  for (let index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index])
    if (kw.length) {
      params[`${campaignKeywords[index]} [last touch]`] = kw
    }
  }

  for (let index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(document.URL, campaignKeywords[index])
    if (kw.length) {
      firstParams[`${campaignKeywords[index]} [first touch]`] = kw
    }
  }

  return { params: params, firstParams: firstParams }
}

export const getErrorDetails = e => {
  return (e.response && e.response.data && e.response.data.details) || ''
}

export const getTimePassedSinceEvent = time => {
  const now = moment()
  const eventTime = moment(time)
  const diff = now.diff(eventTime, 'minutes')
  const diffInDays = now.startOf('day').diff(moment(time).startOf('day'), 'days')

  if (diffInDays === 0) {
    if (diff < 1) {
      return 'now'
    } else if (diff >= 1 && diff < 60) {
      return `${diff.toFixed(0)} minutes ago`
    } else if (diff >= 60 && diff < 1440) {
      return `${(diff / 60).toFixed(0)} hours ago`
    }
  } else if (diffInDays === 1) {
    return `yesterday at ${eventTime.format('hh:mm a')}`
  } else if (eventTime.get('year') === now.get('year')) {
    return `${eventTime.format('MMMM DD')} at ${eventTime.format('hh:mm a')}`
  } else {
    return `${eventTime.format('MMMM DD, YYYY')} at ${eventTime.format('hh:mm a')}`
  }
}

export const getNonLaunchedStateModalVisibility = stateName => {
  const states = window.localStorage.getItem('noLaunchedAlertIsShown')

  if (states) {
    return !!JSON.parse(states)[stateName]
  } else {
    return false
  }
}

export const setNonLaunchedStateModalVisibility = (stateName, isShown) => {
  const states = window.localStorage.getItem('noLaunchedAlertIsShown')

  if (states) {
    const stateData = JSON.parse(states)
    stateData[stateName] = isShown
    window.localStorage.setItem('noLaunchedAlertIsShown', JSON.stringify(stateData))
  } else {
    window.localStorage.setItem(
      'noLaunchedAlertIsShown',
      JSON.stringify({
        [stateName]: isShown,
      })
    )
  }
}
