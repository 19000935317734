import React from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '../Checkbox'
import Hint from '../text/hint'
import XIcon from '../Icon'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    marginBottom: '25px',
  },
  hint: {
    color: colors.ERROR,
    textAlign: 'left',
  },
  icon: {
    marginRight: '8px',
  },
  input: {
    marginBottom: 4,
  },
})

const FormikCheckbox = props => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  const classes = useStyles()
  return (
    <div className={classes.div}>
      <Checkbox
        {...field}
        name={props.name}
        checked={props.checked}
        classes={props.classes}
        labelTextClasses={props.labelClasses}
        labelClasses={props.labelClassesControl}
        label={props.label}
        link={props.link}
        onClick={props.onClick}
      />
      {meta.touched && meta.error && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <XIcon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {meta.error}
        </Hint>
      )}
    </div>
  )
}

export default FormikCheckbox
