import NoDataImage from 'static/il_no_events@3x.png'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Tooltip } from 'components'
import classNames from 'classnames'

const useStyles = makeStyles({
  noDataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataImage: {
    height: 305,
    width: 407,
    marginBottom: 16,
  },
  noDataText: {
    marginBottom: 24,
    width: 365,
    fontSize: 15,
    lineHeight: 1.47,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    color: '#011a33',
    textAlign: 'center',
  },
  noDataTextDetails: {
    maxWidth: 570,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 1.47,
    textAlign: 'center',
  },
  exploreButton: {
    width: 200,
  },
})

const NoDataPlaceholder = props => {
  const classes = useStyles()
  const {
    onButtonPress,
    label,
    buttonText,
    hideButton = false,
    disabled,
    buttonTooltip,
    imageSrc,
    tooltipClass,
    titleClass,
    imageClass,
    details,
    email,
    containerClass,
    startIcon = null,
    buttonSize = 'large',
  } = props

  return (
    <div className={classNames({ [classes.noDataContainer]: true, [containerClass]: containerClass })}>
      <img
        className={classNames({ [classes.noDataImage]: !imageClass, [imageClass]: imageClass })}
        src={imageSrc || NoDataImage}
        alt={label}
      />
      <Typography
        variant="subtitle2"
        className={classNames({ [classes.noDataText]: !titleClass, [titleClass]: titleClass })}
      >
        {label}
      </Typography>
      <Typography variant="subtitle2" className={classes.noDataTextDetails}>
        {details} {email}
      </Typography>
      {!hideButton &&
        (buttonTooltip ? (
          <Tooltip title={buttonTooltip} interactive tooltipClass={tooltipClass}>
            <Button
              onClick={onButtonPress}
              variant="primary"
              size={buttonSize}
              className={classes.exploreButton}
              disabled={disabled}
              startIcon={startIcon}
            >
              {buttonText}
            </Button>
          </Tooltip>
        ) : (
          <Button
            onClick={onButtonPress}
            variant="primary"
            size="large"
            className={classes.exploreButton}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        ))}
    </div>
  )
}

export default NoDataPlaceholder
