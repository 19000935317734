import React from 'react'
import { Typography, SubCalendarList } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { CONSTANTS } from 'constants/constants'

const useStyles = makeStyles({
  calendarSettingsContainer: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  calendarSettingsHeader: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarSettingsTitle: {
    marginLeft: 16,
  },
})

const CalendarSettings = ({ calendar, setCalendar }) => {
  const classes = useStyles()
  const calendarSettings = CONSTANTS.CALENDAR_SETTINGS[calendar.provider]
  const onSubCalendarListChange = (id, value) => {
    const calendars = calendar.calendars.map(subCalendar =>
      subCalendar.id === id ? { ...subCalendar, selected: value } : subCalendar
    )
    setCalendar({ ...calendar, calendars })
  }
  return (
    <div className={classes.calendarSettingsContainer}>
      <Typography variant="subtitle1">
        Set the calendar(s) XIRA should check for conflicts to prevent double bookings
      </Typography>
      <div className={classes.calendarSettingsHeader}>
        {calendarSettings.calendarLogo}
        <Typography className={classes.calendarSettingsTitle} variant="body1">
          {calendar.username}
        </Typography>
      </div>
      <SubCalendarList calendars={calendar.calendars} onSubCalendarListChange={onSubCalendarListChange} />
    </div>
  )
}

export default CalendarSettings
