import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CONSTANTS } from 'constants/index'
import { FormAutocomplete, Typography } from 'components'

const useStyles = makeStyles({
  standardMargin: {
    marginBottom: 16,
  },
})

const invoicingOptions = Object.values(CONSTANTS.INVOICING)
const invoiceFrequencyOptions = Object.values(CONSTANTS.INVOICE_FREQUENCY)

const getInvoicingHint = invoicingType => {
  return CONSTANTS.INVOICING[invoicingType] ? CONSTANTS.INVOICING[invoicingType].hint : ''
}

const TimeAndExpenseSection = props => {
  const classes = useStyles()
  const { caseData, mode, onChange, errors = {}, setErrors = error => {} } = props

  const handleChange = (name, value) => {
    onChange({
      [name]: value,
    })
    setErrors({
      [name]: '',
    })
  }

  return (
    <div>
      <FormAutocomplete
        label="Invoicing *"
        disabled={mode !== CONSTANTS.MODE.ADD}
        value={caseData.invoicing}
        fullWidth
        containerClass={classes.standardMargin}
        name="invoicing"
        error={errors.invoicing}
        onChange={handleChange}
        options={invoicingOptions}
        placeholder="Please select"
        hint={getInvoicingHint(caseData.invoicing)}
      />
      {caseData.invoicing === CONSTANTS.INVOICING[2].value && (
        <FormAutocomplete
          label="Invoice frequency *"
          disabled={mode !== CONSTANTS.MODE.ADD}
          value={caseData.invoice_frequency}
          fullWidth
          containerClass={classes.standardMargin}
          name="invoice_frequency"
          error={errors.invoice_frequency}
          onChange={handleChange}
          options={invoiceFrequencyOptions}
          placeholder="Please select"
        />
      )}
    </div>
  )
}

export default TimeAndExpenseSection
