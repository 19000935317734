import * as CONSTANTS from 'store/constants/auth'
import {
  createNewUserPath,
  getCheckEmailPath,
  getCurrentProfilePath,
  getCurrentUserPath,
  getPasswordResetEmailPath,
  getPasswordResetPath,
  getPortalStatePath,
  getUpdatePasswordPath,
  getVerificationLinkStatusPath,
  loginUserPath,
  resendVerificationEmailPath,
  socialSignUpPath,
} from 'utils/path-helpers/api'
import {
  navigateTo,
  navigateToAccountDashboard,
  navigateToClientDashboard,
  navigateToClientWelcome,
  navigateToProfileStep,
  navigateToRoot,
  navigateToWelcome,
  redirectTo,
} from 'store/actions/navigation'
import {
  getTimezonesList,
  openSnackbar,
  setRedirectPath,
  toggleForgotPassEmail,
  toggleLogin,
  toggleMobileHint,
  toggleRestorePass,
  toggleSignUp,
} from 'store/actions/common'
import axios from 'axios'
import { getStates, getUserContactInfo } from 'store/actions/profileCreation'
import {
  getJWTLocally,
  removeJWTLocally,
  removeRefreshTokenLocally,
  setJWTLocally,
  setRefreshTokenLocally,
} from 'utils/auth'
import {
  getPracticeAreaMatches,
  getProfileList,
  getProfileListEnd,
  getProfileListStart,
  setCountryStateValue,
  setPracticeAreaValue,
  setPractiseSearchName,
  setPractiseSearchType,
} from 'store/actions/search'
import {
  getUserCalendarTimezone,
  checkCalendarConnections,
  setHasCalendarProblems,
  connectToCalendarHealthService,
  disconnectFromCalendarHealthService,
} from 'store/actions/calendar'
import { setProfileApproveStatus } from 'store/actions/accountProfile'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { setCurrentStep } from '../profileCreation'
import {
  ERROR_SNACKBAR,
  SOMETHING_WENT_WRONG,
  SUCCESS_SNACKBAR,
  VERIFICATION_EMAIL_REQUEST_SUCCESS,
  VERIFICATION_EMAIL_RESEND_SUCCESS,
} from '../../constants/common'
import {
  checkIfUserHasUnreadMessages,
  subscribeToChatChanges,
  initializeChatInstance,
  destroyChatInstance,
  unsubscribeFromChatChanges,
} from 'store/actions/chat/chat'
import { setVaultPlanAccepted } from '../vault'
import { getUtmSource } from 'utils/getters/common'
import {
  getUnreadNotificationsCount,
  connectToNotificationService,
  disconnectFromNotificationService,
} from 'store/actions/notifications/notifications'
import { v4 as uuidv4 } from 'uuid'
import { setSessionID } from '../common'

export const logOutUser = link => async (dispatch, getState, { history }) => {
  const payload = {
    currentUser: {},
    isUserLoggedIn: false,
  }

  axios
    .delete(loginUserPath())
    .then(() => {
      removeJWTLocally()
      removeRefreshTokenLocally()
      if (link) {
        window.location.href = link
        return
      }
      dispatch(getHideHeaderBanner())
      dispatch(setCurrentUser(payload))
      dispatch(navigateToRoot())
      dispatch(setHasCalendarProblems(false))
    })
    .catch(() => dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while logout')))
  Analytics.logout()
  disconnectFromNotificationService()
  disconnectFromCalendarHealthService()
  unsubscribeFromChatChanges()
  dispatch(destroyChatInstance())
}

export const getHeaderBanner = payload => ({
  type: CONSTANTS.SET_SHOW_BANNER,
  payload,
})
export const getHideHeaderBanner = () => ({
  type: CONSTANTS.SET_HIDE_BANNER,
})

export const onApplicationStart = () => async (dispatch, getState, { history }) => {
  dispatch(getProfileListStart())
  dispatch(getCurrentUser())
  dispatch(getTimezonesList())
  dispatch(toggleMobileHint(true))
  await dispatch(getPracticeAreaMatches()) //it is very important to load practice areas first, because we set saved practice area from session storage to application store so than we can use it fto set default filter for federal and non-federal practice areas
  let countryState = window.sessionStorage.getItem('countryState')
  let searchName = window.sessionStorage.getItem('searchName')
  let practiceArea = window.sessionStorage.getItem('practiceArea')
  let searchType = +window.sessionStorage.getItem('searchType')
  if (window.location.pathname === '/search') {
    const query = new URLSearchParams(window.location.search)
    practiceArea = query.get('practice_area') || practiceArea
    countryState = query.get('state') || countryState
    searchType = +query.has('attorney_name') || searchType
    searchName = query.get('attorney_name') || searchName
  }
  const sessionID = uuidv4()
  dispatch(setSessionID(sessionID))
  if (countryState) dispatch(setCountryStateValue(countryState))
  if (practiceArea) dispatch(setPracticeAreaValue(practiceArea))
  if (searchName) dispatch(setPractiseSearchName(searchName))
  if (searchType) dispatch(setPractiseSearchType(searchType))
  const states = await dispatch(getStates())
  setNonLaunchedStates(states)
  if ((practiceArea || searchName) && countryState) {
    dispatch(getProfileList())
  } else {
    dispatch(getProfileListEnd({ results: [], count: 0 }))
  }
}

export const onApplicationStartSearchOnly = () => async dispatch => {
  await dispatch(getPracticeAreaMatches())
  const states = await dispatch(getStates())
  setNonLaunchedStates(states)
}

const setNonLaunchedStates = states => {
  if (!window.localStorage.getItem('noLaunchedAlertIsShown')) {
    const nonLaunchedStatesVisibility = states.reduce((result, item) => {
      return {
        ...result,
        [item.name]: item.name === 'California',
      }
    }, {})
    window.localStorage.setItem('noLaunchedAlertIsShown', JSON.stringify(nonLaunchedStatesVisibility))
  }
}

export const requestVerificationLink = () => async (dispatch, getState, { history }) => {
  const { signUpEmail } = getState().auth
  dispatch(resendVerificationEmail(VERIFICATION_EMAIL_REQUEST_SUCCESS, signUpEmail))
}

export const resendVerificationLink = isInvited => async (dispatch, getState, { history }) => {
  const username = window.sessionStorage.getItem('verifyEmail')
  dispatch(resendVerificationEmail(VERIFICATION_EMAIL_RESEND_SUCCESS, username, isInvited))
}

export const resendVerificationEmail = (snackMsg, username, isInvited) => async (dispatch, getState, { history }) => {
  axios
    .post(resendVerificationEmailPath(), { username, is_invited: isInvited || false })
    .then(() => dispatch(openSnackbar(SUCCESS_SNACKBAR, snackMsg)))
    .catch(() => dispatch(openSnackbar(ERROR_SNACKBAR, SOMETHING_WENT_WRONG)))
  Analytics.track(EVENTS.RESEND_THE_LINK)
}

export const getVerificationLinkStatus = hash => async (dispatch, getState, { history }) => {
  return axios
    .post(getVerificationLinkStatusPath(), { hash })
    .then(res => {
      return res.data.hash_is_valid
    })
    .catch(() => dispatch(openSnackbar(ERROR_SNACKBAR, SOMETHING_WENT_WRONG)))
}

const checkProfileCompletion = (currentStep, isLogIn, history) => dispatch => {
  if (currentStep === 1) {
    dispatch(navigateToWelcome())
  } else if (currentStep) {
    dispatch(redirectTo(`/pro/profile/create/${currentStep}`))
  } else if (
    //cause we have zendesk-login url that's why === 1
    history.location.pathname.indexOf('login') === 1 ||
    history.location.pathname.indexOf('password') > -1 ||
    history.location.pathname.indexOf('signup') > -1 ||
    history.location.pathname === '/' ||
    history.location.search.indexOf('client') > -1
  ) {
    dispatch(navigateToAccountDashboard())
  }
}

export const sendResetPasswordEmail = email => async (dispatch, getState, { history }) => {
  axios
    .post(getPasswordResetEmailPath(), { username: email })
    .then(() => {
      if (history.location.pathname === '/password/reset') {
        dispatch(navigateToRoot())
      } else {
        dispatch(toggleRestorePass(false))
      }
      dispatch(toggleForgotPassEmail(true))
    })
    .catch(() => dispatch(openSnackbar(ERROR_SNACKBAR, SOMETHING_WENT_WRONG)))
}
export const resendResetPasswordEmail = username => dispatch => {
  axios
    .post(getPasswordResetEmailPath(), { username: username })
    .then(() => {
      dispatch(navigateToRoot())
      dispatch(toggleForgotPassEmail(true))
    })
    .catch(() => dispatch(openSnackbar(ERROR_SNACKBAR, SOMETHING_WENT_WRONG)))
}
export const resetPassword = payload => dispatch => {
  return axios.post(getPasswordResetPath(), { hash: payload.hash, new_password: payload.password }).catch(error => {
    const data = error.response && error.response.data && error.response.data
    return Promise.reject('error')
  })
}

const setActicatedFeatures = payload => ({
  type: CONSTANTS.SET_ACTIVATED_FEATURES,
  payload,
})

export const getCurrentUser = (params = {}) => async (dispatch, getState, { history }) => {
  const token = getJWTLocally()
  const { redirectPath } = getState().common
  if (token) {
    dispatch({ type: CONSTANTS.USER_LOGGING_IN })
    dispatch(setAuthRole(''))
    return axios
      .get(getCurrentUserPath())
      .then(async response => {
        if (params.isAfterRegistration && !params.isEmailExists) {
          Analytics.register({
            $email: response.data.username,
            user_type: response.data.role === 'pro' ? 'lawyer' : 'client',
            auth_type: params.provider,
            uuid: response.data.uuid,
          })
        }

        if (params.isLogIn) {
          Analytics.login({
            $email: response.data.username,
            auth_type: params.provider,
            uuid: response.data.uuid,
          })
        }

        if (response.data.role === 'pro') {
          await dispatch(getUserCalendarTimezone())
          dispatch({ type: CONSTANTS.GETTING_PROFILE })
          axios
            .get(getCurrentProfilePath())
            .then(response => {
              const currentStep = response.data.profile_state
              const isApproved = response.data.is_approved
              const activatedFeature = {
                hasBanner: response.data.activated_feature.has_banner,
                hasCalendar: response.data.activated_feature.has_calendar,
                hasCases: response.data.activated_feature.has_cases,
                hasVault: response.data.activated_feature.has_vault,
              }
              dispatch(setActicatedFeatures(activatedFeature))
              dispatch({ type: CONSTANTS.SET_ACTIVATED_FEATURES_LOADED })
              if (currentStep === 0 && isApproved) {
                dispatch(checkCalendarConnections())
                dispatch(connectToCalendarHealthService())
              }
              dispatch(setCurrentStep(currentStep))
              dispatch({ type: CONSTANTS.GOT_PROFILE })
              dispatch(checkProfileCompletion(currentStep, params.isLogIn, history))
              dispatch(setProfileApproveStatus(response.data.is_approved))
              dispatch(setVaultPlanAccepted(response.data.is_accepted_pro_vault_plan))
              dispatch(
                getHeaderBanner({ show: response.data.activated_feature.has_banner, text: response.data.banner_text })
              )
            })
            .catch(() => {
              dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while user info loading'))
            })
        } else if (response.data.role === 'client') {
          dispatch(getUserContactInfo())
          axios
            .get(getPortalStatePath('logInStatus'))
            .then(() => {
              const { pathname } = history.location
              if (params.isLogIn && (pathname === '/login' || pathname === '/')) {
                dispatch(navigateToClientDashboard())
              } else if (redirectPath) {
                dispatch(navigateTo(redirectPath))
                dispatch(setRedirectPath(''))
              }
            })
            .catch(e => {
              if (e.response && e.response.status === 404) {
                if (!params.withoutRedirect) {
                  if (!redirectPath && history.location.pathname.indexOf('/client/schedule') < 0) {
                    dispatch(navigateToClientWelcome())
                  } else if (redirectPath) {
                    dispatch(navigateTo(redirectPath))
                    dispatch(setRedirectPath(''))
                  }
                }
                dispatch(setLoginStatus())
              }
            })
        }
        const payload = {
          currentUser: response.data,
          isUserLoggedIn: true,
        }
        dispatch(setCurrentUser(payload))
        Analytics.identify(response.data)
        dispatch(getUnreadNotificationsCount())
        dispatch(connectToNotificationService())
        dispatch(initializeChatInstance())
        dispatch(checkIfUserHasUnreadMessages())
        dispatch(subscribeToChatChanges(response.data.twilio_sid))
        return payload
      })
      .catch(e => {
        dispatch({ type: CONSTANTS.USER_LOGGED_IN })
        dispatch({ type: CONSTANTS.GOT_PROFILE })
        if (e.response && e.response.status === 401) {
          dispatch(getCurrentUser())
        } else {
          dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while user info loading'))
          if (params.isLogIn) {
            Analytics.login({
              $email: '',
              auth_type: '',
              uuid: null,
              error: e.response && e.response.data && e.response.data.datail,
            })
          }
        }
        return {
          currentUser: {},
          isUserLoggedIn: false,
        }
      })
  }
  return {
    currentUser: {},
    isUserLoggedIn: false,
  }
}

export const setLoginStatus = () => dispatch => {
  axios.post(getPortalStatePath('logInStatus'), {
    key: 'logInStatus',
    state: true,
  })
}
export const updateCurrentUser = payload => async (dispatch, getState, { history }) => {
  const currentUser = getState().auth.currentUser
  const updatedUser = { ...currentUser, ...payload }
  dispatch(setCurrentUser({ currentUser: updatedUser, isUserLoggedIn: true }))
  return axios.patch(getCurrentUserPath(), payload).catch(e => {
    dispatch(setCurrentUser({ currentUser: currentUser, isUserLoggedIn: true }))
    return Promise.reject(e)
  })
}

export const updateLocalCurrentUSer = payload => {
  return {
    type: CONSTANTS.UPDATE_LOCAL_CURRENT_USER,
    payload,
  }
}

export const setSignUpEmail = payload => {
  return {
    type: CONSTANTS.SET_SIGN_UP_EMAIL,
    payload,
  }
}

export const registerUser = (payload, isInvite) => async (dispatch, getState, { history }) => {
  const { redirectPath } = getState().common
  const utmSource = getUtmSource()
  const body = {
    first_name: payload.firstName,
    last_name: payload.lastName,
    username: getState().auth.signUpEmail,
    password: payload.password,
    role: payload.userType,
    redirect: redirectPath,
    is_active: isInvite,
    utm_source: utmSource,
  }
  dispatch(startAuthByEmail())
  return axios
    .post(createNewUserPath(), body)
    .then(response => {
      window.sessionStorage.setItem('isInvited', response.data.is_invited)
      if (!isInvite) {
        window.sessionStorage.setItem('verifyEmail', getState().auth.signUpEmail)
        history.push('/verify-email')
      }
      dispatch(toggleSignUp(false))
      dispatch(setRedirectPath(''))
      dispatch(endAuthByEmail())
      //    dispatch(setCurrentUser(body))
      //    dispatch(getAuthTokenByEmail({ username: body.username, password: body.password }))
      //        history.push('/verify-email')
      return response.data
    })
    .catch(e => {
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while user creation'))
      dispatch(endAuthByEmail())
      return Promise.reject(e.response && e.response.data && e.response.data.detail)
    })
}

export const getAuthTokenByEmail = (body, params = {}) => (dispatch, getState, { history }) => {
  dispatch(startAuthByEmail())
  return axios.post(loginUserPath(), body).then(response => {
    dispatch(toggleLogin(false))
    setJWTLocally(response.data.access)
    setRefreshTokenLocally(response.data.refresh)
    dispatch(
      getCurrentUser({
        ...params,
        isLogIn: true,
        provider: 'email',
        //withoutRedirect: params.withoutRedirect || !!response.data.hash,
      })
    )
    dispatch(endAuthByEmail())
    // if (response.data.hash && !params.withoutRedirect) {
    //   dispatch(navigateTo(`/invite-registration?hash=${response.data.hash}`))
    // }
  })
}

export const logInWithSocial = payload => dispatch => {
  const data = { ...payload }

  if (!payload.is_login) {
    data.utm_source = getUtmSource()
  }

  return axios.post(socialSignUpPath(), data).then(response => {
    dispatch(toggleLogin(false))
    dispatch(toggleSignUp(false))
    setJWTLocally(response.data.token)
    setRefreshTokenLocally(response.data.refresh)
    // if (response.data.hash) {
    //   dispatch(navigateTo(`/invite-registration?hash=${response.data.hash}`))
    // }
    dispatch(
      getCurrentUser({
        isLogIn: true,
        provider: payload.provider === 'facebook' ? 'fb' : 'google',
        isAfterRegistration: true,
        isEmailExists: payload.isEmailExists,
        //withoutRedirect: !!response.data.hash,
      })
    )
  })
}

export const checkEmail = email => (dispatch, getState, { history }) => {
  return axios
    .get(getCheckEmailPath(), {
      params: { email },
    })
    .catch(() => {
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while email check'))
    })
}

export const startAuthByEmail = () => ({
  type: CONSTANTS.IS_AUTH_BY_EMAIL_START,
})

export const endAuthByEmail = () => ({
  type: CONSTANTS.IS_AUTH_BY_EMAIL_END,
})

export const setCurrentUser = payload => ({
  type: CONSTANTS.SET_CURRENT_USER,
  payload,
})

const startChangePassword = () => ({
  type: CONSTANTS.IS_CHANGING_PASSWORD,
})

const endChangePassword = () => ({
  type: CONSTANTS.END_CHANGING_PASSWORD,
})

export const updateUserPassword = ({ oldPassword, newPassword }) => dispatch => {
  dispatch(startChangePassword())
  axios
    .patch(getUpdatePasswordPath(), {
      old_password: oldPassword,
      new_password: newPassword,
    })
    .then(resp => {
      dispatch(endChangePassword())
      dispatch(logOutUser())
      dispatch(openSnackbar('success', 'Password was changed'))
    })
    .catch(error => {
      dispatch(endChangePassword())
      const message =
        (error && error.response && error.response.data && error.response.data.detail[0]) ||
        'Error while user changing password'
      dispatch(openSnackbar(ERROR_SNACKBAR, message))
      return Promise.reject(error)
    })
}

export const setProfilePhoto = photoUrl => ({
  type: CONSTANTS.SET_PROFILE_PHOTO,
  payload: photoUrl,
})

export const setAuthRole = role => ({
  type: CONSTANTS.CHANGE_AUTH_ROLE,
  payload: role,
})

export const getCurrentProfile = () => dispatch => {
  return axios
    .get(getCurrentProfilePath())
    .then(response => {
      return response.data.profile_state
    })
    .catch(e => {
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while user info loading'))
      return Promise.reject(e)
    })
}

export const getLoginStatus = () => dispatch => {
  return axios
    .get(getPortalStatePath('logInStatus'))
    .then(response => response.data.state)
    .catch(e => {
      if (e.response.status === 401) {
        return false
      }
      return Promise.reject(e)
    })
}

export const toggleAuthModal = payload => ({
  type: CONSTANTS.TOGGLE_USER_DELETED_MODAL,
  payload,
})
