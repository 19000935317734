import React from 'react'
import { InvoiceDocument, PDFViewer } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import * as moment from 'moment-timezone'

const useStyles = makeStyles({
  viewInvoiceBody: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})

const InvoiceDocumentView = ({ invoice, timezone = moment.tz.guess() }) => {
  const classes = useStyles()

  return (
    <div className={classes.viewInvoiceBody}>
      <div>
        <div id="invoice">
          {invoice.uuid && <PDFViewer document={<InvoiceDocument timezone={timezone} invoice={invoice} />} />}
        </div>
      </div>
    </div>
  )
}

export default InvoiceDocumentView
