export const SET_CALENDAR = 'SET_CALENDAR'
export const SET_LOCAL_CALENDAR = 'SET_LOCAL_CALENDAR'
export const RESET_XIRA_CALENDAR = 'RESET_XIRA_CALENDAR'
export const SET_CONNECTED_CALENDAR_LIST = 'SET_CONNECTED_CALENDAR_LIST'
export const SET_READY_FOR_CONNECT_CALENDARS = 'SET_READY_FOR_CONNECT_CALENDARS'
export const REFRESH_CALENDAR_CONNECTIONS_START = 'REFRESH_CALENDAR_CONNECTIONS_START'
export const REFRESH_CALENDAR_CONNECTIONS_END = 'REFRESH_CALENDAR_CONNECTIONS_END'
export const GET_ICLOUD_CALENDAR_CONNECTION_START = 'GET_ICLOUD_CALENDAR_CONNECTION_START'
export const SET_USER_ADVANCED_CALENDAR = 'SET_USER_ADVANCED_CALENDAR'
export const GET_WORKING_DAYS_START = 'GET_WORKING_DAYS_START'
export const GET_WORKING_DAYS_END = 'GET_WORKING_DAYS_END'
export const SAVE_WORKING_DAYS_START = 'SAVE_WORKING_DAYS_START'
export const SAVE_WORKING_DAYS_END = 'SAVE_WORKING_DAYS_END'
export const GET_CALENDAR_CONNECTION_START = 'GET_CALENDAR_CONNECTION_START'
export const GET_CALENDAR_CONNECTION_END = 'GET_CALENDAR_CONNECTION_END'
export const GET_CALENDAR_LIST_START = 'GET_CALENDAR_LIST_START'
export const GET_CALENDAR_LIST_END = 'GET_CALENDAR_LIST_END'
export const GET_CALENDAR_SUBLIST_START = 'GET_CALENDAR_SUBLIST_START'
export const GET_CALENDAR_SUBLIST_END = 'GET_CALENDAR_SUBLIST_END'
export const SET_USER_TIMEZONE = 'SET_USER_TIMEZONE'
export const GET_WORKING_DAYS_FLAT_END = 'GET_WORKING_DAYS_FLAT_END'
export const SET_HAS_CALENDAR_PROBLEMS = 'SET_HAS_CALENDAR_PROBLEMS'
