import React from 'react'
import { InputLabel, Typography } from 'components'

const FormViewField = props => {
  const { label, value, containerClass, variant, valueClass } = props

  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <Typography variant={variant} className={valueClass}>
        {value}
      </Typography>
    </div>
  )
}

export default FormViewField
