import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  CasesTableFilters,
  ColorCircularProgress,
  ConfirmationModal,
  Link,
  NoDataPlaceholder,
  ProCaseTableRow,
  Table,
  TableEmptyState,
  TablePagination,
  TableResetFilters,
  Typography,
  ProActivityEntryModal,
  ErrorModal,
} from 'components'
import { connect, useSelector, useDispatch } from 'react-redux'
import { colors, CONSTANTS } from 'constants/index'
import {
  getProCases,
  makeCaseInactive,
  resetCasesFilter,
  setCasesSorting,
  resetCasesSorting,
  setTableConfiguration,
} from 'store/actions/cases'
import {
  navigateToActivitiesListInNewTab,
  navigateToCaseDetails,
  navigateToCreateCase,
  navigateToEditCase,
  navigateToInvoicesListInNewTab,
} from 'store/actions/navigation'
import { getUserConnectionsForFilter } from 'store/actions/connections'
import { isEqual } from 'lodash'
import NoCases from 'static/cases/no_cases.png'
import moment from 'moment-timezone'
import { goToZendesk, closeErrorModal } from 'store/actions/common'
import MakeInactiveForbiddenMsg from 'components/cases/MakeInactiveForbiddenMsg'
import { checkProCases, checkProConnections } from '../../store/actions/cases'
import ChatModal from '../../components/chat/ChatModal'
import CaseInactivationErrorModal from './CaseInactivationErrorModal'

const useStyles = makeStyles({
  activitiesContainer: {
    padding: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableHeaderCell: {
    borderBottom: `2px solid ${colors.BASIC.COLOR[10]}`,
  },
  clearFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 16,
  },
  casesTable: {
    overflow: 'initial',
  },
})

const columns = [
  {
    value: 'is_active',
    label: '',
  },
  {
    value: 'name',
    label: 'Case/Matter name',
    sortable: true,
  },
  {
    value: 'client__user__first_name',
    label: 'Client',
    sortable: true,
  },
  {
    value: 'fee_type',
    label: 'Type',
    sortable: true,
  },
  {
    value: 'practice_area__name',
    label: 'Practice area',
    sortable: true,
  },
  {
    value: 'updated_at',
    label: 'Last activity',
    sortable: true,
  },
  {
    value: 'buttons',
    label: '',
    sortable: false,
  },
]

const initialFilter = {
  search: '',
  status: true,
  clientId: null,
}

const TooltipText = ({ cases, profileIsApproved, addButtonActive, goToZendesk }) => {
  if (!profileIsApproved) {
    const wordings = cases ? 'cases' : 'activities'
    return (
      <span>
        {`You can not manage ${wordings} until your license is approved. Please contact `}
        <Link onClick={goToZendesk}>XIRA</Link>
        {' admin for details.'}
      </span>
    )
  }

  if (!addButtonActive) {
    return <span>Cases can only be created with clients in your connections list.</span>
  }
}

const currentTimezone = moment.tz.guess()

const ProCasesContainer = state => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    cases,
    count,
    casesAreLoading,
    tableConfiguration,
    filters,
    sorting,
    profileIsApproved,
    getUserConnectionsForFilter,
    getProCases,
    makeCaseInactive,
    setCasesSorting,
    setTableConfiguration,
    navigateToCreateCase,
    navigateToCaseDetails,
    navigateToEditCase,
    navigateToActivitiesListInNewTab,
    navigateToInvoicesListInNewTab,
    resetCasesFilter,
    resetCasesSorting,
    timezone,
    checkProConnections,
    addButtonActive,
    goToZendesk,
    checkProCases,
    userHasCases,
    userCasesAreLoading,
  } = state
  const { limit, tablePage } = tableConfiguration
  const [confirmationModalShow, setConfirmationModalShow] = useState(false)
  const [showCancellationError, setShowCancellationError] = useState(false)
  const [selectedCase, setSelectedCase] = useState('')
  const isInitialFilters = isEqual(initialFilter, filters)
  const [showActivityModal, setActivityModal] = useState({
    open: false,
    action: null,
    type: null,
    activity: {},
  })
  const { title: titleErrorModal, isOpen: isOpenErrorModal } = useSelector(state => state.common.errorModal)

  const onActivityModalClose = () => {
    setActivityModal({
      open: false,
      type: null,
      action: null,
      activity: {},
    })
  }

  useEffect(() => {
    getUserConnectionsForFilter()
    getProCases()
    checkProCases()
    return () => {
      resetCasesFilter()
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    }
  }, [])

  useEffect(() => {
    checkProConnections()
  }, [])

  const onAddCasePress = () => {
    navigateToCreateCase()
  }

  const onMakeCaseInactiveConfirm = async () => {
    try {
      await makeCaseInactive(selectedCase.uuid, selectedCase.name)
      setConfirmationModalShow(false)
      setShowCancellationError(false)
      getProCases()
    } catch (e) {
      console.error(e)
    }
  }

  const getRowActions = rowData => {
    let actions = [
      {
        key: 'seeDetails',
        label: 'see details',
        action: data => {
          navigateToCaseDetails(data.uuid)
        },
      },
    ]

    if (rowData.is_active && rowData.fee_type !== CONSTANTS.FEE_TYPES['0'].key) {
      actions = [
        ...actions,
        {
          key: 'edit',
          label: 'edit',
          action: data => {
            navigateToEditCase(data.uuid)
          },
          hint: profileIsApproved ? (
            ''
          ) : (
            <TooltipText cases profileIsApproved={profileIsApproved} goToZendesk={goToZendesk} />
          ),
          disabled: !profileIsApproved,
        },
      ]
    }

    if (rowData.is_able_to_create_time_entry) {
      actions = [
        ...actions,
        {
          key: 'newTimeEntry',
          label: 'new time entry',
          action: data => {
            setActivityModal(config => ({
              ...config,
              open: true,
              action: 'create',
              type: 'time',
              activity: { caseId: data.uuid },
            }))
          },
          hint: profileIsApproved ? (
            ''
          ) : (
            <TooltipText profileIsApproved={profileIsApproved} goToZendesk={goToZendesk} />
          ),
          disabled: !profileIsApproved,
        },
      ]
    }

    if (rowData.is_able_to_create_time_expense_entry) {
      actions = [
        ...actions,
        {
          key: 'newExpenseEntry',
          label: 'new expense entry',
          action: data => {
            setActivityModal(config => ({
              ...config,
              open: true,
              action: 'create',
              type: 'expense',
              activity: { caseId: data.uuid },
            }))
          },
          hint: profileIsApproved ? (
            ''
          ) : (
            <TooltipText profileIsApproved={profileIsApproved} goToZendesk={goToZendesk} />
          ),
          disabled: !profileIsApproved,
        },
      ]
    }

    if (rowData.is_able_to_see_activities) {
      actions = [
        ...actions,
        {
          key: 'seeRelatedActivities',
          label: 'see related activities',
          action: data => {
            navigateToActivitiesListInNewTab({ caseId: data.uuid })
          },
        },
      ]
    }

    if (rowData.is_able_to_see_invoices) {
      actions = [
        ...actions,
        {
          key: 'seeRelatedInvoices',
          label: 'see related invoices',
          action: data => {
            navigateToInvoicesListInNewTab({ caseId: data.uuid })
          },
        },
      ]
    }
    if (rowData.is_active) {
      actions = [
        ...actions,
        {
          key: 'makeInactive',
          label: 'make inactive',
          action: data => {
            setSelectedCase(data)
            if (rowData.can_inactivate && rowData.reason_for_disable_inactivate) {
              setShowCancellationError(true)
            } else if (!rowData.can_inactivate) {
              setShowCancellationError(true)
            } else {
              setConfirmationModalShow(true)
            }
          },
          hint: profileIsApproved ? (
            ''
          ) : (
            <TooltipText cases profileIsApproved={profileIsApproved} goToZendesk={goToZendesk} />
          ),
          disabled: !profileIsApproved,
        },
      ]
    }

    return actions
  }

  const onSort = (name, isAcs) => {
    setCasesSorting(name, isAcs)
    setTableConfiguration({
      tablePage: 0,
      offset: 0,
    })
    getProCases()
  }

  const onRowsPerPageChange = value => {
    setTableConfiguration({
      tablePage: 0,
      offset: 0,
      limit: value,
    })
    getProCases()
  }

  const onPageChange = value => {
    setTableConfiguration({
      tablePage: value,
      offset: value * limit,
    })
    getProCases()
  }

  const onClearFilters = () => {
    resetCasesFilter()
    resetCasesSorting()
    setTableConfiguration({
      tablePage: 0,
      offset: 0,
    })
    getProCases()
  }

  const onErrorModalClose = () => {
    dispatch(closeErrorModal())
    setConfirmationModalShow(false)
    setShowCancellationError(false)
  }

  return (
    <div className={classes.activitiesContainer}>
      <div className={classes.header}>
        <Typography variant="h2">Cases</Typography>
        <CasesTableFilters showFilters={userHasCases} isCasesAble={state.isCasesAble} />
      </div>
      {userHasCases && (
        <TableResetFilters
          count={count}
          onClearFilters={onClearFilters}
          showClearFilters={!isInitialFilters}
          containerClass={classes.clearFilterContainer}
        />
      )}
      {casesAreLoading || userCasesAreLoading ? (
        <ColorCircularProgress />
      ) : !userHasCases && !count ? (
        <NoDataPlaceholder
          hideButton
          label="This page will list all your cases/matters, and allows you to create cases, and view, edit
            or make inactive existing cases and clients, as well as create time and expense entries for them."
          imageSrc={NoCases}
        />
      ) : !count ? (
        <TableEmptyState />
      ) : (
        <Table
          tableColumns={columns}
          className={classes.casesTable}
          tableHeaderCellClass={{ root: classes.tableHeaderCell }}
          tableData={cases}
          getRowActions={getRowActions}
          onSort={onSort}
          sorting={sorting}
          tableDataRowRenderer={(columns, data) => (
            <ProCaseTableRow timezone={timezone} columns={columns} data={data} profileIsApproved={profileIsApproved} />
          )}
          footer={
            <TablePagination
              page={tablePage}
              colSpan={columns.length}
              count={count}
              rowsPerPage={limit}
              onRowsPerPageChange={onRowsPerPageChange}
              onPageChange={onPageChange}
            />
          }
          activeRows
        />
      )}
      <ConfirmationModal
        isOpen={confirmationModalShow}
        message={`If you make the case ${selectedCase.name} inactive, you won't be able to create activities for it and no invoice will be generated. Continue?`}
        onClose={() => setConfirmationModalShow(false)}
        title="Make case inactive?"
        onConfirm={onMakeCaseInactiveConfirm}
        confirmationButtonText="yes, make inactive"
        cancelationButtonText="cancel"
      />
      <ProActivityEntryModal
        open={showActivityModal.open}
        type={showActivityModal.type}
        action={showActivityModal.action}
        activity={showActivityModal.activity}
        handleClose={onActivityModalClose}
      />
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onErrorModalClose}
        title={titleErrorModal}
        message={<MakeInactiveForbiddenMsg />}
        cancelationButtonText="ok"
        variant="primary"
      />
      <CaseInactivationErrorModal
        code={selectedCase.reason_for_disable_inactivate}
        isOpen={showCancellationError}
        onClose={() => setShowCancellationError(false)}
        title={selectedCase.can_inactivate ? 'Make case inactive?' : 'You can not make the case inactive'}
        confirmButtonText={'yes, make inactive'}
        cancellationButtonText={selectedCase.can_inactivate ? 'cancel' : 'close'}
        cancelButtonVariant={selectedCase.can_inactivate ? 'secondary' : 'primary'}
        onConfirm={selectedCase.can_inactivate ? onMakeCaseInactiveConfirm : null}
      />
      <ChatModal />
    </div>
  )
}

const mapStateToProps = ({ cases, accountProfile, calendar, auth }) => ({
  cases: cases.cases,
  count: cases.casesLength,
  casesAreLoading: cases.casesAreLoading,
  tableConfiguration: cases.tableConfiguration,
  filters: cases.filters,
  sorting: cases.sort,
  profileIsApproved: accountProfile.isProfileApproved,
  isCasesAble: auth.activatedFeature.hasCases,
  timezone: calendar.advanced_calendar.timezone || currentTimezone,
  addButtonActive: cases.addButtonActive,
  userHasCases: cases.userHasCases,
  userCasesAreLoading: cases.userCasesAreLoading,
})

const mapDispatchToProps = dispatch => ({
  getUserConnectionsForFilter: () => dispatch(getUserConnectionsForFilter()),
  getProCases: () => dispatch(getProCases()),
  setTableConfiguration: config => dispatch(setTableConfiguration(config)),
  makeCaseInactive: (id, name) => dispatch(makeCaseInactive(id, name)),
  setCasesSorting: (name, isAcs) => dispatch(setCasesSorting(name, isAcs)),
  navigateToCreateCase: () => dispatch(navigateToCreateCase()),
  navigateToCaseDetails: id => dispatch(navigateToCaseDetails(id)),
  navigateToEditCase: id => dispatch(navigateToEditCase(id)),
  navigateToActivitiesListInNewTab: params => dispatch(navigateToActivitiesListInNewTab(params)),
  navigateToInvoicesListInNewTab: params => dispatch(navigateToInvoicesListInNewTab(params)),
  resetCasesFilter: () => dispatch(resetCasesFilter()),
  resetCasesSorting: () => dispatch(resetCasesSorting()),
  checkProConnections: () => dispatch(checkProConnections()),
  goToZendesk: () => dispatch(goToZendesk()),
  checkProCases: () => dispatch(checkProCases()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProCasesContainer)
