import React, { useState, useMemo } from 'react'
import { Select, Icon, SvgIcon, Button, Autocomplete } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'
import { ReactComponent as IconFilter } from 'static/icon_filter.svg'
import { ReactComponent as IconSort } from 'static/icon_24_sort.svg'
import { colors } from '../../constants/colors'
import { useSelector, useDispatch } from 'react-redux'
import { showFilter, showSort } from 'store/actions/common'
import ProfileSort from './ProfileSort'
import classnames from 'classnames'

const MENU_ITEM_ICON_WIDTH = 35
const useStyles = makeStyles({
  select: {
    minWidth: 172,
    fontSize: 14,
    zIndex: 1,
    color: colors.BASIC.COLOR[80],
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    width: 24,
    height: 24,
    fontSize: 18,
    position: 'absolute',
    right: 10,
    zIndex: 0,
  },
  menuItem: {
    paddingLeft: MENU_ITEM_ICON_WIDTH,
  },
  selectedItemIcon: {
    fontSize: 14,
    marginLeft: -MENU_ITEM_ICON_WIDTH,
    width: MENU_ITEM_ICON_WIDTH,
    textAlign: 'center',
    paddingTop: 5,
    color: colors.SUCCESS,
  },
  listItem: {
    marginTop: 0,
    color: colors.BASIC.COLOR[80],
  },
  sortingParam: {
    fontWeight: 500,
  },
  btn: {
    display: 'none',
  },
  selectFilter: {
    display: 'none',
  },
  filters: {
    display: 'none',
  },
  '@media(max-width: 939px)': {
    select: {
      display: 'none',
    },
    selectIcon: {
      display: 'none',
      outlineStyle: 'none',
    },
    listItem: {
      display: 'none',
    },
    selectFilter: {
      display: 'flex',
      width: 24,
      height: 24,
      fontSize: 18,
      position: 'absolute',
      right: 10,
      zIndex: 0,
      border: 'none',
      color: colors.WHITE,
    },
    btn: {
      display: 'flex',
      flexDirection: 'row',
      color: colors.BASIC.COLOR[80],
      width: '110px',
    },
    btnsConfig: {
      display: 'flex',
      flexDirection: 'row',
    },
    btnNone: {
      display: 'none',
    },
    showSortField: {
      display: 'flex',
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1205,
    },
    filters: {
      width: 400,
      backgroundColor: colors.WHITE,
      overflowY: 'auto',
    },
  },
  '@media(max-width: 767px)': {
    filters: {
      width: '100%',
    },
  },
  dropdown: {
    width: 240,
  },
  textField: {
    backgroundColor: colors.BASIC.COLOR['5'],
    color: colors.BLACK,
  },
  inputButtons: {
    backgroundColor: colors.BASIC.COLOR['5'],
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['0'],
    },
  },
})

const SORTING_OPTIONS = [
  {
    value: '-rating',
    label: 'highest star ranking',
  },
  {
    value: '-user_practice_areas__hourly_rate',
    label: 'highest standard hourly rate',
  },
  {
    value: 'user_practice_areas__hourly_rate',
    label: 'lowest standard hourly rate',
  },
  {
    value: 'free_time',
    label: 'earliest availability',
  },
]

const Arrow = () => {
  const classes = useStyles()
  return <SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.arrowIcon} />
}

const ProfileSearchSorting = props => {
  const { value, setSorter, loadProfiles } = props
  const classes = useStyles()
  const stateSort = useSelector(state => state.common.showSort)

  const handleSorting = (event, v) => {
    setSorter(v ? v.value : '')
    loadProfiles()
  }

  const dispatch = useDispatch()
  return (
    <div>
      <div className={classes.btnNone}>
        <Autocomplete
          value={value}
          onChange={handleSorting}
          variant="outlined"
          options={SORTING_OPTIONS}
          popupIcon={<Arrow />}
          fullWidth
          className={classes.dropdown}
          placeholder="sort by"
          classes={{
            popupIndicator: classes.inputButtons,
            clearIndicator: classes.inputButtons,
          }}
          InputProps={{
            classes: {
              root: classes.textField,
            },
          }}
        />
      </div>
      <div className={classnames({ [classes.showSortField]: stateSort }, classes.filters)}>
        <ProfileSort options={SORTING_OPTIONS} setSorter={setSorter} loadProfiles={loadProfiles} value={value} />
      </div>
      <div className={classes.btnsConfig}>
        <Button variant="text" classes={{ root: classes.btn }} onClick={() => dispatch(showFilter(true))}>
          <SvgIcon component={IconFilter} viewBox="0 0 24 24" className={classes.selectFilter} />
          Filter
        </Button>
        <Button variant="text" classes={{ root: classes.btn }} onClick={() => dispatch(showSort(true))}>
          <SvgIcon component={IconSort} viewBox="0 0 24 24" className={classes.selectFilter} />
          Sort
        </Button>
      </div>
    </div>
  )
}

export default ProfileSearchSorting
