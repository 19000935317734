import React from 'react'
import { ActivitiesFilterButtons, Autocomplete, DateRangePickerComponent, TableResetFilters } from 'components'
import {
  getProActivities,
  resetActivitiesFilter,
  resetActivitiesSorting,
  setActivitiesFilter,
  setTableConfiguration,
} from 'store/actions/activities'
import { colors } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectConnectionOptionsWithEmptyState } from '../../store/selectors/connectionsSelector'
import { selectCasesOptionsWithEmptyState } from '../../store/selectors/caseSelector'

const invoiceFilterOptions = [
  {
    value: true,
    label: 'Generated',
  },
  {
    value: false,
    label: 'Not generated',
  },
  {
    value: null,
    label: 'All',
  },
]

const billableFilterOptions = [
  {
    value: false,
    label: 'Billable',
  },
  {
    value: true,
    label: 'Not Billable',
  },
]

const useStyles = makeStyles({
  additionalFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #f0efee',
    backgroundColor: colors.WHITE,
  },
  additionalFilter: {
    display: 'flex',
    marginBottom: 30,
  },
  lgFilter: {
    marginLeft: 12,
    width: 403,
  },
  mdFilter: {
    marginLeft: 12,
    width: 240,
  },
  dateRangeFilter: {
    marginRight: 12,
  },
  rangeInput: {
    width: 240,
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  result: {
    fontWeight: 'normal',
    fontSize: 15,
  },
  clearFilterBtn: {
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[70],
  },
  clearFilterIcon: {
    marginLeft: 6,
  },
})

const AdditionalActivityFilters = ({ filters, results, isInitialFilters }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clientFilterOptions = useSelector(selectConnectionOptionsWithEmptyState)
  const casesFilterOptions = useSelector(selectCasesOptionsWithEmptyState)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  const onFilterChange = (name, value) => {
    dispatch(setActivitiesFilter(name, value))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProActivities())
  }

  const clearFilters = () => {
    dispatch(resetActivitiesFilter())
    dispatch(resetActivitiesSorting())
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProActivities())
  }
  return (
    <div className={classes.additionalFilterContainer}>
      <div className={classes.additionalFilter}>
        <ActivitiesFilterButtons
          activityTypeFilter={filters.activityType}
          changeActivityTypeFilter={value => onFilterChange('activityType', value)}
        />
        <Autocomplete
          fullWidth
          placeholder="Client"
          label="Client"
          variant="outlined"
          className={classes.mdFilter}
          value={filters.client}
          onChange={(e, v) => onFilterChange('client', v ? v.value : null)}
          options={clientFilterOptions}
        />
        <Autocomplete
          fullWidth
          placeholder=" All Cases"
          label="Cases"
          variant="outlined"
          className={classes.mdFilter}
          value={filters.case}
          onChange={(e, v) => onFilterChange('case', v ? v.value : null)}
          options={casesFilterOptions}
        />
        <Autocomplete
          fullWidth
          placeholder="Invoice generation status"
          label="Invoice generation status"
          options={invoiceFilterOptions}
          variant="outlined"
          disableClearable
          className={classes.mdFilter}
          value={filters.invoiceGenerationStatus}
          onChange={(e, v) => onFilterChange('invoiceGenerationStatus', v ? v.value : null)}
        />
      </div>
      <div className={classes.additionalFilter}>
        {/*<Autocomplete*/}
        {/*  fullWidth*/}
        {/*  placeholder="Billable status"*/}
        {/*  label="Billable status"*/}
        {/*  variant="outlined"*/}
        {/*  disableClearable*/}
        {/*  options={billableFilterOptions}*/}
        {/*  className={classes.mdFilter}*/}
        {/*  value={filters.billableStatus}*/}
        {/*  onChange={(e, v) => onFilterChange('billableStatus', v ? v.value : null)}*/}
        {/*/>*/}
        <DateRangePickerComponent
          containerClasses={classes.dateRangeFilter}
          range={filters.entryRange}
          inputClasses={classes.rangeInput}
          updateRange={value => onFilterChange('entryRange', value)}
          label="Entry created"
          timezone={timezone}
        />
        <DateRangePickerComponent
          containerClasses={classes.dateRangeFilter}
          inputClasses={classes.rangeInput}
          range={filters.invoicesRange}
          updateRange={value => onFilterChange('invoicesRange', value)}
          label="Invoice created"
          timezone={timezone}
        />
      </div>
      <TableResetFilters
        count={results}
        onClearFilters={clearFilters}
        showClearFilters={!isInitialFilters}
        containerClass={classes.resultContainer}
      />
    </div>
  )
}

export default AdditionalActivityFilters
