import React from 'react'
import {
  Typography,
  ExpansionPanelProAccount,
  PracticeAreasExpansionPanelSummary,
  PracticeAreasExpansionPanelDetail,
} from 'components'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  proPracticeAreaShortViewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 70,
  },
  proPracticeAreaCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  proPracticeAreaColPrice: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  freeCost: {
    color: colors.BASIC.COLOR[100],
  },
  cost: {
    fontWeight: 500,
  },
  label: {
    marginTop: 0,
    marginBottom: 7,
  },
  labelName: {
    marginTop: 0,
    marginBottom: 7,
    textAlign: 'left',
  },
  rate: {
    fontWeight: 'bold',
    color: colors.BASIC.COLOR[100],
  },
  freeText: {
    fontWeight: 'bold',
  },
  '@media(min-width: 768px) and (max-width: 939px)': {
    proPracticeAreaShortViewContainer: {
      margin: '-40px 0 0 100px',
      width: 'calc(100% / 1.4)',
      justifyContent: 'space-between',
      fontSize: '12px',
      paddingRight: 10,
    },
  },
  '@media (max-width: 767px)': {},
})

const PracticeAreasShortView = ({ practiceAreas, isUploaded }) => {
  const classes = useStyles()

  return (
    <div className={classes.proPracticeAreaShortViewContainer}>
      <div className={classes.proPracticeAreaCol}>
        <Typography variant="subtitle2" className={classes.labelName}>
          initial consultation
        </Typography>
        <Typography variant="subtitle2" className={classes.labelName}>
          standard rate
        </Typography>
      </div>
      <div className={classes.proPracticeAreaColPrice}>
        {isUploaded ? (
          <Typography variant="subtitle2" className={classNames(classes.label, classes.rate)}>
            unavailable
          </Typography>
        ) : (
          <Typography
            variant="subtitle2"
            className={classNames(
              classes.label,
              { [classes.freeCost]: !practiceAreas[0].consultation_cost },
              { [classes.cost]: practiceAreas[0].consultation_cost }
            )}
          >
            {practiceAreas[0].consultation_cost ? (
              `$${practiceAreas[0].consultation_cost} / 30 min`
            ) : (
              <>
                <span className={classes.freeText}>no charge</span>/ 30 min
              </>
            )}
          </Typography>
        )}
        {isUploaded ? (
          <Typography variant="subtitle2" className={classNames(classes.label, classes.rate)}>
            unavailable
          </Typography>
        ) : (
          <Typography variant="subtitle2" className={classNames(classes.label, classes.cost)}>
            <span className={classes.rate}>${practiceAreas[0].hourly_rate}</span>/ hour
          </Typography>
        )}
      </div>
    </div>
  )
}

export default PracticeAreasShortView
