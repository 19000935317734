import React from 'react'
import Link from '@material-ui/core/Link'

const XLink = ({ children, classes, underline = 'hover', ...props }) => (
  <Link classes={classes} underline={underline} {...props}>
    {children}
  </Link>
)

export default XLink
