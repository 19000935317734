import React from 'react'
import { Modal } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import VaultPlans from './VaultPlans'
import { connect, useDispatch } from 'react-redux'
import { setVaultVaultPlanModalOpen } from '../../store/actions/vault'

const useStyles = makeStyles({
  modal: {
    padding: '70px 258px 137px 258px',
  },
})

const VaultPlansModal = ({ isOpen, openVaultModal, onClose }) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <VaultPlans openVaultModal={openVaultModal} />
      </div>
    </Modal>
  )
}

export default VaultPlansModal
