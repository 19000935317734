import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'components'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  sectionWrapper: {
    color: colors.BASIC.COLOR[100],
    marginBottom: 28,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.6,
  },
  subtitle: {
    maxWidth: 360,
    margin: '4px 0',
    fontWeight: 'normal',
    fontSize: 13,
    color: colors.BASIC.COLOR[70],
    lineHeight: 1.46,
  },
  children: {
    width: 360,
  },
})

const AdvancedSection = ({ title, subtitle, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.sectionWrapper}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {subtitle}
      </Typography>
      <div className={classes.children}>{children}</div>
    </div>
  )
}

export default AdvancedSection
