import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { getMeetingInfo } from 'store/actions/meeting'
import NoPhoto from 'static/search/pro_no photo.png'
import {
  Avatar,
  Button,
  ColorCircularProgress,
  Link,
  MeetingCountdown,
  Typography,
  NotFound,
  Tooltip,
} from 'components'
import { ReactComponent as ZoomLogo } from 'static/Zoom_Logo.svg'
import { ReactComponent as Shield } from 'static/ic_shield.svg'
import moment from 'moment-timezone'
import { getTimezoneLabel } from 'utils/getters/timezone'
import { CONSTANTS, colors } from 'constants/index'
import { getSocketMeetingPath } from 'utils/path-helpers/api'
import { getJWTLocally } from 'utils/auth'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { WebsocketService } from 'utils/service/WebsocketService'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 12,
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'noWrap',
    marginBottom: 24,
  },
  labels: {
    textAlign: 'end',
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  values: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  timeText: {
    fontSize: 15,
  },
  dataText: {
    fontWeight: 500,
  },
  avatar: {
    height: 150,
    width: 150,
    margin: '0 auto 14px',
    backgroundColor: colors.BASIC.COLOR[80],
  },
  avatarsContainer: {
    display: 'flex',
    marginBottom: 24,
  },
  userInfoContainer: {
    margin: '0 55px 0 55px',
    textAlign: 'center',
    maxWidth: 180,
  },
  timerContainer: {
    height: 40,
    marginBottom: 20,
  },
  buttonContainer: {
    marginBottom: 24,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    maxWidth: 1280,
    marginBottom: 25,
  },
  zoomInfo: {
    display: 'flex',
    width: 400,
    alignItems: 'center',
  },
  zoomLogo: {
    marginRight: 8,
  },
  zoomInfoDesc: {
    display: 'flex',
  },
  phoneContainer: {
    marginRight: 15,
  },
  phoneInfoTitle: {
    marginBottom: 4,
  },
  link: {
    color: colors.BASIC.COLOR[100],
    textDecoration: 'underline',
    transition: 'linear .2s',
    fontSize: 13,
  },
  meetingIdText: {
    color: colors.SUCCESS,
    fontWeight: 500,
  },
  meetingIdPlaceholder: {
    color: colors.PRIMARY.COLOR[50],
    fontWeight: 500,
  },
  joinButton: {
    padding: '9px 51px',
    minHeight: 40,
  },
  clientName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  phoneNumber: {
    color: colors.BLUE_20,
    fontWeight: 500,
  },
  btnDisabled: {
    '&.Mui-disabled': {
      color: colors.BASIC.COLOR[10],
      backgroundColor: colors.BASIC.COLOR[20],
    },
  },
  dataContainer: {
    marginLeft: 8,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7,
  },
  hint: {
    marginLeft: 4,
    color: colors.BASIC.COLOR[70],
    fontSize: 20,
    cursor: 'pointer',
    lineHeight: '1.29',
    transition: '.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  toolTip: {
    maxWidth: 440,
    padding: '16px 16px',
    backgroundColor: colors.WHITE,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    fontSize: 13,
  },
  routerLink: {
    color: colors.BASIC.COLOR[100],
    fontSize: 13,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  policy: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  shieldWrap: {
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    marginRight: 8,
  },
  toolTipDashed: {
    height: '1em',
    borderBottom: '1px dotted',
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    textDecoration: 'none',
    lineHeight: '1.05em',
    cursor: 'pointer',
    transition: 'linear .2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  timeInfo: {
    marginBottom: 24,
    textAlign: 'center',
  },
  startTime: {
    display: 'flex',
    marginBottom: 5,
  },
  durationText: {
    fontWeight: 500,
    marginLeft: 3,
  },
  timeZone: {
    fontSize: 12,
    color: colors.BASIC.COLOR[70],
  },
  contactInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 400,
  },
  '@media(max-width:939px)': {
    timerContainer: {
      marginBottom: 15,
    },
    footerContainer: {
      width: '100%',
    },
    zoomInfo: {
      width: 'auto',
    },
    contactInfo: {
      minWidth: 370,
    },
    avatar: {
      height: 150,
      width: 150,
    },
  },
  '@media(max-width:767px)': {
    title: {
      fontSize: 20,
      textAlign: 'center',
    },
    timeText: {
      lineHeight: 1.47,
    },
    dataText: {
      fontSize: 15,
      lineHeight: 1.47,
    },
    durationText: {
      fontSize: 15,
      lineHeight: 1.47,
    },
    avatarsContainer: {
      width: '100%',
      maxWidth: 280,
      justifyContent: 'space-between',
    },
    userInfoContainer: {
      margin: 0,
    },
    avatar: {
      height: 80,
      width: 80,
      marginBottom: 10,
    },
    lawyerName: {
      fontSize: 15,
    },
    clientName: {
      fontSize: 15,
    },
    footerContainer: {
      flexDirection: 'column-reverse',
      maxWidth: 290,
    },
    contactInfo: {
      flexDirection: 'column',
      marginBottom: 12,
    },
    phoneContainer: {
      marginBottom: 12,
    },
  },
  zoomTitle: {
    fontSize: 13,
    color: colors.BASIC.COLOR[100],
  },
})

const XiraTermsOfService = () => {
  const classes = useStyles()
  return (
    <>
      {`XIRA takes the security of online meetings very seriously.
        All meetings are conducted through encrypted connections and utilize a unique password
        specific to each meeting  that is issued just prior to the meeting.
        Recording of online meetings is prohibited and constitutes a violation of `}
      <RouterLink to="/user-agreement" target="_blank" className={classes.routerLink}>
        XIRA’s Terms of Service
      </RouterLink>
      {` and may also be a violation of your state's law.`}
    </>
  )
}

const getTitle = status => {
  switch (status) {
    case CONSTANTS.MEETING_STATUS.STARTED:
      return 'The meeting is in progress'
    case CONSTANTS.MEETING_STATUS.DELETED:
      return 'The meeting is over, thank you for using XIRA!'
    case CONSTANTS.MEETING_STATUS.WAITING:
    default:
      return 'welcome to your XIRA meeting!'
  }
}

const getDuration = duration => {
  if (!duration) {
    return ''
  }
  const time = moment(duration, 'hh:mm:ss').add(1, 'second')
  const hours = time.hour()
  const minutes = time.minute()
  return `${hours ? `${hours} hour ` : ''} ${minutes ? `${minutes} minutes ` : ''}`
}

const getMeetingStart = (startTime, timezone) => {
  if (!startTime) {
    return ''
  }
  const date = moment(startTime).tz(timezone)
  const day = date.format(CONSTANTS.DATE_FORMAT.FORMAT_TWO)
  const time = date.format('h:mm a')
  return `${day} at ${time}`
}

const getMeetingStartWording = status => {
  switch (status) {
    case CONSTANTS.MEETING_STATUS.STARTED:
      return 'the meeting started'
    case CONSTANTS.MEETING_STATUS.DELETED:
      return ''
    case CONSTANTS.MEETING_STATUS.WAITING:
    default:
      return 'the meeting will start on'
  }
}

const MeetingContainer = () => {
  const classes = useStyles()
  const { meetingId } = useParams()
  const showNotFound = useSelector(state => state.common.showNotFound)
  const dispatch = useDispatch()
  const { meetingInfo, isMeetingInfoLoading } = useSelector(state => state.meeting)
  const timezonesList = useSelector(state => state.common.timezonesList)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  useEffect(() => {
    dispatch(getMeetingInfo(meetingId))
  }, [meetingId])

  useEffect(() => {
    if (meetingInfo.startTime) {
      const diff = moment(meetingInfo.startTime).diff(moment()) / 1000 / 60
      Analytics.track(EVENTS.VISIT_LOBBY, {
        minutes_before_event: diff,
      })
    }
  }, [meetingInfo.startTime])

  useEffect(() => {
    const token = getJWTLocally()
    const subject = new WebsocketService(getSocketMeetingPath(meetingId, token))
    subject.subscribe(msg => {
      if (msg.type === 'zoom_meeting') {
        dispatch(getMeetingInfo(meetingId, false))
      }
    })
    return () => {
      subject.unsubscribe()
    }
  }, [meetingId])

  const onOpenZoomMeeting = () => {
    window.open(meetingInfo.joinUrl, '_blank')
  }

  return isMeetingInfoLoading ? (
    <ColorCircularProgress />
  ) : showNotFound ? (
    <NotFound />
  ) : (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.title}>
        {getTitle(meetingInfo.meetingStatus)}
      </Typography>
      <div className={classes.timeInfo}>
        <Typography variant="subtitle1" className={classes.timeText}>
          {getMeetingStartWording(meetingInfo.meetingStatus)}
        </Typography>
        <div className={classes.startTime}>
          <Typography variant="subtitle1" className={classes.dataText}>
            {getMeetingStart(meetingInfo.startTime, timezone || moment.tz.guess())} &#8226;
          </Typography>
          <Typography variant="subtitle1" className={classes.durationText}>
            {getDuration(meetingInfo.duration)}
          </Typography>
        </div>
        <Typography variant="subtitle2" className={classes.timeZone}>
          {getTimezoneLabel(timezonesList, timezone || moment.tz.guess())}
        </Typography>
      </div>
      <div className={classes.avatarsContainer}>
        <div className={classes.userInfoContainer}>
          <Avatar className={classes.avatar} src={meetingInfo.proPhoto || NoPhoto} />
          <Typography variant="body2">lawyer</Typography>
          <Typography variant="h4" className={classes.lawyerName}>
            {meetingInfo.proName}
          </Typography>
        </div>
        <div className={classes.userInfoContainer}>
          <Avatar className={classes.avatar} src={meetingInfo.clientPhoto || NoPhoto} />
          <Typography variant="body2">client</Typography>
          <Typography variant="h4" className={classes.clientName}>
            {meetingInfo.clientName}
          </Typography>
        </div>
      </div>
      {meetingInfo.meetingStatus !== CONSTANTS.MEETING_STATUS.DELETED && (
        <>
          <MeetingCountdown startTime={meetingInfo.startTime} containerClass={classes.timerContainer} />
          <div className={classes.buttonContainer}>
            <Button
              variant="primary"
              disabled={meetingInfo.meetingStatus !== CONSTANTS.MEETING_STATUS.STARTED}
              className={classes.joinButton}
              classes={{ containedPrimary: classes.btnDisabled }}
              onClick={onOpenZoomMeeting}
            >
              Join meeting
            </Button>
          </div>
          <div className={classes.footerContainer}>
            <div>
              <div className={classes.policy}>
                <div className={classes.shieldWrap}>
                  <Shield className={classes.shield} />
                </div>
                <Tooltip tooltipClass={classes.toolTip} title={<XiraTermsOfService />} interactive>
                  <Typography variant="body2" className={classes.toolTipDashed}>
                    online meeting security policy
                  </Typography>
                </Tooltip>
              </div>
              <div className={classes.zoomInfo}>
                <ZoomLogo className={classes.zoomLogo} />
                <div className={classes.zoomInfoDesc}>
                  <Typography variant="body2" className={classes.zoomTitle}>
                    please{' '}
                    <Link href="https://zoom.us/download" target="_blank" className={classes.link}>
                      install zoom
                    </Link>{' '}
                    for a better
                    <br />
                    user experience
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.contactInfo}>
              <div className={classes.phoneContainer}>
                <Typography variant="body1" className={classes.phoneInfoTitle}>
                  to join a meeting by phone
                </Typography>
                <div className={classes.phoneNumberContainer}>
                  <Typography className={classes.phoneNumber} variant="body2">
                    +1 669 900 6833
                  </Typography>
                  <Typography className={classes.phoneNumber} variant="body2">
                    +1 646 876 9923
                  </Typography>
                </div>
              </div>
              <div className={classes.meetingContainer}>
                <Typography variant="body1" className={classes.phoneInfoTitle}>
                  meeting ID
                </Typography>
                <div className={classes.meetingIdContainer}>
                  {meetingInfo.meetingId ? (
                    <Typography variant="body2" className={classes.meetingIdText}>
                      {meetingInfo.meetingId}
                    </Typography>
                  ) : (
                    <Typography variant="body2" className={classes.meetingIdPlaceholder}>
                      provided 10 minutes
                      <br />
                      before the meeting
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MeetingContainer
