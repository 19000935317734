import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../../constants'
import classNames from 'classnames'
import { Button, Typography, PDFViewer, Link } from 'components'
import InvoiceCreditAdjustmentDocument from '../pdf/InvoiceCreditAdjusmentDocument'
import { default as RecordTransactionModal } from 'containers/account-invoices/RecordTransactionModal'
import { ReactComponent as EditIcon } from 'static/invoices/edit.svg'
import { ReactComponent as EmptyPayments } from 'static/invoices/emptyPayments.svg'
import { getItemName } from 'utils/invoice/invoice-helpers'

const useStyles = makeStyles({
  invoicePaymentsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  emptyInvoicePaymentsContainer: {
    marginTop: 58,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTitle: {
    fontSize: 15,
    fontWeight: 'normal',
    marginTop: 23,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    margin: '0 24px',
  },
  invoicePDFPaymentsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentTitle: {
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 13,
    fontWeight: 'normal',
    '&:hover': {
      color: colors.BLUE_20,
    },
  },
  summaryList: {
    listStyleType: 'square',
  },
  summaryListItem: {
    marginBottom: 17,
    '&:hover': {
      color: colors.BLUE_20,
    },
  },
  creditSummaryListItem: {
    color: colors.BLUE_20,
  },
  summaryName: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  summaryListContainer: {
    marginTop: 16,
    marginLeft: 20,
    width: 300,
  },
  editBtn: {
    minWidth: 0,
    padding: 0,
    fill: colors.BASIC.COLOR['70'],
    '&:hover': {
      fill: colors.BLUE_20,
    },
  },
  paymentLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
})

const InvoicePayments = ({ summary, invoice, timezone, isClient }) => {
  const classes = useStyles()
  const [updatePayment, setUpdatePayment] = useState(null)
  return (
    <>
      {summary.length ? (
        <div className={classes.invoicePaymentsContainer}>
          <div className={classes.invoicePDFPaymentsContainer}>
            {summary.map((credit, i) => (
              <div id={credit.generated_payment_record_number} key={credit.number}>
                <PDFViewer
                  document={<InvoiceCreditAdjustmentDocument credit={credit} invoice={invoice} timezone={timezone} />}
                />
              </div>
            ))}
          </div>
          <div className={classes.contentContainer}>
            <h3 className={classes.contentTitle}>Content</h3>
            <div className={classes.summaryListContainer}>
              <ul className={classes.summaryList}>
                {summary.map(summaryItem => (
                  <li
                    key={summaryItem.uuid}
                    className={classNames(classes.summaryListItem, {
                      [classes.creditSummaryListItem]: summaryItem.type === 'C',
                    })}
                  >
                    <div className={classes.summaryRow}>
                      <Link href={`#${summaryItem.generated_payment_record_number}`} className={classes.paymentLink}>
                        {`${getItemName(summaryItem.type)} ${summaryItem.generated_payment_record_number} ${
                          summaryItem.is_void ? '– VOID' : ''
                        }`}
                      </Link>
                      {!summaryItem.is_void && !isClient && summaryItem.can_edit && (
                        <Button
                          variant={'text'}
                          onClick={() => setUpdatePayment(summaryItem)}
                          className={classes.editBtn}
                        >
                          <EditIcon />
                        </Button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {!!updatePayment && (
            <RecordTransactionModal
              open={!!updatePayment}
              onClose={() => setUpdatePayment(null)}
              invoice={invoice}
              viewType="view"
              payment={updatePayment}
            />
          )}
        </div>
      ) : (
        <div className={classes.emptyInvoicePaymentsContainer}>
          <EmptyPayments />
          <Typography className={classes.emptyTitle}>There are no payments for this invoice</Typography>
        </div>
      )}
    </>
  )
}

export default InvoicePayments
