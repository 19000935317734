import React from 'react'
import { Button, Icon, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  result: {
    fontWeight: 'normal',
    fontSize: 15,
  },
  clearFilterBtn: {
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[70],
    marginLeft: 12,
    padding: 5,
  },
  clearFilterIcon: {
    marginLeft: 6,
  },
})

const TableResetFilters = props => {
  const classes = useStyles()
  const { count, onClearFilters, containerClass, showClearFilters = true } = props
  return (
    <div className={containerClass}>
      <Typography className={classes.result}>Results: {count}</Typography>
      {showClearFilters && (
        <Button className={classes.clearFilterBtn} variant="text" onClick={onClearFilters}>
          Clear filters <Icon style={classes.clearFilterIcon} iconClass="las la-times" />
        </Button>
      )}
    </div>
  )
}

export default TableResetFilters
