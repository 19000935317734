import React, { useEffect, useState } from 'react'
import { Typography, MeetingSchedulerStepper, Divider, MeetingSchedulerBodyBuilder, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

const mockCountries = [
  {
    label: 'USA',
    value: 840,
    icon: (
      <SvgIcon>
        <path fill="#F0F0F0" d="M16 2.667H0v10.666h16V2.667z" />
        <path
          fill="#D80027"
          d="M16 4H0v1.333h16V4zM16 6.667H0V8h16V6.667zM16 9.333H0v1.333h16V9.333zM16 12H0v1.333h16V12z"
        />
        <path fill="#2E52B2" d="M8 2.667H0V8.41h8V2.667z" />
        <path
          fill="#F0F0F0"
          d="M3.12 5.02l-.129.396h-.417l.337.245-.128.396.337-.245.337.245-.129-.396.337-.245h-.417L3.12 5.02zM3.248 6.846L3.12 6.45l-.129.396h-.417l.337.245-.128.396.337-.244.337.244-.129-.396.337-.245h-.417zM1.487 6.846l-.129-.396-.129.396H.812l.338.245-.13.396.338-.244.337.244-.129-.396.337-.245h-.416zM1.358 5.02l-.129.396H.812l.338.245-.13.396.338-.245.337.245-.129-.396.337-.245h-.416l-.129-.396zM3.12 3.59l-.129.395h-.417l.337.245-.128.397.337-.245.337.245-.129-.397.337-.245h-.417L3.12 3.59zM1.358 3.59l-.129.395H.812l.338.245-.13.397.338-.245.337.245-.129-.397.337-.245h-.416l-.129-.396zM4.881 5.02l-.128.396h-.417l.337.245-.129.396.338-.245.337.245-.13-.396.338-.245H5.01l-.128-.396zM5.01 6.846l-.128-.396-.13.396h-.416l.337.245-.129.396.338-.244.337.244-.13-.396.338-.245H5.01zM6.77 6.846L6.64 6.45l-.129.396h-.416l.337.245-.129.396.337-.244.337.244-.129-.396.338-.245H6.77zM6.641 5.02l-.129.396h-.416l.337.245-.129.396.337-.245.337.245-.129-.396.338-.245H6.77L6.64 5.02zM4.881 3.59l-.128.395h-.417l.337.245-.129.397.338-.245.337.245-.13-.397.338-.245H5.01l-.128-.396zM6.641 3.59l-.129.395h-.416l.337.245-.129.397.337-.245.337.245-.129-.397.338-.245H6.77L6.64 3.59z"
        />
      </SvgIcon>
    ),
  },
]
const useStyles = makeStyles({
  meetingSchedulerClientInfo: {
    paddingTop: 25,
  },
  clientInfoHeader: {
    padding: '15px 0 15px 24px',
  },
  clientInfoConfirmationHeader: {
    padding: '15px 0 0 24px',
  },
  bodyTitle: {
    fontSize: 15,
  },
  bodyTitleConfirmation: {
    fontSize: 20,
    fontWeight: 500,
  },
  '@media(max-width:939px)': {
    meetingSchedulerClientInfo: {
      paddingTop: 24,
    },
    clientInfoHeader: {
      padding: '0 24px 15px',
    },
    clientInfoConfirmationHeader: {
      padding: '0 24px',
    },
  },
  '@media(max-width:767px)': {
    meetingSchedulerClientInfo: {
      paddingTop: 20,
    },
    meetingSchedulerDivider: {
      display: 'none',
    },
    clientInfoHeader: {
      padding: '0 15px 6px',
      margin: '0 auto',
      width: '100%',
      maxWidth: 400,
    },
    clientInfoConfirmationHeader: {
      padding: '0 15px',
      margin: '0 auto',
      width: '100%',
      maxWidth: 400,
    },
  },
})
const MeetingSchedulerClientInfo = ({
  onSubmit,
  onCancel,
  setPaymentCard,
  states,
  contactInfo,
  proAccount,
  start,
  end,
  practiceAreaValue,
  practiceArea,
  userCards,
  paymentCard,
  isSubmitMeetingData,
  stripeError,
  cancellationFee,
  initialPrice,
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [completedStep, setCompletedStep] = useState([])
  useEffect(() => {
    const completedSteps = []
    const incompletedSteps = []
    if (practiceAreaValue) {
      completedSteps.push(0)
    } else {
      incompletedSteps.push(0)
    }
    if (contactInfo.street && contactInfo.city && contactInfo.state && contactInfo.zip && contactInfo.phone) {
      completedSteps.push(1)
    } else {
      incompletedSteps.push(1)
    }
    if (userCards.length) {
      completedSteps.push(2)
    } else {
      incompletedSteps.push(2)
    }
    incompletedSteps.push(3)
    if (!activeStep && !completedStep.length) setActiveStep(incompletedSteps[0])
    setCompletedStep(completedSteps)
  }, [activeStep, contactInfo, userCards])
  return (
    <div className={classes.meetingSchedulerClientInfo}>
      {initialPrice ? (
        <>
          <div className={classes.clientInfoHeader}>
            <Typography className={classes.bodyTitle}>Setup meeting</Typography>
            <MeetingSchedulerStepper
              activeStep={activeStep}
              completedStep={completedStep}
              setActiveStep={setActiveStep}
            />
          </div>
          <Divider classes={{ root: classes.meetingSchedulerDivider }} />
        </>
      ) : (
        <div className={classes.clientInfoConfirmationHeader}>
          <Typography className={classes.bodyTitleConfirmation}>Confirmation</Typography>
        </div>
      )}
      <MeetingSchedulerBodyBuilder
        activeStep={activeStep}
        countries={mockCountries}
        states={states}
        contactInfo={contactInfo}
        setActiveStep={setActiveStep}
        setCompletedStep={setCompletedStep}
        practiceArea={practiceArea}
        start={start}
        end={end}
        proAccount={proAccount}
        userCards={userCards}
        onCancel={onCancel}
        onSubmit={onSubmit}
        paymentCard={paymentCard}
        setPaymentCard={setPaymentCard}
        isSubmitMeetingData={isSubmitMeetingData}
        stripeError={stripeError}
        cancellationFee={cancellationFee}
        initialPrice={initialPrice}
      />
    </div>
  )
}

export default MeetingSchedulerClientInfo
