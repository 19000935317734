import React, { useRef } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { routes } from 'constants/routes'
import { WorkingHoursContainer, CalendarConnections, CalendarAdvanced } from 'containers'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: colors.WHITE,
    paddingTop: 35,
  },
})

const scrollToRef = ref => {
  ref.current.scrollTo(0, 0)
}

const Calendar = () => {
  const classes = useStyles()
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  return (
    <div ref={myRef} className={classes.root}>
      <Route path={`${routes.getProAccountCalendarPath()}/connections`} component={CalendarConnections} />
      <Route
        path={`${routes.getProAccountCalendarPath()}/working-hours`}
        render={() => <WorkingHoursContainer executeScroll={executeScroll} />}
      />
      <Route path={`${routes.getProAccountCalendarPath()}/timezones`} component={CalendarAdvanced} />
    </div>
  )
}

export default withRouter(Calendar)
