import React from 'react'
import { Typography, Icon, Button } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../constants'

const { ERROR } = colors

const useStyles = makeStyles({
  passwordUpdatedInfoContainer: {
    margin: '32px 40px',
    display: 'flex',
    flexDirection: 'column',
  },
  passwordUpdateTitle: {
    marginBottom: 16,
  },
  successPasswordUpdateIcon: {
    color: ERROR,
    fontSize: 32,
    marginRight: 8,
  },
  passwordUpdateBtn: {
    alignSelf: 'flex-end',
  },
})

const FailPasswordUpdate = ({ submit }) => {
  const classes = useStyles()
  return (
    <div className={classes.passwordUpdatedInfoContainer}>
      <Typography className={classes.passwordUpdateTitle} variant="h4">
        <Icon style={classes.successPasswordUpdateIcon} iconClass="las la-exclamation-triangle" />
        Your Password Link Has Expired
      </Typography>
      <Typography variant="subtitle1">
        The link you've used has expired, please try to reset your password once again.
      </Typography>
      <Button variant="primary" type="submit" className={classes.passwordUpdateBtn} onClick={submit}>
        Request another link
      </Button>
    </div>
  )
}

export default FailPasswordUpdate
