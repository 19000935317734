import * as CONSTANTS from 'store/constants/invoices'

const initialState = {
  filters: {
    search: '',
    client: null,
    pro: null,
    case: null,
    issuedRange: { from: null, to: null },
    invoiceStatus: null,
  },
  initialFilters: {
    search: '',
    client: null,
    pro: null,
    case: null,
    issuedRange: { from: null, to: null },
    invoiceStatus: null,
  },
  sort: {
    property: 'issued_at',
    isAsc: false,
  },
  initialSort: {
    property: 'issued_at',
    isAsc: false,
  },
  invoices: [],
  invoicesAreLoading: false,
  invoicesFileAreLoading: false,
  invoicesLength: 0,
  invoice: {},
  invoiceSummary: [],
  invoiceSummaryAreLoading: false,
  sortSummary: {
    property: 'created_at',
    isAsc: true,
  },
  invoiceIsLoading: false,
  paymentIsProcessing: false,
  tableConfiguration: {
    tablePage: 0,
    limit: 20,
    offset: 0,
  },
  uploadingInvoice: false,
}

const invoices = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_INVOICE_START:
      return {
        ...state,
        invoiceIsLoading: true,
      }
    case CONSTANTS.GET_INVOICE_END:
      return {
        ...state,
        invoiceIsLoading: false,
        invoice: action.invoice,
      }
    case CONSTANTS.GET_INVOICE_SUMMARY_START:
      return {
        ...state,
        invoiceSummaryAreLoading: true,
      }
    case CONSTANTS.GET_INVOICE_SUMMARY_END:
      return {
        ...state,
        invoiceSummaryAreLoading: false,
        invoiceSummary: action.summary,
      }
    case CONSTANTS.GET_INVOICES_START:
      return {
        ...state,
        invoicesAreLoading: true,
      }
    case CONSTANTS.GET_INVOICES_END:
      return {
        ...state,
        invoicesAreLoading: false,
        invoices: action.payload,
        invoicesLength: action.length,
      }
    case CONSTANTS.GET_INVOICES_FILE_START:
      return {
        ...state,
        invoicesFileAreLoading: true,
      }
    case CONSTANTS.GET_INVOICES_FILE_END:
      return {
        ...state,
        invoicesFileAreLoading: false,
      }
    case CONSTANTS.SET_TABLE_CONFIGURATION:
      return {
        ...state,
        tableConfiguration: {
          ...state.tableConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      }
    case CONSTANTS.SET_LIMIT:
      return {
        ...state,
        limit: action.payload,
      }
    case CONSTANTS.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      }
    case CONSTANTS.RESET_FILTERS:
      return {
        ...state,
        filters: state.initialFilters,
      }
    case CONSTANTS.RESET_SORTING:
      return {
        ...state,
        sort: state.initialSort,
      }
    case CONSTANTS.RESET_INVOICE:
      return {
        ...state,
        invoice: {},
      }
    case CONSTANTS.SET_PREDEFINED_INVOICE:
      return {
        ...state,
        invoice: {
          ...state.activity,
          [action.property]: action.value,
        },
      }
    case CONSTANTS.SET_SORTING:
      return {
        ...state,
        sort: {
          property: action.property,
          isAsc: action.isAsc,
        },
      }
    case CONSTANTS.SET_SUMMARY_SORTING:
      return {
        ...state,
        sortSummary: {
          property: action.property,
          isAsc: action.isAsc,
        },
      }
    case CONSTANTS.SET_CLIENT_PAYMENT_START:
      return {
        ...state,
        paymentIsProcessing: true,
      }
    case CONSTANTS.SET_CLIENT_PAYMENT_END:
      return {
        ...state,
        paymentIsProcessing: false,
      }
    case CONSTANTS.SET_INVOICES_FILE_START:
      return {
        ...state,
        uploadingInvoice: true,
      }
    case CONSTANTS.SET_INVOICES_FILE_END:
      return {
        ...state,
        uploadingInvoice: false,
      }
    default:
      return state
  }
}

export default invoices
