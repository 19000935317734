import React, { useEffect, useState } from 'react'
import { ColorCircularProgress, InviteRegistrationStepSelector, StyledStepper } from 'components'
import { useDispatch } from 'react-redux'
import { validateClientInvite, openSnackbar, submitInvitationFlow } from 'store/actions/common'
import { navigateToClientConnections, navigateToRoot } from 'store/actions/navigation'
import { getAuthTokenByEmail, getCurrentProfile, getCurrentUser, setSignUpEmail } from 'store/actions/auth'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
})

const getSteps = isRegistered => [
  isRegistered ? "let's get you logged in" : "let's get you signed up",
  ' provide your contact info',
]

const InviteRegistrationSteps = props => {
  const classes = useStyles()
  const { hash, userEmail, firstName, lastName } = props
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState("let's get you logged in")
  const [isRegistered, setIsRegistered] = useState(false)
  const steps = getSteps(isRegistered)

  useEffect(() => {
    validateHash()
  }, [hash, validateHash])

  const validateHash = async () => {
    setIsLoading(true)
    try {
      const isRegisteredUser = await dispatch(validateClientInvite(hash))
      setIsRegistered(isRegisteredUser)
      const { isUserLoggedIn, currentUser } = await dispatch(getCurrentUser({ withoutRedirect: true }))

      if (currentUser.role === 'pro') {
        dispatch(navigateToRoot())
        return
      }

      if (isUserLoggedIn) {
        await getInvitationStep()
      } else {
        setCurrentTab(isRegisteredUser ? "let's get you logged in" : "let's get you signed up")
        if (!isRegisteredUser) {
          dispatch(setSignUpEmail(userEmail))
        }
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      dispatch(navigateToRoot())
    }
  }

  const getInvitationStep = async () => {
    try {
      const step = await dispatch(getCurrentProfile())
      setStep(step)
    } catch (e) {
      console.error(e)
    }
  }

  const onAfterLogin = async () => {
    const { currentUser } = await dispatch(getCurrentUser({ withoutRedirect: true }))

    if (currentUser.role === 'pro') {
      dispatch(navigateToRoot())
      return
    }
    await getInvitationStep()
  }

  const onAfterSignUp = async ({ password }) => {
    try {
      await dispatch(getAuthTokenByEmail({ password: password, username: userEmail }, { withoutRedirect: true }))
      setStep(1)
    } catch (e) {
      console.error(e)
    }
  }

  const onAfterContactInfoSubmit = async () => {
    await submitConnection()
  }

  const setStep = option => {
    switch (option) {
      case 1:
        setCurrentTab(' provide your contact info')
        return
      case 0:
        submitConnection()
    }
  }

  const submitConnection = async () => {
    try {
      await dispatch(submitInvitationFlow(hash))
      dispatch(navigateToClientConnections())
      dispatch(openSnackbar('success', "Welcome to XIRA - you're now connected to your lawyer!"))
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={classes.container}>
      {isLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <StyledStepper steps={steps} activeStep={steps.indexOf(currentTab)} />
          <InviteRegistrationStepSelector
            activeStep={currentTab}
            setStep={setCurrentTab}
            onAfterLogin={onAfterLogin}
            onAfterSignUp={onAfterSignUp}
            onAfterContactInfoSubmit={onAfterContactInfoSubmit}
            firstName={firstName}
            lastName={lastName}
          />
        </>
      )}
    </div>
  )
}

export default InviteRegistrationSteps
