import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import React from 'react'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { Icon } from 'components'

const ExpandMoreIcon = <Icon iconClass="las la-angle-down" />

const XExpansionPanel = props => {
  const {
    expandIcon = ExpandMoreIcon,
    summary,
    details,
    panelClassName,
    panelClasses,
    onClick,
    expanded,
    expansionPanelSummaryClass,
    expansionPanelDetailsClass,
    onChange,
  } = props

  return (
    <ExpansionPanel classes={panelClasses} className={panelClassName} expanded={expanded} onChange={onChange}>
      <ExpansionPanelSummary classes={expansionPanelSummaryClass} expandIcon={expandIcon} onClick={onClick}>
        {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={expansionPanelDetailsClass}>{details}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default XExpansionPanel
