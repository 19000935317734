import React from 'react'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles({
  summaryRoot: {
    marginLeft: 56,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
    justifyContent: 'space-between',
  },
  columnWidth: {
    width: 270,
  },
  text: {
    fontFamily: 'san-serif, "Roboto"',
    marginBottom: 4,
  },
  bold: {
    fontWeight: 500,
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const DegreeExpansionPanelDetail = ({ degree }) => {
  const classes = useStyles()
  return (
    <div className={classes.summaryRoot}>
      <div className={classes.column}>
        <Typography className={classes.text} variant="body2">
          Field of study
        </Typography>
        <Typography className={classes.text} variant="body2">
          Degree
        </Typography>
      </div>
      <div className={classNames(classes.column, classes.columnWidth)}>
        <Typography title={degree.major} className={classes.bold} variant="body2">
          {degree.major}
        </Typography>
        <Typography title={degree.level} className={classes.bold} variant="body2">
          {degree.level}
        </Typography>
      </div>
    </div>
  )
}

export default DegreeExpansionPanelDetail
