import React from 'react'
import { Prompt, matchPath } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

const DataLossWarning = ({ path, whenCondition, currentUser }) => {
  return (
    <Prompt
      when={whenCondition && !isEmpty(currentUser)}
      message={({ pathname }) => {
        const pathMatching = matchPath(pathname, { path: path }) || matchPath(pathname, {
          path: "/",
          exact: true,
          strict: true
        })
        return pathMatching || "You haven't saved your changes.\nAre you sure you want to leave this page?"
      }}
    />
  )
}

const mapStateToProps = ({ auth }) => ({
  currentUser: auth.currentUser,
})

export default connect(mapStateToProps)(DataLossWarning)
