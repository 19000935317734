import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getUserEventDetails, rescheduleUserMeeting } from 'store/actions/events'
import {
  ColorCircularProgress,
  Avatar,
  Typography,
  CalendarView,
  MeetingTimeRange,
  RescheduleEventModal,
} from 'components'
import { getMeetingDay, getMeetingDuration, getMeetingRange } from 'utils/getters/events'
import moment from 'moment-timezone'
import { getProAccountAvailableTime, setMeetingDuration } from 'store/actions/search'
import SelectNewPaymentMethodModal from '../clientAccount/payments/SelectNewPaymentMethodModal'
import { navigateToClientDashboard } from '../../store/actions/navigation'
import NoPhoto from 'static/search/pro_no photo.png'
import { getMeetingDurationTime } from '../../utils/getters/events'

const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    boxShadow: '1px 3px 16px 0 rgba(0, 0, 0, 0.1)',
    border: `1px solid ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.WHITE,
    display: 'flex',
  },
  calendarColumn: {
    flexGrow: 1,
    position: 'relative',
    padding: '16px 24px',
  },
  contactInfoColumn: {
    borderRight: `1px solid ${colors.BASIC.COLOR[10]}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 250,
    paddingTop: 16,
  },
  avatar: {
    width: 150,
    height: 150,
    marginBottom: 16,
  },
  userName: {
    marginBottom: 7,
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    padding: '0 10px',
    textAlign: 'center',
  },
  timeSection: {
    width: '100%',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '16px 24px',
  },
  sectionSubTitle: {
    fontSize: 16,
    marginBottom: 4,
  },
  sectionText: {
    fontSize: 15,
    marginBottom: 4,
  },
  dateSlotsColumn: {
    width: 500,
    backgroundColor: colors.BASIC.COLOR[0],
    padding: '16px 24px',
    position: 'relative',
  },
  dateSlotsTitle: {
    fontSize: 18,
    fontWeight: 'normal',
  },
  notFound: {
    textAlign: 'center',
    marginTop: '10%',
  },
})

const timezone = moment.tz.guess()

const RescheduleComponent = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { eventId } = props
  const [showCalendar, setShowCalendar] = useState(false)
  const eventInfoIsLoading = useSelector(store => store.events.meetingInfoIsLoading)
  const eventInfo = useSelector(store => store.events.meetingInfo)
  const proAccountAvailableTimeIsLoading = useSelector(state => state.search.proAccountAvailableTimeIsLoading)
  const availableTime = useSelector(state => state.search.proAccountAvailableTime)
  const [selectedDate, handleDateChange] = useState(moment())
  const [minDate, setMinDate] = useState(moment())
  const [newMeeting, setNewMeeting] = useState({ startTime: null, endTime: null, timezone: timezone })
  const { proInfo, startTime, endTime, isInitial, isUpdateButtonVisible } = eventInfo
  const [openModal, setOpenModal] = useState(false)
  const [isCardSelectionModalOpen, setIsCardSelectionModalOpen] = useState(false)
  const [cardError, setCardError] = useState(false)
  const [cardInstruction, setCardInstruction] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState(eventInfo.paymentMethodId)
  const [reason, setReason] = useState('')

  useEffect(() => {
    setSelectedCardId(eventInfo.paymentMethodId)
  }, [eventInfo.paymentMethodId])

  const onModalClose = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    const getRescheduleInfo = async () => {
      if (eventId) {
        try {
          const data = await dispatch(getUserEventDetails(eventId))
          dispatch(setMeetingDuration(getMeetingDurationTime(data.startTime, data.endTime)))
          dispatch(getProAccountAvailableTime(data.pro))
        } catch (e) {
          console.error(e)
        }
      }
    }
    getRescheduleInfo()
  }, [eventId])

  useEffect(() => {
    setShowCalendar(!!Object.values(availableTime).length)
    setMinDate(moment(Object.keys(availableTime).sort()[0]))
  }, [availableTime])

  const onMonthChange = date => {
    if (date.diff(moment()) > 0) {
      setCurrentDate(date, availableTime)
    } else {
      setCurrentDate(moment(), availableTime)
    }
  }

  const setCurrentDate = (date, newAvailableTime) => {
    if (newAvailableTime[date.format('YYYY-MM-DD')]) {
      handleDateChange(date)
    } else setCurrentDate(moment(date).add(1, 'd'), newAvailableTime)
  }

  const onTimeSlotClick = time => {
    setNewMeeting({
      ...newMeeting,
      startTime: moment
        .unix(time.start)
        .utc()
        .toDate(),
      endTime: moment
        .unix(time.end)
        .utc()
        .toDate(),
    })
    setOpenModal(true)
  }

  const onDurationChange = async () => {
    dispatch(getProAccountAvailableTime(eventInfo.pro))
  }

  const onRescheduleConfirm = reason => {
    submitReschedule(reason, selectedCardId)
  }

  const onCardSelectionSubmit = paymentId => {
    submitReschedule(reason, paymentId)
  }

  const submitReschedule = async (reason, paymentId) => {
    try {
      const response = await dispatch(
        rescheduleUserMeeting(
          eventInfo.uuid,
          {
            ...newMeeting,
            startTime: moment(newMeeting.startTime).unix(),
            endTime: moment(newMeeting.endTime).unix(),
            paymentId: paymentId,
          },
          reason
        )
      )

      if (response.error && response.isStripeError) {
        setIsCardSelectionModalOpen(true)
        setOpenModal(false)
        setCardError(response.error)
        setCardInstruction(response.instruction)
        return
      }

      if (response.error) {
        setIsCardSelectionModalOpen(false)
        setOpenModal(true)
        return
      }

      setOpenModal(false)
      setIsCardSelectionModalOpen(false)
      dispatch(navigateToClientDashboard())
    } catch (e) {
      console.error(e)
    }
  }

  const onModalSelectClose = () => {
    setIsCardSelectionModalOpen(false)
    setSelectedCardId(eventInfo.paymentMethodId)
  }

  return (
    <>
      {eventInfoIsLoading ? (
        <ColorCircularProgress />
      ) : !isUpdateButtonVisible ? (
        <Typography variant="h4" className={classes.notFound}>
          Action is not available because the meeting was canceled, is ongoing, or is over
        </Typography>
      ) : (
        <>
          {proInfo && startTime && endTime && (
            <div className={classes.root}>
              <div className={classes.contactInfoColumn}>
                <Avatar src={proInfo.photo || NoPhoto} className={classes.avatar} />
                <Typography variant="h4" className={classes.userName}>
                  {proInfo.firstName} {proInfo.lastName}
                </Typography>
                <div className={classes.timeSection}>
                  <Typography variant="body1" className={classes.sectionSubTitle}>
                    current date &amp; time
                  </Typography>
                  <Typography variant="body2" className={classes.sectionText}>
                    {getMeetingDay(startTime)}
                  </Typography>
                  <Typography variant="body2" className={classes.sectionText}>
                    {getMeetingRange(startTime, endTime)}
                  </Typography>
                  <Typography variant="body2" className={classes.sectionText}>
                    {getMeetingDuration(startTime, endTime)}
                  </Typography>
                </div>
              </div>
              <div className={classes.calendarColumn}>
                <Typography variant="body1" className={classes.sectionSubTitle}>
                  select date &amp; time
                </Typography>
                {proAccountAvailableTimeIsLoading ? (
                  <ColorCircularProgress />
                ) : (
                  showCalendar && (
                    <CalendarView
                      selectedDate={selectedDate}
                      minDate={minDate}
                      onMonthChange={onMonthChange}
                      handleDateChange={handleDateChange}
                      availableTime={availableTime}
                    />
                  )
                )}
              </div>
              <div className={classes.dateSlotsColumn}>
                <Typography variant="subtitle2" className={classes.dateSlotsTitle}>
                  {getMeetingDay(selectedDate)}
                </Typography>
                {proAccountAvailableTimeIsLoading ? (
                  <ColorCircularProgress />
                ) : (
                  showCalendar && (
                    <MeetingTimeRange
                      isInitial={isInitial}
                      onDurationChange={onDurationChange}
                      isReschedule
                      onClick={onTimeSlotClick}
                      availableTimeRange={availableTime[selectedDate.format('YYYY-MM-DD')]}
                    />
                  )
                )}
              </div>
            </div>
          )}
        </>
      )}
      <RescheduleEventModal
        newEvent={newMeeting}
        open={openModal}
        currentEvent={eventInfo}
        onModalClose={onModalClose}
        onRescheduleConfirm={onRescheduleConfirm}
        reason={reason}
        setReason={setReason}
      />
      <SelectNewPaymentMethodModal
        isOpen={isCardSelectionModalOpen}
        onClose={onModalSelectClose}
        errorMessage={cardError}
        cardInstruction={cardInstruction}
        selectedCardId={selectedCardId}
        onChangeSelection={setSelectedCardId}
        onSubmit={onCardSelectionSubmit}
      />
    </>
  )
}
export default RescheduleComponent
