import React from 'react'
import classNames from 'classnames'
import { CONSTANTS } from 'constants/index'
import { FormikInput, FormikSelect, FormikAutocomplete } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContainer: {
    width: '47%',
  },
  phoneInput: {
    height: '40px !important',
  },
  selectIcon: {
    marginRight: '7px',
  },
})

const ContactInfoFormBody = ({ states, countries, updateContactInfo }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          size="small"
          name="street"
          label="Address"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
        <FormikInput
          variant="outlined"
          size="small"
          name="suite"
          required={false}
          label="Apartment, Suite, PO Box"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
      </div>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          size="small"
          name="city"
          label="City"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
        <FormikAutocomplete
          variant="outlined"
          size="small"
          name="state"
          label="State"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          options={states}
          handleChange={updateContactInfo}
          filterOptions={(options, { inputValue }) =>
            states.filter(option => option.label.toLowerCase().startsWith(inputValue.toLowerCase()))
          }
        />
      </div>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          size="small"
          name="zip"
          label="Zip сode"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
        <FormikSelect
          value={840}
          variant="outlined"
          size="small"
          name="country"
          label="Country"
          type="text"
          fullWidth
          options={countries}
          containerClasses={classes.inputContainer}
          disabled
        />
      </div>
      <div className={classNames(classes.twoColumn, classes.phoneContainer)}>
        <FormikInput
          variant="outlined"
          size="small"
          name="phone"
          label="Mobile phone"
          type={CONSTANTS.INPUT_TYPE.PHONE}
          fullWidth
          containerClasses={classes.inputContainer}
          inputClass={classes.phoneInput}
          country="us"
          handleChange={updateContactInfo}
        />
        <FormikInput
          variant="outlined"
          size="small"
          name="optionalPhone"
          label="Work phone"
          type={CONSTANTS.INPUT_TYPE.PHONE}
          required={false}
          fullWidth
          containerClasses={classes.inputContainer}
          inputClass={classes.phoneInput}
          country="us"
          handleChange={updateContactInfo}
        />
      </div>
    </>
  )
}

export default ContactInfoFormBody
