import React, { useEffect, useState } from 'react'
import {
  Button,
  ColorCircularProgress,
  ConfigureCardModal,
  Icon,
  Modal,
  Typography,
  ClientCardsRadioButtonList,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getClientPaymentSecret, getUserCards, saveUserCard } from 'store/actions/payments'

const useStyles = makeStyles({
  modal: {
    width: 648,
    minHeight: 200,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    marginBottom: 12,
    fontSize: '20px',
  },
  boxBtn: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: 4,
  },
  closeBtn: {
    marginRight: 24,
  },
  doneBtn: {
    width: 97,
  },
  header: {
    margin: '0 0 12px 0',
  },
  subtitle: {
    marginBottom: 12,
    fontSize: '16px',
  },
})

const SelectNewPaymentMethodModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpen, onClose, errorMessage, selectedCardId, onChangeSelection, onSubmit, cardInstruction } = props
  const [selectedId, setSelectedId] = useState('')
  const [isModalShown, setIsModalShown] = useState(false)
  const clientPaymentSecretKey = useSelector(state => state.payments.clientPaymentSecretKey)
  const clientSecretIsLoading = useSelector(state => state.payments.clientSecretIsLoading)
  const userCards = useSelector(state => state.payments.userCards)
  const cardsAreLoading = useSelector(state => state.payments.cardsAreLoading)

  useEffect(() => {
    dispatch(getClientPaymentSecret())
  }, [])

  useEffect(() => {
    dispatch(getUserCards(selectedCardId))
    setSelectedId(selectedCardId)
  }, [selectedCardId])

  const handleSubmit = () => {
    onChangeSelection(selectedId)
    onSubmit(selectedId)
  }

  const onModalSubmit = async data => {
    try {
      const { payment_method_id } = await dispatch(saveUserCard(data))
      await dispatch(getUserCards(payment_method_id))
      setSelectedId(payment_method_id)
      isModalShown(false)
    } catch (error) {
      dispatch(getClientPaymentSecret())
      return Promise.reject(error)
    } finally {
      setIsModalShown(false)
    }
  }

  return (
    <>
      <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
        <div className={classes.modal}>
          {cardsAreLoading ? (
            <ColorCircularProgress />
          ) : (
            <>
              {' '}
              <div className={classes.header}>
                <Typography variant="h4" className={classes.title}>
                  {errorMessage ||
                    'There is not enough money to place a hold on the selected card. Please select another payment method and try again.'}
                </Typography>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {cardInstruction || ''}
                </Typography>
              </div>
              {userCards.length && (
                <div className={classes.cardsContainer}>
                  <ClientCardsRadioButtonList userCards={userCards} value={selectedId} onChange={setSelectedId} />
                </div>
              )}
              <div className={classes.boxBtn}>
                <Button variant="secondary" onClick={() => setIsModalShown(true)}>
                  <Icon iconClass="las la-plus-circle" style={classes.icon} /> add card
                </Button>
                <div>
                  <Button variant="text" onClick={onClose} className={classes.closeBtn}>
                    cancel
                  </Button>
                  <Button variant="primary" onClick={handleSubmit} circularSize={22} className={classes.doneBtn}>
                    Continue
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      <ConfigureCardModal
        isOpen={isModalShown}
        onClose={() => setIsModalShown(false)}
        onSave={onModalSubmit}
        mode={CONSTANTS.MODE.ADD}
        clientPaymentSecretKey={clientPaymentSecretKey}
        clientSecretIsLoading={clientSecretIsLoading}
        cardData={{ label: '', isDefault: false, remember: true }}
        showIsRememberOption={userCards.length}
      />
    </>
  )
}

export default SelectNewPaymentMethodModal
