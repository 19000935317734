import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InviteRegistrationStepSelector, Typography } from 'components'
import EmailSignUp from '../auth/signUp/emailSignUp'

const useStyles = makeStyles({
  signUpFormBody: {
    margin: 0,
    alignItems: 'start',
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',
    marginBottom: 24,
  },
})

const InviteRegistrationSignUp = props => {
  const { onAfterSignUp, firstName, lastName } = props
  const classes = useStyles()

  return (
    <EmailSignUp
      userType="client"
      onAfterSignUp={onAfterSignUp}
      formBodyClass={classes.signUpFormBody}
      submitButtonText="Sign up as client"
      isInvite
      firstName={firstName}
      lastName={lastName}
    />
  )
}

export default InviteRegistrationSignUp
