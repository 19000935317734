import React, { useEffect, useState } from 'react'
import { ColorCircularProgress, Footer, Typography, Icon, MainPageStepper, VideoPopup, ErrorModal } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import MainPageBackgroundImage from 'static/mainPage/mainPage3.jpg'
import Container2BgImage from 'static/mainPage/container2.jpg'
import Container1BgImage from 'static/mainPage/container1.jpg'
import Container3BgImage from 'static/mainPage/container3.jpg'
import { useQuery, useAnalytics } from 'utils/hooks'
import classNames from 'classnames'
import { getVerificationLinkStatus, toggleAuthModal } from 'store/actions/auth'
import { navigateToSignUp, navigateToSignIn } from 'store/actions/navigation'
import PortalSearch from '../../components/portalSearch/PortalSearch'
import BackgroundImage from '../../static/mainPage/landing.photo1.jpg'

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
  },
  startPage: {
    minHeight: 'calc(100vh - 50px)',
  },
  mainMiddleText: {
    fontSize: 20,
    color: colors.WHITE,
    marginTop: 17,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    lineHeight: 2.5,
    '@media(max-width:375px)': {
      fontSize: 16,
      lineHeight: 3.13,
    },
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
    height: '100%',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '@media(max-width:1023px) and (min-width:768px)': {},
    '@media(max-width:500px)': {
      backgroundPosition: '71%',
    },
  },

  image: {
    width: '100%',
  },
  container1: {
    width: '100%',
    padding: '160px 0 120px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
  },
  container2: {
    width: '100%',
    padding: '160px 0 120px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.BASIC.COLOR[0],
  },
  container3: {
    width: '100%',
    padding: '80px 0 120px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
    '@media(max-width:1023px)': {
      padding: '160px 0 120px',
    },
  },
  container4: {
    width: '100%',
    padding: '160px 0 120px',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${MainPageBackgroundImage})`,
    backgroundSize: 'cover',
    '@media(max-width:1023px)': {
      paddingBottom: 40,
    },
  },
  contentContainer4: {
    padding: '64px 0',
    maxWidth: 1280,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    padding: '0 40px',
    maxWidth: 1280,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width:1023px)': {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
      maxWidth: 720,
    },
    '@media(max-width:767px)': {
      padding: '0 15px',
    },
  },
  contentBordered: {
    zIndex: 0,
    backgroundImage: `url(${Container1BgImage})`,
    width: '570px',
    height: '260px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media(max-width:767px)': {
      width: '100%',
      height: 230,
      minHeight: '30vh',
    },
  },
  zContent: {
    zIndex: 1000,
    width: 480,
    marginLeft: 80,
    '@media(max-width:1023px)': {
      width: '100%',
      marginLeft: 0,
      textAlign: 'center',
      marginBottom: '1.5rem',
    },
  },
  contentTitle: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 24,
    fontFamily: 'Montserrat',
  },
  contentSubtitle: {
    fontSize: 18,
    fontWeight: 'normal',
  },
  containerContent2: {
    maxWidth: 1280,
    width: '100%',
    padding: '0 40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width:1023px)': {
      flexWrap: 'wrap',
      maxWidth: 720,
      justifyContent: 'center',
    },
    '@media(max-width:767px)': {
      padding: '0 15px',
    },
  },
  containerContent3: {
    display: 'flex',
    maxWidth: 1280,
    padding: '0 40px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width:1023px)': {
      flexWrap: 'wrap',
      maxWidth: 720,
      justifyContent: 'center',
      flexDirection: 'column-reverse',
    },
    '@media(max-width:767px)': {
      padding: '0 15px',
    },
  },
  iconClass: {
    color: colors.PRIMARY.COLOR[50],
    marginRight: 12.5,
  },
  listItem: {
    marginBottom: 20,
    lineHeight: 1.44,
  },
  content3: {
    marginLeft: -185,
    width: 'calc(100% - 630px)',
    maxWidth: 485,
    height: 285,
    color: colors.WHITE,
    '@media(max-width:1023px)': {
      width: '100%',
      marginLeft: 0,
      textAlign: 'center',
      marginBottom: '1.5rem',
      height: 'auto',
    },
  },
  whiteText: {
    color: colors.WHITE,
  },
  list: {
    marginRight: 150,
    '@media(max-width:1023px)': {
      width: '100%',
      marginRight: 0,
      textAlign: 'center',
      marginBottom: '1.5rem',
    },
  },
  container5: {
    height: 550,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    padding: '120px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(238,95,16,0.5)',
  },
  photoContainer2: {
    height: 328,
    width: 570,
    backgroundImage: `url(${Container2BgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media(max-width:767px)': {
      width: '100%',
      height: 250,
      minHeight: '30vh',
    },
  },
  photoContainer3: {
    height: 405,
    width: 570,
    backgroundImage: `url(${Container3BgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media(max-width:767px)': {
      width: '100%',
      height: 300,
      minHeight: '30vh',
    },
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100%',
    zIndex: '-100',
    transform: 'translateX(-50%) translateY(-50%)',
    backgroundSize: 'cover',
    transition: '1s opacity',
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
    '@media(max-width:1023px)': {
      justifyContent: 'center',
    },
  },
  contentImage: {
    boxShadow: '0px 0px 30px rgba(120, 120, 120, 0.3)',
    borderRadius: '0.25rem',
  },
  orangeText: {
    color: colors.PRIMARY.COLOR[50],
  },
  videoText: {
    color: colors.WHITE,
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 'bold',
  },
  videoTextSubtitle: {
    fontSize: 18,
    margin: '12px 0 82px',
    fontWeight: 'normal',
  },
  videoTextButton: {
    width: 150,
    height: 150,
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY.COLOR[50],
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  triangle: {
    width: 0,
    height: 0,
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderLeft: '36px solid' + colors.WHITE,
    marginLeft: 10,
  },
})

const StartPage = ({ location }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const isUserLogginingIn = useSelector(state => state.auth.isUserLogginingIn)
  const isProfileGetting = useSelector(state => state.auth.isProfileGetting)
  const currentUser = useSelector(state => state.auth.currentUser)
  const openUserDeletedModal = useSelector(state => state.auth.openUserDeletedModal)
  const role = useSelector(state => state.auth.currentUser.role)
  let [openPopup, handleVideoPopup] = useState(false)
  let [srcVideo, setSrcVideo] = useState('')
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)

  useAnalytics()

  useEffect(() => {
    const hash = query.get('hash')
    if (!hash || currentUser.role || location.pathname.replace('/', '')) return
    dispatch(getVerificationLinkStatus(hash)).then(res => {
      if (res) {
        dispatch(navigateToSignIn(location.search))
      } else {
        setIsOpenErrorModal(true)
      }
    })
  }, [currentUser])

  const onErrorModalClose = () => {
    setIsOpenErrorModal(false)
    dispatch(navigateToSignUp())
  }

  const onUserDeletedModalClose = () => {
    dispatch(toggleAuthModal(false))
  }

  return isUserLogginingIn || isProfileGetting ? (
    <ColorCircularProgress />
  ) : (
    <div className={classes.wrapper}>
      <PortalSearch className={classes.startPage} />
      <div className={classes.container5}>
        <div className={classes.videoText}>
          see the XIRA video
          <div className={classes.videoTextSubtitle}>click to see a 2 min video</div>
          <div
            className={classes.videoTextButton}
            onClick={() => {
              setSrcVideo(CONSTANTS.START_VIDEO_YOUTUBE_ID)
              handleVideoPopup(true)
            }}
          >
            <div className={classes.triangle}></div>
          </div>
        </div>

        <video className={classes.video} loop autoPlay muted playsInline>
          <source src={process.env.REACT_APP_XIRA_APP_MP4} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {openPopup ? (
        <VideoPopup
          src={srcVideo}
          close={() => {
            setSrcVideo('')
            handleVideoPopup(false)
          }}
        />
      ) : (
        ''
      )}
      <div className={classes.container1}>
        <div className={classes.contentContainer}>
          <div className={classNames(classes.contentBordered, classes.contentImage)} />
          <div className={classes.zContent}>
            <Typography className={classes.contentTitle}>get legal help without leaving home</Typography>
            <Typography className={classes.contentSubtitle}>
              if you have a legal question or want
              <br />
              to manage life events effectively, connect with
              <br />
              the legal professional that is right for you
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.container2}>
        <div className={classes.containerContent2}>
          <div className={classes.list}>
            <Typography className={classes.contentTitle}>
              easy, convenient
              <br />& private
            </Typography>
            <div className={classes.row}>
              <Icon style={classes.iconClass} iconClass="las la-check" />
              <Typography className={classNames(classes.listItem, classes.contentSubtitle)}>
                no surprise pricing
              </Typography>
            </div>
            <div className={classes.row}>
              <Icon style={classes.iconClass} iconClass="las la-check" />
              <Typography className={classNames(classes.listItem, classes.contentSubtitle)}>
                maximum efficiency & privacy
              </Typography>
            </div>
            <div className={classes.row}>
              <Icon style={classes.iconClass} iconClass="las la-check" />
              <Typography className={classNames(classes.listItem, classes.contentSubtitle)}>
                receive valuable consultation from
                <br />
                the comfort of your home
              </Typography>
            </div>
          </div>
          <div className={classNames(classes.photoContainer2, classes.contentImage)} />
        </div>
      </div>
      <div className={classes.container3}>
        <div className={classes.containerContent3}>
          <div className={classNames(classes.photoContainer3, classes.contentImage)} />
          <div className={classes.content3}>
            <Typography className={classNames(classes.contentTitle)}>stop asking friends for legal advice</Typography>
            <Typography className={classNames(classes.contentSubtitle, classes.orangeText)}>
              professional advice is now
              <br />
              <b>available, accessible & affordable</b>
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.container4}>
        <div className={classes.contentContainer4}>
          <Typography className={classes.contentTitle}>how it works</Typography>
          <MainPageStepper />
        </div>
      </div>
      <div className={classes.container5}>
        <div
          className={classes.videoTextButton}
          onClick={() => {
            setSrcVideo(CONSTANTS.MOBILE_VIDEO_YOUTUBE_ID)
            handleVideoPopup(true)
          }}
        >
          <div className={classes.triangle}></div>
        </div>

        <video className={classes.video} loop autoPlay muted playsInline>
          <source src={process.env.REACT_APP_XIRA_MOBILE_MP4} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Footer />
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onErrorModalClose}
        title="Your verification link has expired."
        message="Please check the latest verification link sent to your email. If it is expired, please create a new account."
        cancelationButtonText="sign up"
        variant="primary"
      />
      <ErrorModal
        isOpen={openUserDeletedModal}
        onClose={onUserDeletedModalClose}
        title="Account is deleted"
        message="Your account was deleted"
        cancelationButtonText="ok"
        variant="primary"
      />
    </div>
  )
}

export default StartPage
