import { extendMoment } from 'moment-range'
import Moment from 'moment'

const moment = extendMoment(Moment)

export const validateHours = workingHours => {
  let isValid = true

  const hours = workingHours.map((elem, index) => {
    const isStartTimeEmpty = checkForEmpty(elem.fromHour)
    const isEndTimeEmpty = checkForEmpty(elem.toHour)
    let isOverlapping = false
    let isRangeCorrect = true

    if (!isEndTimeEmpty && !isStartTimeEmpty) {
      isOverlapping = checkForOverlapping(workingHours, elem, index)
      isRangeCorrect = checkForCorrectRange(elem)
    }

    if (isOverlapping || !isRangeCorrect || isStartTimeEmpty || isEndTimeEmpty) {
      isValid = false
    }

    const errorMessage = `${isStartTimeEmpty || isEndTimeEmpty ? 'Start and End time cannot be blank. ' : ''}${
      isOverlapping ? 'Intervals are overlapping. ' : ''
    }${!isRangeCorrect ? 'Your end time cannot be before your start time. ' : ''}`

    return {
      ...elem,
      error: errorMessage,
    }
  })

  return {
    valid: isValid,
    workingHours: hours,
  }
}

const checkForEmpty = time => {
  return !time
}

const checkForOverlapping = (workingHours, elem, index) => {
  const firstEndTime =
    elem.toHour === '12:00 AM' ? moment(elem.toHour, 'hh:mm A').endOf('day') : moment(elem.toHour, 'hh:mm A')

  return workingHours.some((secondElem, secondIndex) => {
    if (secondIndex === index) {
      return false
    }

    const secondEndTime =
      secondElem.toHour === '12:00 AM'
        ? moment(secondElem.toHour, 'hh:mm A').endOf('day')
        : moment(secondElem.toHour, 'hh:mm A')

    const range1 = moment.range(moment(elem.fromHour, 'hh:mm A'), firstEndTime)
    const range2 = moment.range(moment(secondElem.fromHour, 'hh:mm A'), secondEndTime)
    return range1.overlaps(range2)
  })
}

const checkForCorrectRange = hours => {
  if (hours.toHour === '12:00 AM') {
    return true
  }
  return moment(hours.fromHour, 'hh:mm A').isBefore(moment(hours.toHour, 'hh:mm A'))
}

export const checkForDaySelect = weekData => {
  return weekData.some(elem => elem.isAvailable)
}
