import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { Typography } from 'components'

const useStyles = makeStyles({
  proRatingTitle: {
    fontWeight: 'bold',
    marginLeft: 3,
    lineHeight: 1,
  },
  reviewCount: {
    color: colors.BASIC.COLOR['60'],
    fontWeight: 'normal',
    marginLeft: 3,
  },
  ratingTooltipTextClasses: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textUnderlinePosition: 'under',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
})

const ProRatingTitle = ({ rating, reviews, isFullProfile, isProProfile, openRatingModal = () => {} }) => {
  const classes = useStyles()
  const handleTitleClick = () => {
    if (isFullProfile || isProProfile) openRatingModal()
  }
  return (
    <Typography
      onClick={handleTitleClick}
      className={classNames(classes.proRatingTitle, {
        [classes.ratingTooltipTextClasses]: isFullProfile || isProProfile,
      })}
    >
      {rating ? rating.toFixed(1) : '0.0'}
      <span className={classes.reviewCount}>{reviews ? `(${reviews})` : ' '}</span>
    </Typography>
  )
}

export default ProRatingTitle
