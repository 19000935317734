import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const XAutocomplete = ({
  fullWidth,
  variant,
  InputProps = {},
  InputLabelProps = {},
  value,
  options = [],
  optionLabel = 'label',
  valueName = 'value',
  label,
  placeholder,
  ...props
}) => {
  const [val, setVal] = useState('')

  useEffect(() => {
    setVal(options.find(opt => opt[valueName] === value) || '')
  }, [options, value, valueName])

  return (
    <Autocomplete
      {...props}
      options={options}
      value={val}
      getOptionLabel={option => `${option[optionLabel] || ''}`}
      autoHighlight
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant={variant}
          fullWidth={fullWidth}
          InputLabelProps={{ ...InputLabelProps }}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
          }}
        />
      )}
    />
  )
}

export default XAutocomplete
