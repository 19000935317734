import React from 'react'
import { Typography, Button, Modal, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  disconnectCalendarContainer: {
    minHeight: 204,
    width: 648,
    padding: '32px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  icon: {
    color: colors.ERROR,
    fontSize: 32,
    marginRight: 8,
  },
  title: {
    marginBottom: 16,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginRight: 40,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
})

const DisconnectCalendarModal = ({ open, calendar, onClose, onDisconnect, isLastCalendar }) => {
  const classes = useStyles()
  return (
    <Modal maxWidth="md" open={open} handleClose={onClose} scroll="body">
      <div className={classes.disconnectCalendarContainer}>
        {calendar && (
          <>
            <div className={classes.titleContainer}>
              <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
              <Typography className={classes.title} variant="h4">
                Disconnect calendar?
              </Typography>
            </div>
            <Typography className={classes.title} variant="subtitle1">
              {isLastCalendar
                ? `${calendar.username} will be permanently deleted from your profile
              and clients will not be able to book meetings with you. Continue?`
                : `${calendar.username} will be permanently deleted
                 from your profile and your availability time will be updated. Continue?`}
            </Typography>
            <div className={classes.btnContainer}>
              <div>
                <Button className={classes.cancelBtn} onClick={onClose} variant="text">
                  cancel
                </Button>
                <Button onClick={() => onDisconnect(calendar.provider)} variant="primary">
                  yes, disconnect
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default DisconnectCalendarModal
