import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  ColorCircularProgress,
  InputLabel,
  PaymentCardElement,
  PoweredByStripeLogo,
  TextField,
  Typography,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { getClientPaymentSecret, saveUserCard } from 'store/actions/payments'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    minHeight: 300,
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',
    marginBottom: 24,
  },
  doneBtn: {
    width: 97,
  },
  cardForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stripeLogo: {
    height: 26,
    width: 119,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notched: {
    borderColor: colors.BASIC.COLOR[20],
  },
})

const AddCardForm = props => {
  const classes = useStyles()
  const stripe = useStripe()
  const dispatch = useDispatch()
  const elements = useElements()
  const { onSave, onBackPress } = props
  const clientPaymentSecretKey = useSelector(state => state.payments.clientPaymentSecretKey)
  const clientSecretIsLoading = useSelector(state => state.payments.clientSecretIsLoading)
  const [userCardData, setUserCardData] = useState({})
  const [stripeError, setStripeError] = useState('')

  useEffect(() => {
    dispatch(getClientPaymentSecret())
  }, [])

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }
    const result = await stripe.confirmCardSetup(clientPaymentSecretKey, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })

    if (result.error) {
      setStripeError(result.error.message)
    } else {
      setStripeError('')
      const data = {
        label: userCardData.label,
        is_default: userCardData.isDefault,
        remember_card: true,
        payment_method_id: result.setupIntent.payment_method,
      }
      try {
        await dispatch(saveUserCard(data))
        onSave()
      } catch (e) {
        dispatch(getClientPaymentSecret())
      }
    }
  }

  const onChangeDefault = e => {
    setUserCardData({
      ...userCardData,
      isDefault: e.target.checked,
    })
  }

  const onLabelChange = e => {
    setUserCardData({
      ...userCardData,
      label: e.target.value,
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          add payment card
        </Typography>
        <PoweredByStripeLogo className={classes.stripeLogo} />
      </div>
      {clientSecretIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <div className={classes.cardForm}>
            <InputLabel>Credit or debit card</InputLabel>
            <PaymentCardElement stripeError={stripeError} />
            <InputLabel>Card label</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              onChange={onLabelChange}
              value={userCardData.label}
              inputProps={{
                maxLength: 50,
              }}
              InputProps={{
                classes: { notchedOutline: classes.notched },
              }}
            />
            <Checkbox
              label="Set as default payment method"
              onChange={onChangeDefault}
              checked={userCardData.isDefault}
            />
          </div>
          <div className={classes.btnContainer}>
            <Button variant="text" onClick={onBackPress}>
              Back
            </Button>
            <Button variant="primary" onClick={handleSubmit} className={classes.doneBtn}>
              complete
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default AddCardForm
