import React from 'react'
import { Button, Checkbox, ExpansionPanel, Icon, PracticeAreaDetails, SvgIcon, Typography } from 'components'
import { ReactComponent as AlertTriangle } from 'static/alert-triangle.svg'
import { ReactComponent as ChevronDown } from 'static/chevron-down.svg'
import withStyles from '@material-ui/core/styles/withStyles'
import { colors, REGEXP } from 'constants/index'
import classNames from 'classnames'

const styles = {
  checked: {
    backgroundColor: colors.WHITE,
  },
  checkbox: {
    backgroundColor: 'transparent',
  },
  content: {
    margin: '7px 0',
  },
  label: {
    margin: 'auto 5px',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[100],
  },
  labelUncheck: {
    color: colors.BASIC.COLOR[50],
  },
  errorIcon: {
    color: colors.ERROR,
    fontSize: 22,
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  summaryLabel: {
    display: 'flex',
  },
  alertLogo: {
    color: '#F55838',
  },
  expansionPanelDetails: {
    padding: 0,
  },
}
class PracticeAreas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedPanel: { uuid: '', expanded: false },
      errorPanel: props.invalidAreas,
    }
  }

  onBlurHR = (e, areaDetails) => {
    const checkedSubAreas = areaDetails.subSpecialities.filter(sub => sub.checked)
    if (
      areaDetails.hourlyRate !== '0' &&
      e.target.value &&
      checkedSubAreas.length &&
      !REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(e.target.value) &&
      REGEXP.fiveDecimals.test(e.target.value)
    ) {
      this.setState({ errorPanel: this.state.errorPanel.filter(panel => panel !== areaDetails.uuid) })
    } else {
      if (areaDetails.checked) this.setState({ errorPanel: [...this.state.errorPanel, areaDetails.uuid] })
    }
  }

  onAreaCheckboxClick = (event, uuid, name) => {
    event.stopPropagation()
    this.props.setErrorMessage(null)
    if (event.target.checked) {
      if (this.state.expandedPanel.uuid) this.setState({ errorPanel: [...this.state.errorPanel, uuid] })
      this.setState({ expandedPanel: { ...this.state.expandedPanel, uuid: uuid, expanded: true } })
    } else if (!event.target.checked) {
      const errorListId = this.state.errorPanel.filter(panel => panel !== uuid)
      this.setState({ errorPanel: errorListId })
      this.props.setInvalidAreas(errorListId)
      if (errorListId.length) this.props.setErrorMessage(`Please specify practice area details`)
    }
    if (event.target.checked === undefined) {
      return
    }
    const updatedPracticeAreas = this.props.practiceAreas.map(area =>
      area.uuid === uuid && event.target.checked !== area.checked
        ? {
            ...area,
            checked: event.target.checked,
            subSpecialities: area.subSpecialities.map(sub => ({
              ...sub,
              checked: event.target.checked,
              disabled: false,
            })),
            federalSubSpecialities: area.federalSubSpecialities.map(sub => ({
              ...sub,
              checked: false,
              disabled: !event.target.checked,
            })),
            consultationCost: event.target.checked ? area.consultationCost : '',
            hourlyRate: event.target.checked ? area.hourlyRate : '',
            fixedPrice: event.target.checked ? area.fixedPrice : false,
            contingency: event.target.checked ? area.contingency : false,
          }
        : area
    )
    this.props.addProfilePracticeAreas(updatedPracticeAreas)
  }

  onAreaDetailsClick = (areaDetails, name, value) => {
    const checkedSubAreas = areaDetails.subSpecialities.filter(sub => sub.checked)
    if ((areaDetails.hourlyRate || (name === 'hourlyRate' && value)) && checkedSubAreas.length) {
      this.setState({ errorPanel: this.state.errorPanel.filter(panel => panel !== areaDetails.uuid) })
    } else {
      this.setState({ errorPanel: [...this.state.errorPanel, areaDetails.uuid] })
    }
    const updatedPracticeAreas = this.props.practiceAreas.map(area =>
      area.uuid === areaDetails.uuid
        ? {
            ...area,
            checked: true,
            [name]: value,
          }
        : area
    )
    this.props.addProfilePracticeAreas(updatedPracticeAreas)
  }

  onAreaSubSpecialityClick = (areaDetails, checked, isFederal) => {
    if (areaDetails.hourlyRate && ((checked && !isFederal) || isFederal)) {
      this.setState({ errorPanel: this.state.errorPanel.filter(panel => panel !== areaDetails.uuid) })
    } else {
      this.setState({ errorPanel: [...this.state.errorPanel, areaDetails.uuid] })
    }

    const updatedPracticeAreas = this.props.practiceAreas.map(area => {
      if (area.uuid !== areaDetails.uuid) {
        return area
      }

      if (isFederal) {
        return {
          ...area,
          checked: true,
          subSpecialities: area.subSpecialities.map(sub => {
            const filteredSubSpeciality = area.federalSubSpecialities.find(federalSub => federalSub.uuid === sub.uuid)

            return {
              ...sub,
              checked: filteredSubSpeciality && checked ? true : sub.checked,
              disabled: filteredSubSpeciality ? checked : sub.disabled,
            }
          }),
          federalSubSpecialities: area.federalSubSpecialities.map(sub => ({ ...sub, checked: checked })),
        }
      }

      return {
        ...area,
        checked: true,
        subSpecialities: area.subSpecialities.map(sub => ({ ...sub, checked: checked })),
        federalSubSpecialities: area.federalSubSpecialities.map(sub => {
          const filteredSubSpeciality = area.subSpecialities.find(federalSub => federalSub.uuid === sub.uuid)

          return {
            ...sub,
            checked: filteredSubSpeciality && checked ? sub.checked : false,
            disabled: filteredSubSpeciality ? !checked : sub.disabled,
          }
        }),
      }
    })
    this.props.addProfilePracticeAreas(updatedPracticeAreas)
  }

  onAreaSubDetailsClick = (areaDetails, subDetail, subDetailUuid, checked, isFederal) => {
    const updatedPracticeAreas = this.props.practiceAreas.map(area => {
      if (isFederal) {
        return area.uuid === areaDetails.uuid
          ? {
              ...area,
              checked: true,
              [subDetail]: area[subDetail].map(sub =>
                sub.uuid === subDetailUuid ? { ...sub, checked: checked } : sub
              ),
              subSpecialities: area.subSpecialities.map(sub => {
                return sub.uuid === subDetailUuid
                  ? { ...sub, disabled: checked, checked: checked ? true : sub.checked }
                  : sub
              }),
            }
          : area
      }

      return area.uuid === areaDetails.uuid
        ? {
            ...area,
            checked: true,
            [subDetail]: area[subDetail].map(sub => (sub.uuid === subDetailUuid ? { ...sub, checked: checked } : sub)),
            federalSubSpecialities: area.federalSubSpecialities.map(sub => {
              return sub.uuid === subDetailUuid
                ? { ...sub, disabled: !checked, checked: checked ? sub.checked : false }
                : sub
            }),
          }
        : area
    })
    const checkedSubAreas = updatedPracticeAreas
      .find(area => area.uuid === areaDetails.uuid)
      .subSpecialities.filter(sub => sub.checked)
    if (areaDetails.hourlyRate && checkedSubAreas.length) {
      this.setState({ errorPanel: this.state.errorPanel.filter(panel => panel !== areaDetails.uuid) })
    } else {
      this.setState({ errorPanel: [...this.state.errorPanel, areaDetails.uuid] })
    }
    this.props.addProfilePracticeAreas(updatedPracticeAreas)
  }
  handleChange = (e, uuid) => {
    if (e.target.value === undefined)
      this.state.expandedPanel.uuid === uuid
        ? this.setState({ expandedPanel: { uuid: '', expanded: false } })
        : this.setState({ expandedPanel: { uuid: uuid, expanded: true } })
  }

  render() {
    const {
      practiceAreas,
      classes,
      labelClasses,
      panelClasses,
      checkboxClasses,
      errorPanelClasses,
      invalidAreas,
    } = this.props
    return (
      <div>
        {practiceAreas &&
          practiceAreas.map(area => {
            const labelClass = area.checked
              ? { root: classNames(classes.label) }
              : { root: classNames(classes.label, classes.labelUncheck) }

            return (
              <ExpansionPanel
                expansionPanelSummaryClass={
                  area.checked
                    ? this.state.errorPanel.includes(area.uuid) && !this.state.expandedPanel.expanded
                      ? errorPanelClasses
                      : { root: classNames(classes.checked), content: classes.content }
                    : { content: classes.content }
                }
                expansionPanelDetailsClass={{ root: classes.expansionPanelDetails }}
                onClick={e => this.handleChange(e, area.uuid)}
                key={area.uuid}
                expanded={this.state.expandedPanel.uuid === area.uuid && this.state.expandedPanel.expanded}
                expandIcon={<ChevronDown />}
                summary={
                  <div className={classes.summary}>
                    <div className={classes.summaryLabel}>
                      <Checkbox
                        checked={area.checked ? area.checked : false}
                        onChange={event => this.onAreaCheckboxClick(event, area.uuid, area.name)}
                        classes={{ root: classes.checkbox }}
                      />
                      <Typography classes={labelClass}>{area.name}</Typography>
                    </div>
                    {(this.state.errorPanel.includes(area.uuid) || invalidAreas.includes(area.uuid)) && (
                      <SvgIcon component={AlertTriangle} viewBox="0 0 24 24" className={classes.alertLogo} />
                    )}
                  </div>
                }
                details={
                  this.state.expandedPanel.uuid === area.uuid && (
                    <PracticeAreaDetails
                      setPracticeAreaDetails={this.onAreaDetailsClick}
                      setPracticeAreaSubDetails={this.onAreaSubDetailsClick}
                      setPracticeAreaSubSpeciality={this.onAreaSubSpecialityClick}
                      areaDetails={area}
                      onBlurHR={this.onBlurHR}
                      showHRValidation={this.state.errorPanel.includes(area.uuid)}
                      areaChecked={area.checked}
                    />
                  )
                }
                panelClasses={
                  invalidAreas.includes(area.uuid) ||
                  (this.state.errorPanel.includes(area.uuid) && area.uuid === this.state.expandedPanel.uuid)
                    ? errorPanelClasses
                    : panelClasses
                }
              />
            )
          })}
      </div>
    )
  }
}
export default withStyles(styles)(PracticeAreas)
