import * as CONSTANTS from 'store/constants/meeting'

const initialState = {
  isMeetingInfoLoading: false,
  meetingInfo: {},
}

const meeting = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_MEETING_INFO_START:
      return {
        ...state,
      }
    case CONSTANTS.GET_MEETING_INFO_END:
      return {
        ...state,
        meetingInfo: action.payload,
      }
    case CONSTANTS.SHOW_MEETING_LOADER:
      return {
        ...state,
        isMeetingInfoLoading: true,
      }
    case CONSTANTS.HIDE_MEETING_LOADER:
      return {
        ...state,
        isMeetingInfoLoading: false,
      }

    default:
      return state
  }
}

export default meeting
