export const REGISTRATION_BEGIN = 'REGISTRATION_BEGIN'
export const REGISTRATION_END = 'REGISTRATION_END'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UPDATE_LOCAL_CURRENT_USER = 'UPDATE_LOCAL_CURRENT_USER'
export const SET_SIGN_UP_DATA = 'SET_SIGN_UP_DATA'
export const SET_SIGN_UP_EMAIL = 'SET_SIGN_UP_EMAIL'
export const SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO'
export const IS_CHANGING_PASSWORD = 'IS_CHANGING_PASSWORD'
export const END_CHANGING_PASSWORD = 'END_CHANGING_PASSWORD'
export const USER_LOGGING_IN = 'USER_LOGGING_IN'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const GETTING_PROFILE = 'GETTING_PROFILE'
export const GOT_PROFILE = 'GOT_PROFILE'
export const IS_AUTH_BY_EMAIL_START = 'IS_AUTH_BY_EMAIL_START'
export const IS_AUTH_BY_EMAIL_END = 'IS_AUTH_BY_EMAIL_END'
export const CHANGE_AUTH_ROLE = 'CHANGE_AUTH_ROLE'
export const TOGGLE_USER_DELETED_MODAL = 'TOGGLE_USER_DELETED_MODAL'

export const SET_SHOW_BANNER = 'SET_SHOW_BANNER'
export const SET_HIDE_BANNER = 'SET_HIDE_BANNER'

export const SET_ACTIVATED_FEATURES = 'SET_ACTIVATED_FEATURES'
export const SET_ACTIVATED_FEATURES_LOADED = 'SET_ACTIVATED_FEATURES_LOADED'
