import React from 'react'
import { Button, Modal, XIRACalendarSettings } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import {useSelector} from "react-redux";
import {ColorCircularProgress} from "../../../components";

const useStyles = makeStyles({
  XIRACalendarContainer: {
    minHeight: 204,
    minWidth: 648,
    padding: '32px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginRight: 40,
  },
})

const XIRACalendarModal = ({ open, onClose, onSave, saveDisabled }) => {
  const classes = useStyles()
  const isRefreshingConnectedCalendars = useSelector(state => state.calendar.isRefreshingConnectedCalendars)
  return (
    <Modal maxWidth="md" open={open} handleClose={onClose} scroll="body">
      <div className={classes.XIRACalendarContainer}>
        {isRefreshingConnectedCalendars
          ? <ColorCircularProgress/>
          : <>
            <XIRACalendarSettings />
            <div className={classes.btnContainer}>
              <div>
                <Button className={classes.cancelBtn} onClick={onClose} variant="text">
                  cancel
                </Button>
                <Button variant="primary" disabled={saveDisabled} onClick={onSave}>
                  save
                </Button>
              </div>
            </div>
          </>
          }
      </div>
    </Modal>
  )
}

export default XIRACalendarModal
