import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { ProList, ProfileSearchFilters, Footer, ColorCircularProgress } from 'components'
import {
  getProfileList,
  resetUploadOffset,
  increaseUploadOffset,
  setSearchFilter,
  resetSearchFilters,
  setSearchSorter,
} from 'store/actions/search'
import { showFilter, showSort } from 'store/actions/common'
import { colors } from 'constants/colors'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

const styles = () => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  root: {
    display: 'flex',
    flex: '1 0 auto',
    backgroundColor: colors.WHITE,
  },
  filters: {
    width: 400,
    backgroundColor: colors.WHITE,
    overflowY: 'auto',
  },
  search: {
    width: '100%',
    position: 'relative',
  },
  '@media (max-width: 939px)': {
    filters: {
      display: 'none',
    },
    showFilter: {
      width: '465px',
      display: 'flex',
      position: 'fixed',
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1205,
    },
    addRelative: {
      position: 'relative',
    },
    darkField: {
      left: '0 !important',
      opacity: '1 !important',
      zIndex: 1204,
    },
    backdrop: {
      position: 'fixed',
      left: '-100%',
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(38, 38, 37, 0.4)',
      opacity: 0,
      transition: 'opacity 0.3s ease',
    },
  },
  '@media (max-width: 767px)': {
    showFilter: {
      width: '100%',
    },
  },
})

const ProfileSearch = props => {
  const {
    classes,
    profiles,
    profilesLength,
    getPros,
    increaseOffset,
    setFilter,
    resetFilters,
    setSorter,
    filters,
    sorter,
    averagePrice,
    searchListTitle,
    profileListIsLoading,
  } = props

  const dispatch = useDispatch()
  const stateFilter = useSelector(state => state.common.showFilter)
  const stateSort = useSelector(state => state.common.showSort)
  const handleClickBackground = () => {
    dispatch(showSort(false))
    dispatch(showFilter(false))
  }

  useEffect(() => {
    window.gtag('event', 'conversion', { send_to: 'AW-654481491/RWnCCM65qOUBENOwirgC' })
  }, [])
  return (
    <div className={classes.container}>
      <div className={classnames(classes.root, { [classes.addRelative]: stateFilter })}>
        <div className={classnames(classes.filters, { [classes.showFilter]: stateFilter })}>
          <ProfileSearchFilters
            setFilter={setFilter}
            resetFilters={resetFilters}
            filters={filters}
            getProfiles={getPros}
            averagePrice={averagePrice}
            profilesLength={profilesLength}
          />
        </div>
        <div
          className={classnames(classes.backdrop, { [classes.darkField]: stateFilter || stateSort })}
          onClick={handleClickBackground}
        ></div>
        <div className={classes.search}>
          <ProList
            searchListTitle={searchListTitle}
            profiles={profiles}
            profilesLength={profilesLength}
            loadProfiles={getPros}
            increaseOffset={increaseOffset}
            setSorter={setSorter}
            sorter={sorter}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}
const mapStateToProps = ({ search }) => ({
  profiles: search.profiles,
  profilesLength: search.profilesCount,
  filters: search.filters,
  sorter: search.sorter,
  averagePrice: search.averagePrice,
  searchListTitle: search.searchListTitle,
  profileListIsLoading: search.profileListIsLoading,
})

const mapDispatchToProps = dispatch => ({
  getPros: isReUpload => dispatch(getProfileList(isReUpload)),
  resetOffset: () => dispatch(resetUploadOffset()),
  increaseOffset: () => dispatch(increaseUploadOffset()),
  setFilter: (name, value) => dispatch(setSearchFilter(name, value)),
  resetFilters: () => dispatch(resetSearchFilters()),
  setSorter: value => dispatch(setSearchSorter(value)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ProfileSearch)
