import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton'

const XStepper = ({
  activeStep,
  steps,
  alternativeLabel,
  nonLinear,
  classes,
  classesStepLabel,
  connector,
  classesStep,
  classesStepIcon,
  setActiveStep,
  completedStep,
  classesShortName,
  classesFullName,
}) => {
  return (
    <Stepper
      connector={connector}
      classes={classes}
      activeStep={activeStep}
      nonLinear={nonLinear}
      alternativeLabel={alternativeLabel}
    >
      {steps.map((label, index) => (
        <Step
          classes={classesStep}
          completed={completedStep ? completedStep.indexOf(index) !== -1 : index < activeStep}
          key={label}
        >
          {nonLinear ? (
            <StepButton onClick={() => index < completedStep && setActiveStep(index)}>
              <StepLabel
                StepIconProps={{
                  classes: classesStepIcon,
                }}
                classes={classesStepLabel}
              >
                {label}
              </StepLabel>
            </StepButton>
          ) : (
            <StepLabel
              StepIconProps={{
                classes: classesStepIcon,
              }}
              classes={classesStepLabel}
            >
              {label === 'contact information' ? (
                <>
                  <span className={classesFullName}>{label}</span>
                  <span className={classesShortName}>info</span>
                </>
              ) : (
                label
              )}
            </StepLabel>
          )}
        </Step>
      ))}
    </Stepper>
  )
}

export default XStepper
