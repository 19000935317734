import React from 'react'
import { Button, Icon, Menu, MenuItem } from 'components'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import classNames from 'classnames'

const SplitButton = props => {
  const { variant, onSelect, options, children, classes, color } = props
  const [anchorRef, setAnchorRef] = React.useState(null)

  const toggleMenu = event => {
    setAnchorRef(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorRef(null)
  }

  const onMenuSelect = option => {
    onSelect(option)
    setAnchorRef(null)
  }

  return (
    <>
      <ButtonGroup variant={variant} color={color} className={classes.buttonGroup}>
        <Button onClick={toggleMenu} className={classes.buttons}>
          {children}
        </Button>
        <Button onClick={toggleMenu} className={classNames(classes.buttons, classes.iconButton)}>
          <Icon iconClass="las la-angle-down" />
        </Button>
      </ButtonGroup>
      <Menu anchorEl={anchorRef} open={Boolean(anchorRef)} onClose={handleClose}>
        {options.map(option => (
          <MenuItem key={option} onClick={() => onMenuSelect(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SplitButton
