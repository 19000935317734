import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { ColorCircularProgress, NoDataPlaceholder, UserNotification } from 'components'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationsEmptyStateImage from 'static/notifications/notification.png'
import classNames from 'classnames'
import { getUserNotifications, increaseNotificationsOffset } from 'store/actions/notifications/notifications'

const useStyles = makeStyles({
  notificationList: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  noDataImage: {
    height: 64,
    width: 64,
  },
  notificationListSmall: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationListFull: {
    height: 580,
    display: 'flex',
  },
  loader: {
    position: 'relative',
    width: '100%',
    height: 40,
    marginBottom: 30,
  },
  scroll: {
    width: '100%',
    height: '100%',
  },
})

const NotificationList = props => {
  const classes = useStyles()
  const { closePopover, isAdmin } = props
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications.notifications)
  const notificationsLength = useSelector(state => state.notifications.notificationsLength)
  const adminNotifications = useSelector(state => state.notifications.adminNotifications)
  const adminNotificationsLength = useSelector(state => state.notifications.adminNotificationsLength)
  const notificationsAreLoading = useSelector(state => state.notifications.notificationsAreLoading)
  const dataSource = isAdmin ? adminNotifications : notifications
  const dataSourceLength = isAdmin ? adminNotificationsLength : notificationsLength

  const onLoadMore = () => {
    dispatch(increaseNotificationsOffset())
    dispatch(getUserNotifications(false, isAdmin))
  }

  return (
    <div
      className={classNames({
        [classes.notificationList]: true,
        [classes.notificationListSmall]: !dataSourceLength,
        [classes.notificationListFull]: dataSourceLength,
      })}
      id="notification_list_id"
    >
      {notificationsAreLoading ? (
        <ColorCircularProgress />
      ) : !dataSourceLength ? (
        <NoDataPlaceholder
          imageSrc={NotificationsEmptyStateImage}
          imageClass={classes.noDataImage}
          hideButton
          details="no new notifications here"
        />
      ) : (
        <div className={classes.scroll}>
          <InfiniteScroll
            scrollableTarget="notification_list_id"
            dataLength={dataSource.length}
            hasMore={dataSourceLength > dataSource.length}
            next={onLoadMore}
            loader={
              <div className={classes.loader}>
                <ColorCircularProgress />
              </div>
            }
          >
            {!!dataSource.length &&
              dataSource.map(notification => (
                <UserNotification notification={notification} closePopover={closePopover} key={notification.uuid} />
              ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  )
}

export default NotificationList
