import React from 'react'
import { Modal, Typography, Button } from 'components'
import { colors } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  modal: {
    padding: '32px 40px',
    width: 680,
    height: 494,
  },
  title: {
    marginBottom: 16,
    fontSize: 25,
    fontWeight: 'bold',
  },
  rate: {
    fontSize: 15,
  },
  freePlan: {
    fontWeight: 500,
  },
  premiumPlan: {
    fontWeight: 500,
    color: colors.PRIMARY.COLOR[50],
  },
  desc: {
    marginBottom: 16,
    fontSize: 13,
    color: colors.BASIC.COLOR[70],
  },
  panelsArea: {
    display: 'flex',
    height: 287,
    textAlign: 'center',
  },
  freeArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 200,
    padding: '32px 40px 24px 40px',
    borderRadius: 4,
    boxShadow: '1px 3px 16px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px',
    borderColor: colors.BASIC.COLOR[10],
    marginRight: 24,
  },
  premiumArea: {
    width: 362,
    padding: '32px 20px 24px 20px',
    borderRadius: 4,
    boxShadow: '1px 3px 16px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px',
    borderColor: colors.PRIMARY.COLOR[30],
  },
  freeTitle: {
    fontSize: 11,
    fontWeight: 500,
    color: colors.ADDITIONAL.COLOR[50],
    marginBottom: 8,
  },
  premiumTitle: {
    fontSize: 11,
    fontWeight: 500,
    color: colors.PRIMARY.COLOR[50],
    marginBottom: 8,
  },
  planSpace: {
    fontWeight: 'bold',
    fontSize: 25,
  },
  freeDesc: {
    marginTop: 8,
    marginBottom: 72,
    fontSize: 12,
    color: colors.BASIC.COLOR[70],
  },
  premiumDesc: {
    marginTop: 8,
    marginBottom: 15,
    fontSize: 11,
    color: colors.BASIC.COLOR[70],
  },
  stayFreeBtn: {
    minHeight: 32,
    padding: '6px 16px',
    fontSize: 11,
    backgroundColor: colors.ADDITIONAL.COLOR[50],
  },
  rowTable: {
    display: 'flex',
  },
  rowTable2: {
    height: 57,
    display: 'flex',
    borderTop: '1px solid',
    borderTopColor: colors.BASIC.COLOR[10],
    paddingTop: 6,
    marginBottom: 11,
  },
  column1: {
    width: '50%',
    textAlign: 'right',
    marginRight: 17,
  },
  column1Text: {
    fontSize: 13,
  },
  column2: {
    width: '50%',
    textAlign: 'left',
    marginLeft: 7,
  },
  column2Text: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  premiumPrice: {
    fontWeight: 'normal',
    fontSize: 13,
  },
  acceptBtn: {
    minHeight: 32,
    padding: '6px 16px',
    fontSize: 11,
  },
})

const VaultSwitchToPremiumModal = ({ isOpen, onClose, onAcceptClick, plansRate }) => {
  const classes = useStyles()
  const freeSpace = plansRate[0] ? plansRate[0].toValue : ''
  const premiumSpace = plansRate[1] ? plansRate[1].toValue : ''
  const planPrice = plansRate[1] ? plansRate[1].planPrice : ''
  const perGbPrice = plansRate[1] ? plansRate[1].pricePerGb : ''
  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h3" className={classes.title}>
          Secure vault Premium Plan
        </Typography>
        <Typography variant="subtitle1" className={classes.rate}>
          You can stay on <span className={classes.freePlan}>{`the free plan (${freeSpace} GB)`}</span> or switch to the
          <span className={classes.premiumPlan}>{` Premium plan (${premiumSpace} GB+)`}</span> for file storage to
          continue
        </Typography>
        <Typography variant="subtitle1" className={classes.desc}>
          You can change your plan at any time
        </Typography>
        <div className={classes.panelsArea}>
          <div className={classes.freeArea}>
            <div className={classes.freeAreaWrapDesc}>
              <Typography variant="subtitle1" className={classes.freeTitle}>
                FREE PLAN
              </Typography>
              <Typography variant="h3" className={classes.planSpace}>
                {`${freeSpace} GB`}
              </Typography>
              <Typography variant="subtitle1" className={classes.freeDesc}>
                free available space
              </Typography>
            </div>
            <Button variant="primary" className={classes.stayFreeBtn} onClick={onClose}>
              stay on free
            </Button>
          </div>
          <div className={classes.premiumArea}>
            <Typography variant="subtitle1" className={classes.premiumTitle}>
              PREMIUM PLAN
            </Typography>
            <Typography variant="h3" className={classes.planSpace}>
              {`${premiumSpace}+ GB`}
            </Typography>
            <Typography variant="subtitle1" className={classes.premiumDesc}>
              rates per month
            </Typography>
            <div className={classes.rowTable}>
              <div className={classes.column1}>
                <Typography variant="subtitle1" className={classes.column1Text}>
                  {`up to ${premiumSpace} GB`}
                </Typography>
              </div>
              <div className={classes.column2}>
                <Typography variant="subtitle1" className={classes.column2Text}>
                  {`$${planPrice}`}
                </Typography>
              </div>
            </div>
            <div className={classes.rowTable2}>
              <div className={classes.column1}>
                <Typography variant="subtitle1" className={classes.column1Text}>
                  {`beyond ${premiumSpace}+ GB`}
                </Typography>
              </div>
              <div className={classes.column2}>
                <Typography variant="subtitle1" className={classes.column2Text}>
                  {`$${planPrice} + $${perGbPrice} `}
                  <span className={classes.premiumPrice}>per GB</span>
                </Typography>
              </div>
            </div>
            <Button variant="primary" className={classes.acceptBtn} onClick={onAcceptClick}>
              continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default VaultSwitchToPremiumModal
