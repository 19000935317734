import React, {useEffect} from 'react'
import TableRow from '@material-ui/core/TableRow'
import { useDrag, useDrop} from "react-dnd"
import classNames from 'classnames'
import {makeStyles} from "@material-ui/core"
import {colors} from 'constants/index'
import { getEmptyImage } from 'react-dnd-html5-backend'

const useStyles = makeStyles({
  hoverRow:{
    backgroundColor: colors.ADDITIONAL.TRANSPARENT["8"],
    border: `2px solid ${colors.ADDITIONAL.COLOR["50"]}`
  },
  draggableRow: {
    cursor: 'move'
  }

})

const type = 'draggable_row'
const DraggableTableRow = ({key, rowClasses, onClick, hover, title, children, selectedRows, data, handleDrop = () => {}}) => {
  const classes = useStyles()

  const ref = React.useRef();
  const [{isOver}, drop] = useDrop({
    accept: type,
    collect: monitor => {
      if(!data.isFolder || selectedRows.find(row => row.uuid === data.uuid)) return {}
      return {
        isOver: monitor.isOver({ shallow: true }),
      };
    },
    canDrop: () => data.isFolder && !selectedRows.find(row => row.uuid === data.uuid),
    drop: item => {
      handleDrop(item, data)
    },
});
  const [{isDragging}, drag, preview] = useDrag({
    item: { type },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => {
      return selectedRows
    },
    canDrag: () => {
      return !!selectedRows.find(row => row.uuid === data.uuid)
    },
  });
  drop(drag(ref))

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return <>
    <TableRow
      ref={ref}
      key={key}
      classes={{ root: classNames(rowClasses, {[classes.hoverRow]: isOver}, {[classes.draggableRow]: isDragging}) }}
      onClick={onClick}
      hover={hover}
      title={title}
    >
      {children}
    </TableRow>
  </>
}

export default DraggableTableRow