import React from 'react'
import { Stepper, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  stepper: {
    padding: 0,
  },
  connector: {
    margin: '0 30px',
  },
  horizontal: {
    paddingLeft: 0,
  },
  connectCalendarStepIcon: {
    paddingRight: 12,
    fontWeight: 400,
  },
  rootStepIcon: {
    color: colors.BASIC.COLOR[20],
    '&. MuiStepIcon-text': {
      fontSize: 11,
    },
    '&.MuiSvgIcon-root': {
      width: 28,
      height: 28,
    },
    '&.MuiStepIcon-active': {
      color: colors.PRIMARY.COLOR[50],
    },
    '&.MuiStepIcon-completed': {
      color: colors.PRIMARY.COLOR[50],
    },
  },

  connectCalendarStepLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  label: {
    color: colors.BASIC.COLOR[20],
    fontSize: 14,
    fontWeight: 500,
    '&.MuiStepLabel-active': {
      color: colors.BASIC.COLOR[100],
    },
    '&.MuiStepLabel-completed': {
      color: colors.BASIC.COLOR[20],
      '&.MuiStepLabel-active': {
        color: colors.BASIC.COLOR[100],
      },
    },
  },
  stepIconText: {
    fontSize: 11,
  },
  shortName: {
    display: 'none',
  },
  '@media(max-width:939px)': {
    label: {
      whiteSpace: 'nowrap',
    },
    connector: {
      margin: '0 7px',
    },
    horizontal: {
      paddingRight: 0,
    },
  },
  '@media(max-width:767px)': {
    connector: {
      margin: '0 5px',
      fontSize: 14,
    },
    connectCalendarStepIcon: {
      paddingRight: 6,
      fontSize: 12,
    },
    rootStepIcon: {
      color: colors.BASIC.COLOR[20],
      '&.MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
    label: {
      fontSize: 12,
    },
    fullName: {
      display: 'none',
    },
    shortName: {
      display: 'inline-block',
    },
    stepper: {
      marginTop: 10,
    },
  },
})

const StyledStepper = ({ activeStep, setActiveStep, completedStep, steps }) => {
  const classes = useStyles()
  return (
    <div className={classes.meetingSchedulerContainer}>
      <Stepper
        connector={<Icon style={classes.connector} iconClass="las la-angle-right" />}
        classes={{ root: classes.stepper }}
        classesStep={{ horizontal: classes.horizontal }}
        classesStepIcon={{ root: classes.rootStepIcon, text: classes.stepIconText }}
        classesStepLabel={{
          iconContainer: classes.connectCalendarStepIcon,
          root: classes.connectCalendarStepLabel,
          label: classes.label,
        }}
        classesShortName={classes.shortName}
        classesFullName={classes.fullName}
        steps={steps}
        activeStep={activeStep}
        completedStep={completedStep}
        setActiveStep={setActiveStep}
      />
    </div>
  )
}

export default StyledStepper
