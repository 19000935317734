import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  profileContentHeader: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
})

const ClientCasesContainer = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.profileContentHeader}>Cases</div>
    </>
  )
}

export default ClientCasesContainer
