export const SET_TABLE_CONFIGURATION = 'SET_TABLE_CONFIGURATION'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SORTING = 'SET_SORTING'
export const GET_CASES_START = 'GET_CASES_START'
export const GET_CASES_END = 'GET_CASES_END'
export const GET_CASES_FILTER_START = 'GET_CASES_FILTER_START'
export const GET_CASES_FILTER_END = 'GET_CASES_FILTER_END'
export const UPDATE_CASES_END = 'UPDATE_CASES_END'
export const UPDATE_CASES_START = 'UPDATE_CASES_START'
export const GET_CASE_FULL_INFO_START = 'GET_CASE_FULL_INFO_START'
export const GET_CASE_FULL_INFO_END = 'GET_CASE_FULL_INFO_END'
export const GET_INITIAL_CASES_START = 'GET_INITIAL_CASES_START'
export const GET_INITIAL_CASES_END = 'GET_INITIAL_CASES_END'
export const MAKE_CASE_INACTIVE_START = 'MAKE_CASE_INACTIVE_START'
export const MAKE_CASE_INACTIVE_END = 'MAKE_CASE_INACTIVE_END'
export const SAVE_PRO_CASE_START = 'SAVE_PRO_CASE_START'
export const SAVE_PRO_CASE_END = 'SAVE_PRO_CASE_END'
export const RESET_FILTERS = 'RESET_FILTERS'
export const RESET_SORTING = 'RESET_CASES_SORTING'
export const GET_PRO_SPECIFIC_PRACTICE_AREAS_START = 'GET_PRO_SPECIFIC_PRACTICE_AREAS_START'
export const GET_PRO_SPECIFIC_PRACTICE_AREAS_END = 'GET_PRO_SPECIFIC_PRACTICE_AREAS_END'
export const SET_ADD_CASES_BUTTON_ACTIVE = 'SET_ADD_CASES_BUTTON_ACTIVE'
export const SET_USER_HAS_CASES = 'SET_USER_HAS_CASES'
export const USER_HAS_CASES_START = 'USER_HAS_CASES_START'
