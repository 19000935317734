export const GET_USER_CONNECTIONS_START = 'GET_USER_CONNECTIONS_START'
export const GET_USER_CONNECTIONS_END = 'GET_USER_CONNECTIONS_END'
export const RESET_CONNECTIONS_UPLOAD_OFFSET = 'RESET_CONNECTIONS_UPLOAD_OFFSET'
export const INCREASE_CONNECTIONS_UPLOAD_OFFSET = 'INCREASE_CONNECTIONS_UPLOAD_OFFSET'
export const RESET_CONNECTIONS_LIST = 'RESET_CONNECTIONS_LIST'
export const GET_USER_CONNECTIONS_FOR_FILTER_START = 'GET_USER_CONNECTIONS_FOR_FILTER_START'
export const GET_USER_CONNECTIONS_FOR_FILTER_END = 'GET_USER_CONNECTIONS_FOR_FILTER_END'
export const SET_FILTERS = 'SET_CONNECTIONS_FILTERS'
export const SET_TABLE_CONFIGURATION = 'SET_CONNECTIONS_TABLE_CONFIGURATION'
export const SET_SORTING = 'SET_CONNECTIONS_SORTING'
export const OPEN_INVITE_POPUP = 'OPEN_INVITE_POPUP'
export const IS_SEND_INVITE_LINK_START = 'IS_SEND_INVITE_LINK_START'
export const IS_SEND_INVITE_LINK_END = 'IS_SEND_INVITE_LINK_END'
export const SET_CLIENT_INVITES = 'SET_CLIENT_INVITES'
export const SET_CLIENT_INVITES_LOADING = 'SET_CLIENT_INVITES_LOADING'
export const SET_ARCHIVE_USER_CONNECTIONS_START = 'SET_ARCHIVE_USER_CONNECTIONS_START'
export const SET_ARCHIVE_USER_CONNECTIONS_END = 'SET_ARCHIVE_USER_CONNECTIONS_END'
