import React from 'react'
import { colors } from 'constants/index'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import {
  InvoiceDocumentTable,
  PartialAmountTable,
  InvoiceDocumentHeader,
  InvoiceDocumentFooter,
  CreditAdjustmentEntry,
  SummaryTableEntry,
} from './parts'
import * as moment from 'moment-timezone'
import { PDFViewer } from '../../index'
import InvoiceCreditAdjustmentDocument from './InvoiceCreditAdjusmentDocument'
import { getInvoiceSummaryForInvoice } from '../../../utils/invoice/invoice-helpers'
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    paddingBottom: 90,
  },
  mainSection: {
    padding: '0 30px',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 28,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  caseTitle: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 10,
  },
  pageInfoSection: {
    width: '100%',
    position: 'absolute',
    height: 20,
    bottom: 70,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 32px',
  },
  pageInfoTitle: {
    fontSize: 8,
    marginTop: 4,
    fontFamily: 'Helvetica-Bold',
    color: colors.BASIC.COLOR[80],
  },
})

const InvoiceDocument = ({ invoice, download, timezone = moment.tz.guess(), summary }) => {
  const timeActivities = invoice.activities.filter(act => act.activity_type === 0)
  const expenseActivities = invoice.activities.filter(act => act.activity_type === 1)

  return (
    <Document>
      {download && summary && (
        <Page>
          <SummaryTableEntry summary={summary} invoice={invoice} timezone={timezone} />
        </Page>
      )}
      <Page size="A4" style={styles.page}>
        <InvoiceDocumentHeader invoice={invoice} timezone={timezone} download={download} />
        <View style={styles.mainSection}>
          {!!invoice.caseFeeType && (
            <>
              <Text style={styles.caseTitle}>{invoice.caseName}</Text>
              <View style={styles.section}>
                <View style={styles.container}>
                  {!!timeActivities.length && (
                    <InvoiceDocumentTable
                      timezone={timezone}
                      activities={timeActivities}
                      subTotal={invoice.timeSubtotal}
                      title="TIME CHARGES"
                      activityType="time"
                    />
                  )}
                </View>
              </View>
            </>
          )}
          <View style={[styles.section, invoice.caseFeeType ? {} : { marginTop: 120 }]}>
            <View style={styles.container}>
              {!!expenseActivities.length && invoice.expenseSubtotal && (
                <InvoiceDocumentTable
                  timezone={timezone}
                  activities={expenseActivities}
                  subTotal={invoice.expenseSubtotal}
                  activityType="expense"
                  title={invoice.caseFeeType ? 'EXPENSE CHARGES' : ''}
                />
              )}
              {!invoice.expenseSubtotal && !invoice.timeSubtotal && (
                <PartialAmountTable
                  timezone={timezone}
                  activities={expenseActivities}
                  amount={invoice.invoiceAmount}
                  caseAmount={invoice.caseAmount}
                  paidAmount={invoice.caseAmountPaidToDateSubtotal}
                />
              )}
            </View>
          </View>
        </View>
        <View style={styles.pageInfoSection} fixed>
          <Text
            style={styles.pageInfoTitle}
            render={({ pageNumber, totalPages }) =>
              totalPages > 1 && pageNumber !== totalPages && 'Continue on the next page'
            }
            fixed
          />
          <Text
            style={styles.pageInfoTitle}
            render={({ pageNumber, totalPages }) => `Page: ${pageNumber} of ${totalPages}`}
            fixed
          />
        </View>
        <InvoiceDocumentFooter
          invoice={invoice}
          timeSubTotal={invoice.timeSubtotal}
          expenseSubTotal={invoice.expenseSubtotal}
        />
      </Page>
      {download &&
        summary &&
        getInvoiceSummaryForInvoice(summary).map((credit, i) => (
          <Page>
            <CreditAdjustmentEntry invoice={invoice} credit={credit} timezone={timezone} />
          </Page>
        ))}
    </Document>
  )
}

export default InvoiceDocument
