import React from 'react'
import { TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  outlinedRoot: {
    width: '100%',
    padding: '10px 10px 30px 10px',
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: 1.69,
    resize: 'none',
    borderRadius: 4,
    border: `solid 1px ${colors.BASIC.COLOR[20]}`,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: colors.BASIC.COLOR[10],
    },
  },
  textAreaWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    bottom: 5,
    right: 0,
    fontSize: 14,
    padding: '0 10px 10px 0',
    margin: '0 20px',
    width: 'calc(100% - 35px)',
    textAlign: 'right',
    backgroundColor: 'transparent',
    color: colors.BASIC.COLOR[20],
  },
})

const TextArea = props => {
  const classes = useStyles()
  const {
    value,
    handleChange,
    disabled,
    characterLimit,
    placeholder,
    wrapperClass,
    areaClass,
    name,
    rowsMax = 25,
    rowsMin = 5,
    onKeyDown,
    showCharacterLimit = true,
  } = props
  return (
    <div className={classNames({ [classes.textAreaWrapper]: true, [wrapperClass]: true })}>
      <TextareaAutosize
        disabled={disabled}
        className={classNames({ [classes.outlinedRoot]: true, [areaClass]: true })}
        placeholder={placeholder}
        value={value}
        variant="outlined"
        rowsMax={rowsMax}
        rowsMin={rowsMin}
        onChange={handleChange}
        maxLength={characterLimit}
        name={name}
        onKeyDown={onKeyDown}
      />
      {showCharacterLimit && characterLimit && (
        <div className={classes.counter}>{`${value.length}/${characterLimit}`}</div>
      )}
    </div>
  )
}

export default TextArea
