import React from 'react'
import { Button, Icon, Modal, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  degreeDeleteModal: {
    width: 648,
  },
  degreeDeleteContainer: {
    margin: 40,
  },
  degreeDeleteTitle: {
    marginBottom: 16,
  },
  degreeDeleteIcon: {
    color: 'red',
    fontSize: 32,
    marginRight: 8,
  },
  degreeDeleteSubtitle: {
    marginBottom: 32,
  },
  degreeFormButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  degreeCancelBtn: {
    marginRight: 16,
  },
})

const DeleteDegreeModal = ({ open, onClose, onSubmit, degree }) => {
  const classes = useStyles()

  return (
    <Modal open={open} handleClose={onClose} maxWidth={false}>
      <div className={classes.degreeDeleteModal}>
        <div className={classes.degreeDeleteContainer}>
          <Typography className={classes.degreeDeleteTitle} variant="h4">
            <Icon style={classes.degreeDeleteIcon} iconClass="las la-exclamation-triangle" />
            Delete this degree?
          </Typography>
          <Typography className={classes.degreeDeleteSubtitle} variant="subtitle1">
            This degree will be permanently deleted from your profile.
          </Typography>
          <div className={classes.degreeFormButtonContainer}>
            <Button className={classes.degreeCancelBtn} type="reset" variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" onClick={() => onSubmit(degree)} variant="primary">
              Yes, please
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteDegreeModal
