import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FolderListItem, CreateFolderRow, Typography } from 'components'
import { sortVaultFiles } from 'utils/vault/vault-helpers'

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  iconContainer: {
    width: 27,
  },
  emptyStateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
})

const FoldersList = ({
  children,
  selectedFolder,
  setSelectedFolder,
  addFolder,
  defaultSubFolderName,
  nameCheck,
  onCreateFolderConfirm,
  isCaseFolderSelected,
  cancelCreateFolder,
}) => {
  const [openFolders, setOpenedFolders] = useState([])
  const classes = useStyles()
  const sortedChildren = sortVaultFiles(children, 'name', true)

  const openSubFolders = folder => {
    setOpenedFolders(folders => [...folders, folder.uuid])
  }

  const hideSubFolders = folder => {
    setOpenedFolders(openFolders.filter(openFolder => openFolder !== folder.uuid))
  }

  useEffect(() => {
    if (!selectedFolder) return
    if (addFolder) {
      const openFolder = openFolders.find(folder => selectedFolder.uuid === folder)
      if (!openFolder) setOpenedFolders(folders => [...folders, selectedFolder.uuid])
    }
  }, [addFolder, selectedFolder])

  return (
    <div>
      {children.length ? (
        sortedChildren.map(folder => (
          <div key={folder.uuid}>
            <FolderListItem
              folder={folder}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              addFolder={addFolder}
              cancelCreateFolder={cancelCreateFolder}
              defaultSubFolderName={defaultSubFolderName}
              nameCheck={nameCheck}
              onCreateFolderConfirm={onCreateFolderConfirm}
              openFolders={openFolders}
              openSubFolders={openSubFolders}
              hideSubFolders={hideSubFolders}
            />
            {
              <div className={classes.row}>
                <div className={classes.iconContainer} />
                {selectedFolder && selectedFolder.uuid === folder.uuid && addFolder && (
                  <CreateFolderRow
                    onClose={cancelCreateFolder}
                    defaultSubFolderName={defaultSubFolderName}
                    nameCheck={nameCheck}
                    handleSave={onCreateFolderConfirm}
                  />
                )}
              </div>
            }
          </div>
        ))
      ) : (
        <div className={classes.emptyStateContainer}>
          {!addFolder && <Typography variant="body2">This folder is empty</Typography>}
        </div>
      )}
      {isCaseFolderSelected && addFolder && (
        <CreateFolderRow
          onClose={cancelCreateFolder}
          defaultSubFolderName={defaultSubFolderName}
          nameCheck={nameCheck}
          handleSave={onCreateFolderConfirm}
        />
      )}
    </div>
  )
}

export default FoldersList
