import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, FormikInput, FormikSelect, Modal, Typography, FormikAutocomplete } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  degreeModalContainer: {
    width: 668,
  },
  degreeForm: {
    margin: '32px 40px',
    position: 'relative',
    backgroundColor: 'white',
  },
  twoColumnContainer: {
    display: 'flex',
  },
  leftInput: {
    marginRight: 32,
  },
  twoColumnInput: {
    width: 281,
  },
  degreeFormButtonContainer: {
    marginTop: 12,
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  degreeCancelBtn: {
    marginRight: 40,
  },
  degreeModalTitle: {
    margin: '32px 104px 24px 40px',
  },
})

const validationSchema = Yup.object({
  schoolName: Yup.string()
    .required('Please fill out this field')
    .trim()
    .max(100, 'School Name should be maximum 100 characters'),
  areaOfStudy: Yup.string()
    .trim()
    .max(100, 'Area of study should be maximum 100 characters')
    .required('Please fill out this field'),
  degreeLevel: Yup.string().required('Please fill out this field'),
  graduated: Yup.string().required('Please fill out this field'),
  degreeTitle: Yup.string().max(100, 'Degree title should be maximum 100 characters'),
})

const graduatedYears = []
for (let i = 2020; i > 1949; i--) {
  graduatedYears.push({ value: i, label: i })
}

const DegreeModal = ({ degree, open, onClose, title, onSubmit, allDegrees }) => {
  const classes = useStyles()

  return (
    <Modal open={open} handleClose={onClose} maxWidth={false}>
      <div className={classes.degreeModalContainer}>
        <Typography className={classes.degreeModalTitle} variant="h4">
          {title}
        </Typography>
        <Formik
          initialValues={{
            schoolName: degree ? degree.schoolName : '',
            areaOfStudy: degree ? degree.areaOfStudy : '',
            degreeLevel: degree ? degree.degreeLevel : '',
            graduated: degree ? degree.graduated : '',
          }}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange={false}
          enableReinitialize
          onSubmit={(values, actions) => {
            onSubmit({ ...values, id: degree ? degree.id : Math.random() })
          }}
        >
          <Form className={classes.degreeForm}>
            <FormikInput variant="outlined" name="schoolName" label="School Name" fullWidth type="text" />
            <FormikInput variant="outlined" name="areaOfStudy" label="Major / Field of Study" type="text" fullWidth />
            <div className={classes.twoColumnContainer}>
              <div className={classes.leftInput}>
                <FormikAutocomplete
                  variant="outlined"
                  name="degreeLevel"
                  label="Degree"
                  type="text"
                  fullWidth
                  InputProps={{ placeholder: 'Please Select' }}
                  containerClasses={classes.twoColumnInput}
                  options={allDegrees}
                />
              </div>
              <FormikAutocomplete
                variant="outlined"
                name="graduated"
                label="Graduated"
                type="text"
                fullWidth
                InputProps={{ placeholder: 'Please Select' }}
                containerClasses={classes.twoColumnInput}
                options={graduatedYears}
              />
            </div>
            <div className={classes.degreeFormButtonContainer}>
              <Button className={classes.degreeCancelBtn} type="reset" variant="text" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}

export default DegreeModal
