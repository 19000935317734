export const OPEN_VAULT_MODAL = 'OPEN_VAULT_MODAL'
export const CLOSE_VAULT_MODAL = 'CLOSE_VAULT_MODAL'
export const SET_VAULT_USER = 'SET_VAULT_USER'
export const SET_VAULT_CASE = 'SET_VAULT_CASE'
export const SET_VAULT_SORTING = 'SET_VAULT_SORTING'
export const SET_VAULT_TRASH_SORTING = 'SET_VAULT_TRASH_SORTING'
export const SET_VAULT_FOLDER_LIST = 'SET_VAULT_FOLDER_LIST'
export const GET_VAULT_FILES_LIST_START = 'GET_VAULT_FILES_LIST_START'
export const GET_VAULT_FILES_LIST_END = 'GET_VAULT_FILES_LIST_END'
export const SET_VAULT_SELECTED_FOLDERS = 'SET_VAULT_SELECTED_FOLDERS'
export const GET_VAULT_SUBFOLDER_ITEMS = 'GET_VAULT_SUBFOLDER_ITEMS'
export const SET_IS_MOVING = 'SET_IS_MOVING'
export const SET_COPING = 'SET_COPING'
export const SET_RESTORING = 'SET_RESTORING'
export const SET_VAULT_FOLDER_CREATION_PROGRESS = 'SET_VAULT_FOLDER_CREATION_PROGRESS'
export const SET_VAULT_UPLOAD_FILE = 'SET_VAULT_UPLOAD_FILE'
export const SET_VAULT_UPLOAD_FILE_END = 'SET_VAULT_UPLOAD_FILE_END'
export const SET_VAULT_UPLOAD_FILE_START = 'SET_VAULT_UPLOAD_FILE_START'
export const SET_VAULT_UPLOAD_FILE_PROGRESS = 'SET_VAULT_UPLOAD_FILE_PROGRESS'
export const SET_VAULT_UPLOAD_FILE_SUCCESS = 'SET_VAULT_UPLOAD_FILE_SUCCESS'
export const SET_VAULT_UPLOAD_FILE_FAILURE = 'SET_VAULT_UPLOAD_FILE_FAILURE'
export const SET_VAULT_UPLOAD_FILE_CANCELLED = 'SET_VAULT_UPLOAD_FILE_CANCELLED'
export const SET_VAULT_UPLOAD_FILE_UUID = 'SET_VAULT_UPLOAD_FILE_UUID'
export const SET_VAULT_PREVIEW_ITEM = 'SET_VAULT_PREVIEW_ITEM'
export const SET_VAULT_SELECTED_ITEMS = 'SET_VAULT_SELECTED_ITEMS'
export const SET_VAULT_ITEMS_ACTION = 'SET_VAULT_ITEMS_ACTION'
export const SET_VAULT_UPLOAD_ITEMS_MODAL_OPEN = 'SET_VAULT_UPLOAD_ITEMS_MODAL_OPEN'
export const SET_VAULT_PLAN_ACCEPTED = 'SET_VAULT_PLAN_ACCEPTED'
export const SET_TABLE_CONFIGURATION = 'SET_TABLE_CONFIGURATION'
export const SET_VAULT_EVENTS = 'SET_VAULT_EVENTS'
export const SET_VAULT_EVENTS_LOADING_START = 'SET_VAULT_EVENTS_LOADING_START'
export const SET_VAULT_EVENTS_LOADING_END = 'SET_VAULT_EVENTS_LOADING_END'
export const SET_VAULT_PLANS_RATE = 'SET_VAULT_PLANS_RATE'
export const SET_VAULT_SIZE_EXCEEDED = 'SET_VAULT_SIZE_EXCEEDED'
export const SET_VAULT_COPY_SIZE_EXCEEDED = 'SET_VAULT_COPY_SIZE_EXCEEDED'
export const SET_VAULT_SETTINGS = 'SET_VAULT_SETTINGS'
export const SET_VAULT_SETTINGS_LOADING_START = 'SET_VAULT_SETTINGS_LOADING_START'
export const SET_VAULT_SETTINGS_LOADING_END = 'SET_VAULT_SETTINGS_LOADING_END'
export const SET_VAULT_USAGE = 'SET_VAULT_USAGE'
export const SET_VAULT_USAGE_LOADING_START = 'SET_VAULT_USAGE_LOADING_START'
export const SET_VAULT_USAGE_LOADING_END = 'SET_VAULT_USAGE_LOADING_END'
export const GET_VAULT_TRASH_LIST_START = 'GET_VAULT_TRASH_LIST_START'
export const GET_VAULT_TRASH_LIST_END = 'GET_VAULT_TRASH_LIST_END'
export const SET_VAULT_PAUSED_UPLOAD_FILES = 'SET_VAULT_PAUSED_UPLOAD_FILES'
export const SET_VAULT_SINGLE_ACTION_ITEM = 'SET_VAULT_SINGLE_ACTION_ITEM'
export const SET_LOADING_DELETE_ITEM_START = 'SET_LOADING_DELETE_ITEM_START'
export const SET_LOADING_DELETE_ITEM_END = 'SET_LOADING_DELETE_ITEM_END'
export const GET_VAULT_PREVIEW_ITEM_START = 'GET_VAULT_PREVIEW_ITEM_START'
export const GET_VAULT_PREVIEW_ITEM_END = 'GET_VAULT_PREVIEW_ITEM_END'
export const SET_SECURE_VAULT_SORTING = 'SET_SECURE_VAULT_SORTING'
export const SET_VAULT_OPEN_PLACE = 'SET_VAULT_OPEN_PLACE'
