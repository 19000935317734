import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { IconButton, SvgIcon, Link } from 'components'
import { ReactComponent as IconExpand } from 'static/footerMenu/menu.svg'
import { ReactComponent as IconClose } from 'static/footerMenu/close.svg'
import { openMobileMenu, closeMobileMenu, goToZendesk } from 'store/actions/common'
import { navigateTo, navigateToWindow } from 'store/actions/navigation'
import { useParams } from 'react-router-dom'
import { EVENTS } from 'utils/analytics/Events'
import Analytics from 'utils/analytics/AnalyticsService'

const useStyles = makeStyles({
  leftMenu: {
    width: 178,
    height: 338,
    padding: '10px 16px 10px 24px',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    background: colors.WHITE,
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
    '@media (max-width:767px)': {
      position: 'absolute',
      width: '100vw',
      height: 'auto',
      minHeight: '100vh',
      top: 0,
      left: 0,
      zIndex: 10000,
      background: colors.WHITE,
      paddingTop: 82,
    },
  },
  closed: {
    '@media (max-width:767px)': {
      display: 'none',
    },
  },
  closeButton: {
    alignSelf: 'flex-end',
    width: 24,
    height: 24,
    top: 24,
    right: 24,
    fill: '#51504F',
    cursor: 'pointer',
    '&:hover': {
      fill: '#EE5F10',
    },
    '@media (max-width:767px)': {
      display: 'block',
    },
  },
  leftMenuLink: {
    fontSize: 14,
    height: 34,
    fontWeight: 500,
    cursor: 'pointer',
    '@media (max-width:767px)': {
      width: '100%',
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 12,
    },
  },
  leftMenuLinkItem: {
    color: colors.BASIC.COLOR[70],
    textDecoration: 'none',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  handleMenuButton: {
    display: 'none',
    width: 18,
    height: 18,
    flexWrap: 'wrap',
    position: 'absolute',
    left: 18,
    top: 18,
    zIndex: 10000,
    '@media (max-width:767px)': {
      display: 'flex',
    },
  },
  rectangle: {
    width: '100%',
    height: 2,
    backgroundColor: colors.PRIMARY.COLOR[50],
    borderRadius: 4,
  },
  active: {
    color: colors.PRIMARY.COLOR[50],
  },
  menuButton: {
    width: 62,
    height: 62,
    stroke: '#51504F',
    '&:hover': {
      stroke: '#EE5F10',
    },
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
  },
  iconUpload: {
    fill: 'inherit',
  },
  infoWrapperMenu: {
    position: 'fixed',
    top: 76,
    right: 40,
    zIndex: 10,
  },
  infoWrapperMenuFull: {
    top: 76,
    right: 40,
    '@media (max-width:767px)': {
      top: 0,
      right: '100%',
    },
  },
})

const links = [
  { title: 'about XIRA', route: '/about-xira' },
  { title: 'XIRA for lawyers', route: '/xira-for-lawyers' },
  { title: 'FAQ', route: '/' },
  { title: 'support', route: '/' },
  { title: 'user agreement', route: '/user-agreement' },
  { title: 'privacy policy', route: '/privacy-policy' },
  { title: 'press', route: 'https://media.xira.com/press.html' },
  { title: 'contact us', route: '/contact-us' },
]

const linksToAnalyticsEvents = [null, null, EVENTS.CLICK_FAQ, EVENTS.CLICK_SUPPORT, null, null, EVENTS.VIEW_PRESS, null]

const InfoLeftMenu = ({ page }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const showMobileMenu = useSelector(state => state.common.showMobileMenu)
  const { footerTab } = useParams()
  let selectedTab
  if (page) {
    selectedTab = '/' + page
  } else {
    selectedTab = '/' + footerTab
  }

  return (
    <div className={classNames(classes.infoWrapperMenu, { [classes.infoWrapperMenuFull]: showMobileMenu })}>
      {showMobileMenu ? (
        <div className={classNames(classes.leftMenu, { [classes.closed]: !showMobileMenu })}>
          <div className={classes.closeButton} onClick={() => dispatch(closeMobileMenu())}>
            <SvgIcon component={IconClose} viewBox="0 0 23 23" className={classes.iconUpload} />
          </div>
          {links.map((link, i) => {
            return (
              <div key={i} className={classes.leftMenuLink} key={link.title}>
                <Link
                  className={classNames({ [classes.active]: selectedTab === link.route }, classes.leftMenuLinkItem)}
                  onClick={() => {
                    dispatch(closeMobileMenu())
                    if (linksToAnalyticsEvents[i]) {
                      Analytics.track(linksToAnalyticsEvents[i])
                    }
                    if (link.title === 'FAQ') {
                      dispatch(goToZendesk(true))
                    } else if (link.title === 'support') {
                      dispatch(goToZendesk(true, true))
                    } else if (link.title === 'press') {
                      dispatch(navigateToWindow(link.route))
                    } else {
                      dispatch(navigateTo(link.route))
                    }
                  }}
                >
                  {link.title}
                </Link>
              </div>
            )
          })}
        </div>
      ) : (
        <IconButton className={classes.menuButton} onClick={() => dispatch(openMobileMenu())}>
          <SvgIcon component={IconExpand} viewBox="0 0 24 19" className={classes.iconUpload} />
        </IconButton>
      )}
    </div>
  )
}

export default InfoLeftMenu
