import * as CONSTANTS from 'store/constants/activities'

const initialState = {
  filters: {
    search: '',
    client: null,
    invoicesRange: { from: null, to: null },
    entryRange: { from: null, to: null },
    activityType: null,
    invoiceGenerationStatus: null,
    case: null,
  },
  initialFilters: {
    search: '',
    client: null,
    invoicesRange: { from: null, to: null },
    entryRange: { from: null, to: null },
    activityType: null,
    invoiceGenerationStatus: null,
    case: null,
  },
  sort: {
    property: 'updated_at',
    isAsc: false,
  },
  initialSort: {
    property: 'updated_at',
    isAsc: false,
  },
  activities: [],
  activitiesAreLoading: false,
  activitiesFileAreLoading: false,
  activitiesLength: 0,
  activity: {},
  activityIsLoading: false,
  tableConfiguration: {
    tablePage: 0,
    limit: 20,
    offset: 0,
  },
}

const activities = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_ACTIVITY_START:
      return {
        ...state,
        activityIsLoading: true,
      }
    case CONSTANTS.GET_ACTIVITY_END:
      return {
        ...state,
        activityIsLoading: false,
        activity: action.payload,
      }
    case CONSTANTS.GET_ACTIVITIES_START:
      return {
        ...state,
        activitiesAreLoading: true,
      }
    case CONSTANTS.GET_ACTIVITIES_END:
      return {
        ...state,
        activitiesAreLoading: false,
        activities: action.payload,
        activitiesLength: action.length,
      }
    case CONSTANTS.GET_ACTIVITIES_FILE_START:
      return {
        ...state,
        activitiesFileAreLoading: true,
      }
    case CONSTANTS.GET_ACTIVITIES_FILE_END:
      return {
        ...state,
        activitiesFileAreLoading: false,
      }
    case CONSTANTS.SET_TABLE_CONFIGURATION:
      return {
        ...state,
        tableConfiguration: {
          ...state.tableConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      }
    case CONSTANTS.SET_LIMIT:
      return {
        ...state,
        limit: action.payload,
      }
    case CONSTANTS.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      }
    case CONSTANTS.RESET_FILTERS:
      return {
        ...state,
        filters: state.initialFilters,
      }
    case CONSTANTS.RESET_SORTING:
      return {
        ...state,
        sort: state.initialSort,
      }
    case CONSTANTS.RESET_ACTIVITY:
      return {
        ...state,
        activity: {},
      }
    case CONSTANTS.SET_PREDEFINED_ACTIVITY:
      return {
        ...state,
        activity: {
          ...state.activity,
          [action.property]: action.value,
        },
      }
    case CONSTANTS.SET_SORTING:
      return {
        ...state,
        sort: {
          property: action.property,
          isAsc: action.isAsc,
        },
      }
    default:
      return state
  }
}

export default activities
