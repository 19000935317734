import React from 'react'
import { colors, CONSTANTS } from 'constants/index'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  footerSectionWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: 64,
  },
  footerSection: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: 64,
    backgroundColor: colors.PRIMARY.COLOR[0],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 32px',
  },
  footerTitle: {
    fontSize: 6,
    fontFamily: 'Helvetica-Bold',
    color: colors.BASIC.COLOR[80],
  },
  footerSubtitle: {
    marginTop: 2,
    fontSize: 12,
  },
  lawTitle: {
    fontSize: 10,
    fontWeight: 500,
    color: colors.BASIC.COLOR[80],
  },
  amount: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    color: colors.PRIMARY.COLOR[50],
  },
})

const InvoiceDocumentFooter = ({ invoice, credit, isSummary }) => (
  <View fixed style={styles.footerSection}>
    <Text style={styles.lawTitle}>law made easy</Text>
    <View style={styles.container}>
      {credit ? (
        <>
          <Text style={styles.footerTitle}>BALANCE DUE</Text>
          <Text style={styles.amount}>${credit.current_invoice_amount_for_display}</Text>
        </>
      ) : (
        <>
          <Text style={styles.footerTitle}>
            {isSummary
              ? 'CURRENT BALANCE'
              : invoice.caseFeeType === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.FLAT_FEE
              ? 'BALANCE REMAINING'
              : 'TOTAL'}
          </Text>
          <Text style={styles.amount}>{isSummary ? `$${invoice.amountDue}` : `$${invoice.invoiceAmount}`}</Text>
        </>
      )}
    </View>
  </View>
)

export default InvoiceDocumentFooter
