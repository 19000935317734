import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Icon, Modal, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../constants'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 25,
  },
  boxBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: 20,
  },
  content: {
    columnCount: 2,
  },
})

const LanguageModal = props => {
  const { languages, isOpen, onClose, onSaveLanguages } = props
  const classes = useStyles()
  const [userLanguages, setUserLanguages] = useState(languages)

  useEffect(() => {
    setUserLanguages(languages)
  }, [languages])

  const onChange = (uuid, value) => {
    const updatedLanguages = userLanguages.map(item =>
      item.uuid === uuid
        ? {
            ...item,
            checked: value,
          }
        : item
    )
    setUserLanguages(updatedLanguages)
  }

  const onSaveClick = () => {
    onSaveLanguages(userLanguages)
  }

  const onModalClose = () => {
    setUserLanguages(languages)
    onClose()
  }

  return (
    <Modal open={isOpen} handleClose={onModalClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          Select the languages you speak
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          We have many clients who appreciate lawyers who can speak their language.
        </Typography>
        <div className={classes.content}>
          {userLanguages &&
            userLanguages.map(language => (
              <div className={classes.checkbox} key={language.uuid}>
                <Checkbox
                  checked={language.checked}
                  label={language.name}
                  onChange={(e, value) => onChange(language.uuid, value)}
                  disabled={language.name === 'English'}
                />
              </div>
            ))}
        </div>

        <div className={classes.boxBtn}>
          <Button variant="text" onClick={onModalClose}>
            Cancel
          </Button>
          <Button variant="primary" className={classes.saveButton} onClick={onSaveClick}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LanguageModal
