import React, { useEffect } from 'react'
import {
  Tooltip,
  PoweredByStripeLogo,
  PaymentCardElement,
  InputLabel,
  TextField,
  Typography,
  ClientCardsRadioButtonList,
} from 'components'
import { colors } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    marginRight: 4,
    fontSize: 15,
  },
  btnSubmit: {
    marginLeft: 30,
  },
  icon: {
    marginRight: 7,
  },
  tooltip: {
    padding: 15,
    maxWidth: 440,
  },
  tooltipText: {
    marginTop: 15,
  },
  tooltipLabel: {
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  stripeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  stripeLogo: {
    width: 120,
    height: 26,
  },
  cardForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 32,
  },
  cardsContainer: {
    marginTop: 20,
    marginBottom: 30,
  },
  notched: {
    borderColor: colors.BASIC.COLOR[20],
  },
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
})

const TooltipContentCSV = () => (
  <>
    <Typography variant="subtitle1">
      The CVV/CVC code (Card Verification Value/Code) is located on the back of your credit/debit card on the right side
      of the white signature strip.
    </Typography>
  </>
)

const ClientPaymentList = ({ userCards, paymentCard, setPaymentCard, userCardData, setUserCardData, stripeError }) => {
  const classes = useStyles()
  useEffect(() => {
    if (userCardData.payment_method_id) {
      const newCard = userCards.find(card => userCardData.payment_method_id === card.paymentMethodId)
      if (newCard) setPaymentCard(newCard)
    }
  }, [userCards, userCardData])

  const onLabelChange = e => {
    setUserCardData({
      ...userCardData,
      label: e.target.value,
    })
  }
  const onCardChange = uuid => {
    const card = userCards.find(card => card.uuid === uuid)
    setPaymentCard(card)
  }
  return (
    <>
      {userCards.length ? (
        <div className={classes.cardsContainer}>
          {paymentCard && (
            <ClientCardsRadioButtonList userCards={userCards} value={paymentCard.uuid} onChange={onCardChange} />
          )}
        </div>
      ) : (
        <>
          <div className={classes.stripeContainer}>
            <PoweredByStripeLogo className={classes.stripeLogo} />
          </div>
          <div className={classes.cardForm}>
            <div className={classes.btnContainer}>
              <InputLabel>Credit or debit card*</InputLabel>
              <Tooltip
                placement="bottom-start"
                childrenClass={classes.tooltipLabel}
                title={<TooltipContentCSV />}
                tooltipClass={classes.tooltip}
                interactive
              >
                What is CVC?
              </Tooltip>
            </div>
            <PaymentCardElement stripeError={stripeError} />
            <InputLabel>Card nickname</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              onChange={onLabelChange}
              value={userCardData.label}
              inputProps={{
                maxLength: 50,
              }}
              InputProps={{
                classes: { notchedOutline: classes.notched },
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default ClientPaymentList
