import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, ContactInfoForm, SvgIcon } from 'components'
import { isEqual } from 'lodash'
import { MobileVerificationModal } from '../profileCreation/MobileVerificationModal'

const useStyles = makeStyles({
  selectIcon: {
    marginRight: '7px',
  },
  saveButton: {
    width: 80,
    marginLeft: 24,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 31,
  },
})

const ContactInfoSection = props => {
  const {
    changeContactInfo,
    saveProfileContactInfo,
    states,
    contactInfo,
    countries,
    containerClass,
    showSnackbar,
    updateLocalContactInfo,
    executeScroll,
    languages,
    languagesDB,
    saveCurrentUserLanguages,
    updateLocalUserLanguages,
  } = props
  const classes = useStyles()
  const [isSubmit, setIsSubmit] = useState(false)
  const [verifyNumber, setVerifyNumber] = useState('')
  const onClose = () => setVerifyNumber('')
  const mockCountries = [
    {
      label: 'USA',
      value: 840,
      icon: (
        <SvgIcon classes={{ root: classes.selectIcon }}>
          <path fill="#F0F0F0" d="M16 2.667H0v10.666h16V2.667z" />
          <path
            fill="#D80027"
            d="M16 4H0v1.333h16V4zM16 6.667H0V8h16V6.667zM16 9.333H0v1.333h16V9.333zM16 12H0v1.333h16V12z"
          />
          <path fill="#2E52B2" d="M8 2.667H0V8.41h8V2.667z" />
          <path
            fill="#F0F0F0"
            d="M3.12 5.02l-.129.396h-.417l.337.245-.128.396.337-.245.337.245-.129-.396.337-.245h-.417L3.12 5.02zM3.248 6.846L3.12 6.45l-.129.396h-.417l.337.245-.128.396.337-.244.337.244-.129-.396.337-.245h-.417zM1.487 6.846l-.129-.396-.129.396H.812l.338.245-.13.396.338-.244.337.244-.129-.396.337-.245h-.416zM1.358 5.02l-.129.396H.812l.338.245-.13.396.338-.245.337.245-.129-.396.337-.245h-.416l-.129-.396zM3.12 3.59l-.129.395h-.417l.337.245-.128.397.337-.245.337.245-.129-.397.337-.245h-.417L3.12 3.59zM1.358 3.59l-.129.395H.812l.338.245-.13.397.338-.245.337.245-.129-.397.337-.245h-.416l-.129-.396zM4.881 5.02l-.128.396h-.417l.337.245-.129.396.338-.245.337.245-.13-.396.338-.245H5.01l-.128-.396zM5.01 6.846l-.128-.396-.13.396h-.416l.337.245-.129.396.338-.244.337.244-.13-.396.338-.245H5.01zM6.77 6.846L6.64 6.45l-.129.396h-.416l.337.245-.129.396.337-.244.337.244-.129-.396.338-.245H6.77zM6.641 5.02l-.129.396h-.416l.337.245-.129.396.337-.245.337.245-.129-.396.338-.245H6.77L6.64 5.02zM4.881 3.59l-.128.395h-.417l.337.245-.129.397.338-.245.337.245-.13-.397.338-.245H5.01l-.128-.396zM6.641 3.59l-.129.395h-.416l.337.245-.129.397.337-.245.337.245-.129-.397.338-.245H6.77L6.64 3.59z"
          />
        </SvgIcon>
      ),
    },
  ]

  const updateContactInfo = event => {
    updateLocalContactInfo({
      [event.target.name]: event.target.value,
    })
  }

  const onSubmit = async values => {
    try {
      setIsSubmit(true)
      const v = await saveProfileContactInfo(values)
      await saveCurrentUserLanguages(languagesDB)
      changeContactInfo(values)
      showSnackbar('success', 'Contact info changed')
      setIsSubmit(false)
      if (!v.is_phone_confirmed) setVerifyNumber(v.phone)
    } catch (e) {
      showSnackbar('error', 'There was some error while changing contact info')
      setIsSubmit(false)
    } finally {
      executeScroll()
    }
  }

  const onReset = () => {
    executeScroll()
    updateLocalUserLanguages(languages)
    updateLocalContactInfo({
      ...contactInfo,
    })
  }

  const Footer = ({ disabled }) => (
    <div className={classes.buttons}>
      <Button variant="text" disabled={(disabled && isEqual(languages, languagesDB)) || isSubmit} type="reset">
        cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        disabled={(disabled && isEqual(languages, languagesDB)) || isSubmit}
        className={classes.saveButton}
        isButtonBlocked={isSubmit}
        circularSize={22}
      >
        Save
      </Button>
    </div>
  )

  return (
    <div className={containerClass}>
      <ContactInfoForm
        onReset={onReset}
        updateContactInfo={updateContactInfo}
        languages={languages}
        languagesDB={languagesDB}
        saveCurrentUserLanguages={saveCurrentUserLanguages}
        updateLocalUserLanguages={updateLocalUserLanguages}
        onSubmit={onSubmit}
        contactInfo={contactInfo}
        countries={mockCountries}
        states={states}
        footer={<Footer />}
      />
      <MobileVerificationModal open={!!verifyNumber} number={verifyNumber} onOk={onClose} onClose={onClose} />
    </div>
  )
}

export default ContactInfoSection
