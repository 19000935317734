const getConvertedLabel = minutes => {
  const minutesPerDay = 1440
  const minutesPerHour = 60

  if (minutes >= minutesPerDay) {
    const days = minutes / minutesPerDay
    return `${days} ${days === 1 ? 'day' : 'days'}`
  } else if (minutes >= minutesPerHour) {
    const hours = minutes / minutesPerHour
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`
  } else {
    return `${minutes} minutes`
  }
}

export const parseTimeOptions = options => {
  return Object.values(options).reduce((previous, current) => {
    return [...previous, { value: current, label: getConvertedLabel(current) }]
  }, [])
}
