import React, { useState } from 'react'
import { TextField, IconButton, Icon } from 'components'
import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
})

const TableSearch = ({ search, setSearch, setFilter, setTableConfiguration, getSearchResults, searchClass }) => {
  const classes = useStyles()

  const onSearch = () => {
    setFilter(search)
    setTableConfiguration()
    getSearchResults()
  }

  const onKey = event => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const onSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <TextField
      variant="outlined"
      className={searchClass}
      value={search}
      placeholder="Search"
      InputProps={{
        endAdornment: (
          <IconButton onClick={onSearch} className={classes.searchButton}>
            <Icon iconClass="las la-search" style={classes.searchIcon} />
          </IconButton>
        ),
      }}
      onChange={onSearchChange}
      onKeyPress={onKey}
    />
  )
}

export default TableSearch
