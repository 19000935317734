import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Modal, Button } from 'components'
import { colors } from '../../../../../constants/colors'

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  boxBtn: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  saveButton: {
    marginLeft: 20,
    minWidth: 80,
  },
  icon: {
    fontSize: 18,
    color: colors.BASIC.COLOR[80],
    borderRight: '1px solid #E8E8E8',
    height: 50,
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textField: {
    marginTop: 26,
    marginBottom: 32,
  },
  input: {
    paddingLeft: 0,
  },
})

const AccountsModal = ({ isOpen, onClose, saveButtonText, onSave, children, buttonDisabled }) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        {children}
        <div className={classes.boxBtn}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" className={classes.saveButton} onClick={onSave} disabled={buttonDisabled}>
            {saveButtonText || 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AccountsModal
