import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import moment from 'moment'
import { colors } from 'constants/index'
import { ReactComponent as FacebookIcon } from 'static/footer/ic-facebook.svg'
import { ReactComponent as TwitterIcon } from 'static/footer/ic-twitter.svg'
import { ReactComponent as LinkedInIcon } from 'static/footer/ic-linkedin.svg'
import { ReactComponent as YouTubeIcon } from 'static/footer/ic-youtube.svg'
import { goToZendesk } from 'store/actions/common'
import { navigateToWindow } from 'store/actions/navigation'
import { EVENTS } from 'utils/analytics/Events'
import Analytics from 'utils/analytics/AnalyticsService'
import { Link, SvgIcon } from 'components'

const useStyles = makeStyles({
  main: {
    flex: '0 0 auto',
    textAlign: 'center',
    background: colors.BASIC.COLOR[0],
    padding: '12px 40px 24px',
    color: colors.BASIC.COLOR[80],
    fontWeight: 500,
    width: '100%',
  },
  tableDiv: {
    display: 'flex',
    margin: '0px auto',
    width: '100%',
    maxWidth: 840,
  },
  table: {
    width: '60%',
    tableLayout: 'fixed',
  },
  table2: {
    width: '40%',
    tableLayout: 'fixed',
  },
  linksWrap: {
    textAlign: 'left',
    fontSize: 13,
    paddingTop: 12,
  },
  linksWrap2: {
    textAlign: 'left',
    fontSize: 13,
    paddingTop: 12,
    '&:last-child': {
      width: 70,
    },
  },
  links: {
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
      cursor: 'pointer',
      borderBottom: '2px solid',
    },
  },
  underline: {
    paddingBottom: 20,
    borderBottom: `0.5px solid ${colors.BASIC.COLOR[10]}`,
  },
  bottomInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    paddingTop: 20,
  },
  rightsReserved: {
    paddingTop: 20,
  },
  socialLink: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    margin: 12,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  youTube: {
    width: 27,
    height: 18,
  },
  '@media(max-width:767px)': {
    main: {
      padding: '12px 15px 24px',
    },
    tableDiv: {
      maxWidth: 320,
      flexDirection: 'column',
    },
    table: {
      width: '100%',
      tableLayout: 'fixed',
    },
    table2: {
      width: '100%',
      tableLayout: 'fixed',
    },
    linksWrap: {
      '&:last-child': {
        width: 102,
      },
    },
    linksWrap2: {
      '&:last-child': {
        width: 102,
      },
    },
  },
})

const footerLinksLeft = {
  top: [
    { title: 'about XIRA', route: 'https://xira.com/p/about-us/' },
    { title: 'XIRA for lawyers', route: 'https://xira.com/p/lawyers/' },
  ],
  bot: [
    { title: 'FAQ', route: 'https://xira.com/fp/xira_faq.html' },
    { title: 'support', route: 'https://xira.com/fp/xira_support.html' },
  ],
}
const footerLinksRight = {
  top: [
    { title: 'user agreement', route: 'https://xira.com/p/xira_user_agreement/' },
    { title: 'contact us', route: 'https://xira.com/p/contact/' },
  ],
  bot: [
    { title: 'privacy policy', route: 'https://xira.com/p/xira_privacy_policy/' },
    { title: 'press', route: 'https://xira.com/p/press/' },
  ],
}

const leftFooterLinksToAnalyticsEvents = [
  [null, null],
  [EVENTS.CLICK_FAQ, EVENTS.CLICK_SUPPORT],
]

const socialLinks = [
  {
    href: 'https://www.facebook.com/XIRA-104146357802827',
    icon: FacebookIcon,
    viewBox: '0 0 24 24',
    analyticsEvent: EVENTS.CLICK_FB,
  },
  {
    href: 'https://twitter.com/xiraconnect',
    icon: TwitterIcon,
    viewBox: '0 0 24 24',
    analyticsEvent: EVENTS.CLICK_TWITTER,
  },
  {
    href: 'https://www.linkedin.com/company/xiraconnect',
    icon: LinkedInIcon,
    viewBox: '0 0 24 24',
    analyticsEvent: EVENTS.CLICK_LINKEDIN,
  },
  {
    href: 'https://www.youtube.com/channel/UC0f6HAlTEkxlpweKwrx8CLQ?view_as=subscriber',
    icon: YouTubeIcon,
    viewBox: '0 0 27 18',
    analyticsEvent: EVENTS.CLICK_YOUTUBE,
  },
]

const Footer = props => {
  const { className } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentYear = moment().year()

  const openLeftFooterLink = (link, indexI, indexJ) => {
    if (leftFooterLinksToAnalyticsEvents[indexI][indexJ]) {
      Analytics.track(leftFooterLinksToAnalyticsEvents[indexI][indexJ])
    }

    if (indexI === 1) {
      if (link.title === 'support') {
        dispatch(goToZendesk(true, true))
      } else {
        dispatch(goToZendesk(true))
      }
    } else if (indexI === 0) {
      dispatch(navigateToWindow(link.route))
    }
  }

  const mountTable1 = () => {
    return Object.values(footerLinksLeft).map((key, indexI) => (
      <tr key={key[0].title}>
        {key.map((link, indexJ) => (
          <td onClick={() => openLeftFooterLink(link, indexI, indexJ)} className={classes.linksWrap} key={link.title}>
            <span className={classes.links}>{link.title}</span>
          </td>
        ))}
      </tr>
    ))
  }
  const mountTable2 = () => {
    return Object.values(footerLinksRight).map(key => (
      <tr key={key[0].title}>
        {key.map(link => (
          <td onClick={() => dispatch(navigateToWindow(link.route))} className={classes.linksWrap2} key={link.title}>
            <span className={classes.links}>{link.title}</span>
          </td>
        ))}
      </tr>
    ))
  }

  const socialNetworkLinks = socialLinks.map((link, idx) => (
    <Link href={link.href} target="_blank" key={link.href} onClick={() => Analytics.track(link.analyticsEvent)}>
      <SvgIcon
        component={link.icon}
        viewBox={link.viewBox}
        className={classNames(classes.socialLink, { [classes.youTube]: idx === 3 })}
      />
    </Link>
  ))

  return (
    <>
      <footer className={classNames({ [classes.main]: true, [className]: !!className })}>
        <div className={classes.tableDiv}>
          <table className={classes.table}>
            <tbody>{mountTable1()}</tbody>
          </table>
          <table className={classes.table2}>
            <tbody>{mountTable2()}</tbody>
          </table>
        </div>
        <div className={classes.underline} />
        <div className={classes.bottomInfo}>
          <div className={classes.btnContainer}>{socialNetworkLinks}</div>
          <div className={classes.rightsReserved}>{`${currentYear} XIRA, All rights reserved.`}</div>
        </div>
      </footer>
    </>
  )
}

export default Footer
