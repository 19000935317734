import axios, { CancelToken } from 'axios'
import { openSnackbar, showAdminMessageModal } from 'store/actions/common'
import * as STORE_CONSTANTS from 'store/constants/notifications/notifications'
import { getErrorDetails } from 'utils/getters/common'
import {
  getDeleteAllNotificationsPath,
  getMarkAllNotificationsReadPath,
  getMarkNotificationReadPath,
  getNotificationsWebSocketPath,
  getUserNotificationsPath,
  getUserUnreadNotificationsPath,
  getReadChatNotificationsPath,
  getUserCurrentNotificationPath,
  getNotificationSettingsPath,
  updateNotificationSettingsPath,
} from 'utils/path-helpers/api'
import { getJWTLocally } from 'utils/auth'
import { CONSTANTS } from 'constants/index'
import { WebsocketService } from 'utils/service/WebsocketService'
import { uniqBy } from 'lodash'

const setNotificationsAreLoading = payload => ({
  type: STORE_CONSTANTS.SET_NOTIFICATIONS_ARE_LOADING,
  payload,
})

export const setNotificationListConfiguration = payload => ({
  type: STORE_CONSTANTS.SET_NOTIFICATION_LIST_CONFIGURATION,
  payload,
})

export const increaseNotificationsOffset = () => (dispatch, getState) => {
  const { limit, offset } = getState().notifications.notificationListConfiguration
  dispatch(
    setNotificationListConfiguration({
      offset: offset + limit,
    })
  )
}

const setUserNotificationsList = (payload, count) => ({
  type: STORE_CONSTANTS.SET_USER_NOTIFICATIONS_LIST,
  payload,
  count,
})

const setUserAdminNotificationsList = (payload, count) => ({
  type: STORE_CONSTANTS.SET_USER_ADMIN_NOTIFICATIONS_LIST,
  payload,
  count,
})

export const getUserNotifications = (isReUpload = true, isAdmin) => async (dispatch, getState) => {
  try {
    if (isReUpload) {
      dispatch(setNotificationsAreLoading(true))
      dispatch(
        setNotificationListConfiguration({
          offset: 0,
        })
      )
    }
    const { data } = await axios.get(getUserNotificationsPath(), {
      params: {
        ...getState().notifications.notificationListConfiguration,
        excluded_type: isAdmin ? null : 5,
        type: isAdmin ? 5 : null,
      },
    })

    if (isReUpload) {
      if (isAdmin) {
        dispatch(setUserAdminNotificationsList(data.results, data.count))
      } else {
        dispatch(setUserNotificationsList(data.results, data.count))
      }

      dispatch(setNotificationsAreLoading(false))
    } else {
      if (isAdmin) {
        dispatch(
          setUserAdminNotificationsList([...getState().notifications.adminNotifications, ...data.results], data.count)
        )
      } else {
        dispatch(setUserNotificationsList([...getState().notifications.notifications, ...data.results], data.count))
      }
    }
  } catch (e) {
    dispatch(openSnackbar('error', getErrorDetails(e) || 'Error while loading user notifications'))
    dispatch(setNotificationsAreLoading(false))
  }
}

export const setUnreadNotificationsCounter = payload => ({
  type: STORE_CONSTANTS.SET_UNREAD_NOTIFICATIONS_COUNTER,
  payload,
})

let cancelUnreadNotificationCountRequest

export const getUnreadNotificationsCount = () => async dispatch => {
  cancelUnreadNotificationCountRequest && cancelUnreadNotificationCountRequest()
  return axios
    .get(getUserUnreadNotificationsPath(), {
      cancelToken: new CancelToken(c => {
        cancelUnreadNotificationCountRequest = c
      }),
    })
    .then(({ data }) => {
      dispatch(setUnreadNotificationsCounter(data))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return
      }
      dispatch(openSnackbar('error', getErrorDetails(e) || 'Error while loading unread notifications'))
    })
}

export const markAllNotificationsAsRead = () => async (dispatch, getState) => {
  try {
    await axios.put(getMarkAllNotificationsReadPath())
    const notifications = getState().notifications.notifications.map(notification => ({
      ...notification,
      unread: false,
    }))
    const adminNotifications = getState().notifications.adminNotifications.map(notification => ({
      ...notification,
      unread: false,
    }))
    dispatch(setUserNotificationsList(notifications, getState().notifications.notificationsLength))
    dispatch(setUserAdminNotificationsList(adminNotifications, getState().notifications.adminNotificationsLength))
    dispatch(
      setUnreadNotificationsCounter({
        admin_messages_unread_count: 0,
        not_admin_messages_unread_count: 0,
        unread_count: 0,
      })
    )
  } catch (e) {
    dispatch(openSnackbar('error', getErrorDetails(e) || 'Error while marking all notifications as read'))
  }
}

export const markNotificationAsRead = (uuid, unread, type) => async (dispatch, getState) => {
  try {
    await axios.put(getMarkNotificationReadPath(uuid))
    let notificationsList
    if (type === CONSTANTS.NOTIFICATION_TYPES.ADMIN_MESSAGE) {
      notificationsList = getState().notifications.adminNotifications
    } else {
      notificationsList = getState().notifications.notifications
    }
    const notifications = notificationsList.map(notification =>
      notification.uuid === uuid
        ? {
            ...notification,
            unread: !unread,
          }
        : notification
    )
    if (type === CONSTANTS.NOTIFICATION_TYPES.ADMIN_MESSAGE) {
      dispatch(setUserAdminNotificationsList(notifications, getState().notifications.adminNotificationsLength))
    } else {
      dispatch(setUserNotificationsList(notifications, getState().notifications.notificationsLength))
    }
    await dispatch(getUnreadNotificationsCount())
  } catch (e) {
    dispatch(openSnackbar('error', getErrorDetails(e) || 'Error while marking notification as read'))
  }
}

export const deleteAllNotifications = () => async dispatch => {
  try {
    await axios.delete(getDeleteAllNotificationsPath())
    dispatch(setUserNotificationsList([], 0))
    dispatch(setUserAdminNotificationsList([], 0))
    dispatch(
      setUnreadNotificationsCounter({
        admin_messages_unread_count: 0,
        not_admin_messages_unread_count: 0,
        unread_count: 0,
      })
    )
  } catch (e) {
    dispatch(openSnackbar('error', getErrorDetails(e) || 'Error while deleting all notifications'))
  }
}

export const showNotificationSnackbar = notification => ({
  type: STORE_CONSTANTS.SHOW_NOTIFICATION_SNACKBAR,
  payload: notification,
})

export const hideNotificationSnackbar = () => ({
  type: STORE_CONSTANTS.HIDE_NOTIFICATION_SNACKBAR,
})

let notificationSubject

export const connectToNotificationService = () => (dispatch, getState) => {
  const token = getJWTLocally()
  notificationSubject = new WebsocketService(getNotificationsWebSocketPath(token))
  notificationSubject.subscribe(msg => {
    if (msg.type === 'notifications') {
      const notification = msg.notification

      if (getState().vault.isVaultModalOpen) {
        dispatch(showNotificationSnackbar(notification))
      }
      if (notification.type === CONSTANTS.NOTIFICATION_TYPES.ADMIN_MESSAGE) {
        dispatch(
          setUserAdminNotificationsList(
            [notification, ...getState().notifications.adminNotifications],
            getState().notifications.adminNotificationsLength + 1
          )
        )
      } else {
        const mergedNotifications = uniqBy([notification, ...getState().notifications.notifications], 'uuid')
        dispatch(setUserNotificationsList(mergedNotifications, mergedNotifications.length))
      }

      dispatch(getUnreadNotificationsCount())
      dispatch(
        setNotificationListConfiguration({
          offset: getState().notifications.notificationListConfiguration.offset + 1,
        })
      )
      if (
        getState().chat.selectedChat.twilio_chat_sid === notification.data.chat_id &&
        notification.unread &&
        (notification.type === CONSTANTS.NOTIFICATION_TYPES.PRO_NEW_MESSAGE ||
          notification.type === CONSTANTS.NOTIFICATION_TYPES.CLIENT_NEW_MESSAGE)
      ) {
        dispatch(markNotificationAsRead(notification.uuid, notification.unread, notification.type))
      }
    }
  })
}

export const disconnectFromNotificationService = () => {
  if (notificationSubject) {
    notificationSubject.unsubscribe()
  }
}

export const reactChatNotificationsById = twilioChatId => async dispatch => {
  try {
    const response = await axios.post(getReadChatNotificationsPath(), {
      twilio_chat_sid: twilioChatId,
    })
    dispatch(setUnreadNotificationsCounter(response.data))
  } catch (e) {
    console.error(e)
  }
}

export const getUserCurrentNotification = uuid => async dispatch => {
  try {
    const response = await axios.get(getUserCurrentNotificationPath(uuid))
    dispatch(showAdminMessageModal(response.data.data))
    if (response.data.unread) dispatch(markNotificationAsRead(uuid))
  } catch (e) {
    dispatch(openSnackbar('error', getErrorDetails(e) || 'Error while loading user notification'))
  }
}

export const getNotificationSettings = () => async dispatch => {
  try {
    const response = await axios.get(getNotificationSettingsPath())
    return response.data
  } catch (e) {
    dispatch(openSnackbar('error', e.response.data.detail || 'Error while loading user notification settings'))
    return e
  }
}

export const updateNotificationSettings = (uuid, value) => async dispatch => {
  try {
    if (value) await axios.put(updateNotificationSettingsPath(uuid))
    else await axios.delete(updateNotificationSettingsPath(uuid))
    return {}
  } catch (e) {
    dispatch(openSnackbar('error', e.response.data.detail || 'Error while updating user notification settings'))
    return e
  }
}
