import { CONSTANTS } from 'constants/index'

export const getVaultCasesPath = () => '/api/v1/vaults/current/'
export const getVaultCasesMainLevelPath = () => '/api/v1/vaults/current/?main_level=true'
export const getClientVaultCasesPath = proID => `/api/v1/vaults/current/${proID}/`
export const getClientProVaultSettingsPath = proID => `/api/v1/vaults/${proID}/settings/`
export const getProVaultUploadRegisterPath = parentFolderID =>
  `/api/v1/vaults/current/vault-assets/${parentFolderID}/confirm-upload/`
export const getVaultCreateFolderPath = () => '/api/v1/vaults/current/create-folder/'
export const getVaultMoveItemsPath = () => '/api/v1/vaults/current/bulk-move/'
export const getVaultCopyItemsPath = () => '/api/v1/vaults/current/bulk-copy/'
export const getVaultDeleteItemsPath = () => '/api/v1/vaults/current/bulk-delete/'
export const getVaultRenameItemPath = object_uuid => `/api/v1/vaults/current/rename/${object_uuid}/`
export const getVaultUploadFileLinkPath = () => '/api/v1/vaults/current/file-upload-link/'
export const getVaultUploadFileConfirmPath = file_uuid => `/api/v1/vaults/current/file/${file_uuid}/confirm-upload/`
export const getVaultPlansPath = () => '/api/v1/vaults/current/settings/'
export const getVaultEventsPath = folderId => `/api/v1/vaults/current/vault-assets/${folderId}/vault-events/`
export const getVaultTrashPath = clientID => `/api/v1/vaults/current/trash/${clientID}/`
export const getVaultUsagePath = () => '/api/v1/vaults/current/vault-usage/'
export const getVaultPlansRatePath = () => '/api/v1/vaults/vault-plans/'
export const getVaultTrashRestorePath = () => '/api/v1/vaults/current/trash/bulk-restore/'
export const getVaultTrashDeletePath = () => '/api/v1/vaults/current/trash/bulk-delete/'
export const getVaultZipChannelPath = () => '/api/v1/vaults/current/zip/'
export const getVaultZipSocketChannelPath = (channelId, token) =>
  `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/vault/zip/${channelId}/?token=${token}`
export const getEventsRealUpdateSocketPath = (folderId, token) =>
  `wss://${CONSTANTS.WEBSOCKET_HOST}/ws/v1/vault_asset/${folderId}/?token=${token}`
export const getVaultLoggingEventPath = (fileId, event) =>
  `/api/v1/vaults/current/file/${fileId}/register/?event=${event}`
export const getVerifyPhonePath = () => '/api/v1/twilio/verify_phone_number/'
export const getVerifyCodePath = () => '/api/v1/twilio/send_verification_code/'
