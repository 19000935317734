import moment from 'moment'

export const sortInvoiceSummary = (payment1, payment2) => {
  if (!payment1.created_at) return 1
  if (!payment1.created_at) return -1
  if (moment(payment1.created_at).isAfter(payment2.created_at)) {
    return 1
  }
  if (moment(payment1.created_at).isBefore(payment2.created_at)) {
    return -1
  }
  return 0
}

export const getItemName = type => {
  switch (type) {
    case 'I':
      return 'Invoice'
    case 'P':
      return 'Payment'
    case 'A':
      return 'Debit'
    case 'C':
      return 'Credit'
    default:
      return ''
  }
}
export const getInvoiceSummaryForInvoice = summary => {
  return summary.filter(summaryItem => summaryItem.type !== 'I').sort(sortInvoiceSummary)
}
