import { ReactComponent as PoweredByStripe } from 'static/powered_by_stripe_logo.svg'
import React from 'react'
import SvgIcon from './SvgIcon'

const PoweredByStripeLogo = ({ className }) => (
  <SvgIcon viewBox="0 0 119 26" className={className}>
    <PoweredByStripe />
  </SvgIcon>
)

export default PoweredByStripeLogo
