import * as CONSTANTS from 'store/constants/wallet/wallet'
import axios, { CancelToken } from 'axios'
import { openSnackbar } from 'store/actions/common'
import {
  getWalletItemsPath,
  getWalletSummaryPath,
  getWalletExportPath,
  getWalletPendingFundsPath,
  getPaymentAccountPath,
  getRequestFundsPath,
  getStripeDashboardPath,
} from 'utils/path-helpers/api'
import { getWalletFilters, getWalletFiltersExport } from 'utils/getters/wallet/wallet'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'
import { SET_TAX_FORM_MODAL_OPEN } from 'store/constants/wallet/wallet'

export const setTableConfiguration = payload => ({
  type: CONSTANTS.SET_TABLE_CONFIGURATION,
  payload,
})

export const setWalletFilter = (name, value) => ({
  type: CONSTANTS.SET_FILTERS,
  name,
  value,
})

export const resetWalletFilter = () => ({
  type: CONSTANTS.RESET_FILTERS,
})

const getWalletSummaryStart = () => ({
  type: CONSTANTS.GET_WALLET_SUMMARY_START,
})

const getWalletSummaryEnd = payload => ({
  type: CONSTANTS.GET_WALLET_SUMMARY_END,
  payload,
})

const setHasTaxForm = payload => ({
  type: CONSTANTS.SET_HAS_TAX_FORM,
  payload,
})

export const getWalletSummary = () => dispatch => {
  dispatch(getWalletSummaryStart())
  return axios
    .get(getWalletSummaryPath())
    .then(response => {
      dispatch(getWalletSummaryEnd(response.data))
      dispatch(setHasTaxForm(response.data.has_tax_form))
      return response.data
    })
    .catch(e => {
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while loading wallet summary'
        )
      )
      dispatch(getWalletSummaryEnd({}))
      return Promise.reject(e)
    })
}

const getWalletItemsStart = () => ({
  type: CONSTANTS.GET_WALLET_ITEMS_START,
})

const getWalletItemsEnd = (payload, count) => ({
  type: CONSTANTS.GET_WALLET_ITEMS_END,
  payload,
  count,
})

let cancelWalletItemsRequest

export const getWalletItems = () => (dispatch, getState) => {
  cancelWalletItemsRequest && cancelWalletItemsRequest()
  dispatch(getWalletItemsStart())
  return axios
    .get(getWalletItemsPath(), {
      params: getWalletFilters(getState().wallet),
      cancelToken: new CancelToken(c => {
        cancelWalletItemsRequest = c
      }),
    })
    .then(response => {
      dispatch(getWalletItemsEnd(response.data.results, response.data.count))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while loading wallet items'
        )
      )
      dispatch(getWalletSummaryEnd([], 0))
      return Promise.reject(e)
    })
}

const getWalletPendingFundsStart = () => ({
  type: CONSTANTS.GET_WALLET_PENDING_FUNDS_START,
})

const getWalletPendingFundsEnd = (payload, count) => ({
  type: CONSTANTS.GET_WALLET_PENDING_FUNDS_END,
  payload,
  count,
})

let cancelWalletPendingFundsRequest

export const getWalletPendingFunds = () => (dispatch, getState) => {
  cancelWalletPendingFundsRequest && cancelWalletPendingFundsRequest()
  dispatch(getWalletPendingFundsStart())
  return axios
    .get(getWalletPendingFundsPath(), {
      params: getWalletFilters(getState().wallet),
      cancelToken: new CancelToken(c => {
        cancelWalletPendingFundsRequest = c
      }),
    })
    .then(response => {
      dispatch(getWalletPendingFundsEnd(response.data.results, response.data.count))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while loading wallet items'
        )
      )
      dispatch(getWalletPendingFundsEnd([], 0))
      return Promise.reject(e)
    })
}

export const exportWallet = () => (dispatch, getState) => {
  return axios
    .get(getWalletExportPath(), {
      params: getWalletFiltersExport(getState().wallet),
      responseType: 'blob',
    })
    .then(response => {
      return response
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while exporting wallet'))
      return Promise.reject(e)
    })
}

const getPaymentConnectionStatusStart = () => ({
  type: CONSTANTS.GET_PAYMENT_CONNECTION_STATUS_START,
})

const getPaymentConnectionStatusEnd = payload => ({
  type: CONSTANTS.GET_PAYMENT_CONNECTION_STATUS_END,
  payload,
})

export const getPaymentConnectionStatus = () => dispatch => {
  dispatch(getPaymentConnectionStatusStart())
  return axios
    .get(getPaymentAccountPath())
    .then(response => {
      dispatch(getPaymentConnectionStatusEnd(response.data.is_stripe_account_connected))
    })
    .catch(e => {
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while loading payment account data'
        )
      )
      dispatch(getPaymentConnectionStatusEnd(false))
      return Promise.reject(e)
    })
}

const disconnectPaymentStart = () => ({
  type: CONSTANTS.DISCONNECT_PAYMENT_START,
})

const disconnectPaymentEnd = () => ({
  type: CONSTANTS.DISCONNECT_PAYMENT_END,
})

export const disconnectPaymentMethod = () => dispatch => {
  dispatch(disconnectPaymentStart())
  return axios
    .delete(getPaymentAccountPath())
    .then(() => {
      dispatch(disconnectPaymentEnd())
      Analytics.track(EVENTS.PRO_DELETED_STRIPE_CONNECT, { stripe_connect: false })
    })
    .catch(e => {
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while disconnecting stripe account'
        )
      )
      dispatch(disconnectPaymentEnd())
      return Promise.reject(e)
    })
}

const savePaymentMethodStart = () => ({
  type: CONSTANTS.SAVE_PAYMENT_METHOD_START,
})

const savePaymentMethodEnd = () => ({
  type: CONSTANTS.SAVE_PAYMENT_METHOD_END,
})

export const savePaymentMethod = code => dispatch => {
  dispatch(savePaymentMethodStart())
  return axios
    .post(getPaymentAccountPath(), {
      code: code,
    })
    .then(() => {
      dispatch(savePaymentMethodEnd())
      Analytics.track(EVENTS.PRO_ADDED_STRIPE_CONNECT, { stripe_connect: true })
    })
    .catch(e => {
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while saving payment data'
        )
      )
      dispatch(savePaymentMethodEnd())
      return Promise.reject(e)
    })
}

export const redirectToStripeDashboard = () => dispatch => {
  return axios
    .get(getStripeDashboardPath())
    .then(response => {
      return response.data.link
    })
    .catch(e => {
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while redirecting to stripe dashboard'
        )
      )
      return Promise.reject(e)
    })
}

const requestFundsStart = () => ({
  type: CONSTANTS.REQUEST_FUNDS_START,
})

const requestFundsEnd = () => ({
  type: CONSTANTS.REQUEST_FUNDS_END,
})

export const setRequestFundsErrorModalOpen = isShown => ({
  type: CONSTANTS.SET_REQUEST_FUNDS_ERROR_MODAL_OPEN,
  isShown,
})

export const requestFunds = amount => dispatch => {
  dispatch(requestFundsStart())
  let message
  if (amount >= 500) {
    message = 'Funds requested successfully'
  } else {
    message = 'Funds transferred  successfully'
  }
  return axios
    .post(getRequestFundsPath(), {
      amount: amount,
    })
    .then(() => {
      dispatch(requestFundsEnd())
      dispatch(openSnackbar('success', message))
    })
    .catch(e => {
      dispatch(requestFundsEnd())
      if (e.response && e.response.data && e.response.data.show_as_popup) {
        dispatch(setRequestFundsErrorModalOpen(true))
        return Promise.reject(e)
      }
      dispatch(
        openSnackbar(
          'error',
          (e.response && e.response.data && e.response.data.detail) || 'Error while requesting funds'
        )
      )
      return Promise.reject(e)
    })
}
