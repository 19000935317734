import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const XBreadcrumbs = ({ children, className, separator }) => (
  <Breadcrumbs className={className} aria-label="breadcrumb" separator={separator}>
    {children}
  </Breadcrumbs>
)

export default XBreadcrumbs
