import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link, MessagesButton, Tooltip, Typography, VaultButton } from 'components'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch } from 'react-redux'
import { goToZendesk } from 'store/actions/common'

const useStyles = makeStyles({
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR[80],
    color: colors.WHITE,
    fontSize: 13,
    fontWeight: 'normal',
  },
  tableCellTextClass: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  clientNameCellTextClass: {
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  messageBtn: {
    marginLeft: 8,
  },
  placeholderText: {
    fontSize: 13,
    color: colors.BASIC.COLOR['30'],
  },
  invitedText: {
    marginLeft: 8,
    textTransform: 'uppercase',
    color: colors.PRIMARY.COLOR['50'],
    fontWeight: 600,
    fontSize: 12,
  },
})

const TooltipText = ({ goToZendesk }) => (
  <span>
    You can not send messages until your license is approved. Please contact <Link onClick={goToZendesk}>XIRA</Link>{' '}
    admin for details.
  </span>
)

const ProConnectionsTableRow = ({ data, profileIsApproved }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clientInfo = data.clientInfo
  const contactInfo = (data.clientInfo && data.clientInfo.contactInfo) || ''
  const clientName = (clientInfo && `${clientInfo.firstName} ${clientInfo.lastName}`) || ''
  const username = (clientInfo && clientInfo.username) || ''
  const phoneNumber = (contactInfo && contactInfo.phone) || ''
  const address = (contactInfo && contactInfo.street && `${contactInfo.suite} ${contactInfo.street}`) || ''
  const city =
    (contactInfo &&
      contactInfo.city &&
      `${contactInfo.city}, ${contactInfo.state ? contactInfo.state.replace('US-', '') : ''} ${contactInfo.zip}`) ||
    ''

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  return (
    <>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.clientNameCellTextClass }}>
          {clientName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {data.isInitialMeetingSuccessfull || data.isInvited ? (
            <>
              {username}
              <br />
              {phoneNumber}
            </>
          ) : (
            <span className={classes.placeholderText}>
              Contact info will be available once your initial meeting successfully finished
            </span>
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.clientNameCellTextClass }}>
          {data.isInitialMeetingSuccessfull || data.isInvited ? (
            <>
              {address}
              <br />
              {city}
            </>
          ) : (
            <span className={classes.placeholderText}>
              Contact info will be available once your initial meeting successfully finished
            </span>
          )}
        </Typography>
      </TableCell>
      <TableCell>
        {data.isInvited ? (
          <div className={classes.btnContainer}>
            <VaultButton disabled={!data.hasClientFolder} options={{ userID: data.client }} vaultPlace="connections" />
            <Tooltip title="Invited">
              <Typography className={classes.invitedText}>Inv.</Typography>
            </Tooltip>
          </div>
        ) : (
          <div className={classes.btnContainer}>
            <VaultButton disabled={!data.hasClientFolder} options={{ userID: data.client }} vaultPlace="connections" />
            <MessagesButton
              className={classes.messageBtn}
              chatId={data ? data.twilio_chat_sid : ''}
              disabledText={
                !profileIsApproved ? <TooltipText goToZendesk={zendeskSso} /> : CONSTANTS.DISABLED_MESSAGES_TOOLTIP
              }
              disabled={!profileIsApproved || !data.readyForMessages}
              place="connections"
            />
          </div>
        )}
      </TableCell>
    </>
  )
}

export default ProConnectionsTableRow
