import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Typography,
  NoDataPlaceholder,
  Button,
  Tooltip,
  ColorCircularProgress,
  TableEmptyState,
  InviteClientPopup,
  TableSearch,
  ProConnectionsTable,
  ProActivityEntryModal,
  ConfirmationModal,
  ErrorModal,
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors, INVITE_CLIENT_CONFIRMATION } from 'constants/index'
import {
  setConnectionFilter,
  getProConnections,
  setTableConfiguration,
  openInvitePopup,
  sendInviteLink,
  editInviteInfo,
  resendInviteLink,
  deleteInvite,
} from 'store/actions/connections'
import { openSnackbar } from 'store/actions/common'
import { useQuery } from 'utils/hooks'
import { isEqual } from 'lodash'
import NoConnections from 'static/connections/no_pro_connections.png'
import ChatModal from '../../components/chat/ChatModal'
import { ReactComponent as InviteUserIcon } from 'static/person-add.svg'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { ReactComponent as CheckIcon } from 'static/Check.svg'

const useStyles = makeStyles({
  connectionsContainer: {
    padding: 30,
    paddingLeft: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addButton: {
    padding: '5px 15px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  controllerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tooltip: {
    maxWidth: 210,
    backgroundColor: colors.BASIC.COLOR[80],
    color: colors.WHITE,
    fontSize: 13,
    fontWeight: 400,
    borderRadius: '4px',
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
  },
  icon: {
    marginRight: 16,
  },
})
const ProConnectionsContainer = () => {
  const classes = useStyles()
  const query = useQuery()
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const dispatch = useDispatch()
  const clientName = query.get('connection')
  const connections = useSelector(state => state.connections.connections)
  const connectionsAreLoading = useSelector(state => state.connections.connectionsAreLoading)
  const filters = useSelector(state => state.connections.filters)
  const initialFilters = useSelector(state => state.connections.initialFilters)
  const count = useSelector(state => state.connections.connectionsLength)
  const [search, setSearch] = useState(filters.search)
  const popupOpened = useSelector(state => state.connections.openInvitePopup)
  const [popupInfo, setPopupInfo] = useState({
    inviteInfo: {
      first_name: '',
      last_name: '',
      username: '',
      street: '',
      suite: '',
      city: '',
      country: 840,
      state: '',
      zip: '',
      phone: '',
    },
    isCreateMode: true,
    clientID: '',
  })
  const isInitialFilters = isEqual(initialFilters, filters)
  const [showActivityModal, setActivityModal] = useState({
    open: false,
    action: null,
    type: null,
    activity: {},
  })
  const [confirmationDelete, setConfirmationDelete] = useState({ uuid: '', hasCases: true })
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [confirmationModalShow, setConfirmationModalShow] = useState(false)
  const [invitationStatusText, setInvitationStatusText] = useState({})

  useEffect(() => {
    dispatch(getProConnections())
    return () => {
      dispatch(setConnectionFilter('search', ''))
    }
  }, [])

  useEffect(() => {
    if (!clientName) return
    setSearch(clientName)
    dispatch(setConnectionFilter('search', clientName))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProConnections())
  }, [clientName])

  const onInviteClientClick = () => {
    setPopupInfo({
      inviteInfo: {
        first_name: '',
        last_name: '',
        username: '',
        street: '',
        suite: '',
        city: '',
        country: 840,
        state: '',
        zip: '',
        phone: '',
      },
      isCreateMode: true,
      clientID: '',
    })
    handlePopup(true)
  }

  const handlePopup = open => {
    dispatch(openInvitePopup(open))
  }

  const onEditPopupOpen = (clientInfo, invitedUserID) => {
    const { contactInfo, firstName, lastName, username } = clientInfo
    const { city = '', phone = '', country = 840, state = '', street = '', suite = '', zip = '' } = contactInfo
    setPopupInfo({
      inviteInfo: {
        first_name: firstName,
        last_name: lastName,
        username,
        street,
        suite,
        city,
        country,
        state,
        zip,
        phone,
      },
      isCreateMode: false,
      clientID: invitedUserID,
    })
    handlePopup(true)
  }

  const sendInvite = async payload => {
    const response = await dispatch(sendInviteLink(payload))
    handlePopup(false)
    const texts = INVITE_CLIENT_CONFIRMATION(payload.invited_user.username)
    setInvitationStatusText(texts[response.invitation_status])
    setConfirmationModalShow(true)
    let client_status
    dispatch(getProConnections())
    if (!response) return
    if (response.is_existing_user_connected) {
      client_status = 'registered'
    } else if (response.is_existing_user_connected === false) {
      client_status = 'not registered'
    }
    Analytics.track(EVENTS.PRO_INVITED_CLIENT, {
      client_status,
      client_id: response.client_uuid,
    })
  }

  const onSubmitInviteForm = payload => {
    if (popupInfo.isCreateMode) {
      sendInvite(payload)
    } else {
      dispatch(editInviteInfo(payload, popupInfo.clientID))
    }
  }

  const onActivityModalClose = () => {
    setActivityModal({
      open: false,
      type: null,
      action: null,
      activity: {},
    })
  }

  const resendInvite = () => {
    dispatch(resendInviteLink(popupInfo.clientID))
  }

  const openConfirmationDeletionClient = (uuid, hasCases, hasActiveCases) => {
    if (!hasActiveCases) {
      setConfirmationDelete({ uuid: uuid, hasCases: hasCases })
    } else if (hasActiveCases) {
      setShowErrorModal(true)
    }
  }

  const onConfirmDelete = async () => {
    setConfirmationDelete({ uuid: '', hasCases: true })
    try {
      await dispatch(deleteInvite(confirmationDelete.uuid))
      dispatch(getProConnections())
    } catch (e) {
      const error = e.response.data.detail || 'Error while deleting connection'
      dispatch(openSnackbar('error', error))
    }
  }

  return (
    <div className={classes.connectionsContainer}>
      <div className={classes.header}>
        <Typography variant="h2">Connections</Typography>
        {(!isInitialFilters || !!count) && (
          <div className={classes.controllerContainer}>
            <TableSearch
              search={search}
              searchClass={classes.search}
              setSearch={setSearch}
              setFilter={value => dispatch(setConnectionFilter('search', value))}
              setTableConfiguration={() => dispatch(setTableConfiguration({ tablePage: 0, offset: 0 }))}
              getSearchResults={() => dispatch(getProConnections())}
            />
            <Tooltip tooltipClass={classes.tooltip} title="invite your current clients to XIRA by sending them a link">
              <Button
                variant="primary"
                className={classes.addButton}
                onClick={onInviteClientClick}
                disabled={!profileIsApproved}
                startIcon={<InviteUserIcon />}
              >
                add/invite client
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      {connectionsAreLoading ? (
        <ColorCircularProgress />
      ) : !count && isInitialFilters ? (
        <NoDataPlaceholder
          label="This page displays contact information for clients and people who consulted with you through XIRA"
          onButtonPress={onInviteClientClick}
          buttonText="add/invite client"
          imageSrc={NoConnections}
          disabled={!profileIsApproved}
          buttonTooltip="invite your current clients to XIRA by sending them a link"
          tooltipClass={classes.tooltip}
          startIcon={<InviteUserIcon />}
          buttonSize="medium"
        />
      ) : !count && !isInitialFilters ? (
        <TableEmptyState />
      ) : (
        <ProConnectionsTable
          connections={connections}
          profileIsApproved={profileIsApproved}
          count={count}
          onEditPopupOpen={onEditPopupOpen}
          setActivityModal={setActivityModal}
          openConfirmationDeletionClient={openConfirmationDeletionClient}
        />
      )}
      <InviteClientPopup
        onClose={() => handlePopup(false)}
        onSubmit={onSubmitInviteForm}
        inviteInfo={popupInfo.inviteInfo}
        isCreateMode={popupInfo.isCreateMode}
        resendInvite={resendInvite}
        open={popupOpened}
      />
      <ProActivityEntryModal
        open={showActivityModal.open}
        type={showActivityModal.type}
        action={showActivityModal.action}
        activity={showActivityModal.activity}
        handleClose={onActivityModalClose}
      />
      <ChatModal />
      <ConfirmationModal
        title="Delete client?"
        message={
          confirmationDelete.hasCases
            ? 'Client will be permanently deleted from your connections list but you have already created cases for them. Continue?'
            : 'Client will be permanently deleted from your connections list. If you uploaded any files for this client, they will be deleted as well Continue?'
        }
        confirmationButtonText="Yes, delete"
        cancelationButtonText="Cancel"
        isOpen={confirmationDelete.uuid}
        onClose={() => setConfirmationDelete({ uuid: '', hasCases: true })}
        onConfirm={() => onConfirmDelete()}
      />
      <ConfirmationModal
        isOpen={confirmationModalShow}
        message={invitationStatusText.content}
        onClose={() => setConfirmationModalShow(false)}
        title={invitationStatusText.title}
        onConfirm={() => setConfirmationModalShow(false)}
        confirmationButtonText="continue"
        showCancel={false}
        icon={<CheckIcon className={classes.icon} />}
      />
      <ErrorModal
        variant="primary"
        title="You can not delete the client"
        message="To delete the client, plase make sure that all cases with them are made inactive"
        cancelationButtonText="ok"
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  )
}

export default ProConnectionsContainer
