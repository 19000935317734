import React from 'react'
import { CONSTANTS, colors } from 'constants/index'
import { FormikInput, FormikSelect, FormikAutocomplete } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles({
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContainer: {
    width: 'calc(50% - 13px)',
  },
  phoneInput: {
    height: '40px !important',
  },
  selectIcon: {
    marginRight: '7px',
  },
  contactInfo: {
    padding: 24,
    border: `1px solid ${colors.BASIC.COLOR[5]}`,
    borderRadius: 4,
    backgroundColor: colors.WHITE,
  },
  '@media(max-width:939px)': {
    twoColumn: {
      flexDirection: 'column',
    },
    inputContainer: {
      width: '100%',
      maxWidth: 372,
    },
    formikResponsive: {
      width: '100%',
      maxWidth: 372,
    },
  },
})

const ClientContactInfoFormBody = ({ states, countries, updateContactInfo, disableContactInfoStyle }) => {
  const classes = useStyles()
  return (
    <div className={classNames({ [classes.contactInfo]: !disableContactInfoStyle })}>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          size="small"
          name="street"
          label="Address"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
        <FormikInput
          variant="outlined"
          size="small"
          name="suite"
          required={false}
          label="Apartment, Suite, PO Box"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
      </div>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          size="small"
          name="city"
          label="City"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
        <FormikAutocomplete
          variant="outlined"
          size="small"
          name="state"
          label="State"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          options={states}
          handleChange={updateContactInfo}
          filterOptions={(options, { inputValue }) =>
            states.filter(option => option.label.toLowerCase().startsWith(inputValue.toLowerCase()))
          }
        />
      </div>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          size="small"
          name="zip"
          label="Zip сode"
          type="text"
          fullWidth
          containerClasses={classes.inputContainer}
          handleChange={updateContactInfo}
        />
        <FormikInput
          variant="outlined"
          size="small"
          name="phone"
          label="Mobile phone"
          type={CONSTANTS.INPUT_TYPE.PHONE}
          fullWidth
          containerClasses={classes.inputContainer}
          inputClass={classes.phoneInput}
          country="us"
          handleChange={updateContactInfo}
        />
      </div>
      <FormikSelect
        value={840}
        variant="outlined"
        size="small"
        name="country"
        label="Country"
        type="text"
        fullWidth
        options={countries}
        disabled
        containerClasses={classes.formikResponsive}
      />
    </div>
  )
}

export default ClientContactInfoFormBody
