import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link, Typography } from 'components'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS } from 'constants/index'
import moment from 'moment-timezone'
import { formatNegativeNumber } from 'utils/formatters'
import classNames from 'classnames'

const useStyles = makeStyles({
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
    display: 'block',
  },
  statusColor: {
    color: colors.PRIMARY.COLOR[40],
    fontSize: 13,
  },
  boldText: {
    fontWeight: 600,
  },
})

const AvailableWalletTableRow = ({ data, setSelectedInvoiceId, timezone }) => {
  const classes = useStyles()
  const action = CONSTANTS.WALLET_ITEMS[data.action] || {}
  const status = CONSTANTS.WALLET_ITEM_STATUSES[data.funds_request_status] || {}

  return (
    <>
      <TableCell>
        <Typography
          variant="body2"
          className={classNames({
            [classes.text]: true,
            [classes.boldText]: action.key === CONSTANTS.WALLET_ITEMS['3'].key,
          })}
        >
          {moment
            .utc(data.posted_date)
            .tz(timezone)
            .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
        </Typography>
      </TableCell>
      <TableCell>
        {action.key === CONSTANTS.WALLET_ITEMS['0'].key ? (
          <Link className={classes.link} onClick={() => setSelectedInvoiceId(data.object_link)}>
            {data.title}
          </Link>
        ) : (
          <Typography
            variant="body2"
            className={classNames({
              [classes.text]: true,
              [classes.boldText]: action.key === CONSTANTS.WALLET_ITEMS['3'].key,
            })}
          >
            {data.title}
          </Typography>
        )}
        <span className={classes.statusColor}>{status.label || ''}</span>
      </TableCell>
      <TableCell align={'right'}>
        <Typography
          variant="body2"
          className={classNames({
            [classes.text]: true,
            [classes.boldText]: action.key === CONSTANTS.WALLET_ITEMS['3'].key,
          })}
        >
          {formatNegativeNumber(data.revenue, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography
          variant="body2"
          className={classNames({
            [classes.text]: true,
            [classes.boldText]: action.key === CONSTANTS.WALLET_ITEMS['3'].key,
          })}
        >
          {formatNegativeNumber(data.xira_charge, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography
          variant="body2"
          className={classNames({
            [classes.text]: true,
            [classes.boldText]: action.key === CONSTANTS.WALLET_ITEMS['3'].key,
          })}
        >
          {formatNegativeNumber(data.transaction_charge, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography
          variant="body2"
          className={classNames({
            [classes.text]: true,
            [classes.boldText]: action.key === CONSTANTS.WALLET_ITEMS['3'].key,
          })}
        >
          {formatNegativeNumber(data.net_revenue, '$')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" className={classes.boldText}>
          {formatNegativeNumber(data.balance, '$')}
        </Typography>
      </TableCell>
    </>
  )
}

export default AvailableWalletTableRow
