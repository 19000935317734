import * as CONSTANTS from 'store/constants/common'

const initialState = {
  snackbar: {
    message: '',
    variant: 'error',
    isOpen: false,
  },
  errorModal: {
    title: '',
    detail: '',
    isOpen: false,
  },
  defaultPracticeAreas: [],
  allLanguagesList: [],
  timezonesList: [],
  advancedSchedulesList: {},
  advancedEventBufferList: {},
  showNotFound: false,
  showLogin: false,
  showSignUp: false,
  showRestore: false,
  showForgotPassEmail: false,
  showFilter: false,
  showSort: false,
  redirectPath: '',
  isExporting: false,
  calendarTab: 'timeGridWeek',
  showMobileMenu: false,
  ticketIsLoading: false,
  resendLinkUsername: '',
  sessionID: null,
  showAdminMessageModal: false,
  aminMessageModalData: {},
  showMobileAppHint: false,
}

const common = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: {
          message: action.message,
          variant: action.variant,
          isOpen: true,
        },
      }
    case CONSTANTS.CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpen: false,
        },
      }
    case CONSTANTS.SET_ALL_LANGUAGES:
      return {
        ...state,
        allLanguagesList: action.payload,
      }
    case CONSTANTS.EXPORT_FILE_START:
      return {
        ...state,
        isExporting: true,
      }
    case CONSTANTS.EXPORT_FILE_END:
      return {
        ...state,
        isExporting: false,
      }

    case CONSTANTS.SET_ADVANCED_CALENDAR_VALUES:
      return {
        ...state,
        ...action.payload,
      }
    case CONSTANTS.SHOW_NOT_FOUND:
      return {
        ...state,
        showNotFound: true,
      }
    case CONSTANTS.HIDE_NOT_FOUND:
      return {
        ...state,
        showNotFound: false,
      }
    case CONSTANTS.TOGGLE_MOBILE_HINT:
      return {
        ...state,
        showMobileAppHint: action.payload,
      }
    case CONSTANTS.TOGGLE_LOGIN:
      return {
        ...state,
        showLogin: action.payload,
      }
    case CONSTANTS.TOGGLE_SIGNUP:
      return {
        ...state,
        showSignUp: action.payload,
      }
    case CONSTANTS.TOGGLE_RESTORE:
      return {
        ...state,
        showRestore: action.payload,
      }
    case CONSTANTS.TOGGLE_FORGOT_PASS_EMAIL:
      return {
        ...state,
        showForgotPassEmail: action.payload,
      }
    case CONSTANTS.SET_REDIRECT:
      return {
        ...state,
        redirectPath: action.payload,
      }
    case CONSTANTS.OPEN_ERROR_MODAL:
      return {
        ...state,
        errorModal: {
          title: action.title,
          detail: action.detail,
          isOpen: true,
        },
      }
    case CONSTANTS.CLOSE_ERROR_MODAL:
      return {
        ...state,
        errorModal: {
          ...state.errorModal,
          isOpen: false,
        },
      }
    case CONSTANTS.SHOW_FILTER:
      return {
        ...state,
        showFilter: action.payload,
      }
    case CONSTANTS.SHOW_SORT:
      return {
        ...state,
        showSort: action.payload,
      }
    case CONSTANTS.SET_CALENDAR_TAB:
      return {
        ...state,
        calendarTab: action.payload,
      }
    case CONSTANTS.SET_MOBILE_MENU_OPEN:
      return {
        ...state,
        showMobileMenu: true,
      }
    case CONSTANTS.SET_MOBILE_MENU_CLOSE:
      return {
        ...state,
        showMobileMenu: false,
      }
    case CONSTANTS.TICKET_UPLOAD_START:
      return {
        ...state,
        ticketIsLoading: true,
      }
    case CONSTANTS.TICKET_UPLOAD_END:
      return {
        ...state,
        ticketIsLoading: false,
      }
    case CONSTANTS.SET_SESSION_ID:
      return {
        ...state,
        sessionID: action.payload,
      }
    case CONSTANTS.SET_USERNAME_FOR_RESEND_PASSWORD:
      return {
        ...state,
        resendLinkUsername: action.payload,
      }
    case CONSTANTS.OPEN_ADMIN_MESSAGE_MODAL:
      return {
        ...state,
        showAdminMessageModal: true,
        aminMessageModalData: action.payload,
      }
    case CONSTANTS.CLOSE_ADMIN_MESSAGE_MODAL:
      return {
        ...state,
        showAdminMessageModal: false,
        aminMessageModalData: {},
      }
    default:
      return state
  }
}

export default common
