import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { ContingencySection, FlatFeeSection, InputLabel, TimeAndExpenseSection, Typography } from 'components'
import { RadioGroup } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles({
  section: {
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    marginBottom: 20,
  },
  formData: {
    width: '60%',
  },
  primaryInfo: {
    display: 'flex',
  },
  standardMargin: {
    marginBottom: 16,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.BASIC.COLOR[0],
    padding: '10px 24px',
  },
})

const getFeeTypeLabel = feeType => {
  return CONSTANTS.FEE_TYPES[feeType] ? CONSTANTS.FEE_TYPES[feeType].label : ''
}

const getSection = (feeType, params) => {
  switch (feeType) {
    case CONSTANTS.FEE_TYPES['1'].key:
      return <FlatFeeSection {...params} />
    case CONSTANTS.FEE_TYPES['2'].key:
      return <ContingencySection {...params} />
    case CONSTANTS.FEE_TYPES['3'].key:
      return <TimeAndExpenseSection {...params} />
    default:
      return null
  }
}

const SectionSubtitle = ({ feeType }) => {
  const classes = useStyles()
  switch (feeType) {
    case CONSTANTS.FEE_TYPES['1'].key:
      return (
        <Typography variant="subtitle2" className={classes.standardMargin}>
          Flat fee agreements can be paid incrementally or all at once, according to the agreement between lawyer and
          client. Use the Invoice Date field to set the schedule. Payments may not exceed the total amount of the agreed
          flat fee. If additional work not included in the flat fee is requested by the client, the lawyer may need to
          create a new case/matter for such work.
        </Typography>
      )
    case CONSTANTS.FEE_TYPES['2'].key:
      return (
        <Typography variant="subtitle2" className={classes.standardMargin}>
          Under this fee type, activities for time and expenses can be generated in the activities table. XIRA can
          optionally generate an invoice for expenses if the option "Expense invoicing" is selected. Any other invoice
          needs to be manually generated and you must inform XIRA.
        </Typography>
      )
    case CONSTANTS.FEE_TYPES['3'].key:
      return (
        <Typography variant="subtitle2" className={classes.standardMargin}>
          Under this fee type, activities can be generated in the activities table. Client will be charged for the time
          and expenses as noted in the activity table.
        </Typography>
      )
    default:
      return null
  }
}

const CaseFeeSettingsSelector = props => {
  const classes = useStyles()
  const { caseData, mode, onChange, setErrors, isInitial, children } = props

  const onFeeTypeChange = (event, value) => {
    onChange({
      fee_type: parseInt(value, 10),
      invoicing: null,
      expense_invoicing: false,
      invoice_frequency: null,
      invoicing_sections: [
        {
          invoice_amount: '',
          invoice_date: '',
        },
      ],
      amount: '',
    })
    if (setErrors) {
      setErrors({
        invoicing: '',
        expense_invoicing: '',
        invoice_frequency: '',
        invoicing_sections: [],
        amount: '',
      })
    }
  }

  return (
    <div>
      {!isInitial && <InputLabel>Case fee settings</InputLabel>}
      {!isInitial &&
        (mode === CONSTANTS.MODE.ADD ? (
          <RadioGroup value={caseData.fee_type} onChange={onFeeTypeChange} className={classes.radioGroup}>
            <FormControlLabel value={3} control={<Radio color="primary" />} label={CONSTANTS.FEE_TYPES['3'].label} />
            <FormControlLabel value={1} control={<Radio color="primary" />} label={CONSTANTS.FEE_TYPES['1'].label} />
            <FormControlLabel value={2} control={<Radio color="primary" />} label={CONSTANTS.FEE_TYPES['2'].label} />
          </RadioGroup>
        ) : (
          <InputLabel classes={{ root: classes.standardMargin }}>{getFeeTypeLabel(caseData.fee_type)}</InputLabel>
        ))}
      {!isInitial && <SectionSubtitle feeType={caseData.fee_type} />}
      {children}
      {!isInitial && getSection(caseData.fee_type, props)}
    </div>
  )
}

export default CaseFeeSettingsSelector
