import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, ProRating, Rating, ProRatingTitle } from 'components'
import { ReactComponent as StarIcon } from 'static/rating/rating_star.svg'
import { ReactComponent as ExpandIcon } from 'static/rating/chevron-right.svg'

const useStyles = makeStyles({
  proCommentsListRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  proCommentsTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  proReviewIcon: {
    marginLeft: 24,
  },
  proRatingTitle: {
    marginLeft: 8,
  },
  proOpenModalBtn: {
    display: 'none',
    '@media(max-width:767px) and (min-width:320px)': {
      display: 'block',
    },
  },
  ratingContainer: {
    margin: '0 16px 0 24px',
    display: 'flex',
    alignItems: 'center',
  },
})

const ProCommentsTitle = ({ rating, reviews, isProfile, openRatingModal, stats, proID }) => {
  const classes = useStyles()
  return (
    <div className={classes.proCommentsListRow}>
      <div className={classes.proCommentsTitle}>
        <Typography variant="h4">{'reviews'}</Typography>
        <Rating
          value={rating}
          name="rating"
          readOnly
          ratingClasses={{ root: classes.ratingClassesRoot }}
          ratingContainerClasses={classes.ratingContainer}
          precision={0.1}
        />
        {isProfile ? (
          <ProRating stats={stats} reviews={reviews} rating={rating} openRatingModal={openRatingModal} proID={proID} />
        ) : (
          <ProRatingTitle rating={rating} reviews={reviews} />
        )}
      </div>
      {isProfile && (
        <Button variant="text" className={classes.proOpenModalBtn} onClick={() => openRatingModal()}>
          <ExpandIcon />
        </Button>
      )}
    </div>
  )
}

export default ProCommentsTitle
