import React, { useState, useEffect } from 'react'
import { FormikAutocomplete, ActivityShortInfo, CommonActivityForm, ExpenseFormBody, TimeFormBody } from 'components'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import { getActivityByType } from 'utils/getters/activities'
import moment from 'moment'
import { CONSTANTS } from 'constants/index'
import {getDecimalFromTimeAndMinutes, mathRounding} from 'utils/getters/activities'
import {
  selectCasesForExpenseCreation,
  selectCasesForTimeCreation,
  selectExpenseCasesByClient,
  selectTimeCasesByClient,
  selectCasesOptions,
} from 'store/selectors/caseSelector'
import {
  selectExpenseActivitiesConnectionOptions,
  selectTimeActivitiesConnectionOptions,
  selectConnectionOptions,
} from 'store/selectors/connectionsSelector'

const selectCasesOnCondition = (type, clientId, mode) => state => {
  if (mode !== 'create') return selectCasesOptions(state)
  const clientSelector = clientId
    ? type === 'time'
      ? selectTimeCasesByClient(state)
      : selectExpenseCasesByClient(state)
    : null

  const selector = type === 'time' ? selectCasesForTimeCreation(state) : selectCasesForExpenseCreation(state)

  return (clientSelector && clientSelector(clientId)) || selector
}

const selectConnectionsOnCondition = (type, mode) => state => {
  if (mode !== 'create') return selectConnectionOptions(state)
  return type === 'time'
    ? selectTimeActivitiesConnectionOptions(state)
    : selectExpenseActivitiesConnectionOptions(state)
}

const ActivityFormBody = ({ setActivityInfo, type = 'time', mode = 'create', ...props }) => {
  const classes = useStyles()
  const { values, dirty, setFieldValue, setFieldTouched, touched, errors } = props
  const { billable, case: caseItem, clientId, charge, hours, minutes, rate, expense, caseId, editMode } = values
  const cases = useSelector(selectCasesOnCondition(type, clientId, mode))
  const connections = useSelector(selectConnectionsOnCondition(type, mode))
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const [showDetail, setShowDetail] = useState(false)

  useEffect(() => {
    if (!dirty) return
    if (!billable) {
      setFieldValue('clientCharge', null)
      setFieldValue('invoiceDate', null)
      setFieldValue('charge', null)
      return
    }
    const invoiceDate =
      caseItem.invoicing === 1
        ? null
        : caseItem.invoice_frequency === 1
        ? new Date(
            moment()
              .endOf('isoWeek')
              .toDate()
          )
        : new Date(
            moment()
              .endOf('month')
              .toDate()
          )
    setFieldValue('invoiceDate', invoiceDate)
    setFieldValue('charge', true)
  }, [billable])

  useEffect(() => {
    if (cases.length === 1) {
      setFieldValue('caseId', cases[0].value)
    }
  }, [cases])

  useEffect(() => {
    if (connections.length === 1) {
      setFieldValue('clientId', connections[0].value)
    }
  }, [connections])

  useEffect(() => {
    if (!dirty) return
    if (charge === null) return
    if (!charge) {
      setFieldValue('clientCharge', 0)
    }
    if (type === 'time') {
      const decimalTime = getDecimalFromTimeAndMinutes(hours, minutes)
      if (+rate && (+hours || +minutes)) {
        setFieldValue('clientCharge', mathRounding(decimalTime * rate, 2))
        setFieldValue('charge', true)
      } else {
        if ((+rate === 0 && rate) || (!decimalTime && hours && minutes)) {
          setFieldValue('charge', false)
          return
        }
        setFieldValue('clientCharge', ' ')
      }
    } else if (type === 'expense') {
      if (+expense) {
        setFieldValue('clientCharge', Number(expense).toFixed(2))
        setFieldValue('charge', true)
      } else {
        if (+expense === 0 && expense) {
          setFieldValue('charge', false)
          return
        }
        setFieldValue('clientCharge', ' ')
      }
    }
  }, [rate, charge, hours, minutes, expense])

  useEffect(() => {
    if (mode !== 'create') {
      setShowDetail(true)
      return
    }
    if (!dirty && !caseId) return
    if (!cases) return
    const selectedCaseItem = cases.find(item => item.uuid === caseId)
    if (selectedCaseItem) {
      setShowDetail(true)
      const settings = getActivityByType(type, selectedCaseItem, timezone)[type][selectedCaseItem.fee_type]
      for (let [key, value] of Object.entries(settings)) {
        setFieldValue(key, value)
      }
    } else {
      setShowDetail(false)
      setFieldValue('case', {})
      setFieldValue('clientCharge', ' ')
    }
    }, [caseId, cases])

  useEffect(() => {
    if (!cases) return
    if (caseItem.uuid && caseItem.client !== clientId) {
      setFieldValue('caseId', null)
    }
    if (cases.length === 1) {
      setFieldValue('caseId', cases[0].value)
    }
  }, [clientId, connections])

  return (
    <>
      <div className={classes.activityFormBodyContainer}>
        <FormikAutocomplete
          variant="outlined"
          name="clientId"
          label="Client name *"
          type="text"
          fullWidth
          disabled={mode !== 'create' || !editMode}
          value={clientId}
          containerClasses={classes.autocompleteContainer}
          options={connections}
        />
        <FormikAutocomplete
          variant="outlined"
          name="caseId"
          label="Case/Matter name *"
          type="text"
          fullWidth
          disabled={mode !== 'create' || !editMode}
          value={caseId}
          containerClasses={classes.autocompleteContainer}
          options={cases}
        />
        {showDetail && (
          <div className={classes.bodyContainer}>
            <div>
              {type === 'time' && <TimeFormBody values={values} setFieldValue={setFieldValue} />}
              {type === 'expense' && <ExpenseFormBody values={values} setFieldValue={setFieldValue} />}
              <CommonActivityForm
                setActivityInfo={setActivityInfo}
                type={type}
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                timezone={timezone}
              />
            </div>
          </div>
        )}
      </div>
      {caseItem.fee_type === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.INITIAL && <ActivityShortInfo caseItem={caseItem} />}
    </>
  )
}

export default ActivityFormBody
