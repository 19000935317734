import React from 'react'
import { Table, Link, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  tableHeaderCell: {
    borderBottom: 0,
    color: colors.BASIC.COLOR[100],
    paddingLeft: 0,
  },
  tableBodyCell: {
    paddingLeft: 0,
    whiteSpace: 'nowrap',
    maxWidth: '1px',
    padding: 8,
  },
  tableCellTextClass: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '90%',
    display: 'inline-block',
    margin: 0,
    verticalAlign: 'middle',
  },
  icon: {
    color: colors.BASIC.COLOR[50],
    fontSize: 16,
    marginLeft: 8,
  },
  link: {
    cursor: 'pointer',
  },
  '@media(max-width:767px)': {
    tableBodyCell: {
      border: 'none',
    },
  },
})

const LicensesTable = ({ licenses, states }) => {
  const classes = useStyles()
  const columns = [
    {
      value: 'bar_id',
      label: 'bar number',
      link: 'verification_link',
      iconStyle: classes.icon,
      linkStyle: classes.link,
      icon: 'las la-external-link-alt',
    },
    {
      value: 'state',
      label: 'issuing state',
      match: states,
    },
  ]

  return (
    <Table
      tableColumns={columns}
      className="degree-table"
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableBodyCellClass={{ root: classes.tableBodyCell }}
      tableData={licenses}
      tableCellTextClass={classes.tableCellTextClass}
    />
  )
}

export default LicensesTable
