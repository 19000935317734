import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Typography,
  NoDataPlaceholder,
  ActivityFilters,
  ColorCircularProgress,
  ConfirmationModal,
  TableEmptyState,
  ActivitiesTable,
  ProActivityEntryModal,
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'constants/index'
import { getFilterProCases } from 'store/actions/cases'
import {
  getProActivities,
  deleteProActivity,
  setTableConfiguration,
  resetActivitiesFilter,
} from 'store/actions/activities'
import { getUserConnectionsForFilter } from 'store/actions/connections'
import { isEqual } from 'lodash'
import ActivitiesAddEntriesButtons from '../../components/activities/ActivitiesAddEntriesButtons'
import { selectTimeCreationAbility, selectExpenseCreationAbility } from '../../store/selectors/caseSelector'
import NoActivities from 'static/activities/no_activities.png'
import moment from 'moment-timezone'

const useStyles = makeStyles({
  activitiesContainer: {
    padding: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 23,
  },
  filters: {
    display: 'flex',
  },
  statusFilter: {
    marginLeft: 12,
    width: 150,
  },
  lgStatusFilter: {
    marginLeft: 12,
    width: 230,
  },
  clientFilter: {
    marginLeft: 12,
    width: 200,
  },
  addButton: {
    padding: '5px 15px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  search: {
    marginLeft: 12,
    width: 300,
  },
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
  noDataPlaceholderLabel: {
    width: 365,
    fontSize: 15,
    lineHeight: 1.47,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    color: '#011a33',
    textAlign: 'center',
  },
})

const currentTimezone = moment.tz.guess()
const ProActivitiesContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState('')
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone) || currentTimezone
  const [showActivityModal, setActivityModal] = useState({
    open: false,
    action: null,
    type: null,
    activity: {},
  })
  const activities = useSelector(state => state.activities.activities)
  const timeCreationAbility = useSelector(selectTimeCreationAbility)
  const expenseCreationAbility = useSelector(selectExpenseCreationAbility)
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const activitiesAreLoading = useSelector(state => state.activities.activitiesAreLoading)
  const filters = useSelector(state => state.activities.filters)
  const initialFilters = useSelector(state => state.activities.initialFilters)
  const count = useSelector(state => state.activities.activitiesLength)
  const isInitialFilters = isEqual(initialFilters, filters)

  useEffect(() => {
    dispatch(getProActivities())
    dispatch(getFilterProCases())
    dispatch(getUserConnectionsForFilter())

    return () => {
      dispatch(resetActivitiesFilter())
      dispatch(
        setTableConfiguration({
          tablePage: 0,
          offset: 0,
        })
      )
    }
  }, [])

  const onDeleteModalConfirm = async () => {
    await dispatch(deleteProActivity(selectedActivity.uuid))
    setShowDeleteModal(false)
    dispatch(getProActivities())
  }

  const onActivityModalClose = () => {
    setActivityModal({
      open: false,
      type: null,
      action: null,
      activity: {},
    })
  }
  return (
    <div className={classes.activitiesContainer}>
      <div className={classes.header}>
        <Typography variant="h2">Activities</Typography>
        <ActivitiesAddEntriesButtons
          profileIsApproved={profileIsApproved}
          disableTime={!timeCreationAbility}
          disableExpense={!expenseCreationAbility}
          handleActivityModal={setActivityModal}
        />
      </div>
      {(!isInitialFilters || !!count) && (
        <ActivityFilters activities={activities} count={count} isInitialFilters={isInitialFilters} />
      )}
      {activitiesAreLoading ? (
        <ColorCircularProgress />
      ) : !count && isInitialFilters ? (
        <NoDataPlaceholder
          label="This page will display your meetings, billing and expense entries that you create for your clients entries"
          hideButton
          imageSrc={NoActivities}
        />
      ) : !count && !isInitialFilters ? (
        <TableEmptyState />
      ) : (
        <ActivitiesTable
          profileIsApproved={profileIsApproved}
          setSelectedActivity={setSelectedActivity}
          handleActivityModal={setActivityModal}
          setShowDeleteModal={setShowDeleteModal}
          activities={activities}
          timezone={timezone}
          count={count}
        />
      )}
      <ConfirmationModal
        isOpen={showDeleteModal}
        message={`If you delete the activity, the invoice won’t be generated for that. Delete?`}
        onClose={() => setShowDeleteModal(false)}
        title="Delete activity?"
        onConfirm={onDeleteModalConfirm}
        confirmationButtonText="yes, delete"
        cancelationButtonText="cancel"
      />
      <ProActivityEntryModal
        open={showActivityModal.open}
        type={showActivityModal.type}
        action={showActivityModal.action}
        activity={showActivityModal.activity}
        handleClose={onActivityModalClose}
      />
    </div>
  )
}

export default ProActivitiesContainer
