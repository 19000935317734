import { Button, Modal, Typography } from '../index'
import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import makeStyles from '@material-ui/core/styles/makeStyles'
import * as Yup from 'yup'
import { colors, CONSTANTS } from '../../constants'
import { getPredefinedInvitedUserContactInfo } from 'store/actions/connections'
import { UserAddressFormFragment } from './UserAdressFormFragment'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles({
  modal: {
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.BASIC.COLOR['80'],
    marginBottom: 8,
  },
  subTitle: {
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR['80'],
    marginBottom: 24,
  },
  btnGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginLeft: 24,
  },
})

const validationSchema = Yup.object({
  street: Yup.string()
    .trim()
    .max(100, 'Please ensure this field has no more than 100 characters')
    .required('Please fill in the field'),
  suite: Yup.string()
    .trim()
    .max(50, 'Please ensure this field has no more than 50 characters'),
  city: Yup.string()
    .trim()
    .max(50, 'Please ensure this field has no more than 50 characters')
    .required('Please fill in the field'),
  state: Yup.string()
    .trim()
    .required('Please fill in the field'),
  zip: Yup.string()
    .typeError('Please enter valid ZIP code (5 digits)')
    .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
    .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
    .required('Please fill in the field'),
  phone: Yup.string()
    .test('len', 'Please enter 10 digits', val => (val ? val.toString().replace(/([+ -])/g, '').length === 11 : false))
    .required('Please fill in the field'),
})

export const ContactInfoPopup = ({ isOpen, onClose, onSaveContactInfo, proId }) => {
  const classes = useStyles()
  const states = useSelector(state => state.profileCreation.states)
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({
    phone: '',
    street: '',
    suite: '',
    city: '',
    state: '',
    zip: '',
    country: 840,
  })

  useEffect(() => {
    getPredefinedContactInfo()
  }, [])

  const getPredefinedContactInfo = async () => {
    try {
      const data = await dispatch(getPredefinedInvitedUserContactInfo(proId))
      setInitialValues(data)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <Typography className={classes.title}>Accept invitation</Typography>
        <Typography className={classes.subTitle}>
          Please provide your contact info that will be shared with the lawyer
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={values => {
            onSaveContactInfo(values)
          }}
        >
          <Form>
            <UserAddressFormFragment states={states} />
            <div className={classes.btnGroup}>
              <Button variant="text" onClick={onClose}>
                cancel
              </Button>
              <Button type="submit" variant="primary" className={classes.saveButton} circularSize={22}>
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}
