import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { connect } from 'react-redux'
import MuiAlert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import { Icon, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { hideNotificationSnackbar } from '../../store/actions/notifications/notifications'
import { BaseNotificationBody, getNotificationIcon } from './NotificationTypes'

const useStyles = makeStyles({
  iconButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  icon: {
    color: colors.WHITE,
  },
  alert: {
    backgroundColor: colors.BASIC.COLOR['80'],
  },
})

const NotificationShackbar = props => {
  const { notificationSnackbar, close } = props

  const classes = useStyles()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notificationSnackbar.isOpen}
      autoHideDuration={6000}
      onClose={close}
    >
      <MuiAlert
        severity="info"
        elevation={6}
        variant="filled"
        action={
          <IconButton aria-label="close" size="small" onClick={close} classes={{ root: classes.iconButton }}>
            <Icon style={classes.icon} iconClass="las la-times" />
          </IconButton>
        }
        icon={
          <SvgIcon
            viewBox="0 0 24 24"
            className={classNames({
              [classes.icon]: true,
              [classes.disabledText]: !notificationSnackbar.unread,
            })}
          >
            {getNotificationIcon(notificationSnackbar.type)}
          </SvgIcon>
        }
        classes={{ filledInfo: classes.alert }}
      >
        <div className={classes.content}>
          <BaseNotificationBody notification={notificationSnackbar} />
        </div>
      </MuiAlert>
    </Snackbar>
  )
}

const mapStateToProps = ({ notifications }) => ({
  notificationSnackbar: notifications.notificationSnackbar,
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideNotificationSnackbar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationShackbar)
