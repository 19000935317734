import React from 'react'
import moment from 'moment-timezone'
import TableCell from '@material-ui/core/TableCell'
import classNames from 'classnames'
import { Link } from 'components'
import { CONSTANTS } from 'constants/index'
import { useStyles } from './rowStyles'
import { ReactComponent as MainFolderIcon } from 'static/vault/casesList/folder_row.svg'
import { ReactComponent as SecureIcon } from 'static/vault/secure_folder.svg'
import { formatBytes } from 'utils/vault/vault-helpers'

const SecureVaultTableRow = ({ data, timezone }) => {
  const classes = useStyles()
  return (
    <>
      <TableCell classes={{ body: classes.tableCell }}>
        <div className={classes.cell}>
          {data.isPrivateFolder ? (
            <SecureIcon className={classes.mainFolderIcon} />
          ) : (
            <MainFolderIcon className={classes.mainFolderIcon} />
          )}
          <Link className={classNames(classes.link, { [classes.boldLink]: data.isPrivateFolder })}>{data.name}</Link>
        </div>
      </TableCell>
      <TableCell classes={{ body: classes.tableCell }}>{`${formatBytes(data.size)}`}</TableCell>
      <TableCell classes={{ body: classes.tableCell }}>
        {data.lastUploaded
          ? moment(data.lastUploaded)
              .tz(timezone)
              .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)
          : '-'}
      </TableCell>
    </>
  )
}

export default SecureVaultTableRow
