import * as CONSTANTS from 'store/constants/search'
import {
  getAnswersPath,
  getAveragePricePath,
  getExtendedPracticeAreasPath,
  getProAccountAvailableTimePath,
  getProAccountDegreePath,
  getProAccountLanguagesPath,
  getProAccountLicensesPath,
  getProAccountPath,
  getProAccountPracticeAreasPath,
  getProAccountProfilePath,
  getProAccountSocialLinksPath,
  getProfileListPath,
} from 'utils/path-helpers/api'
import axios, { CancelToken } from 'axios'
import moment from 'moment-timezone'
import { filter, isEmpty, isEqual, clone } from 'lodash'
import { hideNotFound, openSnackbar, showNotFound } from 'store/actions/common'
import { ERROR_SNACKBAR } from 'store/constants/common'
import { getAnalyticsParams, getSearchParams } from 'utils/getters'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

const getProAccountStart = () => ({
  type: CONSTANTS.GET_PRO_ACCOUNT_START,
})
export const setAutocompletePracticeArray = payload => ({
  type: CONSTANTS.SET_AUTOCOMPLETE_PRACTICE_ARRAY,
  payload,
})

export const setCountryState = payload => ({
  type: CONSTANTS.SET_COUNTRY_STATE_VALUE,
  payload,
})

export const setCountryStateValue = payload => dispatch => {
  window.sessionStorage.setItem('countryState', payload)
  dispatch(setCountryState(payload))
}

export const setPractiseArea = payload => ({
  type: CONSTANTS.SET_PRACTICE_AREA_VALUE,
  payload,
})

export const setPractiseSearchName = payload => ({
  type: CONSTANTS.SET_PRACTICE_SEARCH_NAME,
  payload,
})

export const setPractiseSearchType = payload => ({
  type: CONSTANTS.SET_PRACTICE_SEARCH_TYPE,
  payload,
})

const setPractiseAreaParam = payload => ({
  type: CONSTANTS.SET_PRACTICE_AREA_PARAM,
  payload,
})

export const setPracticeAreaSearchObject = payload => ({
  type: CONSTANTS.SET_PRACTICE_AREA_SEARCH_OBJECT,
  payload,
})

export const setClientQuestion = payload => ({
    type: CONSTANTS.SET_CLIENT_QUESTION,
    payload,
})

export const setClientAnswer = payload => ({
    type: CONSTANTS.SET_ANSWER,
    payload,
})

export const setIsLoading = payload => ({
    type: CONSTANTS.SET_IS_LOADING,
    payload,
})

export const setPracticeAreaValue = payload => (dispatch, getState) => {
  window.sessionStorage.setItem('practiceArea', payload)
  const { autocompletePracticeArray } = getState().search
  let practiceAreaParam = '',
    subSpecialityName
  if (payload && !isEmpty(autocompletePracticeArray)) {
    subSpecialityName = autocompletePracticeArray.find(option => option.name === payload)
    if (subSpecialityName) {
      practiceAreaParam = subSpecialityName.sub_speciality_name
    }
  }
  if (practiceAreaParam) {
    dispatch(setPractiseArea(payload))
    dispatch(setPractiseAreaParam(practiceAreaParam))
  }
}

export const setAutocompleteArray = payload => ({
  type: CONSTANTS.SET_AUTOCOMPLETE_ARRAY,
  payload,
})

export const getAnswer = () => (dispatch, getState) => {
    dispatch(setIsLoading(true))
    const { clientQuestion, countryStateValue } = getState().search
    axios
        .post(getAnswersPath(), {
            question: clientQuestion,
            state: countryStateValue,
        })
        .then(response => {
          dispatch(setClientAnswer(response.data.answer))
          dispatch(setPractiseAreaParam(response.data.categories[0] || ''))
          dispatch(setPractiseArea(response.data.categories[0] || ''))
          dispatch(setIsLoading(false))
        })
        .catch(e => {
          dispatch(openSnackbar(ERROR_SNACKBAR, 'Something went wrong. Try again later'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
}

export const getPracticeAreaMatches = () => dispatch => {
  dispatch(getPracticeAreaMatchesStart())
  return axios
    .get(getExtendedPracticeAreasPath())
    .then(response => {
      const savedArea = window.sessionStorage.getItem('practiceArea')
      const data = response.data.reduce((result, area) => {
        const subSpecialities = area.sub_specialities.map(subSpeciality => ({
          ...subSpeciality,
          practiceArea: area.name,
        }))
        return [...result, ...subSpecialities]
      }, [])
      const selectedPractiseArea = data.find(item => item.name === savedArea) || {}
      dispatch(setPracticeAreaSearchObject(selectedPractiseArea))
      dispatch(
        setDefaultFilter({
          state_search: !selectedPractiseArea.is_federal,
        }),
      )
      dispatch(setAutocompletePracticeArray(data))
    })
    .catch(e => {
      if (e.response && e.response.status === 401) {
        dispatch(getPracticeAreaMatches())
      } else {
        dispatch(openSnackbar('error', 'Error while practice areas loading'))
        return Promise.reject(e)
      }
    })
    .finally(() => dispatch(getPracticeAreaMatchesEnd()))
}

export const getPracticeAreaMatchesStart = () => ({
  type: CONSTANTS.GET_PRACTICE_AREA_MATCHES_START,
})

export const getPracticeAreaMatchesEnd = () => ({
  type: CONSTANTS.GET_PRACTICE_AREA_MATCHES_END,
})

export const getProAccount = id => (dispatch, getState) => {
  const proAccount = {
    id: id,
    degrees: [],
    bio: '',
    languages: [],
    licenses: [],
    practiceAreas: [],
    rating: null,
    reviews: null,
    socialLinks: [],
    firstName: '',
    lastName: '',
    photo: '',
    video: '',
    isInitial: false,
  }
  dispatch(getProAccountStart())
  Promise.all([
    axios.get(getProAccountDegreePath(id)).then(response => (proAccount.degrees = response.data)),
    axios.get(getProAccountLanguagesPath(id)).then(response => (proAccount.languages = response.data)),
    axios.get(getProAccountLicensesPath(id)).then(response => (proAccount.licenses = response.data)),
    axios.get(getProAccountPracticeAreasPath(id)).then(response => (proAccount.practiceAreas = response.data)),
    axios.get(getProAccountSocialLinksPath(id)).then(response => (proAccount.socialLinks = response.data)),
    axios.get(getProAccountProfilePath(id)).then(response => {
      proAccount.bio = response.data.bio
      proAccount.video = response.data.introduction_video
      proAccount.rating = response.data.rating
      proAccount.reviews = response.data.reviews
      proAccount.connectionWithProExists = response.data.is_ready_for_followup
      proAccount.isInitial = !response.data.is_ready_for_followup
      proAccount.isRecommended = response.data.is_recommended
      proAccount.active_calendar = response.data.active_calendar
      proAccount.email = response.data.email
    }),
    axios.get(getProAccountPath(id)).then(response => {
      proAccount.firstName = response.data.first_name
      proAccount.lastName = response.data.last_name
      proAccount.photo = response.data.photo
    }),
  ])
    .then(() => {
      dispatch(hideNotFound())
      dispatch(getProAccountEnd(proAccount))
    })
    .catch(error => {
      dispatch(getProAccountError())
      if (error.response && error.response.status === 404) {
        dispatch(showNotFound())
      }
    })
}

export const setMeetingDuration = payload => ({
  type: CONSTANTS.SET_MEETING_DURATION,
  payload,
})

export const getProAccountAvailableTime = id => (dispatch, getState) => {
  dispatch({
    type: CONSTANTS.GET_PRO_ACCOUNT_AVAILABLE_TIME_START,
  })
  axios
    .get(getProAccountAvailableTimePath(id), {
      params: {
        timestamp: moment().unix(),
        timezone: moment.tz.guess(),
        duration: getState().search.duration,
      },
    })

    .then(response => {
      dispatch({
        type: CONSTANTS.GET_PRO_ACCOUNT_AVAILABLE_TIME_END,
        payload: response.data.days,
      })
    })
    .catch(e => {
      dispatch(openSnackbar(ERROR_SNACKBAR, 'Error while calendar loading'))
      dispatch({
        type: CONSTANTS.GET_PRO_ACCOUNT_AVAILABLE_TIME_END,
        payload: {},
      })
      return Promise.resolve()
    })
}
const getProAccountEnd = payload => ({
  type: CONSTANTS.GET_PRO_ACCOUNT_END,
  payload,
})

const getProAccountError = payload => ({
  type: CONSTANTS.GET_PRO_ACCOUNT_ERROR,
  payload,
})

export const getProfileListStart = () => ({
  type: CONSTANTS.GET_PROFILE_LIST_START,
})

export const getProfileListLazyLoadStart = () => ({
  type: CONSTANTS.GET_PROFILE_LIST_LAZY_LOAD_START,
})

export const getProfileListEnd = data => ({
  type: CONSTANTS.GET_PROFILE_LIST_END,
  payload: data.results,
  profilesCount: data.count,
  profilesEmptyMsg: data.message,
})

const setDefaultSearchParams = payload => ({
  type: CONSTANTS.SET_DEFAULT_SEARCH_PARAMS,
  payload,
})

const setRandomCeed = payload => ({
  type: CONSTANTS.SET_RANDOM_SEED,
  payload,
})

let searchCancelToken

export const getProfileList = (isReUpload = true, isSearchPressed = false) => (dispatch, getState) => {
  if (isReUpload && searchCancelToken) {
    searchCancelToken()
  }

  const { practiceAreaValue, practiceAreaParam, practiceSearchName, practiceSearchType } = getState().search
  if (!practiceAreaValue.length && !practiceSearchName) {
    return dispatch(openSnackbar(ERROR_SNACKBAR, 'Please choose valid practice area'))
  }
  const searchListParam = practiceSearchType
    ? 'Search'
    : practiceAreaValue === practiceAreaParam
    ? practiceAreaParam
    : `${practiceAreaParam} (${practiceAreaValue})`
  dispatch(setSearchListTitle(searchListParam))
  //don't show loader when infinite scroll upload additional users
  if (isReUpload) {
    dispatch(getProfileListStart())
    if (!practiceSearchType) {
      dispatch(getAveragePrice(practiceAreaParam))
    } else {
      dispatch(gotAveragePrice(''))
    }
  } else {
    dispatch(getProfileListLazyLoadStart())
  }

  if (isReUpload) {
    dispatch(resetUploadOffset())
  }

  const { search, profileCreation } = getState()
  const params = getSearchParams(search, profileCreation.states)
  const clonedParams = clone({
    ...params,
    start_free_time: search.filters.startFreeTimeValue,
    end_free_time: search.filters.endFreeTimeValue,
  })
  let { random_seed, defaultSearchParams } = search
  if (isEmpty(defaultSearchParams)) {
    dispatch(setDefaultSearchParams(clonedParams))
  } else if (isEqual(clonedParams, search.defaultSearchParams) && isSearchPressed) {
    random_seed = Math.random()
    dispatch(setRandomCeed(random_seed))
  }

  Analytics.track(EVENTS.SEARCH, getAnalyticsParams(search))

  return axios
    .get(getProfileListPath(), {
      params: { ...params, random_seed },
      cancelToken: new CancelToken(c => {
        searchCancelToken = c
      }),
    })
    .then(payload => {
      const { data } = payload
      const result = {
        count: data.count,
        results: isReUpload ? data.results : [...search.profiles, ...payload.data.results],
        message: data && data.message,
      }

      dispatch(getProfileListEnd(result))
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        return
      }
      dispatch(getProfileListEnd({ results: [], count: 0 }))
      return Promise.reject(error && error.data)
    })
}

export const resetUploadOffset = () => ({
  type: CONSTANTS.RESET_UPLOAD_OFFSET,
})

export const increaseUploadOffset = () => ({
  type: CONSTANTS.INCREASE_UPLOAD_OFFSET,
})

export const resetSearchFilters = () => ({
  type: CONSTANTS.RESET_FILTERS,
})

export const setSearchFilter = (name, value) => ({
  type: CONSTANTS.SET_FILTER,
  name,
  value,
})

export const setSearchSorter = value => ({
  type: CONSTANTS.SET_SORTER,
  value,
})

const gotAveragePrice = payload => ({
  type: CONSTANTS.GOT_AVERAGE_PRICE,
  payload,
})

const getAveragePrice = practiceAreaValue => (dispatch, getState) => {
  const { states } = getState().profileCreation
  const { countryStateValue } = getState().search
  if (!practiceAreaValue) {
    return
  }
  const stateCode = filter(states, ['label', countryStateValue])[0]
  if (!stateCode) {
    return
  }
  axios
    .get(getAveragePricePath(), {
      params: {
        search: practiceAreaValue,
        state: stateCode.value,
      },
    })
    .then(response => {
      dispatch(gotAveragePrice(response.data))
    })
    .catch()
}

const setSearchListTitle = title => ({
  type: CONSTANTS.SET_SEARCH_LIST_TITLE,
  payload: title,
})

export const setDefaultFilter = payload => ({
  type: CONSTANTS.SET_DEFAULT_SEARCH_FILTER,
  payload,
})
