import React from 'react'
import Fade from '@material-ui/core/Fade'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core'
import { Icon, IconButton } from 'components'
import classNames from 'classnames'

const useStyles = makeStyles({
  closeModalButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    height: 25,
    width: 25,
    padding: '0!important',
  },
  closeModal: {
    fontSize: 18,
    color: '#575757',
    cursor: 'pointer',
  },
  hiddenCloseIcon: {
    display: 'none',
  },
})

const XModal = ({
  title,
  description,
  open,
  handleClose,
  children,
  maxWidth = 'sm',
  TransitionComponent,
  dialogClasses,
  scroll = 'body',
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  BackdropProps,
  closeIconClass,
  closeButtonClass,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      aria-labelledby={title}
      aria-describedby={description}
      open={open}
      onClose={handleClose}
      scroll={scroll}
      maxWidth={maxWidth}
      TransitionComponent={TransitionComponent}
      classes={dialogClasses}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      BackdropProps={BackdropProps}
    >
      <Fade in={open}>{children}</Fade>
      <IconButton
        onClick={handleClose}
        className={classNames({
          [classes.closeModalButton]: true,
          [closeButtonClass]: closeButtonClass,
        })}
      >
        <Icon
          iconClass="las la-times"
          style={classNames({ [classes.closeModal]: true, [closeIconClass]: closeIconClass })}
        />
      </IconButton>
    </Dialog>
  )
}

export default XModal
