import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, Link, Tooltip, VaultButton } from 'components'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS, routes } from 'constants/index'
import { ReactComponent as DescriptionLogo } from 'static/activities/description.svg'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import {
  navigateToCaseDetailsInNewTab,
  navigateToEditCaseInNewTab,
  navigateToProConnectionsInNewTab,
} from 'store/actions/navigation'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles({
  bulletCell: {
    width: 10,
  },
  circle: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    backgroundColor: colors.BASIC.COLOR[20],
  },
  activeBullet: {
    backgroundColor: colors.SUCCESS,
  },
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
  },
  tooltip: {
    padding: 16,
  },
  inactiveCase: {
    color: colors.BASIC.COLOR[20],
  },
  feeType: {
    cursor: 'pointer',
  },
  tableCellTextClass: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  amountCellTextClass: {
    textAlign: 'end',
  },
  cell: {
    maxWidth: 1,
  },
  tooltipText: {
    fontSize: 13,
  },
})

const ACTIVITIES_TYPE = {
  TIME: 0,
  EXPENSE: 1,
}

const ProActivitiesTableRow = ({ data, timezone }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const timeLabel = data.activityType === ACTIVITIES_TYPE.TIME ? `${data.hours}h ${data.minutes}m` : '-'
  const chargeLabel = !data.billable ? 'N/A' : data.clientCharge ? `$${data.clientCharge}` : 'no charge'

  const invoiceDate = moment(data.invoiceDate)
    .tz(timezone)
    .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)

  const invoiceLabel =
    data.billable && data.invoiceDate ? (
      data.invoice ? (
        <Link
          className={classes.link}
          component={RouterLink}
          to={`${routes.getProAccountFinancesInvoicesViewPath()}/${data.invoice}`}
          target="_blank"
        >
          {invoiceDate}
        </Link>
      ) : (
        invoiceDate
      )
    ) : (
      'N/A'
    )

  const navigateToCase = () => {
    data.case && data.case.is_active && data.case.fee_type !== CONSTANTS.CASE_FEE_TYPES_BY_LABEL.INITIAL
      ? dispatch(navigateToEditCaseInNewTab(data.case.uuid))
      : dispatch(navigateToCaseDetailsInNewTab(data.case.uuid))
  }

  const navigateToClient = () => {
    dispatch(navigateToProConnectionsInNewTab({ clientName: data.case.client_name }))
  }

  const clientName = data.isClientDeleted ? (
    <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
      {data.case.client_name}
    </Typography>
  ) : (
    <Link onClick={navigateToClient} className={classes.link}>
      {data.case.client_name}
    </Link>
  )

  return (
    <>
      <TableCell>{clientName}</TableCell>
      <TableCell>
        <Link onClick={navigateToCase} className={classes.link}>
          {data.case.name}
        </Link>
      </TableCell>
      <TableCell>
        {data.description && (
          <Tooltip
            title={
              <Typography className={classes.tooltipText} variant="subtitle1">
                {data.description}
              </Typography>
            }
            tooltipClass={classes.tooltip}
          >
            <DescriptionLogo />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            <Typography className={classes.tooltipText} variant="subtitle1">
              {CONSTANTS.ACTIVITY_TYPE[data.activityType].hint}
            </Typography>
          }
          tooltipClass={classes.tooltip}
        >
          {CONSTANTS.ACTIVITY_TYPE[data.activityType].icon}
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {timeLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {moment(data.creationDate)
            .tz(timezone)
            .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {chargeLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {invoiceLabel}
        </Typography>
      </TableCell>
      <TableCell>
        <VaultButton
          isInitialCase={data.case.fee_type === CONSTANTS.FEE_TYPES['0'].key}
          options={{ userID: data.case.client, caseID: data.case.uuid }}
          vaultPlace="activities"
        />
      </TableCell>
    </>
  )
}

export default ProActivitiesTableRow
