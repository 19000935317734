import React from 'react'
import { Formik, Form } from 'formik'
import { FormikInput, Button, Link, Modal, Typography, Icon } from 'components'
import { sendResetPasswordEmail } from 'store/actions/auth'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  modal: {
    '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
      '@media (max-width:665px)': {
        width: '100%',
        maxWidth: 'none',
      },
    },
    '@media (max-width:665px)': {
      margin: 0,
    },
  },
  restorePassword: {
    width: '100%',
  },
  restorePasswordContainer: {
    margin: '50px 100px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:665px)': {
      margin: '50px auto',
      padding: '0 15px',
    },
  },
  restorePasswordTitle: {
    marginBottom: 32,
  },
  logInLink: {
    marginTop: 40,
    alignSelf: 'flex-end',
  },
  btn: {
    '@media (max-width:665px)': {
      width: '100%',
    },
  },
  closeButtonClass: {
    display: 'none',
  },
})

const initialValues = {
  email: '',
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Looks like this email is incomplete')
    .trim()
    .required('please type your email'),
})

const RestorePassword = ({ sendResetUserPasswordEmail, disableClose, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <>
      <Modal
        open={props.open}
        handleClose={props.handleClose}
        scroll="body"
        dialogClasses={{ paper: classes.modal }}
        closeButtonClass={disableClose && classes.closeButtonClass}
      >
        <div className={classes.restorePassword}>
          <div className={classes.restorePasswordContainer}>
            <Typography className={classes.restorePasswordTitle} variant="h4">
              Enter your email to reset password
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={values => {
                dispatch(sendResetPasswordEmail(values.email))
              }}
            >
              <Form>
                <FormikInput variant="outlined" name="email" label="Email" type="text" />
                <Button variant="primary" size="large" type="submit" classes={{ containedSizeLarge: classes.btn }}>
                  Reset Password
                </Button>
              </Form>
            </Formik>
            <Link className={classes.logInLink} onClick={props.goToLogIn}>
              Back to Log in
            </Link>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RestorePassword
