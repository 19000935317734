import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormikInput, SvgIcon, Typography, ColorCircularProgress, FormikAutocomplete } from 'components'
import classNames from 'classnames'
import { colors, CONSTANTS } from 'constants/index'
import { ReactComponent as IconLocation } from 'static/contactUs/at.svg'
import { ReactComponent as IconPhone } from 'static/contactUs/phone.svg'
import { ReactComponent as IconEmail } from 'static/contactUs/pin.svg'
import * as Yup from 'yup'
import { submitTicket } from 'store/actions/common'
import { useDispatch, useSelector } from 'react-redux'
import { useAnalytics } from 'utils/hooks'

const { WHITE } = colors

const useStyles = makeStyles({
  contentWrapper: {
    width: '100%',
    fontFamily: 'Rubik',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.47,
    color: colors.BASIC.COLOR[100],
  },
  header: {
    padding: '100px 0',
    background: 'linear-gradient(45deg, #55b4d4, #0f7699)',
    textAlign: 'center',
  },
  headerTitle: {
    fontFamily: 'Raleway',
    fontSize: 86,
    fontWeight: 300,
    color: WHITE,
  },
  contacts: {
    maxWidth: 1140,
    margin: '0 auto',
    padding: '100px 10px',
    backgroundColor: WHITE,
  },
  sectionForm: {
    width: '100%',
    paddingTop: 48,
    paddingBottom: 51,
    backgroundColor: colors.BASIC.COLOR[5],
    position: 'relative',
    minHeight: 400,
  },
  form: {
    maxWidth: 620,
    padding: '0 10px',
    margin: '0 auto',
  },
  row: {
    marginBottom: 24,
  },
  headerForm: {
    marginBottom: 57,
    fontFamily: 'Montserrat',
    fontSize: 54,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1,
  },
  halfRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  half: {
    width: '48%',
    '@media (max-width: 767px)': {
      marginBottom: 31,
      width: '100%',
    },
  },
  control: {
    marginBottom: 0,
    textAlign: 'center',
  },
  label: {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: 16,
    color: colors.BASIC.COLOR[100],
  },
  btn: {
    width: 200,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    marginTop: 12,
  },
  advice: {
    marginTop: 4,
    fontFamily: 'Rubik',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.31,
  },

  desc: {
    marginBottom: 16,
  },
  contactList: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contactItem: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: 270,
      marginBottom: 30,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  contactTitle: {
    marginBottom: 16,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 28,
    fontWeight: 500,
    color: colors.PRIMARY.COLOR[50],
    lineHeight: 1,
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  contactdesc: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: 16,
    fontWeight: 300,
    color: colors.BASIC.COLOR[70],
    lineHeight: 1.41,
    '@media (max-width: 767px)': {
      textAlign: 'center',
    },
  },
  contactIcon: {
    marginRight: 15,
    marginTop: 2,
    '@media (max-width: 767px)': {
      margin: 0,
    },
  },
  textArea: {
    height: 'auto',
  },

  error: {
    color: colors.ERROR,
    marginBottom: 12,
  },
})

const ContactDesc1 = () => {
  const classes = useStyles()
  return (
    <Typography variant="body1" className={classes.contactdesc}>
      XIRA Connect, Inc.
      <br />
      228 Hamilton Avenue, 3rd Flr
      <br />
      Palo Alto, CA 94301
    </Typography>
  )
}

const ContactDesc2 = () => {
  const classes = useStyles()
  return (
    <Typography variant="body1" className={classes.contactdesc}>
      +1 (408) 714 0000
    </Typography>
  )
}

const ContactDesc3 = () => {
  const classes = useStyles()
  return (
    <Typography variant="body1" className={classes.contactdesc}>
      General - info@xira.com
      <br />
      Media - media@xira.com
    </Typography>
  )
}

const contactsContent = [
  {
    icon: <SvgIcon component={IconLocation} viewBox="0 0 24 24" />,
    title: 'address',
    desc: <ContactDesc1 />,
  },
  {
    icon: <SvgIcon component={IconPhone} viewBox="0 0 24 24" />,
    title: 'phone',
    desc: <ContactDesc2 />,
  },
  {
    icon: <SvgIcon component={IconEmail} viewBox="0 0 24 24" />,
    title: 'email',
    desc: <ContactDesc3 />,
  },
]

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  subject: '',
  description: '',
}

const validation = Yup.object({
  email: Yup.string()
    .email('Looks like this email is incomplete')
    .trim()
    .required('Email cannot be blank'),
  first_name: Yup.string()
    .trim()
    .required('First name cannot be blank'),
  last_name: Yup.string()
    .trim()
    .required('Last name cannot be blank'),
  subject: Yup.string()
    .trim()
    .required('Subject cannot be blank'),
  description: Yup.string()
    .trim()
    .required('Description cannot be blank'),
})

const ContactUsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const ticketIsLoading = useSelector(state => state.common.ticketIsLoading)

  useAnalytics()

  const contacts = contactsContent.map(contact => {
    return (
      <li className={classes.contactItem} key={contact.title}>
        <div className={classes.contactIcon}>{contact.icon}</div>
        <div className={classes.contactInfo}>
          <Typography variant="h2" className={classes.contactTitle}>
            {contact.title}
          </Typography>
          {contact.desc}
        </div>
      </li>
    )
  })

  const onSubmit = async data => {
    await dispatch(submitTicket(data))
  }

  return (
    <div className={classes.contentWrapper}>
      <section className={classes.header}>
        <Typography variant="h2" className={classes.headerTitle}>
          contact us
        </Typography>
      </section>
      <section className={classes.contacts}>
        <ul className={classes.contactList}>{contacts}</ul>
      </section>
      <section className={classes.sectionForm}>
        <Typography variant="h2" className={classes.headerForm}>
          submit a request
        </Typography>
        {ticketIsLoading ? (
          <ColorCircularProgress />
        ) : (
          <Formik initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit} enableReinitialize>
            <Form className={classes.form}>
              <div className={classes.row}>
                <FormikInput
                  innerLabel
                  variant="outlined"
                  size="small"
                  name="email"
                  label="Email *"
                  type="text"
                  fullWidth
                  InputLabelProps={{ classes: { root: classes.label }, shrink: true }}
                />
              </div>
              <div className={classNames(classes.row, classes.halfRow)}>
                <FormikInput
                  variant="outlined"
                  size="small"
                  name="first_name"
                  label="First name *"
                  type="text"
                  fullWidth
                  containerClasses={classes.half}
                  innerLabel
                  InputLabelProps={{ classes: { root: classes.label }, shrink: true }}
                />
                <FormikInput
                  variant="outlined"
                  size="small"
                  name="last_name"
                  label="Last name *"
                  type="text"
                  fullWidth
                  containerClasses={classes.half}
                  innerLabel
                  InputLabelProps={{ classes: { root: classes.label }, shrink: true }}
                />
              </div>
              <div className={classes.row}>
                <FormikAutocomplete
                  variant="outlined"
                  size="small"
                  name="subject"
                  label="Subject *"
                  type="text"
                  fullWidth
                  innerLabel
                  InputLabelProps={{ classes: { root: classes.label }, shrink: true }}
                  options={CONSTANTS.CONTACT_US_SUBJECTS}
                />
              </div>
              <div className={classNames(classes.row, classes.desc)}>
                <FormikInput
                  variant="outlined"
                  size="small"
                  name="description"
                  label="Description *"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  inputClasses={classes.textArea}
                  innerLabel
                  InputLabelProps={{ classes: { root: classes.label }, shrink: true }}
                />
                <div className={classes.advice}>
                  Please enter the details of your request. A member of our support staff will respond as soon as
                  possible.
                </div>
              </div>
              <div className={classNames(classes.row, classes.control)}>
                <Button variant="primary" type="submit" className={classes.btn}>
                  SEND REQUEST
                </Button>
              </div>
            </Form>
          </Formik>
        )}
      </section>
    </div>
  )
}

export default ContactUsPage
