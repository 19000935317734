import React from 'react'
import { connect } from 'react-redux'
import { Button, ColorCircularProgress, DegreesTable, Icon, Typography } from 'components'
import DegreeModal from './modals/DegreeModal'
import DeleteDegreeModal from './modals/DeleteDegreeModal'
import { openSnackbar } from 'store/actions/common'
import { updateUserDegrees } from 'store/actions/accountProfile'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants'


const styles = {
  credentialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  credentialLabel: {
    marginBottom: 4,
  },
  credentialSubtitle: {
    color: colors.BASIC.COLOR[100],
    marginBottom: 18,
  },
  credentialAddBtn: {
    marginTop: 20,
    marginBottom: 30,
  },
}

class CredentialsContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      addEditModalState: {
        isOpen: false,
        title: '',
        degree: null,
        onSubmit: null,
        onClose: this.onCloseModal,
      },
      errorMessage: null,
      deleteModalState: {
        isOpen: false,
        degree: null,
      },
    }
  }

  onCloseModal = () => {
    this.setState(prevState => ({
      addEditModalState: { ...prevState.addEditModalState, isOpen: false },
    }))
  }

  onAddDegreeClick = () => {
    this.setState(prevState => ({
      addEditModalState: {
        ...prevState.addEditModalState,
        isOpen: true,
        title: 'Add Degree',
        degree: null,
        onSubmit: this.onAddDegree,
      },
    }))
  }
  onAddDegree = data => {
    const updatedDegrees = [...this.props.degrees, data]
    this.props.updateProfileUserDegrees(updatedDegrees)
    this.onCloseModal()
  }
  onEditDegree = data => {
    const updatedDegrees = this.props.degrees.map(degree => (degree.id === data.id ? data : degree))
    this.props.updateProfileUserDegrees(updatedDegrees)
    this.onCloseModal()
  }
  onDeleteDegree = id => {
    const updatedDegrees = this.props.degrees.filter(degree => degree.id !== id)
    this.props.updateProfileUserDegrees(updatedDegrees)
    this.setState(prevState => ({
      deleteModalState: { ...prevState.deleteModalState, isOpen: false },
    }))
  }
  onDeleteDegreeBtnClick = id => {
    this.setState(prevState => ({
      deleteModalState: {
        ...prevState.deleteModalState,
        isOpen: true,
        degree: id,
      },
    }))
  }
  onEditDegreeBtnClick = id => {
    this.setState(prevState => ({
      addEditModalState: {
        ...prevState.addEditModalState,
        isOpen: true,
        title: 'Edit Degree',
        degree: this.props.degrees.find(degree => degree.id === id),
        onSubmit: this.onEditDegree,
      },
    }))
  }

  render() {
    const { classes } = this.props

    return this.props.areDegreesLoading || this.props.areAllDegreesLoading ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.credentialContainer}>
        <Typography className={classes.credentialLabel} variant="h4">
          Degrees
        </Typography>
        <Typography variant="subtitle1" className={classes.credentialSubtitle}>
          Add the schools you attended, areas of study, and degrees earned.
        </Typography>
        {this.props.degrees && this.props.degrees.length ? (
          <DegreesTable
            degrees={this.props.degrees}
            onEdit={this.onEditDegreeBtnClick}
            onDelete={this.onDeleteDegreeBtnClick}
          />
        ) : null}
        {this.props.degrees && this.props.degrees.length < 10 && (
          <Button
            className={classes.credentialAddBtn}
            variant="primary"
            onClick={this.onAddDegreeClick}
            startIcon={<Icon iconClass="las la-plus-circle" />}
          >
            add degree
          </Button>
        )}
        <DegreeModal
          title={this.state.addEditModalState.title}
          open={this.state.addEditModalState.isOpen}
          degree={this.state.addEditModalState.degree}
          onSubmit={this.state.addEditModalState.onSubmit}
          onClose={this.state.addEditModalState.onClose}
          allDegrees={this.props.allDegrees}
        />
        <DeleteDegreeModal
          open={this.state.deleteModalState.isOpen}
          onClose={() =>
            this.setState(prevState => ({
              deleteModalState: {
                ...prevState.deleteModalState,
                isOpen: false,
              },
            }))
          }
          onSubmit={this.onDeleteDegree}
          degree={this.state.deleteModalState.degree}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ accountProfile }) => ({
  degrees: accountProfile.degrees,
  allDegrees: accountProfile.allDegrees,
  areDegreesLoading: accountProfile.areDegreesLoading,
  areAllDegreesLoading: accountProfile.areAllDegreesLoading,
})

const mapDispatchToProps = dispatch => ({
  updateProfileUserDegrees: degrees => dispatch(updateUserDegrees(degrees)),
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CredentialsContainer))
