import React, { useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Button,
  Checkbox,
  Icon,
  InputSlider,
  Rating,
  Slider,
  SvgIcon,
  Switch,
  Tooltip,
  Typography,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLanguages, showFilter } from 'store/actions/common'
import isEqual from 'lodash/isEqual'
import { colors, CONSTANTS } from 'constants/index'
import { initialState } from 'store/reducers/search'
import FilterSection from './FilterSection'
import classnames from 'classnames'
import { ReactComponent as IconBack } from 'static/icon_24_back.svg'
import { ReactComponent as IconCheckLang } from 'static/icon_24_check.svg'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'
import { useQuery } from 'utils/hooks'

const useStyles = makeStyles({
  filtersContainer: {
    height: '100%',
    borderRight: `1px solid ${colors.BASIC.COLOR[5]}`,
    padding: '10px 20px 20px 40px',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  language: {},
  resetBtnLabel: {
    textTransform: 'lowercase',
    textDecoration: 'underline',
    fontSize: 15,
    color: colors.BASIC.COLOR[100],
    '&:hover': {
      borderColor: colors.PRIMARY.COLOR[50],
      color: colors.PRIMARY.COLOR[50],
    },
  },
  resetBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.PRIMARY.COLOR[50],
    },
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  popupIcon: {
    fontSize: 16,
  },
  ratingContainerClasses: {
    display: 'flex',
    paddingTop: 5,
  },
  ratingContainerText: {
    fontWeight: 500,
    margin: '3px 5px',
    fontSize: 15,
  },
  hint: {
    color: colors.BASIC.COLOR[70],
    fontSize: 20,
    cursor: 'pointer',
    lineHeight: '1.29',
    transition: '.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolTip: {
    width: 281,
    padding: 16,
    backgroundColor: colors.white,
    color: colors.BASIC.COLOR[100],
    fontSize: 13,
    fontWeight: 'normal',
  },
  availabilityLabel: {
    color: colors.PRIMARY.COLOR[50],
    fontWeight: 500,
  },
  anytimeLabel: {
    color: colors.BASIC.COLOR[80],
  },
  availabilityTimeSlider: {
    color: colors.WHITE,
  },
  popper: {
    backgroundColor: colors.PRIMARY.COLOR[50],
    color: colors.WHITE,
    fontSize: 12,
    fontWeight: 'normal',
  },
  arrow: {
    color: colors.PRIMARY.COLOR[50],
    boxShadow: 'none',
  },
  markLabel1: {
    margin: 0,
    fontSize: 13,
    color: colors.BASIC.COLOR[100],
    marginLeft: 30,
  },
  label: {
    marginRight: 6,
  },
  checkbox: {
    padding: '8px 12px 8px 9px',
  },
  laptop: {
    display: 'none',
  },
  dekstop: {
    display: 'block',
  },
  sliderLabelRoot: {
    marginRight: 0,
  },
  '@media(max-width: 939px)': {
    filtersContainer: {
      width: '100%',
      height: '100vh',
      padding: 0,
    },
    content: {
      flex: '1 0 auto',
    },
    filterHeader: {
      display: 'flex !important',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '13px 20px',
      fontSize: 17,
    },
    closeBtn: {
      fontSize: 20,
      cursor: 'pointer',
    },

    btnShowResults: {
      display: 'flex',
      flexDirection: 'column',
      height: '48px',
      fontSize: 14,
      width: '90%',
      justifyContent: 'center',
      margin: '0 auto',
      flex: '0 0 auto',
      justifySelf: 'center',
      zIndex: 1206,
      marginBottom: 10,
      marginTop: 20,
    },
    btnShowResultsSticky: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: colors.WHITE,
    },
    countLawyers: {
      fontWeight: 200,
      fontSize: 11,
    },
    outline: {
      outline: 'none',
      border: 'none',
    },
    mobile: {
      display: 'none',
      '&:before': {
        content: '""',
        border: 'none',
      },
    },
    dekstop: {
      display: 'none',
    },
    laptop: {
      display: 'block',
    },
    iconBack: {
      color: colors.WHITE,
    },
    iconBack2: {
      transform: 'rotate(180deg)',
    },

    increaseSize: {
      display: 'flex',
      justifyContent: 'center',
      transform: 'scale(1.7)',
      marginBottom: 15,
    },

    /*switch button*/
    checked: {
      backgroundColor: '#ee5f10',
    },
    switchContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '12px 0',
      '& label': {
        display: 'flex',
      },
    },
    ratingContainerClasses: {
      flexDirection: 'column',
      paddingTop: 10,
    },
    root: {
      width: '50px!important',
      height: '32px!important',
      padding: '0!important',
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(18px)!important',
        color: '#fff!important',
        '& + $track': {
          backgroundColor: '#ee5f10!important',
          opacity: '1!important',
          border: 'none!important',
        },
      },
    },
    thumb: {
      width: '28px!important',
      height: '28px!important',
    },
    track: {
      borderRadius: '16px!important',
      backgroundColor: '#969696!important',
      opacity: '1!important',
    },
    label: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      margin: 0,
    },
    checkboxContainer: {
      margin: '12px 0',
      padding: '5px 0',
    },
    formLang: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    option: {
      display: 'flex',
      alignItems: 'center',
      height: 45,
      borderBottom: `1px solid #f2f2f2`,
      paddingLeft: 15,
    },
    btnLang: {
      backgroundColor: colors.WHITE,
      border: 'none',
      height: '43px',
      '&:active, .btnLang:focus': {
        outline: 'none !important',
      },
      outline: 'none !important',
      cursor: 'pointer',
    },
    checkIcon: {
      visibility: 'hidden',
      color: colors.WHITE,
    },
    checkIconActive: {
      visibility: 'visible',
    },
    btnLangOpen: {
      border: 'none',
      backgroundColor: colors.WHITE,
      '&:active, &:focus': {
        outline: 'none !important',
      },
      outline: 'none !important',
      cursor: 'pointer',
    },
    inp: {
      border: 'none',
      cursor: 'default',
      fontSize: 17,
      marginBottom: 0,
    },
    filterLang: {
      padding: '0 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    lableFee: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      fontSize: 17,
    },
    headersLable: {
      color: '#969696',
      fontSize: '17px',
    },
    responsiveRatingButton: {
      display: 'none',
    },
    rate: {
      paddingRight: 60,
    },
  },
  '@media(max-width: 767px)': {
    checkboxContainer: {
      margin: '10px 0',
    },
    rate: {
      paddingRight: 30,
    },
  },
  slider: {
    '&.Mui-disabled': {
      color: colors.WHITE,
    },
  },
  sliderLabel: {
    fontWeight: 'normal',
    color: colors.BLACK,
    marginRight: 8,
    '& + .Mui-disabled': {
      color: colors.BASIC.COLOR['50'],
    },
  },
})

const ValueLabelComponent = props => {
  const { children, value } = props
  const classes = useStyles()
  return (
    <Tooltip tooltipClass={classes.popper} arrowClass={classes.arrow} showChildren placement="top" arrow title={value}>
      {children}
    </Tooltip>
  )
}
const RESET_FILTERS = 'reset all'
const HOURLY_RATE_FILTER = 'hourly rate'
const FEE_TYPES_FILTER = 'fee types'
const AVAILABILITY_TIME_FILTER = 'availability time'
const RATING_FILTER = 'rating'
const LANGUAGE_FILTER = 'preferred language'
const SHORT_LANGUAGE_FILTER = 'Language'
const MIN_PRICE = 'min'
const MAX_PRICE = 'max'
const UP_RATING_TEXT = 'min rating'
const CHECKBOX_NAMES = {
  FREE_CONSULTATION: 'consultationCost',
  CONTINGENCY: 'contingency',
  FIXED_PRICE: 'fixedPrice',
  STATE_SEARCH: 'state_search',
}
const CHECKBOX_LABELS = {
  [CHECKBOX_NAMES.FREE_CONSULTATION]: 'no charge initial consult',
  [CHECKBOX_NAMES.CONTINGENCY]: 'contingency',
  [CHECKBOX_NAMES.FIXED_PRICE]: 'flat fee',
  [CHECKBOX_NAMES.STATE_SEARCH]: 'lawyers in my state only',
}
const SWITCH_HINTS =
  'If you choose to view a list of lawyers that includes out of state lawyers, XIRA allows that for certain practice areas that are based on federal law. To see which state(s) a lawyer is licensed in, visit that lawyer’s XIRA profile page.'
const CHECKBOX_HINTS = {
  [CHECKBOX_NAMES.CONTINGENCY]:
    'Under a contingency agreement, the lawyer does not collect a fee unless the case is won. The lawyer then takes a percentage of the award.',
  [CHECKBOX_NAMES.FIXED_PRICE]:
    'A lawyer may agree to charge a specific flat fee. A flat fee is typically offered only when your case is relatively simple or routine (such as preparing a will or an uncontested divorce).',
  [CHECKBOX_NAMES.STATE_SEARCH]: SWITCH_HINTS,
}

const sliderInputsData = {
  min: {
    name: 'minHourlyRate',
    value: 0,
    label: MIN_PRICE,
  },
  max: {
    name: 'maxHourlyRate',
    value: 1000,
    label: MAX_PRICE,
  },
}

const Arrow = () => {
  const classes = useStyles()
  return <SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.arrowIcon} />
}

const ProfileSearchFilters = props => {
  const { filters, setFilter, resetFilters, getProfiles, averagePrice } = props
  const classes = useStyles()
  const query = useQuery()
  const languageQuery = query.get('language')
  const searchFilters = useSelector(store => store.search.filters)
  const isSelectedSubSpecialityFederal = useSelector(store => store.search.practiceAreaSearchObject.is_federal)
  const initialFilters = useSelector(store => store.search.defaultSearchFilters)
  const sorter = useSelector(store => store.search.sorter)
  const languages = useSelector(store => store.common.allLanguagesList)
  const dispatch = useDispatch()
  const [timeValues, setTimeValues] = useState({
    minTime: filters.startFreeTimeValue,
    maxTime: filters.endFreeTimeValue,
  })

  const AVAILABLE_TIME_MARKS = [
    {
      value: 0,
      label: (
        <p className={classes.markLabel1}>
          <b>now</b>
        </p>
      ),
    },
    {
      value: 13,
      label: (
        <p style={{ margin: 0, marginRight: 30, textAlign: 'end' }}>
          <b style={{ color: colors.BASIC.COLOR[100], fontSize: 13 }}>30</b> days
        </p>
      ),
    },
  ]
  const stateFilter = useSelector(state => state.common.showFilter)
  const [openLang, setOpenLanguage] = useState(false)
  const [langId, setLangId] = useState('15')
  const [lang, setLang] = useState('English')

  useEffect(() => {
    dispatch(getAllLanguages())
    if (languageQuery) setFilter('languages', languageQuery)
  }, [dispatch])

  const buttonResults = () => {
    getProfiles()
    dispatch(showFilter(false))
  }

  useEffect(() => {
    if (filters.startFreeTimeValue !== timeValues.minTime || filters.endFreeTimeValue !== timeValues.maxTime)
      setTimeValues({
        minTime: filters.startFreeTimeValue,
        maxTime: filters.endFreeTimeValue,
      })
  }, [filters.startFreeTimeValue, filters.endFreeTimeValue])

  const handleRating = (e, value) => {
    setFilter('averageRating', value)
    if (!stateFilter) getProfiles()
  }

  const onResetFilters = () => {
    setLang('English')
    resetFilters()
    getProfiles()
  }

  const onFilterChange = (e, value) => {
    setFilter(e.target.name, value)
    if (!stateFilter) getProfiles()
  }

  const onSelectFilterChange = (name, value) => {
    setFilter(name, value)
    if (!stateFilter) getProfiles()
  }

  const onSliderChange = (minVal, maxVal) => {
    setFilter('minHourlyRate', minVal)
    setFilter('maxHourlyRate', maxVal)
    if (!stateFilter) getProfiles()
  }

  const getAvailabilityFilterLabel = (minVal, maxVal) => {
    const minAvailableTime = CONSTANTS.AVAILABLE_TIME_FILTER[minVal]
    const maxAvailableTime = CONSTANTS.AVAILABLE_TIME_FILTER[maxVal]
    return minVal === 0 && maxVal === 13
      ? 'anytime'
      : minVal === maxVal
      ? `${minAvailableTime.valueLabel}`
      : `${minAvailableTime.valueLabel} - ${maxAvailableTime.valueLabel}`
  }

  const onTimeSliderChange = (e, [minVal, maxVal]) => {
    if (minVal === maxVal) return
    setTimeValues({
      minTime: minVal,
      maxTime: maxVal,
    })
  }
  const onTimeSliderChangeCommitted = () => {
    setFilter('startFreeTimeValue', timeValues.minTime)
    setFilter('endFreeTimeValue', timeValues.maxTime)
    if (!stateFilter) getProfiles()
  }

  const showResetFilters = !isEqual(searchFilters, initialFilters) || sorter

  const handleClickLang = e => {
    if (langId !== e.target.closest('div').id) {
      setLang(e.target.name)
      setLangId(e.target.closest('div').id)
      let prevChoice = document.getElementById(`${langId}`).firstChild
      e.target.previousSibling.classList.add(classes.checkIconActive)
      prevChoice.classList.remove(classes.checkIconActive)
      onSelectFilterChange('languages', e.target.name)
    }
  }
  const handleBack = () => {
    openLang ? setOpenLanguage(false) : setOpenLanguage(true)
  }

  const CheckboxFilter = ({ name }) => (
    <div className={classes.checkboxContainer}>
      <Checkbox
        id={name}
        labelClasses={{ root: classes.label }}
        classes={{ root: classes.checkbox }}
        onChange={onFilterChange}
        name={name}
        label={CHECKBOX_LABELS[name]}
        checked={filters[name]}
        color="primary"
      />
      {CHECKBOX_HINTS[name] && (
        <Tooltip tooltipClass={classes.toolTip} title={CHECKBOX_HINTS[name]}>
          <Icon iconClass="las la-question-circle" style={classes.hint} />
        </Tooltip>
      )}
    </div>
  )

  const CheckboxSwitch = ({ name, disabled }) => (
    <div className={classes.checkboxContainer}>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            id={name}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            onChange={onFilterChange}
            name={name}
            checked={filters[name]}
            margin="none"
          />
        }
        margin="none"
        className={classnames(classes.label, classes.headersLable)}
        labelTextClasses={{ root: classes.weight }}
        label={
          <div className={classes.lableFee}>
            {CHECKBOX_LABELS[name]}
            {CHECKBOX_HINTS[name] && (
              <Tooltip tooltipClass={classes.toolTip} title={CHECKBOX_HINTS[name]}>
                <Icon iconClass="las la-question-circle" style={classes.hint} />
              </Tooltip>
            )}
          </div>
        }
        labelPlacement="start"
      />
    </div>
  )

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.content}>
        <div className={classnames(classes.laptop, classes.filterHeader)}>
          {openLang ? (
            <button className={classes.btnLangOpen} onClick={handleBack}>
              <SvgIcon
                component={IconBack}
                viewBox="0 0 24 24"
                className={classnames(classes.iconBack, classes.iconBack2)}
              />
            </button>
          ) : (
            <div className={classes.closeBtn} onClick={() => dispatch(showFilter(false))}>
              &#215;
            </div>
          )}
          <div>{!openLang ? 'Filter' : 'Language'}</div>
          <div onClick={onResetFilters}>Clear</div>
        </div>

        {!openLang ? (
          <div>
            {showResetFilters && (
              <FilterSection
                subtitleItem={
                  <Button
                    onClick={onResetFilters}
                    disableRipple
                    classes={{ label: classes.resetBtnLabel, root: classes.resetBtn }}
                    variant="text"
                  >
                    {RESET_FILTERS}
                  </Button>
                }
              />
            )}

            <FilterSection
              filterName={AVAILABILITY_TIME_FILTER}
              className={classes.rate}
              subtitleItem={
                <Typography
                  className={
                    getAvailabilityFilterLabel(filters.startFreeTimeValue, filters.endFreeTimeValue) !== 'anytime'
                      ? classes.availabilityLabel
                      : classes.anytimeLabel
                  }
                  variant="subtitle2"
                >
                  {getAvailabilityFilterLabel(filters.startFreeTimeValue, filters.endFreeTimeValue)}
                </Typography>
              }
            >
              <div>
                <Slider
                  value={[timeValues.minTime, timeValues.maxTime]}
                  marks={AVAILABLE_TIME_MARKS}
                  onChange={onTimeSliderChange}
                  onChangeCommitted={onTimeSliderChangeCommitted}
                  valueLabelFormat={value => CONSTANTS.AVAILABLE_TIME_FILTER[value].valueLabel}
                  ValueLabelComponent={ValueLabelComponent}
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={13}
                />
              </div>
            </FilterSection>

            <FilterSection
              filterName={HOURLY_RATE_FILTER}
              className={classes.rate}
              subtitleItem={
                <Typography variant="body2">{averagePrice && `average is $${parseInt(averagePrice)}`}</Typography>
              }
            >
              <InputSlider
                inputsData={sliderInputsData}
                onInputChange={onFilterChange}
                onSliderChange={onSliderChange}
                values={{
                  minHourlyRate: filters.minHourlyRate,
                  maxHourlyRate: filters.maxHourlyRate,
                }}
              />
            </FilterSection>

            <FilterSection className={classes.dekstop}>
              <CheckboxFilter name={CHECKBOX_NAMES.FREE_CONSULTATION} />
            </FilterSection>

            <FilterSection className={classes.laptop}>
              <CheckboxSwitch name={CHECKBOX_NAMES.FREE_CONSULTATION} />
            </FilterSection>

            <FilterSection className={classnames(classes.dekstop, classes.flexColumn)} filterName={FEE_TYPES_FILTER}>
              <CheckboxFilter name={CHECKBOX_NAMES.CONTINGENCY} />
              <CheckboxFilter name={CHECKBOX_NAMES.FIXED_PRICE} />
            </FilterSection>

            <FilterSection className={classnames(classes.laptop, classes.flexColumn)} filterName={FEE_TYPES_FILTER}>
              <CheckboxSwitch name={CHECKBOX_NAMES.CONTINGENCY} />
              <CheckboxSwitch name={CHECKBOX_NAMES.FIXED_PRICE} />
            </FilterSection>

            <FilterSection className={classnames(classes.dekstop, classes.flexColumn)}>
              <div className={classes.switchWrapper}>
                <Switch
                  name={CHECKBOX_NAMES.STATE_SEARCH}
                  formControlClasses={{ label: classes.sliderLabel, root: classes.sliderLabelRoot }}
                  checked={filters.state_search}
                  color="default"
                  onChange={onFilterChange}
                  disabled={!isSelectedSubSpecialityFederal}
                  classes={{ switchBase: classes.slider }}
                  label={CHECKBOX_LABELS.state_search}
                />
                <Tooltip tooltipClass={classes.toolTip} title={SWITCH_HINTS}>
                  <Icon iconClass="las la-question-circle" style={classes.hint} />
                </Tooltip>
              </div>
            </FilterSection>

            <FilterSection className={classnames(classes.laptop, classes.flexColumn)}>
              <CheckboxSwitch name={CHECKBOX_NAMES.STATE_SEARCH} disabled={!isSelectedSubSpecialityFederal} />
            </FilterSection>

            <FilterSection className={classes.dekstop}>
              <Rating
                name="ratingFilters"
                text={!filters.rating && RATING_FILTER}
                onChange={handleRating}
                value={filters.averageRating}
                ratingContainerClasses={classes.ratingContainerClasses}
                ratingContainerText={classes.ratingContainerText}
              />
            </FilterSection>
            <FilterSection className={classes.laptop} filterName={UP_RATING_TEXT}>
              <div className={classes.increaseSize}>
                <Rating
                  ratingClasses={{
                    icon: 'responsiveRatingButton',
                    root: 'raitingRoot',
                  }}
                  name="ratingFilters"
                  onChange={handleRating}
                  value={filters.averageRating}
                  ratingContainerClasses={classes.ratingContainerClasses}
                  ratingContainerText={classes.ratingContainerText}
                />
              </div>
            </FilterSection>
            <FilterSection className={classnames(classes.mobile, classes.laptop)} filterName={SHORT_LANGUAGE_FILTER}>
              <div className={classes.filterLang}>
                <input value={lang} className={classes.inp} readOnly />
                <button className={classes.btnLangOpen} onClick={handleBack}>
                  <SvgIcon component={IconBack} viewBox="0 0 24 24" className={classes.iconBack} />
                </button>
              </div>
            </FilterSection>

            <FilterSection className={classes.dekstop} filterName={LANGUAGE_FILTER}>
              <Autocomplete
                value={filters.languages}
                valueName="name"
                options={languages}
                optionLabel="name"
                fullWidth
                variant="outlined"
                popupIcon={<Arrow />}
                onChange={(e, val) => onSelectFilterChange('languages', val ? val.name : null)}
              />
            </FilterSection>
          </div>
        ) : (
          <div className={classes.formLang}>
            {languages.map((language, index) => (
              <div className={classes.option} key={index} id={index}>
                <SvgIcon
                  component={IconCheckLang}
                  viewBox="0 0 24 24"
                  className={classnames(classes.checkIcon, language.name, {
                    [classes.checkIconActive]: language.name === lang,
                  })}
                ></SvgIcon>
                <button className={classes.btnLang} onClick={handleClickLang} name={language.name}>
                  {language.name}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={classnames({ [classes.btnShowResultsSticky]: openLang })}>
        <Button
          variant="primary"
          className={classnames(classes.btnShowResults, classes.laptop)}
          onClick={buttonResults}
        >
          <div>Show Results</div>
        </Button>
      </div>
    </div>
  )
}

export default ProfileSearchFilters
