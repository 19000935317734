import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormikAutocomplete, FormikInput } from '../index'
import { CONSTANTS } from '../../constants'

const useStyles = makeStyles({
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  twoColumnInput: {
    width: 360,
    '&:first-child': {
      marginRight: 24,
    },
  },
  phoneInput: {
    height: '40px !important',
  },
})

export const UserAddressFormFragment = ({ states }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          name="street"
          label="Address"
          fullWidth
          type="text"
          containerClasses={classes.twoColumnInput}
        />
        <FormikInput
          variant="outlined"
          name="suite"
          label="Apartment, Suite, PO Box"
          fullWidth
          type="text"
          containerClasses={classes.twoColumnInput}
        />
      </div>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          name="city"
          label="City"
          type="text"
          fullWidth
          containerClasses={classes.twoColumnInput}
        />
        <FormikAutocomplete
          variant="outlined"
          size="small"
          name="state"
          label="State"
          type="text"
          fullWidth
          containerClasses={classes.twoColumnInput}
          options={states}
          filterOptions={(options, { inputValue }) =>
            states.filter(option => option.label.toLowerCase().startsWith(inputValue.toLowerCase()))
          }
        />
      </div>
      <div className={classes.twoColumn}>
        <FormikInput
          variant="outlined"
          name="zip"
          label="Zip code"
          fullWidth
          type="text"
          containerClasses={classes.twoColumnInput}
        />
        <FormikInput
          variant="outlined"
          name="phone"
          label="Phone number"
          type={CONSTANTS.INPUT_TYPE.PHONE}
          fullWidth
          containerClasses={classes.twoColumnInput}
          inputClass={classes.phoneInput}
          country="us"
        />
      </div>
    </>
  )
}
