import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import {
  getCurrentProfileStatus,
  getDeletionRequestInfo,
  getUserDegrees,
  getUserLanguages,
  getVideo,
  getDegrees,
  getSocialAccounts,
  getRatingStatus,
} from 'store/actions/accountProfile'
import { getLicensing, getPracticeAreas, getUserContactInfo } from 'store/actions/profileCreation'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ProfileContent from './profileContent/profileContent'
import { getProReviews } from 'store/actions/proReview/proReview'

const useStyles = makeStyles({
  profile: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
  },
})

const scrollToRef = ref => {
  return ref.current.scrollTo && ref.current.scrollTo(0, 0)
}

const Profile = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    dispatch(getDegrees())
    dispatch(getUserDegrees())
    dispatch(getPracticeAreas())
    dispatch(getLicensing())
    dispatch(getUserContactInfo())
    dispatch(getUserLanguages())
    dispatch(getCurrentProfileStatus())
    dispatch(getDeletionRequestInfo())
    dispatch(getVideo())
    dispatch(getSocialAccounts())
    dispatch(getRatingStatus())
    dispatch(getProReviews())
  }, [dispatch])

  return (
    <div ref={myRef} className={classes.profile}>
      <ProfileContent executeScroll={executeScroll} />
    </div>
  )
}

export default withRouter(Profile)
