import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClientPaymentInfoContainer, ClientPayWithContainer } from 'containers'
import { useDispatch, useSelector } from 'react-redux'
import { getProInvoice, getProInvoiceSummary, setClientPaymentInvoice } from 'store/actions/invoices'
import { getClientPaymentSecret, getUserCards } from 'store/actions/payments'
import { ColorCircularProgress, ErrorModal } from 'components'
import { openSnackbar } from 'store/actions/common'

const useStyles = makeStyles({
  payNowContainer: {
    height: '100%',
    display: 'flex',
  },
})

const ClientInvoicePaymentPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const invoice = useSelector(state => state.invoices.invoice)
  const invoiceSummary = useSelector(state => state.invoices.invoiceSummary)
  const invoiceId = match.params.invoiceId
  const invoiceIsLoading = useSelector(state => state.invoices.invoiceIsLoading)
  const userCards = useSelector(state => state.payments.userCards)
  const [paymentCard, setPaymentCard] = useState({})
  const [showErrorModal, setShowErrorModal] = useState({ open: false, description: '', instruction: '' })
  const [inputAmount, setInputAmount] = useState(invoice.amountDue)
  const [paymentType, setPaymentType] = useState('full')

  useEffect(() => {
    setInputAmount(invoice.amountDue)
  }, [invoice])

  useEffect(() => {
    if (userCards.length) {
      const defaultCard = invoice.paymentMethodId
        ? userCards.find(card => card.paymentMethodId === invoice.paymentMethodId)
        : userCards.find(card => card.isDefault)
      defaultCard && setPaymentCard(defaultCard)
    }
  }, [invoice, userCards])

  useEffect(() => {
    dispatch(getProInvoice(invoiceId, false, true))
    dispatch(getClientPaymentSecret())
    dispatch(getUserCards())
  }, [])

  const onSubmit = paymentId => {
    const amount = paymentType === 'full' ? +invoice.amountDue : +inputAmount
    dispatch(setClientPaymentInvoice(invoice.uuid, paymentId, amount)).catch(e => {
      const errorData = e.response && e.response.data
      if (errorData && errorData.is_stripe_error) {
        const description = errorData.description
        const instruction = errorData.instructions
        setShowErrorModal({ open: true, description: description, instruction: instruction })
      } else dispatch(openSnackbar('error', 'Error occurred. Please check the status of the invoice and try again'))
    })
  }

  return (
    <div className={classes.payNowContainer}>
      {invoiceIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <ClientPaymentInfoContainer
            invoice={invoice}
            invoiceSummary={invoiceSummary}
            amount={inputAmount}
            setAmount={setInputAmount}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
          />
          <ClientPayWithContainer
            paymentCard={paymentCard}
            userCards={userCards}
            setPaymentCard={setPaymentCard}
            onSubmit={onSubmit}
            disablePayment={paymentType === 'partly' && !inputAmount}
          />
        </>
      )}
      <ErrorModal
        variant="primary"
        title="Your payment failed"
        message={`${showErrorModal.description} ${showErrorModal.instruction}`}
        cancelationButtonText="select another card"
        isOpen={showErrorModal.open}
        onClose={() => setShowErrorModal({ description: '', instruction: '', open: false })}
      />
    </div>
  )
}

export default ClientInvoicePaymentPage
