import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, routes } from 'constants/index'
import { Breadcrumbs, Link, RescheduleComponent, Typography, SvgIcon } from 'components'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from 'static/search/ic_arrow-left.svg'

const useStyles = makeStyles({
  profileContentHeader: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  navBar: {
    marginBottom: 16,
  },
  link: {
    display: 'flex',
    color: colors.BASIC.COLOR[80],
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    '&:hover': {
      color: colors.BASIC.COLOR[100],
    },
  },
  linkIcon: {
    width: 'auto',
    height: 'auto',
    marginRight: 8,
  },
})

const ClientEventRescheduleContainer = props => {
  const classes = useStyles()
  const { match } = props

  return (
    <>
      <Breadcrumbs className={classes.navBar}>
        <Link className={classes.link} component={RouterLink} to={routes.getClientAccountDashboardPath()}>
          <SvgIcon classes={{ root: classes.linkIcon }} component={ArrowLeftIcon} viewBox="0 0 20 20" />
          back to events
        </Link>
      </Breadcrumbs>
      <Typography variant="h2" className={classes.profileContentHeader}>
        Reschedule a meeting
      </Typography>
      <RescheduleComponent eventId={match.params.uuid} />
    </>
  )
}

export default ClientEventRescheduleContainer
