import React, { useEffect, useRef, useState } from 'react'
import { jarallax } from 'jarallax'
import ReactCardFlip from 'react-card-flip'
import { BlockWithVideoBackground, Button, VideoPopup, Link } from 'components'
import { useDispatch } from 'react-redux'
import { CONSTANTS } from 'constants/constants'
import { toggleSignUp } from 'store/actions/common'
import Bg from 'static/xiraForLawyers/team.png'
import './XiraForLayersPageCss.scss'
import XiraVideo1 from 'static/xiraForLawyers/xiraForLawyers1.mp4'
import XiraVideo2 from 'static/xiraForLawyers/xiraForLawyers2.mp4'
import { useAnalytics } from 'utils/hooks'
import XiraForLawyersStepper from './XiraForLawyersStepper'

const { parallaxProperties } = CONSTANTS

const PricingRow = ({ title, text, price }) => {
  return (
    <div className="pricing-row">
      <div className="pricing-row_header">
        <div>
          <h4 className="pricing-row_title">{title}</h4>
          <p className="pricing-row_description">{text}</p>
        </div>
        <p className="pricing-row_price">{price}</p>
      </div>
    </div>
  )
}

const proicing = [
  {
    title: 'client acquisition',
    price: '$20',
    text:
      'when you meet a new client on xira, you incur a flat fee that includes the initial consultation and setup. you never pay marketing charges and there is no pass through of expenses related to xira marketing. our research shows that 81% of prospects that you meet will convert to a paid client',
  },
  {
    title: 'secure online virtual meeting',
    price: '$3 / 30m',
    text:
      "ongoing meetings with an existing client are affordable, simple and convenient. you can simply conduct all your recurring meetings on xira where it's most convenient for both the lawyer and the client. when meeting is needed, send an automated meeting request to your client and let them pick a time directly on your calendar",
  },
  {
    title: 'invoicing & collection',
    price: '1%',
    text: (
      <span>
        we make billing super easy. you enter your time or expense as they happen and we will bill the client on the
        time interim of your choice. xira recommends micro-billing and you can see a video on this{' '}
        <b>
          <i>
            <a
              href="https://youtu.be/faiMFw1Or_Q"
              target="_blank"
              rel="noopener noreferrer"
              className="invoicing-tutorial-link"
            >
              here
            </a>
          </i>
        </b>
        . xira charges a flat percentage on the collected amount in addition to any credit card or ACH fees. those fees
        are just a simple pass through
      </span>
    ),
  },
  {
    title: 'document vault',
    price: '$10 / mo',
    text: (
      <span>
        your secure document vault is designed for easy filing and accessability to your client documents. you get{' '}
        <b>5Gb</b> of storage space <b>free</b> of charge. an additional 100Gb of space can be purchased when needed
      </span>
    ),
  },
  {
    title: 'messaging',
    price: 'free',
    text:
      'keep communication with your client simple, efficient and secure through the use of messaging on xira. all your messaging is free of charge',
  },
]

const XiraForLawyersPage = () => {
  const dispatch = useDispatch()
  const [firstCardFlipped, setFirstCardFlipped] = useState(false)
  const [secondCardFlipped, setSecondCardFlipped] = useState(false)
  const [thirdCardFlipped, setThirdCardFlipped] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(false)
  const parallaxSectionRef1 = useRef(null)

  useAnalytics()

  useEffect(() => {
    const currentParallax1 = parallaxSectionRef1.current
    jarallax(currentParallax1, parallaxProperties)

    return () => {
      jarallax(currentParallax1, 'destroy')
    }
  }, [])

  const onShowSignUpForm = () => {
    dispatch(toggleSignUp(true))
  }

  const onOpenVideoPopup = id => {
    setSelectedVideo(id)
  }

  return (
    <div className="for-lawyers-page">
      <section className="heading-layer">
        <h1 className="heading-layer_title">xira for lawyers</h1>
        <p className="heading-layer_subtitle">practicing law made easy</p>
        <Button variant="primary" className="button" onClick={onShowSignUpForm}>
          join us
        </Button>
      </section>
      <section className="sub-heading-layer">
        <h1 className="sub-heading-layer_title">
          no cost to join
          <br />
          no subscription
          <br />
          no contract
        </h1>
        <p className="sub-heading-layer_subtitle">no risk</p>
      </section>
      <BlockWithVideoBackground
        title="how xira helps"
        subtitle="click to see a 3 min video"
        videoId="ekA66jCGnYg"
        videoSource={XiraVideo1}
        onPressPlay={onOpenVideoPopup}
      />
      <section className="jarallax" ref={parallaxSectionRef1}>
        <img className="jarallax-img jarallax-img_society" src={Bg} alt="society" />
      </section>
      <section className="benefit-cards">
        <div className="benefit-cards_wrapper">
          <ReactCardFlip
            isFlipped={firstCardFlipped}
            flipDirection="horizontal"
            containerStyle={{ height: '300px', width: '330px' }}
          >
            <div
              onMouseOver={() => setFirstCardFlipped(true)}
              onMouseOut={() => setFirstCardFlipped(false)}
              className="benefit-card benefit-card__front"
            >
              acquire new clients
            </div>
            <div
              onMouseOut={() => setFirstCardFlipped(false)}
              onMouseOver={() => setFirstCardFlipped(true)}
              className="benefit-card benefit-card__back"
            >
              <p>- no geographic boundaries</p>
              <p>- a new way to meet clients</p>
              <p>- let client book your free time</p>
              <p>- your profile is visible where motivated clients are looking</p>
            </div>
          </ReactCardFlip>
        </div>
        <div className="benefit-cards_wrapper">
          <ReactCardFlip
            isFlipped={secondCardFlipped}
            flipDirection="horizontal"
            containerStyle={{ height: '300px', width: '330px' }}
          >
            <div
              onMouseOver={() => setSecondCardFlipped(true)}
              onMouseOut={() => setSecondCardFlipped(false)}
              className="benefit-card benefit-card__front"
            >
              increase billable hours
            </div>
            <div
              onMouseOut={() => setSecondCardFlipped(false)}
              onMouseOver={() => setSecondCardFlipped(true)}
              className="benefit-card benefit-card__back"
            >
              <p>- spend less time on administrative, marketing, and managerial tasks</p>
              <p> - spend more time with new clients</p>
              <p> - more billable hours</p>
            </div>
          </ReactCardFlip>
        </div>
        <div className="benefit-cards_wrapper">
          <ReactCardFlip
            isFlipped={thirdCardFlipped}
            flipDirection="horizontal"
            containerStyle={{ height: '300px', width: '330px' }}
          >
            <div
              onMouseOut={() => setThirdCardFlipped(false)}
              onMouseOver={() => setThirdCardFlipped(true)}
              className="benefit-card benefit-card__front"
            >
              reduce overhead
            </div>
            <div
              onMouseOut={() => setThirdCardFlipped(false)}
              onMouseOver={() => setThirdCardFlipped(true)}
              className="benefit-card benefit-card__back"
            >
              <p> - no need for brick and mortar office</p>
              <p> - no need for assistants to manage your time or handle your billing</p>
              <p> - less infrastructure / more cashflow</p>
            </div>
          </ReactCardFlip>
        </div>
      </section>
      <section className="steps-container">
        <h1 className="steps-container_title">
          how <span className="bold-text">xira works</span>{' '}
        </h1>
        <XiraForLawyersStepper />
      </section>
      <BlockWithVideoBackground
        title="how it all works for you"
        subtitle="click to see a 3 min video"
        videoId="cOagQPqm1Tk"
        videoSource={XiraVideo2}
        onPressPlay={onOpenVideoPopup}
      />
      <section className="instruction-section">
        <h4 className="instruction-section_title">
          how to <b>get listed</b>
        </h4>
        <div className="instruction-subsection">
          <div className="instruction-subsection_container">
            <p className="instruction-subsection_number">01.</p>
            <p className="instruction-subsection_subtitle">
              <b>join</b> in less than 10 min
            </p>
            <p className="instruction-subsection_list_item">- provide contact</p>
            <p className="instruction-subsection_list_item">- submit license info</p>
            <p className="instruction-subsection_list_item">- pick practice areas</p>
            <p className="instruction-subsection_list_item">- add your photo</p>
            <p className="instruction-subsection_list_item">- link your calendar</p>
          </div>
          <div className="instruction-subsection_container">
            <p className="instruction-subsection_number">02.</p>
            <p className="instruction-subsection_subtitle">
              <b>promote</b> yourself
            </p>
            <p className="instruction-subsection_list_item">- add your bio</p>
            <p className="instruction-subsection_list_item">- share bio video</p>
            <p className="instruction-subsection_list_item">- share social links</p>
            <p className="instruction-subsection_list_item">- submit existing ratings</p>
          </div>
        </div>
      </section>
      <section className="pricing-section">
        <h4 className="pricing-section_title">
          pricing made <b>simple</b>
        </h4>
        <h4 className="pricing-section_subtitle">we succeed when you succeed</h4>
        <div className="pricing-table">
          {proicing.map(o => (
            <PricingRow text={o.text} title={o.title} price={o.price} key={o.title} />
          ))}
        </div>
      </section>
      <section className="footer">
        <h4 className="footer_title">
          let's get you <b>listed</b>
        </h4>
        <Button variant="primary" onClick={onShowSignUpForm} className="button footer_button">
          join us
        </Button>
      </section>
      {selectedVideo && (
        <VideoPopup
          src={selectedVideo}
          close={() => {
            setSelectedVideo(null)
          }}
        />
      )}
    </div>
  )
}

export default XiraForLawyersPage
