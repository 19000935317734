import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Typography, ClientContactInfoFormBody, Button } from 'components'
import { updateProfileContactInfo } from 'store/actions/profileCreation'
import { useQuery } from '../../utils/hooks'
import { CONSTANTS } from '../../constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'constants/index'
import LegalTopicSelector from './LegalTopicSelector'

const useStyles = makeStyles({
  stepButtons: {
    display: 'flex',
    marginTop: '40px',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 16,
    marginBottom: 17,
  },
  button: {
    marginLeft: 30,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  '@media(max-width:939px)': {
    stepButtons: {
      width: '100%',
      maxWidth: 372,
    },
  },
})

const MeetingSchedulerContactInfoForm = props => {
  const dispatch = useDispatch()
  const {
    contactInfo,
    countries,
    states,
    onReset,
    updateContactInfo = () => {},
    cancelButton,
    onContinue,
    onSubmit,
    showTopicSelect,
    onTopicChange,
    onBack,
  } = props
  const classes = useStyles()
  const query = useQuery()
  const practiceArea = query.get('area')
  const proAccount = useSelector(state => state.search.proAccount)
  const options = useMemo(
    () =>
      proAccount.practiceAreas
        .map(area =>
          area.selected_sub_specialities.map(e => ({
            value: e.uuid,
            name: e.name,
            label: e.name,
            practiceArea: area.name,
            practiceAreaUuid: area.selected_practice_area.uuid,
          }))
        )
        .flat(),
    [proAccount.practiceAreas]
  )
  const initialValues = useMemo(
    () => ({
      street: contactInfo.street,
      suite: contactInfo.suite,
      city: contactInfo.city,
      state: contactInfo.state,
      zip: contactInfo.zip,
      phone: contactInfo.phone,
      optionalPhone: contactInfo.optionalPhone,
      country: contactInfo.country,
      topic: options.find(el => el.name === practiceArea)?.value || '',
    }),
    [contactInfo, options]
  )

  const onSubmitData = data => {
    dispatch(updateProfileContactInfo(data))
    if (onSubmit) onSubmit(data)
    else onContinue()
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          street: Yup.string()
            .trim()
            .required('Please fill in the field'),
          suite: Yup.string(),
          city: Yup.string()
            .trim()
            .required('Please fill in the field'),
          state: Yup.string()
            .trim()
            .required('Please fill in the field'),
          zip: Yup.string()
            .typeError('Please enter valid ZIP code (5 digits)')
            .matches(/^[0-9]+$/g, { message: 'Only digits available', excludeEmptyString: true })
            .length(CONSTANTS.ZIP_CODE_LENGTH, 'Please enter valid ZIP code (5 digits)')
            .required('Please fill in the field'),
          phone: Yup.string()
            .test('len', 'Please enter 10 digits', val =>
              val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
            )
            .required('Please fill in the field'),
          optionalPhone: Yup.string().test('len', 'Please enter 10 digits', val =>
            val === '+1' || !val ? true : val ? val.toString().replace(/([+ -])/g, '').length === 11 : false
          ),
          country: Yup.string()
            .trim()
            .required('Please fill in the field'),
          topic: showTopicSelect
            ? Yup.string()
                .trim()
                .required('Please fill in the field')
            : false,
        })}
        onSubmit={onSubmitData}
        onReset={onReset}
        enableReinitialize
      >
        {props => (
          <Form>
            {showTopicSelect && (
              <>
                <Typography classes={{ h4: classes.title }} variant="h4">
                  Select area of law or legal topic
                </Typography>
                <LegalTopicSelector options={options} onChange={onTopicChange} />
              </>
            )}

            <Typography classes={{ h4: classes.title }} variant="h4">
              Contact Information
            </Typography>
            <ClientContactInfoFormBody
              disableContactInfoStyle
              countries={countries}
              states={states}
              updateContactInfo={updateContactInfo}
            />
            <div className={classes.stepButtons}>
              <>
                {cancelButton}
                <div>
                  {onBack && (
                    <Button variant="text" onClick={onBack}>
                      back
                    </Button>
                  )}
                  <Button type="submit" variant="primary" className={classes.button}>
                    {onSubmit ? 'complete' : 'continue'}
                  </Button>
                </div>
              </>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MeetingSchedulerContactInfoForm
