import React from 'react'
import { Modal, Typography, Button, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    margin: '0 0 24px 0',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    maxWidth: '568px',
  },
  boxBtn: {
    textAlign: 'right',
    marginTop: 24,
  },
  icon: {
    color: 'red',
    marginRight: 8,
    fontSize: 36,
  },
  errors: {
    margin: 0,
    paddingLeft: 15,
    marginBottom: 32,
  },
  closeBtn: {
    marginRight: 24,
  },
  deleteBtn: {
    minWidth: 127,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '648px !important',
    },
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
    boxBtn: {
      marginTop: 16,
    },
  },
})

const ConfirmationModal = ({
  message,
  isOpen,
  onClose,
  title,
  onConfirm,
  confirmationButtonText,
  cancelationButtonText,
  disableButton = false,
  showCancel = true,
  icon,
}) => {
  const classes = useStyles()
  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          {icon || <Icon iconClass="las la-exclamation-triangle" style={classes.icon} />}
          {title}
        </Typography>
        <Typography variant="subtitle1">{message}</Typography>
        <div className={classes.boxBtn}>
          {showCancel && (
            <Button variant="text" onClick={onClose} disabled={disableButton} className={classes.closeBtn}>
              {cancelationButtonText || 'cancel'}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={onConfirm}
            isButtonBlocked={disableButton}
            disabled={disableButton}
            circularSize={22}
            className={classes.deleteBtn}
          >
            {confirmationButtonText || 'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
