import React, { useEffect, useState } from 'react'
import { Button, ColorCircularProgress, Hint, Icon, InputLabel, Modal, TextField, Typography, Select } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getMeetingDay, getMeetingDuration, getMeetingRange } from 'utils/getters/events'
import { navigateToAccountDashboard } from 'store/actions/navigation'
import { getUserEventDetails, cancelUserMeeting } from 'store/actions/events'

const useStyles = makeStyles({
  root: {
    width: 568,
    minHeight: 350,
    position: 'relative',
    paddingBottom: 24,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  meetingTimeSection: {
    width: '100%',
    marginBottom: 24,
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    marginTop: 24,
    marginBottom: 16,
  },
  reasonSection: {
    width: '100%',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    padding: '24px 40px',
    marginBottom: 24,
  },
  tooltipTextContainer: {
    width: '100%',
    padding: '0 40px',
    marginBottom: 24,
  },
  buttonGroup: {
    width: '100%',
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    height: 40,
    padding: '5px 24px',
    marginLeft: 10,
    minHeight: 40,
  },
  hint: {
    color: colors.ERROR,
    marginTop: 4,
  },
  rescheduleDesc: {
    marginTop: 4,
    fontSize: 13,
    fontWeight: 400,
    color: colors.BASIC.COLOR[70],
  },
  cancelButton: {
    height: 'auto',
    fontSize: 13,
    padding: '8px 24px',
  },
  reasonSelect: {
    width: '100%',
    '& .MuiInput-underline:after': {
      borderBottom: `2px solid ${colors.PRIMARY.COLOR[50]}`,
    },
    '& .MuiInputBase-input': {
      padding: '12px 15px 9px',
    },
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
})

const ProRescheduleEventModal = props => {
  const classes = useStyles()
  const { eventId } = props
  const dispatch = useDispatch()
  const [rescheduleReason, setRescheduleReason] = useState('')
  const [error, setError] = useState(false)
  const eventInfoIsLoading = useSelector(store => store.events.meetingInfoIsLoading)
  const eventInfo = useSelector(store => store.events.meetingInfo)
  const [isOpenModal, setIsOpenModal] = useState(true)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  useEffect(() => {
    setError(false)
    if (eventInfo.isInitial) {
      setRescheduleReason(CONSTANTS.REASON_FOR_RESCHEDULE_BY_PRO[0].value)
    }
  }, [eventInfo])

  useEffect(() => {
    dispatch(getUserEventDetails(eventId))
  }, [eventId])

  const onConfirm = async () => {
    if (!rescheduleReason) {
      setError(true)
      return
    }
    setRescheduleReason(rescheduleReason)
    setError(false)

    try {
      await dispatch(cancelUserMeeting(eventInfo.uuid, rescheduleReason, true))
      onModalClose()
    } catch (e) {
      onModalClose()
      console.error(e)
    }
  }

  const onModalClose = () => {
    setIsOpenModal(false)
    dispatch(navigateToAccountDashboard())
  }

  return (
    <>
      <Modal open={isOpenModal} maxWidth={false} handleClose={onModalClose}>
        <div className={classes.root}>
          {eventInfoIsLoading ? (
            <ColorCircularProgress />
          ) : (
            eventInfo.clientInfo &&
            eventInfo.startTime &&
            eventInfo.endTime && (
              <div className={classes.content}>
                <Typography variant="h4" className={classes.title}>
                  {`reschedule meeting with ${eventInfo.clientInfo.firstName} ${eventInfo.clientInfo.lastName}`}
                </Typography>
                <div className={classes.meetingTimeSection}>
                  <Typography variant="body2">
                    {getMeetingDay(eventInfo.startTime, timezone)} &bull;{' '}
                    {getMeetingRange(eventInfo.startTime, eventInfo.endTime, timezone)} &bull;{' '}
                    {getMeetingDuration(eventInfo.startTime, eventInfo.endTime)}
                  </Typography>
                </div>
                <div className={classes.reasonSection}>
                  <InputLabel variant="body1">reason for rescheduling*</InputLabel>
                  {eventInfo.isInitial ? (
                    <Select
                      formControlClassName={classes.reasonSelect}
                      onChange={e => setRescheduleReason(e.target.value)}
                      value={rescheduleReason}
                      options={CONSTANTS.REASON_FOR_RESCHEDULE_BY_PRO}
                    />
                  ) : (
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={rescheduleReason}
                      onChange={e => setRescheduleReason(e.target.value)}
                      inputProps={{
                        maxLength: 150,
                      }}
                    />
                  )}
                  <Typography variant="body1" className={classes.rescheduleDesc}>
                    once you confirm, existing meeting will be canceled and your client will receive an email with
                    request for booking a new meeting
                  </Typography>
                  {error && (
                    <Hint classes={{ subtitle2: classes.hint }}>
                      <Icon iconClass="las las la-exclamation-triangle" />
                      Please provide reason for rescheduling
                    </Hint>
                  )}
                </div>
                <div className={classes.buttonGroup}>
                  <Button variant="secondary" onClick={onModalClose} className={classes.cancelButton}>
                    cancel
                  </Button>
                  <Button variant="primary" onClick={onConfirm} className={classes.confirmButton}>
                    confirm
                  </Button>
                </div>
              </div>
            )
          )}
        </div>
      </Modal>
    </>
  )
}

export default ProRescheduleEventModal
