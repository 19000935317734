import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  CaseFeeSettingsSelector,
  FormAutocomplete,
  FormDatePicker,
  FormInput,
  FormSubSpecialityAutocomplete,
  FormTextArea,
  FormViewField,
  LabelWithHint,
  Typography,
} from 'components'
import { colors, CONSTANTS } from 'constants/index'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { getDateWithTZ } from 'utils/getters/common'

const useStyles = makeStyles({
  formData: {
    width: '60%',
  },
  primaryInfo: {
    display: 'flex',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    marginBottom: 24,
  },
  twoColumn: {
    width: '48%',
  },
  twoColumnRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textArea: {
    width: '100%',
    marginBottom: 16,
  },
  standardMargin: {
    marginBottom: 16,
  },
  sideInfoValues: {
    marginBottom: 24,
    fontWeight: 500,
    fontSize: 15,
    lineHeight: 1.47,
  },
  sideInfo: {
    paddingLeft: 100,
  },
  statusActive: {
    color: colors.SUCCESS,
    fontWeight: 500,
  },
  viewValueClass: {
    fontWeight: 500,
  },
  statusInactive: {
    fontWeight: 500,
  },
  tooltip: {
    padding: 16,
    fontSize: 13,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[100],
  },
  fullWidth: {
    width: '100%',
  },
  adornment: {
    marginRight: 0,
  },
  addClientButton: {
    marginBottom: 26,
    maxHeight: 30,
    height: 30,
  },
})

const getCaseStatus = status => {
  if (status) {
    return 'Active'
  }
  return 'Inactive'
}

const CaseConfigurationForm = props => {
  const classes = useStyles()
  const { caseData, mode, onChange, errors = {}, setErrors = error => {}, addClient } = props
  const connections = useSelector(state => state.connections.filterConnections)
  const proName = useSelector(state => `${state.auth.currentUser.first_name} ${state.auth.currentUser.last_name}`)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const isInitial = caseData.fee_type === CONSTANTS.FEE_TYPES['0'].key

  const handleChange = (name, value) => {
    onChange({
      [name]: value,
    })
    setErrors({
      [name]: '',
    })
  }

  useEffect(() => {
    if (connections.length === 1 && mode === CONSTANTS.MODE.ADD) handleChange('client', connections[0].value)
  }, [connections])

  const handleSubSpecialityChange = value => {
    onChange({
      ...value,
    })
    setErrors({
      practice_area: '',
      sub_speciality: '',
    })
  }

  return (
    caseData && (
      <div className={classes.content}>
        {isInitial && (
          <Typography variant="subtitle2" className={classes.standardMargin}>
            This case is created to document the details of the initial consultation. An entry into the activity table
            will be automatically created.
          </Typography>
        )}

        <div className={classes.primaryInfo}>
          <div className={classes.formData}>
            {mode === CONSTANTS.MODE.ADD ? (
              <>
                <FormAutocomplete
                  label="Client name *"
                  value={caseData.client}
                  fullWidth
                  containerClass={classes.standardMargin}
                  name="client"
                  error={errors.client}
                  onChange={handleChange}
                  options={connections}
                  placeholder="Please select"
                />
                {addClient && (
                  <Button variant="text" className={classes.addClientButton} onClick={addClient}>
                    Add client
                  </Button>
                )}
              </>
            ) : (
              <FormInput
                label="Initial consultation fee"
                disabled
                value={caseData.client_name}
                fullWidth
                containerClass={classes.standardMargin}
                name="client"
              />
            )}
            <CaseFeeSettingsSelector
              caseData={caseData}
              mode={mode}
              onChange={onChange}
              errors={errors}
              setErrors={setErrors}
              isInitial={isInitial}
            >
              <>
                <FormInput
                  label="Case/Matter name *"
                  disabled={mode === CONSTANTS.MODE.VIEW || isInitial}
                  value={caseData.name}
                  fullWidth
                  containerClass={classes.standardMargin}
                  name="name"
                  error={errors.name}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <div className={classes.twoColumnRow}>
                  <div
                    className={
                      caseData.fee_type === CONSTANTS.FEE_TYPES['3'].key ? classes.twoColumn : classes.fullWidth
                    }
                  >
                    <FormSubSpecialityAutocomplete
                      label="Practice area/Sub-speciality *"
                      disabled={mode === CONSTANTS.MODE.VIEW || isInitial}
                      value={{
                        sub_speciality: caseData.sub_speciality,
                        practice_area: caseData.practice_area,
                      }}
                      fullWidth
                      containerClass={classes.standardMargin}
                      error={errors.practice_area || errors.sub_speciality}
                      onChange={handleSubSpecialityChange}
                      placeholder="Please select"
                    />
                  </div>

                  {caseData.fee_type === CONSTANTS.FEE_TYPES['3'].key && (
                    <div className={classes.twoColumn}>
                      {isInitial ? (
                        <FormInput
                          label="Initial consultation fee"
                          disabled
                          value={caseData.amount}
                          fullWidth
                          containerClass={classes.standardMargin}
                          name="initial_consultation_fee"
                        />
                      ) : (
                        <FormInput
                          label={
                            <LabelWithHint
                              label="Hourly rate, $ *"
                              hintText="The hourly rate entered in the case is the default value for all the activities created on the case. However, the rate can be changed for each individual entry in the activity table. "
                              toolTipClass={classes.tooltip}
                            />
                          }
                          disabled={mode === CONSTANTS.MODE.VIEW}
                          value={caseData.hourly_rate}
                          fullWidth
                          containerClass={classes.standardMargin}
                          name="hourly_rate"
                          error={errors.hourly_rate}
                          onChange={handleChange}
                          type="number"
                        />
                      )}
                    </div>
                  )}
                </div>
                {!isInitial && (
                  <FormTextArea
                    label="Case description"
                    disabled={mode === CONSTANTS.MODE.VIEW}
                    value={caseData.description}
                    fullWidth
                    containerClass={classes.standardMargin}
                    name="description"
                    error={errors.description}
                    onChange={handleChange}
                    areaWrapperClass={classes.textArea}
                    characterLimit={500}
                  />
                )}
              </>
            </CaseFeeSettingsSelector>
          </div>
          <div className={classes.sideInfo}>
            {mode === CONSTANTS.MODE.ADD ? (
              <FormDatePicker
                label="Date of creation *"
                value={caseData.date_of_creation}
                onChange={handleChange}
                fullWidth
                name="date_of_creation"
                error={errors.date_of_creation}
                containerClass={classes.standardMargin}
                disabledDays={{ after: getDateWithTZ(null, timezone) }}
                timezone={timezone}
              />
            ) : (
              <FormViewField
                containerClass={classes.standardMargin}
                label="Date of creation"
                value={moment(caseData.date_of_creation)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
                valueClass={classes.viewValueClass}
              />
            )}
            {mode !== CONSTANTS.MODE.ADD && (
              <FormViewField
                containerClass={classes.standardMargin}
                label="Status"
                value={getCaseStatus(caseData.is_active)}
                valueClass={caseData.is_active ? classes.statusActive : classes.statusInactive}
              />
            )}
            {!caseData.is_active && (
              <FormViewField
                containerClass={classes.standardMargin}
                label="Date of inactivation"
                value={moment(caseData.updated_at)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
                valueClass={classes.viewValueClass}
              />
            )}
            {mode !== CONSTANTS.MODE.ADD && (
              <FormViewField
                containerClass={classes.standardMargin}
                label="System Case ID"
                value={caseData.system_case_id}
                valueClass={classes.viewValueClass}
              />
            )}
            <FormViewField
              containerClass={classes.standardMargin}
              label="Lawyer"
              value={proName}
              valueClass={classes.viewValueClass}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default CaseConfigurationForm
