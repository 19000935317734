import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'components'

const useStyles = makeStyles({
  link: {
    fontSize: 16,
  },
})

const RestrictionMsg = ({ message, goToZendesk }) => {
  const classes = useStyles()
  const bodyMsg = message.split('Contact us')[0]
  return (
    <span>
      {`${bodyMsg} `}
      {message.indexOf('Contact us') > -1 && (
        <span>
          <Link onClick={goToZendesk} classes={{ root: classes.link }}>
            Contact us
          </Link>
          {' for assistance.'}
        </span>
      )}
    </span>
  )
}

export default RestrictionMsg
