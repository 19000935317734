import React from 'react'
import { Modal, Typography, Button, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as SuccessLogo } from 'static/icon_24_checkmark-circle-2.svg'
import { colors } from 'constants/colors'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 550,
    position: 'relative',
    padding: 32,
    backgroundColor: WHITE,
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
  },
  title: {
    margin: '0 0 24px 0',
    display: 'flex',
    paddingRight: 2,
  },
  boxBtn: {
    textAlign: 'right',
  },
  icon: {
    marginRight: 8,
  },
})

const SuccessModal = ({ message, isOpen, onClose, title }) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          <SuccessLogo className={classes.icon} />
          {title}
        </Typography>
        <Typography variant="subtitle1">{message}</Typography>
        <div className={classes.boxBtn}>
          <Button onClick={onClose}>close</Button>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal
