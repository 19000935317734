import React from 'react'
import { Route } from 'react-router-dom'
import { ClientNavDrawer } from 'components'
import { makeStyles } from '@material-ui/core/styles'

import { ClientPaymentsContainer, ClientProfile, ClientSettings, NotificationSettings } from 'containers'

const useStyles = makeStyles({
  content: {
    marginLeft: 240,
  },
})

const ClientProfileContainer = ({ executeScroll }) => {
  const classes = useStyles()

  return (
    <>
      <Route
        path={[
          '/client/account/profile',
          '/client/account/settings',
          '/client/account/notifications',
          '/client/account/payments',
        ]}
        component={ClientNavDrawer}
      />
      <div className={classes.content}>
        <Route path="/client/account/profile" render={() => <ClientProfile executeScroll={executeScroll} />} />
        <Route path="/client/account/settings" component={() => <ClientSettings />} />
        <Route path="/client/account/notifications" component={() => <NotificationSettings isClientSettings />} />
        <Route path="/client/account/payments" component={ClientPaymentsContainer} />
      </div>
    </>
  )
}

export default ClientProfileContainer
