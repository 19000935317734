import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'components'
import { useSelector, useDispatch } from 'react-redux'
import { navigateToRoot } from 'store/actions/navigation'
import BackgroundImage from 'static/welcome.jpg'
import BackgroundImageMin from 'static/welcome-min.jpg'

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
  },
  title: {
    fontSize: 36,
    margin: '0',
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: '40px',
    width: '768px',
    alignSelf: 'center',
  },
  boxBtn: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    alignSelf: 'center',
    width: 280,
    letterSpacing: 1,
    marginTop: 40,
  },
  content: {
    position: 'relative',
    top: '25%',
    margin: '0 auto',
    padding: '0 20px',
    width: '100%',
    maxWidth: 670,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: 'cover',
  },
  image: {
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  '@media(max-width:1279px)': {
    content: {
      top: '20%',
      maxWidth: 610,
    },
  },
  '@media(max-width:767px)': {
    imageContainer: {
      backgroundImage: `url(${BackgroundImageMin})`,
    },
  },
  '@media(max-width:429px)': {
    title: {
      fontSize: 20,
      paddingLeft: 95,
    },
    btn: {
      marginTop: 70,
    },
    content: {
      top: '23%',
    },
  },
})

const ClientWelcome = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.auth.currentUser)
  const onBtnClick = () => dispatch(navigateToRoot())

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <Typography variant="h2" className={classes.title}>
            welcome to XIRA
          </Typography>
          <Typography variant="h2" className={classes.title}>
            {currentUser.first_name}!
          </Typography>
          <div className={classes.boxBtn}>
            <Button variant="primary" className={classes.btn} onClick={onBtnClick}>
              start exploring
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.imageContainer}>
        {/*<img src={BackgroundImage} alt="Verify email" className={classes.image} />*/}
      </div>
    </div>
  )
}

export default ClientWelcome
