import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Text from './text/text'

const XCheckBox = ({
  id,
  checked,
  disabled,
  classes,
  inputProps,
  onChange,
  label,
  link,
  onClick,
  labelClasses,
  labelTextClasses,
  name,
  color = 'primary',
  indeterminate,
}) => {
  const checkBox = (
    <Checkbox
      id={id}
      indeterminate={indeterminate}
      checked={checked}
      disabled={disabled}
      classes={classes}
      inputProps={inputProps}
      onChange={onChange}
      color={color}
      name={name}
    />
  )

  return label ? (
    <FormControlLabel
      control={checkBox}
      onClick={onClick}
      classes={labelClasses}
      label={
        <Text classes={labelTextClasses}>
          {label} {link}
        </Text>
      }
    />
  ) : (
    checkBox
  )
}

export default XCheckBox
