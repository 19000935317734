import { Button, FormDatePicker, FormInput, Icon, Typography } from 'components'
import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import {formatDecimal, getDateWithTZ} from 'utils/getters/common'
import { useSelector } from 'react-redux'
import {isInFutureCaseInvoice} from "utils/cases/casesGetters";

const useStyles = makeStyles({
  standardMargin: {
    marginBottom: 16,
  },
  rowColumn: {
    marginBottom: 16,
    width: '48%',
  },
  balanceField: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  deleteIcon: {
    position: 'absolute',
    right: '-26px',
    top: 34,
    fontSize: 18,
    cursor: 'pointer',
    color: colors.BASIC.COLOR[70],
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  adornment: {
    marginRight: 0,
  },
})

const InvoicingScheduleRow = props => {
  const classes = useStyles()
  const { data, onChange, index, mode, onDelete, errors = {} } = props
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const [invoiceDateChanged, setInvoiceDateChanged] = useState(false)
  const isInFuture = isInFutureCaseInvoice(data, timezone, invoiceDateChanged)
  const isDisabled = (data.invoice_date && mode === CONSTANTS.MODE.EDIT && !isInFuture) || mode === CONSTANTS.MODE.VIEW
  const showDeleteIcon = !isDisabled && index !== 0

  const handleChange = (name, value) => {
    if(name === 'invoice_date') setInvoiceDateChanged(true)
    const newValue = {
      ...data,
      [name]: value,
    }
    onChange(newValue, index)
  }

  return (
    <div className={classes.row}>
      <FormInput
        label="Amount, $"
        disabled={isDisabled}
        value={data.invoice_amount}
        fullWidth
        containerClass={classes.rowColumn}
        name="invoice_amount"
        onChange={handleChange}
        handleBlur={e => handleChange('invoice_amount', formatDecimal(e.target.value))}
        type="number"
        error={errors.invoice_amount}
        autoFocus={index !== 0 && !data.uuid}
        inputProps={{ style: { textAlign: 'right' } }}
      />
      <FormDatePicker
        disabled={isDisabled}
        label="Invoice date"
        value={data.invoice_date}
        onChange={handleChange}
        fullWidth
        name="invoice_date"
        containerClass={classes.rowColumn}
        error={errors.invoice_date}
        disabledDays={{ before: getDateWithTZ(null, timezone) }}
        timezone={timezone}
      />
      {showDeleteIcon && <Icon onClick={() => onDelete(index)} style={classes.deleteIcon} iconClass="las la-times" />}
    </div>
  )
}

const InvoicingSchedule = props => {
  const { invoicingSections, onChange, mode, errors = [], balance = 0, setErrors = error => {} } = props
  const classes = useStyles()
  const showButton = mode !== CONSTANTS.MODE.VIEW && balance > 0

  const onAddNew = () => {
    onChange({
      invoicing_sections: [
        ...invoicingSections,
        {
          invoice_amount: '',
          invoice_date: '',
        },
      ],
    })
  }

  const onDelete = index => {
    const newSections = invoicingSections.filter((e, i) => i !== index)
    const newErrors = errors.filter((e, i) => i !== index)
    onChange({
      invoicing_sections: newSections,
    })
    setErrors({
      invoicing_sections: newErrors,
    })
  }

  const handleChange = (newValue, index) => {
    const arr = [...invoicingSections]
    arr[index] = newValue
    const newErrors = [...errors]
    newErrors[index] = {
      invoice_amount: '',
      invoice_date: '',
    }
    onChange({
      invoicing_sections: arr,
    })
    setErrors({
      invoicing_sections: newErrors,
    })
  }

  return (
    <div className={classes.standardMargin}>
      <Typography variant="subtitle1" className={classes.standardMargin}>
        Invoicing schedule
      </Typography>
      {invoicingSections &&
        invoicingSections.map((section, i) => (
          <InvoicingScheduleRow
            key={i}
            data={section}
            onChange={handleChange}
            index={i}
            onDelete={onDelete}
            mode={mode}
            errors={errors[i]}
          />
        ))}
      {showButton && (
        <Button variant="text" onClick={onAddNew} startIcon={<Icon iconClass="las la-plus-circle" />}>
          add more
        </Button>
      )}
    </div>
  )
}

export default InvoicingSchedule
