import * as CONSTANTS from 'store/constants/connections'
import { SET_ARCHIVE_USER_CONNECTIONS_END } from 'store/constants/connections'

const initialState = {
  filters: {
    search: '',
  },
  initialFilters: {
    search: '',
  },
  sort: {
    property: '',
    iaAsc: false,
  },
  connections: [],
  connectionsAreLoading: false,
  limit: 10,
  offset: 0,
  tableConfiguration: {
    tablePage: 0,
    limit: 20,
    offset: 0,
  },
  connectionsLength: 0,
  filterConnections: [],
  openInvitePopup: false,
  isSendInviteLink: false,
  clientInvites: [],
  clientInvitesIsLoading: false,
  clientArchiveIsLoading: false,
}

const connections = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.GET_USER_CONNECTIONS_START: {
      return {
        ...state,
        connectionsAreLoading: true,
      }
    }
    case CONSTANTS.GET_USER_CONNECTIONS_END: {
      return {
        ...state,
        connectionsAreLoading: false,
        connections: action.payload,
        connectionsLength: action.length,
      }
    }
    case CONSTANTS.RESET_CONNECTIONS_UPLOAD_OFFSET:
      return {
        ...state,
        offset: 0,
      }
    case CONSTANTS.INCREASE_CONNECTIONS_UPLOAD_OFFSET:
      return {
        ...state,
        offset: state.offset + state.limit,
      }
    case CONSTANTS.RESET_CONNECTIONS_LIST:
      return {
        ...state,
        connections: [],
      }
    case CONSTANTS.GET_USER_CONNECTIONS_FOR_FILTER_START:
      return {
        ...state,
      }
    case CONSTANTS.GET_USER_CONNECTIONS_FOR_FILTER_END:
      return {
        ...state,
        filterConnections: action.payload,
      }
    case CONSTANTS.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      }
    case CONSTANTS.SET_TABLE_CONFIGURATION:
      return {
        ...state,
        tableConfiguration: {
          ...state.tableConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_SORTING:
      return {
        ...state,
        sort: {
          property: action.property,
          isAsc: action.isAsc,
        },
      }
    case CONSTANTS.OPEN_INVITE_POPUP:
      return {
        ...state,
        openInvitePopup: action.open,
      }
    case CONSTANTS.IS_SEND_INVITE_LINK_START:
      return {
        ...state,
        isSendInviteLink: true,
      }
    case CONSTANTS.IS_SEND_INVITE_LINK_END:
      return {
        ...state,
        isSendInviteLink: false,
      }
    case CONSTANTS.SET_CLIENT_INVITES:
      return {
        ...state,
        clientInvites: action.payload,
      }
    case CONSTANTS.SET_CLIENT_INVITES_LOADING:
      return {
        ...state,
        clientInvitesIsLoading: action.payload,
      }
    case CONSTANTS.SET_ARCHIVE_USER_CONNECTIONS_START:
      return {
        ...state,
        clientArchiveIsLoading: true,
      }
    case CONSTANTS.SET_ARCHIVE_USER_CONNECTIONS_END:
      return {
        ...state,
        clientArchiveIsLoading: false,
      }
    default:
      return state
  }
}

export default connections
