import React from 'react'
import classNames from 'classnames'
import { colors } from 'constants/colors'
import { Typography, Avatar, PriceSummary, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as OpenContainer } from 'static/chevron-down.svg'
import NoPhoto from 'static/search/pro_no photo.png'
import MeetingTimeInfo from './MeetingTimeInfo'

const useStyles = makeStyles({
  proInfoMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '0 26px',
  },
  avatar: {
    width: 180,
    height: 180,
    marginTop: 24,
    marginBottom: 16,
  },
  title: {
    marginBottom: 10,
  },
  subtitle: {
    marginBottom: 15,
    color: colors.BASIC.COLOR[80],
    fontSize: 15,
  },
  bodyTitle: {
    fontSize: 15,
    marginBottom: 5,
  },
  dateContainerWrapper: {
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px 26px',
  },
  priceSummaryContainer: {
    padding: '0 26px',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  openDateContainer: {
    display: 'none',
  },
  '@media(max-width:767px)': {
    proInfoMainContainer: {
      position: 'relative',
      order: 0,
      borderBottom: 'none',
      alignItems: 'flex-start',
      width: '100%',
      minHeight: 65,
      maxWidth: 400,
      padding: '20px 15px 0 95px',
      margin: '0 auto',
    },
    openDateContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 50,
      height: 50,
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    closeDateContainer: {
      transform: 'rotate(180deg)',
    },
    dateContainerWrapper: {
      maxHeight: 100,
      transition: 'max-height .3s, opacity .3s',
      order: 2,
      width: '100%',
      opacity: 1,
    },
    closeDateContainerWrapper: {
      maxHeight: 0,
      overflow: 'hidden',
      borderBottom: 'none',
      opacity: 0,
    },
    priceSummaryContainer: {
      order: 1,
      borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
      padding: 0,
    },
    dateContainer: {
      padding: '16px 15px',
      margin: '0 auto',
      width: '100%',
      maxWidth: 400,
    },
    bodyTitle: {
      display: 'none',
    },
    avatar: {
      position: 'absolute',
      top: 20,
      left: 15,
      width: 64,
      height: 64,
      margin: 0,
    },
    title: {
      fontSize: 14,
      margin: 0,
    },
    subtitle: {
      fontSize: 12,
      marginBottom: 6,
    },
  },
})

const MeetingSchedulerProInfo = ({ practiceArea, subPracticeArea, proAccount, start, end, initialPrice }) => {
  const classes = useStyles()
  const practiceAreaLabel = subPracticeArea
    ? subPracticeArea.name
    : practiceArea
    ? practiceArea.selected_practice_area.name
    : 'Area of law or legal topic'
  const [openContainer, setOpenContainer] = React.useState(false)
  return (
    <>
      <div className={classes.proInfoMainContainer}>
        <div
          className={classNames(classes.openDateContainer, { [classes.closeDateContainer]: openContainer })}
          onClick={() => setOpenContainer(!openContainer)}
        >
          <SvgIcon viewBox="0 0 24 24">
            <OpenContainer />
          </SvgIcon>
        </div>
        <Avatar className={classes.avatar} src={proAccount.photo || NoPhoto} />
        <Typography className={classes.title} variant="h4">
          {proAccount.firstName} {proAccount.lastName}
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          {practiceAreaLabel}
        </Typography>
      </div>
      <div
        className={classNames(classes.dateContainerWrapper, { [classes.closeDateContainerWrapper]: !openContainer })}
      >
        <div className={classes.dateContainer}>
          <Typography className={classes.bodyTitle}>date & time</Typography>
          <MeetingTimeInfo start={start} end={end} />
        </div>
      </div>
      <div className={classes.priceSummaryContainer}>
        <PriceSummary initialPrice={initialPrice} proAccount={proAccount} variant="profile" />
      </div>
    </>
  )
}

export default MeetingSchedulerProInfo
