import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAuthRole } from 'store/actions/auth'
import {
  navigateToRoot,
  navigateToRestorePassword,
  navigateToSignUp,
  navigateTo,
  navigateToClientDashboard,
} from 'store/actions/navigation'
import { routes } from 'constants/index'
import LogInContainer from './LogInContainer'
import { getJWTLocally } from '../../utils/auth'

const LogInByRoute = props => {
  const dispatch = useDispatch()
  const [isRedirect] = useState(window.location.search)
  const disableClose = props.location.pathname === routes.SIGN_IN || props.location.search.includes('redirect')
  const goToSignUp = () => {
    if (disableClose) {
      dispatch(navigateTo('/sign-up'))
    } else {
      dispatch(navigateToSignUp(props.location.search))
    }
  }

  const closeModal = () => {
    if (disableClose) return
    dispatch(setAuthRole(''))
    dispatch(navigateToRoot())
  }

  const goToRestore = () => {
    if (disableClose) dispatch(navigateTo(routes.getUserPasswordResetUnauthorizedPath()))
    else dispatch(navigateToRestorePassword())
  }

  const onAfterLogin = () => {
    if (!isRedirect) {
      dispatch(navigateToRoot())
    }
  }

  useEffect(() => {
    if (getJWTLocally()) {
      dispatch(navigateToClientDashboard())
    }
  }, [])

  return (
    <LogInContainer
      open
      handleClose={closeModal}
      goToSignUp={goToSignUp}
      goToRestore={goToRestore}
      disableClose={disableClose}
      onAfterLogin={onAfterLogin}
    />
  )
}

export default LogInByRoute
