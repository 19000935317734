import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, VaultDragDropArea } from 'components'
import { ReactComponent as UploadIcon } from 'static/vault/cloud_upload.svg'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  uploadWrapper: {
    position: 'absolute',
    backgroundColor: colors.WHITE,
    bottom: 0,
    right: 0,
    left: 0,
    height: '10%',
    padding: '0 24px 16px 24px',
    transition: '0.2s linear',
  },
  hoverUploadWrapper: {
    height: '100%',
    padding: 0,
  },
  upload: {
    backgroundColor: colors.BASIC.COLOR['5'],
    border: `2px dashed ${colors.BASIC.COLOR['20']}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    height: '100%',
    transition: '0.2s linear',
  },
  hoverUpload: {
    backgroundColor: colors.ADDITIONAL.COLOR['5'],
    border: `2px dashed ${colors.ADDITIONAL.COLOR['40']}`,
  },
  fileInputLabel: {
    cursor: 'pointer',
    color: colors.PRIMARY.COLOR['50'],
    fontSize: 15,
    fontWeight: 500,
    marginLeft: 4,
    marginBottom: 1,
  },
  fileInputText: {
    fontSize: 15,
    fontWeight: 'normal',
    marginLeft: 12,
  },
  input: {
    display: 'none',
  },
})

const VaultFolderInputArea = ({ onAddFile, caseLabel }) => {
  const classes = useStyles()
  return (
    <VaultDragDropArea
      onAddFile={onAddFile}
      wrapperClasses={classes.uploadWrapper}
      hoverWrapperClasses={classes.hoverUploadWrapper}
      containerClasses={classes.upload}
      hoverContainerClasses={classes.hoverUpload}
      inputClasses={classes.input}
      hoverText={`drop your files here to upload to the folder ${caseLabel}`}
      inputID={'vault_file_upload'}
      withFileInput
    >
      <UploadIcon />
      <Typography className={classes.fileInputText}>
        <b>drag & drop</b> your files or
      </Typography>
      <label className={classes.fileInputLabel} htmlFor={'vault_file_upload'}>
        browse
      </label>
    </VaultDragDropArea>
  )
}

export default VaultFolderInputArea
