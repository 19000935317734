import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/index'
import { Button, ColorCircularProgress, ConfirmationModal, Icon, SvgIcon, ThreeDotsMenu, Typography } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { navigateToStripeConnect, redirectTo } from 'store/actions/navigation'
import { ReactComponent as StripeLogo } from 'static/stripe_logo.svg'
import {
  disconnectPaymentMethod,
  getPaymentConnectionStatus,
  redirectToStripeDashboard,
  savePaymentMethod,
} from 'store/actions/wallet/wallet'
import { useQuery } from 'utils/hooks'

const useStyles = makeStyles({
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 4,
  },
  icon: {
    marginRight: 8,
    fontSize: 18,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '15px 0',
    minWidth: 200,
    maxWidth: 600,
  },
  rowRightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  stripeLogo: {
    height: 24,
    width: 58,
    marginRight: 24,
  },
  rowText: {
    color: colors.BASIC.COLOR[20],
    fontSize: 13,
  },
  paymentDesc: {
    maxWidth: 715,
    marginBottom: 24,
    fontSize: 15,
    fontWeight: 400,
    color: colors.BASIC.COLOR[100],
  },
  paragraph: {
    marginBottom: 12,
  },
})

const ProPaymentContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const code = useQuery().get('code')
  const paymentAccountStatus = useSelector(state => state.wallet.paymentAccountStatus)
  const paymentAccountStatusIsLoading = useSelector(state => state.wallet.paymentAccountStatusIsLoading)
  const [isConfirmationModalShown, setIsConfirmationModalShown] = useState(false)
  const paymentMethodId = ''

  const onConfirmationModalConfirm = () => {
    dispatch(navigateToStripeConnect())
  }

  useEffect(() => {
    dispatch(getPaymentConnectionStatus())
  }, [])

  useEffect(() => {
    if (code) {
      savePaymentMethodCode(code)
    }
  }, [code])

  const savePaymentMethodCode = async code => {
    try {
      await dispatch(savePaymentMethod(code))
      dispatch(redirectTo('/pro/account/settings/payment'))
      dispatch(getPaymentConnectionStatus())
    } catch (e) {
      console.error(e)
    }
  }

  const actions = [
    {
      key: 'edit',
      label: 'edit',
      action: async () => {
        try {
          window.location.href = await dispatch(redirectToStripeDashboard())
        } catch (e) {
          console.error(e)
        }
      },
    },
    {
      key: 'disconnect',
      label: 'disconnect',
      action: async () => {
        try {
          await dispatch(disconnectPaymentMethod())
          dispatch(getPaymentConnectionStatus())
        } catch (e) {
          console.error(e)
        }
      },
    },
  ]

  return (
    <div className={classes.walletContainer}>
      <Typography variant="h4" className={classes.title}>
        Payment
      </Typography>
      {paymentAccountStatusIsLoading ? (
        <ColorCircularProgress />
      ) : paymentAccountStatus ? (
        <div className={classes.row}>
          <div className={classes.rowRightSide}>
            <SvgIcon viewBox="0 0 58 24" className={classes.stripeLogo}>
              <StripeLogo />
            </SvgIcon>
            <Typography className={classes.rowText}>account connected</Typography>
          </div>
          <div className={classes.rowActions}>
            <ThreeDotsMenu actions={actions} data={paymentMethodId} />
          </div>
        </div>
      ) : (
        <>
          <Typography className={classes.paymentDesc}>
            When clients pay you the funds go into your XIRA wallet. In order to have it transferred to your business
            account you need to connect your business account. You may do this now or later when you wish to transfer
            funds that have become available.
          </Typography>
          <Button variant="primary" onClick={() => setIsConfirmationModalShown(true)}>
            <Icon iconClass="las la-link" style={classes.icon} />
            Connect payment method
          </Button>
        </>
      )}
      <ConfirmationModal
        title="No banking information on file"
        message={
          <>
            <p className={classes.paragraph}>
              By clicking the button below you will be redirected to Stripe for creation of your Express account that
              will be used for payout. You will be asked for additional contact details and a debit card or your bank
              account depending on your choice.
            </p>

            <p className={classes.paragraph}>
              In case you already have a Stripe account on your current email, you will be asked to login to make
              registration faster.
            </p>

            <p className={classes.paragraph}>
              Once you’ve created the payment account you will be able to proceed with requesting funds.
            </p>
          </>
        }
        confirmationButtonText="ADD MY ACCOUNT"
        cancelationButtonText="Cancel"
        isOpen={isConfirmationModalShown}
        onClose={() => setIsConfirmationModalShown(false)}
        onConfirm={() => onConfirmationModalConfirm()}
      />
    </div>
  )
}

export default ProPaymentContainer
