import React, { useEffect, useState, useRef } from 'react'
import { DateUtils } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { TextField } from 'components'
import { InputAdornment } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { colors } from 'constants/index'
import { ReactComponent as CalendarLogo } from 'static/proMenuIcons/calendar/calendar.svg'
import { ReactComponent as DeleteIcon } from 'static/common/delete.svg'
import moment from 'moment-timezone'
import './styles/index.scss'
import 'react-day-picker/lib/style.css'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getDatePickerValueWithProTZ, getDateWithTZ } from '../../../utils/getters/common'

const useStyles = makeStyles({
  adornment: {
    cursor: 'pointer',
  },
})

const DateRangePickerComponent = ({
  containerClasses,
  inputClasses,
  numberOfMonths = 1,
  label,
  range,
  updateRange,
  timezone = moment.tz.guess(),
}) => {
  const classes = useStyles()
  const DateRangePickerInput = React.forwardRef((props, ref) => {
    {
      return (
        <TextField
          variant="outlined"
          InputProps={{
            classes: { root: inputClasses },
            startAdornment: (
              <InputAdornment disableTypography position="start">
                <CalendarLogo style={{ stroke: colors.BASIC.COLOR[70] }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                disableTypography
                position="end"
                classes={{ root: classes.adornment }}
                onClick={clearRange}
              >
                <DeleteIcon style={{ stroke: colors.BASIC.COLOR[70] }} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            readOnly: true,
          }}
          label={label}
          {...props}
        />
      )
    }
  })
  const [value, setValue] = useState('')
  const inputRef = useRef(null)
  const modifiers = { start: range.from, end: range.to, today: getDateWithTZ(null, timezone) }

  const handleDayClick = day => {
    const newRange = DateUtils.addDayToRange(getDatePickerValueWithProTZ(day, timezone), range)
    const fromMoment = newRange.from
      ? moment(newRange.from)
          .tz(timezone)
          .startOf('day')
      : null
    const toMoment = newRange.to
      ? moment(newRange.to)
          .tz(timezone)
          .endOf('day')
      : null
    const updatedNewRange = {
      from: fromMoment && fromMoment.toDate(),
      to: toMoment && toMoment.toDate(),
    }
    updateRange(updatedNewRange)
  }

  const clearRange = e => {
    e.stopPropagation()
    inputRef && inputRef.current && inputRef.current.hideDayPicker()
    updateRange({ from: null, to: null })
  }

  const clickAwayHandler = () => {
    if (!range.from && !range.to) return
    if (!range.from || !range.to) updateRange({ from: null, to: null })
    inputRef && inputRef.current && inputRef.current.hideDayPicker()
  }

  useEffect(() => {
    let value = ''
    if (range.from && range.to)
      value = `${moment(range.from)
        .tz(timezone)
        .format('DD MMM')} - ${moment(range.to)
        .tz(timezone)
        .format('DD MMM YY')}`
    else if (range.from)
      value = `${moment(range.from)
        .tz(timezone)
        .format('DD MMM')}`
    else if (range.to)
      value = `${moment(range.to)
        .tz(timezone)
        .format('DD MMM')}`
    else value = 'Choose date range'
    setValue(value)
  }, [range])

  const rangeFromTZ = range.from && getDateWithTZ(range.from, timezone)
  const rangeToTZ = range.to && getDateWithTZ(range.to, timezone)
  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className={containerClasses}>
        <DayPickerInput
          ref={inputRef}
          placeholder=""
          value={value}
          component={DateRangePickerInput}
          hideOnDayClick={!!(range.from && range.to)}
          dayPickerProps={{
            className: 'Selectable',
            numberOfMonths: numberOfMonths,
            selectedDays: [rangeFromTZ, { from: rangeFromTZ, to: rangeToTZ }],
            modifiers: modifiers,
            onDayClick: handleDayClick,
          }}
        />
      </div>
    </ClickAwayListener>
  )
}

export default DateRangePickerComponent
