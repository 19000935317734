import React from 'react'
import { makeStyles } from '@material-ui/core'
import { InvoiceSummaryTableRow, Loader, Table } from 'components/index'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'constants/index'
import { setInvoiceSummarySorting, getProInvoiceSummary } from 'store/actions/invoices'
import TableCell from '@material-ui/core/TableCell'

const useStyles = makeStyles({
  activitiesTable: {
    marginTop: 16,
    height: '100%',
  },
  tableHeaderCell: {
    borderBottom: `2px solid ${colors.BASIC.COLOR[10]}`,
  },
  footer: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: colors.BASIC.COLOR['100'],
  },
})

const columns = [
  {
    value: 'item',
    label: 'Item',
    sortable: false,
  },
  {
    value: 'created_at',
    label: 'Date',
    sortable: true,
  },
  {
    value: 'amount',
    label: 'Amount',
    sortable: false,
    numeric: true,
  },
  {
    value: 'balance',
    label: 'Balance',
    sortable: false,
    numeric: true,
  },
  {
    value: 'isVoid',
    label: '',
    sortable: false,
  },
]

const InvoiceSummaryTable = ({ summary, timezone, balance, invoiceID, setInvoiceTab }) => {
  const sorting = useSelector(state => state.invoices.sortSummary)
  const invoiceSummaryAreLoading = useSelector(state => state.invoices.invoiceSummaryAreLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  const onSort = (name, isAcs) => {
    dispatch(setInvoiceSummarySorting(name, isAcs))
    dispatch(getProInvoiceSummary(invoiceID))
  }

  return (
    <>
      {invoiceSummaryAreLoading ? (
        <Loader />
      ) : (
        <Table
          tableColumns={columns}
          className={classes.activitiesTable}
          tableHeaderCellClass={{ root: classes.tableHeaderCell }}
          tableData={summary}
          sorting={sorting}
          onSort={onSort}
          tableDataRowRenderer={(columns, data) => (
            <InvoiceSummaryTableRow timezone={timezone} columns={columns} data={data} setInvoiceTab={setInvoiceTab} />
          )}
          footer={
            <>
              <TableCell className={classes.footer} style={{ fontSize: 12 }}>
                Current Balance
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell className={classes.footer} align={'right'}>
                {' '}
                ${balance}{' '}
              </TableCell>
              <TableCell />
            </>
          }
          activeRows
        />
      )}
    </>
  )
}

export default InvoiceSummaryTable
