import React from 'react'
import { ConfirmationModal } from 'components'

const VaultDowngradeModal = ({ isOpen, onClose, title, message, onConfirm }) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      message={message}
      confirmationButtonText="go to vault"
      onConfirm={onConfirm}
    />
  )
}
export default VaultDowngradeModal
