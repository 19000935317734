import { CreditAdjustmentTable, InvoiceDocumentFooter, InvoiceDocumentHeader } from './index'
import { View, StyleSheet, Text } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  mainSection: {
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
  },
  creditAdjustmentContainer: {
    height: '100%',
  },
})

const CreditAdjustmentEntry = ({ credit, invoice, timezone }) => (
  <View style={styles.creditAdjustmentContainer} break>
    <InvoiceDocumentHeader credit={credit} invoice={invoice} timezone={timezone} />
    <View style={styles.mainSection}>
      <CreditAdjustmentTable credit={credit} invoice={invoice} timezone={timezone} />
    </View>
    <InvoiceDocumentFooter invoice={invoice} credit={credit} />
  </View>
)

export default CreditAdjustmentEntry
