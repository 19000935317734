import React from 'react'
import GoogleCalendarConnectButton from './googleCalendarConnectButton'
import OutlookConnectButton from './outlookConnectButton'
import AppleConnectButton from './appleConnectButton'
import { Button, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
})

const calendarSettingsMock = {
  email: 'marjorie.miles@gmail.com',
  calendars: [
    { name: 'work', selected: true },
    { name: 'my_husband@gmail.com', selected: true },
    { name: 'sport and travel', selected: true },
    { name: 'my_boss@gmail.com', selected: true },
  ],
}

const ConnectButton = ({ changeStep, setCalendar, provider, setAppleLoginModal }) => {
  const classes = useStyles()
  const getConnectButton = () => {
    switch (provider) {
      case 'google':
        return <GoogleCalendarConnectButton changeStep={changeStep} setCalendar={setCalendar} provider={provider} />
      case 'microsoft':
        return <OutlookConnectButton changeStep={changeStep} setCalendar={setCalendar} provider={provider} />
      case 'apple':
        return (
          <AppleConnectButton
            changeStep={changeStep}
            setCalendar={setCalendar}
            provider={provider}
            setAppleLoginModal={setAppleLoginModal}
          />
        )
      default:
        return (
          <Button
            variant="secondary"
            onClick={() => {
              changeStep()
              setCalendar({ ...calendarSettingsMock, provider })
            }}
          >
            <Icon style={classes.icon} iconClass="las la-plug" />
            connect
          </Button>
        )
    }
  }
  return getConnectButton()
}

export default ConnectButton
