import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Icon, WorkingHoursList, AdvancedSection } from 'components'
import { colors } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAdvancedCalendarValues, getWorkingDays, saveWorkingDays } from '../../store/actions/calendar'
import { checkForDaySelect, validateHours } from '../../utils/calendar/working-hours'
import { getAdvancedCalendarValues, openSnackbar } from '../../store/actions/common'
import CalendarAdvanced from '../account-calendar/calendarAdvanced/CalendarAdvanced'
import AdvancedSchedule from '../account-calendar/calendarAdvanced/AdvancedSchedule'
import EventBuffer from '../account-calendar/calendarAdvanced/EventBuffer'
import TimezoneSetup from '../account-calendar/calendarAdvanced/TimezoneSetup'
import { ColorCircularProgress } from '../../components'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'

const useStyles = makeStyles({
  step: {
    paddingLeft: 32,
    display: 'flex',
    flexDirection: 'column',
  },
  workingHoursContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0',
    width: 700,
  },
  advancedContainer: {
    marginTop: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  validationMessage: {
    color: colors.ERROR,
    margin: '10px 0 20px 0',
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    marginBottom: 10,
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: 15,
  },
})

const AdvancedCalendarSettingsStep = ({ isValidation, resetValidation, submitProfileData }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(null)
  const weekWorkingHours = useSelector(state => state.calendar.workingDays)
  const workingHoursAreLoading = useSelector(state => state.calendar.isWorkingDaysLoading)
  const [isLoading, setIsLoading] = useState(false)
  const [weekData, setWeekData] = useState(weekWorkingHours)
  const [validationShown, setValidationShown] = useState(false)

  useEffect(() => {
    if (isValidation) handleAdvancedSettingsSubmit()
  }, [isValidation])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getWorkingDays())
    Promise.all([dispatch(getAdvancedCalendarValues()), dispatch(getUserAdvancedCalendarValues())])
      .then(() => setIsLoading(false))
      .catch(() => {
        dispatch(openSnackbar('error', 'Error while advanced info loading'))
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    setWeekData(weekWorkingHours)
  }, [weekWorkingHours])

  const handleAdvancedSettingsSubmit = () => {
    resetValidation()
    let isValid = true
    const newWeekData = weekData.map(dayData => {
      const { valid, workingHours } = validateHours(dayData.workingHours)

      if (!valid) {
        isValid = valid
      }

      return {
        ...dayData,
        workingHours: workingHours,
      }
    })

    setWeekData(newWeekData)

    if (!isValid) {
      dispatch(openSnackbar('error', 'Your working hours are incorrect. Please check them'))
      return
    }

    if (!checkForDaySelect(newWeekData)) {
      setValidationShown(true)
      return
    }
    saveWorkingDaysData(newWeekData)
    Analytics.track(EVENTS.PRO_ADVANCED_CAL_SETTINGS_COMPLETED)
  }

  const saveWorkingDaysData = async data => {
    try {
      await dispatch(saveWorkingDays(data))
      submitProfileData()
    } catch (error) {
      dispatch(openSnackbar('error', 'There was some error while saving working hours'))
    }
  }

  return (
    <div className={classes.step}>
      {errorMessage && (
        <Typography variant="subtitle2" className={classes.validationMessage}>
          <Icon iconClass="las las la-exclamation-triangle" />
          {errorMessage}
        </Typography>
      )}
      {workingHoursAreLoading || isLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <Typography variant="h4" classes={{ h4: classes.title }}>
            Set your working hours
          </Typography>
          <Typography variant="body1" classes={{ body1: classes.subtitle }}>
            Select the daily time periods you will be available on your XIRA calendar so clients can book meetings with
            you
          </Typography>
          <div className={classes.row}>
            <div className={classes.workingHoursContainer}>
              <WorkingHoursList
                weekData={weekData}
                setWeekData={setWeekData}
                validationShown={validationShown}
                setValidationShown={setValidationShown}
                saveWorkingDaysData={saveWorkingDaysData}
              />
            </div>
            <div className={classes.advancedContainer}>
              <AdvancedSection
                title="Advance notice"
                subtitle="Choose the minimum scheduling time you require to prevent last minute bookings"
              >
                <AdvancedSchedule
                  handleLoading={loading => {
                    setIsLoading(loading)
                  }}
                />
              </AdvancedSection>
              <AdvancedSection title="Event buffer" subtitle="Set the time gap you need between meetings">
                <EventBuffer
                  handleLoading={loading => {
                    setIsLoading(loading)
                  }}
                />
              </AdvancedSection>

              <AdvancedSection title="My time zone">
                <TimezoneSetup handleLoading={loading => setIsLoading(loading)} />
              </AdvancedSection>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AdvancedCalendarSettingsStep
