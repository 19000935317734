import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { colors } from 'constants/index.js'

const useStyles = makeStyles({
  root: {
    color: colors.WHITE,
  },
  secondary: {
    color: colors.PRIMARY.COLOR[50],
  },
  label: {
    color: colors.BASIC.COLOR[80],
  },
})

const ButtonPrimaryProgress = ({ circularSize = 40, circularColor = 'root' }) => {
  const classes = useStyles()

  return <CircularProgress className={classes[circularColor]} size={circularSize} />
}
export default ButtonPrimaryProgress
