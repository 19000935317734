import React, { useState, useEffect } from 'react'
import { Icon, Link, Modal, Typography } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { toggleLogin, toggleForgotPassEmail, setRedirectPath } from 'store/actions/common'
import { colors } from 'constants/index'

const { SUCCESS } = colors

const useStyles = makeStyles({
  restorePasswordInfoContainer: {
    margin: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  restorePasswordInfoTitle: {
    marginBottom: 16,
  },
  restorePasswordInfoIcon: {
    color: SUCCESS,
    fontSize: 32,
    marginRight: 8,
  },
  logInLink: {
    marginTop: 40,
    alignSelf: 'flex-end',
  },
})

const RestorePasswordInfoModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const showForgotPassEmail = useSelector(state => state.common.showForgotPassEmail)
  const [open, setOpen] = useState(showForgotPassEmail)
  useEffect(() => {
    setOpen(showForgotPassEmail)
  }, [showForgotPassEmail])

  const closeModal = () => {
    dispatch(toggleForgotPassEmail(false))
    dispatch(setRedirectPath(''))
  }
  const goToLogIn = () => {
    dispatch(toggleForgotPassEmail(false))
    dispatch(toggleLogin(true))
  }
  return (
    <Modal open={open} handleClose={closeModal} scroll="body">
      <div>
        <div className={classes.restorePasswordInfoContainer}>
          <Typography className={classes.restorePasswordInfoTitle} variant="h4">
            <Icon style={classes.restorePasswordInfoIcon} iconClass="las la-check-circle" />
            Thank You
          </Typography>
          <Typography variant="subtitle1">
            We just emailed you the password reset instructions.
            <br />
            Didn’t get the email? Please check your spam folder or validate the correct email address was submitted.
          </Typography>
          <Link className={classes.logInLink} onClick={goToLogIn}>
            Back to Log in
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default RestorePasswordInfoModal
