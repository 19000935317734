import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { VaultTable, VaultFolderTableRow, ActionRow, ErrorModal } from 'components'
import { ReactComponent as SeeDetailsLogo } from 'static/vault/folder/actions/see_details_action.svg'
import { ReactComponent as DownloadLogo } from 'static/vault/folder/actions/download_action.svg'
import { ReactComponent as DeleteLogo } from 'static/vault/folder/actions/delete_action.svg'
import { ReactComponent as EditLogo } from 'static/vault/folder/actions/edit_action.svg'
import { ReactComponent as MoveLogo } from 'static/vault/folder/actions/move_action.svg'
import { ReactComponent as OpenLogo } from 'static/vault/folder/actions/open_action.svg'
import { ReactComponent as PrintLogo } from 'static/vault/folder/actions/print_action.svg'
import { ReactComponent as CopyLogo } from 'static/vault/folder/actions/copy_action.svg'
import { colors } from 'constants/index'
import {
  setVaultSelectedFolders,
  downloadVaultFile,
  setVaultItemsAction,
  setVaultSelectedItems,
  setVaultPreviewItem,
  setVaultSingleActionItem,
  downloadToZipFile,
  setVaultLoggingEventPrint,
} from 'store/actions/vault'
import { checkFileNameUnique, handleFilePrint, isPrintAvailable } from 'utils/vault/vault-helpers'
import { getIsMoveActionAvailable, getIsPrivateFolderSelected } from 'store/selectors/vaultSelector'
import { MESSAGES } from 'constants/index'

const useStyles = makeStyles({
  logo: {
    marginRight: 8,
  },
  folderDragContainer: {
    backgroundColor: colors.ADDITIONAL.COLOR['5'],
    width: 'fit-content',
    height: 40,
    padding: '9px 17px',
    cursor: 'move',
  },
  shadowTwo: {
    boxShadow: `2px 2px ${colors.ADDITIONAL.COLOR['20']}`,
  },
  shadowMore: {
    boxShadow: `2px 2px ${colors.ADDITIONAL.COLOR['20']}, 4px 4px ${colors.ADDITIONAL.COLOR['30']}`,
  },
  badgeColor: {
    backgroundColor: colors.ADDITIONAL.COLOR['50'],
    color: colors.WHITE,
  },
  tableRow: {
    '&.MuiTableRow-hover:hover': {
      backgroundColor: colors.ADDITIONAL.TRANSPARENT['8'],
      color: colors.ADDITIONAL.COLOR['50'],
      '& a': {
        color: colors.ADDITIONAL.COLOR['50'],
      },
    },
  },
})

const columns = [
  {
    value: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    value: 'type',
    label: 'Type',
    sortable: false,
  },
  {
    value: 'size',
    label: 'Size',
    sortable: false,
  },
  {
    value: 'last_uploaded',
    label: 'Last Uploaded',
    sortable: true,
  },
]

const FolderDragPreview = ({ selectedRows }) => {
  const classes = useStyles()
  return (
    <Badge badgeContent={selectedRows.length} color="primary" classes={{ colorPrimary: classes.badgeColor }}>
      <div
        className={classNames(
          classes.folderDragContainer,
          { [classes.shadowTwo]: selectedRows && selectedRows.length === 2 },
          { [classes.shadowMore]: selectedRows && selectedRows.length > 2 }
        )}
      >
        {`${selectedRows[0] && selectedRows[0].name}...`}
      </div>
    </Badge>
  )
}

const FolderVaultTable = ({ data, selectedItems, selectedFolders, setViewItem, handleMove, disableSelection }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const isClientVault = useSelector(state => state.vault.isClientVault)
  const [renameItem, setRenameItem] = useState(null)
  const [isError, setError] = React.useState(false)

  const handleSelectAll = (e, data) => {
    if (disableSelection) return
    if (e.target.checked) {
      dispatch(setVaultSelectedItems(data))
      return
    }
    dispatch(setVaultSelectedItems([]))
  }

  const handleBulkSelection = selection => {
    if (!selectedItems.length) return
    const startIndex = data.findIndex(item => item.uuid === selectedItems[selectedItems.length - 1].uuid)
    const endIndex = data.findIndex(row => row.uuid === selection.uuid)
    const filteredSelection =
      startIndex < endIndex
        ? data.slice(startIndex, endIndex + 1)
        : data.slice(endIndex, startIndex + 1).filter(select => !selectedItems.find(row => row.uuid === select.uuid))
    dispatch(setVaultSelectedItems([...selectedItems, ...filteredSelection]))
  }

  const handleItemAction = (data, action) => {
    dispatch(setVaultSingleActionItem([data]))
    dispatch(setVaultItemsAction(action))
  }

  const getRowActions = rowData => {
    if (selectedItems.indexOf(rowData.name) !== -1) return []
    const print = isPrintAvailable(rowData)
      ? [
          {
            key: 'print',
            label: <ActionRow classes={classes} title={'print'} logo={<PrintLogo className={classes.logo} />} />,
            action: async data => {
              try {
                await handleFilePrint(data)
                dispatch(setVaultLoggingEventPrint(data.uuid))
              } catch (e) {
                setError(true)
              }
            },
          },
        ]
      : []
    const copy = [
      {
        key: 'copy',
        label: <ActionRow classes={classes} title={'copy'} logo={<CopyLogo className={classes.logo} />} />,
        action: data => handleItemAction(data, 'copy'),
      },
    ]
    const folderCommon = [
      {
        key: 'open_folder',
        label: <ActionRow classes={classes} title={'open'} logo={<OpenLogo className={classes.logo} />} />,
        action: data => dispatch(setVaultSelectedFolders([...selectedFolders, data])),
      },
      {
        key: 'download',
        disabled: !rowData.fileCount,
        hint: rowData.fileCount ? '' : MESSAGES.M0094,
        label: (
          <ActionRow
            disabled={!rowData.fileCount}
            classes={classes}
            title={'download'}
            logo={<DownloadLogo className={classes.logo} />}
          />
        ),
        action: data => dispatch(downloadToZipFile([data])),
      },
    ]
    const fileCommon = [
      {
        key: 'see_details',
        label: <ActionRow classes={classes} title={'see details'} logo={<SeeDetailsLogo className={classes.logo} />} />,
        action: data => dispatch(setVaultPreviewItem(data)),
      },
      {
        key: 'download',
        label: <ActionRow classes={classes} title={'download'} logo={<DownloadLogo className={classes.logo} />} />,
        action: data => dispatch(downloadVaultFile(data)),
      },
      ...print,
    ]
    if (isClientVault) {
      return rowData.isFolder ? folderCommon : fileCommon
    } else {
      const proFolderCommon = [
        {
          key: 'rename',
          label: <ActionRow classes={classes} title={'rename'} logo={<EditLogo className={classes.logo} />} />,
          action: data => setRenameItem(data),
        },
        {
          key: 'move',
          label: <ActionRow classes={classes} title={'move'} logo={<MoveLogo className={classes.logo} />} />,

          action: data => handleItemAction(data, 'move'),
        },
        {
          key: 'delete',
          label: <ActionRow classes={classes} title={'delete'} logo={<DeleteLogo className={classes.logo} />} />,
          action: data => handleItemAction(data, 'delete'),
        },
        ...copy,
      ]
      return rowData.isFolder ? [...folderCommon, ...proFolderCommon] : [...fileCommon, ...proFolderCommon]
    }
  }

  const handleRowClick = (e, rowData) => {
    if (disableSelection) return
    if (e.shiftKey) {
      handleBulkSelection(rowData)
    } else {
      const isSelected = selectedItems.find(row => row.uuid === rowData.uuid)
      handleCheckboxClick(!isSelected, rowData)
    }
  }

  const handleCheckboxClick = (selected, data) => {
    if (disableSelection) return
    if (selected) {
      if (!selectedItems.find(row => row.uuid === data.uuid)) dispatch(setVaultSelectedItems([...selectedItems, data]))
    } else dispatch(setVaultSelectedItems(selectedItems.filter(row => row.uuid !== data.uuid)))
  }

  return (
    <>
      <VaultTable
        columns={columns}
        tableRowClasses={classes.tableRow}
        selectable
        draggable={!isClientVault}
        dragPreviewComponent={<FolderDragPreview selectedRows={selectedItems} />}
        selectedRows={selectedItems}
        onSelectAllClick={handleSelectAll}
        data={data}
        getRowActions={getRowActions}
        onRowClick={handleRowClick}
        handleDrop={handleMove}
        disableSelection={disableSelection}
        tableRow={
          <VaultFolderTableRow
            handleCheckboxClick={handleCheckboxClick}
            handleBulkSelection={handleBulkSelection}
            setViewItem={setViewItem}
            timezone={timezone}
            selectedRows={selectedItems}
            selectedFolders={selectedFolders}
            renameItem={renameItem}
            setRenameItem={setRenameItem}
            disableSelection={disableSelection}
            nameCheck={name => checkFileNameUnique(data, name)}
          />
        }
      />
      <ErrorModal
        isOpen={isError}
        onClose={() => setError(false)}
        title={"Can't perform print action on this file"}
        message={'Please download file to print or try in another browser'}
        cancelationButtonText="ok"
      />
    </>
  )
}

export default FolderVaultTable
