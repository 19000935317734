import React from 'react'
import { ConfirmationModal } from 'components'
import { useSelector } from 'react-redux'

const getConfig = (items, trash) => {
  const defaultConfig = {
    title: '',
    message: '',
  }
  if (!items.length) {
    return defaultConfig
  }
  if (trash) {
    if (items.length === 1) {
      const item = items[0]
      if (!item) {
        return defaultConfig
      }
      return {
        title: item.isFolder ? 'Delete subfolder?' : 'Delete file?',
        message: item.isFolder
          ? `If you delete ${item.name}, it and all their files will be permanently deleted from the system. Continue?`
          : `${item.name} will be permanently deleted from the system. Continue?`,
      }
    } else {
      return {
        title: 'Permanently delete files?',
        message: `${items.length} items will be gone forever and you won’t be able to restore them. Continue?`,
      }
    }
  } else {
    if (items.length === 1) {
      const item = items[0]
      if (!item) {
        return defaultConfig
      }
      return {
        title: item.isFolder ? 'Delete subfolder?' : 'Delete file?',
        message: item.isFolder
          ? `If you delete ${item.name}, it and all their files will be moved to the trash folder. Continue?`
          : `If you delete ${item.name}, it will be moved to the trash folder. Continue?`,
      }
    } else {
      return {
        title: 'Delete items?',
        message: `If you delete selected ${items.length} items, it will be moved to the trash folder. Continue?`,
      }
    }
  }
}

const VaultDeleteItemsModal = ({ deleteItems, onClose, isOpen, onConfirm, trash = false }) => {
  const modalConfiguration = getConfig(deleteItems, trash)
  const isLoadingDeleteItem = useSelector(state => state.vault.isLoadingDeleteItem)

  return (
    <ConfirmationModal
      title={modalConfiguration.title}
      message={modalConfiguration.message}
      confirmationButtonText="yes, delete"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => onConfirm(deleteItems)}
      disableButton={isLoadingDeleteItem}
    />
  )
}

export default VaultDeleteItemsModal
