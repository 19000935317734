import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChatModal, ColorCircularProgress, EventsCalendar, EventsFullView, Popover, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getWorkingDaysFlat } from 'store/actions/calendar'
import moment from 'moment-timezone'
import { getJWTLocally } from 'utils/auth'
import { getEventsSocketsPath } from 'utils/path-helpers/api'
import { colors } from 'constants/colors'
import { getProCalendarEvents } from 'store/actions/events'
import { getCalendarTab, saveCalendarTab } from 'store/actions/common'
import { setChatWindowVisibility } from 'store/actions/chat/chat'
import CalendarToolbar from 'components/common/eventsCalendar/CalendarToolbar'
import { WebsocketService } from 'utils/service/WebsocketService'
import { useQuery } from 'utils/hooks'
import { getUserCurrentNotification, markNotificationAsRead } from 'store/actions/notifications/notifications'

const useClasses = makeStyles({
  root: {
    height: '100%',
    padding: 41,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  shroud: {
    backgroundColor: colors.WHITE,
    position: 'absolute',
    top: 90,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 50,
  },
  position: {
    top: '50vh',
    left: '50%',
  },
  headerContainer: {
    width: '100%',
    padding: '16px 0 7px 24px',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
  },
  popoverHeader: {
    width: '354px !important',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  popoverContainer: {
    padding: 0,
  },
  eventsWrapper: {
    flex: 1,
  },
})

const currentTimezone = moment.tz.guess()

const PopoverHeader = ({ clientInfo }) => {
  const classes = useClasses()
  return (
    <div className={classes.headerContainer}>
      <Typography className={classes.headerTitle} variant="h5">
        {clientInfo ? `${clientInfo.firstName} ${clientInfo.lastName}` : ''}
      </Typography>
    </div>
  )
}

const EventsContainer = () => {
  const classes = useClasses()
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone) || currentTimezone
  const businessHours = useSelector(state => state.calendar.workingDaysFlat)
  const currentTab = useSelector(state => state.common.calendarTab)
  const [selectedMeetingData, setSelectedMeetingData] = useState('')
  const [anchorPosition, setAnchorPosition] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const open = Boolean(anchorPosition)
  const calendar = useRef()
  const query = useQuery()
  const adminMessageId = query.get('admin_message_id')

  useEffect(() => {
    dispatch(getCalendarTab())
    if (adminMessageId) {
      dispatch(getUserCurrentNotification(adminMessageId))
    }
  }, [])

  useEffect(() => {
    dispatch(getWorkingDaysFlat())
    const token = getJWTLocally()
    const subject = new WebsocketService(getEventsSocketsPath(token))
    subject.subscribe(msg => {
      if (msg.type === 'user_events') {
        calendar.current.calendar.refetchEvents()
      }
    })
    return () => {
      subject.unsubscribe()
    }
  }, [])

  const onSelect = useCallback((data, targetCoordinates) => {
    if (data.is_external_event) {
      return
    }
    dispatch(setChatWindowVisibility(false))
    setSelectedMeetingData(data)
    setAnchorPosition(targetCoordinates)
  }, [])

  const handleClose = () => {
    setAnchorPosition(null)
  }

  const loadEvents = useCallback(
    (startTime, endTime) => {
      return dispatch(getProCalendarEvents(startTime, endTime))
    },
    [dispatch]
  )

  const setCurrentTab = useCallback(
    tab => {
      dispatch(saveCalendarTab(tab))
    },
    [dispatch]
  )

  return (
    <>
      <div className={classes.root}>
        <CalendarToolbar
          timezone={timezone}
          setCurrentView={setCurrentTab}
          currentView={currentTab}
          calendarRef={calendar}
        />
        <div className={classes.eventsWrapper}>
          <EventsCalendar
            calendarRef={calendar}
            onEventSelect={onSelect}
            getEvents={loadEvents}
            timezone={timezone}
            businessHours={businessHours}
            setIsLoading={setIsLoading}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {isLoading && (
            <div className={classes.shroud}>
              <ColorCircularProgress positionClass={classes.position} />
            </div>
          )}
        </div>
      </div>
      <Popover
        open={open}
        anchorPosition={anchorPosition}
        handleClose={handleClose}
        anchorReference="anchorPosition"
        headerClasses={classes.popoverHeader}
        containerClasses={classes.popoverContainer}
        header={<PopoverHeader clientInfo={selectedMeetingData.clientInfo} />}
      >
        <EventsFullView
          meetingInfo={selectedMeetingData}
          timezone={timezone}
          handleClose={handleClose}
          calendar={calendar}
        />
      </Popover>
      <ChatModal responsive={false} />
    </>
  )
}

export default EventsContainer
