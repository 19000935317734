import React from 'react'
import Menu from '@material-ui/core/Menu'

const XMenu = ({
  MenuListProps,
  getContentAnchorEl,
  anchorOrigin,
  id,
  anchorEl,
  keepMounted,
  open,
  onClose,
  children,
  ...restProps
}) => (
  <Menu
    MenuListProps={MenuListProps}
    getContentAnchorEl={getContentAnchorEl}
    anchorOrigin={anchorOrigin}
    id={id}
    anchorEl={anchorEl}
    keepMounted={keepMounted}
    open={open}
    onClose={onClose}
    {...restProps}
  >
    {children}
  </Menu>
)

export default XMenu
