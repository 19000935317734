import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Search } from 'components'
import { onApplicationStartSearchOnly } from 'store/actions/auth'

const useStyles = makeStyles({
  searchContent: {
    width: '100%',
    maxWidth: '755px',
    display: 'flex',
    alignItems: 'center',
  },
})

const PortalSearchOnly = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onApplicationStartSearchOnly())
  }, [dispatch])

  return (
    <div className={classes.searchContent}>
      <Search type="startPage" />
    </div>
  )
}

export default PortalSearchOnly
