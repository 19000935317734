import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, Button, SvgIcon } from 'components'
import { colors } from 'constants/index'
import { ReactComponent as IconNotFound } from 'static/404_error.svg'
import { useDispatch } from 'react-redux'
import { navigateToRoot } from 'store/actions/navigation'


const useStyles = makeStyles({
  root: {
    padding: '81px 142px 92px 142px',
    display: 'flex',
    justifyContent: 'space-between',
    width: 1280,
    margin: '0 auto',
  },
  mainMessage: {
    lineHeight: 1.32,
  },
  subMessage: {
    fontSize: 15,
    lineHeight: 1.47,
    color: colors.BASIC.COLOR[100],
    marginTop: 30,
  },
  homeButton: {
    marginTop: 32,
  },
  svgIconRoot: {
    width: 480,
    height: 355,
    fill: 'none',
  },
})

const NotFound = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const goToHomePage = () => dispatch(navigateToRoot())

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.mainMessage} variant="h2">
          Oops,
          <br />
          that page is
          <br />
          gone
        </Typography>
        <Typography className={classes.subMessage} variant="subtitle1">
          The link you clicked may be broken <br />
          or the page may have been removed.
        </Typography>
        <Button onClick={goToHomePage} className={classes.homeButton} variant="primary">
          Go to home page
        </Button>
      </div>
      <SvgIcon classes={{ root: classes.svgIconRoot }} component={IconNotFound} viewBox="0 0 687 538" />
    </div>
  )
}

export default NotFound
