import React from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import CardRow from './CardRow'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  checked: {
    color: colors.PRIMARY.COLOR[50],
  },
  radioBtn: {
    backgroundColor: 'transparent',
  },
  formControlLabel: {
    marginTop: 16,
    width: '100%',
  },
  label: {
    width: '100%',
  },
})

const ClientCardsRadioButtonList = ({ value, userCards, onChange }) => {
  const classes = useStyles()
  return (
    <RadioGroup value={value || ''} onChange={e => onChange(e.target.value)}>
      {userCards.map(card => (
        <FormControlLabel
          classes={{ root: classes.formControlLabel, label: classes.label }}
          value={card.uuid}
          key={card.uuid}
          control={
            <Radio
              checked={value === card.uuid}
              color="default"
              classes={{ checked: classes.checked, root: classes.radioBtn }}
            />
          }
          label={<CardRow cardData={card} key={card.uuid} />}
        />
      ))}
    </RadioGroup>
  )
}

export default ClientCardsRadioButtonList
