import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as BreadcrumbIcon } from 'static/vault/breadcrump.svg'
import { ReactComponent as DeleteIcon } from 'static/vault/header/delete.svg'
import { ReactComponent as RestoreIcon } from 'static/vault/trash/actions/restore_header.svg'
import { Breadcrumbs, Link, Button, Typography } from 'components'
import {
  deleteTrashVaultItem,
  getVaultTrashList,
  setVaultItemsAction,
  restoreTrashVaultItem,
  setVaultSelectedItems,
} from 'store/actions/vault'

const VaultTrashTabHeader = ({ selectedFolders, selectedItems, headerClasses, handleTabChange }) => {
  const dispatch = useDispatch()
  const trashList = useSelector(state => state.vault.trashList)

  const handleTrashAction = async action => {
    if (action === 'restore') {
      await dispatch(restoreTrashVaultItem(selectedItems))
      dispatch(setVaultSelectedItems([]))
    } else if (action === 'mass_delete_trash') {
      dispatch(setVaultItemsAction(action))
    }
  }

  const handleBulkDelete = async () => {
    await dispatch(deleteTrashVaultItem(trashList))
  }

  const onBreadcrumbsClick = () => {
    handleTabChange(0)
  }

  return (
    <>
      <Breadcrumbs separator={<BreadcrumbIcon />}>
        <Link className={headerClasses.vaultHeaderTitle} onClick={onBreadcrumbsClick}>
          {selectedFolders[0] && selectedFolders[0].name}
        </Link>
        <Link className={headerClasses.vaultHeaderTitle}>Deleted files</Link>
      </Breadcrumbs>
      {!!selectedItems.length && !!trashList.length && (
        <div className={headerClasses.btnContainer}>
          <Typography className={headerClasses.selectedItemsLabel}>
            {`${selectedItems.length} ${selectedItems.length > 1 ? 'items' : 'item'}`}
          </Typography>
          <Button
            variant={'secondary'}
            className={headerClasses.buttonClass}
            onClick={() => handleTrashAction('mass_delete_trash')}
          >
            <DeleteIcon className={headerClasses.iconClass} /> delete forever
          </Button>
          <Button
            variant={'primary'}
            className={headerClasses.buttonClass}
            onClick={() => handleTrashAction('restore')}
          >
            <RestoreIcon className={headerClasses.iconClass} /> restore
          </Button>
        </div>
      )}
    </>
  )
}

export default VaultTrashTabHeader
