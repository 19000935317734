import { createSelector } from 'reselect'

export const selectConnectionOptionsWithEmptyState = createSelector(
  state => state.connections.filterConnections,
  connections => [
    {
      value: null,
      label: 'All',
    },
    ...connections,
  ]
)

export const selectConnectionOptions = createSelector(
  state => state.connections.filterConnections,
  connections => connections
)

export const selectTimeActivitiesConnectionOptions = createSelector(
  state => state.connections.filterConnections,
  connections => connections.filter(connection => connection.enableTimeActivities)
)

export const selectExpenseActivitiesConnectionOptions = createSelector(
  state => state.connections.filterConnections,
  connections => connections.filter(connection => connection.enableExpenseActivities)
)
