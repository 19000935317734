import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ProConnectionsTableRow, Table, TablePagination, Typography, Tooltip } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'constants/index'
import {
  setConnectionFilter,
  getProConnections,
  setConnectionsSorting,
  setTableConfiguration,
} from 'store/actions/connections'
import {
  navigateToActivitiesListInNewTab,
  navigateToCreateCaseInNewTab,
  navigateToCaseListInNewTab,
  navigateToInvoicesListInNewTab,
} from 'store/actions/navigation'
import { ReactComponent as InfoLogo } from 'static/connections/info.svg'
import { resendInviteLink } from '../../../store/actions/connections'

const useStyles = makeStyles({
  connectionsTable: {
    marginTop: 20,
  },
  tableHeaderCell: {
    borderBottom: `2px solid ${colors.BASIC.COLOR[10]}`,
  },
  tooltipTable: {
    padding: 16,
    minWidth: 360,
  },
  tooltipIcon: {
    marginRight: 16,
  },
  tooltipText: {
    fontSize: 13,
  },
})

const ClientTableTooltip = () => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <Typography variant="subtitle1" className={classes.tooltipText}>
          Client data cannot be modified as it is entered by them.
        </Typography>
      }
      tooltipClass={classes.tooltipTable}
    >
      <InfoLogo className={classes.tooltipIcon} />
    </Tooltip>
  )
}
const ClientColumnHeaderTable = () => {
  return (
    <div style={{ display: 'flex', fontWeight: 500, color: ' rgba(0, 0, 0, 0.87)', alignItems: 'center' }}>
      <ClientTableTooltip /> Client name
    </div>
  )
}

const columns = [
  {
    value: 'full_name',
    label: <ClientColumnHeaderTable />,
    sortable: true,
  },
  {
    value: 'contacts',
    label: 'Contacts',
    sortable: false,
  },
  {
    value: 'address',
    label: 'Address',
    sortable: false,
  },
  {
    value: 'buttons',
    label: '',
    sortable: false,
  },
]

const getHint = (profileIsApproved, type, isCaseCreationAvailable = true) => {
  let hint
  if (!profileIsApproved) {
    hint = `You can not create ${type} until your license is approved. Please contact XIRA admin for details`
  } else if (!isCaseCreationAvailable && type === 'case') {
    hint = 'You will be able to create a case with the client only after the successful initial meeting end'
  } else {
    hint = ''
  }
  return hint
}

const ProConnectionsTable = ({
  connections,
  count,
  profileIsApproved,
  onEditPopupOpen,
  setActivityModal,
  openConfirmationDeletionClient,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const sorting = useSelector(state => state.connections.sort)
  const { limit, tablePage } = useSelector(state => state.connections.tableConfiguration)

  const getRowActions = rowData => {
    const createCase = {
      key: 'newCase',
      label: 'create case',
      action: data => {
        dispatch(navigateToCreateCaseInNewTab({ clientId: data.client }))
      },
      hint: getHint(profileIsApproved, 'case', rowData.isCaseCreationAvailable),
      disabled: !profileIsApproved || !rowData.isCaseCreationAvailable,
    }
    const seeRelated = [
      {
        key: 'seeActivities',
        label: 'see related activities',
        action: data => {
          dispatch(navigateToActivitiesListInNewTab({ clientId: data.client }))
        },
        disabled: !rowData.hasCases,
      },
      {
        key: 'seeCases',
        label: 'see related cases',
        action: data => {
          dispatch(navigateToCaseListInNewTab({ clientId: data.client }))
        },
        disabled: !rowData.hasCases,
      },
      {
        key: 'seeInvoices',
        label: 'see related invoices',
        action: data => {
          dispatch(navigateToInvoicesListInNewTab({ clientId: data.client }))
        },
        disabled: !rowData.hasCases,
      },
    ]
    const timeEntry = rowData.enableTimeActivities
      ? [
          {
            key: 'newTimeEntry',
            label: 'create time entry',
            action: data => {
              setActivityModal(config => ({
                ...config,
                open: true,
                action: 'create',
                type: 'time',
                activity: { clientId: data.client },
              }))
            },
            hint: getHint(profileIsApproved, 'activity'),
            disabled: !profileIsApproved,
          },
        ]
      : []
    const expenseEntry = rowData.enableExpenseActivities
      ? [
          {
            key: 'newExpenseEntry',
            label: 'create expense entry',
            action: data => {
              setActivityModal(config => ({
                ...config,
                open: true,
                action: 'create',
                type: 'expense',
                activity: { clientId: data.client },
              }))
            },
            hint: getHint(profileIsApproved, 'activity'),
            disabled: !profileIsApproved,
          },
        ]
      : []
    const inviteActions = rowData.invitedUserID
      ? [
          {
            key: 'editInviteInfo',
            label: 'edit client info',
            action: data => {
              onEditPopupOpen(data.clientInfo, data.invitedUserID)
            },
          },
        ]
      : []
    const resendInvite = rowData.isInvited
      ? [
          {
            key: 'resendInvite',
            label: 'resend invitation',
            action: data => dispatch(resendInviteLink(data.client)),
          },
        ]
      : []
    const deleteInvite = rowData.invitedUserID
      ? [
          {
            key: 'deleteAction',
            label: 'delete invitation',
            action: data => {
              openConfirmationDeletionClient(data.invitedUserID, data.hasCases, data.hasActiveCases)
            },
          },
        ]
      : []
    return [
      ...timeEntry,
      ...expenseEntry,
      createCase,
      ...seeRelated,
      ...inviteActions,
      ...resendInvite,
      ...deleteInvite,
    ]
  }

  const onSort = (name, isAcs) => {
    dispatch(setConnectionsSorting(name, isAcs))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProConnections())
  }

  const onRowsPerPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
        limit: value,
      })
    )
    dispatch(setConnectionFilter())
    dispatch(getProConnections())
  }

  const onPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: value,
        offset: value * limit,
      })
    )
    dispatch(setConnectionFilter())
    dispatch(getProConnections())
  }

  return (
    <Table
      tableColumns={columns}
      className={classes.connectionsTable}
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableData={connections}
      getRowActions={getRowActions}
      sorting={sorting}
      onSort={onSort}
      tableDataRowRenderer={(columns, data) => (
        <ProConnectionsTableRow
          columns={columns}
          data={data}
          profileIsApproved={profileIsApproved}
          handleActivityModal={setActivityModal}
        />
      )}
      footer={
        <TablePagination
          colSpan={columns.length}
          page={tablePage}
          count={count}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
        />
      }
      activeRows
    />
  )
}

export default ProConnectionsTable
