import React, { useEffect, useState, useMemo } from 'react'
import {
  MemoryChart,
  Typography,
  Button,
  Tooltip,
  SvgIcon,
  MenuItem,
  Menu,
  VaultPlans,
  VaultDowngradeModal,
  VaultSwitchToPremiumModal,
  ColorCircularProgress,
  VaultFreePlanAlertModal,
} from 'components'
import { NotForFeeModal } from 'components/cases/NotForFeeModal'
import { makeStyles } from '@material-ui/core/styles'
import { colors, routes } from 'constants/index'
import LinearProgress from '@material-ui/core/LinearProgress'
import classNames from 'classnames'
import { getVaultPlans, getVaultPlansRate, updateVaultPlans, getVaultUsage } from 'store/actions/vault'
import { useDispatch, useSelector, batch } from 'react-redux'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import moment from 'moment'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { closeErrorModal } from 'store/actions/common'
import { navigateToVault } from 'store/actions/navigation'

import { ReactComponent as CircleQuestionIcon } from 'static/icon_24_question-mark-circle.svg'
import { ReactComponent as ArrowRightIcon } from 'static/icon_24_chevron-right.svg'
import { ReactComponent as CheckIcon } from 'static/profile/ic_check.svg'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'

const useStyles = makeStyles({
  modal: {
    padding: '70px 258px 137px 258px',
  },
  vaultHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 36,
  },
  titleVault: {
    fontSize: 25,
    fontWeight: 'bold',
    lineHeight: 1.44,
    color: colors.BASIC.COLOR[100],
  },
  planInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  usedMemory: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    marginRight: 24,
  },
  usedSize: {
    marginRight: 5,
    color: colors.PRIMARY.COLOR[50],
  },
  used: {
    marginRight: 5,
  },
  date: {
    fontSize: 16,
    color: colors.BASIC.COLOR[100],
    textAlign: 'center',
    marginBottom: 15,
  },
  size: {
    height: '100%',
    transform: 'rotate(-90deg)',
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
  },
  chart: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  paymentHistory: {
    textAlign: 'right',
  },
  paymentHistoryBtn: {
    '&.MuiButton-endIcon': {
      marginBottom: 2,
    },
  },
  tooltip: {
    maxWidth: 398,
    padding: '16px 16px',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: 13,
  },
  icon: {
    marginTop: 5,
    cursor: 'pointer',
  },
  rateList: {
    listStyleType: 'disc',
    marginLeft: 0,
    paddingLeft: 0,
  },
  rateItem: {
    position: 'relative',
    marginLeft: '1em',
    marginBottom: 10,
    '&:before': {
      position: 'absolute',
      left: '-1em',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  tooltipTitle: {
    fontSize: 13,
    marginBottom: 10,
  },
  tooltipDesc: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: 10,
  },
  memory: {
    fontWeight: 500,
  },
  cost: {
    fontWeight: 400,
  },
  progressLine: {
    marginBottom: 40,
  },
  progress: {
    height: 24,
    borderRadius: 4,
    border: `1px solid ${colors.BASIC.COLOR[20]}`,
  },
  progreesBar1: {
    backgroundColor: colors.ADDITIONAL.COLOR[50],
  },
  progreesBar2: {
    backgroundColor: colors.BASIC.COLOR[5],
  },
  space: {
    display: 'flex',
    marginBottom: 9,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 400,
    color: colors.BASIC.COLOR[100],
  },
  spaceContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      marginRight: 64,
    },
  },
  checkbox: {
    width: 16,
    height: 16,
    marginRight: 12,
    borderRadius: 3,
  },
  checkboxActive: {
    backgroundColor: colors.ADDITIONAL.COLOR[50],
  },
  checkboxUnused: {
    backgroundColor: colors.BASIC.COLOR[5],
    border: `1px solid ${colors.BASIC.COLOR[20]}`,
  },
  button: {
    minHeight: 'auto',
    padding: '10px 16px',
    lineHeight: 1,
    fontSize: 11,
  },
  btnSwitchPlan: {
    backgroundColor: colors.BASIC.COLOR[10],
    color: colors.BASIC.COLOR[70],
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR[20],
    },
  },
  menu: {
    width: 194,
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
    paddingTop: 3,
    paddingBottom: 3,
  },
  statusItem: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.08,
    color: colors.BASIC.COLOR[70],
  },
  activePlan: {
    color: colors.BASIC.COLOR[100],
  },
  arrowIcon: {
    width: 16,
    height: 16,
    marginLeft: 5,
  },
})

const TooltipContent = ({ plansRate }) => {
  const classes = useStyles()
  const rates = plansRate.map(rate => {
    return (
      <li className={classes.rateItem} key={rate.plan}>
        <span className={classes.memory}>{`${rate.fromValue} - ${rate.toValue} GB: `}</span>
        {
          {
            free: <span className={classes.cost}>{`${rate.plan}`}</span>,
            premium: <span className={classes.cost}>{`$${rate.planPrice}`}</span>,
          }[rate.plan]
        }
      </li>
    )
  })
  return (
    <>
      <Typography className={classes.tooltipTitle} variant="subtitle1">
        vault charges are determined by the amount of storage space used for the month.
      </Typography>
      <Typography className={classes.tooltipDesc} variant="subtitle1">
        Rates (cost per month):
      </Typography>
      <ul className={classes.rateList}>
        {rates}
        <li className={classes.rateItem}>
          <span className={classes.memory}>{`> ${plansRate[1].toValue} GB: `}</span>
          <span className={classes.cost}>{`$${plansRate[1].planPrice} plus $${plansRate[1].pricePerGb} per 1 GB`}</span>
        </li>
      </ul>
    </>
  )
}

const usableMemoryToPercent = (usableSpace, allSpace) => (100 * usableSpace) / allSpace

const VaultSettingsContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openSwitchToPremiumModal, setOpenSwitchToPremiumModal] = useState(false)
  const [openSwitchToFreeModal, setOpenSwitchToFreeModal] = useState(false)
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false)

  const plansRate = useSelector(state => state.vault.plansRate)
  const vaultSettings = useSelector(state => state.vault.vaultSettings)
  const isProfileGetting = useSelector(state => state.auth.isProfileGetting)
  const vaultUsageIsloading = useSelector(state => state.vault.vaultUsageIsloading)
  const isVaultPlanAccepted = useSelector(state => state.vault.isVaultPlanAccepted)
  const vaultSettingsIsLoading = useSelector(state => state.vault.vaultSettingsIsLoading)
  const { title: downgradeTile, detail: downgradeMessage, isOpen: openDowngradeModal } = useSelector(
    state => state.common.errorModal
  )

  const currentMonth = moment().format('MMMM')
  const currentYear = moment().format('YYYY')
  const dataIsLoaded = vaultSettingsIsLoading || isProfileGetting || vaultUsageIsloading
  const { overallFreeSpace, usableSpace = 0, unusableSpace = 0, vaultPlan } = vaultSettings

  const usableMemorySpaceToPercent = useMemo(() => usableMemoryToPercent(usableSpace, overallFreeSpace), [
    overallFreeSpace,
    usableSpace,
  ])

  useEffect(() => {
    batch(() => {
      dispatch(getVaultPlans())
      dispatch(getVaultPlansRate())
      dispatch(getVaultUsage())
    })
  }, [])

  useEffect(() => {
    if (location.pathname.includes('update')) {
      setOpenSwitchToPremiumModal(true)
    }
  }, [location])

  const onCloseUpgradeModal = () => setOpenUpgradeModal(false)
  const onOpenUpgradeModal = () => setOpenUpgradeModal(true)

  const onMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const onMenuClose = () => {
    setAnchorEl(null)
  }

  const onOpenSwithToFreePlanModal = () => {
    setOpenSwitchToFreeModal(true)
    onMenuClose()
  }

  const onAcceptFreePlan = () => {
    onMenuClose()
    onUpgradePlanClick('free')
    setOpenSwitchToFreeModal(false)
  }

  const onAcceprPremiumPlan = () => {
    onUpgradePlanClick('premium')
    setOpenSwitchToPremiumModal(false)
  }

  const onUpgradePlanClick = async plan => {
    await dispatch(updateVaultPlans({ vault_plan: plan }))
    dispatch(getVaultPlans())
  }

  const onDowngradeModalClose = () => {
    dispatch(closeErrorModal())
  }

  const onGoToVaultClick = () => {
    dispatch(closeErrorModal())
    dispatch(navigateToVault())
  }

  const UsedPremiumMemory = () => {
    return (
      <>
        <span className={classes.usedSize}>{`${usableSpace} GB`}</span>
        <span className={classes.used}>used</span>
      </>
    )
  }

  const UsedFreeMemory = () => {
    return (
      <>
        <span className={classes.usedSize}>{`${usableSpace} GB`}</span>
        <span className={classes.used}>{`of Free ${overallFreeSpace} GB used`}</span>
      </>
    )
  }

  return (
    <div>
      {dataIsLoaded ? (
        <ColorCircularProgress />
      ) : !isVaultPlanAccepted ? (
        <VaultPlans choiceFromSettings />
      ) : (
        <div className={classes.memorySettings}>
          <div className={classes.vaultHeader}>
            <Typography variant="h1" className={classes.titleVault}>
              Secure vault
            </Typography>
            <div className={classes.planInfo}>
              <div className={classes.usedMemory}>
                {
                  {
                    free: <UsedFreeMemory />,
                    premium: <UsedPremiumMemory />,
                  }[vaultPlan]
                }
                <Tooltip
                  title={<TooltipContent tooltipText={classes.tooltipText} plansRate={plansRate} />}
                  tooltipClass={classes.tooltip}
                  interactive
                >
                  <SvgIcon className={classes.icon} component={CircleQuestionIcon} viewBox="0 0 24 24" />
                </Tooltip>
              </div>
              {
                {
                  free: (
                    <Button variant="primary" classes={{ root: classes.button }} onClick={onOpenUpgradeModal}>
                      upgrade
                    </Button>
                  ),
                  premium: (
                    <>
                      <Button
                        aria-controls="simple-menu"
                        variant="primary"
                        aria-haspopup="true"
                        onClick={onMenuOpen}
                        classes={{ root: classNames(classes.btnSwitchPlan, classes.button) }}
                      >
                        <span>premium</span>
                        <SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.arrowIcon} />
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={onMenuClose}
                        classes={{ list: classes.menu }}
                      >
                        <MenuItem onClick={() => onMenuClose()} className={classes.menuItem}>
                          <ListItemIcon className={classes.menuItem}>
                            <div className={classNames({ [classes.activePlan]: true }, classes.statusItem)}>
                              PREMIUM PLAN
                            </div>
                            <CheckIcon />
                          </ListItemIcon>
                        </MenuItem>
                        <MenuItem onClick={() => onOpenSwithToFreePlanModal()} className={classes.menuItem} disabled>
                          <ListItemIcon className={classes.menuItem}>
                            <div className={classNames({ [classes.activePlan]: false }, classes.statusItem)}>
                              FREE PLAN
                            </div>
                          </ListItemIcon>
                        </MenuItem>
                      </Menu>
                    </>
                  ),
                }[vaultPlan]
              }
            </div>
          </div>
          {vaultPlan === 'free' && (
            <div className={classes.progressLine}>
              <div className={classes.space}>
                <div className={classes.spaceContainer}>
                  <div className={classNames(classes.checkbox, classes.checkboxActive)} />
                  <div className={classes.label}>{`Active space and trash (${usableSpace} GB)`}</div>
                </div>
                <div className={classes.spaceContainer}>
                  <div className={classNames(classes.checkbox, classes.checkboxUnused)} />
                  <div className={classes.label}>{`Unused space (${unusableSpace} GB)`}</div>
                </div>
              </div>
              <LinearProgress
                variant="determinate"
                value={usableMemorySpaceToPercent || 0}
                classes={{ root: classes.progress, bar: classes.progreesBar1, colorPrimary: classes.progreesBar2 }}
              />
            </div>
          )}
          <Typography variant="h5" className={classes.date}>{`usage in ${currentMonth} ${currentYear}`}</Typography>
          <div className={classes.chart}>
            <div className={classes.size}>GB</div>
            <MemoryChart overallFreeSpace={overallFreeSpace} vaultPlan={vaultPlan} />
          </div>
          <div className={classes.paymentHistory}>
            <Button
              variant="text"
              endIcon={<ArrowRightIcon />}
              classes={{ endIcon: classes.paymentHistoryBtn }}
              component={RouterLink}
              to={{
                pathname: `${routes.PRO}${routes.ACCOUNT}${routes.FINANCES}${routes.WALLET}`,
                search: '?vault=7',
              }}
            >
              see payment history
            </Button>
          </div>
        </div>
      )}
      <VaultSwitchToPremiumModal
        isOpen={openSwitchToPremiumModal}
        onAcceptClick={onAcceprPremiumPlan}
        onClose={() => setOpenSwitchToPremiumModal(false)}
        plansRate={plansRate}
      />
      <VaultFreePlanAlertModal
        isOpen={openSwitchToFreeModal}
        onClose={() => setOpenSwitchToFreeModal(false)}
        onAcceptClick={onAcceptFreePlan}
        plansRate={plansRate}
      />
      <VaultDowngradeModal
        isOpen={openDowngradeModal}
        title={downgradeTile}
        message={downgradeMessage}
        onClose={onDowngradeModalClose}
        onConfirm={onGoToVaultClick}
      />
      <NotForFeeModal open={openUpgradeModal} onClose={onCloseUpgradeModal} />
    </div>
  )
}
export default VaultSettingsContainer
