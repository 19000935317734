import React, { useState } from 'react'
import {
  Typography,
  ExpansionPanelProAccount,
  DegreeExpansionPanelSummary,
  DegreeExpansionPanelDetail,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  title: {
    marginBottom: '12px',
  },
  degreesContainer: {
    marginTop: 32,
  },
  degreesPanelContainer: {
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  '@media(max-width:767px)': {
    degreesContainer: {
      borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
      paddingTop: 24,
      marginTop: 15,
    },
  },
})

const DegreesProAccount = ({ degrees }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.degreesContainer}>
      <Typography className={classes.title} variant="h4">
        degrees
      </Typography>
      <div className={classes.degreesPanelContainer}>
        {degrees.map((degree, i) => {
          return (
            <ExpansionPanelProAccount
              onChange={handleChange(i)}
              area={degree}
              expanded={expanded === i}
              summary={<DegreeExpansionPanelSummary degree={degree} />}
              key={degree.school_name}
              details={<DegreeExpansionPanelDetail degree={degree} />}
            />
          )
        })}
      </div>
    </div>
  )
}

export default DegreesProAccount
