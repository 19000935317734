import React, { useState, useMemo } from 'react'
import { Router, Link, useParams } from 'react-router-dom'
import { Modal, Typography, Avatar, MeetingTimeInfo, Button, ErrorModal } from 'components'
import { MobileVerificationModal } from 'components/profileCreation/MobileVerificationModal'
import { createMeeting, toggleMeetingModal } from 'store/actions/meetingScheduler'
import { colors } from 'constants/colors'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { closeErrorModal, goToZendesk, openSnackbar } from 'store/actions/common'
import RestrictionMsg from 'components/events/RestrictionMsg'
import { getUserContactInfo } from '../../../../store/actions/profileCreation'
import LegalTopicSelector from '../../../meetingScheduler/LegalTopicSelector'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { navigateTo } from '../../../../store/actions/navigation'
import history from '../../../../history'

const useStyles = makeStyles({
  modal: {
    width: 566,
    height: 420,
    backgroundColor: colors.WHITE,
    padding: '32px 40px 24px 40px',
  },
  modalShort: {
    maxHeight: '90vh',
    backgroundColor: colors.WHITE,
    padding: '32px 40px 24px 40px',
  },
  title: {
    marginBottom: 32,
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: 8,
    fontSize: '13px',
    lineHeight: '19px',
    color: '#42586E',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 150,
    height: 150,
    marginRight: 20,
  },
  boxBtn: {
    textAlign: 'right',
  },
  closeBtn: {
    marginRight: 24,
  },
  confirmBtn: {
    width: 103,
  },
  link: {
    fontSize: 16,
  },
  consultationCost: {
    marginTop: 8,
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#011A33',
  },
  barLink: {
    color: '#fff',
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
    },
    avatar: {
      width: 50,
      height: 50,
      margin: '0 0 8px 0',
    },
    modal: {
      padding: '200px 15px 50px',
      width: '100%',
      height: 'auto',
    },
    title: {
      textAlign: 'left',
      marginBottom: 15,
    },
    content: {
      marginBottom: 40,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
})

const MeetingModal = ({
  isOpen,
  onClose,
  photo,
  meetingType,
  firstName,
  lastName,
  proId,
  isInitialMeeting,
  practiceAreas,
  contactInfo,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { pro_uuid } = useParams()
  const practiceAreaParam = useSelector(state => state.search.practiceAreaParam)
  const start = useSelector(state => state.meetingScheduler.start)
  const end = useSelector(state => state.meetingScheduler.end)
  const createMeetingLoading = useSelector(state => state.meetingScheduler.createMeetingLoading)
  const { title: titleErrorMOdal, detail: detailErrorModal, isOpen: isOpenErrorModal } = useSelector(
    state => state.common.errorModal
  )

  const scheme = Yup.object({
    topic: isInitialMeeting
      ? Yup.string()
          .trim()
          .required('Please fill in the field')
      : false,
  })

  const initialValues = { topic: practiceAreaParam }

  const options = useMemo(
    () =>
      practiceAreas
        .map(area =>
          area.selected_sub_specialities.map(e => ({
            value: e.name,
            name: e.name,
            label: e.name,
            practiceArea: area.name,
          }))
        )
        .flat(),
    [practiceAreas]
  )

  const [selectedTopic, setSelectedTopic] = useState('')

  const onSubmit = ({ topic }) => {
    if (!contactInfo.phone) {
      dispatch(
        openSnackbar(
          'error',
          <Router history={history}>
            Please add your contact info in your{' '}
            <Link to="/client/account/profile" className={classes.barLink}>
              profile
            </Link>{' '}
            first
          </Router>
        )
      )
      return
    }
    if (contactInfo.is_phone_confirmed) {
      onAfterConfirmPhone(topic)
    } else {
      setSelectedTopic(isInitialMeeting ? topic : 'followup-verification')
    }
  }
  const onAfterConfirmPhone = topic => {
    const selectedPracticeArea =
      practiceAreas.find(area => area.selected_practice_area.name === topic) ||
      practiceAreas.find(area => area.user_sub_specialities.some(sub => sub.selected_sub_speciality.name === topic))

    if (selectedPracticeArea?.consultation_cost && isInitialMeeting) {
      dispatch(toggleMeetingModal(false))
      dispatch(navigateTo(`/client/schedule/${pro_uuid}?start=${start}&end=${end}&area=${topic}`))
      return
    }

    const selectedSubPracticeArea =
      selectedPracticeArea &&
      selectedPracticeArea.user_sub_specialities.find(sub => sub.selected_sub_speciality.name === topic)
    let data = {
      pro: proId,
      start_time: start,
      end_time: end,
      is_initial: isInitialMeeting,
      client_timezone: moment.tz.guess(),
    }
    if (isInitialMeeting) {
      data = {
        practice_area: selectedPracticeArea ? selectedPracticeArea.selected_practice_area.uuid : '',
        sub_speciality: selectedSubPracticeArea ? selectedSubPracticeArea.selected_sub_speciality.uuid : '',
        ...data,
      }
    }
    dispatch(createMeeting(data))
    dispatch(getUserContactInfo())
  }

  const onCloseVerification = () => {
    setSelectedTopic('')
  }

  const onErrorModalClose = () => {
    dispatch(closeErrorModal())
    onClose()
  }

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  return (
    <>
      <Modal
        open={isOpen && !isOpenErrorModal && !selectedTopic}
        handleClose={onClose}
        maxWidth={false}
        dialogClasses={{ paper: classes.paper }}
      >
        <Formik initialValues={initialValues} validationSchema={scheme} onSubmit={onSubmit} enableReinitialize>
          {props => (
            <Form>
              <div className={isInitialMeeting ? classes.modal : classes.modalShort}>
                <Typography className={classes.title} variant="h4">
                  book {meetingType} with {firstName} {lastName}
                </Typography>
                {isInitialMeeting && (
                  <>
                    <Typography className={classes.subtitle}>
                      *Please, before proceed select an area of law or legal topic
                    </Typography>
                    <LegalTopicSelector options={options} />
                  </>
                )}
                <div className={classes.content}>
                  <Avatar className={classes.avatar} src={photo} />
                  <div>
                    <MeetingTimeInfo start={start} end={end} />
                    {isInitialMeeting && (
                      <div className={classes.consultationCost}>
                        $
                        {Number(
                          practiceAreas.find(area => area.selected_practice_area.name === props.values.topic) ||
                            practiceAreas.find(area =>
                              area.user_sub_specialities.some(
                                sub => sub.selected_sub_speciality.name === props.values.topic
                              )
                            )?.consultation_cost ||
                            0
                        ).toFixed(2)}
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.boxBtn}>
                  <Button variant="text" onClick={onClose} className={classes.closeBtn} disabled={createMeetingLoading}>
                    cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    isButtonBlocked={createMeetingLoading}
                    disabled={createMeetingLoading}
                    circularSize={22}
                    className={classes.confirmBtn}
                  >
                    confirm
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onErrorModalClose}
        title={titleErrorMOdal}
        message={<RestrictionMsg goToZendesk={zendeskSso} message={detailErrorModal} />}
        cancelationButtonText="ok"
      />
      <MobileVerificationModal
        open={selectedTopic}
        number={contactInfo.phone}
        onOk={() => onAfterConfirmPhone(selectedTopic)}
        onClose={onCloseVerification}
      />
    </>
  )
}

export default MeetingModal
