import { FormAutocomplete, Switch } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  standardMargin: {
    marginBottom: 16,
  },
  switchBase: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: colors.WHITE,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.PRIMARY.COLOR[50],
      },
    },
    '&.Mui-disabled': {
      color: colors.WHITE,
      '& + .MuiSwitch-track': {
        backgroundColor: colors.PRIMARY.COLOR[50],
        opacity: 0.5,
      },
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selections: {
    flexGrow: 1,
  },
})

const invoicingOptions = Object.values(CONSTANTS.INVOICING)
const invoiceFrequencyOptions = Object.values(CONSTANTS.INVOICE_FREQUENCY)

const getInvoicingHint = invoicingType => {
  return CONSTANTS.INVOICING[invoicingType] ? CONSTANTS.INVOICING[invoicingType].hint : ''
}

const ExpenseInvoicingSelection = props => {
  const classes = useStyles()
  const { caseData, onChange, mode, errors, setErrors = error => {} } = props

  const handleSwitchChange = (e, v) => {
    onChange({ expense_invoicing: v, invoice_frequency: null, invoicing: null })
    setErrors({
      expense_invoicing: '',
      invoice_frequency: '',
      invoicing: '',
    })
  }

  const handleChange = (name, value) => {
    onChange({
      [name]: value,
    })
    setErrors({
      [name]: '',
    })
  }

  return (
    <div className={classes.row}>
      <div>
        <Switch
          label="Expense invoicing"
          checked={caseData.expense_invoicing}
          color="primary"
          onChange={handleSwitchChange}
          disabled={mode !== CONSTANTS.MODE.ADD}
          classes={{
            switchBase: classes.switchBase,
            colorPrimary: classes.colorPrimary,
          }}
        />
      </div>
      <div className={classes.selections}>
        <FormAutocomplete
          label="Invoicing"
          disabled={mode !== CONSTANTS.MODE.ADD || !caseData.expense_invoicing}
          value={caseData.invoicing}
          fullWidth
          containerClass={classes.standardMargin}
          name="invoicing"
          error={errors.invoicing}
          onChange={handleChange}
          options={invoicingOptions}
          placeholder="Please select"
          hint={getInvoicingHint(caseData.invoicing)}
        />
        {caseData.invoicing === CONSTANTS.INVOICING[2].value && (
          <FormAutocomplete
            label="Invoice frequency"
            disabled={mode !== CONSTANTS.MODE.ADD || !caseData.expense_invoicing}
            value={caseData.invoice_frequency}
            fullWidth
            containerClass={classes.standardMargin}
            name="invoice_frequency"
            error={errors.invoice_frequency}
            onChange={handleChange}
            options={invoiceFrequencyOptions}
            placeholder="Please select"
          />
        )}
      </div>
    </div>
  )
}

export default ExpenseInvoicingSelection
