import React from 'react'
import { Page, Document } from '@react-pdf/renderer'
import * as moment from 'moment-timezone'
import { SummaryTableEntry } from './parts'

const InvoiceSummary = ({ invoice, timezone = moment.tz.guess(), summary }) => {
  return (
    <Document>
      <Page>
        <SummaryTableEntry summary={summary} invoice={invoice} timezone={timezone} />
      </Page>
    </Document>
  )
}

export default InvoiceSummary
