import React from 'react'
import { Button, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants/colors'
import classNames from 'classnames'
import { ReactComponent as IconUpload } from 'static/icon_24_upload .svg'

const useStyles = makeStyles({})

const AboutContainer = props => {
  const classes = useStyles()

  return <></>
}

export default AboutContainer
