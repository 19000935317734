import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'components'
import { CONSTANTS, colors } from 'constants/index'
import moment from 'moment'

const useStyles = makeStyles({
  shortInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 125,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10.5,
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: 15,
    marginBottom: 16.5,
  },
  active: {
    color: colors.SUCCESS,
  },
})

const ActivityShortInfo = ({ caseItem }) => {
  const classes = useStyles()
  const feeType = caseItem.fee_type
  return (
    <div className={classes.shortInfoContainer}>
      <div>
        <Typography className={classes.title}>Fee type</Typography>
        <Typography className={classes.subtitle}>{CONSTANTS.FEE_TYPES[feeType].activityLabel}</Typography>
      </div>
      <div>
        <Typography className={classes.title}>Services performed by</Typography>
        <Typography className={classes.subtitle}>{caseItem.pro_name}</Typography>
      </div>
      <div>
        <Typography className={classes.title}>Date of creation</Typography>
        <Typography className={classes.subtitle}>
          {moment(caseItem.date_of_creation).format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
        </Typography>
      </div>
    </div>
  )
}

export default ActivityShortInfo
