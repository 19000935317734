import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SearchByAi } from 'components'
import { useDispatch } from 'react-redux'
import { onApplicationStartSearchOnly } from '../../store/actions/auth'

const useStyles = makeStyles({
    searchContent: {
        width: '100%',
        maxWidth: '755px',
        display: 'flex',
        alignItems: 'center',
    },
})

const PortalSearchByAi = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(onApplicationStartSearchOnly())
    }, [dispatch])

    return (
      <div className={classes.searchContent}>
        <SearchByAi />
      </div>
    )
}

export default PortalSearchByAi
