import React, { useEffect, useState } from 'react'
import { colors } from 'constants/index'
import { CommonSearch, ExportButton, CommonFilterButton, AdditionalActivityFilters } from 'components'
import {
  setActivitiesFilter,
  getProActivities,
  setTableConfiguration,
  getProActivitiesFile,
} from 'store/actions/activities'
import { makeStyles } from '@material-ui/core'
import { useQuery } from 'utils/hooks'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles({
  filters: {
    display: 'flex',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    padding: '5px 15px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  search: {
    marginLeft: 12,
    width: 300,
  },
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
  btnIcon: {
    marginRight: 4,
  },
  activeFilterBtn: {
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  exportBtn: {
    padding: '10px 24px',
    border: 'solid 1px #bbbab9',
    color: colors.BASIC.COLOR[80],
    fill: colors.BASIC.COLOR[80],
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 40,
    minHeight: 40,
    '&:hover': {
      fill: colors.PRIMARY.COLOR[50],
    },
  },
})

const ActivityFilters = ({ activities, count, isInitialFilters }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const caseId = query.get('case')
  const clientId = query.get('connection')
  const filters = useSelector(state => state.activities.filters)
  const activitiesFileAreLoading = useSelector(state => state.activities.activitiesFileAreLoading)
  const [additionalFilter, setAdditionalFilter] = useState(false)
  const [search, setSearch] = useState(filters.search)

  useEffect(() => {
    if (clientId || caseId) {
      setAdditionalFilter(true)
      if (caseId) dispatch(setActivitiesFilter('case', caseId))
      if (clientId) dispatch(setActivitiesFilter('client', clientId))
      dispatch(getProActivities())
    }
  }, [clientId, caseId])

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.filters}>
        <CommonSearch
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          setFilter={value => dispatch(setActivitiesFilter('search', value))}
          setTableConfiguration={() => dispatch(setTableConfiguration({ tablePage: 0, offset: 0 }))}
          getSearchResults={() => dispatch(getProActivities())}
        />
        <CommonFilterButton onClick={() => setAdditionalFilter(show => !show)} showFilters={additionalFilter} range />
        <ExportButton
          variant="secondary"
          className={classes.exportBtn}
          getFileInfo={getProActivitiesFile}
          disabled={!activities.length}
          isFileLoading={activitiesFileAreLoading}
        />
      </div>
      {additionalFilter && (
        <AdditionalActivityFilters filters={filters} results={count} isInitialFilters={isInitialFilters} />
      )}
    </div>
  )
}

export default ActivityFilters
