import React from 'react'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  emptyStateContainer: {
    marginTop: 32,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 'normal',
    lineHeight: 1.47,
  },
})

const TableEmptyState = () => {
  const classes = useStyles()
  return (
    <div className={classes.emptyStateContainer}>
      <Typography className={classes.title}>No results</Typography>
      <Typography className={classes.subtitle}>Try adjusting your search by changing or removing filters</Typography>
    </div>
  )
}

export default TableEmptyState
