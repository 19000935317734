import React from 'react'
import { Button } from 'components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  button: {
    alignSelf: 'flex-end',
    marginTop: 25,
    marginRight: 25,
  },
  doneButton: {
    marginTop: 48,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})
const CalendarStepperFooter = ({
  activeStep,
  availableForConnectionCalendars,
  closeModal,
  onNextClick,
  selectedCalendarList,
  localXiraCalendar,
}) => {
  const classes = useStyles()
  const getStepButtons = step => {
    switch (step) {
      case 0:
        return (
          <div className={classes.btnContainer}>
            <Button className={classes.button} variant="text" onClick={closeModal}>
              cancel
            </Button>
            <Button
              className={classes.button}
              variant="primary"
              disabled={!selectedCalendarList.length}
              onClick={() => onNextClick(0)}
            >
              continue
            </Button>
          </div>
        )
      case 1:
        return (
          <div className={classes.btnContainer}>
            <Button className={classes.button} variant="text" onClick={closeModal}>
              cancel
            </Button>
            <Button
              className={classes.button}
              variant="primary"
              disabled={!localXiraCalendar.id}
              onClick={() => onNextClick(1)}
            >
              complete
            </Button>
          </div>
        )
      case 2:
        return (
          <>
            {!!(availableForConnectionCalendars && availableForConnectionCalendars.length) ? (
              <Button className={classes.button} variant="text" onClick={closeModal}>
                skip for now
              </Button>
            ) : (
              <Button variant="secondary" className={classes.doneButton} onClick={closeModal}>
                done
              </Button>
            )}
          </>
        )
      default:
        return <div />
    }
  }
  return getStepButtons(activeStep)
}

export default CalendarStepperFooter
