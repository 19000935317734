import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closeAdminMessageModal } from 'store/actions/common'
import { Button, Modal, Typography } from 'components'
import { colors } from 'constants/index'
import { navigateToNewWindow, navigateToWindow } from 'store/actions/navigation'

const useStyles = makeStyles({
  modal: {
    minWidth: 100,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 40px',
    maxHeight: 400,
    maxWidth: 400,
  },
  summary: {
    fontWeight: 500,
    color: colors.BASIC.COLOR['100'],
    marginBottom: 24,
    fontSize: 20,
  },
  body: {
    fontWeight: 'normal',
    color: colors.BASIC.COLOR['100'],
    marginBottom: 24,
    fontSize: 16,
  },
})

const AdminMessageModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const showAdminMessageModal = useSelector(state => state.common.showAdminMessageModal)
  const aminMessageModalData = useSelector(state => state.common.aminMessageModalData)
  const isVaultModalOpen = useSelector(state => state.vault.isVaultModalOpen)

  const onButtonPress = () => {
    if (isVaultModalOpen) {
      return dispatch(navigateToNewWindow(aminMessageModalData.link))
    } else {
      return dispatch(navigateToWindow(aminMessageModalData.link))
    }
  }

  return (
    <Modal
      open={showAdminMessageModal}
      handleClose={() => dispatch(closeAdminMessageModal())}
      maxWidth={false}
      dialogClasses={{ paper: classes.paper }}
    >
      <div className={classes.modal}>
        <Typography className={classes.summary}>{aminMessageModalData.summary}</Typography>
        <Typography className={classes.body}>{aminMessageModalData.body}</Typography>
        {aminMessageModalData.link && (
          <Button type="primary" onClick={onButtonPress}>
            {aminMessageModalData.link_name}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default AdminMessageModal
