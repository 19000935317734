import React from 'react'
import { Typography } from 'components'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { getTimezoneLabel } from 'utils/getters/timezone'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  bodySubtitle: {
    fontSize: 18,
    lineHeight: 1.78,
    fontWeight: 'normal',
  },
  timezone: {
    color: colors.BASIC.COLOR[80],
    fontSize: 13,
    fontWeight: 'normal',
    marginTop: 5,
  },
  '@media(max-width:767px)': {
    bodySubtitle: {
      fontSize: 14,
      lineHeight: 1.43,
    },
    timezone: {
      fontSize: 12,
      marginTop: 3,
    },
  },
})

const MeetingTimeInfo = ({ start = 1585747800, end = 1585749600 }) => {
  const classes = useStyles()
  const timezonesList = useSelector(state => state.common.timezonesList)
  const startMoment = moment.unix(start)
  const endMoment = moment.unix(end)
  return (
    <div>
      <Typography className={classes.bodySubtitle}>{startMoment.format(CONSTANTS.DATE_FORMAT.FORMAT_TWO)}</Typography>
      <Typography className={classes.bodySubtitle}>
        {startMoment.format('h:mm')} - {endMoment.format('h:mm a')} · {endMoment.diff(startMoment, 'minutes')} minutes
      </Typography>
      <Typography className={classes.timezone}>{getTimezoneLabel(timezonesList)}</Typography>
    </div>
  )
}

export default MeetingTimeInfo
