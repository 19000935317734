import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { goToZendesk } from 'store/actions/common'
import { ColorCircularProgress } from 'components'

const Zendesk = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(goToZendesk(true))
  }, [goToZendesk])

  return <ColorCircularProgress />
}

export default Zendesk
