import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  AvailableWalletTable,
  ColorCircularProgress,
  NoDataPlaceholder,
  PendingFundsTable,
  RequestFundsErrorModal,
  RequestFundsModal,
  Typography,
  ViewInvoiceModal,
  WalletRevenue,
  WalletTableFilters,
} from 'components'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { useDispatch, useSelector } from 'react-redux'
import {
  getWalletItems,
  getWalletPendingFunds,
  getWalletSummary,
  resetWalletFilter,
  setRequestFundsErrorModalOpen,
  setTableConfiguration,
  getPaymentConnectionStatus,
  setWalletFilter,
} from 'store/actions/wallet/wallet'
import { isEqual } from 'lodash'
import { colors } from 'constants/index'
import NoWalletItems from 'static/wallet@3x.png'
import { useQuery } from 'utils/hooks'
import { TaxInformationModal } from './TaxInformationModal'

const useStyles = makeStyles({
  walletContainer: {
    padding: 30,
  },
  title: {
    marginBottom: 24,
  },
  tables: {
    marginTop: 24,
  },
  tabs: {
    marginTop: 16,
  },
  content: {
    minHeight: 200,
    position: 'relative',
  },
  indicator: {
    backgroundColor: colors.PRIMARY.COLOR[50],
  },
  selectedTab: {
    color: colors.PRIMARY.COLOR[50],
  },
})

const getTable = (tab, setSelectedInvoiceId) => {
  switch (tab) {
    case 0:
      return <AvailableWalletTable setSelectedInvoiceId={setSelectedInvoiceId} />
    case 1:
      return <PendingFundsTable setSelectedInvoiceId={setSelectedInvoiceId} />
    default:
      return null
  }
}

const ProWalletContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const vault = query.get('vault')
  const [selectedTab, setSelectedTab] = useState(0)
  const [openTaxModal, setOpenTaxModal] = useState(false)
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(false)
  const [showRequestFundsDialog, setShowRequestFundsDialog] = useState(false)
  const walletItemsAreLoading = useSelector(state => state.wallet.walletItemsAreLoading)
  const paymentAccountStatus = useSelector(state => state.wallet.paymentAccountStatus)
  const userHasWalletItems = useSelector(state => state.auth.currentUser.has_wallet_actions)
  const hasTaxForm = useSelector(state => state.wallet.hasTaxForm)

  useEffect(() => {
    if (vault) {
      dispatch(setWalletFilter('action', Number(vault)))
    }
  }, [vault])

  useEffect(() => {
    dispatch(getPaymentConnectionStatus())
    dispatch(getWalletItems())
    dispatch(getWalletSummary())
    return () => {
      dispatch(resetWalletFilter())
    }
  }, [])

  const showFundsPopup = () => {
    if (paymentAccountStatus) {
      setShowRequestFundsDialog(true)
    } else {
      dispatch(setRequestFundsErrorModalOpen(true))
    }
  }

  const onRequestFunds = () => {
    if (!hasTaxForm) {
      setOpenTaxModal(true)
      return
    }
    showFundsPopup()
  }

  const onTabChange = tab => {
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    setSelectedTab(tab)
    dispatch(resetWalletFilter())
    if (tab === 0) {
      dispatch(getWalletItems())
    } else if (tab === 1) {
      dispatch(getWalletPendingFunds())
    }
  }

  const onRequestFundsComplete = () => {
    setShowRequestFundsDialog(false)
    dispatch(getWalletSummary())
    if (selectedTab === 0) {
      dispatch(getWalletItems())
    }
  }

  const onCloseTaxModal = () => {
    setOpenTaxModal(false)
  }

  return (
    <div className={classes.walletContainer}>
      <Typography variant="h2" className={classes.title}>
        My wallet
      </Typography>
      {!userHasWalletItems ? (
        <NoDataPlaceholder
          hideButton
          label="This page will show your XIRA account where funds received from your clients
          is shown along charges and is where you can request transfers to your business account"
          imageSrc={NoWalletItems}
        />
      ) : (
        <>
          <WalletRevenue />
          <div className={classes.tables}>
            <WalletTableFilters selectedTab={selectedTab} onRequestFunds={onRequestFunds} />
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => onTabChange(newValue)}
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
            >
              <Tab label="Available" classes={{ selected: classes.selectedTab }} />
              <Tab label="Pending funds" classes={{ selected: classes.selectedTab }} />
            </Tabs>
            <div className={classes.content}>
              {walletItemsAreLoading ? <ColorCircularProgress /> : getTable(selectedTab, setSelectedInvoiceId)}
            </div>
          </div>
        </>
      )}
      <RequestFundsModal
        isOpen={showRequestFundsDialog}
        onClose={() => setShowRequestFundsDialog(false)}
        requestFundsComplete={onRequestFundsComplete}
      />
      <RequestFundsErrorModal />
      <ViewInvoiceModal
        isOpen={Boolean(selectedInvoiceId)}
        onClose={() => setSelectedInvoiceId('')}
        invoiceId={selectedInvoiceId}
      />
      <TaxInformationModal open={openTaxModal} onClose={onCloseTaxModal} onAfterSubmit={showFundsPopup} />
    </div>
  )
}

export default ProWalletContainer
