export const GET_COUNTRIES_BEGIN = 'GET_COUNTRIES_BEGIN'
export const GET_COUNTRIES_END = 'GET_COUNTRIES_END'
export const GET_STATES_START = 'GET_STATES_START'
export const GET_STATES_END = 'GET_STATES_END'
export const ADD_LICENSING = 'ADD_LICENSING'
export const ADD_PRACTICE_AREAS = 'ADD_PRACTICE_AREAS'
export const CHANGE_PROFILE_CONTACT_INFO = 'CHANGE_PROFILE_CONTACT_INFO'
export const GET_PRACTICE_AREAS_START = 'GET_PRACTICE_AREAS_START'
export const SAVE_PRACTICE_AREAS_START = 'SAVE_PRACTICE_AREAS_START'
export const SAVE_PRACTICE_AREAS_END = 'SAVE_PRACTICE_AREAS_END'
export const GET_PRACTICE_AREAS_END = 'GET_PRACTICE_AREAS_END'
export const SAVE_PROFILE_CONTACT_INFO_START = 'SAVE_PROFILE_CONTACT_INFO_START'
export const SAVE_PROFILE_CONTACT_INFO_END = 'SAVE_PROFILE_CONTACT_INFO_END'
export const SAVE_PROFILE_LICENSING_START = 'SAVE_PROFILE_LICENSING_START'
export const SAVE_PROFILE_LICENSING_END = 'SAVE_PROFILE_LICENSING_END'
export const GET_PROFILE_LICENSING_START = 'GET_PROFILE_LICENSING_START'
export const GET_PROFILE_LICENSING_END = 'GET_PROFILE_LICENSING_END'
export const SAVE_PROFILE_PHOTO_START = 'SAVE_PROFILE_PHOTO_START'
export const SAVE_PROFILE_PHOTO_END = 'SAVE_PROFILE_PHOTO_END'
export const SUBMIT_PROFILE_START = 'SUBMIT_PROFILE_START'
export const SUBMIT_PROFILE_END = 'SUBMIT_PROFILE_END'
export const GET_USER_CONTACT_INFO_START = 'GET_USER_CONTACT_INFO_START'
export const GET_USER_CONTACT_INFO = 'GET_USER_CONTACT_INFO'
export const GET_USER_CONTACT_INFO_END = 'GET_USER_CONTACT_INFO_END'
export const GET_USER_PRACTICE_AREA_START = 'GET_USER_PRACTICE_AREA_START'
export const GET_USER_PRACTICE_AREA = 'GET_USER_PRACTICE_AREA'
export const GET_USER_PRACTICE_AREA_END = 'GET_USER_PRACTICE_AREA_END'
export const RESET_PROFILE_CREATION_INFO = 'RESET_PROFILE_CREATION_INFO'
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const IS_CHANGE_PROFILE_DATA_START = 'IS_CHANGE_PROFILE_DATA_START'
export const IS_CHANGE_PROFILE_DATA_END = 'IS_CHANGE_PROFILE_DATA_END'
