import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { saveBio, saveVideoLink, setUserBio } from 'store/actions/accountProfile'
import { Button, ColorCircularProgress, DataLossWarning, Typography } from 'components'
import BioTextArea from './bioTextArea.js'
import { connect } from 'react-redux'
import VideoPreview from './video/videoPreview.js'
import VideoUploadSwitcher from './video/VideouploadSwitcher'
import { colors } from 'constants/index'

const CONTENT_WIDTH = 587

const styles = {
  container: {
    width: CONTENT_WIDTH,
  },
  buttonSaveRoot: {
    width: 79,
    marginLeft: 15,
  },
  profileContentHeader: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  bio: {
    display: 'flex',
    width: '100%',
    marginBottom: 55,
    flexDirection: 'column',
  },
  textArea: {
    width: '100%',
  },
  bioActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: CONTENT_WIDTH,
  },
  subtitle: {
    marginBottom: 16,
  },
}

class Bio extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      videoLink: '',
      option: 'upload',
      videoLinkError: '',
    }
  }

  handleChange = event => {
    this.props.changeBio(event.target.value)
  }

  onCancelClick = () => {
    this.props.changeBio(this.props.dbBio)
    this.setState({
      videoLink: '',
      videoLinkError: '',
    })
  }

  onSaveClick = () => {
    if (this.state.option === 'link') {
      if (!this.validateLink()) {
        return
      }
      this.props.saveVideoLink(this.state.videoLink)
    }

    this.props.saveBio({
      bio: this.props.bio,
    })
  }

  componentWillUnmount() {
    this.props.changeBio(this.props.dbBio)
  }

  onOptionChange(option) {
    this.setState({
      option: option,
      videoLink: '',
    })
  }

  onVideoUrlChange(url) {
    this.setState({
      videoLink: url,
    })
  }

  validateLink() {
    const link = this.state.videoLink
    const pat = /^https?:\/\/www.youtube.com/i
    const isValid = pat.test(link)
    this.setState({
      videoLinkError: isValid ? '' : 'Please provide correct YouTube link',
    })
    return isValid
  }

  render() {
    const { bio, dbBio, classes, profileIsLoading, videoURL } = this.props
    const { option, videoLink, videoLinkError } = this.state
    const isDirty = bio !== dbBio || (option === 'link' && !videoURL && videoLink !== '')

    return profileIsLoading && !isDirty ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.container}>
        <DataLossWarning whenCondition={isDirty} path="/pro/account/profile/bio" />
        <div className={classes.bio}>
          <Typography variant="h4">Bio</Typography>
          <BioTextArea handleChange={this.handleChange} value={bio} className={classes.textArea} />
        </div>
        <div>
          <Typography variant="h4">Video</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Browse your mp4 file or paste a link to the video
          </Typography>
          {videoURL ? (
            <VideoPreview video={videoURL} resetVideoUrl={() => this.onVideoUrlChange('')} />
          ) : (
            <VideoUploadSwitcher
              option={option}
              onOptionChange={option => this.onOptionChange(option)}
              onVideoUrlChange={url => this.onVideoUrlChange(url)}
              videoLink={videoLink}
              videoLinkError={videoLinkError}
            />
          )}
        </div>
        <div className={classes.bioActions}>
          <Button onClick={this.onCancelClick} disabled={!isDirty || profileIsLoading} variant="text">
            Cancel
          </Button>
          <Button
            classes={{
              root: classes.buttonSaveRoot,
            }}
            onClick={this.onSaveClick}
            variant="primary"
            isButtonBlocked={profileIsLoading}
            disabled={!isDirty || profileIsLoading}
            circularSize={22}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ accountProfile }) => ({
  bio: accountProfile.bio,
  dbBio: accountProfile.dbBio,
  profileIsLoading: accountProfile.profileIsLoading,
  videoURL: accountProfile.videoURL,
})

const mapDispatchToProps = dispatch => ({
  changeBio: data => dispatch(setUserBio(data)),
  saveBio: data => dispatch(saveBio(data)),
  saveVideoLink: link => dispatch(saveVideoLink(link)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Bio))
