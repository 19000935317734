import moment from 'moment'
import { CONSTANTS } from 'constants/index'
import { size } from 'lodash'
import axios from 'axios'
import printJS from 'print-js'

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const vaultComparator = {
  last_uploaded: function(file1, file2) {
    if (!file2.lastUploaded) return 1
    if (!file1.lastUploaded) return -1
    if (moment(file1.lastUploaded).isAfter(file2.lastUploaded)) {
      return 1
    }
    if (moment(file1.lastUploaded).isBefore(file2.lastUploaded)) {
      return -1
    }
    return 0
  },
  deleted: function(file1, file2) {
    if (!file2.deletedAt) return 1
    if (!file1.deletedAt) return -1
    if (moment(file1.deletedAt).isAfter(file2.deletedAt)) {
      return 1
    }
    if (moment(file1.deletedAt).isBefore(file2.deletedAt)) {
      return -1
    }
    return 0
  },
  name: function(file1, file2) {
    if (file1.name.toLowerCase() > file2.name.toLowerCase()) {
      return 1
    }
    if (file1.name.toLowerCase() < file2.name.toLowerCase()) {
      return -1
    }
    return 0
  },
}

export const sortVaultFiles = (files, field, isAsc) => {
  if (!files) return []
  const privateFolder = files.find(file => file.isPrivateFolder)
  const generalFolder = files.find(file => file.isGeneralFolder)
  const sortedDirectories = files
    .filter(file => file.type === 'folder' && !file.isPrivateFolder && !file.isGeneralFolder)
    .sort(vaultComparator[field])
  const sortedFiles = files.filter(file => file.type !== 'folder').sort(vaultComparator[field])
  const sortedItems = isAsc
    ? [...sortedDirectories, ...sortedFiles]
    : [...sortedDirectories.reverse(), ...sortedFiles.reverse()]
  return [privateFolder, generalFolder, ...sortedItems].filter(i => !!i)
}

export const getSubFolder = (selectedFolders, items) => {
  let itemsList = items
  selectedFolders.forEach(folder => {
    let selectedFolder = itemsList.find(item => item.uuid === folder.uuid)
    itemsList = selectedFolder ? selectedFolder.children : []
  })
  return itemsList
}

export const getSubFolderByPath = (folder, items) => {
  const actionSelectedFolders = []
  let itemsList = items
  const path = folder.path.split('/').filter(path => path.length)
  path.forEach(folder => {
    let selectedFolder = itemsList.find(item => item.name === folder)
    if (!selectedFolder.isFolder) return
    actionSelectedFolders.push(selectedFolder)
    itemsList = selectedFolder ? selectedFolder.children : []
  })
  return actionSelectedFolders
}

export const getUpdatedSubFolderList = (selectedFolders, updatedItems) => {
  const updatedSelectedFolders = []
  let itemsList = updatedItems
  selectedFolders.forEach(folder => {
    let selectedFolder = itemsList.find(item => item.uuid === folder.uuid)
    updatedSelectedFolders.push(selectedFolder)
    itemsList = selectedFolder ? selectedFolder.children : []
  })
  return updatedSelectedFolders
}

export const checkFileNameUnique = (items, name) => {
  return !items.find(item => item.name === name)
}

export const isMoveActionAvailable = (selectedRows, filesList) => {
  const checkedFolders = selectedRows.filter(row => row.isFolder)
  const foldersPresented = filesList.filter(file => file.isFolder)
  const otherFoldersPresented = foldersPresented.filter(
    file => !checkedFolders.find(checkedFolder => checkedFolder.uuid === file.uuid)
  )
  return !checkedFolders.length ? !foldersPresented.length : !otherFoldersPresented.length
}

const folderTreeCompare = (checkItem, folder) => {
  if (folder.uuid === checkItem.uuid) return true
  else
    return checkItem.children && checkItem.children.length
      ? checkItem.children.every(child => folderTreeCompare(child, folder))
      : false
}

const searchTree = (element, matchingItem) => {
  if (element.uuid === matchingItem.uuid) {
    return element
  } else if (element.children != null) {
    let i
    let result = null
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], matchingItem)
    }
    return result
  }
  return null
}

export const isMoveDestinationAvailable = (moveItems, destination) => {
  return moveItems.every(
    item => !searchTree(item, destination) && !destination.children.find(dest => dest.uuid === item.uuid)
  )
}

const getFolderNumber = (numbersList, number) => {
  if (numbersList.includes(number)) return getFolderNumber(numbersList, number + 1)
  else return number === 1 ? getFolderNumber(numbersList, 2) : number
}

export const getDefaultSubFolderName = filesList => {
  const numbers = []
  const defaultSubFolder = filesList
    .filter(file => file.isFolder)
    .filter(folder => folder.name.includes(CONSTANTS.DEFAULT_SUBFOLDER_NAME))
  defaultSubFolder.forEach(folder => {
    const number = folder.name.match(/\d/)
    number && numbers.push(+number[0])
  })
  const defaultLength = defaultSubFolder.length
  const defaultNumber = getFolderNumber(numbers, defaultSubFolder.length + 1)
  return defaultLength ? `${CONSTANTS.DEFAULT_SUBFOLDER_NAME}(${defaultNumber})` : CONSTANTS.DEFAULT_SUBFOLDER_NAME
}

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {}
  for (let i = 0; i < files.length; i++) {
    const id = size(existingFiles) + i + 1
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files[i],
        progress: 0,
        status: CONSTANTS.FILE_UPLOAD_STATUS.IN_PROGRESS,
      },
    }
  }

  return fileToUpload
}

export const extractKeys = (keys, obj) => {
  if (!keys.length) return obj
  const { [keys.pop()]: omitted, ...rest } = obj
  return extractKeys(keys, rest)
}

export const getFileBlob = async url => {
  const { data } = await axios.get(url, { responseType: 'blob' })
  return data
}

export const getFileSizeGB = size => {
  return Number(size / Math.pow(1024, 3))
}

export const memoryRate = {
  free: {
    ticksValue: [],
    domainValue: 0,
    formatValue: '.2',
  },
  premium: {
    ticksValue: [],
    domainValue: 0,
    formatValue: 'd',
  },
}

const INITIAL_FREE_TICK = 1
const INITIAL_PREMIUM_TICK = 8
const SCALE_FREE_KOEF = 2
const SCALE_PREMIUM_KOEF = 4

const findMaxTick = (maxValue, intialTick, scaleKoef) => {
  let maxTick = intialTick
  while (maxTick < maxValue) {
    maxTick = maxTick * scaleKoef
  }
  return maxTick
}

export const getValuesYAxisFreePlan = overallFreeSpace => {
  const maxValue = findMaxTick(overallFreeSpace, INITIAL_FREE_TICK, SCALE_FREE_KOEF)
  memoryRate.free.ticksValue = [0, maxValue / 2, maxValue]
  memoryRate.free.domainValue = maxValue
}

export const getValuesYAxiPremiumPlan = maxUsedMemoryValue => {
  const maxValue = findMaxTick(maxUsedMemoryValue, INITIAL_PREMIUM_TICK, SCALE_PREMIUM_KOEF)
  memoryRate.premium.ticksValue = [0, maxValue / 4, maxValue / 2, maxValue]
  memoryRate.premium.domainValue = maxValue
}

export const handleFilePrint = async file => {
  if (file.fileType === 'docx') {
    const link = encodeURIComponent(file.link)
    const wopiUrl = `http://nl3-view-wopi.wopi.live.net:808/oh/wopi/files/@/wFileId?wFileId=${link}`
    const printLink = `https://nl3-word-view.officeapps.live.com/wv/WordViewer/request.pdf?WOPISrc=${encodeURIComponent(
      wopiUrl
    )}&type=printpdf&useNamedAction=1`
    sendToPrint(printLink)
  } else if (
    file.fileType === 'pdf' ||
    file.fileType === 'png' ||
    file.fileType === 'jpeg' ||
    file.fileType === 'jpg'
  ) {
    const blobData = await getFileBlob(file.link)
    const link = window.URL.createObjectURL(blobData)
    const type = file.fileType === 'pdf' ? 'pdf' : 'image'
    try {
      printJS(link, type)
    } catch (e) {
      return Promise.reject()
    }
  }
}

export const isPrintAvailable = item => {
  return (
    item.fileType === 'pdf' ||
    item.fileType === 'jpg' ||
    item.fileType === 'jpeg' ||
    item.fileType === 'png' ||
    item.fileType === 'docx'
  )
}

const sendToPrint = file => {
  const objFra = document.createElement('iframe') // Create an IFrame.
  objFra.style.visibility = 'hidden' // Hide the frame.
  document.body.appendChild(objFra)
  objFra.src = file
  objFra.contentWindow.focus() // Set focus.
  objFra.contentWindow.print()
}

export const getFileLinkById = (id, data) => {
  let file = false
  for (const element of data) {
    if (file) break
    if (element.children) {
      file = getFileLinkById(id, element.children)
    } else if (element.uuid === id) {
      file = element
      break
    }
  }
  return file
}
