import React from 'react'
import { ActionButton, SvgIcon, Tooltip } from 'components'
import { ReactComponent as MessageLogo } from 'static/messages/messages.svg'
import { ReactComponent as MessageDisabledLogo } from 'static/messages/messages_disabled.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { openChatModal } from 'store/actions/chat/chat'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  tooltip: {
    padding: 16,
    maxWidth: 330,
    color: colors.BASIC.COLOR[100],
    fontWeight: 'normal',
    fontSize: 13,
  },
  messageIcon: {
    color: colors.PRIMARY.COLOR['50'],
    fontSize: '1rem',
  },
})

const disabledMessagesText = 'You need to be approved by admin to start messaging'

const MessagesButton = ({ disabled, className, chatId, disabledText, place, handleClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(openChatModal(chatId, place))
    if (handleClose) {
      handleClose()
    }
  }

  return (
    <div className={className}>
      {disabled ? (
        <Tooltip tooltipClass={classes.tooltip} title={disabledText || disabledMessagesText}>
          <ActionButton disabled>
            <SvgIcon viewBox="0 0 20 20" className={classes.messageIcon}>
              <MessageDisabledLogo />
            </SvgIcon>
          </ActionButton>
        </Tooltip>
      ) : (
        <ActionButton onClick={onClick}>
          <SvgIcon viewBox="0 0 20 20" className={classes.messageIcon}>
            <MessageLogo />
          </SvgIcon>
        </ActionButton>
      )}
    </div>
  )
}

export default MessagesButton
