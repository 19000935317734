import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, Link, Button, Tooltip, InvoiceStatus } from 'components'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS, routes } from 'constants/index'
import * as moment from 'moment'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles({
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
    fontSize: 14,
  },
  tableCellTextClass: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  payNowBtn: {
    minWidth: 74,
    padding: '8px 12px',
    fontSize: 11,
    minHeight: 28,
  },
  payNowCell: {
    maxWidth: 105,
    textAlign: 'center',
  },
  clientInvoiceRow: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  icon: {
    lineHeight: 0,
  },
})

const ClientInvoicesTableRow = ({ data }) => {
  const classes = useStyles()
  const invoiceDate = moment(data.invoiceDate).format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)

  return (
    <>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          <Link
            className={classes.link}
            component={RouterLink}
            to={`${routes.getClientAccountInvoiceDetailsViewPath()}/${data.uuid}`}
          >
            {data.invoiceNumber}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {invoiceDate}
        </Typography>
      </TableCell>
      <TableCell>{data.proFullName}</TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          $ {data.amountDue}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          $ {data.amountPaid}
        </Typography>
      </TableCell>
      <TableCell classes={{ root: classes.payNowCell }}>
        {data.isPayButtonState === CONSTANTS.PAY_NOW_BUTTON_STATE.SHOWED && (
          <Button
            className={classes.payNowBtn}
            component={RouterLink}
            to={`${routes.getClientInvoicePaymentLinkPath()}/${data.uuid}`}
          >
            pay now
          </Button>
        )}
        {data.isPayButtonState === CONSTANTS.PAY_NOW_BUTTON_STATE.SHOWED_AND_DISABLED && (
          <Tooltip
            title={'Automatic charge will be performed in less than 5 min'}
            tooltipClass={classes.tooltip}
            interactive
          >
            <Button className={classes.payNowBtn} disabled>
              pay now
            </Button>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <InvoiceStatus status={data.statusForDisplay} title={data.failureReason} clientStatus />
      </TableCell>
    </>
  )
}

export default ClientInvoicesTableRow
