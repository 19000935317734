import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, Link, InvoiceStatus } from 'components'
import TableCell from '@material-ui/core/TableCell'
import { colors, CONSTANTS, routes } from 'constants/index'
import * as moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import {
  navigateToProConnectionsInNewTab,
  navigateToCaseDetailsInNewTab,
  navigateToEditCaseInNewTab,
} from 'store/actions/navigation'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles({
  link: {
    color: colors.BLUE_20,
    textDecoration: 'none',
    fontSize: 14,
  },
  tableCellTextClass: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
})

const ProInvoicesTableRow = ({ data, timezone }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const invoiceDate = moment(data.invoiceDate)
    .tz(timezone)
    .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)

  const navigateToClient = () => {
    dispatch(navigateToProConnectionsInNewTab({ clientName: data.clientFullName }))
  }

  const navigateToCase = () => {
    data.caseIsActive && data.caseFeeType !== CONSTANTS.CASE_FEE_TYPES_BY_LABEL.INITIAL
      ? dispatch(navigateToEditCaseInNewTab(data.case))
      : dispatch(navigateToCaseDetailsInNewTab(data.case))
  }

  const clientsName = data.clientInfo.isDeleted ? (
    <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
      {data.clientFullName}
    </Typography>
  ) : (
    <Link onClick={navigateToClient} className={classes.link}>
      {data.clientFullName}
    </Link>
  )

  return (
    <>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          <Link
            className={classes.link}
            component={RouterLink}
            to={`${routes.getProAccountFinancesInvoicesViewPath()}/${data.uuid}`}
          >
            {data.invoiceNumber}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          {invoiceDate}
        </Typography>
      </TableCell>
      <TableCell>{clientsName}</TableCell>
      <TableCell>
        <Link onClick={navigateToCase} className={classes.link}>
          {data.caseName}
        </Link>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          $ {data.amountDue}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Typography variant="body2" classes={{ root: classes.tableCellTextClass }}>
          $ {data.amountPaid}
        </Typography>
      </TableCell>
      <TableCell>
        <InvoiceStatus status={data.status} />
      </TableCell>
    </>
  )
}

export default ProInvoicesTableRow
