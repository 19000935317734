import React, { useMemo } from 'react'
import { Modal, Typography, Button } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  modal: {
    padding: '28px 157px 51px 157px',
    textAlign: 'center',
  },
  title: {
    marginBottom: 42,
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.BASIC.COLOR[100],
  },
  desc: {
    fontSize: 15,
    fontWeight: 400,
    marginBottom: 43,
  },
  btn: {
    minHeight: 'auto',
    height: 'auto',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
  },
  btnPremium: {
    marginRight: 25,
    padding: '12px 40px',
  },
  btnContinue: {
    padding: '13px 24px',
  },
})

const VaultFreePlanAlertModal = ({ isOpen, onClose, onAcceptClick, plansRate }) => {
  const classes = useStyles()
  const overallFreeSpace = useMemo(() => plansRate.find(plan => plan.plan === 'free'), [plansRate])
  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h1" className={classes.title}>
          secure vault — FREE PLAN
        </Typography>
        <Typography variant="subtitle1" className={classes.desc}>
          {`Your clients will not be able to upload documents to your vault that exceed ${overallFreeSpace &&
            overallFreeSpace.toValue}GB`}
        </Typography>
        <div className={classes.controlBtns}>
          <Button variant="secondary" className={classNames(classes.btn, classes.btnPremium)} onClick={onClose}>
            stay on premium
          </Button>
          <Button variant="primary" className={classNames(classes.btn, classes.btnContinue)} onClick={onAcceptClick}>
            accept and continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default VaultFreePlanAlertModal
