import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import moment from 'moment'
import axios from 'axios'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Button,
  Divider,
  FormikAutocomplete,
  FormikDatePicker,
  FormikInput,
  Hint,
  Icon,
  InputLabel,
  Modal,
  SvgIcon,
  TextField,
  Typography,
} from 'components'
import { IconButton, InputAdornment, RadioGroup } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { openSnackbar } from 'store/actions/common'
import { ReactComponent as PasswordShowIcon } from 'static/password.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { getStates } from '../../store/actions/profileCreation'
import { colors } from '../../constants'
import { getWalletSummary } from '../../store/actions/wallet/wallet'

const useStyles = makeStyles({
  paper: {
    padding: '24px 40px',
    width: 832,
  },
  container: {
    position: 'relative',
  },
  title: {
    marginBottom: 16,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      flex: '1',
    },
  },
  marginLeft: {
    marginLeft: 20,
  },
  divider: {
    margin: '16px 0',
  },
  address: {
    marginBottom: 16,
  },
  label: {
    marginBottom: 8,
    display: 'flex',
  },
  labelNumber: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '20px',
    color: '#fff',
    fontWeight: 700,
    fontSize: '12px',
    backgroundColor: '#0080DB',
    borderRadius: '50%',
  },
  labelText: {
    marginLeft: 12,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: '#6F8194',
    fontWeight: 500,
  },
  textareaWrap: {
    marginBottom: 16,
    padding: '10px 12px',
  },
  textarea: {
    fontSize: 12,
    lineHeight: '14px',
    color: '#011A33',
    '& p:not(:first-of-type)': {
      margin: '5px 0 0',
    },
  },
  signature: {
    padding: 12,
    backgroundColor: '#D6EFFC',
    borderRadius: 4,
  },
  datepicker: {
    marginTop: 12,
    marginLeft: 20,
    flex: '170px 0 1 !important',
  },
  taxPayerIdentification: {
    flexDirection: 'row',
  },
  taxClassificationLimited: {
    paddingLeft: 32,
    display: 'flex',
  },
  taxClassificationLimitedInput: {
    width: 110,
  },
  taxClassificationLimitedText: {
    width: 195,
    marginLeft: 8,
    fontSize: 12,
    lineHeight: '14px',
    color: '#6F8194',
    display: 'flex',
    alignItems: 'flex-end',
  },
  ipAddress: {
    position: 'absolute',
    left: 0,
    bottom: 10,
    fontSize: '13px',
    lineHeight: '19px',
    color: '#6F8194',
  },
  footer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: 16,
  },
  inputError: {
    borderColor: colors.ERROR,
  },
  codeInput: {
    height: 40,
    width: '100%',
    padding: '18px 14px',
  },
  password: {
    // '-webkit-text-security': 'disc',
    color: 'transparent',
    caretColor: '#000',
    fontFamily: '"Roboto", "Helvetica", "Arial", monospace',
    letterSpacing: '0.5px',
    '&::-moz-selection': {
      color: 'transparent',
      background: '#ACCEF7',
    },
    '&::selection': {
      color: 'transparent',
      background: '#ACCEF7',
    },
  },
  hint: {
    color: colors.ERROR,
    margin: '5px 0 0',
  },
  icon: {
    color: colors.ERROR,
    marginRight: 8,
  },
  hiddenText: {
    position: 'absolute',
    top: 10,
    left: 14,
    letterSpacing: '0.5px',
    fontSize: 15,
    fontFamily: '"Roboto", "Helvetica", "Arial", monospace',
    lineHeight: 1.44,
    pointerEvents: 'none',
  },
  hiddenTextPass: {
    letterSpacing: '2.1px',
  },
})

const initialValues = {
  name: '',
  business_name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  signature: '',
  date: moment().format('MMM DD, YYYY'),
}
const initialData = {
  federal_tax_type: 0,
  llc_type: '',
  other_federal_tax: '',
  tax_number_type: 0,
  taxPayerIdentificationSocial: '',
  taxPayerIdentificationEIN: '',
}

export const TaxInformationModal = ({ open, onClose, onAfterSubmit }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [ipAddress, setIpAddress] = useState('')
  const [showPasswordSocial, setShowPasswordSocial] = useState(false)
  const [showPasswordEIN, setShowPasswordEIN] = useState(false)
  const states = useSelector(state => state.profileCreation.states)

  const validate = values => {
    const errors = {}
    if (!values.name.length) errors.name = 'Please fill out this field'
    if (!values.address.length) errors.address = 'Please fill out this field'
    if (!values.city.length) errors.city = 'Please fill out this field'
    if (!values.state.length) errors.state = 'Please fill out this field'
    if (!values.zip.length) {
      errors.zip = 'Please fill out this field'
    } else if (!values.zip.match(/^[0-9]+$/g)) {
      errors.zip = 'Only digits available'
    } else if (values.zip.length !== 5) {
      errors.zip = 'Please enter valid ZIP code (5 digits)'
    }
    if (values.federal_tax_type === 5 && !values.llc_type.length) errors.llc_type = 'Please fill out this field'
    if (values.federal_tax_type === 6 && !values.other_federal_tax.length)
      errors.other_federal_tax = 'Please fill out this field'
    if (!values.tax_number_type) {
      if (!values.taxPayerIdentificationSocial.length)
        errors.taxPayerIdentificationSocial = 'Please fill out this field'
      if (!values.taxPayerIdentificationSocial.match(/\d{3}-\d{2}-\d{4}/))
        errors.taxPayerIdentificationSocial = 'Please enter in required format'
    }
    if (values.tax_number_type === 1) {
      if (!values.taxPayerIdentificationEIN.length) errors.taxPayerIdentificationEIN = 'Please fill out this field'
      if (!values.taxPayerIdentificationEIN.match(/\d{2}-\d{7}/))
        errors.taxPayerIdentificationEIN = 'Please enter in required format'
    }
    if (!values.signature.length) errors.signature = 'Please fill out this field'
    setErrors(errors)
    return errors
  }

  const onChangeFormData = (value, field) => {
    if (field === 'federal_tax_type') {
      setFormData(d => ({ ...d, llc_type: '', other_federal_tax: '' }))
    } else if (field === 'tax_number_type') {
      setFormData(d => ({ ...d, taxPayerIdentificationSocial: '', taxPayerIdentificationEIN: '' }))
      setShowPasswordSocial(false)
      setShowPasswordEIN(false)
    } else if (field === 'taxPayerIdentificationSocial') {
      const stringValue = value.replace(/\D/g, '')
      let str = stringValue.slice(0, 3)
      if (value.length > 3) str = `${str}-${stringValue.slice(3, 5)}`
      if (value.length > 6) str = `${str}-${stringValue.slice(5, 9)}`
      setFormData(d => ({ ...d, [field]: str }))
      return
    } else if (field === 'taxPayerIdentificationEIN') {
      const stringValue = value.replace(/\D/g, '')
      let str = stringValue.slice(0, 2)
      if (value.length > 2) str = `${str}-${stringValue.slice(2, 9)}`
      setFormData(d => ({ ...d, [field]: str }))
      return
    } else if (field === 'zip' && value.length > 5) {
      return
    }
    setFormData(d => ({ ...d, [field]: value }))
  }
  const onButtonCancel = () => {
    setFormData(initialData)
    setErrors({})
    setLoading(false)
    setIpAddress('')
    setShowPasswordSocial(false)
    setShowPasswordEIN(false)
    onClose()
  }
  const onButtonPress = values => {
    const { taxPayerIdentificationSocial, taxPayerIdentificationEIN, ...formValues } = formData
    const data = {
      ...values,
      ...formValues,
      taxpayer_number: taxPayerIdentificationSocial || taxPayerIdentificationEIN,
      date: moment(values.date).format('YYYY-MM-DD'),
    }
    if (formValues.federal_tax_type !== 5) {
      delete data.llc_type
    }
    if (formValues.federal_tax_type !== 6) {
      delete data.other_federal_tax
    }

    setLoading(true)
    axios
      .post('/api/v1/users/current/wallet/taxform/', data)
      .then(() => {
        dispatch(openSnackbar('success', 'Tax data has saved.'))
        dispatch(getWalletSummary())
          .then(onAfterSubmit)
          .finally(onButtonCancel)
      })
      .catch(() => {
        setLoading(false)
        dispatch(openSnackbar('error', 'Error while saving tax data.'))
      })
  }
  const onPasswordShowIdentificationSocial = () => setShowPasswordSocial(s => !s)
  const onPasswordShowEIN = () => setShowPasswordEIN(s => !s)

  useEffect(() => {
    if (open) {
      fetch('https://geolocation-db.com/json/')
        .then(r => r.json())
        .then(data => {
          setIpAddress(`IP:${data.IPv4}`)
        })
        .catch(() => {
          setIpAddress('')
        })
      dispatch(getStates())
    }
  }, [dispatch, open])

  return (
    <Modal dialogClasses={{ paper: classes.paper }} open={open} maxWidth="md" handleClose={onButtonCancel}>
      <div className={classes.container}>
        <Formik
          initialValues={initialValues}
          validate={v => validate({ ...v, ...formData })}
          onSubmit={onButtonPress}
          validateOnChange={false}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Typography className={classes.title} variant="h4">
                Please complete your electronic W9 form below as per IRS requirement
              </Typography>
              <NumerableLabel number={1}>tax payer</NumerableLabel>
              <div className={classes.row}>
                <FormikInput
                  variant="outlined"
                  name="name"
                  label="Name (as shown on your income tax return)"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                />
                <FormikInput
                  containerClasses={classes.marginLeft}
                  variant="outlined"
                  name="business_name"
                  label="Business name (if different from left)"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <Divider classes={{ root: classes.divider }} />
              <NumerableLabel number={2}>Address Information</NumerableLabel>
              <FormikInput
                containerClasses={classes.address}
                variant="outlined"
                name="address"
                label="Address"
                type="text"
                inputProps={{ maxLength: 70 }}
              />
              <div className={classes.row}>
                <FormikInput variant="outlined" name="city" label="City" type="text" inputProps={{ maxLength: 50 }} />
                <FormikAutocomplete
                  variant="outlined"
                  size="small"
                  name="state"
                  label="State"
                  type="text"
                  fullWidth
                  containerClasses={classes.marginLeft}
                  options={states}
                  handleChange={e => onChangeFormData(e.target.value, 'state')}
                  filterOptions={(options, { inputValue }) =>
                    states.filter(option => option.label.toLowerCase().startsWith(inputValue.toLowerCase()))
                  }
                />
                <FormikInput
                  containerClasses={classes.marginLeft}
                  variant="outlined"
                  name="zip"
                  label="Zip"
                  type="text"
                />
              </div>
              <Divider classes={{ root: classes.divider }} />
              <div className={classes.row}>
                <div>
                  <NumerableLabel number={3}>Federal tax classification</NumerableLabel>
                  <RadioGroup
                    value={formData.federal_tax_type}
                    onChange={(_e, v) => onChangeFormData(+v, 'federal_tax_type')}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio color="primary" />}
                      label="Individual/sole proprietor or single member LLC"
                    />
                    <FormControlLabel value={1} control={<Radio color="primary" />} label="C Corporation" />
                    <FormControlLabel value={2} control={<Radio color="primary" />} label="S corporation" />
                    <FormControlLabel value={3} control={<Radio color="primary" />} label="Partnership" />
                    <FormControlLabel value={4} control={<Radio color="primary" />} label="Trust/estate" />
                    <FormControlLabel value={5} control={<Radio color="primary" />} label="Limited liability company" />
                    {formData.federal_tax_type === 5 && (
                      <>
                        <div className={classes.taxClassificationLimited}>
                          <div className={classes.taxClassificationLimitedInput}>
                            <InputLabel>Tax classification</InputLabel>
                            <TextField
                              value={formData.llc_type}
                              onChange={e =>
                                ['s', 'c', 'p', 'S', 'C', 'P', ''].includes(e.target.value) &&
                                onChangeFormData(e.target.value.toUpperCase(), 'llc_type')
                              }
                              variant="outlined"
                              type="text"
                              name="llc_type"
                              inputProps={{ maxLength: 1 }}
                              externalError={errors.llc_type}
                            />
                          </div>
                          <div className={classes.taxClassificationLimitedText}>
                            C = Corporation; S = S Corporation; P = Partnership
                          </div>
                        </div>
                        {errors.llc_type && (
                          <div className={classes.taxClassificationLimited}>
                            <Hint classes={{ subtitle2: classes.hint }}>
                              <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                              {errors.llc_type}
                            </Hint>
                          </div>
                        )}
                      </>
                    )}
                    <FormControlLabel value={6} control={<Radio color="primary" />} label="Other" />
                    {formData.federal_tax_type === 6 && (
                      <>
                        <TextField
                          value={formData.other_federal_tax}
                          onChange={e => onChangeFormData(e.target.value, 'other_federal_tax')}
                          variant="outlined"
                          type="text"
                          name="other_federal_tax"
                          placeholder="Paste the classification"
                          externalError={errors.other_federal_tax}
                          inputProps={{ maxLength: 50 }}
                        />
                        {errors.other_federal_tax && (
                          <Hint classes={{ subtitle2: classes.hint }}>
                            <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                            {errors.other_federal_tax}
                          </Hint>
                        )}
                      </>
                    )}
                  </RadioGroup>
                </div>
                <div>
                  <NumerableLabel number={4}>Taxpayer Identification Number</NumerableLabel>
                  <RadioGroup
                    value={formData.tax_number_type}
                    onChange={(_e, v) => onChangeFormData(+v, 'tax_number_type')}
                    className={classes.taxPayerIdentification}
                  >
                    <FormControlLabel value={0} control={<Radio color="primary" />} label="Social security" />
                    <FormControlLabel value={1} control={<Radio color="primary" />} label="EIN" />
                  </RadioGroup>
                  {!formData.tax_number_type ? (
                    <div style={{ position: 'relative' }}>
                      <TextField
                        value={formData.taxPayerIdentificationSocial}
                        onChange={e => onChangeFormData(e.target.value, 'taxPayerIdentificationSocial')}
                        variant="outlined"
                        type="text"
                        name="taxPayerIdentificationSocial"
                        fullWidth
                        inputProps={{
                          maxLength: 11,
                          className: classes.password,
                          autoComplete: 'do-not-autofill',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment disableTypography position="end">
                              <IconButton onClick={onPasswordShowIdentificationSocial}>
                                <SvgIcon viewBox="0 0 24 24">
                                  <PasswordShowIcon />
                                </SvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                          autocomplete: 'off',
                        }}
                        externalError={errors.taxPayerIdentificationSocial}
                        placeholder="000-00-0000"
                      />
                      <div className={cn(classes.hiddenText, { [classes.hiddenTextPass]: !showPasswordSocial })}>
                        {showPasswordSocial
                          ? formData.taxPayerIdentificationSocial
                          : formData.taxPayerIdentificationSocial.replace(/\d/g, '*')}
                      </div>
                      {errors.taxPayerIdentificationSocial && (
                        <Hint classes={{ subtitle2: classes.hint }}>
                          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                          {errors.taxPayerIdentificationSocial}
                        </Hint>
                      )}
                    </div>
                  ) : (
                    <div style={{ position: 'relative' }}>
                      <TextField
                        value={formData.taxPayerIdentificationEIN}
                        onChange={e => onChangeFormData(e.target.value, 'taxPayerIdentificationEIN')}
                        variant="outlined"
                        type="text"
                        name="taxPayerIdentificationEIN"
                        fullWidth
                        inputProps={{
                          maxlength: 10,
                          className: classes.password,
                          autoComplete: 'do-not-autofill',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment disableTypography position="end">
                              <IconButton onClick={onPasswordShowEIN}>
                                <SvgIcon viewBox="0 0 24 24">
                                  <PasswordShowIcon />
                                </SvgIcon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        externalError={errors.taxPayerIdentificationEIN}
                        placeholder="00-0000000"
                      />
                      <div className={cn(classes.hiddenText, { [classes.hiddenTextPass]: !showPasswordEIN })}>
                        {showPasswordEIN
                          ? formData.taxPayerIdentificationEIN
                          : formData.taxPayerIdentificationEIN.replace(/\d/g, '*')}
                      </div>
                      {errors.taxPayerIdentificationEIN && (
                        <Hint classes={{ subtitle2: classes.hint }}>
                          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
                          {errors.taxPayerIdentificationEIN}
                        </Hint>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Divider classes={{ root: classes.divider }} />
              <div className={classes.textareaWrap}>
                <div className={classes.textarea}>
                  <p>
                    By typing my name below, I certify, as to myself or the business entity on whose behalf I provide
                    this information, that:
                  </p>
                  <p>1. The number shown on this form is my correct taxpayer identification number; and</p>
                  <p>
                    2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I
                    have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding
                    as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I
                    am no longer subject to backup withholding; and
                  </p>
                  <p>3. I am a U.S. citizen or other U.S. person (defined below).</p>
                </div>
              </div>
              <div className={classes.row}>
                <FormikInput
                  containerClasses={classes.signature}
                  variant="outlined"
                  name="signature"
                  label="Signature"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                />
                <FormikDatePicker
                  containerClass={classes.datepicker}
                  disabled
                  name="date"
                  label="Date"
                  value={moment().format('MMM DD, YYYY')}
                />
              </div>
              <div className={classes.footer}>
                <Button className={classes.cancelButton} onClick={onButtonCancel} variant="text">
                  cancel
                </Button>
                <Button circularSize={22} isButtonBlocked={loading} disabled={loading} variant="primary" type="submit">
                  submit
                </Button>
              </div>
              <div className={classes.ipAddress}>{ipAddress}</div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

const NumerableLabel = ({ number, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.label}>
      <div className={classes.labelNumber}>{number}</div>
      <div className={classes.labelText}>{children}</div>
    </div>
  )
}
