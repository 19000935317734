import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, FormikInput, Link, Modal, PasswordChecker, Typography } from 'components'
import { navigateToLogin, navigateToRoot } from 'store/actions/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { resendResetPasswordEmail, resetPassword } from 'store/actions/auth'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'utils/hooks'
import { Link as RouterLink } from 'react-router-dom'
import { routes } from 'constants/index'
import { setUsernameForResendPassword } from 'store/actions/common'
import PasswordUpdatedModal from './PasswordUpdatedModal'
import { navigateToPasswordChange } from '../../store/actions/navigation'

const useStyles = makeStyles({
  modal: {
    '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
      '@media (max-width:665px)': {
        width: '100%',
        maxWidth: 'none',
      },
    },
    '@media (max-width:665px)': {
      margin: 0,
    },
  },
  updatePassword: {
    width: '100%',
  },
  chooseNewPasswordContainer: {
    margin: '50px 100px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:665px)': {
      margin: '50px auto',
      padding: '0 15px',
    },
  },
  chooseNewPasswordTitle: {
    alignSelf: 'center',
    marginBottom: 24,
  },
  logInLink: {
    marginTop: 40,
    alignSelf: 'flex-end',
  },
  btn: {
    '@media (max-width:665px)': {
      width: '100%',
    },
  },
  closeBtnModal: {
    display: 'none',
  },
})

const initialValues = {
  password: '',
  confirmPassword: '',
}

const validationSchema = Yup.object({
  password: Yup.string()
    .required('please type your password')
    .max(50, 'Password should be maximum 50 characters')
    .test('regex', 'please enter a valid password', val => {
      const regExp = new RegExp('^(?=.*\\d)(?=.*[@!#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$')
      return regExp.test(val)
    })
    .test('regex', 'please avoid using spaces', val => {
      return !/\s/g.test(val)
    }),
  confirmPassword: Yup.string()
    .required('please type your password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const ChooseNewPassword = ({ location, history, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const [isOpen, setIsOpen] = useState(true)
  const [hash, setHash] = useState('')
  const resendLinkUsername = useSelector(state => state.common.resendLinkUsername)
  const [openPswdUpdateStateModal, setOpenPswdUpdateStateModal] = useState({
    isOpen: false,
    state: '',
    close: null,
    submit: null,
  })
  const username = query.get('username')

  useEffect(() => {
    const hash = query.get('hash')
    setHash(hash)
    dispatch(setUsernameForResendPassword(username))
    dispatch(navigateToPasswordChange())
  }, [])

  const closeModal = () => {
    dispatch(navigateToRoot())
  }

  const onFailSubmit = () => {
    dispatch(resendResetPasswordEmail(resendLinkUsername))
  }
  const closePswdUpdateStateModal = () => {
    setOpenPswdUpdateStateModal(prevState => ({
      ...prevState,
      isOpen: false,
    }))
  }
  const onSubmitForm = values => {
    dispatch(
      resetPassword({
        hash,
        password: values.password,
      })
    )
      .then(res => {
        setOpenPswdUpdateStateModal({
          isOpen: true,
          state: 'success',
          close: closePswdUpdateStateModal,
          submit: () => dispatch(navigateToLogin()),
        })
      })
      .catch(e => {
        setOpenPswdUpdateStateModal({
          isOpen: true,
          state: 'fail',
          close: closePswdUpdateStateModal,
          submit: onFailSubmit,
        })
      })
    setIsOpen(false)
  }
  return (
    <>
      <Modal
        open={isOpen}
        scroll="body"
        dialogClasses={{ paper: classes.modal }}
        closeButtonClass={classes.closeBtnModal}
      >
        <div className={classes.updatePassword}>
          <div className={classes.chooseNewPasswordContainer}>
            <Typography className={classes.chooseNewPasswordTitle} variant="h4">
              Choose a new password
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={values => onSubmitForm(values)}
            >
              {props => (
                <Form>
                  <FormikInput variant="outlined" name="password" label="Choose Password" type="password" />
                  {(props.values.password || props.touched.password) && (
                    <PasswordChecker password={props.values.password} />
                  )}
                  <FormikInput variant="outlined" name="confirmPassword" label="Confirm Password" type="password" />
                  <Button variant="primary" size="large" type="submit" classes={{ containedSizeLarge: classes.btn }}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
            <Link className={classes.logInLink} component={RouterLink} to={routes.SIGN_IN}>
              Back to Log in
            </Link>
          </div>
        </div>
      </Modal>
      <PasswordUpdatedModal modalSettings={openPswdUpdateStateModal} />
    </>
  )
}

export default ChooseNewPassword
