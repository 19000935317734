import * as CONSTANTS from 'store/constants/calendar'
import moment from 'moment-timezone'

const initialState = {
  availableCalendarList: ['microsoft', 'google', 'apple'],
  connectedCalendars: [],
  readyForConnectCalendars: [],
  xiraCalendar: {},
  localXiraCalendar: {},
  advanced_calendar: {
    timezone: moment.tz.guess(),
    advanced_schedule: null,
    buffer: null,
  },
  isCalendarListLoading: false,
  isCalendarSubListLoading: { loading: false, provider: null },
  isRefreshingConnectedCalendars: false,
  isCalendarConnecting: false,
  isICloudCalendarConnecting: false,
  isWorkingDaysLoading: false,
  workingDays: [],
  workingDaysFlat: [],
  calendarsConnectionError: false,
}

const calendar = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.SET_CALENDAR:
      return {
        ...state,
        xiraCalendar: action.payload,
        localXiraCalendar: action.payload,
      }
    case CONSTANTS.SET_LOCAL_CALENDAR:
      return {
        ...state,
        localXiraCalendar: action.payload,
      }
    case CONSTANTS.SET_CONNECTED_CALENDAR_LIST:
      return {
        ...state,
        connectedCalendars: action.payload,
      }
    case CONSTANTS.SET_USER_ADVANCED_CALENDAR:
      return {
        ...state,
        advanced_calendar: {
          ...state.advanced_calendar,
          ...action.payload,
        },
      }
    case CONSTANTS.GET_CALENDAR_LIST_START:
      return {
        ...state,
        isCalendarListLoading: true,
      }
    case CONSTANTS.GET_CALENDAR_LIST_END:
      return {
        ...state,
        isCalendarListLoading: false,
      }
    case CONSTANTS.GET_CALENDAR_CONNECTION_START:
      return {
        ...state,
        isCalendarConnecting: true,
      }
    case CONSTANTS.GET_ICLOUD_CALENDAR_CONNECTION_START:
      return {
        ...state,
        isICloudCalendarConnecting: true,
      }
    case CONSTANTS.GET_CALENDAR_CONNECTION_END:
      return {
        ...state,
        isCalendarConnecting: false,
        isICloudCalendarConnecting: false,
      }
    case CONSTANTS.GET_CALENDAR_SUBLIST_START:
      return {
        ...state,
        isCalendarSubListLoading: { loading: true, provider: action.payload },
      }
    case CONSTANTS.GET_CALENDAR_SUBLIST_END:
      return {
        ...state,
        isCalendarSubListLoading: { loading: false, provider: null },
      }
    case CONSTANTS.RESET_XIRA_CALENDAR:
      return {
        ...state,
        xiraCalendar: {},
        localXiraCalendar: {},
      }
    case CONSTANTS.GET_WORKING_DAYS_START:
      return {
        ...state,
        isWorkingDaysLoading: true,
      }
    case CONSTANTS.GET_WORKING_DAYS_END:
      return {
        ...state,
        isWorkingDaysLoading: false,
        workingDays: action.payload,
      }
    case CONSTANTS.SAVE_WORKING_DAYS_START:
      return {
        ...state,
      }
    case CONSTANTS.SAVE_WORKING_DAYS_END:
      return {
        ...state,
        workingDays: action.payload,
      }
    case CONSTANTS.SET_USER_TIMEZONE:
      return {
        ...state,
        advanced_calendar: {
          ...state.advanced_calendar,
          timezone: action.payload,
        },
      }
    case CONSTANTS.SET_READY_FOR_CONNECT_CALENDARS:
      return {
        ...state,
        readyForConnectCalendars: action.payload,
      }
    case CONSTANTS.GET_WORKING_DAYS_FLAT_END:
      return {
        ...state,
        workingDaysFlat: action.payload,
      }
    case CONSTANTS.REFRESH_CALENDAR_CONNECTIONS_START:
      return {
        ...state,
        isRefreshingConnectedCalendars: true,
      }
    case CONSTANTS.REFRESH_CALENDAR_CONNECTIONS_END:
      return {
        ...state,
        isRefreshingConnectedCalendars: false,
      }
    case CONSTANTS.SET_HAS_CALENDAR_PROBLEMS:
      return {
        ...state,
        calendarsConnectionError: action.payload,
      }
    default:
      return state
  }
}

export default calendar
