import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Hint, Icon, InputLabel, Modal, SvgIcon, TextField, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { ReactComponent as PaymentIcon } from 'static/icon_24_ credit-card.svg'
import { useDispatch, useSelector } from 'react-redux'
import { requestFunds } from 'store/actions/wallet/wallet'
import {formatDecimal} from "utils/getters/common";

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    marginLeft: 12,
    fontSize: '20px',
  },
  boxBtn: {
    textAlign: 'right',
  },
  icon: {
    marginRight: 4,
  },
  closeBtn: {
    marginRight: 24,
  },
  doneBtn: {
    width: 97,
  },
  input: {
    marginBottom: 12,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '648px !important',
    },
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: colors.WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
  },
  paymentIcon: {
    height: 24,
    width: 24,
  },
  hint: {
    color: colors.ERROR,
    marginBottom: 12,
  },
})

const RequestFundsModal = props => {
  const classes = useStyles()
  const { isOpen, onClose, requestFundsComplete } = props
  const dispatch = useDispatch()
  const { available_funds } = useSelector(state => state.wallet.walletSummary)
  const requestingFunds = useSelector(state => state.wallet.requestingFunds)
  const [amount, setAmount] = useState('')
  const [error, setError] = useState('')
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setError('')
    setAmount('')
    setChecked(false)
  }, [isOpen])

  const onSubmit = async () => {
    if (!amount) {
      setError('Please fill out this field')
      return
    } else if (Number(amount) > available_funds) {
      setError('Please enter no more than the amount of available funds')
      return
    }

    try {
      await dispatch(requestFunds(Number(amount)))
      requestFundsComplete()
    } catch (e) {
      console.error(e)
    }
  }

  const onInputChange = e => {
    setError('')
    setAmount(e.target.value)
  }

  return (
    <>
      <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
        <div className={classes.modal}>
          <div className={classes.header}>
            <SvgIcon viewBox="0 0 18 18" className={classes.paymentIcon}>
              <PaymentIcon />
            </SvgIcon>
            <Typography variant="h4" className={classes.title}>
              Request funds
            </Typography>
          </div>
          <InputLabel>Enter amount, $</InputLabel>
          <TextField
            value={amount}
            onChange={onInputChange}
            onBlur={e => setAmount(formatDecimal(e.target.value))}
            fullWidth
            variant="outlined"
            className={classes.input}
            inputProps={{ style: { textAlign: 'right' } }}
            type="number"
          />
          {error && (
            <Hint classes={{ subtitle2: classes.hint }}>
              <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
              {error}
            </Hint>
          )}
          <Checkbox
            checked={checked}
            label={
              'Funds that I am requesting to withdraw either do not require a client retainer agreement or such agreement has been executed and is in the file.'
            }
            onClick={e => setChecked(e.target.checked)}
          />
          <div className={classes.boxBtn}>
            <Button variant="text" onClick={onClose} className={classes.closeBtn}>
              cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              className={classes.doneBtn}
              disabled={!checked || requestingFunds}
              isButtonBlocked={requestingFunds}
              circularSize={22}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RequestFundsModal
