import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { Tooltip } from '../../index'
import { colors } from 'constants/colors'

const StyledSlider = withStyles({
  rail: {
    height: 4,
    borderRadius: 1,
    backgroundColor: colors.PRIMARY.COLOR[10],
  },
  track: {
    height: 4,
    borderRadius: 1,
    backgroundColor: colors.PRIMARY.COLOR[50],
  },
  thumb: {
    width: 20,
    height: 20,
    border: `4px solid ${colors.WHITE}`,
    backgroundColor: colors.PRIMARY.COLOR[50],
    marginTop: -8,
    marginLeft: -5,
    boxShadow: '0 1px 2px 0 rgba(87, 87, 87, 0.35)',
  },
  markLabel: {
    whiteSpace: 'normal',
    color: colors.BASIC.COLOR[70],
    fontSize: 11,
  },
  mark: {
    height: 4,
    opacity: -0.5,
  },
  markActive: {
    height: 4,
    backgroundColor: colors.PRIMARY.COLOR[50],
  },
  root: {
    color: colors.WHITE,
  },
})(Slider)

const XSlider = ({
  min = 0,
  max,
  value,
  onChange,
  onChangeCommitted,
  marks,
  valueLabelFormat,
  valueLabelDisplay,
  step,
  ValueLabelComponent,
}) => {
  return (
    <StyledSlider
      ValueLabelComponent={ValueLabelComponent}
      marks={marks}
      valueLabelFormat={valueLabelFormat}
      max={max}
      step={step}
      min={min}
      value={value}
      onChange={onChange}
      valueLabelDisplay={valueLabelDisplay}
      onChangeCommitted={onChangeCommitted}
      aria-labelledby="range-slider"
    />
  )
}

export default XSlider
