import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Icon, Typography, Select, TextField } from 'components'
import { colors } from 'constants/colors'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'

const useStyles = makeStyles({
  icon: {
    fontSize: 25,
  },
  chip: {
    margin: 5,
    borderRadius: 4,
    backgroundColor: colors.BASIC.COLOR[5],
    border: `1px solid ${colors.BASIC.COLOR[20]}`,
    color: colors.BASIC.COLOR[70],
  },
  chipContainer: {
    marginTop: 30,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipLabel: {
    paddingRight: 5,
  },
  deleteIcon: {
    marginRight: 5,
  },
  title: {
    marginBottom: 8,
  },
  subtitle: {
    marginBottom: 24,
  },
  menu: {
    maxHeight: 200,
  },
  autocomplete: {
    height: 'fit-content',
    '&[class*="MuiOutlinedInput-root"]': {
      padding: 3,
    },
  },
})

const LanguageSection = props => {
  const { languagesDB, containerClass, updateLocalUserLanguages } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleChange = (e, value) => {
    const updatedLanguages = languagesDB.map(item =>
      value.find(lang => lang.value === item.uuid)
        ? {
            ...item,
            checked: true,
          }
        : item
    )
    updateLocalUserLanguages(updatedLanguages)
  }

  const onChipClick = uuid => {
    const updatedLanguages = languagesDB.map(item =>
      item.uuid === uuid
        ? {
            ...item,
            checked: false,
          }
        : item
    )
    updateLocalUserLanguages(updatedLanguages)
  }
  const getOptions = () => {
    return languagesDB.filter(lang => !lang.checked).map(lang => ({ label: lang.name, value: lang.uuid }))
  }
  const getDefaultLanguage = () => {
    return languagesDB.filter(lang => lang.name === 'English').map(lang => ({ label: lang.name, value: lang.uuid }))
  }
  const getValue = () => {
    return languagesDB.filter(lang => lang.checked).map(lang => ({ label: lang.name, value: lang.uuid }))
  }

  return (
    <div className={containerClass}>
      <Typography variant="h4" className={classes.title}>
        spoken languages
      </Typography>
      <Autocomplete
        multiple
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        value={getValue()}
        options={getOptions()}
        filterSelectedOptions
        disableCloseOnSelect
        defaultValue={getDefaultLanguage()}
        getOptionLabel={option => option.label}
        onChange={handleChange}
        classes={{ inputRoot: classes.autocomplete }}
        renderTags={(value, getTagProps) => (
          <div className={classes.chips}>
            {value.map(v => (
              <Chip
                key={v.value}
                label={v.label}
                classes={{ label: classNames({ [classes.chipLabel]: v.label !== 'English' }) }}
                onDelete={v.label !== 'English' ? () => onChipClick(v.value) : null}
                deleteIcon={<Icon iconClass="las la-times" style={classes.deleteIcon} />}
                onMouseDown={event => event.stopPropagation()}
                className={classes.chip}
              />
            ))}
          </div>
        )}
        renderInput={params => <TextField {...params} fullWidth variant="outlined" />}
      />
    </div>
  )
}

export default LanguageSection
