import { StyleSheet, Text, View } from '@react-pdf/renderer'
import * as moment from 'moment-timezone'
import React from 'react'
import { CONSTANTS } from 'constants/index'
import { getInvoiceActivityDescription } from 'utils/getters/invoices'

const styles = StyleSheet.create({
  table: {
    marginTop: 8,
    display: 'table',
    width: '100%',
  },
  titleTable: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
  },
  tableRow: {
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#e6eaec',
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 1,
  },
  partialTableCol: {
    width: '15%',
  },
  partialDescriptionTableCol: {
    width: '70%',
  },
  tableHeaderCell: {
    marginTop: 8,
    fontSize: 9,
    height: 24,
  },
  tableCell: {
    marginTop: 8,
    fontSize: 10,
    minHeight: 24,
  },
  tableCellAmount: {
    marginTop: 8,
    fontSize: 10,
    minHeight: 24,
    fontFamily: 'Helvetica-Bold',
  },
  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  subtotalTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subtotalAmount: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
  },
  partialAmountContainer: {
    marginTop: 32,
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  info: {
    fontSize: 10,
  },
  smDivider: {
    height: 1,
    backgroundColor: '#e6eaec',
    marginBottom: 5,
  },
  amountTableCell: {
    textAlign: 'right',
  },
})

const PartialAmountTable = ({ amount, caseAmount, paidAmount, activities, timezone }) => {
  return (
    <>
      <Text style={styles.titleTable}>PARTIAL AMOUNT</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.partialTableCol}>
            <Text style={styles.tableHeaderCell}>Date</Text>
          </View>
          <View style={styles.partialDescriptionTableCol}>
            <Text style={styles.tableHeaderCell}>Description</Text>
          </View>
          <View style={[styles.partialTableCol, styles.amountTableCell]}>
            <Text style={styles.tableHeaderCell}>Amount</Text>
          </View>
        </View>
        {activities.map(activity => (
          <View style={styles.tableRow} wrap={false}>
            <View style={styles.partialTableCol}>
              <Text style={styles.tableCell}>
                {moment(activity.date_of_creation)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
              </Text>
            </View>
            <View style={styles.partialDescriptionTableCol}>
              <Text style={styles.tableCell}>{getInvoiceActivityDescription(activity.description)}</Text>
            </View>
            <View style={[styles.partialTableCol, styles.amountTableCell]}>
              <Text style={styles.tableCellAmount}>{`$${activity.client_charge}`}</Text>
            </View>
          </View>
        ))}
        <View style={styles.subtotal}>
          <Text style={styles.subtotalTitle}>Total</Text>
          <Text style={styles.subtotalAmount}>{`$${amount}`}</Text>
        </View>
      </View>
      <View style={styles.partialAmountContainer}>
        <View style={styles.infoSection}>
          <Text style={styles.info}>Total agreed flat fee</Text>
          <Text style={styles.info}>{`$${caseAmount}`}</Text>
        </View>
        <View style={styles.smDivider} />
        <View style={styles.infoSection}>
          <Text style={styles.info}>Paid to date</Text>
          <Text style={styles.info}>{`$${Number(paidAmount).toFixed(2)}`}</Text>
        </View>
      </View>
    </>
  )
}

export default PartialAmountTable
