import React, { useEffect, useState } from 'react'
import { Autocomplete, DateRangePickerComponent, TableResetFilters } from 'components'
import {
  getProInvoices,
  setTableConfiguration,
  resetInvoicesFilter,
  setInvoicesFilter,
  resetInvoicesSorting,
} from 'store/actions/invoices'
import { colors, CONSTANTS } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

const invoiceStatusOptions = [
  ...Object.values(CONSTANTS.INVOICE_TYPE_BY_CODE),
  {
    value: null,
    label: 'All statuses',
  },
]

const useStyles = makeStyles({
  additionalFilter: {
    display: 'flex',
    marginTop: 30,
    marginBottom: 15,
    justifyContent: 'flex-end',
  },
  lgFilter: {
    marginLeft: 12,
    width: 290,
  },
  mdFilter: {
    marginLeft: 12,
    width: 250,
  },
  dateRangeFilter: {
    marginLeft: 12,
  },
  rangeInput: {
    width: 250,
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})

const InvoicesAdditionalFilters = ({ filters, results, isInitialFilters }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const cases = useSelector(state => state.cases.filterCases)
  const connections = useSelector(state => state.connections.filterConnections)
  const [casesFilterOptions, setCasesFilterOptions] = useState([])
  const [clientFilterOptions, setClientsFilterOptions] = useState([])
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  useEffect(() => {
    const newOptions = [
      {
        value: null,
        label: 'All cases',
      },
      ...cases.map(caseItem => ({ label: caseItem.name, value: caseItem.uuid })),
    ]
    setCasesFilterOptions(newOptions)
  }, [cases])

  useEffect(() => {
    const newOptions = [
      {
        value: null,
        label: 'All clients',
      },
      ...connections,
    ]
    setClientsFilterOptions(newOptions)
  }, [connections])

  const onFilterChange = (name, value) => {
    dispatch(setInvoicesFilter(name, value))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProInvoices())
  }

  const clearFilters = () => {
    dispatch(resetInvoicesFilter())
    dispatch(resetInvoicesSorting())
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProInvoices())
  }
  return (
    <>
      <div className={classes.additionalFilter}>
        <Autocomplete
          fullWidth
          placeholder="Client"
          label="Client"
          variant="outlined"
          className={classes.lgFilter}
          value={filters.client}
          onChange={(e, v) => onFilterChange('client', v ? v.value : null)}
          options={clientFilterOptions}
        />
        <Autocomplete
          fullWidth
          placeholder=" All Cases"
          label="Cases"
          variant="outlined"
          className={classes.lgFilter}
          value={filters.case}
          onChange={(e, v) => onFilterChange('case', v ? v.value : null)}
          options={casesFilterOptions}
        />
        <Autocomplete
          fullWidth
          placeholder="Invoice status"
          label="Invoice status"
          options={invoiceStatusOptions}
          variant="outlined"
          disableClearable
          className={classes.mdFilter}
          value={filters.invoiceStatus}
          onChange={(e, v) => onFilterChange('invoiceStatus', v ? v.value : null)}
        />
        <DateRangePickerComponent
          containerClasses={classes.dateRangeFilter}
          range={filters.issuedRange}
          inputClasses={classes.rangeInput}
          updateRange={value => onFilterChange('issuedRange', value)}
          label="Date issued"
          timezone={timezone}
        />
      </div>
      <TableResetFilters
        count={results}
        onClearFilters={clearFilters}
        containerClass={classes.resultContainer}
        showClearFilters={!isInitialFilters}
      />
    </>
  )
}

export default InvoicesAdditionalFilters
