import React, { useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Text, Typography, Rating, TextArea, Button } from 'components'
import { colors } from 'constants/index'

const useClasses = makeStyles({
  ratingWrapper: {
    marginTop: 51,
  },
  ratingStars: {
    display: 'flex',
    marginTop: 8,
  },
  ratingContainerClasses: {
    display: 'flex',
    alignSelf: 'flex-end',
    marginTop: 0,
  },
  ratingContainerText: {
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    lineHeight: 1.46,
    marginLeft: 8,
  },
  ratingText: {
    fontSize: 15,
    lineHeight: 1.6,
    marginTop: 10,
  },
  ratingNAText: {
    color: colors.BASIC.COLOR[80],
    marginTop: 8,
  },
  areaWrapperClass: {
    width: 588,
    marginTop: 16,
  },
  requestRatingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 'fit-content',
  },
  sendRequestBtn: {
    marginTop: 16,
    maxWidth: 140,
  },
  underReviewText: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 15,
    marginTop: 8,
  },
  socialMediaPageHeader: {
    fontSize: 20,
    lineHeight: 1.4,
  },
})

const XiraRating = ({ ratingValue, reviews, ratingStatus, sendRatingRequest }) => {
  const classes = useClasses()
  const [ratingRequest, setXiraRating] = useState('')

  const handleRatingRequest = e => {
    setXiraRating(e.target.value)
  }

  const getRatingBody = () => {
    if (ratingValue)
      return (
        <div className={classes.ratingStars}>
          <Rating
            size="small"
            value={ratingValue}
            name="socialRating"
            readOnly
            text={`${Number(ratingValue).toFixed(1)} ${reviews ? `(${reviews})` : ''}`}
            ratingClasses={{ root: classes.ratingClassesRoot }}
            ratingContainerClasses={classes.ratingContainerClasses}
            ratingContainerText={classes.ratingContainerText}
            precision={0.1}
          />
        </div>
      )
    if (!ratingStatus)
      return (
        <>
          <Text className={classNames(classes.ratingText, classes.ratingNAText)}>N/A</Text>
          <Text className={classes.ratingText}>
            If you would like to bring over your rating from other sites such as Google Business, Yelp or others please
            provide the links to those pages for XIRA to review. XIRA will use its own algorithm in tabulating an
            initial rating for you.
          </Text>
          <Text className={classes.ratingText}>
            After the initial rating, your rating will be purely based on your performance on XIRA.
          </Text>
          <div className={classes.requestRatingContainer}>
            <TextArea
              value={ratingRequest}
              variant="outlined"
              fullWidth
              placeholder="Please add my rating and reviews from the following website..."
              wrapperClass={classes.areaWrapperClass}
              handleChange={handleRatingRequest}
              characterLimit={500}
            />
            <Button
              variant="primary"
              disabled={!ratingRequest}
              className={classes.sendRequestBtn}
              onClick={() => sendRatingRequest(ratingRequest)}
            >
              Send Request
            </Button>
          </div>
        </>
      )
    if (ratingStatus === 'pending') return <Text className={classes.underReviewText}>under review</Text>
    if (ratingStatus === 'done' && !ratingValue)
      return <Text className={classNames(classes.ratingText, classes.ratingNAText)}>N/A</Text>
  }
  return (
    <div className={classes.ratingWrapper}>
      <Typography className={classes.socialMediaPageHeader} variant="h4">
        XIRA Rating
      </Typography>
      {getRatingBody()}
    </div>
  )
}

export default XiraRating
