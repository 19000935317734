import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Modal, Button, Typography } from 'components'
import { colors } from '../../../constants/colors'

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    marginBottom: '15px',
  },
  deleteIcon: {
    color: colors.ERROR,
    fontSize: 32,
    verticalAlign: 'middle',
    marginRight: 8,
  },
  subtitle: {
    marginBottom: 25,
  },
  boxBtn: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  delButton: {
    marginLeft: 20,
    minWidth: 80,
  },
})

const DeleteModal = ({ isOpen, onClose, deleteTitle, onDelete, deleteText, buttonDisabled }) => {
  const classes = useStyles()
  const CANCEL_BUTTON_TEXT = 'Cancel'
  const CONFIRM_BUTTON_TEXT = 'Yes, delete'

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          <Icon iconClass="las la-exclamation-triangle" style={classes.deleteIcon} />
          {`Delete ${deleteTitle}?`}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {`${deleteText} will be permanently deleted from your profile.`}
        </Typography>
        <div className={classes.boxBtn}>
          <Button variant="text" onClick={onClose}>
            {CANCEL_BUTTON_TEXT}
          </Button>
          <Button variant="primary" className={classes.delButton} onClick={onDelete} disabled={buttonDisabled}>
            {CONFIRM_BUTTON_TEXT}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
