import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'components'
import { useDispatch } from 'react-redux'
import { navigateToProfileStep } from 'store/actions/navigation'
import BackgroundImage from 'static/welcome.jpg'

const useStyles = makeStyles({
  container: {
   width: '100%',
   height: '100%',
   backgroundImage: `url(${BackgroundImage})`,
   backgroundPosition: 'center center',
   backgroundRepeat: 'no-repeat',
   backgroundSize: 'cover',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 24,
  },
  subtitle: {
    fontSize: '20px',
  },
  boxBtn: {
    marginTop: '32px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: '161px 0 0 0',
  },
  contentContainer: {
    maxWidth: 420,
    textAlign: 'center',
  },
  btn: {
    width: 240,
    letterSpacing: 1,
    fontSize: 15,
    lineHeight: 1.2,
    fontWeight: 'normal',
  },
})

const WelcomePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const onBuildProfileClick = () => {
    dispatch(navigateToProfileStep(1))
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <Typography variant="h2" className={classes.title}>
            welcome to XIRA!
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            let’s get you registered so clients can find you
          </Typography>
          <div className={classes.boxBtn}>
            <Button className={classes.btn} variant="primary" onClick={onBuildProfileClick}>
              get registered
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(WelcomePage)
