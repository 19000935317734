import React from 'react'
import { DatePicker, Hint, Icon, InputLabel } from 'components'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  error: {
    marginTop: 5,
    color: colors.ERROR,
  },
  hint: {
    marginTop: 5,
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
  text: {
    fontWeight: 500,
  },
  datePickerContainer: {
    width: '100%',
  },
})

const FormikDatePicker = ({
  containerClass,
  name,
  label,
  fullWidth,
  disabled,
  value,
  error,
  touched,
  disabledDays,
  onChange = () => {},
  setFieldTouched = () => {},
  calendarOrientation,
  timezone,
  ...props
}) => {
  const classes = useStyles()
  const touchedState = touched !== undefined
  const handleChange = value => {
    onChange(name, value)
    // field value updated async, that is why we need timeout
    // because when filed set touched, validation will be running
    setTimeout(() => setFieldTouched(name, value))
  }
  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <DatePicker
        fullWidth={fullWidth}
        disabled={disabled}
        selectedDate={value}
        calendarOrientation={calendarOrientation}
        updateDate={handleChange}
        onDayPickerHide={() => setFieldTouched(name, value)}
        error={error && touchedState}
        disabledDays={disabledDays}
        containerClasses={classes.datePickerContainer}
        timezone={timezone}
      />
      {error && touchedState && (
        <Hint classes={{ root: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {error}
        </Hint>
      )}
    </div>
  )
}

export default FormikDatePicker
