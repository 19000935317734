import axios from 'axios'
import * as CONSTANTS from 'store/constants/accountProfile'
import {
  getAllDegreesPath,
  getCurrentProfilePath,
  getLanguagesPath,
  getUserDegreesPath,
  getUserLanguagesPath,
  getVideoPath,
  socialLinksPath,
  getDeletionRequestPath,
  getCancelDeletionRequestPath,
  getRatingStatusPath,
} from 'utils/path-helpers/api'
import { openSnackbar } from 'store/actions/common'

//degrees
const getUserDegreesStart = () => ({
  type: CONSTANTS.GET_USER_DEGREES_START,
})
const getUserDegreesEnd = payload => ({
  type: CONSTANTS.GET_USER_DEGREES_END,
  payload,
})
export const getUserDegrees = () => dispatch => {
  dispatch(getUserDegreesStart())
  axios
    .get(getUserDegreesPath())
    .then(response => {
      const degrees = response.data.map(res => ({
        schoolName: res.school_name,
        areaOfStudy: res.major,
        degreeLevel: res.level,
        graduated: res.year_of_graduation,
        degreeTitle: res.title,
        id: Math.random(),
      }))
      dispatch(getUserDegreesEnd(degrees))
    })
    .catch(e => {
      dispatch(getUserDegreesEnd([]))
      dispatch(openSnackbar('error', 'Error while degrees loading'))
    })
}
const setUserDegrees = payload => ({
  type: CONSTANTS.SET_USER_DEGREES,
  payload,
})
export const updateUserDegrees = degrees => (dispatch, getState) => {
  const prevDegrees = getState().accountProfile.degrees
  dispatch(setUserDegrees(degrees))
  const degreesBody = degrees.map(degree => ({
    school_name: degree.schoolName,
    level: degree.degreeLevel,
    title: degree.degreeTitle,
    major: degree.areaOfStudy,
    year_of_graduation: degree.graduated,
  }))
  return axios
    .post(getUserDegreesPath(), { degrees: degreesBody })
    .then(response => {
      dispatch(openSnackbar('success', 'Degrees were updated'))
    })
    .catch(error => {
      dispatch(openSnackbar('error', 'There was some error while changing degrees'))
      dispatch(setUserDegrees(prevDegrees))
    })
}

export const setUserBio = payload => ({
  type: CONSTANTS.SET_USER_BIO,
  payload,
})

export const saveBio = data => dispatch => {
  dispatch(getCurrentProfileStatusStart())
  return axios
    .patch(getCurrentProfilePath(), data)
    .then(response => {
      dispatch(getCurrentProfileStatusEnd(data))
      dispatch(openSnackbar('success', 'Bio was updated'))
    })
    .catch(error => {
      dispatch(getCurrentProfileStatusError())
      dispatch(openSnackbar('error', 'Error while bio saving'))
    })
}

export const saveVideoLink = link => dispatch => {
  return axios
    .patch(getVideoPath(), { introduction_video_url: link })
    .then(response => {
      dispatch(setVideo(response.data.introduction_video_url))
    })
    .catch(error => {
      dispatch(openSnackbar('error', 'Error while video save'))
    })
}

const getUserLanguagesStart = () => ({
  type: CONSTANTS.GET_USER_LANGUAGES_START,
})

const getUserLanguagesEnd = payload => ({
  type: CONSTANTS.GET_USER_LANGUAGES_END,
  payload,
})

export const updateLocalLanguages = payload => ({
  type: CONSTANTS.UPDATE_LOCAL_LANGUAGES,
  payload,
})
export const getUserLanguages = () => dispatch => {
  dispatch(getUserLanguagesStart())
  const getLanguages = axios.get(getLanguagesPath())
  const getProfileLanguages = axios.get(getUserLanguagesPath())
  Promise.all([getLanguages, getProfileLanguages])
    .then(([languages, userLanguages]) => {
      const payload = languages.data.map(item => ({
        ...item,
        checked: userLanguages.data.some(lang => lang.uuid === item.uuid),
      }))
      dispatch(getUserLanguagesEnd(payload))
    })
    .catch(e => {
      dispatch(getUserLanguagesEnd([]))
      dispatch(openSnackbar('error', 'Error while languages loading'))
    })
}

const saveUserLanguagesStart = () => ({
  type: CONSTANTS.SAVE_USER_LANGUAGES_START,
})

const saveUserLanguagesEnd = payload => ({
  type: CONSTANTS.SAVE_USER_LANGUAGES_END,
  payload,
})

export const saveUserLanguages = languages => dispatch => {
  dispatch(saveUserLanguagesStart())
  const payload = languages.reduce((result, item) => {
    return item.checked ? [...result, item.uuid] : result
  }, [])
  return axios
    .post(getUserLanguagesPath(), { languages: payload })
    .then(() => {
      dispatch(saveUserLanguagesEnd(languages))
    })
    .catch(e => {
      return Promise.reject(e)
    })
}

const getCurrentProfileStatusStart = () => ({
  type: CONSTANTS.GET_CURRENT_PROFILE_STATUS_START,
})

const getCurrentProfileStatusEnd = payload => ({
  type: CONSTANTS.GET_CURRENT_PROFILE_STATUS_END,
  payload,
})

const getCurrentProfileStatusError = () => ({
  type: CONSTANTS.GET_CURRENT_PROFILE_STATUS_ERROR,
})

export const getCurrentProfileStatus = () => dispatch => {
  dispatch(getCurrentProfileStatusStart())
  axios
    .get(getCurrentProfilePath())
    .then(payload => {
      const data = {
        isHidden: payload.data.is_hidden,
        isApproved: payload.data.is_approved,
        bio: payload.data.bio,
        rating: payload.data.rating,
        reviews: payload.data.reviews,
      }
      dispatch(getCurrentProfileStatusEnd(data))
    })
    .catch(e => {
      dispatch(getCurrentProfileStatusError())
      dispatch(openSnackbar('error', 'Error while profile loading'))
    })
}

const getDeletionRequestInfoStart = () => ({
  type: CONSTANTS.GET_DELETION_REQUEST_STATUS_START,
})

export const getDeletionRequestInfoEnd = payload => ({
  type: CONSTANTS.GET_DELETION_REQUEST_STATUS_END,
  payload,
})

export const getDeletionRequestInfo = () => dispatch => {
  let delitionRequestUuid = ''
  dispatch(getDeletionRequestInfoStart())
  axios
    .get(getDeletionRequestPath())
    .then(payload => {
      if (payload.data[0]) {
        delitionRequestUuid = payload.data[0].uuid
      }
    })
    .catch(e => {})
    .finally(() => dispatch(getDeletionRequestInfoEnd(delitionRequestUuid)))
}

export const sendDeleteProfileRequest = () => dispatch => {
  return axios.post(getDeletionRequestPath()).catch(e => {
    return Promise.reject(e)
  })
}

export const cancelAccountDeletion = () => (dispatch, getState) => {
  const deletionRequestUuid = getState().accountProfile.deletionRequestUuid
  axios.delete(getCancelDeletionRequestPath(deletionRequestUuid)).then(() => {
    dispatch(openSnackbar('success', 'You deletion request is canceled'))
    dispatch(getDeletionRequestInfoEnd(''))
  })
}

const changeProfileVisibilityStart = () => ({
  type: CONSTANTS.CHANGE_PROFILE_VISIBILITY_START,
})

const changeProfileVisibilityEnd = payload => ({
  type: CONSTANTS.CHANGE_PROFILE_VISIBILITY_END,
  payload,
})

export const changeProfileVisibility = value => dispatch => {
  dispatch(changeProfileVisibilityStart())
  const data = {
    is_hidden: value,
  }
  return axios
    .patch(getCurrentProfilePath(), data)
    .then(() => {
      dispatch(changeProfileVisibilityEnd(value))
    })
    .catch(e => {
      return Promise.reject(e)
    })
}

const setAccounts = payload => ({
  type: CONSTANTS.SET_ACCOUNTS,
  payload,
})

export const getSocialAccounts = () => dispatch => {
  axios
    .get(socialLinksPath())
    .then(res => dispatch(setAccounts(res.data)))
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while social links loading'))
      Promise.reject(e)
    })
}

export const postSocialAccount = body => () => {
  return axios.post(socialLinksPath(), body)
}

export const deleteSocialAccount = uuid => () => {
  return axios.delete(`${socialLinksPath()}${uuid}/`)
}

const setVideo = payload => ({
  type: CONSTANTS.SET_VIDEO,
  payload,
})

export const getVideo = () => dispatch => {
  return axios
    .get(getVideoPath())
    .then(response => {
      dispatch(setVideo(response.data.introduction_video))
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while video loading'))
      Promise.reject(e)
    })
}

let cancelVideo
export const cancelVideoUpload = () => cancelVideo('Video upload was canceled')

export const uploadVideo = (file, onUploadProgress) => dispatch => {
  const payload = new FormData()
  payload.append('introduction_video', file)

  const CancelToken = axios.CancelToken

  return axios
    .patch(getVideoPath(), payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100
        onUploadProgress(progress)
      },
      cancelToken: new CancelToken(function executor(cancel) {
        cancelVideo = cancel
      }),
    })
    .then(response => {
      dispatch(setVideo(response.data.introduction_video))
      dispatch(openSnackbar('success', 'Video is successfully uploaded!'))
    })
    .catch(e => {
      let errorMsg = ''
      if (e.response) {
        if (e.response.data.introduction_video) {
          errorMsg = e.response.data.introduction_video[0]
        } else if (e.response.data.detail) {
          errorMsg = e.response.data.detail[0]
        }
      } else if (e.message) {
        errorMsg = e.message
      } else {
        errorMsg = 'Error while video upload'
      }
      dispatch(openSnackbar('error', errorMsg))
      Promise.reject(e)
    })
}

export const deleteVideo = () => dispatch => {
  return axios
    .delete(getVideoPath())
    .then(() => {
      dispatch(setVideo(''))
      dispatch(openSnackbar('success', 'Video is deleted'))
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while video delete'))
      Promise.reject(e)
    })
}

const getDegreesStart = () => ({
  type: CONSTANTS.GET_DEGREES_START,
})

const getDegreesEnd = payload => ({
  type: CONSTANTS.GET_DEGREES_END,
  payload,
})

export const getDegrees = () => dispatch => {
  dispatch(getDegreesStart())
  axios
    .get(getAllDegreesPath())
    .then(response => {
      const data = response.data.map(item => ({ value: item.degree, label: item.degree }))
      dispatch(getDegreesEnd(data))
    })
    .catch(error => {
      dispatch(getDegreesEnd([]))
      dispatch(openSnackbar('error', 'Error while loading degrees!'))
      return Promise.reject(error)
    })
}

export const setProfileApproveStatus = payload => ({
  type: CONSTANTS.SET_PROFILE_APPROVE_STATUS,
  payload,
})

const getRatingStatusStart = () => ({
  type: CONSTANTS.GET_RATING_STATUS_START,
})

const getRatingStatusEnd = payload => ({
  type: CONSTANTS.GET_RATING_STATUS_END,
  payload,
})

export const getRatingStatus = () => dispatch => {
  dispatch(getRatingStatusStart())
  axios
    .get(getRatingStatusPath())
    .then(res => {
      const status = res.data.status || null
      dispatch(getRatingStatusEnd(status))
    })
    .catch(() => {
      dispatch(openSnackbar('error', 'Error while getting rating status'))
      dispatch(getRatingStatusEnd(null))
    })
  dispatch(getRatingStatusEnd(null))
}
export const sendRatingRequest = message => dispatch => {
  axios
    .post(getRatingStatusPath(), { message: message })
    .then(res => {
      dispatch(getRatingStatus())
    })
    .catch(() => {
      dispatch(openSnackbar('error', 'Error while sending rating message'))
    })
}
