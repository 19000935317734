import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIcon, Tooltip, Typography } from 'components'
import { getTimePassedSinceEvent } from 'utils/getters/common'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { useDispatch } from 'react-redux'
import { markNotificationAsRead } from 'store/actions/notifications/notifications'
import { dispatchNotificationAction, BaseNotificationBody, getNotificationIcon } from './NotificationTypes'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '12px 24px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['5'],
    },
  },
  content: {
    flexGrow: 1,
  },
  indicatorContainer: {
    paddingLeft: 24,
  },
  readIndicator: {
    height: 8,
    width: 8,
    borderRadius: '50%',
  },
  unreadMessage: {
    backgroundColor: colors.PRIMARY.COLOR['50'],
  },
  readMessage: {
    backgroundColor: colors.BASIC.COLOR['20'],
  },
  icon: {
    marginRight: 16,
    color: colors.BASIC.COLOR['80'],
    height: 24,
    width: 24,
  },
  time: {
    fontSize: 11,
    color: colors.BASIC.COLOR['50'],
    fontWeight: 'normal',
    marginTop: 3,
    lineHeight: 1.73,
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR['80'],
    color: colors.WHITE,
    fontSize: 13,
    fontWeight: 'normal',
    borderRadius: '4px',
    boxShadow: '2px 4px 24px 0 rgba(0, 0, 0, 0.15)',
  },
  text: {
    color: colors.BASIC.COLOR['100'],
  },
  disabledText: {
    color: colors.BASIC.COLOR['50'],
  },
})

const UserNotification = props => {
  const classes = useStyles()
  const { notification, closePopover } = props
  const dispatch = useDispatch()

  const onNotificationClick = () => {
    dispatch(dispatchNotificationAction(notification))
    if (closePopover) {
      closePopover()
    }
  }

  const onMarkAsReadClick = e => {
    e.stopPropagation()
    dispatch(markNotificationAsRead(notification.uuid, notification.unread, notification.type))
  }

  return (
    <div className={classes.container} onClick={onNotificationClick}>
      <SvgIcon
        viewBox="0 0 24 24"
        className={classNames({
          [classes.icon]: true,
          [classes.disabledText]: !notification.unread,
        })}
      >
        {getNotificationIcon(notification.type)}
      </SvgIcon>
      <div className={classes.content}>
        <div
          className={classNames({
            [classes.text]: notification.unread,
            [classes.disabledText]: !notification.unread,
          })}
        >
          <BaseNotificationBody notification={notification} closePopover={closePopover} />
        </div>
        <Typography className={classes.time}>{getTimePassedSinceEvent(notification.created_at)}</Typography>
      </div>
      <div className={classes.indicatorContainer}>
        <Tooltip title={`mark as ${notification.unread ? 'read' : 'unread'}`} tooltipClass={classes.tooltip}>
          <div
            className={classNames({
              [classes.readIndicator]: true,
              [classes.readMessage]: !notification.unread,
              [classes.unreadMessage]: notification.unread,
            })}
            onClick={onMarkAsReadClick}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default UserNotification
