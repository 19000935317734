import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ExpenseInvoicingSelection, FormInput, InvoicingSchedule, Typography } from 'components'
import { colors, CONSTANTS } from 'constants/index'
import {formatDecimal} from "utils/getters/common";

const useStyles = makeStyles({
  standardMargin: {
    marginBottom: 16,
  },
  balanceField: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  balanceSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    marginBottom: 16,
    overflow: 'hidden',
  },
  adornment: {
    marginRight: 0,
  },
})

const getBalance = (amount, sections) => {
  if (sections.length) {
    const sectionAmount = sections.reduce((sum, item) => sum + Number(item.invoice_amount), 0)
    return Math.round((Number(amount) - sectionAmount + Number.EPSILON) * 100) / 100
  }
  return amount
}

const FlatFeeSection = props => {
  const classes = useStyles()
  const { caseData, mode, onChange, errors = {}, setErrors = error => {} } = props
  const [balance, setBalance] = useState(0)

  useEffect(() => {
    setBalance(getBalance(caseData.amount, caseData.invoicing_sections))
  }, [caseData.amount, caseData.invoicing_sections])

  const handleChange = (name, value) => {
    onChange({
      [name]: value,
    })
    setErrors({
      [name]: '',
    })
  }

  return (
    <div>
      <FormInput
        label="Total amount, $ *"
        disabled={mode !== CONSTANTS.MODE.ADD}
        value={caseData.amount}
        inputProps={{ style: { textAlign: 'right' } }}
        fullWidth
        containerClass={classes.standardMargin}
        name="amount"
        error={errors.amount}
        onChange={handleChange}
        handleBlur={e => handleChange('amount', formatDecimal(e.target.value))}
        type="number"
      />
      <InvoicingSchedule
        invoicingSections={caseData.invoicing_sections}
        errors={errors.invoicing_sections}
        mode={mode}
        onChange={onChange}
        balance={balance}
        setErrors={setErrors}
      />
      <div className={classes.balanceSection}>
        <Typography variant="subtitle1">
          Balance: <span className={classes.balanceField}>${Number(balance).toFixed(2)}</span>
        </Typography>
      </div>
      <ExpenseInvoicingSelection
        caseData={caseData}
        errors={errors}
        onChange={onChange}
        mode={mode}
        setErrors={setErrors}
      />
    </div>
  )
}

export default FlatFeeSection
