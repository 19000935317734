import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Button, Icon, TimezoneLabel, Typography } from 'components'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  title: {
    fontSize: 20,
    color: colors.BASIC.COLOR[80],
    fontWeight: 500,
    lineHeight: 1.4,
  },
  buttonGroupButton: {
    padding: '5px 15px',
    backgroundColor: colors.BASIC.COLOR[0],
    color: colors.BASIC.COLOR[70],
    fontWeight: 500,
    textTransform: 'lowercase',
  },
  navigationButtons: {
    marginRight: 12,
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
})

const titleFormat = {
  titleFormat: {
    separator: ' - ',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  },
}

const getTitle = calendar => {
  if (calendar) {
    return calendar.calendar.component.computeTitle(calendar.calendar.state.dateProfile, titleFormat)
  }
  return ''
}

const CalendarToolbar = props => {
  const classes = useStyles()
  const { timezone, calendarRef, setCurrentView, currentView } = props
  const [date, setDate] = useState('')
  const calendar = calendarRef.current

  useEffect(() => {
    if (calendar) {
      calendar.calendar.changeView(currentView)
      calendar.calendar.today()
      setDate(getTitle(calendar))
    }
  }, [currentView, calendar])

  const onViewChange = view => {
    setCurrentView(view)
  }

  const onDateNavigation = option => {
    switch (option) {
      case 'prev':
        calendar.calendar.prev()
        break
      case 'today':
        calendar.calendar.today()
        break
      case 'next':
        calendar.calendar.next()
        break
    }
    setDate(getTitle(calendar))
  }

  return (
    <div className={classes.container}>
      <div className={classes.btnGroup}>
        <ButtonGroup className={classes.navigationButtons} disabled={currentView === 'listThirtyDaysView'}>
          <Button
            className={classes.buttonGroupButton}
            variant="secondary"
            onClick={() => {
              onDateNavigation('prev')
            }}
          >
            <Icon iconClass="las la-angle-left" />
          </Button>
          <Button
            className={classes.buttonGroupButton}
            variant="secondary"
            onClick={() => {
              onDateNavigation('today')
            }}
          >
            Today
          </Button>
          <Button
            className={classes.buttonGroupButton}
            variant="secondary"
            onClick={() => {
              onDateNavigation('next')
            }}
          >
            <Icon iconClass="las la-angle-right" />
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            className={classes.buttonGroupButton}
            variant="secondary"
            onClick={() => onViewChange('timeGridWeek')}
          >
            week
          </Button>
          <Button
            className={classes.buttonGroupButton}
            variant="secondary"
            onClick={() => onViewChange('listThirtyDaysView')}
          >
            schedule
          </Button>
        </ButtonGroup>
      </div>
      <Typography className={classes.title}>{date}</Typography>
      <TimezoneLabel timezone={timezone} />
    </div>
  )
}

export default CalendarToolbar
