import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import {
  getJWTLocally,
  getRefreshTokenLocally,
  removeJWTLocally,
  removeRefreshTokenLocally,
  setJWTLocally,
  setRefreshTokenLocally,
} from 'utils/auth'
import store from './store'
import { setCurrentUser, toggleAuthModal } from 'store/actions/auth'
import { loginUserPath, refreshTokenPath } from 'utils/path-helpers/api'
import moment from 'moment-timezone'
import history from './history'
import App from './app/app'
import * as serviceWorker from './utils/serviceWorker'

const localTimezone = moment.tz.guess()

axios.interceptors.request.use(
  config => {
    if (!config) return config
    if (config.url.includes('googleapis')) return config
    if (getJWTLocally()) {
      if (config.url[0] === '/') {
        config.headers.authorization = `JWT ${getJWTLocally()}`
      }
    }
    config.headers['Xira-User-Time-Zone'] = localTimezone
    return config
  },
  error => Promise.reject(error)
)

let getFreshTokenRequest = null

axios.interceptors.response.use(
  response => response,
  error => {
    if (!(error.response && error.response.status === 401)) {
      return Promise.reject(error)
    }
    if (error.config.url === refreshTokenPath()) {
      return Promise.reject(error)
    }

    if (error.config.url === loginUserPath()) {
      return Promise.reject(error)
    }
    if (error.response.data.code === 'user_inactive') {
      const payload = {
        currentUser: {},
        isUserLoggedIn: false,
      }
      removeRefreshTokenLocally()
      store.dispatch(setCurrentUser(payload))
      store.dispatch(toggleAuthModal(true))
    }
    if (getRefreshTokenLocally()) {
      if (!getFreshTokenRequest) {
        return (getFreshTokenRequest = axios
          .post(refreshTokenPath(), { refresh: getRefreshTokenLocally() })
          .then(response => {
            setJWTLocally(response.data.access)
            setRefreshTokenLocally(response.data.refresh)
            return axios(error.config)
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              history.push('/')
              removeJWTLocally()
              removeRefreshTokenLocally()
              console.log(error.config)
              return Promise.reject(error)
            }
          })
          .finally(() => {
            getFreshTokenRequest = null
          }))
      } else {
        return getFreshTokenRequest.then(() => {
          return axios(error.config)
        })
      }
    } else {
      history.push('/')
      removeJWTLocally()
      removeRefreshTokenLocally()
      return Promise.reject(error)
    }
  }
)

if (!window.location.origin.includes('localhost')) {
  Sentry.init({ dsn: 'http://b7ccf4daa06b4188b281798652d6ab40@ec2-44-232-76-29.us-west-2.compute.amazonaws.com/2' })
}

if (
  window.location.pathname === '/' &&
  !getJWTLocally() &&
  !window.location.search &&
  window.location.origin.includes('law.xira')
) {
  window.location.href = 'https://xira.com'
} else {
  ReactDOM.render(<App />, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
