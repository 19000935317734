import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors, CONSTANTS } from 'constants/index'
import { ClientInvoicesTableRow, CommonInvoicesTable, InvoiceDocument } from 'components'
import { useDispatch } from 'react-redux'
import { navigateToClientInvoiceDetails, navigateToClientInvoicePayment } from 'store/actions/navigation'
import * as moment from 'moment'
import { pdf } from '@react-pdf/renderer'
import { exportFile } from 'store/actions/common'
import { getProInvoice, getProInvoiceSummary } from '../../../store/actions/invoices'
import InvoiceSummary from '../pdf/summaryPDF'
import InvoicePaymentsCredits from '../pdf/paymentsCredits'
import PDFMerger from 'pdf-merger-js/browser'

const useStyles = makeStyles({
  invoiceTable: {
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.BASIC.COLOR['5']}`,
  },
})

const columns = [
  {
    value: 'invoice_number',
    label: 'Invoice number',
    sortable: true,
  },
  {
    value: 'issued_at',
    label: 'Issued',
    sortable: true,
  },
  {
    value: 'pro_full_name',
    label: 'Lawyer',
    sortable: true,
  },
  {
    value: 'amount_due',
    label: 'Amount due',
    sortable: true,
    numeric: true,
  },
  {
    value: 'amount_paid',
    label: 'Paid',
    sortable: true,
    numeric: true,
  },
  {
    value: 'pay_now',
    label: '',
    sortable: false,
  },
  {
    value: 'status',
    label: 'Status',
    sortable: true,
  },
]

const ClientInvoicesTable = ({ invoices }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const getRowActions = rowData => {
    const commonActions = [
      {
        key: 'view',
        label: 'view details',
        action: data => {
          dispatch(navigateToClientInvoiceDetails(data.uuid))
        },
      },
      {
        key: 'download',
        label: 'download in PDF',
        action: async data => {
          const pdfInvoice = await dispatch(getProInvoice(data.uuid, true))
          const pdfInvoiceSummary = await dispatch(getProInvoiceSummary(data.uuid, true))
          const fileName = `Invoice ${data.invoiceNumber} ${moment().format(CONSTANTS.DATE_FORMAT.PDF_DATE)}.pdf`
          if (!pdfInvoice.file_link) {
            const blob = await pdf(
              <InvoiceDocument download invoice={pdfInvoice} summary={pdfInvoiceSummary} />
            ).toBlob()
            dispatch(exportFile(blob, fileName, 'application/pdf'))
          } else {
            try {
              const summaryBlob = await pdf(
                <InvoiceSummary invoice={pdfInvoice} summary={pdfInvoiceSummary} />
              ).toBlob()
              const paymentsBlob = await pdf(
                <InvoicePaymentsCredits invoice={pdfInvoice} summary={pdfInvoiceSummary} />
              ).toBlob()

              const pdfFile = await fetch(pdfInvoice.file_link).then(res => res.blob())
              const pdfBlob = new Blob([pdfFile], { type: 'application/pdf' })
              let merger = new PDFMerger()
              await Promise.all([summaryBlob, pdfBlob, paymentsBlob].map(async file => await merger.add(file, null)))

              const mergedPdf = await merger.saveAsBlob()
              dispatch(exportFile(mergedPdf, fileName, 'application/pdf'))
              merger = null
            } catch (err) {
              console.log(err)
            }
          }
        },
      },
    ]
    const payAgainAction = rowData.caseFeeType
      ? []
      : [
          {
            key: 'pay',
            label: 'pay again',
            action: data => {
              dispatch(navigateToClientInvoicePayment(data.uuid))
            },
          },
        ]
    switch (rowData.statusForDisplay) {
      case 0:
      case 2:
      case 4:
      case 5:
        return commonActions
      case 1:
        return [...commonActions, ...payAgainAction]
      default:
        return []
    }
  }

  return (
    <CommonInvoicesTable
      invoices={invoices}
      className={classes.invoiceTable}
      getRowActions={getRowActions}
      tableDataRowRenderer={(columns, data) => <ClientInvoicesTableRow columns={columns} data={data} />}
      columns={columns}
    />
  )
}

export default ClientInvoicesTable
