import React, { useState, useEffect } from 'react'
import { SvgIcon, Button } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../constants/colors'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import { ReactComponent as IconCheckLang } from 'static/icon_24_check.svg'
import { showSort } from 'store/actions/common'

const useStyles = makeStyles({
  filtersContainer: {
    borderRight: `1px solid ${colors.BASIC.COLOR[5]}`,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100vh',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  sortHeader: {
    display: 'flex !important',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '13px 15px',
    fontSize: 17,
  },
  closeBtn: {
    fontSize: 20,
    cursor: 'pointer',
  },
  formSort: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    borderBottom: `1px solid #f2f2f2`,
    padding: '0 15px',
  },
  btnSort: {
    backgroundColor: colors.WHITE,
    border: 'none',
    height: '43px',
    '&:active, .btnLang:focus': {
      outline: 'none !important',
    },
    outline: 'none !important',
    cursor: 'pointer',
  },
  checkIcon: {
    visibility: 'hidden',
    color: colors.WHITE,
  },
  checkIconActive: {
    visibility: 'visible',
  },
  rowBtn: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: 55,
  },
  btnApply: {
    width: 300,
  },

  '@media(max-width: 767px)': {
    rowBtn: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    btnApply: {
      position: 'fixed',
      left: 0,
      right: 0,
      margin: '0 auto',
      width: '90%',
      bottom: 8,
    },
  },
})

const ProfileSort = props => {
  const { options, setSorter, loadProfiles, value } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [sortId, setSortId] = useState('2')
  const [sort, setSort] = useState('user_practice_areas__hourly_rate')

  useEffect(() => {
    if (value !== '') {
      setSort(value)
    }
  }, [])

  const handleClickSort = e => {
    if (sortId !== e.target.closest('div').id) {
      setSortId(e.target.closest('div').id)
      const prevChoice = document.getElementById(`${sortId}`).firstChild
      e.target.previousSibling.classList.add(classes.checkIconActive)
      prevChoice.classList.remove(classes.checkIconActive)
      const option = findOption(e.target.name)
      setSort(option.value)
      setSorter(option.value)
    }
  }
  const findOption = name => {
    return options.find(option => {
      return option.label === name
    })
  }
  const handleClickApply = () => {
    loadProfiles()
    dispatch(showSort(false))
  }
  return (
    <div className={classes.filtersContainer}>
      <div className={classes.container}>
        <div className={classes.sortHeader}>
          <div className={classes.closeBtn} onClick={() => dispatch(showSort(false))}>
            &#215;
          </div>
          <div>Sort</div>
          <div></div>
        </div>
        <div className={classes.formSort}>
          {options.map((option, index) => (
            <div className={classes.option} key={index} id={index}>
              <SvgIcon
                component={IconCheckLang}
                viewBox="0 0 24 24"
                className={classnames(classes.checkIcon, classes.lable, {
                  [classes.checkIconActive]: option.value === sort,
                })}
              />
              <button className={classes.btnSort} onClick={handleClickSort} name={option.label}>
                {option.label}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.rowBtn}>
        <Button variant="primary" className={classes.btnApply} onClick={handleClickApply}>
          <div>Apply</div>
        </Button>
      </div>
    </div>
  )
}

export default ProfileSort
