import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Hint, Icon, InputLabel, Switch, TextField, Typography } from 'components'
import { InputAdornment } from '@material-ui/core'
import { colors } from 'constants/colors'
import classNames from 'classnames'
import VideoUpload from './videoUpload'

const useStyles = makeStyles({
  inputIcon: {
    fontSize: 18,
    color: colors.BASIC.COLOR[80],
    borderRight: '1px solid #E8E8E8',
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.BASIC.COLOR[10],
  },
  switcher: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    marginBottom: 24,
  },
  input: {
    marginBottom: 4,
    padding: 0,
    borderColor: colors.BASIC.COLOR[20],
  },
  hint: {
    color: colors.ERROR,
  },
  disabledOptionText: {
    opacity: 0.5,
  },
  selectedOptionText: {
    color: colors.BASIC.COLOR[100],
    fontWeight: 500,
  },
  optionText: {
    color: colors.BASIC.COLOR[20],
  },
  switchBase: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  track: {
    opacity: 1,
    backgroundColor: colors.PRIMARY.COLOR[50],
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: colors.WHITE,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.PRIMARY.COLOR[50],
      },
    },
    '&.Mui-disabled': {
      color: colors.WHITE,
      '& + .MuiSwitch-track': {
        backgroundColor: colors.PRIMARY.COLOR[50],
        opacity: 0.5,
      },
    },
  },
})

const VideoUploadLink = props => {
  const { onVideoUrlChange, videoLink, videoLinkError } = props
  const classes = useStyles()
  return (
    <>
      <InputLabel>Link to YouTube video</InputLabel>
      <TextField
        variant="outlined"
        size="small"
        name="link"
        required
        value={videoLink}
        onChange={e => onVideoUrlChange(e.target.value)}
        type="text"
        fullWidth
        error={videoLinkError}
        placeholder="https://"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon iconClass="las la-link" style={classes.inputIcon} />
            </InputAdornment>
          ),
          classes: { root: classes.input },
        }}
      />
      {videoLinkError && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {videoLinkError}
        </Hint>
      )}
    </>
  )
}

const VideoUploadSwitcher = props => {
  const classes = useStyles()
  const { option, onOptionChange, onVideoUrlChange, videoLink, videoLinkError } = props
  const [isUploading, setIsUploading] = useState(false)

  const onSwitchChange = (e, v) => {
    const value = v ? 'link' : 'upload'
    onOptionChange(value)
  }

  return (
    <div className={classes.container}>
      <div className={classes.switcher}>
        <Typography
          variant="body2"
          className={classNames({
            [classes.optionText]: option !== 'upload',
            [classes.disabledOptionText]: isUploading,
            [classes.selectedOptionText]: option === 'upload',
          })}
        >
          upload
        </Typography>
        <Switch
          checked={option === 'link'}
          color="primary"
          onChange={onSwitchChange}
          disabled={isUploading}
          classes={{
            switchBase: classes.switchBase,
            track: classes.track,
            colorPrimary: classes.colorPrimary,
          }}
        />
        <Typography
          variant="body2"
          className={classNames({
            [classes.optionText]: option !== 'link',
            [classes.disabledOptionText]: isUploading,
            [classes.selectedOptionText]: option === 'link',
          })}
        >
          insert link
        </Typography>
      </div>
      {option === 'upload' ? (
        <VideoUpload updateUploadingStatus={isUploadInProgress => setIsUploading(isUploadInProgress)} />
      ) : (
        <VideoUploadLink onVideoUrlChange={onVideoUrlChange} videoLink={videoLink} videoLinkError={videoLinkError} />
      )}
    </div>
  )
}

export default VideoUploadSwitcher
