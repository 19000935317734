import { createMuiTheme } from '@material-ui/core/styles'
import { colors } from './colors'

export const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        height: 40,
        backgroundColor: colors.WHITE,
        color: colors.BASIC.COLOR[100],
        fontSize: 15,
        '&$disabled': {
          backgroundColor: colors.BASIC.COLOR[10],
        },
        '&.MuiOutlinedInput-root.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.BASIC.COLOR[80],
          },
          '& > .MuiSelect-outlined': {
            backgroundColor: 'transparent',
          },
        },
      },
      input: {
        '&:-webkit-autofill': {
          height: 0,
        },
      },
    },
    MuiAppBar: {
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 'auto',
        height: '70px',
      },
      colorPrimary: {
        backgroundColor: colors.WHITE,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        padding: '13px 24px',
        fontSize: 12,
      },
      containedPrimary: {
        backgroundColor: colors.PRIMARY.COLOR[50],
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[60],
        },
        '&$disabled': {
          backgroundColor: colors.BASIC.COLOR[10],
          color: colors.BASIC.COLOR[20],
        },
      },
      contained: {
        color: colors.WHITE,
        minHeight: '46px',
        boxShadow: '0 1px 6px 0 rgba(59, 37, 15, 0.2)',
      },
      containedSizeLarge: {
        color: colors.WHITE,
        width: '368px',
        height: '52px',
        backgroundColor: colors.PRIMARY.COLOR[50],
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[60],
        },
        '&$disabled': {
          backgroundColor: colors.BASIC.COLOR[20],
          color: colors.BASIC.COLOR[50],
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        padding: '13px 24px',
      },
      outlinedSecondary: {
        boxShadow: '0 0 0 0',
        color: colors.PRIMARY.COLOR[50],
        borderColor: colors.PRIMARY.COLOR[50],
        height: '46px',
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[0],
          color: colors.PRIMARY.COLOR[50],
          borderColor: colors.PRIMARY.COLOR[50],
        },
      },
      text: {
        color: colors.PRIMARY.COLOR[50],
        padding: '13px 8px',
        boxShadow: '0 0 0 0',
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[0],
        },
        '&$disabled': {
          color: colors.BASIC.COLOR[20],
        },
      },
      disabled: {},
    },
    MuiIconButton: {
      root: {
        backgroundColor: colors.WHITE,
        '&:hover': {
          backgroundColor: colors.BASIC.COLOR[5],
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '-1px -1px 6px 1px #e0e0e0',
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '41px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      h2: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '31px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      h3: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '25px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.44,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      h4: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '20px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.41,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      h5: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '18px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.618,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      caption: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '31px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      subtitle1: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.69,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      subtitle2: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '13px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
      },
      body1: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '14px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.29,
        letterSpacing: '0.3px',
        color: colors.BASIC.COLOR[100],
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        color: colors.BASIC.COLOR[100],
        fontSize: 15,
        lineHeight: 1.6,
        '&:hover': {
          color: colors.PRIMARY.COLOR[50],
        },
      },
      underlineHover: {
        textDecoration: 'underline',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.2',
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[100],
        marginBottom: '8px',
        '&.Mui-focused': {
          color: colors.BASIC.COLOR[100],
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.69,
        letterSpacing: 'normal',
        color: colors.BASIC.COLOR[80],
      },
    },
    MuiCheckbox: {
      root: {
        backgroundColor: 'transparent',
      },
      colorPrimary: {
        '&$disabled': {
          color: colors.BASIC.COLOR[10],
        },
        '&$checked': {
          color: colors.PRIMARY.COLOR[50],
          '&$disabled': {
            color: colors.PRIMARY.COLOR[50],
            opacity: '0.5',
          },
        },
      },
      colorSecondary: {
        '&$disabled': {
          color: colors.BASIC.COLOR[10],
        },
        '&$checked': {
          color: colors.ADDITIONAL.COLOR[50],
          '&$disabled': {
            color: colors.ADDITIONAL.COLOR[50],
            opacity: '0.5',
          },
        },
      },
    },
    MuiRadio: {
      root: {
        backgroundColor: 'transparent',
      },
      colorPrimary: {
        '&$disabled': {
          color: colors.BASIC.COLOR[10],
        },
        '&$checked': {
          color: colors.PRIMARY.COLOR[50],
          '&$disabled': {
            color: colors.PRIMARY.COLOR[50],
            opacity: '0.5',
          },
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        alignSelf: 'flex-start',
      },
    },
    MuiListItemIcon: {
      root: {
        marginTop: '10px',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.WHITE,
        color: colors.BASIC.COLOR[80],
        fontSize: '16px',
        borderRadius: '4px',
        boxShadow: '0 1px 8px 0 rgba(87, 87, 87, 0.12)',
        border: 'solid 1px #e8e8e8',
      },
      arrow: {
        color: colors.WHITE,
        boxShadow: '0 1px 8px 0 rgba(87, 87, 87, 0.12)',
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: colors.BASIC.COLOR[0],
        '&$expanded': {
          backgroundColor: colors.WHITE,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: colors.BASIC.COLOR[0],
        '&$expanded': {
          backgroundColor: colors.WHITE,
        },
      },
      content: {
        '&$expanded': {
          backgroundColor: colors.WHITE,
        },
      },
      expandIcon: {
        backgroundColor: 'transparent',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        flexWrap: 'initial',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        justifyContent: 'center',
      },
      daysHeader: {
        justifyContent: 'space-between',
      },
      dayLabel: {
        fontSize: 11,
        fontWeight: 500,
        color: colors.BASIC.COLOR[100],
        textTransform: 'uppercase',
      },
      transitionContainer: {
        width: '50%',
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-between',
        marginBottom: 4,
      },
      transitionContainer: {
        minHeight: 240,
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        '& .MuiTypography-body1': {
          fontSize: 18,
          fontWeight: 'normal',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: colors.PRIMARY.COLOR[50],
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[50],
        },
      },
      current: {
        border: `solid 1px ${colors.PRIMARY.COLOR[50]}`,
        backgroundColor: colors.WHITE,
        color: colors.PRIMARY.COLOR[50],
      },
      day: {
        borderRadius: 4,
        '& .MuiTypography-body2': {
          fontWeight: 500,
        },
        '&:hover': {
          backgroundColor: colors.BASIC.COLOR[5],
        },
      },
    },
    MuiRating: {
      iconFilled: {
        color: colors.PRIMARY.COLOR[50],
      },
      iconEmpty: {
        color: colors.BASIC.COLOR[20],
      },
      iconHover: {
        color: colors.PRIMARY.COLOR[50],
      },
    },
    MuiButtonGroup: {
      groupedOutlinedPrimary: {
        color: colors.WHITE,
        borderColor: colors.PRIMARY.COLOR[50],
        backgroundColor: colors.PRIMARY.COLOR[50],
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[60],
          color: colors.WHITE,
          borderColor: colors.PRIMARY.COLOR[60],
        },
      },
      groupedOutlinedSecondary: {
        color: colors.PRIMARY.COLOR[50],
        borderColor: colors.PRIMARY.COLOR[50],
        '&:hover': {
          backgroundColor: colors.PRIMARY.COLOR[0],
          color: colors.PRIMARY.COLOR[50],
          borderColor: colors.PRIMARY.COLOR[50],
        },
      },
      groupedHorizontal: {
        '&:not(:last-child)': {
          borderRight: 'none',
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        backgroundColor: 'transparent',
        color: colors.WHITE,
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: colors.PRIMARY.COLOR[50],
          opacity: 1,
        },
        '&.Mui-checked .MuiSwitch-thumb': {
          backgroundColor: colors.PRIMARY.COLOR[5],
          border: `1px solid ${colors.PRIMARY.COLOR[50]}`,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: colors.BASIC.COLOR[20],
          opacity: 1,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          backgroundColor: colors.BASIC.COLOR[5],
          border: `1px solid ${colors.BASIC.COLOR[20]}`,
        },
        '&.Mui-disabled.Mui-checked .MuiSwitch-thumb': {
          backgroundColor: colors.PRIMARY.COLOR[5],
          border: `1px solid ${colors.PRIMARY.COLOR[20]}`,
        },
        '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
          backgroundColor: colors.PRIMARY.COLOR[20],
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      track: {
        backgroundColor: colors.BASIC.COLOR[50],
        opacity: 1,
      },
      thumb: {
        backgroundColor: colors.BASIC.COLOR[5],
        border: `1px solid ${colors.BASIC.COLOR[50]}`,
      },
    },
    MuiTableCell: {
      root: {
        padding: '8px 16px',
        fontSize: 14,
      },
    },
  },
})
