import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { InvoiceTabs, InvoiceDetailsBody } from 'components'
import { colors } from '../../constants'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { navigateTo } from '../../store/actions/navigation'

const useStyles = makeStyles({
  invoiceDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 4,
    border: `solid 1px ${colors.BASIC.COLOR['10']}`,
  },
  documentDetailsContainer: {
    minHeight: '100vw',
    height: 'max-content',
  },
  documentDetailsBody: {
    minHeight: 'inherit',
  },
  invoiceDetailsBody: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.BASIC.COLOR['0'],
    height: 'auto',
    '& .viewer-layout-container': {
      padding: '26px 16px 16px',
      display: 'block',
      border: 'none',
      backgroundColor: '#fafafa',
      '& .viewer-layout-toolbar': {
        display: 'none',
      },
      '& .viewer-page-layer': {
        boxShadow: 'none',
        margin: 0,
      },
    },
  },
})

const InvoiceDetails = ({ invoice, invoiceSummary, isClient }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [invoiceTab, setInvoiceTab] = useState(1)
  const location = useLocation()
  const documentHash = location.hash

  useEffect(() => {
    if (documentHash) {
      if (invoiceTab !== 2) setInvoiceTab(2)
      const documentElement = document.getElementById(documentHash.replace('#', ''))
      if (documentElement) {
        setTimeout(() => {
          documentElement.scrollIntoView()
        }, 1000)
      }
    }
  }, [documentHash, invoiceTab, invoiceSummary])

  const handleTabChange = value => {
    setInvoiceTab(value)
    dispatch(navigateTo(location.pathname))
  }

  return (
    <div
      className={classNames(classes.invoiceDetailsContainer, { [classes.documentDetailsContainer]: invoiceTab === 2 })}
    >
      <InvoiceTabs invoiceTab={invoiceTab} handleTabChange={handleTabChange} />
      <div className={classNames(classes.invoiceDetailsBody, { [classes.documentDetailsBody]: invoiceTab === 2 })}>
        <InvoiceDetailsBody
          invoiceTab={invoiceTab}
          invoice={invoice}
          invoiceSummary={invoiceSummary}
          isClient={isClient}
          setInvoiceTab={setInvoiceTab}
        />
      </div>
    </div>
  )
}

export default InvoiceDetails
