import React from 'react'
import { DatePicker, Hint, Icon, InputLabel, Typography } from 'components'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'constants/index'
import moment from 'moment'

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  error: {
    marginTop: 5,
    color: colors.ERROR,
  },
  hint: {
    marginTop: 5,
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
  text: {
    fontWeight: 500,
  },
  datePickerContainer: {
    width: '100%',
  },
})

const FormDatePicker = props => {
  const classes = useStyles()
  const {
    onChange,
    label,
    disabled,
    value,
    fullWidth,
    containerClass,
    name,
    error,
    hint,
    disabledDays,
    timezone,
  } = props

  const handleChange = value => {
    onChange(name, value)
  }

  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <DatePicker
        fullWidth={fullWidth}
        disabled={disabled}
        selectedDate={value}
        updateDate={handleChange}
        error={error}
        disabledDays={disabledDays}
        containerClasses={classes.datePickerContainer}
        timezone={timezone}
      />
      {hint && <Hint classes={{ root: classes.hint }}>{hint}</Hint>}
      {error && (
        <Hint classes={{ root: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {error}
        </Hint>
      )}
    </div>
  )
}

export default FormDatePicker
