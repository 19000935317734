import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

const XAlert = ({ variant = 'filled', icon, classes, ...props }) => (
  <Alert
    variant={variant}
    icon={icon}
    classes={classes}
    {...props}
  />
)

export default XAlert
