import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ColorCircularProgress } from 'components'

const useStyles = makeStyles({
  loader: {
    position: 'relative',
    width: '100%',
    height: 150,
  },
})

const Loader = () => {
  const classes = useStyles()
  return (
    <div className={classes.loader}>
      <ColorCircularProgress />
    </div>
  )
}
export default Loader
