import * as CONSTANTS from 'store/constants/events'

const initialState = {
  meetings: [],
  proMeetings: [],
  meetingAreLoading: false,
  meetingInfo: {},
  meetingInfoIsLoading: false,
  limit: 10,
  offset: 0,
  ordering: 'start_time',
  currentTimestamp: null,
  eventsLength: 0,
}

const events = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_USER_EVENTS_START:
      return {
        ...state,
        meetingAreLoading: true,
      }
    case CONSTANTS.GET_USER_EVENTS_END:
      return {
        ...state,
        proMeetings: action.payload,
        meetingAreLoading: false,
      }
    case CONSTANTS.GET_USER_EVENT_START:
      return {
        ...state,
        meetingInfoIsLoading: true,
      }
    case CONSTANTS.GET_USER_EVENT_END:
      return {
        ...state,
        meetingInfoIsLoading: false,
        meetingInfo: action.payload,
      }
    case CONSTANTS.RESET_EVENTS_UPLOAD_OFFSET:
      return {
        ...state,
        offset: 0,
      }
    case CONSTANTS.INCREASE_EVENTS_UPLOAD_OFFSET:
      return {
        ...state,
        offset: state.offset + state.limit,
      }
    case CONSTANTS.SET_EVENTS_CURRENT_TIMESTAMP:
      return {
        ...state,
        currentTimestamp: action.payload,
      }
    case CONSTANTS.GET_CLIENT_EVENTS_START:
      return {
        ...state,
        meetingAreLoading: true,
      }
    case CONSTANTS.GET_CLIENT_EVENTS_END:
      return {
        ...state,
        meetingAreLoading: false,
        meetings: action.payload,
        eventsLength: action.length,
      }
    case CONSTANTS.CANCEL_USER_MEETING_START:
      return {
        ...state,
        meetingInfoIsLoading: true,
      }
    case CONSTANTS.CANCEL_USER_MEETING_END:
      return {
        ...state,
        meetingInfoIsLoading: false,
      }
    case CONSTANTS.RESCHEDULE_USER_MEETING_START:
      return {
        ...state,
        meetingInfoIsLoading: true,
      }
    case CONSTANTS.RESCHEDULE_USER_MEETING_END:
      return {
        ...state,
        meetingInfoIsLoading: false,
      }
    case CONSTANTS.RESET_EVENTS_LIST:
      return {
        ...state,
        meetings: [],
      }
    default:
      return state
  }
}

export default events
