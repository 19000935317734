import React, { useEffect } from 'react'
import Slide from '@material-ui/core/Slide'
import {
  Modal,
  CalendarStepperHeader,
  CalendarStepperBody,
  ColorCircularProgress,
  Divider,
} from 'components'
import {setReadyForConnectCalendars, setCalendarEntities, updatedConnectedCalendarList, setLocalXIRACalendar, disconnectCalendar} from "store/actions/calendar";
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  connectCalendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  paper: {
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0,
    height: '100%',
    width: '90%',
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const ConnectCalendarModal = ({
  open,
  onClose,
  availableForConnectionCalendars,
  isCalendarLoading,
  xiraCalendar, localXiraCalendar, selectedCalendarList
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)

  useEffect(() => {
    if (open) {
      setActiveStep(0)
      dispatch(setReadyForConnectCalendars([]))
    }
  }, [open])

  const onNextClick = async step => {
    switch (step) {
      case 0: {
        setActiveStep(1)
        dispatch(updatedConnectedCalendarList())
        return
      }
      case 1: {
        dispatch(setCalendarEntities())
        onModalClose(true)
      }
    }
  }

  const setSelectedCalendar = calendar => {
    if (selectedCalendarList.find(cal => cal.uuid === calendar.uuid)) {
      dispatch(setReadyForConnectCalendars(selectedCalendarList.map(cal => (cal.uuid === calendar.uuid ? calendar : cal))))
    } else {
      dispatch(setReadyForConnectCalendars([...selectedCalendarList, calendar]))
    }
  }

  const removeSelectedCalendar = calendar => {
    dispatch(setReadyForConnectCalendars(selectedCalendarList.filter(cal => cal.uuid !== calendar.uuid)))
  }

  const onModalClose = (completed = false) => {
    if (!completed){
      selectedCalendarList.forEach(async cal => await dispatch(disconnectCalendar(cal.provider)))
      setLocalXIRACalendar(xiraCalendar)
    }
    dispatch(setReadyForConnectCalendars([]))
    onClose()
  }
  return (
    <Modal
      maxWidth="xl"
      className={classes.connectCalendarModal}
      open={open}
      handleClose={() => onModalClose(false)}
      TransitionComponent={Transition}
      dialogClasses={{ paper: classes.paper }}
      scroll="body"
    >
      <div className={classes.connectCalendarContainer}>
        {isCalendarLoading ? (
          <ColorCircularProgress />
        ) : (
          <>
            <CalendarStepperHeader activeStep={activeStep} />
            <Divider />
            <div className={classes.bodyContainer}>
              <CalendarStepperBody
                activeStep={activeStep}
                availableForConnectionCalendars={availableForConnectionCalendars}
                setSelectedCalendar={setSelectedCalendar}
                removeSelectedCalendar={removeSelectedCalendar}
                isCalendarLoading={isCalendarLoading}
                setActiveStep={setActiveStep}
                selectedCalendarList={selectedCalendarList}
                closeModal={() => onModalClose(false)}
                onNextClick={onNextClick}
                localXiraCalendar={localXiraCalendar}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ConnectCalendarModal
