import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Icon, Hint, Button } from 'components'
import classNames from 'classnames'
import { MESSAGES, REGEXP } from 'constants/index'

const useStyles = makeStyles({
  folderRow: {
    display: 'flex',
    marginLeft: 42,
  },
  input: {
    height: 32,
  },
  btn: {
    minHeight: 32,
    height: 32,
    padding: '6px 16px',
  },
  saveBtn: {
    marginLeft: 12,
  },
  icon: {
    marginRight: 4,
  },
})

const CreateFolderRow = ({ defaultSubFolderName, nameCheck, handleSave, onClose }) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState(null)
  const [nameInput, setNameInput] = useState(defaultSubFolderName)

  useEffect(() => {
    setNameInput(defaultSubFolderName)
  }, [defaultSubFolderName])

  const onSave = () => {
    if (!nameCheck(nameInput)) {
      setErrorMessage(MESSAGES.M0086)
      return
    }
    if (!nameInput) setNameInput(defaultSubFolderName)
    handleSave(nameInput)
  }

  const onNameInputFocus = e => {
    e.target.select()
    setErrorMessage(null)
  }

  const onNameInputChange = e => {
    const value = e.target.value
    if (value && (value.length > 50 || REGEXP.folderNameRestrictedChars.test(value))) return
    setNameInput(value)
  }

  return (
    <div className={classes.folderRow}>
      <div>
        <TextField
          autoFocus
          fullWidth
          onFocus={onNameInputFocus}
          variant="outlined"
          value={nameInput}
          onChange={onNameInputChange}
          InputProps={{
            classes: { root: classes.input },
          }}
        />
        {errorMessage && (
          <Hint classes={{ subtitle2: classes.error }}>
            <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
            {errorMessage}
          </Hint>
        )}
      </div>
      <Button variant="primary" onClick={onSave} className={classNames(classes.saveBtn, classes.btn)}>
        {'save'}
      </Button>
      <Button variant="text" onClick={onClose} className={classes.btn}>
        {'cancel'}
      </Button>
    </div>
  )
}

export default CreateFolderRow
