import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Timer } from 'components'

const getTimeBeforeMeeting = startTime => {
  if (!startTime) {
    return 0
  }
  const startDate = moment(startTime)
  const currentTime = moment()

  if (startDate.isBefore(currentTime)) {
    return 0
  }

  return startDate.diff(currentTime)
}

const MeetingCountdown = props => {
  const { startTime, containerClass } = props
  const [countdown, setCountdown] = useState(0)

  useEffect(() => {
    setCountdown(getTimeBeforeMeeting(startTime))
  }, [startTime])

  return (
    countdown > 0 && (
      <div className={containerClass}>
        <Timer countdownValue={countdown} />
      </div>
    )
  )
}

export default MeetingCountdown
