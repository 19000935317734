import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from "@material-ui/core/Slide"
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { colors } from 'constants/index'

const useStyles = makeStyles({
  alert:{
    backgroundColor: colors.WHITE,
    boxShadow: `2px 4px 24px 0 rgba(0, 0, 0, 0.15)`
  }
})

const  TransitionUp = (props) =>  {
  return <Slide {...props} direction="up" />;
}

const MoveProgressModal = ({isOpen, message, icon}) => {
  const classes = useStyles()
  return <Snackbar
    open={isOpen}
    TransitionComponent={TransitionUp}
    message={message}
    autoHideDuration={12000}
  >
    <Alert icon={icon} severity="info" classes={{standardInfo: classes.alert}}>
      {message}
    </Alert>
  </Snackbar>
}

export default MoveProgressModal