import React from 'react'
import { Typography, ContactInfoFormBody, ClientContactInfoFormBody } from 'components'

const ClientAddressInfo = ({ states, countries, formClasses, className, updateContactInfo, ...props }) => {
  return (
    <div className={className}>
      <Typography classes={{ h4: formClasses.title }} variant="h4">
        Contact Info
      </Typography>
      <Typography variant="subtitle1" classes={{ subtitle1: formClasses.subtitle }}>
        Only your city and state will be displayed publicly
      </Typography>
      <ClientContactInfoFormBody countries={countries} states={states} updateContactInfo={updateContactInfo} />
    </div>
  )
}

export default ClientAddressInfo
