import moment from 'moment-timezone'

export const isInFutureCaseInvoice = (data, timezone, changed) => {
  const invoiceDate =
    data.uuid && !changed
      ? moment(data.invoice_date).utc()
      : moment(data.invoice_date)
          .tz(timezone)
          .endOf('d')
          .utc()
  const invoiceGeneration = moment()
    .tz('US/Pacific')
    .startOf('day')
    .utc()
  return invoiceDate.isAfter(invoiceGeneration)
}
