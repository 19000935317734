import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Typography, ClientPaymentList, ConfigureCardModal, Button, Icon, ConfirmationModal } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS } from 'constants/index'
import { getClientPaymentSecret, saveUserCard, getUserCards } from 'store/actions/payments'
import { navigateToClientInvoices } from 'store/actions/navigation'

const useStyles = makeStyles({
  payWithContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  btnContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnSubmit: {
    marginLeft: 15,
  },
  buttonIcon: {
    marginRight: 4,
    fontSize: 15,
  },
})

const ClientPayWithContainer = ({ paymentCard, userCards, setPaymentCard, onSubmit, disablePayment }) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const clientPaymentSecretKey = useSelector(state => state.payments.clientPaymentSecretKey)
  const clientSecretIsLoading = useSelector(state => state.payments.clientSecretIsLoading)
  const [userCardData, setUserCardData] = useState({})
  const [isModalShown, setIsModalShown] = useState(false)
  const [isCancelModalShown, setIsCancelModalShown] = useState(false)
  const [stripeError, setStripeError] = useState('')

  useEffect(() => {
    if (userCardData.payment_method_id) {
      const newCard = userCards.find(card => userCardData.payment_method_id === card.paymentMethodId)
      if (newCard) setPaymentCard(newCard)
    }
  }, [userCards, userCardData])

  const onAddPayment = () => {
    dispatch(getClientPaymentSecret())
    setIsModalShown(true)
  }

  const handleSubmit = async () => {
    if (!userCards.length) {
      if (!stripe || !elements) {
        return
      }
      const result = await stripe.confirmCardSetup(clientPaymentSecretKey, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })

      if (result.error) {
        setStripeError(result.error.message)
      } else {
        setStripeError('')
        const data = {
          label: userCardData.label,
          is_default: userCardData.isDefault,
          remember_card: true,
          payment_method_id: result.setupIntent.payment_method,
        }
        try {
          await dispatch(saveUserCard(data))
          dispatch(getUserCards())
          onSubmit(data.payment_method_id)
        } catch (e) {
          dispatch(getClientPaymentSecret())
        }
      }
    } else if (paymentCard.uuid) {
      onSubmit(paymentCard.paymentMethodId)
    }
  }

  const onModalSubmit = async data => {
    try {
      const { payment_method_id } = await dispatch(saveUserCard(data))
      await dispatch(getUserCards(payment_method_id))
      setUserCardData(data)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setIsModalShown(false)
    }
  }
  return (
    <div className={classes.payWithContainer}>
      <Typography className={classes.title}>Pay with</Typography>
      <ClientPaymentList
        userCards={userCards}
        paymentCard={paymentCard}
        setPaymentCard={setPaymentCard}
        userCardData={userCardData}
        setUserCardData={setUserCardData}
        stripeError={stripeError}
      />
      <div className={classes.btnContainer}>
        <div>
          {!!userCards.length && (
            <Button variant="secondary" onClick={onAddPayment}>
              <Icon iconClass="las la-plus-circle" style={classes.buttonIcon} /> add card
            </Button>
          )}
        </div>
        <div>
          <Button variant="text" onClick={() => setIsCancelModalShown(true)}>
            cancel
          </Button>
          <Button onClick={handleSubmit} className={classes.btnSubmit} variant="primary" disabled={disablePayment}>
            confirm payment
          </Button>
        </div>
      </div>
      <ConfigureCardModal
        isOpen={isModalShown}
        onClose={() => setIsModalShown(false)}
        onSave={onModalSubmit}
        mode={CONSTANTS.MODE.ADD}
        clientPaymentSecretKey={clientPaymentSecretKey}
        clientSecretIsLoading={clientSecretIsLoading}
        cardData={{ label: '', isDefault: false, remember: true }}
        showIsRememberOption={userCards.length}
      />
      <ConfirmationModal
        isOpen={isCancelModalShown}
        message={`If you cancel the process the invoice won’t be paid.`}
        onClose={() => setIsCancelModalShown(false)}
        title="Cancel payment?"
        onConfirm={() => dispatch(navigateToClientInvoices())}
        confirmationButtonText="yes"
        cancelationButtonText="no"
      />
    </div>
  )
}
export default ClientPayWithContainer
