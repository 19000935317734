import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Modal,
  Transition,
  ProCommentsTitle,
  ProRatingStatsFilter,
  ProCommentsList,
  Typography,
  Button,
  ColorCircularProgress,
} from 'components'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { ReactComponent as DescIcon } from 'static/rating/arrow-down.svg'
import { ReactComponent as AscIcon } from 'static/rating/arrow-up.svg'
import { ReactComponent as BackIcon } from 'static/rating/chevron-left.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setReviewCommentsFilter, setReviewCommentsSorting, getProReviews } from 'store/actions/proReview/proReview'

const useStyles = makeStyles({
  ratingModalContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    padding: 40,
    justifyContent: 'space-between',
    '@media(max-width:767px) and (min-width:320px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  ratingModalRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  firstColumn: {
    width: '20%',
    '@media(max-width:767px) and (min-width:320px)': {
      width: '100%',
    },
  },
  secondColumn: {
    width: '75%',
    '@media(max-width:767px) and (min-width:320px)': {
      width: '100%',
    },
  },
  paper: {
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: '100%',
    width: '90%',
    '@media(max-width:1279px) and (min-width:320px)': {
      width: '100%',
      borderRadius: 0,
    },
  },
  paperXL: {
    '&.MuiDialog-paperScrollBody': {
      '@media(max-width:1279px) and (min-width:320px)': {
        maxWidth: '100%',
      },
    },
  },
  reviewStatsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
  },
  sortRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  sortTitle: {
    fontSize: 13,
    color: colors.BASIC.COLOR['50'],
    marginRight: 24,
  },
  sortButton: {
    textTransform: 'none',
    color: colors.BASIC.COLOR['70'],
    fontSize: 13,
    fontWeight: 'normal',
  },
  activeSortButton: {
    color: colors.PRIMARY.COLOR['50'],
  },
  clearFilterBtn: {
    color: colors.ADDITIONAL.COLOR['50'],
    textTransform: 'none',
    fontSize: 13,
    marginLeft: 28,
  },
  clearFilterRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clearFilterText: {
    fontSize: 13,
  },
  proInfoContainer: {
    display: 'none',
    '@media(max-width:767px) and (min-width:320px)': {
      display: 'flex',
      padding: 16,
      fontSize: 15,
      fontWeight: 500,
      borderBottom: `1px solid ${colors.BASIC.COLOR['10']}`,
    },
  },
  closeModalBtn: {
    '@media(max-width:767px) and (min-width:320px)': {
      display: 'none',
    },
  },
  closeModalBtnSmall: {
    padding: 0,
    minWidth: 35,
  },
})

const SortButton = ({ sort, activeSort, handleSortChange }) => {
  const classes = useStyles()
  const isActiveSort = activeSort ? sort.value === activeSort.property : false
  const onSortClick = sort => {
    if (isActiveSort) handleSortChange(activeSort.property, !activeSort.isAsc)
    else handleSortChange(sort, false)
  }
  const getSortingIcon = () => {
    return activeSort.isAsc ? (
      <AscIcon fill={colors.PRIMARY.COLOR['50']} />
    ) : (
      <DescIcon fill={colors.PRIMARY.COLOR['50']} />
    )
  }

  return (
    <Button
      variant={'text'}
      onClick={() => onSortClick(sort.value)}
      className={classNames(classes.sortButton, { [classes.activeSortButton]: isActiveSort })}
    >
      {sort.label}
      {isActiveSort && getSortingIcon()}
    </Button>
  )
}

const ProRatingModal = ({
  open,
  handleClose,
  reviews,
  rating,
  stats,
  comments,
  proName,
  proID,
  proReviewCommentsCount,
  selectedComment,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const proReviewCommentsSort = useSelector(state => state.proReview.proReviewCommentsSort)
  const proReviewCommentsFilter = useSelector(state => state.proReview.proReviewCommentsFilter)
  const isProReviewLoading = useSelector(state => state.proReview.isProReviewLoading)
  const sortFields = useSelector(state => state.proReview.sortFields)
  const isPro = !proID
  const handleSortChange = (property, isAsc) => {
    dispatch(setReviewCommentsSorting(property, isAsc))
    dispatch(getProReviews(proID))
  }
  const handleClearFilter = () => {
    dispatch(setReviewCommentsFilter(null))
    dispatch(getProReviews(proID))
  }
  return (
    <Modal
      open={open}
      TransitionComponent={Transition}
      maxWidth="xl"
      handleClose={handleClose}
      closeButtonClass={classes.closeModalBtn}
      dialogClasses={{ paper: classes.paper, paperWidthXl: classes.paperXL }}
    >
      <div>
        <div className={classes.proInfoContainer}>
          <Button variant={'text'} onClick={handleClose} className={classes.closeModalBtnSmall}>
            <BackIcon />
          </Button>
          {proName}
        </div>
        <div className={classes.ratingModalContainer}>
          <div className={classNames(classes.ratingModalRow, classes.firstColumn)}>
            <ProCommentsTitle reviews={reviews} rating={rating} />
            <div className={classes.reviewStatsContainer}>
              <ProRatingStatsFilter stats={stats} reviews={reviews} proID={proID} />
            </div>
          </div>
          <div className={classNames(classes.ratingModalRow, classes.secondColumn)}>
            {!!comments.length && (
              <div className={classes.sortRowContainer}>
                <Typography variant={'body2'} className={classes.sortTitle}>
                  sort by:
                </Typography>
                {sortFields.map(sort => (
                  <SortButton
                    sort={sort}
                    activeSort={proReviewCommentsSort}
                    handleSortChange={handleSortChange}
                    key={sort.value}
                  />
                ))}
              </div>
            )}
            <div>
              {isProReviewLoading ? (
                <ColorCircularProgress />
              ) : (
                <>
                  {proReviewCommentsFilter && (
                    <div className={classes.clearFilterRow}>
                      <Typography
                        className={classes.clearFilterText}
                      >{`${proReviewCommentsFilter} star reviews (${comments.length} of ${proReviewCommentsCount})`}</Typography>
                      <Button variant={'text'} onClick={handleClearFilter} className={classes.clearFilterBtn}>
                        Clear filter
                      </Button>
                    </div>
                  )}
                  <ProCommentsList
                    selectedComment={selectedComment}
                    comments={comments}
                    rating={rating}
                    reviews={reviews}
                    isPro={isPro}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProRatingModal
