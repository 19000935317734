import React, { useState } from 'react'
import {
  HideProfileConfirmation,
  MenuItem,
  Button,
  Menu,
  SvgIcon,
  ConfirmationModal,
  SuccessModal,
  Typography,
} from 'components'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'
import { ReactComponent as DeleteAccountLogo } from 'static/icon_24_delete_user.svg'
import { ReactComponent as CheckIcon } from 'static/profile/ic_check.svg'
import { ReactComponent as UnderReviewLogo } from 'static/profile/under_review.svg'
import { ReactComponent as ActiveLogo } from 'static/profile/active.svg'
import { ReactComponent as InactiveLogo } from 'static/profile/inactive.svg'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  menu: {
    width: 219,
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
    fontSize: 15,
    lineHeight: 1.47,
  },
  icon: {
    marginRight: 8,
  },
  arrowIcon: {
    width: 16,
    height: 16,
    marginLeft: 26,
    color: colors.BASIC.COLOR[100],
  },
  button: {
    textTransform: 'none',
    border: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '6px 10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  beforeText: {
    fontSize: 15,
    lineHeight: 1.47,
    marginTop: 7,
    fontWeight: 500,
    marginBottom: 7,
  },
  listItem: {
    fontSize: 15,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.47,
    marginTop: 7,
  },
  afterText: {
    fontSize: 15,
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.47,
    marginTop: 10,
  },
  list: {
    listStyleType: 'disc',
    marginLeft: 25,
    color: colors.PRIMARY.COLOR[50],
  },
  menuBtnText: {
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: '1.47',
    color: colors.BASIC.COLOR['100'],
  },
  statusItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statusText: {
    color: colors.BASIC.COLOR[100],
  },
  activeStatus: {
    color: colors.SUCCESS,
  },
  activeIcon: {
    fill: colors.SUCCESS,
  },
  inactiveStatus: {
    color: colors.ERROR,
  },
  inactiveIcon: {
    fill: colors.ERROR,
  },
})

const ProfileStatusSelector = props => {
  const { isHidden, changeVisibility, showSnackbar, sendDeleteRequest, setRequestUuid, isApproved } = props
  const classes = useStyles()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isDeleteConfirmOpen, openDeleteConfirm] = useState(false)
  const [isSuccessModalOpen, openSuccesModal] = useState(false)
  const [uuid, setDeleteRequestUuid] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)

  const onModalClose = () => {
    setIsModalOpened(false)
  }

  const showModal = () => {
    setIsModalOpened(true)
  }

  const onAccept = async () => {
    try {
      await changeVisibility(true)
      showSnackbar('success', 'Profile visibility changed')
    } catch (e) {
      showSnackbar('error', 'There was some error while changing profile visibility')
    }
    setIsModalOpened(false)
  }

  const onSelect = async isVisible => {
    if (!isVisible) {
      showModal()
    } else {
      try {
        await changeVisibility(false)
        showSnackbar('success', 'Profile visibility changed')
      } catch (e) {
        showSnackbar('error', 'There was some error while changing profile visibility')
      }
    }
    handleClose()
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getDeleteMessage = () => (
    <>
      <Typography className={classes.afterText}>
        Your request for account deletion will be sent to XIRA Admin.
      </Typography>
      <Typography className={classes.beforeText}>Before we can delete your account, please ensure</Typography>
      <ul className={classes.list}>
        <li>
          <Typography className={classes.listItem}>
            you have entered all work performed for your clients in the activity table – please do this immediately so
            XIRA can invoice all remaining activities
          </Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>all invoices have been sent to your clients and are paid</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>you have no scheduled meetings</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>all your cases are made inactive</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>
            you remove all documents in your secure vault (this includes making sure you empty your trash folder)
          </Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>
            once all steps above are completed, request final payment from XIRA or pay XIRA if there is an open balance.
            Your wallet balance must be $0.00.
          </Typography>
        </li>
      </ul>
      <Typography className={classes.afterText}>
        XIRA Admin will be notified when these steps have been completed and will then proceed to delete your account
      </Typography>
      <Typography className={classes.afterText}>
        please note, it is your responsibility to ensure all pending activities for your clients have been closed
      </Typography>
    </>
  )

  const openDeleteAccountModal = () => {
    handleClose()
    openDeleteConfirm(true)
  }

  const onDeleteConfirmClose = () => {
    openDeleteConfirm(false)
  }

  const onSuccessModalClose = () => {
    openSuccesModal(false)
    setRequestUuid(uuid)
  }

  const sendDelete = async () => {
    openDeleteConfirm(false)
    try {
      const { data } = await sendDeleteRequest()
      setDeleteRequestUuid(data.uuid)
      openSuccesModal(true)
    } catch (e) {
      showSnackbar('error', 'There was some error while your deletion request')
    }
    setIsModalOpened(false)
  }

  const getIconByStatus = () => {
    return !isApproved ? (
      <UnderReviewLogo className={classes.icon} />
    ) : isHidden ? (
      <InactiveLogo className={classNames(classes.icon, classes.inactiveIcon)} />
    ) : (
      <ActiveLogo className={classNames(classes.icon, classes.activeIcon)} />
    )
  }
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        variant="text"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{ root: classes.button }}
      >
        <div className={classes.buttonRow}>
          {getIconByStatus()}
          <span
            className={classNames(
              classes.menuBtnText,
              { [classes.activeStatus]: isApproved && !isHidden },
              { [classes.inactiveStatus]: isApproved && isHidden }
            )}
          >
            {!isApproved ? 'under review' : isHidden ? 'inactive' : 'active'}
          </span>
        </div>
        <SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.arrowIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ list: classes.menu }}
      >
        <MenuItem onClick={() => (!isApproved ? null : onSelect(true))}>
          <ListItemIcon className={classes.menuItem}>
            <div className={classes.statusItem}>
              {!isApproved ? (
                <UnderReviewLogo className={classes.icon} />
              ) : (
                <ActiveLogo className={classNames(classes.icon, classes.activeIcon)} />
              )}
              <span className={classNames(classes.statusText, { [classes.activeStatus]: isApproved })}>
                {!isApproved ? 'under review' : isHidden ? 'reactivate my profile' : 'active'}
              </span>
            </div>
            {!isApproved ? <CheckIcon /> : isHidden ? null : <CheckIcon />}
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => onSelect(false)} disabled={!isApproved}>
          <ListItemIcon className={classes.menuItem}>
            <div className={classes.statusItem}>
              <InactiveLogo className={classNames(classes.icon, { [classes.inactiveIcon]: isApproved })} />
              <span className={classNames(classes.statusText, { [classes.inactiveStatus]: isApproved })}>
                {!isApproved ? 'deactivate my profile' : isHidden ? 'inactive' : 'deactivate my profile'}
              </span>
            </div>
            {!isApproved ? null : isHidden ? <CheckIcon /> : null}
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={openDeleteAccountModal}>
          <ListItemIcon className={classes.menuItem}>
            <div className={classes.statusItem}>
              <DeleteAccountLogo className={classes.icon} />
              <span className={classes.statusText}>delete my account</span>
            </div>
          </ListItemIcon>
        </MenuItem>
      </Menu>

      <HideProfileConfirmation isOpen={isModalOpened} onClose={onModalClose} onAccept={() => onAccept()} />
      <ConfirmationModal
        title="Delete account"
        message={getDeleteMessage()}
        confirmationButtonText="send request"
        isOpen={isDeleteConfirmOpen}
        onClose={onDeleteConfirmClose}
        onConfirm={sendDelete}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={onSuccessModalClose}
        title="Your request for account deletion was sent to XIRA Admin"
      />
    </div>
  )
}

export default ProfileStatusSelector
