import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Chat, Icon, IconButton, OnlineIndicator, SvgIcon, Typography } from 'components'
import { getChatToken, resetSelectedChat, resetUserMessages, setChatWindowVisibility } from 'store/actions/chat/chat'
import { navigateTo } from 'store/actions/navigation'
import TwilioChat from 'twilio-chat'
import { ReactComponent as ExpandIcon } from 'static/chat/icon _ 24 _ expand.svg'
import { ReactComponent as BackIcon } from 'static/chat/icon _ 24_ arrow-left.svg'
import { ReactComponent as MessageLogo } from 'static/chat/icon_24_message-circle.svg'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles({
  modal: {
    position: 'absolute',
    bottom: 10,
    right: 40,
    border: `1px solid ${colors.BASIC.COLOR['10']}`,
    boxShadow: `0 2px 12px 0 ${colors.BASIC.COLOR[20]}`,
    zIndex: 1299,
    backgroundColor: colors.WHITE,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  rootSmall: {
    height: 350,
    width: 330,
  },
  rootMedium: {
    height: 600,
    width: 490,
  },
  header: {
    backgroundColor: colors.BASIC.COLOR['80'],
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
  },
  backButtonIcon: {
    height: 24,
    width: 24,
    fill: colors.WHITE,
  },
  backButton: {
    backgroundColor: colors.BASIC.COLOR['80'],
    height: 30,
    width: 30,
    padding: 3,
    marginRight: 8,
    display: 'none',
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['80'],
      '& $backButtonIcon': {
        fill: colors.PRIMARY.COLOR['50'],
      },
    },
  },
  expandIcon: {
    height: 24,
    width: 24,
    fill: colors.WHITE,
  },
  expandIconButton: {
    backgroundColor: colors.BASIC.COLOR['80'],
    height: 30,
    width: 30,
    padding: 3,
    marginRight: 12,
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['80'],
      '& $expandIcon': {
        fill: colors.PRIMARY.COLOR['50'],
      },
    },
  },
  closeIcon: {
    color: colors.WHITE,
  },
  closeIconButton: {
    backgroundColor: colors.BASIC.COLOR['80'],
    height: 30,
    width: 30,
    padding: 3,
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['80'],
      '& $closeIcon': {
        color: colors.PRIMARY.COLOR['50'],
      },
    },
  },
  messageLogo: {
    height: 24,
    width: 24,
    marginRight: 8,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: colors.WHITE,
  },
  indicator: {
    marginLeft: 0,
    fontSize: 11,
  },
  indicatorWrapper: {
    display: 'flex',
  },
  transparent: {
    opacity: 0,
  },
  indicatorWrapperSmall: {
    flexDirection: 'column',
    alignItems: 'start',
  },
  indicatorWrapperMedium: {
    flexDirection: 'row',
    alignItems: 'center',
    '& > $indicator': {
      marginLeft: 6,
    },
  },
  '@media(max-height:480px)': {
    responsiveModal: {
      top: 50,
      bottom: 0,
      right: 0,
      left: 0,
      height: 'auto',
      width: 'auto',
      '& $expandIconButton': {
        display: 'none',
      },
      '& $closeIconButton': {
        display: 'none',
      },
      '& $backButton': {
        display: 'block',
      },
    },
    indicatorWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      '& > $indicator': {
        marginLeft: 6,
      },
    },
  },
  '@media(max-width:720px)': {
    responsiveModal: {
      top: 50,
      bottom: 0,
      right: 0,
      left: 0,
      height: 'auto',
      width: 'auto',
      '& $expandIconButton': {
        display: 'none',
      },
      '& $closeIconButton': {
        display: 'none',
      },
      '& $backButton': {
        display: 'block',
      },
    },
    indicatorWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      '& > $indicator': {
        marginLeft: 6,
      },
    },
  },
  '@media(max-width:350px)': {
    indicatorWrapper: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  '@media(max-height:320px)': {
    indicatorWrapper: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
})

const sizes = ['small', 'medium']

const getSize = currentSize => {
  const index = sizes.findIndex(size => currentSize === size)
  if (index === sizes.length - 1) {
    return sizes[0]
  } else {
    return sizes[index + 1]
  }
}

const ChatModal = props => {
  const { responsive = true } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector(state => state.chat.showChatWindow)
  const selectedChat = useSelector(state => state.chat.selectedChat)
  const [size, setSize] = useState('small')

  useEffect(() => {
    return () => {
      dispatch(resetSelectedChat())
      dispatch(setChatWindowVisibility(false))
      dispatch(resetUserMessages())
    }
  }, [])

  const onDialogClose = () => {
    dispatch(resetSelectedChat())
    dispatch(resetUserMessages())
    dispatch(setChatWindowVisibility(false))
    dispatch(navigateTo(props.history.location.pathname))
  }

  const onExpand = () => {
    const newSize = getSize(size)
    setSize(newSize)
  }

  return (
    open && (
      <div
        className={classNames({
          [classes.rootSmall]: size === 'small',
          [classes.rootMedium]: size === 'medium',
          [classes.root]: true,
          [classes.modal]: true,
          [classes.responsiveModal]: responsive,
        })}
      >
        <div className={classes.header}>
          <div className={classes.nameContainer}>
            <IconButton onClick={onDialogClose} className={classes.backButton}>
              <SvgIcon viewBox="0 0 24 24" className={classes.backButtonIcon}>
                <BackIcon />
              </SvgIcon>
            </IconButton>
            <SvgIcon viewBox="0 0 24 24" className={classes.messageLogo}>
              <MessageLogo />
            </SvgIcon>
            <div
              className={classNames({
                [classes.indicatorWrapperSmall]: size === 'small',
                [classes.indicatorWrapperMedium]: size === 'medium',
                [classes.indicatorWrapper]: true,
              })}
            >
              <Typography className={classes.name}>{selectedChat.chat_member_full_name}</Typography>
              <OnlineIndicator
                className={classNames({
                  [classes.indicator]: true,
                  [classes.transparent]: !selectedChat.chat_member_is_online,
                })}
                showText
              />
            </div>
          </div>
          <div>
            <IconButton onClick={onExpand} className={classes.expandIconButton}>
              <SvgIcon viewBox="0 0 24 24" className={classes.expandIcon}>
                <ExpandIcon />
              </SvgIcon>
            </IconButton>
            <IconButton onClick={onDialogClose} className={classes.closeIconButton}>
              <Icon iconClass="las la-times" style={classes.closeIcon} />
            </IconButton>
          </div>
        </div>
        <Chat size={size} selectedChat={selectedChat} />
      </div>
    )
  )
}

export default withRouter(ChatModal)
