import React from 'react'
import { Typography, Icon, Checkbox } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  detailsContainer: {
    marginLeft: 56,
    width: '100%',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
  },
  checkboxLabel: {
    marginBottom: 0,
  },
  subTitle: {
    fontWeight: 600,
  },
  subSpecialityContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  subSpeciality: {
    width: '50%',
  },
  checkbox: {
    '&.Mui-checked.Mui-disabled': {
      color: colors.BASIC.COLOR[50],
      opacity: '0.5',
    },
  },
  margin: {
    marginTop: 4,
  },
  labelContainer: {
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginRight: 8,
  },
})

const PracticeAreasExpansionPanelDetail = ({ area }) => {
  const classes = useStyles()
  return (
    <div className={classes.detailsContainer}>
      {(area.contingency || area.fixed_price) && (
        <div className={classes.checkboxContainer}>
          {area.contingency && (
            <div className={classes.labelContainer}>
              <Icon style={classes.icon} iconClass="las la-check" />
              <Typography variant="body2">Would consider contingency</Typography>
            </div>
          )}
          {area.fixed_price && (
            <div className={classes.labelContainer}>
              <Icon style={classes.icon} iconClass="las la-check" />
              <Typography variant="body2">Would consider flat fee</Typography>
            </div>
          )}
        </div>
      )}
      <Typography variant="subtitle1" className={classes.subTitle}>
        Sub-specialities
      </Typography>
      <div className={classes.subSpecialityContainer}>
        {area.user_sub_specialities.map(item => (
          <div key={item.selected_sub_speciality.uuid} className={classes.subSpeciality}>
            <Typography className={classes.margin} variant="body2">
              {item.selected_sub_speciality.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PracticeAreasExpansionPanelDetail
