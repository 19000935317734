import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { Button, Typography } from 'components'
import { useAnalytics } from 'utils/hooks'

const { BLUE_20, WHITE } = colors

const useStyles = makeStyles({
  contentWrapper: {
    width: '100%',
    fontFamily: 'Rubik',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.47,
    color: colors.BASIC.COLOR[100],
  },
  header: {
    padding: '100px 0',
    background: 'linear-gradient(45deg, #55b4d4, #0f7699)',
    textAlign: 'center',
    marginBottom: 40,
  },
  headerTitle: {
    fontFamily: 'Raleway',
    fontSize: 86,
    fontWeight: 300,
    color: WHITE,
  },
  mainTitle: {
    fontSize: 36,
    fontWeight: 'bold',
    marginTop: 0,
  },
  textWrapper: {
    maxWidth: 1044,
    margin: '0 auto',
    paddingBottom: 70,
    scrollBehavior: 'smooth',
    '@media(max-width:1280px)': {
      padding: '0 15px 70px 15px',
    },
  },
  contentTitle: {
    display: 'block',
    fontSize: 20,
    fontWeight: 500,
    margin: '0 0 16px 0',
  },
  contentParagraph: {
    marginTop: 0,
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 36,
    },
  },
  conditionsContentLink: {
    color: colors.PRIMARY.COLOR[50],
    textDecoration: 'none',
  },
  conditionsBlueLink: {
    color: BLUE_20,
  },
  conditionsContentUnderline: {
    textDecoration: 'underline',
  },
  conditionsContentMedium: {
    fontWeight: 500,
  },
})

const PrivacyPolicy = props => {
  const { className } = props
  const classes = useStyles()
  useAnalytics()
  const textData = [
    {
      title: 'Introduction',
      paragraph: [
        <span>
          This privacy policy (this <i className={classes.conditionsContentMedium}>“Privacy Policy”</i> or{' '}
          <i className={classes.conditionsContentMedium}>“Policy”</i>) governs the manner in which XIRA Connect, Inc. (
          <i className={classes.conditionsContentMedium}>“XIRA,” “we,” “our,” “us”</i> and similar terms) collects,
          uses, maintains and discloses information collected from users who register for and/or use services available
          on or through its websites, including{' '}
          <a href="/" target="_blank" rel="nofollow noopener noreferrer" className={classes.conditionsContentLink}>
            http://www.xira.com
          </a>{' '}
          and/or software applications or mobile applications, including the XIRA application (collectively, the{' '}
          <i className={classes.conditionsContentMedium}>“Service”</i>).
        </span>,
        'We take reasonable steps to protect user privacy consistent with the guidelines set forth in this Policy and with applicable U.S. state and federal laws.  WE TAKE THESE PRECAUTIONS IN AN EFFORT TO PROTECT YOUR INFORMATION AGAINST SECURITY BREACHES.  HOWEVER, THIS IS NOT A GUARANTEE THAT SUCH INFORMATION MAY NOT BE ACCESSED, DISCLOSED, ALTERED, OR DESTROYED BY BREACH OF SUCH FIREWALLS AND SECURE SERVER SOFTWARE.  BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU UNDERSTAND AND AGREE TO ASSUME THESE RISKS.',
        <span>
          In this Policy, <i className={classes.conditionsContentMedium}>“user”</i> or{' '}
          <i className={classes.conditionsContentMedium}>“you”</i> means any individual using the Service. This Policy
          forms part of and is incorporated into our Terms of Service published at{' '}
          <a
            href="/user-agreement"
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={classes.conditionsContentLink}
          >
            http://www.xira.com/tos
          </a>{' '}
          (the <i className={classes.conditionsContentMedium}>“Terms of Service”</i>).
        </span>,
        'BY ACCESSING OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THIS PRIVACY POLICY AND OUR TERMS OF SERVICE.  IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE.',
      ],
    },
    {
      title: 'To Whom Does this Policy Apply?\n',
      paragraph: [
        'This Privacy Policy applies to everyone who uses our Service.  Portions of this Policy are directed toward particular groups of users as defined below:',
        <span>
          <span className={classes.conditionsContentMedium}>Clients:</span> “Clients” are users who use the Service for
          the purpose of connecting with attorney users (“Attorneys”), and who may engage Attorneys for the provision of
          legal services through the Service.
        </span>,
        <span>
          <span className={classes.conditionsContentMedium}>Attorneys:</span> “Attorneys” are attorney users who use the
          Service to identify prospective Clients and for facilitation of the provision of legal services they provide
          to Clients.
        </span>,
      ],
    },
    {
      title: 'What Information Do We Collect? ',
      paragraph: [
        <span>
          <span className={classes.conditionsContentUnderline}>
            Limited Personal Information Collected from All Users
          </span>
          : All information which can be used to personally identify an individual is referred to in this Policy as{' '}
          <i className={classes.conditionsContentMedium}>“Personal Information.”</i> We may collect certain Personal
          Information about you in connection with your use of the Service. If you communicate with us by email but do
          not otherwise register to use the Service, we will collect your email address.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Attorney Users</span>: If you register to use the Service
          as an Attorney, we will collect a username and password from you. We will also collect information through our
          online registration form, such as your name, email address, mailing address, phone number, photograph and
          optional video recording, and additional information about your professional background, including bar
          registration number and states of admission (
          <i className={classes.conditionsContentMedium}>“Attorney Profile Information”</i>). Once XIRA has verified bar
          admission and attorney registration information, Attorneys may provide additional professional information to
          their XIRA Attorney Profile Information including practice areas and educational information, and may link
          their XIRA profile to social media profiles including those on Facebook and LinkedIn, and import calendar
          information from third party calendar applications. XIRA will not view, collect or download any information from 
          your calendar and will only access it to determine if your calendar shows you as free or busy. Xira will not modify 
          your calendar in any way except to add, reschedule or cancel meetings and events scheduled on Xira. If you choose to 
          link your XIRA profile to your social
          media profiles, you understand that the information these sites provide to XIRA is governed by their privacy
          policies and your account settings on those sites. If you provide documents which include Personal Information
          in connection with your provision of services through the Service (
          <i className={classes.conditionsContentMedium}>“Attorney Documents”</i>), those documents will be stored with
          a third-party contractor engaged to provide services on our behalf (
          <i className={classes.conditionsContentMedium}>“Contractor”</i>) contracted by XIRA for hosting services and
          shared with the XIRA users you have designated, if any. You may also provide information about your Clients to
          the Service which includes Personal Information.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Client Users</span>: If you register to use the Service
          as a Client, we may collect information including your name, email address, mailing address, phone number,
          username and password. If you directly disclose Personal Information or personally sensitive data through
          public areas of the Service or messages with other users, this information may be collected and used by
          others. If you provide documents which include Personal Information through the Service in connection with
          your receipt of services through the Service (
          <i className={classes.conditionsContentMedium}>“Client Documents”</i>), those documents will be stored with a
          third-party Contractor contracted by XIRA for hosting services and shared with the XIRA users you have
          designated, if any. If you contact XIRA or another user and disclose additional Personal Information, we may
          store that information.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Web Tracking Information and Cookies</span>: We and our
          Contractors may use web tracking technologies such as cookies, pixel tags and clear GIFs in order to operate
          the Service efficiently and to collect data related to usage of the Service. Such collected data (
          <i className={classes.conditionsContentMedium}>“Web Tracking Information”</i>) may include the address of
          other websites you have visited, the type of browser you are using, your Internet Protocol (IP) address,
          device model, device ID and other unique device identifiers, what pages in the Service you visit and what
          links you clicked on, and whether you opened email communications we sent to you. We and our Contractors may
          also use web tracking technologies that are placed in web pages on the Service or in email communications to
          collect information about actions that you take when you interact with the Service or such email
          communications. We do not correlate Web Tracking Information to individual user Personal Information. Some Web
          Tracking Information may include data, such as IP address data, that is unique to you. We use this information
          not to identify individual users, but to analyze trends, administer the Service, track users’ movements around
          the Service, and gather demographic information about our user base as a whole which provides us with the
          ability to determine aggregate information about our user base and usage patterns.
        </span>,
        'The XIRA website also uses cookies to enhance the browsing experience.  A cookie is a small text file or record that is stored on your computer when you visit our website, which collects information about your activities on the website.  Cookies transmit this information back to the computers at XIRA or our third-party distributors of banners and newsletters; these computers are, generally speaking, the only computers which are authorized to read such information.  The information captured makes it possible for us to: (i) speed up navigation, and provide you with custom tailored content; (ii) remember information you give to us, so you don’t have to reenter it each time you visit the website; (iii) monitor the effectiveness of our marketing campaigns; and (iv) monitor total number of visitors, pages viewed, and the total number of banners served.  You can choose to have your browser warn you every time a cookie is being sent to you or you can turn off cookie placement.  Also, by not using cookies, your overall internet browsing experience will be affected.',
        <span>
          <span className={classes.conditionsContentUnderline}>Payment Information</span>: If you make transactions
          through the Service, you will be required to provide payment information, such as your credit or debit card
          number and other card information; other account and authentication information; and billing and contact
          details, to help process your transactions as further detailed in{' '}
          <span className={classes.conditionsContentUnderline}>User Payment Information</span> below.
        </span>,
      ],
    },
    {
      title: 'How Do We Use the Information We Collect?',
      paragraph: [
        <span>
          <span className={classes.conditionsContentUnderline}>Personal Information</span>: We will use and store your
          Personal Information for the purpose of delivering the Service, and to analyze and enhance the operation of
          the Service. We may also use your Personal Information for the internal operational and administrative
          purposes of the Service, including to facilitate provision and receipt of services between Attorneys and
          Clients. If you become a registered User, we will use your Personal Information including your name and e-mail
          address to communicate with you regarding the functionality of the Service, including to send you messages
          regarding service requests and new messages in your account from other Users.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>User Payment Information</span>: If you make or receive
          payments on or through the Service, we may use Personal Information that you provide for one-time and
          recurring billing purposes according to the terms pertaining to such payment. If we (or our payment processor)
          have trouble processing a payment, such information may be used to contact you. Payment card information is
          sent in encrypted format and is used only to send to our payment card processor, for the purposes of
          collecting or remitting payment for services ordered. We do not make this information visible to anyone other
          than our payment card processor and do not communicate it over a non-encrypted connection. The full payment
          card number is not seen by anyone inside XIRA, once entered and submitted, except by the payment card
          processor for the purposes of authorizing, clearing and reversing charges to your payment card.
        </span>,
        <span>
          Payment processing services for the Service are provided by Stripe, Inc. (
          <i className={classes.conditionsContentMedium}>“Stripe”</i>), and are subject to the{' '}
          <a
            href="https://stripe.com/connect-account/legal"
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={classes.conditionsBlueLink}
          >
            Stripe Connected Account Agreement
          </a>
          , which includes the{' '}
          <a
            href="https://stripe.com/legal"
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={classes.conditionsBlueLink}
          >
            Stripe Services Agreement
          </a>{' '}
          and{' '}
          <a
            href="https://stripe.com/privacy"
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={classes.conditionsBlueLink}
          >
            Stripe Privacy Policy
          </a>
          . Users are required to share payment information with Stripe as necessary to process payments.{' '}
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Web Tracking Information</span>: We use Web Tracking
          Information to administer the Service and to understand how well the Service is working, to store your user
          preferences, and to develop statistical information on usage of the Services. This allows us to determine
          which features visitors like best to help us improve the Service, to personalize your user experience, and to
          measure overall effectiveness.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Aggregate and De-Identified Information</span>: We will
          also create statistical, aggregated and/or de-identified data relating to our users and the Service for
          analytical and commercial purposes. Aggregated and/or de-identified data is derived from Personal Information
          but in its aggregated and/or de-identified form, it does not duplicate or reveal any Personal Information or
          relate to or identify any individual. This data is used to understand our customer base and to develop,
          improve and market our services.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Legal Exception</span>: Notwithstanding the above, we may
          store and use Personal Information to the extent required by law or legal process, to resolve disputes, to
          enforce our agreements (including this Privacy Policy and the Terms of Service) with you, or if in our
          reasonable discretion use is necessary to protect our legal rights or to protect third parties.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Promotional Communications</span>: If you register and
          provide your email address, we may send you administrative and promotional emails.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Account Closure</span>: If any of your account
          information appears to be incorrect, please contact us and let us know. If you close your account, we will
          delete any Personal Information associated with your account, including Client information, except for
          information that we are required to retain for our business records. Other information you submitted to us
          that is not associated with you personally may not be deleted.
        </span>,
      ],
    },
    {
      title: 'What Information Do We Disclose to Third Parties?',
      paragraph: [
        <span>
          <span className={classes.conditionsContentUnderline}>Personal Information</span>. We will not disclose your
          Personal Information to any third parties except as follows:
        </span>,
        '(i) If you are a Client and engage an Attorney to perform services on your behalf via the Service, we may disclose your name, email address, other contact information, and Client Documents you submit through the Service related to the matters for which you have engaged the Attorney, to such Attorney.',
        '(ii) If you are an Attorney, we may disclose your Attorney Profile Information to Clients who may engage or have engaged you to perform services on their behalf, and, with your permission, we may disclose Attorney Documents which include your Personal Information to your Clients.',
        '(iii) To third-party Contractors, such as analyzing data and usage of the Service, hosting and operating the Service, providing marketing, payment processing or support and maintenance services for the Service or providing customer service.  We enter into agreements with all Contractors that require Contractors to use the Personal Information they receive only to perform services for us.',
        '(iv) When we have your consent to share the information.',
        <span>
          <span className={classes.conditionsContentUnderline}>Web Tracking Information</span>: We disclose Web Tracking
          Information to Contractors, in order to analyze the performance of the Service and the behavior of users, and
          to operate and improve the Service.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Aggregate and De-Identified Information</span>: We may
          disclose aggregated and/or de-identified data that does not contain Personal Information to any third parties,
          such as Contractors, business partners, marketers, and funding sources, in order to describe our business and
          operations or to notify you about products and services that may be relevant to you.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Network Operators</span>: Use of the Service may involve
          use of the services of third-party telecommunications carriers. Such carriers are not our contractors, and any
          information that a carrier collects in connection with your use of the Service is not “Personal Information”
          and is not subject to this Privacy Policy. We are not responsible for the acts or omissions of
          telecommunications carriers.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Additional Disclosures</span>: We reserve the right to
          disclose any information we collect in connection with the Service, including Personal Information: (a) to any
          successor to our business as a result of any merger, acquisition, asset sale or similar transaction; (b) to
          protect against or prevent fraud or unauthorized transactions; and (b) any law enforcement, judicial
          authority, or governmental or regulatory authority, to the extent required by law or if in our reasonable
          discretion disclosure is necessary to enforce or protect our legal rights or to protect third parties.
        </span>,
      ],
    },
    {
      title: 'Privacy Settings/Opt Out',
      paragraph: [
        <span>
          If you would like your name and email address permanently removed from our database, please contact us at{' '}
          <a className={classes.conditionsContentLink} href="mailto:privacy@xira.com">
            privacy@xira.com
          </a>
          . We will promptly delete your name and email address and you will no longer receive email from XIRA. Your
          removal from the mailing list or database will not remove data you have submitted to us or records of past use
          of the Service, nor delete information stored in our data backups and archives. Such data will be maintained
          and/or deleted in the ordinary course of XIRA’s business.
        </span>,
        'If you wish to opt out of promotional emails (but would not like to have your information permanently removed from our database), you may do so by following the “unsubscribe” instructions in the email, or by modifying your account settings.  All users receive administrative emails, and so you cannot opt out of them while you remain registered.',
        'If you have provided Client Documents or Attorney Documents to XIRA, you may remove those documents from the Service at any time. Your removal of such documents will not delete information stored in our third-party storage Contractor’s data backups and archives. Such information will be deleted in the ordinary course of the storage Contractor’s business.',
      ],
    },
    {
      title: 'General',
      paragraph: [
        <span>
          <span className={classes.conditionsContentUnderline}>Security</span>: We use reasonable security precautions
          to protect the security and integrity of your Personal Information in accordance with this Policy and
          applicable law. However, no internet transmission is completely secure, and we cannot guarantee that security
          breaches will not occur. Without limitation of the foregoing, we are not responsible for the actions of
          hackers and other unauthorized third parties that breach our reasonable security procedures.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Links</span>: The XIRA Service may contain links to other
          websites. XIRA is not responsible for the privacy practices or the content of those websites. Users should be
          aware of this when they leave the Service and review the privacy statements of each third-party website. This
          Privacy Policy applies solely to information collected by the Service. We encourage our users to read the
          privacy policies of these other websites before proceeding to use them.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Amendments</span>: XIRA may modify or amend this Policy
          from time to time. If we make any material changes, as determined by XIRA, in the way in which Personal
          Information is collected, used or transferred, we will notify you of these changes by email. Notwithstanding
          any modifications we may make, any Personal Information collected by XIRA from you will be treated in
          accordance with the Privacy Policy in effect at the time such information was collected, unless we obtain your
          consent otherwise.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Minors</span>: XIRA does not knowingly collect or
          maintain personally identifiable information from persons under 18 years of age, and no part of the Service is
          directed at persons under 18. If you are under 18 years of age, then please do not use the Service. If XIRA
          learns that personally identifiable information of persons less than 13 years of age has been collected, then
          XIRA will take the appropriate steps to delete this information. To request the deletion of any such
          information, please contact us at{' '}
          <a className={classes.conditionsContentLink} href="mailto:privacy@xira.com">
            privacy@xira.com
          </a>
          .
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Users from outside the United States</span>: XIRA and its
          servers are located in the United States and are subject to the applicable state and federal laws of the
          United States. If you choose to access the Service, you consent to the use and disclosure of information in
          accordance with this Privacy Policy and subject to such laws.
        </span>,
        <span>
          <span className={classes.conditionsContentUnderline}>Your California Privacy Rights</span>: On September 27,
          2013, California enacted A.B. 370, amending the California Online Privacy Protection Act to require website
          operators like us to disclose how we respond to "Do Not Track Signals"; and whether third parties collect
          personally identifiable information about users when they visit us. <br /> (1) We honor “do not track” signals
          and do not track, use cookies, or use advertising when a “do not track” mechanism is in place. <br /> (2) We
          do not authorize the collection of personally identifiable information from our users for third party use
          through advertising technologies.
        </span>,
        'California Civil Code Section § 1798.83 permits users of our Website that are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to privacy@xira.. Please note that we are only required to respond to one request per user each year.',
      ],
    },
    {
      title: 'Contact Information',
      paragraph: [
        <span>
          XIRA welcomes your comments regarding this Privacy Policy. If you believe that XIRA has not adhered to this
          Privacy Policy, please contact XIRA at 
          <a className={classes.conditionsContentLink} href="mailto:privacy@xira.com">
            privacy@xira.com
          </a>
          . We will use commercially reasonable efforts to promptly investigate and remedy the problem.
        </span>,
        'Effective Date of this Policy: June 18, 2020',
      ],
    },
  ]

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.header}>
        <Typography variant="h2" className={classes.headerTitle}>
          privacy policy
        </Typography>
      </div>
      <div className={classes.textWrapper}>
        {textData.map(item => {
          return (
            <div key={item.title}>
              <h4 className={classes.contentTitle}>
                &#8226;
                {` ${item.title}`}
              </h4>
              {item.paragraph.map((text, index) => {
                return (
                  <p key={index} className={classes.contentParagraph}>
                    {text}
                  </p>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PrivacyPolicy
