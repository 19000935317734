import 'firebase/analytics'
import ReactGA from 'react-ga'
import history from '../../history'

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTCS_TRACKING_ID

export class GoogleAnalyticsService {
  constructor() {
    ReactGA.initialize(trackingId)
    ReactGA.pageview(window.location.pathname)
    history.listen(location => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
  }
}
