import React, { useEffect } from 'react'
import { Modal, Typography, ActivitiesForm, Button, ColorCircularProgress } from 'components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { createProActivity, getProActivities, updateProActivity } from '../../store/actions/activities'
import { getFilterProCases } from '../../store/actions/cases'
import { getUserConnectionsForFilter } from '../../store/actions/connections'
import Slide from '@material-ui/core/Slide'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  proActivityEntryContainer: {
    height: '100%',
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 4,
  },
  submitButton: {
    marginLeft: 20,
    minHeight: 40,
    padding: '9px 24px',
  },
  cancelButton: {
    minHeight: 40,
    height: 40,
  },
  textContainer: {
    paddingLeft: 24,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  paper: {
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 0,
    height: '100%',
    width: '90%',
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const ProActivityEntryModal = ({ open, handleClose, type, activity = {}, action }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filterCasesAreLoading = useSelector(state => state.cases.filterCasesAreLoading)

  useEffect(() => {
    if (open) {
      dispatch(getFilterProCases())
      dispatch(getUserConnectionsForFilter())
    }
  }, [open])

  const mode = action === 'create' ? 'create' : activity.editMode ? 'edit' : 'view'
  const FormButtons = ({ disabled }) => {
    return (
      <>
        <Button variant="text" className={classes.cancelButton} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" className={classes.submitButton} disabled={disabled}>
          {action === 'create' ? 'Create' : 'Save'}
        </Button>
      </>
    )
  }

  const ModalHeader = () => (
    <div className={classes.textContainer}>
      {action === 'create' ? (
        <>
          <Typography className={classes.title} variant="h2">
            New {type} entry
          </Typography>
          <Typography className={classes.subtitle} variant="h2">
            Please review your entry carefully before creating, entries cannot be revised after an invoice is created
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.title} variant="h2">
            {activity.case && activity.case.fee_type !== 0
              ? `View / Edit ${type} activity`
              : 'Initial case entry details'}
          </Typography>
          <Typography className={classes.subtitle} variant="h2">
            {activity.case && activity.case.fee_type === 1
              ? `To manage this activity, please update partial invoicing amount information in the case`
              : ''}
            {activity.case && activity.case.fee_type === 0
              ? `This entry is created automatically by the system to record charge for initial consultation. Once initial consultation ends, invoice will be generated and sent to the user.`
              : ''}
          </Typography>
        </>
      )}
    </div>
  )
  const onSubmit = values => {
    if (action === 'create') {
      dispatch(createProActivity(values)).then(() => {
        handleClose()
        dispatch(getProActivities())
      })
    } else {
      dispatch(updateProActivity(activity.uuid, values)).then(() => {
        handleClose()
        dispatch(getProActivities())
      })
    }
  }

  return (
    <Modal
      open={open}
      TransitionComponent={Transition}
      maxWidth="xl"
      handleClose={handleClose}
      dialogClasses={{ paper: classes.paper }}
    >
      <div className={classes.proActivityEntryContainer}>
        {filterCasesAreLoading ? (
          <ColorCircularProgress />
        ) : (
          <ActivitiesForm
            header={<ModalHeader />}
            type={type}
            activity={activity}
            onSubmit={onSubmit}
            mode={mode}
            footer={<FormButtons />}
          />
        )}
      </div>
    </Modal>
  )
}

export default ProActivityEntryModal
