import React from 'react'
import { Switch } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const XSwitch = ({
  checked,
  classes,
  color,
  disabled,
  inputProps,
  className,
  onChange,
  label,
  name,
  formControlClasses,
}) =>
  label ? (
    <FormControlLabel
      classes={formControlClasses}
      control={
        <Switch
          name={name}
          checked={checked}
          classes={classes}
          color={color}
          disabled={disabled}
          inputProps={inputProps}
          className={className}
          onChange={onChange}
        />
      }
      label={label}
    />
  ) : (
    <Switch
      name={name}
      checked={checked}
      classes={classes}
      color={color}
      disabled={disabled}
      inputProps={inputProps}
      className={className}
      onChange={onChange}
    />
  )

export default XSwitch
