import React from 'react'
import { Modal } from 'components'
import { navigateToRoot } from 'store/actions/navigation'
import { useDispatch } from 'react-redux'
import SuccessPasswordUpdate from './SuccessPasswordUpdate'
import FailPasswordUpdate from './FailPaswordUpdate'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  closeButton: {
    display: 'none',
  },
})
const PasswordUpdatedModal = ({ modalSettings, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpen, state, close, submit } = modalSettings
  const closeModal = () => {
    close()
    dispatch(navigateToRoot())
  }
  return (
    <Modal
      open={isOpen}
      handleClose={state === 'success' ? closeModal : null}
      scroll="body"
      closeButtonClass={classes.closeButton}
    >
      <div>
        {state === 'success' ? <SuccessPasswordUpdate submit={submit} /> : <FailPasswordUpdate submit={submit} />}
      </div>
    </Modal>
  )
}

export default PasswordUpdatedModal
