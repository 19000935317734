import React, { useEffect, useReducer } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { ReactComponent as XiraLogoSvg } from 'static/Logo_Color_Primary.svg'
import { ReactComponent as ReviewDoneSvg } from 'static/review/review_done.svg'
import { colors, MESSAGES } from 'constants/index'
import { Typography, Button, Rating, TextArea, ColorCircularProgress, NotFound } from 'components'
import { openSnackbar, showNotFound, hideNotFound } from 'store/actions/common'
import { navigateToRoot } from 'store/actions/navigation'
import Analytics from 'utils/analytics/AnalyticsService'
import { mapRatingAnalytics, mapRatingSubmitAnalytics } from 'utils/mappers/backend'
import { EVENTS } from 'utils/analytics/Events'

const useStyles = makeStyles({
  clientReviewContainer: {
    backgroundColor: colors.BASIC.COLOR['0'],
    display: 'flex',
    alignItems: 'center',
    padding: 32,
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100%',
  },
  clientReviewHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  clientReviewTitle: {
    marginTop: 32,
  },
  xiraReviewTitle: {
    marginTop: 40,
    marginBottom: 16,
  },
  clientReviewSubtitle: {
    marginTop: 8,
    marginBottom: 24,
    fontWeight: 'normal',
  },
  clientReviewQuestionsWrapper: {
    width: 636,
    backgroundColor: colors.WHITE,
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
  },
  clientReviewQuestionsContainer: {
    display: 'flex',
    padding: '32px 40px',
    flexDirection: 'column',
    minHeight: 'fit-content',
  },
  clientReviewDone: {
    display: 'flex',
    padding: 32,
    flexDirection: 'column',
    alignItems: 'center',
  },
  reviewCloseTitle: {
    fontSize: 25,
    lineHeight: 1.44,
    marginBottom: 8,
    textAlign: 'center',
  },
  reviewCloseSubtitle: {
    fontSize: 15,
    lineHeight: 1.47,
    marginBottom: 32,
    fontWeight: 'normal',
  },
  reviewBtn: {
    marginTop: 24,
    padding: '15px 40px',
    fontSize: 15,
    letterSpacing: 1,
    lineHeight: 1.13,
    width: 'fit-content',
    alignSelf: 'center',
  },
  reviewQuestion: {
    fontSize: 16,
    lineHeight: 1.75,
  },
  ratingStarsContainer: {
    marginRight: 25,
  },
  ratingStars: {
    fontSize: 50,
  },
  reviewQuestionContainer: {
    marginBottom: 32,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  reviewCommentContainer: {
    marginBottom: 32,
    width: 478,
  },
  reviewQuestionAnswer: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  reviewQuestionRow: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingComment: {
    width: '100%',
  },
  reviewCommentTip: {
    color: colors.BASIC.COLOR['50'],
    fontSize: 13,
  },
  '@media (min-width: 320px) and (max-width: 768px)': {
    clientReviewQuestionsWrapper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '100%',
    },
    clientReviewQuestionsContainer: {
      padding: 16,
    },
    reviewCommentContainer: {
      width: '100%',
    },
  },
  reviewCloseBtn: {
    marginTop: 32,
  },
  logoCompleted: {
    marginBottom: 89,
    '@media (min-width: 320px) and (max-width: 768px)': {
      marginBottom: 32,
    },
  },
})

const initialReviewState = {
  loading: true,
  uuid: '',
  formatted_title: '',
  is_completed: false,
  pro_email: '',
  questionnaire: {
    rating_type: '',
    comment_enabled: false,
    questions: [],
    comment: '',
    xiraQuestions: [
      { uuid: 1, content: 'please rate your XIRA experience', rating: 0 },
      { uuid: 2, content: 'lorem ipsum bla', rating: 0 },
    ],
    xira_comment_enabled: false,
    xiraComment: '',
  },
}

function reviewReducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_DATA':
      return {
        ...state,
        uuid: action.payload.uuid,
        pro_email: action.payload.pro_email,
        formatted_title: action.payload.formatted_title,
        is_completed: action.payload.is_completed,
        questionnaire: {
          ...state.questionnaire,
          ...action.payload.questionnaire,
          questions: action.payload.questionnaire.questions.map(question => ({ ...question, rating: 0 })),
          xiraQuestions: action.payload.questionnaire.xira_questions.map(question => ({ ...question, rating: 0 })),
        },
      }
    case 'REVIEW_UPDATE':
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          questions: action.payload,
        },
      }
    case 'COMMENT_UPDATE':
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          comment: action.payload,
        },
      }
    case 'XIRA_REVIEW_UPDATE':
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          xiraQuestions: action.payload,
        },
      }
    case 'XIRA_COMMENT_UPDATE':
      return {
        ...state,
        questionnaire: {
          ...state.questionnaire,
          xiraComment: action.payload,
        },
      }
    case 'REVIEW_COMPLETE':
      return {
        ...state,
        is_completed: true,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }
    default:
      throw new Error()
  }
}

const ClientRatingQuestion = ({ reviewItem, handleRating, number, xiraRating }) => {
  const classes = useStyles()

  return (
    <div className={classes.reviewQuestionContainer}>
      <Typography variant={'h6'} className={classes.reviewQuestion}>
        {`${number}. ${reviewItem.content}`}
      </Typography>
      <div className={classes.reviewQuestionRow}>
        <Rating
          name={`${reviewItem.uuid}_rating`}
          onChange={(e, value) => handleRating(value, reviewItem.uuid, xiraRating)}
          value={reviewItem.rating}
          ratingClasses={{ sizeLarge: classes.ratingStars }}
          ratingContainerClasses={classes.ratingStarsContainer}
          size={'large'}
        />
      </div>
    </div>
  )
}

const ClientRatingComment = ({ comment, handleCommentChange, number, xiraRating }) => {
  const classes = useStyles()

  return (
    <div className={classes.reviewCommentContainer}>
      <Typography variant={'h6'} className={classes.reviewQuestion}>
        {`${number}. Leave here any other comments`}
      </Typography>
      <div className={classes.reviewQuestionRow}>
        <TextArea
          handleChange={e => handleCommentChange(e, xiraRating)}
          value={comment}
          variant="outlined"
          areaClass={classes.noBorders}
          placeholder="Place your text"
          rowsMin={3}
          wrapperClass={classes.ratingComment}
          characterLimit={500}
          showCharacterLimit={false}
        />
      </div>
      {!xiraRating && (
        <Typography variant={'body2'} className={classes.reviewCommentTip}>
          once your comments have been reviewed, they may be published on the lawyer's page and will be visible to
          everyone
        </Typography>
      )}
    </div>
  )
}

const ClientRatingPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [state, dispatchReview] = useReducer(reviewReducer, initialReviewState)
  const notFound = useSelector(state => state.common.showNotFound)
  const ratingID = match.params.ratingID
  const ratingPath = `/api/v1/ratings/questionnaires/${ratingID}/`

  useEffect(() => {
    getRatingData()
      .then(data => {
        dispatchReview({ type: 'SET_INITIAL_DATA', payload: data })
        Analytics.track(EVENTS.CLIENT_OPEN_FEEDBACK, mapRatingAnalytics(data))
      })
      .catch(e => console.log(e))
    return () => {
      dispatch(hideNotFound())
    }
  }, [])

  const handleRating = (value, updatedItemID, isXIRAReview) => {
    const questions = isXIRAReview ? state.questionnaire.xiraQuestions : state.questionnaire.questions
    const type = isXIRAReview ? 'XIRA_REVIEW_UPDATE' : 'REVIEW_UPDATE'
    const updatedReview = questions.map(reviewItem => {
      return reviewItem.uuid === updatedItemID ? { ...reviewItem, rating: value } : reviewItem
    })
    dispatchReview({ type: type, payload: updatedReview })
  }

  const handleCommentChange = (e, isXIRAReview) => {
    const type = isXIRAReview ? 'XIRA_COMMENT_UPDATE' : 'COMMENT_UPDATE'
    dispatchReview({ type: type, payload: e.target.value })
  }

  const handleCloseRating = e => {
    dispatch(navigateToRoot())
  }

  const handleReviewComplete = async () => {
    if (!state.questionnaire.questions.some(question => question.rating)) {
      dispatch(openSnackbar('error', MESSAGES.М0112))
      return
    }
    dispatchReview({ type: 'SET_LOADING', payload: true })
    const answers = state.questionnaire.questions
      .filter(question => question.rating)
      .map(question => ({ question: question.uuid, rating: question.rating }))
    const xiraAnswers = state.questionnaire.xiraQuestions
      .filter(question => question.rating)
      .map(question => ({ question: question.uuid, rating: question.rating }))

    const xiraRatingBody = state.questionnaire.xiraComment
      ? {
          xira_questions: xiraAnswers,
          xira_comment: state.questionnaire.xiraComment,
        }
      : {
          xira_questions: xiraAnswers,
        }

    const laywerRatingBody = state.questionnaire.comment
      ? {
          comment: state.questionnaire.comment,
          user_questions: answers,
        }
      : {
          user_questions: answers,
        }

    try {
      await axios.put(ratingPath, { ...laywerRatingBody, ...xiraRatingBody })
      dispatchReview({ type: 'REVIEW_COMPLETE' })
      Analytics.track(
        EVENTS.CLIENT_SUBMITTED_FEEDBACK,
        mapRatingSubmitAnalytics(state, { ...laywerRatingBody, ...xiraRatingBody })
      )
    } catch (e) {
      dispatch(openSnackbar('error', 'Error while saving review'))
    } finally {
      dispatchReview({ type: 'SET_LOADING', payload: false })
    }
  }

  const getRatingData = async () => {
    dispatchReview({ type: 'SET_LOADING', payload: true })
    try {
      const { data } = await axios.get(ratingPath)
      return Promise.resolve(data)
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(showNotFound())
      } else {
        dispatch(openSnackbar('error', 'Error while getting review questions'))
      }
      return Promise.reject('Error while getting review questions')
    } finally {
      dispatchReview({ type: 'SET_LOADING', payload: false })
    }
  }

  return (
    <div className={classes.clientReviewContainer}>
      <div className={classNames({ [classes.logoCompleted]: state.is_completed })}>
        <XiraLogoSvg />
      </div>
      {!state.is_completed && (
        <div className={classes.clientReviewHeader}>
          <Typography variant={'h3'} className={classes.clientReviewTitle}>
            rate your lawyer
          </Typography>
          <Typography variant={'body1'} className={classes.clientReviewSubtitle}>
            {state.formatted_title}
          </Typography>
        </div>
      )}
      {state.loading ? (
        <ColorCircularProgress />
      ) : (
        <>
          {notFound ? (
            <NotFound />
          ) : (
            <>
              {state.is_completed ? (
                <div className={classNames(classes.clientReviewDone, classes.clientReviewQuestionsWrapper)}>
                  <Typography variant={'h3'} className={classes.reviewCloseTitle}>
                    thank you for your feedback!
                  </Typography>
                  <Typography variant={'h5'} className={classes.reviewCloseSubtitle}>
                    {state.formatted_title}
                  </Typography>
                  <ReviewDoneSvg />
                  <Button
                    variant={'primary'}
                    className={classNames(classes.reviewCloseBtn, classes.reviewBtn)}
                    onClick={handleCloseRating}
                  >
                    go to xira
                  </Button>
                </div>
              ) : (
                <>
                  <div
                    className={classNames(classes.clientReviewQuestionsContainer, classes.clientReviewQuestionsWrapper)}
                  >
                    {state.questionnaire.questions.map((reviewItem, i, array) => (
                      <ClientRatingQuestion
                        reviewItem={reviewItem}
                        key={reviewItem.uuid}
                        handleRating={handleRating}
                        number={i + 1}
                      />
                    ))}
                    {state.questionnaire.comment_enabled && (
                      <ClientRatingComment
                        comment={state.questionnaire.comment}
                        handleCommentChange={handleCommentChange}
                        number={state.questionnaire.questions.length + 1}
                      />
                    )}
                  </div>
                  {!!state.questionnaire.xiraQuestions.length && (
                    <>
                      <Typography variant={'h3'} className={classes.xiraReviewTitle}>
                        rate XIRA
                      </Typography>
                      <div
                        className={classNames(
                          classes.clientReviewQuestionsContainer,
                          classes.clientReviewQuestionsWrapper
                        )}
                      >
                        {state.questionnaire.xiraQuestions.map((reviewItem, i) => (
                          <ClientRatingQuestion
                            reviewItem={reviewItem}
                            key={reviewItem.uuid}
                            handleRating={handleRating}
                            number={i + 1}
                            xiraRating
                          />
                        ))}
                        {state.questionnaire.xira_comment_enabled && (
                          <ClientRatingComment
                            comment={state.questionnaire.xiraComment}
                            handleCommentChange={handleCommentChange}
                            xiraRating
                            number={state.questionnaire.xiraQuestions.length + 1}
                          />
                        )}
                      </div>
                    </>
                  )}
                  <Button variant={'primary'} className={classes.reviewBtn} onClick={handleReviewComplete}>
                    complete
                  </Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ClientRatingPage
