import * as CONSTANTS from 'store/constants/accountProfile'

const initialState = {
  allDegrees: [],
  areAllDegreesLoading: false,
  bio: '',
  dbBio: '',
  degrees: [],
  areDegreesLoading: false,
  languages: [],
  languagesDB: [],
  languagesAreLoading: false,
  deleteRequestIsloading: false,
  deletionRequestUuid: '',
  isProfileHidden: false,
  rating: null,
  reviews: null,
  ratingStatus: null,
  ratingRequestStatusLoading: false,
  socialAccounts: {
    facebook: {
      uuid: '',
      social_account: 'facebook',
      link: '',
    },
    linkedin: {
      uuid: '',
      social_account: 'linkedin',
      link: '',
    },
  },
  isProfileApproved: true,
  profileIsLoading: false,
  videoURL: '',
}

const accountProfile = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_USER_DEGREES_START:
      return {
        ...state,
        areDegreesLoading: true,
      }
    case CONSTANTS.GET_USER_DEGREES_END:
      return {
        ...state,
        areDegreesLoading: false,
        degrees: action.payload,
      }
    case CONSTANTS.SET_USER_DEGREES:
      return {
        ...state,
        degrees: action.payload,
      }
    case CONSTANTS.GET_USER_LANGUAGES_START:
      return {
        ...state,
        languagesAreLoading: true,
      }
    case CONSTANTS.GET_USER_LANGUAGES_END:
      return {
        ...state,
        languagesAreLoading: false,
        languages: action.payload,
        languagesDB: action.payload,
      }
    case CONSTANTS.UPDATE_LOCAL_LANGUAGES:
      return {
        ...state,
        languagesDB: action.payload,
      }
    case CONSTANTS.SAVE_USER_LANGUAGES_START:
      return {
        ...state,
      }
    case CONSTANTS.SAVE_USER_LANGUAGES_END:
      return {
        ...state,
        languages: action.payload,
      }
    case CONSTANTS.GET_DELETION_REQUEST_STATUS_START:
      return {
        ...state,
        deleteRequestIsloading: true,
      }
    case CONSTANTS.GET_DELETION_REQUEST_STATUS_END:
      return {
        ...state,
        deleteRequestIsloading: false,
        deletionRequestUuid: action.payload,
      }
    case CONSTANTS.GET_CURRENT_PROFILE_STATUS_START:
      return {
        ...state,
        profileIsLoading: true,
      }
    case CONSTANTS.GET_CURRENT_PROFILE_STATUS_END:
      return {
        ...state,
        profileIsLoading: false,
        isProfileHidden: action.payload.isHidden,
        isProfileApproved: action.payload.isApproved,
        bio: action.payload.bio,
        dbBio: action.payload.bio,
        rating: action.payload.rating,
        reviews: action.payload.reviews,
      }
    case CONSTANTS.GET_CURRENT_PROFILE_STATUS_ERROR:
      return {
        ...state,
        profileIsLoading: false,
      }
    case CONSTANTS.CHANGE_PROFILE_VISIBILITY_START:
      return {
        ...state,
      }
    case CONSTANTS.CHANGE_PROFILE_VISIBILITY_END:
      return {
        ...state,
        isProfileHidden: action.payload,
      }
    case CONSTANTS.SET_USER_BIO:
      return {
        ...state,
        bio: action.payload,
      }

    case CONSTANTS.SET_ACCOUNTS:
      const newAccounts = action.payload.reduce((result, current) => {
        const socialMediaType = current.social_account
        return {
          ...result,
          [socialMediaType]: {
            link: current.link,
            uuid: current.uuid,
            social_account: current.social_account,
          },
        }
      }, {})
      return {
        ...state,
        socialAccounts: { ...initialState.socialAccounts, ...newAccounts },
      }

    case CONSTANTS.SET_VIDEO:
      return {
        ...state,
        videoURL: action.payload,
      }

    case CONSTANTS.GET_DEGREES_START:
      return {
        ...state,
        areAllDegreesLoading: true,
      }
    case CONSTANTS.GET_DEGREES_END:
      return {
        ...state,
        areAllDegreesLoading: false,
        allDegrees: action.payload,
      }
    case CONSTANTS.GET_RATING_STATUS_START:
      return {
        ...state,
        ratingRequestStatusLoading: true,
      }
    case CONSTANTS.GET_RATING_STATUS_END:
      return {
        ...state,
        ratingRequestStatusLoading: false,
        ratingStatus: action.payload,
      }
    case CONSTANTS.SET_PROFILE_APPROVE_STATUS:
      return {
        ...state,
        isProfileApproved: action.payload,
      }

    default:
      return state
  }
}

export default accountProfile
