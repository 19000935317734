import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import * as Yup from 'yup'
import { Button, FormikInput, PasswordChecker } from 'components'
import { Form, Formik } from 'formik'
import classNames from 'classnames'

const useStyles = makeStyles({
  inputContainer: {
    marginBottom: 24,
  },
  form: {
    marginTop: 32,
  },
  formClient: {
    marginTop: 16,
    marginBottom: 30,
    padding: 24,
    border: `1px solid ${colors.BASIC.COLOR[5]}`,
    borderRadius: 4,
    backgroundColor: colors.WHITE,
  },
  controlBtn: {
    textAlign: 'right',
  },
  cancelBtn: {
    marginRight: 32,
  },
  submitBtn: {
    width: 140,
  },
})

const ChangePasswordForm = ({ changePassword, isPasswordChanging, isClient }) => {
  const onSubmitForm = formData => {
    changePassword(formData)
  }
  const classes = useStyles()
  const inputSize = isClient ? 'responsive' : 'large'
  const formClasses = isClient ? classes.formClient : classes.form
  return (
    <div className={formClasses}>
      <Formik
        initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
        validationSchema={Yup.object({
          oldPassword: Yup.string()
            .trim()
            .required('please type your password'),
          newPassword: Yup.string()
            .required('please type your password')
            .max(50, 'Password should be maximum 50 characters')
            .test('regex', 'please enter a valid password', val => {
              const regExp = new RegExp('^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$')
              return regExp.test(val)
            })
            .test('regex', 'please avoid using spaces', val => {
              return !/\s/g.test(val)
            }),
          confirmPassword: Yup.string()
            .required('please type your password')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        })}
        onSubmit={(values, actions) => {
          onSubmitForm(values)
        }}
      >
        {props => (
          <Form>
            <FormikInput
              variant="outlined"
              size={inputSize}
              name="oldPassword"
              label="Current Password"
              type="password"
              needEndAddornment={false}
              containerClasses={classes.inputContainer}
            />
            <FormikInput
              variant="outlined"
              size={inputSize}
              name="newPassword"
              label="New Password"
              type="password"
              needEndAddornment={false}
              containerClasses={classes.inputContainer}
            />
            {(props.values.newPassword || props.touched.newPassword) && (
              <PasswordChecker password={props.values.newPassword} />
            )}
            <FormikInput
              variant="outlined"
              size={inputSize}
              name="confirmPassword"
              label="Re-enter Your New Password"
              type="password"
              needEndAddornment={false}
              containerClasses={classes.inputContainer}
            />
            {!isClient && (
              <Button variant="primary" size="large" type="submit" isButtonBlocked={isPasswordChanging}>
                Change Password
              </Button>
            )}
            {isClient && (
              <div className={classes.controlBtn}>
                <Button
                  variant="text"
                  type="reset"
                  className={classes.cancelBtn}
                  disabled={
                    isPasswordChanging ||
                    (!props.values.newPassword && !props.values.oldPassword && !props.values.confirmPassword)
                  }
                >
                  cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isButtonBlocked={isPasswordChanging}
                  className={classes.submitBtn}
                  disabled={
                    isPasswordChanging ||
                    (!props.values.newPassword && !props.values.oldPassword && !props.values.confirmPassword)
                  }
                  circularSize={22}
                >
                  save changes
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ChangePasswordForm
