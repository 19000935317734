import React from 'react'
import IconButton from '@material-ui/core/IconButton'

const XIconButton = ({ classes, children, onClick, className, disabled }) => (
  <IconButton onClick={onClick} classes={classes} className={className} disabled={disabled}>
    {children}
  </IconButton>
)

export default XIconButton
