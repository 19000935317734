import React from 'react'
import { Page, Document } from '@react-pdf/renderer'
import { CreditAdjustmentEntry } from './parts'

const InvoiceCreditAdjustmentDocument = ({ credit, invoice, timezone }) => {
  return (
    <Document>
      <Page>
        <CreditAdjustmentEntry credit={credit} invoice={invoice} timezone={timezone}/>
      </Page>
    </Document>
  )
}

export default InvoiceCreditAdjustmentDocument
