import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'components'

const useStyles = makeStyles({
  msg: {
    marginLeft: 45,
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 10,
  },
  list: {
    marginBottom: 10,
  },
  item: {
    fontSize: 15,
    fontWeight: 400,
    listStylePosition: 'inside',
  },
})

const errorCases = [
  'invoices are generated for all created activities,',
  'invoices are generated for all partial amounts for flat fee cases,',
  'all invoices are paid or written off.',
]

const MakeInactiveForbiddenMsg = ({ newCases = errorCases }) => {
  const classes = useStyles()
  const cases = newCases.map(error => (
    <li key={error} className={classes.item}>
      {error}
    </li>
  ))
  return (
    <div className={classes.msg}>
      <Typography variant="h2" className={classes.title}>
        Please make sure that
      </Typography>
      <ul>{cases}</ul>
    </div>
  )
}

export default MakeInactiveForbiddenMsg
