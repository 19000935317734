import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MeetingSchedulerProInfo, MeetingSchedulerClientInfo, ColorCircularProgress } from 'components'
import { colors } from 'constants/colors'
import { getProAccount } from 'store/actions/search'
import {
  getStates,
  getUserContactInfo,
  updateProfileContactInfo,
  changeProfileContactInfo,
  saveContactInfo,
} from 'store/actions/profileCreation'
import { navigateToSearchProAccount } from 'store/actions/navigation'
import {
  createMeeting,
  setMeetingTime,
  toggleMeetingModal,
  getInitialMeetingPricing,
} from 'store/actions/meetingScheduler'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getClientPaymentSecret, getUserCards } from 'store/actions/payments'
import moment from 'moment-timezone'
import { MobileVerificationModal } from '../../components/profileCreation/MobileVerificationModal'

const useStyles = makeStyles({
  scheduleMeetingContainer: {
    minHeight: '100%',
    display: 'flex',
  },
  proInfoContainer: {
    backgroundColor: colors.BASIC.COLOR[0],
    width: '33%',
    borderRight: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  clientInfoContainer: {
    width: '77%',
  },
  '@media(max-width:939px)': {
    proInfoContainer: {
      width: 279,
    },
    clientInfoContainer: {
      width: 'auto',
      flexGrow: 1,
    },
  },
  '@media(max-width:767px)': {
    scheduleMeetingContainer: {
      flexDirection: 'column',
    },
    proInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderRight: 'none',
    },
  },
})

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ScheduleMeetingPage = ({ match, ...props }) => {
  const classes = useStyles()
  const query = useQuery()
  const dispatch = useDispatch()
  const proAccount = useSelector(state => state.search.proAccount)
  const userCards = useSelector(state => state.payments.userCards)
  const practiceAreaValue = useSelector(state => state.search.practiceAreaValue)
  const proAccountIsLoading = useSelector(state => state.search.proAccountIsLoading)
  const contactInfoIsLoading = useSelector(state => state.profileCreation.contactInfoIsLoading)
  const states = useSelector(state => state.profileCreation.states)
  const contactInfo = useSelector(state => state.profileCreation.profileData.contactInfo)
  const localContactInfo = useSelector(state => state.profileCreation.profileData.localContactInfo)
  const [stripeError, setStripeError] = useState('')
  const [paymentCard, setPaymentCard] = useState({})
  const [initialPrice, setInitialPrice] = useState(0)
  const [cancellationFee, setCancellationFee] = useState(0)
  const [isSubmitMeetingData, setIsSubmitMeetingData] = useState(false)
  const [submitMeetingData, setSubmitMeetingData] = useState({})
  const practiceArea = query.get('area')
  const start = query.get('start').replace(' ', '-')
  const end = query.get('end').replace(' ', '-')
  const selectedPracticeArea =
    proAccount.practiceAreas.find(area => area.selected_practice_area.name === practiceArea) ||
    proAccount.practiceAreas.find(area =>
      area.user_sub_specialities.some(sub => sub.selected_sub_speciality.name === practiceArea)
    )
  const selectedSubPracticeArea =
    selectedPracticeArea &&
    selectedPracticeArea.user_sub_specialities.find(sub => sub.selected_sub_speciality.name === practiceArea)
  const proId = match.params.pro_uuid

  const [verifyNumber, setVerifyNumber] = useState('')
  const onClose = async data => {
    dispatch(getUserContactInfo())
    const response = await dispatch(createMeeting(data))
    if (response.error && response.isStripeError) {
      setStripeError(response.error)
    }
    setVerifyNumber('')
    setSubmitMeetingData({})
  }

  const onCancel = () => {
    dispatch(updateProfileContactInfo(contactInfo))
    dispatch(navigateToSearchProAccount(proId))
  }

  const onSubmit = async contactData => {
    const payment_method_id = initialPrice ? { payment_method_id: paymentCard.paymentMethodId } : {}
    const data = {
      pro: proId,
      start_time: start,
      end_time: end,
      practice_area: selectedPracticeArea ? selectedPracticeArea.selected_practice_area.uuid : '',
      sub_speciality: selectedSubPracticeArea ? selectedSubPracticeArea.selected_sub_speciality.uuid : '',
      is_initial: true,
      ...payment_method_id,
      client_timezone: moment.tz.guess(),
    }

    setIsSubmitMeetingData(true)
    const { is_phone_confirmed, phone } = await dispatch(saveContactInfo(contactData || localContactInfo))
    if (!is_phone_confirmed) {
      setVerifyNumber(phone)
      setSubmitMeetingData(data)
    } else {
      await onClose(data)
    }
    dispatch(changeProfileContactInfo(contactData || localContactInfo))
    setIsSubmitMeetingData(false)
  }

  useEffect(() => {
    dispatch(getStates())
    dispatch(getClientPaymentSecret())
    dispatch(getUserCards())
    dispatch(getProAccount(proId))
  }, [])

  useEffect(() => {
    if (proId && selectedPracticeArea)
      dispatch(getInitialMeetingPricing(proId, selectedPracticeArea.selected_practice_area.uuid))
        .then(res => {
          setCancellationFee(res.cancellation_fee)
          setInitialPrice(res.price)
        })
        .catch(e => {
          if (selectedPracticeArea) {
            setInitialPrice(selectedPracticeArea.consultation_cost)
            const cancellationFee = selectedPracticeArea.consultation_cost || selectedPracticeArea.hourly_rate * 0.25
            setCancellationFee(cancellationFee)
          }
        })
  }, [selectedPracticeArea, proId])

  const goToMeetingModal = () => {
    dispatch(setMeetingTime({ start: start, end: end }))
    dispatch(toggleMeetingModal(true))
    dispatch(navigateToSearchProAccount(proId))
  }

  // useEffect(() => {
  //   if (proAccount.id === proId && proAccount.connectionWithProExists) {
  //     goToMeetingModal()
  //   }
  // }, [proAccount])

  useEffect(() => {
    if (
      contactInfo.street &&
      contactInfo.city &&
      contactInfo.state &&
      contactInfo.zip &&
      contactInfo.phone &&
      selectedPracticeArea &&
      selectedPracticeArea.consultation_cost === 0 &&
      !isSubmitMeetingData &&
      proAccount.id === proId
    ) {
      goToMeetingModal()
    }
  }, [contactInfo])

  useEffect(() => {
    if (userCards.length) {
      const defaultCard = userCards.find(card => card.isDefault)
      defaultCard && setPaymentCard(defaultCard)
    }
  }, [userCards])

  const onPaymentCardChange = id => {
    setPaymentCard(id)
    setStripeError('')
  }

  return (
    <div className={classes.scheduleMeetingContainer}>
      {proAccountIsLoading || contactInfoIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <div className={classes.proInfoContainer}>
            <MeetingSchedulerProInfo
              proAccount={proAccount}
              initialPrice={initialPrice}
              practiceArea={selectedPracticeArea}
              subPracticeArea={selectedSubPracticeArea && selectedSubPracticeArea.selected_sub_speciality}
              start={start}
              end={end}
            />
          </div>
          <div className={classes.clientInfoContainer}>
            <MeetingSchedulerClientInfo
              onCancel={onCancel}
              onSubmit={onSubmit}
              proAccount={proAccount}
              practiceAreaValue={practiceArea}
              practiceArea={selectedPracticeArea}
              cancellationFee={cancellationFee}
              initialPrice={initialPrice}
              states={states}
              start={start}
              end={end}
              contactInfo={localContactInfo}
              userCards={userCards}
              paymentCard={paymentCard}
              setPaymentCard={onPaymentCardChange}
              isSubmitMeetingData={isSubmitMeetingData}
              stripeError={stripeError}
            />
          </div>
        </>
      )}
      <MobileVerificationModal
        open={!!verifyNumber}
        number={verifyNumber}
        onOk={() => onClose(submitMeetingData)}
        onClose={() => setVerifyNumber('')}
      />
    </div>
  )
}

export default ScheduleMeetingPage
