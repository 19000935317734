import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'components'
import { navigateToSettingsGeneral } from 'store/actions/navigation'
import BackgroundImage from 'static/pro_build_profile.jpg'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'center',
    backgroundPosition: 'top, center',
  },
  title: {
    paddingTop: 40,
    marginBottom: 20,
    fontSize: 41,
    fontWeight: 500,
  },
  subtitle: {
    lineHeight: 1.7,
  },
  subtitleNormal: {
    marginTop: 10,
    fontWeight: 'normal',
  },
  btn: {
    fontSize: 15,
    fontWeight: 500,
    padding: '14px 48px',
    lineHeight: 1.2,
    letterSpacing: 1,
  },
  completeProfile__row: {
    display: 'flex',
    justifyContent: 'center',
  },
  completeProfileSteps__title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#011a33',
  },
  completeProfileSteps__titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  completeProfileSteps__stepsContainer: {
    listStyleType: 'disc',
    marginTop: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: 15,
    fontFamily: "san-serif, 'Roboto'",
  },
  reviewProfile: {
    fontSize: 25,
    fontWeight: 'bold',
  },
})

const CompletePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const onBuildProfileClick = () => {
    dispatch(navigateToSettingsGeneral())
    Analytics.track(EVENTS.PRO_REGISTERED)
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h2">
        thank you for completing your registration!
      </Typography>
      <div className={classes.subtitle}>
        <Typography className={classes.reviewProfile} variant="h4">
          we are reviewing your credentials
        </Typography>
        <Typography className={classes.subtitleNormal} variant="h4">
          in the meantime, let’s build and promote your profile
        </Typography>
      </div>
      <div className={classes.completeProfile__row}>
        <div className={classes.completeProfileSteps__titleContainer}>
          <Typography className={classes.completeProfileSteps__title} variant="h2">
            ADD
          </Typography>
        </div>
        <ol className={classes.completeProfileSteps__stepsContainer}>
          <li>photo</li>
          <li>credentials</li>
          <li>bio</li>
          <li>bio video</li>
          <li>languages</li>
          <li>social connections</li>
          <li>ratings</li>
        </ol>
      </div>
      <Button variant="primary" className={classes.btn} onClick={onBuildProfileClick}>
        build my profile
      </Button>
    </div>
  )
}

export default CompletePage
