import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, routes } from 'constants/index'
import {
  BioProAccount,
  Breadcrumbs,
  ColorCircularProgress,
  DegreesProAccount,
  Footer,
  LanguagesProAccount,
  LicenseProAccount,
  Link,
  MainProAccount,
  NotFound,
  PracticeAreasProAccount,
  ProCalendar,
  SocialProAccount,
  VideoProAccount,
  ProCommentsList,
  ProRatingModal,
  SvgIcon,
} from 'components'
import { connect, useDispatch } from 'react-redux'
import { getProAccount, getProAccountAvailableTime, setMeetingDuration } from 'store/actions/search'
import { Link as RouterLink } from 'react-router-dom'
import {
  getProReviews,
  setReviewCommentsFilter,
  proReviewModalClose,
  proReviewModalOpen,
  setReviewCommentsSorting,
} from 'store/actions/proReview/proReview'
import { useQuery } from 'utils/hooks'
import { navigateTo } from 'store/actions/navigation'
import { ReactComponent as ArrowLeftIcon } from 'static/search/ic_arrow-left.svg'
import Analytics from '../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../utils/analytics/Events'

const useStyles = makeStyles({
  rootContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
  },
  searchResultContainer: {
    flex: '1 0 auto',
    width: '100%',
    maxWidth: 1280,
    overflowY: 'auto',
    backgroundColor: colors.WHITE,
    padding: '30px 40px',
  },
  link: {
    display: 'flex',
    color: colors.BASIC.COLOR[80],
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    '&:hover': {
      color: colors.BASIC.COLOR[100],
    },
  },
  linkIcon: {
    width: 'auto',
    height: 'auto',
    marginRight: 8,
  },
  searchResultBody: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bodyColumn: {
    marginTop: '46px',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 63px)',
  },
  optionAreasWrapperResponsive: {
    display: 'none',
  },
  '@media(max-width:1279px)': {
    searchResultContainer: {
      padding: '30px 24px',
    },
    bodyColumn: {
      width: 'calc(50% - 12px)',
    },
  },
  '@media(max-width:767px)': {
    searchResultContainer: {
      padding: '30px 15px',
    },
    searchResultBody: {
      flexDirection: 'column',
    },
    bodyColumn: {
      width: '100%',
      marginTop: 32,
      '&:nth-child(2)': {
        marginTop: 0,
      },
    },
    optionAreasWrapper: {
      display: 'none',
    },
    optionAreasWrapperResponsive: {
      display: 'block',
    },
  },
})

const ProfileSearchResult = ({
  match,
  proAccount,
  states,
  proAccountIsLoading,
  showNotFound,
  proReviewComments,
  proReviewStats,
  proReviewCommentsCount,
  reviewModalOpen,
  proReviewCommentsFilter,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    rating,
    reviews,
    firstName,
    lastName,
    photo,
    practiceAreas,
    languages,
    socialLinks,
    bio,
    video,
    licenses,
    degrees,
    connectionWithProExists,
    isInitial,
    isRecommended,
    active_calendar,
    email,
  } = proAccount

  const query = useQuery()
  const commentID = query.get('comment_uuid')
  const proID = match.params.pro_uuid

  useEffect(() => {
    dispatch(setMeetingDuration(30))
    dispatch(getProAccount(proID))
    dispatch(getProAccountAvailableTime(proID))
    dispatch(getProReviews(proID))
  }, [])

  useEffect(() => {
    if (commentID) dispatch(proReviewModalOpen())
  }, [commentID])

  useEffect(() => {
    Analytics.track(EVENTS.VIEW_PRO_DETAILS, {
      pro_id: match.params.pro_uuid,
    })
  }, [match.params.pro_uuid])

  const handleRatingModalClose = () => {
    dispatch(proReviewModalClose())
    dispatch(navigateTo(match.url))
    if (proReviewCommentsFilter) {
      dispatch(setReviewCommentsFilter(null))
      dispatch(getProReviews(proID))
    }
  }

  const handleRatingModalOpen = filterValue => {
    dispatch(setReviewCommentsFilter(filterValue || null))
    dispatch(setReviewCommentsSorting('created_at', false))
    dispatch(getProReviews(match.params.pro_uuid))
    dispatch(proReviewModalOpen())
  }
  return proAccountIsLoading ? (
    <ColorCircularProgress />
  ) : showNotFound ? (
    <NotFound />
  ) : (
    <div className={classes.rootContainer}>
      <div className={classes.searchResultContainer}>
        <div>
          <Breadcrumbs>
            <Link className={classes.link} component={RouterLink} to={routes.SEARCH_PAGE}>
              <SvgIcon classes={{ root: classes.linkIcon }} component={ArrowLeftIcon} viewBox="0 0 20 20" />
              back to search results
            </Link>
          </Breadcrumbs>
        </div>
        <div className={classes.searchResultBody}>
          <div className={classes.bodyColumn}>
            <MainProAccount
              rating={rating}
              reviews={reviews}
              stats={proReviewStats}
              comments={proReviewComments}
              firstName={firstName}
              lastName={lastName}
              photo={photo}
              checkmark={isRecommended}
              proID={proID}
              email={email}
              type="pro_details_view"
              openRatingModal={handleRatingModalOpen}
              proReviewCommentsCount={proReviewCommentsCount}
              checkmarkClassName="checkmarkProfile"
            />
            <ProCalendar
              firstName={firstName}
              lastName={lastName}
              photo={photo}
              proId={match.params.pro_uuid}
              practiceAreas={practiceAreas}
              connectionWithProExists={connectionWithProExists}
              isInitial={isInitial}
              hasCalendar={active_calendar}
            />
            <div className={classes.optionAreasWrapper}>
              {!!(practiceAreas && practiceAreas.length) && <PracticeAreasProAccount practiceAreas={practiceAreas} />}
              {!!(languages && languages.length) && <LanguagesProAccount languages={languages} />}
              {!!(socialLinks && socialLinks.length) && <SocialProAccount socialLinks={socialLinks} />}
            </div>
          </div>
          <div className={classes.bodyColumn}>
            {bio && <BioProAccount bio={bio} firstName={firstName} lastName={lastName} />}
            {video && <VideoProAccount video={video} />}
            {!!(licenses && licenses.length) && <LicenseProAccount licenses={licenses} states={states} />}
            {!!(degrees && degrees.length) && <DegreesProAccount degrees={degrees} />}
            {!!proReviewComments.length && (
              <ProCommentsList
                comments={proReviewComments}
                rating={rating}
                reviews={reviews}
                isProfile
                stats={proReviewStats}
                openRatingModal={handleRatingModalOpen}
                proReviewCommentsCount={proReviewCommentsCount}
              />
            )}
          </div>
          <div className={classes.optionAreasWrapperResponsive}>
            {!!(languages && languages.length) && <LanguagesProAccount languages={languages} />}
            {!!(socialLinks && socialLinks.length) && <SocialProAccount socialLinks={socialLinks} />}
            {!!(practiceAreas && practiceAreas.length) && <PracticeAreasProAccount practiceAreas={practiceAreas} />}
          </div>
        </div>
      </div>
      <ProRatingModal
        open={reviewModalOpen}
        handleClose={handleRatingModalClose}
        reviews={reviews}
        rating={rating}
        comments={proReviewComments}
        stats={proReviewStats}
        proReviewCommentsCount={proReviewCommentsCount}
        proName={`${firstName} ${lastName}`}
        proID={proID}
        selectedComment={commentID}
      />
      <Footer />
    </div>
  )
}

const mapStateToProps = ({ search, profileCreation, common, proReview }) => ({
  proAccount: search.proAccount,
  proAccountIsLoading: search.proAccountIsLoading,
  states: profileCreation.states,
  showNotFound: common.showNotFound,
  proReviewComments: proReview.proReviewComments,
  proReviewStats: proReview.proReviewStats,
  proReviewCommentsCount: proReview.proReviewCommentsCount,
  reviewModalOpen: proReview.reviewModalOpen,
  proReviewCommentsFilter: proReview.proReviewCommentsFilter,
})
export default connect(mapStateToProps)(ProfileSearchResult)
