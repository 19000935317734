import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Slider } from 'components'
import { colors } from 'constants/colors'
import Input from './Input'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    width: 100,
    border: ` 1px solid ${colors.BASIC.COLOR[50]}`,
    borderRadius: 4,
    backgroundColor: 'transparent !important',
    '&:hover': {
      border: ` 1px solid ${colors.BASIC.COLOR[100]}`,
    },
  },
  label: {
    fontSize: 10,
  },
  adornedStart: {
    fontSize: 16,
  },
  input: { textAlign: 'right' },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const InputSlider = ({ inputsData, onInputChange, onSliderChange, values }) => {
  const classes = useStyles()
  const { min, max } = inputsData
  const minName = min.name,
    maxName = max.name
  const [inputValues, setValues] = React.useState({
    [minName]: min.value,
    [maxName]: max.value,
  })

  useEffect(() => {
    setValues({
      [minName]: values[minName],
      [maxName]: values[maxName],
    })
  }, [values])

  const handleSliderChange = (event, [minVal, maxVal]) => {
    setValues({ [minName]: minVal, [maxName]: maxVal })
  }

  const onChangeCommitted = (event, [minVal, maxVal]) => {
    onSliderChange(minVal, maxVal)
  }

  const handleInputChange = event => {
    const inputValue = Number(event.target.value.replace(/[^\d]/, ''))
    const inputName = event.target.name
    setValues({
      ...inputValues,
      [inputName]: inputValue,
    })
    onInputChange(event, inputValue)
  }

  return (
    <div className={classes.root}>
      <Slider
        max={max.value}
        min={min.value}
        value={Object.values(inputValues)}
        onChange={handleSliderChange}
        onChangeCommitted={onChangeCommitted}
      />
      <div className={classes.flexWrapper}>
        <Input
          fieldData={min}
          inputValue={inputValues[minName]}
          validationRange={{ min: 0, max: inputValues[maxName] }}
          onChange={handleInputChange}
        />
        <Input
          fieldData={max}
          endIcon="+"
          inputValue={inputValues[maxName]}
          validationRange={{ min: inputValues[minName], max: 1000 }}
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

export default InputSlider
