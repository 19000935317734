import { MixpanelService } from './Mixpanel'
import { FirebaseService } from './Firebase'
import { GoogleAnalyticsService } from './GoogleAnalytics'
import { FacebookPixelAnalyticsService } from './FacebookPixel'

class AnalyticsService {
  constructor() {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    this.mixpanel = new MixpanelService()
    this.firebase = new FirebaseService()
    this.googleAnalytics = new GoogleAnalyticsService()
    this.pixelAnalytics = new FacebookPixelAnalyticsService()
  }

  track(event, payload) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    this.mixpanel.track(event, payload)
    this.firebase.track(event, payload)
  }

  register(profile) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    this.mixpanel.register(profile)
    this.firebase.register(profile)
  }

  login(profile) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    this.mixpanel.login(profile)
    this.firebase.login(profile)
  }

  logout() {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    this.mixpanel.logout()
    this.firebase.logout()
  }

  identify(profile) {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    this.mixpanel.identify(profile)
    this.firebase.identify(profile)
  }
}

const Analytics = new AnalyticsService()

export default Analytics
