import { StyleSheet, Text, View } from '@react-pdf/renderer'
import * as moment from 'moment-timezone'
import { getTimeFromDecimal } from 'utils/getters/activities'
import React from 'react'
import { CONSTANTS } from 'constants/index'
import { getInvoiceActivityDescription } from 'utils/getters/invoices'

const styles = StyleSheet.create({
  table: {
    marginTop: 8,
    display: 'table',
    width: '100%',
  },
  titleTable: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
  },
  tableRow: {
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#e6eaec',
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 1,
  },
  tableHeaderCell: {
    marginTop: 8,
    fontSize: 9,
    height: 24,
  },
  tableCell: {
    marginTop: 8,
    fontSize: 10,
    minHeight: 24,
  },
  tableCol: {
    width: '15%',
  },
  smTableCol: {
    width: '10%',
  },
  descriptionTableCol: {
    width: '55%',
  },
  tableCellAmount: {
    marginTop: 8,
    minHeight: 24,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  subtotalTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subtotalAmount: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
  },
  amountTableCell: {
    textAlign: 'right',
  },
})

const InvoiceDocumentTable = ({ activities, subTotal, activityType, title, timezone }) => {
  return (
    <>
      <Text style={styles.titleTable}>{title}</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeaderCell}>Date</Text>
          </View>
          <View style={styles.descriptionTableCol}>
            <Text style={styles.tableHeaderCell}>Description</Text>
          </View>
          <View style={styles.smTableCol}>
            <Text style={styles.tableHeaderCell}>{activityType === 'time' ? 'Time' : ''}</Text>
          </View>
          <View style={styles.smTableCol}>
            <Text style={styles.tableHeaderCell}>{activityType === 'time' ? 'Rate' : ''}</Text>
          </View>
          <View style={styles.smTableCol}>
            <Text style={[styles.tableHeaderCell, styles.amountTableCell]}>Amount</Text>
          </View>
        </View>
        {activities.map(activity => (
          <View style={styles.tableRow} key={activity.uuid} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(activity.date_of_creation)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
              </Text>
            </View>
            <View style={styles.descriptionTableCol}>
              <Text style={styles.tableCell}>{getInvoiceActivityDescription(activity.description)}</Text>
            </View>
            <View style={styles.smTableCol}>
              <Text style={styles.tableCell}>
                {activityType === 'time' ? getTimeFromDecimal(activity.time_duration) : ''}
              </Text>
            </View>
            <View style={styles.smTableCol}>
              <Text style={styles.tableCell}>{activityType === 'time' ? `$${activity.hourly_rate}` : ''}</Text>
            </View>
            <View style={styles.smTableCol}>
              <Text style={[styles.tableCellAmount, styles.amountTableCell]}>${activity.client_charge}</Text>
            </View>
          </View>
        ))}
        <View style={styles.subtotal}>
          <Text style={styles.subtotalTitle}>Subtotal</Text>
          <Text style={styles.subtotalAmount}>${subTotal}</Text>
        </View>
      </View>
    </>
  )
}

export default InvoiceDocumentTable
