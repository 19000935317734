import React from 'react'
import { Avatar, Link, SvgIcon, Tooltip, Typography } from 'components'
import { ReactComponent as LocationIcon } from 'static/icon_24_pin.svg'
import NoPhoto from 'static/search/pro_no photo.png'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import { ReactComponent as Checkmark } from '../../static/checkmark.svg'
import { ReactComponent as ArchivePro } from '../../static/connections/archive_pro.svg'
import { ReactComponent as UnarchivePro } from '../../static/connections/unarchive_pro.svg'

const useStyles = makeStyles({
  cardContainer: {
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    marginBottom: 26,
    marginRight: 24,
    '&:hover': {
      boxShadow: '1px 3px 16px 0 rgba(0, 0, 0, 0.1)',
    },
    position: 'relative',
  },
  content: {
    display: 'flex',
    padding: 16,
    height: '100%',
  },
  archivePro: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 1,
    cursor: 'pointer',
  },
  contactInfo: {
    width: 300,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: 18,
  },
  checkmark: {
    padding: 0,
    width: 40,
    height: 40,
    right: 0,
    top: 110,
    '& rect': {
      fill: '#FAFBFC',
    },
  },
  tooltip: {
    margin: '5px 0',
  },
  avatar: {
    height: 150,
    width: 150,
    cursor: 'pointer',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sectionSubTitle: {
    fontWeight: 'normal',
    fontSize: 15,
    color: colors.BASIC.COLOR[100],
  },
  lineClamp: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoColumn: {
    flexGrow: 1,
    width: 300,
  },
  email: {
    display: 'block',
    color: colors.BLUE_20,
    textDecoration: 'none',
    fontSize: 15,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  row: {
    display: 'flex',
    marginBottom: 12,
  },
  iconColumn: {
    width: 30,
  },
  icons: {
    width: 20,
    height: 20,
  },
  icon: {
    marginRight: 4,
  },
  price: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
  },
  profileName: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: 1.4,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  emailResponsive: {
    display: 'none',
  },
  reasonOfCancel: {
    paddingLeft: 28,
    marginBottom: 28,

    fontSize: 15,
  },
  reasonTitle: {
    fontWeight: 500,
  },
  reasonDesc: {
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '@media(max-width:1279px)': {
    checkmark: {
      width: 32,
      height: 32,
      top: 88,
      right: 'unset',
      left: 84,
    },
    avatar: {
      position: 'absolute',
      top: 20,
      left: 16,
      height: 100,
      width: 100,
    },
    content: {
      position: 'relative',
    },
    contactInfo: {
      paddingLeft: 0,
    },
    row: {
      '&:first-child': {
        paddingLeft: 136,
      },
      '&:nth-child(2)': {
        paddingLeft: 136,
        marginBottom: 20,
        minHeight: 65,
      },
    },
    info: {
      marginBottom: 12,
    },
  },
  '@media(max-width:939px)': {
    checkmark: {
      width: 52,
      height: 52,
      top: 98,
      left: 98,
    },
    avatar: {
      position: 'relative',
      top: 0,
      left: 0,
      height: 150,
      width: 150,
    },
    contactInfo: {
      paddingLeft: 18,
    },
    row: {
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
        minHeight: 'unset',
      },
    },
  },
  '@media(max-width:767px)': {
    checkmark: {
      width: 24,
      height: 24,
      top: 72,
      left: 72,
    },
    avatar: {
      position: 'absolute',
      top: 16,
      left: 16,
      height: 80,
      width: 80,
    },
    contactInfo: {
      paddingLeft: 0,
      width: '100%',
    },
    row: {
      '&:first-child': {
        paddingLeft: 116,
        minHeight: 90,
        'flex-direction': 'column',
      },
    },
    profileName: {
      whiteSpace: 'pre-wrap',
    },
    emailResponsive: {
      display: 'inline-block',
      fontSize: 13,
    },
    emailDesktop: {
      display: 'none',
    },
    placeholderText: {
      fontSize: 13,
      color: colors.BASIC.COLOR['30'],
    },
  },
})

const ProInfoClientCard = ({
  header,
  proInfo,
  isInitial,
  buttons,
  navigateToProPage,
  cardStyle,
  isInitialMeetingSuccessfull,
  isCanceled,
  reasonOfCancelation,
  checkmark,
  onArchive,
  isArchived,
}) => {
  const classes = useStyles()
  const onAvatarClick = () => {
    if (!proInfo.isDeleted) navigateToProPage()
  }
  const prosName = proInfo.isDeleted ? (
    <Typography className={classes.profileName}>{`${proInfo.firstName} ${proInfo.lastName}`}</Typography>
  ) : (
    <Link className={classes.profileName} onClick={navigateToProPage}>
      {`${proInfo.firstName} ${proInfo.lastName}`}
    </Link>
  )
  return (
    <div className={classNames(classes.cardContainer, cardStyle)}>
      {header}
      {onArchive &&
        (isArchived ? (
          <div className={classes.archivePro}>
            <Tooltip title="Make active">
              <UnarchivePro onClick={onArchive} />
            </Tooltip>
          </div>
        ) : (
          <div className={classes.archivePro}>
            <Tooltip title="Archive">
              <ArchivePro onClick={onArchive} />
            </Tooltip>
          </div>
        ))}
      <div className={classes.content}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            checkmark ? (
              <div>
                <Tooltip title="Peer recommended" tooltipClass={classes.tooltip}>
                  <Checkmark className="checkmark" />
                </Tooltip>
              </div>
            ) : null
          }
          classes={{ badge: classes.checkmark }}
        >
          <Avatar className={classes.avatar} src={proInfo.photo || NoPhoto} onClick={onAvatarClick} />
        </Badge>
        <div className={classes.contactInfo}>
          <div className={classes.info}>
            <div className={classes.row}>
              {prosName}
              {isInitialMeetingSuccessfull ? (
                <Link
                  href={`mailto:${proInfo.username}`}
                  className={classNames(classes.email, classes.emailResponsive)}
                >
                  {proInfo.username}
                </Link>
              ) : (
                <Typography></Typography>
              )}
            </div>

            <div className={classes.row}>
              <div className={classes.iconColumn}>
                <SvgIcon viewBox="0 0 24 24" className={classes.icons}>
                  <LocationIcon />
                </SvgIcon>
              </div>
              <div className={classes.infoColumn}>
                {isInitialMeetingSuccessfull ? (
                  <>
                    <Typography variant="subtitle2" className={classNames(classes.sectionSubTitle, classes.lineClamp)}>
                      {`${proInfo.contactInfo.suite} ${proInfo.contactInfo.street}`}
                    </Typography>
                    <Typography variant="subtitle2" className={classNames(classes.sectionSubTitle, classes.lineClamp)}>
                      {`${proInfo.contactInfo.city}, ${proInfo.contactInfo.state &&
                        proInfo.contactInfo.state.replace('US-', '')} ${proInfo.contactInfo.zip}`}
                    </Typography>
                    <Link
                      href={`mailto:${proInfo.username}`}
                      className={classNames(classes.email, classes.emailDesktop)}
                    >
                      {proInfo.username}
                    </Link>
                    <Typography variant="subtitle2" className={classes.sectionSubTitle}>
                      {proInfo.contactInfo.phone}
                      {proInfo.contactInfo.optionalPhone ? `, ${proInfo.contactInfo.optionalPhone}` : ''}
                    </Typography>
                  </>
                ) : (
                  <span className={classes.placeholderText}>
                    Contact info will be available once your initial meeting successfully finished
                  </span>
                )}
              </div>
            </div>

            {isInitial && <div className={classes.row}>{isInitial}</div>}
          </div>
          {isCanceled && (
            <div className={classes.reasonOfCancel}>
              <Typography variant="subtitle2" className={classes.reasonTitle}>
                the reason of cancellation
              </Typography>
              <Typography variant="subtitle2" className={classes.reasonDesc}>
                {reasonOfCancelation}
              </Typography>
            </div>
          )}
          {buttons}
        </div>
      </div>
    </div>
  )
}

export default ProInfoClientCard
