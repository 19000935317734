import React from 'react'
import { Button } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { exportFile } from 'store/actions/common'
import { getFilenameFromContentDisposition } from 'utils/getters/common'

const ExportButton = ({ variant, className, getFileInfo, fileName, isFileLoading, disabled }) => {
  const dispatch = useDispatch()
  const isExporting = useSelector(state => state.common.isExporting)
  const onExportClick = async () => {
    const response = await dispatch(getFileInfo())
    dispatch(exportFile(response.data, fileName || getFilenameFromContentDisposition(response)))
  }
  return (
    <Button
      variant={variant}
      className={className}
      onClick={onExportClick}
      disabled={disabled || isExporting || isFileLoading}
    >
      Export
    </Button>
  )
}

export default ExportButton
