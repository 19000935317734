import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ProRatingTitle, Tooltip, ProRatingStatsFilter } from 'components'

const useStyles = makeStyles({
  reviewTooltipContainer: {
    borderRadius: 4,
    boxShadow: '2px 4px 24px 0 rgba(0, 0, 0, 0.15)',
    padding: 16,
    width: 243,
  },
  reviewPopperTooltipContainer: {
    zIndex: 1200,
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipContainer: {
    '@media (min-width: 320px) and (max-width: 768px)': {
      display: 'none',
    },
  },
  titleContainer: {
    display: 'none',
    '@media (min-width: 320px) and (max-width: 768px)': {
      display: 'flex',
    },
  },
})

const ProRating = ({ stats, reviews, rating, openRatingModal, proID, ratingComponent }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.tooltipContainer}>
        <Tooltip
          placement="bottom-start"
          title={
            <ProRatingStatsFilter
              stats={stats}
              reviews={reviews}
              openRatingModal={openRatingModal}
              fullProfile
              proID={proID}
            />
          }
          tooltipClass={classes.reviewTooltipContainer}
          popperTooltipClass={classes.reviewPopperTooltipContainer}
          interactive
        >
          <div className={classes.ratingContainer}>
            {ratingComponent}
            <ProRatingTitle rating={rating} reviews={reviews} isFullProfile />
          </div>
        </Tooltip>
      </div>
      <div className={classes.titleContainer} onClick={() => openRatingModal()}>
        {ratingComponent}
        <ProRatingTitle rating={rating} reviews={reviews} isFullProfile />
      </div>
    </>
  )
}

export default ProRating
