import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Typography,
  ColorCircularProgress,
  TableEmptyState,
  ClientInvoicesTable,
  NoDataPlaceholder,
  ExportButton,
  ClientInvoicesFilter,
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import {
  getProInvoices,
  getProInvoicesFile,
  resetInvoicesFilter,
  resetInvoicesSorting,
  setTableConfiguration,
} from 'store/actions/invoices'
import { getUserConnectionsForFilter } from 'store/actions/connections'

const useStyles = makeStyles({
  clientInvoicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 25,
  },
  clientInvoicesHeader: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  addButton: {
    padding: '10px 24px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const ClientInvoicesContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const invoices = useSelector(state => state.invoices.invoices)
  const invoicesAreLoading = useSelector(state => state.invoices.invoicesAreLoading)
  const filters = useSelector(state => state.invoices.filters)
  const initialFilters = useSelector(state => state.invoices.initialFilters)
  const count = useSelector(state => state.invoices.invoicesLength)
  const isInitialFilters = isEqual(initialFilters, filters)

  useEffect(() => {
    dispatch(getProInvoices())
    dispatch(getUserConnectionsForFilter())
    return () => {
      dispatch(resetInvoicesFilter())
      dispatch(resetInvoicesSorting())
      dispatch(
        setTableConfiguration({
          tablePage: 0,
          offset: 0,
        })
      )
    }
  }, [])

  return (
    <div className={classes.clientInvoicesContainer}>
      <div className={classes.row}>
        <Typography className={classes.clientInvoicesHeader}>Invoices</Typography>
        <ExportButton
          variant="primary"
          className={classes.addButton}
          getFileInfo={getProInvoicesFile}
          disabled={!invoices.length}
          isFileLoading={invoicesAreLoading}
        />
      </div>
      {(count || !isInitialFilters) && <ClientInvoicesFilter results={count} isInitialFilters={isInitialFilters} />}
      {invoicesAreLoading ? (
        <ColorCircularProgress />
      ) : !count && isInitialFilters ? (
        <NoDataPlaceholder label="you currently have no invoices listed" hideButton />
      ) : !count && !isInitialFilters ? (
        <TableEmptyState />
      ) : (
        <ClientInvoicesTable invoices={invoices} />
      )}
    </div>
  )
}

export default ClientInvoicesContainer
