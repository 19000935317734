import React, { useState } from 'react'
import { Modal, Typography, Link, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants/colors'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    position: 'relative',
    padding: '40px 40px 60px 40px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    margin: '0 0 24px 0',
    fontSize: '20px',
  },
  subtitle: {
    maxWidth: '568px',
    marginBottom: '40px',
  },
  boxBtn: {
    textAlign: 'right',
  },
  btn: {
    width: 'auto',
  },
  link: {
    color: colors.BASIC.COLOR[80],
    paddingBottom: '1px',
    borderBottom: `1px solid ${colors.BASIC.COLOR[80]}`,
  },
})

const EmailConfirmationAlert = () => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const onClose = () => {
    setOpen(false)
  }
  return (
    <Modal open={open} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h3" className={classes.title}>
          Thank you!
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {`We've sent password reset instructions to your email address. 
            If no email is received within ten minutes, 
            check that the submitted address is correct or your spam folder.`}
        </Typography>
        <div className={classes.boxBtn}>
          <Link className={classes.link} underline="none">
            Back to Log In
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default EmailConfirmationAlert
