import React from 'react'
import { TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../../constants/colors'

const useStyles = makeStyles({
  outlinedRoot: {
    marginTop: 24,
    width: '100%',
    padding: '20px 10px 35px 24px',
    fontFamily: 'Roboto',
    fontSize: 16,
    lineHeight: 1.69,
    resize: 'none',
    borderRadius: 4,
    border: `solid 1px ${colors.BASIC.COLOR[20]}`,
    '&:focus': {
      outline: 'none',
    },
  },
  textAreaWrapper: {
    width: 'auto',
    height: 'auto',
    display: 'inline-block',
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    bottom: 5,
    right: 0,
    fontSize: 14,
    padding: '0 16px 16px 0',
    margin: '0 20px',
    width: 'calc(100% - 35px)',
    textAlign: 'right',
    backgroundColor: colors.WHITE,
    color: colors.BASIC.COLOR[20],
  },
})

const BioTextArea = props => {
  const classes = useStyles()
  const { value, handleChange } = props
  const CHARACTER_LIMIT = 1500
  return (
    <div className={classes.textAreaWrapper}>
      <TextareaAutosize
        className={classes.outlinedRoot}
        placeholder="Tell your client about yourself"
        value={value}
        variant="outlined"
        rowsMax={25}
        rowsMin={5}
        onChange={handleChange}
        maxLength={CHARACTER_LIMIT}
      />
      <div className={classes.counter}>{`${value.length}/${CHARACTER_LIMIT}`}</div>
    </div>
  )
}

export default BioTextArea
