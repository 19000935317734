import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Table, Pagination, VaultLogTableRow, ColorCircularProgress, Typography } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'constants/index'
import { setTableConfiguration, getVaultEvents } from 'store/actions/vault'

const useStyles = makeStyles({
  wrapTableLog: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 24px',
  },
  placeHolder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeHolderTitle: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.BASIC.COLOR[80],
  },
  pagination: {
    marginBottom: 14,
  },
  tableHeaderCell: {
    paddingLeft: 0,
    paddingBottom: 6,
    borderBottom: `2px solid ${colors.BASIC.COLOR[10]}`,
    fontSize: 14,
    fontWeight: 500,
    color: colors.BASIC.COLOR[80],
  },
})

const columnsCase = [
  {
    value: 'date',
    label: 'Date',
    sortable: false,
  },
  {
    value: 'case_folder',
    label: 'Case folder',
    sortable: false,
  },
  {
    value: 'user',
    label: 'User',
    sortable: false,
  },
  {
    value: 'action',
    label: 'Action',
    sortable: false,
  },
]

const columnsPrivate = [
  {
    value: 'date',
    label: 'Date',
    sortable: false,
  },
  {
    value: 'case_folder',
    label: 'Case folder',
    sortable: false,
  },
  {
    value: 'action',
    label: 'Action',
    sortable: false,
  },
]

const LogVaultTable = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { limit, tablePage } = useSelector(state => state.vault.tableConfiguration)
  const vaultEvents = useSelector(state => state.vault.vaultEvents)
  const vaultEventsCount = useSelector(state => state.vault.vaultEventsCount)
  const vaultEventsIsLoading = useSelector(state => state.vault.vaultEventsIsLoading)
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)
  const selectedFolders = useSelector(state => state.vault.selectedFolders)

  const isPrivate = !!(selectedFolders.length !== 0 && selectedFolders[0].name === 'Private')
  const columns = isPrivate ? columnsPrivate : columnsCase

  useEffect(() => {
    if (selectedFolders.length !== 0) dispatch(getVaultEvents(selectedFolders[0].uuid))
  }, [dispatch, selectedFolders])

  const onRowsPerPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
        limit: value,
      })
    )
    dispatch(getVaultEvents(selectedFolders[0].uuid))
  }

  const onPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: value,
        offset: value * limit,
      })
    )
    dispatch(getVaultEvents(selectedFolders[0].uuid))
  }

  return vaultEventsIsLoading ? (
    <ColorCircularProgress />
  ) : vaultEventsCount === 0 ? (
    <div className={classes.placeHolder}>
      <Typography variant="h1" className={classes.placeHolderTitle}>
        there are no logs yet
      </Typography>
    </div>
  ) : (
    <div className={classes.wrapTableLog}>
      <Table
        tableColumns={columns}
        className={classes.logTable}
        tableRowClasses={classes.logRow}
        tableHeaderCellClass={{ root: classes.tableHeaderCell }}
        tableData={vaultEvents}
        tableDataRowRenderer={(columns, data) => (
          <VaultLogTableRow columns={columns} data={data} timezone={timezone} isPrivate={isPrivate} />
        )}
      />
      <div className={classes.pagination}>
        <Pagination
          colSpan={columns.length}
          page={tablePage}
          count={vaultEventsCount}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default LogVaultTable
