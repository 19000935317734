import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'components'
import { colors } from 'constants/index'
import { setVaultSorting, setVaultTrashSorting, setSecureVaultSorting } from 'store/actions/vault'

const useStyles = makeStyles({
  vaultTable: {
    height: '100%',
  },
  tableHeaderCell: {
    borderBottom: `2px solid ${colors.BASIC.COLOR['10']}`,
  },
})

const VaultTable = ({
  data,
  columns,
  tableRow,
  selectable = false,
  draggable = false,
  disableSelection,
  trash,
  secure,
  ...props
}) => {
  const sorting = useSelector(state => state.vault.sort)
  const trashSorting = useSelector(state => state.vault.trashSort)
  const secureSorting = useSelector(state => state.vault.secureSort)
  const dispatch = useDispatch()
  const classes = useStyles()
  const sort = trash ? trashSorting : secure ? secureSorting : sorting
  const onSort = (name, isAcs) => {
    if (trash) dispatch(setVaultTrashSorting(name, isAcs))
    else if (secure) dispatch(setSecureVaultSorting(name, isAcs))
    else dispatch(setVaultSorting(name, isAcs))
  }

  return (
    <Table
      tableColumns={columns}
      className={classes.vaultTable}
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableData={data}
      selectable={selectable}
      sorting={sort}
      onSort={onSort}
      draggable={draggable}
      disableSelection={disableSelection}
      {...props}
      tableDataRowRenderer={(columns, data, hoverRow) =>
        React.cloneElement(tableRow, { columns: columns, data: data, hoverRow: hoverRow })
      }
      activeRows
    />
  )
}

export default VaultTable
