import * as CONSTANTS from 'store/constants/proReview/proReview'

export const initialState = {
  proReviewCommentsSort: {
    property: 'created_at',
    isAsc: false,
  },
  proReviewCommentsFilter: null,
  proReviewComments: [],
  proReviewStats: null,
  isProReviewLoading: false,
  proReviewCommentsCount: 0,
  sortFields: [
    {
      label: 'date',
      value: 'created_at',
    },
    {
      label: 'rating',
      value: 'user_questionnaire__rating',
    },
  ],
  reviewModalOpen: false,
  commentReviewed: false,
}

const proReview = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_PRO_REVIEW_START:
      return {
        ...state,
        isProReviewLoading: true,
      }
    case CONSTANTS.GET_PRO_REVIEW_END:
      return {
        ...state,
        proReviewComments: action.proReviewComments,
        proReviewStats: action.proReviewStats,
        proReviewCommentsCount: action.proReviewCommentsCount,
        isProReviewLoading: false,
      }
    case CONSTANTS.SET_PRO_REVIEW_SORTING:
      return {
        ...state,
        proReviewCommentsSort: {
          property: action.property,
          isAsc: action.isAsc,
        },
      }
    case CONSTANTS.SET_PRO_REVIEW_FILTER:
      return {
        ...state,
        proReviewCommentsFilter: action.payload,
      }
    case CONSTANTS.SET_PRO_REVIEW_MODAL_OPEN:
      return {
        ...state,
        reviewModalOpen: true,
      }
    case CONSTANTS.SET_PRO_REVIEW_MODAL_CLOSE:
      return {
        ...state,
        reviewModalOpen: false,
        commentReviewed: false,
      }
    case CONSTANTS.SET_PRO_COMMENT_REVIEWED:
      return {
        ...state,
        commentReviewed: true,
      }
    default:
      return state
  }
}

export default proReview
