import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { toArray } from 'lodash'
import { Link, Breadcrumbs, Typography, Button, FileUploadButton, Tooltip, VaultCreateFolderModal } from 'components'
import { ReactComponent as BreadcrumbIcon } from 'static/vault/breadcrump.svg'
import { ReactComponent as ButtonFolderIcon } from 'static/vault/button_folder.svg'
import { ReactComponent as DownloadIcon } from 'static/vault/header/download.svg'
import { ReactComponent as MoveIcon } from 'static/vault/header/move.svg'
import { ReactComponent as DeleteIcon } from 'static/vault/header/delete.svg'
import { ReactComponent as CopyIcon } from 'static/vault/header/copy.svg'
import {
  setVaultSelectedFolders,
  createVaultFolder,
  setUploadFile,
  setVaultItemsAction,
  downloadToZipFile,
  downloadVaultFile,
  closeVaultModal,
} from 'store/actions/vault'
import { colors } from 'constants/index'
import { checkFileNameUnique, getDefaultSubFolderName } from 'utils/vault/vault-helpers'
import { MESSAGES } from 'constants/index'
import { navigateToVault } from 'store/actions/navigation'

const useStyles = makeStyles({
  input: {
    display: 'none',
  },
  vaultHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
    textDecoration: 'none',
    '&:hover': {
      color: colors.BASIC.COLOR['100'],
    },
  },
  buttonClass: {
    backgroundColor: colors.PRIMARY.COLOR['0'],
    padding: '6px 16px',
    height: 32,
    marginRight: 12,
    fontSize: 11,
  },
  iconClass: {
    marginRight: 4,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedItemsLabel: {
    fontSize: 15,
    fontWeight: 'normal',
    marginRight: 24,
  },
  tooltipLabel: {
    fontSize: 13,
    fontWeight: 'normal',
  },
})

const getFolderName = name => {
  return name.length < 25 ? name : name.slice(0, 25) + '...'
}

const getBreadCrumbArray = (selectedFolders, isClientVault, proName) => {
  const array = isClientVault ? [{ name: proName }, ...selectedFolders.slice(1)] : selectedFolders
  const lastFolder = array[array.length - 1]
  return array.length < 4 ? array : [...array.slice(0, 2), { name: '...', collapsed: true }, lastFolder]
}

const CasesBreadcrumb = ({ selectedFolders, isClientVault, proName }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const breadcrumbArray = getBreadCrumbArray(selectedFolders, isClientVault, proName)

  const onBreadcrumbsClick = (index, folder) => {
    const newSelectedFolders = folder.collapsed
      ? selectedFolders.slice(0, selectedFolders.length - 1)
      : selectedFolders.slice(0, index + 1)
    dispatch(setVaultSelectedFolders(newSelectedFolders))
  }
  const handleVaultClick = () => {
    dispatch(navigateToVault())
    dispatch(closeVaultModal())
  }

  return (
    <Breadcrumbs separator={<BreadcrumbIcon />}>
      {!isClientVault && (
        <Link className={classes.vaultHeaderTitle} onClick={handleVaultClick}>
          Vault
        </Link>
      )}
      {breadcrumbArray.map((folder, i) => (
        <Link key={folder} className={classes.vaultHeaderTitle} onClick={() => onBreadcrumbsClick(i, folder)}>
          {getFolderName(folder.name)}
        </Link>
      ))}
    </Breadcrumbs>
  )
}

const VaultCasesTabHeader = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const caseID = useSelector(state => state.vault.caseID)
  const currentFolderItemsList = useSelector(state => state.vault.currentFolderItemsList)
  const { selectedFolders, isPrivateFolderSelected, isClientVault, proName, selectedItems } = props
  const [isCreateModal, setIsCreateModal] = useState(false)
  const defaultSubFolderName = getDefaultSubFolderName(currentFolderItemsList)
  const isDownloadAvailable = selectedItems.some(item => !item.isFolder) || selectedItems.some(item => item.fileCount)

  const onCreateModalConfirm = name => {
    dispatch(createVaultFolder(name))
    setIsCreateModal(false)
  }

  const setItemAction = action => {
    dispatch(setVaultItemsAction(action))
  }

  const handleFilesUpload = e => {
    const files = e.currentTarget.files
    dispatch(setUploadFile(toArray(files)))
  }

  const handleDownload = () => {
    if (selectedItems.length > 1) {
      dispatch(downloadToZipFile(selectedItems))
    } else if (selectedItems[0]) {
      if (selectedItems[0].isFolder) {
        dispatch(downloadToZipFile(selectedItems))
      } else {
        dispatch(downloadVaultFile(selectedItems[0]))
      }
    }
  }

  return (
    <>
      <CasesBreadcrumb selectedFolders={selectedFolders} proName={proName} isClientVault={isClientVault} />
      <div className={classes.btnContainer}>
        {selectedItems.length ? (
          <>
            <Typography className={classes.selectedItemsLabel}>
              {`${selectedItems.length} ${selectedItems.length > 1 ? 'items' : 'item'}`}
            </Typography>
            {isDownloadAvailable ? (
              <Button variant={'secondary'} className={classes.buttonClass} onClick={handleDownload}>
                <DownloadIcon className={classes.iconClass} />
                download
              </Button>
            ) : (
              <Tooltip tooltipClass={classes.tooltipLabel} title={MESSAGES.M0094}>
                <Button variant={'secondary'} className={classes.buttonClass} disabled>
                  <DownloadIcon className={classes.iconClass} />
                  download
                </Button>
              </Tooltip>
            )}
            {!isClientVault && (
              <>
                <Button
                  variant={'secondary'}
                  className={classes.buttonClass}
                  onClick={() => setItemAction('mass_copy')}
                >
                  <CopyIcon className={classes.iconClass} />
                  copy
                </Button>
                <Button
                  variant={'secondary'}
                  className={classes.buttonClass}
                  onClick={() => setItemAction('mass_move')}
                >
                  <MoveIcon className={classes.iconClass} />
                  move
                </Button>
                <Button
                  variant={'secondary'}
                  className={classes.buttonClass}
                  onClick={() => setItemAction('mass_delete')}
                >
                  <DeleteIcon className={classes.iconClass} />
                  delete
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {(caseID || isPrivateFolderSelected) && !isClientVault && (
              <Button variant={'secondary'} className={classes.buttonClass} onClick={() => setIsCreateModal(true)}>
                <ButtonFolderIcon className={classes.iconClass} />
                create folder
              </Button>
            )}
            {!!(currentFolderItemsList.length && (caseID || isPrivateFolderSelected)) && (
              <>
                <input
                  multiple
                  name={'vault_files[]'}
                  onChange={handleFilesUpload}
                  className={classes.input}
                  id="vault_file_upload"
                  type="file"
                  value={''}
                />
                <FileUploadButton variant={'secondary'} htmlFor={'vault_file_upload'} label={'UPLOAD FILES'} />
              </>
            )}
          </>
        )}
        <VaultCreateFolderModal
          isOpen={isCreateModal}
          onClose={() => setIsCreateModal(false)}
          defaultSubFolderName={defaultSubFolderName}
          nameCheck={name => checkFileNameUnique(currentFolderItemsList, name)}
          onConfirm={onCreateModalConfirm}
        />
      </div>
    </>
  )
}

export default VaultCasesTabHeader
