import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { colors, routes } from 'constants/index'
import {
  Avatar,
  Breadcrumbs,
  Button,
  ColorCircularProgress,
  Footer,
  LicenseProAccount,
  Link,
  NotFound,
  PracticeAreasProAccount,
  SvgIcon,
  Typography,
} from 'components'
import NoPhoto from 'static/search/pro_no photo.png'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'
import { ReactComponent as ArrowLeftIcon } from 'static/search/ic_arrow-left.svg'
import { ReactComponent as Communication } from 'static/profile/communication.svg'
import { ReactComponent as Clock } from 'static/icon_24_clock.svg'
import axios from 'axios'
import { getUploadedProPath } from '../../utils/path-helpers/api'
import { showNotFound } from '../../store/actions/common'
import { navigateTo } from '../../store/actions/navigation'

const useStyles = makeStyles({
  rootContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
  },
  searchResultContainer: {
    flex: '1 0 auto',
    width: '100%',
    maxWidth: 1280,
    overflowY: 'auto',
    backgroundColor: colors.WHITE,
    padding: '30px 40px',
  },
  link: {
    display: 'flex',
    color: colors.BASIC.COLOR[80],
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    '&:hover': {
      color: colors.BASIC.COLOR[100],
    },
  },
  linkIcon: {
    width: 'auto',
    height: 'auto',
    marginRight: 8,
  },
  searchResultBody: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bodyColumn: {
    marginTop: '46px',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 63px)',
  },
  optionAreasWrapperResponsive: {
    display: 'none',
  },
  avatarContainer: {
    display: 'inherit',
    marginBottom: '32px',
  },
  avatar: {
    height: 200,
    width: 200,
  },
  responsiveTitle: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '25px',
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 600,
    lineHeight: 1.44,
  },
  lineClamp: {
    width: '210px',
    '& + p': {
      display: 'none',
    },
  },
  contactButton: {
    marginTop: 16,
    width: 135,
    height: 40,
    textDecoration: 'none !important',
  },
  attorneyInfo: {
    marginLeft: 32,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  updateProfile: {
    marginTop: 32,
    width: 456,
    padding: 14,
    border: '1px solid #ADDDF9',
    borderRadius: 4,
    backgroundColor: '#EBF6FE',
    display: 'flex',
    '@media(max-width:1023px)': {
      width: '100%',
    },
    '@media(max-width:767px)': {
      maxWidth: 456,
    },
  },
  updateProfileIcon: {
    flex: '0 0 24px',
    width: 24,
    height: 24,
  },
  updateProfileInfo: {
    margin: '0 24px 0 12px',
  },
  updateProfileButton: {
    margin: 'auto',
    flex: '1 0 145px',
    height: 40,
    minHeight: 40,
    whiteSpace: 'nowrap',
    backgroundColor: '#008AED',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0080DB',
    },
  },
  '@media(max-width:1279px)': {
    searchResultContainer: {
      padding: '30px 24px',
    },
    bodyColumn: {
      width: 'calc(50% - 12px)',
    },
    avatarContainerDetails: {
      display: 'flex',
      marginBottom: '13px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatarProDetails: {
      height: 150,
      width: 150,
      cursor: 'pointer',
      marginBottom: 8,
    },
    attorneyInfo: {
      alignItems: 'center',
      marginLeft: 0,
    },
    responsiveTitle: {
      width: '100%',
    },
  },
  '@media(max-width:767px)': {
    searchResultContainer: {
      padding: '30px 15px',
    },
    searchResultBody: {
      flexDirection: 'column',
    },
    bodyColumn: {
      width: '100%',
      marginTop: 32,
      '&:nth-child(2)': {
        marginTop: 0,
      },
    },
    optionAreasWrapper: {
      display: 'none',
    },
    optionAreasWrapperResponsive: {
      display: 'block',
    },
  },
})

const ProfileSearchResultUploaded = ({ match, states }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const proID = match.params.pro_uuid
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    practiceAreas: [],
    licenses: [],
    username: '',
  })
  const { firstName, lastName, practiceAreas, licenses, username } = data
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)

  const onContactPro = () => {
    if (isUserLoggedIn) {
      window.location.href = `mailto:${username}`
    } else {
      dispatch(navigateTo('/sign-up?role=client'))
    }
  }

  useEffect(() => {
    setLoading(true)
    axios
      .get(getUploadedProPath(proID))
      .then(resp => {
        const proData = resp.data
        setData({
          firstName: proData.first_name,
          lastName: proData.last_name,
          practiceAreas: proData.practice_areas,
          licenses: proData.licenses,
          username: proData.username,
        })
      })
      .catch(() => {
        dispatch(showNotFound())
        setData({
          firstName: '',
          lastName: '',
          practiceAreas: [],
          licenses: [],
          username: '',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, proID])

  useEffect(() => {
    Analytics.track(EVENTS.VIEW_PRO_DETAILS, {
      pro_id: match.params.pro_uuid,
    })
  }, [match.params.pro_uuid])

  const fullName =
    firstName.length >= 16 || lastName.length >= 16 ? (
      <>
        <span className={classes.lineClamp}>{firstName}</span>
        <span className={classes.lineClamp}>{lastName}</span>
      </>
    ) : (
      `${firstName} ${lastName}`
    )

  return loading ? (
    <ColorCircularProgress />
  ) : !username ? (
    <NotFound />
  ) : (
    <div className={classes.rootContainer}>
      <div className={classes.searchResultContainer}>
        <div>
          <Breadcrumbs>
            <Link className={classes.link} component={RouterLink} to={routes.SEARCH_PAGE}>
              <SvgIcon classes={{ root: classes.linkIcon }} component={ArrowLeftIcon} viewBox="0 0 20 20" />
              back to search results
            </Link>
          </Breadcrumbs>
        </div>
        <div className={classes.searchResultBody}>
          <div className={classes.bodyColumn}>
            <div className={cn(classes.avatarContainer, classes.avatarContainerDetails)}>
              <Avatar className={cn(classes.avatar, classes.avatarProDetails)} src={NoPhoto} />
              <div className={classes.attorneyInfo}>
                <Typography variant="h1" className={classes.responsiveTitle}>
                  {fullName}
                </Typography>
                <Button
                  className={classes.contactButton}
                  variant="secondary"
                  startIcon={<Communication />}
                  onClick={onContactPro}
                >
                  contact
                </Button>
              </div>
              <Helmet>
                <title>{fullName ? `Attorney ${firstName} ${lastName}` : 'XIRA'}</title>
              </Helmet>
            </div>
            <div>
              {!!(practiceAreas && practiceAreas.length) && (
                <PracticeAreasProAccount practiceAreas={practiceAreas} unspecified />
              )}
            </div>
          </div>
          <div className={classes.bodyColumn}>
            {!!(licenses && licenses.length) && <LicenseProAccount licenses={licenses} states={states} />}
            <div className={classes.updateProfile}>
              <Clock className={classes.updateProfileIcon} />
              <Typography className={classes.updateProfileInfo}>
                Claim your account and update your profile for free
              </Typography>
              {!isUserLoggedIn && (
                <Button
                  component={RouterLink}
                  variant="primary"
                  className={classes.updateProfileButton}
                  to="/sign-up?role=pro"
                >
                  update profile
                </Button>
              )}
            </div>
          </div>
          <div className={classes.optionAreasWrapperResponsive}>
            {!!(practiceAreas && practiceAreas.length) && (
              <PracticeAreasProAccount practiceAreas={practiceAreas} unspecified />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = ({ profileCreation }) => ({
  states: profileCreation.states,
})
export default connect(mapStateToProps)(ProfileSearchResultUploaded)
