import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  Icon,
  Tooltip,
  PoweredByStripeLogo,
  PaymentCardElement,
  InputLabel,
  TextField,
  ConfigureCardModal,
  ClientPaymentList,
} from 'components'
import { CONSTANTS } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { getUserCards, saveUserCard, getClientPaymentSecret } from 'store/actions/payments'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  title: {
    fontSize: 16,
    marginBottom: 10,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnContainerButton: {
    marginTop: -35,
  },
  btnContainerButtonResponsive: {
    display: 'none',
  },
  buttonIcon: {
    marginRight: 4,
    fontSize: 15,
  },
  btnSubmit: {
    marginLeft: 30,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  icon: {
    marginRight: 7,
  },
  tooltip: {
    padding: 15,
    maxWidth: 440,
  },
  tooltipText: {
    fontSize: 13,
    marginTop: 15,
    '&:first-child': {
      marginTop: 0,
    },
  },
  tooltipLabel: {
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  stripeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  stripeLogo: {
    width: 120,
    height: 26,
  },
  cardForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    marginBottom: 32,
  },
  cardsContainer: {
    marginTop: 20,
  },
  checked: {
    color: colors.PRIMARY.COLOR[50],
  },
  radioBtn: {
    position: 'relative',
    top: -8,
    paddingLeft: 0,
    backgroundColor: 'transparent',
  },
  formControlLabel: {
    alignItems: 'baseline',
    width: '100%',
    borderBottom: `1px solid ${colors.BASIC.COLOR[5]}`,
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 32,
    },
  },
  label: {
    width: '100%',
  },
  notched: {
    borderColor: colors.BASIC.COLOR[20],
  },
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 13,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  '@media(max-width:767px)': {
    formControlLabel: {
      margiRight: 0,
      marginLeft: -2,
      '&:last-child': {
        marginBottom: 20,
      },
    },
    btnContainerButton: {
      display: 'none',
    },
    btnContainerButtonResponsive: {
      display: 'block',
      marginBottom: 30,
    },
  },
})
const TooltipContent = ({ tooltipText }) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={tooltipText} variant="subtitle1">
        A temporary credit card hold will be applied to your account to guarantee your attendance. The hold is either
        equal to the initial consultation fee indicated in the lawyer’s profile.
      </Typography>
      <Typography className={tooltipText} variant="subtitle1">
        You can cancel the initial consultation more than 24 hours in advance without incurring charges.
      </Typography>
      <Typography className={tooltipText} variant="subtitle1">
        {'Refer to '}
        <Link to="/client/booking-policy" target="_blank" className={classes.link}>
          XIRA&apos;s booking policy
        </Link>
      </Typography>
    </>
  )
}

const MeetingSchedulerPayment = ({
  cancelButton,
  onBack,
  onContinue,
  userCards,
  paymentCard,
  setPaymentCard,
  clientPaymentSecretKey,
  clientSecretIsLoading,
}) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const [userCardData, setUserCardData] = useState({})
  const [isModalShown, setIsModalShown] = useState(false)
  const [stripeError, setStripeError] = useState('')

  useEffect(() => {
    if (userCardData.payment_method_id) {
      const newCard = userCards.find(card => userCardData.payment_method_id === card.paymentMethodId)
      if (newCard) setPaymentCard(newCard)
    }
  }, [userCards, userCardData])

  const handleSubmit = async () => {
    if (!userCards.length) {
      if (!stripe || !elements) {
        return
      }
      const result = await stripe.confirmCardSetup(clientPaymentSecretKey, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })

      if (result.error) {
        setStripeError(result.error.message)
      } else {
        setStripeError('')
        const data = {
          label: userCardData.label,
          is_default: userCardData.isDefault,
          remember_card: true,
          payment_method_id: result.setupIntent.payment_method,
        }
        try {
          await dispatch(saveUserCard(data))
          dispatch(getUserCards())
          onContinue()
        } catch (e) {
          dispatch(getClientPaymentSecret())
        }
      }
    } else if (paymentCard.uuid) {
      onContinue()
    }
  }

  const onModalSubmit = async data => {
    try {
      const { payment_method_id } = await dispatch(saveUserCard(data))
      await dispatch(getUserCards(payment_method_id))
      setUserCardData(data)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setIsModalShown(false)
    }
  }
  const onAddPayment = () => {
    dispatch(getClientPaymentSecret())
    setIsModalShown(true)
  }
  return (
    <div>
      <Typography classes={{ h4: classes.title }} variant="h4">
        Payment
      </Typography>

      <div className={classes.btnContainer}>
        <Tooltip
          placement="bottom-start"
          childrenClass={classes.tooltipLabel}
          title={<TooltipContent tooltipText={classes.tooltipText} />}
          tooltipClass={classes.tooltip}
          interactive
        >
          Why do I need provide my card?
        </Tooltip>
        {!!userCards.length && (
          <Button variant="secondary" onClick={onAddPayment} className={classes.btnContainerButton}>
            <Icon iconClass="las la-plus-circle" style={classes.buttonIcon} /> add card
          </Button>
        )}
      </div>
      <ClientPaymentList
        userCards={userCards}
        paymentCard={paymentCard}
        setPaymentCard={setPaymentCard}
        userCardData={userCardData}
        setUserCardData={setUserCardData}
        stripeError={stripeError}
      />
      {!!userCards.length && (
        <Button variant="secondary" onClick={onAddPayment} className={classes.btnContainerButtonResponsive}>
          <Icon iconClass="las la-plus-circle" style={classes.buttonIcon} /> add card
        </Button>
      )}
      <div className={classes.btnContainer}>
        {cancelButton}
        <div>
          <Button variant="text" onClick={onBack}>
            back
          </Button>
          <Button onClick={handleSubmit} className={classes.btnSubmit} variant="primary">
            <Icon iconClass="las la-lock" style={classes.icon} />
            continue
          </Button>
        </div>
      </div>
      <ConfigureCardModal
        isOpen={isModalShown}
        onClose={() => setIsModalShown(false)}
        onSave={onModalSubmit}
        mode={CONSTANTS.MODE.ADD}
        clientPaymentSecretKey={clientPaymentSecretKey}
        clientSecretIsLoading={clientSecretIsLoading}
        cardData={{ label: '', isDefault: false, remember: true }}
        showIsRememberOption={userCards.length}
      />
    </div>
  )
}

export default MeetingSchedulerPayment
