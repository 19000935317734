import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { setAuthRole } from 'store/actions/auth'
import { toggleLogin, toggleSignUp, setRedirectPath, toggleRestorePass } from 'store/actions/common'
import { useDispatch, useSelector } from 'react-redux'
import LogInContainer from './LogInContainer'

const LogInDialog = () => {
  const showLogin = useSelector(state => state.common.showLogin)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(showLogin)

  useEffect(() => {
    setOpen(showLogin)
  }, [showLogin])

  const closeModal = () => {
    dispatch(setAuthRole(''))
    dispatch(setRedirectPath(''))
    dispatch(toggleLogin(false))
  }

  const goToSignUp = () => {
    dispatch(toggleLogin(false))
    dispatch(toggleSignUp(true))
  }

  const goToRestore = () => {
    dispatch(toggleLogin(false))
    dispatch(toggleRestorePass(true))
  }

  return <LogInContainer open={open} handleClose={closeModal} goToSignUp={goToSignUp} goToRestore={goToRestore} />
}

export default withRouter(LogInDialog)
