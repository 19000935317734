import React from 'react'
import { Modal, Typography, Button, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    margin: '0 0 24px 0',
    fontSize: '20px',
    display: 'flex',
  },
  subtitle: {
    maxWidth: '568px',
  },
  boxBtn: {
    textAlign: 'right',
    marginTop: 10,
  },
  icon: {
    color: 'red',
    marginRight: 8,
    fontSize: 36,
  },
  errors: {
    margin: 0,
    paddingLeft: 15,
    marginBottom: 32,
  },
  closeBtn: {
    marginRight: 24,
  },
})

const ErrorModal = ({ message, isOpen, onClose, title, cancelationButtonText, variant = 'text' }) => {
  const classes = useStyles()

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false}>
      <div className={classes.modal}>
        <Typography variant="h4" className={classes.title}>
          <Icon iconClass="las la-exclamation-triangle" style={classes.icon} />
          {title}
        </Typography>
        <Typography variant="subtitle1">{message}</Typography>
        <div className={classes.boxBtn}>
          <Button variant={variant} onClick={onClose} className={classes.closeBtn}>
            {cancelationButtonText || 'cancel'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ErrorModal
