import React, { useState, useEffect } from 'react'
import { Typography, Tooltip, Icon, FormTextArea, FormikDatePicker } from 'components'
import { useStyles } from './styles'
import { InvoiceTooltipContent, DescriptionTooltipContent } from './ActivityTooltips'
import { getDateWithTZ } from '../../utils/getters/common'
import { CONSTANTS } from 'constants/index'

const INVOICING_TYPE = {
  BY_DATE: 1,
  PERIODICALLY: 2,
}
const CommonActivityForm = props => {
  const classes = useStyles()
  const [disableInvoicing, setDisabledInvoicing] = useState(false)
  const { values, setFieldValue, errors, touched, setFieldTouched, timezone } = props
  const { case: caseItem, billable, editMode, description, creationDate, invoiceDate } = values
  const { description: descriptionError, creationDate: creationDateError, invoiceDate: invoiceDateError } = errors
  const { creationDate: creationDateTouched, invoiceDate: invoiceDateTouched } = touched
  useEffect(() => {
    setDisabledInvoicing(caseItem.invoicing !== INVOICING_TYPE.BY_DATE || !billable)
  }, [caseItem, billable])
  {
    /*will be used in future*/
  }
  //const showContingencyText = props.values.case.fee_type === 2 && props.type === 'time'
  return (
    <div>
      <div className={classes.activityDescriptionContainer}>
        <div className={classes.switchRow}>
          <Typography className={classes.title}>Description</Typography>
          <Tooltip
            title={<DescriptionTooltipContent tooltipText={classes.tooltipText} />}
            tooltipClass={classes.tooltip}
            interactive
          >
            <Icon style={classes.tooltipIcon} iconClass="las la-question-circle" />
          </Tooltip>
        </div>
        <FormTextArea
          disabled={!editMode}
          rowsMax={3}
          rowsMin={3}
          onChange={setFieldValue}
          areaClass={classes.textArea}
          value={description}
          error={descriptionError}
          characterLimit={150}
          name="description"
        />
      </div>
      <div className={classes.row}>
        <div className={classes.dateContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Activity date*</Typography>
          </div>
          <FormikDatePicker
            disabled={!editMode || caseItem.fee_type === CONSTANTS.CASE_FEE_TYPES_BY_LABEL.INITIAL}
            error={creationDateError}
            touched={creationDateTouched}
            value={creationDate}
            disabledDays={{
              after: getDateWithTZ(null, timezone),
              before: getDateWithTZ(caseItem.date_of_creation, timezone),
            }}
            name="creationDate"
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            calendarOrientation="top"
            timezone={timezone}
          />
        </div>
        <div className={classes.dateContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Invoice date*</Typography>
            <Tooltip
              title={<InvoiceTooltipContent tooltipText={classes.tooltipText} />}
              tooltipClass={classes.tooltip}
              interactive
            >
              <Icon style={classes.tooltipIcon} iconClass="las la-question-circle" />
            </Tooltip>
          </div>
          <FormikDatePicker
            disabled={disableInvoicing || !editMode}
            error={invoiceDateError}
            touched={invoiceDateTouched}
            value={invoiceDate}
            disabledDays={{ before: getDateWithTZ(null, timezone) }}
            name="invoiceDate"
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            calendarOrientation="top"
            timezone={timezone}
          />
        </div>
      </div>
      {/*will be used in future*/}
      {/*<div className={showContingencyText ? classes.contingencyChargeContainer : classes.chargeContainer}>*/}
      {/*  {showContingencyText && (*/}
      {/*    <Typography className={classes.timeContingencyText}>*/}
      {/*      Time activity entries in contingency cases are for tracking purposes. No invoices are generated.*/}
      {/*    </Typography>*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  )
}

export default CommonActivityForm
