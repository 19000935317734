import * as CONSTANTS from 'store/constants/vault'
import { modifyFiles } from 'utils/vault/vault-helpers'
import { CONSTANTS as PROJECT_CONSTANTS } from 'constants/index'

export const initialState = {
  isVaultPlanAccepted: false,
  isVaultModalOpen: false,
  isClientVault: false,
  userID: null,
  caseID: null,
  proID: null,
  userName: '',
  caseName: '',
  proName: '',
  proTwilioChatID: '',
  sort: {
    property: 'last_uploaded',
    isAsc: false,
  },
  secureSort: {
    property: 'last_uploaded',
    isAsc: false,
  },
  trashSort: {
    property: 'deleted',
    isAsc: false,
  },
  itemsList: [],
  itemsListIsLoading: false,
  currentFolderItemsList: [],
  selectedFolders: [],
  isMoving: false,
  isCoping: false,
  isRestoring: false,
  isFolderCreationInProgress: false,
  filesAreUploading: false,
  fileProgress: {},
  pausedUploadFiles: [],
  previewItem: null,
  previewItemLoading: false,
  selectedItems: [],
  singleActionItem: [],
  itemsAction: null,
  isUploadModalOpen: false,
  tableConfiguration: {
    tablePage: 0,
    limit: 20,
    offset: 0,
  },
  vaultEvents: [],
  vaultEventsIsLoading: false,
  vaultEventsCount: 0,
  vaultSizeExceeded: false,
  copyVaultSizeExceeded: false,
  maxCopySize: 0,
  plansRate: [],
  vaultSettings: {},
  vaultSettingsIsLoading: true,
  vaultUsage: [],
  vaultUsageIsloading: true,
  trashList: [],
  trashListIsLoading: false,
  isLoadingDeleteItem: false,
  isQueryFilePreview: false,
  previewedFileId: '',
  isPrivateFolder: false,
  vaultOpenPlace: '',
}

const vault = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.OPEN_VAULT_MODAL:
      return {
        ...state,
        ...action.payload,
      }
    case CONSTANTS.SET_VAULT_PLAN_ACCEPTED:
      return {
        ...state,
        isVaultPlanAccepted: action.payload,
      }
    case CONSTANTS.SET_VAULT_SIZE_EXCEEDED:
      return {
        ...state,
        vaultSizeExceeded: action.payload,
      }
    case CONSTANTS.SET_VAULT_COPY_SIZE_EXCEEDED:
      return {
        ...state,
        copyVaultSizeExceeded: action.payload.isCopyExceeded,
        maxCopySize: action.payload.maxCopySize,
      }
    case CONSTANTS.CLOSE_VAULT_MODAL:
      return {
        ...state,
        isVaultModalOpen: false,
        isClientVault: false,
        userID: null,
        caseID: null,
        previewItem: null,
        proID: null,
        proName: '',
        proTwilioChatID: '',
        selectedFolders: [],
        selectedItems: [],
        currentFolderItemsList: state.itemsList,
        isPrivateFolder: false,
        previewedFileId: null,
      }
    case CONSTANTS.SET_VAULT_USER:
      return {
        ...state,
        userID: action.payload,
      }
    case CONSTANTS.SET_VAULT_ITEMS_ACTION:
      return {
        ...state,
        itemsAction: action.payload,
      }
    case CONSTANTS.SET_VAULT_UPLOAD_ITEMS_MODAL_OPEN:
      return {
        ...state,
        isUploadModalOpen: action.payload,
      }
    case CONSTANTS.SET_VAULT_CASE:
      return {
        ...state,
        caseID: action.payload,
      }
    case CONSTANTS.SET_VAULT_SORTING:
      return {
        ...state,
        sort: {
          property: action.property,
          isAsc: action.isAsc,
        },
        currentFolderItemsList: action.currentFolderItemsList,
      }
    case CONSTANTS.SET_SECURE_VAULT_SORTING:
      return {
        ...state,
        secureSort: {
          property: action.property,
          isAsc: action.isAsc,
        },
        itemsList: action.itemsList,
      }
    case CONSTANTS.SET_VAULT_TRASH_SORTING:
      return {
        ...state,
        trashSort: {
          property: action.property,
          isAsc: action.isAsc,
        },
        trashList: action.trashList,
      }
    case CONSTANTS.GET_VAULT_FILES_LIST_START:
      return {
        ...state,
        itemsListIsLoading: true,
      }
    case CONSTANTS.GET_VAULT_FILES_LIST_END:
      return {
        ...state,
        itemsListIsLoading: false,
        itemsList: action.itemsList,
        currentFolderItemsList: action.currentFolderItemsList,
        caseName: '',
      }
    case CONSTANTS.GET_VAULT_SUBFOLDER_ITEMS:
      return {
        ...state,
        currentFolderItemsList: action.payload,
      }
    case CONSTANTS.SET_VAULT_FOLDER_LIST:
      return {
        ...state,
        currentFolderItemsList: action.payload,
      }
    case CONSTANTS.SET_VAULT_SELECTED_FOLDERS:
      return {
        ...state,
        selectedFolders: action.payload,
      }
    case CONSTANTS.SET_IS_MOVING:
      return {
        ...state,
        isMoving: action.payload,
      }
    case CONSTANTS.SET_COPING:
      return {
        ...state,
        isCoping: action.payload,
      }
    case CONSTANTS.SET_RESTORING:
      return {
        ...state,
        isRestoring: action.payload,
      }
    case CONSTANTS.SET_VAULT_FOLDER_CREATION_PROGRESS:
      return {
        ...state,
        isFolderCreationInProgress: action.payload,
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload),
        },
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_END:
      return {
        ...state,
        filesAreUploading: action.filesAreUploading,
        fileProgress: action.fileProgress,
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_START:
      return {
        ...state,
        filesAreUploading: true,
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_UUID:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            file_uuid: action.payload.file_uuid,
            source: action.payload.source,
          },
        },
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: PROJECT_CONSTANTS.FILE_UPLOAD_STATUS.SUCCESS,
          },
        },
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: PROJECT_CONSTANTS.FILE_UPLOAD_STATUS.FAILED,
            progress: 0,
          },
        },
      }
    case CONSTANTS.SET_VAULT_UPLOAD_FILE_CANCELLED:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: PROJECT_CONSTANTS.FILE_UPLOAD_STATUS.CANCELLED,
            progress: 0,
          },
        },
      }
    case CONSTANTS.SET_VAULT_PREVIEW_ITEM:
      return {
        ...state,
        previewItem: action.payload,
      }
    case CONSTANTS.SET_VAULT_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      }
    case CONSTANTS.SET_VAULT_PLANS_RATE:
      return {
        ...state,
        plansRate: action.payload,
      }
    case CONSTANTS.SET_VAULT_SETTINGS:
      return {
        ...state,
        vaultSettings: action.payload,
      }
    case CONSTANTS.SET_VAULT_SETTINGS_LOADING_START:
      return {
        ...state,
        vaultSettingsIsLoading: true,
      }
    case CONSTANTS.SET_VAULT_SETTINGS_LOADING_END:
      return {
        ...state,
        vaultSettingsIsLoading: false,
      }
    case CONSTANTS.SET_VAULT_USAGE:
      return {
        ...state,
        vaultUsage: action.payload,
      }
    case CONSTANTS.SET_VAULT_USAGE_LOADING_START:
      return {
        ...state,
        vaultUsageIsloading: true,
      }
    case CONSTANTS.SET_VAULT_USAGE_LOADING_END:
      return {
        ...state,
        vaultUsageIsloading: false,
      }
    case CONSTANTS.SET_TABLE_CONFIGURATION:
      return {
        ...state,
        tableConfiguration: {
          ...state.tableConfiguration,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_VAULT_EVENTS:
      return {
        ...state,
        vaultEvents: action.payload.events,
        vaultEventsCount: action.payload.count,
      }
    case CONSTANTS.SET_VAULT_EVENTS_LOADING_START:
      return {
        ...state,
        vaultEventsIsLoading: true,
      }
    case CONSTANTS.SET_VAULT_EVENTS_LOADING_END:
      return {
        ...state,
        vaultEventsIsLoading: false,
      }
    case CONSTANTS.GET_VAULT_TRASH_LIST_START:
      return {
        ...state,
        trashListIsLoading: true,
      }
    case CONSTANTS.GET_VAULT_TRASH_LIST_END:
      return {
        ...state,
        trashListIsLoading: false,
        trashList: action.payload,
      }
    case CONSTANTS.SET_VAULT_SINGLE_ACTION_ITEM:
      return {
        ...state,
        singleActionItem: action.payload,
      }
    case CONSTANTS.SET_LOADING_DELETE_ITEM_START:
      return {
        ...state,
        isLoadingDeleteItem: true,
      }
    case CONSTANTS.SET_LOADING_DELETE_ITEM_END:
      return {
        ...state,
        isLoadingDeleteItem: false,
      }
    case CONSTANTS.GET_VAULT_PREVIEW_ITEM_START:
      return {
        ...state,
        previewItemLoading: true,
      }
    case CONSTANTS.GET_VAULT_PREVIEW_ITEM_END:
      return {
        ...state,
        previewItemLoading: false,
        previewedFileId: null,
      }
    case CONSTANTS.SET_VAULT_PAUSED_UPLOAD_FILES:
      return {
        ...state,
        pausedUploadFiles: action.payload,
      }
    case CONSTANTS.SET_VAULT_OPEN_PLACE:
      return {
        ...state,
        vaultOpenPlace: action.payload,
      }
    default:
      return state
  }
}

export default vault
