import { SvgIcon, Typography } from '../index'
import React from 'react'
import { ReactComponent as NoMessagesImage } from 'static/chat/il_no messages.svg'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'

const useStyles = makeStyles({
  emptyState: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 260,
    width: 330,
  },
  emptyMessageText: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'center',
    color: colors.BLACK,
  },
  iconHidden: {
    display: 'none!important',
  },
  '@media(max-height:720px)': {
    iconResponsive: {
      display: 'block',
    },
  },
  '@media(max-width:1280px)': {
    iconResponsive: {
      display: 'block',
    },
  },
})

const NoMessagesState = props => {
  const classes = useStyles()
  const { text, showImage = true } = props

  return (
    <div className={classes.emptyState}>
      <SvgIcon
        viewBox="0 0 330 260"
        className={classNames({
          [classes.image]: true,
          [classes.iconHidden]: !showImage,
          [classes.iconResponsive]: true,
        })}
      >
        <NoMessagesImage />
      </SvgIcon>

      <Typography className={classes.emptyMessageText}>{text}</Typography>
    </div>
  )
}

export default NoMessagesState
