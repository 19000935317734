import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'
import { ProComment, Button, ProCommentsTitle } from 'components'

const useStyles = makeStyles({
  proCommentsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 24,
    width: '100%',
  },
  proCommentsListProfile: {
    marginTop: 48,
  },
  showAllReview: {
    padding: '6px 16px',
    height: 32,
    backgroundColor: colors.PRIMARY.COLOR['0'],
    fontSize: 11,
    marginTop: 24,
  },
  proCommentsListContainerBig: {
    '@media(max-width:767px) and (min-width:320px)': {
      display: 'none',
    },
  },
  proCommentsListWrapper: {
    width: '100%',
  },
})

const ProCommentsList = ({
  rating,
  reviews,
  comments,
  isProfile,
  openRatingModal,
  proReviewCommentsCount,
  isPro,
  selectedComment,
  stats,
  proID,
}) => {
  const classes = useStyles()
  const commentsList = isProfile ? comments.slice(0, 4) : comments
  return (
    <div
      id="commentsList"
      className={classNames(classes.proCommentsListContainer, { [classes.proCommentsListProfile]: isProfile })}
    >
      {isProfile && (
        <ProCommentsTitle
          reviews={reviews}
          rating={rating}
          isProfile
          openRatingModal={openRatingModal}
          stats={stats}
          proID={proID}
        />
      )}
      <div className={classNames(classes.proCommentsListWrapper, { [classes.proCommentsListContainerBig]: isProfile })}>
        {commentsList.map(comment => (
          <ProComment
            comment={comment}
            isPro={isPro}
            key={comment.uuid}
            isProfile={isProfile}
            isSelected={selectedComment === comment.uuid}
          />
        ))}
        {openRatingModal && proReviewCommentsCount > 4 && (
          <Button variant="secondary" className={classes.showAllReview} onClick={() => openRatingModal()}>
            {`show all ${proReviewCommentsCount} comments`}
          </Button>
        )}
      </div>
    </div>
  )
}

export default ProCommentsList
