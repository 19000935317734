export const getConnectionsParams = state => {
  return {
    limit: state.connections.limit,
    offset: state.connections.offset,
  }
}

export const getProConnectionsParams = state => {
  return {
    search: state.connections.filters.search || null,
    limit: state.connections.tableConfiguration.limit,
    offset: state.connections.tableConfiguration.offset,
    ordering: state.connections.sort.property
      ? `${state.connections.sort.isAsc ? '' : '-'}${state.connections.sort.property}`
      : null,
  }
}
