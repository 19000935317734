import React, { useEffect, useState } from 'react'
import { colors } from 'constants/index'
import { AdditionalWalletFilters, Button, CommonFilterButton, CommonSearch, ExportButton } from 'components'
import {
  exportWallet,
  getWalletItems,
  getWalletPendingFunds,
  setTableConfiguration,
  setWalletFilter,
} from 'store/actions/wallet/wallet'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'

const useStyles = makeStyles({
  filters: {
    display: 'flex',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    padding: '5px 15px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  search: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
  btnIcon: {
    marginRight: 4,
  },
  activeFilterBtn: {
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  exportBtn: {
    padding: '10px 24px',
    border: 'solid 1px #bbbab9',
    color: colors.BASIC.COLOR[80],
    fill: colors.BASIC.COLOR[80],
    borderRadius: 0,
    height: 40,
    minHeight: 40,
    '&:hover': {
      fill: colors.PRIMARY.COLOR[50],
    },
  },
  revenueButton: {
    minHeight: 40,
    height: 40,
    width: 200,
    padding: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const WalletTableFilters = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selectedTab, onRequestFunds } = props
  const filters = useSelector(state => state.wallet.filters)
  const initialFilters = useSelector(state => state.wallet.initialFilters)
  const walletPendingFundsLength = useSelector(state => state.wallet.walletPendingFundsLength)
  const walletItemsLength = useSelector(state => state.wallet.walletItemsLength)
  const count = selectedTab === 0 ? walletItemsLength : walletPendingFundsLength
  const [additionalFilter, setAdditionalFilter] = useState(false)
  const [search, setSearch] = useState(filters.search)

  useEffect(() => {
    if (filters.action) setAdditionalFilter(true)
  }, [filters.action])

  useEffect(() => {
    setSearch(filters.search)
  }, [filters.search])

  const getWalletData = () => {
    if (selectedTab === 0) {
      dispatch(getWalletItems())
    } else if (selectedTab === 1) {
      dispatch(getWalletPendingFunds())
    }
  }

  return (
    <div className={classes.filtersContainer}>
      <div className={classes.filters}>
        <CommonSearch
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          setFilter={value => dispatch(setWalletFilter('search', value))}
          setTableConfiguration={() => dispatch(setTableConfiguration({ tablePage: 0, offset: 0 }))}
          getSearchResults={getWalletData}
          searchClass={classes.search}
        />
        <CommonFilterButton onClick={() => setAdditionalFilter(show => !show)} showFilters={additionalFilter} range />
        <ExportButton variant="secondary" className={classes.exportBtn} getFileInfo={exportWallet} disabled={!count} />
        <Button variant="primary" onClick={onRequestFunds} className={classes.revenueButton}>
          request funds
        </Button>
      </div>
      {additionalFilter && (
        <AdditionalWalletFilters
          filters={filters}
          getWalletData={getWalletData}
          count={count}
          isInitialFilters={isEqual(filters, initialFilters)}
          selectedTab={selectedTab}
        />
      )}
    </div>
  )
}

export default WalletTableFilters
