import moment from 'moment-timezone'
import { CONSTANTS } from 'constants/index'

export const getEventsParams = state => {
  return {
    limit: state.events.limit,
    offset: state.events.offset,
    ordering: state.events.ordering,
    start_time: state.events.currentTimestamp,
  }
}

export const getMeetingDay = (start, timezone) =>
  timezone
    ? moment(start)
        .tz(timezone)
        .format(CONSTANTS.DATE_FORMAT.FORMAT_TWO)
    : moment(start).format(CONSTANTS.DATE_FORMAT.FORMAT_TWO)

export const getMeetingRange = (start, end, timezone) =>
  timezone
    ? `${moment(start)
        .tz(timezone)
        .format('h:mm')} - ${moment(end)
        .tz(timezone)
        .format('h:mm a')}`
    : `${moment(start).format('h:mm')} - ${moment(end).format('h:mm a')}`

export const getMeetingDuration = (start, end) => {
  const startTime = moment(start)
  const endTime = moment(end)
  const diff = endTime.diff(startTime)
  const diffDuration = moment.duration(diff)
  const hours = diffDuration.hours()
  const minutes = diffDuration.minutes()

  return `${hours ? `${hours} hour ` : ''}${minutes ? `${minutes} minutes` : ''}`
}
export const getMeetingDurationTime = (start, end) => {
  const startTime = moment(start)
  const endTime = moment(end)
  const diff = endTime.diff(startTime)
  const duration = diff / 1000 / 60
  return duration > 30 ? duration : 30
}

export const getRestrictionError = error => {
  const errorTitle = error.response && error.response.data && error.response.data.title && error.response.data.title[0]
  switch (errorTitle) {
    case "You can't schedule this meeting":
    case "You can't reschedule this meeting":
    case "You can't reschedule this meeting to the selected time":
    case "You can't cancel this meeting":
    case 'Client is blocked':
    case 'Sorry, that time is no longer available':
      return true
    default:
      return null
  }
}
