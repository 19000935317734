import * as CONSTANTS from 'store/constants/cases'
import axios, { CancelToken } from 'axios'
import { openSnackbar, openErrorModal } from 'store/actions/common'
import { getCasesDetailsPath, getCasesPath, getUserPracticeAreasFlat } from 'utils/path-helpers/api'
import { getCasesParameters } from 'utils/getters/cases'
import { mapCaseDataToBack } from 'utils/mappers/backend'
import { getUserConnectionsPath } from '../../../utils/path-helpers/api'

export const setTableConfiguration = payload => ({
  type: CONSTANTS.SET_TABLE_CONFIGURATION,
  payload,
})

export const setCasesFilter = (name, value) => ({
  type: CONSTANTS.SET_FILTERS,
  name,
  value,
})

export const resetCasesFilter = () => ({
  type: CONSTANTS.RESET_FILTERS,
})

export const resetCasesSorting = () => ({
  type: CONSTANTS.RESET_SORTING,
})

export const setCasesSorting = (property, isAsc) => {
  return {
    type: CONSTANTS.SET_SORTING,
    property,
    isAsc,
  }
}

const getCasesStart = () => ({
  type: CONSTANTS.GET_CASES_START,
})

const getCasesEnd = (payload, length) => ({
  type: CONSTANTS.GET_CASES_END,
  payload,
  length,
})

const getCasesFilterStart = () => ({
  type: CONSTANTS.GET_CASES_FILTER_START,
})

const getCasesFilterEnd = payload => ({
  type: CONSTANTS.GET_CASES_FILTER_END,
  payload,
})

let cancelProCasesRequest

export const getProCases = () => (dispatch, getState) => {
  cancelProCasesRequest && cancelProCasesRequest()
  dispatch(getCasesStart())
  return axios
    .get(getCasesPath(), {
      params: getCasesParameters(getState().cases),
      cancelToken: new CancelToken(c => {
        cancelProCasesRequest = c
      }),
    })
    .then(response => {
      dispatch(getCasesEnd(response.data.results, response.data.count))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(getCasesEnd([]))
      dispatch(openSnackbar('error', 'Error while loading cases'))
      return Promise.reject(e)
    })
}

export const getActiveFeeFilterProCases = () => (dispatch, getState) => {
  dispatch(getCasesFilterStart())
  return axios
    .get(getCasesPath(), {
      params: {
        is_active: true,
        fee_types: true,
      },
    })
    .then(response => {
      dispatch(getCasesFilterEnd(response.data))
    })
    .catch(e => {
      dispatch(getCasesFilterEnd([]))
      dispatch(openSnackbar('error', 'Error while loading cases'))
      return Promise.reject(e)
    })
}

export const getFilterProCases = () => (dispatch, getState) => {
  dispatch(getCasesFilterStart())
  return axios
    .get(getCasesPath())
    .then(response => {
      dispatch(getCasesFilterEnd(response.data))
    })
    .catch(e => {
      dispatch(getCasesFilterEnd([]))
      dispatch(openSnackbar('error', 'Error while loading cases'))
      return Promise.reject(e)
    })
}

export const getProCasesWithActivities = () => (dispatch, getState) => {
  dispatch(getCasesStart())
  return axios
    .get(getCasesPath(), {
      params: {
        activities_exists: true,
      },
    })
    .then(response => {
      dispatch(getCasesEnd(response.data))
    })
    .catch(e => {
      dispatch(getCasesEnd([]))
      dispatch(openSnackbar('error', 'Error while loading cases'))
      return Promise.reject(e)
    })
}

const getProCaseFullInfoStart = () => ({
  type: CONSTANTS.GET_CASE_FULL_INFO_START,
})

const getProCaseFullInfoEnd = payload => ({
  type: CONSTANTS.GET_CASE_FULL_INFO_END,
  payload,
})

export const getProCaseFullInfo = uuid => dispatch => {
  dispatch(getProCaseFullInfoStart())
  return axios
    .get(getCasesDetailsPath(uuid))
    .then(payload => {
      dispatch(getProCaseFullInfoEnd(payload.data))
    })
    .catch(e => {
      dispatch(getProCaseFullInfoEnd(null))
      dispatch(openSnackbar('error', 'Error while loading case details'))
      return Promise.reject(e)
    })
}

const updateProCasesStart = () => ({
  type: CONSTANTS.UPDATE_CASES_START,
})

const updateProCasesEnd = () => ({
  type: CONSTANTS.UPDATE_CASES_END,
})

export const updateProCases = (uuid, data) => (dispatch, getState) => {
  dispatch(updateProCasesStart())
  return axios
    .patch(getCasesDetailsPath(uuid), mapCaseDataToBack(data, getState().calendar))
    .then(() => {
      dispatch(updateProCasesEnd())
      dispatch(openSnackbar('success', 'Case saved'))
    })
    .catch(e => {
      dispatch(updateProCasesEnd())
      const message =
        e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while updating case'
      dispatch(openSnackbar('error', message))
      return Promise.reject(e)
    })
}

const makeCaseInactiveStart = () => ({
  type: CONSTANTS.MAKE_CASE_INACTIVE_START,
})

const makeCaseInactiveEnd = () => ({
  type: CONSTANTS.MAKE_CASE_INACTIVE_END,
})

export const makeCaseInactive = (uuid, name) => dispatch => {
  dispatch(makeCaseInactiveStart())
  return axios
    .patch(getCasesDetailsPath(uuid), {
      is_active: false,
    })
    .then(() => {
      dispatch(makeCaseInactiveEnd())
      dispatch(openSnackbar('success', 'Case inactivated'))
    })
    .catch(e => {
      dispatch(makeCaseInactiveEnd())
      if (
        e.response &&
        e.response.data &&
        e.response.data.title &&
        e.response.data.title === 'You can not make the case inactive'
      ) {
        dispatch(openErrorModal(e.response.data.title))
        return Promise.reject(e)
      }
      const errorMessage =
        e.response && e.response.data && e.response.data.detail
          ? e.response.data.detail
          : `You can not make case ${name} inactive until all invoices are generated for all activities and all invoices are paid.`
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject(e)
    })
}

const getInitialCasesStart = () => ({
  type: CONSTANTS.GET_INITIAL_CASES_START,
})

const getInitialCasesEnd = payload => ({
  type: CONSTANTS.GET_INITIAL_CASES_END,
  payload,
})

export const getProInitialCases = () => dispatch => {
  dispatch(getInitialCasesStart())
  return axios
    .get(getCasesPath(), {
      params: {
        fee_type: 0,
        is_linked: false,
      },
    })
    .then(response => {
      dispatch(getInitialCasesEnd(response.data))
    })
    .catch(e => {
      dispatch(getInitialCasesEnd([]))
      dispatch(openSnackbar('error', 'Error while loading initial cases'))
      return Promise.reject(e)
    })
}

const saveProCaseStart = () => ({
  type: CONSTANTS.SAVE_PRO_CASE_START,
})

const saveProCaseEnd = () => ({
  type: CONSTANTS.SAVE_PRO_CASE_END,
})

export const saveProCase = data => (dispatch, getState) => {
  dispatch(saveProCaseStart())
  return axios
    .post(getCasesPath(), mapCaseDataToBack(data, getState().calendar))
    .then(() => {
      dispatch(saveProCaseEnd())
      dispatch(openSnackbar('success', 'New case created'))
    })
    .catch(e => {
      dispatch(saveProCaseEnd([]))
      const errorMessage =
        e.response && e.response.data && e.response.data.detail ? e.response.data.detail : 'Error while saving case'
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject(e)
    })
}

const getProSpecificPracticeAreasStart = () => ({
  type: CONSTANTS.GET_PRO_SPECIFIC_PRACTICE_AREAS_START,
})

const getProSpecificPracticeAreasEnd = payload => ({
  type: CONSTANTS.GET_PRO_SPECIFIC_PRACTICE_AREAS_END,
  payload,
})

export const getProSpecificPracticeAreas = () => (dispatch, getState) => {
  dispatch(getProSpecificPracticeAreasStart())
  return axios
    .get(getUserPracticeAreasFlat())
    .then(response => {
      dispatch(getProSpecificPracticeAreasEnd(response.data))
    })
    .catch(e => {
      dispatch(getProSpecificPracticeAreasEnd([]))
      dispatch(openSnackbar('error', 'Error while downloading user practise areas'))
      return Promise.reject(e)
    })
}

const setAddCasesButtonActive = payload => ({
  type: CONSTANTS.SET_ADD_CASES_BUTTON_ACTIVE,
  payload,
})

export const checkProConnections = () => dispatch => {
  return axios
    .get(getUserConnectionsPath(), {
      params: {
        limit: 1,
        offset: 0,
      },
    })
    .then(response => {
      dispatch(setAddCasesButtonActive(!!response.data.results.length))
    })
    .catch(e => {
      dispatch(setAddCasesButtonActive(false))
      return Promise.reject(e)
    })
}

const setUserHasCases = payload => ({
  type: CONSTANTS.SET_USER_HAS_CASES,
  payload,
})

const checkProHasCasesStart = payload => ({
  type: CONSTANTS.USER_HAS_CASES_START,
  payload,
})

export const checkProCases = () => dispatch => {
  dispatch(checkProHasCasesStart())
  return axios
    .get(getCasesPath(), {
      params: {
        limit: 1,
        offset: 0,
      },
    })
    .then(response => {
      dispatch(setUserHasCases(!!response.data.results.length))
    })
    .catch(e => {
      dispatch(setUserHasCases(false))
      return Promise.reject(e)
    })
}
