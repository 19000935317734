import * as CONSTANTS from 'store/constants/events'
import axios, { CancelToken } from 'axios'
import {
  getUserCalendarEventsPath,
  getUserMeetingCancelPath,
  getUserMeetingDeletePath,
  getUserMeetingPath,
  getUserMeetingReschedulePath,
  getUserMeetingsPath,
} from 'utils/path-helpers/api'
import { mapMeetingInfoToFront, mapProMeetingInfoToFront, mapExternalMeetingInfoToFront } from 'utils/mappers/backend'
import { getEventsParams } from 'utils/getters/events'
import { openSnackbar, openErrorModal } from 'store/actions/common'
import { getRestrictionError } from 'utils/getters'

export const getUserEventsStart = () => ({
  type: CONSTANTS.GET_USER_EVENTS_START,
})

const getUserEventsEnd = payload => ({
  type: CONSTANTS.GET_USER_EVENTS_END,
  payload,
})

let cancelUserEventsRequest

export const getProEvents = (startDate, endDate) => dispatch => {
  dispatch(getUserEventsStart())
  cancelUserEventsRequest && cancelUserEventsRequest()
  return axios
    .get(getUserMeetingsPath(), {
      params: {
        start_time: startDate,
        end_time: endDate,
      },
      cancelToken: new CancelToken(c => {
        cancelUserEventsRequest = c
      }),
    })
    .then(response => {
      const data = response.data.map(event => mapProMeetingInfoToFront(event))
      dispatch(getUserEventsEnd(data))
      return data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(getUserEventsEnd([]))
      dispatch(openSnackbar('error', 'Error while loading events'))
      return Promise.reject(e)
    })
}

export const getUserEventStart = () => ({
  type: CONSTANTS.GET_USER_EVENT_START,
})

const getUserEventEnd = payload => ({
  type: CONSTANTS.GET_USER_EVENT_END,
  payload,
})

let cancelEventDetailsRequest

export const getUserEventDetails = uuid => dispatch => {
  dispatch(getUserEventStart())
  cancelEventDetailsRequest && cancelEventDetailsRequest()

  return axios
    .get(getUserMeetingPath(uuid), {
      cancelToken: new CancelToken(c => {
        cancelEventDetailsRequest = c
      }),
    })
    .then(response => {
      const data = mapMeetingInfoToFront(response.data)
      dispatch(getUserEventEnd(data))
      return data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(getUserEventEnd({}))
      dispatch(openSnackbar('error', 'Error while loading event details'))
      return Promise.reject(e)
    })
}

export const resetEventsUploadOffset = () => ({
  type: CONSTANTS.RESET_EVENTS_UPLOAD_OFFSET,
})

export const increaseEventsUploadOffset = () => ({
  type: CONSTANTS.INCREASE_EVENTS_UPLOAD_OFFSET,
})

export const setEventsCurrentTimestamp = payload => ({
  type: CONSTANTS.SET_EVENTS_CURRENT_TIMESTAMP,
  payload,
})

export const getClientEventsStart = () => ({
  type: CONSTANTS.GET_CLIENT_EVENTS_START,
})

const getClientEventsEnd = (payload, length) => ({
  type: CONSTANTS.GET_CLIENT_EVENTS_END,
  payload,
  length,
})

export const getClientEvents = (isReloading = true) => (dispatch, getState) => {
  dispatch(getClientEventsStart())

  return axios
    .get(getUserMeetingsPath(), {
      params: getEventsParams(getState()),
    })
    .then(response => {
      const data = response.data.results.map(event => mapMeetingInfoToFront(event))
      const newData = isReloading ? data : [...getState().events.meetings, ...data]
      dispatch(getClientEventsEnd(newData, response.data.count))
    })
    .catch(e => {
      dispatch(getClientEventsEnd([]))
      dispatch(openSnackbar('error', 'Error while loading events'))
      return Promise.reject(e)
    })
}

export const cancelUserMeetingStart = payload => ({
  type: CONSTANTS.CANCEL_USER_MEETING_START,
  payload,
})

export const cancelUserMeetingEnd = () => ({
  type: CONSTANTS.CANCEL_USER_MEETING_END,
})

export const cancelUserMeeting = (uuid, reason, IsReschedule) => dispatch => {
  dispatch(cancelUserMeetingStart())

  return axios
    .put(getUserMeetingCancelPath(uuid), {
      update_reason: reason,
      is_reschedule: IsReschedule,
    })
    .then(() => {
      dispatch(cancelUserMeetingEnd())
      dispatch(openSnackbar('success', 'Event successfully canceled'))
    })
    .catch(e => {
      dispatch(cancelUserMeetingEnd())
      if (getRestrictionError(e)) {
        dispatch(openErrorModal(e.response.data.title[0], e.response.data.detail[0]))
        return Promise.reject(e)
      }
      dispatch(openSnackbar('error', 'Error while canceling event'))
      return Promise.reject(e)
    })
}

export const rescheduleUserMeetingStart = payload => ({
  type: CONSTANTS.RESCHEDULE_USER_MEETING_START,
  payload,
})

export const rescheduleUserMeetingEnd = () => ({
  type: CONSTANTS.RESCHEDULE_USER_MEETING_END,
})

export const rescheduleUserMeeting = (uuid, newMeeting, reason) => dispatch => {
  dispatch(rescheduleUserMeetingStart())

  return axios
    .put(getUserMeetingReschedulePath(uuid), {
      update_reason: reason,
      start_time: newMeeting.startTime,
      end_time: newMeeting.endTime,
      client_timezone: newMeeting.timezone,
      payment_method_id: newMeeting.paymentId,
    })
    .then(response => {
      dispatch(rescheduleUserMeetingEnd())
      dispatch(openSnackbar('success', 'Event successfully rescheduled'))
      return {
        data: response.data,
        error: '',
      }
    })
    .catch(e => {
      dispatch(rescheduleUserMeetingEnd())
      if (getRestrictionError(e)) {
        dispatch(openErrorModal(e.response.data.title[0], e.response.data.detail[0]))
        return Promise.reject(e)
      }
      dispatch(openSnackbar('error', 'Error while rescheduling event'))
      return {
        data: {},
        error: (e.response && e.response.data && e.response.data.description) || 'Error while rescheduling event',
        instruction: (e.response && e.response.data && e.response.data.instructions) || '',
        isStripeError: e.response && e.response.data ? e.response.data.is_stripe_error : false,
      }
    })
}

export const deleteClientMeeting = uuid => dispatch => {
  return axios
    .delete(getUserMeetingDeletePath(uuid))
    .then(() => {
      dispatch(resetEventsUploadOffset())
      dispatch(getClientEvents())
      dispatch(openSnackbar('success', 'Event successfully deleted'))
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while deleting event'))
      return Promise.reject(e)
    })
}

export const deleteProMeeting = uuid => dispatch => {
  return axios
    .delete(getUserMeetingDeletePath(uuid))
    .then(() => {
      dispatch(openSnackbar('success', 'Event successfully deleted'))
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while deleting event'))
      return Promise.reject(e)
    })
}

export const resetEventList = () => ({
  type: CONSTANTS.RESET_EVENTS_LIST,
})

let cancelProCalendarEventsRequest

export const getProCalendarEvents = (startTime, endTime) => dispatch => {
  cancelProCalendarEventsRequest && cancelProCalendarEventsRequest()
  return axios
    .get(getUserCalendarEventsPath(), {
      params: {
        start_time: startTime,
        end_time: endTime,
      },
      cancelToken: new CancelToken(c => {
        cancelProCalendarEventsRequest = c
      }),
    })
    .then(response =>
      response.data.map(event => {
        if (event.is_external_event === true) {
          return mapExternalMeetingInfoToFront(event)
        } else {
          return mapProMeetingInfoToFront(event)
        }
      })
    )
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(openSnackbar('error', 'Error while loading calendar events'))
      return Promise.reject(e)
    })
}
