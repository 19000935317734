import React from 'react'
import { FormikInput } from 'components'
import { useStyles } from '../styles'
import {formatDecimal} from "utils/getters/common";

const ExpenseFormBody = ({ values, setFieldValue }) => {
  const classes = useStyles()
  const { editMode } = values
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <FormikInput
          variant="outlined"
          size="small"
          name="expense"
          label="Expense amount, $"
          placeholder="0.00"
          type="text"
          fullWidth
          disabled={!editMode}
          handleBlur={e => setFieldValue('expense', formatDecimal(e.target.value))}
          inputProps={{ style: { textAlign: 'right' } }}
          containerClasses={classes.autocompleteContainer}
        />
      </div>
      {/*will be used in future*/}
      {/*<div className={classes.switchRow}>*/}
      {/*  <Switch*/}
      {/*    color="default"*/}
      {/*    formControlClasses={{ root: classes.formControlClasses }}*/}
      {/*    labelClasses={{ root: classes.checkboxLabel }}*/}
      {/*    disabled={(!values.case.expense_invoicing && values.case.fee_type !== 3) || !values.editMode}*/}
      {/*    label="Billable"*/}
      {/*    checked={values.billable}*/}
      {/*    onChange={e => setFieldValue('billable', e.target.checked)}*/}
      {/*  />*/}
      {/*  <Tooltip*/}
      {/*    title={<BillableTooltipContent billable={values.billable} tooltipText={classes.tooltipText} />}*/}
      {/*    tooltipClass={classes.tooltip}*/}
      {/*    interactive*/}
      {/*  >*/}
      {/*    <Icon style={classes.tooltipIcon} iconClass="las la-question-circle" />*/}
      {/*  </Tooltip>*/}
      {/*  {values.billable && values.case.fee_type === 3 && (*/}
      {/*    <Switch*/}
      {/*      color="default"*/}
      {/*      labelClasses={{ root: classes.checkboxLabel }}*/}
      {/*      label="Charge"*/}
      {/*      disabled={!values.editMode}*/}
      {/*      checked={values.charge}*/}
      {/*      onChange={e => setFieldValue('charge', e.target.checked)}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  )
}

export default ExpenseFormBody
