import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as GlobeLogo } from 'static/icon_24_ globe.svg'
import { ReactComponent as CloseLogo } from 'static/ic_close_32.svg'
import { colors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import { getTimezoneLabel } from 'utils/getters/timezone'
import { Button, Chip, Link, Typography } from 'components'
import moment from 'moment'
import classNames from 'classnames'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { setMeetingDuration } from '../../../store/actions/search'

const useStyles = makeStyles({
  meetingTimeRangeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '23px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  localeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timezone: {
    marginLeft: 8,
    color: colors.BASIC.COLOR[70],
    fontWeight: 'normal',
  },
  timeslotContainer: {
    marginTop: 17,
    display: 'grid',
  },
  timeslotContainerWide: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    '-ms-grid-template-columns': '1fr 1fr 1fr 1fr',
  },
  timeslotContainerSlim: {
    gridTemplateColumns: '1fr 1fr',
    '-ms-grid-template-columns': '1fr 1fr',
  },
  timeslot: {
    borderRadius: 4,
    margin: '0 2px 8px 2px',
    height: 39,
    border: `1px solid ${colors.BASIC.COLOR[100]}`,
    '& .MuiChip-label': {
      fontSize: 15,
    },
    '&.MuiChip-outlined:hover': {
      backgroundColor: colors.PRIMARY.COLOR[50],
      color: colors.WHITE,
      borderColor: colors.PRIMARY.COLOR[50],
    },
  },
  buttonRoleOutlined: {
    width: '50%',
    height: 32,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: '5px 24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none',
    borderColor: '#d0cfcf',
    color: colors.BASIC.COLOR[70],
    backgroundColor: colors.BASIC.COLOR[0],
    '&:last-child': {
      borderBottomRightRadius: 16,
      borderTopRightRadius: 16,
    },
    '&:first-child': {
      borderBottomLeftRadius: 16,
      borderTopLeftRadius: 16,
    },
  },
  buttonRolePrimary: {
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY.COLOR[50],
    border: 'none',
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  buttonGroup: {
    width: '100%',
    marginBottom: 16,
  },
  rowSubtitle: {
    marginBottom: 10,
  },
  label: {
    paddingLeft: 7,
    paddingRight: 7,
  },
  '@media(max-width:1279px)': {
    row: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    localeContainer: {
      marginTop: 10,
    },
  },
  openedModal: {},
  selectedDate: {
    display: 'none',
  },
  globe: {},
  closeButton: {
    display: 'none',
  },
  buttonRoleSecondary: {},
  '@media(max-width:767px)': {
    meetingTimeRangeContainer: {
      paddingBottom: 15,
      display: 'none',
    },
    openedModal: {
      display: 'block',
      position: 'fixed',
      width: '100%',
      height: '100vh',
      overflow: 'auto',
      top: 0,
      left: 0,
      backgroundColor: colors.WHITE,
      zIndex: 1220,
      padding: '56px 15px 15px',
    },
    buttonRoleOutlined: {
      height: 44,
      padding: 14,
      '&:last-child': {
        borderRadius: '0 20px 20px 0',
      },
      '&:first-child': {
        borderRadius: '20px 0 0 20px',
      },
    },
    buttonRoleSecondary: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    buttonRolePrimary: {
      borderRadius: '20px !important',
    },
    buttonGroup: {
      backgroundColor: colors.BASIC.COLOR[0],
      borderRadius: 20,
      border: '1px solid #d0cfcf',
    },
    selectedDate: {
      display: 'block',
      marginBottom: 24,
      fontSize: 18,
    },
    globe: {
      display: 'none',
    },
    closeButton: {
      display: 'block',
      position: 'absolute',
      top: 20,
      right: 20,
      width: 20,
    },
  },
  '@media(max-width:379px)': {
    timeslotContainerWide: {
      gridTemplateColumns: '1fr 1fr',
      '-ms-grid-template-columns': '1fr 1fr',
    },
  },
})

const DURATION = {
  MINUTES: 30,
  HOURS: 60,
}

const MeetingTimeRange = ({
  availableTimeRange,
  onClick,
  isReschedule,
  onDurationChange,
  isInitial,
  openSelectModal,
  setOpenSelectModal,
  selectedDate,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showMoreOptions, setShowMoreOptions] = useState(false)
  const timezonesList = useSelector(state => state.common.timezonesList)
  const duration = useSelector(state => state.search.duration)

  const SLOT_NUMBER = window.innerWidth > 767 ? 16 : 48

  const onChangeDuration = duration => {
    dispatch(setMeetingDuration(duration))
    onDurationChange(duration)
  }

  return (
    <div className={classNames([classes.meetingTimeRangeContainer], { [classes.openedModal]: !!openSelectModal })}>
      {selectedDate && (
        <>
          <Typography variant="body2" className={classes.selectedDate}>
            {selectedDate}
          </Typography>
          <CloseLogo onClick={() => setOpenSelectModal(false)} className={classes.closeButton} />
        </>
      )}
      {!isInitial && (
        <>
          <div className={classes.row}>
            <Typography variant="body1" className={classes.rowSubtitle}>
              how long do you need?
            </Typography>
          </div>
          <div className={classes.row}>
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                onClick={() => onChangeDuration(DURATION.MINUTES)}
                className={classNames({
                  [classes.buttonRolePrimary]: duration === DURATION.MINUTES,
                  [classes.buttonRoleOutlined]: true,
                  [classes.buttonRoleSecondary]: duration !== DURATION.MINUTES,
                })}
                variant="secondary"
              >
                30 minutes
              </Button>
              <Button
                onClick={() => onChangeDuration(DURATION.HOURS)}
                className={classNames({
                  [classes.buttonRolePrimary]: duration === DURATION.HOURS,
                  [classes.buttonRoleOutlined]: true,
                  [classes.buttonRoleSecondary]: duration !== DURATION.HOURS,
                })}
                variant="secondary"
              >
                1 hour
              </Button>
            </ButtonGroup>
          </div>
        </>
      )}

      <div className={classes.row}>
        <Typography variant="body1">what time works best?</Typography>
        <div className={classes.localeContainer}>
          {!isReschedule && <GlobeLogo className={classes.globe} />}
          <Typography className={classes.timezone} variant="body1">
            {getTimezoneLabel(timezonesList)}
          </Typography>
        </div>
      </div>
      <div
        className={classNames({
          [classes.timeslotContainer]: true,
          [classes.timeslotContainerWide]: !isReschedule,
          [classes.timeslotContainerSlim]: isReschedule,
        })}
      >
        {availableTimeRange &&
          (showMoreOptions
            ? availableTimeRange.map(time => (
                <Chip
                  key={time.start}
                  className={classes.timeslot}
                  variant="outlined"
                  label={moment.unix(time.start).format('h:mm a')}
                  onClick={() => onClick(time)}
                  classes={{ label: classes.label }}
                />
              ))
            : availableTimeRange
                .slice(0, SLOT_NUMBER)
                .map(time => (
                  <Chip
                    key={time.start}
                    className={classes.timeslot}
                    variant="outlined"
                    label={moment.unix(time.start).format('h:mm a')}
                    onClick={() => onClick(time)}
                    classes={{ label: classes.label }}
                  />
                )))}
      </div>
      {availableTimeRange && availableTimeRange.length > SLOT_NUMBER && (
        <Link onClick={() => setShowMoreOptions(prevState => !prevState)}>
          {showMoreOptions ? 'show less' : `show ${availableTimeRange.length - SLOT_NUMBER} more`}
        </Link>
      )}
    </div>
  )
}

export default MeetingTimeRange
