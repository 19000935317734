import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Hint, Icon, TextField, Typography } from 'components'
import { colors } from 'constants/index'
import className from 'classnames'
import { FormControlLabel, InputAdornment, Radio, RadioGroup } from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'normal',
  },
  invoiceInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.BASIC.COLOR[0],
    width: '33%',
    borderRight: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  finalInvoiceAmount: {
    fontSize: 25,
    fontWeight: 500,
    color: colors.PRIMARY.COLOR[50],
    marginBottom: '56px',
  },
  invoiceAmount: {
    fontSize: 18,
    fontWeight: 500,
  },
  creditAmount: {
    color: colors.SUCCESS,
  },
  paddingContainer: {
    padding: '40px 40px 0 40px',
  },
  creditContainer: {
    paddingBottom: 40,
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  payPartly: {
    flex: '1',
    backgroundColor: '#EBEFF5',
    padding: '24px 32px 62px',
  },
  payPartlyTitle: {
    marginBottom: 24,
  },
  checked: {
    color: colors.PRIMARY.COLOR[50],
  },
  radioBtn: {
    backgroundColor: 'transparent',
  },
  formControlLabel: {
    marginTop: 16,
    width: '100%',
  },
  label: {
    width: '100%',
  },
  radioButtonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  amountInputWrapper: {
    maxWidth: 200,
    position: 'relative',
  },
  hint: {
    position: 'absolute',
    marginTop: 4,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '19px',
    color: '#F71305',
  },
  amountInputRoot: {
    padding: 0,
    width: 200,
    '& fieldset': {
      borderColor: '#A5B3C2',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      textAlign: 'right',
    },
  },
  amountInputPrefix: {
    height: 38,
    maxHeight: 'unset',
    padding: '0 12px',
    marginLeft: 1,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    lineHeight: '38px',
    backgroundColor: '#EBEFF5',
    borderRight: '1px solid #A5B3C2',
  },
})

const ClientPaymentInfoContainer = ({ invoice, invoiceSummary, amount, setAmount, paymentType, setPaymentType }) => {
  const classes = useStyles()
  const [inputError, setInputError] = useState(false)

  const onChangeInputAmount = e => {
    const { value } = e.target
    if (/^\d*(\.\d{0,2})?$/.test(value)) {
      setInputError(false)
      if (value && +value < 1) {
        setAmount('1.00')
      } else if (+value > +invoice.amountDue) {
        setAmount(invoice.amountDue)
      } else {
        setAmount(value)
      }
    }
  }

  const onInputBlur = () => {
    if (!amount) {
      setInputError(true)
    }
  }

  const invoiceItem = invoiceSummary.find(sum => sum.type === 'I')
  const creditItem = invoiceSummary.filter(sum => sum.type === 'C')
  const paymentItem = invoiceSummary.filter(sum => sum.type === 'P')
  const debitItem = invoiceSummary.filter(sum => sum.type === 'A')

  return (
    <div className={classes.invoiceInfoContainer}>
      <div className={classes.paddingContainer}>
        <Typography className={classes.title}>
          {`Invoice ${invoiceItem && invoiceItem.generated_payment_record_number} details`}
        </Typography>
      </div>
      <div className={classes.creditContainer}>
        {!!(creditItem.length || paymentItem.length || debitItem.length) && (
          <div className={className(classes.row, classes.paddingContainer)}>
            <Typography className={classes.subtitle}>Invoice amount</Typography>
            <Typography className={classes.invoiceAmount}>${invoiceItem.current_invoice_amount}</Typography>
          </div>
        )}
        {creditItem.map(credit => (
          <div
            key={credit.generated_payment_record_number}
            className={className(classes.row, classes.paddingContainer)}
          >
            <Typography className={classes.subtitle}>
              Credit
              {credit.generated_payment_record_number}
            </Typography>
            <Typography className={className(classes.invoiceAmount, classes.creditAmount)}>
              -$
              {credit.amount}
            </Typography>
          </div>
        ))}
        {paymentItem.map(p => (
          <div key={p.generated_payment_record_number} className={className(classes.row, classes.paddingContainer)}>
            <Typography className={classes.subtitle}>
              Payment
              {p.generated_payment_record_number}
            </Typography>
            <Typography className={className(classes.invoiceAmount, classes.creditAmount)}>
              -$
              {p.amount}
            </Typography>
          </div>
        ))}
        {debitItem.map(p => (
          <div key={p.generated_payment_record_number} className={className(classes.row, classes.paddingContainer)}>
            <Typography className={classes.subtitle}>
              Payment
              {p.generated_payment_record_number}
            </Typography>
            <Typography className={classes.invoiceAmount}>
              +$
              {p.amount}
            </Typography>
          </div>
        ))}
      </div>
      <div className={className(classes.row, classes.paddingContainer)}>
        <Typography className={classes.title}>Total</Typography>
        <Typography className={classes.finalInvoiceAmount}>${invoice.amountDue}</Typography>
      </div>
      <div className={classes.payPartly}>
        <Typography variant="h4" className={classes.payPartlyTitle}>
          Payment amount
        </Typography>
        <RadioGroup value={paymentType} onChange={e => setPaymentType(e.target.value)}>
          <FormControlLabel
            classes={{ root: classes.formControlLabel, label: classes.label }}
            value="full"
            control={
              <Radio
                checked={paymentType === 'full'}
                color="default"
                classes={{ checked: classes.checked, root: classes.radioBtn }}
              />
            }
            label={
              <div className={classes.radioButtonLabel}>
                <span>Total balance</span>
                <span>${invoice.amountDue}</span>
              </div>
            }
          />
          <FormControlLabel
            classes={{ root: classes.formControlLabel, label: classes.label }}
            value="partly"
            control={
              <Radio
                checked={paymentType === 'partly'}
                color="default"
                classes={{ checked: classes.checked, root: classes.radioBtn }}
              />
            }
            label={
              <div className={classes.radioButtonLabel}>
                <span>Other amount</span>
                <div className={classes.amountInputWrapper}>
                  <TextField
                    className="pay-partly-amount"
                    type="number"
                    variant="outlined"
                    value={amount}
                    name="amount"
                    label=""
                    onChange={onChangeInputAmount}
                    onBlur={onInputBlur}
                    InputProps={{
                      classes: { root: classes.amountInputRoot },
                      startAdornment: (
                        <InputAdornment disableTypography className={classes.amountInputPrefix} position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                    disabled={paymentType === 'full' || !invoice}
                  />
                  {inputError && paymentType === 'partly' && (
                    <Hint classes={{ subtitle2: classes.hint }}>
                      <Icon iconClass="las las la-exclamation-triangle" />
                      Please enter the amount you want to pay for the invoice
                    </Hint>
                  )}
                </div>
              </div>
            }
          />
        </RadioGroup>
      </div>
    </div>
  )
}

export default ClientPaymentInfoContainer
