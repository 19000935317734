import React, { useEffect } from 'react'
import {
  ColorCircularProgress,
  DownloadPDFButton,
  InvoiceDocument,
  InvoiceDocumentView,
  Modal,
  Typography,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { getProInvoice } from 'store/actions/invoices'
import * as moment from 'moment'

const useStyles = makeStyles({
  modal: {
    minWidth: 648,
    minHeight: 300,
    overflowY: 'auto',
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: colors.WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    marginLeft: 12,
    fontSize: 25,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    justifyContent: 'space-between',
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '648px !important',
    },
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: colors.WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
  },
})

const ViewInvoiceModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpen, onClose, invoiceId } = props
  const invoice = useSelector(state => state.invoices.invoice)
  const invoiceIsLoading = useSelector(state => state.invoices.invoiceIsLoading)

  useEffect(() => {
    if (invoiceId) {
      dispatch(getProInvoice(invoiceId))
    }
  }, [invoiceId])

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.title}>
            Invoice details
          </Typography>
          <DownloadPDFButton
            document={<InvoiceDocument invoice={invoice} download />}
            fileName={`Invoice ${invoice.invoiceNumber} ${moment().format(CONSTANTS.DATE_FORMAT.PDF_DATE)}`}
            btnClasses={classes.btn}
            variant="secondary"
          />
        </div>
        {invoiceIsLoading ? <ColorCircularProgress /> : <InvoiceDocumentView invoice={invoice} />}
      </div>
    </Modal>
  )
}

export default ViewInvoiceModal
