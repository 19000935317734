import React from 'react'
import { ProActivitiesContainer } from 'containers'
import { Route, Switch } from 'react-router-dom'

const ProActivitiesPage = () => {
  return (
    <Switch>
      <Route path="/pro/account/activities" component={ProActivitiesContainer} />
    </Switch>
  )
}

export default ProActivitiesPage
