import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Typography,
  DownloadPDFButton,
  InvoiceDocument,
  Button,
  ColorCircularProgress,
  Breadcrumbs,
  Link,
  Tooltip,
  InvoiceStatus,
  InvoiceDetails,
} from 'components'
import * as moment from 'moment'
import { getProInvoice } from 'store/actions/invoices'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { colors, CONSTANTS, routes } from 'constants/index'
import { Link as RouterLink } from 'react-router-dom'
import { pdf } from '@react-pdf/renderer'
import PDFMerger from 'pdf-merger-js/browser'
import { exportFile } from 'store/actions/common'
import InvoiceSummary from 'components/invoices/pdf/summaryPDF'
import InvoicePaymentsCredits from '../../../components/invoices/pdf/paymentsCredits'

const useStyles = makeStyles({
  clientViewInvoicesContainer: {
    padding: 40,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '23px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    marginRight: 24,
  },
  btn: {
    padding: '10px 24px',
    marginLeft: 12,
    height: 40,
    minHeight: 40,
  },
  link: {
    color: colors.BASIC.COLOR[20],
    textDecoration: 'none',
  },
  breadcrumbs: {
    fontWeight: 500,
    fontSize: 12,
  },
  btnContainer: {
    display: 'flex',
  },
})

const ClientViewInvoicesContainer = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const invoice = useSelector(state => state.invoices.invoice)
  const invoiceSummary = useSelector(state => state.invoices.invoiceSummary)
  const invoiceId = match.params.invoiceId
  const invoiceIsLoading = useSelector(state => state.invoices.invoiceIsLoading)
  const paymentIsProcessing = useSelector(state => state.invoices.paymentIsProcessing)
  const fileName = `Invoice ${invoice.invoiceNumber} ${moment().format(CONSTANTS.DATE_FORMAT.PDF_DATE)}.pdf`

  useEffect(() => {
    dispatch(getProInvoice(invoiceId))
  }, [])

  const onButtonClick = async () => {
    if (!invoice.file_link) {
      const blob = await pdf(<InvoiceDocument download invoice={invoice} summary={invoiceSummary} />).toBlob()
      dispatch(exportFile(blob, fileName, 'application/pdf'))
    } else {
      try {
        const summaryBlob = await pdf(<InvoiceSummary invoice={invoice} summary={invoiceSummary} />).toBlob()
        const paymentsBlob = await pdf(<InvoicePaymentsCredits invoice={invoice} summary={invoiceSummary} />).toBlob()

        const pdfFile = await fetch(invoice.file_link).then(res => res.blob())
        const pdfBlob = new Blob([pdfFile], { type: 'application/pdf' })

        let merger = new PDFMerger()
        await Promise.all([summaryBlob, pdfBlob, paymentsBlob].map(async file => await merger.add(file, null)))

        const mergedPdf = await merger.saveAsBlob()
        dispatch(exportFile(mergedPdf, fileName, 'application/pdf'))
        merger = null
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <div className={classes.clientViewInvoicesContainer}>
      {invoiceIsLoading || paymentIsProcessing ? (
        <ColorCircularProgress />
      ) : (
        <>
          <Breadcrumbs className={classes.standardMargin}>
            <Link
              className={classNames(classes.link, classes.breadcrumbs)}
              component={RouterLink}
              to={routes.getClientAccountInvoicesPath()}
            >
              invoices
            </Link>
            <Typography className={classes.breadcrumbs} variant="body1">
              invoice {invoice.invoiceNumber} details
            </Typography>
          </Breadcrumbs>
          <div className={classes.row}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title} variant="h2">
                Invoice details {invoice.invoiceNumber}
              </Typography>
              <InvoiceStatus status={invoice.status || 0} />
            </div>
            <div className={classes.btnContainer}>
              <DownloadPDFButton
                onClick={onButtonClick}
                document={<InvoiceDocument invoice={invoice} download summary={invoiceSummary} />}
                fileName={fileName}
                btnClasses={classes.btn}
                variant="secondary"
              />
              {invoice.isPayButtonState === CONSTANTS.PAY_NOW_BUTTON_STATE.SHOWED && (
                <Button
                  className={classes.btn}
                  variant="primary"
                  component={RouterLink}
                  to={`${routes.getClientInvoicePaymentLinkPath()}/${invoice.uuid}`}
                >
                  pay now
                </Button>
              )}
              {invoice.isPayButtonState === CONSTANTS.PAY_NOW_BUTTON_STATE.SHOWED_AND_DISABLED && (
                <Tooltip
                  title={'Automatic charge will be performed in less than 5 min'}
                  tooltipClass={classes.tooltip}
                  interactive
                >
                  <Button className={classes.btn} disabled>
                    pay now
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <InvoiceDetails invoice={invoice} invoiceSummary={invoiceSummary} isClient summary={invoiceSummary} />
        </>
      )}
    </div>
  )
}

export default ClientViewInvoicesContainer
