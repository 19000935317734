import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ProActivitiesTableRow, Table, TablePagination } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { colors, CONSTANTS } from 'constants/index'
import { setActivitiesSorting, getProActivities, setTableConfiguration } from 'store/actions/activities'
import { goToZendesk } from 'store/actions/common'
import { ProfileIsUnderApprovalTooltipText } from '../../components/activities/ActivityTooltips'

const useStyles = makeStyles({
  activitiesTable: {
    marginTop: 20,
    height: '100%',
  },
  tableHeaderCell: {
    borderBottom: `2px solid ${colors.BASIC.COLOR[10]}`,
  },
})

const columns = [
  {
    value: 'client_full_name',
    label: 'Client',
    sortable: true,
  },
  {
    value: 'case__name',
    label: 'Case',
    sortable: true,
  },
  {
    value: 'description',
    label: '',
    sortable: false,
  },
  {
    value: 'activity_type',
    label: 'Activity',
    sortable: true,
  },
  {
    value: 'time_duration',
    label: 'Time',
    sortable: false,
  },
  {
    value: 'date_of_creation',
    label: 'Activity date',
    sortable: true,
  },
  {
    value: 'client_charge',
    label: 'Charge',
    sortable: true,
    numeric: true,
  },
  {
    value: 'invoice_date',
    label: 'Invoice date',
    sortable: true,
  },
  {
    value: 'buttons',
    label: '',
    sortable: false,
  },
]

const ActivitiesTable = ({
  profileIsApproved,
  setSelectedActivity,
  setShowDeleteModal,
  activities,
  count,
  timezone,
  handleActivityModal,
}) => {
  const sorting = useSelector(state => state.activities.sort)
  const { limit, tablePage } = useSelector(state => state.activities.tableConfiguration)
  const dispatch = useDispatch()
  const classes = useStyles()

  const getRowActions = rowData => {
    return rowData.editMode
      ? [
          {
            key: 'edit',
            label: 'edit',
            action: data => {
              handleActivityModal(config => ({
                ...config,
                open: true,
                action: 'edit',
                type: CONSTANTS.ACTIVITY_TYPE[data.activityType || 0].type,
                activity: data,
              }))
            },
            hint: profileIsApproved ? (
              ''
            ) : (
              <ProfileIsUnderApprovalTooltipText goToZendesk={() => dispatch(goToZendesk())} />
            ),
            disabled: !profileIsApproved,
          },
          {
            key: 'delete',
            label: 'delete',
            action: data => {
              setSelectedActivity(data)
              setShowDeleteModal(true)
            },
            hint: profileIsApproved ? (
              ''
            ) : (
              <ProfileIsUnderApprovalTooltipText goToZendesk={() => dispatch(goToZendesk())} />
            ),
            disabled: !profileIsApproved,
          },
        ]
      : [
          {
            key: 'view',
            label: 'view details',
            action: data => {
              handleActivityModal(config => ({
                ...config,
                open: true,
                action: 'edit',
                type: CONSTANTS.ACTIVITY_TYPE[data.activityType || 0].type,
                activity: data,
              }))
            },
          },
        ]
  }

  const onSort = (name, isAcs) => {
    dispatch(setActivitiesSorting(name, isAcs))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProActivities())
  }

  const onRowsPerPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
        limit: value,
      })
    )
    dispatch(getProActivities())
  }

  const onPageChange = value => {
    dispatch(
      setTableConfiguration({
        tablePage: value,
        offset: value * limit,
      })
    )
    dispatch(getProActivities())
  }
  return (
    <Table
      tableColumns={columns}
      className={classes.activitiesTable}
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableData={activities}
      getRowActions={getRowActions}
      sorting={sorting}
      onSort={onSort}
      tableDataRowRenderer={(columns, data) => (
        <ProActivitiesTableRow timezone={timezone} columns={columns} data={data} />
      )}
      footer={
        <TablePagination
          colSpan={columns.length}
          page={tablePage}
          count={count}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
          onRowsPerPageChange={onRowsPerPageChange}
          onPageChange={onPageChange}
        />
      }
      activeRows
    />
  )
}

export default ActivitiesTable
