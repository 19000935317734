import React from 'react'
import { useSelector } from 'react-redux'
import { InvoiceDocumentView, InvoiceSummaryTable, InvoicePayments } from 'components'
import { sortInvoiceSummary } from 'utils/invoice/invoice-helpers'
import Viewer from '@phuocng/react-pdf-viewer'

const InvoiceDetailsBody = ({ invoiceTab, invoice, invoiceSummary, isClient, setInvoiceTab }) => {
  const timezone = useSelector(state => state.calendar.advanced_calendar.timezone)

  const getInvoiceSummary = () => {
    return invoiceSummary.filter(summary => summary.type !== 'I').sort(sortInvoiceSummary)
  }

  return (
    <>
      {invoiceTab === 0 && (
        <InvoiceSummaryTable
          summary={invoiceSummary}
          timezone={timezone}
          invoiceID={invoice.uuid}
          balance={invoice.amountDue}
          setInvoiceTab={setInvoiceTab}
        />
      )}
      {invoiceTab === 1 &&
        (invoice.file_link ? (
          <Viewer fileUrl={invoice.file_link} defaultScale={1} />
        ) : (
          <InvoiceDocumentView invoice={invoice} timezone={timezone} />
        ))}
      {invoiceTab === 2 && (
        <InvoicePayments summary={getInvoiceSummary()} invoice={invoice} timezone={timezone} isClient={isClient} />
      )}
    </>
  )
}

export default InvoiceDetailsBody
