import React from 'react'
import { openSocialLoginWindow } from 'utils/auth'
import { Button, Icon } from 'components'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getCalendarConnection } from 'store/actions/calendar'
import {openSnackbar} from "store/actions/common"

const useStyles = makeStyles({
  icon: {
    fontSize: 20,
    marginRight: 8,
  },
})

const OutlookConnectButton = ({ setCalendar, provider }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const onOutlookLogin = async payload => {
    try{
      const calendar = await dispatch(getCalendarConnection({ code: payload.code, provider }))
      if(calendar) setCalendar(calendar)
    }
    catch (e) {
      if(e.response && e.response.data && e.response.data.detail === 'OutlookCalendarClient:Not Found'){
        window.alert('Please, connect your calendar once again')
      }else{
        dispatch(openSnackbar('error', 'Error while connecting calendar'))
      }
    }
  }

  const onClick = () => {
    openSocialLoginWindow('outlook', onOutlookLogin)
  }
  return (
    <Button variant="secondary" onClick={onClick}>
      <Icon style={classes.icon} iconClass="las la-plug" />
      connect
    </Button>
  )
}

export default OutlookConnectButton
