import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, ConfirmationModal, SuccessModal, Tooltip } from 'components'
import { ReactComponent as CircleLogo } from 'static/icon_24_alert-circle.svg'
import { ReactComponent as DeleteUserIcon } from 'static/ic_delete_user.svg'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  deleteAcc: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  startText: {
    fontSize: 15,
    lineHeight: 1.47,
    fontWeight: 'normal',
  },
  beforeText: {
    marginTop: 7,
    marginBottom: 7,
    fontSize: 15,
    lineHeight: 1.47,
  },
  listItem: {
    fontSize: 15,
    lineHeight: 1.47,
    fontWeight: 'normal',
    marginTop: 8,
  },
  afterText: {
    fontSize: 15,
    lineHeight: 1.47,
    fontWeight: 'normal',
    marginTop: 10,
  },
  cancelDelete: {
    width: '100%',
    height: 120,
    padding: 24,
    backgroundColor: colors.WHITE,
  },
  cancelDeleteText: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: 8,
  },
  beforeTooltipText: {
    fontSize: 13,
    lineHeight: 1.47,
    marginBottom: 7,
  },
  listTooltipItem: {
    fontSize: 13,
    lineHeight: 1.47,
    fontWeight: 'normal',
    marginTop: 8,
  },
  cancelBtn: {
    float: 'right',
  },
  list: {
    listStyleType: 'disc',
    marginLeft: 25,
    color: colors.PRIMARY.COLOR[50],
  },
})

const BeforeDeleteTooltipContent = () => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.beforeTooltipText}>Before we can delete your account, please ensure</Typography>
      <ul className={classes.list}>
        <li>
          <Typography className={classes.listTooltipItem}>all invoices have been paid</Typography>
        </li>
        <li>
          <Typography className={classes.listTooltipItem}>you have no scheduled meetings</Typography>
        </li>
        <li>
          <Typography className={classes.listTooltipItem}>you download all documents in your secure vault that you want to keep</Typography>
        </li>
      </ul>
    </div>
  )
}

const DeleteClientAccount = ({
  requestDelete,
  setRequestUuid,
  showSnackbar,
  deletionRequestUuid,
  onCancelDeletion,
}) => {
  const classes = useStyles()
  const [isDeleteConfirmOpen, openDeleteConfirm] = useState(false)
  const [isSuccessModalOpen, openSuccesModal] = useState(false)
  const [isRemoveConfirmOpen, openRemoveConfirm] = useState(false)
  const [uuid, setDeleteRequestUuid] = useState('')

  const showDeleteConfirm = () => {
    openDeleteConfirm(true)
  }

  const onDeleteConfirmClose = () => {
    openDeleteConfirm(false)
  }

  const onSuccessModalClose = () => {
    openSuccesModal(false)
    setRequestUuid(uuid)
  }

  const sendDelete = async () => {
    openDeleteConfirm(false)
    try {
      const { data } = await requestDelete()
      setDeleteRequestUuid(data.uuid)
      openSuccesModal(true)
    } catch (e) {
      showSnackbar('error', 'There was some error while your deletion request')
    }
  }

  const getDeleteMessage = () => (
    <>
      <Typography className={classes.startText}>
        Your request for account deletion will be sent to XIRA Admin.
      </Typography>
      <Typography className={classes.beforeText}>Before we can delete your account, please ensure</Typography>
      <ul className={classes.list}>
        <li>
          <Typography className={classes.listItem}>all invoices have been paid</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>you have no scheduled meetings</Typography>
        </li>
        <li>
          <Typography className={classes.listItem}>you download all documents in your secure vault that you want to keep</Typography>
        </li>
      </ul>
      <Typography className={classes.afterText}>
        The system will regularly check the delete conditions above. Once completed, XIRA Admin will be informed and you
        will be removed from XIRA.
      </Typography>
    </>
  )

  const cancelAccountDeletion = () => {
    openRemoveConfirm(true)
  }

  const onRemoveConfirmClose = () => {
    openRemoveConfirm(false)
  }

  const onRemoveCancelDeletion = () => {
    openRemoveConfirm(false)
    onCancelDeletion()
  }

  const getCancelDeletion = () => (
    <div className={classes.cancelDelete}>
      <div className={classes.cancelDeleteText}>
        <Tooltip title={<BeforeDeleteTooltipContent />} interactive>
          <CircleLogo className={classes.logo} />
        </Tooltip>
        <Typography className={classes.textInfo}>
          Your request for account deletion was sent to XIRA Admin which may require several days to process.
        </Typography>
      </div>
      <Button variant="text" onClick={cancelAccountDeletion} className={classes.cancelBtn}>
        Cancel account deletion
      </Button>
    </div>
  )

  const getDeletionSection = () => {
    if (deletionRequestUuid) {
      return getCancelDeletion()
    } else {
      return (
        <Button onClick={showDeleteConfirm} variant="text" startIcon={<DeleteUserIcon />}>
          delete my account
        </Button>
      )
    }
  }

  return (
    <div className={classes.deleteAcc}>
      {getDeletionSection()}
      <ConfirmationModal
        title="Send account deletion request"
        message={getDeleteMessage()}
        confirmationButtonText="send request"
        isOpen={isDeleteConfirmOpen}
        onClose={onDeleteConfirmClose}
        onConfirm={sendDelete}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={onSuccessModalClose}
        title="Your request for account deletion was sent to XIRA Admin"
      />
      <ConfirmationModal
        title="Remove account deletion request"
        message="If you cancel your request, your account won't be deleted. Continue?"
        confirmationButtonText="yes, remove request"
        isOpen={isRemoveConfirmOpen}
        onClose={onRemoveConfirmClose}
        onConfirm={onRemoveCancelDeletion}
      />
    </div>
  )
}

export default DeleteClientAccount
