import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  ColorCircularProgress,
  Icon,
  InputLabel,
  Modal,
  PaymentCardElement,
  PoweredByStripeLogo,
  TextField,
  Typography,
  Tooltip,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 648,
    minHeight: 300,
    position: 'relative',
    padding: '40px 40px',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    margin: '0 0 24px 0',
    fontSize: '20px',
  },
  boxBtn: {
    textAlign: 'right',
  },
  icon: {
    marginRight: 4,
  },
  closeBtn: {
    marginRight: 24,
  },
  doneBtn: {
    width: 97,
  },
  cardForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stripeLogo: {
    height: 26,
    width: 119,
  },
  disabledInputContainer: {
    borderRadius: 4,
    width: '100%',
    height: 40,
    backgroundColor: colors.BASIC.COLOR[10],
    border: `1px solid ${colors.BASIC.COLOR[20]}`,
    color: colors.BASIC.COLOR[20],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 14px',
    marginBottom: 16,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltip: {
    padding: 15,
    maxWidth: 440,
  },
  tooltipText: {
    fontSize: 13,
  },
  tooltipLabel: {
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
  },
  notched: {
    borderColor: colors.BASIC.COLOR[20],
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '648px !important',
    },
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
  },
})

const CardDetailsField = ({ details }) => {
  const classes = useStyles()
  return (
    <div className={classes.disabledInputContainer}>
      <Typography variant="body2">{`**** **** **** ${details.lastDigits}`}</Typography>
      <Typography variant="body2">{`${details.expirationMonth} / ${details.expirationYear}`}</Typography>
    </div>
  )
}

const TooltipContentCSV = () => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.tooltipText} variant="subtitle1">
        The CVV/CVC code (Card Verification Value/Code) is located on the back of your credit/debit card on the right
        side of the white signature strip.
      </Typography>
    </div>
  )
}

const ConfigureCardModal = props => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const {
    isOpen,
    onClose,
    onSave,
    mode,
    clientPaymentSecretKey,
    cardData,
    clientSecretIsLoading,
    showIsRememberOption,
  } = props
  const [userCardData, setUserCardData] = useState(cardData)
  const [stripeError, setStripeError] = useState('')
  const [cardSavingIsLoading, setCardSavingIsLoading] = useState(false)

  useEffect(() => {
    setUserCardData(cardData)
  }, [cardData])

  useEffect(() => {
    setStripeError('')
  }, [isOpen])

  const handleSubmit = async () => {
    try {
      if (mode === CONSTANTS.MODE.ADD) {
        if (!stripe || !elements) {
          return
        }
        setCardSavingIsLoading(true)
        const result = await stripe.confirmCardSetup(clientPaymentSecretKey, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        })

        if (result.error) {
          setStripeError(result.error.message)
          setCardSavingIsLoading(false)
        } else {
          setStripeError('')
          const data = {
            label: userCardData.label,
            is_default: userCardData.isDefault,
            remember: userCardData.remember,
            payment_method_id: result.setupIntent.payment_method,
          }
          try {
            await onSave(data)
            setCardSavingIsLoading(false)
          } catch (error) {
            setCardSavingIsLoading(false)
          }
        }
      } else if (mode === CONSTANTS.MODE.EDIT) {
        const data = {
          label: userCardData.label,
          uuid: userCardData.uuid,
        }
        try {
          setCardSavingIsLoading(true)
          await onSave(data)
          setCardSavingIsLoading(false)
        } catch (error) {
          setCardSavingIsLoading(false)
        }
      }
    } catch (e) {
      setCardSavingIsLoading(false)
    }
  }

  const onCheckboxChange = (name, checked) => {
    setUserCardData({
      ...userCardData,
      [name]: checked,
    })
  }

  const onRememberCardChange = checked => {
    const data = {
      remember: checked,
    }

    if (!checked) {
      data.isDefault = false
    }

    setUserCardData({
      ...userCardData,
      ...data,
    })
  }

  const onLabelChange = e => {
    setUserCardData({
      ...userCardData,
      label: e.target.value,
    })
  }

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.title}>
            {mode === CONSTANTS.MODE.ADD ? 'Add card' : 'Edit card'}
          </Typography>
          <PoweredByStripeLogo className={classes.stripeLogo} />
        </div>
        {clientSecretIsLoading ? (
          <ColorCircularProgress />
        ) : (
          <>
            <div className={classes.cardForm}>
              <div className={classes.btnContainer}>
                <InputLabel>Credit or debit card</InputLabel>
                <Tooltip
                  placement="bottom-start"
                  childrenClass={classes.tooltipLabel}
                  title={<TooltipContentCSV />}
                  tooltipClass={classes.tooltip}
                  interactive
                >
                  What is CVC?
                </Tooltip>
              </div>
              {mode === CONSTANTS.MODE.ADD ? (
                <PaymentCardElement stripeError={stripeError} />
              ) : (
                <CardDetailsField details={userCardData} />
              )}
              <InputLabel>Card nickname</InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                onChange={onLabelChange}
                value={userCardData.label}
                inputProps={{
                  maxLength: 50,
                }}
                InputProps={{
                  classes: { notchedOutline: classes.notched },
                }}
              />
              {showIsRememberOption && (
                <Checkbox
                  label="Save this card"
                  onChange={e => onRememberCardChange(e.target.checked)}
                  disabled={mode === CONSTANTS.MODE.EDIT}
                  checked={userCardData.remember}
                />
              )}
              <Checkbox
                label="Set as default payment method"
                onChange={e => onCheckboxChange('isDefault', e.target.checked)}
                disabled={mode === CONSTANTS.MODE.EDIT || userCardData.remember === false}
                checked={userCardData.isDefault}
              />
            </div>
            <div className={classes.boxBtn}>
              <Button variant="text" onClick={onClose} className={classes.closeBtn} disabled={cardSavingIsLoading}>
                cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                isButtonBlocked={cardSavingIsLoading}
                disabled={cardSavingIsLoading}
                circularSize={22}
                className={classes.doneBtn}
              >
                <Icon iconClass="las la-lock" style={classes.icon} />
                done
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ConfigureCardModal
