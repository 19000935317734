import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CalendarConnections from '../account-calendar/connections/CalendarConnections'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAdvancedCalendarValues } from '../../store/actions/calendar'
import { Button, Modal, Typography } from '../../components'
import { colors } from '../../constants'
import { submitProfile } from '../../store/actions/profileCreation'
import img from 'static/skip-popup.jpg'

const useStyles = makeStyles({
  step: {
    padding: '0 32px',
    minWidth: 1050,
  },
  modal: {
    width: 780,
    minHeight: 480,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 40px 32px',
  },
  body: {
    fontWeight: 'normal',
    color: colors.BASIC.COLOR['100'],
    marginTop: 36,
    fontSize: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  blueButton: {
    borderColor: colors.BLUE_20,
    color: colors.BLUE_20,
    '&:hover': {
      borderColor: colors.BLUE_20,
      color: colors.BLUE_20,
      backgroundColor: 'rgba(26, 115, 232, .2)',
    },
  },
  skipInfo: {
    marginBottom: 24,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  skipInfoText: {
    width: 250,
    fontSize: 32,
    fontWeight: 600,
  },
  skipDescription: {
    fontSize: 20,
  },
  skipDescriptionLink: {
    color: colors.PRIMARY.COLOR[50],
    textDecoration: 'none',
  },
})

const CalendarConnectionStep = ({ goToNextStep, isValidation, resetValidation }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [loadingTimezone, setLoadingTimezone] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const connectedCalendars = useSelector(state => state.calendar.connectedCalendars)

  const onCloseModal = () => setOpenModal(false)
  const submitRegistration = () => dispatch(submitProfile())

  useEffect(() => {
    setLoadingTimezone(true)
    if (openModal) dispatch(getUserAdvancedCalendarValues()).finally(() => setLoadingTimezone(false))
  }, [openModal])

  useEffect(() => {
    if (isValidation) handleCalendarConnectionSubmit()
  }, [isValidation])

  const handleCalendarConnectionSubmit = () => {
    resetValidation()
    if (!connectedCalendars.length) setOpenModal(true)
    else goToNextStep()
  }
  return (
    <div className={classes.step}>
      <CalendarConnections disablePadding />
      <Modal open={openModal} handleClose={onCloseModal} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
        <div className={classes.modal}>
          <div className={classes.skipInfo}>
            <img src={img} alt="" width={300} />
            <p className={classes.skipInfoText}>maybe you like to reconsider?</p>
          </div>
          <p className={classes.skipDescription}>
            Its always free to be listed on XIRA. By skipping calendar connection you pass on the{' '}
            <a
              href="https://member.xira.com/skiptrial"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.skipDescriptionLink}
            >
              <strong>FREE TRIAL OF GAVEL</strong>
            </a>
            . Please note that <strong>clients prefer</strong> to schedule consultations online.
          </p>
          <Typography className={classes.body}>
            <Button type="primary" onClick={onCloseModal}>
              LET’S BACKUP & CONNECT
            </Button>
            <Button
              className={classes.blueButton}
              variant="secondary"
              target="_blank"
              href="https://member.xira.com/skiptrial/"
            >
              MORE ABOUT THE FREE TRIAL
            </Button>
            <Button variant="secondary" onClick={submitRegistration} disabled={loadingTimezone}>
              COMPLETE REGISTRATION
            </Button>
          </Typography>
        </div>
      </Modal>
    </div>
  )
}

export default CalendarConnectionStep
