import React from 'react'
import { Button, ColorCircularProgress, Icon, PracticeAreas, Typography } from 'components'
import withStyles from '@material-ui/core/styles/withStyles'
import { colors, REGEXP } from 'constants/index'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { addPracticeAreas, getPracticeAreas, savePracticeAreas } from 'store/actions/profileCreation'

const styles = {
  step: {
    padding: '0 32px',
  },
  stepButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: 900,
  },
  title: {
    marginBottom: 24,
  },
  checkboxLabel: {
    marginBottom: 0,
  },
  validationMessage: {
    color: colors.ERROR,
    margin: '10px 0 20px 0',
  },
  panelValid: {
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  panel: {
    boxShadow: 'none',
    '&::before': {
      background: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    width: 900,
  },
  panelError: {
    border: `solid 1px ${colors.ERROR}`,
    backgroundColor: colors.WHITE,
  },
}

class PracticeAreasStep extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isValid: true,
      errorMessage: '',
      invalidAreas: [],
    }
  }

  componentDidMount() {
    this.props.getInitialPracticeAreas()
  }

  componentDidUpdate() {
    if (this.props.isValidation) {
      this.props.resetValidation()
      this.handlePracticeAreasSubmit()
    }
  }

  handlePracticeAreasSubmit = () => {
    let valid = true
    const invalidPracticeAreas = []
    if (this.props.practiceAreas && this.props.practiceAreas.some(area => area.checked)) {
      const selectedAreas = this.props.practiceAreas
        .filter(area => area.checked)
        .forEach(area => {
          const hourlyRateError = !area.hourlyRate ||
            area.hourlyRate <= 0 ||
            REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(area.hourlyRate) ||
            !REGEXP.fiveDecimals.test(area.hourlyRate)
          const initialConsultationError = area.consultationCost &&
            (REGEXP.fiveDecimalsDotTwoDecimalsPracticeAreas.test(area.consultationCost) ||
            !REGEXP.fiveDecimals.test(area.consultationCost))

          if (!area.subSpecialities.some(sub => sub.checked) || hourlyRateError || initialConsultationError) {
            valid = false
            invalidPracticeAreas.push(area.uuid)
          }
        })

      if (valid) {
        this.props
          .saveProfilePracticeAreas()
          .then(() => {
            this.setState({ invalidAreas: [] })
            this.props.handleValidationStatus('practiceAreasStep', true)
            this.props.goToNextStep()
          })
          .catch(e => {
            this.props.showSnackbar('error', 'There was some error while changing practice areas')
          })
      } else {
        this.props.executeScroll()
        this.setState({
          isValid: false,
          errorMessage: `Please specify practice area details`,
          invalidAreas: invalidPracticeAreas,
        })
      }
    } else {
      this.props.handleValidationStatus('practiceAreasStep', false)
      this.setState({ isValid: false, errorMessage: 'please select at least one practice area' })
      this.props.executeScroll()
    }
  }

  render() {
    const { practiceAreas, classes } = this.props
    const { isValid, errorMessage } = this.state
    return this.props.arePracticeAreasLoading ? (
      <ColorCircularProgress />
    ) : (
      <div className={classes.step}>
        <Typography variant="h4" className={classes.title}>
          Select Your Practice Areas
        </Typography>
        {errorMessage && (
          <Typography variant="subtitle2" className={classes.validationMessage}>
            <Icon iconClass="las las la-exclamation-triangle" />
            {errorMessage}
          </Typography>
        )}
        <PracticeAreas
          practiceAreas={practiceAreas}
          addProfilePracticeAreas={this.props.addProfilePracticeAreas}
          labelClasses={{
            root: classes.checkboxLabel,
          }}
          panelClasses={{
            root: classNames(classes.panel, classes.panelValid),
          }}
          errorPanelClasses={{
            root: classNames(classes.panel, classes.panelError),
          }}
          invalidAreas={this.state.invalidAreas}
          setInvalidAreas={areas => this.setState({ invalidAreas: areas })}
          setErrorMessage={message => this.setState({ errorMessage: message })}
          key={this.state.invalidAreas.length}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  addProfilePracticeAreas: practiceAreas => dispatch(addPracticeAreas(practiceAreas)),
  saveProfilePracticeAreas: () => dispatch(savePracticeAreas()),
  getInitialPracticeAreas: () => dispatch(getPracticeAreas()),
})

export default withStyles(styles)(connect(null, mapDispatchToProps)(PracticeAreasStep))
