import * as CONSTANTS from 'store/constants/invoices/'
import axios, { CancelToken } from 'axios'
import { openSnackbar } from 'store/actions/common'
import {
  getInvoicesPath,
  getInvoicePath,
  getInvoicesFilePath,
  getInvoicePaymentPath,
  getInvoiceSummaryPath,
  getUpdateInvoiceSummaryPath,
  getUploadInvoicePath,
} from 'utils/path-helpers/api'
import { getInvoicesParameters, getInvoicesFileParameters } from 'utils/getters/invoices'
import { mapInvoiceDataToFront, mapInvoiceDataToBack } from 'utils/mappers/backend'
import { navigateToInvoicesList, navigateToClientInvoices } from '../navigation'

export const setTableConfiguration = payload => ({
  type: CONSTANTS.SET_TABLE_CONFIGURATION,
  payload,
})

export const setInvoicesFilter = (name, value) => ({
  type: CONSTANTS.SET_FILTERS,
  name,
  value,
})

export const resetInvoicesFilter = () => ({
  type: CONSTANTS.RESET_FILTERS,
})

export const resetInvoicesSorting = () => ({
  type: CONSTANTS.RESET_SORTING,
})

export const setInvoicesSorting = (property, isAsc) => {
  return {
    type: CONSTANTS.SET_SORTING,
    property,
    isAsc,
  }
}

export const setInvoiceSummarySorting = (property, isAsc) => {
  return {
    type: CONSTANTS.SET_SUMMARY_SORTING,
    property,
    isAsc,
  }
}

const getInvoicesStart = () => ({
  type: CONSTANTS.GET_INVOICES_START,
})

const getInvoicesEnd = (payload, length) => ({
  type: CONSTANTS.GET_INVOICES_END,
  payload,
  length,
})

const getInvoiceStart = () => ({
  type: CONSTANTS.GET_INVOICE_START,
})

const getInvoiceEnd = (invoice, summary) => ({
  type: CONSTANTS.GET_INVOICE_END,
  invoice,
  summary,
})

const getInvoiceSummaryStart = () => ({
  type: CONSTANTS.GET_INVOICE_SUMMARY_START,
})

const getInvoiceSummaryEnd = summary => ({
  type: CONSTANTS.GET_INVOICE_SUMMARY_END,
  summary,
})

const getInvoicesFileStart = () => ({
  type: CONSTANTS.GET_INVOICES_FILE_START,
})

const getInvoicesFileEnd = () => ({
  type: CONSTANTS.GET_INVOICES_FILE_END,
})

let cancelProInvoicesRequest

export const getProInvoices = () => (dispatch, getState) => {
  cancelProInvoicesRequest && cancelProInvoicesRequest()
  dispatch(getInvoicesStart())
  return axios
    .get(getInvoicesPath(), {
      params: getInvoicesParameters(getState().invoices),
      cancelToken: new CancelToken(c => {
        cancelProInvoicesRequest = c
      }),
    })
    .then(response => {
      const data = response.data.results.map(invoice => mapInvoiceDataToFront(invoice))
      dispatch(getInvoicesEnd(data, response.data.count))
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return []
      }
      dispatch(getInvoicesEnd([]))
      dispatch(openSnackbar('error', 'Error while getting invoices'))
      return Promise.reject(e)
    })
}

const setClientPaymentInvoiceStart = () => ({
  type: CONSTANTS.SET_CLIENT_PAYMENT_START,
})

const setClientPaymentInvoiceEnd = () => ({
  type: CONSTANTS.SET_CLIENT_PAYMENT_END,
})

export const setClientPaymentInvoice = (uuid, paymentId, paymentAmount) => (dispatch, getState) => {
  dispatch(setClientPaymentInvoiceStart())
  return axios
    .post(getInvoicePaymentPath(uuid), {
      payment_method_id: paymentId,
      partial_amount: paymentAmount,
    })
    .then(response => {
      dispatch(openSnackbar('success', 'Your payment has been processed successfully'))
      dispatch(navigateToClientInvoices())
    })
    .catch(e => {
      return Promise.reject(e)
    })
    .finally(() => dispatch(setClientPaymentInvoiceEnd()))
}

export const setInvoiceStatus = (uuid, status) => (dispatch, getState) => {
  return axios
    .patch(getInvoicePath(uuid), { status: status })
    .then(response => {
      dispatch(openSnackbar('success', 'Invoice status has been updated successfully'))
      dispatch(getProInvoices())
    })
    .catch(e => {
      const errorMessage =
        (e.response && e.response.data && e.response.data.detail) || 'Error while updating invoice status'
      dispatch(openSnackbar('error', errorMessage))
      return Promise.reject(e)
    })
}

export const getProInvoice = (uuid, noStoreUpdate, voidFalse = false) => (dispatch, getState) => {
  if (!noStoreUpdate) dispatch(getInvoiceStart())
  return axios
    .get(getInvoicePath(uuid))
    .then(async response => {
      if (noStoreUpdate) {
        return Promise.resolve(mapInvoiceDataToFront(response.data))
      } else {
        dispatch(getInvoiceEnd(mapInvoiceDataToFront(response.data)))
        dispatch(getProInvoiceSummary(uuid, false, voidFalse))
      }
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while getting invoice'))
      dispatch(navigateToInvoicesList())
      return Promise.reject(e)
    })
}

export const getProInvoiceSummary = (uuid, noStoreUpdate, voidFalse = false) => async (dispatch, getState) => {
  const { sortSummary } = getState().invoices
  if (!noStoreUpdate) dispatch(getInvoiceSummaryStart())
  let params = {
    ordering: sortSummary.property ? `${sortSummary.isAsc ? '' : '-'}${sortSummary.property}` : null,
  }
  if (voidFalse) {
    params = {
      is_void: false,
      ...params,
    }
  }
  try {
    const { data } = await axios.get(getInvoiceSummaryPath(uuid), {
      params,
    })
    if (noStoreUpdate) return Promise.resolve(data)
    else dispatch(getInvoiceSummaryEnd(data))
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while getting invoice summary'))
    dispatch(getInvoiceSummaryEnd([]))
    return Promise.reject(e)
  }
}

export const recordTransaction = data => (dispatch, getState) => {
  return axios
    .patch(
      data.paymentId ? getUpdateInvoiceSummaryPath(data.invoiceId, data.paymentId) : getInvoicePath(data.invoiceId),
      data.paymentId
        ? {
            amount: data.amount,
            description: data.description,
            type: data.type,
          }
        : {
            payment_record: {
              amount: data.amount,
              description: data.description,
              type: data.type,
            },
          }
    )
    .then(() => {
      data.viewType === 'view' ? dispatch(getProInvoice(data.invoiceId)) : dispatch(getProInvoices())
      dispatch(openSnackbar('success', `Transaction was ${data.paymentId ? 'updated' : 'created'} successfully`))
    })
    .catch(e => {
      const errorMessage =
        (e.response && e.response.data && e.response.data.detail) ||
        `Error while ${data.paymentId ? 'update' : 'creation'} of transaction`
      dispatch(openSnackbar('error', errorMessage))
    })
}

export const getProInvoicesFile = () => (dispatch, getState) => {
  dispatch(getInvoicesFileStart())
  return axios
    .get(getInvoicesFilePath(), { params: getInvoicesFileParameters(getState().invoices), responseType: 'blob' })
    .then(response => {
      return response
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while exporting invoices'))
      return Promise.reject(e)
    })
    .finally(() => {
      dispatch(getInvoicesFileEnd())
    })
}

const uploadInvoicesFileStart = () => ({
  type: CONSTANTS.SET_INVOICES_FILE_START,
})

const uploadInvoicesFileEnd = () => ({
  type: CONSTANTS.SET_INVOICES_FILE_END,
})

export const uploadInvoice = data => dispatch => {
  dispatch(uploadInvoicesFileStart())
  return axios
    .post(getUploadInvoicePath(), data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      return response
    })
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while uploading invoices'))
      return Promise.reject(e)
    })
    .finally(() => {
      dispatch(uploadInvoicesFileEnd())
    })
}
