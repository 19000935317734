import React from 'react'
import Popover from '@material-ui/core/Popover'
import { Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import classNames from 'classnames'

const useClasses = makeStyles({
  closeModal: {
    fontSize: 18,
    color: colors.BASIC.COLOR[70],
    cursor: 'pointer',
    marginRight: 8,
    marginTop: 8,
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    padding: '5px 16px 16px 16px',
  },
})

const XPopover = props => {
  const { open, anchorEl, handleClose, children, anchorReference, anchorPosition, header, headerClasses, containerClasses } = props
  const classes = useClasses()

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      anchorPosition={anchorPosition}
      anchorReference={anchorReference}
    >
      <div className={classNames(classes.header, headerClasses)}>
        {header}
        <Icon iconClass="las la-times" style={classes.closeModal} onClick={handleClose} />
      </div>
      <div className={containerClasses || classes.container}>{children}</div>
    </Popover>
  )
}

export default XPopover
