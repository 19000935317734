import React from 'react'
import { InputLabel, TextField, Hint, Icon } from 'components'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  icon: {
    marginRight: 4,
  },
  error: {
    marginTop: 5,
    color: colors.ERROR,
  },
  hint: {
    marginTop: 5,
    fontSize: 13,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
})

const FormInput = props => {
  const classes = useStyles()
  const {
    onChange,
    handleBlur = () => {},
    label,
    disabled,
    value,
    inputClass,
    fullWidth,
    containerClass,
    inputProps,
    name,
    error,
    hint,
    InputProps,
    type,
    autoFocus,
  } = props

  const handleChange = e => {
    onChange(name, e.target.value)
  }

  return (
    <div className={containerClass}>
      <InputLabel>{label}</InputLabel>
      <TextField
        disabled={disabled}
        value={value}
        variant="outlined"
        fullWidth={fullWidth}
        className={inputClass}
        onChange={handleChange}
        inputProps={inputProps}
        name={name}
        InputProps={InputProps}
        type={type}
        error={Boolean(error)}
        autoFocus={autoFocus}
        onBlur={handleBlur}
      />
      {hint && <Hint classes={{ root: classes.hint }}>{hint}</Hint>}
      {error && (
        <Hint classes={{ root: classes.error }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {error}
        </Hint>
      )}
    </div>
  )
}

export default FormInput
