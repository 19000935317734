import React from 'react'
import { Typography, Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import classNames from 'classnames'

const useStyles = makeStyles({
  summaryRoot: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  summaryCol: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
  },
  summaryColPrice: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
  },
  icon: {
    fontSize: '24px',
    marginRight: '32px',
  },
  summaryRow: {
    display: 'flex',
    margin: 0,
    alignItems: 'center',
  },
  typography: {
    marginTop: '4px',
  },
  consultationCost: {
    marginTop: 12,
    fontWeight: 'normal',
  },
  paidConsultation: {
    color: colors.BASIC.COLOR[100],
    textAlign: 'right',
  },
  freeConsultation: {
    color: colors.SUCCESS,
    textAlign: 'right',
  },
  unspecifiedConsultation: {
    color: colors.BASIC.COLOR[100],
    textAlign: 'right',
    fontWeight: 600,
  },
  typographyText: {
    marginTop: 5,
    textAlign: 'right',
    fontWeight: 600,
  },
  margin: {
    color: colors.BASIC.COLOR[100],
    marginTop: 8,
  },
  name: {
    fontWeight: 600,
  },
})

const INITIAL_CONSULTATION = 'initial consultation'

const PracticeAreasExpansionPanelSummary = ({ iconClass, area, unspecified }) => {
  const classes = useStyles()
  return (
    <div className={classes.summaryRoot}>
      <div className={classes.summaryRow}>
        <Icon style={classes.icon} iconClass={iconClass} />
        <div className={classes.summaryCol}>
          <Typography className={classes.name} variant="subtitle1">
            {area.selected_practice_area.name}
          </Typography>
          <Typography className={classes.margin} variant="body2">
            {INITIAL_CONSULTATION}
          </Typography>
        </div>
      </div>
      <div className={classes.summaryCol}>
        <Typography className={classes.typographyText}>
          {unspecified ? 'unavailable' : `$ ${area.hourly_rate} / hour`}
        </Typography>
        <Typography
          className={classNames(
            classes.consultationCost,
            { [classes.paidConsultation]: area.consultation_cost },
            { [classes.freeConsultation]: !area.consultation_cost },
            { [classes.unspecifiedConsultation]: unspecified }
          )}
        >
          {unspecified
            ? 'unavailable'
            : area.consultation_cost
            ? `$${area.consultation_cost} / 30 min`
            : 'no charge / 30 min'}
        </Typography>
      </div>
    </div>
  )
}

export default PracticeAreasExpansionPanelSummary
