import React from 'react'
import { Typography, Button, Modal, SubCalendarList, Tooltip } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { CONSTANTS } from 'constants/constants'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  updateCalendarContainer: {
    minHeight: 410,
    width: 566,
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: 'center',
  },
  title: {
    marginBottom: 12,
  },
  subtitle: {
    marginLeft: 16,
    fontWeight: 500,
  },
  btnContainer: {
    width: '100%',
    padding: 40,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    marginRight: 40,
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR[80],
    border: `1px solid ${colors.BASIC.COLOR[80]}`,
    color: colors.WHITE,
    fontSize: 13,
    fontWeight: 'normal',
  },
})

const UpdateCalendarModal = ({
  open,
  calendar,
  onClose,
  onSave,
  onDisconnect,
  onSubCalendarListChange,
  xiraCalendar,
  isLastCalendar,
}) => {
  const classes = useStyles()
  const calendarSettings = calendar ? CONSTANTS.CALENDAR_SETTINGS[calendar.provider] : {}
  return (
    <Modal open={open} className={classes.updateCalendarModal} handleClose={onClose} scroll="body">
      <div className={classes.updateCalendarContainer}>
        {calendar && (
          <>
            <div className={classes.titleContainer}>
              <Typography className={classes.title} variant="h3">
                Availability determination
              </Typography>
              <Typography className={classes.title} variant="subtitle1">
                Set the calendar(s) XIRA should check for conflicts to prevent double bookings
              </Typography>
              <div className={classes.logoContainer}>
                {calendarSettings.calendarLogo}
                <Typography className={classes.subtitle} variant="subtitle1">
                  {calendar.username}
                </Typography>
              </div>
            </div>
            <SubCalendarList calendars={calendar.calendars} onSubCalendarListChange={onSubCalendarListChange} />
            <div className={classes.btnContainer}>
              {xiraCalendar.provider === calendar.provider && !isLastCalendar ? (
                <Tooltip
                  tooltipClass={classes.tooltip}
                  title="If you need to disconnect this calendar account please select another calendar for XIRA events"
                >
                  <Button disabled variant="text">
                    disconnect
                  </Button>
                </Tooltip>
              ) : (
                <Button onClick={() => onDisconnect(calendar)} variant="text">
                  disconnect
                </Button>
              )}
              <div>
                <Button className={classes.cancelBtn} onClick={onClose} variant="text">
                  cancel
                </Button>
                <Button onClick={onSave} variant="primary">
                  save
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default UpdateCalendarModal
