import React, { useRef, useEffect } from 'react'
import { jarallax } from 'jarallax'
import { CONSTANTS } from 'constants/constants'
import mate1 from 'static/aboutXira/rg-250x251.png'
import mate2 from 'static/aboutXira/rg-2-250x251.png'
import mate3 from 'static/aboutXira/farshid1-1-250x251.png'
import mate4 from 'static/aboutXira/josemiguel-250x251.png'
import mate5 from 'static/aboutXira/mike-3-250x251.png'
import mate6 from 'static/aboutXira/todd-pic-250x250.jpg'
import mate7 from 'static/aboutXira/karen1-250x251.png'
import mate8 from 'static/aboutXira/afshin.jpg'
import advisor1 from 'static/aboutXira/pat-250x251.png'
import advisor2 from 'static/aboutXira/kayvan-alikhani1-250x250.png'
import advisor3 from 'static/aboutXira/mike-1-250x251.png'
import advisor4 from 'static/aboutXira/sam-bigdeli-1-250x250.jpg'
import { useAnalytics } from 'utils/hooks'
import Bg from '../../static/aboutXira/welcome-low-resolution-copy-2000x595.jpg'
import Bg1 from '../../static/aboutXira/adobestock-41456411-2000x834.jpg'
import Bg2 from '../../static/aboutXira/mbr-1920x960.jpg'
import Bg3 from '../../static/aboutXira/mbr-1-1920x1210.jpg'

import './AboutXira.scss'

const { parallaxProperties } = CONSTANTS

const teamInfo = [
  {
    firstName: 'Reza',
    lastName: 'Ghaffari',
    photo: mate1,
    position: 'Founder & CEO',
    linkedIn: 'https://www.linkedin.com/in/reza-ghaffari-2b5925/',
  },
  {
    firstName: 'Omid',
    lastName: 'Farokhzad',
    photo: mate2,
    position: 'Founder & Director',
    linkedIn: 'https://www.linkedin.com/in/omidfarokhzad/',
  },
  {
    firstName: 'Farshid',
    lastName: 'Mohammadi',
    photo: mate3,
    position: 'CCO',
    linkedIn: 'https://www.linkedin.com/in/farshidmohammadi/',
  },
  {
    firstName: 'Jose Miguel',
    lastName: 'Peleato',
    photo: mate4,
    position: 'CTO',
    linkedIn: 'https://www.linkedin.com/in/jose-miguel-peleato-a23626/',
  },
  {
    firstName: 'Michael',
    lastName: 'Djavaherian',
    photo: mate5,
    position: 'General Counsel',
    linkedIn: 'https://www.linkedin.com/in/michael-djavaherian-5494608/',
  },
  {
    firstName: 'Todd',
    lastName: 'Tuomala',
    photo: mate6,
    position: 'CIO',
    linkedIn: 'https://www.linkedin.com/in/toddtuomala/',
  },
  {
    firstName: 'Karen',
    lastName: 'Schulte',
    photo: mate7,
    position: 'Head of Marketing',
    linkedIn: 'https://www.linkedin.com/in/karen-schulte/',
  },
  {
    firstName: 'Afshin',
    lastName: 'Moshrefi',
    photo: mate8,
    position: 'Head of Software Development',
    linkedIn: 'https://www.linkedin.com/in/afshinmoshrefi/',
  },
]

const advisorsInfo = [
  {
    firstName: 'Patrick',
    lastName: 'DiPietro',
    photo: advisor1,
    linkedIn: 'https://www.linkedin.com/in/patdipietro/',
  },
  {
    firstName: 'Kayvan',
    lastName: 'Alikhani',
    photo: advisor2,
    linkedIn: 'https://www.linkedin.com/in/kayvanalikhani/',
  },
  {
    firstName: 'Mike',
    lastName: 'Dagenais',
    photo: advisor3,
    linkedIn: 'https://www.linkedin.com/in/mike-dagenais-8436697/',
  },
]

const AboutXira = () => {
  const parallaxSectionRef1 = useRef(null)
  const parallaxSectionRef2 = useRef(null)
  const parallaxSectionRef3 = useRef(null)
  const parallaxSectionRef4 = useRef(null)

  useAnalytics()

  useEffect(() => {
    const currentParallax1 = parallaxSectionRef1.current
    const currentParallax2 = parallaxSectionRef2.current
    const currentParallax3 = parallaxSectionRef3.current
    const currentParallax4 = parallaxSectionRef4.current

    const parallaxArray = [currentParallax1, currentParallax2, currentParallax3, currentParallax4]

    parallaxArray.forEach(parallax => jarallax(parallax, parallaxProperties))

    return () => {
      parallaxArray.forEach(parallax => jarallax(parallax, 'destroy'))
    }
  }, [])

  const team = teamInfo.map(mate => {
    return (
      <li className="team__item" key={mate.linkedIn}>
        <div className="team__wrap">
          <div className="team__avatar">
            <img src={mate.photo} alt="teammate" className="team__photo" />
          </div>
          <a href={mate.linkedIn} className="team__linkedin" target="_blank" rel="noopener noreferrer">
            <div className="team__circle">
              <div className="team__circle_text">in</div>
            </div>
          </a>
        </div>
        <div>
          <span className="team__first-name">{`${mate.firstName} `}</span>
          <span className="team__last-name">{mate.lastName}</span>
        </div>
        <div className="team__position">{mate.position}</div>
      </li>
    )
  })

  const advisors = advisorsInfo.map(mate => {
    return (
      <li className="team__item" key={mate.linkedIn}>
        <div className="team__wrap">
          <div className="team__avatar">
            <img src={mate.photo} alt="teammate" className="team__photo" />
          </div>
          <a href={mate.linkedIn} className="team__linkedin" target="_blank" rel="noopener noreferrer">
            <div className="team__circle">
              <div className="team__circle_text">in</div>
            </div>
          </a>
        </div>
        <div>
          <span className="team__first-name">{`${mate.firstName} `}</span>
          <span className="team__last-name">{mate.lastName}</span>
        </div>
      </li>
    )
  })

  return (
    <div className="parallax-wrapper">
      <section className="jarallax" ref={parallaxSectionRef1}>
        <div className="parallax">
          <img className="jarallax-img" src={Bg} alt="background" />
          <h1 className="title title_hero">welcome</h1>
          <div className="jarallax-overlay jarallax-overlay_hero" />
        </div>
      </section>

      <section className=" section section_desc normal-scroll">
        <div className="container container_desc">
          <h1 className="title title_desc">
            we're a technology company providing a gig
            <br />
            economy platform revolutionizing
            <br />
            professional services
          </h1>
        </div>
      </section>

      <section className="jarallax" ref={parallaxSectionRef2}>
        <img className="jarallax-img jarallax-img_lags" src={Bg1} alt="background" />
        <div className="jarallax-overlay jarallax-overlay_lags" />
      </section>

      <section className="section section_market">
        <div className="container container_market">
          <h1 className="title title_market">
            <span className="title__first-letter title_letter">{'our '}</span>
            <span className="title__second-letter title_letter">marketplace</span>
          </h1>
          <p className="desc desc_market">
            our online marketplace enables professionals to meet with clients
            <br />
            remotely, engage other professionals to form project-based virtual teams,
            <br />
            and reduce time spent on non-billable tasks. XIRA provides real-time
            <br />
            online access to professionals, putting the power of choice, convenience,
            <br />
            and price in your hands
          </p>
        </div>
      </section>

      <section className="jarallax" ref={parallaxSectionRef3}>
        <img className="jarallax-img jarallax-img_lags" src={Bg2} alt="background" />
        <div className="jarallax-overlay jarallax-overlay_planet" />
      </section>

      <section className="section section_promise normal-scroll">
        <div className="container container_promise">
          <h1 className="title title_promise">
            <span className="title__first-letter title_letter">{'our '}</span>
            <span className="title__second-letter title_letter">promise</span>
          </h1>
          <div className="desc desc_promise">
            "we'll make good advice affordable, accessible & available to all"
            <br />
            <div className="desc_italic">Reza Ghaffari</div>
          </div>
        </div>
      </section>
      <section className="section section_mission normal-scroll">
        <div className="container container_mission">
          <div className="box">
            <h4 className="box__title">
              <span className="title__first-letter title_letter box__letter">{'our '}</span>
              <span className="title__second-letter title_letter box__letter">mission</span>
            </h4>
            <p className="box__desc">
              to build the most robust, user-driven online platform that redefines the working environment for
              solo-practitioners and small businesses and democratizes professional services for all
            </p>
          </div>
          <div className="box">
            <h4 className="box__title">
              <span className="title__first-letter title_letter box__letter">{'our '}</span>
              <span className="title__second-letter title_letter box__letter">vision</span>
            </h4>
            <p className="box__desc">
              to help everyone utilize professional expertise and advice so they navigate life better
            </p>
          </div>
        </div>
      </section>

      <section className="jarallax" ref={parallaxSectionRef4}>
        <img className="jarallax-img jarallax-img_hands" src={Bg3} alt="background" />
        <div className="jarallax-overlay jarallax-overlay_hands" />
      </section>

      <section className="section section_history normal-scroll">
        <div className="container container_history">
          <h1 className="title title_history">
            <span className="title__first-letter title_letter">{'our '}</span>
            <span className="title__second-letter title_letter">history</span>
          </h1>
          <p className="desc desc_history">
            XIRA consists of seasoned entrepreneurs and creative leaders committed to using technology to achieve better
            outcomes for our users
          </p>
          <p className="desc desc_history">
            reflecting on our own education and professional experiences, we quickly recognized that universities did
            not train us to find customers and set up the necessary business infrastructure to reach financial
            profitability and independence
          </p>
          <p className="desc desc_history">
            XIRA continues to focus on removing traditional barriers that make starting a professional services business
            prohibitively challenging and expensive
          </p>
        </div>
      </section>

      <section className="section section_team" id="hero-3">
        <div className="container container_team">
          <h1 className="title title_team">
            <span className="title__first-letter title_letter">{'our '}</span>
            <span className="title__second-letter title_letter">team</span>
          </h1>
          <ul className="team__list">{team}</ul>
        </div>
      </section>

      <section className="section section_advisors">
        <div className="container container_advisors">
          <h1 className="title title_advisors">advisors</h1>
          <ul className="team__list">{advisors}</ul>
        </div>
      </section>
    </div>
  )
}

export default AboutXira
