import React from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames/bind'
import { Autocomplete, Hint, Icon, InputLabel } from 'components'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  div: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
  },
  input: {
    marginBottom: '4px',
  },
  focused: {
    borderColor: colors.BASIC.COLOR[80],
    borderWidth: '1px',
  },
  notchedOutline: {
    borderColor: colors.BASIC.COLOR[50],
    '&$focused': {
      borderColor: colors.BASIC.COLOR[80],
    },
  },
  hint: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: '8px',
  },
  label: {
    textAlign: 'left',
    display: 'flex',
  },
  optional: {
    color: colors.BASIC.COLOR[50],
    margin: '0 0 0 5px',
  },
  valid: {
    color: colors.SUCCESS,
  },
  error: {
    borderColor: colors.ERROR,
  },
  large: {
    width: '368px',
  },
})

export default function XFormikAutocomplete({
  label,
  type,
  containerClasses,
  size,
  required = true,
  InputLabelProps,
  innerLabel,
  InputProps,
  handleChange = () => {},
  handleChangeValue = () => {},
  ...props
}) {
  const [field, meta] = useField(props)
  const classes = useStyles()
  const optional = required ? '' : <InputLabel classes={{ root: classes.optional }}> (optional)</InputLabel>

  const handleAutocompleteChange = (e, value) => {
    const event = {
      target: {
        value: value ? value.value : '',
        name: props.name,
      },
    }
    field.onChange(event)
    handleChange(event)
    handleChangeValue(value)
  }

  return (
    <div
      className={classNames({
        [classes.div]: true,
        [containerClasses]: true,
      })}
    >
      {!innerLabel && (
        <InputLabel classes={{ root: classes.label }}>
          {label}
          {optional}
        </InputLabel>
      )}
      <Autocomplete
        {...field}
        {...props}
        InputProps={{
          classes: {
            root: size === 'large' ? classes.large : classes.input,
            focused: classes.focused,
            notchedOutline: meta.touched && meta.error ? classes.error : classes.notchedOutline,
          },
          ...InputProps,
        }}
        label={innerLabel && label}
        InputLabelProps={InputLabelProps}
        onChange={handleAutocompleteChange}
      />
      {meta.touched && meta.error && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {meta.error}
        </Hint>
      )}
    </div>
  )
}
