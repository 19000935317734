import React from 'react'
import { Chip, Icon, LanguageModal, Typography, LicensesTable } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  licensesContainer: {
    marginTop: 40,
  },
  title: {
    marginBottom: '12px',
  },
  '@media(max-width:767px)': {
    licensesContainer: {
      marginTop: 24,
    },
    title: {
      marginBottom: 0,
    },
  },
})

const LicenseProAccount = ({ licenses, states }) => {
  const classes = useStyles()

  return (
    <div className={classes.licensesContainer}>
      <Typography className={classes.title} variant="h4">
        licenses
      </Typography>
      <LicensesTable licenses={licenses} states={states} />
    </div>
  )
}

export default LicenseProAccount
