import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'
import { Checkbox, Switch } from 'components'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  calendarListContainer: {
    marginTop: 16,
    width: '100%',
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    backgroundColor: colors.BASIC.COLOR[0],
    display: 'flex',
  },
  calendarList: {
    margin: 'auto',
    padding: 25,
    display: 'flex',
    alignSelf: 'center',
    width: 'fit-content',
    flexDirection: 'column',
  },
  checkboxLabel: {
    fontSize: 15,
    color: colors.BASIC.COLOR[100],
  },
  slider: {
    '&.Mui-disabled': {
      color: colors.WHITE,
    },
  },
})

const SubCalendarList = ({ calendars, onSubCalendarListChange, border = true }) => {
  const classes = useStyles()
  const xiraCalendar = useSelector(state => state.calendar.xiraCalendar)
  return (
    <div className={border && classes.calendarListContainer}>
      <div className={classes.calendarList}>
        {calendars.map(calendar => (
          <Switch
            key={calendar.name}
            color="default"
            labelClasses={{ root: classes.checkboxLabel }}
            disabled={xiraCalendar.id === calendar.id}
            label={calendar.name}
            checked={calendar.selected}
            classes={{ switchBase: classes.slider }}
            onChange={e => onSubCalendarListChange(calendar.id, e.target.checked)}
          />
        ))}
      </div>
    </div>
  )
}

export default SubCalendarList
