import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'

const XMenuItem = React.forwardRef(({ classes, disableGutters, onClick, children, value, disabled }, ref) => (
  <MenuItem
    classes={classes}
    onClick={onClick}
    innerRef={ref}
    value={value}
    disabled={disabled}
    disableGutters={disableGutters}
  >
    {children}
  </MenuItem>
))
export default XMenuItem
