import React from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { useParams, withRouter } from 'react-router-dom'
import { colors, CONSTANTS } from 'constants/index'
import { useDispatch, useSelector } from 'react-redux'
import { navigateToAccountTab } from 'store/actions/navigation'
import Collapse from '@material-ui/core/Collapse'
import classNames from 'classnames'

const useStyles = makeStyles({
  drawerPaper: {
    paddingTop: 50,
    width: 236,
    borderRight: 0,
  },
  drawerPaperExtra: {
    paddingTop: 98,
  },
  list: {
    padding: 0,
    paddingTop: 24,
  },
  listDefault: {
    padding: 0,
    paddingTop: 10,
  },
  listItem: {
    paddingTop: 17,
    paddingBottom: 17,
    paddingLeft: 32,
    color: colors.BASIC.COLOR[70],
    fontSize: 15,
    height: 34,
    stroke: colors.BASIC.COLOR[70],
    textTransform: 'lowercase',
    backgroundColor: 'transparent !important',
    '& .dollar': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[70],
    },
    '& .message-icon-path': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[70],
    },
    '& .camera': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[70],
    },
    '& .folder': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[70],
    },
    '& .activities': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[70],
    },
    '& .connections': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[70],
    },
    '&:hover .dollar': {
      fill: colors.BASIC.COLOR[100],
    },
    '&:hover .message-icon-path': {
      fill: colors.BASIC.COLOR[100],
    },
    '&:hover .camera': {
      fill: colors.BASIC.COLOR[100],
    },
    '&:hover .folder': {
      fill: colors.BASIC.COLOR[100],
    },
    '&:hover .activities': {
      fill: colors.BASIC.COLOR[100],
    },
    '&:hover .connections': {
      fill: colors.BASIC.COLOR[100],
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.BASIC.COLOR[100],
      stroke: colors.BASIC.COLOR[100],
      '& .MuiListItemText-primary': {
        color: colors.BASIC.COLOR[100],
        stroke: colors.BASIC.COLOR[100],
      },
    },
  },
  listItemDisabled: {
    paddingTop: 17,
    paddingBottom: 17,
    paddingLeft: 32,
    color: colors.BASIC.COLOR[30],
    fontSize: 15,
    height: 34,
  },
  primaryDisabled: {
    color: colors.BASIC.COLOR[30],
    fontWeight: 500,
  },
  listItemIcon: {
    minWidth: 18,
    marginRight: 12,
    margin: 0,
    color: 'inherit',
    position: 'relative',
  },
  listItemTextPrimary: {
    fontWeight: 500,
    color: 'inherit',
    textTransform: 'lowercase',
  },
  listItemSelected: {
    textTransform: 'lowercase',
    backgroundColor: 'transparent !important',
    fontWeight: 500,
    color: colors.PRIMARY.COLOR[50],
    stroke: colors.PRIMARY.COLOR[50],
    '& .dollar': {
      stroke: 'transparent',
      fill: colors.BASIC.COLOR[100],
    },
    '& .message-icon-path': {
      stroke: 'transparent',
      fill: colors.PRIMARY.COLOR[50],
    },
    '& .camera': {
      stroke: 'transparent',
      fill: colors.PRIMARY.COLOR[50],
    },
    '& .folder': {
      stroke: 'transparent',
      fill: colors.PRIMARY.COLOR[50],
    },
    '& .activities': {
      stroke: 'transparent',
      fill: colors.PRIMARY.COLOR[50],
    },
    '& .connections': {
      stroke: 'transparent',
      fill: colors.PRIMARY.COLOR[50],
    },
    '&:hover': {
      color: colors.BASIC.COLOR[100],
      stroke: colors.BASIC.COLOR[100],
    },
  },
  listItemIconSelected: {
    stroke: colors.BASIC.COLOR[100],
    '& .dollar': {
      fill: colors.BASIC.COLOR[100],
    },
  },
  sublistItemSelected: {
    color: colors.PRIMARY.COLOR[50],
  },
  nested: {
    paddingLeft: 62,
  },
  comingSoonContainer: {
    marginLeft: 32,
    marginTop: 25,
    fontSize: 12,
    color: colors.BASIC.COLOR[30],
  },
  indicator: {
    backgroundColor: colors.ERROR,
    height: 8,
    width: 8,
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '50%',
  },
  newBadge: {
    backgroundColor: colors.PRIMARY.COLOR['60'],
    color: colors.WHITE,
    borderRadius: 4,
    fontSize: 11,
    marginLeft: 20,
    padding: '3px 5px',
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  itemTextContainer: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    justifyContent: 'space-between',
  },
})

const NewBadge = () => {
  const classes = useStyles()

  return <div className={classes.newBadge}>new</div>
}

const AppNavDrawer = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { accountTab, accountSubTab } = useParams()
  const userHasNewMessages = useSelector(state => state.chat.userHasNewMessages)
  const headerBanner = useSelector(state => state.auth.headerBanner)

  const onListItemClick = (route, external) => {
    if (external) {
      window.open(route, '_blank', 'noopener noreferrer')
      return
    }
    if (
      (accountTab === route &&
        ((route !== 'cases' && route !== 'activities' && route !== 'finances') || !accountSubTab)) ||
      (`${accountTab}/${accountSubTab}` === route && accountSubTab !== 'invoices')
    ) {
      return
    }
    dispatch(navigateToAccountTab(route))
  }

  const getItem = item => {
    const { route, icon, text, children, newOption, external } = item
    const messageIndicator = userHasNewMessages && route === 'messaging'
    return (
      <div key={route}>
        <ListItem
          button
          selected={accountTab === route}
          onClick={() => onListItemClick(route, external)}
          classes={{
            root: classes.listItem,
            selected: classNames({ [classes.listItemSelected]: !children }),
          }}
        >
          <ListItemIcon
            classes={{
              root: classNames(classes.listItemIcon, {
                [classes.listItemIconSelected]: children && accountTab === route,
              }),
            }}
          >
            {!!messageIndicator && <div className={classes.indicator} />}
            {icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classNames({
                [classes.listItemSelected]: !children && accountTab === route,
                [classes.listItemTextPrimary]: accountTab !== route,
              }),
            }}
            primary={
              <div className={classes.itemTextContainer}>
                {text}
                {newOption && <NewBadge />}
              </div>
            }
          />
        </ListItem>
        {children && children.map(child => getListSubItem(child, route))}
      </div>
    )
  }

  const getListSubItem = (child, parentRoute) => (
    <Collapse key={child.route} in={accountTab === parentRoute} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem
          button
          onClick={() => onListItemClick(`${parentRoute}/${child.route}`, child.external)}
          selected={accountSubTab === child.route}
          classes={{
            root: classes.listItem,
            selected: classes.listItemSelected,
          }}
          className={classes.nested}
        >
          <ListItemText
            classes={{
              primary:
                child.route === accountSubTab
                  ? classNames(classes.listItemSelected, classes.sublistItemSelected)
                  : classes.listItemTextPrimary,
            }}
            primary={child.text}
          />
        </ListItem>
      </List>
    </Collapse>
  )

  const getListItems = () => {
    return CONSTANTS.APP_DRAWER_MENU_ITEMS.map((item, i) => {
      return getItem(item, i)
    })
  }

  const getDisabledItem = () => {
    return CONSTANTS.DISABLED_MENU_ITEMS.map((item, i) => {
      return (
        <div key={item.text}>
          <ListItem button disabled classes={{ root: classes.listItemDisabled }}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>{item.icon}</ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.primaryDisabled,
              }}
              primary={item.text}
            />
          </ListItem>
        </div>
      )
    })
  }

  return (
    <Drawer
      classes={{
        paper: classNames(classes.drawerPaper, { [classes.drawerPaperExtra]: headerBanner.show }),
      }}
      variant="permanent"
      anchor="left"
    >
      <List
        classes={{
          root: classes.list,
        }}
      >
        {getListItems()}
      </List>
      <Typography className={classes.comingSoonContainer} variant="body2">
        coming soon
      </Typography>
      <List
        classes={{
          root: classes.listDefault,
        }}
      >
        {getDisabledItem()}
      </List>
    </Drawer>
  )
}

export default withRouter(AppNavDrawer)
