import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, FormikInput, Link, SvgIcon, Text, Typography } from 'components'
import {
  endAuthByEmail,
  getAuthTokenByEmail,
  logInWithSocial,
  requestVerificationLink,
  setSignUpEmail,
} from 'store/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'constants/index'
import { makeStyles } from '@material-ui/core/styles'
import { useGoogleLogin } from '@react-oauth/google'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { ReactComponent as IconFacebook } from 'static/ic_facebook.svg'
import { ReactComponent as IconGoogle } from 'static/ic_google.svg'
import { ReactComponent as AlertTriangle } from 'static/alert-triangle.svg'
import classNames from 'classnames'

const schema = Yup.object({
  username: Yup.string()
    .trim()
    .required('please type your email'),
  password: Yup.string()
    .trim()
    .required('please type your password'),
})

const useStyles = makeStyles({
  socialButtons: {
    width: 368,
    height: 46,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 16,
    padding: 0,
  },
  outlinedSecondaryGoogle: {
    color: colors.BASIC.COLOR[100],
    borderColor: colors.BASIC.COLOR[10],
    backgroundColor: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.WHITE,
      borderColor: colors.BASIC.COLOR[10],
      color: colors.BASIC.COLOR[100],
    },
    '&:disabled': {
      backgroundColor: colors.BASIC.COLOR[10],
    },
  },
  iconGoogle: {
    height: 16,
    width: 16,
  },
  outlinedSecondaryFacebook: {
    color: colors.WHITE,
    borderColor: colors.FACEBOOK_BLUE,
    backgroundColor: colors.FACEBOOK_BLUE,
    '&:hover': {
      backgroundColor: colors.FACEBOOK_BLUE,
      borderColor: colors.FACEBOOK_BLUE,
      color: colors.WHITE,
    },
    '&:disabled': {
      backgroundColor: colors.BASIC.COLOR[10],
    },
  },
  iconFacebook: {
    height: 16,
    width: 16,
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',
  },
  text: {
    margin: '32px',
  },
  link: {
    lineHeight: 1.17,
    marginLeft: 8,
  },
  footerText: {
    fontSize: 15,
    lineHeight: 1.47,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    width: '100%',
    maxWidth: 360,
    marginBottom: 24,
  },
  errorMsg: {
    display: 'flex',
    margin: '0 auto',
    fontSize: '14px',
    marginBottom: 16,
    width: 360,
    border: `1px solid ${colors.PRIMARY.COLOR[40]}`,
    backgroundColor: colors.PRIMARY.COLOR[0],
    padding: 12,
    borderRadius: 4,
  },
  errorDesc: {
    textAlign: 'left',
    color: colors.BASIC.COLOR[100],
  },
  alertLogo: {
    width: 24,
    height: 24,
    marginRight: 8,
    marginTop: 1,
    color: colors.ERROR,
  },
  restorePassword: {
    alignSelf: 'flex-start',
    marginBottom: 12,
  },
  signUp: {
    textAlign: 'center',
    width: 568,
    height: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  signUpHeader: {
    marginTop: 56,
    marginBottom: 20,
  },
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: 360,
  },
  buttonContainer: {
    display: 'flex',
    maxWidth: 360,
    flexGrow: 1,
    margin: '10px auto',
  },
  signUpFooter: {
    textAlign: 'center',
    marginTop: 34,
    marginBottom: 64,
  },
  verifyLink: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: colors.PRIMARY.COLOR[50],
    fontWeight: 500,
    textDecoration: 'none',
  },
  errorCont: {
    marginTop: 6,
  },
  buttonText: {
    marginLeft: 8,
  },
  '@media(max-width:767px)': {
    signUpForm: {
      width: '100%',
      maxWidth: 360,
    },
    buttonResponsive: {
      width: '100%',
    },
    errorMsg: {
      width: '100%',
      maxWidth: 360,
    },
    socialButtons: {
      width: '100%',
    },
  },
  '@media(max-width:400px)': {
    buttonText: {
      display: 'none',
    },
    buttonContainer: {
      justifyContent: 'center',
    },
    socialButtons: {
      width: 46,
      minWidth: 46,
      borderRadius: '50%',
      margin: '4px 7px',
    },
  },
  bolderText: {
    fontWeight: 500,
  },
})

const initialFormikValues = {
  username: '',
  password: '',
}

const LogInForm = props => {
  const {
    goToRestore,
    onAfterLogin,
    formBodyClass,
    footerContainerClass,
    socialLoginButtonsClass,
    showSocialButtonText = true,
    errorsClass,
    withoutTitle,
    withoutSocial,
  } = props
  const isAuthByEmail = useSelector(state => state.auth.isAuthByEmail)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isSocialLogin, setIsSocialLogin] = useState(false)
  const [errorVisibility, setErrorVisibility] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [verificationState, setVerificationState] = useState(false)

  const socialErrorHandler = () => {
    setIsSocialLogin(false)
  }

  const onFBLogin = ({ accessToken }) => {
    socialAuth('facebook', accessToken)
  }

  const onGoogleLogin = ({ access_token }) => {
    socialAuth('google-oauth2', access_token)
  }

  const socialAuth = (provider, access_token) => {
    dispatch(
      logInWithSocial({
        provider,
        access_token,
        is_login: true,
      })
    )
      .then(() => {
        if (onAfterLogin) {
          onAfterLogin()
        }
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.detail) {
          setErrorMessage(e.response.data.detail)
        }
        setErrorVisibility(true)
      })
      .finally(() => setIsSocialLogin(false))
  }

  const onFormSubmit = async (values, actions) => {
    const payload = {
      ...values,
    }
    try {
      setVerificationState(false)
      setErrorVisibility(false)
      dispatch(setSignUpEmail(values.username))
      await dispatch(getAuthTokenByEmail(payload))
      if (onAfterLogin) {
        onAfterLogin()
      }
    } catch (e) {
      dispatch(endAuthByEmail())
      if (e.response && e.response.data && e.response.data.detail) {
        if (e.response.data.title && e.response.data.title[0] === 'Inactive') {
          setVerificationState(true)
        }
        setErrorMessage(e.response.data.detail)
      } else {
        setErrorMessage('Your login information was incorrect. Please check and try again.')
      }

      actions.setTouched({
        username: false,
        password: false,
      })
      setErrorVisibility(true)
    }
  }

  const socialLogin = onClick => {
    setVerificationState(false)
    setErrorVisibility(false)
    setIsSocialLogin(true)
    onClick()
  }

  const navToRestore = () => {
    setErrorVisibility(false)
    goToRestore()
  }

  const requestVerification = event => {
    event.preventDefault()
    dispatch(requestVerificationLink())
    setErrorVisibility(false)
  }

  const buttonResponsive = {
    root: classes.buttonResponsive,
  }

  const login = useGoogleLogin({ onSuccess: onGoogleLogin, onError: socialErrorHandler })

  return (
    <>
      {!withoutTitle && (
        <div className={classes.signUpHeader}>
          <Typography classes={{ h2: classes.title }} variant="h2">
            let&apos;s get you logged in
          </Typography>
        </div>
      )}
      {errorVisibility && (
        <div
          className={classNames({
            [classes.errorMsg]: true,
            [errorsClass]: errorsClass,
          })}
        >
          <SvgIcon component={AlertTriangle} viewBox="0 0 24 24" className={classes.alertLogo} />
          <div className={classes.errorDesc}>
            {verificationState ? (
              <>
                The email you entered during registration{' '}
                <span className={classes.bolderText}>hasn&apos;t been verified yet</span>
                . Please select the VERIFY MY EMAIL button in the email that was sent to you
                <br />
                <div className={classes.errorCont}>
                  or,{' '}
                  <Link
                    href="#"
                    onClick={requestVerification}
                    classes={{
                      root: classes.verifyLink,
                    }}
                  >
                    send new verification email
                  </Link>
                </div>
              </>
            ) : (
              errorMessage
            )}
          </div>
        </div>
      )}
      <div>
        <Formik
          initialValues={initialFormikValues}
          validationSchema={schema}
          onSubmit={onFormSubmit}
          validateOnChange={false}
        >
          {formikProps => (
            <Form
              className={classNames({ [classes.signUpForm]: true, [formBodyClass]: formBodyClass })}
              onSubmit={formikProps.handleSubmit}
            >
              <FormikInput
                variant="outlined"
                size="responsive"
                name="username"
                label="Email"
                type="text"
                externalError={errorVisibility}
              />
              <FormikInput
                variant="outlined"
                size="responsive"
                name="password"
                label="Password"
                type="password"
                externalError={errorVisibility}
              />
              <Link className={classes.restorePassword} onClick={navToRestore}>
                forgot password?
              </Link>
              <Button
                classes={buttonResponsive}
                variant="primary"
                size="large"
                children="Continue"
                type="submit"
                className={classes.btn}
                isButtonBlocked={isAuthByEmail}
                disabled={isAuthByEmail}
              />
            </Form>
          )}
        </Formik>
        {!withoutSocial && (
          <div className={footerContainerClass}>
            <Text className={classes.footerText}>or log in using</Text>
            <div className={classes.buttonContainer}>
              <Button
                classes={{
                  root: socialLoginButtonsClass || classes.socialButtons,
                  outlinedSecondary: classes.outlinedSecondaryGoogle,
                }}
                variant="secondary"
                onClick={() => socialLogin(login)}
                disabled={isAuthByEmail}
              >
                <SvgIcon component={IconGoogle} viewBox="0 0 22 22" className={classes.iconGoogle} />
                {showSocialButtonText && <span className={classes.buttonText}>Google</span>}
              </Button>
              {/*<FacebookLogin*/}
              {/*  appId={process.env.REACT_APP_FACEBOOK_APP}*/}
              {/*  autoLoad={false}*/}
              {/*  callback={onFBLogin}*/}
              {/*  responseType="code"*/}
              {/*  fields="name,email"*/}
              {/*  render={renderProps => (*/}
              {/*    <Button*/}
              {/*      classes={{*/}
              {/*        root: socialLoginButtonsClass || classes.socialButtons,*/}
              {/*        outlinedSecondary: classes.outlinedSecondaryFacebook,*/}
              {/*      }}*/}
              {/*      variant="secondary"*/}
              {/*      disabled={isAuthByEmail}*/}
              {/*      onClick={() => {*/}
              {/*        socialLogin(renderProps.onClick)*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <SvgIcon component={IconFacebook} viewBox="0 0 8 15" className={classes.iconFacebook} />*/}
              {/*      {showSocialButtonText && <span className={classes.buttonText}>Facebook</span>}*/}
              {/*    </Button>*/}
              {/*  )}*/}
              {/*/>*/}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default LogInForm
