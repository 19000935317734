import * as CONSTANTS from 'store/constants/meetingScheduler'

const initialState = {
  start: null,
  end: null,
  showMeetingModal: false,
  createMeetingLoading: false,
}

const meetingScheduler = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.SET_MEETING_TIME:
      return {
        ...state,
        ...action.payload,
      }
    case CONSTANTS.TOGGLE_MEETING_MODAL:
      return {
        ...state,
        showMeetingModal: action.payload,
      }
    case CONSTANTS.CREATE_MEETING_LOADING_START:
      return {
        ...state,
        createMeetingLoading: true,
      }
    case CONSTANTS.CREATE_MEETING_LOADING_END:
      return {
        ...state,
        createMeetingLoading: false,
      }
    default:
      return state
  }
}

export default meetingScheduler
