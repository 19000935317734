import React from 'react'
import './eventsCalendar.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import momentPlugin from '@fullcalendar/moment'
import ReactDOM from 'react-dom'
import moment from 'moment-timezone'
import { getEventsForCalendarView } from 'utils/mappers/backend'
import { CONSTANTS } from 'constants/index'
import Event from './Event'
import ListEvent from './ListEvent'
import CalendarToolbar from './CalendarToolbar'

const getHeaderHTML = (date, timezone) => {
  return `<div class="fc-calendar-header-day">${moment
    .tz(date, timezone)
    .format(
      'ddd'
    )}</div><div class="fc-calendar-header-number-container"><div class="fc-calendar-header-number">${moment
    .tz(date, timezone)
    .format('DD')}</div></div>`
}

const getEventElement = (info, timezone) => {
  const startTime = moment(info.event.start).tz(timezone)
  const endTime = moment(info.event.end).tz(timezone)
  const today = moment(new Date()).tz(timezone)
  const pastEvent = today.isAfter(endTime)
  const isToday = today.isSame(startTime, 'd')

  if (info.view.type === 'timeGridWeek') {
    ReactDOM.render(
      <Event
        eventData={info.event.extendedProps}
        start={info.event.start}
        end={info.event.end}
        isStart={info.isStart}
        isEnd={info.isEnd}
        timezone={timezone}
      />,
      info.el.querySelector('.fc-content')
    )
  } else if (info.view.type === 'listThirtyDaysView') {
    //set interval to highlight current date in list view
    setTimeout(() => {
      const date = moment().tz(timezone)
      const node = document.querySelector(`[data-date="${date.format('YYYY-MM-DD')}"]`)
      if (node && !node.classList.contains('fc-list-current-date-indicator')) {
        node.classList.add('fc-list-current-date-indicator')
        if (date.get('date').toString().length === 1) {
          node.classList.add('fc-list-current-date-indicator-one-digit-in-date')
        }
      }
    }, 0)
    if (!pastEvent && isToday) {
      info.el.classList.add('fc-list-event-future-event')
    }

    ReactDOM.render(
      <ListEvent
        eventData={info.event.extendedProps}
        start={info.event.start}
        end={info.event.end}
        timezone={timezone}
      />,
      info.el
    )
  }
  return info.el
}

const eventDestroyHandler = info => {
  if (info.view.type === 'timeGridWeek') {
    ReactDOM.unmountComponentAtNode(info.el.querySelector('.fc-content'))
  } else if (info.view.type === 'listThirtyDaysView') {
    ReactDOM.unmountComponentAtNode(info.el)
  }
}

const getValidRange = (view, timezone) => {
  if (view === 'listThirtyDaysView') {
    const a = moment()
      .tz(timezone)
      .startOf('day')
      .toDate()
    return {
      start: a,
      end: moment()
        .tz(timezone)
        .add(29, 'days')
        .endOf('day')
        .toDate(),
    }
  }
}

const EventsCalendar = props => {
  const { onEventSelect, timezone, getEvents, businessHours, calendarRef, setIsLoading, currentTab } = props

  const onSelect = e => {
    const data = { ...e.event.extendedProps, startTime: e.event.start, endTime: e.event.end }
    onEventSelect(data, { top: e.jsEvent.y, left: e.jsEvent.x })
  }

  const eventsHandler = async (weekData, successCallback, failureCallback) => {
    const startWeek = moment(weekData.start)
      .utc()
      .unix()
    const endWeek = moment(weekData.end)
      .utc()
      .unix()
    try {
      const data = await getEvents(startWeek, endWeek)
      successCallback(getEventsForCalendarView(data, currentTab, timezone))
    } catch (e) {
      failureCallback(e)
    }
  }

  return (
    <FullCalendar
      timeGridEventMinHeight={24}
      loading={isLoading => setIsLoading(isLoading)}
      ref={calendarRef}
      height="parent"
      defaultView="timeGridWeek"
      plugins={[dayGridPlugin, timeGridPlugin, momentTimezonePlugin, listPlugin, momentPlugin]}
      events={eventsHandler}
      businessHours={businessHours}
      nowIndicator
      eventClick={onSelect}
      timeZone={timezone}
      columnHeaderHtml={date => getHeaderHTML(date, timezone)}
      listDayFormat="D MMM, ddd"
      listDayAltFormat={false}
      noEventsMessage="You have no upcoming events"
      header={false}
      views={{
        listThirtyDaysView: {
          type: 'list',
          duration: { days: 31 },
        },
      }}
      eventRender={info => getEventElement(info, timezone)}
      eventDestroy={eventDestroyHandler}
      validRange={getValidRange(currentTab, timezone)}
    />
  )
}

export default React.memo(props => <EventsCalendar {...props} />)
