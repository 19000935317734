import Select from '@material-ui/core/Select'
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import XMenuItem from '../menu/menuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'

const XSelect = ({
  options = [],
  value,
  onChange,
  selectClasses,
  name,
  variant,
  type,
  fullWidth,
  className,
  inputProps,
  disabled,
  error,
  labelId,
  label,
  disablePlaceholder,
  menuItemClasses,
  listItemIconClasses,
  formControlClassName,
  ...restProps
}) => {
  return (
    <FormControl fullWidth={fullWidth} error={error} className={formControlClassName}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        variant={variant}
        name={name}
        type={type}
        fullWidth={fullWidth}
        onChange={onChange}
        value={value || ''}
        classes={selectClasses}
        className={className}
        inputProps={inputProps}
        disabled={disabled}
        displayEmpty
        {...restProps}
      >
        {!disablePlaceholder && (
          <XMenuItem value="" disabled>
            <em>Please Select</em>
          </XMenuItem>
        )}
        {options.map(v => (
          <XMenuItem key={v.value} value={v.value} classes={menuItemClasses}>
            {v.icon ? (
              <ListItemIcon className={listItemIconClasses}>
                <>
                  {v.icon}
                  {v.label}
                </>
              </ListItemIcon>
            ) : (
              v.label
            )}
          </XMenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default XSelect
