import React from 'react'
import { Icon, Link, Typography, SvgIcon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as FacebookLogo } from 'static/pro_account_facebook.svg'
import { ReactComponent as LinkedInLogo } from 'static/ic_linkedin.svg'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  socialContainer: {
    marginTop: 32,
  },
  socialLinks: {
    display: 'flex',
    marginTop: '10px',
  },
  link: {
    marginRight: 32,
    cursor: 'pointer',
  },
  '@media(max-width:767px)': {
    socialContainer: {
      borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
      borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
      marginTop: 23,
      paddingTop: 24,
      paddingBottom: 23,
    },
  },
})

const SocialProAccount = ({ socialLinks }) => {
  const classes = useStyles()
  const facebook = socialLinks.find(link => link.social_account === 'facebook')
  const linkedin = socialLinks.find(link => link.social_account === 'linkedin')
  return (
    <div className={classes.socialContainer}>
      <Typography variant="h4">social links</Typography>
      <div className={classes.socialLinks}>
        {facebook && (
          <Link
            underline="none"
            onClick={() => {
              window.open(facebook.link)
            }}
            className={classes.link}
          >
            <FacebookLogo />
          </Link>
        )}
        {linkedin && (
          <Link
            underline="none"
            onClick={() => {
              window.open(linkedin.link)
            }}
            className={classes.link}
          >
            <LinkedInLogo />
          </Link>
        )}
      </div>
    </div>
  )
}

export default SocialProAccount
