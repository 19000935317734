import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { Tooltip, Typography } from 'components'
import { colors } from 'constants/index'
import { ReactComponent as InfoIcon } from '../../../static/invoices/info_tooltip.svg'

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  actionText: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  disabledActionText: {
    color: colors.BASIC.COLOR[20],
  },
  logo: {
    marginRight: 8,
  },
  actionIcon: {
    marginLeft: 4,
  },
  tooltip: {
    fontSize: 13,
    padding: 16,
    maxWidth: 330,
    color: colors.BASIC.COLOR[100],
    fontWeight: 'normal',
  },
})

const ActionRow = ({ title, logo, disabled, tooltip }) => {
  const classes = useStyles()
  return (
    <div className={classes.row}>
      {logo}
      <Typography className={classNames(classes.actionText, { [classes.disabledActionText]: disabled })}>
        {title}
      </Typography>
      {tooltip && (
        <Tooltip title={tooltip} tooltipClass={classes.tooltip}>
          <InfoIcon className={classes.actionIcon} />
        </Tooltip>
      )}
    </div>
  )
}

export default ActionRow
