import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTimezoneLabel } from 'utils/getters/timezone'
import { Typography } from 'components'

const TimezoneLabel = props => {
  const { timezone, containerClasses } = props
  const timezonesList = useSelector(state => state.common.timezonesList)
  const [timezoneName, setTimezone] = useState(null)

  useEffect(() => {
    setTimezone(getTimezoneLabel(timezonesList, timezone))
  }, [timezonesList, timezone])

  return (
    <div className={containerClasses}>
      <Typography variant="subtitle2">{`${timezoneName}`}</Typography>
    </div>
  )
}

export default TimezoneLabel
