import React, { useMemo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link, Breadcrumbs, VaultCasesTabHeader, VaultFilePreviewHeader, VaultTrashTabHeader } from 'components'
import { ReactComponent as BreadcrumbIcon } from 'static/vault/breadcrump.svg'
import { colors } from 'constants/index'
import { useSelector } from 'react-redux'
import { getIsMoveActionAvailable, getIsPrivateFolderSelected } from 'store/selectors/vaultSelector'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles({
  vaultHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 56,
    backgroundColor: colors.BASIC.COLOR['0'],
    padding: '16px 56px 12px 24px',
    borderBottom: `1px solid ${colors.BASIC.COLOR['10']}`,
    position: 'absolute',
    left: 0,
    width: '100%',
  },
  vaultHeaderTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
    textDecoration: 'none',
    '&:hover': {
      color: colors.BASIC.COLOR['100'],
    },
  },
  buttonClass: {
    padding: '6px 16px',
    height: 32,
    minHeight: 32,
    marginRight: 12,
    fontSize: 11,
  },
  iconClass: {
    marginRight: 4,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedItemsLabel: {
    fontSize: 15,
    fontWeight: 'normal',
    marginRight: 24,
  },
})

const VaultModalHeader = ({ previewItem, vaultTab, isClientVault, handleTabChange }) => {
  const classes = useStyles()
  const selectedFolders = useSelector(state => state.vault.selectedFolders)
  const isPrivateFolderSelected = useSelector(getIsPrivateFolderSelected)
  const selectedItems = useSelector(state => state.vault.selectedItems)
  const proName = useSelector(state => state.vault.proName)

  const nameOfSelectedFolder = selectedFolders[0] && selectedFolders[0].name
  const copyOfSelectedFolders = useMemo(() => cloneDeep(selectedFolders), [selectedFolders])
  if (nameOfSelectedFolder === 'Private') copyOfSelectedFolders[0].name = 'My folder'
  const copyOfnameInHeader = copyOfSelectedFolders[0] && copyOfSelectedFolders[0].name

  return (
    <div className={classes.vaultHeaderContainer}>
      {vaultTab === 0 && (
        <>
          {!!previewItem ? (
            <VaultFilePreviewHeader
              previewItem={previewItem}
              isClientVault={isClientVault}
              isPrivateFolderSelected={isPrivateFolderSelected}
            />
          ) : (
            <VaultCasesTabHeader
              selectedFolders={copyOfSelectedFolders}
              isPrivateFolderSelected={isPrivateFolderSelected}
              isClientVault={isClientVault}
              selectedItems={selectedItems}
              proName={proName}
            />
          )}
        </>
      )}
      {vaultTab === 1 && (
        <>
          <Breadcrumbs separator={<BreadcrumbIcon />}>
            <Link className={classes.vaultHeaderTitle}>{isClientVault ? proName : copyOfnameInHeader}</Link>
          </Breadcrumbs>
        </>
      )}
      {vaultTab === 2 && (
        <VaultTrashTabHeader
          selectedFolders={selectedFolders}
          selectedItems={selectedItems}
          headerClasses={classes}
          handleTabChange={handleTabChange}
        />
      )}
    </div>
  )
}

export default VaultModalHeader
