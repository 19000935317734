import React, { useState } from 'react'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { ColorCircularProgress, Typography } from 'components'
import MomentUtils from '@date-io/moment'

const useStyles = makeStyles({
  calendarContainer: {
    overflow: 'hidden',
  },
  currentDate: {
    marginTop: 8,
    color: 'rgba(0, 0, 0, 0.38)',
    fontWeight: 500,
  },
})

const CalendarView = ({ selectedDate, handleDateChange, availableTime, onMonthChange, minDate, disableDay }) => {
  const classes = useStyles()
  const tillDate = moment(Object.keys(availableTime).slice(-1)[0])
  return (
    <div className={classes.calendarContainer}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Calendar
          maxDate={tillDate}
          minDate={minDate}
          date={selectedDate}
          loadingIndicator={<ColorCircularProgress />}
          disablePast
          onMonthChange={onMonthChange}
          shouldDisableDate={date => (date.diff(tillDate) > 0 ? true : !availableTime[date.format('YYYY-MM-DD')])}
          onChange={handleDateChange}
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            if (!disableDay) return dayComponent
            if (day.isSame(selectedDate) && disableDay)
              return (
                <Typography className={classes.currentDate} variant="body2">
                  {day.get('date')}
                </Typography>
              )
            return dayComponent
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default CalendarView
