import React from 'react'
import MaterialAvatar from '@material-ui/core/Avatar'
import NoPhoto from 'static/search/pro_no photo.png'

const Avatar = props => {
  return (
    <MaterialAvatar src={props.src} className={props.className} onClick={props.onClick}>
      {props.children || <img src={NoPhoto} alt="no photo placeholder"/>}
    </MaterialAvatar>
  )
}

export default Avatar
