import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { LogInForm } from 'containers'
import { AddCardForm, InviteRegistrationContactInfo, InviteRegistrationSignUp } from 'components'
import { toggleRestorePass } from '../../store/actions/common'
import { useDispatch } from 'react-redux'
import RestoreDialog from '../../containers/restorePassword/RestoreDialog'

const useStyles = makeStyles({
  loginFormBody: {
    margin: 0,
    alignItems: 'start',
  },
  socialLoginButtons: {
    height: 40,
    minHeight: 40,
    width: 40,
    minWidth: 40,
    color: colors.FACEBOOK_BLUE,
    backgroundColor: colors.WHITE,
    borderColor: colors.BASIC.COLOR[10],
    borderRadius: '50%',
    marginLeft: 10,
    padding: 0,

    '&:hover': {
      color: colors.FACEBOOK_BLUE,
      backgroundColor: colors.WHITE,
      borderColor: colors.BASIC.COLOR[20],
    },
  },
  loginFooter: {
    display: 'flex',
  },
  root: {
    minWidth: 600,
    marginTop: 24,
  },
  errorsClass: {
    margin: 0,
    marginBottom: 12,
  },
  '@media(max-width:767px)': {
    root: {
      minWidth: 100,
      width: '100%',
    },
  },
})

const InviteRegistrationStepSelector = props => {
  const { activeStep, onAfterLogin, onAfterSignUp, onAfterContactInfoSubmit, setStep, firstName, lastName } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const goToRestore = () => {
    dispatch(toggleRestorePass(true))
  }

  const getComponent = step => {
    switch (step) {
      case "let's get you logged in":
        return (
          <>
            <LogInForm
              goToRestore={goToRestore}
              onAfterLogin={onAfterLogin}
              formBodyClass={classes.loginFormBody}
              socialLoginButtonsClass={classes.socialLoginButtons}
              footerContainerClass={classes.loginFooter}
              showSocialButtonText={false}
              errorsClass={classes.errorsClass}
              withoutTitle
              withoutSocial
            />
            <RestoreDialog />
          </>
        )
      case "let's get you signed up":
        return <InviteRegistrationSignUp onAfterSignUp={onAfterSignUp} firstName={firstName} lastName={lastName} />
      case ' provide your contact info':
        return <InviteRegistrationContactInfo onAfterContactInfoSubmit={onAfterContactInfoSubmit} />
      default:
        return null
    }
  }

  return <div className={classes.root}>{getComponent(activeStep)}</div>
}

export default InviteRegistrationStepSelector
