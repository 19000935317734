import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { range } from 'lodash'
import classNames from 'classnames'
import { Typography, LinearProgress, Button } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { setReviewCommentsFilter, getProReviews } from 'store/actions/proReview/proReview'
import { ReactComponent as StarIcon } from 'static/rating/rating_star.svg'

const useStyles = makeStyles({
  reviewStat: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    cursor: 'pointer',
  },
  reviewStatInactive: {
    opacity: 0.3,
  },
  reviewStatModal: {
    marginBottom: 32,
  },
  reviewStatRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  reviewStatText: {
    fontSize: 13,
  },
  reviewStatPercent: {
    color: colors.ADDITIONAL.COLOR['50'],
  },
  reviewStatTextBold: {
    fontWeight: 500,
    color: colors.BASIC.COLOR['80'],
  },
  reviewStatBtnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  reviewStatBtn: {
    padding: '6px 16px',
    height: 32,
    backgroundColor: colors.PRIMARY.COLOR['0'],
    fontSize: 11,
  },
  reviewStarsRow: {
    marginBottom: 5,
  },
  starIcon: {
    marginRight: 9,
  },
})

const ProRatingStatsFilter = ({ stats, openRatingModal, fullProfile, proID }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const proReviewCommentsFilter = useSelector(state => state.proReview.proReviewCommentsFilter)
  const statArray = stats ? Object.entries(stats).reverse() : []
  const handleFilterClick = key => {
    if (fullProfile) {
      openRatingModal(key)
    } else {
      dispatch(setReviewCommentsFilter(key))
      dispatch(getProReviews(proID))
    }
  }
  return (
    <>
      {statArray.map(stat => (
        <div
          key={stat[0]}
          className={classNames(
            classes.reviewStat,
            { [classes.reviewStatModal]: !fullProfile },
            {
              [classes.reviewStatInactive]:
                proReviewCommentsFilter && proReviewCommentsFilter !== stat[0] && !fullProfile,
            }
          )}
          onClick={() => handleFilterClick(stat[0])}
        >
          <div className={classes.reviewStatRow}>
            <div className={classes.reviewStarsRow}>
              {range(stat[0]).map((x, index) => (
                <StarIcon key={index} className={classes.starIcon} />
              ))}
            </div>
            <Typography variant="h6" className={classNames(classes.reviewStatText, classes.reviewStatPercent)}>
              {`${stat[1]}%`}
            </Typography>
          </div>
          <LinearProgress variant="determinate" value={stat[1]} />
        </div>
      ))}
      {fullProfile && (
        <div className={classes.reviewStatBtnContainer}>
          <Button variant="secondary" className={classes.reviewStatBtn} onClick={() => openRatingModal()}>
            show comments
          </Button>
        </div>
      )}
    </>
  )
}

export default ProRatingStatsFilter
