import React from 'react'
import { StyledStepper } from 'components'

const getSteps = () => [
  'contact info',
  'licensing',
  'practice areas',
  'profile picture',
  'calendars',
  'advance settings',
]

const ProProfileCreationStepper = ({ activeStep, setActiveStep }) => {
  return <StyledStepper steps={getSteps()} activeStep={activeStep} setActiveStep={setActiveStep} />
}

export default ProProfileCreationStepper
