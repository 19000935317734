import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link, Select, ConfigureCardModal, Hint, Icon } from 'components'
import { CONSTANTS, colors } from 'constants/index'
import { getUserCards, saveUserCard, getClientPaymentSecret } from 'store/actions/payments'
import { useDispatch } from 'react-redux'
import { ReactComponent as AMLogo } from 'static/AMEX.svg'
import { ReactComponent as MCLogo } from 'static/MC.svg'
import { ReactComponent as VisaLogo } from 'static/Visa.svg'
import { ReactComponent as JCBLogo } from 'static/ic_logo_JCB.svg'
import { ReactComponent as DCLogo } from 'static/ic_logo_dinner_club.svg'
import { ReactComponent as DiscoverLogo } from 'static/ic_logo_discover.svg'

const useStyles = makeStyles({
  bodySubtitle: {
    fontSize: 15,
    fontWeight: 'normal',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  infoContainer: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'column',
  },
  iconStyle: {
    height: 20,
    width: 30,
    marginRight: 8,
  },
  selectStyle: {
    marginTop: 5,
    alignItems: 'center',
    color: colors.BASIC.COLOR[100],
  },
  title: {
    fontSize: 15,
  },
  hint: {
    color: colors.ERROR,
    marginTop: 8,
  },
})

const getCardIcon = (cardType, style) => {
  switch (cardType.toLowerCase()) {
    case 'visa':
      return <VisaLogo className={style} />
    case 'mastercard':
      return <MCLogo className={style} />
    case 'amex':
      return <AMLogo className={style} />
    case 'jcb':
      return <JCBLogo className={style} />
    case 'discover':
      return <DiscoverLogo className={style} />
    case 'diners':
      return <DCLogo className={style} />
    default:
      return <div />
  }
}

const PaymentInfo = ({
  clientPaymentSecretKey,
  clientSecretIsLoading,
  userCards,
  paymentCard,
  setPaymentCard,
  stripeError,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isModalShown, setIsModalShown] = useState(false)
  const [userCardData, setUserCardData] = useState({})
  const userCardsOptions = userCards.map(card => ({
    label: `${card.label} **** ${card.lastDigits}`,
    value: card.uuid,
    icon: getCardIcon(card.brand, classes.iconStyle),
  }))

  useEffect(() => {
    if (userCardData.payment_method_id) {
      const newCard = userCards.find(card => userCardData.payment_method_id === card.paymentMethodId)
      if (newCard) setPaymentCard(newCard)
    }
  }, [userCards, userCardData])
  const onCardChange = uuid => {
    const card = userCards.find(card => card.uuid === uuid)
    setPaymentCard(card)
  }
  const onModalSubmit = async data => {
    try {
      const { payment_method_id } = await dispatch(saveUserCard(data))
      await dispatch(getUserCards(payment_method_id))
      setUserCardData(data)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setIsModalShown(false)
    }
  }
  const onAddPayment = () => {
    dispatch(getClientPaymentSecret())
    setIsModalShown(true)
  }
  return (
    <div className={classes.infoContainer}>
      <div className={classes.priceContainer}>
        <Typography className={classes.title}>pay with</Typography>
        <Link onClick={onAddPayment}>add new card</Link>
      </div>
      <Select
        variant="outlined"
        options={userCardsOptions}
        onChange={e => onCardChange(e.target.value)}
        value={paymentCard.uuid}
        listItemIconClasses={classes.selectStyle}
      />
      {stripeError && (
        <Hint classes={{ subtitle2: classes.hint }}>
          <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
          {stripeError}
        </Hint>
      )}
      <ConfigureCardModal
        isOpen={isModalShown}
        onClose={() => setIsModalShown(false)}
        onSave={onModalSubmit}
        mode={CONSTANTS.MODE.ADD}
        clientPaymentSecretKey={clientPaymentSecretKey}
        clientSecretIsLoading={clientSecretIsLoading}
        cardData={{ label: '', isDefault: false, remember: true }}
        showIsRememberOption={userCards.length}
      />
    </div>
  )
}

export default PaymentInfo
