import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link } from 'react-router-dom'
import { colors, CONSTANTS } from 'constants/index'
import { Breadcrumbs, CaseConfigurationForm, ColorCircularProgress, Typography } from 'components'
import { getProCaseFullInfo, getProSpecificPracticeAreas } from 'store/actions/cases'
import { getUserConnectionsForFilter } from 'store/actions/connections'

const useStyles = makeStyles({
  root: {
    padding: '24px 36px',
  },
  link: {
    color: colors.BASIC.COLOR[20],
    textDecoration: 'none',
  },
  standardMargin: {
    marginBottom: 16,
  },
})

const ProViewCaseContainer = props => {
  const classes = useStyles()
  const { match } = props
  const dispatch = useDispatch()
  const caseData = useSelector(state => state.cases.caseFullInfo)
  const caseFullInfoIsLoading = useSelector(state => state.cases.caseFullInfoIsLoading)

  useEffect(() => {
    dispatch(getUserConnectionsForFilter())
    dispatch(getProSpecificPracticeAreas())
  }, [])

  useEffect(() => {
    dispatch(getProCaseFullInfo(match.params.caseId))
  }, [match.params.caseId])

  return (
    <div className={classes.root}>
      {caseFullInfoIsLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          <Breadcrumbs className={classes.standardMargin}>
            <Link className={classes.link} to="/pro/account/cases">
              cases
            </Link>
            <Typography variant="body1">{caseData && caseData.name}</Typography>
          </Breadcrumbs>
          <Typography variant="h3" className={classes.standardMargin}>
            Case details
          </Typography>
          {caseData && <CaseConfigurationForm caseData={caseData} mode={CONSTANTS.MODE.VIEW} />}
        </>
      )}
    </div>
  )
}

export default ProViewCaseContainer
