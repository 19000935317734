import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button, FormikCheckbox, FormikInput, PasswordChecker } from 'components'
import { connect, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { registerUser } from '../../../store/actions/auth'
import { colors } from '../../../constants'
import TermsOfServiceLink from './termsOfServiceLink'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

const useStyles = makeStyles({
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: 368,
  },
  checkBox: {
    color: colors.BASIC.COLOR[50],
    padding: 0,
    marginRight: 8,
  },
  checkBoxLabelText: {
    cursor: 'pointer',
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
    lineHeight: 1.6,
    marginTop: 5,
  },
  labelClassesControl: {
    margin: '0 0 0 -2px',
  },
  '@media(max-width:767px)': {
    signUpForm: {
      width: '100%',
    },
    buttonResponsive: {
      width: '100%',
    },
  },
})

const EmailSignUp = props => {
  const classes = useStyles()
  const {
    formBodyClass,
    submitButtonText,
    termsText,
    isInvite,
    registerUser,
    firstName,
    lastName,
    userType,
    onAfterSignUp,
  } = props
  const email = useSelector(state => state.auth.signUpEmail)

  const onSubmitForm = async formData => {
    try {
      const data = await registerUser({ ...formData, userType }, isInvite)
      Analytics.register({
        $email: email,
        user_type: userType === 'pro' ? 'lawyer' : 'client',
        auth_type: 'email',
        uuid: data.uuid,
      })
      if (onAfterSignUp) {
        onAfterSignUp(formData)
      }
    } catch (e) {
      Analytics.track(EVENTS.REGISTRATION, {
        $email: email,
        user_type: props.userType,
        auth_type: 'email',
        error: e,
      })
    }
  }

  const [acceptTerms, setAcceptTerms] = useState(false)
  const isAuthByEmail = useSelector(state => state.auth.isAuthByEmail)

  const checkBoxClasses = {
    root: classes.checkBox,
  }

  const labelTextClasses = {
    root: classes.checkBoxLabelText,
  }
  const labelClassesControl = {
    root: classes.labelClassesControl,
  }

  const buttonResponsive = {
    root: classes.buttonResponsive,
  }

  return (
    <Formik
      initialValues={{
        firstName: firstName || '',
        lastName: lastName || '',
        password: '',
        confirmPassword: '',
        acceptTerms: false,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .required('please type your first name')
          .trim()
          .max(50, 'First Name should be maximum 50 characters'),
        lastName: Yup.string()
          .required('please type your last name')
          .trim()
          .max(50, 'Last Name should be maximum 50 characters'),
        password: Yup.string()
          .required('please type your password')
          .max(50, 'Password should be maximum 50 characters')
          .test('regex', 'please enter a valid password', val => {
            const regExp = new RegExp('^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$')
            return regExp.test(val)
          })
          .test('regex', 'please avoid using spaces', val => {
            return !/\s/g.test(val)
          }),
        confirmPassword: Yup.string()
          .trim()
          .required('please type your password')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        acceptTerms: Yup.boolean()
          .required('Required')
          .oneOf([true], 'You must accept the terms and conditions.'),
      })}
      validateOnBlur
      validateOnChange={false}
      onSubmit={(values, actions) => {
        onSubmitForm(values)
      }}
    >
      {props => (
        <Form className={classNames({ [classes.signUpForm]: true, [formBodyClass]: formBodyClass })}>
          <FormikInput variant="outlined" size="responsive" name="firstName" label="First Name" type="text" />
          <FormikInput variant="outlined" size="responsive" name="lastName" label="Last Name" type="text" />
          <FormikInput variant="outlined" size="responsive" name="password" label="Password" type="password" />
          {(props.values.password || props.touched.password) && <PasswordChecker password={props.values.password} />}
          <FormikInput
            variant="outlined"
            size="responsive"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
          />
          <FormikCheckbox
            checked={acceptTerms || false}
            classes={checkBoxClasses}
            name="acceptTerms"
            labelClasses={labelTextClasses}
            labelClassesControl={labelClassesControl}
            label="I accept XIRA"
            link={<TermsOfServiceLink />}
            onClick={e => setAcceptTerms(e.target.checked)}
          />
          <Button
            variant="primary"
            size="large"
            type="submit"
            isButtonBlocked={isAuthByEmail}
            disabled={isAuthByEmail}
            classes={buttonResponsive}
          >
            {submitButtonText || 'Continue'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const mapDispatchToProps = dispatch => ({
  registerUser: (formData, isInvite) => dispatch(registerUser(formData, isInvite)),
})

export default connect(null, mapDispatchToProps)(EmailSignUp)
