import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Icon } from 'components'
import classNames from 'classnames'
import { colors } from '../../constants/colors'

const STAR_FONT_SIZE = 20
const STAR_MARGIN = 8

const useStyles = makeStyles({
  icon: {
    fontSize: STAR_FONT_SIZE,
  },
  iconMargin: {
    '&:not(:last-child)': {
      marginRight: STAR_MARGIN,
    },
  },
  starWrapper: {
    color: colors.BASIC.COLOR[50],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  stars: {
    position: 'relative',
    margin: '0 20px',
    width: 132,
  },
  filledStars: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: colors.YELLOW_MAIN,
  },
})

const StarRating = ({ rating, stars }) => {
  const classes = useStyles()

  const getFilledStarsWidth = rate => {
    const innerPad = 4 // padding from the edge of the star component to the part to fill in
    const unitInterval = (STAR_FONT_SIZE - 2 * innerPad) / 10 // number of pixels to draw 0.1 of rate
    const integer = Number.parseInt(rate, 10)
    const rest = rate % 1

    return integer * (STAR_FONT_SIZE + STAR_MARGIN) + (innerPad + rest * unitInterval * 10)
  }

  return (
    <div className={classes.stars}>
      <Box component="span" mb={3} borderColor="transparent" className={classes.starWrapper}>
        {[...Array(stars)].map((item, index) => (
          <Icon key={index} iconClass="lar la-star" style={classNames(classes.iconMargin, classes.icon)} />
        ))}
      </Box>
      {!!rating && (
        <Box
          component="span"
          mb={3}
          borderColor="transparent"
          className={classNames(classes.starWrapper, classes.filledStars)}
          style={{ width: `${getFilledStarsWidth(rating)}px` }}
        >
          {[...Array(stars)].map((item, index) => (
            <Icon key={index} iconClass="las la-star" style={classNames(classes.iconMargin, classes.icon)} />
          ))}
        </Box>
      )}
    </div>
  )
}

export default StarRating
