import React, { useState, useEffect } from 'react'
import { Modal } from 'components'
import { toggleSignUp, toggleLogin, setRedirectPath } from 'store/actions/common'
import { setAuthRole } from 'store/actions/auth'
import SignUp from 'components/auth/signUp/signUp'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '568px !important',
    },
  },
})

const SignUpDialog = props => {
  const dispatch = useDispatch()
  const showSignUp = useSelector(state => state.common.showSignUp)
  const [open, setOpen] = useState(showSignUp)
  const classes = useStyles()
  const closeModal = () => {
    dispatch(setAuthRole(''))
    dispatch(setRedirectPath(''))
    dispatch(toggleSignUp(false))
  }

  const goToLogin = redirect => {
    dispatch(toggleSignUp(false))
    dispatch(toggleLogin(true))
  }

  useEffect(() => {
    setOpen(showSignUp)
  }, [showSignUp])

  return (
    <Modal open={open} handleClose={closeModal} scroll="body" dialogClasses={{ paper: classes.paper }}>
      <SignUp goToLogin={goToLogin} />
    </Modal>
  )
}

export default SignUpDialog
