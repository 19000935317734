import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { FormikAutocomplete } from 'components'
import parse from 'autosuggest-highlight/parse'
import { colors, CONSTANTS } from '../../constants'

const useStyles = makeStyles({
  title: {
    fontSize: 16,
    marginBottom: 17,
  },
  stepButtons: {
    display: 'flex',
    marginTop: '40px',
    justifyContent: 'space-between',
  },
})

const AutocompleteOption = props => {
  const { name, value, children, type } = props
  const classes = useStyles()

  const optionSplit = /\W/.test(value)
    ? value.toLowerCase()
    : name
        .split(/\W/)
        .filter(world => world.toLowerCase().startsWith(value.toLowerCase()))
        .find(opt => !CONSTANTS.WORLDS_EXCLUDE_IN_SEARCH.includes(opt.toLowerCase()))
  const index = optionSplit ? name.toLowerCase().indexOf(optionSplit.toLowerCase()) : -1
  const matches = index === -1 ? [[0, 0]] : [[index, index + value.length]]
  const parts = parse(name, matches)

  return (
    <li
      className={
        type === 'group' ? classNames({ [classes.group]: true, [classes.hideGroup]: value !== '' }) : classes.option
      }
    >
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : type === 'group' ? 500 : 400 }}>
          {value !== '' && type === 'group' ? '' : part.text}
        </span>
      ))}
      {children}
    </li>
  )
}

const filterPracticeAreas = (options, { inputValue }) => {
  return /\W/.test(inputValue)
    ? options.filter(option => option.name.toLowerCase().startsWith(inputValue.toLowerCase()))
    : options
        .map(option => {
          let index = 100
          option.name.split(/\W/).forEach((opt, i) => {
            if (
              opt.toLowerCase().startsWith(inputValue.toLowerCase()) &&
              !CONSTANTS.WORLDS_EXCLUDE_IN_SEARCH.includes(opt.toLowerCase()) &&
              index === 100
            ) {
              index = i
            }
          })
          return { ...option, wordIndex: index }
        })
        .filter(option => option.wordIndex < 100)
        .sort((optionA, optionB) => {
          if (optionA.wordIndex > optionB.wordIndex) {
            return 1
          }
          if (optionA.wordIndex < optionB.wordIndex) {
            return -1
          }
          return 0
        })
}

const LegalTopicSelector = ({ options, onChange }) => {
  return (
    <FormikAutocomplete
      variant="outlined"
      size="small"
      name="topic"
      label="Area of law or legal topic"
      type="text"
      fullWidth
      groupBy={option => option.practiceArea}
      renderGroup={option => (
        <AutocompleteOption name={option.group} value="" type="group">
          {option.children}
        </AutocompleteOption>
      )}
      filterOptions={filterPracticeAreas}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.name}
      options={options}
      handleChangeValue={onChange}
    />
  )
}

export default LegalTopicSelector
