export const GET_PRO_ACCOUNT_START = 'GET_PRO_ACCOUNT_START'
export const GET_PRO_ACCOUNT = 'GET_PRO_ACCOUNT'
export const GET_PRO_ACCOUNT_AVAILABLE_TIME_START = 'GET_PRO_ACCOUNT_AVAILABLE_TIME_START'
export const GET_PRO_ACCOUNT_AVAILABLE_TIME = 'GET_PRO_ACCOUNT_AVAILABLE_TIME'
export const GET_PRO_ACCOUNT_AVAILABLE_TIME_END = 'GET_PRO_ACCOUNT_AVAILABLE_TIME_END'
export const GET_PRO_ACCOUNT_END = 'GET_PRO_ACCOUNT_END'
export const GET_PRO_ACCOUNT_ERROR = 'GET_PRO_ACCOUNT_ERROR'
export const SET_AUTOCOMPLETE_PRACTICE_ARRAY = 'SET_AUTOCOMPLETE_PRACTICE_ARRAY'
export const GET_PROFILE_LIST_START = 'GET_PROFILE_LIST_START'
export const GET_PROFILE_LIST_LAZY_LOAD_START = 'GET_PROFILE_LIST_LAZY_LOAD_START'
export const GET_PROFILE_LIST_END = 'GET_PROFILE_LIST_END'
export const RESET_UPLOAD_OFFSET = 'RESET_UPLOAD_OFFSET'
export const INCREASE_UPLOAD_OFFSET = 'INCREASE_UPLOAD_OFFSET'
export const SET_FILTER = 'SET_FILTER'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_SORTER = 'SET_SORTER'
export const GOT_AVERAGE_PRICE = 'GOT_AVERAGE_PRICE'

export const SET_PRACTICE_AREA_VALUE = 'SET_PRACTICE_AREA_VALUE'
export const SET_PRACTICE_SEARCH_TYPE = 'SET_PRACTICE_SEARCH_TYPE'
export const SET_PRACTICE_SEARCH_NAME = 'SET_PRACTICE_SEARCH_NAME'
export const SET_PRACTICE_AREA_PARAM = 'SET_PRACTICE_AREA_PARAM'
export const SET_PRACTICE_AREA_SEARCH_OBJECT = 'SET_PRACTICE_AREA_SEARCH_OBJECT'
export const GET_PRACTICE_AREA_MATCHES_START = 'GET_PRACTICE_AREA_MATCHES_START'
export const GET_PRACTICE_AREA_MATCHES_END = 'GET_PRACTICE_AREA_MATCHES_END'
export const SET_COUNTRY_STATE_VALUE = 'SET_COUNTRY_STATE_VALUE'
export const SET_AUTOCOMPLETE_ARRAY = 'SET_AUTOCOMPLETE_ARRAY'
export const SET_SEARCH_LIST_TITLE = 'SET_SEARCH_LIST_TITLE'
export const SET_MEETING_DURATION = 'SET_MEETING_DURATION'
export const SET_DEFAULT_SEARCH_PARAMS = 'SET_DEFAULT_SEARCH_PARAMS'
export const SET_RANDOM_SEED = 'SET_RANDOM_SEED'
export const SET_DEFAULT_SEARCH_FILTER = 'SET_DEFAULT_SEARCH_FILTER'
export const SET_CLIENT_QUESTION = 'SET_CLIENT_QUESTION'
export const SET_ANSWER = 'SET_ANSWER'
export const SET_IS_LOADING = 'SET_IS_LOADING'
