import React from 'react'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import background from 'static/oldBrowserBG.png'
import safari from 'static/Safari.svg'
import chrome from 'static/Chrome.svg'
import firefox from 'static/Firefox_logo.svg'
import opera from 'static/opera_logo.svg'
import edge from 'static/edge_logo.svg'
import logo from 'static/xira-L-W-A-360.gif'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  leftBlock: {
    width: 'calc(100% - 35%)',
    height: '100%',
    backgroundColor: colors.WHITE,
    padding: '135px 50px 200px 100px ',
    position: 'relative',
  },
  oldBrowserWrapper: {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right',
  },
  title: {
    marginBottom: 24,
    fontSize: 25,
  },
  text: {
    fontSize: 15,
    marginBottom: 24,
  },
  supportedBrowser: {
    width: 115,
    textAlign: 'center',
    cursor: 'pointer',
  },
  browserName: {
    margin: '5px 0',
  },
  browserVersion: {
    color: colors.BASIC.COLOR[70],
    fontSize: 13,
  },
  browserVersionsWrapper: {
    width: 425,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '26px 0 48px',
  },
  browserImage: {
    width: 50,
    height: 50,
  },
  link: {
    color: colors.BLUE_20,
  },
  bottomText: {
    color: colors.WHITE,
    fontFamily: "'Montserrat', sans-serif",
    marginTop: 120,
    fontWeight: 500,
    fontSize: 16,
    position: 'absolute',
    right: -144,
    top: -90,
  },
  logo: {
    position: 'absolute',
    height: 31,
    top: 24,
    left: 100,
  },
})

const supportedBrowsers = [
  { icon: chrome, name: 'Google Chrome', version: 30, link: 'https://www.google.com/chrome/' },
  { icon: firefox, name: 'Firefox', version: 30, link: 'https://www.mozilla.org/ru/firefox/new/' },
  { icon: safari, name: 'Safari', version: 7, link: 'https://support.apple.com/downloads/safari' },
  { icon: opera, name: 'Opera', version: 15, link: 'https://www.opera.com/download' },
  { icon: edge, name: 'Edge', version: 80, link: 'https://www.microsoft.com/en-us/edge' },
]

const OldBrowserWarning = props => {
  const classes = useStyles()

  const goToLink = link => window.open(link, '_blank')

  return (
    <div className={classes.oldBrowserWrapper}>
      <div className={classes.leftBlock}>
        <img src={logo} className={classes.logo} />
        <Typography variant="h3" className={classes.title}>
          Please use one of the supported browsers listed below
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Download one of these browsers and you'll be on your way:
        </Typography>
        <div className={classes.browserVersionsWrapper}>
          {supportedBrowsers.map((i, key) => {
            return (
              <div key={key} className={classes.supportedBrowser} onClick={() => goToLink(i.link)}>
                <img src={i.icon} className={classes.browserImage} />
                <Typography variant="body1" className={classes.browserName}>
                  {i.name}
                </Typography>
                <Typography variant="body2" className={classes.browserVersion}>
                  Version {i.version}+
                </Typography>
              </div>
            )
          })}
        </div>
        <Typography variant="body2" className={classes.browserName}>
          Already upgraded but still having problems? Contact us:{' '}
          <a href="mailto:support@xira.com" className={classes.link}>
            support@xira.com
          </a>
        </Typography>
        <div className={classes.bottomText}>law made easy</div>
      </div>
    </div>
  )
}

export default OldBrowserWarning
