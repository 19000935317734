import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openSnackbar } from 'store/actions/common'
import { Button, ColorCircularProgress, NoMessagesState } from 'components'
import {
  addMessageToList,
  addPreviousMessagesToList,
  checkIfUserHasUnreadMessages,
  setUserMessages,
  updateChatMessage,
} from 'store/actions/chat/chat'
import { makeStyles } from '@material-ui/core/styles'
import MessageInput from './MessageInput'
import TypingIndicator from './TypingIndicator'
import MessageGroupper from './MessageGroupper'
import { reactChatNotificationsById } from 'store/actions/notifications/notifications'

const useStyles = makeStyles({
  chat: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  chatBody: {
    overflowY: 'auto',
    height: '100%',
    padding: '0 24px',
  },
  indicator: {
    padding: '0 24px 10px 24px',
    height: 50,
  },
  button: {
    width: '100%',
  },
})

const getBrowserTabVisibility = (() => {
  let stateKey
  let eventKey
  const keys = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  }
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey]
      break
    }
  }
  return c => {
    if (c) document.addEventListener(eventKey, c)
    return !document[stateKey]
  }
})()

const Chat = props => {
  const { selectedChat, size = 'big' } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const chatBottom = useRef()
  const messageList = useSelector(state => state.chat.messages)
  const currentUser = useSelector(state => state.auth.currentUser)
  const client = useSelector(state => state.chat.chatClient)
  const [channel, setChannel] = useState(null)
  const [lastConsumedMessage, setLastConsumedMesage] = useState(null)
  const [newMessagesIndex, setNewMessagesIndex] = useState(null)
  const [messagePaginator, setMessagePaginator] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [messageSent, setMessageSent] = useState(false)

  useEffect(() => {
    return () => {
      if (channel) {
        channel.off('messageAdded', onMessageAdded)
        channel.off('messageUpdated', onMessageUpdated)
      }
    }
  }, [channel])

  useEffect(() => {
    onChangeChat()
  }, [selectedChat.twilio_chat_sid, client])

  const onChangeChat = async () => {
    if (client && selectedChat.twilio_chat_sid) {
      try {
        setIsLoading(true)
        const openedChannel = await client.channels.getChannel(selectedChat.twilio_chat_sid)
        setChannel(openedChannel)
        const members = await openedChannel.getMembers()
        const userIdentity = members.find(member => member.identity === currentUser.twilio_identity)
        const opponentIdentity = members.find(member => member.identity === selectedChat.chat_member_twilio_identity)
        let newMessageIndex = 0
        let lasMessageIndex = 0
        if (openedChannel.lastMessage) {
          lasMessageIndex = openedChannel.lastMessage.index
        }
        if (opponentIdentity) {
          setLastConsumedMesage(opponentIdentity.lastConsumedMessageIndex)
        }
        if (userIdentity) {
          newMessageIndex = userIdentity.lastConsumedMessageIndex
        }
        setNewMessagesIndex(newMessageIndex)
        const recentMessages = await openedChannel.getMessages(lasMessageIndex - newMessageIndex + 30)
        setMessagePaginator(recentMessages)
        dispatch(setUserMessages(recentMessages.items))
        await openedChannel.setAllMessagesConsumed()
        dispatch(checkIfUserHasUnreadMessages())
        setIsLoading(false)
        scrollToBottom()
        dispatch(reactChatNotificationsById(selectedChat.twilio_chat_sid))
      } catch (e) {
        setIsLoading(false)
        console.error(e)
        dispatch(openSnackbar('error', 'Could not join a channel'))
      }
    }
  }

  useEffect(() => {
    if (channel) {
      channel.on('messageUpdated', onMessageUpdated)
      channel.on('messageAdded', onMessageAdded)
      channel.on('memberUpdated', ({ member }) => {
        if (member.identity === selectedChat.chat_member_twilio_identity) {
          setLastConsumedMesage(member.lastConsumedMessageIndex)
        }
      })
    }
  }, [channel])

  const onMessageAdded = async message => {
    dispatch(addMessageToList(message))
    setNewMessagesIndex(null)
    if (getBrowserTabVisibility()) {
      channel.setAllMessagesConsumed()
    }
    scrollToBottom()
  }

  const onMessageUpdated = async message => {
    dispatch(updateChatMessage(message))
  }

  const scrollToBottom = () => {
    if (chatBottom.current) {
      chatBottom.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const onLoadPreviousMessages = async () => {
    const prevMessages = await messagePaginator.prevPage()
    dispatch(addPreviousMessagesToList(prevMessages.items))
    setMessagePaginator(prevMessages)
  }

  return selectedChat.twilio_chat_sid ? (
    <div className={classes.chat}>
      <div className={classes.chatBody}>
        {isLoading ? (
          <ColorCircularProgress />
        ) : !messageList.length ? (
          <NoMessagesState text="No messages here yet" showImage={size !== 'small'} />
        ) : (
          <>
            {messagePaginator && messagePaginator.hasPrevPage && (
              <Button variant="text" className={classes.button} onClick={onLoadPreviousMessages}>
                load previous messages
              </Button>
            )}
            <MessageGroupper
              messages={messageList}
              chatInfo={selectedChat}
              currentUser={currentUser}
              lastConsumedMessage={lastConsumedMessage}
              newMessagesIndex={newMessagesIndex}
              showAvatar={size !== 'small'}
            />
          </>
        )}
        <div ref={chatBottom} />
      </div>
      <div className={classes.indicator}>
        <TypingIndicator channel={channel} selectedChat={selectedChat} />
      </div>
      <MessageInput
        multiline
        channel={channel}
        fullSendButton={size === 'big'}
        selectedChat={selectedChat}
        messageSent={messageSent}
        setMessageSent={setMessageSent}
      />
    </div>
  ) : (
    <NoMessagesState text="please select a chat or search for a connection to start a new one" />
  )
}

export default Chat
