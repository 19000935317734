import * as CONSTANTS from 'store/constants/payments'
import axios from 'axios'
import { getClientSecretPath, getUserCardsPath, getUserCardPath } from 'utils/path-helpers/api'
import { mapPaymentCardToFront } from 'utils/mappers/backend'
import { openSnackbar } from 'store/actions/common'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

const getClientPaymentSecretStart = () => ({
  type: CONSTANTS.GET_CLIENT_PAYMENT_SECRET_START,
})

const getClientPaymentSecretEnd = payload => ({
  type: CONSTANTS.GET_CLIENT_PAYMENT_SECRET_END,
  payload,
})

export const getClientPaymentSecret = () => dispatch => {
  dispatch(getClientPaymentSecretStart())
  return axios
    .post(getClientSecretPath())
    .then(response => {
      dispatch(getClientPaymentSecretEnd(response.data.setup_intent_client_secret))
    })
    .catch(error => {
      dispatch(getClientPaymentSecretEnd(''))
      return Promise.reject(error)
    })
}

const getUserCardsStart = () => ({
  type: CONSTANTS.GET_USER_CARDS_START,
})

const getUserCardsEnd = payload => ({
  type: CONSTANTS.GET_USER_CARDS_END,
  payload,
})

export const getUserCards = hiddenCardPaymentId => dispatch => {
  dispatch(getUserCardsStart())
  return axios
    .get(getUserCardsPath(), {
      params: {
        include_payment_method_id: hiddenCardPaymentId,
      },
    })
    .then(response => {
      const cards = response.data.map(card => mapPaymentCardToFront(card))
      dispatch(getUserCardsEnd(cards))
      return cards
    })
    .catch(error => {
      dispatch(getUserCardsEnd([]))
      return Promise.reject(error)
    })
}

const saveUserCardStart = () => ({
  type: CONSTANTS.SAVE_USER_CARD_START,
})

const saveUserCardEnd = () => ({
  type: CONSTANTS.SAVE_USER_CARD_END,
})

export const saveUserCard = data => (dispatch, getState) => {
  dispatch(saveUserCardStart())
  return axios
    .post(getUserCardsPath(), data)
    .then(response => {
      dispatch(saveUserCardEnd())
      dispatch(openSnackbar('success', data.remember ? 'Card saved' : 'Card added'))
      Analytics.track(EVENTS.CLIENT_ADDED_CARD, {
        card: data.remember !== false || !!getState().payments.userCards.length,
      })
      return response.data
    })
    .catch(error => {
      const errorMessage =
        (error.response && error.response.data && error.response.data.detail) || 'Error while saving card'
      dispatch(openSnackbar('error', errorMessage))
      dispatch(saveUserCardEnd())
      return Promise.reject(error)
    })
}

const updateUserCardStart = () => ({
  type: CONSTANTS.UPDATE_USER_CARD_START,
})

const updateUserCardEnd = () => ({
  type: CONSTANTS.UPDATE_USER_CARD_END,
})

export const updateUserCard = (uuid, data) => dispatch => {
  dispatch(updateUserCardStart())
  return axios
    .patch(getUserCardPath(uuid), data)
    .then(() => {
      dispatch(updateUserCardEnd())
    })
    .catch(error => {
      dispatch(updateUserCardEnd())
      return Promise.reject(error)
    })
}

const deleteUserCardStart = () => ({
  type: CONSTANTS.DELETE_USER_CARD_START,
})

const deleteUserCardEnd = () => ({
  type: CONSTANTS.DELETE_USER_CARD_END,
})

export const deleteUserCard = uuid => (dispatch, getState) => {
  dispatch(deleteUserCardStart())
  return axios
    .delete(getUserCardPath(uuid))
    .then(() => {
      dispatch(deleteUserCardEnd())
    })
    .catch(error => {
      dispatch(deleteUserCardEnd())
      return Promise.reject(error)
    })
}

const getUserCardStart = () => ({
  type: CONSTANTS.GET_USER_CARD_START,
})

const getUserCardEnd = () => ({
  type: CONSTANTS.GET_USER_CARD_END,
})

export const getUserCard = uuid => dispatch => {
  dispatch(getUserCardStart())
  return axios
    .get(getUserCardPath(uuid))
    .then(response => {
      dispatch(getUserCardEnd())
      return mapPaymentCardToFront(response.data)
    })
    .catch(error => {
      dispatch(getUserCardEnd([]))
      return Promise.reject(error)
    })
}
