export const REGEXP = {
  time: /(^([0-9][0-9]?[h])(\s)?([0-5][0-9][m])?$)|(^100[h]$)|(^[0-9][0-9]?[.][0-9][h]$)/,
  decimal: /^\d{1,5}([.]\d{1,2})?$/,
  possibleNegativeDecimal: /^-?\d{1,5}([.]\d{1,2})?$/,
  filename: /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/,
  hour: /\d+(.)?(\d+)?(?=h)/,
  minute: /\d+(?=m)/,
  fiveDecimalsDotTwoDecimals: /^\d{1,5}([.]\d{1,2})?$/,
  fiveDecimalsDotTwoDecimalsPracticeAreas: /^\d{1,5}[.]\d{0,2}?$/,
  fiveDecimals: /^\d{1,5}?$/,
  matchToSearchRoute: /\/(search)([/\-\d\w]+)?/,
  fileType: /\.[0-9a-z]+$/i,
  folderNameRestrictedChars: /([<>:\/|?*^.])/,
}
