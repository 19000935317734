import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Button, Typography } from 'components'
import { ReactComponent as CloseIcon } from 'static/chat/_close.svg'
import { useDispatch } from 'react-redux'
import { getHideHeaderBanner } from '../../store/actions/auth'

const DEFAULT_POSITION = 'sticky'
const useStyles = makeStyles({
  bar: {
    margin: 0,
    zIndex: 1201,
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
    height: 50,
    display: 'block',
  },
  leftPadding: {
    '@media (max-width:767px)': {
      paddingLeft: 35,
    },
  },
  withBanner: {
    height: 98,
  },
  headerContent: {
    height: 50,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  headerBanner: {
    padding: '0 80px',
    position: 'relative',
    width: '100%',
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(238, 95, 16, 0.08)',
  },
  headerBannerText: {
    maxWidth: 880,
  },
  contactAdmin: {
    marginLeft: 20,
    textDecoration: 'none',
    '& button': {
      padding: '10px 16px',
      minHeight: 32,
      lineHeight: '12px',
      fontSize: 11,
    },
  },
  headerBannerClose: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 28,
    cursor: 'pointer',

    '& path': {
      fill: '#596C80',
    },
  },
})

const HeaderBanner = ({ classes, onClose, text }) => (
  <div className={classes.headerBanner}>
    <Typography className={classes.headerBannerText}>{text}</Typography>
    <a
      className={classes.contactAdmin}
      href="https://member.xira.com/trialover"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button>learn more</Button>
    </a>
    <CloseIcon className={classes.headerBannerClose} onClick={onClose} />
  </div>
)

const XAppBar = ({ children, position = DEFAULT_POSITION, withMenuExpandSpace, showBanner, bannerText }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const onHideBanner = () => {
    dispatch(getHideHeaderBanner())
  }
  return (
    <AppBar
      position={position}
      className={classNames(classes.bar, {
        [classes.leftPadding]: withMenuExpandSpace,
        [classes.withBanner]: showBanner,
      })}
    >
      {showBanner && <HeaderBanner classes={classes} onClose={onHideBanner} text={bannerText} />}
      <div className={classes.headerContent}>{children}</div>
    </AppBar>
  )
}

export default XAppBar
