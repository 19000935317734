export const GET_INVOICES_START = 'GET_INVOICES_START'
export const GET_INVOICES_END = 'GET_INVOICES_END'

export const GET_INVOICE_SUMMARY_START = 'GET_INVOICE_SUMMARY_START'
export const GET_INVOICE_SUMMARY_END = 'GET_INVOICE_SUMMARY_END'
export const SET_SUMMARY_SORTING = 'SET_SUMMARY_SORTING'

export const GET_INVOICES_FILE_START = 'GET_INVOICES_FILE_START'
export const GET_INVOICES_FILE_END = 'GET_INVOICES_FILE_END'

export const GET_INVOICE_START = 'GET_INVOICE_START'
export const GET_INVOICE_END = 'GET_INVOICE_END'
export const SET_PREDEFINED_INVOICE = 'SET_PREDEFINED_INVOICE'
export const RESET_INVOICE = 'RESET_INVOICES'

export const SET_OFFSET = 'SET_INVOICES_OFFSET'
export const SET_LIMIT = 'SET_INVOICES_LIMIT'
export const SET_TABLE_CONFIGURATION = 'SET_INVOICES_TABLE_CONFIGURATION'

export const SET_FILTERS = 'SET_INVOICES_FILTERS'
export const RESET_FILTERS = 'RESET_INVOICES_FILTERS'
export const SET_SORTING = 'SET_INVOICES_SORTING'
export const RESET_SORTING = 'RESET_INVOICES_SORTING'

export const SET_CLIENT_PAYMENT_START = 'SET_CLIENT_PAYMENT_START'
export const SET_CLIENT_PAYMENT_END = 'SET_CLIENT_PAYMENT_END'

export const SET_INVOICES_FILE_START = 'SET_INVOICES_FILE_START'
export const SET_INVOICES_FILE_END = 'SET_INVOICES_FILE_END'
