import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { colors } from 'constants/index'
import { ReactComponent as FilterLogo } from 'static/activities/filter.svg'
import { Button, ExportButton, Link, TableSearch, Tooltip } from 'components'
import { getProInvoices, setTableConfiguration, getProInvoicesFile, setInvoicesFilter } from 'store/actions/invoices'
import { makeStyles } from '@material-ui/core'
import { useQuery } from 'utils/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as IconUpload } from 'static/icon_24_upload .svg'
import UploadInvoiceModal from './UploadInvoiceModal'
import { goToZendesk } from '../../store/actions/common'

const useStyles = makeStyles({
  filters: {
    display: 'flex',
  },
  addButton: {
    padding: '10px 24px',
    marginLeft: 24,
    height: 40,
    minHeight: 40,
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: 4,
      transform: 'rotate(180deg)',
      '& path': {
        fill: 'white',
      },
    },
  },
  upload: {
    marginLeft: 8,
  },
  filter: {
    marginLeft: 4,
    padding: 4,
    height: 40,
    minWidth: 40,
  },
  search: {
    marginLeft: 12,
    width: 395,
  },
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
  activeFilterBtn: {
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  tooltip: {
    padding: 16,
    maxWidth: 330,
    color: colors.BASIC.COLOR[100],
    fontWeight: 'normal',
    fontSize: 13,
  },
})

const InvoicesFilters = ({ invoices, invoicesAreLoading, filters, setShowAdditionalFilter, additionalFilter }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const caseId = query.get('case')
  const clientId = query.get('connection')
  const [search, setSearch] = useState(filters.search)
  const [openUploadInvoice, setOpenUploadInvoice] = useState(false)
  const profileIsApproved = useSelector(state => state.accountProfile.isProfileApproved)
  const profileIsHidden = useSelector(state => state.accountProfile.isProfileHidden)

  const changeOpenUploadInvoice = () => setOpenUploadInvoice(o => !o)

  const zendeskSso = () => {
    dispatch(goToZendesk())
  }

  useEffect(() => {
    if (search !== filters.search) setSearch(filters.search)
  }, [filters.search])

  useEffect(() => {
    if (clientId || caseId) {
      setShowAdditionalFilter(true)
      if (caseId) dispatch(setInvoicesFilter('case', caseId))
      if (clientId) dispatch(setInvoicesFilter('client', clientId))
      dispatch(getProInvoices())
    }
  }, [clientId, caseId, setShowAdditionalFilter, dispatch])

  return (
    <div className={classes.filters}>
      <TableSearch
        search={search}
        searchClass={classes.search}
        setSearch={setSearch}
        setFilter={value => dispatch(setInvoicesFilter('search', value))}
        setTableConfiguration={() => dispatch(setTableConfiguration({ tablePage: 0, offset: 0 }))}
        getSearchResults={() => dispatch(getProInvoices())}
      />
      <Button
        variant="secondary"
        className={classNames(classes.filter, { [classes.activeFilterBtn]: additionalFilter })}
        onClick={() => setShowAdditionalFilter(show => !show)}
      >
        <FilterLogo />
      </Button>
      <ExportButton
        variant="secondary"
        className={classes.addButton}
        getFileInfo={getProInvoicesFile}
        disabled={!invoices.length}
        isFileLoading={invoicesAreLoading}
      />
      {profileIsApproved ? (
        <Button
          variant="primary"
          className={classNames(classes.addButton, classes.upload)}
          onClick={changeOpenUploadInvoice}
          disabled={profileIsHidden}
        >
          <IconUpload />
          {' upload invoice'}
        </Button>
      ) : (
        <Tooltip
          title={
            <span>
              {'You can not upload invoices until your license is  approved. Please contact '}
              <Link onClick={zendeskSso}>XIRA</Link>
              {' admin for details.'}
            </span>
          }
          tooltipClass={classes.tooltip}
          interactive
        >
          <Button variant="primary" className={classNames(classes.addButton, classes.upload)} disabled>
            <IconUpload />
            {' upload invoice'}
          </Button>
        </Tooltip>
      )}
      <UploadInvoiceModal open={openUploadInvoice} onClose={changeOpenUploadInvoice} />
    </div>
  )
}

export default InvoicesFilters
