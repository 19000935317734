import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Icon, Tooltip } from 'components'
import { colors } from 'constants/index'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  hint: {
    height: 16,
    marginLeft: 5,
    color: colors.PRIMARY.COLOR[50],
    fontSize: 15,
    cursor: 'pointer',
    lineHeight: 1,
    transition: '.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  toolTip: {
    backgroundColor: colors.white,
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
  },
  span: {
    lineHeight: 1,
    fontSize: 14,
  },
})

const LabelWithHint = props => {
  const { label, hintText, toolTipClass, interactive } = props
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      {label}
      <Tooltip tooltipClass={toolTipClass || classes.tooltip} title={hintText} showChildren interactive={interactive}>
        <span className={classes.span}>
          <Icon iconClass="las la-question-circle" style={classes.hint} />
        </span>
      </Tooltip>
    </div>
  )
}

export default LabelWithHint
