import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors, CONSTANTS } from 'constants/index'
import { Typography, SubCalendarList, Button } from 'components'

const useStyles = makeStyles({
  title: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
    fontSize: 15,
  },
  company: {
    marginLeft: 8,
    fontWeight: 500,
    color: colors.BASIC.COLOR[80],
    fontSize: 13,
  },
  bodyText: {
    fontSize: 13,
    color: colors.BASIC.COLOR[50],
  },
  titleContainer: {
    display: 'flex',
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
    justifyContent: 'space-between',
    padding: 11,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  list: {
    padding: 16,
    margin: 0,
  },
  bodyContainer: {
    padding: 16,
    backgroundColor: colors.WHITE,
    borderBottom: `solid 1px ${colors.BASIC.COLOR[10]}`,
  },
  icon: {
    fontSize: 20,
  },
  emptyTitle: {
    fontSize: 15,
  },
  listItem: {
    marginBottom: 7,
  },
  subContainer: {
    display: 'flex',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: colors.WHITE,
    paddingRight: 16,
  },
})

const XIRACalendarCardBody = ({ XIRACalendar, onEditClick, calendarsConnectionError }) => {
  const classes = useStyles()
  const calendarSettings = CONSTANTS.CALENDAR_SETTINGS[XIRACalendar.provider]
  return (
    <>
      <div className={classes.titleContainer}>
        <div className={classes.subContainer}>
          <div>{calendarSettings.calendarLogo}</div>
          <div className={classes.columnContainer}>
            <Typography variant="body1" className={classes.company}>
              {calendarSettings.title}
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              {XIRACalendar.username}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <Typography variant="subtitle2" className={classes.listItem}>
          add to
        </Typography>
        <Typography variant="subtitle1">{XIRACalendar.name}</Typography>
      </div>
      <div className={classes.btnContainer}>
        <Button variant="text" onClick={onEditClick} disabled={calendarsConnectionError}>
          edit
        </Button>
      </div>
    </>
  )
}

export default XIRACalendarCardBody
