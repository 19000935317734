import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames/bind'
import { Icon, InputLabel, TextField, Autocomplete, Hint, SvgIcon } from 'components'
import { ReactComponent as ExternalLinkIcon } from 'static/ic_external_link.svg'
import { colors } from '../../constants'

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  column: {
    width: '47%',
  },
  input: {
    width: '100%',
    marginBottom: 4,
  },
  deleteIcon: {
    position: 'absolute',
    right: '-30px',
    top: 30,
    fontSize: 23,
    cursor: 'pointer',
    color: '#575757',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  hint: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: 8,
  },
  inputError: {
    borderColor: colors.ERROR,
  },
  adminLink: {
    marginLeft: 10,
    fontSize: 20,
    color: colors.BASIC.COLOR[80],
  },
  label: {
    display: 'flex',
  },
  notchedOutline: {
    borderColor: colors.BASIC.COLOR[50],
    '&$focused': {
      borderColor: colors.BASIC.COLOR[80],
    },
  },
  error: {
    borderColor: colors.ERROR,
  },
  externalLinkIcon: {
    width: 16,
    height: 16,
    marginBottom: 1,
  },
  bar: {
    marginRight: 8,
    lineHeight: '1.3',
  },
})

const LicensingRow = props => {
  const {
    state,
    states,
    barId,
    verificationLink,
    errors = {},
    onLicenseChange,
    onLicenseDelete,
    rowClass,
    id,
    isDisabled,
    isAdminLinkShown,
  } = props
  const classes = useStyles()

  const VerificationLink = () => (
    <a href={verificationLink} target="_blank" rel="noopener noreferrer">
      <SvgIcon component={ExternalLinkIcon} viewBox="0 0 16 16" className={classes.externalLinkIcon} />
    </a>
  )

  return (
    <div
      className={classNames({
        [classes.row]: true,
        [rowClass]: true,
      })}
    >
      <div className={classes.column}>
        <div className={classes.label}>
          <InputLabel classes={{ root: classes.bar }}>Bar/License Number</InputLabel>
          {isAdminLinkShown && verificationLink && <VerificationLink />}
        </div>
        <TextField
          className={classes.input}
          variant="outlined"
          InputProps={{
            classes: {
              notchedOutline: !!errors.barId ? classes.inputError : null,
            },
          }}
          value={barId}
          onChange={e => onLicenseChange(id, 'barId', e.target.value)}
          disabled={isDisabled}
        />
        {errors.barId && (
          <Hint classes={{ subtitle2: classes.hint }}>
            <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
            {errors.barId}
          </Hint>
        )}
      </div>
      <div className={classes.column}>
        <InputLabel>Issuing State</InputLabel>
        <Autocomplete
          className={classes.input}
          variant="outlined"
          value={state}
          onChange={(e, value) => onLicenseChange(id, 'state', value ? value.value : '')}
          filterOptions={(options, { inputValue }) =>
            states.filter(option => option.label.toLowerCase().startsWith(inputValue.toLowerCase()))
          }
          options={states}
          fullWidth
          disabled={isDisabled}
          InputProps={{
            classes: {
              notchedOutline: errors.state ? classes.error : classes.notchedOutline,
            },
          }}
        />
        {errors.state && (
          <Hint classes={{ subtitle2: classes.hint }}>
            <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
            {errors.state}
          </Hint>
        )}
      </div>
      {!isDisabled && (
        <Icon onClick={() => onLicenseDelete(id)} style={classes.deleteIcon} iconClass="las la-trash-alt" />
      )}
    </div>
  )
}

export default LicensingRow
