import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip, Icon } from 'components'
import { colors } from 'constants/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  title: {
    marginBottom: 10,
  },
  bodySubtitle: {
    fontSize: 15,
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoContainer: {
    display: 'flex',
    padding: '20px 0',
    flexDirection: 'column',
  },
  freeText: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.SUCCESS,
  },
  tooltip: {
    padding: 15,
    maxWidth: 440,
  },
  tooltipIcon: {
    fontSize: 17,
    color: colors.PRIMARY.COLOR[50],
    marginLeft: 5,
  },
  tooltipText: {
    marginTop: 15,
    fontSize: 13,
    '&:first-child': {
      marginTop: 0,
    },
  },
  link: {
    color: colors.BASIC.COLOR[100],
    fontSize: 13,
    transition: '0.2s',
    '&:hover': {
      color: colors.PRIMARY.COLOR[50],
    },
  },
  '@media(max-width:767px)': {
    bodySubtitle: {
      fontSize: 12,
    },
    freeText: {
      fontSize: 12,
      marginLeft: 16,
    },
    price: {
      marginLeft: 16,
    },
    infoContainer: {
      margin: '0 auto',
      width: '100%',
      maxWidth: 400,
      padding: '0 0 17px',
    },
    infoContainerProfile: {
      padding: '0 15px 23px 95px',
    },
    priceContainerProfile: {
      justifyContent: 'flex-start',
    },
  },
})

const TooltipContent = ({ tooltipText }) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={tooltipText} variant="subtitle1">
        A temporary credit card hold will be applied to your account to guarantee your attendance. The hold is either
        equal to the initial consultation fee indicated in the lawyer’s profile.
      </Typography>
      <Typography className={tooltipText} variant="subtitle1">
        You can cancel the initial consultation more than 24 hours in advance without incurring charges.
      </Typography>
      <Typography className={tooltipText} variant="subtitle1">
        {'Refer to '}
        <Link to="/client/booking-policy" target="_blank" className={classes.link}>
          XIRA&apos;s booking policy
        </Link>
      </Typography>
    </>
  )
}
const PriceSummary = ({ showTooltip, variant = '', initialPrice }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.infoContainer, { [classes.infoContainerProfile]: variant === 'profile' })}>
      <div className={classNames(classes.priceContainer, { [classes.priceContainerProfile]: variant === 'profile' })}>
        <div className={classes.priceContainer}>
          <Typography className={classes.bodySubtitle}>initial consultation</Typography>
          {showTooltip && (
            <Tooltip
              title={<TooltipContent tooltipText={classes.tooltipText} />}
              tooltipClass={classes.tooltip}
              interactive
            >
              <Icon style={classes.tooltipIcon} iconClass="las la-question-circle" />
            </Tooltip>
          )}
        </div>
        <Typography className={classNames({ [classes.freeText]: !initialPrice, [classes.price]: initialPrice })}>
          {initialPrice ? `$${Number(initialPrice).toFixed(2)}` : 'no charge'}
        </Typography>
      </div>
    </div>
  )
}

export default PriceSummary
