import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { colors, CONSTANTS, MESSAGES } from 'constants/index'
import classNames from 'classnames'
import { Typography, Rating, Link, Button, TextArea, Hint, Icon, ConfirmationModal, Tooltip } from 'components'
import { ReactComponent as ReplyIcon } from 'static/rating/reply.svg'
import { ReactComponent as EditIcon } from 'static/rating/edit.svg'
import { ReactComponent as DeleteIcon } from 'static/rating/delete.svg'
import moment from 'moment'
import { createProReply, deleteProReply, editProReply, proProCommentReviewed } from 'store/actions/proReview/proReview'

const useStyles = makeStyles({
  proCommentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 25,
    padding: 5,
    '-webkit-transition': 'background-color 1000ms linear',
    '-moz-transition': 'background-color 1000ms linear',
    '-o-transition': 'background-color 1000ms linear',
    '-ms-transition': 'background-color 1000ms linear',
    transition: 'background-color 1000ms linear',
  },
  proCommentRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width:767px) and (min-width:320px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  proCommentRatingRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  proCommentRowContainerProfile: {
    '@media(max-width:1279px) and (min-width:768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  proCommentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ratingContainer: {
    marginTop: 0,
  },
  ratingTitle: {
    color: colors.BASIC.COLOR['80'],
    fontSize: 16,
    marginLeft: 16,
  },
  commentClientNameTitle: {
    color: colors.BASIC.COLOR['80'],
    fontSize: 15,
    fontWeight: 500,
    marginRight: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media(max-width:1023px) and (min-width:320px)': {
      maxWidth: 250,
    },
  },
  commentClientNameTitleProfile: {
    maxWidth: 250,
    '@media(max-width:1279px) and (min-width:1024px)': {
      maxWidth: 400,
    },
  },
  commentDate: {
    color: colors.BASIC.COLOR['50'],
    fontSize: 13,
  },
  proCommentTextContainer: {
    paddingRight: '1em',
  },
  proCommentText: {
    fontSize: 15,
  },
  proResponseContainer: {
    marginTop: 16,
    backgroundColor: colors.ADDITIONAL.COLOR['0'],
    padding: 12,
    borderRadius: 4,
  },
  proResponse: {
    borderLeft: `2px solid ${colors.ADDITIONAL.COLOR['30']}`,
    padding: '2px 0 2px 12px',
  },
  commentResponseTitle: {
    color: colors.BASIC.COLOR['80'],
    fontSize: 13,
    marginBottom: 4,
    fontWeight: 500,
  },
  commentContainer: {
    marginTop: 8,
    fontFamily: 'san-serif, "Roboto"',
    fontSize: 15,
    lineHeight: 1.47,
    whiteSpace: 'pre-line',
  },
  commentContainerHidden: {
    fontFamily: 'san-serif, "Roboto"',
    fontSize: 15,
    overflow: 'hidden',
    position: 'relative',
    lineHeight: 1.47,
    maxHeight: 66,
    textAlign: 'justify',
    marginRight: '-1em',
    paddingRight: '1em',
  },
  link: {
    color: colors.BASIC.COLOR[80],
    cursor: 'pointer',
  },
  replyBtn: {
    backgroundColor: colors.PRIMARY.COLOR['0'],
    border: `1px solid ${colors.PRIMARY.COLOR['10']}`,
  },
  replyActionsBtn: {
    padding: 6,
    minWidth: 32,
  },
  replyBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  linkBtnContainer: {
    justifyContent: 'space-between',
  },
  commentHelpersRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  proReplyTextAreaContainer: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'column',
  },
  replyTitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 4,
  },
  error: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  icon: {
    marginRight: 8,
  },
  replyAreaBtn: {
    marginTop: 12,
    padding: '6px 16px',
    height: 32,
    minHeight: 32,
  },
  cancelReplyBtn: {
    color: colors.BASIC.COLOR['60'],
    backgroundColor: colors.BASIC.COLOR['10'],
    marginRight: 16,
    borderColor: colors.BASIC.COLOR['60'],
  },
  selectedComment: {
    backgroundColor: colors.ADDITIONAL.COLOR['0'],
  },
  reviewCommentTip: {
    color: colors.BASIC.COLOR['50'],
    fontSize: 13,
    marginTop: 4,
  },
  commentAuthor: {
    fontSize: 13,
    fontWeight: 'normal',
  },
})

const ProCommentContent = ({ text, showReplyBtn, onReply }) => {
  const classes = useStyles()
  const [isHidden, setIsHidden] = useState(false)
  const [isReadMoreShown, setIsReadMoreShown] = useState(false)
  const commentContainer = useRef(null)

  useEffect(() => {
    if (commentContainer && commentContainer.current && commentContainer.current.getBoundingClientRect().height > 66) {
      setIsHidden(true)
      setIsReadMoreShown(true)
    }
  }, [])

  return (
    <div className={classes.proCommentTextContainer}>
      <div
        ref={commentContainer}
        className={classNames({ [classes.commentContainerHidden]: isHidden }, classes.commentContainer)}
      >
        <p>{text}</p>
      </div>
      <div
        className={classNames(
          classes.commentHelpersRow,
          { [classes.replyBtnContainer]: !isReadMoreShown },
          { [classes.linkBtnContainer]: isReadMoreShown }
        )}
      >
        {isReadMoreShown && (
          <Link
            classes={{ root: classes.link }}
            underline="always"
            onClick={() => setIsHidden(prevState => !prevState)}
          >
            {isHidden ? 'read more' : 'read less'}
          </Link>
        )}
        {showReplyBtn && (
          <Button variant="text" onClick={onReply} className={classNames(classes.replyBtn, classes.replyActionsBtn)}>
            <ReplyIcon />
          </Button>
        )}
      </div>
    </div>
  )
}

const ProComment = ({ comment, isPro, isSelected, isProfile }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showReplyTextArea, setShowReplyTextArea] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const [deleteItem, setDeleteItem] = useState(null)
  const [showError, setError] = useState(false)
  const [proReply, setProReply] = useState('')
  const [selectedComment, setSelectedComment] = useState(null)
  const commentContainer = useRef()
  const commentReviewed = useSelector(state => state.proReview.commentReviewed)
  const isProReviewLoading = useSelector(state => state.proReview.isProReviewLoading)

  useEffect(() => {
    if (isSelected && !commentReviewed && !isProReviewLoading) {
      commentContainer.current.scrollIntoView({ block: 'center' })
      setSelectedComment(comment.uuid)
      setTimeout(() => {
        setSelectedComment(null)
        dispatch(proProCommentReviewed())
      }, 5000)
    }
  }, [isSelected, isProReviewLoading, commentReviewed, comment.uuid, dispatch])

  const handleReply = () => {
    setShowReplyTextArea(true)
  }

  const handleCancel = () => {
    setShowReplyTextArea(false)
    setProReply('')
    if (editItem) setEditItem(null)
  }

  const handleEdit = reply => {
    setShowReplyTextArea(true)
    setProReply(reply.content)
    setEditItem(reply)
  }

  const handleDelete = () => {
    setDeleteItem(null)
    dispatch(deleteProReply(deleteItem.uuid))
  }

  const handleConfirmReply = () => {
    if (!proReply) {
      setError(true)
      return
    }
    if (editItem) {
      dispatch(editProReply(editItem.uuid, proReply))
    } else {
      dispatch(createProReply(comment.uuid, proReply))
    }
    setShowReplyTextArea(false)
  }

  const handleReplyChange = e => {
    const value = e.target.value
    setProReply(value)
    if (value && setError) setError(false)
  }

  return (
    <div
      ref={commentContainer}
      className={classNames(classes.proCommentContainer, {
        [classes.selectedComment]: selectedComment === comment.uuid,
      })}
    >
      <div
        className={classNames(classes.proCommentRowContainer, { [classes.proCommentRowContainerProfile]: isProfile })}
      >
        <div className={classes.proCommentRatingRowContainer}>
          <Rating
            value={comment.rating}
            name="rating"
            readOnly
            ratingContainerClasses={classes.ratingContainer}
            precision={0.1}
          />
          <Typography variant="body2" className={classes.ratingTitle}>
            {comment.rating.toFixed(1)}
          </Typography>
        </div>
        <div className={classes.proCommentRow}>
          <Typography
            variant="body2"
            className={classNames(classes.commentClientNameTitle, {
              [classes.commentClientNameTitleProfile]: isProfile,
            })}
          >
            {`by ${comment.creator_short_name}`}
          </Typography>
          <Typography variant="body2" className={classes.commentDate}>
            {moment(comment.created_at).format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
          </Typography>
        </div>
      </div>
      <ProCommentContent text={comment.content} showReplyBtn={!comment.reply && isPro} onReply={handleReply} />
      {comment.reply && (
        <div className={classes.proResponseContainer}>
          <div className={classes.proResponse}>
            <div className={classes.proCommentRow}>
              <Typography variant="body2" className={classes.commentResponseTitle}>
                {`Response from ${comment.reply.creator_full_name}`}
              </Typography>
              <Typography variant="body2" className={classes.commentDate}>
                {moment(comment.reply.created_at).format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
              </Typography>
            </div>
            <ProCommentContent text={comment.reply.content} />
          </div>
          {isPro && (
            <div className={classes.replyBtnContainer}>
              <Button variant="text" onClick={() => handleEdit(comment.reply)} className={classes.replyActionsBtn}>
                <EditIcon />
              </Button>
              <Button variant="text" onClick={() => setDeleteItem(comment.reply)} className={classes.replyActionsBtn}>
                <DeleteIcon />
              </Button>
            </div>
          )}
        </div>
      )}
      {showReplyTextArea && (
        <div className={classes.proReplyTextAreaContainer}>
          <Typography className={classes.replyTitle}>Reply to this review</Typography>
          <TextArea
            handleChange={handleReplyChange}
            value={proReply}
            variant="outlined"
            placeholder="Place your text"
            rowsMin={2}
            characterLimit={250}
            showCharacterLimit={false}
          />
          {showError && (
            <Hint classes={{ subtitle2: classes.error }}>
              <Icon style={classes.icon} iconClass="las la-exclamation-triangle" />
              {MESSAGES.M0001}
            </Hint>
          )}
          <Typography variant="body2" className={classes.reviewCommentTip}>
            your replies will be visible to everyone
          </Typography>
          <div className={classes.replyBtnContainer}>
            <Button
              variant="secondary"
              onClick={handleCancel}
              className={classNames(classes.replyAreaBtn, classes.cancelReplyBtn)}
            >
              cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmReply} className={classes.replyAreaBtn}>
              send reply
            </Button>
          </div>
        </div>
      )}
      <ConfirmationModal
        title="Delete reply"
        message="Reply will be permanently deleted from the system. Continue?"
        confirmationButtonText="yes, delete"
        isOpen={!!deleteItem}
        onClose={() => setDeleteItem('')}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export default ProComment
