import React from 'react'
import { IconButton, Tooltip } from 'components'
import { colors } from 'constants/index'
import { ReactComponent as TimeLogo } from 'static/connections/time.svg'
import { ReactComponent as TimeDisabledLogo } from 'static/connections/timer_disabled.svg'
import { ReactComponent as ExpenseLogo } from 'static/connections/expense.svg'
import { ReactComponent as ExpenseDisabledLogo } from 'static/connections/expense_disabled.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  connectionRowBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconBtn: {
    borderRadius: 4,
    backgroundColor: colors.BASIC.COLOR[10],
    marginRight: 12,
    '&:disabled': {
      backgroundColor: colors.BASIC.COLOR[5],
    },
  },
  tooltip: {
    backgroundColor: colors.BASIC.COLOR[80],
    color: colors.WHITE,
    fontSize: 13,
    borderRadius: '4px',
    boxShadow: '2px 4px 20px 0 rgba(0, 0, 0, 0.1)',
  },
})

const ProConnectionsRowButtons = ({
  hasActiveCases,
  onTimeClick,
  onExpenseClick,
  enableExpenseActivities,
  enableTimeActivities,
  profileIsApproved,
}) => {
  const classes = useStyles()
  const BUTTON_PROPERTIES_BY_TYPE = {
    time: {
      activeTooltip: 'create time entry',
      disabledTooltip: 'You do not have active T&E cases with the client to create time entries.',
      profileUnderApprovalTooltip:
        'You can not create activity until your license is approved. Please contact XIRA admin for details',
      iconActive: <TimeLogo />,
      iconInactive: <TimeDisabledLogo />,
      action: onTimeClick,
    },
    expense: {
      activeTooltip: 'create expense entry',
      disabledTooltip: 'You do not have active T&E, Flat fee and Contingency cases with expense invoicing with the client to create expense entries.',
      profileUnderApprovalTooltip:
        'You can not create activity until your license is approved. Please contact XIRA admin for details',
      iconActive: <ExpenseLogo />,
      iconInactive: <ExpenseDisabledLogo />,
      action: onExpenseClick,
    },
  }
  const getButton = (type, hasActiveCases, profileIsApproved, enableType) => {
    if (enableType && profileIsApproved) {
      return (
        <Tooltip tooltipClass={classes.tooltip} title={BUTTON_PROPERTIES_BY_TYPE[type].activeTooltip}>
          <IconButton classes={{ root: classes.iconBtn }} onClick={BUTTON_PROPERTIES_BY_TYPE[type].action}>
            {BUTTON_PROPERTIES_BY_TYPE[type].iconActive}
          </IconButton>
        </Tooltip>
      )
    }

    if (!profileIsApproved) {
      return (
        <Tooltip tooltipClass={classes.tooltip} title={BUTTON_PROPERTIES_BY_TYPE[type].profileUnderApprovalTooltip}>
          <IconButton classes={{ root: classes.iconBtn }}>{BUTTON_PROPERTIES_BY_TYPE[type].iconInactive}</IconButton>
        </Tooltip>
      )
    }

    if (!enableType) {
      return (
        <Tooltip tooltipClass={classes.tooltip} title={BUTTON_PROPERTIES_BY_TYPE[type].disabledTooltip}>
          <IconButton classes={{ root: classes.iconBtn }} disabled>
            {BUTTON_PROPERTIES_BY_TYPE[type].iconInactive}
          </IconButton>
        </Tooltip>
      )
    }
  }
  return (
    <div className={classes.connectionRowBtnContainer}>
      {getButton('time', hasActiveCases, profileIsApproved, enableTimeActivities)}
      {getButton('expense', hasActiveCases, profileIsApproved, enableExpenseActivities)}
    </div>
  )
}

export default ProConnectionsRowButtons
