import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Icon, Typography, ProProfileCreationStepper, VideoPopup, Button } from 'components'
import { colors } from 'constants/colors'
import {
  addLicensing,
  addPracticeAreas,
  changeProfileContactInfo,
  getCountries,
  getLicensing,
  getStates,
  getUserContactInfo,
  resetProfileCreationInfo,
  saveContactInfo,
  saveLicensing,
  savePhoto,
  savePracticeAreas,
  submitProfile,
} from 'store/actions/profileCreation'
import { navigateToProfileStep } from 'store/actions/navigation'
import { openSnackbar } from 'store/actions/common'
import { ReactComponent as WatchTutorialIcon } from 'static/profile/watch_tutorial.svg'
import ContactInfoStep from './contactInfoStep'
import LicensingStep from './LicensingStep'
import PracticeAreasStep from './practiceAreasStep'
import ProfilePictureStep from './profilePictureStep'
import CalendarConnectionStep from './CalendarConnectionStep'
import AdvancedCalendarSettingsStep from './AdvancedCalendarSettingsStep'
import { CONSTANTS } from '../../constants'
import { MobileVerificationModal } from '../../components/profileCreation/MobileVerificationModal'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // flex: '1 0 auto',
  },
  tabs: {
    width: 176,
    position: 'relative',
    '&::after': {
      content: '""',
      width: 4,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: colors.BASIC.COLOR[10],
      zIndex: -999,
    },
  },
  indicator: {
    backgroundColor: colors.PRIMARY.COLOR[50],
    width: 4,
  },
  steps: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: 24,
    paddingBottom: 60,
  },
  tab: {
    maxWidth: '100%',
    minHeight: 50,
    textTransform: 'lowercase',
    color: colors.BASIC.COLOR[50],
    fontSize: 15,
  },
  selectedTab: {
    color: colors.BASIC.COLOR[100],
  },
  tabText: {
    color: 'inherit',
  },
  wrapper: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  icon: {
    color: colors.SUCCESS,
    marginLeft: 10,
    fontSize: 20,
  },
  disabledTab: {
    opacity: '1 !important',
  },
  stepperHeaderContainer: {
    paddingLeft: 32,
    paddingBottom: 24,
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  stepButtons: {
    display: 'flex',
    height: 60,
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '8px 32px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: colors.WHITE,
    zIndex: 100,
  },
  tutorialBtn: {
    color: colors.WHITE,
    backgroundColor: colors.ADDITIONAL.COLOR[50],
    height: 32,
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: '#0080DB',
    },
  },
  tutorialContainer: {
    marginRight: 30,
  },
  tutorialIcon: {
    marginRight: 4,
  },
  tutorialDescription: {
    color: colors.BASIC.COLOR[70],
    fontSize: 13,
    marginTop: 4,
    textAlign: 'center',
  },
})

const ProfileCreationStepper = props => {
  const {
    currentStep,
    navToStep,
    profile,
    states,
    practiceAreas,
    getUserCountries,
    countries,
    addLicensing,
    changeContactInfo,
    saveProfileContactInfo,
    getCountryStates,
    saveProfileLicensing,
    saveProfilePicture,
    submitProfileData,
    getCurrentProfileContactInfo,
    getProfileLicenses,
    resetProfileInfo,
    licensingAreLoading,
    contactInfoIsLoading,
    pictureUrl,
    showSnackbar,
    executeScroll,
    arePracticeAreasLoading,
    isCalendarListLoading,
    isWorkingDaysLoading,
  } = props
  const [stepValidationStatus, setStepValidationStatus] = useState({
    contactInfoStep: currentStep > 1,
    licensingStep: currentStep > 2,
    practiceAreasStep: currentStep > 3,
    profilePictureStep: currentStep > 4,
    connectCalendarsStep: currentStep > 5,
    advancesSettingsStep: false,
  })
  const [isValidation, setIsValidation] = useState(null)
  const classes = useStyles()
  const [openedVideoId, setOpenedVideoId] = useState(null)
  const getIsTutorialBtnShown = step => {
    switch (step) {
      case 1:
        return !contactInfoIsLoading
      case 2:
        return !licensingAreLoading
      case 3:
        return !arePracticeAreasLoading
      case 5:
        return !isCalendarListLoading
      case 6:
        return !isWorkingDaysLoading
      default:
        return true
    }
  }
  const getProfileCreationBody = step => {
    switch (step) {
      case 1:
        return (
          <ContactInfoStep
            onTileClick={onTileClick}
            contactInfo={profile.contactInfo}
            handleValidationStatus={handleStepValidationStatusChange}
            contactInfoIsLoading={contactInfoIsLoading}
            goToNextStep={handleGoToNextStep}
            countries={countries}
            changeContactInfo={changeContactInfo}
            states={states}
            setValidated={() => handleStepValidationStatusChange('contactInfoStep', true)}
            saveProfileContactInfo={saveProfileContactInfo}
            getCurrentProfileContactInfo={getCurrentProfileContactInfo}
            isValidation={isValidation === 1}
            resetValidation={() => setIsValidation(null)}
          />
        )
      case 2:
        return (
          <LicensingStep
            onTileClick={onTileClick}
            licensing={profile.licensing}
            states={states}
            licensingAreLoading={licensingAreLoading}
            handleValidationStatus={handleStepValidationStatusChange}
            goToPreviousStep={handleGoToPreviousStep}
            goToNextStep={handleGoToNextStep}
            addLicensing={addLicensing}
            setValid={handleStepValidationStatusChange}
            saveProfileLicensing={saveProfileLicensing}
            getProfileLicenses={getProfileLicenses}
            showSnackbar={showSnackbar}
            isValidation={isValidation === 2}
            resetValidation={() => setIsValidation(null)}
          />
        )
      case 3:
        return (
          <PracticeAreasStep
            onTileClick={onTileClick}
            practiceAreas={practiceAreas}
            arePracticeAreasLoading={arePracticeAreasLoading}
            handleValidationStatus={handleStepValidationStatusChange}
            goToPreviousStep={handleGoToPreviousStep}
            goToNextStep={handleGoToNextStep}
            showSnackbar={showSnackbar}
            executeScroll={executeScroll}
            isValidation={isValidation === 3}
            resetValidation={() => setIsValidation(null)}
          />
        )
      case 4:
        return (
          <ProfilePictureStep
            onTileClick={onTileClick}
            profile={profile}
            handleValidationStatus={handleStepValidationStatusChange}
            goToPreviousStep={handleGoToPreviousStep}
            goToNextStep={handleGoToNextStep}
            saveProfilePicture={saveProfilePicture}
            pictureUrl={pictureUrl}
            isValidation={isValidation === 4}
            resetValidation={() => setIsValidation(null)}
          />
        )
      case 5:
        return (
          <CalendarConnectionStep
            onTileClick={onTileClick}
            handleValidationStatus={handleStepValidationStatusChange}
            goToPreviousStep={handleGoToPreviousStep}
            goToNextStep={handleGoToNextStep}
            isValidation={isValidation === 5}
            resetValidation={() => setIsValidation(null)}
          />
        )
      case 6:
        return (
          <AdvancedCalendarSettingsStep
            onTileClick={onTileClick}
            handleValidationStatus={handleStepValidationStatusChange}
            goToPreviousStep={handleGoToPreviousStep}
            goToNextStep={handleGoToNextStep}
            isValidation={isValidation === 6}
            resetValidation={() => setIsValidation(null)}
            submitProfileData={submitProfileData}
          />
        )
      default:
        return <div />
    }
  }
  const onTileClick = id => {
    setOpenedVideoId(id)
  }

  useEffect(() => {
    getUserCountries()

    return function resetStore() {
      resetProfileInfo()
    }
  }, [getCountryStates, getUserCountries, resetProfileInfo])

  useEffect(() => {
    setStepValidationStatus({
      contactInfoStep: currentStep > 1,
      licensingStep: currentStep > 2,
      practiceAreasStep: currentStep > 3,
      profilePictureStep: currentStep > 4,
      connectCalendarsStep: currentStep > 5,
      advancesSettingsStep: false,
    })
  }, [currentStep])

  const handleStepChange = (event, newValue) => {
    navToStep(newValue + 1)
  }

  const handleGoToPreviousStep = () => {
    navToStep(currentStep - 1)
  }

  const handleGoToNextStep = () => {
    navToStep(currentStep + 1)
  }

  const handleNextButtonClick = () => {
    switch (currentStep) {
      case 1: {
        setIsValidation(1)
        return
      }
      case 2: {
        setIsValidation(2)
        return
      }
      case 3: {
        setIsValidation(3)
        return
      }
      case 4: {
        setIsValidation(4)
        return
      }
      case 5: {
        setIsValidation(5)
        return
      }
      case 6: {
        setIsValidation(6)
        return
      }
      default:
        handleGoToNextStep()
    }
  }

  const handleStepValidationStatusChange = (name, value) => {
    setStepValidationStatus({
      ...stepValidationStatus,
      [name]: value,
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.stepperHeaderContainer}>
        <Typography classes={{ h3: classes.title }} variant="h3">
          Let&apos;s get you registered
        </Typography>
        <ProProfileCreationStepper activeStep={currentStep - 1} />
      </div>
      <div className={classes.steps}>
        {getProfileCreationBody(currentStep)}
        {getIsTutorialBtnShown(currentStep) && (
          <div className={classes.tutorialContainer}>
            <Button
              variant="primary"
              className={classes.tutorialBtn}
              onClick={() => setOpenedVideoId(CONSTANTS.PROFILE_CREATION_TUTORIAL_VIDEO[currentStep].id)}
            >
              <WatchTutorialIcon className={classes.tutorialIcon} /> watch tutorial
            </Button>
            <Typography variant={'subtitle1'} className={classes.tutorialDescription}>
              {`${currentStep} step – ${CONSTANTS.PROFILE_CREATION_TUTORIAL_VIDEO[currentStep].duration}s`}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.stepButtons}>
        <div>
          {currentStep !== 1 && (
            <Button
              type="submit"
              onClick={handleGoToPreviousStep}
              variant="secondary"
              startIcon={<Icon iconClass="las la-arrow-left" />}
            >
              Back
            </Button>
          )}
        </div>
        <div>
          <Button
            type="submit"
            onClick={handleNextButtonClick}
            variant="primary"
            endIcon={currentStep !== 6 ? <Icon iconClass="las la-arrow-right" /> : null}
          >
            {currentStep !== 6 ? 'Next' : 'Complete'}
          </Button>
        </div>
      </div>
      {Boolean(openedVideoId) && (
        <VideoPopup
          src={openedVideoId}
          close={() => {
            setOpenedVideoId(null)
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ profileCreation, auth, calendar }) => ({
  profile: profileCreation.profileData,
  states: profileCreation.states,
  licensingAreLoading: profileCreation.profileData.licensingAreLoading,
  contactInfoIsLoading: profileCreation.profileData.contactInfoIsLoading,
  arePracticeAreasLoading: profileCreation.arePracticeAreasLoading,
  isCalendarListLoading: calendar.isCalendarListLoading,
  isWorkingDaysLoading: calendar.isWorkingDaysLoading,
  practiceAreas: profileCreation.practiceAreas,
  countries: profileCreation.countries,
  pictureUrl: auth.currentUser.photo,
})

const mapDispatchToProps = dispatch => ({
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
  getUserCountries: () => dispatch(getCountries()),
  addLicensing: licensing => dispatch(addLicensing(licensing)),
  addProfilePracticeAreas: practiceAreas => dispatch(addPracticeAreas(practiceAreas)),
  changeContactInfo: data => dispatch(changeProfileContactInfo(data)),
  saveProfileContactInfo: data => dispatch(saveContactInfo(data)),
  saveProfilePracticeAreas: () => dispatch(savePracticeAreas()),
  getCountryStates: () => dispatch(getStates()),
  saveProfileLicensing: () => dispatch(saveLicensing()),
  saveProfilePicture: photo => dispatch(savePhoto(photo)),
  submitProfileData: () => dispatch(submitProfile()),
  navToStep: step => dispatch(navigateToProfileStep(step)),
  getCurrentProfileContactInfo: () => dispatch(getUserContactInfo()),
  getProfileLicenses: () => dispatch(getLicensing()),
  resetProfileInfo: () => dispatch(resetProfileCreationInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCreationStepper)
