import React from 'react'
import { Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { colors } from '../../../constants/colors'

const useStyles = makeStyles({
  filter: {
    padding: '15px 15px 15px 0',
    '&:not(:first-child)': {
      borderTop: `1px solid ${colors.BASIC.COLOR[5]}`,
    },
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtitle: {
    fontWeight: 500,
  },
  '@media(max-width: 939px)': {
    subtitle: {
      color: colors.BASIC.COLOR[50],
      fontWeight: 'normal',
      fontSize: 17,
    },
    filter: {
      padding: '15px 60px 13px 60px',
    },
  },
  '@media(max-width: 767px)': {
    filter: {
      padding: '8px 16px 10px 16px',
    },
  },
})

const FilterSection = ({ filterName, className, subtitleItem, children }) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.filter, className)}>
      <div className={classes.flexWrapper}>
        <Typography className={classes.subtitle} variant="subtitle1">
          {filterName}
        </Typography>
        {subtitleItem}
      </div>
      {children}
    </div>
  )
}

export default FilterSection
