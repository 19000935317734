import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import {
  Icon,
  Link,
  ProfileAvatarEditor,
  ProfileStatusSelector,
  Text,
  Tooltip,
  Typography,
  Rating,
  ProRatingTitle,
} from 'components'
import BlockedStatus from './BlockedStatus'
import { SettingsContainer } from 'containers'
import classNames from 'classnames'
import { savePhoto } from 'store/actions/profileCreation'
import {
  changeProfileVisibility,
  sendDeleteProfileRequest,
  cancelAccountDeletion,
  getDeletionRequestInfoEnd,
} from 'store/actions/accountProfile'
import { openSnackbar, goToZendesk } from 'store/actions/common'
import NoPhoto from 'static/search/pro_no photo.png'
import { colors } from 'constants/colors'

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  wrapper: {
    backgroundColor: colors.BASIC.COLOR['0'],
    border: `1px solid ${colors.BASIC.COLOR['10']}`,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
  },
  avatarWrapper: {
    padding: '32px 41px 0 41px',
  },
  avatar: {
    width: 168,
    height: 168,
    marginBottom: 30,
  },
  uppercaseText: {
    textTransform: 'uppercase',
  },
  icon: {
    marginLeft: 16,
    color: colors.BASIC.COLOR[70],
    fontSize: 16,
    cursor: 'pointer',
    '&:hover': {
      color: colors.PRIMARY.COLOR[60],
    },
  },
  tooltip: {
    padding: 10,
    width: 180,
  },
  userName: {
    flexWrap: 'noWrap',
    display: 'flex',
    alignItems: 'center',
  },
  userNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 16px 16px',
    borderBottom: `1px solid ${colors.BASIC.COLOR['10']}`,
    alignItems: 'center',
  },
  name: {
    wordWrap: 'break-word',
    maxWidth: 200,
  },
  status: {
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.BASIC.COLOR['10']}`,
  },
  approveLabel: {
    color: colors.PRIMARY.COLOR[50],
    display: 'flex',
    alignItems: 'center',
    textTransform: 'lowercase',
  },
  emailTitle: {
    marginBottom: 4,
    fontSize: 15,
  },
  userNameTitle: {
    fontSize: 15,
  },
  ratingContainer: {
    marginTop: 5,
    marginRight: 16,
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  settingsContainer: {
    padding: '28px 0 16px 16px',
  },
})

const NameTooltipContent = ({ goToZendesk }) => (
  <div>
    <Text>
      To change your name contact <Link onClick={() => goToZendesk(false, true)}>XIRA</Link>.
    </Text>
  </div>
)

class UserPersonalInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      picture: null,
      isSubmit: false,
    }
  }

  async onSavePicture(file) {
    try {
      this.setState({ isSubmit: true })
      await this.props.saveProfilePicture(file)
      this.setState({
        picture: window.URL.createObjectURL(file),
      })
      this.setState({ isSubmit: false })
    } catch (e) {
      this.setState({ isSubmit: false })
      const message = e && e.detail ? e.detail : ['Please upload a photo that includes your face']
      this.props.setErrorMessage([message], 'We have detected some problems with your uploaded portrait image')
    }
  }

  setPicture(picture) {
    this.setState({
      picture: window.URL.createObjectURL(picture),
    })
  }

  getStatus = () => {
    const {
      isHidden,
      isApproved,
      changeVisibility,
      showSnackbar,
      sendDeleteRequest,
      deletionRequestUuid,
      onCancelDeletion,
      setRequestUuid,
    } = this.props
    if (deletionRequestUuid) {
      return <BlockedStatus onCancelDeletion={onCancelDeletion} />
    } else {
      return (
        <ProfileStatusSelector
          isHidden={isHidden}
          changeVisibility={changeVisibility}
          sendDeleteRequest={sendDeleteRequest}
          showSnackbar={showSnackbar}
          setRequestUuid={setRequestUuid}
          isApproved={isApproved}
        />
      )
    }
  }

  render() {
    const { picture, isSubmit } = this.state
    const {
      userInfo,
      classes,
      className,
      setErrorMessage,
      pictureUrl,
      goToZendesk,
      rating,
      reviews,
      proReviewCommentsCount,
      handleRatingModalOpen,
    } = this.props
    const userName = `${userInfo.first_name || ''} ${userInfo.last_name || ''}`
    return (
      <div
        className={classNames({
          [className]: true,
          [classes.root]: true,
        })}
      >
        <div className={classes.wrapper}>
          <div className={classes.avatarWrapper}>
            <ProfileAvatarEditor
              editable
              avatarStyles={classes.avatar}
              picture={picture}
              pictureUrl={pictureUrl || NoPhoto}
              onSave={file => this.onSavePicture(file)}
              setPicture={file => this.setPicture(file)}
              setErrorMessage={setErrorMessage}
              isSubmit={isSubmit}
            />
          </div>
          <div className={classes.userNameContainer}>
            <div className={classes.userName}>
              <Typography variant="h4" className={classes.name}>
                {userName}
              </Typography>
              <Tooltip
                title={<NameTooltipContent adminEmail={userInfo.admin_email} goToZendesk={goToZendesk} />}
                tooltipClass={classes.tooltip}
                interactive
              >
                <Icon iconClass="las la-info-circle" style={classes.icon} />
              </Tooltip>
            </div>
            <div className={classes.ratingWrapper}>
              <Rating
                value={rating}
                name="rating"
                size={'small'}
                readOnly
                ratingContainerClasses={classes.ratingContainer}
                precision={0.1}
              />
              <ProRatingTitle
                isProProfile={!!proReviewCommentsCount}
                rating={rating}
                reviews={reviews}
                openRatingModal={handleRatingModalOpen}
              />
            </div>
          </div>
          <div className={classes.status}>{this.getStatus()}</div>
          <div className={classes.status}>
            <Typography variant="body1" className={classes.emailTitle}>
              Current Email
            </Typography>
            <Typography variant="subtitle1" className={classes.userNameTitle}>
              {userInfo.username || ''}
            </Typography>
          </div>
          <div className={classes.settingsContainer}>
            <SettingsContainer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, accountProfile, proReview }) => ({
  userInfo: auth.currentUser,
  isHidden: accountProfile.isProfileHidden,
  isApproved: accountProfile.isProfileApproved,
  rating: accountProfile.rating,
  reviews: accountProfile.reviews,
  deletionRequestUuid: accountProfile.deletionRequestUuid,
  pictureUrl: auth.currentUser.photo,
})

const mapDispatchToProps = dispatch => ({
  saveProfilePicture: photo => dispatch(savePhoto(photo)),
  changeVisibility: value => dispatch(changeProfileVisibility(value)),
  sendDeleteRequest: () => dispatch(sendDeleteProfileRequest()),
  onCancelDeletion: () => dispatch(cancelAccountDeletion()),
  showSnackbar: (variant, message) => dispatch(openSnackbar(variant, message)),
  setRequestUuid: uuid => dispatch(getDeletionRequestInfoEnd(uuid)),
  goToZendesk: (mail, redirect) => dispatch(goToZendesk(mail, redirect)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(UserPersonalInfo)
