import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { connect } from 'react-redux'
import MuiAlert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import { closeSnackbar } from '../../../store/actions/common'
import { Icon } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants'

const useStyles = makeStyles({
  iconButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  icon: {
    color: colors.WHITE,
  },
})

const XSnackbar = props => {
  const { snackbar, onSnackbarClose } = props

  const classes = useStyles()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={snackbar.isOpen}
      autoHideDuration={6000}
      onClose={onSnackbarClose}
    >
      <MuiAlert
        severity={snackbar.variant}
        elevation={6}
        variant="filled"
        action={
          <IconButton aria-label="close" size="small" onClick={onSnackbarClose} classes={{ root: classes.iconButton }}>
            <Icon style={classes.icon} iconClass="las la-times" />
          </IconButton>
        }
      >
        {snackbar.message}
      </MuiAlert>
    </Snackbar>
  )
}

const mapStateToProps = ({ common }) => ({
  snackbar: common.snackbar,
})

const mapDispatchToProps = dispatch => ({
  onSnackbarClose: () => dispatch(closeSnackbar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(XSnackbar)
