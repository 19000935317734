import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { colors, CONSTANTS, routes } from 'constants/index'
import {
  Button,
  Icon,
  SplitButton,
  SvgIcon,
  Typography,
  ProInfoClientCard,
  VaultButton,
  MessagesButton,
} from 'components'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import classNames from 'classnames'
import { ReactComponent as ListIcon } from 'static/icon_24_list.svg'
import { useDispatch } from 'react-redux'
import { navigateToCancelEvent, navigateToRescheduleEvent, navigateToSearchProAccount } from 'store/actions/navigation'
import { getMeetingDay, getMeetingDuration, getMeetingRange } from 'utils/getters/events'
import { deleteClientMeeting } from 'store/actions/events'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles({
  eventCard: {
    width: 570,
  },
  header: {
    backgroundColor: colors.BASIC.COLOR[0],
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    padding: '12px 16px',
  },
  content: {
    display: 'flex',
    padding: 16,
    height: '100%',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionSubTitle: {
    fontWeight: 'normal',
    fontSize: 15,
    color: colors.BASIC.COLOR[100],
  },
  ongoingStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  canceledSubTitle: {
    color: colors.ERROR,
    fontWeight: 500,
    marginRight: 16,
    fontSize: 15,
  },
  rescheduledSubTitle: {
    color: colors.PRIMARY.COLOR[40],
    fontWeight: 500,
    marginRight: 16,
    fontSize: 15,
    whiteSpace: 'nowrap',
  },
  todaySubTitle: {
    color: colors.SUCCESS,
    fontWeight: 500,
    marginRight: 16,
    fontSize: 15,
  },
  canceledTime: {
    color: colors.BASIC.COLOR[20],
    textDecoration: 'line-through',
  },
  ongoingSubTitle: {
    color: colors.SUCCESS,
    fontSize: 15,
    whiteSpace: 'nowrap',
  },
  row: {
    display: 'flex',
    marginBottom: 12,
  },
  iconColumn: {
    width: 30,
  },
  icons: {
    width: 20,
    height: 20,
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: 15,
    color: colors.BASIC.COLOR[100],
  },
  priceSection: {
    display: 'flex',
  },
  separator: {
    flexGrow: 1,
    borderBottom: `1px dashed ${colors.BASIC.COLOR[10]}`,
  },
  practiceAreaSection: {
    width: '100%',
  },
  icon: {
    marginRight: 4,
  },
  buttons: {
    marginLeft: 12,
    padding: '5px 12px',
    height: 32,
    minHeight: 32,
    fontSize: 11,
  },
  manageButton: {
    height: 32,
    minHeight: 32,
    fontSize: 11,
    marginLeft: 12,
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  manageInnerButtons: {
    height: 32,
    minHeight: 32,
    fontSize: 11,
    padding: '5px 10px',
  },
  manageIconButton: {
    minWidth: 'auto',
    padding: '5px 10px 5px 0',
    borderLeft: 'none',
    '&:hover': {
      borderLeft: 'none',
    },
  },
  price: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
  },
  weekDay: {
    marginRight: 8,
  },
  messageBtn: {
    marginLeft: 8,
  },
  removeBtn: {
    backgroundColor: colors.PRIMARY.COLOR[0],
  },
  '@media(max-width:1279px)': {
    eventCard: {
      margin: '0 12px 24px !important',
      width: 'calc(50% - 24px)',
    },
  },
  '@media(max-width:939px)': {
    eventCard: {
      width: 'calc(100% - 24px)',
    },
  },
  '@media(max-width:767px)': {
    eventCard: {
      margin: '0 0 16px !important',
      width: '100%',
    },
    header: {
      height: 'auto',
    },
  },
})

const MANAGE_OPTIONS = ['reschedule', 'cancel']

const moment = extendMoment(Moment)

const getIsOngoing = (start, end) => {
  const range = moment().range(start, end)
  return range.contains(moment())
}

const getIsToday = start => {
  const startTime = moment(start)
  return startTime.isSame(moment(), 'd')
}

const EventHeader = ({ start, end, status }) => {
  const classes = useStyles()
  const isRescheduled =
    status === CONSTANTS.EVENT_STATUS.RESCHEDULED_BY_PRO || status === CONSTANTS.EVENT_STATUS.RESCHEDULED_BY_CLIENT
  const isCanceled =
    status === CONSTANTS.EVENT_STATUS.CANCELED_BY_PRO ||
    status === CONSTANTS.EVENT_STATUS.CANCELED_BY_CLIENT ||
    status === CONSTANTS.EVENT_STATUS.DELETED_CANCELED_MEETING_BY_PRO
  const isOngoing = getIsOngoing(start, end)

  return (
    <div
      className={classNames({
        [classes.ongoingStyle]: isOngoing,
      })}
    >
      <div className={classes.headerContent}>
        {getIsToday(start) && (
          <Typography variant="subtitle2" className={classes.todaySubTitle}>
            today
          </Typography>
        )}
        {isCanceled && (
          <Typography variant="subtitle2" className={classes.canceledSubTitle}>
            canceled
          </Typography>
        )}
        {isRescheduled && (
          <Typography variant="subtitle2" className={classes.rescheduledSubTitle}>
            rescheduled to
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          className={classNames({
            [classes.sectionSubTitle]: true,
            [classes.canceledTime]: isCanceled,
          })}
        >
          <b className={classes.weekDay}>{getMeetingDay(start)}</b> {getMeetingRange(start, end)} &bull;{' '}
          {getMeetingDuration(start, end)}
        </Typography>
      </div>

      {isOngoing && !isCanceled && (
        <div>
          <Typography variant="subtitle2" className={classes.ongoingSubTitle}>
            &bull; ongoing
          </Typography>
        </div>
      )}
    </div>
  )
}

const ClientEventCard = props => {
  const { event } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const isFree = Number(event.price) === 0
  const isCanceled =
    event.status === CONSTANTS.EVENT_STATUS.CANCELED_BY_PRO ||
    event.status === CONSTANTS.EVENT_STATUS.CANCELED_BY_CLIENT ||
    event.status === CONSTANTS.EVENT_STATUS.DELETED_CANCELED_MEETING_BY_PRO
  const isOngoing = getIsOngoing(event.startTime, event.endTime)
  const proName = `${event.proInfo.firstName} ${event.proInfo.lastName}`

  const navigateToProPage = () => {
    dispatch(navigateToSearchProAccount(event.pro))
  }

  const onManage = action => {
    switch (action) {
      case 'cancel':
        dispatch(navigateToCancelEvent(event.uuid, 'client'))
        return
      case 'reschedule':
        dispatch(navigateToRescheduleEvent(event.uuid, 'client'))
    }
  }

  const onDeleteMeeting = () => {
    dispatch(deleteClientMeeting(event.uuid))
  }

  const getHeader = useMemo(
    () => (
      <div className={classes.header}>
        <EventHeader start={event.startTime} end={event.endTime} status={event.status} />
      </div>
    ),
    [event.startTime, event.endTime, event.status]
  )

  const getButtons = useMemo(
    () => (
      <div className={classes.buttonGroup}>
        {isCanceled ? (
          <Button
            variant="secondary"
            onClick={onDeleteMeeting}
            className={classNames(classes.removeBtn, classes.buttons)}
          >
            <Icon iconClass="lar la-trash-alt" style={classes.icon} />
            remove from the list
          </Button>
        ) : (
          <>
            <VaultButton
              options={{
                proID: event.pro,
                isClientVault: true,
                proName: proName,
                proTwilioChatID: event ? event.twilio_chat_sid : '',
              }}
              disabled={!(event.vaultPlanAccepted && event.hasClientFolder)}
              vaultPlace="events"
            />
            <MessagesButton
              className={classes.messageBtn}
              chatId={event.twilio_chat_sid}
              place="events"
              disabled={!event.readyForMessages}
              disabledText={CONSTANTS.DISABLED_MESSAGES_TOOLTIP}
            />
            {!isOngoing && (
              <SplitButton
                color="secondary"
                variant="secondary"
                options={MANAGE_OPTIONS}
                classes={{
                  buttonGroup: classes.manageButton,
                  buttons: classes.manageInnerButtons,
                  iconButton: classes.manageIconButton,
                }}
                onSelect={onManage}
              >
                manage
              </SplitButton>
            )}
            <Button
              variant="primary"
              className={classes.buttons}
              target="_blank"
              component={RouterLink}
              to={`${routes.MEETING_LINK}/${event.uuid}`}
            >
              go to meeting
            </Button>
          </>
        )}
      </div>
    ),
    [isCanceled, isOngoing]
  )

  const getInitialMeetingInfo = useMemo(
    () => (
      <>
        <div className={classes.iconColumn}>
          <SvgIcon viewBox="0 0 24 24" className={classes.icons}>
            <ListIcon />
          </SvgIcon>
        </div>
        <div className={classes.practiceAreaSection}>
          <>
            <Typography variant="body1" className={classes.sectionTitle}>
              {event.practiceAreaName} {event.subSpecialityName ? `/ ${event.subSpecialityName}` : ''}
            </Typography>
            <div className={classes.priceSection}>
              <Typography variant="subtitle2" className={classes.sectionSubTitle}>
                Initial consultation
              </Typography>
              <div className={classes.separator} />
              <Typography variant="subtitle2" className={classes.price}>
                {isFree ? 'no charge' : `$${event.price}`}
              </Typography>
            </div>
          </>
        </div>
      </>
    ),
    [event.practiceAreaName, event.subSpecialityName, event.price, event.isInitial]
  )

  return (
    <ProInfoClientCard
      header={getHeader}
      proInfo={event.proInfo}
      isInitial={event.isInitial ? getInitialMeetingInfo : null}
      navigateToProPage={navigateToProPage}
      buttons={getButtons}
      cardStyle={classes.eventCard}
      isInitialMeetingSuccessfull={event.isInitialMeetingSuccessfull}
      isCanceled={isCanceled}
      reasonOfCancelation={event.updateReason}
    />
  )
}

export default ClientEventCard
