import React, { useEffect, useState } from 'react'
import { PrivacyPolicy, DownloadPDF } from 'components'
import file from 'static/Xira_privacy_policy.pdf'

const PrivacyPolicyContainer = props => {
  return (
    <>
      <PrivacyPolicy />
      <DownloadPDF file={file} name="XIRA Privacy Policy" />
    </>
  )
}

export default PrivacyPolicyContainer
