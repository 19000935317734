import React from 'react'
import { Typography, Link } from 'components'

export const BillableTooltipContent = ({ billable, tooltipText }) => {
  return (
    <>
      {billable ? (
        <Typography className={tooltipText} variant="subtitle1">
          XIRA allows you to track time and expenses that won't be charged to the client or appear on an invoice. Switch
          it off to accurately keep track of costs and productivity. Non-billable activities are not included to the
          invoice and invoice date is not applicable for them.
        </Typography>
      ) : (
        <Typography className={tooltipText} variant="subtitle1">
          Non-billable activities are not included to the invoice and invoice date is not applicable for them.
        </Typography>
      )}
    </>
  )
}

export const TimeTooltipContent = ({ tooltipText }) => {
  return (
    <>
      <Typography className={tooltipText} variant="subtitle1">
        You can enter time in the following formats:
        <br />
        -00h 00m
        <br />
        -00.0h
      </Typography>
    </>
  )
}

export const InvoiceTooltipContent = ({ tooltipText }) => {
  return (
    <Typography className={tooltipText} variant="subtitle1">
      Invoice date for activities is entered according to the invoicing configuration in the case.
    </Typography>
  )
}
export const DescriptionTooltipContent = ({ tooltipText }) => {
  return (
    <>
      <Typography className={tooltipText} variant="subtitle1">
        This description text is included in your invoice to the client. Please enter a clear description of your
        activity. Entries cannot be revised after an invoice is sent.
      </Typography>
    </>
  )
}

export const ProfileIsUnderApprovalTooltipText = ({ tooltipText, goToZendesk }) => (
  <Typography className={tooltipText} variant="subtitle1">
    You can not manage activities until your license is approved. Please contact <Link onClick={goToZendesk}>XIRA</Link>{' '}
    admin for details.
  </Typography>
)
export const TimeCreationDisabledTooltipText = ({ tooltipText }) => (
  <Typography className={tooltipText} variant="subtitle1">
    You can create time entries only for active T&E cases.
  </Typography>
)

export const ExpenseCreationDisabledTooltipText = ({ tooltipText }) => (
  <Typography className={tooltipText} variant="subtitle1">
    You can create expense entries only for active T&E cases and for active Contingency and Flat fee cases with expense
    invoicing.
  </Typography>
)
