import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Modal, Typography, Button, FoldersList, Tooltip, ColorCircularProgress, Breadcrumbs, Link } from 'components'
import { ReactComponent as MoveIcon } from 'static/vault/moveModal/move.svg'
import { ReactComponent as AddIcon } from 'static/vault/moveModal/add.svg'
import { ReactComponent as BreadcrumbIcon } from 'static/vault/moveModal/chevron-right.svg'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { checkFileNameUnique, getDefaultSubFolderName, isMoveDestinationAvailable } from 'utils/vault/vault-helpers'
import List from '@material-ui/core/List'
import { createVaultFolder } from 'store/actions/vault'
import { useDispatch } from 'react-redux'
import { getSubFolderByPath, getUpdatedSubFolderList } from '../../utils/vault/vault-helpers'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 534,
    position: 'relative',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  modalBody: {
    height: '80%',
    padding: 24,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.BASIC.COLOR['100'],
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 9,
  },
  userNameTitle: {
    color: colors.BASIC.COLOR['50'],
    textDecoration: 'none',
    fontSize: 13,
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
  },
  caseNameTitle: {
    color: colors.BASIC.COLOR['100'],
    fontSize: 13,
    fontWeight: 500,
  },
  boxBtn: {
    display: 'flex',
  },
  btnContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colors.BASIC.COLOR['0'],
    borderTop: `1px solid ${colors.BASIC.COLOR['10']}`,
    padding: '8px 24px',
  },
  icon: {
    marginRight: 8,
  },
  closeBtn: {
    marginRight: 24,
  },
  confirmBtn: {
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
    },
  },
  btn: {
    minHeight: 32,
    height: 32,
    paddingTop: 6,
    paddingBottom: 6,
  },
  foldersContainer: {
    width: 486,
    border: `solid 3px ${colors.ADDITIONAL.COLOR['20']}`,
    backgroundColor: colors.WHITE,
    minHeight: 50,
    maxHeight: 200,
    overflowY: 'scroll',
  },
  addIcon: {
    marginRight: 4,
  },
  tooltipLabel: {
    fontSize: 13,
    fontWeight: 'normal',
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '648px !important',
    },
    modal: {
      width: '100%',
      padding: '45px 15px 40px',
      backgroundColor: WHITE,
      boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
    },
    boxBtn: {
      marginTop: 16,
    },
  },
})

const getFolderName = name => {
  return name.length < 25 ? name : name.slice(0, 25) + '...'
}

const getCaseFolder = folders => {
  return folders.find(selectedFolder => selectedFolder && selectedFolder.children.every(folder => !folder.relatedID))
}

const getLastFolderChildren = (lastFolder, primaryFolders, isPrivateFolder) => {
  if (lastFolder) {
    if (lastFolder.uuid === primaryFolders[0].uuid && !isPrivateFolder) {
      return lastFolder.children.filter(folder => folder.uuid === primaryFolders[1].uuid)
    } else {
      return lastFolder.children.filter(folder => folder.isFolder)
    }
  } else {
    return []
  }
}

const VaultMoveItemsModal = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    isOpen,
    onClose,
    moveItems,
    caseFolder,
    handleMove,
    isFolderCreationInProgress,
    primaryFolders,
    itemsList,
    isPrivateFolder,
    action,
  } = props
  const [actionSelectedFolders, setActionSelectedFolders] = useState([])
  const [foldersList, setFoldersList] = useState([])
  const [addFolder, setAddFolder] = useState(false)
  const [defaultSubFolderName, setDefaultSubFolderName] = useState('')
  const [moveDisabled, setMoveDisable] = useState(true)
  const lastFolder = actionSelectedFolders[actionSelectedFolders.length - 1]
  const lastFolderChildren = getLastFolderChildren(lastFolder, primaryFolders, isPrivateFolder)
  const firstAvailableFolder = getCaseFolder(actionSelectedFolders)
  const isCaseFolderSelected = lastFolder && firstAvailableFolder && lastFolder.uuid === firstAvailableFolder.uuid

  useEffect(() => {
    if (!isOpen) {
      setAddFolder(false)
      setActionSelectedFolders([])
      setMoveDisable(true)
    }
    if (isOpen && !isPrivateFolder) {
      setMoveFolderList()
    }
  }, [isOpen, isPrivateFolder])

  useEffect(() => {
    if (!actionSelectedFolders.length) {
      if (isPrivateFolder) {
        setFoldersList(itemsList.filter(folder => folder.isFolder))
      } else if (isOpen) {
        setMoveFolderList()
      }
    } else {
      const isMoveActive =
        actionSelectedFolders.length === 1 && !lastFolder.isPrivateFolder
          ? true
          : !isMoveDestinationAvailable(moveItems, lastFolder)
      setMoveDisable(isMoveActive)
      setDefaultSubFolderName(getDefaultSubFolderName(lastFolderChildren))
      if (lastFolder.isPrivateFolder || lastFolder.relatedID) setFoldersList(lastFolderChildren)
    }
  }, [actionSelectedFolders])

  useEffect(() => {
    if (isOpen) {
      const updatedSelectedFolders = getUpdatedSubFolderList(actionSelectedFolders, itemsList)
      const updatedCaseFolder = getCaseFolder(updatedSelectedFolders)
      const updatedFolderList = updatedCaseFolder ? updatedCaseFolder.children : []
      setFoldersList(updatedFolderList.filter(item => item.isFolder))
      setActionSelectedFolders(updatedSelectedFolders)
    }
  }, [itemsList])

  const setMoveFolderList = () => {
    const filteredItemsList = itemsList.filter(
      folder => folder.uuid === primaryFolders[0].uuid || folder.isPrivateFolder
    )
    setFoldersList(filteredItemsList)
  }

  const onCreateFolderConfirm = name => {
    setAddFolder(false)
    dispatch(createVaultFolder(name, lastFolder))
  }

  const onMoveItemsConfirm = () => {
    handleMove(moveItems, lastFolder)
    onClose()
  }

  const cancelCreateFolder = () => setAddFolder(false)

  const addActionSelectedFolder = folder => {
    const selectedFolders = getSubFolderByPath(folder, itemsList)
    setActionSelectedFolders(selectedFolders)
  }

  const handleBreadcrumbClick = (folder, i, array) => {
    if (array.length === 1) {
      setActionSelectedFolders([])
      return
    } else {
      setActionSelectedFolders(actionSelectedFolders.slice(0, i + 1))
    }
  }

  return (
    <Modal open={isOpen} handleClose={onClose} maxWidth={false} dialogClasses={{ paper: classes.paper }}>
      <div className={classes.modal}>
        <div className={classes.modalBody}>
          <div className={classes.modalHeader}>
            <MoveIcon className={classes.icon} />
            <Typography variant="h4" className={classes.title}>
              {`${action} ${moveItems.length} ${moveItems.length === 1 ? 'item' : 'items'} to...`}
            </Typography>
          </div>
          <div className={classes.breadcrumb}>
            <Breadcrumbs separator={<BreadcrumbIcon />}>
              {actionSelectedFolders.map((folder, i, array) => (
                <Link
                  key={folder.uuid}
                  className={classes.userNameTitle}
                  onClick={() => handleBreadcrumbClick(folder, i, array)}
                >
                  {getFolderName(folder.name)}
                </Link>
              ))}
            </Breadcrumbs>
          </div>
          <div className={classes.foldersContainer}>
            {isFolderCreationInProgress ? (
              <ColorCircularProgress />
            ) : (
              <List component="nav" className={classes.root}>
                <FoldersList
                  children={foldersList}
                  addFolder={addFolder}
                  setSelectedFolder={addActionSelectedFolder}
                  cancelCreateFolder={cancelCreateFolder}
                  selectedFolder={lastFolder}
                  defaultSubFolderName={defaultSubFolderName}
                  onCreateFolderConfirm={onCreateFolderConfirm}
                  caseFolder={caseFolder}
                  isCaseFolderSelected={isCaseFolderSelected}
                  nameCheck={name => checkFileNameUnique(lastFolderChildren, name)}
                />
              </List>
            )}
          </div>
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="secondary"
            disabled={
              !actionSelectedFolders.length || (actionSelectedFolders.length === 1 && !lastFolder.isPrivateFolder)
            }
            onClick={() => setAddFolder(true)}
            className={classNames(classes.closeBtn, classes.btn)}
          >
            <AddIcon className={classes.addIcon} />
            {'create new folder'}
          </Button>
          <div className={classes.boxBtn}>
            <Button variant="text" onClick={onClose} className={classNames(classes.closeBtn, classes.btn)}>
              {'cancel'}
            </Button>
            {moveDisabled ? (
              <Tooltip tooltipClass={classes.tooltipLabel} title={'Item is already in this folder'}>
                <Button disabled className={classNames(classes.confirmBtn, classes.btn)}>
                  {action}
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="primary"
                onClick={onMoveItemsConfirm}
                className={classNames(classes.confirmBtn, classes.btn)}
              >
                {action}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VaultMoveItemsModal
