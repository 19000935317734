import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Modal, Typography } from 'components'
import { ReactComponent as PremiumIcon } from 'static/cases/premium.svg'

const useStyles = makeStyles({
  paper: {
    margin: 16,
  },
  casesContainer: {
    width: 360,
    padding: '16px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  casesImage: {
    marginTop: 40,
  },
  casesTitle: {
    marginTop: 24,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '26px',
  },
  casesText: {
    marginTop: 6,
    maxWidth: 215,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '19px',
    letterSpacing: 'normal',
  },
  casesFooter: {
    width: '100%',
    marginTop: 56,
    display: 'flex',
    justifyContent: 'flex-end',
    '& a': {
      textDecoration: 'none',
    },
  },
  casesFooterOkButton: {
    marginLeft: 24,
  },
  '@media(max-width:767px)': {
    paper: {
      maxWidth: '100% !important',
    },
    casesContainer: {
      width: 288,
    },
    casesFooter: {
      justifyContent: 'center',
    },
    casesFooterCancellButton: {
      display: 'none',
    },
    casesFooterOkButton: {
      margin: 0,
    },
  },
})

export const NotForFeeModal = ({ open, onClose, onOk }) => {
  const classes = useStyles()

  return (
    <Modal dialogClasses={{ paper: classes.paper }} open={open} maxWidth="md" handleClose={onClose}>
      <div className={classes.casesContainer}>
        <PremiumIcon className={classes.casesImage} />
        <Typography className={classes.casesTitle} variant="subtitle1">
          Premium account required
        </Typography>
        <div className={classes.casesFooter}>
          <Button className={classes.casesFooterCancellButton} variant="text" onClick={onClose}>
            cancel
          </Button>
          <a onClick={onOk} href="https://member.xira.com/upgrade/" target="_blank" rel="noopener noreferrer">
            <Button className={classes.casesFooterOkButton}>learn more</Button>
          </a>
        </div>
      </div>
    </Modal>
  )
}
