import React from 'react'
import { SvgIcon, Table } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as IconEdit } from 'static/ic_edit.svg'
import { ReactComponent as IconTrash } from 'static/ic_trash.svg'
import { colors } from '../../constants'

const useStyles = makeStyles({
  degreeTable: {
    boxShadow: '0 0 0 0',
  },
  tableHeaderCell: {
    borderBottom: 0,
    color: colors.BASIC.COLOR[80],
    paddingLeft: 0,
  },
  tableBodyCell: {
    paddingLeft: 0,
    maxWidth: '1px',
  },
  tableCellTextClass: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  svgRoot: {
    height: 20,
    width: 20,
    marginRight: 12,
  },
})

const columns = [
  {
    value: 'schoolName',
    label: 'School Name',
  },
  {
    value: 'areaOfStudy',
    label: 'Field of Study',
  },
  {
    value: 'degreeLevel',
    label: 'Degree',
  },
  {
    value: 'graduated',
    label: 'Graduated',
  },
]

const DegreesTable = props => {
  const classes = useStyles()

  const getRowActions = () => [
    {
      key: 'edit',
      label: (
        <>
          <SvgIcon className={classes.svgRoot} component={IconEdit} viewBox="0 0 20 20" />
          <span>edit</span>
        </>
      ),
      action: data => props.onEdit(data.id),
    },
    {
      key: 'delete',
      label: (
        <>
          <SvgIcon className={classes.svgRoot} component={IconTrash} viewBox="0 0 20 20" />
          <span>delete</span>
        </>
      ),
      action: data => props.onDelete(data.id),
    },
  ]

  return (
    <Table
      tableColumns={columns}
      className={classes.degreeTable}
      tableHeaderCellClass={{ root: classes.tableHeaderCell }}
      tableBodyCellClass={{ root: classes.tableBodyCell }}
      tableCellTextClass={classes.tableCellTextClass}
      tableData={props.degrees}
      getRowActions={getRowActions}
    />
  )
}

export default DegreesTable
