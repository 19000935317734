import React from 'react'
import { Button, Modal } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import LogInForm from './LoginForm'

const useStyles = makeStyles({
  text: {
    margin: '32px',
  },
  link: {
    width: '100%',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.17,
  },
  signUp: {
    textAlign: 'center',
    width: 568,
    height: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },

  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: 368,
  },

  signUpFooter: {
    width: '100%',
    maxWidth: 360,
    textAlign: 'center',
    margin: '24px auto 64px',
  },
  verifyLink: {
    fontSize: 13,
    fontWeight: 400,
    marginLeft: 5,
  },
  '@media(max-width:767px)': {
    paper: {
      margin: '0',
      width: '100%',
      maxWidth: '568px !important',
    },
    signUp: {
      width: '100%',
      padding: '0 15px',
    },
    signUpForm: {
      width: '100%',
    },
    signUpContent: {
      width: '100%',
      maxWidth: 368,
      margin: '0 auto',
    },
    btn: {
      width: '100%',
    },
  },
  '@media(max-width:400px)': {
    buttonText: {
      display: 'none',
    },
    socialButtons: {
      width: 46,
      minWidth: 46,
      borderRadius: '50%',
      margin: '4px 7px',
    },
    iconFacebook: {
      margin: 0,
    },
    iconGoogle: {
      margin: 0,
    },
  },
  closeButtonClass: {
    display: 'none',
  },
})

const LogInContainer = props => {
  const classes = useStyles()

  const closeModal = () => {
    props.handleClose()
  }

  const navToSignUp = () => {
    props.goToSignUp()
  }

  return (
    <Modal
      open={props.open}
      handleClose={closeModal}
      scroll="body"
      dialogClasses={{ paper: classes.paper }}
      closeButtonClass={props.disableClose && classes.closeButtonClass}
    >
      <div className={classes.signUp}>
        <LogInForm goToRestore={props.goToRestore} onAfterLogin={props.onAfterLogin} />
        <div className={classes.signUpFooter}>
          <Button variant="secondary" className={classes.link} onClick={navToSignUp}>
            new to XIRA? Sign up
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LogInContainer
