import * as CONSTANTS from 'store/constants/chat/chat'
import axios from 'axios'
import { openSnackbar } from 'store/actions/common'
import {
  getChatTokenPath,
  getTwilioSubscriptionPath,
  getTwilioUnreadMessagesPath,
  getUserChatByIdPath,
  getUserChatsPath,
} from 'utils/path-helpers/api'
import { mapUserMessages, mergeMessages, newMessageMapper, updateMessageBody } from 'utils/mappers/backend'
import { getJWTLocally } from 'utils/auth'
import TwilioChat from 'twilio-chat'
import { WebsocketService } from 'utils/service/WebsocketService'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

export const setSelectedChat = payload => ({
  type: CONSTANTS.SET_SELECTED_CHAT,
  payload,
})

export const setChatInfo = (id, data) => (dispatch, getState) => {
  const result = getState().chat.chats.map(chat => {
    if (chat.twilio_chat_sid === id) {
      return {
        ...chat,
        ...data,
      }
    }
    return chat
  })
  dispatch({
    type: CONSTANTS.SET_CHAT_INFO,
    payload: result,
  })
}

export const setChatListSearchValue = payload => ({
  type: CONSTANTS.SET_CHAT_SEARCH_VALUE,
  payload,
})

export const increaseChatListOffset = payload => ({
  type: CONSTANTS.INCREASE_CHAT_LIST_OFFSET,
  payload,
})

export const resetChatListOffset = () => ({
  type: CONSTANTS.RESET_CHAT_LIST_OFFSET,
})

export const resetSelectedChat = () => ({
  type: CONSTANTS.RESET_SELECTED_CHAT,
})

export const setChatWindowVisibility = payload => ({
  type: CONSTANTS.SET_CHAT_WINDOW_VISIBILITY,
  payload,
})

export const openChatModal = (chatId, place) => (dispatch, getState) => {
  if (chatId) {
    return axios
      .get(getUserChatByIdPath(chatId))
      .then(response => {
        dispatch(setChatWindowVisibility(true))
        dispatch(setSelectedChat(response.data))
        Analytics.track(
          getState().auth.currentUser.role === 'pro' ? EVENTS.PRO_OPENED_MESSAGES : EVENTS.CLIENT_OPENED_MESSAGES,
          {
            message_place: place,
          }
        )
      })
      .catch(e => {
        dispatch(openSnackbar('error', 'Error while loading chat details'))
        return Promise.reject(e)
      })
  }
}

const setUserMessagesAction = payload => ({
  type: CONSTANTS.SET_USER_MESSAGES,
  payload,
})

export const addMessageToList = data => (dispatch, getState) => {
  const result = newMessageMapper(data, getState().chat.messages)
  dispatch(setUserMessagesAction(result))
}

export const setUserMessages = data => dispatch => {
  const result = mapUserMessages(data)
  dispatch(setUserMessagesAction(result))
}

export const resetUserMessages = () => ({
  type: CONSTANTS.RESET_USER_MESSAGES,
})

export const addPreviousMessagesToList = data => (dispatch, getState) => {
  const previousMessages = mapUserMessages(data)
  const result = mergeMessages(previousMessages, getState().chat.messages)
  dispatch(setUserMessagesAction(result))
}

export const getChatToken = () => dispatch => {
  return axios
    .get(getChatTokenPath())
    .then(response => response.data.twilio_token)
    .catch(e => {
      dispatch(openSnackbar('error', 'Error while loading access token'))
      return Promise.reject(e)
    })
}

export const getUserChatsStart = payload => ({
  type: CONSTANTS.GET_USER_CHATS_START,
  payload,
})

export const getUserChatsEnd = (payload, count) => ({
  type: CONSTANTS.GET_USER_CHATS_END,
  payload,
  count,
})

export const getUserChats = (isReloading = true) => (dispatch, getState) => {
  const state = getState().chat
  dispatch(getUserChatsStart())
  return axios
    .get(getUserChatsPath(), {
      params: {
        limit: state.chatListConfiguration.limit,
        offset: state.chatListConfiguration.offset,
        search: state.searchValue,
      },
    })
    .then(response => {
      const newData = isReloading ? response.data.results : [...getState().chat.chats, ...response.data.results]
      const selectedChat = getState().chat.selectedChat
      dispatch(
        getUserChatsEnd(
          newData.map(chat =>
            chat.twilio_chat_sid === selectedChat.twilio_chat_sid ? { ...chat, unread_messages_count: 0 } : chat
          ),
          response.data.count
        )
      )
      return response.data
    })
    .catch(e => {
      dispatch(getUserChatsEnd([], 0))
      dispatch(openSnackbar('error', 'Error while loading user chats'))
      return Promise.reject(e)
    })
}

export const checkIfUserHasUnreadMessagesEnd = payload => ({
  type: CONSTANTS.CHECK_IF_USER_HAS_UNREAD_MESSAGES_END,
  payload,
})

export const checkIfUserHasUnreadMessages = () => dispatch => {
  return axios
    .get(getTwilioUnreadMessagesPath())
    .then(response => {
      dispatch(checkIfUserHasUnreadMessagesEnd(response.data.has_unread_messages))
      return response.data
    })
    .catch(e => {
      dispatch(checkIfUserHasUnreadMessagesEnd(false))
      return Promise.reject(e)
    })
}

export const setSelectedChatOnline = payload => ({
  type: CONSTANTS.SET_SELECTED_CHAT_ONLINE,
  payload,
})

let chatSubject

export const subscribeToChatChanges = sid => (dispatch, getState) => {
  chatSubject = new WebsocketService(getTwilioSubscriptionPath(sid, getJWTLocally()))
  chatSubject.subscribe(msg => {
    if (msg.sub_type === 'twilio_events_message') {
      if (msg.twilio_chat_sid !== getState().chat.selectedChat.twilio_chat_sid) {
        dispatch(checkIfUserHasUnreadMessages())
        const selectedChat = getState().chat.chats.find(chat => chat.twilio_chat_sid === msg.twilio_chat_sid)
        if (selectedChat) {
          selectedChat.unread_messages_count = msg.unread_messages_count
          selectedChat.last_message_sent_at = msg.last_message_sent_at
          const chats = getState().chat.chats.filter(chat => chat.twilio_chat_sid !== msg.twilio_chat_sid)
          dispatch(getUserChatsEnd([selectedChat, ...chats], getState().chat.count))
        } else {
          dispatch(resetChatListOffset())
          dispatch(getUserChats())
        }
      }
    } else if (msg.sub_type === 'twilio_events_status') {
      if (msg.twilio_chat_sid === getState().chat.selectedChat.twilio_chat_sid) {
        dispatch(setSelectedChatOnline(msg.is_online))
      }
      const chatListHasThisChat = getState().chat.chats.find(chat => chat.twilio_chat_sid === msg.twilio_chat_sid)
      if (chatListHasThisChat) {
        dispatch(
          setChatInfo(msg.twilio_chat_sid, {
            chat_member_is_online: msg.is_online,
          })
        )
      }
    }
  })
}

export const unsubscribeFromChatChanges = () => {
  if (chatSubject) {
    chatSubject.unsubscribe()
  }
}

export const updateChatMessage = message => (dispatch, getState) => {
  const result = updateMessageBody(message, getState().chat.messages)
  dispatch(setUserMessagesAction(result))
}

export const getUserChat = id => dispatch => {
  if (id) {
    return axios
      .get(getUserChatByIdPath(id))
      .then(response => {
        return response.data
      })
      .catch(e => {
        dispatch(openSnackbar('error', 'Error while loading chat details'))
        return Promise.reject(e)
      })
  }
}

const setChatClient = payload => ({
  type: CONSTANTS.SET_CHAT_CLIENT,
  payload,
})

let chatClient = null

export const initializeChatInstance = () => async dispatch => {
  try {
    const token = await dispatch(getChatToken())
    chatClient = await TwilioChat.create(token)
    if (chatClient) {
      dispatch(setChatClient(chatClient))
      chatClient.on('tokenAboutToExpire', async () => {
        const newToken = await dispatch(getChatToken())
        await chatClient.updateToken(newToken)
      })
    }
  } catch (e) {
    dispatch(openSnackbar('error', 'Error while initializing chat client'))
  }
}

export const destroyChatInstance = () => dispatch => {
  if (chatClient) {
    chatClient.shutdown()
    dispatch(setChatClient(null))
    chatClient = null
  }
}
