import { BlobProvider } from '@react-pdf/renderer'
import { URLPDFViewer, ColorCircularProgress } from 'components'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './style/index.scss'

const PDFViewer = ({ document, className }) => {
  const location = useLocation()
  const documentHash = location.hash
  const [loading, setLoading] = useState(!documentHash)

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }, [])
  return (
    <BlobProvider document={document}>
      {({ url }) => {
        //fake loader was added to fix bug EXIRA01-5002 because there is an issue https://github.com/wojtekmaj/react-pdf/issues/679
        return loading ? <ColorCircularProgress /> : <URLPDFViewer url={url} className={className} />
      }}
    </BlobProvider>
  )
}

export default PDFViewer
