import XTextField from '../textField'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  searchInput: {
    width: '340px',
    borderRadius: 0,
  },
})

const SearchInput = props => {
  const classes = useStyles()
  return (
    <XTextField
      InputProps={{ classes: { root: classes.searchInput } }}
      id="main-search"
      variant="outlined"
      placeholder="Practice area"
      {...props}
    />
  )
}

export default SearchInput
