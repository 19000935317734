import React from 'react'
import { Button, FileInput, Icon, Modal, Typography } from 'components'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { ReactComponent as UploadIcon } from 'static/profile/file-upload__orange.svg'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../constants/colors'
import './cropAvatarModal.scss'
import classNames from 'classnames'

const { WHITE } = colors

const useStyles = makeStyles({
  modal: {
    width: 648,
    position: 'relative',
    padding: '40px 0',
    backgroundColor: WHITE,
    boxShadow: '0 2px 12px 0 rgba(87, 87, 87, 0.15)',
  },
  title: {
    paddingLeft: '40px',
    margin: '0 0 16px 0',
    fontSize: '20px',
  },
  subtitle: {
    width: '568px',
    marginBottom: '32px',
    textAlign: 'center',
  },
  photo: {
    height: '250px',
    marginBottom: '30px',
    backgroundColor: colors.BASIC.COLOR[20],
    textAlign: 'center',
    overflow: 'hidden',
  },
  boxBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    alignItems: 'center',
  },
  fileInput: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
  },
  saveBtn: {
    height: 'auto',
    minWidth: 0,
    fontSize: 11,
    marginLeft: 16,
    padding: '10px 16px',
  },
  cancelBtn: {
    height: 'auto',
    fontSize: 11,
  },
  textFileInput: {
    fontSize: 11,
    fontWeight: 500,
    color: colors.PRIMARY.COLOR[50],
    border: `1px solid ${colors.PRIMARY.COLOR[50]}`,
    borderRadius: 4,
    padding: '4px 12px',
    width: 140,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  textFileInput__icon: {
    marginRight: 4,
  },
})

const CameraIcon = () => <Icon iconClass="las la-camera" />

const CropAvatarModal = props => {
  const { isOpen, handleClose, picture, handleImageUpload, onSave, isSubmit } = props
  const cropper = React.createRef(null)
  const classes = useStyles()

  const onSaveClick = () => {
    const canvas = cropper.current.getCroppedCanvas().toDataURL()
    fetch(canvas)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'avatar.png', {
          type: 'image/png',
          lastModified: new Date(),
        })
        onSave(file)
      })
  }

  return (
    <Modal open={isOpen} handleClose={handleClose} maxWidth={false}>
      <div className={classNames({ [classes.modal]: true, 'crop-avatar-modal': true })}>
        <Typography variant="h3" className={classes.title}>
          Choose Fragment
        </Typography>
        <div className={classes.photo}>
          <Cropper
            ref={cropper}
            src={picture}
            aspectRatio={1}
            guides={false}
            style={{ height: 250, width: '100%' }}
            minCanvasWidth={300}
            minCanvasHeight={300}
            viewMode={1}
          />
        </div>
        <div className={classes.boxBtn}>
          <FileInput
            id="fileSecond"
            onChange={handleImageUpload}
            labelClass={classes.fileInput}
            textClasses={classes.textFileInput}
            disabled={isSubmit}
          >
            <UploadIcon className={classes.textFileInput__icon} /> upload photo
          </FileInput>
          <div>
            <Button variant="text" onClick={handleClose} disabled={isSubmit} className={classes.cancelBtn}>
              cancel
            </Button>
            <Button
              variant="primary"
              className={classes.saveBtn}
              onClick={onSaveClick}
              disabled={isSubmit}
              isButtonBlocked={isSubmit}
              circularSize={22}
              circularColor="primary"
            >
              save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CropAvatarModal
