import { createSelector } from 'reselect'
import { isMoveActionAvailable } from '../../utils/vault/vault-helpers'

const getVaultSelectedFoldersList = state => state.vault.selectedFolders
const getVaultSelectedItemsList = state => state.vault.selectedItems
const getVaultItemsList = state => state.vault.itemsList
const getVaultUserId = state => state.vault.userID
const getVaultCaseId = state => state.vault.caseID
const getVaultIsMoving = state => state.vault.isMoving
const getVaultIsCoping= state => state.vault.isCoping

export const getVaultCaseFolder = createSelector(
  [getVaultItemsList, getVaultUserId, getVaultCaseId],
  (itemsList, userID, caseID) => {
    const userFolder = itemsList.find(data => data.relatedID === userID)
    return userFolder && userFolder.children && userFolder.children.find(data => data.relatedID === caseID)
  }
)

export const getVaultCaseChildrenFolder = createSelector(
  [getVaultItemsList, getVaultUserId, getVaultCaseId],
  (itemsList, userID, caseID) => {
    const userFolder = itemsList.find(data => data.relatedID === userID)
    const caseFolder = userFolder && userFolder.children && userFolder.children.find(data => data.relatedID === caseID)
    return (caseFolder && caseFolder.children) || []
  }
)

export const getIsMoveActionAvailable = createSelector(
  [getVaultSelectedItemsList, getVaultCaseChildrenFolder],
  (selectedRows, casesFolders) => isMoveActionAvailable(selectedRows, casesFolders)
)

export const getVaultPrimaryFolders = createSelector([getVaultSelectedFoldersList], selectedFolders =>
  selectedFolders.slice(0, 2)
)

export const getIsPrivateFolderSelected = createSelector([getVaultSelectedFoldersList], selectedFolders =>
  selectedFolders.find(folder => folder.isPrivateFolder)
)

export const getIsDisableSelection = createSelector([getVaultIsCoping, getVaultIsMoving], (isMoving, isCoping) =>
  isMoving || isCoping
)
