import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { connect, useDispatch } from 'react-redux'
import { ColorCircularProgress, SecureVaultTable, Typography, Button, VaultPlans } from 'components'
import { ReactComponent as SettingsIcon } from 'static/vault/vault_settings.svg'
import { getVaultFilesList, openVaultModal, setVaultOpenPlace } from 'store/actions/vault'
import { navigateToVaultSettings } from 'store/actions/navigation'
import { useQuery } from 'utils/hooks'
import Analytics from '../../../utils/analytics/AnalyticsService'
import { EVENTS } from '../../../utils/analytics/Events'

const useStyles = makeStyles({
  secureVaultContainer: {
    padding: 30,
  },
  secureVaultTableContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  secureVaultHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  vaultSettingsIcon: {
    marginRight: 4,
    marginBottom: 2,
  },
  vaultSettingBtn: {
    padding: '6px 16px',
    height: 32,
    fontSize: 11,
  },
})

const ProVaultPage = props => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { itemsListIsLoading, isVaultPlanAccepted, itemsList, isProfileGetting } = props
  const query = useQuery()
  const fileId = query.get('file')
  const userID = query.get('userID')

  useEffect(() => {
    Analytics.track(EVENTS.PRO_OPENED_VAULT, {
      vault_place: 'main_menu',
    })
  }, [])

  useEffect(() => {
    if (fileId) {
      dispatch(setVaultOpenPlace('main_menu'))
      dispatch(openVaultModal({ previewedFileId: fileId }))
    }
  }, [fileId])

  useEffect(() => {
    if (userID) {
      dispatch(setVaultOpenPlace('main_menu'))
      dispatch(openVaultModal({ userID: userID }))
    }
  }, [userID])

  useEffect(() => {
    if (isVaultPlanAccepted && !userID && !fileId) dispatch(getVaultFilesList(false, true))
  }, [isVaultPlanAccepted])

  return (
    <div className={classes.secureVaultContainer}>
      {itemsListIsLoading || isProfileGetting ? (
        <ColorCircularProgress />
      ) : (
        <>
          {isVaultPlanAccepted ? (
            <div className={classes.secureVaultTableContainer}>
              <div className={classes.secureVaultHeaderContainer}>
                <Typography variant="h3">Secure vault</Typography>
                <Button
                  variant={'secondary'}
                  className={classes.vaultSettingBtn}
                  onClick={() => dispatch(navigateToVaultSettings())}
                >
                  <SettingsIcon className={classes.vaultSettingsIcon} />
                  settings
                </Button>
              </div>
              <SecureVaultTable filesList={itemsList} />
            </div>
          ) : (
            <VaultPlans />
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  itemsListIsLoading: state.vault.itemsListIsLoading,
  isVaultPlanAccepted: state.vault.isVaultPlanAccepted,
  itemsList: state.vault.itemsList,
  isProfileGetting: state.auth.isProfileGetting,
})

export default connect(mapStateToProps)(ProVaultPage)
