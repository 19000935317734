import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import CompanyLogo from 'static/invoices/invoice_logo.png'
import * as moment from 'moment-timezone'
import React from 'react'
import { CONSTANTS, colors } from 'constants/index'
import { getItemName } from 'utils/invoice/invoice-helpers'

const styles = StyleSheet.create({
  header: {
    padding: '30px 30px 0 30px',
  },
  section: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 28,
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 5,
  },
  creditTitle: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 2,
    color: colors.BLUE_20,
    textTransform: 'uppercase',
  },
  creditInvoiceTitle: {
    fontSize: 11,
  },
  name: {
    fontSize: 17,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 5,
  },
  info: {
    fontSize: 10,
    lineHeight: 1.6,
  },
  subtitle: {
    fontSize: 9,
  },
  headerSubtitle: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 2,
  },
  divider: {
    height: 4,
    backgroundColor: '#e6eaec',
    marginBottom: 28,
  },
  logo: {
    height: 20,
    width: 65,
  },
})

const InvoiceDocumentHeader = ({ invoice, credit, timezone, download }) => {
  const proName = invoice.proFullName || ''
  const clientName = invoice.clientFullName || ''
  const proUsername = invoice.proInfo ? `${invoice.proInfo.username || ''}` : ''
  const clientUsername = invoice.clientUsername || ''
  const proAddress =
    invoice.proInfo && invoice.proInfo.contactInfo
      ? `${invoice.proInfo.contactInfo.suite || ''} ${invoice.proInfo.contactInfo.street || ''}`
      : ''

  const clientAddress = invoice.clientContactInfo
    ? `${invoice.clientContactInfo.suite || ''} ${invoice.clientContactInfo.street || ''}`
    : ''
  const proCity =
    invoice.proInfo && invoice.proInfo.contactInfo
      ? `${invoice.proInfo.contactInfo.city ? `${invoice.proInfo.contactInfo.city},` : ''} ${
          invoice.proInfo.contactInfo.state ? invoice.proInfo.contactInfo.state.replace('US-', '') : ''
        } ${invoice.proInfo.contactInfo.zip || ''}`
      : ''
  const clientCity = invoice.clientContactInfo
    ? `${invoice.clientContactInfo.city ? `${invoice.clientContactInfo.city},` : ''} ${
        invoice.clientContactInfo.state ? invoice.clientContactInfo.state.replace('US-', '') : ''
      } ${invoice.clientContactInfo.zip || ''}`
    : ''
  const proInfo =
    invoice.proInfo && invoice.proInfo.contactInfo
      ? `${invoice.proInfo.contactInfo.phone || ''} ${
          invoice.proInfo.contactInfo.optionalPhone ? `, ${invoice.proInfo.contactInfo.optionalPhone}` : ''
        }`
      : ''
  const clientInfo = invoice.clientContactInfo ? `${invoice.clientContactInfo.phone || ''}` : ''

  return (
    <View fixed style={styles.header}>
      <View fixed style={styles.section}>
        <Image src={CompanyLogo} style={styles.logo} />
        <View style={styles.container}>
          {credit ? (
            <>
              <Text style={styles.creditTitle}>{`${getItemName(credit.type)} ${
                credit.generated_payment_record_number
              }`}</Text>
              <Text style={styles.creditInvoiceTitle}>applied to invoice {invoice.invoiceNumber}</Text>
              <Text style={styles.subtitle}>
                {moment(credit.created_at)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
              </Text>
            </>
          ) : (
            <>
              <Text style={styles.title}>invoice {invoice.invoiceNumber}</Text>
              <Text style={styles.subtitle}>
                {moment(invoice.invoiceDate)
                  .tz(timezone)
                  .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
              </Text>
            </>
          )}
        </View>
      </View>
      <View
        fixed
        style={styles.infoSection}
        render={({ pageNumber }) =>
          (download ? pageNumber !== 1 : true) && (
            <>
              <View style={styles.container}>
                <Text style={styles.headerSubtitle}>TO:</Text>
                <Text style={styles.name}>{clientName}</Text>
                <Text style={styles.info}>{clientAddress}</Text>
                <Text style={styles.info}>{clientCity}</Text>
                <Text style={styles.info}>{clientUsername}</Text>
                <Text style={styles.info}>{clientInfo}</Text>
              </View>
              <View style={styles.container}>
                <Text style={styles.headerSubtitle}>FROM:</Text>
                <Text style={styles.name}>{proName}</Text>
                <Text style={styles.info}>{proAddress}</Text>
                <Text style={styles.info}>{proCity}</Text>
                <Text style={styles.info}>{proUsername}</Text>
                <Text style={styles.info}>{proInfo}</Text>
              </View>
            </>
          )
        }
      />
      <View fixed style={styles.divider} />
    </View>
  )
}

export default InvoiceDocumentHeader
