import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import * as moment from 'moment-timezone'
import { CONSTANTS, colors } from 'constants/index'
import { getInvoiceActivityDescription } from 'utils/getters/invoices'
import { getItemName } from 'utils/invoice/invoice-helpers'

const styles = StyleSheet.create({
  table: {
    marginTop: 8,
    display: 'table',
    width: '100%',
  },
  titleTable: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
  },
  tableRow: {
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#e6eaec',
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 1,
  },
  partialTableCol: {
    width: '15%',
  },
  partialDescriptionTableCol: {
    width: '70%',
  },
  tableHeaderCell: {
    marginTop: 8,
    fontSize: 9,
    height: 24,
  },
  amountTableCell: {
    textAlign: 'right',
  },
  tableCell: {
    marginTop: 8,
    fontSize: 10,
    minHeight: 24,
  },
  tableCellAmount: {
    marginTop: 8,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    minHeight: 24,
  },
  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  subtotalTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  subtotalAmount: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
  },
  partialAmountContainer: {
    marginTop: 32,
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  info: {
    fontSize: 10,
  },
  smDivider: {
    height: 1,
    backgroundColor: '#e6eaec',
    marginBottom: 5,
  },
  caseTitle: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 10,
  },
  void: {
    fontSize: 40,
    fontWeight: 'bold',
    color: colors.BASIC.COLOR['80'],
  },
  voidContainer: {
    position: 'absolute',
    left: 200,
    top: 20,
  },
})

export const getPreviousInvoiceTitle = type => {
  if (type === 'C') return 'Invoice amount'
  else return 'Balance due'
}

export const getCurrentInvoiceTitle = type => {
  if (type === 'C') return 'Adjusted invoice balance after credit'
  else return 'New balance'
}

export const getCreditAmount = (type, amount) => {
  return `$${type === 'C' || type === 'P' ? '-' : ''}${amount && amount.replace('-', '')}`
}

const CreditAdjustmentTable = ({ credit, invoice, timezone }) => {
  return (
    <>
      <Text style={styles.caseTitle}>{invoice.caseName}</Text>
      {credit.is_void && (
        <View style={styles.voidContainer}>
          <Text style={styles.void}>VOID</Text>
        </View>
      )}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.partialTableCol}>
            <Text style={styles.tableHeaderCell}>Date</Text>
          </View>
          <View style={styles.partialDescriptionTableCol}>
            <Text style={styles.tableHeaderCell}>Description</Text>
          </View>
          <View style={styles.partialTableCol}>
            <Text style={[styles.tableHeaderCell, styles.amountTableCell]}>Amount</Text>
          </View>
        </View>
        <View style={styles.tableRow} wrap={false}>
          <View style={styles.partialTableCol}>
            <Text style={styles.tableCell}>
              {moment(credit.created_at)
                .tz(timezone)
                .format(CONSTANTS.DATE_FORMAT.FORMAT_ONE)}
            </Text>
          </View>
          <View style={styles.partialDescriptionTableCol}>
            <Text style={styles.tableCell}>{getInvoiceActivityDescription(credit.description)}</Text>
          </View>
          <View style={styles.partialTableCol}>
            <Text style={[styles.tableCellAmount, styles.amountTableCell]}>{`${credit.type === 'С' ? '-' : ''}$${Number(
              credit.amount
            ).toFixed(2)}`}</Text>
          </View>
        </View>
        <View style={styles.subtotal}>
          <Text style={styles.subtotalTitle}>Total</Text>
          <Text style={styles.subtotalAmount}>{`${credit.type === 'С' ? '-' : ''}$${credit.amount}`}</Text>
        </View>
      </View>
      <View style={styles.partialAmountContainer}>
        <View style={styles.infoSection}>
          <Text style={styles.info}>{getPreviousInvoiceTitle(credit.type)}</Text>
          <Text style={styles.info}>${credit.previous_invoice_amount}</Text>
        </View>
        <View style={styles.smDivider} />
        <View style={styles.infoSection}>
          <Text style={styles.info}>{getItemName(credit.type)}</Text>
          <Text style={styles.info}>{getCreditAmount(credit.type, credit.amount)}</Text>
        </View>
        <View style={styles.smDivider} />
        <View style={styles.infoSection}>
          <Text style={styles.info}>{getCurrentInvoiceTitle(credit.type)}</Text>
          <Text style={styles.info}>${credit.current_invoice_amount_for_display}</Text>
        </View>
      </View>
    </>
  )
}

export default CreditAdjustmentTable
