import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'

export const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  bodyContainer: {
    display: 'flex',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 15,
    paddingLeft: 24,
    paddingBottom: 100,
  },
  footerContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    backgroundColor: colors.WHITE,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    alignItems: 'center',
    padding: '12px 40px',
    justifyContent: 'space-between',
    borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
  },
  container: {
    width: 510,
  },
  footer: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 588,
  },
  switchRow: {
    display: 'flex',
    alignItems: 'center',
    height: 'min-content',
  },
  switchBillable: {
    marginTop: 21,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  autocompleteContainer: {
    width: 588,
  },
  inputContainer: {
    width: 237,
  },
  dateContainer: {
    width: 237,
  },
  flatInputContainer: {
    width: 197,
  },
  inputExpenseContainer: {
    width: 440,
    marginRight: 28,
  },
  tooltip: {
    padding: 16,
    minWidth: 360,
  },
  tooltipIcon: {
    fontSize: 17,
    color: colors.PRIMARY.COLOR[50],
    cursor: 'pointer',
  },
  tooltipText: {
    fontSize: 13,
  },
  formControlClasses: {
    marginRight: 0,
  },
  formControlFlatClasses: {
    marginRight: 0,
  },
  textArea: {
    width: 588,
  },
  subtitle: {
    marginTop: 21,
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
  },
  title: {
    fontWeight: 500,
    letterSpacing: 'normal',
    marginRight: 5,
  },
  shortInfo: {
    paddingTop: 24,
    paddingLeft: 126,
  },
  chargeContainer: {
    padding: '24px 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contingencyChargeContainer: {
    padding: '24px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chargeColumn: {
    display: 'flex',
  },
  chargeText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  chargeTitle: {
    color: colors.BASIC.COLOR[80],
    fontWeight: 'normal',
    fontSize: 18,
    marginRight: 4,
  },
  fullWidthContainer: {
    width: '100%',
  },
  timeContingencyText: {
    width: 300,
    fontSize: 13,
    fontWeight: 'normal',
  },
  link: {
    color: colors.BLUE_20,
  },
  initSubtitle: {
    width: 588,
    fontSize: 13,
    fontWeight: 'normal',
    margin: '16px 0',
  },
  activityFormBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  activityFormBodyContainerScroll: {
    overflowY: 'scroll',
  },
  timeInputContainer: {
    width: 118,
  },
  hoursInputContainer: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  minutesInputContainer: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogActions: {
    padding: 0,
  },
  submitButton: {
    marginRight: 24,
  },
  hintClasses: {
    paddingRight: 5,
  },
  activityDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
  },
})
