import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColorCircularProgress, SvgIcon, TextField, Typography, OnlineIndicator } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { IconButton, InputAdornment } from '@material-ui/core'
import { ReactComponent as SearchIcon } from 'static/chat/icon_24_ search.svg'
import { ReactComponent as CloseIcon } from 'static/chat/_close.svg'
import { getFormattedMessageDate } from 'utils/getters/common'
import {
  getUserChats,
  increaseChatListOffset,
  resetChatListOffset,
  setChatInfo,
  setChatListSearchValue,
  setSelectedChat,
} from 'store/actions/chat/chat'
import classNames from 'classnames'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 59,
    borderBottom: `1px solid ${colors.BASIC.COLOR['10']}`,
  },
  title: {
    margin: '18px 24px 11px 24px',
    fontWeight: 500,
    fontSize: 16,
  },
  search: {
    backgroundColor: colors.BASIC.COLOR['5'],
    borderRadius: 0,
    height: 42,
    fontSize: 15,
  },
  searchFocused: {
    backgroundColor: colors.WHITE,
    '& $icon': {
      fill: colors.PRIMARY.COLOR['50'],
    },
    '& $clearButton': {
      backgroundColor: colors.WHITE,
    },
    '& $searchButton': {
      backgroundColor: colors.WHITE,
    },
  },
  searchIcon: {
    height: 24,
    width: 24,
  },
  classesTabIndicator: {
    backgroundColor: colors.PRIMARY.COLOR['40'],
    left: 0,
    width: 3,
  },
  tabs: {
    backgroundColor: colors.BASIC.COLOR['0'],
    flexGrow: 1,
  },
  tab: {
    maxWidth: '100%',
    padding: 0,
    opacity: 1,
  },
  selectedTab: {
    backgroundColor: colors.WHITE,
    color: colors.PRIMARY.COLOR['40'],
    fontWeight: 500,
  },
  tabInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '14px 28px 14px 16px',
  },
  tabInnerContainerLoadMore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '14px 28px 14px 16px',
    color: colors.PRIMARY.COLOR['50'],
  },
  unreadMessages: {
    backgroundColor: colors.ERROR,
    color: colors.WHITE,
    borderRadius: 12,
    padding: '1px 7px',
    marginRight: 8,
    fontSize: 11,
    fontWeight: 400,
  },
  indicators: {
    display: 'flex',
    alignItems: 'center',
  },
  tabTime: {
    color: colors.BASIC.COLOR['100'],
    fontWeight: 400,
  },
  clearButton: {
    height: 30,
    width: 30,
    backgroundColor: colors.BASIC.COLOR['5'],
  },
  searchButton: {
    height: 30,
    width: 30,
    padding: 0,
    backgroundColor: colors.BASIC.COLOR['5'],
  },
  adornedEnd: {
    position: 'relative',
    marginRight: 35,
  },
  icon: {
    height: 24,
    width: 24,
  },
  circleClass: {
    top: -15,
    left: -15,
  },
  chatMemberName: {
    textTransform: 'none',
  },
  indicator: {
    marginRight: 6,
  },
  indicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  transparent: {
    opacity: 0,
  },
})

const ChatRow = ({ chat }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.indicatorWrapper}>
        <OnlineIndicator
          className={classNames({ [classes.indicator]: true, [classes.transparent]: !chat.chat_member_is_online })}
        />
        <span className={classes.chatMemberName}>{chat.chat_member_full_name}</span>
      </div>

      <div className={classes.indicators}>
        {!!chat.unread_messages_count && <div className={classes.unreadMessages}>{chat.unread_messages_count}</div>}
        <span className={classes.tabTime}>{getFormattedMessageDate(chat.last_message_at)}</span>
      </div>
    </>
  )
}

const ProAccountChatListContainer = props => {
  const { selectedChatId } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const chats = useSelector(state => state.chat.chats)
  const chatCount = useSelector(state => state.chat.count)
  const chatsAreLoading = useSelector(state => state.chat.chatsAreLoading)
  const searchValue = useSelector(state => state.chat.searchValue)

  const onChatSelect = (event, newValue) => {
    if (newValue === 'load_more') {
      onLoadMore()
    }
    const selectedChat = chats.find(chat => chat.twilio_chat_sid === newValue)
    if (selectedChat) {
      dispatch(setSelectedChat(selectedChat))
      dispatch(setChatInfo(selectedChat.twilio_chat_sid, { unread_messages_count: 0 }))
    }
  }

  const onLoadMore = () => {
    dispatch(increaseChatListOffset())
    dispatch(getUserChats(false))
  }

  const onClearSearch = () => {
    dispatch(setChatListSearchValue(''))
    dispatch(resetChatListOffset())
    dispatch(getUserChats())
  }

  const onKeyPress = e => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  const onSearch = () => {
    dispatch(resetChatListOffset())
    dispatch(getUserChats())
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h4" className={classes.title}>
          Connections
        </Typography>
      </div>
      <TextField
        value={searchValue}
        placeholder="Search for people"
        onChange={e => dispatch(setChatListSearchValue(e.target.value))}
        onKeyDown={onKeyPress}
        variant="outlined"
        fullWidth
        InputProps={{
          classes: { root: classes.search, focused: classes.searchFocused },
          startAdornment: (
            <InputAdornment disableTypography className={classes.adornedStart} position="start">
              <IconButton onClick={onSearch} className={classes.searchButton}>
                <SvgIcon viewBox="0 0 24 24" className={classes.icon}>
                  <SearchIcon />
                </SvgIcon>
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment disableTypography className={classes.adornedEnd} position="end">
              {chatsAreLoading ? (
                <ColorCircularProgress size={30} positionClass={classes.circleClass} />
              ) : searchValue ? (
                <IconButton onClick={onClearSearch} className={classes.clearButton}>
                  <SvgIcon viewBox="0 0 24 24">
                    <CloseIcon />
                  </SvgIcon>
                </IconButton>
              ) : (
                ''
              )}
            </InputAdornment>
          ),
        }}
      />

      <Tabs
        value={selectedChatId || false}
        onChange={onChatSelect}
        className={classes.tabs}
        scrollable="true"
        orientation="vertical"
        classes={{
          indicator: classes.classesTabIndicator,
          root: classes.tabs,
        }}
      >
        {chats.map(chat => {
          return (
            <Tab
              key={chat.twilio_chat_sid}
              value={chat.twilio_chat_sid}
              label={<ChatRow chat={chat} />}
              classes={{
                root: classes.tab,
                selected: classes.selectedTab,
                wrapper: classes.tabInnerContainer,
              }}
            />
          )
        })}
        {chats.length < chatCount && !chatsAreLoading && (
          <Tab
            label="Load more"
            classes={{
              root: classes.tab,
              selected: classes.selectedTab,
              wrapper: classes.tabInnerContainerLoadMore,
            }}
          />
        )}
      </Tabs>
    </div>
  )
}

export default ProAccountChatListContainer
