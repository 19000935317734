import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { connect, useDispatch } from 'react-redux'
import { ColorCircularProgress, Switch, Typography } from 'components'
import { MobileVerificationModal } from 'components/profileCreation/MobileVerificationModal'
import { openSnackbar } from 'store/actions/common'
import { getNotificationSettings, updateNotificationSettings } from 'store/actions/notifications/notifications'
import { getUserContactInfo } from 'store/actions/profileCreation'
import { notificationMapper } from './utils'

const useStyles = makeStyles({
  notificationContainer: {
    position: 'relative',
    minHeight: 400,
  },
  notificationTitle: {
    marginBottom: 24,
  },
  notificationHeader: {
    marginBottom: 8,
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationType: {
    display: 'inline-block',
    width: 58,
    textAlign: 'center',
    marginRight: 50,
    '&:last-child': {
      margin: 0,
    },
  },
  notificationItem: {
    padding: '10px 16px',
    height: 60,
    border: '1px solid #DDE2EB',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FAFBFC',
    '&:not(:first-child)': {
      borderTop: 0,
    },
  },
  notificationItemClient: {
    backgroundColor: '#fff',
  },
  notificationItemName: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
  },
})

const NotificationSettings = ({ contactInfo, isClientSettings }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openVerifyModal, setOpenVerifyModal] = useState(null)
  const [loading, setLoading] = useState(false)
  const [notificationsList, setNotificationsList] = useState([])
  const [notificationSettings, setNotificationSettings] = useState({
    hasSMS: false,
    hasPush: false,
    hasEmail: false,
  })

  const onCloseVerification = () => setOpenVerifyModal(null)
  const onAfterConfirmPhone = () => {
    dispatch(getUserContactInfo())
    onFilterChange(openVerifyModal.item, openVerifyModal.v)
    setOpenVerifyModal(null)
  }

  const getNotifications = useCallback(() => {
    setLoading(true)
    dispatch(getNotificationSettings())
      .then(resp => {
        const hasSMS = resp.find(el => el.type === 0)
        const hasPush = resp.find(el => el.type === 1)
        const hasEmail = resp.find(el => el.type === 2)
        setNotificationSettings({ hasSMS, hasPush, hasEmail })
        setNotificationsList(notificationMapper(resp))
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [dispatch])

  const onFilterChange = (item, v) => {
    if (!contactInfo.phone) {
      dispatch(openSnackbar('error', 'Add phone number to manage notifications.'))
      return
    }
    if (!contactInfo.is_phone_confirmed) {
      setOpenVerifyModal({ item, v })
      return
    }
    setNotificationsList(n =>
      n.map(e => ({
        ...e,
        items: e.items.map(el => (el.event === item.type && el.type === item.type ? { ...el, is_enabled: v } : el)),
      }))
    )
    dispatch(updateNotificationSettings(item.uuid, v)).finally(getNotifications)
  }

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  return (
    <div className={classes.notificationContainer}>
      <Typography variant="h3" className={classes.notificationTitle}>
        {isClientSettings ? 'Manage notifications' : 'Notifications'}
      </Typography>
      {loading && <ColorCircularProgress />}
      {!!notificationsList.length && (
        <div className={classes.notificationHeader}>
          <Typography>Select notifications for events you don’t want to miss</Typography>
          <div>
            {notificationSettings.hasEmail && <Typography className={classes.notificationType}>email</Typography>}
            {notificationSettings.hasPush && <Typography className={classes.notificationType}>push</Typography>}
            {notificationSettings.hasSMS && <Typography className={classes.notificationType}>SMS</Typography>}
          </div>
        </div>
      )}
      <div>
        {notificationsList.map(el => (
          <div
            key={el.title}
            className={cn(classes.notificationItem, { [classes.notificationItemClient]: isClientSettings })}
          >
            <Typography className={classes.notificationItemName}>{el.title}</Typography>
            <div>
              {el.items.map(item => (
                <Switch
                  key={item.uuid}
                  name={item.uuid}
                  checked={item.is_enabled}
                  color="default"
                  onChange={(_e, v) => onFilterChange(item, v)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <MobileVerificationModal
        open={!!openVerifyModal}
        number={contactInfo.phone}
        onOk={onAfterConfirmPhone}
        onClose={onCloseVerification}
      />
    </div>
  )
}

const mapStateToProps = ({ profileCreation }) => ({
  contactInfo: profileCreation.profileData.contactInfo,
})

export default connect(mapStateToProps, null)(NotificationSettings)
