import React from 'react'
import { Page, Document } from '@react-pdf/renderer'
import * as moment from 'moment-timezone'
import { getInvoiceSummaryForInvoice } from 'utils/invoice/invoice-helpers'
import { CreditAdjustmentEntry } from './parts'

const InvoicePaymentsCredits = ({ invoice, timezone = moment.tz.guess(), summary }) => {
  return (
    <Document>
      {getInvoiceSummaryForInvoice(summary).map(credit => (
        <Page>
          <CreditAdjustmentEntry invoice={invoice} credit={credit} timezone={timezone} />
        </Page>
      ))}
    </Document>
  )
}

export default InvoicePaymentsCredits
