import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import moment from 'moment-timezone'

const useStyles = makeStyles({
  row: {
    paddingLeft: 150,
    paddingBottom: 5,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    borderRadius: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR[10],
    },
  },
  circle: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY.COLOR[50],
    marginRight: 16,
  },
  pastEvent: {
    opacity: 0.4,
  },
  timeColumn: {
    width: 180,
  },
  time: {
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR[100],
  },
  name: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.BASIC.COLOR[100],
  },
  pastEventText: {
    color: colors.BASIC.COLOR[20],
  },
})

const ListEvent = props => {
  const { eventData, end, start, timezone } = props
  const classes = useStyles()
  const startTime = moment(start).tz(timezone)
  const endTime = moment(end).tz(timezone)
  const today = moment().tz(timezone)
  const pastEvent = today.isAfter(endTime)

  return (
    <div
      className={classNames({
        [classes.row]: true,
        'fc-list-event-row': true,
      })}
    >
      <div className={classes.content}>
        <div className={classNames({ [classes.circle]: true, [classes.pastEvent]: pastEvent })} />
        <div className={classes.timeColumn}>
          <span className={classNames({ [classes.time]: true, [classes.pastEventText]: pastEvent })}>
            {startTime.format('h:mm a')} - {endTime.format('h:mm a')}
          </span>
        </div>
        <div className={classes.timeColumn}>
          <span className={classNames({ [classes.name]: true, [classes.pastEventText]: pastEvent })}>
            {eventData.clientInfo && eventData.clientInfo.firstName}{' '}
            {eventData.clientInfo && eventData.clientInfo.lastName}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ListEvent
