import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/index'
import { ReactComponent as SendIcon } from 'static/chat/icon_24_ paper-plane.svg'
import { Button, SvgIcon, TextArea } from 'components'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Analytics from 'utils/analytics/AnalyticsService'
import { EVENTS } from 'utils/analytics/Events'

const useStyles = makeStyles({
  container: {
    borderTop: `1px solid ${colors.BASIC.COLOR['10']}`,
    display: 'flex',
    alignItems: 'flex-end',
  },
  smallButton: {
    backgroundColor: colors.BASIC.COLOR['10'],
    padding: 1,
    minHeight: 32,
    minWidth: 32,
    height: 32,
    margin: 10,
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['5'],
    },
  },
  bigButton: {
    backgroundColor: colors.BASIC.COLOR['10'],
    color: colors.BASIC.COLOR['30'],
    padding: '5px 12px',
    minHeight: 32,
    minWidth: 32,
    height: 32,
    margin: 10,
    '&:hover': {
      backgroundColor: colors.BASIC.COLOR['5'],
    },
  },
  wrapperClass: {
    width: '100%',
  },
  input: {
    flexGrow: 1,
  },
  noBorders: {
    border: 'none',
    padding: 10,
  },
  sendIcon: {
    fill: colors.BASIC.COLOR['30'],
  },
  active: {
    backgroundColor: colors.PRIMARY.COLOR['50'],
    color: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR['40'],
    },
    '& $sendIcon': {
      fill: colors.WHITE,
    },
  },
})

const MessageInput = props => {
  const { multiline, channel, fullSendButton, selectedChat, messageSent, setMessageSent } = props
  const classes = useStyles()
  const role = useSelector(state => state.auth.currentUser.role)
  const [messageText, setMessageText] = useState('')

  const onType = e => {
    if (e.target.value === '\n') {
      setMessageText(e.target.value.trim())
      return
    }
    if (channel) {
      channel.typing()
    }
    setMessageText(e.target.value)
  }

  const onSend = () => {
    if (channel && messageText.trim()) {
      channel.sendMessage(messageText, {
        To: selectedChat.chat_member_twilio_sid,
      })
      if (!messageSent) {
        Analytics.track(role === 'pro' ? EVENTS.PRO_SENT_MESSAGE : EVENTS.CLIENT_SENT_MESSAGE)
        setMessageSent(true)
      }
      setMessageText('')
    }
  }

  const onKeyPress = e => {
    if (e.keyCode === 13 && e.ctrlKey) {
      setMessageText(`${messageText}\r\n`)
    } else if (e.keyCode === 13) {
      onSend()
    }
  }

  return (
    <div className={classes.container}>
      <TextArea
        handleChange={onType}
        onKeyDown={onKeyPress}
        value={messageText}
        variant="outlined"
        areaClass={classes.noBorders}
        placeholder="Write a message..."
        rowsMax={multiline ? 5 : 1}
        rowsMin={1}
        wrapperClass={classes.wrapperClass}
        characterLimit={1000}
        showCharacterLimit={false}
      />
      {fullSendButton ? (
        <Button
          onClick={onSend}
          variant="primary"
          className={classNames({ [classes.bigButton]: true, [classes.active]: !!messageText })}
        >
          Send
        </Button>
      ) : (
        <Button
          onClick={onSend}
          variant="primary"
          className={classNames({ [classes.smallButton]: true, [classes.active]: !!messageText })}
        >
          <SvgIcon viewBox="0 0 26 24" className={classes.sendIcon}>
            <SendIcon />
          </SvgIcon>
        </Button>
      )}
    </div>
  )
}

export default MessageInput
