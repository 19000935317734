import React, { useState } from 'react'
import { Typography, ExpansionPanel, Icon, PracticeAreasExpansionPanelSummary } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'constants/colors'

const useStyles = makeStyles({
  expansionPanelSummary: {
    backgroundColor: colors.WHITE,
    boxShadow: '0 0 0 0',
    '&.Mui-expanded': {
      backgroundColor: colors.WHITE,
      borderTop: `1px solid ${colors.BASIC.COLOR[10]}`,
      borderRight: `1px solid ${colors.BASIC.COLOR[10]}`,
      borderLeft: `1px solid ${colors.BASIC.COLOR[10]}`,
      borderRadius: '4px 4px 0 0',
    },
  },
  expansionPanelDetails: {
    backgroundColor: colors.WHITE,
    padding: '0px 24px 24px',
    borderBottom: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderRight: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderLeft: `1px solid ${colors.BASIC.COLOR[10]}`,
    borderRadius: '0 0 4px 4px',
  },
  expansionPanelSummaryContent: {
    width: '100%',
    '&.Mui-expanded': {
      backgroundColor: colors.WHITE,
    },
  },
  expansionPanel: {
    boxShadow: '0 0 0 0',
    '&.Mui-expanded': {
      boxShadow: `0 1px 8px 0 ${colors.BASIC.COLOR[20]}`,
      backgroundColor: colors.WHITE,
      borderRadius: 4,
    },
    '&:before': {
      backgroundColor: colors.WHITE,
    },
  },
})

const ExpansionPanelProAccount = ({ summary, details, expanded, onChange }) => {
  const classes = useStyles()
  const iconClass = expanded ? 'las la-minus' : 'las la-plus'
  return (
    <ExpansionPanel
      expandIcon={null}
      expanded={expanded}
      onChange={onChange}
      expansionPanelSummaryClass={{
        root: classes.expansionPanelSummary,
        content: classes.expansionPanelSummaryContent,
      }}
      expansionPanelDetailsClass={{ root: classes.expansionPanelDetails }}
      panelClasses={{ root: classes.expansionPanel }}
      summary={React.cloneElement(summary, { iconClass: iconClass })}
      details={details}
    />
  )
}

export default ExpansionPanelProAccount
