import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { colors, CONSTANTS } from 'constants/index'
import { ReactComponent as InactiveFilterLogo } from 'static/invoices/inactive_filter.svg'
import {
  Button,
  Autocomplete,
  TableResetFilters,
  DateRangePickerComponent,
  CommonSearch,
  CommonFilterButton,
} from 'components'
import {
  getProInvoices,
  setTableConfiguration,
  setInvoicesFilter,
  resetInvoicesFilter,
  resetInvoicesSorting,
} from 'store/actions/invoices'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectConnectionOptionsWithEmptyState } from 'store/selectors/connectionsSelector'

const useStyles = makeStyles({
  filters: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
  },
  filterBtn: {
    padding: '10px 24px',
    border: 'solid 1px #bbbab9',
    color: colors.BASIC.COLOR[80],
    fill: colors.BASIC.COLOR[80],
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 40,
    minHeight: 40,
    '&:hover': {
      fill: colors.PRIMARY.COLOR[50],
    },
  },
  searchIcon: {
    color: colors.PRIMARY.COLOR[50],
    fontSize: 22,
  },
  btnIcon: {
    marginRight: 4,
  },
  activeFilterBtn: {
    backgroundColor: colors.PRIMARY.COLOR[50],
    color: colors.WHITE,
    fill: colors.WHITE,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  filtersContainer: {
    backgroundColor: colors.WHITE,
    padding: '16px 22px',
  },
  resultContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  lgFilter: {
    marginRight: 12,
    width: 290,
  },
  mdFilter: {
    marginRight: 12,
    width: 250,
  },
  dateRangeFilter: {
    marginRight: 12,
  },
})

const invoiceStatusOptions = [
  ...Object.values(CONSTANTS.CLIENT_INVOICE_TYPE_BY_CODE),
  {
    value: null,
    label: 'All statuses',
  },
]

const ClientInvoicesFilter = ({ results, isInitialFilters }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.invoices.filters)
  const connections = useSelector(selectConnectionOptionsWithEmptyState)
  const [search, setSearch] = useState(filters.search)
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    if (search !== filters.search) setSearch(filters.search)
  }, [filters.search])

  const onFilterChange = (name, value) => {
    dispatch(setInvoicesFilter(name, value))
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProInvoices())
  }

  const clearFilters = () => {
    dispatch(resetInvoicesFilter())
    dispatch(resetInvoicesSorting())
    dispatch(
      setTableConfiguration({
        tablePage: 0,
        offset: 0,
      })
    )
    dispatch(getProInvoices())
  }

  return (
    <div className={classes.filters}>
      <div className={classes.row}>
        <CommonSearch
          placeholder="Search by lawyer or invoice number"
          search={search}
          setSearch={setSearch}
          setFilter={value => dispatch(setInvoicesFilter('search', value))}
          setTableConfiguration={() => dispatch(setTableConfiguration({ tablePage: 0, offset: 0 }))}
          getSearchResults={() => dispatch(getProInvoices())}
        />
        <CommonFilterButton onClick={() => setShowFilters(show => !show)} showFilters={showFilters} />
      </div>
      {showFilters && (
        <div className={classes.filtersContainer}>
          <div className={classes.row}>
            <Autocomplete
              fullWidth
              placeholder="Lawyer"
              label="Lawyer"
              variant="outlined"
              className={classes.lgFilter}
              value={filters.pro}
              onChange={(e, v) => onFilterChange('pro', v ? v.value : null)}
              options={connections}
            />
            <Autocomplete
              fullWidth
              placeholder="Invoice status"
              label="Invoice status"
              options={invoiceStatusOptions}
              variant="outlined"
              disableClearable
              className={classes.mdFilter}
              value={filters.invoiceStatus}
              onChange={(e, v) => onFilterChange('invoiceStatus', v ? v.value : null)}
            />
            <DateRangePickerComponent
              containerClasses={classes.dateRangeFilter}
              range={filters.issuedRange}
              inputClasses={classes.rangeInput}
              updateRange={value => onFilterChange('issuedRange', value)}
              label="Issued between"
            />
          </div>
          <TableResetFilters
            count={results}
            showClearFilters={!isInitialFilters}
            onClearFilters={clearFilters}
            containerClass={classes.resultContainer}
          />
        </div>
      )}
    </div>
  )
}

export default ClientInvoicesFilter
