import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, SvgIcon, Tooltip } from 'components'
import { colors } from 'constants/index'
import { ReactComponent as MoreIcon } from 'static/common/more vertical.svg'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as InfoIcon } from '../../static/invoices/info_tooltip.svg'
import classNames from 'classnames'

const useStyles = makeStyles({
  disabled: {
    color: colors.BASIC.COLOR[20],
    cursor: 'initial',
  },
  tooltip: {
    fontSize: 13,
    padding: 16,
    maxWidth: 330,
    color: colors.BASIC.COLOR[100],
    fontWeight: 'normal',
  },
  labelRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  actionIcon: {
    marginLeft: 4,
  },
  buttonClass: {
    backgroundColor: 'inherit',
  },
})

const ThreeDotsMenu = ({ actions, data, iconButtonClass }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleActionsOpen = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleActionsOpen} className={classNames(iconButtonClass, classes.buttonClass)}>
        <SvgIcon viewBox="0 0 24 24">
          <MoreIcon />
        </SvgIcon>
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {actions.map(action => (
          <MenuItem
            key={action.key}
            classes={{ root: action.disabled && classes.disabled }}
            onClick={e => {
              e.stopPropagation()
              if (!action.disabled) {
                setAnchorEl(null)
                action.action(data)
              }
            }}
          >
            {action.hint ? (
              <Tooltip showChildren title={action.hint} tooltipClass={classes.tooltip} interactive>
                <div>{action.label}</div>
              </Tooltip>
            ) : (
              <div className={classes.labelRow}>
                {action.label}
                {action.tooltipText && (
                  <Tooltip title={action.tooltipText} tooltipClass={classes.tooltip}>
                    <InfoIcon className={classes.actionIcon} />
                  </Tooltip>
                )}
              </div>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ThreeDotsMenu
