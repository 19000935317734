import { makeStyles } from '@material-ui/core'
import { colors } from 'constants/index'

export const useStyles = makeStyles({
  link: {
    fontSize: 15,
    fontWeight: 'normal',
    textDecoration: 'none',
    color: colors.BASIC.COLOR['100'],
    '&:hover': {
      fontSize: 15,
      fontWeight: 'normal',
      color: colors.ADDITIONAL.COLOR['50'],
    },
  },
  boldLink: {
    fontWeight: 500,
  },
  mainFolderIcon: {
    marginRight: 12,
  },
  secondaryIcon: {
    marginRight: 4,
  },
  input: {
    height: 32,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  tableCell: {
    paddingLeft: 0,
  },
  numbers: {
    fontSize: 15,
    fontWeight: 'normal',
    color: colors.BASIC.COLOR['100'],
  },
  inactiveNumber: {
    color: colors.BASIC.COLOR['50'],
  },
  fileInfoContainer: {
    marginLeft: 16,
    display: 'flex',
  },
  icon: {
    marginRight: 8,
  },
  error: {
    color: colors.ERROR,
    padding: '5px 0',
    textAlign: 'left',
  },
  tableCellLog: {
    paddingLeft: 0,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 13,
    fontWeight: 400,
    color: colors.BASIC.COLOR[100],
  },
  action: {
    fontWeight: 500,
  },
  thumbnail: {
    maxWidth: 32,
    maxHeight: 32,
    marginRight: 8,
  },
  selectedRow: {
    backgroundColor: colors.ADDITIONAL.TRANSPARENT["8"]
  }
})
