import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { CONSTANTS } from 'constants/index'
import { ReactComponent as MoveIcon } from 'static/vault/moveModal/move.svg'
import { ReactComponent as DownloadIcon } from 'static/vault/header/download.svg'
import {
  ConfirmationModal,
  CasesVaultTable,
  VaultFolder,
  VaultFilePreview,
  ColorCircularProgress,
  VaultMoveItemsModal,
  MoveProgressModal,
  VaultDeleteItemsModal,
  ErrorModal,
} from 'components'
import { ReactComponent as CopyIcon } from 'static/vault/header/copy.svg'
import {
  getVaultSubFolderItem,
  moveVaultItems,
  setVaultItemsAction,
  setVaultSelectedItems,
  updateVaultPlans,
  setVaultSizeExceeded,
  setVaultCopySizeExceeded,
  copyVaultItems,
  deleteVaultItem,
  closeVaultModal,
  setPausedUploadFiles,
  setUploadFile,
} from 'store/actions/vault'
import {
  getVaultCaseChildrenFolder,
  getVaultCaseFolder,
  getVaultPrimaryFolders,
  getIsPrivateFolderSelected,
} from 'store/selectors/vaultSelector'
import { openChatModal } from 'store/actions/chat/chat'

const VaultCases = props => {
  const dispatch = useDispatch()
  const {
    previewItem,
    caseID,
    selectedFolders,
    itemsListIsLoading,
    currentFolderItemsList,
    isMoving,
    isFolderCreationInProgress,
    caseChildrenFolders,
    caseFolder,
    primaryFolders,
    itemsList,
    itemsAction,
    selectedItems,
    singleActionItem,
    vaultSizeExceeded,
    copyVaultSizeExceeded,
    maxCopySize,
    isPrivateFolder,
    userID,
    isCoping,
    isClientVault,
    proTwilioChatID,
    pausedUploadFiles,
    previewItemLoading,
  } = props
  const [limitError, setLimitError] = useState(false)
  const [copyLimitError, setCopyLimitError] = useState(false)

  useEffect(() => {
    dispatch(getVaultSubFolderItem())
    dispatch(setVaultSelectedItems([]))
  }, [selectedFolders])

  useEffect(() => {
    setLimitError(vaultSizeExceeded)
  }, [vaultSizeExceeded])

  useEffect(() => {
    setCopyLimitError(copyVaultSizeExceeded)
  }, [copyVaultSizeExceeded])

  const getActionItems = () => {
    if (
      [
        CONSTANTS.VAULT_ACTIONS.MASS_DELETE,
        CONSTANTS.VAULT_ACTIONS.MASS_MOVE,
        CONSTANTS.VAULT_ACTIONS.MASS_COPY,
      ].indexOf(itemsAction) > -1
    ) {
      return selectedItems
    } else if (
      [CONSTANTS.VAULT_ACTIONS.DELETE, CONSTANTS.VAULT_ACTIONS.MOVE, CONSTANTS.VAULT_ACTIONS.COPY].indexOf(
        itemsAction
      ) > -1
    ) {
      return singleActionItem
    }
    return []
  }

  const getItemsAction = () => {
    if (itemsAction === CONSTANTS.VAULT_ACTIONS.MASS_MOVE) {
      return 'move'
    } else if (itemsAction === CONSTANTS.VAULT_ACTIONS.MASS_COPY) {
      return 'copy'
    }
    return itemsAction
  }

  const onModalClose = () => {
    dispatch(setVaultItemsAction(null))
  }

  const onItemsDeleteConfirm = async items => {
    await dispatch(deleteVaultItem(items))
    onModalClose()
    dispatch(setVaultSelectedItems([]))
  }

  const onExceedSpaceModalConfirm = async () => {
    if (isClientVault) {
      dispatch(openChatModal(proTwilioChatID, 'connections'))
      dispatch(closeVaultModal())
    } else {
      window.open('https://member.xira.com/upgrade/', '_blank', 'noopener noreferrer')
      // await dispatch(updateVaultPlans({ vault_plan: 'premium' }))
      // dispatch(setUploadFile(pausedUploadFiles))
    }
    setLimitError(false)
    dispatch(setVaultSizeExceeded(false))
  }

  const onExceedSpaceModalClose = () => {
    dispatch(setVaultSizeExceeded(false))
    dispatch(setPausedUploadFiles([]))
    setLimitError(false)
  }

  const onCopyErrorModalClose = () => {
    dispatch(setVaultCopySizeExceeded(false, 0))
    setCopyLimitError(false)
  }

  const onItemsMove = async (items, destination) => {
    try {
      if (itemsAction === CONSTANTS.VAULT_ACTIONS.COPY || itemsAction === CONSTANTS.VAULT_ACTIONS.MASS_COPY) {
        await dispatch(copyVaultItems(items, destination))
      } else {
        await dispatch(moveVaultItems(items, destination))
      }
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setVaultSelectedItems([]))
    }
  }

  return (
    <>
      {(itemsListIsLoading &&
        itemsAction !== CONSTANTS.VAULT_ACTIONS.MASS_MOVE &&
        itemsAction !== CONSTANTS.VAULT_ACTIONS.MASS_COPY &&
        itemsAction !== CONSTANTS.VAULT_ACTIONS.MOVE &&
        itemsAction !== CONSTANTS.VAULT_ACTIONS.COPY) ||
      previewItemLoading ? (
        <ColorCircularProgress />
      ) : (
        <>
          {!caseID && !isPrivateFolder ? (
            <CasesVaultTable selectedFolders={selectedFolders} filesList={currentFolderItemsList} />
          ) : (
            <>
              {previewItem ? (
                <VaultFilePreview previewItem={previewItem} />
              ) : (
                <VaultFolder
                  selectedFolders={selectedFolders}
                  itemsAction={itemsAction}
                  selectedItems={selectedItems}
                  filesList={currentFolderItemsList}
                  handleMove={onItemsMove}
                  {...props}
                />
              )}
            </>
          )}
          <VaultDeleteItemsModal
            deleteItems={getActionItems()}
            onConfirm={onItemsDeleteConfirm}
            isOpen={[CONSTANTS.VAULT_ACTIONS.MASS_DELETE, CONSTANTS.VAULT_ACTIONS.DELETE].indexOf(itemsAction) > -1}
            onClose={onModalClose}
          />
          <VaultMoveItemsModal
            isOpen={
              [
                CONSTANTS.VAULT_ACTIONS.MASS_MOVE,
                CONSTANTS.VAULT_ACTIONS.MASS_COPY,
                CONSTANTS.VAULT_ACTIONS.MOVE,
                CONSTANTS.VAULT_ACTIONS.COPY,
              ].indexOf(itemsAction) > -1
            }
            isPrivateFolder={isPrivateFolder}
            action={getItemsAction()}
            userID={userID}
            moveItems={getActionItems()}
            onClose={onModalClose}
            primaryFolders={primaryFolders}
            selectedFolders={selectedFolders}
            itemsList={itemsList}
            caseFolder={caseFolder}
            caseChildrenFolders={caseChildrenFolders}
            handleMove={onItemsMove}
            caseID={caseID}
            isFolderCreationInProgress={isFolderCreationInProgress}
          />
          <MoveProgressModal
            isOpen={isMoving}
            icon={<MoveIcon />}
            message={`moving ${selectedItems.length || 1} ${selectedItems.length > 1 ? 'items' : 'item'}...`}
          />
          <MoveProgressModal
            isOpen={isCoping}
            icon={<CopyIcon />}
            message={`coping ${selectedItems.length || 1} ${selectedItems.length > 1 ? 'items' : 'item'}...`}
          />
          <MoveProgressModal
            isOpen={itemsAction === CONSTANTS.VAULT_ACTIONS.DOWNLOAD}
            icon={<DownloadIcon />}
            message={`downloading...`}
          />

          <ConfirmationModal
            isOpen={limitError}
            message={
              isClientVault
                ? 'There is not enough space to upload your files. Please contact you lawyer for details.'
                : 'switch to PREMIUM PLAN for unlimited storage'
            }
            onClose={onExceedSpaceModalClose}
            title={
              isClientVault
                ? 'Not enough space'
                : 'Upload FAILED because you are about to exceed your free storage limit'
            }
            onConfirm={onExceedSpaceModalConfirm}
            confirmationButtonText={isClientVault ? 'contact' : 'premium plan'}
            cancelationButtonText="cancel"
          />
          <ErrorModal
            variant="primary"
            isOpen={copyLimitError}
            onClose={onCopyErrorModalClose}
            title="Error"
            message={`File(s) size too large (max ${maxCopySize} MB)`}
            cancelationButtonText="ok"
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  caseID: state.vault.caseID,
  vaultSizeExceeded: state.vault.vaultSizeExceeded,
  copyVaultSizeExceeded: state.vault.copyVaultSizeExceeded,
  maxCopySize: state.vault.maxCopySize,
  userID: state.vault.userID,
  selectedFolders: state.vault.selectedFolders,
  itemsListIsLoading: state.vault.itemsListIsLoading,
  currentFolderItemsList: state.vault.currentFolderItemsList,
  isMoving: state.vault.isMoving,
  isCoping: state.vault.isCoping,
  isFolderCreationInProgress: state.vault.isFolderCreationInProgress,
  caseChildrenFolders: getVaultCaseChildrenFolder(state),
  caseFolder: getVaultCaseFolder(state),
  primaryFolders: getVaultPrimaryFolders(state),
  itemsList: state.vault.itemsList,
  itemsAction: state.vault.itemsAction,
  selectedItems: state.vault.selectedItems,
  singleActionItem: state.vault.singleActionItem,
  isClientVault: state.vault.isClientVault,
  isPrivateFolder: getIsPrivateFolderSelected(state),
  proTwilioChatID: state.vault.proTwilioChatID,
  pausedUploadFiles: state.vault.pausedUploadFiles,
  previewItemLoading: state.vault.previewItemLoading,
})

export default connect(mapStateToProps)(VaultCases)
